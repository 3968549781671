import ROUTES, { DM_ROUTING } from "config/routing";
import D2DCardImage from "static/media/D2DCardImage.png";
import D2DDisabledCardImage from "static/media/door-to-door-disabled.svg";
import DMCardImage from "static/media/DMCardImage.png";
import DMDisabledCardImage from "static/media/direct-mail-disabled.svg";
import suburbanAreas from "static/media/suburban-areas.svg";
import goodConversion from "static/media/good-conversion.svg";
import H2HCardImg from "static/media/H2HCardImage.png";
import H2HDisabledCardImage from "static/media/hand-to-hand.svg";
import cityCenters from "static/media/city-centers.svg";
import highConversion from "static/media/high-conversion.svg";
import { CAMPAIGN_ENUM_TYPES } from "../shared/constants";

export const COUPON_CODE_ERROR_MESSAGE = {
  DEFAULT: "The coupon code you entered is invalid. Please check the code and try again. CODE: 1",
  NO_PROMO_EXIST: "The coupon code you entered is invalid. Please check the code and try again. CODE: 2",
  EXCEED_USE_PROMO: "The coupon code you entered has expired and is no longer valid. CODE: 3",
  EXCEED_USE_COUPON: "The coupon code you entered has expired and is no longer valid. CODE: 4",
  COUPON_EXPRIED: "The coupon code you entered has expired and is no longer valid. CODE: 5",
  NO_PERMISSION:
    "There was an issue with the promo code you entered. Please contact our support team for assistance. CODE: 6",
  INVALID_CURRENCY:
    "There was an issue with the promo code you entered. Please contact our support team for assistance. CODE: 7",
  MINIMUM_ORDER:
    "There was an issue with the promo code you entered. Please contact our support team for assistance. CODE: 8",
  INVALID_CURRENCY_IN_RESTRICTION:
    "There was an issue with the promo code you entered. Please contact our support team for assistance. CODE: 9",
  ONLY_FIRST: "The coupon code you entered has expired and is no longer valid. CODE: 10",
  PROMO_EXPIRED: "The coupon code you entered has expired and is no longer valid. CODE: 11",
  INCORRECT_DISCOUNT_VALUE:
    "There was an issue with the promo code you entered. Please contact our support team for assistance. CODE: 12",
  LESS_THAN:
    "There was an issue with the promo code you entered. Please contact our support team for assistance. CODE: 13",
};

export const CAMPAIGN_INTERNAL_STATUSES = {
  DRAFT: "DRAFT",
  IN_REVIEW: "IN_REVIEW",
  UPLOAD_CODES: "UPLOAD_CODES",
  CODE_REVIEW: "CODE_REVIEW",
  GENERATING_DIGITAL_PROOF: "GENERATING_DIGITAL_PROOF",
  PENDING_DIGITAL_PROOF: "PENDING_DIGITAL_PROOF",
  PRINTING: "PRINTING",
  PAYMENT: "PAYMENT",
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
};

export const READABLE_CAMPAIGN_INTERNAL_STATUSES = {
  DRAFT: "Draft",
  IN_REVIEW: "In Review",
  UPLOAD_CODES: "Upload Codes",
  CODE_REVIEW: "Code Review",
  GENERATING_DIGITAL_PROOF: "Generating Digital Proof",
  PENDING_DIGITAL_PROOF: "Pending Digital Proof",
  PRINTING: "Printing",
  PAYMENT: "Printing",
  ACTIVE: "Active",
  COMPLETED: "Completed",
};

export const MAP_TYPES = {
  CIRCLE: "circle",
  POLYGON: "polygon",
  MARKER: "marker",
  DRAG: "drag",
};

export const HAND2HAND_TYPES = {
  BROAD_AREAS: "BROAD_AREAS",
  PRECISE_LOCATIONS: "PRECISE_LOCATIONS",
};

export const COLORS = {
  BLUE: "#4A86E8",
  RED: "#EE4360",
  ORANGE: "#FF5C00",
  YELLOW: "#FFB03A",
  GREEN: "#32935A",
  WHITE: "#FFFFFF",
  GRAY: "#73797E",
};

export const HEADER_TITLE = {
  CAMPAIGN_CHANNEL: "Channel",
  CAMPAIGN_DETAILS: "Details",
  CAMPAIGN_AUDIENCE: "Audience",
  CAMPAIGN_SEGMENTS: "Segments",
  FLYER_BUILDER: "Design",
  UPLOAD_DESIGN: "Upload design",
  FLYER_CANVAS: "Flyer canvas",
  SUBMIT: "Submit",
};

export const EDDM_FAQ_PAGES = {
  segments: "https://selfserve.oppizi.com/faqs/segments/",
  upload: "https://selfserve.oppizi.com/faqs/upload-design-eddm/",
  details: "https://selfserve.oppizi.com/faqs/details-eddm/",
  submit: "https://selfserve.oppizi.com/faqs/review-and-submit/",
};

export const HELP_URL = {
  [HEADER_TITLE.CAMPAIGN_CHANNEL]: "https://selfserve.oppizi.com/faqs/channel/",
  [HEADER_TITLE.CAMPAIGN_DETAILS]: "https://selfserve.oppizi.com/faqs/details/",
  [HEADER_TITLE.CAMPAIGN_AUDIENCE]: "https://selfserve.oppizi.com/faqs/audience/",
  [HEADER_TITLE.FLYER_BUILDER]: "https://selfserve.oppizi.com/faqs/create-campaign",
  [HEADER_TITLE.FLYER_CANVAS]: "https://selfserve.oppizi.com/faqs/flyer-builder/",
  [HEADER_TITLE.UPLOAD_DESIGN]: "https://selfserve.oppizi.com/faqs/upload-design/",
  [HEADER_TITLE.SUBMIT]: "https://selfserve.oppizi.com/faqs/review-submit/",
};

export const NAVIGATION = [
  // {
  //   title: HEADER_TITLE.CAMPAIGN_CHANNEL,
  //   href: ROUTES.CAMPAIGN_CHANNEL,
  //   step: "channel",
  //   index: 0,
  // },
  {
    title: HEADER_TITLE.CAMPAIGN_AUDIENCE,
    href: ROUTES.CAMPAIGN_AUDIENCE,
    step: "audience",
    index: 1,
  },
  {
    title: HEADER_TITLE.FLYER_BUILDER,
    href: ROUTES.FLYER,
    step: "design",
    index: 2,
  },
  {
    title: HEADER_TITLE.CAMPAIGN_DETAILS,
    href: ROUTES.CAMPAIGN_DETAILS,
    step: "details",
    index: 3,
  },
  {
    title: HEADER_TITLE.SUBMIT,
    href: ROUTES.SUBMIT,
    step: "submit",
    index: 4,
  },
];

export const EDDM_NAVIGATION = [
  // {
  //   title: HEADER_TITLE.CAMPAIGN_CHANNEL,
  //   href: ROUTES.CAMPAIGN_CHANNEL,
  //   step: "channel",
  //   index: 0,
  // },
  {
    title: HEADER_TITLE.CAMPAIGN_SEGMENTS,
    href: DM_ROUTING.DM_CAMPAIGN_SEGMENTS,
    step: "segments",
    index: 1,
  },
  {
    title: HEADER_TITLE.FLYER_BUILDER,
    href: DM_ROUTING.DM_UPLOAD_FLYER,
    step: "design",
    index: 2,
  },
  {
    title: HEADER_TITLE.CAMPAIGN_DETAILS,
    href: DM_ROUTING.DM_CAMPAIGN_DETAILS,
    step: "details",
    index: 3,
  },
  {
    title: HEADER_TITLE.SUBMIT,
    href: DM_ROUTING.DM_SUBMIT,
    step: "submit",
    index: 4,
  },
];

export const CHANNEL_OPTIONS = {
  [CAMPAIGN_ENUM_TYPES.HANDTOHAND]: {
    img: H2HCardImg,
    disabledImg: H2HDisabledCardImage,
    type: CAMPAIGN_ENUM_TYPES.HANDTOHAND,
    title: "Hand to hand",
    subTitle:
      "Our Brand Ambassadors distribute your flyers and leaflets in the streets and catch your target audience on their way: in the streets, around universities, stations, parks, and much more.",
    benefits: [
      { icon: cityCenters, text: "City centers" },
      { icon: highConversion, text: "High conversion" },
    ],
  },
  [CAMPAIGN_ENUM_TYPES.LETTERBOX]: {
    img: D2DCardImage,
    disabledImg: D2DDisabledCardImage,
    type: CAMPAIGN_ENUM_TYPES.LETTERBOX,
    title: "Door to door",
    subTitle: "Reach your future customers directly at home with our seamless letterbox drop flyer distribution.",
    benefits: [
      { icon: suburbanAreas, text: "Suburban areas" },
      { icon: goodConversion, text: "Good conversion" },
    ],
  },
  [CAMPAIGN_ENUM_TYPES.DIRECTMAIL]: {
    img: DMCardImage,
    disabledImg: DMDisabledCardImage,
    type: CAMPAIGN_ENUM_TYPES.DIRECTMAIL,
    title: "Local direct mail",
    subTitle:
      "Deliver your message directly in your target audience's mailbox. Decide where and when you want your flyers distributed and let us take care of the rest.",
    benefits: [
      { icon: suburbanAreas, text: "Suburban areas" },
      { icon: goodConversion, text: "Good conversion" },
    ],
  },
};

export const PAPER_WEIGHT = [
  { title: "200G", value: "200" },
  { title: "300G", value: "300" },
  { title: "350G", value: "350" },
];

export const FLYER_TYPES = [
  { value: "TEARAWAY", name: "TEARAWAY" },
  { value: "a6", name: "A6" },
];

export const FLYER_WEIGHTS = [
  { value: "300", name: "300" },
  { value: "400", name: "400" },
  { value: "500", name: "500" },
];

export const platformUSACities = {
  Athens: "GA",
  Atlanta: "GA",
  Austin: "TX",
  Boston: "MA",
  Charleston: "SC",
  Charlotte: "NC",
  Chicago: "IL",
  Dallas: "TX",
  Denver: "CO",
  "Fort Myers": "FL",
  Jacksonville: "FL",
  Houston: "TX",
  "Jersey City": "NJ",
  "Los Angeles": "CA",
  Miami: "FL",
  "New York City": "NY",
  Philadelphia: "PA",
  Phoenix: "AZ",
  "San Antonio": "TX",
  "San Diego": "CA",
  Sarasota: "FL",
  Savannah: "GA",
  "San Francisco": "CA",
  Seattle: "WA",
  Tampa: "FL",
  Tucson: "AZ",
};

export const stateKeys = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const usaCities = [
  {
    name: "New York",
    state_id: "NY",
    lat: 40.6943,
    lng: -73.9249,
    id: 1840034016,
  },
  {
    name: "Los Angeles",
    state_id: "CA",
    lat: 34.1139,
    lng: -118.4068,
    id: 1840020491,
  },
  {
    name: "Chicago",
    state_id: "IL",
    lat: 41.8373,
    lng: -87.6862,
    id: 1840000494,
  },
  {
    name: "Miami",
    state_id: "FL",
    lat: 25.7839,
    lng: -80.2102,
    id: 1840015149,
  },
  {
    name: "Dallas",
    state_id: "TX",
    lat: 32.7936,
    lng: -96.7662,
    id: 1840019440,
  },
  {
    name: "Philadelphia",
    state_id: "PA",
    lat: 40.0077,
    lng: -75.1339,
    id: 1840000673,
  },
  {
    name: "Houston",
    state_id: "TX",
    lat: 29.7863,
    lng: -95.3889,
    id: 1840020925,
  },
  {
    name: "Atlanta",
    state_id: "GA",
    lat: 33.7627,
    lng: -84.4224,
    id: 1840013660,
  },
  {
    name: "Washington",
    state_id: "DC",
    lat: 38.9047,
    lng: -77.0163,
    id: 1840006060,
  },
  {
    name: "Boston",
    state_id: "MA",
    lat: 42.3188,
    lng: -71.0846,
    id: 1840000455,
  },
  {
    name: "Phoenix",
    state_id: "AZ",
    lat: 33.5722,
    lng: -112.0891,
    id: 1840020568,
  },
  {
    name: "Seattle",
    state_id: "WA",
    lat: 47.6211,
    lng: -122.3244,
    id: 1840021117,
  },
  {
    name: "San Francisco",
    state_id: "CA",
    lat: 37.7562,
    lng: -122.443,
    id: 1840021543,
  },
  {
    name: "Detroit",
    state_id: "MI",
    lat: 42.3834,
    lng: -83.1024,
    id: 1840003971,
  },
  {
    name: "San Diego",
    state_id: "CA",
    lat: 32.8312,
    lng: -117.1225,
    id: 1840021990,
  },
  {
    name: "Minneapolis",
    state_id: "MN",
    lat: 44.9635,
    lng: -93.2678,
    id: 1840007830,
  },
  {
    name: "Tampa",
    state_id: "FL",
    lat: 27.9942,
    lng: -82.4451,
    id: 1840015982,
  },
  {
    name: "Denver",
    state_id: "CO",
    lat: 39.7621,
    lng: -104.8759,
    id: 1840018789,
  },
  {
    name: "Brooklyn",
    state_id: "NY",
    lat: 40.6501,
    lng: -73.9496,
    id: 1840034030,
  },
  {
    name: "Queens",
    state_id: "NY",
    lat: 40.7498,
    lng: -73.7976,
    id: 1840034002,
  },
  {
    name: "Riverside",
    state_id: "CA",
    lat: 33.9381,
    lng: -117.3948,
    id: 1840020551,
  },
  {
    name: "Baltimore",
    state_id: "MD",
    lat: 39.3051,
    lng: -76.6144,
    id: 1840001592,
  },
  {
    name: "Las Vegas",
    state_id: "NV",
    lat: 36.2333,
    lng: -115.2654,
    id: 1840020364,
  },
  {
    name: "Portland",
    state_id: "OR",
    lat: 45.5372,
    lng: -122.65,
    id: 1840019941,
  },
  {
    name: "San Antonio",
    state_id: "TX",
    lat: 29.4658,
    lng: -98.5253,
    id: 1840022220,
  },
  {
    name: "St. Louis",
    state_id: "MO",
    lat: 38.6358,
    lng: -90.2451,
    id: 1840001651,
  },
  {
    name: "Sacramento",
    state_id: "CA",
    lat: 38.5667,
    lng: -121.4683,
    id: 1840021491,
  },
  {
    name: "Orlando",
    state_id: "FL",
    lat: 28.4772,
    lng: -81.3369,
    id: 1840015099,
  },
  {
    name: "San Jose",
    state_id: "CA",
    lat: 37.3019,
    lng: -121.8486,
    id: 1840021570,
  },
  {
    name: "Cleveland",
    state_id: "OH",
    lat: 41.4767,
    lng: -81.6804,
    id: 1840000596,
  },
  {
    name: "Pittsburgh",
    state_id: "PA",
    lat: 40.4396,
    lng: -79.9762,
    id: 1840001254,
  },
  {
    name: "Austin",
    state_id: "TX",
    lat: 30.3004,
    lng: -97.7522,
    id: 1840019590,
  },
  {
    name: "Cincinnati",
    state_id: "OH",
    lat: 39.1413,
    lng: -84.5061,
    id: 1840003814,
  },
  {
    name: "Kansas City",
    state_id: "MO",
    lat: 39.1239,
    lng: -94.5541,
    id: 1840008535,
  },
  {
    name: "Manhattan",
    state_id: "NY",
    lat: 40.7834,
    lng: -73.9662,
    id: 1840034000,
  },
  {
    name: "Indianapolis",
    state_id: "IN",
    lat: 39.7771,
    lng: -86.1458,
    id: 1840030084,
  },
  {
    name: "Columbus",
    state_id: "OH",
    lat: 39.9862,
    lng: -82.985,
    id: 1840003760,
  },
  {
    name: "Charlotte",
    state_id: "NC",
    lat: 35.208,
    lng: -80.8304,
    id: 1840014557,
  },
  {
    name: "Virginia Beach",
    state_id: "VA",
    lat: 36.7335,
    lng: -76.0435,
    id: 1840003871,
  },
  {
    name: "Bronx",
    state_id: "NY",
    lat: 40.8501,
    lng: -73.8662,
    id: 1840033999,
  },
  {
    name: "Milwaukee",
    state_id: "WI",
    lat: 43.0642,
    lng: -87.9673,
    id: 1840003046,
  },
  {
    name: "Providence",
    state_id: "RI",
    lat: 41.823,
    lng: -71.4187,
    id: 1840003289,
  },
  {
    name: "Jacksonville",
    state_id: "FL",
    lat: 30.3322,
    lng: -81.6749,
    id: 1840015031,
  },
  {
    name: "Salt Lake City",
    state_id: "UT",
    lat: 40.7777,
    lng: -111.9306,
    id: 1840021383,
  },
  {
    name: "Nashville",
    state_id: "TN",
    lat: 36.1715,
    lng: -86.7843,
    id: 1840036155,
  },
  {
    name: "Richmond",
    state_id: "VA",
    lat: 37.5295,
    lng: -77.4756,
    id: 1840001698,
  },
  {
    name: "Memphis",
    state_id: "TN",
    lat: 35.1046,
    lng: -89.9773,
    id: 1840015457,
  },
  {
    name: "Raleigh",
    state_id: "NC",
    lat: 35.8325,
    lng: -78.6435,
    id: 1840014497,
  },
  {
    name: "New Orleans",
    state_id: "LA",
    lat: 30.0687,
    lng: -89.9288,
    id: 1840001839,
  },
  {
    name: "Louisville",
    state_id: "KY",
    lat: 38.1663,
    lng: -85.6485,
    id: 1840030815,
  },
  {
    name: "Oklahoma City",
    state_id: "OK",
    lat: 35.4676,
    lng: -97.5136,
    id: 1840020428,
  },
  {
    name: "Bridgeport",
    state_id: "CT",
    lat: 41.1918,
    lng: -73.1953,
    id: 1840004836,
  },
  {
    name: "Buffalo",
    state_id: "NY",
    lat: 42.9016,
    lng: -78.8487,
    id: 1840000386,
  },
  {
    name: "Fort Worth",
    state_id: "TX",
    lat: 32.7811,
    lng: -97.3473,
    id: 1840020696,
  },
  {
    name: "Hartford",
    state_id: "CT",
    lat: 41.7661,
    lng: -72.6834,
    id: 1840004773,
  },
  {
    name: "Tucson",
    state_id: "AZ",
    lat: 32.1545,
    lng: -110.8782,
    id: 1840022101,
  },
  {
    name: "Omaha",
    state_id: "NE",
    lat: 41.2627,
    lng: -96.0522,
    id: 1840009315,
  },
  {
    name: "El Paso",
    state_id: "TX",
    lat: 31.8479,
    lng: -106.4309,
    id: 1840023252,
  },
  {
    name: "Honolulu",
    state_id: "HI",
    lat: 21.3294,
    lng: -157.846,
    id: 1840013305,
  },
  {
    name: "McAllen",
    state_id: "TX",
    lat: 26.2273,
    lng: -98.2471,
    id: 1840021024,
  },
  {
    name: "Albuquerque",
    state_id: "NM",
    lat: 35.1053,
    lng: -106.6464,
    id: 1840019176,
  },
  {
    name: "Birmingham",
    state_id: "AL",
    lat: 33.5277,
    lng: -86.7987,
    id: 1840006507,
  },
  {
    name: "Sarasota",
    state_id: "FL",
    lat: 27.3386,
    lng: -82.5431,
    id: 1840015988,
  },
  {
    name: "Dayton",
    state_id: "OH",
    lat: 39.7797,
    lng: -84.1998,
    id: 1840034249,
  },
  {
    name: "Rochester",
    state_id: "NY",
    lat: 43.168,
    lng: -77.6162,
    id: 1840000373,
  },
  {
    name: "Fresno",
    state_id: "CA",
    lat: 36.7831,
    lng: -119.7941,
    id: 1840020319,
  },
  {
    name: "Allentown",
    state_id: "PA",
    lat: 40.5961,
    lng: -75.4756,
    id: 1840001044,
  },
  {
    name: "Tulsa",
    state_id: "OK",
    lat: 36.1284,
    lng: -95.9042,
    id: 1840021672,
  },
  {
    name: "Cape Coral",
    state_id: "FL",
    lat: 26.6446,
    lng: -81.9956,
    id: 1840015130,
  },
  {
    name: "Concord",
    state_id: "CA",
    lat: 37.9722,
    lng: -122.0016,
    id: 1840018905,
  },
  {
    name: "Colorado Springs",
    state_id: "CO",
    lat: 38.8674,
    lng: -104.7606,
    id: 1840018825,
  },
  {
    name: "Charleston",
    state_id: "SC",
    lat: 32.8153,
    lng: -79.9628,
    id: 1840015163,
  },
  {
    name: "Springfield",
    state_id: "MA",
    lat: 42.1155,
    lng: -72.5395,
    id: 1840000466,
  },
  {
    name: "Grand Rapids",
    state_id: "MI",
    lat: 42.962,
    lng: -85.6562,
    id: 1840002928,
  },
  {
    name: "Mission Viejo",
    state_id: "CA",
    lat: 33.6095,
    lng: -117.655,
    id: 1840020580,
  },
  {
    name: "Albany",
    state_id: "NY",
    lat: 42.6664,
    lng: -73.7987,
    id: 1840000417,
  },
  {
    name: "Knoxville",
    state_id: "TN",
    lat: 35.9692,
    lng: -83.9496,
    id: 1840014486,
  },
  {
    name: "Bakersfield",
    state_id: "CA",
    lat: 35.3529,
    lng: -119.0359,
    id: 1840019148,
  },
  {
    name: "Ogden",
    state_id: "UT",
    lat: 41.2278,
    lng: -111.9682,
    id: 1840020135,
  },
  {
    name: "Baton Rouge",
    state_id: "LA",
    lat: 30.4418,
    lng: -91.131,
    id: 1840013941,
  },
  {
    name: "Akron",
    state_id: "OH",
    lat: 41.0798,
    lng: -81.5219,
    id: 1840000791,
  },
  {
    name: "New Haven",
    state_id: "CT",
    lat: 41.3112,
    lng: -72.9246,
    id: 1840004850,
  },
  {
    name: "Columbia",
    state_id: "SC",
    lat: 34.0376,
    lng: -80.9037,
    id: 1840014730,
  },
  {
    name: "Mesa",
    state_id: "AZ",
    lat: 33.4017,
    lng: -111.718,
    id: 1840020566,
  },
  {
    name: "Palm Bay",
    state_id: "FL",
    lat: 27.955,
    lng: -80.6627,
    id: 1840015094,
  },
  {
    name: "Provo",
    state_id: "UT",
    lat: 40.2457,
    lng: -111.6457,
    id: 1840020174,
  },
  {
    name: "Worcester",
    state_id: "MA",
    lat: 42.2705,
    lng: -71.8079,
    id: 1840000434,
  },
  {
    name: "Murrieta",
    state_id: "CA",
    lat: 33.572,
    lng: -117.1909,
    id: 1840020553,
  },
  {
    name: "Greenville",
    state_id: "SC",
    lat: 34.8353,
    lng: -82.3647,
    id: 1840013501,
  },
  {
    name: "Wichita",
    state_id: "KS",
    lat: 37.6896,
    lng: -97.3442,
    id: 1840001686,
  },
  {
    name: "Toledo",
    state_id: "OH",
    lat: 41.6639,
    lng: -83.5822,
    id: 1840000572,
  },
  {
    name: "Staten Island",
    state_id: "NY",
    lat: 40.5834,
    lng: -74.1496,
    id: 1840034032,
  },
  {
    name: "Des Moines",
    state_id: "IA",
    lat: 41.5725,
    lng: -93.6105,
    id: 1840007069,
  },
  {
    name: "Long Beach",
    state_id: "CA",
    lat: 33.7981,
    lng: -118.1675,
    id: 1840020490,
  },
  {
    name: "Port St. Lucie",
    state_id: "FL",
    lat: 27.2796,
    lng: -80.3883,
    id: 1840015119,
  },
  {
    name: "Denton",
    state_id: "TX",
    lat: 33.2176,
    lng: -97.1419,
    id: 1840019390,
  },
  {
    name: "Madison",
    state_id: "WI",
    lat: 43.0826,
    lng: -89.3931,
    id: 1840002915,
  },
  {
    name: "Reno",
    state_id: "NV",
    lat: 39.5497,
    lng: -119.8483,
    id: 1840020121,
  },
  {
    name: "Harrisburg",
    state_id: "PA",
    lat: 40.2752,
    lng: -76.8843,
    id: 1840001288,
  },
  {
    name: "Little Rock",
    state_id: "AR",
    lat: 34.7256,
    lng: -92.3576,
    id: 1840015509,
  },
  {
    name: "Oakland",
    state_id: "CA",
    lat: 37.7903,
    lng: -122.2165,
    id: 1840020296,
  },
  {
    name: "Durham",
    state_id: "NC",
    lat: 35.9794,
    lng: -78.9031,
    id: 1840013364,
  },
  {
    name: "Winston-Salem",
    state_id: "NC",
    lat: 36.1029,
    lng: -80.2611,
    id: 1840015324,
  },
  {
    name: "Bonita Springs",
    state_id: "FL",
    lat: 26.3559,
    lng: -81.7861,
    id: 1840014227,
  },
  {
    name: "Indio",
    state_id: "CA",
    lat: 33.7346,
    lng: -116.2346,
    id: 1840019304,
  },
  {
    name: "Palm Coast",
    state_id: "FL",
    lat: 29.5392,
    lng: -81.246,
    id: 1840015064,
  },
  {
    name: "Chattanooga",
    state_id: "TN",
    lat: 35.0657,
    lng: -85.2488,
    id: 1840014569,
  },
  {
    name: "Spokane",
    state_id: "WA",
    lat: 47.6671,
    lng: -117.433,
    id: 1840021093,
  },
  {
    name: "Syracuse",
    state_id: "NY",
    lat: 43.0409,
    lng: -76.1438,
    id: 1840000378,
  },
  {
    name: "Lancaster",
    state_id: "PA",
    lat: 40.0421,
    lng: -76.3012,
    id: 1840003718,
  },
  {
    name: "Arlington",
    state_id: "TX",
    lat: 32.6998,
    lng: -97.125,
    id: 1840019422,
  },
  {
    name: "Stockton",
    state_id: "CA",
    lat: 37.9765,
    lng: -121.3109,
    id: 1840021517,
  },
  {
    name: "Poughkeepsie",
    state_id: "NY",
    lat: 41.6949,
    lng: -73.921,
    id: 1840000500,
  },
  {
    name: "Augusta",
    state_id: "GA",
    lat: 33.3645,
    lng: -82.0708,
    id: 1840029462,
  },
  {
    name: "Boise",
    state_id: "ID",
    lat: 43.6007,
    lng: -116.2312,
    id: 1840027142,
  },
  {
    name: "Oxnard",
    state_id: "CA",
    lat: 34.1963,
    lng: -119.1815,
    id: 1840020474,
  },
  {
    name: "Scranton",
    state_id: "PA",
    lat: 41.4044,
    lng: -75.6649,
    id: 1840003389,
  },
  {
    name: "Modesto",
    state_id: "CA",
    lat: 37.6374,
    lng: -121.0028,
    id: 1840020287,
  },
  {
    name: "Kissimmee",
    state_id: "FL",
    lat: 28.3042,
    lng: -81.4164,
    id: 1840015109,
  },
  {
    name: "Aurora",
    state_id: "CO",
    lat: 39.7087,
    lng: -104.7273,
    id: 1840018794,
  },
  {
    name: "Youngstown",
    state_id: "OH",
    lat: 41.0993,
    lng: -80.6463,
    id: 1840003563,
  },
  {
    name: "Fayetteville",
    state_id: "AR",
    lat: 36.0713,
    lng: -94.166,
    id: 1840013368,
  },
  {
    name: "Anaheim",
    state_id: "CA",
    lat: 33.839,
    lng: -117.8572,
    id: 1840019322,
  },
  {
    name: "Pensacola",
    state_id: "FL",
    lat: 30.4427,
    lng: -87.1886,
    id: 1840015005,
  },
  {
    name: "Victorville",
    state_id: "CA",
    lat: 34.5277,
    lng: -117.3536,
    id: 1840021731,
  },
  {
    name: "Lancaster",
    state_id: "CA",
    lat: 34.6935,
    lng: -118.1753,
    id: 1840020516,
  },
  {
    name: "Greensboro",
    state_id: "NC",
    lat: 36.0956,
    lng: -79.8269,
    id: 1840013356,
  },
  {
    name: "Corpus Christi",
    state_id: "TX",
    lat: 27.726,
    lng: -97.3755,
    id: 1840019718,
  },
  {
    name: "Fort Wayne",
    state_id: "IN",
    lat: 41.0886,
    lng: -85.1437,
    id: 1840008261,
  },
  {
    name: "Santa Ana",
    state_id: "CA",
    lat: 33.7366,
    lng: -117.8819,
    id: 1840021964,
  },
  {
    name: "Flint",
    state_id: "MI",
    lat: 43.0235,
    lng: -83.6922,
    id: 1840002949,
  },
  {
    name: "Fayetteville",
    state_id: "NC",
    lat: 35.0846,
    lng: -78.9776,
    id: 1840013490,
  },
  {
    name: "Jackson",
    state_id: "MS",
    lat: 32.3163,
    lng: -90.2124,
    id: 1840014895,
  },
  {
    name: "Santa Rosa",
    state_id: "CA",
    lat: 38.4458,
    lng: -122.7067,
    id: 1840021488,
  },
  {
    name: "Lansing",
    state_id: "MI",
    lat: 42.7142,
    lng: -84.5601,
    id: 1840003102,
  },
  {
    name: "San Juan",
    state_id: "PR",
    lat: 18.4037,
    lng: -66.0636,
    id: 1630035577,
  },
  {
    name: "Lexington",
    state_id: "KY",
    lat: 38.0423,
    lng: -84.4587,
    id: 1840015211,
  },
  {
    name: "Ann Arbor",
    state_id: "MI",
    lat: 42.2755,
    lng: -83.7312,
    id: 1840003172,
  },
  {
    name: "Henderson",
    state_id: "NV",
    lat: 36.0133,
    lng: -115.038,
    id: 1840020361,
  },
  {
    name: "Huntsville",
    state_id: "AL",
    lat: 34.6988,
    lng: -86.6412,
    id: 1840005061,
  },
  {
    name: "Mobile",
    state_id: "AL",
    lat: 30.6783,
    lng: -88.1162,
    id: 1840006009,
  },
  {
    name: "Fort Collins",
    state_id: "CO",
    lat: 40.5478,
    lng: -105.0656,
    id: 1840020151,
  },
  {
    name: "Asheville",
    state_id: "NC",
    lat: 35.5704,
    lng: -82.5536,
    id: 1840013411,
  },
  {
    name: "Santa Clarita",
    state_id: "CA",
    lat: 34.4175,
    lng: -118.4964,
    id: 1840021864,
  },
  {
    name: "St. Paul",
    state_id: "MN",
    lat: 44.9477,
    lng: -93.104,
    id: 1840008940,
  },
  {
    name: "Antioch",
    state_id: "CA",
    lat: 37.9789,
    lng: -121.7958,
    id: 1840018903,
  },
  {
    name: "Lakeland",
    state_id: "FL",
    lat: 28.0556,
    lng: -81.9545,
    id: 1840015108,
  },
  {
    name: "Trenton",
    state_id: "NJ",
    lat: 40.2236,
    lng: -74.7641,
    id: 1840001383,
  },
  {
    name: "Lincoln",
    state_id: "NE",
    lat: 40.809,
    lng: -96.6788,
    id: 1840009357,
  },
  {
    name: "Springfield",
    state_id: "MO",
    lat: 37.1943,
    lng: -93.2916,
    id: 1840009904,
  },
  {
    name: "Anchorage",
    state_id: "AK",
    lat: 61.1508,
    lng: -149.1091,
    id: 1840023385,
  },
  {
    name: "Plano",
    state_id: "TX",
    lat: 33.0502,
    lng: -96.7487,
    id: 1840020662,
  },
  {
    name: "Irvine",
    state_id: "CA",
    lat: 33.6772,
    lng: -117.7738,
    id: 1840019325,
  },
  {
    name: "Davenport",
    state_id: "IA",
    lat: 41.5563,
    lng: -90.6052,
    id: 1840007081,
  },
  {
    name: "Rockford",
    state_id: "IL",
    lat: 42.2597,
    lng: -89.0641,
    id: 1840009132,
  },
  {
    name: "Newark",
    state_id: "NJ",
    lat: 40.7245,
    lng: -74.1725,
    id: 1840002791,
  },
  {
    name: "South Bend",
    state_id: "IN",
    lat: 41.6767,
    lng: -86.2696,
    id: 1840009241,
  },
  {
    name: "Shreveport",
    state_id: "LA",
    lat: 32.4656,
    lng: -93.7956,
    id: 1840015768,
  },
  {
    name: "Round Lake Beach",
    state_id: "IL",
    lat: 42.379,
    lng: -88.0811,
    id: 1840011171,
  },
  {
    name: "Savannah",
    state_id: "GA",
    lat: 32.0281,
    lng: -81.1784,
    id: 1840015830,
  },
  {
    name: "Myrtle Beach",
    state_id: "SC",
    lat: 33.7096,
    lng: -78.8843,
    id: 1840014717,
  },
  {
    name: "Chula Vista",
    state_id: "CA",
    lat: 32.6281,
    lng: -117.0145,
    id: 1840019350,
  },
  {
    name: "Eugene",
    state_id: "OR",
    lat: 44.0563,
    lng: -123.1173,
    id: 1840020007,
  },
  {
    name: "Canton",
    state_id: "OH",
    lat: 40.8075,
    lng: -81.3677,
    id: 1840000963,
  },
  {
    name: "Lubbock",
    state_id: "TX",
    lat: 33.5659,
    lng: -101.8878,
    id: 1840020604,
  },
  {
    name: "Reading",
    state_id: "PA",
    lat: 40.34,
    lng: -75.9267,
    id: 1840001185,
  },
  {
    name: "Winter Haven",
    state_id: "FL",
    lat: 28.0118,
    lng: -81.7017,
    id: 1840015970,
  },
  {
    name: "Salem",
    state_id: "OR",
    lat: 44.9231,
    lng: -123.0246,
    id: 1840021224,
  },
  {
    name: "St. Petersburg",
    state_id: "FL",
    lat: 27.7931,
    lng: -82.6652,
    id: 1840015977,
  },
  {
    name: "Lafayette",
    state_id: "LA",
    lat: 30.2084,
    lng: -92.0323,
    id: 1840015044,
  },
  {
    name: "Laredo",
    state_id: "TX",
    lat: 27.5629,
    lng: -99.4875,
    id: 1840021002,
  },
  {
    name: "Jersey City",
    state_id: "NJ",
    lat: 40.7161,
    lng: -74.0682,
    id: 1840003600,
  },
  {
    name: "Concord",
    state_id: "NC",
    lat: 35.3933,
    lng: -80.6366,
    id: 1840014559,
  },
  {
    name: "Columbus",
    state_id: "GA",
    lat: 32.51,
    lng: -84.8771,
    id: 1840014887,
  },
  {
    name: "Chandler",
    state_id: "AZ",
    lat: 33.2826,
    lng: -111.8516,
    id: 1840019309,
  },
  {
    name: "McKinney",
    state_id: "TX",
    lat: 33.2015,
    lng: -96.6669,
    id: 1840020657,
  },
  {
    name: "Scottsdale",
    state_id: "AZ",
    lat: 33.6872,
    lng: -111.8651,
    id: 1840021940,
  },
  {
    name: "Killeen",
    state_id: "TX",
    lat: 31.0754,
    lng: -97.7296,
    id: 1840020854,
  },
  {
    name: "Tallahassee",
    state_id: "FL",
    lat: 30.4551,
    lng: -84.2526,
    id: 1840015913,
  },
  {
    name: "Peoria",
    state_id: "IL",
    lat: 40.752,
    lng: -89.6153,
    id: 1840009373,
  },
  {
    name: "Wilmington",
    state_id: "NC",
    lat: 34.21,
    lng: -77.8866,
    id: 1840015576,
  },
  {
    name: "Montgomery",
    state_id: "AL",
    lat: 32.3473,
    lng: -86.2666,
    id: 1840008353,
  },
  {
    name: "Gilbert",
    state_id: "AZ",
    lat: 33.3101,
    lng: -111.7463,
    id: 1840021947,
  },
  {
    name: "Glendale",
    state_id: "AZ",
    lat: 33.5791,
    lng: -112.2311,
    id: 1840020563,
  },
  {
    name: "North Las Vegas",
    state_id: "NV",
    lat: 36.288,
    lng: -115.0901,
    id: 1840020363,
  },
  {
    name: "Chesapeake",
    state_id: "VA",
    lat: 36.6778,
    lng: -76.3024,
    id: 1840003874,
  },
  {
    name: "Barnstable",
    state_id: "MA",
    lat: 41.6722,
    lng: -70.3599,
    id: 1840031233,
  },
  {
    name: "Norfolk",
    state_id: "VA",
    lat: 36.8945,
    lng: -76.259,
    id: 1840003869,
  },
  {
    name: "Fremont",
    state_id: "CA",
    lat: 37.5265,
    lng: -121.9852,
    id: 1840020292,
  },
  {
    name: "Kennewick",
    state_id: "WA",
    lat: 46.1978,
    lng: -119.1732,
    id: 1840018481,
  },
  {
    name: "Garland",
    state_id: "TX",
    lat: 32.91,
    lng: -96.6305,
    id: 1840020707,
  },
  {
    name: "Irving",
    state_id: "TX",
    lat: 32.8584,
    lng: -96.9702,
    id: 1840019438,
  },
  {
    name: "Visalia",
    state_id: "CA",
    lat: 36.3276,
    lng: -119.3269,
    id: 1840021639,
  },
  {
    name: "Atlantic City",
    state_id: "NJ",
    lat: 39.3797,
    lng: -74.4527,
    id: 1840003798,
  },
  {
    name: "Paradise",
    state_id: "NV",
    lat: 36.0807,
    lng: -115.1369,
    id: 1840033743,
  },
  {
    name: "Nashua",
    state_id: "NH",
    lat: 42.7491,
    lng: -71.491,
    id: 1840002984,
  },
  {
    name: "Arlington",
    state_id: "VA",
    lat: 38.8786,
    lng: -77.1011,
    id: 1840006112,
  },
  {
    name: "Hialeah",
    state_id: "FL",
    lat: 25.8696,
    lng: -80.3046,
    id: 1840015157,
  },
  {
    name: "York",
    state_id: "PA",
    lat: 39.9651,
    lng: -76.7315,
    id: 1840001472,
  },
  {
    name: "Evansville",
    state_id: "IN",
    lat: 37.9881,
    lng: -87.5341,
    id: 1840013730,
  },
  {
    name: "Avondale",
    state_id: "AZ",
    lat: 33.3858,
    lng: -112.3236,
    id: 1840019310,
  },
  {
    name: "Brownsville",
    state_id: "TX",
    lat: 25.998,
    lng: -97.4565,
    id: 1840019743,
  },
  {
    name: "Gulfport",
    state_id: "MS",
    lat: 30.4271,
    lng: -89.0703,
    id: 1840013947,
  },
  {
    name: "Appleton",
    state_id: "WI",
    lat: 44.2779,
    lng: -88.3891,
    id: 1840002400,
  },
  {
    name: "Bremerton",
    state_id: "WA",
    lat: 47.5436,
    lng: -122.7122,
    id: 1840018410,
  },
  {
    name: "Hickory",
    state_id: "NC",
    lat: 35.7425,
    lng: -81.323,
    id: 1840014524,
  },
  {
    name: "Tacoma",
    state_id: "WA",
    lat: 47.2431,
    lng: -122.4531,
    id: 1840021129,
  },
  {
    name: "San Bernardino",
    state_id: "CA",
    lat: 34.1417,
    lng: -117.2946,
    id: 1840021728,
  },
  {
    name: "College Station",
    state_id: "TX",
    lat: 30.5852,
    lng: -96.296,
    id: 1840019570,
  },
  {
    name: "Kalamazoo",
    state_id: "MI",
    lat: 42.2749,
    lng: -85.5882,
    id: 1840003185,
  },
  {
    name: "Thousand Oaks",
    state_id: "CA",
    lat: 34.1914,
    lng: -118.8755,
    id: 1840021844,
  },
  {
    name: "Roanoke",
    state_id: "VA",
    lat: 37.2785,
    lng: -79.958,
    id: 1840003858,
  },
  {
    name: "Fontana",
    state_id: "CA",
    lat: 34.0968,
    lng: -117.4599,
    id: 1840020402,
  },
  {
    name: "Moreno Valley",
    state_id: "CA",
    lat: 33.9244,
    lng: -117.2045,
    id: 1840020552,
  },
  {
    name: "North Port",
    state_id: "FL",
    lat: 27.0577,
    lng: -82.1975,
    id: 1840015120,
  },
  {
    name: "Fargo",
    state_id: "ND",
    lat: 46.8652,
    lng: -96.8292,
    id: 1840000177,
  },
  {
    name: "Green Bay",
    state_id: "WI",
    lat: 44.515,
    lng: -87.9896,
    id: 1840002344,
  },
  {
    name: "Spring Valley",
    state_id: "NV",
    lat: 36.0987,
    lng: -115.2619,
    id: 1840033832,
  },
  {
    name: "Amarillo",
    state_id: "TX",
    lat: 35.1988,
    lng: -101.8311,
    id: 1840019156,
  },
  {
    name: "Portland",
    state_id: "ME",
    lat: 43.6773,
    lng: -70.2715,
    id: 1840000327,
  },
  {
    name: "Santa Barbara",
    state_id: "CA",
    lat: 34.4285,
    lng: -119.7202,
    id: 1840021829,
  },
  {
    name: "Gainesville",
    state_id: "FL",
    lat: 29.6804,
    lng: -82.3458,
    id: 1840014022,
  },
  {
    name: "Olympia",
    state_id: "WA",
    lat: 47.0417,
    lng: -122.8959,
    id: 1840019865,
  },
  {
    name: "Frisco",
    state_id: "TX",
    lat: 33.1555,
    lng: -96.8215,
    id: 1840020654,
  },
  {
    name: "Yonkers",
    state_id: "NY",
    lat: 40.9466,
    lng: -73.8674,
    id: 1840003478,
  },
  {
    name: "Norwich",
    state_id: "CT",
    lat: 41.5495,
    lng: -72.0882,
    id: 1840004828,
  },
  {
    name: "Glendale",
    state_id: "CA",
    lat: 34.1818,
    lng: -118.2468,
    id: 1840020483,
  },
  {
    name: "Huntington Beach",
    state_id: "CA",
    lat: 33.696,
    lng: -118.0025,
    id: 1840020578,
  },
  {
    name: "Deltona",
    state_id: "FL",
    lat: 28.905,
    lng: -81.2137,
    id: 1840015072,
  },
  {
    name: "Aurora",
    state_id: "IL",
    lat: 41.7637,
    lng: -88.2901,
    id: 1840007034,
  },
  {
    name: "Tempe",
    state_id: "AZ",
    lat: 33.3881,
    lng: -111.9318,
    id: 1840021942,
  },
  {
    name: "Overland Park",
    state_id: "KS",
    lat: 38.887,
    lng: -94.687,
    id: 1840003834,
  },
  {
    name: "Grand Prairie",
    state_id: "TX",
    lat: 32.6872,
    lng: -97.0209,
    id: 1840020709,
  },
  {
    name: "Sunrise Manor",
    state_id: "NV",
    lat: 36.1785,
    lng: -115.049,
    id: 1840033833,
  },
  {
    name: "Waco",
    state_id: "TX",
    lat: 31.5598,
    lng: -97.1881,
    id: 1840022140,
  },
  {
    name: "Salinas",
    state_id: "CA",
    lat: 36.6884,
    lng: -121.6317,
    id: 1840021632,
  },
  {
    name: "Waterbury",
    state_id: "CT",
    lat: 41.5583,
    lng: -73.0361,
    id: 1840004851,
  },
  {
    name: "Clarksville",
    state_id: "TN",
    lat: 36.5695,
    lng: -87.342,
    id: 1840014421,
  },
  {
    name: "Cedar Rapids",
    state_id: "IA",
    lat: 41.9665,
    lng: -91.6782,
    id: 1840000471,
  },
  {
    name: "Sioux Falls",
    state_id: "SD",
    lat: 43.5397,
    lng: -96.732,
    id: 1840002648,
  },
  {
    name: "Huntington",
    state_id: "WV",
    lat: 38.4109,
    lng: -82.4344,
    id: 1840006211,
  },
  {
    name: "Ontario",
    state_id: "CA",
    lat: 34.0393,
    lng: -117.6064,
    id: 1840020410,
  },
  {
    name: "Hagerstown",
    state_id: "MD",
    lat: 39.6401,
    lng: -77.7217,
    id: 1840005613,
  },
  {
    name: "Erie",
    state_id: "PA",
    lat: 42.1168,
    lng: -80.0733,
    id: 1840000478,
  },
  {
    name: "Vancouver",
    state_id: "WA",
    lat: 45.6366,
    lng: -122.5967,
    id: 1840021189,
  },
  {
    name: "Nampa",
    state_id: "ID",
    lat: 43.5845,
    lng: -116.5631,
    id: 1840020041,
  },
  {
    name: "Spartanburg",
    state_id: "SC",
    lat: 34.9437,
    lng: -81.9257,
    id: 1840015482,
  },
  {
    name: "Gastonia",
    state_id: "NC",
    lat: 35.2494,
    lng: -81.1853,
    id: 1840013468,
  },
  {
    name: "Fort Lauderdale",
    state_id: "FL",
    lat: 26.1412,
    lng: -80.1464,
    id: 1840014236,
  },
  {
    name: "Lorain",
    state_id: "OH",
    lat: 41.4409,
    lng: -82.184,
    id: 1840000644,
  },
  {
    name: "Murfreesboro",
    state_id: "TN",
    lat: 35.8492,
    lng: -86.4119,
    id: 1840014495,
  },
  {
    name: "High Point",
    state_id: "NC",
    lat: 35.9907,
    lng: -79.9938,
    id: 1840014479,
  },
  {
    name: "Newport News",
    state_id: "VA",
    lat: 37.1051,
    lng: -76.5185,
    id: 1840003862,
  },
  {
    name: "Rancho Cucamonga",
    state_id: "CA",
    lat: 34.1248,
    lng: -117.5666,
    id: 1840020411,
  },
  {
    name: "Hemet",
    state_id: "CA",
    lat: 33.7341,
    lng: -116.9969,
    id: 1840020550,
  },
  {
    name: "Santa Cruz",
    state_id: "CA",
    lat: 36.9789,
    lng: -122.0346,
    id: 1840021579,
  },
  {
    name: "Danbury",
    state_id: "CT",
    lat: 41.4016,
    lng: -73.471,
    id: 1840004837,
  },
  {
    name: "Peoria",
    state_id: "AZ",
    lat: 33.7844,
    lng: -112.2989,
    id: 1840020567,
  },
  {
    name: "Oceanside",
    state_id: "CA",
    lat: 33.2247,
    lng: -117.3083,
    id: 1840020623,
  },
  {
    name: "Elk Grove",
    state_id: "CA",
    lat: 38.416,
    lng: -121.3842,
    id: 1840020245,
  },
  {
    name: "Pembroke Pines",
    state_id: "FL",
    lat: 26.0128,
    lng: -80.3382,
    id: 1840015141,
  },
  {
    name: "Vallejo",
    state_id: "CA",
    lat: 38.1133,
    lng: -122.2358,
    id: 1840021499,
  },
  {
    name: "Garden Grove",
    state_id: "CA",
    lat: 33.7787,
    lng: -117.9601,
    id: 1840020577,
  },
  {
    name: "Enterprise",
    state_id: "NV",
    lat: 36.0164,
    lng: -115.2208,
    id: 1840033827,
  },
  {
    name: "Medford",
    state_id: "OR",
    lat: 42.3372,
    lng: -122.8537,
    id: 1840020076,
  },
  {
    name: "Cary",
    state_id: "NC",
    lat: 35.782,
    lng: -78.8191,
    id: 1840016196,
  },
  {
    name: "Marysville",
    state_id: "WA",
    lat: 48.0809,
    lng: -122.1561,
    id: 1840019789,
  },
  {
    name: "Corona",
    state_id: "CA",
    lat: 33.8616,
    lng: -117.5649,
    id: 1840019305,
  },
  {
    name: "Ocala",
    state_id: "FL",
    lat: 29.178,
    lng: -82.1511,
    id: 1840015067,
  },
  {
    name: "Fredericksburg",
    state_id: "VA",
    lat: 38.2992,
    lng: -77.4872,
    id: 1840003845,
  },
  {
    name: "Gainesville",
    state_id: "GA",
    lat: 34.2903,
    lng: -83.8301,
    id: 1840013612,
  },
  {
    name: "Manchester",
    state_id: "NH",
    lat: 42.9848,
    lng: -71.4447,
    id: 1840002983,
  },
  {
    name: "Bayamón",
    state_id: "PR",
    lat: 18.3793,
    lng: -66.1635,
    id: 1630035622,
  },
  {
    name: "Champaign",
    state_id: "IL",
    lat: 40.1144,
    lng: -88.2735,
    id: 1840007239,
  },
  {
    name: "Alexandria",
    state_id: "VA",
    lat: 38.8185,
    lng: -77.0861,
    id: 1840003837,
  },
  {
    name: "Hayward",
    state_id: "CA",
    lat: 37.6328,
    lng: -122.0772,
    id: 1840020293,
  },
  {
    name: "Springfield",
    state_id: "IL",
    lat: 39.771,
    lng: -89.6538,
    id: 1840009517,
  },
  {
    name: "Lakewood",
    state_id: "CO",
    lat: 39.6977,
    lng: -105.1168,
    id: 1840020198,
  },
  {
    name: "Lafayette",
    state_id: "IN",
    lat: 40.399,
    lng: -86.8594,
    id: 1840008354,
  },
  {
    name: "Frederick",
    state_id: "MD",
    lat: 39.4336,
    lng: -77.4141,
    id: 1840005710,
  },
  {
    name: "Lake Charles",
    state_id: "LA",
    lat: 30.2012,
    lng: -93.2122,
    id: 1840015039,
  },
  {
    name: "Odessa",
    state_id: "TX",
    lat: 31.8831,
    lng: -102.3406,
    id: 1840020794,
  },
  {
    name: "Tuscaloosa",
    state_id: "AL",
    lat: 33.2348,
    lng: -87.5267,
    id: 1840005563,
  },
  {
    name: "Warner Robins",
    state_id: "GA",
    lat: 32.597,
    lng: -83.6529,
    id: 1840015800,
  },
  {
    name: "Palmdale",
    state_id: "CA",
    lat: 34.5944,
    lng: -118.1057,
    id: 1840020502,
  },
  {
    name: "Hollywood",
    state_id: "FL",
    lat: 26.0294,
    lng: -80.1679,
    id: 1840015144,
  },
  {
    name: "Midland",
    state_id: "TX",
    lat: 32.0249,
    lng: -102.1137,
    id: 1840020792,
  },
  {
    name: "Leesburg",
    state_id: "FL",
    lat: 28.7657,
    lng: -81.8996,
    id: 1840015083,
  },
  {
    name: "Port Arthur",
    state_id: "TX",
    lat: 29.8554,
    lng: -93.9264,
    id: 1840020919,
  },
  {
    name: "Muskegon",
    state_id: "MI",
    lat: 43.2281,
    lng: -86.2563,
    id: 1840002870,
  },
  {
    name: "Macon",
    state_id: "GA",
    lat: 32.8065,
    lng: -83.6974,
    id: 1840043455,
  },
  {
    name: "Kansas City",
    state_id: "KS",
    lat: 39.1234,
    lng: -94.7443,
    id: 1840001626,
  },
  {
    name: "Sunnyvale",
    state_id: "CA",
    lat: 37.3836,
    lng: -122.0256,
    id: 1840021573,
  },
  {
    name: "Pomona",
    state_id: "CA",
    lat: 34.0585,
    lng: -117.7626,
    id: 1840020507,
  },
  {
    name: "Escondido",
    state_id: "CA",
    lat: 33.1347,
    lng: -117.0722,
    id: 1840020620,
  },
  {
    name: "Pasadena",
    state_id: "TX",
    lat: 29.6583,
    lng: -95.1499,
    id: 1840020930,
  },
  {
    name: "New Bedford",
    state_id: "MA",
    lat: 41.6697,
    lng: -70.9428,
    id: 1840003219,
  },
  {
    name: "Fairfield",
    state_id: "CA",
    lat: 38.2592,
    lng: -122.0324,
    id: 1840020253,
  },
  {
    name: "Naperville",
    state_id: "IL",
    lat: 41.7483,
    lng: -88.1657,
    id: 1840008143,
  },
  {
    name: "Bellevue",
    state_id: "WA",
    lat: 47.5951,
    lng: -122.1535,
    id: 1840018417,
  },
  {
    name: "Binghamton",
    state_id: "NY",
    lat: 42.1014,
    lng: -75.9093,
    id: 1840000460,
  },
  {
    name: "Elkhart",
    state_id: "IN",
    lat: 41.6916,
    lng: -85.9628,
    id: 1840008187,
  },
  {
    name: "Topeka",
    state_id: "KS",
    lat: 39.0346,
    lng: -95.6955,
    id: 1840001621,
  },
  {
    name: "Joliet",
    state_id: "IL",
    lat: 41.5189,
    lng: -88.1499,
    id: 1840008192,
  },
  {
    name: "Beaumont",
    state_id: "TX",
    lat: 30.0849,
    lng: -94.1451,
    id: 1840019612,
  },
  {
    name: "Paterson",
    state_id: "NJ",
    lat: 40.9147,
    lng: -74.1624,
    id: 1840000838,
  },
  {
    name: "Merced",
    state_id: "CA",
    lat: 37.3057,
    lng: -120.4778,
    id: 1840020313,
  },
  {
    name: "Pueblo",
    state_id: "CO",
    lat: 38.2705,
    lng: -104.6112,
    id: 1840020255,
  },
  {
    name: "Tyler",
    state_id: "TX",
    lat: 32.3184,
    lng: -95.3062,
    id: 1840022076,
  },
  {
    name: "Torrance",
    state_id: "CA",
    lat: 33.8346,
    lng: -118.3417,
    id: 1840021873,
  },
  {
    name: "Yuma",
    state_id: "AZ",
    lat: 32.5992,
    lng: -114.5488,
    id: 1840021998,
  },
  {
    name: "Metairie",
    state_id: "LA",
    lat: 29.9977,
    lng: -90.178,
    id: 1840013994,
  },
  {
    name: "Surprise",
    state_id: "AZ",
    lat: 33.68,
    lng: -112.4524,
    id: 1840021941,
  },
  {
    name: "Columbia",
    state_id: "MO",
    lat: 38.9478,
    lng: -92.3256,
    id: 1840007391,
  },
  {
    name: "Athens",
    state_id: "GA",
    lat: 33.9508,
    lng: -83.3689,
    id: 1840029463,
  },
  {
    name: "Roseville",
    state_id: "CA",
    lat: 38.7703,
    lng: -121.3196,
    id: 1840020233,
  },
  {
    name: "Thornton",
    state_id: "CO",
    lat: 39.9197,
    lng: -104.9438,
    id: 1840021422,
  },
  {
    name: "Miramar",
    state_id: "FL",
    lat: 25.9773,
    lng: -80.3351,
    id: 1840015137,
  },
  {
    name: "Pasadena",
    state_id: "CA",
    lat: 34.1597,
    lng: -118.139,
    id: 1840020505,
  },
  {
    name: "Mesquite",
    state_id: "TX",
    lat: 32.7623,
    lng: -96.5889,
    id: 1840020713,
  },
  {
    name: "Olathe",
    state_id: "KS",
    lat: 38.8832,
    lng: -94.8198,
    id: 1840003833,
  },
  {
    name: "Santa Maria",
    state_id: "CA",
    lat: 34.9334,
    lng: -120.4432,
    id: 1840021830,
  },
  {
    name: "Houma",
    state_id: "LA",
    lat: 29.5799,
    lng: -90.7058,
    id: 1840015063,
  },
  {
    name: "Carrollton",
    state_id: "TX",
    lat: 32.989,
    lng: -96.8999,
    id: 1840019436,
  },
  {
    name: "Grand Junction",
    state_id: "CO",
    lat: 39.0876,
    lng: -108.5673,
    id: 1840020230,
  },
  {
    name: "Charleston",
    state_id: "WV",
    lat: 38.3484,
    lng: -81.6323,
    id: 1840006196,
  },
  {
    name: "Orange",
    state_id: "CA",
    lat: 33.8038,
    lng: -117.8218,
    id: 1840020582,
  },
  {
    name: "Fullerton",
    state_id: "CA",
    lat: 33.8841,
    lng: -117.9279,
    id: 1840020576,
  },
  {
    name: "Greeley",
    state_id: "CO",
    lat: 40.4151,
    lng: -104.7706,
    id: 1840020149,
  },
  {
    name: "Carolina",
    state_id: "PR",
    lat: 18.4054,
    lng: -65.9792,
    id: 1630035637,
  },
  {
    name: "Las Cruces",
    state_id: "NM",
    lat: 32.3265,
    lng: -106.7892,
    id: 1840020683,
  },
  {
    name: "Panama City",
    state_id: "FL",
    lat: 30.1995,
    lng: -85.6004,
    id: 1840015034,
  },
  {
    name: "Harlingen",
    state_id: "TX",
    lat: 26.1917,
    lng: -97.6976,
    id: 1840021036,
  },
  {
    name: "West Valley City",
    state_id: "UT",
    lat: 40.6889,
    lng: -112.0115,
    id: 1840021389,
  },
  {
    name: "Hampton",
    state_id: "VA",
    lat: 37.0551,
    lng: -76.363,
    id: 1840003866,
  },
  {
    name: "Warren",
    state_id: "MI",
    lat: 42.4934,
    lng: -83.027,
    id: 1840002418,
  },
  {
    name: "Mauldin",
    state_id: "SC",
    lat: 34.7849,
    lng: -82.3005,
    id: 1840015476,
  },
  {
    name: "Bloomington",
    state_id: "IL",
    lat: 40.4757,
    lng: -88.9703,
    id: 1840007207,
  },
  {
    name: "Coral Springs",
    state_id: "FL",
    lat: 26.2702,
    lng: -80.2591,
    id: 1840015134,
  },
  {
    name: "Round Rock",
    state_id: "TX",
    lat: 30.5254,
    lng: -97.666,
    id: 1840020879,
  },
  {
    name: "Yakima",
    state_id: "WA",
    lat: 46.5923,
    lng: -120.5496,
    id: 1840021154,
  },
  {
    name: "Sterling Heights",
    state_id: "MI",
    lat: 42.5809,
    lng: -83.0305,
    id: 1840003094,
  },
  {
    name: "Kent",
    state_id: "WA",
    lat: 47.3887,
    lng: -122.2129,
    id: 1840018424,
  },
  {
    name: "Burlington",
    state_id: "NC",
    lat: 36.0758,
    lng: -79.4686,
    id: 1840013362,
  },
  {
    name: "Bellingham",
    state_id: "WA",
    lat: 48.7543,
    lng: -122.4687,
    id: 1840018346,
  },
  {
    name: "Santa Clara",
    state_id: "CA",
    lat: 37.3645,
    lng: -121.968,
    id: 1840021571,
  },
  {
    name: "Racine",
    state_id: "WI",
    lat: 42.7274,
    lng: -87.8135,
    id: 1840002493,
  },
  {
    name: "Greenville",
    state_id: "NC",
    lat: 35.5956,
    lng: -77.3768,
    id: 1840013407,
  },
  {
    name: "Stamford",
    state_id: "CT",
    lat: 41.1035,
    lng: -73.5583,
    id: 1840004841,
  },
  {
    name: "Elizabeth",
    state_id: "NJ",
    lat: 40.6657,
    lng: -74.1912,
    id: 1840003613,
  },
  {
    name: "Johnson City",
    state_id: "TN",
    lat: 36.3406,
    lng: -82.3803,
    id: 1840014462,
  },
  {
    name: "Lynchburg",
    state_id: "VA",
    lat: 37.4003,
    lng: -79.1909,
    id: 1840003855,
  },
  {
    name: "Simi Valley",
    state_id: "CA",
    lat: 34.2663,
    lng: -118.749,
    id: 1840021843,
  },
  {
    name: "Fort Smith",
    state_id: "AR",
    lat: 35.3493,
    lng: -94.3695,
    id: 1840013456,
  },
  {
    name: "Kenosha",
    state_id: "WI",
    lat: 42.5864,
    lng: -87.8765,
    id: 1840003160,
  },
  {
    name: "Norman",
    state_id: "OK",
    lat: 35.2335,
    lng: -97.3471,
    id: 1840020451,
  },
  {
    name: "South Lyon",
    state_id: "MI",
    lat: 42.4614,
    lng: -83.6526,
    id: 1840002432,
  },
  {
    name: "Boulder",
    state_id: "CO",
    lat: 40.0249,
    lng: -105.2523,
    id: 1840018774,
  },
  {
    name: "Abilene",
    state_id: "TX",
    lat: 32.4543,
    lng: -99.7384,
    id: 1840019476,
  },
  {
    name: "Lehigh Acres",
    state_id: "FL",
    lat: 26.612,
    lng: -81.6388,
    id: 1840014220,
  },
  {
    name: "Pearland",
    state_id: "TX",
    lat: 29.5585,
    lng: -95.3215,
    id: 1840020979,
  },
  {
    name: "Berkeley",
    state_id: "CA",
    lat: 37.8723,
    lng: -122.276,
    id: 1840018914,
  },
  {
    name: "Richardson",
    state_id: "TX",
    lat: 32.9717,
    lng: -96.7093,
    id: 1840020710,
  },
  {
    name: "Redding",
    state_id: "CA",
    lat: 40.5698,
    lng: -122.365,
    id: 1840020141,
  },
  {
    name: "Arvada",
    state_id: "CO",
    lat: 39.8321,
    lng: -105.1511,
    id: 1840018788,
  },
  {
    name: "East Los Angeles",
    state_id: "CA",
    lat: 34.0326,
    lng: -118.1691,
    id: 1840019206,
  },
  {
    name: "St. George",
    state_id: "UT",
    lat: 37.077,
    lng: -113.577,
    id: 1840021559,
  },
  {
    name: "Billings",
    state_id: "MT",
    lat: 45.7891,
    lng: -108.5524,
    id: 1840018496,
  },
  {
    name: "Yuba City",
    state_id: "CA",
    lat: 39.1357,
    lng: -121.6381,
    id: 1840021463,
  },
  {
    name: "Rochester",
    state_id: "MN",
    lat: 44.0151,
    lng: -92.4778,
    id: 1840008987,
  },
  {
    name: "Leominster",
    state_id: "MA",
    lat: 42.5209,
    lng: -71.7717,
    id: 1840000437,
  },
  {
    name: "Kingsport",
    state_id: "TN",
    lat: 36.5224,
    lng: -82.5453,
    id: 1840014425,
  },
  {
    name: "Duluth",
    state_id: "MN",
    lat: 46.7756,
    lng: -92.1392,
    id: 1840007685,
  },
  {
    name: "Rock Hill",
    state_id: "SC",
    lat: 34.9416,
    lng: -81.0244,
    id: 1840014618,
  },
  {
    name: "Gilroy",
    state_id: "CA",
    lat: 37.0047,
    lng: -121.5855,
    id: 1840020327,
  },
  {
    name: "Cambridge",
    state_id: "MA",
    lat: 42.3759,
    lng: -71.1185,
    id: 1840000429,
  },
  {
    name: "Sugar Land",
    state_id: "TX",
    lat: 29.5935,
    lng: -95.6357,
    id: 1840022217,
  },
  {
    name: "Texas City",
    state_id: "TX",
    lat: 29.4128,
    lng: -94.9658,
    id: 1840022234,
  },
  {
    name: "Iowa City",
    state_id: "IA",
    lat: 41.6559,
    lng: -91.5303,
    id: 1840000532,
  },
  {
    name: "Saginaw",
    state_id: "MI",
    lat: 43.4199,
    lng: -83.9501,
    id: 1840002813,
  },
  {
    name: "Chico",
    state_id: "CA",
    lat: 39.7575,
    lng: -121.8152,
    id: 1840018778,
  },
  {
    name: "Dover",
    state_id: "DE",
    lat: 39.161,
    lng: -75.5203,
    id: 1840005810,
  },
  {
    name: "Clearwater",
    state_id: "FL",
    lat: 27.9789,
    lng: -82.7622,
    id: 1840015110,
  },
  {
    name: "Coeur d'Alene",
    state_id: "ID",
    lat: 47.7041,
    lng: -116.7935,
    id: 1840018402,
  },
  {
    name: "Seaside",
    state_id: "CA",
    lat: 36.6224,
    lng: -121.8191,
    id: 1840021634,
  },
  {
    name: "Independence",
    state_id: "MO",
    lat: 39.0871,
    lng: -94.3501,
    id: 1840008534,
  },
  {
    name: "West Jordan",
    state_id: "UT",
    lat: 40.6024,
    lng: -112.0008,
    id: 1840021388,
  },
  {
    name: "Brandon",
    state_id: "FL",
    lat: 27.9367,
    lng: -82.3,
    id: 1840014151,
  },
  {
    name: "Bloomington",
    state_id: "IN",
    lat: 39.1637,
    lng: -86.5257,
    id: 1840007376,
  },
  {
    name: "El Monte",
    state_id: "CA",
    lat: 34.0739,
    lng: -118.0291,
    id: 1840020480,
  },
  {
    name: "North Charleston",
    state_id: "SC",
    lat: 32.9066,
    lng: -80.0722,
    id: 1840015164,
  },
  {
    name: "Carlsbad",
    state_id: "CA",
    lat: 33.1247,
    lng: -117.2837,
    id: 1840019352,
  },
  {
    name: "St. Cloud",
    state_id: "MN",
    lat: 45.5339,
    lng: -94.1718,
    id: 1840008881,
  },
  {
    name: "Temecula",
    state_id: "CA",
    lat: 33.4928,
    lng: -117.1315,
    id: 1840021937,
  },
  {
    name: "Clovis",
    state_id: "CA",
    lat: 36.8278,
    lng: -119.6831,
    id: 1840018944,
  },
  {
    name: "Meridian",
    state_id: "ID",
    lat: 43.6112,
    lng: -116.3968,
    id: 1840020046,
  },
  {
    name: "The Woodlands",
    state_id: "TX",
    lat: 30.1738,
    lng: -95.5134,
    id: 1840019585,
  },
  {
    name: "Ponce",
    state_id: "PR",
    lat: 18.012,
    lng: -66.6198,
    id: 1630023669,
  },
  {
    name: "Westminster",
    state_id: "CO",
    lat: 39.8837,
    lng: -105.0624,
    id: 1840021423,
  },
  {
    name: "Costa Mesa",
    state_id: "CA",
    lat: 33.6667,
    lng: -117.9135,
    id: 1840019326,
  },
  {
    name: "Monroe",
    state_id: "LA",
    lat: 32.5183,
    lng: -92.0774,
    id: 1840014881,
  },
  {
    name: "Utica",
    state_id: "NY",
    lat: 43.0961,
    lng: -75.226,
    id: 1840002746,
  },
  {
    name: "Pompano Beach",
    state_id: "FL",
    lat: 26.2428,
    lng: -80.1312,
    id: 1840015143,
  },
  {
    name: "West Palm Beach",
    state_id: "FL",
    lat: 26.7468,
    lng: -80.1316,
    id: 1840015993,
  },
  {
    name: "Waterloo",
    state_id: "IA",
    lat: 42.492,
    lng: -92.3522,
    id: 1840000440,
  },
  {
    name: "Everett",
    state_id: "WA",
    lat: 47.9525,
    lng: -122.1669,
    id: 1840019785,
  },
  {
    name: "El Centro",
    state_id: "CA",
    lat: 32.7867,
    lng: -115.5586,
    id: 1840020630,
  },
  {
    name: "Santa Fe",
    state_id: "NM",
    lat: 35.6619,
    lng: -105.9819,
    id: 1840021707,
  },
  {
    name: "Downey",
    state_id: "CA",
    lat: 33.9379,
    lng: -118.1312,
    id: 1840019245,
  },
  {
    name: "Spring Hill",
    state_id: "FL",
    lat: 28.4797,
    lng: -82.53,
    id: 1840014105,
  },
  {
    name: "Lowell",
    state_id: "MA",
    lat: 42.6389,
    lng: -71.3217,
    id: 1840000426,
  },
  {
    name: "Centennial",
    state_id: "CO",
    lat: 39.5926,
    lng: -104.8674,
    id: 1840018792,
  },
  {
    name: "Elgin",
    state_id: "IL",
    lat: 42.0385,
    lng: -88.3229,
    id: 1840008134,
  },
  {
    name: "Richmond",
    state_id: "CA",
    lat: 37.9477,
    lng: -122.339,
    id: 1840020277,
  },
  {
    name: "Broken Arrow",
    state_id: "OK",
    lat: 36.0365,
    lng: -95.7809,
    id: 1840019059,
  },
  {
    name: "Miami Gardens",
    state_id: "FL",
    lat: 25.9433,
    lng: -80.2426,
    id: 1840015151,
  },
  {
    name: "Bend",
    state_id: "OR",
    lat: 44.0562,
    lng: -121.3087,
    id: 1840018618,
  },
  {
    name: "Burlington",
    state_id: "VT",
    lat: 44.4877,
    lng: -73.2314,
    id: 1840002310,
  },
  {
    name: "Jurupa Valley",
    state_id: "CA",
    lat: 34.001,
    lng: -117.4705,
    id: 1840043419,
  },
  {
    name: "Sandy Springs",
    state_id: "GA",
    lat: 33.9366,
    lng: -84.3703,
    id: 1840015609,
  },
  {
    name: "Gresham",
    state_id: "OR",
    lat: 45.5023,
    lng: -122.4413,
    id: 1840019939,
  },
  {
    name: "Lewisville",
    state_id: "TX",
    lat: 33.0452,
    lng: -96.9823,
    id: 1840020639,
  },
  {
    name: "Hillsboro",
    state_id: "OR",
    lat: 45.5272,
    lng: -122.9361,
    id: 1840019932,
  },
  {
    name: "San Buenaventura",
    state_id: "CA",
    lat: 34.2741,
    lng: -119.2314,
    id: 1840037414,
  },
  {
    name: "Jacksonville",
    state_id: "NC",
    lat: 34.7288,
    lng: -77.3941,
    id: 1840014653,
  },
  {
    name: "Pottstown",
    state_id: "PA",
    lat: 40.2507,
    lng: -75.6444,
    id: 1840001373,
  },
  {
    name: "Inglewood",
    state_id: "CA",
    lat: 33.9566,
    lng: -118.3444,
    id: 1840019237,
  },
  {
    name: "League City",
    state_id: "TX",
    lat: 29.4873,
    lng: -95.1087,
    id: 1840020973,
  },
  {
    name: "Eau Claire",
    state_id: "WI",
    lat: 44.8199,
    lng: -91.4949,
    id: 1840002296,
  },
  {
    name: "Turlock",
    state_id: "CA",
    lat: 37.5053,
    lng: -120.8588,
    id: 1840021533,
  },
  {
    name: "Temple",
    state_id: "TX",
    lat: 31.1076,
    lng: -97.3894,
    id: 1840022154,
  },
  {
    name: "Sioux City",
    state_id: "IA",
    lat: 42.4959,
    lng: -96.3901,
    id: 1840000447,
  },
  {
    name: "Salisbury",
    state_id: "MD",
    lat: 38.3756,
    lng: -75.5867,
    id: 1840006219,
  },
  {
    name: "Davie",
    state_id: "FL",
    lat: 26.0789,
    lng: -80.287,
    id: 1840017272,
  },
  {
    name: "Daly City",
    state_id: "CA",
    lat: 37.6862,
    lng: -122.4685,
    id: 1840018926,
  },
  {
    name: "Highlands Ranch",
    state_id: "CO",
    lat: 39.5419,
    lng: -104.9708,
    id: 1840028579,
  },
  {
    name: "Allen",
    state_id: "TX",
    lat: 33.1088,
    lng: -96.6735,
    id: 1840019396,
  },
  {
    name: "West Covina",
    state_id: "CA",
    lat: 34.0555,
    lng: -117.9112,
    id: 1840021876,
  },
  {
    name: "Sparks",
    state_id: "NV",
    lat: 39.5729,
    lng: -119.7157,
    id: 1840021337,
  },
  {
    name: "Wichita Falls",
    state_id: "TX",
    lat: 33.9072,
    lng: -98.5291,
    id: 1840021923,
  },
  {
    name: "San Mateo",
    state_id: "CA",
    lat: 37.5521,
    lng: -122.3122,
    id: 1840021551,
  },
  {
    name: "Columbia",
    state_id: "MD",
    lat: 39.2004,
    lng: -76.859,
    id: 1840005803,
  },
  {
    name: "Norwalk",
    state_id: "CA",
    lat: 33.9069,
    lng: -118.0829,
    id: 1840020501,
  },
  {
    name: "Rialto",
    state_id: "CA",
    lat: 34.1174,
    lng: -117.3894,
    id: 1840020407,
  },
  {
    name: "Manteca",
    state_id: "CA",
    lat: 37.7927,
    lng: -121.2264,
    id: 1840020265,
  },
  {
    name: "Arden-Arcade",
    state_id: "CA",
    lat: 38.6034,
    lng: -121.381,
    id: 1840036690,
  },
  {
    name: "El Cajon",
    state_id: "CA",
    lat: 32.8017,
    lng: -116.9605,
    id: 1840020618,
  },
  {
    name: "Burbank",
    state_id: "CA",
    lat: 34.1879,
    lng: -118.3235,
    id: 1840019233,
  },
  {
    name: "Longmont",
    state_id: "CO",
    lat: 40.1686,
    lng: -105.1005,
    id: 1840020178,
  },
  {
    name: "Renton",
    state_id: "WA",
    lat: 47.4784,
    lng: -122.1919,
    id: 1840019827,
  },
  {
    name: "Vista",
    state_id: "CA",
    lat: 33.1896,
    lng: -117.2386,
    id: 1840021994,
  },
  {
    name: "Logan",
    state_id: "UT",
    lat: 41.74,
    lng: -111.8419,
    id: 1840020108,
  },
  {
    name: "Prescott Valley",
    state_id: "AZ",
    lat: 34.5983,
    lng: -112.3176,
    id: 1840021772,
  },
  {
    name: "Vacaville",
    state_id: "CA",
    lat: 38.359,
    lng: -121.968,
    id: 1840021498,
  },
  {
    name: "Edinburg",
    state_id: "TX",
    lat: 26.3197,
    lng: -98.1596,
    id: 1840021020,
  },
  {
    name: "Carmel",
    state_id: "IN",
    lat: 39.965,
    lng: -86.146,
    id: 1840007260,
  },
  {
    name: "Spokane Valley",
    state_id: "WA",
    lat: 47.6626,
    lng: -117.2346,
    id: 1840021094,
  },
  {
    name: "San Angelo",
    state_id: "TX",
    lat: 31.4424,
    lng: -100.4506,
    id: 1840022146,
  },
  {
    name: "La Crosse",
    state_id: "WI",
    lat: 43.824,
    lng: -91.2268,
    id: 1840002577,
  },
  {
    name: "Idaho Falls",
    state_id: "ID",
    lat: 43.4872,
    lng: -112.0362,
    id: 1840018644,
  },
  {
    name: "Holland",
    state_id: "MI",
    lat: 42.7677,
    lng: -86.0985,
    id: 1840002996,
  },
  {
    name: "Charlottesville",
    state_id: "VA",
    lat: 38.0375,
    lng: -78.4855,
    id: 1840001678,
  },
  {
    name: "Longview",
    state_id: "TX",
    lat: 32.5193,
    lng: -94.7621,
    id: 1840020746,
  },
  {
    name: "Tracy",
    state_id: "CA",
    lat: 37.7269,
    lng: -121.4522,
    id: 1840021518,
  },
  {
    name: "Boca Raton",
    state_id: "FL",
    lat: 26.3752,
    lng: -80.108,
    id: 1840014207,
  },
  {
    name: "Lafayette",
    state_id: "CO",
    lat: 39.9949,
    lng: -105.0997,
    id: 1840020180,
  },
  {
    name: "Lee's Summit",
    state_id: "MO",
    lat: 38.9172,
    lng: -94.3816,
    id: 1840032155,
  },
  {
    name: "Rio Rancho",
    state_id: "NM",
    lat: 35.2872,
    lng: -106.6981,
    id: 1840020387,
  },
  {
    name: "South Fulton",
    state_id: "GA",
    lat: 33.6269,
    lng: -84.5802,
    id: 1840029465,
  },
  {
    name: "Beaverton",
    state_id: "OR",
    lat: 45.4779,
    lng: -122.8168,
    id: 1840018556,
  },
  {
    name: "Lawrence",
    state_id: "KS",
    lat: 38.9597,
    lng: -95.2641,
    id: 1840001632,
  },
  {
    name: "Bismarck",
    state_id: "ND",
    lat: 46.8144,
    lng: -100.7694,
    id: 1840001926,
  },
  {
    name: "Orem",
    state_id: "UT",
    lat: 40.2983,
    lng: -111.6992,
    id: 1840020171,
  },
  {
    name: "Middletown",
    state_id: "OH",
    lat: 39.5032,
    lng: -84.366,
    id: 1840003804,
  },
  {
    name: "San Tan Valley",
    state_id: "AZ",
    lat: 33.1879,
    lng: -111.5472,
    id: 1840027990,
  },
  {
    name: "San Marcos",
    state_id: "CA",
    lat: 33.135,
    lng: -117.1744,
    id: 1840021991,
  },
  {
    name: "Sandy",
    state_id: "UT",
    lat: 40.571,
    lng: -111.8505,
    id: 1840037422,
  },
  {
    name: "Federal Way",
    state_id: "WA",
    lat: 47.3091,
    lng: -122.3358,
    id: 1840019826,
  },
  {
    name: "Mandeville",
    state_id: "LA",
    lat: 30.3751,
    lng: -90.0904,
    id: 1840015909,
  },
  {
    name: "Hesperia",
    state_id: "CA",
    lat: 34.3975,
    lng: -117.3147,
    id: 1840020404,
  },
  {
    name: "Brockton",
    state_id: "MA",
    lat: 42.0821,
    lng: -71.0242,
    id: 1840000467,
  },
  {
    name: "Compton",
    state_id: "CA",
    lat: 33.893,
    lng: -118.2275,
    id: 1840019240,
  },
  {
    name: "Riverview",
    state_id: "FL",
    lat: 27.8227,
    lng: -82.3023,
    id: 1840014158,
  },
  {
    name: "Fishers",
    state_id: "IN",
    lat: 39.9589,
    lng: -85.9661,
    id: 1840010496,
  },
  {
    name: "Sunrise",
    state_id: "FL",
    lat: 26.1547,
    lng: -80.2997,
    id: 1840016000,
  },
  {
    name: "Bowling Green",
    state_id: "KY",
    lat: 36.9719,
    lng: -86.4373,
    id: 1840013265,
  },
  {
    name: "Roswell",
    state_id: "GA",
    lat: 34.0391,
    lng: -84.3513,
    id: 1840014741,
  },
  {
    name: "Menifee",
    state_id: "CA",
    lat: 33.6909,
    lng: -117.1849,
    id: 1840022903,
  },
  {
    name: "Plantation",
    state_id: "FL",
    lat: 26.126,
    lng: -80.2617,
    id: 1840015142,
  },
  {
    name: "Dover",
    state_id: "NH",
    lat: 43.1887,
    lng: -70.8845,
    id: 1840002808,
  },
  {
    name: "Quincy",
    state_id: "MA",
    lat: 42.2516,
    lng: -71.0183,
    id: 1840000461,
  },
  {
    name: "Portsmouth",
    state_id: "VA",
    lat: 36.8468,
    lng: -76.354,
    id: 1840003872,
  },
  {
    name: "Chino",
    state_id: "CA",
    lat: 33.9836,
    lng: -117.6654,
    id: 1840019114,
  },
  {
    name: "Lynn",
    state_id: "MA",
    lat: 42.4779,
    lng: -70.9663,
    id: 1840000409,
  },
  {
    name: "Edmond",
    state_id: "OK",
    lat: 35.6689,
    lng: -97.4159,
    id: 1840020423,
  },
  {
    name: "Hanford",
    state_id: "CA",
    lat: 36.3274,
    lng: -119.6549,
    id: 1840020382,
  },
  {
    name: "Dearborn",
    state_id: "MI",
    lat: 42.3127,
    lng: -83.2129,
    id: 1840003969,
  },
  {
    name: "Livonia",
    state_id: "MI",
    lat: 42.3972,
    lng: -83.3733,
    id: 1840003985,
  },
  {
    name: "South Gate",
    state_id: "CA",
    lat: 33.9448,
    lng: -118.1926,
    id: 1840021870,
  },
  {
    name: "Vineland",
    state_id: "NJ",
    lat: 39.4653,
    lng: -74.9981,
    id: 1840001551,
  },
  {
    name: "Florence",
    state_id: "SC",
    lat: 34.1781,
    lng: -79.7877,
    id: 1840013643,
  },
  {
    name: "Portsmouth",
    state_id: "NH",
    lat: 43.058,
    lng: -70.7826,
    id: 1840002932,
  },
  {
    name: "Slidell",
    state_id: "LA",
    lat: 30.2881,
    lng: -89.7826,
    id: 1840015911,
  },
  {
    name: "Lawton",
    state_id: "OK",
    lat: 34.6176,
    lng: -98.4203,
    id: 1840020477,
  },
  {
    name: "Kirkland",
    state_id: "WA",
    lat: 47.6974,
    lng: -122.2054,
    id: 1840019836,
  },
  {
    name: "Missoula",
    state_id: "MT",
    lat: 46.875,
    lng: -114.0214,
    id: 1840019840,
  },
  {
    name: "Auburn",
    state_id: "AL",
    lat: 32.6087,
    lng: -85.4899,
    id: 1840013810,
  },
  {
    name: "Rapid City",
    state_id: "SD",
    lat: 44.0716,
    lng: -103.2204,
    id: 1840002180,
  },
  {
    name: "Terre Haute",
    state_id: "IN",
    lat: 39.4654,
    lng: -87.3763,
    id: 1840009671,
  },
  {
    name: "Suffolk",
    state_id: "VA",
    lat: 36.6953,
    lng: -76.6398,
    id: 1840003873,
  },
  {
    name: "Blacksburg",
    state_id: "VA",
    lat: 37.23,
    lng: -80.4279,
    id: 1840006446,
  },
  {
    name: "Mount Pleasant",
    state_id: "SC",
    lat: 32.8538,
    lng: -79.8204,
    id: 1840017286,
  },
  {
    name: "Carson",
    state_id: "CA",
    lat: 33.8374,
    lng: -118.2559,
    id: 1840019214,
  },
  {
    name: "Conroe",
    state_id: "TX",
    lat: 30.3239,
    lng: -95.4825,
    id: 1840019586,
  },
  {
    name: "Alafaya",
    state_id: "FL",
    lat: 28.5278,
    lng: -81.1865,
    id: 1840029102,
  },
  {
    name: "Livermore",
    state_id: "CA",
    lat: 37.6862,
    lng: -121.7608,
    id: 1840020294,
  },
  {
    name: "Westminster",
    state_id: "CA",
    lat: 33.7523,
    lng: -117.9938,
    id: 1840021969,
  },
  {
    name: "Germantown",
    state_id: "MD",
    lat: 39.1755,
    lng: -77.2643,
    id: 1840005840,
  },
  {
    name: "Santa Monica",
    state_id: "CA",
    lat: 34.0232,
    lng: -118.4813,
    id: 1840021866,
  },
  {
    name: "New Braunfels",
    state_id: "TX",
    lat: 29.6994,
    lng: -98.1148,
    id: 1840021341,
  },
  {
    name: "Fall River",
    state_id: "MA",
    lat: 41.7137,
    lng: -71.1014,
    id: 1840000497,
  },
  {
    name: "Albany",
    state_id: "GA",
    lat: 31.5776,
    lng: -84.1762,
    id: 1840013864,
  },
  {
    name: "Miami Beach",
    state_id: "FL",
    lat: 25.8171,
    lng: -80.1396,
    id: 1840015150,
  },
  {
    name: "Norwalk",
    state_id: "CT",
    lat: 41.1144,
    lng: -73.4215,
    id: 1840004839,
  },
  {
    name: "San Leandro",
    state_id: "CA",
    lat: 37.7071,
    lng: -122.1601,
    id: 1840021538,
  },
  {
    name: "O'Fallon",
    state_id: "MO",
    lat: 38.785,
    lng: -90.7175,
    id: 1840008571,
  },
  {
    name: "Newton",
    state_id: "MA",
    lat: 42.3316,
    lng: -71.2085,
    id: 1840000433,
  },
  {
    name: "Muncie",
    state_id: "IN",
    lat: 40.1989,
    lng: -85.395,
    id: 1840008365,
  },
  {
    name: "Citrus Heights",
    state_id: "CA",
    lat: 38.6948,
    lng: -121.288,
    id: 1840018845,
  },
  {
    name: "State College",
    state_id: "PA",
    lat: 40.7909,
    lng: -77.8568,
    id: 1840000830,
  },
  {
    name: "Town 'n' Country",
    state_id: "FL",
    lat: 28.0108,
    lng: -82.576,
    id: 1840029089,
  },
  {
    name: "Decatur",
    state_id: "IL",
    lat: 39.8556,
    lng: -88.9337,
    id: 1840007269,
  },
  {
    name: "Jackson",
    state_id: "MI",
    lat: 42.2431,
    lng: -84.4037,
    id: 1840003173,
  },
  {
    name: "Fort Myers",
    state_id: "FL",
    lat: 26.6195,
    lng: -81.8303,
    id: 1840014226,
  },
  {
    name: "Goodyear",
    state_id: "AZ",
    lat: 33.2613,
    lng: -112.3622,
    id: 1840020564,
  },
  {
    name: "Dalton",
    state_id: "GA",
    lat: 34.769,
    lng: -84.9712,
    id: 1840014651,
  },
  {
    name: "Bryan",
    state_id: "TX",
    lat: 30.6657,
    lng: -96.3668,
    id: 1840019569,
  },
  {
    name: "Waukegan",
    state_id: "IL",
    lat: 42.3697,
    lng: -87.8716,
    id: 1840010113,
  },
  {
    name: "Hawthorne",
    state_id: "CA",
    lat: 33.9146,
    lng: -118.3476,
    id: 1840020486,
  },
  {
    name: "Anderson",
    state_id: "IN",
    lat: 40.0891,
    lng: -85.6893,
    id: 1840007242,
  },
  {
    name: "Redwood City",
    state_id: "CA",
    lat: 37.5026,
    lng: -122.2252,
    id: 1840020304,
  },
  {
    name: "Hoover",
    state_id: "AL",
    lat: 33.3754,
    lng: -86.8064,
    id: 1840014795,
  },
  {
    name: "Lake Forest",
    state_id: "CA",
    lat: 33.6606,
    lng: -117.6712,
    id: 1840020591,
  },
  {
    name: "Napa",
    state_id: "CA",
    lat: 38.2976,
    lng: -122.3011,
    id: 1840020241,
  },
  {
    name: "Whittier",
    state_id: "CA",
    lat: 33.9678,
    lng: -118.0188,
    id: 1840021878,
  },
  {
    name: "Clifton",
    state_id: "NJ",
    lat: 40.8631,
    lng: -74.1575,
    id: 1840003511,
  },
  {
    name: "Largo",
    state_id: "FL",
    lat: 27.9088,
    lng: -82.7711,
    id: 1840015114,
  },
  {
    name: "Bloomington",
    state_id: "MN",
    lat: 44.8306,
    lng: -93.3151,
    id: 1840006747,
  },
  {
    name: "Johns Creek",
    state_id: "GA",
    lat: 34.0333,
    lng: -84.2027,
    id: 1840014742,
  },
  {
    name: "Newport Beach",
    state_id: "CA",
    lat: 33.6151,
    lng: -117.8669,
    id: 1840020581,
  },
  {
    name: "Mission",
    state_id: "TX",
    lat: 26.2041,
    lng: -98.3251,
    id: 1840021026,
  },
  {
    name: "Milpitas",
    state_id: "CA",
    lat: 37.4339,
    lng: -121.8921,
    id: 1840020329,
  },
  {
    name: "Troy",
    state_id: "MI",
    lat: 42.5818,
    lng: -83.1457,
    id: 1840002434,
  },
  {
    name: "Madera",
    state_id: "CA",
    lat: 36.9631,
    lng: -120.0782,
    id: 1840020300,
  },
  {
    name: "Joplin",
    state_id: "MO",
    lat: 37.0758,
    lng: -94.5018,
    id: 1840008771,
  },
  {
    name: "Chino Hills",
    state_id: "CA",
    lat: 33.9508,
    lng: -117.7253,
    id: 1840019115,
  },
  {
    name: "Alhambra",
    state_id: "CA",
    lat: 34.084,
    lng: -118.1355,
    id: 1840019222,
  },
  {
    name: "Franklin",
    state_id: "TN",
    lat: 35.9216,
    lng: -86.8525,
    id: 1840013380,
  },
  {
    name: "Melbourne",
    state_id: "FL",
    lat: 28.1085,
    lng: -80.6627,
    id: 1840015960,
  },
  {
    name: "Port Huron",
    state_id: "MI",
    lat: 42.9822,
    lng: -82.4387,
    id: 1840003953,
  },
  {
    name: "Springfield",
    state_id: "OH",
    lat: 39.9297,
    lng: -83.7957,
    id: 1840003765,
  },
  {
    name: "Mountain View",
    state_id: "CA",
    lat: 37.4,
    lng: -122.0796,
    id: 1840020332,
  },
  {
    name: "Zephyrhills",
    state_id: "FL",
    lat: 28.2408,
    lng: -82.1796,
    id: 1840015968,
  },
  {
    name: "St. Augustine",
    state_id: "FL",
    lat: 29.8977,
    lng: -81.31,
    id: 1840015935,
  },
  {
    name: "Flagstaff",
    state_id: "AZ",
    lat: 35.1872,
    lng: -111.6195,
    id: 1840020335,
  },
  {
    name: "Buena Park",
    state_id: "CA",
    lat: 33.8572,
    lng: -118.0046,
    id: 1840019324,
  },
  {
    name: "Pleasanton",
    state_id: "CA",
    lat: 37.6663,
    lng: -121.8805,
    id: 1840020298,
  },
  {
    name: "Silver Spring",
    state_id: "MD",
    lat: 39.0028,
    lng: -77.0207,
    id: 1840005845,
  },
  {
    name: "Atascocita",
    state_id: "TX",
    lat: 29.9777,
    lng: -95.1953,
    id: 1840018251,
  },
  {
    name: "Westland",
    state_id: "MI",
    lat: 42.3192,
    lng: -83.3805,
    id: 1840001847,
  },
  {
    name: "Auburn",
    state_id: "WA",
    lat: 47.3039,
    lng: -122.2108,
    id: 1840018416,
  },
  {
    name: "Cranston",
    state_id: "RI",
    lat: 41.7658,
    lng: -71.4857,
    id: 1840003287,
  },
  {
    name: "Somerville",
    state_id: "MA",
    lat: 42.3908,
    lng: -71.1013,
    id: 1840003123,
  },
  {
    name: "Folsom",
    state_id: "CA",
    lat: 38.6668,
    lng: -121.1422,
    id: 1840020246,
  },
  {
    name: "Springdale",
    state_id: "AR",
    lat: 36.1899,
    lng: -94.1574,
    id: 1840015336,
  },
  {
    name: "Deerfield Beach",
    state_id: "FL",
    lat: 26.305,
    lng: -80.1277,
    id: 1840015136,
  },
  {
    name: "Warwick",
    state_id: "RI",
    lat: 41.7062,
    lng: -71.4334,
    id: 1840003344,
  },
  {
    name: "Cicero",
    state_id: "IL",
    lat: 41.8445,
    lng: -87.7593,
    id: 1840009186,
  },
  {
    name: "Farmington Hills",
    state_id: "MI",
    lat: 42.486,
    lng: -83.3771,
    id: 1840002442,
  },
  {
    name: "Newark",
    state_id: "OH",
    lat: 40.0705,
    lng: -82.4251,
    id: 1840001419,
  },
  {
    name: "Williamsburg",
    state_id: "VA",
    lat: 37.2692,
    lng: -76.7076,
    id: 1840001720,
  },
  {
    name: "Brooklyn Park",
    state_id: "MN",
    lat: 45.1112,
    lng: -93.3505,
    id: 1840004023,
  },
  {
    name: "Hattiesburg",
    state_id: "MS",
    lat: 31.3074,
    lng: -89.317,
    id: 1840013878,
  },
  {
    name: "Alexandria",
    state_id: "LA",
    lat: 31.2923,
    lng: -92.4702,
    id: 1840013873,
  },
  {
    name: "Lawrence",
    state_id: "MA",
    lat: 42.7003,
    lng: -71.1626,
    id: 1840000408,
  },
  {
    name: "Florence",
    state_id: "AL",
    lat: 34.8303,
    lng: -87.6654,
    id: 1840002083,
  },
  {
    name: "Valdosta",
    state_id: "GA",
    lat: 30.8502,
    lng: -83.2788,
    id: 1840015894,
  },
  {
    name: "Plymouth",
    state_id: "MN",
    lat: 45.0225,
    lng: -93.4618,
    id: 1840008929,
  },
  {
    name: "Buckeye",
    state_id: "AZ",
    lat: 33.4314,
    lng: -112.6429,
    id: 1840021939,
  },
  {
    name: "Georgetown",
    state_id: "TX",
    lat: 30.6668,
    lng: -97.6953,
    id: 1840020875,
  },
  {
    name: "Cheyenne",
    state_id: "WY",
    lat: 41.1351,
    lng: -104.79,
    id: 1840018713,
  },
  {
    name: "Cedar Park",
    state_id: "TX",
    lat: 30.5106,
    lng: -97.8196,
    id: 1840019572,
  },
  {
    name: "The Villages",
    state_id: "FL",
    lat: 28.9021,
    lng: -81.9888,
    id: 1840014065,
  },
  {
    name: "Kokomo",
    state_id: "IN",
    lat: 40.464,
    lng: -86.1277,
    id: 1840013844,
  },
  {
    name: "Tustin",
    state_id: "CA",
    lat: 33.7309,
    lng: -117.8106,
    id: 1840021967,
  },
  {
    name: "Lakewood",
    state_id: "CA",
    lat: 33.8471,
    lng: -118.1221,
    id: 1840020515,
  },
  {
    name: "Perris",
    state_id: "CA",
    lat: 33.7899,
    lng: -117.2233,
    id: 1840020557,
  },
  {
    name: "St. Joseph",
    state_id: "MO",
    lat: 39.7599,
    lng: -94.821,
    id: 1840009549,
  },
  {
    name: "Flower Mound",
    state_id: "TX",
    lat: 33.0344,
    lng: -97.1146,
    id: 1840022010,
  },
  {
    name: "Pharr",
    state_id: "TX",
    lat: 26.1685,
    lng: -98.1904,
    id: 1840021030,
  },
  {
    name: "Pine Hills",
    state_id: "FL",
    lat: 28.5818,
    lng: -81.4693,
    id: 1840014098,
  },
  {
    name: "Alton",
    state_id: "IL",
    lat: 38.9034,
    lng: -90.1523,
    id: 1840007424,
  },
  {
    name: "Loveland",
    state_id: "CO",
    lat: 40.4166,
    lng: -105.0623,
    id: 1840020152,
  },
  {
    name: "Boynton Beach",
    state_id: "FL",
    lat: 26.5281,
    lng: -80.0811,
    id: 1840014208,
  },
  {
    name: "New Rochelle",
    state_id: "NY",
    lat: 40.9305,
    lng: -73.7836,
    id: 1840000782,
  },
  {
    name: "Anderson",
    state_id: "SC",
    lat: 34.5211,
    lng: -82.6479,
    id: 1840013579,
  },
  {
    name: "Jonesboro",
    state_id: "AR",
    lat: 35.8212,
    lng: -90.6791,
    id: 1840014507,
  },
  {
    name: "Lebanon",
    state_id: "PA",
    lat: 40.3412,
    lng: -76.4227,
    id: 1840001347,
  },
  {
    name: "Parma",
    state_id: "OH",
    lat: 41.3843,
    lng: -81.7286,
    id: 1840003406,
  },
  {
    name: "Layton",
    state_id: "UT",
    lat: 41.0769,
    lng: -111.9621,
    id: 1840020145,
  },
  {
    name: "Elizabethtown",
    state_id: "KY",
    lat: 37.703,
    lng: -85.877,
    id: 1840013218,
  },
  {
    name: "Texarkana",
    state_id: "TX",
    lat: 33.4487,
    lng: -94.0815,
    id: 1840021987,
  },
  {
    name: "Alameda",
    state_id: "CA",
    lat: 37.767,
    lng: -122.2672,
    id: 1840018913,
  },
  {
    name: "Kankakee",
    state_id: "IL",
    lat: 41.1019,
    lng: -87.8643,
    id: 1840008260,
  },
  {
    name: "Watsonville",
    state_id: "CA",
    lat: 36.9206,
    lng: -121.7706,
    id: 1840021581,
  },
  {
    name: "Porterville",
    state_id: "CA",
    lat: 36.0644,
    lng: -119.0338,
    id: 1840020368,
  },
  {
    name: "Baytown",
    state_id: "TX",
    lat: 29.7586,
    lng: -94.9669,
    id: 1840019616,
  },
  {
    name: "Upland",
    state_id: "CA",
    lat: 34.1178,
    lng: -117.6603,
    id: 1840021730,
  },
  {
    name: "Davis",
    state_id: "CA",
    lat: 38.5552,
    lng: -121.7365,
    id: 1840018833,
  },
  {
    name: "Camarillo",
    state_id: "CA",
    lat: 34.223,
    lng: -119.0322,
    id: 1840019201,
  },
  {
    name: "South Jordan",
    state_id: "UT",
    lat: 40.557,
    lng: -111.9782,
    id: 1840021385,
  },
  {
    name: "Battle Creek",
    state_id: "MI",
    lat: 42.2986,
    lng: -85.2296,
    id: 1840003176,
  },
  {
    name: "Bellflower",
    state_id: "CA",
    lat: 33.888,
    lng: -118.1271,
    id: 1840019230,
  },
  {
    name: "San Marcos",
    state_id: "TX",
    lat: 29.8736,
    lng: -97.9381,
    id: 1840022188,
  },
  {
    name: "San Ramon",
    state_id: "CA",
    lat: 37.7625,
    lng: -121.9365,
    id: 1840021530,
  },
  {
    name: "Lake Jackson",
    state_id: "TX",
    lat: 29.0516,
    lng: -95.4522,
    id: 1840020980,
  },
  {
    name: "Bethlehem",
    state_id: "PA",
    lat: 40.6266,
    lng: -75.3679,
    id: 1840000986,
  },
  {
    name: "Wyoming",
    state_id: "MI",
    lat: 42.8909,
    lng: -85.7066,
    id: 1840000374,
  },
  {
    name: "Oshkosh",
    state_id: "WI",
    lat: 44.0228,
    lng: -88.5619,
    id: 1840002246,
  },
  {
    name: "Hammond",
    state_id: "IN",
    lat: 41.6169,
    lng: -87.491,
    id: 1840007092,
  },
  {
    name: "Waldorf",
    state_id: "MD",
    lat: 38.6085,
    lng: -76.9195,
    id: 1840006175,
  },
  {
    name: "Missouri City",
    state_id: "TX",
    lat: 29.563,
    lng: -95.5365,
    id: 1840020950,
  },
  {
    name: "Pasco",
    state_id: "WA",
    lat: 46.2506,
    lng: -119.1304,
    id: 1840019888,
  },
  {
    name: "Wheeling",
    state_id: "WV",
    lat: 40.0752,
    lng: -80.6951,
    id: 1840005510,
  },
  {
    name: "Kendall",
    state_id: "FL",
    lat: 25.6697,
    lng: -80.3556,
    id: 1840014244,
  },
  {
    name: "Baldwin Park",
    state_id: "CA",
    lat: 34.0829,
    lng: -117.9721,
    id: 1840019227,
  },
  {
    name: "Rancho Cordova",
    state_id: "CA",
    lat: 38.574,
    lng: -121.2523,
    id: 1840020248,
  },
  {
    name: "Conway",
    state_id: "AR",
    lat: 35.0754,
    lng: -92.4694,
    id: 1840014598,
  },
  {
    name: "Gary",
    state_id: "IN",
    lat: 41.5906,
    lng: -87.3472,
    id: 1840008194,
  },
  {
    name: "Altoona",
    state_id: "PA",
    lat: 40.5082,
    lng: -78.4007,
    id: 1840001080,
  },
  {
    name: "Lodi",
    state_id: "CA",
    lat: 38.1218,
    lng: -121.2932,
    id: 1840020263,
  },
  {
    name: "Arlington Heights",
    state_id: "IL",
    lat: 42.0955,
    lng: -87.9825,
    id: 1840011243,
  },
  {
    name: "Bolingbrook",
    state_id: "IL",
    lat: 41.6903,
    lng: -88.1019,
    id: 1840011482,
  },
  {
    name: "Rochester Hills",
    state_id: "MI",
    lat: 42.6645,
    lng: -83.1563,
    id: 1840002429,
  },
  {
    name: "Winchester",
    state_id: "VA",
    lat: 39.1735,
    lng: -78.1746,
    id: 1840001623,
  },
  {
    name: "Framingham",
    state_id: "MA",
    lat: 42.3085,
    lng: -71.4368,
    id: 1840003131,
  },
  {
    name: "Hilton Head Island",
    state_id: "SC",
    lat: 32.1896,
    lng: -80.7499,
    id: 1840016974,
  },
  {
    name: "Centreville",
    state_id: "VA",
    lat: 38.8391,
    lng: -77.4388,
    id: 1840006015,
  },
  {
    name: "Union City",
    state_id: "CA",
    lat: 37.603,
    lng: -122.0187,
    id: 1840021539,
  },
  {
    name: "Owensboro",
    state_id: "KY",
    lat: 37.7574,
    lng: -87.1173,
    id: 1840014340,
  },
  {
    name: "Jackson",
    state_id: "TN",
    lat: 35.6535,
    lng: -88.8353,
    id: 1840014528,
  },
  {
    name: "Cleveland",
    state_id: "TN",
    lat: 35.1817,
    lng: -84.8707,
    id: 1840014603,
  },
  {
    name: "Wausau",
    state_id: "WI",
    lat: 44.962,
    lng: -89.6459,
    id: 1840002163,
  },
  {
    name: "Camden",
    state_id: "NJ",
    lat: 39.9362,
    lng: -75.1073,
    id: 1840001507,
  },
  {
    name: "Anniston",
    state_id: "AL",
    lat: 33.6712,
    lng: -85.8136,
    id: 1840006563,
  },
  {
    name: "Evanston",
    state_id: "IL",
    lat: 42.0463,
    lng: -87.6942,
    id: 1840008129,
  },
  {
    name: "Apple Valley",
    state_id: "CA",
    lat: 34.5328,
    lng: -117.2104,
    id: 1840021733,
  },
  {
    name: "Mansfield",
    state_id: "OH",
    lat: 40.7656,
    lng: -82.5275,
    id: 1840000960,
  },
  {
    name: "Caguas",
    state_id: "PR",
    lat: 18.2319,
    lng: -66.0388,
    id: 1630023540,
  },
  {
    name: "Schaumburg",
    state_id: "IL",
    lat: 42.0307,
    lng: -88.0838,
    id: 1840011319,
  },
  {
    name: "Pocatello",
    state_id: "ID",
    lat: 42.8724,
    lng: -112.4646,
    id: 1840013376,
  },
  {
    name: "Woodbury",
    state_id: "MN",
    lat: 44.9056,
    lng: -92.923,
    id: 1840009998,
  },
  {
    name: "Southfield",
    state_id: "MI",
    lat: 42.4765,
    lng: -83.2605,
    id: 1840002431,
  },
  {
    name: "Ellicott City",
    state_id: "MD",
    lat: 39.2773,
    lng: -76.8344,
    id: 1840005802,
  },
  {
    name: "Dale City",
    state_id: "VA",
    lat: 38.6473,
    lng: -77.3459,
    id: 1840006094,
  },
  {
    name: "Maple Grove",
    state_id: "MN",
    lat: 45.1089,
    lng: -93.4626,
    id: 1840008923,
  },
  {
    name: "Pittsburg",
    state_id: "CA",
    lat: 38.0182,
    lng: -121.8964,
    id: 1840020282,
  },
  {
    name: "New Britain",
    state_id: "CT",
    lat: 41.6758,
    lng: -72.7862,
    id: 1840004774,
  },
  {
    name: "Morgantown",
    state_id: "WV",
    lat: 39.638,
    lng: -79.9468,
    id: 1840005647,
  },
  {
    name: "Mansfield",
    state_id: "TX",
    lat: 32.569,
    lng: -97.1211,
    id: 1840020702,
  },
  {
    name: "Hammond",
    state_id: "LA",
    lat: 30.5061,
    lng: -90.4563,
    id: 1840013908,
  },
  {
    name: "Dothan",
    state_id: "AL",
    lat: 31.2335,
    lng: -85.4069,
    id: 1840001518,
  },
  {
    name: "Harrisonburg",
    state_id: "VA",
    lat: 38.4361,
    lng: -78.8735,
    id: 1840003844,
  },
  {
    name: "Wenatchee",
    state_id: "WA",
    lat: 47.4356,
    lng: -120.3283,
    id: 1840021074,
  },
  {
    name: "Waukesha",
    state_id: "WI",
    lat: 43.0087,
    lng: -88.2464,
    id: 1840003008,
  },
  {
    name: "Pawtucket",
    state_id: "RI",
    lat: 41.8744,
    lng: -71.3743,
    id: 1840003285,
  },
  {
    name: "Redmond",
    state_id: "WA",
    lat: 47.6762,
    lng: -122.1169,
    id: 1840019835,
  },
  {
    name: "Lauderhill",
    state_id: "FL",
    lat: 26.1605,
    lng: -80.2242,
    id: 1840015146,
  },
  {
    name: "Sumter",
    state_id: "SC",
    lat: 33.9392,
    lng: -80.393,
    id: 1840015612,
  },
  {
    name: "Redlands",
    state_id: "CA",
    lat: 34.0511,
    lng: -117.1712,
    id: 1840020412,
  },
  {
    name: "Daphne",
    state_id: "AL",
    lat: 30.6286,
    lng: -87.8866,
    id: 1840006178,
  },
  {
    name: "Mount Vernon",
    state_id: "WA",
    lat: 48.4202,
    lng: -122.3115,
    id: 1840019774,
  },
  {
    name: "Weston",
    state_id: "FL",
    lat: 26.1006,
    lng: -80.4054,
    id: 1840016003,
  },
  {
    name: "St. Charles",
    state_id: "MO",
    lat: 38.7954,
    lng: -90.5157,
    id: 1840009719,
  },
  {
    name: "Janesville",
    state_id: "WI",
    lat: 42.6855,
    lng: -89.0136,
    id: 1840002467,
  },
  {
    name: "Sherman",
    state_id: "TX",
    lat: 33.6274,
    lng: -96.6218,
    id: 1840021953,
  },
  {
    name: "North Richland Hills",
    state_id: "TX",
    lat: 32.8605,
    lng: -97.218,
    id: 1840020703,
  },
  {
    name: "Broomfield",
    state_id: "CO",
    lat: 39.9541,
    lng: -105.0527,
    id: 1840018781,
  },
  {
    name: "El Paso de Robles",
    state_id: "CA",
    lat: 35.6394,
    lng: -120.656,
    id: 1840074847,
  },
  {
    name: "Bristol",
    state_id: "TN",
    lat: 36.5572,
    lng: -82.2154,
    id: 1840013301,
  },
  {
    name: "Wilmington",
    state_id: "DE",
    lat: 39.7415,
    lng: -75.5413,
    id: 1840005579,
  },
  {
    name: "Casas Adobes",
    state_id: "AZ",
    lat: 32.3423,
    lng: -111.0114,
    id: 1840018108,
  },
  {
    name: "Walnut Creek",
    state_id: "CA",
    lat: 37.9024,
    lng: -122.0398,
    id: 1840021531,
  },
  {
    name: "Poinciana",
    state_id: "FL",
    lat: 28.1217,
    lng: -81.482,
    id: 1840014132,
  },
  {
    name: "Lynwood",
    state_id: "CA",
    lat: 33.924,
    lng: -118.2017,
    id: 1840020492,
  },
  {
    name: "Glen Burnie",
    state_id: "MD",
    lat: 39.1559,
    lng: -76.6072,
    id: 1840005917,
  },
  {
    name: "Lehi",
    state_id: "UT",
    lat: 40.4136,
    lng: -111.8726,
    id: 1840020168,
  },
  {
    name: "Passaic",
    state_id: "NJ",
    lat: 40.8574,
    lng: -74.1282,
    id: 1840000837,
  },
  {
    name: "Homestead",
    state_id: "FL",
    lat: 25.4665,
    lng: -80.4472,
    id: 1840015159,
  },
  {
    name: "West Bend",
    state_id: "WI",
    lat: 43.4184,
    lng: -88.1824,
    id: 1840002829,
  },
  {
    name: "Delray Beach",
    state_id: "FL",
    lat: 26.455,
    lng: -80.0905,
    id: 1840015123,
  },
  {
    name: "Sheboygan",
    state_id: "WI",
    lat: 43.7403,
    lng: -87.7316,
    id: 1840002628,
  },
  {
    name: "Lake Elsinore",
    state_id: "CA",
    lat: 33.6847,
    lng: -117.3344,
    id: 1840020560,
  },
  {
    name: "Daytona Beach",
    state_id: "FL",
    lat: 29.1994,
    lng: -81.0982,
    id: 1840015068,
  },
  {
    name: "Los Lunas",
    state_id: "NM",
    lat: 34.8115,
    lng: -106.7803,
    id: 1840022814,
  },
  {
    name: "Lima",
    state_id: "OH",
    lat: 40.741,
    lng: -84.1121,
    id: 1840002785,
  },
  {
    name: "Decatur",
    state_id: "AL",
    lat: 34.573,
    lng: -86.9906,
    id: 1840001403,
  },
  {
    name: "Rocklin",
    state_id: "CA",
    lat: 38.8075,
    lng: -121.2488,
    id: 1840020232,
  },
  {
    name: "Rogers",
    state_id: "AR",
    lat: 36.3172,
    lng: -94.1526,
    id: 1840014444,
  },
  {
    name: "Castle Rock",
    state_id: "CO",
    lat: 39.3763,
    lng: -104.8535,
    id: 1840022455,
  },
  {
    name: "Hanover",
    state_id: "PA",
    lat: 39.8117,
    lng: -76.9835,
    id: 1840001450,
  },
  {
    name: "Bossier City",
    state_id: "LA",
    lat: 32.5227,
    lng: -93.6666,
    id: 1840013790,
  },
  {
    name: "Dubuque",
    state_id: "IA",
    lat: 42.5008,
    lng: -90.7067,
    id: 1840000438,
  },
  {
    name: "Rockville",
    state_id: "MD",
    lat: 39.0834,
    lng: -77.1552,
    id: 1840005849,
  },
  {
    name: "Victoria",
    state_id: "TX",
    lat: 28.8285,
    lng: -96.985,
    id: 1840023044,
  },
  {
    name: "Saratoga Springs",
    state_id: "NY",
    lat: 43.0674,
    lng: -73.7775,
    id: 1840000369,
  },
  {
    name: "Guaynabo",
    state_id: "PR",
    lat: 18.3839,
    lng: -66.1134,
    id: 1630023575,
  },
  {
    name: "Gaithersburg",
    state_id: "MD",
    lat: 39.1346,
    lng: -77.2132,
    id: 1840005848,
  },
  {
    name: "Union City",
    state_id: "NJ",
    lat: 40.7674,
    lng: -74.0323,
    id: 1840001034,
  },
  {
    name: "Ames",
    state_id: "IA",
    lat: 42.0259,
    lng: -93.6215,
    id: 1840007019,
  },
  {
    name: "West Des Moines",
    state_id: "IA",
    lat: 41.5522,
    lng: -93.7805,
    id: 1840010185,
  },
  {
    name: "South San Francisco",
    state_id: "CA",
    lat: 37.6536,
    lng: -122.4197,
    id: 1840021552,
  },
  {
    name: "Yorba Linda",
    state_id: "CA",
    lat: 33.889,
    lng: -117.7714,
    id: 1840021970,
  },
  {
    name: "Casper",
    state_id: "WY",
    lat: 42.842,
    lng: -106.3207,
    id: 1840038324,
  },
  {
    name: "Palatine",
    state_id: "IL",
    lat: 42.1181,
    lng: -88.043,
    id: 1840011311,
  },
  {
    name: "Corvallis",
    state_id: "OR",
    lat: 44.5697,
    lng: -123.278,
    id: 1840018611,
  },
  {
    name: "Ankeny",
    state_id: "IA",
    lat: 41.7285,
    lng: -93.6033,
    id: 1840007066,
  },
  {
    name: "Mount Vernon",
    state_id: "NY",
    lat: 40.9136,
    lng: -73.8291,
    id: 1840000781,
  },
  {
    name: "Rowlett",
    state_id: "TX",
    lat: 32.9157,
    lng: -96.5488,
    id: 1840020711,
  },
  {
    name: "Lakeville",
    state_id: "MN",
    lat: 44.6774,
    lng: -93.252,
    id: 1840007859,
  },
  {
    name: "Alpharetta",
    state_id: "GA",
    lat: 34.0704,
    lng: -84.2739,
    id: 1840013659,
  },
  {
    name: "Longview",
    state_id: "WA",
    lat: 46.1461,
    lng: -122.9629,
    id: 1840019904,
  },
  {
    name: "DeKalb",
    state_id: "IL",
    lat: 41.9313,
    lng: -88.7482,
    id: 1840007033,
  },
  {
    name: "Petaluma",
    state_id: "CA",
    lat: 38.2423,
    lng: -122.6267,
    id: 1840020244,
  },
  {
    name: "Redondo Beach",
    state_id: "CA",
    lat: 33.8574,
    lng: -118.3766,
    id: 1840020509,
  },
  {
    name: "Tamarac",
    state_id: "FL",
    lat: 26.2056,
    lng: -80.2542,
    id: 1840016001,
  },
  {
    name: "Laguna Niguel",
    state_id: "CA",
    lat: 33.5275,
    lng: -117.705,
    id: 1840020589,
  },
  {
    name: "Eagan",
    state_id: "MN",
    lat: 44.8169,
    lng: -93.1638,
    id: 1840007852,
  },
  {
    name: "Kenner",
    state_id: "LA",
    lat: 30.0109,
    lng: -90.2549,
    id: 1840015047,
  },
  {
    name: "Bay City",
    state_id: "MI",
    lat: 43.5903,
    lng: -83.8886,
    id: 1840002583,
  },
  {
    name: "North Little Rock",
    state_id: "AR",
    lat: 34.7808,
    lng: -92.2371,
    id: 1840014632,
  },
  {
    name: "Sammamish",
    state_id: "WA",
    lat: 47.6017,
    lng: -122.0416,
    id: 1840021115,
  },
  {
    name: "Shawnee",
    state_id: "KS",
    lat: 39.0158,
    lng: -94.8076,
    id: 1840001641,
  },
  {
    name: "Jupiter",
    state_id: "FL",
    lat: 26.9199,
    lng: -80.1128,
    id: 1840017255,
  },
  {
    name: "Doral",
    state_id: "FL",
    lat: 25.8151,
    lng: -80.3565,
    id: 1840015148,
  },
  {
    name: "Florence-Graham",
    state_id: "CA",
    lat: 33.9682,
    lng: -118.2447,
    id: 1840037057,
  },
  {
    name: "Carbondale",
    state_id: "IL",
    lat: 37.7221,
    lng: -89.2238,
    id: 1840007560,
  },
  {
    name: "Blaine",
    state_id: "MN",
    lat: 45.1696,
    lng: -93.2077,
    id: 1840006719,
  },
  {
    name: "Weirton",
    state_id: "WV",
    lat: 40.406,
    lng: -80.5671,
    id: 1840005405,
  },
  {
    name: "Tulare",
    state_id: "CA",
    lat: 36.1996,
    lng: -119.34,
    id: 1840021638,
  },
  {
    name: "Wellington",
    state_id: "FL",
    lat: 26.6461,
    lng: -80.2699,
    id: 1840018311,
  },
  {
    name: "Pflugerville",
    state_id: "TX",
    lat: 30.452,
    lng: -97.6022,
    id: 1840020892,
  },
  {
    name: "Palo Alto",
    state_id: "CA",
    lat: 37.3913,
    lng: -122.1468,
    id: 1840020333,
  },
  {
    name: "Schenectady",
    state_id: "NY",
    lat: 42.8025,
    lng: -73.9276,
    id: 1840000395,
  },
  {
    name: "Great Falls",
    state_id: "MT",
    lat: 47.5022,
    lng: -111.2995,
    id: 1840019839,
  },
  {
    name: "Michigan City",
    state_id: "IN",
    lat: 41.7099,
    lng: -86.8705,
    id: 1840013869,
  },
  {
    name: "Bayonne",
    state_id: "NJ",
    lat: 40.6661,
    lng: -74.1141,
    id: 1840003595,
  },
  {
    name: "Eden Prairie",
    state_id: "MN",
    lat: 44.8488,
    lng: -93.4595,
    id: 1840007821,
  },
  {
    name: "Port Orange",
    state_id: "FL",
    lat: 29.1084,
    lng: -81.0137,
    id: 1840015077,
  },
  {
    name: "Dublin",
    state_id: "CA",
    lat: 37.7161,
    lng: -121.8963,
    id: 1840018916,
  },
  {
    name: "Grand Forks",
    state_id: "ND",
    lat: 47.9218,
    lng: -97.0886,
    id: 1840000101,
  },
  {
    name: "Noblesville",
    state_id: "IN",
    lat: 40.0354,
    lng: -86.0042,
    id: 1840013891,
  },
  {
    name: "San Clemente",
    state_id: "CA",
    lat: 33.4498,
    lng: -117.6103,
    id: 1840021962,
  },
  {
    name: "Brentwood",
    state_id: "CA",
    lat: 37.9355,
    lng: -121.7191,
    id: 1840018904,
  },
  {
    name: "Carmichael",
    state_id: "CA",
    lat: 38.6337,
    lng: -121.323,
    id: 1840017579,
  },
  {
    name: "East Orange",
    state_id: "NJ",
    lat: 40.7651,
    lng: -74.2117,
    id: 1840002792,
  },
  {
    name: "Eastvale",
    state_id: "CA",
    lat: 33.9617,
    lng: -117.5802,
    id: 1840028421,
  },
  {
    name: "Chapel Hill",
    state_id: "NC",
    lat: 35.927,
    lng: -79.0391,
    id: 1840016152,
  },
  {
    name: "Rocky Mount",
    state_id: "NC",
    lat: 35.9676,
    lng: -77.8048,
    id: 1840014490,
  },
  {
    name: "Haverhill",
    state_id: "MA",
    lat: 42.7838,
    lng: -71.0871,
    id: 1840002450,
  },
  {
    name: "Beloit",
    state_id: "WI",
    lat: 42.523,
    lng: -89.0184,
    id: 1840002471,
  },
  {
    name: "Glens Falls",
    state_id: "NY",
    lat: 43.3109,
    lng: -73.6459,
    id: 1840000345,
  },
  {
    name: "Brentwood",
    state_id: "NY",
    lat: 40.7839,
    lng: -73.2522,
    id: 1840005021,
  },
  {
    name: "Fairbanks",
    state_id: "AK",
    lat: 64.8353,
    lng: -147.6534,
    id: 1840023463,
  },
  {
    name: "Springfield",
    state_id: "OR",
    lat: 44.0538,
    lng: -122.981,
    id: 1840021263,
  },
  {
    name: "Bethesda",
    state_id: "MD",
    lat: 38.9866,
    lng: -77.1188,
    id: 1840005826,
  },
  {
    name: "Johnstown",
    state_id: "PA",
    lat: 40.3258,
    lng: -78.9193,
    id: 1840001116,
  },
  {
    name: "Dundalk",
    state_id: "MD",
    lat: 39.2703,
    lng: -76.4942,
    id: 1840005681,
  },
  {
    name: "Castro Valley",
    state_id: "CA",
    lat: 37.7091,
    lng: -122.0631,
    id: 1840017620,
  },
  {
    name: "Coon Rapids",
    state_id: "MN",
    lat: 45.1755,
    lng: -93.3095,
    id: 1840006714,
  },
  {
    name: "Elmira",
    state_id: "NY",
    lat: 42.0938,
    lng: -76.8097,
    id: 1840000472,
  },
  {
    name: "Mankato",
    state_id: "MN",
    lat: 44.1712,
    lng: -93.9773,
    id: 1840008975,
  },
  {
    name: "Albany",
    state_id: "OR",
    lat: 44.6274,
    lng: -123.0966,
    id: 1840018605,
  },
  {
    name: "North Miami",
    state_id: "FL",
    lat: 25.9007,
    lng: -80.1686,
    id: 1840015154,
  },
  {
    name: "Encinitas",
    state_id: "CA",
    lat: 33.049,
    lng: -117.2613,
    id: 1840020619,
  },
  {
    name: "Skokie",
    state_id: "IL",
    lat: 42.0359,
    lng: -87.74,
    id: 1840011321,
  },
  {
    name: "Leander",
    state_id: "TX",
    lat: 30.5728,
    lng: -97.8618,
    id: 1840020877,
  },
  {
    name: "Parkersburg",
    state_id: "WV",
    lat: 39.2624,
    lng: -81.542,
    id: 1840005786,
  },
  {
    name: "Waltham",
    state_id: "MA",
    lat: 42.3889,
    lng: -71.2423,
    id: 1840000428,
  },
  {
    name: "Port Charlotte",
    state_id: "FL",
    lat: 26.9918,
    lng: -82.114,
    id: 1840014200,
  },
  {
    name: "Palm Harbor",
    state_id: "FL",
    lat: 28.0847,
    lng: -82.7481,
    id: 1840014143,
  },
  {
    name: "San Luis Obispo",
    state_id: "CA",
    lat: 35.2669,
    lng: -120.6691,
    id: 1840021734,
  },
  {
    name: "Sebring",
    state_id: "FL",
    lat: 27.477,
    lng: -81.453,
    id: 1840015986,
  },
  {
    name: "Council Bluffs",
    state_id: "IA",
    lat: 41.2369,
    lng: -95.8517,
    id: 1840007117,
  },
  {
    name: "Hamilton",
    state_id: "OH",
    lat: 39.3938,
    lng: -84.5653,
    id: 1840003803,
  },
  {
    name: "Moore",
    state_id: "OK",
    lat: 35.3293,
    lng: -97.4758,
    id: 1840020449,
  },
  {
    name: "Pico Rivera",
    state_id: "CA",
    lat: 33.9902,
    lng: -118.0888,
    id: 1840020506,
  },
  {
    name: "Casa Grande",
    state_id: "AZ",
    lat: 32.9068,
    lng: -111.7624,
    id: 1840019364,
  },
  {
    name: "Monessen",
    state_id: "PA",
    lat: 40.1519,
    lng: -79.8828,
    id: 1840001146,
  },
  {
    name: "Montebello",
    state_id: "CA",
    lat: 34.0155,
    lng: -118.1108,
    id: 1840020499,
  },
  {
    name: "Rome",
    state_id: "GA",
    lat: 34.2661,
    lng: -85.1862,
    id: 1840014681,
  },
  {
    name: "The Hammocks",
    state_id: "FL",
    lat: 25.67,
    lng: -80.4483,
    id: 1840029009,
  },
  {
    name: "Millcreek",
    state_id: "UT",
    lat: 40.6892,
    lng: -111.8291,
    id: 1840018744,
  },
  {
    name: "Sanford",
    state_id: "FL",
    lat: 28.7893,
    lng: -81.276,
    id: 1840015958,
  },
  {
    name: "National City",
    state_id: "CA",
    lat: 32.6654,
    lng: -117.0983,
    id: 1840020622,
  },
  {
    name: "Mayagüez",
    state_id: "PR",
    lat: 18.2003,
    lng: -67.1397,
    id: 1630035520,
  },
  {
    name: "Burnsville",
    state_id: "MN",
    lat: 44.7648,
    lng: -93.2795,
    id: 1840006766,
  },
  {
    name: "Coconut Creek",
    state_id: "FL",
    lat: 26.2803,
    lng: -80.1842,
    id: 1840015132,
  },
  {
    name: "Morristown",
    state_id: "TN",
    lat: 36.2046,
    lng: -83.3006,
    id: 1840014467,
  },
  {
    name: "Reston",
    state_id: "VA",
    lat: 38.9497,
    lng: -77.3461,
    id: 1840006025,
  },
  {
    name: "Lakewood",
    state_id: "WA",
    lat: 47.1628,
    lng: -122.5299,
    id: 1840019860,
  },
  {
    name: "Spring",
    state_id: "TX",
    lat: 30.0613,
    lng: -95.383,
    id: 1840019615,
  },
  {
    name: "Gadsden",
    state_id: "AL",
    lat: 34.009,
    lng: -86.0156,
    id: 1840006332,
  },
  {
    name: "Taylor",
    state_id: "MI",
    lat: 42.226,
    lng: -83.2688,
    id: 1840003965,
  },
  {
    name: "Novi",
    state_id: "MI",
    lat: 42.4786,
    lng: -83.4893,
    id: 1840002423,
  },
  {
    name: "Marietta",
    state_id: "GA",
    lat: 33.9533,
    lng: -84.5422,
    id: 1840015633,
  },
  {
    name: "Hot Springs",
    state_id: "AR",
    lat: 34.4892,
    lng: -93.0501,
    id: 1840014670,
  },
  {
    name: "Woodland",
    state_id: "CA",
    lat: 38.6712,
    lng: -121.75,
    id: 1840021485,
  },
  {
    name: "Fountainebleau",
    state_id: "FL",
    lat: 25.7723,
    lng: -80.3458,
    id: 1840028983,
  },
  {
    name: "La Habra",
    state_id: "CA",
    lat: 33.9278,
    lng: -117.9513,
    id: 1840020585,
  },
  {
    name: "Malden",
    state_id: "MA",
    lat: 42.4305,
    lng: -71.0576,
    id: 1840000430,
  },
  {
    name: "South Whittier",
    state_id: "CA",
    lat: 33.9336,
    lng: -118.0311,
    id: 1840019209,
  },
  {
    name: "Commerce City",
    state_id: "CO",
    lat: 39.8642,
    lng: -104.8434,
    id: 1840018785,
  },
  {
    name: "South Hill",
    state_id: "WA",
    lat: 47.1203,
    lng: -122.2848,
    id: 1840037886,
  },
  {
    name: "Bristol",
    state_id: "CT",
    lat: 41.6812,
    lng: -72.9407,
    id: 1840004772,
  },
  {
    name: "Columbus",
    state_id: "IN",
    lat: 39.2093,
    lng: -85.9183,
    id: 1840013714,
  },
  {
    name: "West Allis",
    state_id: "WI",
    lat: 43.0068,
    lng: -88.0296,
    id: 1840003034,
  },
  {
    name: "Taylorsville",
    state_id: "UT",
    lat: 40.657,
    lng: -111.9493,
    id: 1840021387,
  },
  {
    name: "Bangor",
    state_id: "ME",
    lat: 44.8322,
    lng: -68.7906,
    id: 1840000238,
  },
  {
    name: "Monterey Park",
    state_id: "CA",
    lat: 34.0497,
    lng: -118.1326,
    id: 1840020500,
  },
  {
    name: "Greenwood",
    state_id: "IN",
    lat: 39.6019,
    lng: -86.1073,
    id: 1840007332,
  },
  {
    name: "Bartlett",
    state_id: "TN",
    lat: 35.2337,
    lng: -89.8195,
    id: 1840013472,
  },
  {
    name: "Bradenton",
    state_id: "FL",
    lat: 27.49,
    lng: -82.5739,
    id: 1840014173,
  },
  {
    name: "Pontiac",
    state_id: "MI",
    lat: 42.6493,
    lng: -83.2878,
    id: 1840002427,
  },
  {
    name: "Staunton",
    state_id: "VA",
    lat: 38.1593,
    lng: -79.0611,
    id: 1840001670,
  },
  {
    name: "Meriden",
    state_id: "CT",
    lat: 41.5367,
    lng: -72.7944,
    id: 1840004848,
  },
  {
    name: "Gardena",
    state_id: "CA",
    lat: 33.8944,
    lng: -118.3073,
    id: 1840020482,
  },
  {
    name: "Apex",
    state_id: "NC",
    lat: 35.7239,
    lng: -78.8728,
    id: 1840015355,
  },
  {
    name: "Royal Oak",
    state_id: "MI",
    lat: 42.5084,
    lng: -83.1539,
    id: 1840002430,
  },
  {
    name: "Cupertino",
    state_id: "CA",
    lat: 37.3168,
    lng: -122.0465,
    id: 1840018950,
  },
  {
    name: "La Mesa",
    state_id: "CA",
    lat: 32.7703,
    lng: -117.0204,
    id: 1840020625,
  },
  {
    name: "Benton Harbor",
    state_id: "MI",
    lat: 42.1159,
    lng: -86.4488,
    id: 1840003215,
  },
  {
    name: "St. Clair Shores",
    state_id: "MI",
    lat: 42.4921,
    lng: -82.8957,
    id: 1840003093,
  },
  {
    name: "Des Plaines",
    state_id: "IL",
    lat: 42.0345,
    lng: -87.9009,
    id: 1840007032,
  },
  {
    name: "Lewiston",
    state_id: "ME",
    lat: 44.0915,
    lng: -70.1681,
    id: 1840000320,
  },
  {
    name: "Margate",
    state_id: "FL",
    lat: 26.2466,
    lng: -80.2119,
    id: 1840015999,
  },
  {
    name: "Midland",
    state_id: "MI",
    lat: 43.6241,
    lng: -84.2319,
    id: 1840002660,
  },
  {
    name: "Carson City",
    state_id: "NV",
    lat: 39.1512,
    lng: -119.7474,
    id: 1840003826,
  },
  {
    name: "Bowie",
    state_id: "MD",
    lat: 38.9569,
    lng: -76.7409,
    id: 1840005970,
  },
  {
    name: "Middletown",
    state_id: "NY",
    lat: 41.4459,
    lng: -74.4236,
    id: 1840000585,
  },
  {
    name: "Caldwell",
    state_id: "ID",
    lat: 43.6453,
    lng: -116.6591,
    id: 1840018641,
  },
  {
    name: "San Rafael",
    state_id: "CA",
    lat: 37.9905,
    lng: -122.5222,
    id: 1840021513,
  },
  {
    name: "Richland",
    state_id: "WA",
    lat: 46.2824,
    lng: -119.2938,
    id: 1840019889,
  },
  {
    name: "St. Peters",
    state_id: "MO",
    lat: 38.7824,
    lng: -90.6061,
    id: 1840009721,
  },
  {
    name: "Wesley Chapel",
    state_id: "FL",
    lat: 28.2106,
    lng: -82.3238,
    id: 1840014118,
  },
  {
    name: "Hendersonville",
    state_id: "TN",
    lat: 36.3063,
    lng: -86.5998,
    id: 1840014419,
  },
  {
    name: "White Plains",
    state_id: "NY",
    lat: 41.022,
    lng: -73.7549,
    id: 1840003477,
  },
  {
    name: "Huntersville",
    state_id: "NC",
    lat: 35.4058,
    lng: -80.8727,
    id: 1840016349,
  },
  {
    name: "Santee",
    state_id: "CA",
    lat: 32.8554,
    lng: -116.9851,
    id: 1840021992,
  },
  {
    name: "Jefferson City",
    state_id: "MO",
    lat: 38.5676,
    lng: -92.1759,
    id: 1840008615,
  },
  {
    name: "Beckley",
    state_id: "WV",
    lat: 37.7878,
    lng: -81.1841,
    id: 1840006350,
  },
  {
    name: "Arcadia",
    state_id: "CA",
    lat: 34.1342,
    lng: -118.0373,
    id: 1840019223,
  },
  {
    name: "Titusville",
    state_id: "FL",
    lat: 28.5727,
    lng: -80.8193,
    id: 1840015962,
  },
  {
    name: "Cartersville",
    state_id: "GA",
    lat: 34.1639,
    lng: -84.8007,
    id: 1840014701,
  },
  {
    name: "Walla Walla",
    state_id: "WA",
    lat: 46.067,
    lng: -118.3366,
    id: 1840021174,
  },
  {
    name: "Orland Park",
    state_id: "IL",
    lat: 41.6074,
    lng: -87.8619,
    id: 1840011310,
  },
  {
    name: "Towson",
    state_id: "MD",
    lat: 39.3944,
    lng: -76.619,
    id: 1840005695,
  },
  {
    name: "Weymouth",
    state_id: "MA",
    lat: 42.1984,
    lng: -70.9466,
    id: 1840132442,
  },
  {
    name: "Parker",
    state_id: "CO",
    lat: 39.5084,
    lng: -104.7753,
    id: 1840022454,
  },
  {
    name: "Palm Beach Gardens",
    state_id: "FL",
    lat: 26.8466,
    lng: -80.1679,
    id: 1840015125,
  },
  {
    name: "Bozeman",
    state_id: "MT",
    lat: 45.6832,
    lng: -111.0552,
    id: 1840018509,
  },
  {
    name: "East Stroudsburg",
    state_id: "PA",
    lat: 41.0023,
    lng: -75.1779,
    id: 1840003507,
  },
  {
    name: "Huntington Park",
    state_id: "CA",
    lat: 33.98,
    lng: -118.2167,
    id: 1840019235,
  },
  {
    name: "Taunton",
    state_id: "MA",
    lat: 41.9036,
    lng: -71.0943,
    id: 1840003220,
  },
  {
    name: "Midwest City",
    state_id: "OK",
    lat: 35.463,
    lng: -97.3709,
    id: 1840020425,
  },
  {
    name: "Medford",
    state_id: "MA",
    lat: 42.4234,
    lng: -71.1087,
    id: 1840000431,
  },
  {
    name: "Goldsboro",
    state_id: "NC",
    lat: 35.3778,
    lng: -77.972,
    id: 1840013432,
  },
  {
    name: "Euless",
    state_id: "TX",
    lat: 32.8508,
    lng: -97.0799,
    id: 1840020693,
  },
  {
    name: "Manhattan",
    state_id: "KS",
    lat: 39.1886,
    lng: -96.6046,
    id: 1840001589,
  },
  {
    name: "Shoreline",
    state_id: "WA",
    lat: 47.7564,
    lng: -122.3426,
    id: 1840021118,
  },
  {
    name: "Lake Havasu City",
    state_id: "AZ",
    lat: 34.5006,
    lng: -114.3113,
    id: 1840021044,
  },
  {
    name: "Smyrna",
    state_id: "GA",
    lat: 33.8633,
    lng: -84.5168,
    id: 1840015634,
  },
  {
    name: "Cape Girardeau",
    state_id: "MO",
    lat: 37.3108,
    lng: -89.5597,
    id: 1840007598,
  },
  {
    name: "Tamiami",
    state_id: "FL",
    lat: 25.7556,
    lng: -80.4016,
    id: 1840029085,
  },
  {
    name: "Kendale Lakes",
    state_id: "FL",
    lat: 25.7081,
    lng: -80.4078,
    id: 1840029045,
  },
  {
    name: "Grants Pass",
    state_id: "OR",
    lat: 42.4333,
    lng: -123.3317,
    id: 1840020087,
  },
  {
    name: "Blue Springs",
    state_id: "MO",
    lat: 39.0124,
    lng: -94.2721,
    id: 1840007396,
  },
  {
    name: "Southaven",
    state_id: "MS",
    lat: 34.9514,
    lng: -89.9787,
    id: 1840015519,
  },
  {
    name: "Tinley Park",
    state_id: "IL",
    lat: 41.567,
    lng: -87.805,
    id: 1840011331,
  },
  {
    name: "Diamond Bar",
    state_id: "CA",
    lat: 33.9991,
    lng: -117.8161,
    id: 1840019244,
  },
  {
    name: "Pittsfield",
    state_id: "MA",
    lat: 42.4517,
    lng: -73.2605,
    id: 1840003116,
  },
  {
    name: "Eagle Pass",
    state_id: "TX",
    lat: 28.7125,
    lng: -100.484,
    id: 1840019690,
  },
  {
    name: "New Brunswick",
    state_id: "NJ",
    lat: 40.487,
    lng: -74.445,
    id: 1840001339,
  },
  {
    name: "Lenexa",
    state_id: "KS",
    lat: 38.9609,
    lng: -94.8018,
    id: 1840001639,
  },
  {
    name: "Hazleton",
    state_id: "PA",
    lat: 40.9504,
    lng: -75.9724,
    id: 1840000753,
  },
  {
    name: "Twin Falls",
    state_id: "ID",
    lat: 42.5645,
    lng: -114.4615,
    id: 1840021301,
  },
  {
    name: "Brookhaven",
    state_id: "GA",
    lat: 33.8744,
    lng: -84.3314,
    id: 1840029224,
  },
  {
    name: "Novato",
    state_id: "CA",
    lat: 38.092,
    lng: -122.5576,
    id: 1840020260,
  },
  {
    name: "Tigard",
    state_id: "OR",
    lat: 45.4237,
    lng: -122.7845,
    id: 1840021206,
  },
  {
    name: "Highland",
    state_id: "CA",
    lat: 34.1113,
    lng: -117.1654,
    id: 1840020405,
  },
  {
    name: "Fountain Valley",
    state_id: "CA",
    lat: 33.7105,
    lng: -117.9514,
    id: 1840020575,
  },
  {
    name: "Dearborn Heights",
    state_id: "MI",
    lat: 42.3164,
    lng: -83.2769,
    id: 1840003970,
  },
  {
    name: "Grapevine",
    state_id: "TX",
    lat: 32.9343,
    lng: -97.0744,
    id: 1840020697,
  },
  {
    name: "Fond du Lac",
    state_id: "WI",
    lat: 43.7718,
    lng: -88.4396,
    id: 1840002603,
  },
  {
    name: "Hacienda Heights",
    state_id: "CA",
    lat: 33.997,
    lng: -117.9728,
    id: 1840017921,
  },
  {
    name: "Ithaca",
    state_id: "NY",
    lat: 42.4442,
    lng: -76.5032,
    id: 1840000442,
  },
  {
    name: "Apple Valley",
    state_id: "MN",
    lat: 44.7457,
    lng: -93.2006,
    id: 1840006768,
  },
  {
    name: "Chicopee",
    state_id: "MA",
    lat: 42.1764,
    lng: -72.5719,
    id: 1840000462,
  },
  {
    name: "Oak Lawn",
    state_id: "IL",
    lat: 41.7139,
    lng: -87.7528,
    id: 1840011306,
  },
  {
    name: "Cathedral City",
    state_id: "CA",
    lat: 33.8363,
    lng: -116.4642,
    id: 1840019293,
  },
  {
    name: "Delano",
    state_id: "CA",
    lat: 35.767,
    lng: -119.2637,
    id: 1840019151,
  },
  {
    name: "Bentonville",
    state_id: "AR",
    lat: 36.3546,
    lng: -94.2306,
    id: 1840013319,
  },
  {
    name: "Stonecrest",
    state_id: "GA",
    lat: 33.6842,
    lng: -84.1372,
    id: 1840029466,
  },
  {
    name: "Kettering",
    state_id: "OH",
    lat: 39.6957,
    lng: -84.1495,
    id: 1840003783,
  },
  {
    name: "Colton",
    state_id: "CA",
    lat: 34.0538,
    lng: -117.3254,
    id: 1840019120,
  },
  {
    name: "Kingston",
    state_id: "NY",
    lat: 41.9295,
    lng: -73.9968,
    id: 1840000493,
  },
  {
    name: "West Haven",
    state_id: "CT",
    lat: 41.2739,
    lng: -72.9672,
    id: 1840004852,
  },
  {
    name: "St. Cloud",
    state_id: "FL",
    lat: 28.2294,
    lng: -81.2829,
    id: 1840015971,
  },
  {
    name: "Normal",
    state_id: "IL",
    lat: 40.5218,
    lng: -88.9881,
    id: 1840009401,
  },
  {
    name: "Berwyn",
    state_id: "IL",
    lat: 41.8433,
    lng: -87.7909,
    id: 1840007027,
  },
  {
    name: "Milford",
    state_id: "CT",
    lat: 41.2256,
    lng: -73.0616,
    id: 1840028594,
  },
  {
    name: "Aloha",
    state_id: "OR",
    lat: 45.492,
    lng: -122.8726,
    id: 1840017414,
  },
  {
    name: "Arroyo Grande",
    state_id: "CA",
    lat: 35.1241,
    lng: -120.5845,
    id: 1840019125,
  },
  {
    name: "Minnetonka",
    state_id: "MN",
    lat: 44.9322,
    lng: -93.4598,
    id: 1840007831,
  },
  {
    name: "Rosemead",
    state_id: "CA",
    lat: 34.0689,
    lng: -118.0823,
    id: 1840020495,
  },
  {
    name: "Paramount",
    state_id: "CA",
    lat: 33.8976,
    lng: -118.1652,
    id: 1840020504,
  },
  {
    name: "Yucaipa",
    state_id: "CA",
    lat: 34.0336,
    lng: -117.0429,
    id: 1840021732,
  },
  {
    name: "Brunswick",
    state_id: "GA",
    lat: 31.145,
    lng: -81.474,
    id: 1840013875,
  },
  {
    name: "Williamsport",
    state_id: "PA",
    lat: 41.2398,
    lng: -77.0371,
    id: 1840000637,
  },
  {
    name: "Elyria",
    state_id: "OH",
    lat: 41.3761,
    lng: -82.1063,
    id: 1840000643,
  },
  {
    name: "Leesburg",
    state_id: "VA",
    lat: 39.1058,
    lng: -77.5544,
    id: 1840005872,
  },
  {
    name: "Mount Prospect",
    state_id: "IL",
    lat: 42.0641,
    lng: -87.9375,
    id: 1840011300,
  },
  {
    name: "Pinellas Park",
    state_id: "FL",
    lat: 27.8589,
    lng: -82.7078,
    id: 1840015112,
  },
  {
    name: "Bellevue",
    state_id: "NE",
    lat: 41.1535,
    lng: -95.9357,
    id: 1840007142,
  },
  {
    name: "West Sacramento",
    state_id: "CA",
    lat: 38.5557,
    lng: -121.5504,
    id: 1840021483,
  },
  {
    name: "Apopka",
    state_id: "FL",
    lat: 28.7014,
    lng: -81.5304,
    id: 1840014093,
  },
  {
    name: "Lewiston",
    state_id: "ID",
    lat: 46.3934,
    lng: -116.9934,
    id: 1840019897,
  },
  {
    name: "Grand Island",
    state_id: "NE",
    lat: 40.9214,
    lng: -98.3584,
    id: 1840000956,
  },
  {
    name: "Palm Desert",
    state_id: "CA",
    lat: 33.7378,
    lng: -116.3695,
    id: 1840020555,
  },
  {
    name: "Milford city ",
    state_id: "CT",
    lat: 41.2256,
    lng: -73.0625,
    id: 1840028605,
  },
  {
    name: "Watertown",
    state_id: "NY",
    lat: 43.9734,
    lng: -75.9094,
    id: 1840002200,
  },
  {
    name: "Little Elm",
    state_id: "TX",
    lat: 33.1856,
    lng: -96.929,
    id: 1840020640,
  },
  {
    name: "Chambersburg",
    state_id: "PA",
    lat: 39.9315,
    lng: -77.6556,
    id: 1840001410,
  },
  {
    name: "Revere",
    state_id: "MA",
    lat: 42.4192,
    lng: -71.0036,
    id: 1840000457,
  },
  {
    name: "Peabody",
    state_id: "MA",
    lat: 42.5335,
    lng: -70.9724,
    id: 1840000414,
  },
  {
    name: "Wylie",
    state_id: "TX",
    lat: 33.0362,
    lng: -96.5161,
    id: 1840022018,
  },
  {
    name: "Beaufort",
    state_id: "SC",
    lat: 32.4597,
    lng: -80.7235,
    id: 1840013818,
  },
  {
    name: "Aspen Hill",
    state_id: "MD",
    lat: 39.0928,
    lng: -77.0823,
    id: 1840005825,
  },
  {
    name: "DeSoto",
    state_id: "TX",
    lat: 32.5992,
    lng: -96.8633,
    id: 1840019441,
  },
  {
    name: "Helena",
    state_id: "MT",
    lat: 46.5965,
    lng: -112.0202,
    id: 1840019820,
  },
  {
    name: "Edina",
    state_id: "MN",
    lat: 44.8914,
    lng: -93.3602,
    id: 1840007822,
  },
  {
    name: "Wheaton",
    state_id: "IL",
    lat: 41.8561,
    lng: -88.1083,
    id: 1840010166,
  },
  {
    name: "West New York",
    state_id: "NJ",
    lat: 40.7856,
    lng: -74.0093,
    id: 1840001035,
  },
  {
    name: "Hoboken",
    state_id: "NJ",
    lat: 40.7453,
    lng: -74.0279,
    id: 1840003599,
  },
  {
    name: "Lacey",
    state_id: "WA",
    lat: 47.046,
    lng: -122.7934,
    id: 1840019866,
  },
  {
    name: "Summerville",
    state_id: "SC",
    lat: 33.0015,
    lng: -80.1799,
    id: 1840016886,
  },
  {
    name: "Oak Park",
    state_id: "IL",
    lat: 41.8872,
    lng: -87.7899,
    id: 1840011307,
  },
  {
    name: "Maricopa",
    state_id: "AZ",
    lat: 33.0404,
    lng: -112.0006,
    id: 1840020629,
  },
  {
    name: "Lompoc",
    state_id: "CA",
    lat: 34.6618,
    lng: -120.4714,
    id: 1840020468,
  },
  {
    name: "Kentwood",
    state_id: "MI",
    lat: 42.8852,
    lng: -85.5925,
    id: 1840002930,
  },
  {
    name: "Levittown",
    state_id: "PA",
    lat: 40.1537,
    lng: -74.853,
    id: 1840005408,
  },
  {
    name: "Sierra Vista",
    state_id: "AZ",
    lat: 31.563,
    lng: -110.3153,
    id: 1840022103,
  },
  {
    name: "Levittown",
    state_id: "NY",
    lat: 40.7241,
    lng: -73.5125,
    id: 1840005270,
  },
  {
    name: "Madison",
    state_id: "AL",
    lat: 34.7114,
    lng: -86.7626,
    id: 1840015524,
  },
  {
    name: "Smyrna",
    state_id: "TN",
    lat: 35.9722,
    lng: -86.5253,
    id: 1840017758,
  },
  {
    name: "Glendora",
    state_id: "CA",
    lat: 34.1449,
    lng: -117.8468,
    id: 1840020484,
  },
  {
    name: "Burien",
    state_id: "WA",
    lat: 47.4762,
    lng: -122.3394,
    id: 1840018420,
  },
  {
    name: "Perth Amboy",
    state_id: "NJ",
    lat: 40.5203,
    lng: -74.2724,
    id: 1840001340,
  },
  {
    name: "Herriman",
    state_id: "UT",
    lat: 40.4899,
    lng: -112.0171,
    id: 1840020155,
  },
  {
    name: "Farmington",
    state_id: "NM",
    lat: 36.7555,
    lng: -108.1823,
    id: 1840020349,
  },
  {
    name: "New Bern",
    state_id: "NC",
    lat: 35.0955,
    lng: -77.0723,
    id: 1840014591,
  },
  {
    name: "Placentia",
    state_id: "CA",
    lat: 33.8807,
    lng: -117.8553,
    id: 1840020583,
  },
  {
    name: "Hinesville",
    state_id: "GA",
    lat: 31.8247,
    lng: -81.6135,
    id: 1840014931,
  },
  {
    name: "Beaumont",
    state_id: "CA",
    lat: 33.9076,
    lng: -116.9766,
    id: 1840019298,
  },
  {
    name: "Collierville",
    state_id: "TN",
    lat: 35.0471,
    lng: -89.6988,
    id: 1840016403,
  },
  {
    name: "West Lafayette",
    state_id: "IN",
    lat: 40.4432,
    lng: -86.9239,
    id: 1840010430,
  },
  {
    name: "Florissant",
    state_id: "MO",
    lat: 38.7996,
    lng: -90.3269,
    id: 1840008586,
  },
  {
    name: "Hoffman Estates",
    state_id: "IL",
    lat: 42.0639,
    lng: -88.1468,
    id: 1840011263,
  },
  {
    name: "Queen Creek",
    state_id: "AZ",
    lat: 33.2386,
    lng: -111.6343,
    id: 1840021944,
  },
  {
    name: "Aliso Viejo",
    state_id: "CA",
    lat: 33.5792,
    lng: -117.7289,
    id: 1840019321,
  },
  {
    name: "Kannapolis",
    state_id: "NC",
    lat: 35.4764,
    lng: -80.6403,
    id: 1840014560,
  },
  {
    name: "Methuen Town",
    state_id: "MA",
    lat: 42.734,
    lng: -71.1889,
    id: 1840031195,
  },
  {
    name: "Sahuarita",
    state_id: "AZ",
    lat: 31.9323,
    lng: -110.9654,
    id: 1840022102,
  },
  {
    name: "Traverse City",
    state_id: "MI",
    lat: 44.7547,
    lng: -85.6035,
    id: 1840003946,
  },
  {
    name: "Severn",
    state_id: "MD",
    lat: 39.1355,
    lng: -76.6958,
    id: 1840005925,
  },
  {
    name: "Catalina Foothills",
    state_id: "AZ",
    lat: 32.3041,
    lng: -110.8835,
    id: 1840018110,
  },
  {
    name: "Galveston",
    state_id: "TX",
    lat: 29.2487,
    lng: -94.891,
    id: 1840020970,
  },
  {
    name: "Country Club",
    state_id: "FL",
    lat: 25.9407,
    lng: -80.3102,
    id: 1840029029,
  },
  {
    name: "Cookeville",
    state_id: "TN",
    lat: 36.1484,
    lng: -85.5114,
    id: 1840014470,
  },
  {
    name: "Mishawaka",
    state_id: "IN",
    lat: 41.6736,
    lng: -86.1669,
    id: 1840014695,
  },
  {
    name: "Rowland Heights",
    state_id: "CA",
    lat: 33.9716,
    lng: -117.8911,
    id: 1840019211,
  },
  {
    name: "Plainfield",
    state_id: "NJ",
    lat: 40.6154,
    lng: -74.4157,
    id: 1840001090,
  },
  {
    name: "Bullhead City",
    state_id: "AZ",
    lat: 35.1205,
    lng: -114.546,
    id: 1840019748,
  },
  {
    name: "Stillwater",
    state_id: "OK",
    lat: 36.1316,
    lng: -97.074,
    id: 1840021675,
  },
  {
    name: "Forney",
    state_id: "TX",
    lat: 32.744,
    lng: -96.4529,
    id: 1840020734,
  },
  {
    name: "Wheaton",
    state_id: "MD",
    lat: 39.0492,
    lng: -77.0572,
    id: 1840031295,
  },
  {
    name: "Azusa",
    state_id: "CA",
    lat: 34.1386,
    lng: -117.9124,
    id: 1840019226,
  },
  {
    name: "North Bethesda",
    state_id: "MD",
    lat: 39.0393,
    lng: -77.1191,
    id: 1840031494,
  },
  {
    name: "Cerritos",
    state_id: "CA",
    lat: 33.8677,
    lng: -118.0686,
    id: 1840019215,
  },
  {
    name: "Ashburn",
    state_id: "VA",
    lat: 39.03,
    lng: -77.4711,
    id: 1840024495,
  },
  {
    name: "Bloomsburg",
    state_id: "PA",
    lat: 41.0027,
    lng: -76.4561,
    id: 1840000811,
  },
  {
    name: "Morehead City",
    state_id: "NC",
    lat: 34.7308,
    lng: -76.7387,
    id: 1840016482,
  },
  {
    name: "Coral Gables",
    state_id: "FL",
    lat: 25.7037,
    lng: -80.2715,
    id: 1840015147,
  },
  {
    name: "Enid",
    state_id: "OK",
    lat: 36.4061,
    lng: -97.8701,
    id: 1840020373,
  },
  {
    name: "Lakewood",
    state_id: "OH",
    lat: 41.4824,
    lng: -81.8008,
    id: 1840000608,
  },
  {
    name: "Oroville",
    state_id: "CA",
    lat: 39.4999,
    lng: -121.5634,
    id: 1840020182,
  },
  {
    name: "Wilson",
    state_id: "NC",
    lat: 35.7312,
    lng: -77.9284,
    id: 1840015383,
  },
  {
    name: "Lawrence",
    state_id: "IN",
    lat: 39.8674,
    lng: -85.9905,
    id: 1840008434,
  },
  {
    name: "Portage",
    state_id: "MI",
    lat: 42.2,
    lng: -85.5906,
    id: 1840003183,
  },
  {
    name: "Minot",
    state_id: "ND",
    lat: 48.2375,
    lng: -101.2781,
    id: 1840000053,
  },
  {
    name: "Dunwoody",
    state_id: "GA",
    lat: 33.9418,
    lng: -84.3122,
    id: 1840022929,
  },
  {
    name: "Poway",
    state_id: "CA",
    lat: 32.9871,
    lng: -117.0201,
    id: 1840020624,
  },
  {
    name: "San Jacinto",
    state_id: "CA",
    lat: 33.797,
    lng: -116.9916,
    id: 1840021936,
  },
  {
    name: "Troy",
    state_id: "NY",
    lat: 42.7354,
    lng: -73.6751,
    id: 1840003087,
  },
  {
    name: "Newark",
    state_id: "CA",
    lat: 37.5204,
    lng: -122.0312,
    id: 1840020295,
  },
  {
    name: "Cuyahoga Falls",
    state_id: "OH",
    lat: 41.1641,
    lng: -81.5207,
    id: 1840000795,
  },
  {
    name: "Downers Grove",
    state_id: "IL",
    lat: 41.7949,
    lng: -88.017,
    id: 1840011404,
  },
  {
    name: "Bedford",
    state_id: "TX",
    lat: 32.8464,
    lng: -97.135,
    id: 1840019424,
  },
  {
    name: "Reedley",
    state_id: "CA",
    lat: 36.5987,
    lng: -119.4473,
    id: 1840020320,
  },
  {
    name: "Dublin",
    state_id: "OH",
    lat: 40.1112,
    lng: -83.1454,
    id: 1840008389,
  },
  {
    name: "Marana",
    state_id: "AZ",
    lat: 32.4355,
    lng: -111.1558,
    id: 1840023057,
  },
  {
    name: "Cypress",
    state_id: "CA",
    lat: 33.8171,
    lng: -118.0386,
    id: 1840019327,
  },
  {
    name: "Hollister",
    state_id: "CA",
    lat: 36.8563,
    lng: -121.3981,
    id: 1840020354,
  },
  {
    name: "Murray",
    state_id: "UT",
    lat: 40.6498,
    lng: -111.8874,
    id: 1840020159,
  },
  {
    name: "Roswell",
    state_id: "NM",
    lat: 33.373,
    lng: -104.5294,
    id: 1840020549,
  },
  {
    name: "Pascagoula",
    state_id: "MS",
    lat: 30.3665,
    lng: -88.5507,
    id: 1840015018,
  },
  {
    name: "Tuckahoe",
    state_id: "VA",
    lat: 37.5878,
    lng: -77.5858,
    id: 1840006395,
  },
  {
    name: "Ceres",
    state_id: "CA",
    lat: 37.5953,
    lng: -120.9625,
    id: 1840018910,
  },
  {
    name: "St. Louis Park",
    state_id: "MN",
    lat: 44.9488,
    lng: -93.3649,
    id: 1840008935,
  },
  {
    name: "Rio Grande City",
    state_id: "TX",
    lat: 26.3808,
    lng: -98.8215,
    id: 1840021016,
  },
  {
    name: "Draper",
    state_id: "UT",
    lat: 40.4957,
    lng: -111.8605,
    id: 1840018747,
  },
  {
    name: "Palm Springs",
    state_id: "CA",
    lat: 33.8017,
    lng: -116.5382,
    id: 1840020556,
  },
  {
    name: "Paducah",
    state_id: "KY",
    lat: 37.0711,
    lng: -88.6436,
    id: 1840014388,
  },
  {
    name: "Findlay",
    state_id: "OH",
    lat: 41.0467,
    lng: -83.6379,
    id: 1840000886,
  },
  {
    name: "University",
    state_id: "FL",
    lat: 28.0771,
    lng: -82.4335,
    id: 1840073852,
  },
  {
    name: "Bel Air South",
    state_id: "MD",
    lat: 39.5051,
    lng: -76.3197,
    id: 1840073592,
  },
  {
    name: "Antelope",
    state_id: "CA",
    lat: 38.7153,
    lng: -121.361,
    id: 1840022490,
  },
  {
    name: "Kyle",
    state_id: "TX",
    lat: 29.9937,
    lng: -97.8859,
    id: 1840020907,
  },
  {
    name: "Monroe",
    state_id: "MI",
    lat: 41.9154,
    lng: -83.385,
    id: 1840003987,
  },
  {
    name: "Lincoln",
    state_id: "CA",
    lat: 38.8759,
    lng: -121.2916,
    id: 1840020231,
  },
  {
    name: "Ocoee",
    state_id: "FL",
    lat: 28.5787,
    lng: -81.5338,
    id: 1840015098,
  },
  {
    name: "Burleson",
    state_id: "TX",
    lat: 32.517,
    lng: -97.3343,
    id: 1840019467,
  },
  {
    name: "La Mirada",
    state_id: "CA",
    lat: 33.9025,
    lng: -118.0093,
    id: 1840020512,
  },
  {
    name: "Farmers Branch",
    state_id: "TX",
    lat: 32.9272,
    lng: -96.8804,
    id: 1840020706,
  },
  {
    name: "East Lansing",
    state_id: "MI",
    lat: 42.748,
    lng: -84.4835,
    id: 1840003101,
  },
  {
    name: "Uniontown",
    state_id: "PA",
    lat: 39.8994,
    lng: -79.7244,
    id: 1840000671,
  },
  {
    name: "Jeffersonville",
    state_id: "IN",
    lat: 38.3376,
    lng: -85.7026,
    id: 1840008642,
  },
  {
    name: "Winder",
    state_id: "GA",
    lat: 33.9917,
    lng: -83.7218,
    id: 1840015623,
  },
  {
    name: "Wauwatosa",
    state_id: "WI",
    lat: 43.0615,
    lng: -88.0347,
    id: 1840003033,
  },
  {
    name: "Littleton",
    state_id: "CO",
    lat: 39.5915,
    lng: -105.0188,
    id: 1840020213,
  },
  {
    name: "Cumberland",
    state_id: "MD",
    lat: 39.6515,
    lng: -78.7585,
    id: 1840005621,
  },
  {
    name: "Cedar Hill",
    state_id: "TX",
    lat: 32.581,
    lng: -96.9591,
    id: 1840019433,
  },
  {
    name: "Carrollton",
    state_id: "GA",
    lat: 33.5817,
    lng: -85.0837,
    id: 1840014801,
  },
  {
    name: "Rancho Santa Margarita",
    state_id: "CA",
    lat: 33.6318,
    lng: -117.5989,
    id: 1840020584,
  },
  {
    name: "Beavercreek",
    state_id: "OH",
    lat: 39.731,
    lng: -84.0624,
    id: 1840007312,
  },
  {
    name: "Niagara Falls",
    state_id: "NY",
    lat: 43.0921,
    lng: -79.0147,
    id: 1840000371,
  },
  {
    name: "McLean",
    state_id: "VA",
    lat: 38.9436,
    lng: -77.1943,
    id: 1840006018,
  },
  {
    name: "North Highlands",
    state_id: "CA",
    lat: 38.6713,
    lng: -121.3721,
    id: 1840018841,
  },
  {
    name: "East Providence",
    state_id: "RI",
    lat: 41.8065,
    lng: -71.3565,
    id: 1840003288,
  },
  {
    name: "Florin",
    state_id: "CA",
    lat: 38.4832,
    lng: -121.4042,
    id: 1840017581,
  },
  {
    name: "East Honolulu",
    state_id: "HI",
    lat: 21.2975,
    lng: -157.7211,
    id: 1840039181,
  },
  {
    name: "Chesterfield",
    state_id: "MO",
    lat: 38.6588,
    lng: -90.5804,
    id: 1840007446,
  },
  {
    name: "Covina",
    state_id: "CA",
    lat: 34.0903,
    lng: -117.8817,
    id: 1840019241,
  },
  {
    name: "Bothell",
    state_id: "WA",
    lat: 47.7735,
    lng: -122.2044,
    id: 1840018419,
  },
  {
    name: "Glenview",
    state_id: "IL",
    lat: 42.0825,
    lng: -87.8216,
    id: 1840011280,
  },
  {
    name: "Mentor",
    state_id: "OH",
    lat: 41.6895,
    lng: -81.3361,
    id: 1840000539,
  },
  {
    name: "Keller",
    state_id: "TX",
    lat: 32.9337,
    lng: -97.2255,
    id: 1840019428,
  },
  {
    name: "Danville",
    state_id: "IL",
    lat: 40.1423,
    lng: -87.6114,
    id: 1840007235,
  },
  {
    name: "Spring Hill",
    state_id: "TN",
    lat: 35.7438,
    lng: -86.9116,
    id: 1840015386,
  },
  {
    name: "Roseville",
    state_id: "MI",
    lat: 42.5074,
    lng: -82.9369,
    id: 1840003092,
  },
  {
    name: "Elmhurst",
    state_id: "IL",
    lat: 41.8973,
    lng: -87.9432,
    id: 1840008142,
  },
  {
    name: "New Philadelphia",
    state_id: "OH",
    lat: 40.486,
    lng: -81.4402,
    id: 1840001281,
  },
  {
    name: "Sandusky",
    state_id: "OH",
    lat: 41.4437,
    lng: -82.7149,
    id: 1840003433,
  },
  {
    name: "El Dorado Hills",
    state_id: "CA",
    lat: 38.675,
    lng: -121.049,
    id: 1840017565,
  },
  {
    name: "Salina",
    state_id: "KS",
    lat: 38.8137,
    lng: -97.6143,
    id: 1840001647,
  },
  {
    name: "Euclid",
    state_id: "OH",
    lat: 41.5904,
    lng: -81.5188,
    id: 1840000600,
  },
  {
    name: "Kingman",
    state_id: "AZ",
    lat: 35.217,
    lng: -114.0105,
    id: 1840021043,
  },
  {
    name: "Everett",
    state_id: "MA",
    lat: 42.4064,
    lng: -71.0545,
    id: 1840000425,
  },
  {
    name: "Farmington",
    state_id: "MO",
    lat: 37.7821,
    lng: -90.4288,
    id: 1840008686,
  },
  {
    name: "Weatherford",
    state_id: "TX",
    lat: 32.7536,
    lng: -97.7723,
    id: 1840022041,
  },
  {
    name: "Middletown",
    state_id: "CT",
    lat: 41.5476,
    lng: -72.6549,
    id: 1840004844,
  },
  {
    name: "Roseburg",
    state_id: "OR",
    lat: 43.2232,
    lng: -123.3518,
    id: 1840020034,
  },
  {
    name: "Biloxi",
    state_id: "MS",
    lat: 30.4422,
    lng: -88.9512,
    id: 1840013948,
  },
  {
    name: "Leavenworth",
    state_id: "KS",
    lat: 39.3239,
    lng: -94.924,
    id: 1840001591,
  },
  {
    name: "Fort Pierce",
    state_id: "FL",
    lat: 27.4256,
    lng: -80.3431,
    id: 1840014178,
  },
  {
    name: "Winter Garden",
    state_id: "FL",
    lat: 28.5421,
    lng: -81.5966,
    id: 1840015965,
  },
  {
    name: "Oro Valley",
    state_id: "AZ",
    lat: 32.4208,
    lng: -110.9768,
    id: 1840023058,
  },
  {
    name: "Morgan Hill",
    state_id: "CA",
    lat: 37.1326,
    lng: -121.6417,
    id: 1840020331,
  },
  {
    name: "Potomac",
    state_id: "MD",
    lat: 39.0141,
    lng: -77.1943,
    id: 1840005837,
  },
  {
    name: "Pinehurst",
    state_id: "NC",
    lat: 35.1922,
    lng: -79.4684,
    id: 1840017852,
  },
  {
    name: "Danville",
    state_id: "VA",
    lat: 36.5831,
    lng: -79.4087,
    id: 1840003878,
  },
  {
    name: "Rockwall",
    state_id: "TX",
    lat: 32.9169,
    lng: -96.4377,
    id: 1840020717,
  },
  {
    name: "Coachella",
    state_id: "CA",
    lat: 33.6905,
    lng: -116.143,
    id: 1840019294,
  },
  {
    name: "Wake Forest",
    state_id: "NC",
    lat: 35.9629,
    lng: -78.5141,
    id: 1840016199,
  },
  {
    name: "Pearl City",
    state_id: "HI",
    lat: 21.4031,
    lng: -157.9566,
    id: 1840023251,
  },
  {
    name: "Tupelo",
    state_id: "MS",
    lat: 34.2692,
    lng: -88.7318,
    id: 1840015561,
  },
  {
    name: "Attleboro",
    state_id: "MA",
    lat: 41.9311,
    lng: -71.295,
    id: 1840000496,
  },
  {
    name: "Pine Bluff",
    state_id: "AR",
    lat: 34.2116,
    lng: -92.0178,
    id: 1840014699,
  },
  {
    name: "Oakland Park",
    state_id: "FL",
    lat: 26.178,
    lng: -80.1528,
    id: 1840015139,
  },
  {
    name: "Marion",
    state_id: "OH",
    lat: 40.5933,
    lng: -83.1237,
    id: 1840008332,
  },
  {
    name: "Hilo",
    state_id: "HI",
    lat: 19.6886,
    lng: -155.0864,
    id: 1840023193,
  },
  {
    name: "Lufkin",
    state_id: "TX",
    lat: 31.3217,
    lng: -94.7277,
    id: 1840020840,
  },
  {
    name: "Altadena",
    state_id: "CA",
    lat: 34.1928,
    lng: -118.1345,
    id: 1840017918,
  },
  {
    name: "Manitowoc",
    state_id: "WI",
    lat: 44.0991,
    lng: -87.6811,
    id: 1840002213,
  },
  {
    name: "Lake Ridge",
    state_id: "VA",
    lat: 38.6843,
    lng: -77.3059,
    id: 1840006099,
  },
  {
    name: "Selma",
    state_id: "CA",
    lat: 36.5715,
    lng: -119.6143,
    id: 1840021567,
  },
  {
    name: "Strongsville",
    state_id: "OH",
    lat: 41.3128,
    lng: -81.8313,
    id: 1840003415,
  },
  {
    name: "Danville",
    state_id: "CA",
    lat: 37.8121,
    lng: -121.9698,
    id: 1840021532,
  },
  {
    name: "Quincy",
    state_id: "IL",
    lat: 39.9335,
    lng: -91.3799,
    id: 1840009466,
  },
  {
    name: "Lancaster",
    state_id: "OH",
    lat: 39.7249,
    lng: -82.6049,
    id: 1840003781,
  },
  {
    name: "Riverton",
    state_id: "UT",
    lat: 40.5177,
    lng: -111.9635,
    id: 1840020157,
  },
  {
    name: "The Colony",
    state_id: "TX",
    lat: 33.0925,
    lng: -96.8977,
    id: 1840022005,
  },
  {
    name: "Urbandale",
    state_id: "IA",
    lat: 41.639,
    lng: -93.7813,
    id: 1840010184,
  },
  {
    name: "Plainfield",
    state_id: "IL",
    lat: 41.6206,
    lng: -88.2252,
    id: 1840011495,
  },
  {
    name: "Lombard",
    state_id: "IL",
    lat: 41.8742,
    lng: -88.0157,
    id: 1840011402,
  },
  {
    name: "Prescott",
    state_id: "AZ",
    lat: 34.585,
    lng: -112.4475,
    id: 1840020436,
  },
  {
    name: "North Lauderdale",
    state_id: "FL",
    lat: 26.2113,
    lng: -80.2209,
    id: 1840015138,
  },
  {
    name: "Eureka",
    state_id: "CA",
    lat: 40.7942,
    lng: -124.1568,
    id: 1840009558,
  },
  {
    name: "Hackensack",
    state_id: "NJ",
    lat: 40.889,
    lng: -74.0461,
    id: 1840003556,
  },
  {
    name: "Sayreville",
    state_id: "NJ",
    lat: 40.4656,
    lng: -74.3237,
    id: 1840001332,
  },
  {
    name: "Altamonte Springs",
    state_id: "FL",
    lat: 28.6615,
    lng: -81.3953,
    id: 1840014072,
  },
  {
    name: "Del Rio",
    state_id: "TX",
    lat: 29.3708,
    lng: -100.88,
    id: 1840019607,
  },
  {
    name: "Cleveland Heights",
    state_id: "OH",
    lat: 41.5112,
    lng: -81.5636,
    id: 1840000597,
  },
  {
    name: "Bountiful",
    state_id: "UT",
    lat: 40.8722,
    lng: -111.8647,
    id: 1840018737,
  },
  {
    name: "Desert Hot Springs",
    state_id: "CA",
    lat: 33.9551,
    lng: -116.543,
    id: 1840019306,
  },
  {
    name: "Peachtree Corners",
    state_id: "GA",
    lat: 33.967,
    lng: -84.2319,
    id: 1840029447,
  },
  {
    name: "Haltom City",
    state_id: "TX",
    lat: 32.8176,
    lng: -97.2707,
    id: 1840020698,
  },
  {
    name: "Ormond Beach",
    state_id: "FL",
    lat: 29.296,
    lng: -81.1003,
    id: 1840015076,
  },
  {
    name: "Cutler Bay",
    state_id: "FL",
    lat: 25.5765,
    lng: -80.3356,
    id: 1840017276,
  },
  {
    name: "Trujillo Alto",
    state_id: "PR",
    lat: 18.3601,
    lng: -66.0103,
    id: 1630023656,
  },
  {
    name: "Adrian",
    state_id: "MI",
    lat: 41.8994,
    lng: -84.0446,
    id: 1840003226,
  },
  {
    name: "Goose Creek",
    state_id: "SC",
    lat: 32.9925,
    lng: -80.0054,
    id: 1840013761,
  },
  {
    name: "Moorhead",
    state_id: "MN",
    lat: 46.8673,
    lng: -96.7461,
    id: 1840007731,
  },
  {
    name: "Westfield",
    state_id: "IN",
    lat: 40.0333,
    lng: -86.1532,
    id: 1840010500,
  },
  {
    name: "Concord",
    state_id: "NH",
    lat: 43.2305,
    lng: -71.5595,
    id: 1840002747,
  },
  {
    name: "Annandale",
    state_id: "VA",
    lat: 38.8324,
    lng: -77.196,
    id: 1840006011,
  },
  {
    name: "Rohnert Park",
    state_id: "CA",
    lat: 38.348,
    lng: -122.6964,
    id: 1840020243,
  },
  {
    name: "Brea",
    state_id: "CA",
    lat: 33.9254,
    lng: -117.8656,
    id: 1840019323,
  },
  {
    name: "Klamath Falls",
    state_id: "OR",
    lat: 42.2191,
    lng: -121.7754,
    id: 1840020054,
  },
  {
    name: "Salem",
    state_id: "MA",
    lat: 42.5129,
    lng: -70.902,
    id: 1840000410,
  },
  {
    name: "Channelview",
    state_id: "TX",
    lat: 29.7914,
    lng: -95.1145,
    id: 1840018253,
  },
  {
    name: "North Miami Beach",
    state_id: "FL",
    lat: 25.9302,
    lng: -80.166,
    id: 1840015155,
  },
  {
    name: "Whitney",
    state_id: "NV",
    lat: 36.1005,
    lng: -115.038,
    id: 1840033834,
  },
  {
    name: "Freeport",
    state_id: "NY",
    lat: 40.6515,
    lng: -73.585,
    id: 1840005288,
  },
  {
    name: "Centralia",
    state_id: "WA",
    lat: 46.7226,
    lng: -122.9696,
    id: 1840018471,
  },
  {
    name: "Gallatin",
    state_id: "TN",
    lat: 36.3782,
    lng: -86.4696,
    id: 1840013295,
  },
  {
    name: "Stevens Point",
    state_id: "WI",
    lat: 44.5241,
    lng: -89.5508,
    id: 1840002320,
  },
  {
    name: "San Bruno",
    state_id: "CA",
    lat: 37.6254,
    lng: -122.4313,
    id: 1840021549,
  },
  {
    name: "Burke",
    state_id: "VA",
    lat: 38.7773,
    lng: -77.2633,
    id: 1840006014,
  },
  {
    name: "Brentwood",
    state_id: "TN",
    lat: 35.9918,
    lng: -86.7758,
    id: 1840013381,
  },
  {
    name: "North Fort Myers",
    state_id: "FL",
    lat: 26.7243,
    lng: -81.8491,
    id: 1840014222,
  },
  {
    name: "Hicksville",
    state_id: "NY",
    lat: 40.7637,
    lng: -73.5245,
    id: 1840005266,
  },
  {
    name: "West Babylon",
    state_id: "NY",
    lat: 40.7112,
    lng: -73.3567,
    id: 1840005109,
  },
  {
    name: "Edmonds",
    state_id: "WA",
    lat: 47.8115,
    lng: -122.3533,
    id: 1840037645,
  },
  {
    name: "Apache Junction",
    state_id: "AZ",
    lat: 33.3985,
    lng: -111.5351,
    id: 1840019365,
  },
  {
    name: "Fairfield",
    state_id: "OH",
    lat: 39.3301,
    lng: -84.5409,
    id: 1840003802,
  },
  {
    name: "Oakley",
    state_id: "CA",
    lat: 37.9929,
    lng: -121.6951,
    id: 1840020279,
  },
  {
    name: "Hutchinson",
    state_id: "KS",
    lat: 38.0671,
    lng: -97.9081,
    id: 1840001673,
  },
  {
    name: "Greenwood",
    state_id: "SC",
    lat: 34.1945,
    lng: -82.1542,
    id: 1840013626,
  },
  {
    name: "Richmond",
    state_id: "IN",
    lat: 39.8318,
    lng: -84.8905,
    id: 1840014176,
  },
  {
    name: "Linden",
    state_id: "NJ",
    lat: 40.6251,
    lng: -74.2381,
    id: 1840001094,
  },
  {
    name: "Puyallup",
    state_id: "WA",
    lat: 47.1794,
    lng: -122.2902,
    id: 1840019859,
  },
  {
    name: "Huntsville",
    state_id: "TX",
    lat: 30.705,
    lng: -95.5544,
    id: 1840019565,
  },
  {
    name: "Urbana",
    state_id: "IL",
    lat: 40.1106,
    lng: -88.1972,
    id: 1840010451,
  },
  {
    name: "Beverly",
    state_id: "MA",
    lat: 42.5681,
    lng: -70.8627,
    id: 1840000411,
  },
  {
    name: "Clovis",
    state_id: "NM",
    lat: 34.4376,
    lng: -103.1923,
    id: 1840019195,
  },
  {
    name: "Statesboro",
    state_id: "GA",
    lat: 32.4376,
    lng: -81.775,
    id: 1840015801,
  },
  {
    name: "Hobbs",
    state_id: "NM",
    lat: 32.7282,
    lng: -103.16,
    id: 1840020616,
  },
  {
    name: "McMinnville",
    state_id: "OR",
    lat: 45.211,
    lng: -123.1918,
    id: 1840019956,
  },
  {
    name: "Schertz",
    state_id: "TX",
    lat: 29.5649,
    lng: -98.2537,
    id: 1840022213,
  },
  {
    name: "Bell Gardens",
    state_id: "CA",
    lat: 33.9663,
    lng: -118.155,
    id: 1840019229,
  },
  {
    name: "Oviedo",
    state_id: "FL",
    lat: 28.658,
    lng: -81.1872,
    id: 1840015089,
  },
  {
    name: "Odenton",
    state_id: "MD",
    lat: 39.0661,
    lng: -76.6939,
    id: 1840005912,
  },
  {
    name: "Grove City",
    state_id: "OH",
    lat: 39.8659,
    lng: -83.0694,
    id: 1840007264,
  },
  {
    name: "Campbell",
    state_id: "CA",
    lat: 37.2802,
    lng: -121.9538,
    id: 1840018949,
  },
  {
    name: "Wentzville",
    state_id: "MO",
    lat: 38.8152,
    lng: -90.8667,
    id: 1840010717,
  },
  {
    name: "Woonsocket",
    state_id: "RI",
    lat: 42.001,
    lng: -71.4993,
    id: 1840003290,
  },
  {
    name: "La Quinta",
    state_id: "CA",
    lat: 33.6536,
    lng: -116.2785,
    id: 1840020559,
  },
  {
    name: "Weslaco",
    state_id: "TX",
    lat: 26.1648,
    lng: -97.9898,
    id: 1840022258,
  },
  {
    name: "Zanesville",
    state_id: "OH",
    lat: 39.9565,
    lng: -82.0132,
    id: 1840001496,
  },
  {
    name: "Newnan",
    state_id: "GA",
    lat: 33.3766,
    lng: -84.7761,
    id: 1840014833,
  },
  {
    name: "Shakopee",
    state_id: "MN",
    lat: 44.7744,
    lng: -93.4764,
    id: 1840008955,
  },
  {
    name: "Brighton",
    state_id: "CO",
    lat: 39.9716,
    lng: -104.7964,
    id: 1840018784,
  },
  {
    name: "Catonsville",
    state_id: "MD",
    lat: 39.2646,
    lng: -76.7424,
    id: 1840005679,
  },
  {
    name: "Rancho Palos Verdes",
    state_id: "CA",
    lat: 33.7554,
    lng: -118.3637,
    id: 1840020508,
  },
  {
    name: "Mableton",
    state_id: "GA",
    lat: 33.8132,
    lng: -84.5656,
    id: 1840013690,
  },
  {
    name: "Coppell",
    state_id: "TX",
    lat: 32.9638,
    lng: -96.9905,
    id: 1840019439,
  },
  {
    name: "Linton Hall",
    state_id: "VA",
    lat: 38.7551,
    lng: -77.575,
    id: 1840006100,
  },
  {
    name: "Moline",
    state_id: "IL",
    lat: 41.4821,
    lng: -90.4921,
    id: 1840008181,
  },
  {
    name: "Delaware",
    state_id: "OH",
    lat: 40.2866,
    lng: -83.0747,
    id: 1840007237,
  },
  {
    name: "Westfield",
    state_id: "MA",
    lat: 42.1382,
    lng: -72.7561,
    id: 1840000463,
  },
  {
    name: "Shelton",
    state_id: "CT",
    lat: 41.306,
    lng: -73.1383,
    id: 1840004840,
  },
  {
    name: "Greenacres",
    state_id: "FL",
    lat: 26.6272,
    lng: -80.1371,
    id: 1840036057,
  },
  {
    name: "Westerville",
    state_id: "OH",
    lat: 40.1241,
    lng: -82.9209,
    id: 1840003763,
  },
  {
    name: "Manassas",
    state_id: "VA",
    lat: 38.7479,
    lng: -77.4839,
    id: 1840003839,
  },
  {
    name: "Kearny",
    state_id: "NJ",
    lat: 40.7528,
    lng: -74.1202,
    id: 1840003601,
  },
  {
    name: "Los Banos",
    state_id: "CA",
    lat: 37.063,
    lng: -120.8406,
    id: 1840020312,
  },
  {
    name: "Richmond",
    state_id: "KY",
    lat: 37.7306,
    lng: -84.2926,
    id: 1840014341,
  },
  {
    name: "Spanish Fork",
    state_id: "UT",
    lat: 40.1101,
    lng: -111.6406,
    id: 1840021398,
  },
  {
    name: "Belleville",
    state_id: "IL",
    lat: 38.5165,
    lng: -89.99,
    id: 1840007486,
  },
  {
    name: "Maplewood",
    state_id: "MN",
    lat: 44.9842,
    lng: -93.0247,
    id: 1840008937,
  },
  {
    name: "Wilkes-Barre",
    state_id: "PA",
    lat: 41.2468,
    lng: -75.8759,
    id: 1840000777,
  },
  {
    name: "Pottsville",
    state_id: "PA",
    lat: 40.6798,
    lng: -76.2092,
    id: 1840002775,
  },
  {
    name: "Columbia",
    state_id: "TN",
    lat: 35.6235,
    lng: -87.0486,
    id: 1840014518,
  },
  {
    name: "Bartlett",
    state_id: "IL",
    lat: 41.9803,
    lng: -88.2069,
    id: 1840011246,
  },
  {
    name: "Bluefield",
    state_id: "WV",
    lat: 37.2608,
    lng: -81.2143,
    id: 1840006401,
  },
  {
    name: "Fitchburg",
    state_id: "MA",
    lat: 42.5912,
    lng: -71.8156,
    id: 1840000435,
  },
  {
    name: "Marion",
    state_id: "IN",
    lat: 40.5496,
    lng: -85.66,
    id: 1840013868,
  },
  {
    name: "Cedar Falls",
    state_id: "IA",
    lat: 42.5195,
    lng: -92.4534,
    id: 1840006946,
  },
  {
    name: "Buffalo Grove",
    state_id: "IL",
    lat: 42.1673,
    lng: -87.9616,
    id: 1840010143,
  },
  {
    name: "Jamestown",
    state_id: "NY",
    lat: 42.0975,
    lng: -79.2366,
    id: 1840000446,
  },
  {
    name: "Woodlawn",
    state_id: "MD",
    lat: 39.3054,
    lng: -76.7489,
    id: 1840005697,
  },
  {
    name: "Clarksburg",
    state_id: "WV",
    lat: 39.2863,
    lng: -80.323,
    id: 1840005767,
  },
  {
    name: "Royal Palm Beach",
    state_id: "FL",
    lat: 26.7038,
    lng: -80.2241,
    id: 1840018309,
  },
  {
    name: "Marion",
    state_id: "IA",
    lat: 42.045,
    lng: -91.5846,
    id: 1840008099,
  },
  {
    name: "Covington",
    state_id: "KY",
    lat: 39.0334,
    lng: -84.5166,
    id: 1840014263,
  },
  {
    name: "Coram",
    state_id: "NY",
    lat: 40.8813,
    lng: -73.0059,
    id: 1840005032,
  },
  {
    name: "Friendswood",
    state_id: "TX",
    lat: 29.5111,
    lng: -95.1979,
    id: 1840020969,
  },
  {
    name: "Indian Trail",
    state_id: "NC",
    lat: 35.0698,
    lng: -80.6457,
    id: 1840016453,
  },
  {
    name: "Woburn",
    state_id: "MA",
    lat: 42.4869,
    lng: -71.1543,
    id: 1840003124,
  },
  {
    name: "The Acreage",
    state_id: "FL",
    lat: 26.7741,
    lng: -80.2779,
    id: 1840026061,
  },
  {
    name: "Holyoke",
    state_id: "MA",
    lat: 42.2125,
    lng: -72.6411,
    id: 1840000465,
  },
  {
    name: "Montclair",
    state_id: "CA",
    lat: 34.0714,
    lng: -117.698,
    id: 1840020408,
  },
  {
    name: "South Valley",
    state_id: "NM",
    lat: 35.0092,
    lng: -106.6819,
    id: 1840033724,
  },
  {
    name: "Torrington",
    state_id: "CT",
    lat: 41.8349,
    lng: -73.1281,
    id: 1840004770,
  },
  {
    name: "Cedar City",
    state_id: "UT",
    lat: 37.6834,
    lng: -113.0956,
    id: 1840018895,
  },
  {
    name: "San Gabriel",
    state_id: "CA",
    lat: 34.0948,
    lng: -118.099,
    id: 1840021862,
  },
  {
    name: "Hallandale Beach",
    state_id: "FL",
    lat: 25.9854,
    lng: -80.1423,
    id: 1840014237,
  },
  {
    name: "Crystal Lake",
    state_id: "IL",
    lat: 42.2333,
    lng: -88.3351,
    id: 1840006969,
  },
  {
    name: "Calexico",
    state_id: "CA",
    lat: 32.6849,
    lng: -115.4944,
    id: 1840019379,
  },
  {
    name: "Lake Oswego",
    state_id: "OR",
    lat: 45.413,
    lng: -122.7003,
    id: 1840019955,
  },
  {
    name: "Amherst",
    state_id: "MA",
    lat: 42.3645,
    lng: -72.5069,
    id: 1840053454,
  },
  {
    name: "Muskogee",
    state_id: "OK",
    lat: 35.7431,
    lng: -95.3568,
    id: 1840020401,
  },
  {
    name: "Romeoville",
    state_id: "IL",
    lat: 41.6318,
    lng: -88.0996,
    id: 1840011489,
  },
  {
    name: "Plant City",
    state_id: "FL",
    lat: 28.014,
    lng: -82.1201,
    id: 1840015115,
  },
  {
    name: "Keizer",
    state_id: "OR",
    lat: 45.0029,
    lng: -123.0241,
    id: 1840018581,
  },
  {
    name: "New Berlin",
    state_id: "WI",
    lat: 42.9726,
    lng: -88.1291,
    id: 1840003025,
  },
  {
    name: "Chelsea",
    state_id: "MA",
    lat: 42.3959,
    lng: -71.0325,
    id: 1840000456,
  },
  {
    name: "La Puente",
    state_id: "CA",
    lat: 34.0323,
    lng: -117.9533,
    id: 1840020513,
  },
  {
    name: "Roy",
    state_id: "UT",
    lat: 41.1715,
    lng: -112.0485,
    id: 1840020132,
  },
  {
    name: "Security-Widefield",
    state_id: "CO",
    lat: 38.7489,
    lng: -104.7142,
    id: 1840074635,
  },
  {
    name: "Marlborough",
    state_id: "MA",
    lat: 42.3494,
    lng: -71.5468,
    id: 1840000427,
  },
  {
    name: "Milton",
    state_id: "GA",
    lat: 34.1353,
    lng: -84.3138,
    id: 1840014738,
  },
  {
    name: "Issaquah",
    state_id: "WA",
    lat: 47.544,
    lng: -122.0471,
    id: 1840018422,
  },
  {
    name: "Waipahu",
    state_id: "HI",
    lat: 21.3858,
    lng: -158.0103,
    id: 1840023258,
  },
  {
    name: "Essex",
    state_id: "MD",
    lat: 39.3024,
    lng: -76.4457,
    id: 1840005675,
  },
  {
    name: "Bartlesville",
    state_id: "OK",
    lat: 36.7357,
    lng: -95.9453,
    id: 1840018977,
  },
  {
    name: "Lancaster",
    state_id: "TX",
    lat: 32.5922,
    lng: -96.7737,
    id: 1840020714,
  },
  {
    name: "Streamwood",
    state_id: "IL",
    lat: 42.0209,
    lng: -88.1778,
    id: 1840011328,
  },
  {
    name: "Germantown",
    state_id: "TN",
    lat: 35.0829,
    lng: -89.7825,
    id: 1840013473,
  },
  {
    name: "Annapolis",
    state_id: "MD",
    lat: 38.9706,
    lng: -76.5047,
    id: 1840005928,
  },
  {
    name: "Carol Stream",
    state_id: "IL",
    lat: 41.9182,
    lng: -88.1308,
    id: 1840010169,
  },
  {
    name: "Asheboro",
    state_id: "NC",
    lat: 35.7158,
    lng: -79.8129,
    id: 1840013400,
  },
  {
    name: "Culver City",
    state_id: "CA",
    lat: 34.0058,
    lng: -118.3968,
    id: 1840019243,
  },
  {
    name: "Kalispell",
    state_id: "MT",
    lat: 48.2156,
    lng: -114.3262,
    id: 1840018350,
  },
  {
    name: "Arecibo",
    state_id: "PR",
    lat: 18.4491,
    lng: -66.7387,
    id: 1630023566,
  },
  {
    name: "Lynnwood",
    state_id: "WA",
    lat: 47.8284,
    lng: -122.3033,
    id: 1840019788,
  },
  {
    name: "Mooresville",
    state_id: "NC",
    lat: 35.5849,
    lng: -80.8265,
    id: 1840016215,
  },
  {
    name: "Brookfield",
    state_id: "WI",
    lat: 43.064,
    lng: -88.1231,
    id: 1840003012,
  },
  {
    name: "Crestview",
    state_id: "FL",
    lat: 30.748,
    lng: -86.5784,
    id: 1840015007,
  },
  {
    name: "Horizon West",
    state_id: "FL",
    lat: 28.4417,
    lng: -81.6145,
    id: 1840038926,
  },
  {
    name: "Clinton",
    state_id: "MD",
    lat: 38.7499,
    lng: -76.9063,
    id: 1840005949,
  },
  {
    name: "Moses Lake",
    state_id: "WA",
    lat: 47.128,
    lng: -119.2761,
    id: 1840019817,
  },
  {
    name: "New Castle",
    state_id: "PA",
    lat: 40.9956,
    lng: -80.3459,
    id: 1840000938,
  },
  {
    name: "Olive Branch",
    state_id: "MS",
    lat: 34.961,
    lng: -89.8469,
    id: 1840014641,
  },
  {
    name: "Enterprise",
    state_id: "AL",
    lat: 31.3276,
    lng: -85.8459,
    id: 1840001548,
  },
  {
    name: "Sun City",
    state_id: "AZ",
    lat: 33.6165,
    lng: -112.2819,
    id: 1840019311,
  },
  {
    name: "Frankfort",
    state_id: "KY",
    lat: 38.1924,
    lng: -84.8643,
    id: 1840013210,
  },
  {
    name: "Northglenn",
    state_id: "CO",
    lat: 39.9108,
    lng: -104.9783,
    id: 1840020192,
  },
  {
    name: "Warren",
    state_id: "OH",
    lat: 41.239,
    lng: -80.8174,
    id: 1840000652,
  },
  {
    name: "Duncanville",
    state_id: "TX",
    lat: 32.646,
    lng: -96.9127,
    id: 1840019442,
  },
  {
    name: "Hurst",
    state_id: "TX",
    lat: 32.8353,
    lng: -97.1808,
    id: 1840019427,
  },
  {
    name: "Clermont",
    state_id: "FL",
    lat: 28.5402,
    lng: -81.7259,
    id: 1840015080,
  },
  {
    name: "Wheeling",
    state_id: "IL",
    lat: 42.1308,
    lng: -87.924,
    id: 1840011334,
  },
  {
    name: "Fort Lee",
    state_id: "NJ",
    lat: 40.8509,
    lng: -73.9712,
    id: 1840003552,
  },
  {
    name: "Pacifica",
    state_id: "CA",
    lat: 37.6112,
    lng: -122.4781,
    id: 1840020307,
  },
  {
    name: "Lake Worth",
    state_id: "FL",
    lat: 26.6196,
    lng: -80.0591,
    id: 1840015127,
  },
  {
    name: "Lebanon",
    state_id: "TN",
    lat: 36.204,
    lng: -86.3481,
    id: 1840014466,
  },
  {
    name: "Kendall West",
    state_id: "FL",
    lat: 25.7065,
    lng: -80.4388,
    id: 1840035805,
  },
  {
    name: "Land O' Lakes",
    state_id: "FL",
    lat: 28.2075,
    lng: -82.4476,
    id: 1840014120,
  },
  {
    name: "Eagle Mountain",
    state_id: "UT",
    lat: 40.3137,
    lng: -112.0114,
    id: 1840018757,
  },
  {
    name: "Reynoldsburg",
    state_id: "OH",
    lat: 39.9587,
    lng: -82.7944,
    id: 1840009473,
  },
  {
    name: "Oxford",
    state_id: "MS",
    lat: 34.3627,
    lng: -89.5336,
    id: 1840014688,
  },
  {
    name: "Rosenberg",
    state_id: "TX",
    lat: 29.546,
    lng: -95.822,
    id: 1840020948,
  },
  {
    name: "Martinez",
    state_id: "CA",
    lat: 37.9985,
    lng: -122.116,
    id: 1840020278,
  },
  {
    name: "Pleasant Grove",
    state_id: "UT",
    lat: 40.3716,
    lng: -111.7412,
    id: 1840020173,
  },
  {
    name: "La Presa",
    state_id: "CA",
    lat: 32.711,
    lng: -117.0027,
    id: 1840018023,
  },
  {
    name: "Severna Park",
    state_id: "MD",
    lat: 39.087,
    lng: -76.5687,
    id: 1840005926,
  },
  {
    name: "Huber Heights",
    state_id: "OH",
    lat: 39.8595,
    lng: -84.113,
    id: 1840003782,
  },
  {
    name: "Stanton",
    state_id: "CA",
    lat: 33.8002,
    lng: -117.9935,
    id: 1840021966,
  },
  {
    name: "East Meadow",
    state_id: "NY",
    lat: 40.7197,
    lng: -73.5604,
    id: 1840005236,
  },
  {
    name: "Menomonee Falls",
    state_id: "WI",
    lat: 43.1487,
    lng: -88.1227,
    id: 1840003020,
  },
  {
    name: "Granbury",
    state_id: "TX",
    lat: 32.4484,
    lng: -97.7685,
    id: 1840020749,
  },
  {
    name: "Waxahachie",
    state_id: "TX",
    lat: 32.4032,
    lng: -96.8444,
    id: 1840022083,
  },
  {
    name: "Valrico",
    state_id: "FL",
    lat: 27.9193,
    lng: -82.2293,
    id: 1840028790,
  },
  {
    name: "Mission Bend",
    state_id: "TX",
    lat: 29.6948,
    lng: -95.6657,
    id: 1840019643,
  },
  {
    name: "Holly Springs",
    state_id: "NC",
    lat: 35.653,
    lng: -78.8397,
    id: 1840016193,
  },
  {
    name: "Butler",
    state_id: "PA",
    lat: 40.8616,
    lng: -79.8962,
    id: 1840000857,
  },
  {
    name: "Albertville",
    state_id: "AL",
    lat: 34.2634,
    lng: -86.2107,
    id: 1840013599,
  },
  {
    name: "London",
    state_id: "KY",
    lat: 37.1175,
    lng: -84.0767,
    id: 1840015258,
  },
  {
    name: "Egypt Lake-Leto",
    state_id: "FL",
    lat: 28.0177,
    lng: -82.5062,
    id: 1840073269,
  },
  {
    name: "Cottage Grove",
    state_id: "MN",
    lat: 44.8161,
    lng: -92.9274,
    id: 1840006735,
  },
  {
    name: "Kailua",
    state_id: "HI",
    lat: 21.392,
    lng: -157.7396,
    id: 1840023244,
  },
  {
    name: "St. Marys",
    state_id: "GA",
    lat: 30.7567,
    lng: -81.5722,
    id: 1840015888,
  },
  {
    name: "Mechanicsville",
    state_id: "VA",
    lat: 37.6262,
    lng: -77.3561,
    id: 1840006340,
  },
  {
    name: "Richmond West",
    state_id: "FL",
    lat: 25.6105,
    lng: -80.4297,
    id: 1840073270,
  },
  {
    name: "Cleburne",
    state_id: "TX",
    lat: 32.357,
    lng: -97.4152,
    id: 1840019464,
  },
  {
    name: "Valley Stream",
    state_id: "NY",
    lat: 40.6647,
    lng: -73.7044,
    id: 1840005331,
  },
  {
    name: "Hanover Park",
    state_id: "IL",
    lat: 41.9819,
    lng: -88.1447,
    id: 1840011251,
  },
  {
    name: "Oswego",
    state_id: "NY",
    lat: 43.4516,
    lng: -76.5005,
    id: 1840000348,
  },
  {
    name: "Navarre",
    state_id: "FL",
    lat: 30.4174,
    lng: -86.8907,
    id: 1840027017,
  },
  {
    name: "Oregon City",
    state_id: "OR",
    lat: 45.3415,
    lng: -122.5927,
    id: 1840019954,
  },
  {
    name: "Winter Springs",
    state_id: "FL",
    lat: 28.6889,
    lng: -81.2704,
    id: 1840015959,
  },
  {
    name: "Parkland",
    state_id: "WA",
    lat: 47.1417,
    lng: -122.4376,
    id: 1840018453,
  },
  {
    name: "Pahrump",
    state_id: "NV",
    lat: 36.2411,
    lng: -116.0176,
    id: 1840018821,
  },
  {
    name: "Carpentersville",
    state_id: "IL",
    lat: 42.1227,
    lng: -88.2895,
    id: 1840010149,
  },
  {
    name: "Wildomar",
    state_id: "CA",
    lat: 33.6173,
    lng: -117.2582,
    id: 1840022902,
  },
  {
    name: "Greenfield",
    state_id: "WI",
    lat: 42.9619,
    lng: -88.0051,
    id: 1840003039,
  },
  {
    name: "Braintree",
    state_id: "MA",
    lat: 42.2039,
    lng: -71.0022,
    id: 1840132398,
  },
  {
    name: "Rock Island",
    state_id: "IL",
    lat: 41.4699,
    lng: -90.5827,
    id: 1840009233,
  },
  {
    name: "South Miami Heights",
    state_id: "FL",
    lat: 25.5886,
    lng: -80.3862,
    id: 1840014240,
  },
  {
    name: "Evans",
    state_id: "GA",
    lat: 33.5619,
    lng: -82.1351,
    id: 1840013095,
  },
  {
    name: "West Fargo",
    state_id: "ND",
    lat: 46.8573,
    lng: -96.9057,
    id: 1840001935,
  },
  {
    name: "Mount Juliet",
    state_id: "TN",
    lat: 36.1991,
    lng: -86.5114,
    id: 1840014465,
  },
  {
    name: "San Juan",
    state_id: "TX",
    lat: 26.1903,
    lng: -98.152,
    id: 1840022256,
  },
  {
    name: "Oakton",
    state_id: "VA",
    lat: 38.8887,
    lng: -77.3016,
    id: 1840006023,
  },
  {
    name: "Georgetown",
    state_id: "KY",
    lat: 38.2249,
    lng: -84.5482,
    id: 1840013197,
  },
  {
    name: "Portage",
    state_id: "IN",
    lat: 41.5866,
    lng: -87.1792,
    id: 1840009264,
  },
  {
    name: "Aventura",
    state_id: "FL",
    lat: 25.9565,
    lng: -80.1372,
    id: 1840014243,
  },
  {
    name: "Owasso",
    state_id: "OK",
    lat: 36.2882,
    lng: -95.8328,
    id: 1840020385,
  },
  {
    name: "Commack",
    state_id: "NY",
    lat: 40.8443,
    lng: -73.2834,
    id: 1840005030,
  },
  {
    name: "Park Ridge",
    state_id: "IL",
    lat: 42.0125,
    lng: -87.8436,
    id: 1840009183,
  },
  {
    name: "Foley",
    state_id: "AL",
    lat: 30.3983,
    lng: -87.6649,
    id: 1840002084,
  },
  {
    name: "New Albany",
    state_id: "IN",
    lat: 38.3089,
    lng: -85.8234,
    id: 1840008662,
  },
  {
    name: "Benton",
    state_id: "AR",
    lat: 34.5775,
    lng: -92.5712,
    id: 1840013572,
  },
  {
    name: "Meridian",
    state_id: "MS",
    lat: 32.3846,
    lng: -88.6896,
    id: 1840014891,
  },
  {
    name: "Bettendorf",
    state_id: "IA",
    lat: 41.5656,
    lng: -90.4764,
    id: 1840007079,
  },
  {
    name: "Dunedin",
    state_id: "FL",
    lat: 28.0329,
    lng: -82.7863,
    id: 1840014144,
  },
  {
    name: "Hilliard",
    state_id: "OH",
    lat: 40.0353,
    lng: -83.1578,
    id: 1840007265,
  },
  {
    name: "Merritt Island",
    state_id: "FL",
    lat: 28.3146,
    lng: -80.6713,
    id: 1840014076,
  },
  {
    name: "Phenix City",
    state_id: "AL",
    lat: 32.4588,
    lng: -85.0251,
    id: 1840006012,
  },
  {
    name: "Addison",
    state_id: "IL",
    lat: 41.9314,
    lng: -88.0085,
    id: 1840010171,
  },
  {
    name: "West Hollywood",
    state_id: "CA",
    lat: 34.0882,
    lng: -118.3718,
    id: 1840028098,
  },
  {
    name: "Roseville",
    state_id: "MN",
    lat: 45.0155,
    lng: -93.1545,
    id: 1840008939,
  },
  {
    name: "Oakville",
    state_id: "MO",
    lat: 38.4472,
    lng: -90.3199,
    id: 1840006121,
  },
  {
    name: "Tucker",
    state_id: "GA",
    lat: 33.8436,
    lng: -84.2021,
    id: 1840013701,
  },
  {
    name: "Moorpark",
    state_id: "CA",
    lat: 34.2855,
    lng: -118.877,
    id: 1840020472,
  },
  {
    name: "Richfield",
    state_id: "MN",
    lat: 44.8762,
    lng: -93.2833,
    id: 1840008930,
  },
  {
    name: "Monrovia",
    state_id: "CA",
    lat: 34.165,
    lng: -117.9921,
    id: 1840020498,
  },
  {
    name: "Kearns",
    state_id: "UT",
    lat: 40.652,
    lng: -112.0093,
    id: 1840017500,
  },
  {
    name: "Oak Creek",
    state_id: "WI",
    lat: 42.8803,
    lng: -87.9009,
    id: 1840003047,
  },
  {
    name: "Lincoln Park",
    state_id: "MI",
    lat: 42.2432,
    lng: -83.1811,
    id: 1840003984,
  },
  {
    name: "Claremont",
    state_id: "CA",
    lat: 34.1259,
    lng: -117.7153,
    id: 1840019216,
  },
  {
    name: "Oswego",
    state_id: "IL",
    lat: 41.6834,
    lng: -88.3372,
    id: 1840011508,
  },
  {
    name: "Post Falls",
    state_id: "ID",
    lat: 47.7213,
    lng: -116.9385,
    id: 1840019811,
  },
  {
    name: "Peachtree City",
    state_id: "GA",
    lat: 33.3943,
    lng: -84.5712,
    id: 1840014822,
  },
  {
    name: "Lauderdale Lakes",
    state_id: "FL",
    lat: 26.1682,
    lng: -80.2017,
    id: 1840015145,
  },
  {
    name: "French Valley",
    state_id: "CA",
    lat: 33.5998,
    lng: -117.1069,
    id: 1840075867,
  },
  {
    name: "Portsmouth",
    state_id: "OH",
    lat: 38.7539,
    lng: -82.9446,
    id: 1840001646,
  },
  {
    name: "Shawnee",
    state_id: "OK",
    lat: 35.3529,
    lng: -96.965,
    id: 1840021785,
  },
  {
    name: "Gillette",
    state_id: "WY",
    lat: 44.2752,
    lng: -105.4983,
    id: 1840019978,
  },
  {
    name: "Tooele",
    state_id: "UT",
    lat: 40.5393,
    lng: -112.3082,
    id: 1840021356,
  },
  {
    name: "Prattville",
    state_id: "AL",
    lat: 32.4605,
    lng: -86.4588,
    id: 1840004086,
  },
  {
    name: "Woodburn",
    state_id: "OR",
    lat: 45.1473,
    lng: -122.8603,
    id: 1840021230,
  },
  {
    name: "Watertown Town",
    state_id: "MA",
    lat: 42.37,
    lng: -71.1774,
    id: 1840031198,
  },
  {
    name: "Calumet City",
    state_id: "IL",
    lat: 41.6134,
    lng: -87.5505,
    id: 1840007023,
  },
  {
    name: "San Juan Capistrano",
    state_id: "CA",
    lat: 33.5009,
    lng: -117.6544,
    id: 1840021963,
  },
  {
    name: "Foothill Farms",
    state_id: "CA",
    lat: 38.6867,
    lng: -121.3475,
    id: 1840017582,
  },
  {
    name: "Elmont",
    state_id: "NY",
    lat: 40.7033,
    lng: -73.7078,
    id: 1840005226,
  },
  {
    name: "Franklin",
    state_id: "WI",
    lat: 42.8854,
    lng: -88.0104,
    id: 1840003043,
  },
  {
    name: "Temple City",
    state_id: "CA",
    lat: 34.1021,
    lng: -118.0579,
    id: 1840021872,
  },
  {
    name: "Cooper City",
    state_id: "FL",
    lat: 26.0463,
    lng: -80.2862,
    id: 1840015133,
  },
  {
    name: "El Mirage",
    state_id: "AZ",
    lat: 33.5905,
    lng: -112.3271,
    id: 1840020562,
  },
  {
    name: "Meadow Woods",
    state_id: "FL",
    lat: 28.3698,
    lng: -81.3467,
    id: 1840029057,
  },
  {
    name: "LaGrange",
    state_id: "GA",
    lat: 33.0274,
    lng: -85.0384,
    id: 1840029147,
  },
  {
    name: "La Vergne",
    state_id: "TN",
    lat: 36.0203,
    lng: -86.5582,
    id: 1840014496,
  },
  {
    name: "Middletown",
    state_id: "DE",
    lat: 39.4453,
    lng: -75.7166,
    id: 1840005581,
  },
  {
    name: "Mount Pleasant",
    state_id: "MI",
    lat: 43.5966,
    lng: -84.7759,
    id: 1840002663,
  },
  {
    name: "Carrollwood",
    state_id: "FL",
    lat: 28.0577,
    lng: -82.5149,
    id: 1840029026,
  },
  {
    name: "Junction City",
    state_id: "KS",
    lat: 39.0271,
    lng: -96.8496,
    id: 1840001620,
  },
  {
    name: "Inver Grove Heights",
    state_id: "MN",
    lat: 44.8247,
    lng: -93.0596,
    id: 1840007858,
  },
  {
    name: "Chillum",
    state_id: "MD",
    lat: 38.9667,
    lng: -76.979,
    id: 1840005948,
  },
  {
    name: "Monroe",
    state_id: "NC",
    lat: 35.0061,
    lng: -80.5595,
    id: 1840014613,
  },
  {
    name: "Randallstown",
    state_id: "MD",
    lat: 39.3723,
    lng: -76.8024,
    id: 1840005688,
  },
  {
    name: "Bell",
    state_id: "CA",
    lat: 33.9801,
    lng: -118.1798,
    id: 1840019228,
  },
  {
    name: "Gahanna",
    state_id: "OH",
    lat: 40.0251,
    lng: -82.8637,
    id: 1840008390,
  },
  {
    name: "Sanford",
    state_id: "NC",
    lat: 35.4874,
    lng: -79.1772,
    id: 1840015421,
  },
  {
    name: "Riviera Beach",
    state_id: "FL",
    lat: 26.7812,
    lng: -80.0742,
    id: 1840015126,
  },
  {
    name: "Wildwood",
    state_id: "MO",
    lat: 38.5799,
    lng: -90.6699,
    id: 1840010746,
  },
  {
    name: "Upper Arlington",
    state_id: "OH",
    lat: 40.0272,
    lng: -83.0704,
    id: 1840003762,
  },
  {
    name: "Auburn",
    state_id: "CA",
    lat: 38.895,
    lng: -121.0778,
    id: 1840010231,
  },
  {
    name: "Plainfield",
    state_id: "IN",
    lat: 39.6953,
    lng: -86.3717,
    id: 1840010579,
  },
  {
    name: "Westmont",
    state_id: "CA",
    lat: 33.9417,
    lng: -118.3018,
    id: 1840028408,
  },
  {
    name: "Oak Harbor",
    state_id: "WA",
    lat: 48.2965,
    lng: -122.6333,
    id: 1840019779,
  },
  {
    name: "Olney",
    state_id: "MD",
    lat: 39.1465,
    lng: -77.0715,
    id: 1840005836,
  },
  {
    name: "Manhattan Beach",
    state_id: "CA",
    lat: 33.8895,
    lng: -118.3972,
    id: 1840020496,
  },
  {
    name: "New City",
    state_id: "NY",
    lat: 41.1543,
    lng: -73.9909,
    id: 1840004964,
  },
  {
    name: "Owings Mills",
    state_id: "MD",
    lat: 39.4115,
    lng: -76.7913,
    id: 1840005684,
  },
  {
    name: "La Porte",
    state_id: "TX",
    lat: 29.6689,
    lng: -95.0484,
    id: 1840020932,
  },
  {
    name: "Englewood",
    state_id: "CO",
    lat: 39.6468,
    lng: -104.9942,
    id: 1840020211,
  },
  {
    name: "Brunswick",
    state_id: "OH",
    lat: 41.2464,
    lng: -81.8198,
    id: 1840003485,
  },
  {
    name: "East Point",
    state_id: "GA",
    lat: 33.6696,
    lng: -84.4701,
    id: 1840013661,
  },
  {
    name: "DeLand",
    state_id: "FL",
    lat: 29.0224,
    lng: -81.2873,
    id: 1840015071,
  },
  {
    name: "Martinez",
    state_id: "GA",
    lat: 33.5209,
    lng: -82.0985,
    id: 1840013747,
  },
  {
    name: "Pleasant Hill",
    state_id: "CA",
    lat: 37.954,
    lng: -122.0759,
    id: 1840020283,
  },
  {
    name: "Orangevale",
    state_id: "CA",
    lat: 38.6881,
    lng: -121.2209,
    id: 1840018842,
  },
  {
    name: "Merrillville",
    state_id: "IN",
    lat: 41.4728,
    lng: -87.3197,
    id: 1840009261,
  },
  {
    name: "Stow",
    state_id: "OH",
    lat: 41.1765,
    lng: -81.4344,
    id: 1840000803,
  },
  {
    name: "Buenaventura Lakes",
    state_id: "FL",
    lat: 28.3349,
    lng: -81.3539,
    id: 1840028974,
  },
  {
    name: "San Luis",
    state_id: "AZ",
    lat: 32.4911,
    lng: -114.7089,
    id: 1840021996,
  },
  {
    name: "Oildale",
    state_id: "CA",
    lat: 35.4249,
    lng: -119.0279,
    id: 1840019137,
  },
  {
    name: "Calhoun",
    state_id: "GA",
    lat: 34.4918,
    lng: -84.9391,
    id: 1840013596,
  },
  {
    name: "Leawood",
    state_id: "KS",
    lat: 38.9076,
    lng: -94.6258,
    id: 1840001638,
  },
  {
    name: "Menlo Park",
    state_id: "CA",
    lat: 37.4686,
    lng: -122.1672,
    id: 1840020305,
  },
  {
    name: "Sun Prairie",
    state_id: "WI",
    lat: 43.1827,
    lng: -89.2358,
    id: 1840002902,
  },
  {
    name: "Dakota Ridge",
    state_id: "CO",
    lat: 39.6192,
    lng: -105.1344,
    id: 1840038683,
  },
  {
    name: "Arcata",
    state_id: "CA",
    lat: 40.8615,
    lng: -124.0757,
    id: 1840009422,
  },
  {
    name: "Lockport",
    state_id: "NY",
    lat: 43.1698,
    lng: -78.6956,
    id: 1840000370,
  },
  {
    name: "Pullman",
    state_id: "WA",
    lat: 46.734,
    lng: -117.1669,
    id: 1840019864,
  },
  {
    name: "Boardman",
    state_id: "OH",
    lat: 41.0334,
    lng: -80.6671,
    id: 1840005186,
  },
  {
    name: "Nacogdoches",
    state_id: "TX",
    lat: 31.6134,
    lng: -94.6528,
    id: 1840020820,
  },
  {
    name: "Kearney",
    state_id: "NE",
    lat: 40.701,
    lng: -99.0834,
    id: 1840000957,
  },
  {
    name: "Vestavia Hills",
    state_id: "AL",
    lat: 33.4518,
    lng: -86.7438,
    id: 1840015673,
  },
  {
    name: "North Ridgeville",
    state_id: "OH",
    lat: 41.3851,
    lng: -82.0194,
    id: 1840000645,
  },
  {
    name: "Socorro",
    state_id: "TX",
    lat: 31.6384,
    lng: -106.2601,
    id: 1840022136,
  },
  {
    name: "Randolph",
    state_id: "MA",
    lat: 42.1778,
    lng: -71.0539,
    id: 1840004675,
  },
  {
    name: "Norristown",
    state_id: "PA",
    lat: 40.1224,
    lng: -75.3398,
    id: 1840003705,
  },
  {
    name: "Montgomery Village",
    state_id: "MD",
    lat: 39.1783,
    lng: -77.1957,
    id: 1840005834,
  },
  {
    name: "Redmond",
    state_id: "OR",
    lat: 44.2631,
    lng: -121.1798,
    id: 1840020003,
  },
  {
    name: "Firestone",
    state_id: "CO",
    lat: 40.1563,
    lng: -104.9494,
    id: 1840021363,
  },
  {
    name: "Goshen",
    state_id: "IN",
    lat: 41.5743,
    lng: -85.8308,
    id: 1840013733,
  },
  {
    name: "Butte",
    state_id: "MT",
    lat: 45.902,
    lng: -112.6571,
    id: 1840032850,
  },
  {
    name: "Russellville",
    state_id: "AR",
    lat: 35.2763,
    lng: -93.1383,
    id: 1840014541,
  },
  {
    name: "Grass Valley",
    state_id: "CA",
    lat: 39.2237,
    lng: -121.0521,
    id: 1840020225,
  },
  {
    name: "Parkland",
    state_id: "FL",
    lat: 26.3218,
    lng: -80.2533,
    id: 1840015140,
  },
  {
    name: "University City",
    state_id: "MO",
    lat: 38.6657,
    lng: -90.3315,
    id: 1840010739,
  },
  {
    name: "Midvale",
    state_id: "UT",
    lat: 40.6148,
    lng: -111.8928,
    id: 1840020158,
  },
  {
    name: "Golden Glades",
    state_id: "FL",
    lat: 25.9129,
    lng: -80.2013,
    id: 1840029039,
  },
  {
    name: "Franklin",
    state_id: "MA",
    lat: 42.0862,
    lng: -71.4113,
    id: 1840031178,
  },
  {
    name: "Kennesaw",
    state_id: "GA",
    lat: 34.026,
    lng: -84.6177,
    id: 1840014756,
  },
  {
    name: "Adelanto",
    state_id: "CA",
    lat: 34.5815,
    lng: -117.4397,
    id: 1840019117,
  },
  {
    name: "Ken Caryl",
    state_id: "CO",
    lat: 39.577,
    lng: -105.1144,
    id: 1840028580,
  },
  {
    name: "Carlsbad",
    state_id: "NM",
    lat: 32.4011,
    lng: -104.2389,
    id: 1840019444,
  },
  {
    name: "University Place",
    state_id: "WA",
    lat: 47.2147,
    lng: -122.5461,
    id: 1840021130,
  },
  {
    name: "Chester",
    state_id: "PA",
    lat: 39.8456,
    lng: -75.3718,
    id: 1840000690,
  },
  {
    name: "Douglasville",
    state_id: "GA",
    lat: 33.7384,
    lng: -84.7065,
    id: 1840014804,
  },
  {
    name: "Salisbury",
    state_id: "NC",
    lat: 35.6656,
    lng: -80.4909,
    id: 1840015384,
  },
  {
    name: "Laramie",
    state_id: "WY",
    lat: 41.3099,
    lng: -105.6085,
    id: 1840020096,
  },
  {
    name: "Lake Stevens",
    state_id: "WA",
    lat: 48.0028,
    lng: -122.096,
    id: 1840019794,
  },
  {
    name: "Foster City",
    state_id: "CA",
    lat: 37.5553,
    lng: -122.2659,
    id: 1840020302,
  },
  {
    name: "Valparaiso",
    state_id: "IN",
    lat: 41.4782,
    lng: -87.0507,
    id: 1840014674,
  },
  {
    name: "Fairborn",
    state_id: "OH",
    lat: 39.801,
    lng: -84.0093,
    id: 1840008440,
  },
  {
    name: "Estero",
    state_id: "FL",
    lat: 26.4277,
    lng: -81.7951,
    id: 1840013129,
  },
  {
    name: "Mason",
    state_id: "OH",
    lat: 39.3571,
    lng: -84.3023,
    id: 1840008467,
  },
  {
    name: "Cottonwood Heights",
    state_id: "UT",
    lat: 40.6137,
    lng: -111.8144,
    id: 1840018746,
  },
  {
    name: "Kaneohe",
    state_id: "HI",
    lat: 21.4062,
    lng: -157.7904,
    id: 1840029498,
  },
  {
    name: "Sonoma",
    state_id: "CA",
    lat: 38.2902,
    lng: -122.4598,
    id: 1840021490,
  },
  {
    name: "Beverly Hills",
    state_id: "CA",
    lat: 34.0786,
    lng: -118.4021,
    id: 1840019231,
  },
  {
    name: "Galesburg",
    state_id: "IL",
    lat: 40.9506,
    lng: -90.3763,
    id: 1840008277,
  },
  {
    name: "Warsaw",
    state_id: "IN",
    lat: 41.2448,
    lng: -85.8464,
    id: 1840010277,
  },
  {
    name: "San Dimas",
    state_id: "CA",
    lat: 34.1082,
    lng: -117.809,
    id: 1840021860,
  },
  {
    name: "Glendale Heights",
    state_id: "IL",
    lat: 41.9196,
    lng: -88.0785,
    id: 1840011406,
  },
  {
    name: "Dana Point",
    state_id: "CA",
    lat: 33.4733,
    lng: -117.6968,
    id: 1840019328,
  },
  {
    name: "Midlothian",
    state_id: "TX",
    lat: 32.4668,
    lng: -96.9892,
    id: 1840020757,
  },
  {
    name: "Newark",
    state_id: "DE",
    lat: 39.6776,
    lng: -75.7573,
    id: 1840005578,
  },
  {
    name: "Alamogordo",
    state_id: "NM",
    lat: 32.8837,
    lng: -105.9624,
    id: 1840019403,
  },
  {
    name: "Alabaster",
    state_id: "AL",
    lat: 33.2198,
    lng: -86.8225,
    id: 1840013756,
  },
  {
    name: "Pikesville",
    state_id: "MD",
    lat: 39.3893,
    lng: -76.702,
    id: 1840005687,
  },
  {
    name: "Fair Oaks",
    state_id: "VA",
    lat: 38.8653,
    lng: -77.3586,
    id: 1840024568,
  },
  {
    name: "Deer Park",
    state_id: "TX",
    lat: 29.6898,
    lng: -95.1151,
    id: 1840019621,
  },
  {
    name: "Long Beach",
    state_id: "NY",
    lat: 40.5887,
    lng: -73.666,
    id: 1840000988,
  },
  {
    name: "Woodridge",
    state_id: "IL",
    lat: 41.7368,
    lng: -88.0408,
    id: 1840011411,
  },
  {
    name: "Bridgeton",
    state_id: "NJ",
    lat: 39.4286,
    lng: -75.2281,
    id: 1840016610,
  },
  {
    name: "Greer",
    state_id: "SC",
    lat: 34.9313,
    lng: -82.2315,
    id: 1840013502,
  },
  {
    name: "Springville",
    state_id: "UT",
    lat: 40.1638,
    lng: -111.6206,
    id: 1840021399,
  },
  {
    name: "Saratoga Springs",
    state_id: "UT",
    lat: 40.3449,
    lng: -111.9152,
    id: 1840021397,
  },
  {
    name: "Copperas Cove",
    state_id: "TX",
    lat: 31.1192,
    lng: -97.914,
    id: 1840019538,
  },
  {
    name: "American Fork",
    state_id: "UT",
    lat: 40.3792,
    lng: -111.7952,
    id: 1840018756,
  },
  {
    name: "Franklin",
    state_id: "NJ",
    lat: 41.1101,
    lng: -74.5886,
    id: 1840003480,
  },
  {
    name: "Andover",
    state_id: "MN",
    lat: 45.2571,
    lng: -93.3265,
    id: 1840006716,
  },
  {
    name: "Matthews",
    state_id: "NC",
    lat: 35.1195,
    lng: -80.7101,
    id: 1840016350,
  },
  {
    name: "Woodstock",
    state_id: "GA",
    lat: 34.1026,
    lng: -84.509,
    id: 1840015574,
  },
  {
    name: "Florence",
    state_id: "KY",
    lat: 38.99,
    lng: -84.647,
    id: 1840013161,
  },
  {
    name: "Northbrook",
    state_id: "IL",
    lat: 42.1292,
    lng: -87.8353,
    id: 1840011304,
  },
  {
    name: "West Little River",
    state_id: "FL",
    lat: 25.857,
    lng: -80.2367,
    id: 1840029096,
  },
  {
    name: "Fair Lawn",
    state_id: "NJ",
    lat: 40.9359,
    lng: -74.1177,
    id: 1840003548,
  },
  {
    name: "St. Charles",
    state_id: "IL",
    lat: 41.9193,
    lng: -88.3109,
    id: 1840009190,
  },
  {
    name: "Huntington Station",
    state_id: "NY",
    lat: 40.8446,
    lng: -73.405,
    id: 1840005081,
  },
  {
    name: "Fairmont",
    state_id: "WV",
    lat: 39.4768,
    lng: -80.1491,
    id: 1840005732,
  },
  {
    name: "Ridgecrest",
    state_id: "CA",
    lat: 35.6308,
    lng: -117.6621,
    id: 1840020417,
  },
  {
    name: "Massillon",
    state_id: "OH",
    lat: 40.7838,
    lng: -81.5254,
    id: 1840000965,
  },
  {
    name: "Spanaway",
    state_id: "WA",
    lat: 47.0979,
    lng: -122.4233,
    id: 1840018451,
  },
  {
    name: "Starkville",
    state_id: "MS",
    lat: 33.4608,
    lng: -88.8297,
    id: 1840015713,
  },
  {
    name: "Harker Heights",
    state_id: "TX",
    lat: 31.0572,
    lng: -97.6445,
    id: 1840020851,
  },
  {
    name: "Elk Grove Village",
    state_id: "IL",
    lat: 42.0063,
    lng: -87.9921,
    id: 1840011271,
  },
  {
    name: "Kaysville",
    state_id: "UT",
    lat: 41.029,
    lng: -111.9456,
    id: 1840018738,
  },
  {
    name: "Lawndale",
    state_id: "CA",
    lat: 33.8884,
    lng: -118.3531,
    id: 1840020517,
  },
  {
    name: "Southlake",
    state_id: "TX",
    lat: 32.9545,
    lng: -97.1503,
    id: 1840022048,
  },
  {
    name: "Ferry Pass",
    state_id: "FL",
    lat: 30.5203,
    lng: -87.1903,
    id: 1840013909,
  },
  {
    name: "Savage",
    state_id: "MN",
    lat: 44.7544,
    lng: -93.3631,
    id: 1840008957,
  },
  {
    name: "Des Moines",
    state_id: "WA",
    lat: 47.3915,
    lng: -122.3157,
    id: 1840018426,
  },
  {
    name: "Bethel Park",
    state_id: "PA",
    lat: 40.3239,
    lng: -80.0364,
    id: 1840001206,
  },
  {
    name: "Princeton",
    state_id: "FL",
    lat: 25.5396,
    lng: -80.3971,
    id: 1840014248,
  },
  {
    name: "Dania Beach",
    state_id: "FL",
    lat: 26.0594,
    lng: -80.1637,
    id: 1840015135,
  },
  {
    name: "Spring Valley",
    state_id: "NY",
    lat: 41.1151,
    lng: -74.0486,
    id: 1840004986,
  },
  {
    name: "Bowling Green",
    state_id: "OH",
    lat: 41.3773,
    lng: -83.65,
    id: 1840000621,
  },
  {
    name: "Springfield",
    state_id: "VA",
    lat: 38.7809,
    lng: -77.1839,
    id: 1840006039,
  },
  {
    name: "East Lake",
    state_id: "FL",
    lat: 28.1205,
    lng: -82.6868,
    id: 1840028980,
  },
  {
    name: "College Park",
    state_id: "MD",
    lat: 38.996,
    lng: -76.9337,
    id: 1840005971,
  },
  {
    name: "Coos Bay",
    state_id: "OR",
    lat: 43.3789,
    lng: -124.233,
    id: 1840018653,
  },
  {
    name: "Smithfield",
    state_id: "NC",
    lat: 35.5131,
    lng: -78.3497,
    id: 1840017795,
  },
  {
    name: "Rome",
    state_id: "NY",
    lat: 43.226,
    lng: -75.4909,
    id: 1840000349,
  },
  {
    name: "Clearfield",
    state_id: "UT",
    lat: 41.103,
    lng: -112.0237,
    id: 1840018735,
  },
  {
    name: "Liberty",
    state_id: "MO",
    lat: 39.2394,
    lng: -94.4191,
    id: 1840008502,
  },
  {
    name: "Eastpointe",
    state_id: "MI",
    lat: 42.4657,
    lng: -82.9461,
    id: 1840002420,
  },
  {
    name: "Franklin Square",
    state_id: "NY",
    lat: 40.7002,
    lng: -73.6775,
    id: 1840005256,
  },
  {
    name: "Pekin",
    state_id: "IL",
    lat: 40.5679,
    lng: -89.6262,
    id: 1840009402,
  },
  {
    name: "Westlake",
    state_id: "OH",
    lat: 41.4524,
    lng: -81.9295,
    id: 1840003420,
  },
  {
    name: "Uniondale",
    state_id: "NY",
    lat: 40.7176,
    lng: -73.5947,
    id: 1840005279,
  },
  {
    name: "Chillicothe",
    state_id: "OH",
    lat: 39.3393,
    lng: -82.9937,
    id: 1840007369,
  },
  {
    name: "La Verne",
    state_id: "CA",
    lat: 34.1208,
    lng: -117.7702,
    id: 1840020514,
  },
  {
    name: "Juneau",
    state_id: "AK",
    lat: 58.4546,
    lng: -134.1739,
    id: 1840023306,
  },
  {
    name: "Prairieville",
    state_id: "LA",
    lat: 30.3151,
    lng: -90.9571,
    id: 1840026029,
  },
  {
    name: "Placerville",
    state_id: "CA",
    lat: 38.7308,
    lng: -120.7978,
    id: 1840020238,
  },
  {
    name: "Bel Air North",
    state_id: "MD",
    lat: 39.5544,
    lng: -76.3733,
    id: 1840073591,
  },
  {
    name: "Redan",
    state_id: "GA",
    lat: 33.7394,
    lng: -84.1644,
    id: 1840013706,
  },
  {
    name: "Martinsville",
    state_id: "VA",
    lat: 36.6827,
    lng: -79.8636,
    id: 1840001727,
  },
  {
    name: "Pueblo West",
    state_id: "CO",
    lat: 38.3551,
    lng: -104.7266,
    id: 1840018860,
  },
  {
    name: "Garfield",
    state_id: "NJ",
    lat: 40.8791,
    lng: -74.1085,
    id: 1840003554,
  },
  {
    name: "Fallbrook",
    state_id: "CA",
    lat: 33.3693,
    lng: -117.2259,
    id: 1840018019,
  },
  {
    name: "Spring Valley",
    state_id: "CA",
    lat: 32.7316,
    lng: -116.9766,
    id: 1840019343,
  },
  {
    name: "Hopkinsville",
    state_id: "KY",
    lat: 36.8386,
    lng: -87.4776,
    id: 1840014399,
  },
  {
    name: "Rochester",
    state_id: "NH",
    lat: 43.299,
    lng: -70.9787,
    id: 1840002809,
  },
  {
    name: "South Riding",
    state_id: "VA",
    lat: 38.912,
    lng: -77.5132,
    id: 1840024507,
  },
  {
    name: "Centereach",
    state_id: "NY",
    lat: 40.8696,
    lng: -73.0808,
    id: 1840005026,
  },
  {
    name: "Milledgeville",
    state_id: "GA",
    lat: 33.0874,
    lng: -83.2414,
    id: 1840014857,
  },
  {
    name: "Syracuse",
    state_id: "UT",
    lat: 41.086,
    lng: -112.0698,
    id: 1840021352,
  },
  {
    name: "Parkville",
    state_id: "MD",
    lat: 39.3832,
    lng: -76.5519,
    id: 1840005685,
  },
  {
    name: "Sherwood",
    state_id: "AR",
    lat: 34.8508,
    lng: -92.2028,
    id: 1840015511,
  },
  {
    name: "Garner",
    state_id: "NC",
    lat: 35.6934,
    lng: -78.6196,
    id: 1840016198,
  },
  {
    name: "Key West",
    state_id: "FL",
    lat: 24.5637,
    lng: -81.7768,
    id: 1840015161,
  },
  {
    name: "Miami Lakes",
    state_id: "FL",
    lat: 25.9125,
    lng: -80.3214,
    id: 1840017275,
  },
  {
    name: "Petersburg",
    state_id: "VA",
    lat: 37.2043,
    lng: -77.3913,
    id: 1840003861,
  },
  {
    name: "North Olmsted",
    state_id: "OH",
    lat: 41.415,
    lng: -81.919,
    id: 1840003400,
  },
  {
    name: "Kahului",
    state_id: "HI",
    lat: 20.8715,
    lng: -156.4603,
    id: 1840023232,
  },
  {
    name: "Wheat Ridge",
    state_id: "CO",
    lat: 39.7728,
    lng: -105.1029,
    id: 1840021431,
  },
  {
    name: "Fruit Cove",
    state_id: "FL",
    lat: 30.0972,
    lng: -81.6176,
    id: 1840013970,
  },
  {
    name: "Cibolo",
    state_id: "TX",
    lat: 29.5639,
    lng: -98.2123,
    id: 1840019639,
  },
  {
    name: "Alliance",
    state_id: "OH",
    lat: 40.9107,
    lng: -81.1189,
    id: 1840000962,
  },
  {
    name: "Banning",
    state_id: "CA",
    lat: 33.946,
    lng: -116.8992,
    id: 1840019297,
  },
  {
    name: "Laguna Hills",
    state_id: "CA",
    lat: 33.592,
    lng: -117.6992,
    id: 1840020588,
  },
  {
    name: "Central Islip",
    state_id: "NY",
    lat: 40.7837,
    lng: -73.1945,
    id: 1840005028,
  },
  {
    name: "Athens",
    state_id: "AL",
    lat: 34.7847,
    lng: -86.951,
    id: 1840000935,
  },
  {
    name: "Princeton",
    state_id: "NJ",
    lat: 40.3563,
    lng: -74.6693,
    id: 1840001381,
  },
  {
    name: "Orcutt",
    state_id: "CA",
    lat: 34.8668,
    lng: -120.4269,
    id: 1840019183,
  },
  {
    name: "Naugatuck",
    state_id: "CT",
    lat: 41.489,
    lng: -73.0518,
    id: 1840004849,
  },
  {
    name: "Eldersburg",
    state_id: "MD",
    lat: 39.4041,
    lng: -76.9529,
    id: 1840005698,
  },
  {
    name: "Columbus",
    state_id: "MS",
    lat: 33.5088,
    lng: -88.4096,
    id: 1840014807,
  },
  {
    name: "Mundelein",
    state_id: "IL",
    lat: 42.2691,
    lng: -88.0101,
    id: 1840011175,
  },
  {
    name: "Fair Oaks",
    state_id: "CA",
    lat: 38.6504,
    lng: -121.2497,
    id: 1840017580,
  },
  {
    name: "San Pablo",
    state_id: "CA",
    lat: 37.9628,
    lng: -122.3425,
    id: 1840021529,
  },
  {
    name: "Rexburg",
    state_id: "ID",
    lat: 43.8224,
    lng: -111.792,
    id: 1840020037,
  },
  {
    name: "Nogales",
    state_id: "AZ",
    lat: 31.3624,
    lng: -110.9336,
    id: 1840020823,
  },
  {
    name: "Nicholasville",
    state_id: "KY",
    lat: 37.8902,
    lng: -84.5669,
    id: 1840014330,
  },
  {
    name: "Goleta",
    state_id: "CA",
    lat: 34.436,
    lng: -119.8596,
    id: 1840001783,
  },
  {
    name: "Opelika",
    state_id: "AL",
    lat: 32.6612,
    lng: -85.3769,
    id: 1840005210,
  },
  {
    name: "Algonquin",
    state_id: "IL",
    lat: 42.1629,
    lng: -88.3159,
    id: 1840010110,
  },
  {
    name: "Marrero",
    state_id: "LA",
    lat: 29.8871,
    lng: -90.1127,
    id: 1840013993,
  },
  {
    name: "Burlingame",
    state_id: "CA",
    lat: 37.586,
    lng: -122.3669,
    id: 1840018925,
  },
  {
    name: "Aiken",
    state_id: "SC",
    lat: 33.531,
    lng: -81.7268,
    id: 1840013722,
  },
  {
    name: "Lawrenceville",
    state_id: "GA",
    lat: 33.9523,
    lng: -83.9931,
    id: 1840014745,
  },
  {
    name: "Winter Park",
    state_id: "FL",
    lat: 28.5987,
    lng: -81.3438,
    id: 1840015966,
  },
  {
    name: "Lake Magdalene",
    state_id: "FL",
    lat: 28.0875,
    lng: -82.4791,
    id: 1840029049,
  },
  {
    name: "Fitchburg",
    state_id: "WI",
    lat: 42.9859,
    lng: -89.4255,
    id: 1840002905,
  },
  {
    name: "Granger",
    state_id: "IN",
    lat: 41.7373,
    lng: -86.135,
    id: 1840030079,
  },
  {
    name: "Wooster",
    state_id: "OH",
    lat: 40.8171,
    lng: -81.9335,
    id: 1840000961,
  },
  {
    name: "Fountain",
    state_id: "CO",
    lat: 38.6886,
    lng: -104.6829,
    id: 1840020236,
  },
  {
    name: "Brooklyn Center",
    state_id: "MN",
    lat: 45.0681,
    lng: -93.3162,
    id: 1840004022,
  },
  {
    name: "Kerrville",
    state_id: "TX",
    lat: 30.0398,
    lng: -99.132,
    id: 1840020908,
  },
  {
    name: "Barstow",
    state_id: "CA",
    lat: 34.8661,
    lng: -117.0472,
    id: 1840019118,
  },
  {
    name: "Oceanside",
    state_id: "NY",
    lat: 40.6328,
    lng: -73.6364,
    id: 1840005246,
  },
  {
    name: "Canton",
    state_id: "GA",
    lat: 34.2467,
    lng: -84.49,
    id: 1840014703,
  },
  {
    name: "Auburn",
    state_id: "NY",
    lat: 42.9338,
    lng: -76.5684,
    id: 1840000367,
  },
  {
    name: "Crown Point",
    state_id: "IN",
    lat: 41.4141,
    lng: -87.3457,
    id: 1840007091,
  },
  {
    name: "Windsor",
    state_id: "CO",
    lat: 40.4693,
    lng: -104.9213,
    id: 1840022401,
  },
  {
    name: "Lakeside",
    state_id: "FL",
    lat: 30.1356,
    lng: -81.7674,
    id: 1840029051,
  },
  {
    name: "Gloucester",
    state_id: "MA",
    lat: 42.626,
    lng: -70.6897,
    id: 1840000412,
  },
  {
    name: "Gurnee",
    state_id: "IL",
    lat: 42.3708,
    lng: -87.9392,
    id: 1840011152,
  },
  {
    name: "Orangeburg",
    state_id: "SC",
    lat: 33.4928,
    lng: -80.867,
    id: 1840014812,
  },
  {
    name: "Holladay",
    state_id: "UT",
    lat: 40.6599,
    lng: -111.8226,
    id: 1840020156,
  },
  {
    name: "Fuquay-Varina",
    state_id: "NC",
    lat: 35.5958,
    lng: -78.7794,
    id: 1840016197,
  },
  {
    name: "Chamblee",
    state_id: "GA",
    lat: 33.8842,
    lng: -84.3008,
    id: 1840014775,
  },
  {
    name: "Carney",
    state_id: "MD",
    lat: 39.405,
    lng: -76.5236,
    id: 1840005678,
  },
  {
    name: "Peru",
    state_id: "IL",
    lat: 41.3484,
    lng: -89.137,
    id: 1840009273,
  },
  {
    name: "Sterling",
    state_id: "VA",
    lat: 39.0052,
    lng: -77.405,
    id: 1840024508,
  },
  {
    name: "Cornelius",
    state_id: "NC",
    lat: 35.4725,
    lng: -80.8801,
    id: 1840016352,
  },
  {
    name: "North Tonawanda",
    state_id: "NY",
    lat: 43.0457,
    lng: -78.8659,
    id: 1840000372,
  },
  {
    name: "Long Branch",
    state_id: "NJ",
    lat: 40.2965,
    lng: -73.9915,
    id: 1840003673,
  },
  {
    name: "Santa Paula",
    state_id: "CA",
    lat: 34.3521,
    lng: -119.0698,
    id: 1840021842,
  },
  {
    name: "Los Gatos",
    state_id: "CA",
    lat: 37.2303,
    lng: -121.956,
    id: 1840021575,
  },
  {
    name: "South Lake Tahoe",
    state_id: "CA",
    lat: 38.9393,
    lng: -119.9828,
    id: 1840021471,
  },
  {
    name: "San Carlos",
    state_id: "CA",
    lat: 37.4982,
    lng: -122.268,
    id: 1840021550,
  },
  {
    name: "Clinton",
    state_id: "IA",
    lat: 41.8434,
    lng: -90.2408,
    id: 1840000507,
  },
  {
    name: "Saratoga",
    state_id: "CA",
    lat: 37.2684,
    lng: -122.0263,
    id: 1840021572,
  },
  {
    name: "Los Altos",
    state_id: "CA",
    lat: 37.3685,
    lng: -122.0966,
    id: 1840020328,
  },
  {
    name: "Ballwin",
    state_id: "MO",
    lat: 38.5951,
    lng: -90.55,
    id: 1840007447,
  },
  {
    name: "Atascadero",
    state_id: "CA",
    lat: 35.4827,
    lng: -120.6858,
    id: 1840019126,
  },
  {
    name: "North Royalton",
    state_id: "OH",
    lat: 41.3138,
    lng: -81.7452,
    id: 1840003402,
  },
  {
    name: "Somerset",
    state_id: "KY",
    lat: 37.0815,
    lng: -84.6091,
    id: 1840015257,
  },
  {
    name: "Seguin",
    state_id: "TX",
    lat: 29.5889,
    lng: -97.9671,
    id: 1840022214,
  },
  {
    name: "Fortuna Foothills",
    state_id: "AZ",
    lat: 32.6616,
    lng: -114.3973,
    id: 1840027989,
  },
  {
    name: "Milford Mill",
    state_id: "MD",
    lat: 39.3444,
    lng: -76.7668,
    id: 1840031493,
  },
  {
    name: "Pasadena",
    state_id: "MD",
    lat: 39.1552,
    lng: -76.5537,
    id: 1840005914,
  },
  {
    name: "Stockbridge",
    state_id: "GA",
    lat: 33.5253,
    lng: -84.2294,
    id: 1840015707,
  },
  {
    name: "East Liverpool",
    state_id: "OH",
    lat: 40.6333,
    lng: -80.5677,
    id: 1840002783,
  },
  {
    name: "Rahway",
    state_id: "NJ",
    lat: 40.6077,
    lng: -74.2807,
    id: 1840001091,
  },
  {
    name: "Madison Heights",
    state_id: "MI",
    lat: 42.5073,
    lng: -83.1034,
    id: 1840002449,
  },
  {
    name: "Williston",
    state_id: "ND",
    lat: 48.1814,
    lng: -103.6364,
    id: 1840001871,
  },
  {
    name: "Bay Shore",
    state_id: "NY",
    lat: 40.7317,
    lng: -73.2505,
    id: 1840005017,
  },
  {
    name: "Eagle",
    state_id: "ID",
    lat: 43.7223,
    lng: -116.3862,
    id: 1840018642,
  },
  {
    name: "Westchester",
    state_id: "FL",
    lat: 25.7474,
    lng: -80.3358,
    id: 1840014241,
  },
  {
    name: "Maryville",
    state_id: "TN",
    lat: 35.7468,
    lng: -83.9789,
    id: 1840015380,
  },
  {
    name: "Walnut",
    state_id: "CA",
    lat: 34.0334,
    lng: -117.8593,
    id: 1840021875,
  },
  {
    name: "Suisun City",
    state_id: "CA",
    lat: 38.2473,
    lng: -122.009,
    id: 1840028115,
  },
  {
    name: "Garden City",
    state_id: "KS",
    lat: 37.9754,
    lng: -100.8529,
    id: 1840001669,
  },
  {
    name: "Texarkana",
    state_id: "AR",
    lat: 33.4361,
    lng: -93.996,
    id: 1840015708,
  },
  {
    name: "Kent",
    state_id: "OH",
    lat: 41.149,
    lng: -81.361,
    id: 1840008256,
  },
  {
    name: "West Falls Church",
    state_id: "VA",
    lat: 38.8648,
    lng: -77.1878,
    id: 1840006117,
  },
  {
    name: "North Chicago",
    state_id: "IL",
    lat: 42.3172,
    lng: -87.8596,
    id: 1840008086,
  },
  {
    name: "Greenville",
    state_id: "MS",
    lat: 33.385,
    lng: -91.0514,
    id: 1840013759,
  },
  {
    name: "Duluth",
    state_id: "GA",
    lat: 34.0053,
    lng: -84.1492,
    id: 1840013675,
  },
  {
    name: "Aberdeen",
    state_id: "WA",
    lat: 46.9757,
    lng: -123.8094,
    id: 1840018446,
  },
  {
    name: "Winona",
    state_id: "MN",
    lat: 44.0505,
    lng: -91.6684,
    id: 1840010034,
  },
  {
    name: "O'Fallon",
    state_id: "IL",
    lat: 38.5976,
    lng: -89.9155,
    id: 1840008632,
  },
  {
    name: "Drexel Heights",
    state_id: "AZ",
    lat: 32.1457,
    lng: -111.0478,
    id: 1840027928,
  },
  {
    name: "Ukiah",
    state_id: "CA",
    lat: 39.1463,
    lng: -123.2105,
    id: 1840021420,
  },
  {
    name: "Atwater",
    state_id: "CA",
    lat: 37.3543,
    lng: -120.5981,
    id: 1840018934,
  },
  {
    name: "Golden Gate",
    state_id: "FL",
    lat: 26.1844,
    lng: -81.7031,
    id: 1840014228,
  },
  {
    name: "Sanford",
    state_id: "ME",
    lat: 43.4244,
    lng: -70.7573,
    id: 1840002672,
  },
  {
    name: "Highland Park",
    state_id: "IL",
    lat: 42.1823,
    lng: -87.8105,
    id: 1840006973,
  },
  {
    name: "Westfield",
    state_id: "NJ",
    lat: 40.6515,
    lng: -74.3433,
    id: 1840001096,
  },
  {
    name: "Hermiston",
    state_id: "OR",
    lat: 45.8323,
    lng: -119.2858,
    id: 1840019915,
  },
  {
    name: "Oak Park",
    state_id: "MI",
    lat: 42.4649,
    lng: -83.1824,
    id: 1840002424,
  },
  {
    name: "Central",
    state_id: "LA",
    lat: 30.5593,
    lng: -91.0369,
    id: 1840023139,
  },
  {
    name: "East Lake-Orient Park",
    state_id: "FL",
    lat: 27.9971,
    lng: -82.3653,
    id: 1840073826,
  },
  {
    name: "Chicago Heights",
    state_id: "IL",
    lat: 41.5101,
    lng: -87.6345,
    id: 1840007024,
  },
  {
    name: "East Palo Alto",
    state_id: "CA",
    lat: 37.4671,
    lng: -122.1352,
    id: 1840020301,
  },
  {
    name: "Newberg",
    state_id: "OR",
    lat: 45.3075,
    lng: -122.9601,
    id: 1840019957,
  },
  {
    name: "Lewes",
    state_id: "DE",
    lat: 38.7777,
    lng: -75.1448,
    id: 1840006068,
  },
  {
    name: "North Attleborough",
    state_id: "MA",
    lat: 41.9699,
    lng: -71.3345,
    id: 1840070236,
  },
  {
    name: "Washington",
    state_id: "UT",
    lat: 37.1304,
    lng: -113.4877,
    id: 1840021561,
  },
  {
    name: "Port Chester",
    state_id: "NY",
    lat: 41.0052,
    lng: -73.668,
    id: 1840004941,
  },
  {
    name: "Oak Ridge",
    state_id: "TN",
    lat: 35.9639,
    lng: -84.2938,
    id: 1840014473,
  },
  {
    name: "Crofton",
    state_id: "MD",
    lat: 39.0144,
    lng: -76.68,
    id: 1840005909,
  },
  {
    name: "Athens",
    state_id: "OH",
    lat: 39.3269,
    lng: -82.0988,
    id: 1840007355,
  },
  {
    name: "Laplace",
    state_id: "LA",
    lat: 30.0731,
    lng: -90.4758,
    id: 1840013968,
  },
  {
    name: "SeaTac",
    state_id: "WA",
    lat: 47.4444,
    lng: -122.2986,
    id: 1840021116,
  },
  {
    name: "Raytown",
    state_id: "MO",
    lat: 38.9944,
    lng: -94.4641,
    id: 1840009681,
  },
  {
    name: "Paragould",
    state_id: "AR",
    lat: 36.0555,
    lng: -90.5149,
    id: 1840014474,
  },
  {
    name: "Graham",
    state_id: "WA",
    lat: 47.0407,
    lng: -122.2756,
    id: 1840017365,
  },
  {
    name: "Southgate",
    state_id: "MI",
    lat: 42.2047,
    lng: -83.2057,
    id: 1840003964,
  },
  {
    name: "Harrison",
    state_id: "NY",
    lat: 41.0233,
    lng: -73.7192,
    id: 1840004931,
  },
  {
    name: "Niles",
    state_id: "IL",
    lat: 42.0278,
    lng: -87.8099,
    id: 1840011301,
  },
  {
    name: "Fernandina Beach",
    state_id: "FL",
    lat: 30.6579,
    lng: -81.4504,
    id: 1840013929,
  },
  {
    name: "Big Spring",
    state_id: "TX",
    lat: 32.2387,
    lng: -101.4802,
    id: 1840019471,
  },
  {
    name: "Bella Vista",
    state_id: "AR",
    lat: 36.4667,
    lng: -94.2707,
    id: 1840015287,
  },
  {
    name: "Milton",
    state_id: "PA",
    lat: 41.009,
    lng: -76.8507,
    id: 1840000845,
  },
  {
    name: "Morrisville",
    state_id: "NC",
    lat: 35.8367,
    lng: -78.8348,
    id: 1840016195,
  },
  {
    name: "Greenville",
    state_id: "TX",
    lat: 33.1116,
    lng: -96.1098,
    id: 1840020649,
  },
  {
    name: "Casselberry",
    state_id: "FL",
    lat: 28.6625,
    lng: -81.3218,
    id: 1840015088,
  },
  {
    name: "Plattsburgh",
    state_id: "NY",
    lat: 44.6951,
    lng: -73.4563,
    id: 1840000296,
  },
  {
    name: "Wasco",
    state_id: "CA",
    lat: 35.5938,
    lng: -119.3671,
    id: 1840021738,
  },
  {
    name: "South Laurel",
    state_id: "MD",
    lat: 39.0603,
    lng: -76.8456,
    id: 1840031498,
  },
  {
    name: "Fleming Island",
    state_id: "FL",
    lat: 30.0988,
    lng: -81.7124,
    id: 1840038864,
  },
  {
    name: "Belle Glade",
    state_id: "FL",
    lat: 26.692,
    lng: -80.6672,
    id: 1840014206,
  },
  {
    name: "Lake in the Hills",
    state_id: "IL",
    lat: 42.1913,
    lng: -88.3476,
    id: 1840011129,
  },
  {
    name: "Agawam",
    state_id: "MA",
    lat: 42.0657,
    lng: -72.6526,
    id: 1840031157,
  },
  {
    name: "Shirley",
    state_id: "NY",
    lat: 40.7949,
    lng: -72.8743,
    id: 1840005097,
  },
  {
    name: "Burton",
    state_id: "MI",
    lat: 42.9974,
    lng: -83.6175,
    id: 1840002945,
  },
  {
    name: "Geneva",
    state_id: "NY",
    lat: 42.8644,
    lng: -76.9827,
    id: 1840000393,
  },
  {
    name: "Schererville",
    state_id: "IN",
    lat: 41.486,
    lng: -87.444,
    id: 1840010222,
  },
  {
    name: "Independence",
    state_id: "KY",
    lat: 38.951,
    lng: -84.5492,
    id: 1840014267,
  },
  {
    name: "West Springfield",
    state_id: "MA",
    lat: 42.1253,
    lng: -72.6503,
    id: 1840031158,
  },
  {
    name: "Austintown",
    state_id: "OH",
    lat: 41.0932,
    lng: -80.7405,
    id: 1840005185,
  },
  {
    name: "Burlington",
    state_id: "IA",
    lat: 40.8072,
    lng: -91.1247,
    id: 1840007164,
  },
  {
    name: "Shamokin",
    state_id: "PA",
    lat: 40.7883,
    lng: -76.555,
    id: 1840000849,
  },
  {
    name: "New Iberia",
    state_id: "LA",
    lat: 30.0049,
    lng: -91.8202,
    id: 1840015053,
  },
  {
    name: "Northampton",
    state_id: "MA",
    lat: 42.3266,
    lng: -72.6745,
    id: 1840000451,
  },
  {
    name: "Englewood",
    state_id: "NJ",
    lat: 40.8917,
    lng: -73.9736,
    id: 1840003546,
  },
  {
    name: "Vineyard",
    state_id: "CA",
    lat: 38.4744,
    lng: -121.319,
    id: 1840018846,
  },
  {
    name: "Zionsville",
    state_id: "IN",
    lat: 39.9897,
    lng: -86.3182,
    id: 1840010515,
  },
  {
    name: "Short Pump",
    state_id: "VA",
    lat: 37.6549,
    lng: -77.6201,
    id: 1840006394,
  },
  {
    name: "Burbank",
    state_id: "IL",
    lat: 41.7444,
    lng: -87.7686,
    id: 1840007022,
  },
  {
    name: "Lumberton",
    state_id: "NC",
    lat: 34.6312,
    lng: -79.0186,
    id: 1840015531,
  },
  {
    name: "Perry Hall",
    state_id: "MD",
    lat: 39.4067,
    lng: -76.4781,
    id: 1840005686,
  },
  {
    name: "Aberdeen",
    state_id: "SD",
    lat: 45.4646,
    lng: -98.468,
    id: 1840002032,
  },
  {
    name: "Benicia",
    state_id: "CA",
    lat: 38.0725,
    lng: -122.1525,
    id: 1840018858,
  },
  {
    name: "Oakleaf Plantation",
    state_id: "FL",
    lat: 30.1689,
    lng: -81.8337,
    id: 1840039045,
  },
  {
    name: "Jacksonville",
    state_id: "AR",
    lat: 34.8807,
    lng: -92.1302,
    id: 1840014633,
  },
  {
    name: "Winchester",
    state_id: "NV",
    lat: 36.1368,
    lng: -115.1299,
    id: 1840033770,
  },
  {
    name: "Rockledge",
    state_id: "FL",
    lat: 28.3201,
    lng: -80.732,
    id: 1840015095,
  },
  {
    name: "Henderson",
    state_id: "KY",
    lat: 37.8397,
    lng: -87.5798,
    id: 1840014337,
  },
  {
    name: "Monterey",
    state_id: "CA",
    lat: 36.5922,
    lng: -121.8807,
    id: 1840020358,
  },
  {
    name: "Converse",
    state_id: "TX",
    lat: 29.5091,
    lng: -98.3084,
    id: 1840019655,
  },
  {
    name: "Granite City",
    state_id: "IL",
    lat: 38.7292,
    lng: -90.1266,
    id: 1840008565,
  },
  {
    name: "Yukon",
    state_id: "OK",
    lat: 35.5201,
    lng: -97.7639,
    id: 1840021740,
  },
  {
    name: "Sonora",
    state_id: "CA",
    lat: 37.9819,
    lng: -120.3828,
    id: 1840021511,
  },
  {
    name: "Melrose",
    state_id: "MA",
    lat: 42.4556,
    lng: -71.059,
    id: 1840000432,
  },
  {
    name: "Mehlville",
    state_id: "MO",
    lat: 38.5018,
    lng: -90.3149,
    id: 1840006120,
  },
  {
    name: "Wisconsin Rapids",
    state_id: "WI",
    lat: 44.3927,
    lng: -89.8265,
    id: 1840002333,
  },
  {
    name: "Galt",
    state_id: "CA",
    lat: 38.2693,
    lng: -121.3,
    id: 1840020247,
  },
  {
    name: "Hutto",
    state_id: "TX",
    lat: 30.5373,
    lng: -97.5516,
    id: 1840019573,
  },
  {
    name: "Bixby",
    state_id: "OK",
    lat: 35.9454,
    lng: -95.8773,
    id: 1840019058,
  },
  {
    name: "Hobart",
    state_id: "IN",
    lat: 41.5139,
    lng: -87.2729,
    id: 1840007093,
  },
  {
    name: "Drexel Hill",
    state_id: "PA",
    lat: 39.9495,
    lng: -75.3039,
    id: 1840005550,
  },
  {
    name: "Oakdale",
    state_id: "MN",
    lat: 44.9876,
    lng: -92.9641,
    id: 1840007813,
  },
  {
    name: "Sunbury",
    state_id: "PA",
    lat: 40.8617,
    lng: -76.7874,
    id: 1840000851,
  },
  {
    name: "Summerlin South",
    state_id: "NV",
    lat: 36.1242,
    lng: -115.3324,
    id: 1840074790,
  },
  {
    name: "New Smyrna Beach",
    state_id: "FL",
    lat: 29.0249,
    lng: -80.9651,
    id: 1840015073,
  },
  {
    name: "Tualatin",
    state_id: "OR",
    lat: 45.3772,
    lng: -122.7746,
    id: 1840021207,
  },
  {
    name: "Forest Hills",
    state_id: "MI",
    lat: 42.9577,
    lng: -85.4895,
    id: 1840004304,
  },
  {
    name: "Fridley",
    state_id: "MN",
    lat: 45.0841,
    lng: -93.2595,
    id: 1840007798,
  },
  {
    name: "East Chicago",
    state_id: "IN",
    lat: 41.6484,
    lng: -87.4536,
    id: 1840008193,
  },
  {
    name: "Kirkwood",
    state_id: "MO",
    lat: 38.5788,
    lng: -90.4203,
    id: 1840008598,
  },
  {
    name: "Sanger",
    state_id: "CA",
    lat: 36.699,
    lng: -119.5575,
    id: 1840021566,
  },
  {
    name: "Morgan City",
    state_id: "LA",
    lat: 29.7041,
    lng: -91.192,
    id: 1840015056,
  },
  {
    name: "Ramsey",
    state_id: "MN",
    lat: 45.2617,
    lng: -93.4494,
    id: 1840008904,
  },
  {
    name: "Ruston",
    state_id: "LA",
    lat: 32.5329,
    lng: -92.6363,
    id: 1840014878,
  },
  {
    name: "Jeffersontown",
    state_id: "KY",
    lat: 38.2048,
    lng: -85.5701,
    id: 1840014311,
  },
  {
    name: "Glen Ellyn",
    state_id: "IL",
    lat: 41.8667,
    lng: -88.0629,
    id: 1840011405,
  },
  {
    name: "Isla Vista",
    state_id: "CA",
    lat: 34.4144,
    lng: -119.8581,
    id: 1840017887,
  },
  {
    name: "Cañon City",
    state_id: "CO",
    lat: 38.443,
    lng: -105.2203,
    id: 1840018855,
  },
  {
    name: "Monticello",
    state_id: "MN",
    lat: 45.298,
    lng: -93.7984,
    id: 1840007795,
  },
  {
    name: "Ashtabula",
    state_id: "OH",
    lat: 41.8806,
    lng: -80.7984,
    id: 1840007046,
  },
  {
    name: "Temescal Valley",
    state_id: "CA",
    lat: 33.7581,
    lng: -117.4677,
    id: 1840043023,
  },
  {
    name: "Bridgewater",
    state_id: "MA",
    lat: 41.9728,
    lng: -70.9749,
    id: 1840003199,
  },
  {
    name: "Mint Hill",
    state_id: "NC",
    lat: 35.1782,
    lng: -80.6533,
    id: 1840016351,
  },
  {
    name: "Mililani Town",
    state_id: "HI",
    lat: 21.4465,
    lng: -158.0147,
    id: 1840023249,
  },
  {
    name: "Statesville",
    state_id: "NC",
    lat: 35.7842,
    lng: -80.8714,
    id: 1840015358,
  },
  {
    name: "Gloversville",
    state_id: "NY",
    lat: 43.0491,
    lng: -74.3465,
    id: 1840000375,
  },
  {
    name: "Gladstone",
    state_id: "MO",
    lat: 39.2133,
    lng: -94.5593,
    id: 1840008496,
  },
  {
    name: "Deer Park",
    state_id: "NY",
    lat: 40.7623,
    lng: -73.3219,
    id: 1840005034,
  },
  {
    name: "Fremont",
    state_id: "NE",
    lat: 41.4396,
    lng: -96.4879,
    id: 1840003346,
  },
  {
    name: "Garfield Heights",
    state_id: "OH",
    lat: 41.4199,
    lng: -81.6038,
    id: 1840000602,
  },
  {
    name: "Imperial Beach",
    state_id: "CA",
    lat: 32.5689,
    lng: -117.1184,
    id: 1840019353,
  },
  {
    name: "Seneca",
    state_id: "SC",
    lat: 34.6815,
    lng: -82.9609,
    id: 1840015506,
  },
  {
    name: "Florence",
    state_id: "AZ",
    lat: 33.059,
    lng: -111.4208,
    id: 1840021999,
  },
  {
    name: "Bayonet Point",
    state_id: "FL",
    lat: 28.3254,
    lng: -82.6834,
    id: 1840014117,
  },
  {
    name: "Lansing",
    state_id: "IL",
    lat: 41.5648,
    lng: -87.5462,
    id: 1840011257,
  },
  {
    name: "Millville",
    state_id: "NJ",
    lat: 39.3903,
    lng: -75.0561,
    id: 1840001550,
  },
  {
    name: "Monroeville",
    state_id: "PA",
    lat: 40.4262,
    lng: -79.7605,
    id: 1840003631,
  },
  {
    name: "St. Helens",
    state_id: "OR",
    lat: 45.8571,
    lng: -122.8164,
    id: 1840021185,
  },
  {
    name: "Bergenfield",
    state_id: "NJ",
    lat: 40.9236,
    lng: -73.9983,
    id: 1840000893,
  },
  {
    name: "Ladera Ranch",
    state_id: "CA",
    lat: 33.5491,
    lng: -117.6416,
    id: 1840028333,
  },
  {
    name: "Horn Lake",
    state_id: "MS",
    lat: 34.9512,
    lng: -90.0501,
    id: 1840014643,
  },
  {
    name: "Prior Lake",
    state_id: "MN",
    lat: 44.7251,
    lng: -93.4409,
    id: 1840008956,
  },
  {
    name: "Huntley",
    state_id: "IL",
    lat: 42.1599,
    lng: -88.433,
    id: 1840011134,
  },
  {
    name: "Maple Valley",
    state_id: "WA",
    lat: 47.3659,
    lng: -122.0368,
    id: 1840019828,
  },
  {
    name: "Easton",
    state_id: "PA",
    lat: 40.6858,
    lng: -75.2209,
    id: 1840000970,
  },
  {
    name: "Oak Forest",
    state_id: "IL",
    lat: 41.6054,
    lng: -87.7527,
    id: 1840008132,
  },
  {
    name: "Glen Cove",
    state_id: "NY",
    lat: 40.8709,
    lng: -73.6287,
    id: 1840000987,
  },
  {
    name: "Indiana",
    state_id: "PA",
    lat: 40.622,
    lng: -79.1552,
    id: 1840000997,
  },
  {
    name: "Shoreview",
    state_id: "MN",
    lat: 45.0842,
    lng: -93.1358,
    id: 1840008938,
  },
  {
    name: "Windsor",
    state_id: "CA",
    lat: 38.5417,
    lng: -122.8086,
    id: 1840022484,
  },
  {
    name: "Sterling",
    state_id: "IL",
    lat: 41.7995,
    lng: -89.6956,
    id: 1840009200,
  },
  {
    name: "Dodge City",
    state_id: "KS",
    lat: 37.761,
    lng: -100.0183,
    id: 1840001684,
  },
  {
    name: "Colleyville",
    state_id: "TX",
    lat: 32.8913,
    lng: -97.1486,
    id: 1840019430,
  },
  {
    name: "Wilmette",
    state_id: "IL",
    lat: 42.0771,
    lng: -87.7282,
    id: 1840011316,
  },
  {
    name: "Plum",
    state_id: "PA",
    lat: 40.5024,
    lng: -79.7496,
    id: 1840003632,
  },
  {
    name: "Mount Pleasant",
    state_id: "WI",
    lat: 42.7129,
    lng: -87.8875,
    id: 1840038102,
  },
  {
    name: "McHenry",
    state_id: "IL",
    lat: 42.3388,
    lng: -88.2931,
    id: 1840008085,
  },
  {
    name: "Waterville",
    state_id: "ME",
    lat: 44.5441,
    lng: -69.6624,
    id: 1840000305,
  },
  {
    name: "Norfolk",
    state_id: "NE",
    lat: 42.0327,
    lng: -97.4208,
    id: 1840008136,
  },
  {
    name: "Shaker Heights",
    state_id: "OH",
    lat: 41.4744,
    lng: -81.5496,
    id: 1840003412,
  },
  {
    name: "Citrus Park",
    state_id: "FL",
    lat: 28.073,
    lng: -82.5628,
    id: 1840014152,
  },
  {
    name: "Reisterstown",
    state_id: "MD",
    lat: 39.4552,
    lng: -76.8144,
    id: 1840005689,
  },
  {
    name: "Erie",
    state_id: "CO",
    lat: 40.0403,
    lng: -105.0398,
    id: 1840021362,
  },
  {
    name: "Brownsburg",
    state_id: "IN",
    lat: 39.833,
    lng: -86.3824,
    id: 1840009536,
  },
  {
    name: "Chaska",
    state_id: "MN",
    lat: 44.8164,
    lng: -93.6092,
    id: 1840006760,
  },
  {
    name: "Ilchester",
    state_id: "MD",
    lat: 39.2187,
    lng: -76.7684,
    id: 1840024482,
  },
  {
    name: "Shelby",
    state_id: "NC",
    lat: 35.2904,
    lng: -81.5451,
    id: 1840015426,
  },
  {
    name: "Maywood",
    state_id: "CA",
    lat: 33.9885,
    lng: -118.1877,
    id: 1840020497,
  },
  {
    name: "Maryland Heights",
    state_id: "MO",
    lat: 38.7189,
    lng: -90.4749,
    id: 1840008591,
  },
  {
    name: "Magna",
    state_id: "UT",
    lat: 40.7634,
    lng: -112.1599,
    id: 1840017499,
  },
  {
    name: "Xenia",
    state_id: "OH",
    lat: 39.6828,
    lng: -83.9414,
    id: 1840003790,
  },
  {
    name: "Belmont",
    state_id: "CA",
    lat: 37.5154,
    lng: -122.2955,
    id: 1840018924,
  },
  {
    name: "Allen Park",
    state_id: "MI",
    lat: 42.2595,
    lng: -83.2107,
    id: 1840003967,
  },
  {
    name: "Mason City",
    state_id: "IA",
    lat: 43.1487,
    lng: -93.1998,
    id: 1840000380,
  },
  {
    name: "New Lenox",
    state_id: "IL",
    lat: 41.5097,
    lng: -87.97,
    id: 1840011493,
  },
  {
    name: "Hanahan",
    state_id: "SC",
    lat: 32.9302,
    lng: -80.0027,
    id: 1840014256,
  },
  {
    name: "Kinston",
    state_id: "NC",
    lat: 35.2748,
    lng: -77.5936,
    id: 1840014585,
  },
  {
    name: "Searcy",
    state_id: "AR",
    lat: 35.2418,
    lng: -91.7351,
    id: 1840015429,
  },
  {
    name: "New London",
    state_id: "CT",
    lat: 41.3502,
    lng: -72.1023,
    id: 1840004827,
  },
  {
    name: "West Islip",
    state_id: "NY",
    lat: 40.7097,
    lng: -73.2971,
    id: 1840005111,
  },
  {
    name: "West Chicago",
    state_id: "IL",
    lat: 41.896,
    lng: -88.2253,
    id: 1840010165,
  },
  {
    name: "Kiryas Joel",
    state_id: "NY",
    lat: 41.3406,
    lng: -74.166,
    id: 1840004872,
  },
  {
    name: "Lemon Grove",
    state_id: "CA",
    lat: 32.7331,
    lng: -117.0344,
    id: 1840020621,
  },
  {
    name: "Lindenhurst",
    state_id: "NY",
    lat: 40.6858,
    lng: -73.371,
    id: 1840005122,
  },
  {
    name: "McDonough",
    state_id: "GA",
    lat: 33.4399,
    lng: -84.1509,
    id: 1840015706,
  },
  {
    name: "West Linn",
    state_id: "OR",
    lat: 45.3669,
    lng: -122.6399,
    id: 1840021218,
  },
  {
    name: "Cortland",
    state_id: "NY",
    lat: 42.6004,
    lng: -76.1784,
    id: 1840000419,
  },
  {
    name: "Lemoore",
    state_id: "CA",
    lat: 36.2949,
    lng: -119.7983,
    id: 1840020383,
  },
  {
    name: "Alvin",
    state_id: "TX",
    lat: 29.3871,
    lng: -95.2933,
    id: 1840019671,
  },
  {
    name: "Waynesboro",
    state_id: "PA",
    lat: 39.7525,
    lng: -77.5822,
    id: 1840001415,
  },
  {
    name: "Marshalltown",
    state_id: "IA",
    lat: 42.0341,
    lng: -92.9067,
    id: 1840008112,
  },
  {
    name: "Lebanon",
    state_id: "NH",
    lat: 43.6353,
    lng: -72.2531,
    id: 1840002192,
  },
  {
    name: "West Whittier-Los Nietos",
    state_id: "CA",
    lat: 33.9759,
    lng: -118.0689,
    id: 1840074745,
  },
  {
    name: "Leisure City",
    state_id: "FL",
    lat: 25.4937,
    lng: -80.4369,
    id: 1840014245,
  },
  {
    name: "Thomasville",
    state_id: "NC",
    lat: 35.8813,
    lng: -80.0807,
    id: 1840015361,
  },
  {
    name: "Temple Terrace",
    state_id: "FL",
    lat: 28.0437,
    lng: -82.3774,
    id: 1840015983,
  },
  {
    name: "Lafayette",
    state_id: "CA",
    lat: 37.8919,
    lng: -122.1189,
    id: 1840020284,
  },
  {
    name: "University Park",
    state_id: "FL",
    lat: 25.7469,
    lng: -80.3684,
    id: 1840029013,
  },
  {
    name: "Norco",
    state_id: "CA",
    lat: 33.9252,
    lng: -117.5499,
    id: 1840020554,
  },
  {
    name: "Immokalee",
    state_id: "FL",
    lat: 26.4253,
    lng: -81.4251,
    id: 1840014230,
  },
  {
    name: "Vernon Hills",
    state_id: "IL",
    lat: 42.234,
    lng: -87.9608,
    id: 1840011181,
  },
  {
    name: "Pearl",
    state_id: "MS",
    lat: 32.273,
    lng: -90.0918,
    id: 1840014888,
  },
  {
    name: "Bethany",
    state_id: "OR",
    lat: 45.5613,
    lng: -122.8369,
    id: 1840034671,
  },
  {
    name: "Marquette",
    state_id: "MI",
    lat: 46.544,
    lng: -87.4082,
    id: 1840003923,
  },
  {
    name: "Sedalia",
    state_id: "MO",
    lat: 38.7042,
    lng: -93.2351,
    id: 1840009724,
  },
  {
    name: "Bessemer",
    state_id: "AL",
    lat: 33.3709,
    lng: -86.9713,
    id: 1840013732,
  },
  {
    name: "Plainview",
    state_id: "NY",
    lat: 40.7832,
    lng: -73.4732,
    id: 1840005250,
  },
  {
    name: "Batavia",
    state_id: "IL",
    lat: 41.8479,
    lng: -88.311,
    id: 1840007035,
  },
  {
    name: "Garden City",
    state_id: "MI",
    lat: 42.3244,
    lng: -83.3412,
    id: 1840003974,
  },
  {
    name: "Chanhassen",
    state_id: "MN",
    lat: 44.8544,
    lng: -93.5621,
    id: 1840006759,
  },
  {
    name: "Cabot",
    state_id: "AR",
    lat: 34.9768,
    lng: -92.0274,
    id: 1840013530,
  },
  {
    name: "Vicksburg",
    state_id: "MS",
    lat: 32.3173,
    lng: -90.8868,
    id: 1840015802,
  },
  {
    name: "Brawley",
    state_id: "CA",
    lat: 32.9783,
    lng: -115.5287,
    id: 1840019378,
  },
  {
    name: "Neenah",
    state_id: "WI",
    lat: 44.167,
    lng: -88.4764,
    id: 1840002244,
  },
  {
    name: "Snoqualmie",
    state_id: "WA",
    lat: 47.5293,
    lng: -121.8412,
    id: 1840021119,
  },
  {
    name: "Troy",
    state_id: "OH",
    lat: 40.0436,
    lng: -84.2191,
    id: 1840010508,
  },
  {
    name: "Hercules",
    state_id: "CA",
    lat: 38.0064,
    lng: -122.2564,
    id: 1840020276,
  },
  {
    name: "Lake City",
    state_id: "FL",
    lat: 30.1901,
    lng: -82.647,
    id: 1840015029,
  },
  {
    name: "Paramus",
    state_id: "NJ",
    lat: 40.9455,
    lng: -74.0712,
    id: 1840000916,
  },
  {
    name: "Okeechobee",
    state_id: "FL",
    lat: 27.2414,
    lng: -80.8298,
    id: 1840015118,
  },
  {
    name: "Soledad",
    state_id: "CA",
    lat: 36.4432,
    lng: -121.3426,
    id: 1840021635,
  },
  {
    name: "Muscatine",
    state_id: "IA",
    lat: 41.4196,
    lng: -91.068,
    id: 1840008202,
  },
  {
    name: "Boone",
    state_id: "NC",
    lat: 36.2111,
    lng: -81.6668,
    id: 1840015310,
  },
  {
    name: "Moscow",
    state_id: "ID",
    lat: 46.7307,
    lng: -116.9986,
    id: 1840019868,
  },
  {
    name: "Cliffside Park",
    state_id: "NJ",
    lat: 40.8221,
    lng: -73.988,
    id: 1840003537,
  },
  {
    name: "Sebastian",
    state_id: "FL",
    lat: 27.7882,
    lng: -80.4813,
    id: 1840015984,
  },
  {
    name: "Northport",
    state_id: "AL",
    lat: 33.2589,
    lng: -87.5984,
    id: 1840014819,
  },
  {
    name: "Natchez",
    state_id: "MS",
    lat: 31.5437,
    lng: -91.3867,
    id: 1840014951,
  },
  {
    name: "Winchester",
    state_id: "KY",
    lat: 38.0017,
    lng: -84.1907,
    id: 1840015216,
  },
  {
    name: "Twentynine Palms",
    state_id: "CA",
    lat: 34.1478,
    lng: -116.0659,
    id: 1840021729,
  },
  {
    name: "Holt",
    state_id: "MI",
    lat: 42.6416,
    lng: -84.5307,
    id: 1840004501,
  },
  {
    name: "East St. Louis",
    state_id: "IL",
    lat: 38.6156,
    lng: -90.1304,
    id: 1840008629,
  },
  {
    name: "Sachse",
    state_id: "TX",
    lat: 32.9726,
    lng: -96.5793,
    id: 1840020712,
  },
  {
    name: "Paris",
    state_id: "TX",
    lat: 33.6688,
    lng: -95.546,
    id: 1840020594,
  },
  {
    name: "Haines City",
    state_id: "FL",
    lat: 28.11,
    lng: -81.6157,
    id: 1840014136,
  },
  {
    name: "Cave Spring",
    state_id: "VA",
    lat: 37.2254,
    lng: -80.0073,
    id: 1840006440,
  },
  {
    name: "Superior",
    state_id: "WI",
    lat: 46.6941,
    lng: -92.0823,
    id: 1840038034,
  },
  {
    name: "West Odessa",
    state_id: "TX",
    lat: 31.8388,
    lng: -102.4996,
    id: 1840037100,
  },
  {
    name: "Medina",
    state_id: "OH",
    lat: 41.1358,
    lng: -81.8694,
    id: 1840003486,
  },
  {
    name: "Conway",
    state_id: "SC",
    lat: 33.8399,
    lng: -79.0424,
    id: 1840014716,
  },
  {
    name: "Barberton",
    state_id: "OH",
    lat: 41.0094,
    lng: -81.6038,
    id: 1840000792,
  },
  {
    name: "Española",
    state_id: "NM",
    lat: 36.0041,
    lng: -106.0669,
    id: 1840020348,
  },
  {
    name: "Dix Hills",
    state_id: "NY",
    lat: 40.8035,
    lng: -73.337,
    id: 1840005035,
  },
  {
    name: "Rock Springs",
    state_id: "WY",
    lat: 41.5951,
    lng: -109.2238,
    id: 1840020101,
  },
  {
    name: "Mercer Island",
    state_id: "WA",
    lat: 47.5661,
    lng: -122.232,
    id: 1840019830,
  },
  {
    name: "Heber",
    state_id: "UT",
    lat: 40.5068,
    lng: -111.3984,
    id: 1840074861,
  },
  {
    name: "White Bear Lake",
    state_id: "MN",
    lat: 45.0657,
    lng: -93.015,
    id: 1840010005,
  },
  {
    name: "Laurel",
    state_id: "MS",
    lat: 31.6956,
    lng: -89.1448,
    id: 1840014945,
  },
  {
    name: "Holbrook",
    state_id: "NY",
    lat: 40.7944,
    lng: -73.0707,
    id: 1840005078,
  },
  {
    name: "Bay Point",
    state_id: "CA",
    lat: 38.0329,
    lng: -121.9614,
    id: 1840028316,
  },
  {
    name: "Woodstock",
    state_id: "IL",
    lat: 42.3096,
    lng: -88.4352,
    id: 1840010107,
  },
  {
    name: "Lochearn",
    state_id: "MD",
    lat: 39.346,
    lng: -76.7307,
    id: 1840005694,
  },
  {
    name: "Green",
    state_id: "OH",
    lat: 40.9483,
    lng: -81.4757,
    id: 1840008254,
  },
  {
    name: "Cloverleaf",
    state_id: "TX",
    lat: 29.7882,
    lng: -95.1724,
    id: 1840018248,
  },
  {
    name: "Owatonna",
    state_id: "MN",
    lat: 44.0914,
    lng: -93.2304,
    id: 1840008983,
  },
  {
    name: "Decatur",
    state_id: "GA",
    lat: 33.7711,
    lng: -84.2968,
    id: 1840014777,
  },
  {
    name: "Pooler",
    state_id: "GA",
    lat: 32.1043,
    lng: -81.2568,
    id: 1840014914,
  },
  {
    name: "Madison",
    state_id: "MS",
    lat: 32.4738,
    lng: -90.13,
    id: 1840015782,
  },
  {
    name: "Lancaster",
    state_id: "SC",
    lat: 34.7247,
    lng: -80.7801,
    id: 1840014630,
  },
  {
    name: "Ewa Gentry",
    state_id: "HI",
    lat: 21.3344,
    lng: -158.0262,
    id: 1840029577,
  },
  {
    name: "Laurel",
    state_id: "MD",
    lat: 39.095,
    lng: -76.8622,
    id: 1840005976,
  },
  {
    name: "Austin",
    state_id: "MN",
    lat: 43.6718,
    lng: -92.9783,
    id: 1840006830,
  },
  {
    name: "Lockport",
    state_id: "IL",
    lat: 41.5904,
    lng: -88.0292,
    id: 1840009256,
  },
  {
    name: "Franklin",
    state_id: "IN",
    lat: 39.4938,
    lng: -86.0546,
    id: 1840008460,
  },
  {
    name: "South Salt Lake",
    state_id: "UT",
    lat: 40.7056,
    lng: -111.8986,
    id: 1840021386,
  },
  {
    name: "Tarpon Springs",
    state_id: "FL",
    lat: 28.1493,
    lng: -82.7623,
    id: 1840015980,
  },
  {
    name: "Edgewood",
    state_id: "MD",
    lat: 39.421,
    lng: -76.2968,
    id: 1840005661,
  },
  {
    name: "San Lorenzo",
    state_id: "CA",
    lat: 37.6737,
    lng: -122.1349,
    id: 1840018912,
  },
  {
    name: "Bluffton",
    state_id: "SC",
    lat: 32.2135,
    lng: -80.9316,
    id: 1840015799,
  },
  {
    name: "Sevierville",
    state_id: "TN",
    lat: 35.8873,
    lng: -83.5677,
    id: 1840015359,
  },
  {
    name: "Forest Grove",
    state_id: "OR",
    lat: 45.5244,
    lng: -123.1101,
    id: 1840019930,
  },
  {
    name: "Sun City West",
    state_id: "AZ",
    lat: 33.6693,
    lng: -112.3575,
    id: 1840019312,
  },
  {
    name: "South Portland",
    state_id: "ME",
    lat: 43.631,
    lng: -70.2895,
    id: 1840000328,
  },
  {
    name: "Ruskin",
    state_id: "FL",
    lat: 27.7065,
    lng: -82.4209,
    id: 1840014159,
  },
  {
    name: "Denison",
    state_id: "TX",
    lat: 33.7672,
    lng: -96.5807,
    id: 1840019317,
  },
  {
    name: "El Cerrito",
    state_id: "CA",
    lat: 37.9197,
    lng: -122.3025,
    id: 1840020275,
  },
  {
    name: "Keystone",
    state_id: "FL",
    lat: 28.1312,
    lng: -82.5999,
    id: 1840028914,
  },
  {
    name: "Coral Terrace",
    state_id: "FL",
    lat: 25.7464,
    lng: -80.3049,
    id: 1840029028,
  },
  {
    name: "Claremore",
    state_id: "OK",
    lat: 36.3143,
    lng: -95.6099,
    id: 1840019049,
  },
  {
    name: "Homewood",
    state_id: "AL",
    lat: 33.4617,
    lng: -86.8092,
    id: 1840014794,
  },
  {
    name: "Farmington",
    state_id: "UT",
    lat: 40.9846,
    lng: -111.9065,
    id: 1840020142,
  },
  {
    name: "Columbine",
    state_id: "CO",
    lat: 39.5879,
    lng: -105.0694,
    id: 1840135257,
  },
  {
    name: "South Pasadena",
    state_id: "CA",
    lat: 34.1103,
    lng: -118.1573,
    id: 1840021871,
  },
  {
    name: "Canyon Lake",
    state_id: "TX",
    lat: 29.8761,
    lng: -98.2611,
    id: 1840018260,
  },
  {
    name: "Kingsville",
    state_id: "TX",
    lat: 27.5094,
    lng: -97.8611,
    id: 1840021013,
  },
  {
    name: "Forest City",
    state_id: "NC",
    lat: 35.3338,
    lng: -81.8702,
    id: 1840016320,
  },
  {
    name: "Salem",
    state_id: "VA",
    lat: 37.2864,
    lng: -80.0555,
    id: 1840001719,
  },
  {
    name: "Bainbridge Island",
    state_id: "WA",
    lat: 47.6439,
    lng: -122.5434,
    id: 1840018409,
  },
  {
    name: "Caledonia",
    state_id: "WI",
    lat: 42.7986,
    lng: -87.8762,
    id: 1840010091,
  },
  {
    name: "Champlin",
    state_id: "MN",
    lat: 45.1702,
    lng: -93.3903,
    id: 1840006746,
  },
  {
    name: "Edwardsville",
    state_id: "IL",
    lat: 38.7923,
    lng: -89.9877,
    id: 1840008564,
  },
  {
    name: "South Bradenton",
    state_id: "FL",
    lat: 27.4612,
    lng: -82.5821,
    id: 1840029076,
  },
  {
    name: "Fairland",
    state_id: "MD",
    lat: 39.0804,
    lng: -76.9527,
    id: 1840001593,
  },
  {
    name: "Palm Springs",
    state_id: "FL",
    lat: 26.6349,
    lng: -80.0968,
    id: 1840018308,
  },
  {
    name: "Elk River",
    state_id: "MN",
    lat: 45.3314,
    lng: -93.567,
    id: 1840007794,
  },
  {
    name: "Rosemount",
    state_id: "MN",
    lat: 44.7465,
    lng: -93.0662,
    id: 1840008951,
  },
  {
    name: "Fountain Hills",
    state_id: "AZ",
    lat: 33.6073,
    lng: -111.7398,
    id: 1840021945,
  },
  {
    name: "Belvidere",
    state_id: "IL",
    lat: 42.2544,
    lng: -88.8649,
    id: 1840006971,
  },
  {
    name: "Riverside",
    state_id: "OH",
    lat: 39.7836,
    lng: -84.1219,
    id: 1840012490,
  },
  {
    name: "Middle River",
    state_id: "MD",
    lat: 39.3345,
    lng: -76.4318,
    id: 1840005682,
  },
  {
    name: "Muskego",
    state_id: "WI",
    lat: 42.886,
    lng: -88.1291,
    id: 1840003023,
  },
  {
    name: "Levittown",
    state_id: "PR",
    lat: 18.4454,
    lng: -66.1759,
    id: 1630035506,
  },
  {
    name: "Hazelwood",
    state_id: "MO",
    lat: 38.7931,
    lng: -90.3899,
    id: 1840007453,
  },
  {
    name: "Scottsbluff",
    state_id: "NE",
    lat: 41.8684,
    lng: -103.6616,
    id: 1840009197,
  },
  {
    name: "Fresno",
    state_id: "TX",
    lat: 29.5358,
    lng: -95.4696,
    id: 1840018266,
  },
  {
    name: "Ridgewood",
    state_id: "NJ",
    lat: 40.9822,
    lng: -74.1128,
    id: 1840000899,
  },
  {
    name: "Yucca Valley",
    state_id: "CA",
    lat: 34.1234,
    lng: -116.4216,
    id: 1840022726,
  },
  {
    name: "Branson",
    state_id: "MO",
    lat: 36.651,
    lng: -93.2635,
    id: 1840007659,
  },
  {
    name: "Balch Springs",
    state_id: "TX",
    lat: 32.7194,
    lng: -96.6151,
    id: 1840019435,
  },
  {
    name: "University Park",
    state_id: "TX",
    lat: 32.8506,
    lng: -96.7937,
    id: 1840022055,
  },
  {
    name: "Timberwood Park",
    state_id: "TX",
    lat: 29.6994,
    lng: -98.4838,
    id: 1840019651,
  },
  {
    name: "De Pere",
    state_id: "WI",
    lat: 44.4309,
    lng: -88.0785,
    id: 1840002348,
  },
  {
    name: "Socastee",
    state_id: "SC",
    lat: 33.6871,
    lng: -79.0086,
    id: 1840013647,
  },
  {
    name: "Terrytown",
    state_id: "LA",
    lat: 29.9014,
    lng: -90.0279,
    id: 1840013990,
  },
  {
    name: "Fajardo",
    state_id: "PR",
    lat: 18.333,
    lng: -65.6592,
    id: 1630035668,
  },
  {
    name: "Marysville",
    state_id: "OH",
    lat: 40.2278,
    lng: -83.3595,
    id: 1840008355,
  },
  {
    name: "Derby",
    state_id: "KS",
    lat: 37.5571,
    lng: -97.2552,
    id: 1840007569,
  },
  {
    name: "Wilsonville",
    state_id: "OR",
    lat: 45.3107,
    lng: -122.7705,
    id: 1840021219,
  },
  {
    name: "Clayton",
    state_id: "NC",
    lat: 35.6591,
    lng: -78.4499,
    id: 1840016290,
  },
  {
    name: "Brookings",
    state_id: "SD",
    lat: 44.3022,
    lng: -96.7859,
    id: 1840002506,
  },
  {
    name: "Riverbank",
    state_id: "CA",
    lat: 37.7268,
    lng: -120.9402,
    id: 1840020286,
  },
  {
    name: "North Laurel",
    state_id: "MD",
    lat: 39.1286,
    lng: -76.8476,
    id: 1840031495,
  },
  {
    name: "Walker",
    state_id: "MI",
    lat: 42.9853,
    lng: -85.7446,
    id: 1840002925,
  },
  {
    name: "Wyandotte",
    state_id: "MI",
    lat: 42.2113,
    lng: -83.1558,
    id: 1840001849,
  },
  {
    name: "Grandview",
    state_id: "MO",
    lat: 38.8802,
    lng: -94.5227,
    id: 1840008532,
  },
  {
    name: "Forest Lake",
    state_id: "MN",
    lat: 45.2536,
    lng: -92.9583,
    id: 1840007808,
  },
  {
    name: "Chesapeake Beach",
    state_id: "MD",
    lat: 38.6881,
    lng: -76.5448,
    id: 1840006151,
  },
  {
    name: "Palm City",
    state_id: "FL",
    lat: 27.1736,
    lng: -80.2861,
    id: 1840014192,
  },
  {
    name: "Hudson",
    state_id: "WI",
    lat: 44.9637,
    lng: -92.7316,
    id: 1840002135,
  },
  {
    name: "Clarksburg",
    state_id: "MD",
    lat: 39.2314,
    lng: -77.2617,
    id: 1840005830,
  },
  {
    name: "South Elgin",
    state_id: "IL",
    lat: 41.9906,
    lng: -88.3134,
    id: 1840013564,
  },
  {
    name: "Auburn Hills",
    state_id: "MI",
    lat: 42.6735,
    lng: -83.2447,
    id: 1840002436,
  },
  {
    name: "Baldwin",
    state_id: "NY",
    lat: 40.6634,
    lng: -73.6104,
    id: 1840005228,
  },
  {
    name: "North Tustin",
    state_id: "CA",
    lat: 33.7644,
    lng: -117.7945,
    id: 1840019319,
  },
  {
    name: "Ontario",
    state_id: "OR",
    lat: 44.0259,
    lng: -116.9759,
    id: 1840020002,
  },
  {
    name: "Melrose Park",
    state_id: "IL",
    lat: 41.9029,
    lng: -87.8642,
    id: 1840011296,
  },
  {
    name: "Ardmore",
    state_id: "OK",
    lat: 34.1944,
    lng: -97.1253,
    id: 1840019258,
  },
  {
    name: "Hastings",
    state_id: "NE",
    lat: 40.5963,
    lng: -98.3914,
    id: 1840001130,
  },
  {
    name: "Burlington",
    state_id: "WI",
    lat: 42.6746,
    lng: -88.2723,
    id: 1840002491,
  },
  {
    name: "Norton Shores",
    state_id: "MI",
    lat: 43.1621,
    lng: -86.2519,
    id: 1840002873,
  },
  {
    name: "Kernersville",
    state_id: "NC",
    lat: 36.1066,
    lng: -80.0844,
    id: 1840016123,
  },
  {
    name: "Sugar Hill",
    state_id: "GA",
    lat: 34.1081,
    lng: -84.056,
    id: 1840015615,
  },
  {
    name: "Herndon",
    state_id: "VA",
    lat: 38.9699,
    lng: -77.3867,
    id: 1840006037,
  },
  {
    name: "Emporia",
    state_id: "KS",
    lat: 38.4028,
    lng: -96.1924,
    id: 1840001652,
  },
  {
    name: "Prosper",
    state_id: "TX",
    lat: 33.2394,
    lng: -96.8087,
    id: 1840022019,
  },
  {
    name: "Rockville Centre",
    state_id: "NY",
    lat: 40.6644,
    lng: -73.6383,
    id: 1840005320,
  },
  {
    name: "Palmetto Bay",
    state_id: "FL",
    lat: 25.622,
    lng: -80.3221,
    id: 1840018335,
  },
  {
    name: "Suitland",
    state_id: "MD",
    lat: 38.8492,
    lng: -76.9225,
    id: 1840031466,
  },
  {
    name: "Somerset",
    state_id: "NJ",
    lat: 40.5083,
    lng: -74.501,
    id: 1840005375,
  },
  {
    name: "Avon Lake",
    state_id: "OH",
    lat: 41.4945,
    lng: -82.016,
    id: 1840000642,
  },
  {
    name: "Lathrop",
    state_id: "CA",
    lat: 37.809,
    lng: -121.3131,
    id: 1840020266,
  },
  {
    name: "Loma Linda",
    state_id: "CA",
    lat: 34.0451,
    lng: -117.2498,
    id: 1840020406,
  },
  {
    name: "Watauga",
    state_id: "TX",
    lat: 32.8718,
    lng: -97.2515,
    id: 1840022049,
  },
  {
    name: "Homer Glen",
    state_id: "IL",
    lat: 41.6044,
    lng: -87.9497,
    id: 1840011483,
  },
  {
    name: "Tifton",
    state_id: "GA",
    lat: 31.4625,
    lng: -83.5205,
    id: 1840015863,
  },
  {
    name: "Dinuba",
    state_id: "CA",
    lat: 36.5453,
    lng: -119.3987,
    id: 1840019044,
  },
  {
    name: "Bloomingdale",
    state_id: "FL",
    lat: 27.8784,
    lng: -82.2624,
    id: 1840014150,
  },
  {
    name: "Westmont",
    state_id: "IL",
    lat: 41.7948,
    lng: -87.9742,
    id: 1840011414,
  },
  {
    name: "Clinton",
    state_id: "MS",
    lat: 32.354,
    lng: -90.3403,
    id: 1840014893,
  },
  {
    name: "Ashland",
    state_id: "CA",
    lat: 37.6942,
    lng: -122.1159,
    id: 1840017619,
  },
  {
    name: "Opelousas",
    state_id: "LA",
    lat: 30.528,
    lng: -92.0851,
    id: 1840015014,
  },
  {
    name: "Camas",
    state_id: "WA",
    lat: 45.6005,
    lng: -122.4305,
    id: 1840018524,
  },
  {
    name: "Harvey",
    state_id: "IL",
    lat: 41.6076,
    lng: -87.6521,
    id: 1840007029,
  },
  {
    name: "Medford",
    state_id: "NY",
    lat: 40.822,
    lng: -72.9859,
    id: 1840005043,
  },
  {
    name: "Trotwood",
    state_id: "OH",
    lat: 39.7927,
    lng: -84.3165,
    id: 1840010582,
  },
  {
    name: "West Memphis",
    state_id: "AR",
    lat: 35.1531,
    lng: -90.1995,
    id: 1840015448,
  },
  {
    name: "Collinsville",
    state_id: "IL",
    lat: 38.6769,
    lng: -90.0059,
    id: 1840007423,
  },
  {
    name: "Mequon",
    state_id: "WI",
    lat: 43.2352,
    lng: -87.9838,
    id: 1840037989,
  },
  {
    name: "Ellensburg",
    state_id: "WA",
    lat: 46.9994,
    lng: -120.5477,
    id: 1840019841,
  },
  {
    name: "Ottumwa",
    state_id: "IA",
    lat: 41.0196,
    lng: -92.4186,
    id: 1840009339,
  },
  {
    name: "Chantilly",
    state_id: "VA",
    lat: 38.8868,
    lng: -77.4453,
    id: 1840006016,
  },
  {
    name: "Shelbyville",
    state_id: "KY",
    lat: 38.2069,
    lng: -85.2293,
    id: 1840015205,
  },
  {
    name: "Woodlawn",
    state_id: "VA",
    lat: 38.7332,
    lng: -77.1149,
    id: 1840145210,
  },
  {
    name: "Lodi",
    state_id: "NJ",
    lat: 40.8784,
    lng: -74.0814,
    id: 1840003535,
  },
  {
    name: "Orchards",
    state_id: "WA",
    lat: 45.689,
    lng: -122.5306,
    id: 1840018519,
  },
  {
    name: "Newport",
    state_id: "RI",
    lat: 41.4803,
    lng: -71.3205,
    id: 1840003355,
  },
  {
    name: "San Fernando",
    state_id: "CA",
    lat: 34.2886,
    lng: -118.4363,
    id: 1840021861,
  },
  {
    name: "Saginaw",
    state_id: "TX",
    lat: 32.8657,
    lng: -97.3654,
    id: 1840022046,
  },
  {
    name: "Peekskill",
    state_id: "NY",
    lat: 41.2883,
    lng: -73.9227,
    id: 1840000783,
  },
  {
    name: "Port Angeles",
    state_id: "WA",
    lat: 48.1142,
    lng: -123.4565,
    id: 1840019782,
  },
  {
    name: "Mill Creek East",
    state_id: "WA",
    lat: 47.836,
    lng: -122.1877,
    id: 1840041996,
  },
  {
    name: "Brandon",
    state_id: "MS",
    lat: 32.2778,
    lng: -89.9896,
    id: 1840013825,
  },
  {
    name: "Sun City Center",
    state_id: "FL",
    lat: 27.7149,
    lng: -82.3569,
    id: 1840001841,
  },
  {
    name: "Inkster",
    state_id: "MI",
    lat: 42.2935,
    lng: -83.3148,
    id: 1840003983,
  },
  {
    name: "Thomasville",
    state_id: "GA",
    lat: 30.8394,
    lng: -83.9782,
    id: 1840015893,
  },
  {
    name: "Ponca City",
    state_id: "OK",
    lat: 36.7235,
    lng: -97.0679,
    id: 1840020345,
  },
  {
    name: "Cataño",
    state_id: "PR",
    lat: 18.4415,
    lng: -66.1388,
    id: 1630023554,
  },
  {
    name: "Tysons",
    state_id: "VA",
    lat: 38.9215,
    lng: -77.2273,
    id: 1840006040,
  },
  {
    name: "West Melbourne",
    state_id: "FL",
    lat: 28.0693,
    lng: -80.6734,
    id: 1840015963,
  },
  {
    name: "Faribault",
    state_id: "MN",
    lat: 44.2985,
    lng: -93.2786,
    id: 1840007889,
  },
  {
    name: "San Benito",
    state_id: "TX",
    lat: 26.1298,
    lng: -97.644,
    id: 1840022260,
  },
  {
    name: "Fort Washington",
    state_id: "MD",
    lat: 38.7339,
    lng: -77.0069,
    id: 1840005953,
  },
  {
    name: "Lincolnton",
    state_id: "NC",
    lat: 35.4747,
    lng: -81.2385,
    id: 1840015427,
  },
  {
    name: "West Springfield",
    state_id: "VA",
    lat: 38.7771,
    lng: -77.2268,
    id: 1840006041,
  },
  {
    name: "Simpsonville",
    state_id: "SC",
    lat: 34.7287,
    lng: -82.2573,
    id: 1840015477,
  },
  {
    name: "Waverly",
    state_id: "MI",
    lat: 42.7401,
    lng: -84.6353,
    id: 1840006924,
  },
  {
    name: "Bailey's Crossroads",
    state_id: "VA",
    lat: 38.8477,
    lng: -77.1305,
    id: 1840037327,
  },
  {
    name: "Okemos",
    state_id: "MI",
    lat: 42.7057,
    lng: -84.4135,
    id: 1840004502,
  },
  {
    name: "Ridgeland",
    state_id: "MS",
    lat: 32.4236,
    lng: -90.1481,
    id: 1840014873,
  },
  {
    name: "Elmwood Park",
    state_id: "IL",
    lat: 41.9225,
    lng: -87.8163,
    id: 1840011272,
  },
  {
    name: "North Platte",
    state_id: "NE",
    lat: 41.1266,
    lng: -100.764,
    id: 1840008251,
  },
  {
    name: "Charles Town",
    state_id: "WV",
    lat: 39.2745,
    lng: -77.8632,
    id: 1840005759,
  },
  {
    name: "Freeport",
    state_id: "IL",
    lat: 42.2893,
    lng: -89.6347,
    id: 1840008083,
  },
  {
    name: "Waukee",
    state_id: "IA",
    lat: 41.5984,
    lng: -93.8872,
    id: 1840010181,
  },
  {
    name: "South Plainfield",
    state_id: "NJ",
    lat: 40.5748,
    lng: -74.4153,
    id: 1840001334,
  },
  {
    name: "Wadsworth",
    state_id: "OH",
    lat: 41.0279,
    lng: -81.7324,
    id: 1840010294,
  },
  {
    name: "Tumwater",
    state_id: "WA",
    lat: 46.989,
    lng: -122.9173,
    id: 1840021145,
  },
  {
    name: "Fairfax",
    state_id: "VA",
    lat: 38.8531,
    lng: -77.2998,
    id: 1840001650,
  },
  {
    name: "Candler-McAfee",
    state_id: "GA",
    lat: 33.7267,
    lng: -84.2723,
    id: 1840073846,
  },
  {
    name: "Oak Ridge",
    state_id: "FL",
    lat: 28.4727,
    lng: -81.4169,
    id: 1840029062,
  },
  {
    name: "Venice",
    state_id: "FL",
    lat: 27.1163,
    lng: -82.4135,
    id: 1840015989,
  },
  {
    name: "Wright",
    state_id: "FL",
    lat: 30.4445,
    lng: -86.6419,
    id: 1840013921,
  },
  {
    name: "Lincolnia",
    state_id: "VA",
    lat: 38.8158,
    lng: -77.1543,
    id: 1840006034,
  },
  {
    name: "Westchase",
    state_id: "FL",
    lat: 28.0597,
    lng: -82.611,
    id: 1840029017,
  },
  {
    name: "Edgewater",
    state_id: "FL",
    lat: 28.9594,
    lng: -80.9407,
    id: 1840014043,
  },
  {
    name: "North Potomac",
    state_id: "MD",
    lat: 39.0955,
    lng: -77.2372,
    id: 1840005835,
  },
  {
    name: "Pelham",
    state_id: "AL",
    lat: 33.3114,
    lng: -86.7573,
    id: 1840014826,
  },
  {
    name: "Corsicana",
    state_id: "TX",
    lat: 32.0824,
    lng: -96.4665,
    id: 1840019505,
  },
  {
    name: "Seal Beach",
    state_id: "CA",
    lat: 33.7542,
    lng: -118.0714,
    id: 1840021965,
  },
  {
    name: "Fort Dodge",
    state_id: "IA",
    lat: 42.5098,
    lng: -94.1751,
    id: 1840000439,
  },
  {
    name: "Dunkirk",
    state_id: "NY",
    lat: 42.4803,
    lng: -79.3323,
    id: 1840000445,
  },
  {
    name: "Calabasas",
    state_id: "CA",
    lat: 34.1375,
    lng: -118.6689,
    id: 1840019234,
  },
  {
    name: "Chalmette",
    state_id: "LA",
    lat: 29.9437,
    lng: -89.9659,
    id: 1840014001,
  },
  {
    name: "North Augusta",
    state_id: "SC",
    lat: 33.5214,
    lng: -81.9547,
    id: 1840014788,
  },
  {
    name: "Palm River-Clair Mel",
    state_id: "FL",
    lat: 27.9239,
    lng: -82.3791,
    id: 1840073873,
  },
  {
    name: "Mount Pocono",
    state_id: "PA",
    lat: 41.1225,
    lng: -75.3582,
    id: 1840003508,
  },
  {
    name: "Farragut",
    state_id: "TN",
    lat: 35.8731,
    lng: -84.1822,
    id: 1840016171,
  },
  {
    name: "Jenks",
    state_id: "OK",
    lat: 35.9981,
    lng: -95.9737,
    id: 1840019060,
  },
  {
    name: "Palatka",
    state_id: "FL",
    lat: 29.6493,
    lng: -81.6705,
    id: 1840015062,
  },
  {
    name: "Coronado",
    state_id: "CA",
    lat: 32.664,
    lng: -117.16,
    id: 1840019354,
  },
  {
    name: "Valinda",
    state_id: "CA",
    lat: 34.0401,
    lng: -117.93,
    id: 1840019213,
  },
  {
    name: "Centerville",
    state_id: "OH",
    lat: 39.6339,
    lng: -84.1449,
    id: 1840007308,
  },
  {
    name: "Van Buren",
    state_id: "AR",
    lat: 35.448,
    lng: -94.3528,
    id: 1840015397,
  },
  {
    name: "Greeneville",
    state_id: "TN",
    lat: 36.168,
    lng: -82.8197,
    id: 1840016099,
  },
  {
    name: "Waycross",
    state_id: "GA",
    lat: 31.2108,
    lng: -82.3579,
    id: 1840015871,
  },
  {
    name: "Half Moon Bay",
    state_id: "CA",
    lat: 37.4688,
    lng: -122.4383,
    id: 1840020303,
  },
  {
    name: "Belton",
    state_id: "MO",
    lat: 38.8193,
    lng: -94.5334,
    id: 1840007463,
  },
  {
    name: "Jacksonville Beach",
    state_id: "FL",
    lat: 30.2782,
    lng: -81.4045,
    id: 1840015032,
  },
  {
    name: "North Lynnwood",
    state_id: "WA",
    lat: 47.8533,
    lng: -122.2762,
    id: 1840037895,
  },
  {
    name: "Ilion",
    state_id: "NY",
    lat: 43.0114,
    lng: -75.0396,
    id: 1840004149,
  },
  {
    name: "Rosemont",
    state_id: "CA",
    lat: 38.5477,
    lng: -121.3553,
    id: 1840018844,
  },
  {
    name: "Seymour",
    state_id: "IN",
    lat: 38.9476,
    lng: -85.891,
    id: 1840009708,
  },
  {
    name: "Oakdale",
    state_id: "CA",
    lat: 37.7616,
    lng: -120.847,
    id: 1840020289,
  },
  {
    name: "Madisonville",
    state_id: "KY",
    lat: 37.3408,
    lng: -87.5034,
    id: 1840015244,
  },
  {
    name: "Romulus",
    state_id: "MI",
    lat: 42.2237,
    lng: -83.366,
    id: 1840003963,
  },
  {
    name: "Cudahy",
    state_id: "CA",
    lat: 33.9631,
    lng: -118.183,
    id: 1840019242,
  },
  {
    name: "Copiague",
    state_id: "NY",
    lat: 40.6728,
    lng: -73.3932,
    id: 1840005031,
  },
  {
    name: "Winter Gardens",
    state_id: "CA",
    lat: 32.8376,
    lng: -116.9268,
    id: 1840019351,
  },
  {
    name: "Leland",
    state_id: "NC",
    lat: 34.2044,
    lng: -78.0277,
    id: 1840016683,
  },
  {
    name: "Rolling Meadows",
    state_id: "IL",
    lat: 42.0747,
    lng: -88.0252,
    id: 1840009185,
  },
  {
    name: "Wekiwa Springs",
    state_id: "FL",
    lat: 28.6984,
    lng: -81.4251,
    id: 1840029016,
  },
  {
    name: "Northdale",
    state_id: "FL",
    lat: 28.1058,
    lng: -82.5263,
    id: 1840029107,
  },
  {
    name: "Fort Hood",
    state_id: "TX",
    lat: 31.1345,
    lng: -97.7797,
    id: 1840073932,
  },
  {
    name: "Benbrook",
    state_id: "TX",
    lat: 32.6787,
    lng: -97.4638,
    id: 1840019425,
  },
  {
    name: "Zion",
    state_id: "IL",
    lat: 42.4598,
    lng: -87.8509,
    id: 1840010114,
  },
  {
    name: "Watertown",
    state_id: "WI",
    lat: 43.1893,
    lng: -88.7285,
    id: 1840003000,
  },
  {
    name: "Montrose",
    state_id: "CO",
    lat: 38.469,
    lng: -107.859,
    id: 1840020251,
  },
  {
    name: "Hialeah Gardens",
    state_id: "FL",
    lat: 25.8878,
    lng: -80.3569,
    id: 1840015158,
  },
  {
    name: "Columbus",
    state_id: "NE",
    lat: 41.4368,
    lng: -97.3563,
    id: 1840003345,
  },
  {
    name: "Plainview",
    state_id: "TX",
    lat: 34.1911,
    lng: -101.7234,
    id: 1840020538,
  },
  {
    name: "Auburn",
    state_id: "ME",
    lat: 44.085,
    lng: -70.2492,
    id: 1840000319,
  },
  {
    name: "Carteret",
    state_id: "NJ",
    lat: 40.5848,
    lng: -74.2284,
    id: 1840001331,
  },
  {
    name: "Arnold",
    state_id: "MD",
    lat: 39.0437,
    lng: -76.4974,
    id: 1840005907,
  },
  {
    name: "Avon",
    state_id: "OH",
    lat: 41.4485,
    lng: -82.0187,
    id: 1840000641,
  },
  {
    name: "West Puente Valley",
    state_id: "CA",
    lat: 34.0512,
    lng: -117.9681,
    id: 1840028409,
  },
  {
    name: "Loves Park",
    state_id: "IL",
    lat: 42.3364,
    lng: -88.9975,
    id: 1840009130,
  },
  {
    name: "Vero Beach South",
    state_id: "FL",
    lat: 27.6164,
    lng: -80.413,
    id: 1840073853,
  },
  {
    name: "Lisle",
    state_id: "IL",
    lat: 41.7918,
    lng: -88.0888,
    id: 1840011401,
  },
  {
    name: "Greenbelt",
    state_id: "MD",
    lat: 38.9953,
    lng: -76.8885,
    id: 1840005974,
  },
  {
    name: "Granite Bay",
    state_id: "CA",
    lat: 38.7601,
    lng: -121.1714,
    id: 1840017559,
  },
  {
    name: "Maywood",
    state_id: "IL",
    lat: 41.8798,
    lng: -87.8442,
    id: 1840011294,
  },
  {
    name: "Dickinson",
    state_id: "ND",
    lat: 46.8917,
    lng: -102.7924,
    id: 1840000192,
  },
  {
    name: "Oxford",
    state_id: "OH",
    lat: 39.5061,
    lng: -84.7434,
    id: 1840009572,
  },
  {
    name: "Kenmore",
    state_id: "WA",
    lat: 47.7516,
    lng: -122.2489,
    id: 1840018423,
  },
  {
    name: "Allison Park",
    state_id: "PA",
    lat: 40.573,
    lng: -79.9603,
    id: 1840026468,
  },
  {
    name: "Farmington",
    state_id: "MN",
    lat: 44.6573,
    lng: -93.1688,
    id: 1840007853,
  },
  {
    name: "Monsey",
    state_id: "NY",
    lat: 41.1181,
    lng: -74.0681,
    id: 1840004961,
  },
  {
    name: "Hastings",
    state_id: "MN",
    lat: 44.7318,
    lng: -92.8538,
    id: 1840006770,
  },
  {
    name: "Rockingham",
    state_id: "NC",
    lat: 34.9386,
    lng: -79.7608,
    id: 1840014617,
  },
  {
    name: "Greenfield",
    state_id: "IN",
    lat: 39.7936,
    lng: -85.7737,
    id: 1840014673,
  },
  {
    name: "Auburn",
    state_id: "IN",
    lat: 41.3666,
    lng: -85.0559,
    id: 1840007100,
  },
  {
    name: "Frederickson",
    state_id: "WA",
    lat: 47.0914,
    lng: -122.3594,
    id: 1840037551,
  },
  {
    name: "Willoughby",
    state_id: "OH",
    lat: 41.646,
    lng: -81.4084,
    id: 1840000547,
  },
  {
    name: "Mustang",
    state_id: "OK",
    lat: 35.3917,
    lng: -97.7246,
    id: 1840020421,
  },
  {
    name: "Patterson",
    state_id: "CA",
    lat: 37.4758,
    lng: -121.1535,
    id: 1840020290,
  },
  {
    name: "Gallup",
    state_id: "NM",
    lat: 35.5183,
    lng: -108.7423,
    id: 1840020395,
  },
  {
    name: "Radcliff",
    state_id: "KY",
    lat: 37.8206,
    lng: -85.9366,
    id: 1840014329,
  },
  {
    name: "West Rancho Dominguez",
    state_id: "CA",
    lat: 33.9057,
    lng: -118.2682,
    id: 1840028406,
  },
  {
    name: "Blue Island",
    state_id: "IL",
    lat: 41.6578,
    lng: -87.6812,
    id: 1840007028,
  },
  {
    name: "Crystal",
    state_id: "MN",
    lat: 45.0377,
    lng: -93.3599,
    id: 1840006745,
  },
  {
    name: "Belton",
    state_id: "TX",
    lat: 31.0525,
    lng: -97.479,
    id: 1840019556,
  },
  {
    name: "Pace",
    state_id: "FL",
    lat: 30.6187,
    lng: -87.1667,
    id: 1840013919,
  },
  {
    name: "Marshall",
    state_id: "TX",
    lat: 32.537,
    lng: -94.3515,
    id: 1840020739,
  },
  {
    name: "Laguna Beach",
    state_id: "CA",
    lat: 33.5448,
    lng: -117.7612,
    id: 1840020587,
  },
  {
    name: "Webster Groves",
    state_id: "MO",
    lat: 38.5866,
    lng: -90.3544,
    id: 1840010744,
  },
  {
    name: "Acworth",
    state_id: "GA",
    lat: 34.0566,
    lng: -84.6715,
    id: 1840013688,
  },
  {
    name: "Griffin",
    state_id: "GA",
    lat: 33.2418,
    lng: -84.2747,
    id: 1840013776,
  },
  {
    name: "Willowbrook",
    state_id: "CA",
    lat: 33.9199,
    lng: -118.2362,
    id: 1840019220,
  },
  {
    name: "Morton Grove",
    state_id: "IL",
    lat: 42.0423,
    lng: -87.789,
    id: 1840011299,
  },
  {
    name: "Keene",
    state_id: "NH",
    lat: 42.9494,
    lng: -72.2997,
    id: 1840003050,
  },
  {
    name: "Marina",
    state_id: "CA",
    lat: 36.6812,
    lng: -121.7895,
    id: 1840020357,
  },
  {
    name: "Solon",
    state_id: "OH",
    lat: 41.3864,
    lng: -81.4401,
    id: 1840003413,
  },
  {
    name: "New Brighton",
    state_id: "MN",
    lat: 45.0658,
    lng: -93.206,
    id: 1840007841,
  },
  {
    name: "Mandan",
    state_id: "ND",
    lat: 46.829,
    lng: -100.887,
    id: 1840000199,
  },
  {
    name: "Pittsburg",
    state_id: "KS",
    lat: 37.4129,
    lng: -94.6984,
    id: 1840001697,
  },
  {
    name: "Elizabeth City",
    state_id: "NC",
    lat: 36.2943,
    lng: -76.2361,
    id: 1840013315,
  },
  {
    name: "Harriman",
    state_id: "TN",
    lat: 35.9307,
    lng: -84.5602,
    id: 1840013385,
  },
  {
    name: "Ives Estates",
    state_id: "FL",
    lat: 25.9632,
    lng: -80.183,
    id: 1840028705,
  },
  {
    name: "Greenfield",
    state_id: "MA",
    lat: 42.614,
    lng: -72.5985,
    id: 1840132426,
  },
  {
    name: "Fairhope",
    state_id: "AL",
    lat: 30.5217,
    lng: -87.8815,
    id: 1840002039,
  },
  {
    name: "Cottage Lake",
    state_id: "WA",
    lat: 47.7466,
    lng: -122.0755,
    id: 1840037848,
  },
  {
    name: "Machesney Park",
    state_id: "IL",
    lat: 42.3666,
    lng: -89.0266,
    id: 1840011123,
  },
  {
    name: "Braidwood",
    state_id: "IL",
    lat: 41.2696,
    lng: -88.2235,
    id: 1840007090,
  },
  {
    name: "Hurricane",
    state_id: "UT",
    lat: 37.1487,
    lng: -113.3517,
    id: 1840018936,
  },
  {
    name: "Trussville",
    state_id: "AL",
    lat: 33.6404,
    lng: -86.5819,
    id: 1840015672,
  },
  {
    name: "Waynesboro",
    state_id: "VA",
    lat: 38.0674,
    lng: -78.9014,
    id: 1840003848,
  },
  {
    name: "Johnston",
    state_id: "IA",
    lat: 41.6857,
    lng: -93.7173,
    id: 1840008170,
  },
  {
    name: "Corcoran",
    state_id: "CA",
    lat: 36.0839,
    lng: -119.561,
    id: 1840019056,
  },
  {
    name: "Meadville",
    state_id: "PA",
    lat: 41.6476,
    lng: -80.1468,
    id: 1840000558,
  },
  {
    name: "Landover",
    state_id: "MD",
    lat: 38.9241,
    lng: -76.8875,
    id: 1840005956,
  },
  {
    name: "Happy Valley",
    state_id: "OR",
    lat: 45.4362,
    lng: -122.5077,
    id: 1840019950,
  },
  {
    name: "East Peoria",
    state_id: "IL",
    lat: 40.6734,
    lng: -89.5419,
    id: 1840008327,
  },
  {
    name: "Hopewell",
    state_id: "VA",
    lat: 37.2915,
    lng: -77.2985,
    id: 1840003859,
  },
  {
    name: "Fort Walton Beach",
    state_id: "FL",
    lat: 30.4249,
    lng: -86.6194,
    id: 1840013923,
  },
  {
    name: "Nixa",
    state_id: "MO",
    lat: 37.0453,
    lng: -93.296,
    id: 1840008789,
  },
  {
    name: "Clinton",
    state_id: "UT",
    lat: 41.1395,
    lng: -112.0656,
    id: 1840018736,
  },
  {
    name: "Munster",
    state_id: "IN",
    lat: 41.5469,
    lng: -87.504,
    id: 1840009262,
  },
  {
    name: "Christiansburg",
    state_id: "VA",
    lat: 37.1411,
    lng: -80.4028,
    id: 1840006447,
  },
  {
    name: "Anthem",
    state_id: "AZ",
    lat: 33.856,
    lng: -112.1168,
    id: 1840022919,
  },
  {
    name: "Roselle",
    state_id: "IL",
    lat: 41.9807,
    lng: -88.0861,
    id: 1840011407,
  },
  {
    name: "Garden City",
    state_id: "NY",
    lat: 40.7266,
    lng: -73.6447,
    id: 1840005289,
  },
  {
    name: "The Crossings",
    state_id: "FL",
    lat: 25.6708,
    lng: -80.4018,
    id: 1840029008,
  },
  {
    name: "Warrensburg",
    state_id: "MO",
    lat: 38.7627,
    lng: -93.726,
    id: 1840010721,
  },
  {
    name: "Kihei",
    state_id: "HI",
    lat: 20.7653,
    lng: -156.4454,
    id: 1840029507,
  },
  {
    name: "Union City",
    state_id: "GA",
    lat: 33.5941,
    lng: -84.5629,
    id: 1840015610,
  },
  {
    name: "Millbrae",
    state_id: "CA",
    lat: 37.5994,
    lng: -122.4023,
    id: 1840020306,
  },
  {
    name: "Florida Ridge",
    state_id: "FL",
    lat: 27.5805,
    lng: -80.3848,
    id: 1840029035,
  },
  {
    name: "Silver Firs",
    state_id: "WA",
    lat: 47.8635,
    lng: -122.1497,
    id: 1840037898,
  },
  {
    name: "Dolton",
    state_id: "IL",
    lat: 41.6284,
    lng: -87.5979,
    id: 1840011269,
  },
  {
    name: "Duncan",
    state_id: "OK",
    lat: 34.5424,
    lng: -97.919,
    id: 1840019253,
  },
  {
    name: "Brent",
    state_id: "FL",
    lat: 30.4729,
    lng: -87.2496,
    id: 1840013913,
  },
  {
    name: "Northfield",
    state_id: "MN",
    lat: 44.455,
    lng: -93.1698,
    id: 1840007891,
  },
  {
    name: "Highland",
    state_id: "IN",
    lat: 41.5485,
    lng: -87.4587,
    id: 1840010226,
  },
  {
    name: "Sequim",
    state_id: "WA",
    lat: 48.0746,
    lng: -123.0944,
    id: 1840021076,
  },
  {
    name: "Prairie Village",
    state_id: "KS",
    lat: 38.9874,
    lng: -94.6361,
    id: 1840001644,
  },
  {
    name: "Kuna",
    state_id: "ID",
    lat: 43.4869,
    lng: -116.3986,
    id: 1840020047,
  },
  {
    name: "Coralville",
    state_id: "IA",
    lat: 41.699,
    lng: -91.5967,
    id: 1840007076,
  },
  {
    name: "Fort Mill",
    state_id: "SC",
    lat: 35.0062,
    lng: -80.9388,
    id: 1840016479,
  },
  {
    name: "Rose Hill",
    state_id: "VA",
    lat: 38.7872,
    lng: -77.1085,
    id: 1840133323,
  },
  {
    name: "Hudson",
    state_id: "OH",
    lat: 41.2399,
    lng: -81.4408,
    id: 1840007138,
  },
  {
    name: "Lutz",
    state_id: "FL",
    lat: 28.1396,
    lng: -82.4467,
    id: 1840014156,
  },
  {
    name: "Taylors",
    state_id: "SC",
    lat: 34.9157,
    lng: -82.3124,
    id: 1840013498,
  },
  {
    name: "Roanoke Rapids",
    state_id: "NC",
    lat: 36.4452,
    lng: -77.6491,
    id: 1840014437,
  },
  {
    name: "Vega Baja",
    state_id: "PR",
    lat: 18.441,
    lng: -66.3993,
    id: 1630023572,
  },
  {
    name: "Raymore",
    state_id: "MO",
    lat: 38.8029,
    lng: -94.4583,
    id: 1840009787,
  },
  {
    name: "Ensley",
    state_id: "FL",
    lat: 30.5261,
    lng: -87.2735,
    id: 1840013104,
  },
  {
    name: "Fremont",
    state_id: "OH",
    lat: 41.3536,
    lng: -83.1148,
    id: 1840000653,
  },
  {
    name: "Mukwonago",
    state_id: "WI",
    lat: 42.8566,
    lng: -88.3269,
    id: 1840003022,
  },
  {
    name: "Watertown",
    state_id: "SD",
    lat: 44.9094,
    lng: -97.1531,
    id: 1840002156,
  },
  {
    name: "East Patchogue",
    state_id: "NY",
    lat: 40.7704,
    lng: -72.9817,
    id: 1840005040,
  },
  {
    name: "Lebanon",
    state_id: "OR",
    lat: 44.5317,
    lng: -122.9071,
    id: 1840019991,
  },
  {
    name: "Pickerington",
    state_id: "OH",
    lat: 39.889,
    lng: -82.7678,
    id: 1840009528,
  },
  {
    name: "Lexington",
    state_id: "SC",
    lat: 33.989,
    lng: -81.2202,
    id: 1840016724,
  },
  {
    name: "Arvin",
    state_id: "CA",
    lat: 35.1944,
    lng: -118.8306,
    id: 1840019147,
  },
  {
    name: "Lino Lakes",
    state_id: "MN",
    lat: 45.1679,
    lng: -93.083,
    id: 1840008902,
  },
  {
    name: "Augusta",
    state_id: "ME",
    lat: 44.3341,
    lng: -69.7319,
    id: 1840000302,
  },
  {
    name: "Milford",
    state_id: "DE",
    lat: 38.9091,
    lng: -75.4224,
    id: 1840006069,
  },
  {
    name: "Shelbyville",
    state_id: "TN",
    lat: 35.4987,
    lng: -86.4516,
    id: 1840015412,
  },
  {
    name: "Corinth",
    state_id: "TX",
    lat: 33.1435,
    lng: -97.0681,
    id: 1840019389,
  },
  {
    name: "Naples",
    state_id: "FL",
    lat: 26.1504,
    lng: -81.7936,
    id: 1840015131,
  },
  {
    name: "West Carson",
    state_id: "CA",
    lat: 33.8229,
    lng: -118.2931,
    id: 1840028405,
  },
  {
    name: "Maple Heights",
    state_id: "OH",
    lat: 41.4094,
    lng: -81.5625,
    id: 1840000611,
  },
  {
    name: "Lealman",
    state_id: "FL",
    lat: 27.8197,
    lng: -82.6847,
    id: 1840025161,
  },
  {
    name: "Chester",
    state_id: "VA",
    lat: 37.3531,
    lng: -77.4342,
    id: 1840006410,
  },
  {
    name: "Gardner",
    state_id: "KS",
    lat: 38.8158,
    lng: -94.93,
    id: 1840001636,
  },
  {
    name: "Union Hill-Novelty Hill",
    state_id: "WA",
    lat: 47.6788,
    lng: -122.0284,
    id: 1840074663,
  },
  {
    name: "Hayesville",
    state_id: "OR",
    lat: 44.9794,
    lng: -122.9739,
    id: 1840034726,
  },
  {
    name: "Siloam Springs",
    state_id: "AR",
    lat: 36.1844,
    lng: -94.5318,
    id: 1840015288,
  },
  {
    name: "Piqua",
    state_id: "OH",
    lat: 40.1505,
    lng: -84.2441,
    id: 1840009467,
  },
  {
    name: "Fish Hawk",
    state_id: "FL",
    lat: 27.8511,
    lng: -82.2164,
    id: 1840028982,
  },
  {
    name: "Port Hueneme",
    state_id: "CA",
    lat: 34.1617,
    lng: -119.2036,
    id: 1840020475,
  },
  {
    name: "Lakeside",
    state_id: "CA",
    lat: 32.856,
    lng: -116.904,
    id: 1840018025,
  },
  {
    name: "Summit",
    state_id: "NJ",
    lat: 40.7154,
    lng: -74.3647,
    id: 1840000999,
  },
  {
    name: "Secaucus",
    state_id: "NJ",
    lat: 40.781,
    lng: -74.0659,
    id: 1840001033,
  },
  {
    name: "Golden Valley",
    state_id: "MN",
    lat: 44.9901,
    lng: -93.3591,
    id: 1840007824,
  },
  {
    name: "Jasmine Estates",
    state_id: "FL",
    lat: 28.293,
    lng: -82.6907,
    id: 1840014116,
  },
  {
    name: "Mount Vernon",
    state_id: "OH",
    lat: 40.3854,
    lng: -82.4734,
    id: 1840001343,
  },
  {
    name: "Bloomington",
    state_id: "CA",
    lat: 34.0601,
    lng: -117.4013,
    id: 1840017799,
  },
  {
    name: "Camp Springs",
    state_id: "MD",
    lat: 38.8052,
    lng: -76.9198,
    id: 1840005947,
  },
  {
    name: "Lennox",
    state_id: "CA",
    lat: 33.938,
    lng: -118.3586,
    id: 1840017923,
  },
  {
    name: "Roselle",
    state_id: "NJ",
    lat: 40.6527,
    lng: -74.2599,
    id: 1840001092,
  },
  {
    name: "Geneva",
    state_id: "IL",
    lat: 41.8832,
    lng: -88.3242,
    id: 1840008135,
  },
  {
    name: "Sunny Isles Beach",
    state_id: "FL",
    lat: 25.9385,
    lng: -80.1246,
    id: 1840016007,
  },
  {
    name: "Rancho San Diego",
    state_id: "CA",
    lat: 32.7624,
    lng: -116.9197,
    id: 1840019347,
  },
  {
    name: "Massapequa",
    state_id: "NY",
    lat: 40.6676,
    lng: -73.4706,
    id: 1840005274,
  },
  {
    name: "Bloomingdale",
    state_id: "IL",
    lat: 41.9497,
    lng: -88.0895,
    id: 1840011399,
  },
  {
    name: "Shafter",
    state_id: "CA",
    lat: 35.4794,
    lng: -119.2013,
    id: 1840021735,
  },
  {
    name: "Eastmont",
    state_id: "WA",
    lat: 47.8968,
    lng: -122.1817,
    id: 1840139116,
  },
  {
    name: "Katy",
    state_id: "TX",
    lat: 29.7904,
    lng: -95.8353,
    id: 1840019648,
  },
  {
    name: "Brownwood",
    state_id: "TX",
    lat: 31.7127,
    lng: -98.9767,
    id: 1840019519,
  },
  {
    name: "Del City",
    state_id: "OK",
    lat: 35.4483,
    lng: -97.4408,
    id: 1840019154,
  },
  {
    name: "Jacksonville",
    state_id: "IL",
    lat: 39.7292,
    lng: -90.2318,
    id: 1840008438,
  },
  {
    name: "North Bay Shore",
    state_id: "NY",
    lat: 40.7601,
    lng: -73.2618,
    id: 1840005055,
  },
  {
    name: "Silverdale",
    state_id: "WA",
    lat: 47.6663,
    lng: -122.6828,
    id: 1840018406,
  },
  {
    name: "Kapolei",
    state_id: "HI",
    lat: 21.3403,
    lng: -158.0665,
    id: 1840025211,
  },
  {
    name: "Allendale",
    state_id: "MI",
    lat: 42.9851,
    lng: -85.9509,
    id: 1840004345,
  },
  {
    name: "Darien",
    state_id: "IL",
    lat: 41.7447,
    lng: -87.9823,
    id: 1840007045,
  },
  {
    name: "Perrysburg",
    state_id: "OH",
    lat: 41.5377,
    lng: -83.6413,
    id: 1840000623,
  },
  {
    name: "Lewistown",
    state_id: "PA",
    lat: 40.5964,
    lng: -77.573,
    id: 1840003592,
  },
  {
    name: "Hamtramck",
    state_id: "MI",
    lat: 42.3954,
    lng: -83.056,
    id: 1840003980,
  },
  {
    name: "La Porte",
    state_id: "IN",
    lat: 41.6077,
    lng: -86.7136,
    id: 1840030063,
  },
  {
    name: "Clarksville",
    state_id: "IN",
    lat: 38.3221,
    lng: -85.7673,
    id: 1840009665,
  },
  {
    name: "New Castle",
    state_id: "IN",
    lat: 39.919,
    lng: -85.3697,
    id: 1840008396,
  },
  {
    name: "Bellview",
    state_id: "FL",
    lat: 30.462,
    lng: -87.312,
    id: 1840013912,
  },
  {
    name: "Biddeford",
    state_id: "ME",
    lat: 43.4674,
    lng: -70.4512,
    id: 1840000342,
  },
  {
    name: "McNair",
    state_id: "VA",
    lat: 38.9513,
    lng: -77.4116,
    id: 1840041794,
  },
  {
    name: "Villa Park",
    state_id: "IL",
    lat: 41.8864,
    lng: -87.9779,
    id: 1840011412,
  },
  {
    name: "Fernley",
    state_id: "NV",
    lat: 39.5611,
    lng: -119.1926,
    id: 1840020214,
  },
  {
    name: "Elkridge",
    state_id: "MD",
    lat: 39.1941,
    lng: -76.7427,
    id: 1840005801,
  },
  {
    name: "Mukilteo",
    state_id: "WA",
    lat: 47.9096,
    lng: -122.3035,
    id: 1840019793,
  },
  {
    name: "Atoka",
    state_id: "TN",
    lat: 35.4239,
    lng: -89.7861,
    id: 1840015416,
  },
  {
    name: "Prichard",
    state_id: "AL",
    lat: 30.7735,
    lng: -88.1301,
    id: 1840014990,
  },
  {
    name: "Hays",
    state_id: "KS",
    lat: 38.8815,
    lng: -99.3218,
    id: 1840001628,
  },
  {
    name: "Birmingham",
    state_id: "MI",
    lat: 42.5447,
    lng: -83.2166,
    id: 1840002438,
  },
  {
    name: "Easley",
    state_id: "SC",
    lat: 34.819,
    lng: -82.5828,
    id: 1840013526,
  },
  {
    name: "Brambleton",
    state_id: "VA",
    lat: 38.9802,
    lng: -77.5323,
    id: 1840024497,
  },
  {
    name: "Mountlake Terrace",
    state_id: "WA",
    lat: 47.7921,
    lng: -122.3076,
    id: 1840019792,
  },
  {
    name: "Ramona",
    state_id: "CA",
    lat: 33.0474,
    lng: -116.8767,
    id: 1840019346,
  },
  {
    name: "Owosso",
    state_id: "MI",
    lat: 42.9955,
    lng: -84.176,
    id: 1840003068,
  },
  {
    name: "King of Prussia",
    state_id: "PA",
    lat: 40.0963,
    lng: -75.382,
    id: 1840005464,
  },
  {
    name: "DeBary",
    state_id: "FL",
    lat: 28.8815,
    lng: -81.324,
    id: 1840015070,
  },
  {
    name: "Eustis",
    state_id: "FL",
    lat: 28.856,
    lng: -81.6781,
    id: 1840014054,
  },
  {
    name: "South Euclid",
    state_id: "OH",
    lat: 41.5239,
    lng: -81.5245,
    id: 1840003414,
  },
  {
    name: "South Holland",
    state_id: "IL",
    lat: 41.5977,
    lng: -87.6022,
    id: 1840011324,
  },
  {
    name: "Palm Valley",
    state_id: "FL",
    lat: 30.2011,
    lng: -81.3949,
    id: 1840013971,
  },
  {
    name: "North Plainfield",
    state_id: "NJ",
    lat: 40.6209,
    lng: -74.4386,
    id: 1840001059,
  },
  {
    name: "Ashland",
    state_id: "OR",
    lat: 42.1905,
    lng: -122.6992,
    id: 1840018674,
  },
  {
    name: "Sapulpa",
    state_id: "OK",
    lat: 36.0089,
    lng: -96.1006,
    id: 1840021691,
  },
  {
    name: "Duarte",
    state_id: "CA",
    lat: 34.161,
    lng: -117.9504,
    id: 1840019246,
  },
  {
    name: "Marietta",
    state_id: "OH",
    lat: 39.4241,
    lng: -81.4465,
    id: 1840001545,
  },
  {
    name: "Battle Ground",
    state_id: "WA",
    lat: 45.7765,
    lng: -122.5413,
    id: 1840018523,
  },
  {
    name: "Stephenville",
    state_id: "TX",
    lat: 32.2148,
    lng: -98.2205,
    id: 1840022097,
  },
  {
    name: "Amsterdam",
    state_id: "NY",
    lat: 42.942,
    lng: -74.1907,
    id: 1840000391,
  },
  {
    name: "Oxford",
    state_id: "AL",
    lat: 33.5967,
    lng: -85.8687,
    id: 1840014780,
  },
  {
    name: "Park Forest",
    state_id: "IL",
    lat: 41.4817,
    lng: -87.6868,
    id: 1840011313,
  },
  {
    name: "Evans",
    state_id: "CO",
    lat: 40.366,
    lng: -104.7389,
    id: 1840020147,
  },
  {
    name: "Alexandria",
    state_id: "MN",
    lat: 45.8777,
    lng: -95.3766,
    id: 1840006680,
  },
  {
    name: "Fort Atkinson",
    state_id: "WI",
    lat: 42.9253,
    lng: -88.8441,
    id: 1840003004,
  },
  {
    name: "Hazel Dell",
    state_id: "WA",
    lat: 45.6797,
    lng: -122.6553,
    id: 1840037662,
  },
  {
    name: "Carrboro",
    state_id: "NC",
    lat: 35.926,
    lng: -79.0878,
    id: 1840015334,
  },
  {
    name: "Bardstown",
    state_id: "KY",
    lat: 37.8174,
    lng: -85.4548,
    id: 1840013219,
  },
  {
    name: "East Ridge",
    state_id: "TN",
    lat: 34.9973,
    lng: -85.2284,
    id: 1840013452,
  },
  {
    name: "Elko",
    state_id: "NV",
    lat: 40.8387,
    lng: -115.7674,
    id: 1840020120,
  },
  {
    name: "Covington",
    state_id: "WA",
    lat: 47.3668,
    lng: -122.1044,
    id: 1840018425,
  },
  {
    name: "Gaffney",
    state_id: "SC",
    lat: 35.0742,
    lng: -81.6558,
    id: 1840013515,
  },
  {
    name: "Sun Valley",
    state_id: "NV",
    lat: 39.6106,
    lng: -119.7765,
    id: 1840018711,
  },
  {
    name: "Sidney",
    state_id: "OH",
    lat: 40.2891,
    lng: -84.1667,
    id: 1840009429,
  },
  {
    name: "Bonney Lake",
    state_id: "WA",
    lat: 47.1791,
    lng: -122.17,
    id: 1840018454,
  },
  {
    name: "Dickinson",
    state_id: "TX",
    lat: 29.4546,
    lng: -95.0583,
    id: 1840019667,
  },
  {
    name: "Martha Lake",
    state_id: "WA",
    lat: 47.8479,
    lng: -122.2327,
    id: 1840037875,
  },
  {
    name: "Bothell West",
    state_id: "WA",
    lat: 47.8056,
    lng: -122.2401,
    id: 1840041906,
  },
  {
    name: "Arnold",
    state_id: "MO",
    lat: 38.4296,
    lng: -90.3733,
    id: 1840007506,
  },
  {
    name: "Ballenger Creek",
    state_id: "MD",
    lat: 39.3807,
    lng: -77.4205,
    id: 1840031490,
  },
  {
    name: "Rolla",
    state_id: "MO",
    lat: 37.9458,
    lng: -91.7608,
    id: 1840009849,
  },
  {
    name: "West Pensacola",
    state_id: "FL",
    lat: 30.4263,
    lng: -87.2679,
    id: 1840013915,
  },
  {
    name: "Harvey",
    state_id: "LA",
    lat: 29.8876,
    lng: -90.0666,
    id: 1840013985,
  },
  {
    name: "Johnstown",
    state_id: "CO",
    lat: 40.3499,
    lng: -104.9481,
    id: 1840021369,
  },
  {
    name: "Cockeysville",
    state_id: "MD",
    lat: 39.4793,
    lng: -76.63,
    id: 1840005680,
  },
  {
    name: "Pleasant Prairie",
    state_id: "WI",
    lat: 42.5266,
    lng: -87.8895,
    id: 1840003163,
  },
  {
    name: "Shelbyville",
    state_id: "IN",
    lat: 39.535,
    lng: -85.7792,
    id: 1840009561,
  },
  {
    name: "Sweetwater",
    state_id: "FL",
    lat: 25.7786,
    lng: -80.376,
    id: 1840016008,
  },
  {
    name: "St. Andrews",
    state_id: "SC",
    lat: 34.051,
    lng: -81.1057,
    id: 1840135211,
  },
  {
    name: "Milwaukie",
    state_id: "OR",
    lat: 45.4447,
    lng: -122.6221,
    id: 1840019952,
  },
  {
    name: "Bryant",
    state_id: "AR",
    lat: 34.6152,
    lng: -92.4914,
    id: 1840013573,
  },
  {
    name: "Salmon Creek",
    state_id: "WA",
    lat: 45.7099,
    lng: -122.6632,
    id: 1840018520,
  },
  {
    name: "Kill Devil Hills",
    state_id: "NC",
    lat: 36.0167,
    lng: -75.6699,
    id: 1840016154,
  },
  {
    name: "North Salt Lake",
    state_id: "UT",
    lat: 40.8439,
    lng: -111.9187,
    id: 1840020144,
  },
  {
    name: "Apollo Beach",
    state_id: "FL",
    lat: 27.7618,
    lng: -82.4002,
    id: 1840014149,
  },
  {
    name: "Guayama",
    state_id: "PR",
    lat: 17.9744,
    lng: -66.1104,
    id: 1630035675,
  },
  {
    name: "Cornelia",
    state_id: "GA",
    lat: 34.517,
    lng: -83.531,
    id: 1840014661,
  },
  {
    name: "Suwanee",
    state_id: "GA",
    lat: 34.0506,
    lng: -84.0687,
    id: 1840015616,
  },
  {
    name: "New Hope",
    state_id: "MN",
    lat: 45.0374,
    lng: -93.3869,
    id: 1840007829,
  },
  {
    name: "Wade Hampton",
    state_id: "SC",
    lat: 34.8821,
    lng: -82.3336,
    id: 1840035800,
  },
  {
    name: "Green Valley",
    state_id: "AZ",
    lat: 31.8436,
    lng: -111.0174,
    id: 1840018111,
  },
  {
    name: "Mill Creek",
    state_id: "WA",
    lat: 47.8631,
    lng: -122.2037,
    id: 1840019790,
  },
  {
    name: "Universal City",
    state_id: "TX",
    lat: 29.5521,
    lng: -98.3074,
    id: 1840022225,
  },
  {
    name: "Montclair",
    state_id: "VA",
    lat: 38.6111,
    lng: -77.34,
    id: 1840006095,
  },
  {
    name: "Lorton",
    state_id: "VA",
    lat: 38.6984,
    lng: -77.2163,
    id: 1840006035,
  },
  {
    name: "Clemmons",
    state_id: "NC",
    lat: 36.0319,
    lng: -80.3861,
    id: 1840017728,
  },
  {
    name: "Rosamond",
    state_id: "CA",
    lat: 34.8658,
    lng: -118.2154,
    id: 1840019140,
  },
  {
    name: "Bear",
    state_id: "DE",
    lat: 39.6188,
    lng: -75.6804,
    id: 1840005568,
  },
  {
    name: "Dyersburg",
    state_id: "TN",
    lat: 36.0465,
    lng: -89.3777,
    id: 1840013372,
  },
  {
    name: "Oneida",
    state_id: "NY",
    lat: 43.0769,
    lng: -75.6621,
    id: 1840000384,
  },
  {
    name: "Hunters Creek",
    state_id: "FL",
    lat: 28.3611,
    lng: -81.4357,
    id: 1840028986,
  },
  {
    name: "Holiday",
    state_id: "FL",
    lat: 28.1864,
    lng: -82.7429,
    id: 1840014114,
  },
  {
    name: "Louisville",
    state_id: "CO",
    lat: 39.9709,
    lng: -105.1441,
    id: 1840020179,
  },
  {
    name: "Dixon",
    state_id: "CA",
    lat: 38.4468,
    lng: -121.8249,
    id: 1840018859,
  },
  {
    name: "Murray",
    state_id: "KY",
    lat: 36.6146,
    lng: -88.3206,
    id: 1840014410,
  },
  {
    name: "Golden",
    state_id: "CO",
    lat: 39.7406,
    lng: -105.2118,
    id: 1840020197,
  },
  {
    name: "Olean",
    state_id: "NY",
    lat: 42.0819,
    lng: -78.4321,
    id: 1840000452,
  },
  {
    name: "Clifton",
    state_id: "CO",
    lat: 39.0763,
    lng: -108.4606,
    id: 1840017556,
  },
  {
    name: "Grayslake",
    state_id: "IL",
    lat: 42.3402,
    lng: -88.0338,
    id: 1840011150,
  },
  {
    name: "North Bellmore",
    state_id: "NY",
    lat: 40.6904,
    lng: -73.539,
    id: 1840005240,
  },
  {
    name: "Palisades Park",
    state_id: "NJ",
    lat: 40.8472,
    lng: -73.9967,
    id: 1840000915,
  },
  {
    name: "South Milwaukee",
    state_id: "WI",
    lat: 42.912,
    lng: -87.8627,
    id: 1840003032,
  },
  {
    name: "Gardner",
    state_id: "MA",
    lat: 42.5845,
    lng: -71.9867,
    id: 1840000436,
  },
  {
    name: "Havelock",
    state_id: "NC",
    lat: 34.9078,
    lng: -76.8987,
    id: 1840014592,
  },
  {
    name: "Lake Ronkonkoma",
    state_id: "NY",
    lat: 40.8308,
    lng: -73.1112,
    id: 1840005086,
  },
  {
    name: "Lebanon",
    state_id: "OH",
    lat: 39.4251,
    lng: -84.2135,
    id: 1840001546,
  },
  {
    name: "East Moline",
    state_id: "IL",
    lat: 41.5199,
    lng: -90.3879,
    id: 1840008180,
  },
  {
    name: "Norwalk",
    state_id: "OH",
    lat: 41.2443,
    lng: -82.6088,
    id: 1840000817,
  },
  {
    name: "Yorkville",
    state_id: "IL",
    lat: 41.6562,
    lng: -88.4507,
    id: 1840010216,
  },
  {
    name: "Logansport",
    state_id: "IN",
    lat: 40.7472,
    lng: -86.3519,
    id: 1840013861,
  },
  {
    name: "Dublin",
    state_id: "GA",
    lat: 32.5359,
    lng: -82.928,
    id: 1840013813,
  },
  {
    name: "North Ogden",
    state_id: "UT",
    lat: 41.3123,
    lng: -111.9585,
    id: 1840020134,
  },
  {
    name: "South El Monte",
    state_id: "CA",
    lat: 34.0493,
    lng: -118.0484,
    id: 1840021869,
  },
  {
    name: "Lynn Haven",
    state_id: "FL",
    lat: 30.2337,
    lng: -85.637,
    id: 1840015922,
  },
  {
    name: "Ferguson",
    state_id: "MO",
    lat: 38.749,
    lng: -90.295,
    id: 1840008584,
  },
  {
    name: "Arlington",
    state_id: "WA",
    lat: 48.1698,
    lng: -122.1443,
    id: 1840018378,
  },
  {
    name: "Willmar",
    state_id: "MN",
    lat: 45.122,
    lng: -95.0569,
    id: 1840009995,
  },
  {
    name: "Alice",
    state_id: "TX",
    lat: 27.7556,
    lng: -98.0653,
    id: 1840019714,
  },
  {
    name: "Swansboro",
    state_id: "NC",
    lat: 34.6958,
    lng: -77.1368,
    id: 1840016532,
  },
  {
    name: "Murphy",
    state_id: "TX",
    lat: 33.0186,
    lng: -96.6105,
    id: 1840020659,
  },
  {
    name: "Culpeper",
    state_id: "VA",
    lat: 38.4705,
    lng: -78.0001,
    id: 1840006169,
  },
  {
    name: "Ozark",
    state_id: "MO",
    lat: 37.0361,
    lng: -93.2155,
    id: 1840009926,
  },
  {
    name: "American Canyon",
    state_id: "CA",
    lat: 38.1796,
    lng: -122.2583,
    id: 1840018834,
  },
  {
    name: "Papillion",
    state_id: "NE",
    lat: 41.1516,
    lng: -96.0679,
    id: 1840009328,
  },
  {
    name: "The Dalles",
    state_id: "OR",
    lat: 45.6053,
    lng: -121.1818,
    id: 1840021212,
  },
  {
    name: "Columbia Heights",
    state_id: "MN",
    lat: 45.0484,
    lng: -93.2472,
    id: 1840006712,
  },
  {
    name: "Barre",
    state_id: "VT",
    lat: 44.1997,
    lng: -72.5083,
    id: 1840002186,
  },
  {
    name: "Amherst Center",
    state_id: "MA",
    lat: 42.3757,
    lng: -72.5188,
    id: 1840003166,
  },
  {
    name: "Crest Hill",
    state_id: "IL",
    lat: 41.5723,
    lng: -88.1124,
    id: 1840007088,
  },
  {
    name: "Punta Gorda",
    state_id: "FL",
    lat: 26.8933,
    lng: -82.0516,
    id: 1840015122,
  },
  {
    name: "Neabsco",
    state_id: "VA",
    lat: 38.6083,
    lng: -77.2847,
    id: 1840043136,
  },
  {
    name: "Newton",
    state_id: "KS",
    lat: 38.0368,
    lng: -97.3451,
    id: 1840003846,
  },
  {
    name: "Poplar Bluff",
    state_id: "MO",
    lat: 36.7632,
    lng: -90.4136,
    id: 1840009935,
  },
  {
    name: "Ennis",
    state_id: "TX",
    lat: 32.3255,
    lng: -96.635,
    id: 1840020754,
  },
  {
    name: "Tukwila",
    state_id: "WA",
    lat: 47.4749,
    lng: -122.2727,
    id: 1840021120,
  },
  {
    name: "Prunedale",
    state_id: "CA",
    lat: 36.8064,
    lng: -121.6555,
    id: 1840019020,
  },
  {
    name: "Lomita",
    state_id: "CA",
    lat: 33.7933,
    lng: -118.3175,
    id: 1840020489,
  },
  {
    name: "Payson",
    state_id: "UT",
    lat: 40.036,
    lng: -111.7395,
    id: 1840020172,
  },
  {
    name: "Mountain Brook",
    state_id: "AL",
    lat: 33.4871,
    lng: -86.74,
    id: 1840014792,
  },
  {
    name: "Glassboro",
    state_id: "NJ",
    lat: 39.7014,
    lng: -75.1113,
    id: 1840003789,
  },
  {
    name: "Bayshore Gardens",
    state_id: "FL",
    lat: 27.4345,
    lng: -82.5794,
    id: 1840014168,
  },
  {
    name: "Ashland",
    state_id: "OH",
    lat: 40.8668,
    lng: -82.3156,
    id: 1840002751,
  },
  {
    name: "Shelton",
    state_id: "WA",
    lat: 47.2186,
    lng: -123.112,
    id: 1840021123,
  },
  {
    name: "Shenandoah",
    state_id: "LA",
    lat: 30.4019,
    lng: -91.002,
    id: 1840031152,
  },
  {
    name: "Cherry Hill",
    state_id: "VA",
    lat: 38.5696,
    lng: -77.2895,
    id: 1840026712,
  },
  {
    name: "Henderson",
    state_id: "NC",
    lat: 36.3259,
    lng: -78.4155,
    id: 1840014433,
  },
  {
    name: "Linda",
    state_id: "CA",
    lat: 39.1241,
    lng: -121.5421,
    id: 1840018800,
  },
  {
    name: "Agoura Hills",
    state_id: "CA",
    lat: 34.151,
    lng: -118.7608,
    id: 1840019221,
  },
  {
    name: "East Hemet",
    state_id: "CA",
    lat: 33.7301,
    lng: -116.941,
    id: 1840028370,
  },
  {
    name: "Libertyville",
    state_id: "IL",
    lat: 42.287,
    lng: -87.967,
    id: 1840011161,
  },
  {
    name: "Durango",
    state_id: "CO",
    lat: 37.2659,
    lng: -107.8782,
    id: 1840018929,
  },
  {
    name: "East Northport",
    state_id: "NY",
    lat: 40.8792,
    lng: -73.3232,
    id: 1840005039,
  },
  {
    name: "Howard",
    state_id: "WI",
    lat: 44.5703,
    lng: -88.092,
    id: 1840002345,
  },
  {
    name: "Monmouth",
    state_id: "OR",
    lat: 44.8505,
    lng: -123.2283,
    id: 1840019971,
  },
  {
    name: "Ypsilanti",
    state_id: "MI",
    lat: 42.2437,
    lng: -83.6205,
    id: 1840000459,
  },
  {
    name: "Mokena",
    state_id: "IL",
    lat: 41.5327,
    lng: -87.8779,
    id: 1840011491,
  },
  {
    name: "Pleasantville",
    state_id: "NJ",
    lat: 39.39,
    lng: -74.5169,
    id: 1840001535,
  },
  {
    name: "Affton",
    state_id: "MO",
    lat: 38.5499,
    lng: -90.3264,
    id: 1840006124,
  },
  {
    name: "Bartow",
    state_id: "FL",
    lat: 27.8868,
    lng: -81.8213,
    id: 1840014128,
  },
  {
    name: "Ashland",
    state_id: "KY",
    lat: 38.4592,
    lng: -82.6449,
    id: 1840013195,
  },
  {
    name: "Mead Valley",
    state_id: "CA",
    lat: 33.8333,
    lng: -117.2852,
    id: 1840028331,
  },
  {
    name: "Miamisburg",
    state_id: "OH",
    lat: 39.6323,
    lng: -84.2725,
    id: 1840008436,
  },
  {
    name: "Merrick",
    state_id: "NY",
    lat: 40.6515,
    lng: -73.5535,
    id: 1840005238,
  },
  {
    name: "Charleston",
    state_id: "IL",
    lat: 39.4842,
    lng: -88.1781,
    id: 1840007324,
  },
  {
    name: "Germantown",
    state_id: "WI",
    lat: 43.2343,
    lng: -88.1217,
    id: 1840002830,
  },
  {
    name: "Escanaba",
    state_id: "MI",
    lat: 45.7477,
    lng: -87.09,
    id: 1840003925,
  },
  {
    name: "Selden",
    state_id: "NY",
    lat: 40.8699,
    lng: -73.0462,
    id: 1840005093,
  },
  {
    name: "Bayou Cane",
    state_id: "LA",
    lat: 29.6244,
    lng: -90.751,
    id: 1840014031,
  },
  {
    name: "Mililani Mauka",
    state_id: "HI",
    lat: 21.4756,
    lng: -157.9947,
    id: 1840039224,
  },
  {
    name: "Snellville",
    state_id: "GA",
    lat: 33.8563,
    lng: -84.0038,
    id: 1840015614,
  },
  {
    name: "Sulphur",
    state_id: "LA",
    lat: 30.2286,
    lng: -93.3565,
    id: 1840015927,
  },
  {
    name: "Harrison",
    state_id: "NJ",
    lat: 40.7431,
    lng: -74.1531,
    id: 1840003598,
  },
  {
    name: "South St. Paul",
    state_id: "MN",
    lat: 44.8876,
    lng: -93.0411,
    id: 1840008948,
  },
  {
    name: "Oregon",
    state_id: "OH",
    lat: 41.6528,
    lng: -83.4322,
    id: 1840000570,
  },
  {
    name: "Middleton",
    state_id: "WI",
    lat: 43.1065,
    lng: -89.5058,
    id: 1840002919,
  },
  {
    name: "Ferndale",
    state_id: "MI",
    lat: 42.4592,
    lng: -83.1314,
    id: 1840002443,
  },
  {
    name: "West Chester",
    state_id: "PA",
    lat: 39.9601,
    lng: -75.6058,
    id: 1840001436,
  },
  {
    name: "Forest Park",
    state_id: "GA",
    lat: 33.6209,
    lng: -84.359,
    id: 1840013750,
  },
  {
    name: "La Cañada Flintridge",
    state_id: "CA",
    lat: 34.2097,
    lng: -118.2002,
    id: 1840020510,
  },
  {
    name: "Hauppauge",
    state_id: "NY",
    lat: 40.8217,
    lng: -73.2119,
    id: 1840005077,
  },
  {
    name: "Safford",
    state_id: "AZ",
    lat: 32.8317,
    lng: -109.7005,
    id: 1840021989,
  },
  {
    name: "Rocky River",
    state_id: "OH",
    lat: 41.4703,
    lng: -81.8526,
    id: 1840003410,
  },
  {
    name: "South San Jose Hills",
    state_id: "CA",
    lat: 34.0123,
    lng: -117.9041,
    id: 1840019208,
  },
  {
    name: "Elmwood Park",
    state_id: "NJ",
    lat: 40.905,
    lng: -74.1201,
    id: 1840003544,
  },
  {
    name: "El Reno",
    state_id: "OK",
    lat: 35.543,
    lng: -97.966,
    id: 1840020420,
  },
  {
    name: "Saco",
    state_id: "ME",
    lat: 43.539,
    lng: -70.4624,
    id: 1840000343,
  },
  {
    name: "Fairwood",
    state_id: "WA",
    lat: 47.4467,
    lng: -122.143,
    id: 1840037517,
  },
  {
    name: "Greenwood",
    state_id: "MS",
    lat: 33.5126,
    lng: -90.1993,
    id: 1840013739,
  },
  {
    name: "West St. Paul",
    state_id: "MN",
    lat: 44.9018,
    lng: -93.0858,
    id: 1840010012,
  },
  {
    name: "Valley",
    state_id: "AL",
    lat: 32.8088,
    lng: -85.1809,
    id: 1840015760,
  },
  {
    name: "Orinda",
    state_id: "CA",
    lat: 37.8808,
    lng: -122.1791,
    id: 1840020280,
  },
  {
    name: "Helena",
    state_id: "AL",
    lat: 33.2845,
    lng: -86.8756,
    id: 1840014827,
  },
  {
    name: "Cullman",
    state_id: "AL",
    lat: 34.1777,
    lng: -86.8407,
    id: 1840001354,
  },
  {
    name: "Alamo",
    state_id: "TX",
    lat: 26.1811,
    lng: -98.1177,
    id: 1840019732,
  },
  {
    name: "Sand Springs",
    state_id: "OK",
    lat: 36.1342,
    lng: -96.1284,
    id: 1840021671,
  },
  {
    name: "Vernal",
    state_id: "UT",
    lat: 40.4517,
    lng: -109.5379,
    id: 1840021391,
  },
  {
    name: "Laurinburg",
    state_id: "NC",
    lat: 34.7597,
    lng: -79.4781,
    id: 1840014631,
  },
  {
    name: "Painesville",
    state_id: "OH",
    lat: 41.724,
    lng: -81.2536,
    id: 1840000542,
  },
  {
    name: "Sherwood",
    state_id: "OR",
    lat: 45.3593,
    lng: -122.8433,
    id: 1840021205,
  },
  {
    name: "Lake Zurich",
    state_id: "IL",
    lat: 42.1955,
    lng: -88.087,
    id: 1840011160,
  },
  {
    name: "West Hempstead",
    state_id: "NY",
    lat: 40.6959,
    lng: -73.6507,
    id: 1840005282,
  },
  {
    name: "Rosedale",
    state_id: "MD",
    lat: 39.3266,
    lng: -76.5084,
    id: 1840005690,
  },
  {
    name: "Ottawa",
    state_id: "IL",
    lat: 41.3532,
    lng: -88.8306,
    id: 1840009272,
  },
  {
    name: "Meadowbrook",
    state_id: "VA",
    lat: 37.4301,
    lng: -77.474,
    id: 1840024786,
  },
  {
    name: "Casa de Oro-Mount Helix",
    state_id: "CA",
    lat: 32.764,
    lng: -116.9687,
    id: 1840073904,
  },
  {
    name: "Lake Shore",
    state_id: "MD",
    lat: 39.1029,
    lng: -76.4876,
    id: 1840005920,
  },
  {
    name: "Parma Heights",
    state_id: "OH",
    lat: 41.3865,
    lng: -81.7637,
    id: 1840003407,
  },
  {
    name: "Old Jamestown",
    state_id: "MO",
    lat: 38.8394,
    lng: -90.2847,
    id: 1840039632,
  },
  {
    name: "Norwood",
    state_id: "OH",
    lat: 39.1605,
    lng: -84.4535,
    id: 1840001610,
  },
  {
    name: "Monroe",
    state_id: "WA",
    lat: 47.8595,
    lng: -121.9852,
    id: 1840019791,
  },
  {
    name: "Selma",
    state_id: "AL",
    lat: 32.4166,
    lng: -87.0336,
    id: 1840008329,
  },
  {
    name: "Makakilo",
    state_id: "HI",
    lat: 21.3591,
    lng: -158.0813,
    id: 1840137080,
  },
  {
    name: "Tahlequah",
    state_id: "OK",
    lat: 35.9116,
    lng: -94.9773,
    id: 1840021700,
  },
  {
    name: "Brockport",
    state_id: "NY",
    lat: 43.2137,
    lng: -77.9404,
    id: 1840004287,
  },
  {
    name: "Rockport",
    state_id: "TX",
    lat: 28.029,
    lng: -97.0722,
    id: 1840020999,
  },
  {
    name: "Mesquite",
    state_id: "NV",
    lat: 36.8035,
    lng: -114.1325,
    id: 1840020362,
  },
  {
    name: "Franconia",
    state_id: "VA",
    lat: 38.7682,
    lng: -77.1587,
    id: 1840006027,
  },
  {
    name: "Front Royal",
    state_id: "VA",
    lat: 38.926,
    lng: -78.1838,
    id: 1840006045,
  },
  {
    name: "West Mifflin",
    state_id: "PA",
    lat: 40.3581,
    lng: -79.9072,
    id: 1840001273,
  },
  {
    name: "Albany",
    state_id: "CA",
    lat: 37.8898,
    lng: -122.3018,
    id: 1840028140,
  },
  {
    name: "La Crescenta-Montrose",
    state_id: "CA",
    lat: 34.2322,
    lng: -118.2353,
    id: 1840074182,
  },
  {
    name: "Somerton",
    state_id: "AZ",
    lat: 32.6009,
    lng: -114.699,
    id: 1840021997,
  },
  {
    name: "Blythe",
    state_id: "CA",
    lat: 33.622,
    lng: -114.6188,
    id: 1840019299,
  },
  {
    name: "Horizon City",
    state_id: "TX",
    lat: 31.6799,
    lng: -106.1903,
    id: 1840020805,
  },
  {
    name: "Montgomery",
    state_id: "IL",
    lat: 41.7237,
    lng: -88.3631,
    id: 1840011350,
  },
  {
    name: "Stillwater",
    state_id: "MN",
    lat: 45.0573,
    lng: -92.8313,
    id: 1840008914,
  },
  {
    name: "Eloy",
    state_id: "AZ",
    lat: 32.747,
    lng: -111.5991,
    id: 1840020628,
  },
  {
    name: "Brigham City",
    state_id: "UT",
    lat: 41.5034,
    lng: -112.0454,
    id: 1840018702,
  },
  {
    name: "Murrysville",
    state_id: "PA",
    lat: 40.4456,
    lng: -79.6555,
    id: 1840001148,
  },
  {
    name: "Brainerd",
    state_id: "MN",
    lat: 46.3553,
    lng: -94.1982,
    id: 1840006644,
  },
  {
    name: "Rio Rico",
    state_id: "AZ",
    lat: 31.4957,
    lng: -110.9886,
    id: 1840027004,
  },
  {
    name: "Central Falls",
    state_id: "RI",
    lat: 41.89,
    lng: -71.3934,
    id: 1840003286,
  },
  {
    name: "Haslett",
    state_id: "MI",
    lat: 42.7525,
    lng: -84.402,
    id: 1840004500,
  },
  {
    name: "Tullahoma",
    state_id: "TN",
    lat: 35.3721,
    lng: -86.2173,
    id: 1840015410,
  },
  {
    name: "Baldwin",
    state_id: "PA",
    lat: 40.369,
    lng: -79.9668,
    id: 1840001201,
  },
  {
    name: "Anna",
    state_id: "TX",
    lat: 33.347,
    lng: -96.5503,
    id: 1840019397,
  },
  {
    name: "Danville",
    state_id: "KY",
    lat: 37.6418,
    lng: -84.7777,
    id: 1840014354,
  },
  {
    name: "Langley Park",
    state_id: "MD",
    lat: 38.9897,
    lng: -76.9808,
    id: 1840005959,
  },
  {
    name: "South Burlington",
    state_id: "VT",
    lat: 44.4622,
    lng: -73.2203,
    id: 1840002311,
  },
  {
    name: "North Liberty",
    state_id: "IA",
    lat: 41.7438,
    lng: -91.611,
    id: 1840008179,
  },
  {
    name: "Lake Butler",
    state_id: "FL",
    lat: 28.4868,
    lng: -81.5453,
    id: 1840143771,
  },
  {
    name: "Schofield Barracks",
    state_id: "HI",
    lat: 21.4936,
    lng: -158.0617,
    id: 1840075052,
  },
  {
    name: "Vincennes",
    state_id: "IN",
    lat: 38.676,
    lng: -87.5102,
    id: 1840010730,
  },
  {
    name: "Sienna Plantation",
    state_id: "TX",
    lat: 29.4834,
    lng: -95.5065,
    id: 1840019642,
  },
  {
    name: "Holtsville",
    state_id: "NY",
    lat: 40.8124,
    lng: -73.0447,
    id: 1840005079,
  },
  {
    name: "Abbeville",
    state_id: "LA",
    lat: 29.975,
    lng: -92.1265,
    id: 1840014005,
  },
  {
    name: "Bourbonnais",
    state_id: "IL",
    lat: 41.183,
    lng: -87.878,
    id: 1840011692,
  },
  {
    name: "Syosset",
    state_id: "NY",
    lat: 40.8156,
    lng: -73.502,
    id: 1840005278,
  },
  {
    name: "Matteson",
    state_id: "IL",
    lat: 41.5095,
    lng: -87.7468,
    id: 1840011293,
  },
  {
    name: "Lynbrook",
    state_id: "NY",
    lat: 40.6579,
    lng: -73.6742,
    id: 1840005304,
  },
  {
    name: "Lake Forest",
    state_id: "IL",
    lat: 42.238,
    lng: -87.8596,
    id: 1840008087,
  },
  {
    name: "Angleton",
    state_id: "TX",
    lat: 29.1718,
    lng: -95.4291,
    id: 1840019672,
  },
  {
    name: "Castaic",
    state_id: "CA",
    lat: 34.4818,
    lng: -118.6317,
    id: 1840022826,
  },
  {
    name: "Canby",
    state_id: "OR",
    lat: 45.2653,
    lng: -122.6866,
    id: 1840018569,
  },
  {
    name: "Wendell",
    state_id: "NC",
    lat: 35.7823,
    lng: -78.3962,
    id: 1840016200,
  },
  {
    name: "Frankfort",
    state_id: "IL",
    lat: 41.4887,
    lng: -87.8361,
    id: 1840011486,
  },
  {
    name: "Laconia",
    state_id: "NH",
    lat: 43.5724,
    lng: -71.4775,
    id: 1840002683,
  },
  {
    name: "Silverthorne",
    state_id: "CO",
    lat: 39.656,
    lng: -106.0867,
    id: 1840022436,
  },
  {
    name: "Altamont",
    state_id: "OR",
    lat: 42.1981,
    lng: -121.7249,
    id: 1840017440,
  },
  {
    name: "Sheridan",
    state_id: "WY",
    lat: 44.7962,
    lng: -106.9643,
    id: 1840021243,
  },
  {
    name: "Hermosa Beach",
    state_id: "CA",
    lat: 33.8654,
    lng: -118.3966,
    id: 1840020487,
  },
  {
    name: "Durant",
    state_id: "OK",
    lat: 33.9957,
    lng: -96.3938,
    id: 1840019280,
  },
  {
    name: "Sylvania",
    state_id: "OH",
    lat: 41.71,
    lng: -83.7091,
    id: 1840009255,
  },
  {
    name: "Corning",
    state_id: "NY",
    lat: 42.147,
    lng: -77.0561,
    id: 1840000443,
  },
  {
    name: "Arbutus",
    state_id: "MD",
    lat: 39.2428,
    lng: -76.6922,
    id: 1840005676,
  },
  {
    name: "White Oak",
    state_id: "OH",
    lat: 39.2106,
    lng: -84.6061,
    id: 1840034164,
  },
  {
    name: "Morristown",
    state_id: "NJ",
    lat: 40.7966,
    lng: -74.4772,
    id: 1840003577,
  },
  {
    name: "Pinole",
    state_id: "CA",
    lat: 37.9931,
    lng: -122.2834,
    id: 1840020281,
  },
  {
    name: "Erlanger",
    state_id: "KY",
    lat: 39.0109,
    lng: -84.5864,
    id: 1840013168,
  },
  {
    name: "Altoona",
    state_id: "IA",
    lat: 41.6481,
    lng: -93.4784,
    id: 1840007065,
  },
  {
    name: "Bethany",
    state_id: "OK",
    lat: 35.5072,
    lng: -97.6417,
    id: 1840019153,
  },
  {
    name: "Sherrelwood",
    state_id: "CO",
    lat: 39.839,
    lng: -105.0014,
    id: 1840028581,
  },
  {
    name: "Mineola",
    state_id: "NY",
    lat: 40.7469,
    lng: -73.6392,
    id: 1840005310,
  },
  {
    name: "Carlisle",
    state_id: "PA",
    lat: 40.1999,
    lng: -77.2042,
    id: 1840001386,
  },
  {
    name: "Stevenson Ranch",
    state_id: "CA",
    lat: 34.3894,
    lng: -118.5885,
    id: 1840076281,
  },
  {
    name: "Highland",
    state_id: "UT",
    lat: 40.4275,
    lng: -111.7955,
    id: 1840020167,
  },
  {
    name: "Liberal",
    state_id: "KS",
    lat: 37.0466,
    lng: -100.9295,
    id: 1840001704,
  },
  {
    name: "Cameron Park",
    state_id: "CA",
    lat: 38.6737,
    lng: -120.9872,
    id: 1840028322,
  },
  {
    name: "Pinecrest",
    state_id: "FL",
    lat: 25.665,
    lng: -80.3042,
    id: 1840018336,
  },
  {
    name: "Evergreen Park",
    state_id: "IL",
    lat: 41.7213,
    lng: -87.7013,
    id: 1840011273,
  },
  {
    name: "Tiffin",
    state_id: "OH",
    lat: 41.1165,
    lng: -83.1805,
    id: 1840000821,
  },
  {
    name: "Mount Airy",
    state_id: "NC",
    lat: 36.5083,
    lng: -80.6154,
    id: 1840014430,
  },
  {
    name: "Broadview Heights",
    state_id: "OH",
    lat: 41.3195,
    lng: -81.6782,
    id: 1840000591,
  },
  {
    name: "Westbrook",
    state_id: "ME",
    lat: 43.6954,
    lng: -70.3539,
    id: 1840000329,
  },
  {
    name: "Red Bluff",
    state_id: "CA",
    lat: 40.1735,
    lng: -122.2413,
    id: 1840020176,
  },
  {
    name: "Arcadia",
    state_id: "FL",
    lat: 27.2213,
    lng: -81.8587,
    id: 1840014187,
  },
  {
    name: "White Oak",
    state_id: "MD",
    lat: 39.0433,
    lng: -76.9906,
    id: 1840005847,
  },
  {
    name: "Fox Crossing",
    state_id: "WI",
    lat: 44.2229,
    lng: -88.4763,
    id: 1840038105,
  },
  {
    name: "McKeesport",
    state_id: "PA",
    lat: 40.3419,
    lng: -79.8439,
    id: 1840001243,
  },
  {
    name: "Bellaire",
    state_id: "TX",
    lat: 29.704,
    lng: -95.4622,
    id: 1840019617,
  },
  {
    name: "Troy",
    state_id: "AL",
    lat: 31.8021,
    lng: -85.9665,
    id: 1840005558,
  },
  {
    name: "Onalaska",
    state_id: "WI",
    lat: 43.8881,
    lng: -91.2074,
    id: 1840002579,
  },
  {
    name: "Lexington",
    state_id: "NC",
    lat: 35.8017,
    lng: -80.2682,
    id: 1840015360,
  },
  {
    name: "Springboro",
    state_id: "OH",
    lat: 39.5612,
    lng: -84.2348,
    id: 1840009573,
  },
  {
    name: "Whitehall",
    state_id: "OH",
    lat: 39.9682,
    lng: -82.8833,
    id: 1840003764,
  },
  {
    name: "Sartell",
    state_id: "MN",
    lat: 45.6187,
    lng: -94.2207,
    id: 1840008886,
  },
  {
    name: "Badger",
    state_id: "AK",
    lat: 64.8006,
    lng: -147.3877,
    id: 1840023690,
  },
  {
    name: "Five Corners",
    state_id: "WA",
    lat: 45.6883,
    lng: -122.5738,
    id: 1840037869,
  },
  {
    name: "McAlester",
    state_id: "OK",
    lat: 34.9262,
    lng: -95.7698,
    id: 1840020457,
  },
  {
    name: "La Follette",
    state_id: "TN",
    lat: 36.3718,
    lng: -84.1256,
    id: 1840036150,
  },
  {
    name: "Terrell",
    state_id: "TX",
    lat: 32.7341,
    lng: -96.2939,
    id: 1840022065,
  },
  {
    name: "Natchitoches",
    state_id: "LA",
    lat: 31.7317,
    lng: -93.0979,
    id: 1840014925,
  },
  {
    name: "Twinsburg",
    state_id: "OH",
    lat: 41.322,
    lng: -81.4451,
    id: 1840000805,
  },
  {
    name: "Central Point",
    state_id: "OR",
    lat: 42.3765,
    lng: -122.911,
    id: 1840018672,
  },
  {
    name: "Seminole",
    state_id: "FL",
    lat: 27.8435,
    lng: -82.7839,
    id: 1840015978,
  },
  {
    name: "Coldwater",
    state_id: "MI",
    lat: 41.9465,
    lng: -84.9989,
    id: 1840003250,
  },
  {
    name: "St. John",
    state_id: "IN",
    lat: 41.4431,
    lng: -87.47,
    id: 1840010221,
  },
  {
    name: "Point Pleasant",
    state_id: "NJ",
    lat: 40.0772,
    lng: -74.0702,
    id: 1840001485,
  },
  {
    name: "Grandview",
    state_id: "WA",
    lat: 46.2444,
    lng: -119.9091,
    id: 1840019871,
  },
  {
    name: "Marshfield",
    state_id: "WI",
    lat: 44.6627,
    lng: -90.1728,
    id: 1840002336,
  },
  {
    name: "Hawthorne",
    state_id: "NJ",
    lat: 40.9579,
    lng: -74.1581,
    id: 1840003513,
  },
  {
    name: "Cimarron Hills",
    state_id: "CO",
    lat: 38.8597,
    lng: -104.6995,
    id: 1840028574,
  },
  {
    name: "Alsip",
    state_id: "IL",
    lat: 41.6701,
    lng: -87.7368,
    id: 1840010147,
  },
  {
    name: "Avon",
    state_id: "IN",
    lat: 39.7603,
    lng: -86.3917,
    id: 1840009535,
  },
  {
    name: "Homewood",
    state_id: "IL",
    lat: 41.5591,
    lng: -87.661,
    id: 1840011264,
  },
  {
    name: "Port Isabel",
    state_id: "TX",
    lat: 26.054,
    lng: -97.2505,
    id: 1840021040,
  },
  {
    name: "Bellwood",
    state_id: "IL",
    lat: 41.8829,
    lng: -87.8762,
    id: 1840011248,
  },
  {
    name: "Marinette",
    state_id: "WI",
    lat: 45.087,
    lng: -87.6328,
    id: 1840003930,
  },
  {
    name: "Bay City",
    state_id: "TX",
    lat: 28.9838,
    lng: -95.9601,
    id: 1840019682,
  },
  {
    name: "North Valley Stream",
    state_id: "NY",
    lat: 40.684,
    lng: -73.7077,
    id: 1840005244,
  },
  {
    name: "Ansonia",
    state_id: "CT",
    lat: 41.3443,
    lng: -73.0689,
    id: 1840004846,
  },
  {
    name: "Deerfield",
    state_id: "IL",
    lat: 42.1654,
    lng: -87.8516,
    id: 1840011167,
  },
  {
    name: "Westminster",
    state_id: "MD",
    lat: 39.5796,
    lng: -77.0066,
    id: 1840005701,
  },
  {
    name: "Creve Coeur",
    state_id: "MO",
    lat: 38.6621,
    lng: -90.443,
    id: 1840007443,
  },
  {
    name: "Seabrook",
    state_id: "MD",
    lat: 38.9802,
    lng: -76.8502,
    id: 1840024547,
  },
  {
    name: "Berea",
    state_id: "OH",
    lat: 41.3696,
    lng: -81.8642,
    id: 1840000588,
  },
  {
    name: "Cocoa",
    state_id: "FL",
    lat: 28.382,
    lng: -80.7674,
    id: 1840015092,
  },
  {
    name: "Moultrie",
    state_id: "GA",
    lat: 31.1591,
    lng: -83.7708,
    id: 1840014977,
  },
  {
    name: "Avenel",
    state_id: "NJ",
    lat: 40.5839,
    lng: -74.272,
    id: 1840005410,
  },
  {
    name: "Forest Park",
    state_id: "OH",
    lat: 39.2861,
    lng: -84.5259,
    id: 1840003818,
  },
  {
    name: "Sudley",
    state_id: "VA",
    lat: 38.7878,
    lng: -77.4961,
    id: 1840006104,
  },
  {
    name: "Anacortes",
    state_id: "WA",
    lat: 48.4878,
    lng: -122.6292,
    id: 1840018366,
  },
  {
    name: "Winthrop",
    state_id: "MA",
    lat: 42.3751,
    lng: -70.9847,
    id: 1840132450,
  },
  {
    name: "Rancho Mirage",
    state_id: "CA",
    lat: 33.7634,
    lng: -116.4271,
    id: 1840020558,
  },
  {
    name: "Gibsonton",
    state_id: "FL",
    lat: 27.826,
    lng: -82.3761,
    id: 1840014148,
  },
  {
    name: "Lynden",
    state_id: "WA",
    lat: 48.9502,
    lng: -122.4545,
    id: 1840019752,
  },
  {
    name: "North Massapequa",
    state_id: "NY",
    lat: 40.7031,
    lng: -73.4678,
    id: 1840005241,
  },
  {
    name: "Gautier",
    state_id: "MS",
    lat: 30.4106,
    lng: -88.6568,
    id: 1840013937,
  },
  {
    name: "Mayfield Heights",
    state_id: "OH",
    lat: 41.5175,
    lng: -81.4534,
    id: 1840000613,
  },
  {
    name: "Breaux Bridge",
    state_id: "LA",
    lat: 30.2829,
    lng: -91.9043,
    id: 1840013960,
  },
  {
    name: "Hopkins",
    state_id: "MN",
    lat: 44.9261,
    lng: -93.4056,
    id: 1840006749,
  },
  {
    name: "Defiance",
    state_id: "OH",
    lat: 41.281,
    lng: -84.3661,
    id: 1840007133,
  },
  {
    name: "Iron Mountain",
    state_id: "MI",
    lat: 45.8275,
    lng: -88.0599,
    id: 1840001991,
  },
  {
    name: "Berea",
    state_id: "KY",
    lat: 37.5904,
    lng: -84.2898,
    id: 1840013225,
  },
  {
    name: "Altus",
    state_id: "OK",
    lat: 34.6566,
    lng: -99.3051,
    id: 1840019202,
  },
  {
    name: "Bryn Mawr-Skyway",
    state_id: "WA",
    lat: 47.4949,
    lng: -122.2411,
    id: 1840037012,
  },
  {
    name: "Uvalde",
    state_id: "TX",
    lat: 29.2153,
    lng: -99.7782,
    id: 1840022231,
  },
  {
    name: "Brushy Creek",
    state_id: "TX",
    lat: 30.5125,
    lng: -97.7388,
    id: 1840135494,
  },
  {
    name: "Brook Park",
    state_id: "OH",
    lat: 41.4036,
    lng: -81.8219,
    id: 1840000594,
  },
  {
    name: "North Amityville",
    state_id: "NY",
    lat: 40.7005,
    lng: -73.4118,
    id: 1840005053,
  },
  {
    name: "Wallingford Center",
    state_id: "CT",
    lat: 41.4499,
    lng: -72.8189,
    id: 1840073312,
  },
  {
    name: "Sunnyside",
    state_id: "WA",
    lat: 46.3158,
    lng: -120.0059,
    id: 1840021150,
  },
  {
    name: "World Golf Village",
    state_id: "FL",
    lat: 29.9653,
    lng: -81.4898,
    id: 1840039162,
  },
  {
    name: "Sycamore",
    state_id: "IL",
    lat: 41.9951,
    lng: -88.6812,
    id: 1840009187,
  },
  {
    name: "Franklin Farm",
    state_id: "VA",
    lat: 38.9133,
    lng: -77.3969,
    id: 1840041752,
  },
  {
    name: "Chowchilla",
    state_id: "CA",
    lat: 37.1095,
    lng: -120.2349,
    id: 1840018919,
  },
  {
    name: "Brookfield",
    state_id: "IL",
    lat: 41.8245,
    lng: -87.847,
    id: 1840010142,
  },
  {
    name: "Ronkonkoma",
    state_id: "NY",
    lat: 40.804,
    lng: -73.1258,
    id: 1840005071,
  },
  {
    name: "Rutherford",
    state_id: "NJ",
    lat: 40.8203,
    lng: -74.1057,
    id: 1840000902,
  },
  {
    name: "Merrifield",
    state_id: "VA",
    lat: 38.8731,
    lng: -77.2426,
    id: 1840006019,
  },
  {
    name: "Newburyport",
    state_id: "MA",
    lat: 42.8124,
    lng: -70.8878,
    id: 1840000413,
  },
  {
    name: "Idylwood",
    state_id: "VA",
    lat: 38.8896,
    lng: -77.2055,
    id: 1840006032,
  },
  {
    name: "Kilgore",
    state_id: "TX",
    lat: 32.3979,
    lng: -94.8603,
    id: 1840020747,
  },
  {
    name: "Radford",
    state_id: "VA",
    lat: 37.1229,
    lng: -80.5587,
    id: 1840003864,
  },
  {
    name: "Palestine",
    state_id: "TX",
    lat: 31.7544,
    lng: -95.6471,
    id: 1840020798,
  },
  {
    name: "Crawfordsville",
    state_id: "IN",
    lat: 40.0428,
    lng: -86.8975,
    id: 1840007261,
  },
  {
    name: "Springfield",
    state_id: "TN",
    lat: 36.4943,
    lng: -86.8709,
    id: 1840015273,
  },
  {
    name: "Boerne",
    state_id: "TX",
    lat: 29.7844,
    lng: -98.7289,
    id: 1840019623,
  },
  {
    name: "Hyattsville",
    state_id: "MD",
    lat: 38.9612,
    lng: -76.9548,
    id: 1840005975,
  },
  {
    name: "Lake Placid",
    state_id: "FL",
    lat: 27.2977,
    lng: -81.3715,
    id: 1840017248,
  },
  {
    name: "St. Michael",
    state_id: "MN",
    lat: 45.2014,
    lng: -93.692,
    id: 1840008901,
  },
  {
    name: "Maumelle",
    state_id: "AR",
    lat: 34.8522,
    lng: -92.4,
    id: 1840015510,
  },
  {
    name: "Niles",
    state_id: "OH",
    lat: 41.1878,
    lng: -80.753,
    id: 1840008241,
  },
  {
    name: "Iselin",
    state_id: "NJ",
    lat: 40.5702,
    lng: -74.317,
    id: 1840005418,
  },
  {
    name: "Menomonie",
    state_id: "WI",
    lat: 44.8893,
    lng: -91.9084,
    id: 1840037988,
  },
  {
    name: "Trenton",
    state_id: "MI",
    lat: 42.1394,
    lng: -83.1929,
    id: 1840003966,
  },
  {
    name: "Rutland",
    state_id: "VT",
    lat: 43.6091,
    lng: -72.9782,
    id: 1840002632,
  },
  {
    name: "Imperial",
    state_id: "CA",
    lat: 32.839,
    lng: -115.5719,
    id: 1840019381,
  },
  {
    name: "Orange",
    state_id: "TX",
    lat: 30.121,
    lng: -93.7616,
    id: 1840020910,
  },
  {
    name: "Otsego",
    state_id: "MN",
    lat: 45.266,
    lng: -93.62,
    id: 1840008899,
  },
  {
    name: "Madison",
    state_id: "IN",
    lat: 38.7581,
    lng: -85.3974,
    id: 1840009728,
  },
  {
    name: "St. Matthews",
    state_id: "KY",
    lat: 38.2497,
    lng: -85.6383,
    id: 1840015194,
  },
  {
    name: "Alton",
    state_id: "TX",
    lat: 26.2884,
    lng: -98.3098,
    id: 1840019733,
  },
  {
    name: "Cudahy",
    state_id: "WI",
    lat: 42.9467,
    lng: -87.8641,
    id: 1840003045,
  },
  {
    name: "Round Lake",
    state_id: "IL",
    lat: 42.3435,
    lng: -88.1058,
    id: 1840011170,
  },
  {
    name: "Oxon Hill",
    state_id: "MD",
    lat: 38.7887,
    lng: -76.9733,
    id: 1840026688,
  },
  {
    name: "Manchester",
    state_id: "MO",
    lat: 38.583,
    lng: -90.5065,
    id: 1840009739,
  },
  {
    name: "Beeville",
    state_id: "TX",
    lat: 28.4059,
    lng: -97.7494,
    id: 1840019698,
  },
  {
    name: "Cary",
    state_id: "IL",
    lat: 42.2129,
    lng: -88.2493,
    id: 1840010109,
  },
  {
    name: "North Wilkesboro",
    state_id: "NC",
    lat: 36.1728,
    lng: -81.139,
    id: 1840016094,
  },
  {
    name: "North Aurora",
    state_id: "IL",
    lat: 41.8083,
    lng: -88.3413,
    id: 1840011351,
  },
  {
    name: "Lithia Springs",
    state_id: "GA",
    lat: 33.781,
    lng: -84.6485,
    id: 1840013740,
  },
  {
    name: "Bon Air",
    state_id: "VA",
    lat: 37.5187,
    lng: -77.5713,
    id: 1840006409,
  },
  {
    name: "Bensenville",
    state_id: "IL",
    lat: 41.9593,
    lng: -87.9433,
    id: 1840011398,
  },
  {
    name: "Eastlake",
    state_id: "OH",
    lat: 41.6581,
    lng: -81.4323,
    id: 1840000533,
  },
  {
    name: "Beaver Dam",
    state_id: "WI",
    lat: 43.469,
    lng: -88.8308,
    id: 1840002738,
  },
  {
    name: "Safety Harbor",
    state_id: "FL",
    lat: 28.008,
    lng: -82.6964,
    id: 1840015975,
  },
  {
    name: "Mattoon",
    state_id: "IL",
    lat: 39.4775,
    lng: -88.3624,
    id: 1840008449,
  },
  {
    name: "Macomb",
    state_id: "IL",
    lat: 40.4721,
    lng: -90.6816,
    id: 1840009411,
  },
  {
    name: "North Druid Hills",
    state_id: "GA",
    lat: 33.8186,
    lng: -84.3254,
    id: 1840029449,
  },
  {
    name: "West Columbia",
    state_id: "SC",
    lat: 33.9931,
    lng: -81.0932,
    id: 1840015605,
  },
  {
    name: "Fayetteville",
    state_id: "GA",
    lat: 33.45,
    lng: -84.4709,
    id: 1840013755,
  },
  {
    name: "Sunland Park",
    state_id: "NM",
    lat: 31.8194,
    lng: -106.5943,
    id: 1840033664,
  },
  {
    name: "Estelle",
    state_id: "LA",
    lat: 29.8447,
    lng: -90.1021,
    id: 1840013111,
  },
  {
    name: "Mountain House",
    state_id: "CA",
    lat: 37.774,
    lng: -121.5452,
    id: 1840026768,
  },
  {
    name: "San Carlos Park",
    state_id: "FL",
    lat: 26.4765,
    lng: -81.8193,
    id: 1840014218,
  },
  {
    name: "Zachary",
    state_id: "LA",
    lat: 30.6642,
    lng: -91.1634,
    id: 1840015908,
  },
  {
    name: "Kingsland",
    state_id: "GA",
    lat: 30.8194,
    lng: -81.7217,
    id: 1840014991,
  },
  {
    name: "Marco Island",
    state_id: "FL",
    lat: 25.933,
    lng: -81.6993,
    id: 1840036141,
  },
  {
    name: "Huntington",
    state_id: "IN",
    lat: 40.8815,
    lng: -85.5053,
    id: 1840007169,
  },
  {
    name: "Ingleside",
    state_id: "TX",
    lat: 27.87,
    lng: -97.2077,
    id: 1840019709,
  },
  {
    name: "Humacao",
    state_id: "PR",
    lat: 18.1519,
    lng: -65.8204,
    id: 1630035683,
  },
  {
    name: "Wantagh",
    state_id: "NY",
    lat: 40.6686,
    lng: -73.5104,
    id: 1840005281,
  },
  {
    name: "Lenoir",
    state_id: "NC",
    lat: 35.9096,
    lng: -81.5247,
    id: 1840014493,
  },
  {
    name: "Concord",
    state_id: "MO",
    lat: 38.5117,
    lng: -90.3574,
    id: 1840006126,
  },
  {
    name: "Easton",
    state_id: "MD",
    lat: 38.776,
    lng: -76.0702,
    id: 1840006089,
  },
  {
    name: "Burke Centre",
    state_id: "VA",
    lat: 38.7903,
    lng: -77.2999,
    id: 1840041683,
  },
  {
    name: "Perry",
    state_id: "GA",
    lat: 32.4719,
    lng: -83.7282,
    id: 1840014885,
  },
  {
    name: "Hannibal",
    state_id: "MO",
    lat: 39.7097,
    lng: -91.3939,
    id: 1840007306,
  },
  {
    name: "Menasha",
    state_id: "WI",
    lat: 44.2124,
    lng: -88.4272,
    id: 1840002243,
  },
  {
    name: "Oak Grove",
    state_id: "OR",
    lat: 45.4156,
    lng: -122.6349,
    id: 1840018568,
  },
  {
    name: "Scarsdale",
    state_id: "NY",
    lat: 40.9902,
    lng: -73.7773,
    id: 1840004943,
  },
  {
    name: "Brenham",
    state_id: "TX",
    lat: 30.1585,
    lng: -96.3964,
    id: 1840019604,
  },
  {
    name: "Ocean Springs",
    state_id: "MS",
    lat: 30.4082,
    lng: -88.7861,
    id: 1840015017,
  },
  {
    name: "Dallas",
    state_id: "OR",
    lat: 44.9222,
    lng: -123.3131,
    id: 1840018590,
  },
  {
    name: "Woodcrest",
    state_id: "CA",
    lat: 33.8789,
    lng: -117.3686,
    id: 1840019296,
  },
  {
    name: "White Settlement",
    state_id: "TX",
    lat: 32.7554,
    lng: -97.4605,
    id: 1840022051,
  },
  {
    name: "Five Forks",
    state_id: "SC",
    lat: 34.8069,
    lng: -82.2271,
    id: 1840013491,
  },
  {
    name: "Knightdale",
    state_id: "NC",
    lat: 35.7918,
    lng: -78.4955,
    id: 1840016194,
  },
  {
    name: "Shenandoah",
    state_id: "PA",
    lat: 40.8167,
    lng: -76.2004,
    id: 1840002779,
  },
  {
    name: "Ada",
    state_id: "OK",
    lat: 34.7683,
    lng: -96.6689,
    id: 1840019191,
  },
  {
    name: "Crossville",
    state_id: "TN",
    lat: 35.9527,
    lng: -85.0294,
    id: 1840014489,
  },
  {
    name: "Beltsville",
    state_id: "MD",
    lat: 39.0394,
    lng: -76.9211,
    id: 1840005945,
  },
  {
    name: "Colonia",
    state_id: "NJ",
    lat: 40.5929,
    lng: -74.3151,
    id: 1840005411,
  },
  {
    name: "Moraga",
    state_id: "CA",
    lat: 37.8439,
    lng: -122.1225,
    id: 1840022538,
  },
  {
    name: "Midway",
    state_id: "FL",
    lat: 30.4138,
    lng: -87.0261,
    id: 1840143881,
  },
  {
    name: "Steubenville",
    state_id: "OH",
    lat: 40.3653,
    lng: -80.652,
    id: 1840001341,
  },
  {
    name: "Tavares",
    state_id: "FL",
    lat: 28.7921,
    lng: -81.7353,
    id: 1840015953,
  },
  {
    name: "Belgrade",
    state_id: "MT",
    lat: 45.7796,
    lng: -111.1751,
    id: 1840018508,
  },
  {
    name: "Takoma Park",
    state_id: "MD",
    lat: 38.981,
    lng: -77.0028,
    id: 1840005850,
  },
  {
    name: "Dover",
    state_id: "NJ",
    lat: 40.8859,
    lng: -74.5597,
    id: 1840003581,
  },
  {
    name: "Lackawanna",
    state_id: "NY",
    lat: 42.8182,
    lng: -78.8326,
    id: 1840000387,
  },
  {
    name: "Melville",
    state_id: "NY",
    lat: 40.7823,
    lng: -73.4088,
    id: 1840005044,
  },
  {
    name: "Wailuku",
    state_id: "HI",
    lat: 20.8834,
    lng: -156.5059,
    id: 1840023239,
  },
  {
    name: "Buckhall",
    state_id: "VA",
    lat: 38.725,
    lng: -77.4472,
    id: 1840026707,
  },
  {
    name: "El Dorado",
    state_id: "AR",
    lat: 33.2184,
    lng: -92.664,
    id: 1840013773,
  },
  {
    name: "Valle Vista",
    state_id: "CA",
    lat: 33.7436,
    lng: -116.8872,
    id: 1840019292,
  },
  {
    name: "Cedar Mill",
    state_id: "OR",
    lat: 45.5355,
    lng: -122.8006,
    id: 1840034678,
  },
  {
    name: "Albert Lea",
    state_id: "MN",
    lat: 43.6547,
    lng: -93.3642,
    id: 1840006824,
  },
  {
    name: "Madison",
    state_id: "NJ",
    lat: 40.7586,
    lng: -74.417,
    id: 1840003574,
  },
  {
    name: "Maitland",
    state_id: "FL",
    lat: 28.6295,
    lng: -81.3717,
    id: 1840015964,
  },
  {
    name: "Gretna",
    state_id: "LA",
    lat: 29.9101,
    lng: -90.0515,
    id: 1840013996,
  },
  {
    name: "Greenfield",
    state_id: "CA",
    lat: 36.3232,
    lng: -121.2451,
    id: 1840020356,
  },
  {
    name: "Hinsdale",
    state_id: "IL",
    lat: 41.8005,
    lng: -87.9273,
    id: 1840011403,
  },
  {
    name: "Santa Fe Springs",
    state_id: "CA",
    lat: 33.933,
    lng: -118.0625,
    id: 1840021865,
  },
  {
    name: "Franklin Park",
    state_id: "IL",
    lat: 41.9361,
    lng: -87.8794,
    id: 1840011278,
  },
  {
    name: "Bethpage",
    state_id: "NY",
    lat: 40.7495,
    lng: -73.4856,
    id: 1840005233,
  },
  {
    name: "East Massapequa",
    state_id: "NY",
    lat: 40.6743,
    lng: -73.4358,
    id: 1840034047,
  },
  {
    name: "Kirksville",
    state_id: "MO",
    lat: 40.1986,
    lng: -92.5753,
    id: 1840008372,
  },
  {
    name: "Nipomo",
    state_id: "CA",
    lat: 35.0323,
    lng: -120.4991,
    id: 1840019122,
  },
  {
    name: "Centerville",
    state_id: "UT",
    lat: 40.9284,
    lng: -111.8849,
    id: 1840018734,
  },
  {
    name: "Butner",
    state_id: "NC",
    lat: 36.1285,
    lng: -78.7502,
    id: 1840022665,
  },
  {
    name: "Salem",
    state_id: "OH",
    lat: 40.9049,
    lng: -80.8491,
    id: 1840009374,
  },
  {
    name: "Tillmans Corner",
    state_id: "AL",
    lat: 30.5818,
    lng: -88.2128,
    id: 1840013896,
  },
  {
    name: "Upper Grand Lagoon",
    state_id: "FL",
    lat: 30.169,
    lng: -85.7407,
    id: 1840029090,
  },
  {
    name: "Anoka",
    state_id: "MN",
    lat: 45.2099,
    lng: -93.3893,
    id: 1840006717,
  },
  {
    name: "Pinewood",
    state_id: "FL",
    lat: 25.8697,
    lng: -80.2174,
    id: 1840029066,
  },
  {
    name: "Laurel",
    state_id: "VA",
    lat: 37.6375,
    lng: -77.5062,
    id: 1840006393,
  },
  {
    name: "Amesbury",
    state_id: "MA",
    lat: 42.853,
    lng: -70.9446,
    id: 1840132383,
  },
  {
    name: "Marion",
    state_id: "IL",
    lat: 37.7173,
    lng: -88.9279,
    id: 1840008712,
  },
  {
    name: "Tallmadge",
    state_id: "OH",
    lat: 41.1023,
    lng: -81.4216,
    id: 1840000804,
  },
  {
    name: "Kingstowne",
    state_id: "VA",
    lat: 38.7625,
    lng: -77.1445,
    id: 1840041770,
  },
  {
    name: "Nanuet",
    state_id: "NY",
    lat: 41.0957,
    lng: -74.0155,
    id: 1840004963,
  },
  {
    name: "Dumont",
    state_id: "NJ",
    lat: 40.9452,
    lng: -73.9923,
    id: 1840003541,
  },
  {
    name: "Maryland City",
    state_id: "MD",
    lat: 39.1016,
    lng: -76.8051,
    id: 1840005922,
  },
  {
    name: "Shorewood",
    state_id: "IL",
    lat: 41.5175,
    lng: -88.2149,
    id: 1840011496,
  },
  {
    name: "Vero Beach",
    state_id: "FL",
    lat: 27.6463,
    lng: -80.393,
    id: 1840015985,
  },
  {
    name: "Clemson",
    state_id: "SC",
    lat: 34.6837,
    lng: -82.8124,
    id: 1840014626,
  },
  {
    name: "Country Walk",
    state_id: "FL",
    lat: 25.6331,
    lng: -80.4353,
    id: 1840028979,
  },
  {
    name: "Spanish Lake",
    state_id: "MO",
    lat: 38.7884,
    lng: -90.2078,
    id: 1840006123,
  },
  {
    name: "Lake Mary",
    state_id: "FL",
    lat: 28.7592,
    lng: -81.3359,
    id: 1840015090,
  },
  {
    name: "Manassas Park",
    state_id: "VA",
    lat: 38.7719,
    lng: -77.445,
    id: 1840003838,
  },
  {
    name: "Woodmere",
    state_id: "NY",
    lat: 40.6374,
    lng: -73.7219,
    id: 1840005284,
  },
  {
    name: "Lakeport",
    state_id: "CA",
    lat: 39.0392,
    lng: -122.9218,
    id: 1840020220,
  },
  {
    name: "Knik-Fairview",
    state_id: "AK",
    lat: 61.4964,
    lng: -149.6535,
    id: 1840075080,
  },
  {
    name: "Martinsburg",
    state_id: "WV",
    lat: 39.4582,
    lng: -77.9776,
    id: 1840005742,
  },
  {
    name: "Tinton Falls",
    state_id: "NJ",
    lat: 40.2709,
    lng: -74.0948,
    id: 1840001368,
  },
  {
    name: "South Houston",
    state_id: "TX",
    lat: 29.6611,
    lng: -95.2285,
    id: 1840022199,
  },
  {
    name: "Jenison",
    state_id: "MI",
    lat: 42.9063,
    lng: -85.8269,
    id: 1840004344,
  },
  {
    name: "Redland",
    state_id: "MD",
    lat: 39.1335,
    lng: -77.1465,
    id: 1840005838,
  },
  {
    name: "Burlington",
    state_id: "KY",
    lat: 39.0223,
    lng: -84.7217,
    id: 1840013160,
  },
  {
    name: "Godfrey",
    state_id: "IL",
    lat: 38.9577,
    lng: -90.2156,
    id: 1840012796,
  },
  {
    name: "Pendleton",
    state_id: "OR",
    lat: 45.6756,
    lng: -118.8209,
    id: 1840019917,
  },
  {
    name: "Taylor",
    state_id: "TX",
    lat: 30.5743,
    lng: -97.4234,
    id: 1840022165,
  },
  {
    name: "Pecan Grove",
    state_id: "TX",
    lat: 29.6235,
    lng: -95.7331,
    id: 1840019645,
  },
  {
    name: "Nederland",
    state_id: "TX",
    lat: 29.9707,
    lng: -94.0015,
    id: 1840020917,
  },
  {
    name: "Colonial Heights",
    state_id: "VA",
    lat: 37.265,
    lng: -77.3969,
    id: 1840003860,
  },
  {
    name: "Stafford",
    state_id: "TX",
    lat: 29.6271,
    lng: -95.5653,
    id: 1840022216,
  },
  {
    name: "Willimantic",
    state_id: "CT",
    lat: 41.7153,
    lng: -72.2173,
    id: 1840003284,
  },
  {
    name: "Ocean Acres",
    state_id: "NJ",
    lat: 39.743,
    lng: -74.2804,
    id: 1840033483,
  },
  {
    name: "La Marque",
    state_id: "TX",
    lat: 29.369,
    lng: -94.9957,
    id: 1840020972,
  },
  {
    name: "Versailles",
    state_id: "KY",
    lat: 38.0487,
    lng: -84.7259,
    id: 1840015212,
  },
  {
    name: "Morris",
    state_id: "IL",
    lat: 41.3749,
    lng: -88.4305,
    id: 1840008243,
  },
  {
    name: "Lemont",
    state_id: "IL",
    lat: 41.6695,
    lng: -87.9836,
    id: 1840011258,
  },
  {
    name: "Gainesville",
    state_id: "VA",
    lat: 38.7931,
    lng: -77.6347,
    id: 1840006098,
  },
  {
    name: "Sayre",
    state_id: "PA",
    lat: 41.9855,
    lng: -76.5207,
    id: 1840000517,
  },
  {
    name: "Portland",
    state_id: "TX",
    lat: 27.8911,
    lng: -97.3284,
    id: 1840021006,
  },
  {
    name: "Lindenwold",
    state_id: "NJ",
    lat: 39.8172,
    lng: -74.9898,
    id: 1840000730,
  },
  {
    name: "Clive",
    state_id: "IA",
    lat: 41.6147,
    lng: -93.798,
    id: 1840007063,
  },
  {
    name: "Oneonta",
    state_id: "NY",
    lat: 42.4551,
    lng: -75.0666,
    id: 1840000405,
  },
  {
    name: "Shippensburg",
    state_id: "PA",
    lat: 40.0484,
    lng: -77.5227,
    id: 1840001395,
  },
  {
    name: "Pampa",
    state_id: "TX",
    lat: 35.5479,
    lng: -100.9651,
    id: 1840022939,
  },
  {
    name: "McKinleyville",
    state_id: "CA",
    lat: 40.9488,
    lng: -124.0857,
    id: 1840017485,
  },
  {
    name: "Ferndale",
    state_id: "MD",
    lat: 39.1869,
    lng: -76.633,
    id: 1840005906,
  },
  {
    name: "South Ogden",
    state_id: "UT",
    lat: 41.1722,
    lng: -111.9577,
    id: 1840021342,
  },
  {
    name: "Albemarle",
    state_id: "NC",
    lat: 35.3594,
    lng: -80.1915,
    id: 1840013444,
  },
  {
    name: "Coalinga",
    state_id: "CA",
    lat: 36.143,
    lng: -120.3262,
    id: 1840018945,
  },
  {
    name: "North Canton",
    state_id: "OH",
    lat: 40.8742,
    lng: -81.3971,
    id: 1840008290,
  },
  {
    name: "La Vista",
    state_id: "NE",
    lat: 41.1816,
    lng: -96.0666,
    id: 1840008263,
  },
  {
    name: "Ashwaubenon",
    state_id: "WI",
    lat: 44.4796,
    lng: -88.0889,
    id: 1840002342,
  },
  {
    name: "Dickson",
    state_id: "TN",
    lat: 36.064,
    lng: -87.3668,
    id: 1840014468,
  },
  {
    name: "North Adams",
    state_id: "MA",
    lat: 42.6844,
    lng: -73.1166,
    id: 1840000423,
  },
  {
    name: "Waxhaw",
    state_id: "NC",
    lat: 34.9364,
    lng: -80.7438,
    id: 1840016458,
  },
  {
    name: "Massapequa Park",
    state_id: "NY",
    lat: 40.6817,
    lng: -73.4496,
    id: 1840005307,
  },
  {
    name: "North Babylon",
    state_id: "NY",
    lat: 40.7311,
    lng: -73.3251,
    id: 1840005054,
  },
  {
    name: "Wahiawa",
    state_id: "HI",
    lat: 21.5005,
    lng: -158.0198,
    id: 1840029550,
  },
  {
    name: "Bastrop",
    state_id: "TX",
    lat: 30.1118,
    lng: -97.3257,
    id: 1840019603,
  },
  {
    name: "Ammon",
    state_id: "ID",
    lat: 43.4746,
    lng: -111.9569,
    id: 1840018643,
  },
  {
    name: "Yelm",
    state_id: "WA",
    lat: 46.9398,
    lng: -122.6261,
    id: 1840021146,
  },
  {
    name: "Lansdale",
    state_id: "PA",
    lat: 40.2417,
    lng: -75.2812,
    id: 1840003703,
  },
  {
    name: "Parole",
    state_id: "MD",
    lat: 38.9861,
    lng: -76.5519,
    id: 1840005913,
  },
  {
    name: "Hybla Valley",
    state_id: "VA",
    lat: 38.7485,
    lng: -77.0822,
    id: 1840006031,
  },
  {
    name: "Glenvar Heights",
    state_id: "FL",
    lat: 25.709,
    lng: -80.3156,
    id: 1840014238,
  },
  {
    name: "Palos Hills",
    state_id: "IL",
    lat: 41.6986,
    lng: -87.8266,
    id: 1840009182,
  },
  {
    name: "Brownsville",
    state_id: "FL",
    lat: 25.8216,
    lng: -80.2417,
    id: 1840029024,
  },
  {
    name: "Camano",
    state_id: "WA",
    lat: 48.1865,
    lng: -122.4708,
    id: 1840037492,
  },
  {
    name: "Streator",
    state_id: "IL",
    lat: 41.1244,
    lng: -88.8296,
    id: 1840009271,
  },
  {
    name: "Buffalo",
    state_id: "MN",
    lat: 45.1796,
    lng: -93.8644,
    id: 1840006703,
  },
  {
    name: "Lincoln",
    state_id: "IL",
    lat: 40.1508,
    lng: -89.3721,
    id: 1840009447,
  },
  {
    name: "Sylacauga",
    state_id: "AL",
    lat: 33.1778,
    lng: -86.2606,
    id: 1840015700,
  },
  {
    name: "Oconomowoc",
    state_id: "WI",
    lat: 43.0995,
    lng: -88.495,
    id: 1840003027,
  },
  {
    name: "Fort Hunt",
    state_id: "VA",
    lat: 38.7361,
    lng: -77.0589,
    id: 1840006010,
  },
  {
    name: "Phoenixville",
    state_id: "PA",
    lat: 40.1358,
    lng: -75.5201,
    id: 1840001433,
  },
  {
    name: "East Cleveland",
    state_id: "OH",
    lat: 41.5318,
    lng: -81.5795,
    id: 1840000599,
  },
  {
    name: "Mountain Home",
    state_id: "ID",
    lat: 43.1324,
    lng: -115.6972,
    id: 1840020017,
  },
  {
    name: "Americus",
    state_id: "GA",
    lat: 32.0736,
    lng: -84.2249,
    id: 1840013841,
  },
  {
    name: "Republic",
    state_id: "MO",
    lat: 37.1452,
    lng: -93.4446,
    id: 1840009906,
  },
  {
    name: "Glassmanor",
    state_id: "MD",
    lat: 38.8181,
    lng: -76.9836,
    id: 1840024545,
  },
  {
    name: "Lemay",
    state_id: "MO",
    lat: 38.5325,
    lng: -90.2845,
    id: 1840006119,
  },
  {
    name: "Buda",
    state_id: "TX",
    lat: 30.0856,
    lng: -97.8472,
    id: 1840019605,
  },
  {
    name: "Roosevelt",
    state_id: "NY",
    lat: 40.6797,
    lng: -73.5837,
    id: 1840005253,
  },
  {
    name: "Cutlerville",
    state_id: "MI",
    lat: 42.8405,
    lng: -85.6739,
    id: 1840004308,
  },
  {
    name: "Calverton",
    state_id: "MD",
    lat: 39.0578,
    lng: -76.9488,
    id: 1840031445,
  },
  {
    name: "Gainesville",
    state_id: "TX",
    lat: 33.6391,
    lng: -97.1488,
    id: 1840020573,
  },
  {
    name: "Southbridge",
    state_id: "MA",
    lat: 42.0604,
    lng: -72.0338,
    id: 1840132611,
  },
  {
    name: "Myrtle Grove",
    state_id: "FL",
    lat: 30.4158,
    lng: -87.3028,
    id: 1840013917,
  },
  {
    name: "Lake St. Louis",
    state_id: "MO",
    lat: 38.7846,
    lng: -90.7886,
    id: 1840008572,
  },
  {
    name: "Seagoville",
    state_id: "TX",
    lat: 32.653,
    lng: -96.5455,
    id: 1840022054,
  },
  {
    name: "Crescent City",
    state_id: "CA",
    lat: 41.7727,
    lng: -124.1902,
    id: 1840018707,
  },
  {
    name: "Live Oak",
    state_id: "CA",
    lat: 36.986,
    lng: -121.9804,
    id: 1840144061,
  },
  {
    name: "Picayune",
    state_id: "MS",
    lat: 30.5322,
    lng: -89.6724,
    id: 1840015002,
  },
  {
    name: "Rosedale",
    state_id: "CA",
    lat: 35.3887,
    lng: -119.2058,
    id: 1840019141,
  },
  {
    name: "North Myrtle Beach",
    state_id: "SC",
    lat: 33.823,
    lng: -78.7089,
    id: 1840014718,
  },
  {
    name: "Wayne",
    state_id: "MI",
    lat: 42.2774,
    lng: -83.3877,
    id: 1840001846,
  },
  {
    name: "Bemidji",
    state_id: "MN",
    lat: 47.4828,
    lng: -94.8796,
    id: 1840006600,
  },
  {
    name: "Goodlettsville",
    state_id: "TN",
    lat: 36.3327,
    lng: -86.7029,
    id: 1840013345,
  },
  {
    name: "Ham Lake",
    state_id: "MN",
    lat: 45.2545,
    lng: -93.2039,
    id: 1840006720,
  },
  {
    name: "Fairburn",
    state_id: "GA",
    lat: 33.5496,
    lng: -84.5914,
    id: 1840013662,
  },
  {
    name: "Bristol",
    state_id: "VA",
    lat: 36.618,
    lng: -82.1606,
    id: 1840003879,
  },
  {
    name: "Lake Wales",
    state_id: "FL",
    lat: 27.9195,
    lng: -81.5961,
    id: 1840015107,
  },
  {
    name: "Clayton",
    state_id: "MO",
    lat: 38.6444,
    lng: -90.3302,
    id: 1840007439,
  },
  {
    name: "New Port Richey",
    state_id: "FL",
    lat: 28.2468,
    lng: -82.717,
    id: 1840015102,
  },
  {
    name: "Truckee",
    state_id: "CA",
    lat: 39.3455,
    lng: -120.1848,
    id: 1840022462,
  },
  {
    name: "Lindsay",
    state_id: "CA",
    lat: 36.2082,
    lng: -119.0897,
    id: 1840020367,
  },
  {
    name: "North Decatur",
    state_id: "GA",
    lat: 33.8073,
    lng: -84.2889,
    id: 1840013704,
  },
  {
    name: "Cohoes",
    state_id: "NY",
    lat: 42.7732,
    lng: -73.7077,
    id: 1840000418,
  },
  {
    name: "McComb",
    state_id: "MS",
    lat: 31.2442,
    lng: -90.4717,
    id: 1840015877,
  },
  {
    name: "Highland Village",
    state_id: "TX",
    lat: 33.0897,
    lng: -97.0615,
    id: 1840020638,
  },
  {
    name: "Glenmont",
    state_id: "MD",
    lat: 39.0698,
    lng: -77.0467,
    id: 1840024490,
  },
  {
    name: "Bull Run",
    state_id: "VA",
    lat: 38.7802,
    lng: -77.5204,
    id: 1840006093,
  },
  {
    name: "Athens",
    state_id: "TN",
    lat: 35.4573,
    lng: -84.6045,
    id: 1840013429,
  },
  {
    name: "Auburndale",
    state_id: "FL",
    lat: 28.0962,
    lng: -81.8011,
    id: 1840014127,
  },
  {
    name: "El Segundo",
    state_id: "CA",
    lat: 33.9169,
    lng: -118.4021,
    id: 1840020481,
  },
  {
    name: "Mercedes",
    state_id: "TX",
    lat: 26.1533,
    lng: -97.9129,
    id: 1840021025,
  },
  {
    name: "Bostonia",
    state_id: "CA",
    lat: 32.8189,
    lng: -116.9479,
    id: 1840018018,
  },
  {
    name: "Artesia",
    state_id: "CA",
    lat: 33.8676,
    lng: -118.0806,
    id: 1840019224,
  },
  {
    name: "Norcross",
    state_id: "GA",
    lat: 33.9379,
    lng: -84.2065,
    id: 1840014744,
  },
  {
    name: "Yauco",
    state_id: "PR",
    lat: 18.0344,
    lng: -66.8615,
    id: 1630035600,
  },
  {
    name: "Batavia",
    state_id: "NY",
    lat: 42.9987,
    lng: -78.1802,
    id: 1840000385,
  },
  {
    name: "Morganton",
    state_id: "NC",
    lat: 35.7408,
    lng: -81.7003,
    id: 1840014504,
  },
  {
    name: "Harrisburg",
    state_id: "NC",
    lat: 35.3124,
    lng: -80.6486,
    id: 1840016364,
  },
  {
    name: "Murraysville",
    state_id: "NC",
    lat: 34.2919,
    lng: -77.8429,
    id: 1840013631,
  },
  {
    name: "Donaldsonville",
    state_id: "LA",
    lat: 30.0954,
    lng: -90.9926,
    id: 1840015045,
  },
  {
    name: "Solvang",
    state_id: "CA",
    lat: 34.5936,
    lng: -120.1401,
    id: 1840021831,
  },
  {
    name: "Los Osos",
    state_id: "CA",
    lat: 35.3068,
    lng: -120.8249,
    id: 1840017805,
  },
  {
    name: "Groveton",
    state_id: "VA",
    lat: 38.7605,
    lng: -77.098,
    id: 1840006029,
  },
  {
    name: "Washington",
    state_id: "IL",
    lat: 40.7049,
    lng: -89.4346,
    id: 1840010377,
  },
  {
    name: "Live Oak",
    state_id: "TX",
    lat: 29.5545,
    lng: -98.3404,
    id: 1840020960,
  },
  {
    name: "Lock Haven",
    state_id: "PA",
    lat: 41.1365,
    lng: -77.4521,
    id: 1840003450,
  },
  {
    name: "Vienna",
    state_id: "VA",
    lat: 38.8996,
    lng: -77.2597,
    id: 1840003829,
  },
  {
    name: "Country Club Hills",
    state_id: "IL",
    lat: 41.5637,
    lng: -87.725,
    id: 1840007025,
  },
  {
    name: "Streetsboro",
    state_id: "OH",
    lat: 41.2396,
    lng: -81.3456,
    id: 1840000808,
  },
  {
    name: "Jasper",
    state_id: "IN",
    lat: 38.3933,
    lng: -86.9402,
    id: 1840008646,
  },
  {
    name: "Eden",
    state_id: "NC",
    lat: 36.5028,
    lng: -79.7412,
    id: 1840013309,
  },
  {
    name: "Pearl River",
    state_id: "NY",
    lat: 41.0615,
    lng: -74.0047,
    id: 1840004966,
  },
  {
    name: "Douglas",
    state_id: "AZ",
    lat: 31.3602,
    lng: -109.5394,
    id: 1840019496,
  },
  {
    name: "Crowley",
    state_id: "TX",
    lat: 32.5781,
    lng: -97.3585,
    id: 1840019431,
  },
  {
    name: "Highland Springs",
    state_id: "VA",
    lat: 37.5516,
    lng: -77.3285,
    id: 1840006391,
  },
  {
    name: "Cinco Ranch",
    state_id: "TX",
    lat: 29.7395,
    lng: -95.7607,
    id: 1840037039,
  },
  {
    name: "Wolf Trap",
    state_id: "VA",
    lat: 38.9395,
    lng: -77.2842,
    id: 1840006042,
  },
  {
    name: "Chickasha",
    state_id: "OK",
    lat: 35.041,
    lng: -97.9472,
    id: 1840019169,
  },
  {
    name: "New Milford",
    state_id: "NJ",
    lat: 40.9337,
    lng: -74.0196,
    id: 1840000908,
  },
  {
    name: "Kings Park",
    state_id: "NY",
    lat: 40.8887,
    lng: -73.2452,
    id: 1840005085,
  },
  {
    name: "Groveland",
    state_id: "FL",
    lat: 28.6021,
    lng: -81.8204,
    id: 1840014056,
  },
  {
    name: "Ojus",
    state_id: "FL",
    lat: 25.9563,
    lng: -80.1606,
    id: 1840028742,
  },
  {
    name: "East Riverdale",
    state_id: "MD",
    lat: 38.96,
    lng: -76.9108,
    id: 1840031482,
  },
  {
    name: "Mount Pleasant",
    state_id: "TX",
    lat: 33.1613,
    lng: -94.9717,
    id: 1840020666,
  },
  {
    name: "River Falls",
    state_id: "WI",
    lat: 44.861,
    lng: -92.6248,
    id: 1840002289,
  },
  {
    name: "Four Corners",
    state_id: "OR",
    lat: 44.9291,
    lng: -122.9731,
    id: 1840034722,
  },
  {
    name: "Hernando",
    state_id: "MS",
    lat: 34.85,
    lng: -89.9922,
    id: 1840014642,
  },
  {
    name: "Hillcrest Heights",
    state_id: "MD",
    lat: 38.8373,
    lng: -76.9641,
    id: 1840005957,
  },
  {
    name: "Ripon",
    state_id: "CA",
    lat: 37.7417,
    lng: -121.131,
    id: 1840020264,
  },
  {
    name: "Monroe",
    state_id: "GA",
    lat: 33.799,
    lng: -83.716,
    id: 1840014786,
  },
  {
    name: "Adelphi",
    state_id: "MD",
    lat: 39.0017,
    lng: -76.9649,
    id: 1840005944,
  },
  {
    name: "Rio Linda",
    state_id: "CA",
    lat: 38.6876,
    lng: -121.4418,
    id: 1840018843,
  },
  {
    name: "Bluffdale",
    state_id: "UT",
    lat: 40.4744,
    lng: -111.9381,
    id: 1840018745,
  },
  {
    name: "Storrs",
    state_id: "CT",
    lat: 41.8045,
    lng: -72.2552,
    id: 1840003276,
  },
  {
    name: "Hazel Park",
    state_id: "MI",
    lat: 42.4619,
    lng: -83.0977,
    id: 1840002444,
  },
  {
    name: "Burley",
    state_id: "ID",
    lat: 42.5379,
    lng: -113.793,
    id: 1840018683,
  },
  {
    name: "Aurora",
    state_id: "OH",
    lat: 41.3118,
    lng: -81.345,
    id: 1840000806,
  },
  {
    name: "Donna",
    state_id: "TX",
    lat: 26.1467,
    lng: -98.0559,
    id: 1840019734,
  },
  {
    name: "Stanford",
    state_id: "CA",
    lat: 37.4252,
    lng: -122.1674,
    id: 1840028400,
  },
  {
    name: "Red Wing",
    state_id: "MN",
    lat: 44.5816,
    lng: -92.6036,
    id: 1840008959,
  },
  {
    name: "Fallon",
    state_id: "NV",
    lat: 39.4738,
    lng: -118.778,
    id: 1840020191,
  },
  {
    name: "Fairview Heights",
    state_id: "IL",
    lat: 38.5974,
    lng: -90.0053,
    id: 1840008630,
  },
  {
    name: "Celina",
    state_id: "TX",
    lat: 33.3188,
    lng: -96.7865,
    id: 1840019395,
  },
  {
    name: "Gun Barrel City",
    state_id: "TX",
    lat: 32.3277,
    lng: -96.1287,
    id: 1840020772,
  },
  {
    name: "Morton",
    state_id: "IL",
    lat: 40.6136,
    lng: -89.4669,
    id: 1840011991,
  },
  {
    name: "Port Jervis",
    state_id: "NY",
    lat: 41.3783,
    lng: -74.6909,
    id: 1840000587,
  },
  {
    name: "Red Hill",
    state_id: "SC",
    lat: 33.7777,
    lng: -79.0111,
    id: 1840013649,
  },
  {
    name: "Kaukauna",
    state_id: "WI",
    lat: 44.2776,
    lng: -88.2645,
    id: 1840002403,
  },
  {
    name: "Addison",
    state_id: "TX",
    lat: 32.959,
    lng: -96.8355,
    id: 1840022057,
  },
  {
    name: "Fort Thomas",
    state_id: "KY",
    lat: 39.0802,
    lng: -84.4518,
    id: 1840013163,
  },
  {
    name: "Mebane",
    state_id: "NC",
    lat: 36.0857,
    lng: -79.2763,
    id: 1840015328,
  },
  {
    name: "Mount Holly",
    state_id: "NC",
    lat: 35.3136,
    lng: -81.0072,
    id: 1840014590,
  },
  {
    name: "Conyers",
    state_id: "GA",
    lat: 33.6645,
    lng: -83.9966,
    id: 1840014806,
  },
  {
    name: "East San Gabriel",
    state_id: "CA",
    lat: 34.1198,
    lng: -118.0807,
    id: 1840028311,
  },
  {
    name: "Centerton",
    state_id: "AR",
    lat: 36.3567,
    lng: -94.2971,
    id: 1840014441,
  },
  {
    name: "Stuart",
    state_id: "FL",
    lat: 27.1958,
    lng: -80.2438,
    id: 1840015990,
  },
  {
    name: "Sikeston",
    state_id: "MO",
    lat: 36.8865,
    lng: -89.587,
    id: 1840009921,
  },
  {
    name: "Sulphur Springs",
    state_id: "TX",
    lat: 33.1421,
    lng: -95.6124,
    id: 1840022031,
  },
  {
    name: "Vincent",
    state_id: "CA",
    lat: 34.0982,
    lng: -117.9238,
    id: 1840074687,
  },
  {
    name: "Boulder City",
    state_id: "NV",
    lat: 35.8407,
    lng: -114.9257,
    id: 1840019028,
  },
  {
    name: "Centralia",
    state_id: "IL",
    lat: 38.5223,
    lng: -89.1233,
    id: 1840007465,
  },
  {
    name: "Dixon",
    state_id: "IL",
    lat: 41.8439,
    lng: -89.4794,
    id: 1840007051,
  },
  {
    name: "Troutdale",
    state_id: "OR",
    lat: 45.5372,
    lng: -122.3955,
    id: 1840021209,
  },
  {
    name: "Mount Clemens",
    state_id: "MI",
    lat: 42.5976,
    lng: -82.882,
    id: 1840003089,
  },
  {
    name: "Fairview Park",
    state_id: "OH",
    lat: 41.4419,
    lng: -81.853,
    id: 1840000601,
  },
  {
    name: "Discovery Bay",
    state_id: "CA",
    lat: 37.9071,
    lng: -121.6076,
    id: 1840018897,
  },
  {
    name: "Stallings",
    state_id: "NC",
    lat: 35.1088,
    lng: -80.6597,
    id: 1840017878,
  },
  {
    name: "Cambridge",
    state_id: "MN",
    lat: 45.5612,
    lng: -93.2283,
    id: 1840006692,
  },
  {
    name: "Frankfort",
    state_id: "IN",
    lat: 40.281,
    lng: -86.5213,
    id: 1840008358,
  },
  {
    name: "Westchester",
    state_id: "IL",
    lat: 41.8492,
    lng: -87.8906,
    id: 1840011332,
  },
  {
    name: "Center Point",
    state_id: "AL",
    lat: 33.6446,
    lng: -86.6851,
    id: 1840014789,
  },
  {
    name: "Canyon",
    state_id: "TX",
    lat: 34.9877,
    lng: -101.9178,
    id: 1840019180,
  },
  {
    name: "West Haven",
    state_id: "UT",
    lat: 41.2082,
    lng: -112.054,
    id: 1840021344,
  },
  {
    name: "Washougal",
    state_id: "WA",
    lat: 45.5822,
    lng: -122.3448,
    id: 1840021190,
  },
  {
    name: "Bridgeview",
    state_id: "IL",
    lat: 41.7403,
    lng: -87.8067,
    id: 1840010140,
  },
  {
    name: "Troy",
    state_id: "MO",
    lat: 38.9708,
    lng: -90.9715,
    id: 1840010689,
  },
  {
    name: "Cherryland",
    state_id: "CA",
    lat: 37.6792,
    lng: -122.1038,
    id: 1840028361,
  },
  {
    name: "Lebanon",
    state_id: "IN",
    lat: 40.0324,
    lng: -86.4551,
    id: 1840008387,
  },
  {
    name: "Griffith",
    state_id: "IN",
    lat: 41.5279,
    lng: -87.424,
    id: 1840010225,
  },
  {
    name: "Villa Rica",
    state_id: "GA",
    lat: 33.7305,
    lng: -84.917,
    id: 1840015679,
  },
  {
    name: "Alma",
    state_id: "MI",
    lat: 43.38,
    lng: -84.6556,
    id: 1840002879,
  },
  {
    name: "Fort Leonard Wood",
    state_id: "MO",
    lat: 37.7562,
    lng: -92.1274,
    id: 1840073756,
  },
  {
    name: "Long Beach",
    state_id: "MS",
    lat: 30.3608,
    lng: -89.165,
    id: 1840015914,
  },
  {
    name: "Lady Lake",
    state_id: "FL",
    lat: 28.9241,
    lng: -81.9299,
    id: 1840017229,
  },
  {
    name: "Aberdeen",
    state_id: "MD",
    lat: 39.5146,
    lng: -76.173,
    id: 1840005666,
  },
  {
    name: "Welby",
    state_id: "CO",
    lat: 39.8403,
    lng: -104.9655,
    id: 1840028449,
  },
  {
    name: "Indianola",
    state_id: "IA",
    lat: 41.3629,
    lng: -93.5652,
    id: 1840000648,
  },
  {
    name: "Washington",
    state_id: "NC",
    lat: 35.5587,
    lng: -77.0545,
    id: 1840015401,
  },
  {
    name: "Phelan",
    state_id: "CA",
    lat: 34.4398,
    lng: -117.5248,
    id: 1840025798,
  },
  {
    name: "Lakeway",
    state_id: "TX",
    lat: 30.3547,
    lng: -97.9854,
    id: 1840020894,
  },
  {
    name: "Dyer",
    state_id: "IN",
    lat: 41.4976,
    lng: -87.509,
    id: 1840010224,
  },
  {
    name: "Archer Lodge",
    state_id: "NC",
    lat: 35.6907,
    lng: -78.3749,
    id: 1840025797,
  },
  {
    name: "Niceville",
    state_id: "FL",
    lat: 30.5291,
    lng: -86.4754,
    id: 1840015009,
  },
  {
    name: "Clearlake",
    state_id: "CA",
    lat: 38.959,
    lng: -122.633,
    id: 1840028139,
  },
  {
    name: "Fillmore",
    state_id: "CA",
    lat: 34.3989,
    lng: -118.9181,
    id: 1840020471,
  },
  {
    name: "Hood River",
    state_id: "OR",
    lat: 45.7092,
    lng: -121.5258,
    id: 1840019942,
  },
  {
    name: "Bellevue",
    state_id: "WI",
    lat: 44.4593,
    lng: -87.9554,
    id: 1840000308,
  },
  {
    name: "Spanish Springs",
    state_id: "NV",
    lat: 39.6567,
    lng: -119.6695,
    id: 1840033826,
  },
  {
    name: "New Haven",
    state_id: "IN",
    lat: 41.0675,
    lng: -85.0175,
    id: 1840008262,
  },
  {
    name: "New River",
    state_id: "AZ",
    lat: 33.8835,
    lng: -112.0858,
    id: 1840019307,
  },
  {
    name: "Sayville",
    state_id: "NY",
    lat: 40.7478,
    lng: -73.084,
    id: 1840005092,
  },
  {
    name: "Millbrook",
    state_id: "AL",
    lat: 32.5028,
    lng: -86.3737,
    id: 1840014877,
  },
  {
    name: "Walnut Park",
    state_id: "CA",
    lat: 33.9683,
    lng: -118.222,
    id: 1840019218,
  },
  {
    name: "Carthage",
    state_id: "MO",
    lat: 37.1503,
    lng: -94.3225,
    id: 1840007629,
  },
  {
    name: "Prospect Heights",
    state_id: "IL",
    lat: 42.1039,
    lng: -87.9267,
    id: 1840009184,
  },
  {
    name: "King City",
    state_id: "CA",
    lat: 36.2166,
    lng: -121.133,
    id: 1840020360,
  },
  {
    name: "Opa-locka",
    state_id: "FL",
    lat: 25.8997,
    lng: -80.2551,
    id: 1840015156,
  },
  {
    name: "Pataskala",
    state_id: "OH",
    lat: 40.011,
    lng: -82.7155,
    id: 1840001420,
  },
  {
    name: "Sunset",
    state_id: "FL",
    lat: 25.7061,
    lng: -80.353,
    id: 1840029084,
  },
  {
    name: "Grandville",
    state_id: "MI",
    lat: 42.9003,
    lng: -85.7564,
    id: 1840002929,
  },
  {
    name: "Hibbing",
    state_id: "MN",
    lat: 47.3981,
    lng: -92.9487,
    id: 1840006594,
  },
  {
    name: "Sarasota Springs",
    state_id: "FL",
    lat: 27.3092,
    lng: -82.4788,
    id: 1840029072,
  },
  {
    name: "Laguna Woods",
    state_id: "CA",
    lat: 33.6098,
    lng: -117.7299,
    id: 1840020590,
  },
  {
    name: "Hope Mills",
    state_id: "NC",
    lat: 34.969,
    lng: -78.9559,
    id: 1840016438,
  },
  {
    name: "Floral Park",
    state_id: "NY",
    lat: 40.7226,
    lng: -73.7029,
    id: 1840005286,
  },
  {
    name: "Sierra Vista Southeast",
    state_id: "AZ",
    lat: 31.4525,
    lng: -110.216,
    id: 1840074401,
  },
  {
    name: "White Center",
    state_id: "WA",
    lat: 47.5086,
    lng: -122.348,
    id: 1840037727,
  },
  {
    name: "Wilmington Island",
    state_id: "GA",
    lat: 32.0036,
    lng: -80.9752,
    id: 1840029461,
  },
  {
    name: "Easthampton",
    state_id: "MA",
    lat: 42.2652,
    lng: -72.672,
    id: 1840031156,
  },
  {
    name: "Humble",
    state_id: "TX",
    lat: 29.9921,
    lng: -95.2655,
    id: 1840020926,
  },
  {
    name: "Parkway",
    state_id: "CA",
    lat: 38.4993,
    lng: -121.452,
    id: 1840024628,
  },
  {
    name: "Payson",
    state_id: "AZ",
    lat: 34.2434,
    lng: -111.3195,
    id: 1840022857,
  },
  {
    name: "Port Washington",
    state_id: "NY",
    lat: 40.8268,
    lng: -73.6764,
    id: 1840005252,
  },
  {
    name: "Circleville",
    state_id: "OH",
    lat: 39.6063,
    lng: -82.9334,
    id: 1840007317,
  },
  {
    name: "East Rancho Dominguez",
    state_id: "CA",
    lat: 33.895,
    lng: -118.1956,
    id: 1840028368,
  },
  {
    name: "Fullerton",
    state_id: "PA",
    lat: 40.6308,
    lng: -75.4834,
    id: 1840034977,
  },
  {
    name: "Sterling",
    state_id: "CO",
    lat: 40.6206,
    lng: -103.1925,
    id: 1840021377,
  },
  {
    name: "South River",
    state_id: "NJ",
    lat: 40.4455,
    lng: -74.3784,
    id: 1840001335,
  },
  {
    name: "Powder Springs",
    state_id: "GA",
    lat: 33.8659,
    lng: -84.6838,
    id: 1840014755,
  },
  {
    name: "Flowing Wells",
    state_id: "AZ",
    lat: 32.2937,
    lng: -111.011,
    id: 1840027988,
  },
  {
    name: "Purcellville",
    state_id: "VA",
    lat: 39.1378,
    lng: -77.7109,
    id: 1840005875,
  },
  {
    name: "Greenwood Village",
    state_id: "CO",
    lat: 39.6152,
    lng: -104.913,
    id: 1840020212,
  },
  {
    name: "Eggertsville",
    state_id: "NY",
    lat: 42.9665,
    lng: -78.8065,
    id: 1840024061,
  },
  {
    name: "Farmingville",
    state_id: "NY",
    lat: 40.8389,
    lng: -73.0401,
    id: 1840005009,
  },
  {
    name: "Andrews",
    state_id: "TX",
    lat: 32.3208,
    lng: -102.552,
    id: 1840019474,
  },
  {
    name: "Blytheville",
    state_id: "AR",
    lat: 35.9321,
    lng: -89.9051,
    id: 1840013390,
  },
  {
    name: "Shively",
    state_id: "KY",
    lat: 38.197,
    lng: -85.8136,
    id: 1840015196,
  },
  {
    name: "North Arlington",
    state_id: "NJ",
    lat: 40.7875,
    lng: -74.1273,
    id: 1840000909,
  },
  {
    name: "Mitchell",
    state_id: "SD",
    lat: 43.7296,
    lng: -98.0337,
    id: 1840002642,
  },
  {
    name: "McFarland",
    state_id: "CA",
    lat: 35.6781,
    lng: -119.2414,
    id: 1840020419,
  },
  {
    name: "Graham",
    state_id: "NC",
    lat: 36.0596,
    lng: -79.3892,
    id: 1840013361,
  },
  {
    name: "Palmetto Estates",
    state_id: "FL",
    lat: 25.6211,
    lng: -80.3616,
    id: 1840029063,
  },
  {
    name: "Tanque Verde",
    state_id: "AZ",
    lat: 32.2687,
    lng: -110.7437,
    id: 1840019489,
  },
  {
    name: "Elkton",
    state_id: "MD",
    lat: 39.6067,
    lng: -75.8208,
    id: 1840005635,
  },
  {
    name: "Parlier",
    state_id: "CA",
    lat: 36.6087,
    lng: -119.5434,
    id: 1840020323,
  },
  {
    name: "Three Lakes",
    state_id: "FL",
    lat: 25.6415,
    lng: -80.4,
    id: 1840029010,
  },
  {
    name: "Fate",
    state_id: "TX",
    lat: 32.9429,
    lng: -96.3858,
    id: 1840020715,
  },
  {
    name: "Riverdale",
    state_id: "GA",
    lat: 33.564,
    lng: -84.4103,
    id: 1840014815,
  },
  {
    name: "Fort Mohave",
    state_id: "AZ",
    lat: 35.0004,
    lng: -114.5748,
    id: 1840042961,
  },
  {
    name: "Chubbuck",
    state_id: "ID",
    lat: 42.9261,
    lng: -112.4624,
    id: 1840018667,
  },
  {
    name: "West University Place",
    state_id: "TX",
    lat: 29.7157,
    lng: -95.4321,
    id: 1840022205,
  },
  {
    name: "Newton",
    state_id: "IA",
    lat: 41.6964,
    lng: -93.0402,
    id: 1840008171,
  },
  {
    name: "Miami",
    state_id: "OK",
    lat: 36.8877,
    lng: -94.8718,
    id: 1840020346,
  },
  {
    name: "McMinnville",
    state_id: "TN",
    lat: 35.6863,
    lng: -85.7812,
    id: 1840015385,
  },
  {
    name: "Longwood",
    state_id: "FL",
    lat: 28.7014,
    lng: -81.3487,
    id: 1840015957,
  },
  {
    name: "Overland",
    state_id: "MO",
    lat: 38.6966,
    lng: -90.3689,
    id: 1840009744,
  },
  {
    name: "Dunn",
    state_id: "NC",
    lat: 35.3113,
    lng: -78.6129,
    id: 1840013434,
  },
  {
    name: "Houghton",
    state_id: "MI",
    lat: 47.1119,
    lng: -88.5672,
    id: 1840003919,
  },
  {
    name: "Ladson",
    state_id: "SC",
    lat: 33.0092,
    lng: -80.1078,
    id: 1840014254,
  },
  {
    name: "Blackfoot",
    state_id: "ID",
    lat: 43.194,
    lng: -112.3455,
    id: 1840018650,
  },
  {
    name: "Buford",
    state_id: "GA",
    lat: 34.1185,
    lng: -83.9916,
    id: 1840013678,
  },
  {
    name: "Lake Geneva",
    state_id: "WI",
    lat: 42.5824,
    lng: -88.4281,
    id: 1840002479,
  },
  {
    name: "Harrison",
    state_id: "OH",
    lat: 39.2582,
    lng: -84.7868,
    id: 1840007381,
  },
  {
    name: "La Grande",
    state_id: "OR",
    lat: 45.3243,
    lng: -118.0865,
    id: 1840019924,
  },
  {
    name: "East Highland Park",
    state_id: "VA",
    lat: 37.577,
    lng: -77.3865,
    id: 1840006388,
  },
  {
    name: "Glen Allen",
    state_id: "VA",
    lat: 37.666,
    lng: -77.4838,
    id: 1840006390,
  },
  {
    name: "Seven Oaks",
    state_id: "SC",
    lat: 34.0474,
    lng: -81.1434,
    id: 1840035850,
  },
  {
    name: "Groves",
    state_id: "TX",
    lat: 29.9457,
    lng: -93.9164,
    id: 1840020916,
  },
  {
    name: "Mastic",
    state_id: "NY",
    lat: 40.8098,
    lng: -72.8479,
    id: 1840005090,
  },
  {
    name: "Hermitage",
    state_id: "PA",
    lat: 41.2305,
    lng: -80.4414,
    id: 1840000732,
  },
  {
    name: "Cloverly",
    state_id: "MD",
    lat: 39.1065,
    lng: -76.9993,
    id: 1840031267,
  },
  {
    name: "Talladega",
    state_id: "AL",
    lat: 33.4329,
    lng: -86.0976,
    id: 1840008244,
  },
  {
    name: "Hartford",
    state_id: "WI",
    lat: 43.3223,
    lng: -88.3782,
    id: 1840002831,
  },
  {
    name: "Holly Springs",
    state_id: "GA",
    lat: 34.1685,
    lng: -84.4845,
    id: 1840014704,
  },
  {
    name: "Belvedere Park",
    state_id: "GA",
    lat: 33.7488,
    lng: -84.2598,
    id: 1840013699,
  },
  {
    name: "Hayden",
    state_id: "ID",
    lat: 47.768,
    lng: -116.8039,
    id: 1840019808,
  },
  {
    name: "Middleburg Heights",
    state_id: "OH",
    lat: 41.3695,
    lng: -81.8151,
    id: 1840000614,
  },
  {
    name: "La Palma",
    state_id: "CA",
    lat: 33.8504,
    lng: -118.0406,
    id: 1840020586,
  },
  {
    name: "Mount Vernon",
    state_id: "IL",
    lat: 38.314,
    lng: -88.9174,
    id: 1840008654,
  },
  {
    name: "Avocado Heights",
    state_id: "CA",
    lat: 34.0381,
    lng: -118.0026,
    id: 1840028318,
  },
  {
    name: "Pacific Grove",
    state_id: "CA",
    lat: 36.6192,
    lng: -121.9255,
    id: 1840020359,
  },
  {
    name: "Asbury Park",
    state_id: "NJ",
    lat: 40.2226,
    lng: -74.0119,
    id: 1840003684,
  },
  {
    name: "Pulaski",
    state_id: "VA",
    lat: 37.0528,
    lng: -80.7624,
    id: 1840006469,
  },
  {
    name: "Northview",
    state_id: "MI",
    lat: 43.0427,
    lng: -85.6016,
    id: 1840004305,
  },
  {
    name: "Bellmore",
    state_id: "NY",
    lat: 40.6569,
    lng: -73.5285,
    id: 1840005232,
  },
  {
    name: "Berkley",
    state_id: "MI",
    lat: 42.4986,
    lng: -83.1853,
    id: 1840002437,
  },
  {
    name: "Washington",
    state_id: "NJ",
    lat: 40.7587,
    lng: -74.9825,
    id: 1840000945,
  },
  {
    name: "Westbury",
    state_id: "NY",
    lat: 40.7599,
    lng: -73.5891,
    id: 1840005332,
  },
  {
    name: "Grosse Pointe Woods",
    state_id: "MI",
    lat: 42.4366,
    lng: -82.8987,
    id: 1840003979,
  },
  {
    name: "Hueytown",
    state_id: "AL",
    lat: 33.4237,
    lng: -87.022,
    id: 1840014796,
  },
  {
    name: "La Grange",
    state_id: "IL",
    lat: 41.8072,
    lng: -87.8741,
    id: 1840011255,
  },
  {
    name: "Aldine",
    state_id: "TX",
    lat: 29.9122,
    lng: -95.3785,
    id: 1840018250,
  },
  {
    name: "Bradley",
    state_id: "IL",
    lat: 41.1641,
    lng: -87.8452,
    id: 1840011693,
  },
  {
    name: "Southchase",
    state_id: "FL",
    lat: 28.3793,
    lng: -81.3903,
    id: 1840029005,
  },
  {
    name: "Wilkinsburg",
    state_id: "PA",
    lat: 40.4442,
    lng: -79.8733,
    id: 1840001278,
  },
  {
    name: "Kerman",
    state_id: "CA",
    lat: 36.7249,
    lng: -120.0625,
    id: 1840020324,
  },
  {
    name: "Hugo",
    state_id: "MN",
    lat: 45.1671,
    lng: -92.9588,
    id: 1840006739,
  },
  {
    name: "Fords",
    state_id: "NJ",
    lat: 40.5415,
    lng: -74.3124,
    id: 1840005409,
  },
  {
    name: "Damascus",
    state_id: "MD",
    lat: 39.2737,
    lng: -77.2006,
    id: 1840005832,
  },
  {
    name: "South Farmingdale",
    state_id: "NY",
    lat: 40.7175,
    lng: -73.4471,
    id: 1840005276,
  },
  {
    name: "Mineral Wells",
    state_id: "TX",
    lat: 32.8169,
    lng: -98.0776,
    id: 1840020689,
  },
  {
    name: "Bay Village",
    state_id: "OH",
    lat: 41.4851,
    lng: -81.9315,
    id: 1840003394,
  },
  {
    name: "Candelaria",
    state_id: "PR",
    lat: 18.4043,
    lng: -66.2175,
    id: 1630023689,
  },
  {
    name: "Elk Plain",
    state_id: "WA",
    lat: 47.0448,
    lng: -122.3671,
    id: 1840037546,
  },
  {
    name: "Weston",
    state_id: "WI",
    lat: 44.8905,
    lng: -89.5487,
    id: 1840038093,
  },
  {
    name: "Hereford",
    state_id: "TX",
    lat: 34.8232,
    lng: -102.4001,
    id: 1840020462,
  },
  {
    name: "Galion",
    state_id: "OH",
    lat: 40.7385,
    lng: -82.7792,
    id: 1840002752,
  },
  {
    name: "College Park",
    state_id: "GA",
    lat: 33.6363,
    lng: -84.464,
    id: 1840014737,
  },
  {
    name: "Dentsville",
    state_id: "SC",
    lat: 34.0754,
    lng: -80.9546,
    id: 1840013089,
  },
  {
    name: "Grovetown",
    state_id: "GA",
    lat: 33.4504,
    lng: -82.2073,
    id: 1840013748,
  },
  {
    name: "Pleasanton",
    state_id: "TX",
    lat: 28.9643,
    lng: -98.4957,
    id: 1840020988,
  },
  {
    name: "Great Bend",
    state_id: "KS",
    lat: 38.3593,
    lng: -98.8016,
    id: 1840001656,
  },
  {
    name: "Gantt",
    state_id: "SC",
    lat: 34.7837,
    lng: -82.4027,
    id: 1840013492,
  },
  {
    name: "Rossville",
    state_id: "MD",
    lat: 39.3572,
    lng: -76.4767,
    id: 1840031398,
  },
  {
    name: "Englewood",
    state_id: "FL",
    lat: 26.9603,
    lng: -82.3535,
    id: 1840013127,
  },
  {
    name: "West Park",
    state_id: "FL",
    lat: 25.984,
    lng: -80.1923,
    id: 1840016002,
  },
  {
    name: "Jamestown",
    state_id: "ND",
    lat: 46.9063,
    lng: -98.6936,
    id: 1840000153,
  },
  {
    name: "North New Hyde Park",
    state_id: "NY",
    lat: 40.746,
    lng: -73.6876,
    id: 1840005243,
  },
  {
    name: "West Richland",
    state_id: "WA",
    lat: 46.3115,
    lng: -119.3998,
    id: 1840021168,
  },
  {
    name: "Park City",
    state_id: "UT",
    lat: 40.6505,
    lng: -111.502,
    id: 1840020140,
  },
  {
    name: "Oldsmar",
    state_id: "FL",
    lat: 28.0507,
    lng: -82.6698,
    id: 1840015111,
  },
  {
    name: "Washington Court House",
    state_id: "OH",
    lat: 39.5383,
    lng: -83.4279,
    id: 1840003801,
  },
  {
    name: "Coto de Caza",
    state_id: "CA",
    lat: 33.5961,
    lng: -117.5862,
    id: 1840028363,
  },
  {
    name: "Seaford",
    state_id: "NY",
    lat: 40.6678,
    lng: -73.4922,
    id: 1840005275,
  },
  {
    name: "Kenmore",
    state_id: "NY",
    lat: 42.9646,
    lng: -78.8713,
    id: 1840004386,
  },
  {
    name: "Depew",
    state_id: "NY",
    lat: 42.9117,
    lng: -78.7044,
    id: 1840004397,
  },
  {
    name: "Susanville",
    state_id: "CA",
    lat: 40.4205,
    lng: -120.6129,
    id: 1840021348,
  },
  {
    name: "Vandalia",
    state_id: "OH",
    lat: 39.879,
    lng: -84.193,
    id: 1840003786,
  },
  {
    name: "Willow Grove",
    state_id: "PA",
    lat: 40.1495,
    lng: -75.1178,
    id: 1840005471,
  },
  {
    name: "Ocean Pointe",
    state_id: "HI",
    lat: 21.3145,
    lng: -158.0289,
    id: 1840039242,
  },
  {
    name: "Fort Carson",
    state_id: "CO",
    lat: 38.7403,
    lng: -104.784,
    id: 1840028557,
  },
  {
    name: "Beech Grove",
    state_id: "IN",
    lat: 39.7157,
    lng: -86.0871,
    id: 1840007307,
  },
  {
    name: "Hewitt",
    state_id: "TX",
    lat: 31.452,
    lng: -97.196,
    id: 1840020809,
  },
  {
    name: "Newport",
    state_id: "KY",
    lat: 39.0855,
    lng: -84.4868,
    id: 1840014261,
  },
  {
    name: "Tehachapi",
    state_id: "CA",
    lat: 35.1274,
    lng: -118.4749,
    id: 1840021737,
  },
  {
    name: "Astoria",
    state_id: "OR",
    lat: 46.1856,
    lng: -123.8053,
    id: 1840018504,
  },
  {
    name: "Alamo",
    state_id: "CA",
    lat: 37.8546,
    lng: -122.013,
    id: 1840017609,
  },
  {
    name: "Taft",
    state_id: "CA",
    lat: 35.1267,
    lng: -119.4242,
    id: 1840021736,
  },
  {
    name: "Ferndale",
    state_id: "WA",
    lat: 48.8525,
    lng: -122.5893,
    id: 1840019751,
  },
  {
    name: "Livingston",
    state_id: "CA",
    lat: 37.3875,
    lng: -120.7248,
    id: 1840020311,
  },
  {
    name: "Whitewater",
    state_id: "WI",
    lat: 42.8372,
    lng: -88.7341,
    id: 1840002476,
  },
  {
    name: "Clarksdale",
    state_id: "MS",
    lat: 34.1971,
    lng: -90.5729,
    id: 1840014696,
  },
  {
    name: "West Lealman",
    state_id: "FL",
    lat: 27.8191,
    lng: -82.7385,
    id: 1840039134,
  },
  {
    name: "Franklin Park",
    state_id: "PA",
    lat: 40.5903,
    lng: -80.0999,
    id: 1840001198,
  },
  {
    name: "Ramsey",
    state_id: "NJ",
    lat: 41.0595,
    lng: -74.1454,
    id: 1840000896,
  },
  {
    name: "New Freedom",
    state_id: "PA",
    lat: 39.7353,
    lng: -76.6967,
    id: 1840001457,
  },
  {
    name: "Alpine",
    state_id: "CA",
    lat: 32.8439,
    lng: -116.7585,
    id: 1840018014,
  },
  {
    name: "Great Falls",
    state_id: "VA",
    lat: 39.011,
    lng: -77.3013,
    id: 1840006028,
  },
  {
    name: "Yankton",
    state_id: "SD",
    lat: 42.8901,
    lng: -97.3926,
    id: 1840003058,
  },
  {
    name: "London",
    state_id: "OH",
    lat: 39.8936,
    lng: -83.4376,
    id: 1840000674,
  },
  {
    name: "New Cassel",
    state_id: "NY",
    lat: 40.76,
    lng: -73.5649,
    id: 1840005239,
  },
  {
    name: "Salem Lakes",
    state_id: "WI",
    lat: 42.5366,
    lng: -88.1307,
    id: 1840038106,
  },
  {
    name: "Brooklyn Park",
    state_id: "MD",
    lat: 39.217,
    lng: -76.6174,
    id: 1840031312,
  },
  {
    name: "Hampton Bays",
    state_id: "NY",
    lat: 40.8692,
    lng: -72.5227,
    id: 1840005076,
  },
  {
    name: "Jackson",
    state_id: "MO",
    lat: 37.3792,
    lng: -89.6521,
    id: 1840008735,
  },
  {
    name: "Yazoo City",
    state_id: "MS",
    lat: 32.8618,
    lng: -90.4067,
    id: 1840015766,
  },
  {
    name: "Mount Washington",
    state_id: "KY",
    lat: 38.043,
    lng: -85.5549,
    id: 1840014322,
  },
  {
    name: "Jacksonville",
    state_id: "TX",
    lat: 31.9642,
    lng: -95.2617,
    id: 1840019515,
  },
  {
    name: "California",
    state_id: "MD",
    lat: 38.2969,
    lng: -76.4949,
    id: 1840006228,
  },
  {
    name: "Grimes",
    state_id: "IA",
    lat: 41.6776,
    lng: -93.7946,
    id: 1840007068,
  },
  {
    name: "Bellair-Meadowbrook Terrace",
    state_id: "FL",
    lat: 30.1796,
    lng: -81.7375,
    id: 1840073845,
  },
  {
    name: "Lamont",
    state_id: "CA",
    lat: 35.2651,
    lng: -118.9159,
    id: 1840017825,
  },
  {
    name: "Lebanon",
    state_id: "MO",
    lat: 37.6717,
    lng: -92.6603,
    id: 1840008711,
  },
  {
    name: "Hot Springs Village",
    state_id: "AR",
    lat: 34.6568,
    lng: -92.9644,
    id: 1840013587,
  },
  {
    name: "St. Simons",
    state_id: "GA",
    lat: 31.1774,
    lng: -81.3857,
    id: 1840013874,
  },
  {
    name: "Campbellsville",
    state_id: "KY",
    lat: 37.3446,
    lng: -85.3511,
    id: 1840013242,
  },
  {
    name: "Bedford",
    state_id: "IN",
    lat: 38.8602,
    lng: -86.4895,
    id: 1840007426,
  },
  {
    name: "Nocatee",
    state_id: "FL",
    lat: 30.0918,
    lng: -81.4097,
    id: 1840028805,
  },
  {
    name: "Tonawanda",
    state_id: "NY",
    lat: 43.0105,
    lng: -78.8805,
    id: 1840004381,
  },
  {
    name: "Cambridge",
    state_id: "OH",
    lat: 40.0221,
    lng: -81.5868,
    id: 1840007259,
  },
  {
    name: "Lemon Hill",
    state_id: "CA",
    lat: 38.5172,
    lng: -121.4573,
    id: 1840075922,
  },
  {
    name: "Kemp Mill",
    state_id: "MD",
    lat: 39.0412,
    lng: -77.0215,
    id: 1840005842,
  },
  {
    name: "Calera",
    state_id: "AL",
    lat: 33.1249,
    lng: -86.745,
    id: 1840013757,
  },
  {
    name: "Youngsville",
    state_id: "LA",
    lat: 30.0964,
    lng: -91.9963,
    id: 1840015931,
  },
  {
    name: "Royse City",
    state_id: "TX",
    lat: 32.9762,
    lng: -96.3175,
    id: 1840020718,
  },
  {
    name: "Azalea Park",
    state_id: "FL",
    lat: 28.5473,
    lng: -81.2956,
    id: 1840014082,
  },
  {
    name: "Hartsville",
    state_id: "SC",
    lat: 34.3676,
    lng: -80.0833,
    id: 1840013610,
  },
  {
    name: "Worthington",
    state_id: "OH",
    lat: 40.0949,
    lng: -83.021,
    id: 1840010522,
  },
  {
    name: "Las Vegas",
    state_id: "NM",
    lat: 35.6011,
    lng: -105.2206,
    id: 1840020397,
  },
  {
    name: "Pierre",
    state_id: "SD",
    lat: 44.3748,
    lng: -100.3205,
    id: 1840000313,
  },
  {
    name: "Southern Pines",
    state_id: "NC",
    lat: 35.1927,
    lng: -79.404,
    id: 1840017850,
  },
  {
    name: "Hershey",
    state_id: "PA",
    lat: 40.2806,
    lng: -76.6458,
    id: 1840005400,
  },
  {
    name: "Berea",
    state_id: "SC",
    lat: 34.8802,
    lng: -82.4653,
    id: 1840013495,
  },
  {
    name: "Saraland",
    state_id: "AL",
    lat: 30.8478,
    lng: -88.1004,
    id: 1840015886,
  },
  {
    name: "El Dorado",
    state_id: "KS",
    lat: 37.8209,
    lng: -96.8614,
    id: 1840001676,
  },
  {
    name: "Manor",
    state_id: "TX",
    lat: 30.3562,
    lng: -97.5228,
    id: 1840020890,
  },
  {
    name: "Williamstown",
    state_id: "NJ",
    lat: 39.6874,
    lng: -74.9786,
    id: 1840016785,
  },
  {
    name: "Paradise Valley",
    state_id: "AZ",
    lat: 33.5434,
    lng: -111.9595,
    id: 1840022913,
  },
  {
    name: "Pewaukee",
    state_id: "WI",
    lat: 43.0701,
    lng: -88.2411,
    id: 1840033459,
  },
  {
    name: "Falls Church",
    state_id: "VA",
    lat: 38.8847,
    lng: -77.1751,
    id: 1840003835,
  },
  {
    name: "Gonzalez",
    state_id: "FL",
    lat: 30.5822,
    lng: -87.2906,
    id: 1840013910,
  },
  {
    name: "Port Orchard",
    state_id: "WA",
    lat: 47.5163,
    lng: -122.661,
    id: 1840019821,
  },
  {
    name: "Forest City",
    state_id: "FL",
    lat: 28.6619,
    lng: -81.4443,
    id: 1840028825,
  },
  {
    name: "Muscle Shoals",
    state_id: "AL",
    lat: 34.7436,
    lng: -87.6344,
    id: 1840014657,
  },
  {
    name: "Jennings",
    state_id: "MO",
    lat: 38.723,
    lng: -90.2644,
    id: 1840008596,
  },
  {
    name: "Henderson",
    state_id: "TX",
    lat: 32.1576,
    lng: -94.796,
    id: 1840020767,
  },
  {
    name: "Connersville",
    state_id: "IN",
    lat: 39.6581,
    lng: -85.141,
    id: 1840007318,
  },
  {
    name: "Royal Kunia",
    state_id: "HI",
    lat: 21.4053,
    lng: -158.0318,
    id: 1840137108,
  },
  {
    name: "Metuchen",
    state_id: "NJ",
    lat: 40.5424,
    lng: -74.3628,
    id: 1840003633,
  },
  {
    name: "Grain Valley",
    state_id: "MO",
    lat: 39.0168,
    lng: -94.2085,
    id: 1840008531,
  },
  {
    name: "Mount Dora",
    state_id: "FL",
    lat: 28.8143,
    lng: -81.6344,
    id: 1840015082,
  },
  {
    name: "Glasgow",
    state_id: "KY",
    lat: 37.0047,
    lng: -85.9263,
    id: 1840013268,
  },
  {
    name: "Fraser",
    state_id: "MI",
    lat: 42.5388,
    lng: -82.9496,
    id: 1840002421,
  },
  {
    name: "Ewa Beach",
    state_id: "HI",
    lat: 21.3181,
    lng: -158.0073,
    id: 1840029470,
  },
  {
    name: "Sun Lakes",
    state_id: "AZ",
    lat: 33.2172,
    lng: -111.8695,
    id: 1840019313,
  },
  {
    name: "Corinth",
    state_id: "MS",
    lat: 34.9474,
    lng: -88.5143,
    id: 1840014638,
  },
  {
    name: "Hartselle",
    state_id: "AL",
    lat: 34.4391,
    lng: -86.94,
    id: 1840013593,
  },
  {
    name: "Thonotosassa",
    state_id: "FL",
    lat: 28.0465,
    lng: -82.291,
    id: 1840014154,
  },
  {
    name: "Fostoria",
    state_id: "OH",
    lat: 41.1601,
    lng: -83.412,
    id: 1840000820,
  },
  {
    name: "Tenafly",
    state_id: "NJ",
    lat: 40.9175,
    lng: -73.9531,
    id: 1840000918,
  },
  {
    name: "Halawa",
    state_id: "HI",
    lat: 21.3753,
    lng: -157.9185,
    id: 1840029579,
  },
  {
    name: "Chaparral",
    state_id: "NM",
    lat: 32.0442,
    lng: -106.4061,
    id: 1840018050,
  },
  {
    name: "Scottsboro",
    state_id: "AL",
    lat: 34.6438,
    lng: -86.0491,
    id: 1840005605,
  },
  {
    name: "Lockhart",
    state_id: "FL",
    lat: 28.6271,
    lng: -81.4354,
    id: 1840014095,
  },
  {
    name: "Thibodaux",
    state_id: "LA",
    lat: 29.7941,
    lng: -90.8163,
    id: 1840015946,
  },
  {
    name: "Washington",
    state_id: "MO",
    lat: 38.5514,
    lng: -91.0151,
    id: 1840010770,
  },
  {
    name: "Bradley Gardens",
    state_id: "NJ",
    lat: 40.5711,
    lng: -74.6678,
    id: 1840033232,
  },
  {
    name: "Greenlawn",
    state_id: "NY",
    lat: 40.863,
    lng: -73.3642,
    id: 1840005074,
  },
  {
    name: "Robbinsdale",
    state_id: "MN",
    lat: 45.0261,
    lng: -93.3332,
    id: 1840008931,
  },
  {
    name: "Clewiston",
    state_id: "FL",
    lat: 26.7529,
    lng: -80.9399,
    id: 1840015128,
  },
  {
    name: "North Fair Oaks",
    state_id: "CA",
    lat: 37.4754,
    lng: -122.2035,
    id: 1840028325,
  },
  {
    name: "Warren",
    state_id: "PA",
    lat: 41.8433,
    lng: -79.1445,
    id: 1840003334,
  },
  {
    name: "Chippewa Falls",
    state_id: "WI",
    lat: 44.9358,
    lng: -91.3902,
    id: 1840002108,
  },
  {
    name: "Ocean View",
    state_id: "DE",
    lat: 38.5355,
    lng: -75.0984,
    id: 1840006082,
  },
  {
    name: "Glasgow",
    state_id: "DE",
    lat: 39.6015,
    lng: -75.7474,
    id: 1840005571,
  },
  {
    name: "Bridgetown",
    state_id: "OH",
    lat: 39.1551,
    lng: -84.6359,
    id: 1840034100,
  },
  {
    name: "Warrington",
    state_id: "FL",
    lat: 30.3821,
    lng: -87.2944,
    id: 1840013914,
  },
  {
    name: "Cayey",
    state_id: "PR",
    lat: 18.115,
    lng: -66.163,
    id: 1630035640,
  },
  {
    name: "Midlothian",
    state_id: "IL",
    lat: 41.6254,
    lng: -87.7243,
    id: 1840011298,
  },
  {
    name: "Summerfield",
    state_id: "MD",
    lat: 38.9042,
    lng: -76.8678,
    id: 1840039531,
  },
  {
    name: "Hollins",
    state_id: "VA",
    lat: 37.3434,
    lng: -79.9535,
    id: 1840006441,
  },
  {
    name: "Mountain Home",
    state_id: "AR",
    lat: 36.3348,
    lng: -92.3846,
    id: 1840014452,
  },
  {
    name: "Alexander City",
    state_id: "AL",
    lat: 32.9242,
    lng: -85.9361,
    id: 1840013788,
  },
  {
    name: "Camden",
    state_id: "SC",
    lat: 34.2565,
    lng: -80.6087,
    id: 1840013598,
  },
  {
    name: "Toppenish",
    state_id: "WA",
    lat: 46.3806,
    lng: -120.3123,
    id: 1840021151,
  },
  {
    name: "Ozark",
    state_id: "AL",
    lat: 31.4508,
    lng: -85.6473,
    id: 1840005283,
  },
  {
    name: "Douglas",
    state_id: "GA",
    lat: 31.5065,
    lng: -82.8543,
    id: 1840014946,
  },
  {
    name: "North Auburn",
    state_id: "CA",
    lat: 38.9306,
    lng: -121.0821,
    id: 1840028390,
  },
  {
    name: "Mill Valley",
    state_id: "CA",
    lat: 37.9085,
    lng: -122.5421,
    id: 1840020259,
  },
  {
    name: "Tamaqua",
    state_id: "PA",
    lat: 40.8033,
    lng: -75.9344,
    id: 1840002780,
  },
  {
    name: "Destin",
    state_id: "FL",
    lat: 30.395,
    lng: -86.4701,
    id: 1840015008,
  },
  {
    name: "Salida",
    state_id: "CA",
    lat: 37.7145,
    lng: -121.087,
    id: 1840018908,
  },
  {
    name: "Lewisville",
    state_id: "NC",
    lat: 36.103,
    lng: -80.4164,
    id: 1840016124,
  },
  {
    name: "Kings Park West",
    state_id: "VA",
    lat: 38.8151,
    lng: -77.296,
    id: 1840041769,
  },
  {
    name: "Stone Ridge",
    state_id: "VA",
    lat: 38.9295,
    lng: -77.5553,
    id: 1840041868,
  },
  {
    name: "East Wenatchee",
    state_id: "WA",
    lat: 47.4174,
    lng: -120.2822,
    id: 1840019795,
  },
  {
    name: "Lindenhurst",
    state_id: "IL",
    lat: 42.4175,
    lng: -88.0257,
    id: 1840011163,
  },
  {
    name: "Phillipsburg",
    state_id: "NJ",
    lat: 40.6895,
    lng: -75.1821,
    id: 1840000944,
  },
  {
    name: "Covington",
    state_id: "GA",
    lat: 33.6049,
    lng: -83.8465,
    id: 1840014808,
  },
  {
    name: "Jefferson Valley-Yorktown",
    state_id: "NY",
    lat: 41.3179,
    lng: -73.8007,
    id: 1840073699,
  },
  {
    name: "Rincon",
    state_id: "GA",
    lat: 32.2947,
    lng: -81.2353,
    id: 1840018091,
  },
  {
    name: "California City",
    state_id: "CA",
    lat: 35.1578,
    lng: -117.8721,
    id: 1840019149,
  },
  {
    name: "Taos",
    state_id: "NM",
    lat: 36.3871,
    lng: -105.5804,
    id: 1840022638,
  },
  {
    name: "Fairview",
    state_id: "NJ",
    lat: 40.8182,
    lng: -74.0022,
    id: 1840003549,
  },
  {
    name: "Hopatcong",
    state_id: "NJ",
    lat: 40.9541,
    lng: -74.6593,
    id: 1840003482,
  },
  {
    name: "Hidalgo",
    state_id: "TX",
    lat: 26.109,
    lng: -98.2463,
    id: 1840021023,
  },
  {
    name: "Cambridge",
    state_id: "MD",
    lat: 38.5515,
    lng: -76.0786,
    id: 1840006165,
  },
  {
    name: "Gardendale",
    state_id: "AL",
    lat: 33.6678,
    lng: -86.8069,
    id: 1840002820,
  },
  {
    name: "Antioch",
    state_id: "IL",
    lat: 42.4742,
    lng: -88.0721,
    id: 1840010115,
  },
  {
    name: "Johnson City",
    state_id: "NY",
    lat: 42.123,
    lng: -75.9624,
    id: 1840004658,
  },
  {
    name: "Hawaiian Gardens",
    state_id: "CA",
    lat: 33.8303,
    lng: -118.0728,
    id: 1840020485,
  },
  {
    name: "Hendersonville",
    state_id: "NC",
    lat: 35.3242,
    lng: -82.4576,
    id: 1840014561,
  },
  {
    name: "Norridge",
    state_id: "IL",
    lat: 41.9637,
    lng: -87.8231,
    id: 1840011302,
  },
  {
    name: "Seabrook",
    state_id: "TX",
    lat: 29.5751,
    lng: -95.0236,
    id: 1840022197,
  },
  {
    name: "Crowley",
    state_id: "LA",
    lat: 30.2175,
    lng: -92.3752,
    id: 1840015041,
  },
  {
    name: "Sauk Rapids",
    state_id: "MN",
    lat: 45.5981,
    lng: -94.1539,
    id: 1840008873,
  },
  {
    name: "Greendale",
    state_id: "WI",
    lat: 42.9371,
    lng: -88.0018,
    id: 1840003038,
  },
  {
    name: "Lockhart",
    state_id: "TX",
    lat: 29.8785,
    lng: -97.6831,
    id: 1840020936,
  },
  {
    name: "New Franklin",
    state_id: "OH",
    lat: 40.9525,
    lng: -81.5838,
    id: 1840008255,
  },
  {
    name: "Chelsea",
    state_id: "AL",
    lat: 33.3255,
    lng: -86.6299,
    id: 1840014823,
  },
  {
    name: "Ionia",
    state_id: "MI",
    lat: 42.9772,
    lng: -85.0727,
    id: 1840003075,
  },
  {
    name: "Pineville",
    state_id: "LA",
    lat: 31.3414,
    lng: -92.4096,
    id: 1840014963,
  },
  {
    name: "Iona",
    state_id: "FL",
    lat: 26.516,
    lng: -81.9601,
    id: 1840028702,
  },
  {
    name: "Cleveland",
    state_id: "MS",
    lat: 33.744,
    lng: -90.7285,
    id: 1840014748,
  },
  {
    name: "Greensburg",
    state_id: "PA",
    lat: 40.3113,
    lng: -79.5444,
    id: 1840001137,
  },
  {
    name: "Middleburg",
    state_id: "FL",
    lat: 30.0502,
    lng: -81.9011,
    id: 1840013998,
  },
  {
    name: "Willowick",
    state_id: "OH",
    lat: 41.6343,
    lng: -81.468,
    id: 1840000549,
  },
  {
    name: "Harrison",
    state_id: "AR",
    lat: 36.2438,
    lng: -93.1198,
    id: 1840013327,
  },
  {
    name: "Chesterton",
    state_id: "IN",
    lat: 41.5997,
    lng: -87.0548,
    id: 1840009267,
  },
  {
    name: "Steamboat Springs",
    state_id: "CO",
    lat: 40.4777,
    lng: -106.8243,
    id: 1840021374,
  },
  {
    name: "Black Forest",
    state_id: "CO",
    lat: 39.0608,
    lng: -104.6752,
    id: 1840017562,
  },
  {
    name: "Fort Payne",
    state_id: "AL",
    lat: 34.4557,
    lng: -85.6965,
    id: 1840004822,
  },
  {
    name: "Cherry Hill Mall",
    state_id: "NJ",
    lat: 39.9384,
    lng: -75.0117,
    id: 1840033469,
  },
  {
    name: "Fruitville",
    state_id: "FL",
    lat: 27.3328,
    lng: -82.4616,
    id: 1840014179,
  },
  {
    name: "Lakewood Park",
    state_id: "FL",
    lat: 27.539,
    lng: -80.3865,
    id: 1840014177,
  },
  {
    name: "Callaway",
    state_id: "FL",
    lat: 30.1348,
    lng: -85.5568,
    id: 1840013958,
  },
  {
    name: "Oatfield",
    state_id: "OR",
    lat: 45.4127,
    lng: -122.5942,
    id: 1840034837,
  },
  {
    name: "Waunakee",
    state_id: "WI",
    lat: 43.1829,
    lng: -89.4445,
    id: 1840002904,
  },
  {
    name: "Wixom",
    state_id: "MI",
    lat: 42.5244,
    lng: -83.5346,
    id: 1840000407,
  },
  {
    name: "Luling",
    state_id: "LA",
    lat: 29.9008,
    lng: -90.3523,
    id: 1840013980,
  },
  {
    name: "Canton",
    state_id: "MS",
    lat: 32.5975,
    lng: -90.0317,
    id: 1840014872,
  },
  {
    name: "Pell City",
    state_id: "AL",
    lat: 33.5609,
    lng: -86.2669,
    id: 1840014771,
  },
  {
    name: "Waimalu",
    state_id: "HI",
    lat: 21.3913,
    lng: -157.9345,
    id: 1840029588,
  },
  {
    name: "Pecos",
    state_id: "TX",
    lat: 31.4046,
    lng: -103.5057,
    id: 1840020806,
  },
  {
    name: "Bay St. Louis",
    state_id: "MS",
    lat: 30.3281,
    lng: -89.3774,
    id: 1840013951,
  },
  {
    name: "Belle Chasse",
    state_id: "LA",
    lat: 29.8472,
    lng: -90.0069,
    id: 1840014028,
  },
  {
    name: "Havre de Grace",
    state_id: "MD",
    lat: 39.5481,
    lng: -76.1145,
    id: 1840005667,
  },
  {
    name: "Monroe",
    state_id: "OH",
    lat: 39.4461,
    lng: -84.3666,
    id: 1840008465,
  },
  {
    name: "D'Iberville",
    state_id: "MS",
    lat: 30.4709,
    lng: -88.9011,
    id: 1840015023,
  },
  {
    name: "Cayce",
    state_id: "SC",
    lat: 33.9458,
    lng: -81.0433,
    id: 1840014734,
  },
  {
    name: "Effingham",
    state_id: "IL",
    lat: 39.1207,
    lng: -88.5508,
    id: 1840008545,
  },
  {
    name: "Santaquin",
    state_id: "UT",
    lat: 39.9711,
    lng: -111.7937,
    id: 1840021396,
  },
  {
    name: "Hobe Sound",
    state_id: "FL",
    lat: 27.0729,
    lng: -80.1425,
    id: 1840014188,
  },
  {
    name: "Wauchula",
    state_id: "FL",
    lat: 27.5469,
    lng: -81.8101,
    id: 1840015987,
  },
  {
    name: "Colesville",
    state_id: "MD",
    lat: 39.073,
    lng: -77.0009,
    id: 1840005831,
  },
  {
    name: "Dayton",
    state_id: "TX",
    lat: 30.0292,
    lng: -94.9038,
    id: 1840019599,
  },
  {
    name: "Reidsville",
    state_id: "NC",
    lat: 36.3377,
    lng: -79.6727,
    id: 1840014436,
  },
  {
    name: "Hutchinson",
    state_id: "MN",
    lat: 44.8856,
    lng: -94.3768,
    id: 1840006756,
  },
  {
    name: "Dallas",
    state_id: "GA",
    lat: 33.9153,
    lng: -84.8416,
    id: 1840014757,
  },
  {
    name: "Franklin Park",
    state_id: "NJ",
    lat: 40.4439,
    lng: -74.5432,
    id: 1840039889,
  },
  {
    name: "Beacon",
    state_id: "NY",
    lat: 41.5036,
    lng: -73.9655,
    id: 1840000499,
  },
  {
    name: "Dumas",
    state_id: "TX",
    lat: 35.8613,
    lng: -101.9642,
    id: 1840019086,
  },
  {
    name: "Raymondville",
    state_id: "TX",
    lat: 26.4759,
    lng: -97.7769,
    id: 1840021035,
  },
  {
    name: "Frederick",
    state_id: "CO",
    lat: 40.1089,
    lng: -104.9694,
    id: 1840021364,
  },
  {
    name: "Olympia Heights",
    state_id: "FL",
    lat: 25.7241,
    lng: -80.339,
    id: 1840014247,
  },
  {
    name: "Artesia",
    state_id: "NM",
    lat: 32.8492,
    lng: -104.4274,
    id: 1840019443,
  },
  {
    name: "North Mankato",
    state_id: "MN",
    lat: 44.1813,
    lng: -94.0387,
    id: 1840007896,
  },
  {
    name: "Glenpool",
    state_id: "OK",
    lat: 35.9485,
    lng: -96.0052,
    id: 1840020384,
  },
  {
    name: "Oil City",
    state_id: "PA",
    lat: 41.4282,
    lng: -79.7035,
    id: 1840003426,
  },
  {
    name: "Hammonton",
    state_id: "NJ",
    lat: 39.6572,
    lng: -74.7678,
    id: 1840003800,
  },
  {
    name: "New Kingman-Butler",
    state_id: "AZ",
    lat: 35.2645,
    lng: -114.0091,
    id: 1840037118,
  },
  {
    name: "Chicago Ridge",
    state_id: "IL",
    lat: 41.7034,
    lng: -87.7795,
    id: 1840010146,
  },
  {
    name: "Deming",
    state_id: "NM",
    lat: 32.2631,
    lng: -107.7525,
    id: 1840019458,
  },
  {
    name: "Big Rapids",
    state_id: "MI",
    lat: 43.6992,
    lng: -85.4806,
    id: 1840002678,
  },
  {
    name: "Miami Springs",
    state_id: "FL",
    lat: 25.8195,
    lng: -80.2894,
    id: 1840015152,
  },
  {
    name: "Fulshear",
    state_id: "TX",
    lat: 29.693,
    lng: -95.8792,
    id: 1840020946,
  },
  {
    name: "South Venice",
    state_id: "FL",
    lat: 27.0434,
    lng: -82.4152,
    id: 1840014180,
  },
  {
    name: "Allouez",
    state_id: "WI",
    lat: 44.4721,
    lng: -88.0261,
    id: 1840002346,
  },
  {
    name: "Princeton",
    state_id: "TX",
    lat: 33.1778,
    lng: -96.5044,
    id: 1840020663,
  },
  {
    name: "Baraboo",
    state_id: "WI",
    lat: 43.4695,
    lng: -89.7376,
    id: 1840002720,
  },
  {
    name: "Jericho",
    state_id: "NY",
    lat: 40.7875,
    lng: -73.5416,
    id: 1840005268,
  },
  {
    name: "Collingswood",
    state_id: "NJ",
    lat: 39.916,
    lng: -75.0759,
    id: 1840016611,
  },
  {
    name: "El Sobrante",
    state_id: "CA",
    lat: 33.8724,
    lng: -117.4625,
    id: 1840075804,
  },
  {
    name: "Cahokia",
    state_id: "IL",
    lat: 38.5649,
    lng: -90.1792,
    id: 1840010781,
  },
  {
    name: "Springdale",
    state_id: "NJ",
    lat: 39.8769,
    lng: -74.9723,
    id: 1840133731,
  },
  {
    name: "Oak Island",
    state_id: "NC",
    lat: 33.9434,
    lng: -78.1366,
    id: 1840016688,
  },
  {
    name: "Gramercy",
    state_id: "LA",
    lat: 30.061,
    lng: -90.6928,
    id: 1840017209,
  },
  {
    name: "Atlantic Beach",
    state_id: "FL",
    lat: 30.3371,
    lng: -81.4128,
    id: 1840013956,
  },
  {
    name: "Teays Valley",
    state_id: "WV",
    lat: 38.4482,
    lng: -81.924,
    id: 1840038312,
  },
  {
    name: "Ephrata",
    state_id: "PA",
    lat: 40.1812,
    lng: -76.1811,
    id: 1840003717,
  },
  {
    name: "Oak Park",
    state_id: "CA",
    lat: 34.1849,
    lng: -118.7669,
    id: 1840019198,
  },
  {
    name: "Richmond Hill",
    state_id: "GA",
    lat: 31.9013,
    lng: -81.3125,
    id: 1840014913,
  },
  {
    name: "Brandermill",
    state_id: "VA",
    lat: 37.434,
    lng: -77.6522,
    id: 1840024784,
  },
  {
    name: "Forrest City",
    state_id: "AR",
    lat: 35.0135,
    lng: -90.7931,
    id: 1840013522,
  },
  {
    name: "Sun Village",
    state_id: "CA",
    lat: 34.5596,
    lng: -117.9559,
    id: 1840076298,
  },
  {
    name: "Moorestown-Lenola",
    state_id: "NJ",
    lat: 39.9659,
    lng: -74.9643,
    id: 1840035113,
  },
  {
    name: "Fergus Falls",
    state_id: "MN",
    lat: 46.2853,
    lng: -96.076,
    id: 1840007747,
  },
  {
    name: "Whitefish Bay",
    state_id: "WI",
    lat: 43.1132,
    lng: -87.9004,
    id: 1840003036,
  },
  {
    name: "Bexley",
    state_id: "OH",
    lat: 39.965,
    lng: -82.9343,
    id: 1840003759,
  },
  {
    name: "Conneaut",
    state_id: "OH",
    lat: 41.9278,
    lng: -80.5685,
    id: 1840003337,
  },
  {
    name: "Thomaston",
    state_id: "GA",
    lat: 32.8908,
    lng: -84.3271,
    id: 1840015776,
  },
  {
    name: "Levelland",
    state_id: "TX",
    lat: 33.5806,
    lng: -102.3636,
    id: 1840020606,
  },
  {
    name: "Palmetto",
    state_id: "FL",
    lat: 27.5251,
    lng: -82.575,
    id: 1840015116,
  },
  {
    name: "Harper Woods",
    state_id: "MI",
    lat: 42.439,
    lng: -82.9292,
    id: 1840003981,
  },
  {
    name: "Piney Green",
    state_id: "NC",
    lat: 34.7498,
    lng: -77.3208,
    id: 1840013558,
  },
  {
    name: "Fort Campbell North",
    state_id: "KY",
    lat: 36.6554,
    lng: -87.4658,
    id: 1840073832,
  },
  {
    name: "Princeton Meadows",
    state_id: "NJ",
    lat: 40.3332,
    lng: -74.5628,
    id: 1840033474,
  },
  {
    name: "Highland Park",
    state_id: "NJ",
    lat: 40.5006,
    lng: -74.4283,
    id: 1840003636,
  },
  {
    name: "Hickory Hills",
    state_id: "IL",
    lat: 41.7248,
    lng: -87.8281,
    id: 1840007030,
  },
  {
    name: "Forest Park",
    state_id: "IL",
    lat: 41.8683,
    lng: -87.8157,
    id: 1840011276,
  },
  {
    name: "Beach Park",
    state_id: "IL",
    lat: 42.4261,
    lng: -87.8584,
    id: 1840011149,
  },
  {
    name: "East Milton",
    state_id: "FL",
    lat: 30.6175,
    lng: -86.9636,
    id: 1840025112,
  },
  {
    name: "Sharonville",
    state_id: "OH",
    lat: 39.2825,
    lng: -84.4071,
    id: 1840001613,
  },
  {
    name: "West Freehold",
    state_id: "NJ",
    lat: 40.2324,
    lng: -74.2943,
    id: 1840005454,
  },
  {
    name: "Middlesex",
    state_id: "NJ",
    lat: 40.5744,
    lng: -74.5011,
    id: 1840001337,
  },
  {
    name: "Spring Creek",
    state_id: "NV",
    lat: 40.7386,
    lng: -115.5971,
    id: 1840018704,
  },
  {
    name: "St. James",
    state_id: "NY",
    lat: 40.8761,
    lng: -73.1521,
    id: 1840005103,
  },
  {
    name: "Maumee",
    state_id: "OH",
    lat: 41.5696,
    lng: -83.6636,
    id: 1840000569,
  },
  {
    name: "Lawrenceburg",
    state_id: "KY",
    lat: 38.0332,
    lng: -84.9032,
    id: 1840014321,
  },
  {
    name: "Brevard",
    state_id: "NC",
    lat: 35.2439,
    lng: -82.7264,
    id: 1840013467,
  },
  {
    name: "Soddy-Daisy",
    state_id: "TN",
    lat: 35.2571,
    lng: -85.1739,
    id: 1840035990,
  },
  {
    name: "Moberly",
    state_id: "MO",
    lat: 39.419,
    lng: -92.4365,
    id: 1840008463,
  },
  {
    name: "Port Clinton",
    state_id: "OH",
    lat: 41.5096,
    lng: -82.9385,
    id: 1840009257,
  },
  {
    name: "Canton",
    state_id: "IL",
    lat: 40.5632,
    lng: -90.0409,
    id: 1840007212,
  },
  {
    name: "Waianae",
    state_id: "HI",
    lat: 21.4569,
    lng: -158.1759,
    id: 1840029552,
  },
  {
    name: "Vadnais Heights",
    state_id: "MN",
    lat: 45.057,
    lng: -93.0748,
    id: 1840010004,
  },
  {
    name: "Wood Dale",
    state_id: "IL",
    lat: 41.9666,
    lng: -87.9807,
    id: 1840010167,
  },
  {
    name: "Brecksville",
    state_id: "OH",
    lat: 41.3079,
    lng: -81.6193,
    id: 1840000590,
  },
  {
    name: "New Providence",
    state_id: "NJ",
    lat: 40.6996,
    lng: -74.4034,
    id: 1840001095,
  },
  {
    name: "Roselle Park",
    state_id: "NJ",
    lat: 40.6653,
    lng: -74.2666,
    id: 1840001093,
  },
  {
    name: "Shiloh",
    state_id: "IL",
    lat: 38.5534,
    lng: -89.916,
    id: 1840012877,
  },
  {
    name: "Rantoul",
    state_id: "IL",
    lat: 40.3031,
    lng: -88.1549,
    id: 1840012227,
  },
  {
    name: "Colonial Park",
    state_id: "PA",
    lat: 40.2997,
    lng: -76.8068,
    id: 1840005398,
  },
  {
    name: "Broadlands",
    state_id: "VA",
    lat: 39.0168,
    lng: -77.5167,
    id: 1840024498,
  },
  {
    name: "Washington",
    state_id: "IN",
    lat: 38.6587,
    lng: -87.1591,
    id: 1840010735,
  },
  {
    name: "Hazel Crest",
    state_id: "IL",
    lat: 41.5732,
    lng: -87.6899,
    id: 1840011260,
  },
  {
    name: "Price",
    state_id: "UT",
    lat: 39.604,
    lng: -110.8004,
    id: 1840020201,
  },
  {
    name: "LaBelle",
    state_id: "FL",
    lat: 26.7219,
    lng: -81.4506,
    id: 1840015129,
  },
  {
    name: "Alpena",
    state_id: "MI",
    lat: 45.074,
    lng: -83.4399,
    id: 1840003947,
  },
  {
    name: "Conway",
    state_id: "FL",
    lat: 28.4968,
    lng: -81.3316,
    id: 1840013121,
  },
  {
    name: "Kettering",
    state_id: "MD",
    lat: 38.8888,
    lng: -76.789,
    id: 1840005958,
  },
  {
    name: "Rocky Point",
    state_id: "NY",
    lat: 40.9357,
    lng: -72.9364,
    id: 1840005070,
  },
  {
    name: "Elizabethton",
    state_id: "TN",
    lat: 36.3367,
    lng: -82.2369,
    id: 1840013314,
  },
  {
    name: "Wauconda",
    state_id: "IL",
    lat: 42.2748,
    lng: -88.1359,
    id: 1840011184,
  },
  {
    name: "Lake Forest Park",
    state_id: "WA",
    lat: 47.7574,
    lng: -122.2865,
    id: 1840019837,
  },
  {
    name: "Avenal",
    state_id: "CA",
    lat: 36.0311,
    lng: -120.1162,
    id: 1840019054,
  },
  {
    name: "Rogers",
    state_id: "MN",
    lat: 45.1865,
    lng: -93.5783,
    id: 1840008932,
  },
  {
    name: "Marshall",
    state_id: "MN",
    lat: 44.4488,
    lng: -95.7897,
    id: 1840007879,
  },
  {
    name: "Jensen Beach",
    state_id: "FL",
    lat: 27.2438,
    lng: -80.2423,
    id: 1840014190,
  },
  {
    name: "Fruita",
    state_id: "CO",
    lat: 39.1549,
    lng: -108.7307,
    id: 1840020229,
  },
  {
    name: "Homosassa Springs",
    state_id: "FL",
    lat: 28.8118,
    lng: -82.5392,
    id: 1840014060,
  },
  {
    name: "Middlesborough",
    state_id: "KY",
    lat: 36.6127,
    lng: -83.7227,
    id: 1840036079,
  },
  {
    name: "Red Oak",
    state_id: "TX",
    lat: 32.5207,
    lng: -96.7864,
    id: 1840020761,
  },
  {
    name: "Olivehurst",
    state_id: "CA",
    lat: 39.0817,
    lng: -121.5549,
    id: 1840018801,
  },
  {
    name: "Big Bear City",
    state_id: "CA",
    lat: 34.2536,
    lng: -116.7903,
    id: 1840017797,
  },
  {
    name: "Grover Beach",
    state_id: "CA",
    lat: 35.1204,
    lng: -120.6199,
    id: 1840020414,
  },
  {
    name: "Marion",
    state_id: "NC",
    lat: 35.6774,
    lng: -82.0013,
    id: 1840015372,
  },
  {
    name: "Fortuna",
    state_id: "CA",
    lat: 40.5862,
    lng: -124.1419,
    id: 1840009553,
  },
  {
    name: "Santa Fe",
    state_id: "TX",
    lat: 29.3892,
    lng: -95.1005,
    id: 1840022233,
  },
  {
    name: "North Valley",
    state_id: "NM",
    lat: 35.1736,
    lng: -106.6231,
    id: 1840033721,
  },
  {
    name: "Lindale",
    state_id: "TX",
    lat: 32.4934,
    lng: -95.4069,
    id: 1840022078,
  },
  {
    name: "Englewood",
    state_id: "OH",
    lat: 39.8644,
    lng: -84.3071,
    id: 1840008435,
  },
  {
    name: "Lakeland South",
    state_id: "WA",
    lat: 47.2784,
    lng: -122.283,
    id: 1840074270,
  },
  {
    name: "Washington",
    state_id: "PA",
    lat: 40.174,
    lng: -80.2466,
    id: 1840003668,
  },
  {
    name: "Jasper",
    state_id: "AL",
    lat: 33.8503,
    lng: -87.2708,
    id: 1840003778,
  },
  {
    name: "Short Hills",
    state_id: "NJ",
    lat: 40.7389,
    lng: -74.3278,
    id: 1840033408,
  },
  {
    name: "Huron",
    state_id: "SD",
    lat: 44.3622,
    lng: -98.2102,
    id: 1840002373,
  },
  {
    name: "Sault Ste. Marie",
    state_id: "MI",
    lat: 46.4817,
    lng: -84.3723,
    id: 1840003938,
  },
  {
    name: "Andover",
    state_id: "KS",
    lat: 37.6869,
    lng: -97.1354,
    id: 1840007546,
  },
  {
    name: "Brookside",
    state_id: "DE",
    lat: 39.6666,
    lng: -75.7153,
    id: 1840005569,
  },
  {
    name: "South Park Township",
    state_id: "PA",
    lat: 40.2989,
    lng: -79.9944,
    id: 1840073713,
  },
  {
    name: "Mountain Park",
    state_id: "GA",
    lat: 33.8458,
    lng: -84.1313,
    id: 1840143063,
  },
  {
    name: "Carpinteria",
    state_id: "CA",
    lat: 34.3962,
    lng: -119.5118,
    id: 1840019187,
  },
  {
    name: "Glenn Heights",
    state_id: "TX",
    lat: 32.5506,
    lng: -96.8548,
    id: 1840020708,
  },
  {
    name: "Powell",
    state_id: "OH",
    lat: 40.1684,
    lng: -83.0826,
    id: 1840009430,
  },
  {
    name: "Lyndhurst",
    state_id: "OH",
    lat: 41.5172,
    lng: -81.4922,
    id: 1840000610,
  },
  {
    name: "Artondale",
    state_id: "WA",
    lat: 47.3024,
    lng: -122.6391,
    id: 1840037617,
  },
  {
    name: "Edgewater",
    state_id: "NJ",
    lat: 40.8237,
    lng: -73.974,
    id: 1840003543,
  },
  {
    name: "Glenn Dale",
    state_id: "MD",
    lat: 38.9833,
    lng: -76.804,
    id: 1840005955,
  },
  {
    name: "Western Springs",
    state_id: "IL",
    lat: 41.8022,
    lng: -87.9006,
    id: 1840011333,
  },
  {
    name: "Okmulgee",
    state_id: "OK",
    lat: 35.6136,
    lng: -96.0069,
    id: 1840020400,
  },
  {
    name: "DeRidder",
    state_id: "LA",
    lat: 30.8467,
    lng: -93.2931,
    id: 1840015013,
  },
  {
    name: "Azle",
    state_id: "TX",
    lat: 32.8955,
    lng: -97.5379,
    id: 1840019423,
  },
  {
    name: "Vidalia",
    state_id: "GA",
    lat: 32.2125,
    lng: -82.4019,
    id: 1840015820,
  },
  {
    name: "Swansea",
    state_id: "IL",
    lat: 38.5507,
    lng: -89.9859,
    id: 1840012880,
  },
  {
    name: "Moss Point",
    state_id: "MS",
    lat: 30.424,
    lng: -88.5289,
    id: 1840015016,
  },
  {
    name: "Fort Morgan",
    state_id: "CO",
    lat: 40.2538,
    lng: -103.7909,
    id: 1840020175,
  },
  {
    name: "Dardenne Prairie",
    state_id: "MO",
    lat: 38.7566,
    lng: -90.732,
    id: 1840007428,
  },
  {
    name: "River Ridge",
    state_id: "LA",
    lat: 29.9593,
    lng: -90.2202,
    id: 1840013995,
  },
  {
    name: "Huntington",
    state_id: "VA",
    lat: 38.7916,
    lng: -77.074,
    id: 1840006030,
  },
  {
    name: "Newark",
    state_id: "NY",
    lat: 43.0418,
    lng: -77.093,
    id: 1840004292,
  },
  {
    name: "Mounds View",
    state_id: "MN",
    lat: 45.1071,
    lng: -93.2076,
    id: 1840007840,
  },
  {
    name: "Jefferson",
    state_id: "GA",
    lat: 34.1373,
    lng: -83.6021,
    id: 1840014723,
  },
  {
    name: "Rochester",
    state_id: "MI",
    lat: 42.6866,
    lng: -83.1198,
    id: 1840002428,
  },
  {
    name: "Solana Beach",
    state_id: "CA",
    lat: 32.9942,
    lng: -117.2575,
    id: 1840021993,
  },
  {
    name: "Nesconset",
    state_id: "NY",
    lat: 40.8467,
    lng: -73.1522,
    id: 1840005051,
  },
  {
    name: "Richton Park",
    state_id: "IL",
    lat: 41.4816,
    lng: -87.7387,
    id: 1840011283,
  },
  {
    name: "Palos Verdes Estates",
    state_id: "CA",
    lat: 33.7871,
    lng: -118.3976,
    id: 1840020503,
  },
  {
    name: "Woodinville",
    state_id: "WA",
    lat: 47.7569,
    lng: -122.1478,
    id: 1840021121,
  },
  {
    name: "Manorville",
    state_id: "NY",
    lat: 40.8575,
    lng: -72.7915,
    id: 1840005088,
  },
  {
    name: "Bellefontaine",
    state_id: "OH",
    lat: 40.3627,
    lng: -83.763,
    id: 1840007233,
  },
  {
    name: "Timberlake",
    state_id: "VA",
    lat: 37.3167,
    lng: -79.2482,
    id: 1840006437,
  },
  {
    name: "Channahon",
    state_id: "IL",
    lat: 41.4213,
    lng: -88.2593,
    id: 1840010214,
  },
  {
    name: "Port Royal",
    state_id: "SC",
    lat: 32.3557,
    lng: -80.7029,
    id: 1840016975,
  },
  {
    name: "Verona",
    state_id: "WI",
    lat: 42.9893,
    lng: -89.5383,
    id: 1840002903,
  },
  {
    name: "Bensville",
    state_id: "MD",
    lat: 38.6176,
    lng: -77.0077,
    id: 1840073595,
  },
  {
    name: "Clayton",
    state_id: "OH",
    lat: 39.8689,
    lng: -84.3292,
    id: 1840010584,
  },
  {
    name: "East Islip",
    state_id: "NY",
    lat: 40.7275,
    lng: -73.1861,
    id: 1840005036,
  },
  {
    name: "New Ulm",
    state_id: "MN",
    lat: 44.3121,
    lng: -94.4686,
    id: 1840007894,
  },
  {
    name: "Newington",
    state_id: "VA",
    lat: 38.7358,
    lng: -77.1993,
    id: 1840006021,
  },
  {
    name: "Baker",
    state_id: "LA",
    lat: 30.5833,
    lng: -91.1581,
    id: 1840013939,
  },
  {
    name: "Cedar Lake",
    state_id: "IN",
    lat: 41.3697,
    lng: -87.4383,
    id: 1840009259,
  },
  {
    name: "Globe",
    state_id: "AZ",
    lat: 33.3869,
    lng: -110.7515,
    id: 1840020533,
  },
  {
    name: "La Grange Park",
    state_id: "IL",
    lat: 41.8308,
    lng: -87.8723,
    id: 1840011256,
  },
  {
    name: "Newton",
    state_id: "NC",
    lat: 35.663,
    lng: -81.2333,
    id: 1840014523,
  },
  {
    name: "Warrenville",
    state_id: "IL",
    lat: 41.821,
    lng: -88.1857,
    id: 1840010164,
  },
  {
    name: "Hockessin",
    state_id: "DE",
    lat: 39.7837,
    lng: -75.6815,
    id: 1840005573,
  },
  {
    name: "Rendon",
    state_id: "TX",
    lat: 32.5789,
    lng: -97.235,
    id: 1840019421,
  },
  {
    name: "Sandpoint",
    state_id: "ID",
    lat: 48.2822,
    lng: -116.5613,
    id: 1840021064,
  },
  {
    name: "Loveland",
    state_id: "OH",
    lat: 39.2679,
    lng: -84.2731,
    id: 1840003809,
  },
  {
    name: "Shorewood",
    state_id: "WI",
    lat: 43.0914,
    lng: -87.8864,
    id: 1840003031,
  },
  {
    name: "Trenton",
    state_id: "OH",
    lat: 39.4792,
    lng: -84.462,
    id: 1840003805,
  },
  {
    name: "Coolidge",
    state_id: "AZ",
    lat: 32.9395,
    lng: -111.5261,
    id: 1840019367,
  },
  {
    name: "North Palm Beach",
    state_id: "FL",
    lat: 26.8216,
    lng: -80.0576,
    id: 1840018307,
  },
  {
    name: "Stoughton",
    state_id: "WI",
    lat: 42.9234,
    lng: -89.2215,
    id: 1840002901,
  },
  {
    name: "Mount Vernon",
    state_id: "VA",
    lat: 38.714,
    lng: -77.1043,
    id: 1840006020,
  },
  {
    name: "Warrensville Heights",
    state_id: "OH",
    lat: 41.4363,
    lng: -81.5222,
    id: 1840003419,
  },
  {
    name: "Worthington",
    state_id: "MN",
    lat: 43.6281,
    lng: -95.599,
    id: 1840010036,
  },
  {
    name: "Plover",
    state_id: "WI",
    lat: 44.4614,
    lng: -89.5383,
    id: 1840002328,
  },
  {
    name: "Martinsville",
    state_id: "IN",
    lat: 39.4228,
    lng: -86.4208,
    id: 1840008461,
  },
  {
    name: "Mahomet",
    state_id: "IL",
    lat: 40.1885,
    lng: -88.3904,
    id: 1840012232,
  },
  {
    name: "Superior",
    state_id: "CO",
    lat: 39.9341,
    lng: -105.1588,
    id: 1840022425,
  },
  {
    name: "Lone Tree",
    state_id: "CO",
    lat: 39.5309,
    lng: -104.871,
    id: 1840020222,
  },
  {
    name: "Montgomeryville",
    state_id: "PA",
    lat: 40.2502,
    lng: -75.2405,
    id: 1840005457,
  },
  {
    name: "South Daytona",
    state_id: "FL",
    lat: 29.1656,
    lng: -81.0056,
    id: 1840015950,
  },
  {
    name: "Riverdale",
    state_id: "IL",
    lat: 41.6441,
    lng: -87.6365,
    id: 1840011286,
  },
  {
    name: "Lumberton",
    state_id: "TX",
    lat: 30.2562,
    lng: -94.2071,
    id: 1840020896,
  },
  {
    name: "Villas",
    state_id: "FL",
    lat: 26.5504,
    lng: -81.8679,
    id: 1840029092,
  },
  {
    name: "Coatesville",
    state_id: "PA",
    lat: 39.9849,
    lng: -75.8199,
    id: 1840001424,
  },
  {
    name: "Moody",
    state_id: "AL",
    lat: 33.5986,
    lng: -86.4963,
    id: 1840014770,
  },
  {
    name: "McPherson",
    state_id: "KS",
    lat: 38.3714,
    lng: -97.6605,
    id: 1840001659,
  },
  {
    name: "Fort Lewis",
    state_id: "WA",
    lat: 47.0955,
    lng: -122.5672,
    id: 1840073933,
  },
  {
    name: "Davidson",
    state_id: "NC",
    lat: 35.4846,
    lng: -80.8252,
    id: 1840016353,
  },
  {
    name: "Edgewood",
    state_id: "WA",
    lat: 47.2309,
    lng: -122.2832,
    id: 1840019852,
  },
  {
    name: "Suamico",
    state_id: "WI",
    lat: 44.6352,
    lng: -88.0664,
    id: 1840000309,
  },
  {
    name: "San Marino",
    state_id: "CA",
    lat: 34.1224,
    lng: -118.1132,
    id: 1840021863,
  },
  {
    name: "Portland",
    state_id: "TN",
    lat: 36.5883,
    lng: -86.5194,
    id: 1840014418,
  },
  {
    name: "Fort Drum",
    state_id: "NY",
    lat: 44.0451,
    lng: -75.7847,
    id: 1840073579,
  },
  {
    name: "Chatham",
    state_id: "IL",
    lat: 39.6733,
    lng: -89.6938,
    id: 1840010560,
  },
  {
    name: "Lakeland Village",
    state_id: "CA",
    lat: 33.648,
    lng: -117.3706,
    id: 1840017989,
  },
  {
    name: "Bellefonte",
    state_id: "PA",
    lat: 40.9141,
    lng: -77.7683,
    id: 1840000822,
  },
  {
    name: "Forest Hill",
    state_id: "TX",
    lat: 32.6619,
    lng: -97.2662,
    id: 1840020695,
  },
  {
    name: "Vail",
    state_id: "AZ",
    lat: 32.0217,
    lng: -110.6937,
    id: 1840019487,
  },
  {
    name: "Cypress Lake",
    state_id: "FL",
    lat: 26.5391,
    lng: -81.9,
    id: 1840029032,
  },
  {
    name: "Gladeview",
    state_id: "FL",
    lat: 25.8395,
    lng: -80.2368,
    id: 1840029038,
  },
  {
    name: "Monfort Heights",
    state_id: "OH",
    lat: 39.1822,
    lng: -84.6075,
    id: 1840034325,
  },
  {
    name: "Braselton",
    state_id: "GA",
    lat: 34.1088,
    lng: -83.8128,
    id: 1840015596,
  },
  {
    name: "Lapeer",
    state_id: "MI",
    lat: 43.0447,
    lng: -83.3254,
    id: 1840002896,
  },
  {
    name: "Sugarland Run",
    state_id: "VA",
    lat: 39.0309,
    lng: -77.3762,
    id: 1840024510,
  },
  {
    name: "Dunmore",
    state_id: "PA",
    lat: 41.4152,
    lng: -75.6072,
    id: 1840003381,
  },
  {
    name: "Georgetown",
    state_id: "GA",
    lat: 31.9849,
    lng: -81.226,
    id: 1840134450,
  },
  {
    name: "Kittanning",
    state_id: "PA",
    lat: 40.8279,
    lng: -79.5233,
    id: 1840003519,
  },
  {
    name: "Vinings",
    state_id: "GA",
    lat: 33.8608,
    lng: -84.4686,
    id: 1840013687,
  },
  {
    name: "Sharon",
    state_id: "PA",
    lat: 41.234,
    lng: -80.4997,
    id: 1840003441,
  },
  {
    name: "Claremont",
    state_id: "NH",
    lat: 43.379,
    lng: -72.3368,
    id: 1840002805,
  },
  {
    name: "New Carrollton",
    state_id: "MD",
    lat: 38.9654,
    lng: -76.8773,
    id: 1840005978,
  },
  {
    name: "Oakland",
    state_id: "NJ",
    lat: 41.0313,
    lng: -74.2408,
    id: 1840000912,
  },
  {
    name: "Verde Village",
    state_id: "AZ",
    lat: 34.7122,
    lng: -111.9943,
    id: 1840017843,
  },
  {
    name: "Ridgefield Park",
    state_id: "NJ",
    lat: 40.8543,
    lng: -74.0201,
    id: 1840000898,
  },
  {
    name: "Irondale",
    state_id: "AL",
    lat: 33.544,
    lng: -86.6599,
    id: 1840014797,
  },
  {
    name: "Lake Wylie",
    state_id: "SC",
    lat: 35.0997,
    lng: -81.0677,
    id: 1840013518,
  },
  {
    name: "Sturgis",
    state_id: "MI",
    lat: 41.7995,
    lng: -85.4182,
    id: 1840003254,
  },
  {
    name: "Loganville",
    state_id: "GA",
    lat: 33.8353,
    lng: -83.8957,
    id: 1840015656,
  },
  {
    name: "Lawrenceburg",
    state_id: "IN",
    lat: 39.0986,
    lng: -84.8713,
    id: 1840008523,
  },
  {
    name: "Lakeland North",
    state_id: "WA",
    lat: 47.3374,
    lng: -122.2812,
    id: 1840074254,
  },
  {
    name: "West Carrollton",
    state_id: "OH",
    lat: 39.6701,
    lng: -84.2542,
    id: 1840059955,
  },
  {
    name: "Finneytown",
    state_id: "OH",
    lat: 39.2159,
    lng: -84.5145,
    id: 1840034109,
  },
  {
    name: "Ridge",
    state_id: "NY",
    lat: 40.9068,
    lng: -72.8816,
    id: 1840005068,
  },
  {
    name: "Glen Carbon",
    state_id: "IL",
    lat: 38.758,
    lng: -89.983,
    id: 1840012795,
  },
  {
    name: "Key Biscayne",
    state_id: "FL",
    lat: 25.6908,
    lng: -80.1653,
    id: 1840018333,
  },
  {
    name: "Marshall",
    state_id: "MO",
    lat: 39.1147,
    lng: -93.201,
    id: 1840008509,
  },
  {
    name: "Lake Tapps",
    state_id: "WA",
    lat: 47.2307,
    lng: -122.1694,
    id: 1840041971,
  },
  {
    name: "Spearfish",
    state_id: "SD",
    lat: 44.4912,
    lng: -103.8167,
    id: 1840002374,
  },
  {
    name: "Federal Heights",
    state_id: "CO",
    lat: 39.8651,
    lng: -105.0154,
    id: 1840028425,
  },
  {
    name: "Cornelius",
    state_id: "OR",
    lat: 45.519,
    lng: -123.0514,
    id: 1840018557,
  },
  {
    name: "Cedartown",
    state_id: "GA",
    lat: 34.0223,
    lng: -85.2479,
    id: 1840014752,
  },
  {
    name: "Cloquet",
    state_id: "MN",
    lat: 46.7221,
    lng: -92.4923,
    id: 1840006646,
  },
  {
    name: "Weigelstown",
    state_id: "PA",
    lat: 39.9843,
    lng: -76.8315,
    id: 1840005509,
  },
  {
    name: "Kingston",
    state_id: "PA",
    lat: 41.2652,
    lng: -75.8875,
    id: 1840000756,
  },
  {
    name: "Lilburn",
    state_id: "GA",
    lat: 33.8887,
    lng: -84.1379,
    id: 1840015613,
  },
  {
    name: "South Sioux City",
    state_id: "NE",
    lat: 42.4627,
    lng: -96.4126,
    id: 1840009129,
  },
  {
    name: "Maryville",
    state_id: "MO",
    lat: 40.3428,
    lng: -94.8701,
    id: 1840008349,
  },
  {
    name: "Ardmore",
    state_id: "PA",
    lat: 40.0033,
    lng: -75.2947,
    id: 1840005455,
  },
  {
    name: "Franklin",
    state_id: "LA",
    lat: 29.7851,
    lng: -91.5098,
    id: 1840014019,
  },
  {
    name: "University Heights",
    state_id: "OH",
    lat: 41.4948,
    lng: -81.5348,
    id: 1840003416,
  },
  {
    name: "College",
    state_id: "AK",
    lat: 64.8694,
    lng: -147.8217,
    id: 1840023462,
  },
  {
    name: "Lahaina",
    state_id: "HI",
    lat: 20.8848,
    lng: -156.6618,
    id: 1840023234,
  },
  {
    name: "Glendale",
    state_id: "WI",
    lat: 43.1288,
    lng: -87.9277,
    id: 1840003037,
  },
  {
    name: "Terryville",
    state_id: "NY",
    lat: 40.909,
    lng: -73.0492,
    id: 1840005105,
  },
  {
    name: "Othello",
    state_id: "WA",
    lat: 46.8221,
    lng: -119.1652,
    id: 1840019862,
  },
  {
    name: "Gulf Shores",
    state_id: "AL",
    lat: 30.2759,
    lng: -87.7013,
    id: 1840013886,
  },
  {
    name: "Wilton Manors",
    state_id: "FL",
    lat: 26.1593,
    lng: -80.1395,
    id: 1840016004,
  },
  {
    name: "Yulee",
    state_id: "FL",
    lat: 30.635,
    lng: -81.5678,
    id: 1840013928,
  },
  {
    name: "Athens",
    state_id: "TX",
    lat: 32.2041,
    lng: -95.8321,
    id: 1840019500,
  },
  {
    name: "Jupiter Farms",
    state_id: "FL",
    lat: 26.9224,
    lng: -80.2187,
    id: 1840042432,
  },
  {
    name: "Tarboro",
    state_id: "NC",
    lat: 35.9046,
    lng: -77.5563,
    id: 1840016177,
  },
  {
    name: "Wickliffe",
    state_id: "OH",
    lat: 41.6072,
    lng: -81.469,
    id: 1840000546,
  },
  {
    name: "Elgin",
    state_id: "TX",
    lat: 30.352,
    lng: -97.3879,
    id: 1840020903,
  },
  {
    name: "Mercerville",
    state_id: "NJ",
    lat: 40.236,
    lng: -74.6916,
    id: 1840033344,
  },
  {
    name: "La Homa",
    state_id: "TX",
    lat: 26.2773,
    lng: -98.3579,
    id: 1840037093,
  },
  {
    name: "Dover",
    state_id: "OH",
    lat: 40.5302,
    lng: -81.4805,
    id: 1840007217,
  },
  {
    name: "Greenville",
    state_id: "OH",
    lat: 40.1043,
    lng: -84.6209,
    id: 1840008366,
  },
  {
    name: "Borger",
    state_id: "TX",
    lat: 35.6598,
    lng: -101.4012,
    id: 1840019083,
  },
  {
    name: "Bonita",
    state_id: "CA",
    lat: 32.6652,
    lng: -117.0295,
    id: 1840018015,
  },
  {
    name: "Charlotte",
    state_id: "MI",
    lat: 42.5662,
    lng: -84.8304,
    id: 1840003107,
  },
  {
    name: "Broussard",
    state_id: "LA",
    lat: 30.1393,
    lng: -91.9538,
    id: 1840013964,
  },
  {
    name: "Dorado",
    state_id: "PR",
    lat: 18.4657,
    lng: -66.2724,
    id: 1630023736,
  },
  {
    name: "Herrin",
    state_id: "IL",
    lat: 37.7983,
    lng: -89.0305,
    id: 1840007576,
  },
  {
    name: "Manatí",
    state_id: "PR",
    lat: 18.4283,
    lng: -66.4823,
    id: 1630023562,
  },
  {
    name: "Choctaw",
    state_id: "OK",
    lat: 35.48,
    lng: -97.2666,
    id: 1840019152,
  },
  {
    name: "Manvel",
    state_id: "TX",
    lat: 29.4793,
    lng: -95.3659,
    id: 1840020977,
  },
  {
    name: "Los Alamos",
    state_id: "NM",
    lat: 35.8926,
    lng: -106.2862,
    id: 1840017779,
  },
  {
    name: "Oregon",
    state_id: "WI",
    lat: 42.9253,
    lng: -89.3892,
    id: 1840002922,
  },
  {
    name: "Commerce",
    state_id: "CA",
    lat: 33.9963,
    lng: -118.1519,
    id: 1840019239,
  },
  {
    name: "Taylorville",
    state_id: "IL",
    lat: 39.5328,
    lng: -89.2804,
    id: 1840009547,
  },
  {
    name: "Riviera Beach",
    state_id: "MD",
    lat: 39.1628,
    lng: -76.5263,
    id: 1840005916,
  },
  {
    name: "Port Neches",
    state_id: "TX",
    lat: 29.9765,
    lng: -93.946,
    id: 1840020920,
  },
  {
    name: "Prineville",
    state_id: "OR",
    lat: 44.2985,
    lng: -120.8607,
    id: 1840019999,
  },
  {
    name: "Lakeland",
    state_id: "TN",
    lat: 35.2585,
    lng: -89.7308,
    id: 1840014594,
  },
  {
    name: "Peru",
    state_id: "IN",
    lat: 40.7594,
    lng: -86.0757,
    id: 1840009363,
  },
  {
    name: "White House",
    state_id: "TN",
    lat: 36.4648,
    lng: -86.6665,
    id: 1840015274,
  },
  {
    name: "Clarkston",
    state_id: "GA",
    lat: 33.8117,
    lng: -84.2404,
    id: 1840014776,
  },
  {
    name: "Keokuk",
    state_id: "IA",
    lat: 40.4095,
    lng: -91.403,
    id: 1840001036,
  },
  {
    name: "St. Ann",
    state_id: "MO",
    lat: 38.7266,
    lng: -90.3872,
    id: 1840009771,
  },
  {
    name: "Mastic Beach",
    state_id: "NY",
    lat: 40.7679,
    lng: -72.8375,
    id: 1840005089,
  },
  {
    name: "Justice",
    state_id: "IL",
    lat: 41.7495,
    lng: -87.8345,
    id: 1840011253,
  },
  {
    name: "Elfers",
    state_id: "FL",
    lat: 28.214,
    lng: -82.723,
    id: 1840013122,
  },
  {
    name: "Fulton",
    state_id: "MO",
    lat: 38.8551,
    lng: -91.9511,
    id: 1840008560,
  },
  {
    name: "Minneola",
    state_id: "FL",
    lat: 28.6067,
    lng: -81.7322,
    id: 1840015081,
  },
  {
    name: "Oskaloosa",
    state_id: "IA",
    lat: 41.2922,
    lng: -92.6403,
    id: 1840000650,
  },
  {
    name: "Urbana",
    state_id: "MD",
    lat: 39.3237,
    lng: -77.3411,
    id: 1840024455,
  },
  {
    name: "Grand Terrace",
    state_id: "CA",
    lat: 34.0312,
    lng: -117.3132,
    id: 1840020403,
  },
  {
    name: "Panama City Beach",
    state_id: "FL",
    lat: 30.2369,
    lng: -85.8775,
    id: 1840015035,
  },
  {
    name: "Woodland Park",
    state_id: "NJ",
    lat: 40.8905,
    lng: -74.1945,
    id: 1840056421,
  },
  {
    name: "Lantana",
    state_id: "FL",
    lat: 26.5834,
    lng: -80.0564,
    id: 1840017259,
  },
  {
    name: "Richmond",
    state_id: "TX",
    lat: 29.5825,
    lng: -95.7602,
    id: 1840020947,
  },
  {
    name: "Jackson",
    state_id: "WY",
    lat: 43.4721,
    lng: -110.7745,
    id: 1840021258,
  },
  {
    name: "Fredericksburg",
    state_id: "TX",
    lat: 30.2661,
    lng: -98.8749,
    id: 1840020898,
  },
  {
    name: "Muscoy",
    state_id: "CA",
    lat: 34.155,
    lng: -117.3477,
    id: 1840028097,
  },
  {
    name: "Belmont",
    state_id: "NC",
    lat: 35.2211,
    lng: -81.0402,
    id: 1840013469,
  },
  {
    name: "Annapolis Neck",
    state_id: "MD",
    lat: 38.9408,
    lng: -76.4997,
    id: 1840039445,
  },
  {
    name: "Jacksonville",
    state_id: "AL",
    lat: 33.8088,
    lng: -85.7545,
    id: 1840007433,
  },
  {
    name: "Endicott",
    state_id: "NY",
    lat: 42.098,
    lng: -76.0639,
    id: 1840004664,
  },
  {
    name: "Cheney",
    state_id: "WA",
    lat: 47.4901,
    lng: -117.579,
    id: 1840018398,
  },
  {
    name: "Palos Heights",
    state_id: "IL",
    lat: 41.6637,
    lng: -87.7959,
    id: 1840009181,
  },
  {
    name: "Boone",
    state_id: "IA",
    lat: 42.053,
    lng: -93.877,
    id: 1840007012,
  },
  {
    name: "North St. Paul",
    state_id: "MN",
    lat: 45.0137,
    lng: -92.9995,
    id: 1840007843,
  },
  {
    name: "Ocean Pines",
    state_id: "MD",
    lat: 38.3825,
    lng: -75.1475,
    id: 1840006252,
  },
  {
    name: "Cherry Creek",
    state_id: "CO",
    lat: 39.6094,
    lng: -104.8645,
    id: 1840028588,
  },
  {
    name: "Río Grande",
    state_id: "PR",
    lat: 18.3789,
    lng: -65.8388,
    id: 1630023701,
  },
  {
    name: "Paris",
    state_id: "KY",
    lat: 38.2016,
    lng: -84.2719,
    id: 1840014315,
  },
  {
    name: "Irmo",
    state_id: "SC",
    lat: 34.1018,
    lng: -81.1957,
    id: 1840016723,
  },
  {
    name: "San Anselmo",
    state_id: "CA",
    lat: 37.9821,
    lng: -122.5699,
    id: 1840022520,
  },
  {
    name: "Marysville",
    state_id: "CA",
    lat: 39.1518,
    lng: -121.5835,
    id: 1840020219,
  },
  {
    name: "Woodhaven",
    state_id: "MI",
    lat: 42.132,
    lng: -83.2374,
    id: 1840001848,
  },
  {
    name: "Bedford",
    state_id: "OH",
    lat: 41.3919,
    lng: -81.5359,
    id: 1840003396,
  },
  {
    name: "Trophy Club",
    state_id: "TX",
    lat: 33.0038,
    lng: -97.1897,
    id: 1840022993,
  },
  {
    name: "Riverton",
    state_id: "WY",
    lat: 43.0421,
    lng: -108.414,
    id: 1840020026,
  },
  {
    name: "Shepherdsville",
    state_id: "KY",
    lat: 37.9806,
    lng: -85.6999,
    id: 1840015214,
  },
  {
    name: "Plymouth",
    state_id: "IN",
    lat: 41.3483,
    lng: -86.3187,
    id: 1840014170,
  },
  {
    name: "Manchester",
    state_id: "TN",
    lat: 35.463,
    lng: -86.0774,
    id: 1840015409,
  },
  {
    name: "Waconia",
    state_id: "MN",
    lat: 44.8412,
    lng: -93.7927,
    id: 1840010008,
  },
  {
    name: "Stony Brook",
    state_id: "NY",
    lat: 40.906,
    lng: -73.1278,
    id: 1840005104,
  },
  {
    name: "St. Peter",
    state_id: "MN",
    lat: 44.3296,
    lng: -93.9659,
    id: 1840008972,
  },
  {
    name: "Kelso",
    state_id: "WA",
    lat: 46.1236,
    lng: -122.891,
    id: 1840018503,
  },
  {
    name: "Elkhorn",
    state_id: "WI",
    lat: 42.6711,
    lng: -88.5377,
    id: 1840002480,
  },
  {
    name: "Gatesville",
    state_id: "TX",
    lat: 31.4419,
    lng: -97.7351,
    id: 1840020825,
  },
  {
    name: "Goldenrod",
    state_id: "FL",
    lat: 28.6114,
    lng: -81.2916,
    id: 1840014080,
  },
  {
    name: "Virginia",
    state_id: "MN",
    lat: 47.5169,
    lng: -92.5128,
    id: 1840009954,
  },
  {
    name: "Chino Valley",
    state_id: "AZ",
    lat: 34.7593,
    lng: -112.4107,
    id: 1840022753,
  },
  {
    name: "Lakeside",
    state_id: "VA",
    lat: 37.6133,
    lng: -77.4768,
    id: 1840006392,
  },
  {
    name: "Newington Forest",
    state_id: "VA",
    lat: 38.7371,
    lng: -77.2339,
    id: 1840041848,
  },
  {
    name: "Blue Ash",
    state_id: "OH",
    lat: 39.248,
    lng: -84.3827,
    id: 1840003812,
  },
  {
    name: "Lewisburg",
    state_id: "TN",
    lat: 35.4494,
    lng: -86.7897,
    id: 1840014544,
  },
  {
    name: "Wilmington",
    state_id: "OH",
    lat: 39.4387,
    lng: -83.8184,
    id: 1840010630,
  },
  {
    name: "Harrison",
    state_id: "WI",
    lat: 44.1935,
    lng: -88.2941,
    id: 1840038103,
  },
  {
    name: "Holly Hill",
    state_id: "FL",
    lat: 29.2443,
    lng: -81.0463,
    id: 1840015078,
  },
  {
    name: "Lansdowne",
    state_id: "VA",
    lat: 39.0846,
    lng: -77.4839,
    id: 1840041773,
  },
  {
    name: "Minnehaha",
    state_id: "WA",
    lat: 45.6577,
    lng: -122.6204,
    id: 1840018518,
  },
  {
    name: "New Baltimore",
    state_id: "MI",
    lat: 42.6903,
    lng: -82.7398,
    id: 1840003090,
  },
  {
    name: "Gulfport",
    state_id: "FL",
    lat: 27.7463,
    lng: -82.71,
    id: 1840014145,
  },
  {
    name: "Forestville",
    state_id: "MD",
    lat: 38.8518,
    lng: -76.8708,
    id: 1840005942,
  },
  {
    name: "Derby",
    state_id: "CT",
    lat: 41.3265,
    lng: -73.0833,
    id: 1840004847,
  },
  {
    name: "Kewanee",
    state_id: "IL",
    lat: 41.2399,
    lng: -89.9263,
    id: 1840008206,
  },
  {
    name: "Orange City",
    state_id: "FL",
    lat: 28.9347,
    lng: -81.2881,
    id: 1840015075,
  },
  {
    name: "Lakeland Highlands",
    state_id: "FL",
    lat: 27.9572,
    lng: -81.9496,
    id: 1840029047,
  },
  {
    name: "Bastrop",
    state_id: "LA",
    lat: 32.7749,
    lng: -91.9058,
    id: 1840013793,
  },
  {
    name: "Gladstone",
    state_id: "OR",
    lat: 45.3864,
    lng: -122.5933,
    id: 1840019949,
  },
  {
    name: "Patchogue",
    state_id: "NY",
    lat: 40.7621,
    lng: -73.0185,
    id: 1840005129,
  },
  {
    name: "Winnetka",
    state_id: "IL",
    lat: 42.1064,
    lng: -87.7421,
    id: 1840011317,
  },
  {
    name: "Markham",
    state_id: "IL",
    lat: 41.6,
    lng: -87.6905,
    id: 1840008130,
  },
  {
    name: "Marion",
    state_id: "AR",
    lat: 35.2041,
    lng: -90.2061,
    id: 1840015445,
  },
  {
    name: "Oak Hills",
    state_id: "OR",
    lat: 45.5403,
    lng: -122.8413,
    id: 1840034830,
  },
  {
    name: "Leon Valley",
    state_id: "TX",
    lat: 29.4954,
    lng: -98.6143,
    id: 1840020959,
  },
  {
    name: "Joppatowne",
    state_id: "MD",
    lat: 39.4181,
    lng: -76.3516,
    id: 1840005665,
  },
  {
    name: "West Plains",
    state_id: "MO",
    lat: 36.7378,
    lng: -91.868,
    id: 1840010887,
  },
  {
    name: "Delhi",
    state_id: "CA",
    lat: 37.4306,
    lng: -120.7759,
    id: 1840018930,
  },
  {
    name: "Winchester",
    state_id: "TN",
    lat: 35.1898,
    lng: -86.1074,
    id: 1840015466,
  },
  {
    name: "Newcastle",
    state_id: "WA",
    lat: 47.5303,
    lng: -122.1633,
    id: 1840019831,
  },
  {
    name: "New Kensington",
    state_id: "PA",
    lat: 40.5711,
    lng: -79.7521,
    id: 1840001151,
  },
  {
    name: "Beatrice",
    state_id: "NE",
    lat: 40.2737,
    lng: -96.7454,
    id: 1840001352,
  },
  {
    name: "Poquoson",
    state_id: "VA",
    lat: 37.1318,
    lng: -76.3569,
    id: 1840003863,
  },
  {
    name: "Clayton",
    state_id: "CA",
    lat: 37.9403,
    lng: -121.9301,
    id: 1840018901,
  },
  {
    name: "St. Marys",
    state_id: "PA",
    lat: 41.4574,
    lng: -78.5342,
    id: 1840000618,
  },
  {
    name: "Ottawa",
    state_id: "KS",
    lat: 38.6,
    lng: -95.2642,
    id: 1840003840,
  },
  {
    name: "Larkspur",
    state_id: "CA",
    lat: 37.9393,
    lng: -122.5313,
    id: 1840020261,
  },
  {
    name: "Cottonwood",
    state_id: "AZ",
    lat: 34.7192,
    lng: -112.0014,
    id: 1840019168,
  },
  {
    name: "Mays Chapel",
    state_id: "MD",
    lat: 39.4343,
    lng: -76.6516,
    id: 1840031492,
  },
  {
    name: "Shady Hills",
    state_id: "FL",
    lat: 28.4042,
    lng: -82.5467,
    id: 1840029004,
  },
  {
    name: "Lincolnwood",
    state_id: "IL",
    lat: 42.0054,
    lng: -87.7329,
    id: 1840011259,
  },
  {
    name: "Arkansas City",
    state_id: "KS",
    lat: 37.0726,
    lng: -97.0385,
    id: 1840001700,
  },
  {
    name: "Elon",
    state_id: "NC",
    lat: 36.1014,
    lng: -79.5085,
    id: 1840016143,
  },
  {
    name: "Palmer",
    state_id: "MA",
    lat: 42.1888,
    lng: -72.3112,
    id: 1840132584,
  },
  {
    name: "West Monroe",
    state_id: "LA",
    lat: 32.512,
    lng: -92.1513,
    id: 1840015795,
  },
  {
    name: "Parker",
    state_id: "SC",
    lat: 34.8514,
    lng: -82.4512,
    id: 1840035848,
  },
  {
    name: "Batesville",
    state_id: "AR",
    lat: 35.7687,
    lng: -91.6226,
    id: 1840013398,
  },
  {
    name: "Amherst",
    state_id: "OH",
    lat: 41.4022,
    lng: -82.2303,
    id: 1840000640,
  },
  {
    name: "Grenada",
    state_id: "MS",
    lat: 33.7816,
    lng: -89.813,
    id: 1840013717,
  },
  {
    name: "Ishpeming",
    state_id: "MI",
    lat: 46.4862,
    lng: -87.6658,
    id: 1840003922,
  },
  {
    name: "Jesup",
    state_id: "GA",
    lat: 31.5992,
    lng: -81.8895,
    id: 1840014943,
  },
  {
    name: "Ringwood",
    state_id: "NJ",
    lat: 41.1064,
    lng: -74.2749,
    id: 1840000835,
  },
  {
    name: "Overlea",
    state_id: "MD",
    lat: 39.3642,
    lng: -76.5176,
    id: 1840005683,
  },
  {
    name: "Speedway",
    state_id: "IN",
    lat: 39.7937,
    lng: -86.2479,
    id: 1840010574,
  },
  {
    name: "Enumclaw",
    state_id: "WA",
    lat: 47.2017,
    lng: -121.9897,
    id: 1840019825,
  },
  {
    name: "Neosho",
    state_id: "MO",
    lat: 36.8441,
    lng: -94.3756,
    id: 1840008793,
  },
  {
    name: "Wells Branch",
    state_id: "TX",
    lat: 30.4432,
    lng: -97.6792,
    id: 1840019588,
  },
  {
    name: "Lincoln City",
    state_id: "OR",
    lat: 44.9751,
    lng: -124.0073,
    id: 1840019974,
  },
  {
    name: "North Merrick",
    state_id: "NY",
    lat: 40.6871,
    lng: -73.5615,
    id: 1840005242,
  },
  {
    name: "Northlake",
    state_id: "IL",
    lat: 41.9143,
    lng: -87.9054,
    id: 1840008131,
  },
  {
    name: "Eatontown",
    state_id: "NJ",
    lat: 40.2913,
    lng: -74.0558,
    id: 1840003679,
  },
  {
    name: "Newport",
    state_id: "OR",
    lat: 44.6242,
    lng: -124.0513,
    id: 1840019975,
  },
  {
    name: "Home Gardens",
    state_id: "CA",
    lat: 33.8784,
    lng: -117.5116,
    id: 1840017987,
  },
  {
    name: "Freeport",
    state_id: "TX",
    lat: 28.9454,
    lng: -95.3601,
    id: 1840020974,
  },
  {
    name: "Webb City",
    state_id: "MO",
    lat: 37.1413,
    lng: -94.4676,
    id: 1840010878,
  },
  {
    name: "Salisbury",
    state_id: "NY",
    lat: 40.7454,
    lng: -73.5604,
    id: 1840005255,
  },
  {
    name: "Woodward",
    state_id: "OK",
    lat: 36.4247,
    lng: -99.4057,
    id: 1840021636,
  },
  {
    name: "Melissa",
    state_id: "TX",
    lat: 33.2891,
    lng: -96.5573,
    id: 1840020658,
  },
  {
    name: "Brock Hall",
    state_id: "MD",
    lat: 38.8617,
    lng: -76.7549,
    id: 1840024541,
  },
  {
    name: "James Island",
    state_id: "SC",
    lat: 32.7353,
    lng: -79.9396,
    id: 1840043446,
  },
  {
    name: "Kingsburg",
    state_id: "CA",
    lat: 36.5245,
    lng: -119.5602,
    id: 1840020325,
  },
  {
    name: "Platteville",
    state_id: "WI",
    lat: 42.728,
    lng: -90.4676,
    id: 1840002981,
  },
  {
    name: "Eureka",
    state_id: "MO",
    lat: 38.5015,
    lng: -90.6492,
    id: 1840008582,
  },
  {
    name: "Lovington",
    state_id: "NM",
    lat: 32.9125,
    lng: -103.3277,
    id: 1840020617,
  },
  {
    name: "Emeryville",
    state_id: "CA",
    lat: 37.8382,
    lng: -122.2932,
    id: 1840020291,
  },
  {
    name: "Little Chute",
    state_id: "WI",
    lat: 44.2905,
    lng: -88.3206,
    id: 1840002408,
  },
  {
    name: "Bainbridge",
    state_id: "GA",
    lat: 30.9046,
    lng: -84.5727,
    id: 1840013899,
  },
  {
    name: "Sedro-Woolley",
    state_id: "WA",
    lat: 48.5112,
    lng: -122.2321,
    id: 1840021066,
  },
  {
    name: "Minden",
    state_id: "LA",
    lat: 32.6187,
    lng: -93.2762,
    id: 1840014864,
  },
  {
    name: "Grantsville",
    state_id: "UT",
    lat: 40.6148,
    lng: -112.4777,
    id: 1840020146,
  },
  {
    name: "Somerville",
    state_id: "NJ",
    lat: 40.5696,
    lng: -74.6092,
    id: 1840001056,
  },
  {
    name: "Middle Valley",
    state_id: "TN",
    lat: 35.1877,
    lng: -85.1958,
    id: 1840013450,
  },
  {
    name: "Union Park",
    state_id: "FL",
    lat: 28.5645,
    lng: -81.2354,
    id: 1840014090,
  },
  {
    name: "South Charleston",
    state_id: "WV",
    lat: 38.3482,
    lng: -81.711,
    id: 1840006200,
  },
  {
    name: "Fairfax Station",
    state_id: "VA",
    lat: 38.7942,
    lng: -77.3358,
    id: 1840024566,
  },
  {
    name: "Leeds",
    state_id: "AL",
    lat: 33.5429,
    lng: -86.5636,
    id: 1840005106,
  },
  {
    name: "Holiday City-Berkeley",
    state_id: "NJ",
    lat: 39.9639,
    lng: -74.2787,
    id: 1840073689,
  },
  {
    name: "East Bethel",
    state_id: "MN",
    lat: 45.3557,
    lng: -93.2038,
    id: 1840007797,
  },
  {
    name: "Festus",
    state_id: "MO",
    lat: 38.2192,
    lng: -90.4095,
    id: 1840008652,
  },
  {
    name: "Riverview",
    state_id: "MI",
    lat: 42.1728,
    lng: -83.1935,
    id: 1840003961,
  },
  {
    name: "Smithfield",
    state_id: "UT",
    lat: 41.8347,
    lng: -111.8266,
    id: 1840021321,
  },
  {
    name: "Weatherford",
    state_id: "OK",
    lat: 35.5384,
    lng: -98.6872,
    id: 1840021725,
  },
  {
    name: "Miller Place",
    state_id: "NY",
    lat: 40.9374,
    lng: -72.9864,
    id: 1840005046,
  },
  {
    name: "Spring Lake",
    state_id: "NC",
    lat: 35.1842,
    lng: -78.9959,
    id: 1840017875,
  },
  {
    name: "Folkston",
    state_id: "GA",
    lat: 30.8393,
    lng: -82.0073,
    id: 1840013903,
  },
  {
    name: "Macedonia",
    state_id: "OH",
    lat: 41.3147,
    lng: -81.4989,
    id: 1840000797,
  },
  {
    name: "Carnot-Moon",
    state_id: "PA",
    lat: 40.5187,
    lng: -80.2178,
    id: 1840073606,
  },
  {
    name: "Jerome",
    state_id: "ID",
    lat: 42.7183,
    lng: -114.5159,
    id: 1840018680,
  },
  {
    name: "Prairie Ridge",
    state_id: "WA",
    lat: 47.1443,
    lng: -122.1408,
    id: 1840037882,
  },
  {
    name: "Hasbrouck Heights",
    state_id: "NJ",
    lat: 40.8618,
    lng: -74.0741,
    id: 1840003558,
  },
  {
    name: "Union",
    state_id: "MO",
    lat: 38.44,
    lng: -90.9927,
    id: 1840010769,
  },
  {
    name: "Hamilton Square",
    state_id: "NJ",
    lat: 40.2248,
    lng: -74.6526,
    id: 1840024324,
  },
  {
    name: "Moncks Corner",
    state_id: "SC",
    lat: 33.1631,
    lng: -80.0135,
    id: 1840016863,
  },
  {
    name: "Goulds",
    state_id: "FL",
    lat: 25.5614,
    lng: -80.388,
    id: 1840014239,
  },
  {
    name: "Newton",
    state_id: "NJ",
    lat: 41.0534,
    lng: -74.7527,
    id: 1840000786,
  },
  {
    name: "Maltby",
    state_id: "WA",
    lat: 47.8027,
    lng: -122.1044,
    id: 1840017326,
  },
  {
    name: "Garden City",
    state_id: "ID",
    lat: 43.6526,
    lng: -116.2743,
    id: 1840020045,
  },
  {
    name: "Somersworth",
    state_id: "NH",
    lat: 43.2534,
    lng: -70.8856,
    id: 1840002810,
  },
  {
    name: "Norton",
    state_id: "OH",
    lat: 41.0294,
    lng: -81.6461,
    id: 1840000800,
  },
  {
    name: "Red Bank",
    state_id: "NJ",
    lat: 40.3481,
    lng: -74.0672,
    id: 1840001358,
  },
  {
    name: "Ellwood City",
    state_id: "PA",
    lat: 40.8619,
    lng: -80.283,
    id: 1840000937,
  },
  {
    name: "Glenwood Springs",
    state_id: "CO",
    lat: 39.5455,
    lng: -107.3346,
    id: 1840020184,
  },
  {
    name: "Greensburg",
    state_id: "IN",
    lat: 39.3515,
    lng: -85.5024,
    id: 1840014567,
  },
  {
    name: "Tucson Estates",
    state_id: "AZ",
    lat: 32.1792,
    lng: -111.1254,
    id: 1840027929,
  },
  {
    name: "Macclenny",
    state_id: "FL",
    lat: 30.281,
    lng: -82.1252,
    id: 1840015921,
  },
  {
    name: "Echelon",
    state_id: "NJ",
    lat: 39.8482,
    lng: -74.9957,
    id: 1840033470,
  },
  {
    name: "East Grand Rapids",
    state_id: "MI",
    lat: 42.9464,
    lng: -85.6088,
    id: 1840002927,
  },
  {
    name: "Newberry",
    state_id: "SC",
    lat: 34.2812,
    lng: -81.601,
    id: 1840014694,
  },
  {
    name: "Lansing",
    state_id: "KS",
    lat: 39.2428,
    lng: -94.8971,
    id: 1840001590,
  },
  {
    name: "Brookhaven",
    state_id: "MS",
    lat: 31.5803,
    lng: -90.4432,
    id: 1840013862,
  },
  {
    name: "Winfield",
    state_id: "KS",
    lat: 37.274,
    lng: -96.9499,
    id: 1840001701,
  },
  {
    name: "Norwalk",
    state_id: "IA",
    lat: 41.4895,
    lng: -93.6913,
    id: 1840008213,
  },
  {
    name: "Camp Pendleton South",
    state_id: "CA",
    lat: 33.2284,
    lng: -117.3791,
    id: 1840073902,
  },
  {
    name: "Hudson",
    state_id: "FL",
    lat: 28.3594,
    lng: -82.6888,
    id: 1840014115,
  },
  {
    name: "Robinson",
    state_id: "TX",
    lat: 31.4501,
    lng: -97.1201,
    id: 1840020813,
  },
  {
    name: "Newport East",
    state_id: "RI",
    lat: 41.5158,
    lng: -71.2878,
    id: 1840073469,
  },
  {
    name: "Portales",
    state_id: "NM",
    lat: 34.1753,
    lng: -103.3565,
    id: 1840020529,
  },
  {
    name: "Port Washington",
    state_id: "WI",
    lat: 43.3847,
    lng: -87.8852,
    id: 1840002826,
  },
  {
    name: "South Miami",
    state_id: "FL",
    lat: 25.7079,
    lng: -80.2952,
    id: 1840016006,
  },
  {
    name: "Waimea",
    state_id: "HI",
    lat: 20.0124,
    lng: -155.6378,
    id: 1840137122,
  },
  {
    name: "Green River",
    state_id: "WY",
    lat: 41.5124,
    lng: -109.4708,
    id: 1840020100,
  },
  {
    name: "Tremonton",
    state_id: "UT",
    lat: 41.7187,
    lng: -112.1891,
    id: 1840021326,
  },
  {
    name: "Cottage Grove",
    state_id: "OR",
    lat: 43.7961,
    lng: -123.0573,
    id: 1840018624,
  },
  {
    name: "Valley Falls",
    state_id: "RI",
    lat: 41.9234,
    lng: -71.3923,
    id: 1840003295,
  },
  {
    name: "Berkley",
    state_id: "CO",
    lat: 39.8045,
    lng: -105.0281,
    id: 1840028561,
  },
  {
    name: "Robertsville",
    state_id: "NJ",
    lat: 40.3395,
    lng: -74.2939,
    id: 1840026509,
  },
  {
    name: "Delavan",
    state_id: "WI",
    lat: 42.6281,
    lng: -88.6324,
    id: 1840002483,
  },
  {
    name: "Vineyard",
    state_id: "UT",
    lat: 40.3059,
    lng: -111.7545,
    id: 1840022419,
  },
  {
    name: "Richfield",
    state_id: "WI",
    lat: 43.2372,
    lng: -88.2413,
    id: 1840002835,
  },
  {
    name: "Port Lavaca",
    state_id: "TX",
    lat: 28.6181,
    lng: -96.6278,
    id: 1840020996,
  },
  {
    name: "Otsego",
    state_id: "MI",
    lat: 42.4575,
    lng: -85.6979,
    id: 1840003111,
  },
  {
    name: "Malvern",
    state_id: "AR",
    lat: 34.3734,
    lng: -92.8205,
    id: 1840015563,
  },
  {
    name: "Cadillac",
    state_id: "MI",
    lat: 44.2494,
    lng: -85.4163,
    id: 1840002512,
  },
  {
    name: "Lake Station",
    state_id: "IN",
    lat: 41.5729,
    lng: -87.2599,
    id: 1840008195,
  },
  {
    name: "Healdsburg",
    state_id: "CA",
    lat: 38.6229,
    lng: -122.8651,
    id: 1840020242,
  },
  {
    name: "Clawson",
    state_id: "MI",
    lat: 42.5367,
    lng: -83.1504,
    id: 1840002440,
  },
  {
    name: "Sandy",
    state_id: "OR",
    lat: 45.3986,
    lng: -122.2692,
    id: 1840021217,
  },
  {
    name: "East Renton Highlands",
    state_id: "WA",
    lat: 47.4718,
    lng: -122.0854,
    id: 1840037866,
  },
  {
    name: "Red Bank",
    state_id: "TN",
    lat: 35.1117,
    lng: -85.2961,
    id: 1840014571,
  },
  {
    name: "Brown Deer",
    state_id: "WI",
    lat: 43.1743,
    lng: -87.975,
    id: 1840003041,
  },
  {
    name: "Crestwood",
    state_id: "MO",
    lat: 38.5569,
    lng: -90.3782,
    id: 1840007442,
  },
  {
    name: "Grants",
    state_id: "NM",
    lat: 35.1538,
    lng: -107.8335,
    id: 1840020452,
  },
  {
    name: "Malibu",
    state_id: "CA",
    lat: 34.0368,
    lng: -118.7845,
    id: 1840028310,
  },
  {
    name: "Smyrna",
    state_id: "DE",
    lat: 39.2936,
    lng: -75.6083,
    id: 1840005820,
  },
  {
    name: "Bucyrus",
    state_id: "OH",
    lat: 40.8054,
    lng: -82.9719,
    id: 1840007170,
  },
  {
    name: "Valencia West",
    state_id: "AZ",
    lat: 32.1355,
    lng: -111.1123,
    id: 1840074666,
  },
  {
    name: "Viera East",
    state_id: "FL",
    lat: 28.261,
    lng: -80.715,
    id: 1840039118,
  },
  {
    name: "Newman",
    state_id: "CA",
    lat: 37.3157,
    lng: -121.0211,
    id: 1840020288,
  },
  {
    name: "Tomball",
    state_id: "TX",
    lat: 30.0951,
    lng: -95.6194,
    id: 1840022203,
  },
  {
    name: "Naranja",
    state_id: "FL",
    lat: 25.5164,
    lng: -80.4221,
    id: 1840014246,
  },
  {
    name: "Florida City",
    state_id: "FL",
    lat: 25.4418,
    lng: -80.4685,
    id: 1840014250,
  },
  {
    name: "Wanaque",
    state_id: "NJ",
    lat: 41.044,
    lng: -74.29,
    id: 1840000840,
  },
  {
    name: "Scotts Valley",
    state_id: "CA",
    lat: 37.0555,
    lng: -122.0118,
    id: 1840021580,
  },
  {
    name: "View Park-Windsor Hills",
    state_id: "CA",
    lat: 33.9955,
    lng: -118.3484,
    id: 1840074686,
  },
  {
    name: "Robstown",
    state_id: "TX",
    lat: 27.7886,
    lng: -97.6685,
    id: 1840021010,
  },
  {
    name: "Georgetown",
    state_id: "SC",
    lat: 33.3595,
    lng: -79.2958,
    id: 1840013743,
  },
  {
    name: "Lake Los Angeles",
    state_id: "CA",
    lat: 34.6097,
    lng: -117.8339,
    id: 1840017922,
  },
  {
    name: "Arlington",
    state_id: "TN",
    lat: 35.2594,
    lng: -89.668,
    id: 1840015458,
  },
  {
    name: "Somerset",
    state_id: "PA",
    lat: 40.005,
    lng: -79.0778,
    id: 1840003747,
  },
  {
    name: "Excelsior Springs",
    state_id: "MO",
    lat: 39.339,
    lng: -94.24,
    id: 1840008495,
  },
  {
    name: "Diamond Springs",
    state_id: "CA",
    lat: 38.692,
    lng: -120.8391,
    id: 1840018829,
  },
  {
    name: "Kalaoa",
    state_id: "HI",
    lat: 19.7369,
    lng: -156.0122,
    id: 1840029493,
  },
  {
    name: "North Wantagh",
    state_id: "NY",
    lat: 40.6983,
    lng: -73.5086,
    id: 1840005245,
  },
  {
    name: "Cordele",
    state_id: "GA",
    lat: 31.9563,
    lng: -83.7694,
    id: 1840014934,
  },
  {
    name: "Mendota",
    state_id: "CA",
    lat: 36.7555,
    lng: -120.3776,
    id: 1840020321,
  },
  {
    name: "Grafton",
    state_id: "WI",
    lat: 43.3204,
    lng: -87.948,
    id: 1840002823,
  },
  {
    name: "Eufaula",
    state_id: "AL",
    lat: 31.9102,
    lng: -85.1505,
    id: 1840001685,
  },
  {
    name: "Glen Rock",
    state_id: "NJ",
    lat: 40.9601,
    lng: -74.1249,
    id: 1840003555,
  },
  {
    name: "Cocoa Beach",
    state_id: "FL",
    lat: 28.3326,
    lng: -80.6274,
    id: 1840015093,
  },
  {
    name: "Clute",
    state_id: "TX",
    lat: 29.0256,
    lng: -95.3975,
    id: 1840019669,
  },
  {
    name: "Tamalpais-Homestead Valley",
    state_id: "CA",
    lat: 37.8793,
    lng: -122.5382,
    id: 1840074828,
  },
  {
    name: "Port St. John",
    state_id: "FL",
    lat: 28.4771,
    lng: -80.7874,
    id: 1840014079,
  },
  {
    name: "Endwell",
    state_id: "NY",
    lat: 42.1185,
    lng: -76.0219,
    id: 1840004657,
  },
  {
    name: "Plano",
    state_id: "IL",
    lat: 41.6757,
    lng: -88.5294,
    id: 1840009258,
  },
  {
    name: "Madison Heights",
    state_id: "VA",
    lat: 37.4487,
    lng: -79.1057,
    id: 1840006372,
  },
  {
    name: "Bothell East",
    state_id: "WA",
    lat: 47.8064,
    lng: -122.1845,
    id: 1840041892,
  },
  {
    name: "Guthrie",
    state_id: "OK",
    lat: 35.8428,
    lng: -97.4357,
    id: 1840020390,
  },
  {
    name: "Dranesville",
    state_id: "VA",
    lat: 38.9955,
    lng: -77.3693,
    id: 1840024565,
  },
  {
    name: "El Campo",
    state_id: "TX",
    lat: 29.1987,
    lng: -96.2724,
    id: 1840020966,
  },
  {
    name: "Accokeek",
    state_id: "MD",
    lat: 38.6745,
    lng: -77.0023,
    id: 1840005943,
  },
  {
    name: "Evanston",
    state_id: "WY",
    lat: 41.2602,
    lng: -110.9646,
    id: 1840020122,
  },
  {
    name: "Pontiac",
    state_id: "IL",
    lat: 40.8881,
    lng: -88.6414,
    id: 1840009352,
  },
  {
    name: "Franklin",
    state_id: "OH",
    lat: 39.5538,
    lng: -84.295,
    id: 1840008466,
  },
  {
    name: "Gold Canyon",
    state_id: "AZ",
    lat: 33.3715,
    lng: -111.4369,
    id: 1840018032,
  },
  {
    name: "Cedarburg",
    state_id: "WI",
    lat: 43.2991,
    lng: -87.9887,
    id: 1840002825,
  },
  {
    name: "Claiborne",
    state_id: "LA",
    lat: 32.5379,
    lng: -92.1981,
    id: 1840013811,
  },
  {
    name: "Beachwood",
    state_id: "OH",
    lat: 41.4759,
    lng: -81.503,
    id: 1840003395,
  },
  {
    name: "Seven Hills",
    state_id: "OH",
    lat: 41.3805,
    lng: -81.6737,
    id: 1840003411,
  },
  {
    name: "Upper Montclair",
    state_id: "NJ",
    lat: 40.8428,
    lng: -74.2014,
    id: 1840024255,
  },
  {
    name: "Martinsville",
    state_id: "NJ",
    lat: 40.603,
    lng: -74.5751,
    id: 1840024279,
  },
  {
    name: "Elk City",
    state_id: "OK",
    lat: 35.385,
    lng: -99.4331,
    id: 1840020437,
  },
  {
    name: "Scottdale",
    state_id: "GA",
    lat: 33.795,
    lng: -84.2634,
    id: 1840013700,
  },
  {
    name: "Silver City",
    state_id: "NM",
    lat: 32.7784,
    lng: -108.2698,
    id: 1840023009,
  },
  {
    name: "Wakefield",
    state_id: "VA",
    lat: 38.823,
    lng: -77.2407,
    id: 1840143806,
  },
  {
    name: "Alum Rock",
    state_id: "CA",
    lat: 37.3695,
    lng: -121.8241,
    id: 1840017638,
  },
  {
    name: "Mitchellville",
    state_id: "MD",
    lat: 38.9358,
    lng: -76.8146,
    id: 1840005951,
  },
  {
    name: "Spirit Lake",
    state_id: "IA",
    lat: 43.4176,
    lng: -95.1109,
    id: 1840009023,
  },
  {
    name: "Woodland Park",
    state_id: "CO",
    lat: 38.9985,
    lng: -105.0594,
    id: 1840021468,
  },
  {
    name: "Bridgeton",
    state_id: "MO",
    lat: 38.7673,
    lng: -90.4275,
    id: 1840006129,
  },
  {
    name: "Mexico",
    state_id: "MO",
    lat: 39.1625,
    lng: -91.8711,
    id: 1840008515,
  },
  {
    name: "Archdale",
    state_id: "NC",
    lat: 35.9033,
    lng: -79.9592,
    id: 1840013399,
  },
  {
    name: "Westwood Lakes",
    state_id: "FL",
    lat: 25.7237,
    lng: -80.3717,
    id: 1840028796,
  },
  {
    name: "Bogalusa",
    state_id: "LA",
    lat: 30.7812,
    lng: -89.8633,
    id: 1840013905,
  },
  {
    name: "Newport",
    state_id: "TN",
    lat: 35.9617,
    lng: -83.1977,
    id: 1840014487,
  },
  {
    name: "Elwood",
    state_id: "NY",
    lat: 40.8462,
    lng: -73.3389,
    id: 1840005008,
  },
  {
    name: "Yeadon",
    state_id: "PA",
    lat: 39.9325,
    lng: -75.2527,
    id: 1840000715,
  },
  {
    name: "Florham Park",
    state_id: "NJ",
    lat: 40.7773,
    lng: -74.3953,
    id: 1840003582,
  },
  {
    name: "Wallington",
    state_id: "NJ",
    lat: 40.8535,
    lng: -74.1069,
    id: 1840000922,
  },
  {
    name: "Trinity",
    state_id: "FL",
    lat: 28.1809,
    lng: -82.6581,
    id: 1840029012,
  },
  {
    name: "Mack",
    state_id: "OH",
    lat: 39.1503,
    lng: -84.6792,
    id: 1840034079,
  },
  {
    name: "Roma",
    state_id: "TX",
    lat: 26.4166,
    lng: -99.006,
    id: 1840021017,
  },
  {
    name: "Mountain Top",
    state_id: "PA",
    lat: 41.1353,
    lng: -75.9045,
    id: 1840004911,
  },
  {
    name: "Port Salerno",
    state_id: "FL",
    lat: 27.1461,
    lng: -80.1895,
    id: 1840014193,
  },
  {
    name: "Magalia",
    state_id: "CA",
    lat: 39.8228,
    lng: -121.6078,
    id: 1840017530,
  },
  {
    name: "Waipio",
    state_id: "HI",
    lat: 21.4143,
    lng: -157.9965,
    id: 1840029589,
  },
  {
    name: "Magnolia",
    state_id: "AR",
    lat: 33.2774,
    lng: -93.2261,
    id: 1840015726,
  },
  {
    name: "Emmaus",
    state_id: "PA",
    lat: 40.5352,
    lng: -75.4978,
    id: 1840001048,
  },
  {
    name: "Jackson",
    state_id: "OH",
    lat: 39.046,
    lng: -82.6492,
    id: 1840008548,
  },
  {
    name: "Nanakuli",
    state_id: "HI",
    lat: 21.3892,
    lng: -158.1445,
    id: 1840029531,
  },
  {
    name: "Webster",
    state_id: "TX",
    lat: 29.5317,
    lng: -95.1188,
    id: 1840022204,
  },
  {
    name: "Elizabethtown",
    state_id: "PA",
    lat: 40.1534,
    lng: -76.5991,
    id: 1840003716,
  },
  {
    name: "Show Low",
    state_id: "AZ",
    lat: 34.2671,
    lng: -110.0384,
    id: 1840021582,
  },
  {
    name: "Maili",
    state_id: "HI",
    lat: 21.4134,
    lng: -158.1702,
    id: 1840029522,
  },
  {
    name: "River Edge",
    state_id: "NJ",
    lat: 40.9268,
    lng: -74.0387,
    id: 1840000900,
  },
  {
    name: "Woods Cross",
    state_id: "UT",
    lat: 40.8731,
    lng: -111.917,
    id: 1840021355,
  },
  {
    name: "Byram",
    state_id: "MS",
    lat: 32.189,
    lng: -90.2861,
    id: 1840023049,
  },
  {
    name: "North Bellport",
    state_id: "NY",
    lat: 40.7868,
    lng: -72.9457,
    id: 1840005056,
  },
  {
    name: "Fort Bliss",
    state_id: "TX",
    lat: 31.8137,
    lng: -106.4119,
    id: 1840073931,
  },
  {
    name: "Highland",
    state_id: "IL",
    lat: 38.7602,
    lng: -89.681,
    id: 1840007425,
  },
  {
    name: "Lyndon",
    state_id: "KY",
    lat: 38.2644,
    lng: -85.5891,
    id: 1840015191,
  },
  {
    name: "Signal Hill",
    state_id: "CA",
    lat: 33.8029,
    lng: -118.1681,
    id: 1840021868,
  },
  {
    name: "Hailey",
    state_id: "ID",
    lat: 43.5141,
    lng: -114.3,
    id: 1840020028,
  },
  {
    name: "Scottsburg",
    state_id: "IN",
    lat: 38.6851,
    lng: -85.783,
    id: 1840009788,
  },
  {
    name: "Urbana",
    state_id: "OH",
    lat: 40.1085,
    lng: -83.7542,
    id: 1840010488,
  },
  {
    name: "Schiller Park",
    state_id: "IL",
    lat: 41.9586,
    lng: -87.8693,
    id: 1840011320,
  },
  {
    name: "Fenton",
    state_id: "MI",
    lat: 42.7994,
    lng: -83.7144,
    id: 1840002948,
  },
  {
    name: "Bayou Blue",
    state_id: "LA",
    lat: 29.6341,
    lng: -90.6732,
    id: 1840039382,
  },
  {
    name: "Los Alamitos",
    state_id: "CA",
    lat: 33.7972,
    lng: -118.0594,
    id: 1840020579,
  },
  {
    name: "Destrehan",
    state_id: "LA",
    lat: 29.9627,
    lng: -90.3676,
    id: 1840013110,
  },
  {
    name: "Minooka",
    state_id: "IL",
    lat: 41.4507,
    lng: -88.2792,
    id: 1840011618,
  },
  {
    name: "Gardere",
    state_id: "LA",
    lat: 30.3582,
    lng: -91.1345,
    id: 1840013938,
  },
  {
    name: "Cascades",
    state_id: "VA",
    lat: 39.0464,
    lng: -77.3874,
    id: 1840041712,
  },
  {
    name: "Hillsborough",
    state_id: "CA",
    lat: 37.5573,
    lng: -122.3587,
    id: 1840021554,
  },
  {
    name: "Gardnerville Ranchos",
    state_id: "NV",
    lat: 38.8872,
    lng: -119.7426,
    id: 1840033828,
  },
  {
    name: "Collegedale",
    state_id: "TN",
    lat: 35.0525,
    lng: -85.0487,
    id: 1840014570,
  },
  {
    name: "Summerfield",
    state_id: "NC",
    lat: 36.1973,
    lng: -79.8997,
    id: 1840016139,
  },
  {
    name: "Snyder",
    state_id: "TX",
    lat: 32.7133,
    lng: -100.9113,
    id: 1840022059,
  },
  {
    name: "Tarrytown",
    state_id: "NY",
    lat: 41.0647,
    lng: -73.8673,
    id: 1840004945,
  },
  {
    name: "Viera West",
    state_id: "FL",
    lat: 28.243,
    lng: -80.7368,
    id: 1840039133,
  },
  {
    name: "Wyandanch",
    state_id: "NY",
    lat: 40.7467,
    lng: -73.3769,
    id: 1840005115,
  },
  {
    name: "Fairfield",
    state_id: "IA",
    lat: 41.0061,
    lng: -91.9669,
    id: 1840000887,
  },
  {
    name: "Mount Sterling",
    state_id: "KY",
    lat: 38.0648,
    lng: -83.9472,
    id: 1840014319,
  },
  {
    name: "Ravenna",
    state_id: "OH",
    lat: 41.1613,
    lng: -81.2421,
    id: 1840009317,
  },
  {
    name: "Bellmawr",
    state_id: "NJ",
    lat: 39.8665,
    lng: -75.0941,
    id: 1840000731,
  },
  {
    name: "Angola",
    state_id: "IN",
    lat: 41.6433,
    lng: -85.0051,
    id: 1840007084,
  },
  {
    name: "Coshocton",
    state_id: "OH",
    lat: 40.2618,
    lng: -81.848,
    id: 1840003692,
  },
  {
    name: "Mendota Heights",
    state_id: "MN",
    lat: 44.8815,
    lng: -93.14,
    id: 1840007855,
  },
  {
    name: "Haysville",
    state_id: "KS",
    lat: 37.5649,
    lng: -97.3527,
    id: 1840007568,
  },
  {
    name: "Tega Cay",
    state_id: "SC",
    lat: 35.039,
    lng: -81.011,
    id: 1840015488,
  },
  {
    name: "Dent",
    state_id: "OH",
    lat: 39.1922,
    lng: -84.6593,
    id: 1840005880,
  },
  {
    name: "Haddonfield",
    state_id: "NJ",
    lat: 39.8955,
    lng: -75.0346,
    id: 1840016699,
  },
  {
    name: "Beachwood",
    state_id: "NJ",
    lat: 39.9286,
    lng: -74.2023,
    id: 1840001483,
  },
  {
    name: "Bradford",
    state_id: "PA",
    lat: 41.9604,
    lng: -78.6414,
    id: 1840000523,
  },
  {
    name: "North Vernon",
    state_id: "IN",
    lat: 39.017,
    lng: -85.6317,
    id: 1840008549,
  },
  {
    name: "Travilah",
    state_id: "MD",
    lat: 39.0571,
    lng: -77.2458,
    id: 1840005846,
  },
  {
    name: "Kaneohe Station",
    state_id: "HI",
    lat: 21.4451,
    lng: -157.7515,
    id: 1840075025,
  },
  {
    name: "Winton",
    state_id: "CA",
    lat: 37.3854,
    lng: -120.6173,
    id: 1840018933,
  },
  {
    name: "Celina",
    state_id: "OH",
    lat: 40.5551,
    lng: -84.5629,
    id: 1840007210,
  },
  {
    name: "Hartsville",
    state_id: "TN",
    lat: 36.3921,
    lng: -86.1568,
    id: 1840073881,
  },
  {
    name: "Canyon Lake",
    state_id: "CA",
    lat: 33.6885,
    lng: -117.2621,
    id: 1840019301,
  },
  {
    name: "Burkburnett",
    state_id: "TX",
    lat: 34.0746,
    lng: -98.5672,
    id: 1840019278,
  },
  {
    name: "Lighthouse Point",
    state_id: "FL",
    lat: 26.2785,
    lng: -80.0891,
    id: 1840015998,
  },
  {
    name: "Eunice",
    state_id: "LA",
    lat: 30.4904,
    lng: -92.4191,
    id: 1840013927,
  },
  {
    name: "Greentree",
    state_id: "NJ",
    lat: 39.8989,
    lng: -74.9614,
    id: 1840033472,
  },
  {
    name: "Westview",
    state_id: "FL",
    lat: 25.8826,
    lng: -80.2415,
    id: 1840029098,
  },
  {
    name: "DuBois",
    state_id: "PA",
    lat: 41.1225,
    lng: -78.7564,
    id: 1840035398,
  },
  {
    name: "Lamesa",
    state_id: "TX",
    lat: 32.7333,
    lng: -101.9542,
    id: 1840020725,
  },
  {
    name: "North Logan",
    state_id: "UT",
    lat: 41.7759,
    lng: -111.8066,
    id: 1840020114,
  },
  {
    name: "Berlin",
    state_id: "NH",
    lat: 44.4869,
    lng: -71.2599,
    id: 1840002100,
  },
  {
    name: "Waupun",
    state_id: "WI",
    lat: 43.6315,
    lng: -88.738,
    id: 1840002600,
  },
  {
    name: "Big Lake",
    state_id: "MN",
    lat: 45.3416,
    lng: -93.7431,
    id: 1840006701,
  },
  {
    name: "Hillsdale",
    state_id: "MI",
    lat: 41.9266,
    lng: -84.6356,
    id: 1840003252,
  },
  {
    name: "Broomall",
    state_id: "PA",
    lat: 39.9694,
    lng: -75.3529,
    id: 1840005549,
  },
  {
    name: "Gloucester City",
    state_id: "NJ",
    lat: 39.8924,
    lng: -75.1172,
    id: 1840003777,
  },
  {
    name: "Grand Rapids",
    state_id: "MN",
    lat: 47.238,
    lng: -93.5327,
    id: 1840007714,
  },
  {
    name: "Hawaiian Paradise Park",
    state_id: "HI",
    lat: 19.5828,
    lng: -154.9693,
    id: 1840029582,
  },
  {
    name: "Arizona City",
    state_id: "AZ",
    lat: 32.7506,
    lng: -111.6707,
    id: 1840018030,
  },
  {
    name: "Seymour",
    state_id: "TN",
    lat: 35.8761,
    lng: -83.7742,
    id: 1840013386,
  },
  {
    name: "Hormigueros",
    state_id: "PR",
    lat: 18.1437,
    lng: -67.12,
    id: 1630023672,
  },
  {
    name: "Camp Verde",
    state_id: "AZ",
    lat: 34.5699,
    lng: -111.8573,
    id: 1840022752,
  },
  {
    name: "Weddington",
    state_id: "NC",
    lat: 35.0228,
    lng: -80.7383,
    id: 1840016459,
  },
  {
    name: "Ridgefield",
    state_id: "NJ",
    lat: 40.8313,
    lng: -74.0147,
    id: 1840000897,
  },
  {
    name: "Poulsbo",
    state_id: "WA",
    lat: 47.7417,
    lng: -122.6407,
    id: 1840019822,
  },
  {
    name: "Stayton",
    state_id: "OR",
    lat: 44.8033,
    lng: -122.7983,
    id: 1840021227,
  },
  {
    name: "Springdale",
    state_id: "OH",
    lat: 39.2909,
    lng: -84.476,
    id: 1840001615,
  },
  {
    name: "Brookings",
    state_id: "OR",
    lat: 42.0697,
    lng: -124.3003,
    id: 1840018677,
  },
  {
    name: "Damascus",
    state_id: "OR",
    lat: 45.4233,
    lng: -122.4437,
    id: 1840018571,
  },
  {
    name: "Niles",
    state_id: "MI",
    lat: 41.8346,
    lng: -86.2473,
    id: 1840003212,
  },
  {
    name: "Fuller Heights",
    state_id: "FL",
    lat: 27.9226,
    lng: -81.9978,
    id: 1840025156,
  },
  {
    name: "Mango",
    state_id: "FL",
    lat: 27.9915,
    lng: -82.307,
    id: 1840014157,
  },
  {
    name: "Piedmont",
    state_id: "CA",
    lat: 37.8226,
    lng: -122.23,
    id: 1840020297,
  },
  {
    name: "Fitzgerald",
    state_id: "GA",
    lat: 31.7135,
    lng: -83.2515,
    id: 1840013855,
  },
  {
    name: "Huntingdon",
    state_id: "PA",
    lat: 40.5,
    lng: -78.0097,
    id: 1840001068,
  },
  {
    name: "Selma",
    state_id: "TX",
    lat: 29.5866,
    lng: -98.3133,
    id: 1840022221,
  },
  {
    name: "Satellite Beach",
    state_id: "FL",
    lat: 28.1782,
    lng: -80.6019,
    id: 1840015961,
  },
  {
    name: "Rossmoor",
    state_id: "CA",
    lat: 33.7886,
    lng: -118.0803,
    id: 1840019320,
  },
  {
    name: "Mayfield",
    state_id: "KY",
    lat: 36.737,
    lng: -88.6446,
    id: 1840015268,
  },
  {
    name: "Guttenberg",
    state_id: "NJ",
    lat: 40.7927,
    lng: -74.0048,
    id: 1840003597,
  },
  {
    name: "Lanham",
    state_id: "MD",
    lat: 38.9621,
    lng: -76.8421,
    id: 1840026687,
  },
  {
    name: "Franklin Lakes",
    state_id: "NJ",
    lat: 41.0086,
    lng: -74.2083,
    id: 1840003553,
  },
  {
    name: "Gulf Gate Estates",
    state_id: "FL",
    lat: 27.2587,
    lng: -82.5065,
    id: 1840029040,
  },
  {
    name: "Summit",
    state_id: "IL",
    lat: 41.7877,
    lng: -87.8146,
    id: 1840011329,
  },
  {
    name: "Yorktown",
    state_id: "IN",
    lat: 40.183,
    lng: -85.5123,
    id: 1840010463,
  },
  {
    name: "Town and Country",
    state_id: "MO",
    lat: 38.6317,
    lng: -90.479,
    id: 1840010738,
  },
  {
    name: "Fulton",
    state_id: "NY",
    lat: 43.3171,
    lng: -76.4162,
    id: 1840000347,
  },
  {
    name: "Mount Sinai",
    state_id: "NY",
    lat: 40.9372,
    lng: -73.018,
    id: 1840005049,
  },
  {
    name: "Princess Anne",
    state_id: "MD",
    lat: 38.2054,
    lng: -75.6969,
    id: 1840006279,
  },
  {
    name: "Jefferson Hills",
    state_id: "PA",
    lat: 40.2927,
    lng: -79.9329,
    id: 1840001280,
  },
  {
    name: "Lindon",
    state_id: "UT",
    lat: 40.3414,
    lng: -111.7187,
    id: 1840020169,
  },
  {
    name: "Maysville",
    state_id: "KY",
    lat: 38.6454,
    lng: -83.7911,
    id: 1840015180,
  },
  {
    name: "South Yarmouth",
    state_id: "MA",
    lat: 41.6692,
    lng: -70.2005,
    id: 1840004766,
  },
  {
    name: "Campton Hills",
    state_id: "IL",
    lat: 41.9499,
    lng: -88.4166,
    id: 1840000495,
  },
  {
    name: "Woodbury",
    state_id: "NY",
    lat: 41.3284,
    lng: -74.1004,
    id: 1840004883,
  },
  {
    name: "Rifle",
    state_id: "CO",
    lat: 39.5362,
    lng: -107.7729,
    id: 1840020185,
  },
  {
    name: "Bennettsville",
    state_id: "SC",
    lat: 34.6303,
    lng: -79.6874,
    id: 1840013583,
  },
  {
    name: "Walden",
    state_id: "NY",
    lat: 41.5603,
    lng: -74.1879,
    id: 1840004880,
  },
  {
    name: "Grove City",
    state_id: "PA",
    lat: 41.1572,
    lng: -80.0894,
    id: 1840000660,
  },
  {
    name: "Lexington Park",
    state_id: "MD",
    lat: 38.2543,
    lng: -76.4415,
    id: 1840006231,
  },
  {
    name: "Merriam",
    state_id: "KS",
    lat: 39.0186,
    lng: -94.6933,
    id: 1840003830,
  },
  {
    name: "Westwood",
    state_id: "NJ",
    lat: 40.9878,
    lng: -74.0308,
    id: 1840000923,
  },
  {
    name: "Lower Burrell",
    state_id: "PA",
    lat: 40.5818,
    lng: -79.7141,
    id: 1840001132,
  },
  {
    name: "Valley Center",
    state_id: "CA",
    lat: 33.233,
    lng: -117.0157,
    id: 1840019349,
  },
  {
    name: "Silverton",
    state_id: "OR",
    lat: 45.0031,
    lng: -122.7809,
    id: 1840021226,
  },
  {
    name: "Wahpeton",
    state_id: "ND",
    lat: 46.2722,
    lng: -96.6118,
    id: 1840000228,
  },
  {
    name: "Bolivar",
    state_id: "MO",
    lat: 37.6057,
    lng: -93.4174,
    id: 1840007578,
  },
  {
    name: "Storm Lake",
    state_id: "IA",
    lat: 42.6431,
    lng: -95.1969,
    id: 1840000397,
  },
  {
    name: "Whitehall",
    state_id: "MI",
    lat: 43.4003,
    lng: -86.3406,
    id: 1840000366,
  },
  {
    name: "Orange Cove",
    state_id: "CA",
    lat: 36.6211,
    lng: -119.3188,
    id: 1840020322,
  },
  {
    name: "Lake Grove",
    state_id: "NY",
    lat: 40.8586,
    lng: -73.1168,
    id: 1840005121,
  },
  {
    name: "Moss Bluff",
    state_id: "LA",
    lat: 30.3039,
    lng: -93.2051,
    id: 1840013962,
  },
  {
    name: "Grosse Pointe Park",
    state_id: "MI",
    lat: 42.3794,
    lng: -82.9288,
    id: 1840003978,
  },
  {
    name: "Four Corners",
    state_id: "TX",
    lat: 29.6705,
    lng: -95.6596,
    id: 1840018265,
  },
  {
    name: "Grand Haven",
    state_id: "MI",
    lat: 43.0553,
    lng: -86.2201,
    id: 1840002995,
  },
  {
    name: "Two Rivers",
    state_id: "WI",
    lat: 44.1564,
    lng: -87.5824,
    id: 1840002206,
  },
  {
    name: "Lawrenceburg",
    state_id: "TN",
    lat: 35.2497,
    lng: -87.3325,
    id: 1840014568,
  },
  {
    name: "Clinton",
    state_id: "SC",
    lat: 34.4777,
    lng: -81.8636,
    id: 1840014667,
  },
  {
    name: "Dobbs Ferry",
    state_id: "NY",
    lat: 41.0127,
    lng: -73.8698,
    id: 1840004952,
  },
  {
    name: "Coamo",
    state_id: "PR",
    lat: 18.0765,
    lng: -66.3638,
    id: 1630035648,
  },
  {
    name: "Suffern",
    state_id: "NY",
    lat: 41.1138,
    lng: -74.1421,
    id: 1840004987,
  },
  {
    name: "Shiloh",
    state_id: "PA",
    lat: 39.9734,
    lng: -76.792,
    id: 1840035195,
  },
  {
    name: "Munhall",
    state_id: "PA",
    lat: 40.3937,
    lng: -79.9004,
    id: 1840001247,
  },
  {
    name: "Lake Morton-Berrydale",
    state_id: "WA",
    lat: 47.3325,
    lng: -122.1032,
    id: 1840074253,
  },
  {
    name: "Florence",
    state_id: "OR",
    lat: 43.9914,
    lng: -124.1062,
    id: 1840020008,
  },
  {
    name: "Browns Mills",
    state_id: "NJ",
    lat: 39.9737,
    lng: -74.5689,
    id: 1840005517,
  },
  {
    name: "Guymon",
    state_id: "OK",
    lat: 36.6901,
    lng: -101.4776,
    id: 1840020350,
  },
  {
    name: "Pike Creek Valley",
    state_id: "DE",
    lat: 39.7296,
    lng: -75.6993,
    id: 1840028614,
  },
  {
    name: "Pompton Lakes",
    state_id: "NJ",
    lat: 41.0024,
    lng: -74.2859,
    id: 1840000833,
  },
  {
    name: "Millsboro",
    state_id: "DE",
    lat: 38.593,
    lng: -75.3118,
    id: 1840006079,
  },
  {
    name: "Kings Mountain",
    state_id: "NC",
    lat: 35.2348,
    lng: -81.3501,
    id: 1840014552,
  },
  {
    name: "Sussex",
    state_id: "WI",
    lat: 43.1341,
    lng: -88.2232,
    id: 1840003006,
  },
  {
    name: "Pearsall",
    state_id: "TX",
    lat: 28.8885,
    lng: -99.0988,
    id: 1840020992,
  },
  {
    name: "Ocean City",
    state_id: "NJ",
    lat: 39.2682,
    lng: -74.6019,
    id: 1840001598,
  },
  {
    name: "Largo",
    state_id: "MD",
    lat: 38.88,
    lng: -76.8289,
    id: 1840005960,
  },
  {
    name: "West Point",
    state_id: "UT",
    lat: 41.122,
    lng: -112.0994,
    id: 1840021354,
  },
  {
    name: "Gonzales",
    state_id: "LA",
    lat: 30.2132,
    lng: -90.9234,
    id: 1840013965,
  },
  {
    name: "Liberty Lake",
    state_id: "WA",
    lat: 47.6687,
    lng: -117.1032,
    id: 1840037678,
  },
  {
    name: "Spencer",
    state_id: "IA",
    lat: 43.1468,
    lng: -95.1534,
    id: 1840000381,
  },
  {
    name: "Chalco",
    state_id: "NE",
    lat: 41.1817,
    lng: -96.1353,
    id: 1840005176,
  },
  {
    name: "Saddlebrooke",
    state_id: "AZ",
    lat: 32.5385,
    lng: -110.8582,
    id: 1840075484,
  },
  {
    name: "Decatur",
    state_id: "IN",
    lat: 40.8286,
    lng: -84.9282,
    id: 1840007177,
  },
  {
    name: "Heath",
    state_id: "OH",
    lat: 40.0241,
    lng: -82.4412,
    id: 1840001418,
  },
  {
    name: "New Albany",
    state_id: "OH",
    lat: 40.0802,
    lng: -82.7883,
    id: 1840012407,
  },
  {
    name: "Vermillion",
    state_id: "SD",
    lat: 42.7811,
    lng: -96.9255,
    id: 1840003082,
  },
  {
    name: "Manchester",
    state_id: "VA",
    lat: 37.4902,
    lng: -77.5396,
    id: 1840024785,
  },
  {
    name: "Cusseta",
    state_id: "GA",
    lat: 32.347,
    lng: -84.787,
    id: 1840029395,
  },
  {
    name: "Walker Mill",
    state_id: "MD",
    lat: 38.8754,
    lng: -76.8862,
    id: 1840031499,
  },
  {
    name: "Elkins",
    state_id: "WV",
    lat: 38.9237,
    lng: -79.854,
    id: 1840005991,
  },
  {
    name: "Halfway",
    state_id: "MD",
    lat: 39.6163,
    lng: -77.77,
    id: 1840005606,
  },
  {
    name: "Soquel",
    state_id: "CA",
    lat: 36.9978,
    lng: -121.9482,
    id: 1840018957,
  },
  {
    name: "Fort Meade",
    state_id: "MD",
    lat: 39.1058,
    lng: -76.7438,
    id: 1840073581,
  },
  {
    name: "Montgomery",
    state_id: "OH",
    lat: 39.2496,
    lng: -84.3457,
    id: 1840008521,
  },
  {
    name: "Lake Monticello",
    state_id: "VA",
    lat: 37.921,
    lng: -78.3295,
    id: 1840006342,
  },
  {
    name: "Lowes Island",
    state_id: "VA",
    lat: 39.0471,
    lng: -77.3524,
    id: 1840024504,
  },
  {
    name: "Kearney",
    state_id: "MO",
    lat: 39.355,
    lng: -94.3598,
    id: 1840008501,
  },
  {
    name: "Bacliff",
    state_id: "TX",
    lat: 29.5077,
    lng: -94.988,
    id: 1840018273,
  },
  {
    name: "Essex Junction",
    state_id: "VT",
    lat: 44.4902,
    lng: -73.114,
    id: 1840004083,
  },
  {
    name: "La Riviera",
    state_id: "CA",
    lat: 38.5683,
    lng: -121.3544,
    id: 1840028382,
  },
  {
    name: "Pleasant View",
    state_id: "UT",
    lat: 41.3249,
    lng: -112.0011,
    id: 1840020137,
  },
  {
    name: "Wasilla",
    state_id: "AK",
    lat: 61.577,
    lng: -149.466,
    id: 1840023371,
  },
  {
    name: "Forest",
    state_id: "VA",
    lat: 37.3728,
    lng: -79.2831,
    id: 1840006398,
  },
  {
    name: "Oak Grove",
    state_id: "SC",
    lat: 33.9809,
    lng: -81.1438,
    id: 1840135088,
  },
  {
    name: "River Forest",
    state_id: "IL",
    lat: 41.895,
    lng: -87.8194,
    id: 1840011284,
  },
  {
    name: "Cheval",
    state_id: "FL",
    lat: 28.1459,
    lng: -82.5185,
    id: 1840028977,
  },
  {
    name: "The Pinery",
    state_id: "CO",
    lat: 39.4462,
    lng: -104.7591,
    id: 1840028583,
  },
  {
    name: "Panthersville",
    state_id: "GA",
    lat: 33.7059,
    lng: -84.2764,
    id: 1840013705,
  },
  {
    name: "Smithville",
    state_id: "MO",
    lat: 39.3921,
    lng: -94.5748,
    id: 1840009605,
  },
  {
    name: "Mount Kisco",
    state_id: "NY",
    lat: 41.2018,
    lng: -73.7282,
    id: 1840004936,
  },
  {
    name: "Sierra Madre",
    state_id: "CA",
    lat: 34.1687,
    lng: -118.0504,
    id: 1840021867,
  },
  {
    name: "Totowa",
    state_id: "NJ",
    lat: 40.9039,
    lng: -74.2213,
    id: 1840000839,
  },
  {
    name: "San Germán",
    state_id: "PR",
    lat: 18.0827,
    lng: -67.046,
    id: 1630023526,
  },
  {
    name: "Highland Park",
    state_id: "MI",
    lat: 42.4052,
    lng: -83.0977,
    id: 1840003982,
  },
  {
    name: "North Branch",
    state_id: "MN",
    lat: 45.5137,
    lng: -92.9601,
    id: 1840007790,
  },
  {
    name: "Winnemucca",
    state_id: "NV",
    lat: 40.9645,
    lng: -117.7247,
    id: 1840021334,
  },
  {
    name: "Castle Pines",
    state_id: "CO",
    lat: 39.4625,
    lng: -104.8706,
    id: 1840022456,
  },
  {
    name: "Burr Ridge",
    state_id: "IL",
    lat: 41.7485,
    lng: -87.9198,
    id: 1840010168,
  },
  {
    name: "Galena Park",
    state_id: "TX",
    lat: 29.7452,
    lng: -95.2333,
    id: 1840020922,
  },
  {
    name: "Myrtle Grove",
    state_id: "NC",
    lat: 34.123,
    lng: -77.8834,
    id: 1840013632,
  },
  {
    name: "Ludington",
    state_id: "MI",
    lat: 43.9573,
    lng: -86.4434,
    id: 1840002547,
  },
  {
    name: "Camden",
    state_id: "AR",
    lat: 33.5672,
    lng: -92.8467,
    id: 1840013736,
  },
  {
    name: "Bellmead",
    state_id: "TX",
    lat: 31.6025,
    lng: -97.0896,
    id: 1840019531,
  },
  {
    name: "Little Ferry",
    state_id: "NJ",
    lat: 40.8463,
    lng: -74.0388,
    id: 1840003534,
  },
  {
    name: "Aguadilla",
    state_id: "PR",
    lat: 18.4382,
    lng: -67.1536,
    id: 1630035605,
  },
  {
    name: "Muskegon Heights",
    state_id: "MI",
    lat: 43.2024,
    lng: -86.242,
    id: 1840002871,
  },
  {
    name: "Mapleton",
    state_id: "UT",
    lat: 40.1188,
    lng: -111.5742,
    id: 1840020170,
  },
  {
    name: "Fruitland Park",
    state_id: "FL",
    lat: 28.86,
    lng: -81.919,
    id: 1840014055,
  },
  {
    name: "Arkadelphia",
    state_id: "AR",
    lat: 34.1255,
    lng: -93.0725,
    id: 1840013638,
  },
  {
    name: "Rochelle",
    state_id: "IL",
    lat: 41.9195,
    lng: -89.0632,
    id: 1840009179,
  },
  {
    name: "Gig Harbor",
    state_id: "WA",
    lat: 47.3353,
    lng: -122.5964,
    id: 1840019855,
  },
  {
    name: "Crestwood",
    state_id: "IL",
    lat: 41.6454,
    lng: -87.7396,
    id: 1840011267,
  },
  {
    name: "Farmersville",
    state_id: "CA",
    lat: 36.3053,
    lng: -119.2083,
    id: 1840020366,
  },
  {
    name: "Darby",
    state_id: "PA",
    lat: 39.921,
    lng: -75.2611,
    id: 1840000695,
  },
  {
    name: "Smithfield",
    state_id: "VA",
    lat: 36.9754,
    lng: -76.6162,
    id: 1840006487,
  },
  {
    name: "Gateway",
    state_id: "FL",
    lat: 26.5804,
    lng: -81.7453,
    id: 1840014213,
  },
  {
    name: "Raceland",
    state_id: "LA",
    lat: 29.7282,
    lng: -90.6362,
    id: 1840014027,
  },
  {
    name: "Sweet Home",
    state_id: "OR",
    lat: 44.4023,
    lng: -122.7028,
    id: 1840021253,
  },
  {
    name: "DeForest",
    state_id: "WI",
    lat: 43.2301,
    lng: -89.3437,
    id: 1840037935,
  },
  {
    name: "Cleveland",
    state_id: "TX",
    lat: 30.3374,
    lng: -95.0931,
    id: 1840019596,
  },
  {
    name: "Avon Park",
    state_id: "FL",
    lat: 27.5908,
    lng: -81.5081,
    id: 1840014166,
  },
  {
    name: "Northbrook",
    state_id: "OH",
    lat: 39.2467,
    lng: -84.5796,
    id: 1840034123,
  },
  {
    name: "Van Wert",
    state_id: "OH",
    lat: 40.865,
    lng: -84.5879,
    id: 1840010350,
  },
  {
    name: "Garden City",
    state_id: "SC",
    lat: 33.5927,
    lng: -79.007,
    id: 1840013645,
  },
  {
    name: "Hornell",
    state_id: "NY",
    lat: 42.3332,
    lng: -77.6633,
    id: 1840000444,
  },
  {
    name: "Pacific",
    state_id: "MO",
    lat: 38.4805,
    lng: -90.7541,
    id: 1840009644,
  },
  {
    name: "Newcastle",
    state_id: "OK",
    lat: 35.2404,
    lng: -97.5998,
    id: 1840020453,
  },
  {
    name: "Ketchikan",
    state_id: "AK",
    lat: 55.3556,
    lng: -131.6698,
    id: 1840023270,
  },
  {
    name: "Alamosa",
    state_id: "CO",
    lat: 37.4751,
    lng: -105.8769,
    id: 1840018921,
  },
  {
    name: "Lansdowne",
    state_id: "PA",
    lat: 39.9408,
    lng: -75.276,
    id: 1840000700,
  },
  {
    name: "Toccoa",
    state_id: "GA",
    lat: 34.5807,
    lng: -83.3256,
    id: 1840015551,
  },
  {
    name: "Brooklyn",
    state_id: "OH",
    lat: 41.4349,
    lng: -81.7498,
    id: 1840000592,
  },
  {
    name: "Saks",
    state_id: "AL",
    lat: 33.7118,
    lng: -85.8536,
    id: 1840013708,
  },
  {
    name: "Millington",
    state_id: "TN",
    lat: 35.335,
    lng: -89.8991,
    id: 1840014593,
  },
  {
    name: "Georgetown",
    state_id: "DE",
    lat: 38.6894,
    lng: -75.3872,
    id: 1840006065,
  },
  {
    name: "Ogdensburg",
    state_id: "NY",
    lat: 44.7088,
    lng: -75.4717,
    id: 1840000297,
  },
  {
    name: "Wyomissing",
    state_id: "PA",
    lat: 40.3317,
    lng: -75.9703,
    id: 1840001196,
  },
  {
    name: "Petal",
    state_id: "MS",
    lat: 31.3477,
    lng: -89.2359,
    id: 1840014968,
  },
  {
    name: "Anderson",
    state_id: "CA",
    lat: 40.4497,
    lng: -122.295,
    id: 1840018733,
  },
  {
    name: "North Lindenhurst",
    state_id: "NY",
    lat: 40.7072,
    lng: -73.3859,
    id: 1840005058,
  },
  {
    name: "Monroe",
    state_id: "WI",
    lat: 42.603,
    lng: -89.6382,
    id: 1840002464,
  },
  {
    name: "Atchison",
    state_id: "KS",
    lat: 39.5625,
    lng: -95.1367,
    id: 1840001543,
  },
  {
    name: "Highland City",
    state_id: "FL",
    lat: 27.9633,
    lng: -81.8781,
    id: 1840014122,
  },
  {
    name: "Kapaa",
    state_id: "HI",
    lat: 22.091,
    lng: -159.352,
    id: 1840029499,
  },
  {
    name: "Waterloo",
    state_id: "IL",
    lat: 38.3403,
    lng: -90.1538,
    id: 1840010803,
  },
  {
    name: "Wyndham",
    state_id: "VA",
    lat: 37.6924,
    lng: -77.6123,
    id: 1840006396,
  },
  {
    name: "Norton",
    state_id: "VA",
    lat: 36.9314,
    lng: -82.6262,
    id: 1840003870,
  },
  {
    name: "Fairfield",
    state_id: "AL",
    lat: 33.4747,
    lng: -86.9194,
    id: 1840001883,
  },
  {
    name: "Greencastle",
    state_id: "IN",
    lat: 39.6431,
    lng: -86.8419,
    id: 1840008439,
  },
  {
    name: "Covington",
    state_id: "LA",
    lat: 30.4808,
    lng: -90.1122,
    id: 1840015019,
  },
  {
    name: "Three Rivers",
    state_id: "MI",
    lat: 41.9465,
    lng: -85.6281,
    id: 1840003255,
  },
  {
    name: "Timonium",
    state_id: "MD",
    lat: 39.4463,
    lng: -76.6083,
    id: 1840026624,
  },
  {
    name: "Lincoln Village",
    state_id: "OH",
    lat: 39.9532,
    lng: -83.1314,
    id: 1840034388,
  },
  {
    name: "Morro Bay",
    state_id: "CA",
    lat: 35.3681,
    lng: -120.8481,
    id: 1840020415,
  },
  {
    name: "Lowell",
    state_id: "IN",
    lat: 41.2921,
    lng: -87.4184,
    id: 1840009260,
  },
  {
    name: "Paris",
    state_id: "TN",
    lat: 36.2934,
    lng: -88.3065,
    id: 1840014438,
  },
  {
    name: "Ironton",
    state_id: "OH",
    lat: 38.5319,
    lng: -82.6777,
    id: 1840008607,
  },
  {
    name: "Star",
    state_id: "ID",
    lat: 43.7013,
    lng: -116.4934,
    id: 1840021279,
  },
  {
    name: "Fairview",
    state_id: "CA",
    lat: 37.676,
    lng: -122.048,
    id: 1840028375,
  },
  {
    name: "Pasadena Hills",
    state_id: "FL",
    lat: 28.2881,
    lng: -82.238,
    id: 1840039051,
  },
  {
    name: "Richmond Heights",
    state_id: "FL",
    lat: 25.6347,
    lng: -80.372,
    id: 1840014249,
  },
  {
    name: "Milton",
    state_id: "FL",
    lat: 30.6286,
    lng: -87.0522,
    id: 1840015006,
  },
  {
    name: "San Diego Country Estates",
    state_id: "CA",
    lat: 33.0093,
    lng: -116.7874,
    id: 1840028398,
  },
  {
    name: "Columbia",
    state_id: "IL",
    lat: 38.4581,
    lng: -90.2156,
    id: 1840007502,
  },
  {
    name: "Roscoe",
    state_id: "IL",
    lat: 42.4256,
    lng: -89.0083,
    id: 1840011122,
  },
  {
    name: "Jefferson",
    state_id: "LA",
    lat: 29.9609,
    lng: -90.1554,
    id: 1840013986,
  },
  {
    name: "Little Canada",
    state_id: "MN",
    lat: 45.0244,
    lng: -93.0863,
    id: 1840008936,
  },
  {
    name: "Rosaryville",
    state_id: "MD",
    lat: 38.7672,
    lng: -76.8266,
    id: 1840005952,
  },
  {
    name: "Wapakoneta",
    state_id: "OH",
    lat: 40.5663,
    lng: -84.1915,
    id: 1840010391,
  },
  {
    name: "Alpine",
    state_id: "UT",
    lat: 40.4629,
    lng: -111.7724,
    id: 1840018755,
  },
  {
    name: "Farmington",
    state_id: "MI",
    lat: 42.4614,
    lng: -83.3784,
    id: 1840002441,
  },
  {
    name: "Doctor Phillips",
    state_id: "FL",
    lat: 28.4476,
    lng: -81.4922,
    id: 1840013119,
  },
  {
    name: "Exeter",
    state_id: "CA",
    lat: 36.294,
    lng: -119.1459,
    id: 1840020365,
  },
  {
    name: "Martin",
    state_id: "TN",
    lat: 36.3386,
    lng: -88.8513,
    id: 1840015282,
  },
  {
    name: "Gifford",
    state_id: "FL",
    lat: 27.6747,
    lng: -80.4102,
    id: 1840014160,
  },
  {
    name: "Bernalillo",
    state_id: "NM",
    lat: 35.3127,
    lng: -106.5537,
    id: 1840020388,
  },
  {
    name: "Pine Castle",
    state_id: "FL",
    lat: 28.4651,
    lng: -81.374,
    id: 1840014097,
  },
  {
    name: "Point Pleasant",
    state_id: "WV",
    lat: 38.8529,
    lng: -82.1303,
    id: 1840006048,
  },
  {
    name: "Cape Canaveral",
    state_id: "FL",
    lat: 28.3933,
    lng: -80.605,
    id: 1840015091,
  },
  {
    name: "Sweetwater",
    state_id: "TX",
    lat: 32.4693,
    lng: -100.4092,
    id: 1840022089,
  },
  {
    name: "Fairview Shores",
    state_id: "FL",
    lat: 28.602,
    lng: -81.3948,
    id: 1840013120,
  },
  {
    name: "Jacinto City",
    state_id: "TX",
    lat: 29.7663,
    lng: -95.241,
    id: 1840019619,
  },
  {
    name: "Worth",
    state_id: "IL",
    lat: 41.6877,
    lng: -87.7916,
    id: 1840011318,
  },
  {
    name: "Portage",
    state_id: "WI",
    lat: 43.5489,
    lng: -89.4658,
    id: 1840002712,
  },
  {
    name: "Tanaina",
    state_id: "AK",
    lat: 61.656,
    lng: -149.4272,
    id: 1840023662,
  },
  {
    name: "Bedford Heights",
    state_id: "OH",
    lat: 41.4042,
    lng: -81.5053,
    id: 1840003397,
  },
  {
    name: "Fox Lake",
    state_id: "IL",
    lat: 42.4239,
    lng: -88.1844,
    id: 1840011168,
  },
  {
    name: "Oak Hills",
    state_id: "CA",
    lat: 34.3912,
    lng: -117.4125,
    id: 1840024934,
  },
  {
    name: "Linthicum",
    state_id: "MD",
    lat: 39.2088,
    lng: -76.6625,
    id: 1840005921,
  },
  {
    name: "Fountain Inn",
    state_id: "SC",
    lat: 34.6994,
    lng: -82.1999,
    id: 1840013500,
  },
  {
    name: "New Baltimore",
    state_id: "VA",
    lat: 38.7495,
    lng: -77.7151,
    id: 1840024594,
  },
  {
    name: "Wellington",
    state_id: "CO",
    lat: 40.7,
    lng: -105.0054,
    id: 1840022411,
  },
  {
    name: "Kendallville",
    state_id: "IN",
    lat: 41.4441,
    lng: -85.2578,
    id: 1840008208,
  },
  {
    name: "Sumner",
    state_id: "WA",
    lat: 47.2189,
    lng: -122.2338,
    id: 1840021128,
  },
  {
    name: "Pine Hill",
    state_id: "NJ",
    lat: 39.7879,
    lng: -74.9857,
    id: 1840001509,
  },
  {
    name: "Forestville",
    state_id: "OH",
    lat: 39.0711,
    lng: -84.3389,
    id: 1840034110,
  },
  {
    name: "Hudson",
    state_id: "NY",
    lat: 42.2515,
    lng: -73.7859,
    id: 1840000454,
  },
  {
    name: "Shasta Lake",
    state_id: "CA",
    lat: 40.679,
    lng: -122.3775,
    id: 1840021349,
  },
  {
    name: "Dayton",
    state_id: "TN",
    lat: 35.4912,
    lng: -85.012,
    id: 1840014525,
  },
  {
    name: "Rodeo",
    state_id: "CA",
    lat: 38.0368,
    lng: -122.2526,
    id: 1840018900,
  },
  {
    name: "West Point",
    state_id: "MS",
    lat: 33.6064,
    lng: -88.6571,
    id: 1840015681,
  },
  {
    name: "Vidor",
    state_id: "TX",
    lat: 30.1291,
    lng: -93.9967,
    id: 1840022193,
  },
  {
    name: "New Port Richey East",
    state_id: "FL",
    lat: 28.2605,
    lng: -82.693,
    id: 1840073871,
  },
  {
    name: "Bellefontaine Neighbors",
    state_id: "MO",
    lat: 38.7529,
    lng: -90.228,
    id: 1840007449,
  },
  {
    name: "Vermilion",
    state_id: "OH",
    lat: 41.4103,
    lng: -82.3214,
    id: 1840010234,
  },
  {
    name: "Bonham",
    state_id: "TX",
    lat: 33.588,
    lng: -96.1901,
    id: 1840019332,
  },
  {
    name: "Kennett",
    state_id: "MO",
    lat: 36.2403,
    lng: -90.0481,
    id: 1840008809,
  },
  {
    name: "Troy",
    state_id: "IL",
    lat: 38.7266,
    lng: -89.8973,
    id: 1840010709,
  },
  {
    name: "Fort Bragg",
    state_id: "CA",
    lat: 39.4399,
    lng: -123.8013,
    id: 1840020189,
  },
  {
    name: "Lyons",
    state_id: "IL",
    lat: 41.8121,
    lng: -87.8192,
    id: 1840011292,
  },
  {
    name: "Miami Shores",
    state_id: "FL",
    lat: 25.867,
    lng: -80.1779,
    id: 1840018334,
  },
  {
    name: "East Whittier",
    state_id: "CA",
    lat: 33.9244,
    lng: -117.9887,
    id: 1840019205,
  },
  {
    name: "Columbia",
    state_id: "PA",
    lat: 40.0348,
    lng: -76.4944,
    id: 1840003713,
  },
  {
    name: "Beverly Hills",
    state_id: "MI",
    lat: 42.522,
    lng: -83.2423,
    id: 1840011052,
  },
  {
    name: "Lexington",
    state_id: "NE",
    lat: 40.7779,
    lng: -99.7461,
    id: 1840008285,
  },
  {
    name: "Richmond Heights",
    state_id: "OH",
    lat: 41.5589,
    lng: -81.5029,
    id: 1840003409,
  },
  {
    name: "Sedona",
    state_id: "AZ",
    lat: 34.8574,
    lng: -111.7951,
    id: 1840021584,
  },
  {
    name: "Summerville",
    state_id: "GA",
    lat: 34.4787,
    lng: -85.3491,
    id: 1840015554,
  },
  {
    name: "Union City",
    state_id: "TN",
    lat: 36.4268,
    lng: -89.0474,
    id: 1840015280,
  },
  {
    name: "Vernon",
    state_id: "TX",
    lat: 34.148,
    lng: -99.3,
    id: 1840021912,
  },
  {
    name: "South Huntington",
    state_id: "NY",
    lat: 40.8225,
    lng: -73.3921,
    id: 1840005100,
  },
  {
    name: "Fort Madison",
    state_id: "IA",
    lat: 40.6207,
    lng: -91.3509,
    id: 1840008321,
  },
  {
    name: "Nanticoke",
    state_id: "PA",
    lat: 41.2004,
    lng: -76.0003,
    id: 1840000761,
  },
  {
    name: "Hillsdale",
    state_id: "NJ",
    lat: 41.0074,
    lng: -74.044,
    id: 1840003560,
  },
  {
    name: "Comstock Park",
    state_id: "MI",
    lat: 43.0438,
    lng: -85.6779,
    id: 1840004307,
  },
  {
    name: "Fredonia",
    state_id: "NY",
    lat: 42.4407,
    lng: -79.3319,
    id: 1840004566,
  },
  {
    name: "Helena-West Helena",
    state_id: "AR",
    lat: 34.5314,
    lng: -90.6201,
    id: 1840027858,
  },
  {
    name: "Park Forest Village",
    state_id: "PA",
    lat: 40.7996,
    lng: -77.9084,
    id: 1840035143,
  },
  {
    name: "Forest Acres",
    state_id: "SC",
    lat: 34.0324,
    lng: -80.9716,
    id: 1840013653,
  },
  {
    name: "Reading",
    state_id: "OH",
    lat: 39.2243,
    lng: -84.4333,
    id: 1840001611,
  },
  {
    name: "Vashon",
    state_id: "WA",
    lat: 47.4122,
    lng: -122.4726,
    id: 1840018414,
  },
  {
    name: "Arden Hills",
    state_id: "MN",
    lat: 45.0721,
    lng: -93.167,
    id: 1840006752,
  },
  {
    name: "Pine Ridge",
    state_id: "FL",
    lat: 28.933,
    lng: -82.4761,
    id: 1840028997,
  },
  {
    name: "Medulla",
    state_id: "FL",
    lat: 27.957,
    lng: -81.9866,
    id: 1840014131,
  },
  {
    name: "Independence",
    state_id: "OR",
    lat: 44.8551,
    lng: -123.1948,
    id: 1840018589,
  },
  {
    name: "Doraville",
    state_id: "GA",
    lat: 33.9072,
    lng: -84.2711,
    id: 1840014778,
  },
  {
    name: "Boulder Hill",
    state_id: "IL",
    lat: 41.7112,
    lng: -88.3353,
    id: 1840004821,
  },
  {
    name: "Cypress Gardens",
    state_id: "FL",
    lat: 28.0036,
    lng: -81.6857,
    id: 1840028660,
  },
  {
    name: "Pebble Creek",
    state_id: "FL",
    lat: 28.1583,
    lng: -82.3411,
    id: 1840028995,
  },
  {
    name: "Melvindale",
    state_id: "MI",
    lat: 42.2802,
    lng: -83.1782,
    id: 1840003986,
  },
  {
    name: "Sauk Village",
    state_id: "IL",
    lat: 41.4905,
    lng: -87.5706,
    id: 1840011290,
  },
  {
    name: "Clearfield",
    state_id: "PA",
    lat: 41.0216,
    lng: -78.439,
    id: 1840003490,
  },
  {
    name: "Pella",
    state_id: "IA",
    lat: 41.4051,
    lng: -92.9177,
    id: 1840009293,
  },
  {
    name: "Lake Stickney",
    state_id: "WA",
    lat: 47.8709,
    lng: -122.2596,
    id: 1840037894,
  },
  {
    name: "Sparta",
    state_id: "WI",
    lat: 43.9378,
    lng: -90.8131,
    id: 1840002565,
  },
  {
    name: "Box Elder",
    state_id: "SD",
    lat: 44.1121,
    lng: -103.0827,
    id: 1840002183,
  },
  {
    name: "Middle Island",
    state_id: "NY",
    lat: 40.8857,
    lng: -72.9454,
    id: 1840005045,
  },
  {
    name: "Barrington",
    state_id: "IL",
    lat: 42.1515,
    lng: -88.1281,
    id: 1840011244,
  },
  {
    name: "Great Neck",
    state_id: "NY",
    lat: 40.8028,
    lng: -73.7332,
    id: 1840005290,
  },
  {
    name: "Fort Lupton",
    state_id: "CO",
    lat: 40.0831,
    lng: -104.8024,
    id: 1840020148,
  },
  {
    name: "Victoria",
    state_id: "MN",
    lat: 44.8634,
    lng: -93.6586,
    id: 1840010007,
  },
  {
    name: "Gunbarrel",
    state_id: "CO",
    lat: 40.0634,
    lng: -105.1714,
    id: 1840028424,
  },
  {
    name: "Bluffton",
    state_id: "IN",
    lat: 40.7424,
    lng: -85.173,
    id: 1840007178,
  },
  {
    name: "Waverly",
    state_id: "IA",
    lat: 42.725,
    lng: -92.4708,
    id: 1840000404,
  },
  {
    name: "West Haverstraw",
    state_id: "NY",
    lat: 41.2063,
    lng: -73.9883,
    id: 1840004990,
  },
  {
    name: "Fife",
    state_id: "WA",
    lat: 47.2328,
    lng: -122.3518,
    id: 1840019853,
  },
  {
    name: "Bound Brook",
    state_id: "NJ",
    lat: 40.5676,
    lng: -74.5383,
    id: 1840001053,
  },
  {
    name: "Somers Point",
    state_id: "NJ",
    lat: 39.3167,
    lng: -74.6066,
    id: 1840001537,
  },
  {
    name: "Woodmere",
    state_id: "LA",
    lat: 29.8493,
    lng: -90.0751,
    id: 1840031148,
  },
  {
    name: "Gages Lake",
    state_id: "IL",
    lat: 42.3519,
    lng: -87.9828,
    id: 1840004638,
  },
  {
    name: "Asbury Lake",
    state_id: "FL",
    lat: 30.0472,
    lng: -81.7855,
    id: 1840029019,
  },
  {
    name: "Pike Road",
    state_id: "AL",
    lat: 32.2934,
    lng: -86.0902,
    id: 1840017000,
  },
  {
    name: "Havre",
    state_id: "MT",
    lat: 48.5427,
    lng: -109.6803,
    id: 1840019758,
  },
  {
    name: "Snohomish",
    state_id: "WA",
    lat: 47.9276,
    lng: -122.0968,
    id: 1840021078,
  },
  {
    name: "Citrus",
    state_id: "CA",
    lat: 34.1161,
    lng: -117.889,
    id: 1840017916,
  },
  {
    name: "Waynesville",
    state_id: "NC",
    lat: 35.4854,
    lng: -82.9995,
    id: 1840016297,
  },
  {
    name: "Clarion",
    state_id: "PA",
    lat: 41.2106,
    lng: -79.3803,
    id: 1840000739,
  },
  {
    name: "Kutztown",
    state_id: "PA",
    lat: 40.5213,
    lng: -75.7772,
    id: 1840001178,
  },
  {
    name: "Danville",
    state_id: "IN",
    lat: 39.7603,
    lng: -86.5076,
    id: 1840009539,
  },
  {
    name: "Vienna",
    state_id: "WV",
    lat: 39.324,
    lng: -81.5383,
    id: 1840005787,
  },
  {
    name: "Manville",
    state_id: "NJ",
    lat: 40.5421,
    lng: -74.5892,
    id: 1840003610,
  },
  {
    name: "Bel Air",
    state_id: "MD",
    lat: 39.5348,
    lng: -76.346,
    id: 1840005668,
  },
  {
    name: "Warr Acres",
    state_id: "OK",
    lat: 35.5285,
    lng: -97.6182,
    id: 1840021743,
  },
  {
    name: "Franklin",
    state_id: "KY",
    lat: 36.7178,
    lng: -86.5595,
    id: 1840013290,
  },
  {
    name: "Tipp City",
    state_id: "OH",
    lat: 39.9643,
    lng: -84.1858,
    id: 1840010507,
  },
  {
    name: "Struthers",
    state_id: "OH",
    lat: 41.051,
    lng: -80.592,
    id: 1840003562,
  },
  {
    name: "Lincoln Park",
    state_id: "NJ",
    lat: 40.9239,
    lng: -74.3035,
    id: 1840000953,
  },
  {
    name: "Waldwick",
    state_id: "NJ",
    lat: 41.0134,
    lng: -74.1259,
    id: 1840000921,
  },
  {
    name: "Shawano",
    state_id: "WI",
    lat: 44.7748,
    lng: -88.5843,
    id: 1840002266,
  },
  {
    name: "Red Bank",
    state_id: "SC",
    lat: 33.9296,
    lng: -81.2321,
    id: 1840013658,
  },
  {
    name: "Progress",
    state_id: "PA",
    lat: 40.2905,
    lng: -76.8382,
    id: 1840035164,
  },
  {
    name: "Fairmount",
    state_id: "NY",
    lat: 43.0414,
    lng: -76.2485,
    id: 1840000377,
  },
  {
    name: "Cedar Hills",
    state_id: "UT",
    lat: 40.4135,
    lng: -111.753,
    id: 1840018754,
  },
  {
    name: "Hobart",
    state_id: "WI",
    lat: 44.4967,
    lng: -88.1602,
    id: 1840038100,
  },
  {
    name: "Le Mars",
    state_id: "IA",
    lat: 42.7809,
    lng: -96.1743,
    id: 1840008022,
  },
  {
    name: "Cresson",
    state_id: "PA",
    lat: 40.4626,
    lng: -78.5866,
    id: 1840001105,
  },
  {
    name: "Harrisonville",
    state_id: "MO",
    lat: 38.6529,
    lng: -94.3467,
    id: 1840007464,
  },
  {
    name: "Clinton",
    state_id: "TN",
    lat: 36.0981,
    lng: -84.1283,
    id: 1840014471,
  },
  {
    name: "Brandon",
    state_id: "SD",
    lat: 43.5928,
    lng: -96.5799,
    id: 1840002650,
  },
  {
    name: "Nolensville",
    state_id: "TN",
    lat: 35.9572,
    lng: -86.672,
    id: 1840016219,
  },
  {
    name: "Isabela",
    state_id: "PR",
    lat: 18.4991,
    lng: -67.022,
    id: 1630023674,
  },
  {
    name: "Wood River",
    state_id: "IL",
    lat: 38.8631,
    lng: -90.0773,
    id: 1840010711,
  },
  {
    name: "Friendly",
    state_id: "MD",
    lat: 38.7601,
    lng: -76.9642,
    id: 1840005954,
  },
  {
    name: "Charter Oak",
    state_id: "CA",
    lat: 34.1025,
    lng: -117.8564,
    id: 1840017919,
  },
  {
    name: "Sleepy Hollow",
    state_id: "NY",
    lat: 41.0936,
    lng: -73.8724,
    id: 1840004944,
  },
  {
    name: "Fair Oaks Ranch",
    state_id: "TX",
    lat: 29.7468,
    lng: -98.6375,
    id: 1840020939,
  },
  {
    name: "Holmen",
    state_id: "WI",
    lat: 43.9699,
    lng: -91.2661,
    id: 1840002576,
  },
  {
    name: "Croydon",
    state_id: "PA",
    lat: 40.0911,
    lng: -74.8975,
    id: 1840005406,
  },
  {
    name: "Coweta",
    state_id: "OK",
    lat: 35.968,
    lng: -95.6543,
    id: 1840019077,
  },
  {
    name: "Fairmont",
    state_id: "MN",
    lat: 43.6441,
    lng: -94.4621,
    id: 1840007950,
  },
  {
    name: "Warrenton",
    state_id: "VA",
    lat: 38.7176,
    lng: -77.7975,
    id: 1840006058,
  },
  {
    name: "Progress Village",
    state_id: "FL",
    lat: 27.8832,
    lng: -82.3593,
    id: 1840028917,
  },
  {
    name: "Pleasant Hill",
    state_id: "IA",
    lat: 41.5868,
    lng: -93.4952,
    id: 1840009219,
  },
  {
    name: "Waggaman",
    state_id: "LA",
    lat: 29.9373,
    lng: -90.2354,
    id: 1840013991,
  },
  {
    name: "Capitola",
    state_id: "CA",
    lat: 36.9772,
    lng: -121.9538,
    id: 1840018959,
  },
  {
    name: "Laurens",
    state_id: "SC",
    lat: 34.5022,
    lng: -82.0207,
    id: 1840014668,
  },
  {
    name: "Midland",
    state_id: "WA",
    lat: 47.1734,
    lng: -122.412,
    id: 1840018452,
  },
  {
    name: "Bull Mountain",
    state_id: "OR",
    lat: 45.4126,
    lng: -122.8322,
    id: 1840040416,
  },
  {
    name: "Flat Rock",
    state_id: "MI",
    lat: 42.0991,
    lng: -83.2716,
    id: 1840003973,
  },
  {
    name: "Quartz Hill",
    state_id: "CA",
    lat: 34.6527,
    lng: -118.2163,
    id: 1840019210,
  },
  {
    name: "Molalla",
    state_id: "OR",
    lat: 45.1502,
    lng: -122.5844,
    id: 1840019953,
  },
  {
    name: "Scotchtown",
    state_id: "NY",
    lat: 41.4759,
    lng: -74.3682,
    id: 1840004862,
  },
  {
    name: "Fort Oglethorpe",
    state_id: "GA",
    lat: 34.9319,
    lng: -85.246,
    id: 1840013554,
  },
  {
    name: "Del Aire",
    state_id: "CA",
    lat: 33.9168,
    lng: -118.3693,
    id: 1840028367,
  },
  {
    name: "Alcoa",
    state_id: "TN",
    lat: 35.8073,
    lng: -83.9752,
    id: 1840013404,
  },
  {
    name: "Country Club",
    state_id: "CA",
    lat: 37.9687,
    lng: -121.3408,
    id: 1840028364,
  },
  {
    name: "Lake Arbor",
    state_id: "MD",
    lat: 38.907,
    lng: -76.8299,
    id: 1840031485,
  },
  {
    name: "Garden Acres",
    state_id: "CA",
    lat: 37.9637,
    lng: -121.2296,
    id: 1840028376,
  },
  {
    name: "Orland",
    state_id: "CA",
    lat: 39.7461,
    lng: -122.1856,
    id: 1840020209,
  },
  {
    name: "Helotes",
    state_id: "TX",
    lat: 29.5693,
    lng: -98.6956,
    id: 1840020957,
  },
  {
    name: "Bee Ridge",
    state_id: "FL",
    lat: 27.2855,
    lng: -82.4731,
    id: 1840028632,
  },
  {
    name: "Key Largo",
    state_id: "FL",
    lat: 25.1224,
    lng: -80.412,
    id: 1840014253,
  },
  {
    name: "Timberlane",
    state_id: "LA",
    lat: 29.8781,
    lng: -90.0303,
    id: 1840031154,
  },
  {
    name: "Westphalia",
    state_id: "MD",
    lat: 38.8385,
    lng: -76.8231,
    id: 1840024549,
  },
  {
    name: "Wabash",
    state_id: "IN",
    lat: 40.8034,
    lng: -85.8301,
    id: 1840010335,
  },
  {
    name: "Winterville",
    state_id: "NC",
    lat: 35.5287,
    lng: -77.3994,
    id: 1840016279,
  },
  {
    name: "Clay",
    state_id: "AL",
    lat: 33.6976,
    lng: -86.607,
    id: 1840014790,
  },
  {
    name: "Celebration",
    state_id: "FL",
    lat: 28.3102,
    lng: -81.551,
    id: 1840014138,
  },
  {
    name: "St. Albans",
    state_id: "WV",
    lat: 38.3769,
    lng: -81.8198,
    id: 1840006201,
  },
  {
    name: "Mission",
    state_id: "KS",
    lat: 39.027,
    lng: -94.6568,
    id: 1840003831,
  },
  {
    name: "Berwick",
    state_id: "PA",
    lat: 41.0555,
    lng: -76.2492,
    id: 1840003484,
  },
  {
    name: "Watervliet",
    state_id: "NY",
    lat: 42.7243,
    lng: -73.7068,
    id: 1840002495,
  },
  {
    name: "Alachua",
    state_id: "FL",
    lat: 29.7778,
    lng: -82.4831,
    id: 1840014020,
  },
  {
    name: "Kinnelon",
    state_id: "NJ",
    lat: 40.9847,
    lng: -74.3862,
    id: 1840000952,
  },
  {
    name: "Ventnor City",
    state_id: "NJ",
    lat: 39.3457,
    lng: -74.486,
    id: 1840001539,
  },
  {
    name: "Rockcreek",
    state_id: "OR",
    lat: 45.5525,
    lng: -122.8757,
    id: 1840034833,
  },
  {
    name: "Inwood",
    state_id: "NY",
    lat: 40.6218,
    lng: -73.7507,
    id: 1840005267,
  },
  {
    name: "White City",
    state_id: "OR",
    lat: 42.4316,
    lng: -122.8322,
    id: 1840018673,
  },
  {
    name: "Emmett",
    state_id: "ID",
    lat: 43.8685,
    lng: -116.489,
    id: 1840020000,
  },
  {
    name: "Sugar Grove",
    state_id: "IL",
    lat: 41.7758,
    lng: -88.448,
    id: 1840011355,
  },
  {
    name: "River Grove",
    state_id: "IL",
    lat: 41.9243,
    lng: -87.8379,
    id: 1840011285,
  },
  {
    name: "Pingree Grove",
    state_id: "IL",
    lat: 42.0857,
    lng: -88.4362,
    id: 1840011352,
  },
  {
    name: "Fort Irwin",
    state_id: "CA",
    lat: 35.2476,
    lng: -116.6834,
    id: 1840025802,
  },
  {
    name: "Northampton",
    state_id: "PA",
    lat: 40.6866,
    lng: -75.4904,
    id: 1840000975,
  },
  {
    name: "Ellisville",
    state_id: "MO",
    lat: 38.5897,
    lng: -90.5884,
    id: 1840008581,
  },
  {
    name: "Madera Acres",
    state_id: "CA",
    lat: 37.0123,
    lng: -120.0799,
    id: 1840028386,
  },
  {
    name: "Gloucester Point",
    state_id: "VA",
    lat: 37.2767,
    lng: -76.5043,
    id: 1840006399,
  },
  {
    name: "Marina del Rey",
    state_id: "CA",
    lat: 33.9765,
    lng: -118.4486,
    id: 1840017920,
  },
  {
    name: "Brandywine",
    state_id: "MD",
    lat: 38.6963,
    lng: -76.8846,
    id: 1840005946,
  },
  {
    name: "Stonegate",
    state_id: "CO",
    lat: 39.5356,
    lng: -104.8033,
    id: 1840028570,
  },
  {
    name: "Carroll",
    state_id: "IA",
    lat: 42.0699,
    lng: -94.8647,
    id: 1840000490,
  },
  {
    name: "Port Townsend",
    state_id: "WA",
    lat: 48.122,
    lng: -122.7872,
    id: 1840019797,
  },
  {
    name: "Coffeyville",
    state_id: "KS",
    lat: 37.0518,
    lng: -95.618,
    id: 1840001707,
  },
  {
    name: "West Perrine",
    state_id: "FL",
    lat: 25.6061,
    lng: -80.3639,
    id: 1840029018,
  },
  {
    name: "Chillicothe",
    state_id: "MO",
    lat: 39.7953,
    lng: -93.5499,
    id: 1840007302,
  },
  {
    name: "Lake Barcroft",
    state_id: "VA",
    lat: 38.8514,
    lng: -77.1579,
    id: 1840006033,
  },
  {
    name: "Burtonsville",
    state_id: "MD",
    lat: 39.1166,
    lng: -76.9356,
    id: 1840005827,
  },
  {
    name: "Franklin",
    state_id: "NH",
    lat: 43.4499,
    lng: -71.6691,
    id: 1840002748,
  },
  {
    name: "Robertsdale",
    state_id: "AL",
    lat: 30.5534,
    lng: -87.7023,
    id: 1840014981,
  },
  {
    name: "East Rockaway",
    state_id: "NY",
    lat: 40.6432,
    lng: -73.6672,
    id: 1840005343,
  },
  {
    name: "Baker City",
    state_id: "OR",
    lat: 44.7749,
    lng: -117.832,
    id: 1840018587,
  },
  {
    name: "New Hyde Park",
    state_id: "NY",
    lat: 40.7324,
    lng: -73.6858,
    id: 1840005313,
  },
  {
    name: "Itasca",
    state_id: "IL",
    lat: 41.9773,
    lng: -88.0182,
    id: 1840011400,
  },
  {
    name: "Brookdale",
    state_id: "NJ",
    lat: 40.8348,
    lng: -74.1798,
    id: 1840024254,
  },
  {
    name: "Jennings",
    state_id: "LA",
    lat: 30.2233,
    lng: -92.6582,
    id: 1840015040,
  },
  {
    name: "Woodbury",
    state_id: "NJ",
    lat: 39.8379,
    lng: -75.1524,
    id: 1840001527,
  },
  {
    name: "Mentone",
    state_id: "CA",
    lat: 34.0609,
    lng: -117.1108,
    id: 1840019109,
  },
  {
    name: "Little River",
    state_id: "SC",
    lat: 33.8787,
    lng: -78.6393,
    id: 1840013648,
  },
  {
    name: "Cody",
    state_id: "WY",
    lat: 44.5212,
    lng: -109.0549,
    id: 1840018595,
  },
  {
    name: "Vega Alta",
    state_id: "PR",
    lat: 18.4152,
    lng: -66.3211,
    id: 1630023587,
  },
  {
    name: "Mooresville",
    state_id: "IN",
    lat: 39.6022,
    lng: -86.3681,
    id: 1840009668,
  },
  {
    name: "Conning Towers Nautilus Park",
    state_id: "CT",
    lat: 41.3855,
    lng: -72.0686,
    id: 1840073288,
  },
  {
    name: "Westgate",
    state_id: "FL",
    lat: 26.6994,
    lng: -80.0989,
    id: 1840042909,
  },
  {
    name: "George Mason",
    state_id: "VA",
    lat: 38.8355,
    lng: -77.3185,
    id: 1840041768,
  },
  {
    name: "Fort Salonga",
    state_id: "NY",
    lat: 40.906,
    lng: -73.2992,
    id: 1840005013,
  },
  {
    name: "Russellville",
    state_id: "AL",
    lat: 34.5056,
    lng: -87.7282,
    id: 1840004247,
  },
  {
    name: "North Bend",
    state_id: "OR",
    lat: 43.4075,
    lng: -124.2364,
    id: 1840020057,
  },
  {
    name: "Clarksville",
    state_id: "AR",
    lat: 35.457,
    lng: -93.4803,
    id: 1840014533,
  },
  {
    name: "Philipsburg",
    state_id: "PA",
    lat: 40.8952,
    lng: -78.2145,
    id: 1840000827,
  },
  {
    name: "Denham Springs",
    state_id: "LA",
    lat: 30.4743,
    lng: -90.9594,
    id: 1840015027,
  },
  {
    name: "Scaggsville",
    state_id: "MD",
    lat: 39.1416,
    lng: -76.8843,
    id: 1840024483,
  },
  {
    name: "Corte Madera",
    state_id: "CA",
    lat: 37.9238,
    lng: -122.5129,
    id: 1840022519,
  },
  {
    name: "Jewett City",
    state_id: "CT",
    lat: 41.607,
    lng: -71.9807,
    id: 1840004826,
  },
  {
    name: "Cuero",
    state_id: "TX",
    lat: 29.1024,
    lng: -97.2871,
    id: 1840019676,
  },
  {
    name: "Budd Lake",
    state_id: "NJ",
    lat: 40.8733,
    lng: -74.7374,
    id: 1840005195,
  },
  {
    name: "Columbia City",
    state_id: "IN",
    lat: 41.1612,
    lng: -85.4855,
    id: 1840007140,
  },
  {
    name: "Blacklick Estates",
    state_id: "OH",
    lat: 39.9049,
    lng: -82.8655,
    id: 1840034379,
  },
  {
    name: "East Aurora",
    state_id: "NY",
    lat: 42.7666,
    lng: -78.6172,
    id: 1840004398,
  },
  {
    name: "Rio Vista",
    state_id: "CA",
    lat: 38.1763,
    lng: -121.7025,
    id: 1840020254,
  },
  {
    name: "Alexandria",
    state_id: "KY",
    lat: 38.9621,
    lng: -84.386,
    id: 1840013162,
  },
  {
    name: "Eidson Road",
    state_id: "TX",
    lat: 28.6677,
    lng: -100.4787,
    id: 1840037044,
  },
  {
    name: "Pájaros",
    state_id: "PR",
    lat: 18.3609,
    lng: -66.2184,
    id: 1630035532,
  },
  {
    name: "West Athens",
    state_id: "CA",
    lat: 33.9235,
    lng: -118.3033,
    id: 1840028403,
  },
  {
    name: "Boaz",
    state_id: "AL",
    lat: 34.1985,
    lng: -86.1529,
    id: 1840013602,
  },
  {
    name: "Chesapeake Ranch Estates",
    state_id: "MD",
    lat: 38.3574,
    lng: -76.4147,
    id: 1840026723,
  },
  {
    name: "Countryside",
    state_id: "VA",
    lat: 39.0518,
    lng: -77.4124,
    id: 1840024500,
  },
  {
    name: "St. Francis",
    state_id: "WI",
    lat: 42.9716,
    lng: -87.8729,
    id: 1840003049,
  },
  {
    name: "Holualoa",
    state_id: "HI",
    lat: 19.6238,
    lng: -155.9269,
    id: 1840029482,
  },
  {
    name: "East Rutherford",
    state_id: "NJ",
    lat: 40.8179,
    lng: -74.0854,
    id: 1840003542,
  },
  {
    name: "Fort Valley",
    state_id: "GA",
    lat: 32.552,
    lng: -83.8819,
    id: 1840013819,
  },
  {
    name: "Commerce",
    state_id: "TX",
    lat: 33.2421,
    lng: -95.8991,
    id: 1840019394,
  },
  {
    name: "West Tawakoni",
    state_id: "TX",
    lat: 32.8976,
    lng: -96.0217,
    id: 1840022015,
  },
  {
    name: "Warrenton",
    state_id: "MO",
    lat: 38.8187,
    lng: -91.1384,
    id: 1840010714,
  },
  {
    name: "Marysville",
    state_id: "MI",
    lat: 42.9084,
    lng: -82.4806,
    id: 1840003957,
  },
  {
    name: "Marlton",
    state_id: "NJ",
    lat: 39.9016,
    lng: -74.9297,
    id: 1840005522,
  },
  {
    name: "Greenville",
    state_id: "MI",
    lat: 43.1797,
    lng: -85.2533,
    id: 1840002877,
  },
  {
    name: "Rio del Mar",
    state_id: "CA",
    lat: 36.9607,
    lng: -121.8807,
    id: 1840028179,
  },
  {
    name: "Picture Rocks",
    state_id: "AZ",
    lat: 32.3274,
    lng: -111.2557,
    id: 1840019482,
  },
  {
    name: "Cameron",
    state_id: "MO",
    lat: 39.7469,
    lng: -94.2364,
    id: 1840007321,
  },
  {
    name: "Waterford",
    state_id: "CA",
    lat: 37.6429,
    lng: -120.7553,
    id: 1840021534,
  },
  {
    name: "Memphis",
    state_id: "FL",
    lat: 27.5435,
    lng: -82.5607,
    id: 1840014172,
  },
  {
    name: "Fort Stewart",
    state_id: "GA",
    lat: 31.8818,
    lng: -81.6105,
    id: 1840073839,
  },
  {
    name: "Port Wentworth",
    state_id: "GA",
    lat: 32.1942,
    lng: -81.1984,
    id: 1840014915,
  },
  {
    name: "Yorkshire",
    state_id: "VA",
    lat: 38.7882,
    lng: -77.4495,
    id: 1840006107,
  },
  {
    name: "Aiea",
    state_id: "HI",
    lat: 21.3865,
    lng: -157.9232,
    id: 1840029469,
  },
  {
    name: "Hermantown",
    state_id: "MN",
    lat: 46.8058,
    lng: -92.2407,
    id: 1840006593,
  },
  {
    name: "Winfield",
    state_id: "IL",
    lat: 41.8776,
    lng: -88.1507,
    id: 1840011410,
  },
  {
    name: "Oxford",
    state_id: "NC",
    lat: 36.3155,
    lng: -78.5848,
    id: 1840014435,
  },
  {
    name: "Cold Springs",
    state_id: "NV",
    lat: 39.6927,
    lng: -119.9775,
    id: 1840033824,
  },
  {
    name: "Keansburg",
    state_id: "NJ",
    lat: 40.4471,
    lng: -74.1316,
    id: 1840003690,
  },
  {
    name: "Powdersville",
    state_id: "SC",
    lat: 34.7825,
    lng: -82.4958,
    id: 1840013581,
  },
  {
    name: "La Plata",
    state_id: "MD",
    lat: 38.5352,
    lng: -76.9701,
    id: 1840006177,
  },
  {
    name: "Merrill",
    state_id: "WI",
    lat: 45.182,
    lng: -89.6995,
    id: 1840001562,
  },
  {
    name: "Bithlo",
    state_id: "FL",
    lat: 28.5644,
    lng: -81.1074,
    id: 1840014083,
  },
  {
    name: "Chevy Chase",
    state_id: "MD",
    lat: 38.9943,
    lng: -77.0737,
    id: 1840031323,
  },
  {
    name: "Wilmore",
    state_id: "KY",
    lat: 37.8786,
    lng: -84.6545,
    id: 1840015220,
  },
  {
    name: "Maywood",
    state_id: "NJ",
    lat: 40.9025,
    lng: -74.0634,
    id: 1840003536,
  },
  {
    name: "Rainbow City",
    state_id: "AL",
    lat: 33.9337,
    lng: -86.0922,
    id: 1840014732,
  },
  {
    name: "Howell",
    state_id: "MI",
    lat: 42.6078,
    lng: -83.9339,
    id: 1840003100,
  },
  {
    name: "Pleasant Grove",
    state_id: "AL",
    lat: 33.4936,
    lng: -86.9782,
    id: 1840014793,
  },
  {
    name: "Blackhawk",
    state_id: "CA",
    lat: 37.816,
    lng: -121.9071,
    id: 1840028358,
  },
  {
    name: "Hope",
    state_id: "AR",
    lat: 33.6682,
    lng: -93.5895,
    id: 1840014765,
  },
  {
    name: "Roxboro",
    state_id: "NC",
    lat: 36.3879,
    lng: -78.9812,
    id: 1840014432,
  },
  {
    name: "Bishop",
    state_id: "CA",
    lat: 37.3665,
    lng: -118.3958,
    id: 1840018955,
  },
  {
    name: "Middletown",
    state_id: "PA",
    lat: 40.201,
    lng: -76.7289,
    id: 1840001291,
  },
  {
    name: "Greenville",
    state_id: "PA",
    lat: 41.4052,
    lng: -80.3837,
    id: 1840000659,
  },
  {
    name: "St. Pete Beach",
    state_id: "FL",
    lat: 27.7235,
    lng: -82.7387,
    id: 1840015976,
  },
  {
    name: "Rice Lake",
    state_id: "WI",
    lat: 45.4863,
    lng: -91.7447,
    id: 1840002075,
  },
  {
    name: "North Gates",
    state_id: "NY",
    lat: 43.1718,
    lng: -77.7064,
    id: 1840024047,
  },
  {
    name: "Alderwood Manor",
    state_id: "WA",
    lat: 47.8147,
    lng: -122.2672,
    id: 1840017323,
  },
  {
    name: "Ecorse",
    state_id: "MI",
    lat: 42.2489,
    lng: -83.1399,
    id: 1840003972,
  },
  {
    name: "Harleysville",
    state_id: "PA",
    lat: 40.2791,
    lng: -75.3872,
    id: 1840005463,
  },
  {
    name: "Fairview",
    state_id: "OR",
    lat: 45.5471,
    lng: -122.4391,
    id: 1840019938,
  },
  {
    name: "The Village",
    state_id: "OK",
    lat: 35.5706,
    lng: -97.5567,
    id: 1840021742,
  },
  {
    name: "Eagle Point",
    state_id: "OR",
    lat: 42.4677,
    lng: -122.8016,
    id: 1840018676,
  },
  {
    name: "Craig",
    state_id: "CO",
    lat: 40.517,
    lng: -107.5555,
    id: 1840018741,
  },
  {
    name: "Willoughby Hills",
    state_id: "OH",
    lat: 41.5873,
    lng: -81.4333,
    id: 1840000548,
  },
  {
    name: "Walterboro",
    state_id: "SC",
    lat: 32.901,
    lng: -80.676,
    id: 1840015757,
  },
  {
    name: "White Meadow Lake",
    state_id: "NJ",
    lat: 40.924,
    lng: -74.5121,
    id: 1840005198,
  },
  {
    name: "Mount Vista",
    state_id: "WA",
    lat: 45.7373,
    lng: -122.6315,
    id: 1840037858,
  },
  {
    name: "Lowell",
    state_id: "AR",
    lat: 36.2561,
    lng: -94.1532,
    id: 1840015285,
  },
  {
    name: "Silsbee",
    state_id: "TX",
    lat: 30.3456,
    lng: -94.1764,
    id: 1840022182,
  },
  {
    name: "Harvard",
    state_id: "IL",
    lat: 42.43,
    lng: -88.6217,
    id: 1840006970,
  },
  {
    name: "Central City",
    state_id: "KY",
    lat: 37.2962,
    lng: -87.128,
    id: 1840014378,
  },
  {
    name: "Tell City",
    state_id: "IN",
    lat: 37.9528,
    lng: -86.7597,
    id: 1840009755,
  },
  {
    name: "Lambertville",
    state_id: "MI",
    lat: 41.7502,
    lng: -83.625,
    id: 1840006576,
  },
  {
    name: "Roxborough Park",
    state_id: "CO",
    lat: 39.4492,
    lng: -105.0746,
    id: 1840028568,
  },
  {
    name: "Rye Brook",
    state_id: "NY",
    lat: 41.0303,
    lng: -73.6865,
    id: 1840004942,
  },
  {
    name: "Reedsburg",
    state_id: "WI",
    lat: 43.5347,
    lng: -89.9965,
    id: 1840002727,
  },
  {
    name: "Laurel",
    state_id: "FL",
    lat: 27.1446,
    lng: -82.4618,
    id: 1840014184,
  },
  {
    name: "Fair Oaks",
    state_id: "GA",
    lat: 33.9192,
    lng: -84.5444,
    id: 1840013091,
  },
  {
    name: "DuPont",
    state_id: "WA",
    lat: 47.1079,
    lng: -122.6496,
    id: 1840018457,
  },
  {
    name: "Mound",
    state_id: "MN",
    lat: 44.9328,
    lng: -93.6591,
    id: 1840007828,
  },
  {
    name: "Jessup",
    state_id: "MD",
    lat: 39.1457,
    lng: -76.7745,
    id: 1840005919,
  },
  {
    name: "Boiling Springs",
    state_id: "SC",
    lat: 35.045,
    lng: -81.9779,
    id: 1840013509,
  },
  {
    name: "Wakefield-Peacedale",
    state_id: "RI",
    lat: 41.4461,
    lng: -71.5004,
    id: 1840073472,
  },
  {
    name: "Erwin",
    state_id: "TN",
    lat: 36.1456,
    lng: -82.4115,
    id: 1840016130,
  },
  {
    name: "Rigby",
    state_id: "ID",
    lat: 43.6735,
    lng: -111.9126,
    id: 1840020020,
  },
  {
    name: "Fort Stockton",
    state_id: "TX",
    lat: 30.8926,
    lng: -102.8844,
    id: 1840020848,
  },
  {
    name: "Airway Heights",
    state_id: "WA",
    lat: 47.646,
    lng: -117.5792,
    id: 1840018399,
  },
  {
    name: "Parsons",
    state_id: "KS",
    lat: 37.3405,
    lng: -95.2959,
    id: 1840001712,
  },
  {
    name: "Fayetteville",
    state_id: "TN",
    lat: 35.149,
    lng: -86.5634,
    id: 1840013476,
  },
  {
    name: "Meadowbrook",
    state_id: "AL",
    lat: 33.3932,
    lng: -86.7041,
    id: 1840027633,
  },
  {
    name: "Lititz",
    state_id: "PA",
    lat: 40.154,
    lng: -76.3044,
    id: 1840003719,
  },
  {
    name: "Siler City",
    state_id: "NC",
    lat: 35.7252,
    lng: -79.4561,
    id: 1840017783,
  },
  {
    name: "Platte City",
    state_id: "MO",
    lat: 39.3576,
    lng: -94.7655,
    id: 1840009591,
  },
  {
    name: "Lantana",
    state_id: "TX",
    lat: 33.092,
    lng: -97.1216,
    id: 1840025053,
  },
  {
    name: "Woodfield",
    state_id: "SC",
    lat: 34.0587,
    lng: -80.9309,
    id: 1840035853,
  },
  {
    name: "Mount Airy",
    state_id: "MD",
    lat: 39.3743,
    lng: -77.1535,
    id: 1840005703,
  },
  {
    name: "Carencro",
    state_id: "LA",
    lat: 30.3126,
    lng: -92.0387,
    id: 1840015043,
  },
  {
    name: "Winslow",
    state_id: "AZ",
    lat: 35.0253,
    lng: -110.7098,
    id: 1840021583,
  },
  {
    name: "Ruidoso",
    state_id: "NM",
    lat: 33.3647,
    lng: -105.6432,
    id: 1840022873,
  },
  {
    name: "Hondo",
    state_id: "TX",
    lat: 29.3531,
    lng: -99.162,
    id: 1840020963,
  },
  {
    name: "Miles City",
    state_id: "MT",
    lat: 46.4059,
    lng: -105.8385,
    id: 1840019878,
  },
  {
    name: "Brownsville",
    state_id: "TN",
    lat: 35.589,
    lng: -89.2578,
    id: 1840013413,
  },
  {
    name: "Manistee",
    state_id: "MI",
    lat: 44.244,
    lng: -86.3242,
    id: 1840002511,
  },
  {
    name: "Rayne",
    state_id: "LA",
    lat: 30.2403,
    lng: -92.2668,
    id: 1840015042,
  },
  {
    name: "Augusta",
    state_id: "KS",
    lat: 37.6955,
    lng: -96.9921,
    id: 1840001675,
  },
  {
    name: "New Richmond",
    state_id: "WI",
    lat: 45.1249,
    lng: -92.5377,
    id: 1840002138,
  },
  {
    name: "Greenwood",
    state_id: "AR",
    lat: 35.2134,
    lng: -94.2408,
    id: 1840013457,
  },
  {
    name: "Monticello",
    state_id: "AR",
    lat: 33.6258,
    lng: -91.7934,
    id: 1840014805,
  },
  {
    name: "Bladensburg",
    state_id: "MD",
    lat: 38.9424,
    lng: -76.9263,
    id: 1840005981,
  },
  {
    name: "St. Stephens",
    state_id: "NC",
    lat: 35.7641,
    lng: -81.2746,
    id: 1840033023,
  },
  {
    name: "Little Falls",
    state_id: "MN",
    lat: 45.9833,
    lng: -94.36,
    id: 1840008861,
  },
  {
    name: "Bryan",
    state_id: "OH",
    lat: 41.4706,
    lng: -84.5484,
    id: 1840000573,
  },
  {
    name: "Amityville",
    state_id: "NY",
    lat: 40.6696,
    lng: -73.4156,
    id: 1840005140,
  },
  {
    name: "Tomah",
    state_id: "WI",
    lat: 43.9879,
    lng: -90.4999,
    id: 1840002566,
  },
  {
    name: "Brunswick",
    state_id: "MD",
    lat: 39.318,
    lng: -77.6253,
    id: 1840005709,
  },
  {
    name: "Roanoke",
    state_id: "TX",
    lat: 33.0148,
    lng: -97.2268,
    id: 1840020641,
  },
  {
    name: "Francisville",
    state_id: "KY",
    lat: 39.1067,
    lng: -84.7277,
    id: 1840026679,
  },
  {
    name: "Hickam Housing",
    state_id: "HI",
    lat: 21.3311,
    lng: -157.9474,
    id: 1840075009,
  },
  {
    name: "Navasota",
    state_id: "TX",
    lat: 30.3874,
    lng: -96.0895,
    id: 1840023174,
  },
  {
    name: "Flowood",
    state_id: "MS",
    lat: 32.3359,
    lng: -90.0802,
    id: 1840013824,
  },
  {
    name: "Pryor Creek",
    state_id: "OK",
    lat: 36.2998,
    lng: -95.3102,
    id: 1840037410,
  },
  {
    name: "Bardmoor",
    state_id: "FL",
    lat: 27.8575,
    lng: -82.7534,
    id: 1840038841,
  },
  {
    name: "Bloomfield",
    state_id: "NM",
    lat: 36.7401,
    lng: -107.9734,
    id: 1840018994,
  },
  {
    name: "Cairo",
    state_id: "GA",
    lat: 30.8791,
    lng: -84.205,
    id: 1840013900,
  },
  {
    name: "Dayton",
    state_id: "NV",
    lat: 39.258,
    lng: -119.5677,
    id: 1840018796,
  },
  {
    name: "Boonville",
    state_id: "MO",
    lat: 38.9588,
    lng: -92.7471,
    id: 1840007420,
  },
  {
    name: "Louisville",
    state_id: "OH",
    lat: 40.8371,
    lng: -81.2643,
    id: 1840000964,
  },
  {
    name: "Beverly Hills",
    state_id: "FL",
    lat: 28.9175,
    lng: -82.4541,
    id: 1840014061,
  },
  {
    name: "Brownfield",
    state_id: "TX",
    lat: 33.1757,
    lng: -102.273,
    id: 1840019404,
  },
  {
    name: "Hackettstown",
    state_id: "NJ",
    lat: 40.854,
    lng: -74.8257,
    id: 1840003573,
  },
  {
    name: "Stuarts Draft",
    state_id: "VA",
    lat: 38.0245,
    lng: -79.0308,
    id: 1840006247,
  },
  {
    name: "Bay Minette",
    state_id: "AL",
    lat: 30.893,
    lng: -87.7912,
    id: 1840000959,
  },
  {
    name: "Golden Hills",
    state_id: "CA",
    lat: 35.1512,
    lng: -118.5024,
    id: 1840017819,
  },
  {
    name: "Edwards",
    state_id: "CO",
    lat: 39.6215,
    lng: -106.6183,
    id: 1840017535,
  },
  {
    name: "Woodmoor",
    state_id: "CO",
    lat: 39.1063,
    lng: -104.8456,
    id: 1840028584,
  },
  {
    name: "Saline",
    state_id: "MI",
    lat: 42.1741,
    lng: -83.778,
    id: 1840003171,
  },
  {
    name: "St. Augustine Shores",
    state_id: "FL",
    lat: 29.8039,
    lng: -81.3086,
    id: 1840013972,
  },
  {
    name: "Madras",
    state_id: "OR",
    lat: 44.6425,
    lng: -121.1315,
    id: 1840019987,
  },
  {
    name: "Clinton",
    state_id: "NC",
    lat: 35.0005,
    lng: -78.3311,
    id: 1840014605,
  },
  {
    name: "Heath",
    state_id: "TX",
    lat: 32.8444,
    lng: -96.4679,
    id: 1840020716,
  },
  {
    name: "Claymont",
    state_id: "DE",
    lat: 39.8032,
    lng: -75.4606,
    id: 1840005570,
  },
  {
    name: "Stansbury Park",
    state_id: "UT",
    lat: 40.6356,
    lng: -112.3054,
    id: 1840037176,
  },
  {
    name: "Rotonda",
    state_id: "FL",
    lat: 26.8845,
    lng: -82.2791,
    id: 1840014201,
  },
  {
    name: "Marlboro Village",
    state_id: "MD",
    lat: 38.8307,
    lng: -76.7699,
    id: 1840039492,
  },
  {
    name: "Lenoir City",
    state_id: "TN",
    lat: 35.811,
    lng: -84.2818,
    id: 1840014514,
  },
  {
    name: "Birch Bay",
    state_id: "WA",
    lat: 48.923,
    lng: -122.7543,
    id: 1840017289,
  },
  {
    name: "Hartland",
    state_id: "WI",
    lat: 43.1027,
    lng: -88.3399,
    id: 1840003009,
  },
  {
    name: "Waihee-Waiehu",
    state_id: "HI",
    lat: 20.9188,
    lng: -156.5063,
    id: 1840037549,
  },
  {
    name: "College Place",
    state_id: "WA",
    lat: 46.0419,
    lng: -118.3879,
    id: 1840018490,
  },
  {
    name: "Liberty",
    state_id: "TX",
    lat: 30.0379,
    lng: -94.788,
    id: 1840020965,
  },
  {
    name: "Zimmerman",
    state_id: "MN",
    lat: 45.4416,
    lng: -93.5981,
    id: 1840009993,
  },
  {
    name: "Wesley Chapel",
    state_id: "NC",
    lat: 34.9985,
    lng: -80.6905,
    id: 1840017881,
  },
  {
    name: "Wood-Ridge",
    state_id: "NJ",
    lat: 40.8507,
    lng: -74.0878,
    id: 1840000925,
  },
  {
    name: "Detroit Lakes",
    state_id: "MN",
    lat: 46.806,
    lng: -95.8449,
    id: 1840006632,
  },
  {
    name: "Fultondale",
    state_id: "AL",
    lat: 33.6177,
    lng: -86.8015,
    id: 1840002481,
  },
  {
    name: "North College Hill",
    state_id: "OH",
    lat: 39.2174,
    lng: -84.552,
    id: 1840001609,
  },
  {
    name: "Cross Lanes",
    state_id: "WV",
    lat: 38.4351,
    lng: -81.7706,
    id: 1840006190,
  },
  {
    name: "Marathon",
    state_id: "FL",
    lat: 24.7262,
    lng: -81.0376,
    id: 1840016010,
  },
  {
    name: "Westwood",
    state_id: "MI",
    lat: 42.303,
    lng: -85.6286,
    id: 1840006978,
  },
  {
    name: "Brentwood",
    state_id: "PA",
    lat: 40.3734,
    lng: -79.9757,
    id: 1840001212,
  },
  {
    name: "Lexington",
    state_id: "VA",
    lat: 37.7825,
    lng: -79.444,
    id: 1840001689,
  },
  {
    name: "Bohemia",
    state_id: "NY",
    lat: 40.7717,
    lng: -73.1271,
    id: 1840005020,
  },
  {
    name: "Hernando",
    state_id: "FL",
    lat: 28.9451,
    lng: -82.3781,
    id: 1840014058,
  },
  {
    name: "Cheat Lake",
    state_id: "WV",
    lat: 39.6672,
    lng: -79.8565,
    id: 1840005645,
  },
  {
    name: "Harahan",
    state_id: "LA",
    lat: 29.9374,
    lng: -90.203,
    id: 1840013997,
  },
  {
    name: "Washington Terrace",
    state_id: "UT",
    lat: 41.1683,
    lng: -111.9783,
    id: 1840021343,
  },
  {
    name: "Sultan",
    state_id: "WA",
    lat: 47.871,
    lng: -121.8043,
    id: 1840021080,
  },
  {
    name: "Muncy",
    state_id: "PA",
    lat: 41.2021,
    lng: -76.7854,
    id: 1840000633,
  },
  {
    name: "Madeira",
    state_id: "OH",
    lat: 39.1856,
    lng: -84.3734,
    id: 1840001605,
  },
  {
    name: "Anthony",
    state_id: "NM",
    lat: 32.0132,
    lng: -106.5984,
    id: 1840018049,
  },
  {
    name: "Gridley",
    state_id: "CA",
    lat: 39.3622,
    lng: -121.6971,
    id: 1840020181,
  },
  {
    name: "Kendall Park",
    state_id: "NJ",
    lat: 40.4138,
    lng: -74.5626,
    id: 1840005419,
  },
  {
    name: "Girard",
    state_id: "OH",
    lat: 41.1666,
    lng: -80.6963,
    id: 1840008239,
  },
  {
    name: "Hillview",
    state_id: "KY",
    lat: 38.0563,
    lng: -85.6848,
    id: 1840014325,
  },
  {
    name: "Burlington",
    state_id: "WA",
    lat: 48.4676,
    lng: -122.3298,
    id: 1840018367,
  },
  {
    name: "Steger",
    state_id: "IL",
    lat: 41.4723,
    lng: -87.6176,
    id: 1840011325,
  },
  {
    name: "Sans Souci",
    state_id: "SC",
    lat: 34.8901,
    lng: -82.4241,
    id: 1840013497,
  },
  {
    name: "White Marsh",
    state_id: "MD",
    lat: 39.3819,
    lng: -76.4573,
    id: 1840005696,
  },
  {
    name: "Spanish Fort",
    state_id: "AL",
    lat: 30.7257,
    lng: -87.8601,
    id: 1840015879,
  },
  {
    name: "Lake Elmo",
    state_id: "MN",
    lat: 44.9944,
    lng: -92.9031,
    id: 1840007814,
  },
  {
    name: "Bethalto",
    state_id: "IL",
    lat: 38.9014,
    lng: -90.0467,
    id: 1840012789,
  },
  {
    name: "Cloverdale",
    state_id: "CA",
    lat: 38.7961,
    lng: -123.0151,
    id: 1840018838,
  },
  {
    name: "Independent Hill",
    state_id: "VA",
    lat: 38.6404,
    lng: -77.409,
    id: 1840026708,
  },
  {
    name: "Good Hope",
    state_id: "CA",
    lat: 33.7706,
    lng: -117.2772,
    id: 1840028330,
  },
  {
    name: "Lynwood",
    state_id: "IL",
    lat: 41.5234,
    lng: -87.5508,
    id: 1840011291,
  },
  {
    name: "Basalt",
    state_id: "CO",
    lat: 39.3663,
    lng: -107.0414,
    id: 1840020195,
  },
  {
    name: "Ivins",
    state_id: "UT",
    lat: 37.1742,
    lng: -113.6809,
    id: 1840018937,
  },
  {
    name: "Wagoner",
    state_id: "OK",
    lat: 35.9641,
    lng: -95.379,
    id: 1840021696,
  },
  {
    name: "St. Marys",
    state_id: "OH",
    lat: 40.5475,
    lng: -84.3931,
    id: 1840009408,
  },
  {
    name: "Elwood",
    state_id: "IN",
    lat: 40.2744,
    lng: -85.837,
    id: 1840008364,
  },
  {
    name: "Woodlyn",
    state_id: "PA",
    lat: 39.8774,
    lng: -75.3445,
    id: 1840035266,
  },
  {
    name: "South Amboy",
    state_id: "NJ",
    lat: 40.4852,
    lng: -74.2831,
    id: 1840001333,
  },
  {
    name: "Bloomingdale",
    state_id: "TN",
    lat: 36.5793,
    lng: -82.5096,
    id: 1840013297,
  },
  {
    name: "Sabana Grande",
    state_id: "PR",
    lat: 18.0821,
    lng: -66.9673,
    id: 1630023550,
  },
  {
    name: "Sullivan",
    state_id: "MO",
    lat: 38.2129,
    lng: -91.1636,
    id: 1840009643,
  },
  {
    name: "Rensselaer",
    state_id: "NY",
    lat: 42.6465,
    lng: -73.7328,
    id: 1840000394,
  },
  {
    name: "Audubon",
    state_id: "PA",
    lat: 40.1304,
    lng: -75.428,
    id: 1840034852,
  },
  {
    name: "Calimesa",
    state_id: "CA",
    lat: 33.9874,
    lng: -117.0542,
    id: 1840019300,
  },
  {
    name: "Dunn Loring",
    state_id: "VA",
    lat: 38.8945,
    lng: -77.2316,
    id: 1840006017,
  },
  {
    name: "Flossmoor",
    state_id: "IL",
    lat: 41.5391,
    lng: -87.6857,
    id: 1840011274,
  },
  {
    name: "Beckett Ridge",
    state_id: "OH",
    lat: 39.3448,
    lng: -84.4381,
    id: 1840034377,
  },
  {
    name: "Plymouth",
    state_id: "MI",
    lat: 42.3718,
    lng: -83.468,
    id: 1840003959,
  },
  {
    name: "Ridgefield",
    state_id: "WA",
    lat: 45.8114,
    lng: -122.705,
    id: 1840019909,
  },
  {
    name: "Hooper",
    state_id: "UT",
    lat: 41.1599,
    lng: -112.2871,
    id: 1840020130,
  },
  {
    name: "Rathdrum",
    state_id: "ID",
    lat: 47.7995,
    lng: -116.8908,
    id: 1840019812,
  },
  {
    name: "Thief River Falls",
    state_id: "MN",
    lat: 48.1112,
    lng: -96.1771,
    id: 1840008825,
  },
  {
    name: "Fairview",
    state_id: "TN",
    lat: 35.9815,
    lng: -87.1291,
    id: 1840013379,
  },
  {
    name: "Fairview",
    state_id: "TX",
    lat: 33.1399,
    lng: -96.6116,
    id: 1840022021,
  },
  {
    name: "Anaconda",
    state_id: "MT",
    lat: 46.0607,
    lng: -113.0679,
    id: 1840074775,
  },
  {
    name: "Murrells Inlet",
    state_id: "SC",
    lat: 33.5566,
    lng: -79.0593,
    id: 1840013742,
  },
  {
    name: "Union",
    state_id: "SC",
    lat: 34.7235,
    lng: -81.6248,
    id: 1840015533,
  },
  {
    name: "Woodburn",
    state_id: "VA",
    lat: 38.8503,
    lng: -77.2322,
    id: 1840024584,
  },
  {
    name: "Monett",
    state_id: "MO",
    lat: 36.922,
    lng: -93.9261,
    id: 1840008797,
  },
  {
    name: "Grinnell",
    state_id: "IA",
    lat: 41.7361,
    lng: -92.7244,
    id: 1840000531,
  },
  {
    name: "Jersey Shore",
    state_id: "PA",
    lat: 41.2012,
    lng: -77.2666,
    id: 1840000630,
  },
  {
    name: "Grosse Pointe Farms",
    state_id: "MI",
    lat: 42.4067,
    lng: -82.8992,
    id: 1840003977,
  },
  {
    name: "Rapid Valley",
    state_id: "SD",
    lat: 44.0674,
    lng: -103.1223,
    id: 1840004098,
  },
  {
    name: "Linganore",
    state_id: "MD",
    lat: 39.4111,
    lng: -77.3026,
    id: 1840026627,
  },
  {
    name: "Edgewater",
    state_id: "MD",
    lat: 38.9373,
    lng: -76.5572,
    id: 1840024528,
  },
  {
    name: "Atmore",
    state_id: "AL",
    lat: 31.0927,
    lng: -87.4763,
    id: 1840013888,
  },
  {
    name: "Economy",
    state_id: "PA",
    lat: 40.641,
    lng: -80.184,
    id: 1840066074,
  },
  {
    name: "Plymouth",
    state_id: "WI",
    lat: 43.7447,
    lng: -87.9657,
    id: 1840002626,
  },
  {
    name: "Burton",
    state_id: "SC",
    lat: 32.4233,
    lng: -80.7454,
    id: 1840013816,
  },
  {
    name: "Berthoud",
    state_id: "CO",
    lat: 40.307,
    lng: -105.0419,
    id: 1840020153,
  },
  {
    name: "Whitestown",
    state_id: "IN",
    lat: 39.9705,
    lng: -86.3612,
    id: 1840010514,
  },
  {
    name: "San Elizario",
    state_id: "TX",
    lat: 31.5793,
    lng: -106.2632,
    id: 1840019523,
  },
  {
    name: "Dillon",
    state_id: "SC",
    lat: 34.4233,
    lng: -79.3689,
    id: 1840014676,
  },
  {
    name: "Clinton",
    state_id: "OK",
    lat: 35.5058,
    lng: -98.9724,
    id: 1840019108,
  },
  {
    name: "Tiburon",
    state_id: "CA",
    lat: 37.8854,
    lng: -122.4637,
    id: 1840022521,
  },
  {
    name: "Monmouth",
    state_id: "IL",
    lat: 40.9141,
    lng: -90.6423,
    id: 1840008283,
  },
  {
    name: "Highland Park",
    state_id: "TX",
    lat: 32.8311,
    lng: -96.8012,
    id: 1840022058,
  },
  {
    name: "Sugarmill Woods",
    state_id: "FL",
    lat: 28.7321,
    lng: -82.4986,
    id: 1840014063,
  },
  {
    name: "Golden Valley",
    state_id: "AZ",
    lat: 35.206,
    lng: -114.2327,
    id: 1840018339,
  },
  {
    name: "Northwest Harborcreek",
    state_id: "PA",
    lat: 42.1494,
    lng: -79.9946,
    id: 1840035146,
  },
  {
    name: "Jeannette",
    state_id: "PA",
    lat: 40.3277,
    lng: -79.6139,
    id: 1840001141,
  },
  {
    name: "Marlton",
    state_id: "MD",
    lat: 38.762,
    lng: -76.7857,
    id: 1840005962,
  },
  {
    name: "Sutherlin",
    state_id: "OR",
    lat: 43.3884,
    lng: -123.3231,
    id: 1840021274,
  },
  {
    name: "Picnic Point",
    state_id: "WA",
    lat: 47.8744,
    lng: -122.3078,
    id: 1840037897,
  },
  {
    name: "Seaside",
    state_id: "OR",
    lat: 45.9889,
    lng: -123.9214,
    id: 1840021182,
  },
  {
    name: "Livingston",
    state_id: "MT",
    lat: 45.6666,
    lng: -110.5538,
    id: 1840019906,
  },
  {
    name: "Fort Polk South",
    state_id: "LA",
    lat: 31.0512,
    lng: -93.2159,
    id: 1840073837,
  },
  {
    name: "Micco",
    state_id: "FL",
    lat: 27.8683,
    lng: -80.51,
    id: 1840014077,
  },
  {
    name: "Chanute",
    state_id: "KS",
    lat: 37.6695,
    lng: -95.4621,
    id: 1840001694,
  },
  {
    name: "Beecher",
    state_id: "MI",
    lat: 43.0903,
    lng: -83.7039,
    id: 1840004343,
  },
  {
    name: "Leisure World",
    state_id: "MD",
    lat: 39.1023,
    lng: -77.0691,
    id: 1840073671,
  },
  {
    name: "Indianola",
    state_id: "MS",
    lat: 33.4492,
    lng: -90.6447,
    id: 1840014774,
  },
  {
    name: "North Kensington",
    state_id: "MD",
    lat: 39.0392,
    lng: -77.0723,
    id: 1840031382,
  },
  {
    name: "Leonia",
    state_id: "NJ",
    lat: 40.8638,
    lng: -73.9899,
    id: 1840000904,
  },
  {
    name: "Brewer",
    state_id: "ME",
    lat: 44.7835,
    lng: -68.7352,
    id: 1840000239,
  },
  {
    name: "La Grange",
    state_id: "KY",
    lat: 38.3987,
    lng: -85.375,
    id: 1840014287,
  },
  {
    name: "Corozal",
    state_id: "PR",
    lat: 18.3411,
    lng: -66.3124,
    id: 1630035655,
  },
  {
    name: "McFarland",
    state_id: "WI",
    lat: 43.0203,
    lng: -89.2858,
    id: 1840002914,
  },
  {
    name: "Silver Springs Shores",
    state_id: "FL",
    lat: 29.1031,
    lng: -82.005,
    id: 1840028949,
  },
  {
    name: "Shelby",
    state_id: "OH",
    lat: 40.8847,
    lng: -82.6578,
    id: 1840009369,
  },
  {
    name: "Pineville",
    state_id: "NC",
    lat: 35.0864,
    lng: -80.8915,
    id: 1840016354,
  },
  {
    name: "Woodway",
    state_id: "TX",
    lat: 31.4988,
    lng: -97.2314,
    id: 1840022142,
  },
  {
    name: "Oakbrook",
    state_id: "KY",
    lat: 38.9996,
    lng: -84.6797,
    id: 1840030814,
  },
  {
    name: "Valley Cottage",
    state_id: "NY",
    lat: 41.116,
    lng: -73.9436,
    id: 1840004971,
  },
  {
    name: "Lindstrom",
    state_id: "MN",
    lat: 45.387,
    lng: -92.8477,
    id: 1840008892,
  },
  {
    name: "Shiprock",
    state_id: "NM",
    lat: 36.7924,
    lng: -108.7005,
    id: 1840018988,
  },
  {
    name: "St. Anthony",
    state_id: "MN",
    lat: 45.0278,
    lng: -93.2174,
    id: 1840008933,
  },
  {
    name: "Presque Isle",
    state_id: "ME",
    lat: 46.6868,
    lng: -67.9874,
    id: 1840000146,
  },
  {
    name: "Farmingdale",
    state_id: "NY",
    lat: 40.7328,
    lng: -73.4465,
    id: 1840005285,
  },
  {
    name: "Manteno",
    state_id: "IL",
    lat: 41.2471,
    lng: -87.8457,
    id: 1840011703,
  },
  {
    name: "Lake Mohawk",
    state_id: "NJ",
    lat: 41.015,
    lng: -74.6639,
    id: 1840004955,
  },
  {
    name: "Dexter",
    state_id: "MO",
    lat: 36.7928,
    lng: -89.9634,
    id: 1840007643,
  },
  {
    name: "Delta",
    state_id: "CO",
    lat: 38.756,
    lng: -108.0772,
    id: 1840018820,
  },
  {
    name: "Mechanicsburg",
    state_id: "PA",
    lat: 40.2115,
    lng: -77.006,
    id: 1840001388,
  },
  {
    name: "Albion",
    state_id: "MI",
    lat: 42.2478,
    lng: -84.7572,
    id: 1840003175,
  },
  {
    name: "Haiku-Pauwela",
    state_id: "HI",
    lat: 20.9156,
    lng: -156.3022,
    id: 1840074990,
  },
  {
    name: "Skidaway Island",
    state_id: "GA",
    lat: 31.9372,
    lng: -81.0449,
    id: 1840029458,
  },
  {
    name: "LaSalle",
    state_id: "IL",
    lat: 41.3575,
    lng: -89.0718,
    id: 1840029891,
  },
  {
    name: "Fairfield Glade",
    state_id: "TN",
    lat: 36.0028,
    lng: -84.8711,
    id: 1840013067,
  },
  {
    name: "Waseca",
    state_id: "MN",
    lat: 44.0826,
    lng: -93.5025,
    id: 1840010030,
  },
  {
    name: "Murphysboro",
    state_id: "IL",
    lat: 37.7679,
    lng: -89.3321,
    id: 1840008701,
  },
  {
    name: "Plainedge",
    state_id: "NY",
    lat: 40.724,
    lng: -73.477,
    id: 1840005249,
  },
  {
    name: "Fallston",
    state_id: "MD",
    lat: 39.5332,
    lng: -76.4452,
    id: 1840005662,
  },
  {
    name: "Triangle",
    state_id: "VA",
    lat: 38.5483,
    lng: -77.3195,
    id: 1840006105,
  },
  {
    name: "Chester",
    state_id: "SC",
    lat: 34.705,
    lng: -81.2132,
    id: 1840014663,
  },
  {
    name: "Derby",
    state_id: "CO",
    lat: 39.8401,
    lng: -104.9171,
    id: 1840028576,
  },
  {
    name: "Lacombe",
    state_id: "LA",
    lat: 30.3141,
    lng: -89.9311,
    id: 1840013942,
  },
  {
    name: "Felida",
    state_id: "WA",
    lat: 45.7138,
    lng: -122.7104,
    id: 1840037495,
  },
  {
    name: "Summit",
    state_id: "WA",
    lat: 47.1694,
    lng: -122.3628,
    id: 1840018456,
  },
  {
    name: "Clinton",
    state_id: "MO",
    lat: 38.3716,
    lng: -93.7679,
    id: 1840007492,
  },
  {
    name: "Stony Brook University",
    state_id: "NY",
    lat: 40.9099,
    lng: -73.1213,
    id: 1840042758,
  },
  {
    name: "Lake Arrowhead",
    state_id: "CA",
    lat: 34.2531,
    lng: -117.1945,
    id: 1840017803,
  },
  {
    name: "Oakwood",
    state_id: "OH",
    lat: 39.7202,
    lng: -84.1733,
    id: 1840003785,
  },
  {
    name: "Sturgeon Bay",
    state_id: "WI",
    lat: 44.8228,
    lng: -87.366,
    id: 1840003935,
  },
  {
    name: "Harrisburg",
    state_id: "IL",
    lat: 37.7373,
    lng: -88.5457,
    id: 1840007570,
  },
  {
    name: "Independence",
    state_id: "KS",
    lat: 37.2118,
    lng: -95.7328,
    id: 1840001708,
  },
  {
    name: "Forestdale",
    state_id: "AL",
    lat: 33.5737,
    lng: -86.9002,
    id: 1840013723,
  },
  {
    name: "Boonton",
    state_id: "NJ",
    lat: 40.9047,
    lng: -74.4048,
    id: 1840000946,
  },
  {
    name: "Oak Grove",
    state_id: "MN",
    lat: 45.3409,
    lng: -93.3264,
    id: 1840007799,
  },
  {
    name: "Harrodsburg",
    state_id: "KY",
    lat: 37.7654,
    lng: -84.8474,
    id: 1840013222,
  },
  {
    name: "Sheffield Lake",
    state_id: "OH",
    lat: 41.4884,
    lng: -82.0978,
    id: 1840000647,
  },
  {
    name: "Folsom",
    state_id: "PA",
    lat: 39.8924,
    lng: -75.3287,
    id: 1840034970,
  },
  {
    name: "Cedar Hills",
    state_id: "OR",
    lat: 45.5047,
    lng: -122.8051,
    id: 1840034716,
  },
  {
    name: "Aledo",
    state_id: "TX",
    lat: 32.6973,
    lng: -97.607,
    id: 1840019418,
  },
  {
    name: "Sellersburg",
    state_id: "IN",
    lat: 38.4028,
    lng: -85.7706,
    id: 1840010786,
  },
  {
    name: "Blanchard",
    state_id: "OK",
    lat: 35.1523,
    lng: -97.6613,
    id: 1840019175,
  },
  {
    name: "Southside",
    state_id: "AL",
    lat: 33.9007,
    lng: -86.0238,
    id: 1840015604,
  },
  {
    name: "Whitehouse",
    state_id: "TX",
    lat: 32.2222,
    lng: -95.2217,
    id: 1840022077,
  },
  {
    name: "Merrydale",
    state_id: "LA",
    lat: 30.4998,
    lng: -91.1081,
    id: 1840013940,
  },
  {
    name: "Sheffield",
    state_id: "AL",
    lat: 34.757,
    lng: -87.6977,
    id: 1840015537,
  },
  {
    name: "St. Albans",
    state_id: "VT",
    lat: 44.8118,
    lng: -73.0846,
    id: 1840002267,
  },
  {
    name: "Oakland",
    state_id: "TN",
    lat: 35.2256,
    lng: -89.5372,
    id: 1840016407,
  },
  {
    name: "West Haven-Sylvan",
    state_id: "OR",
    lat: 45.5164,
    lng: -122.7654,
    id: 1840074715,
  },
  {
    name: "Innsbrook",
    state_id: "VA",
    lat: 37.6552,
    lng: -77.5775,
    id: 1840042829,
  },
  {
    name: "Evergreen",
    state_id: "CO",
    lat: 39.6349,
    lng: -105.3356,
    id: 1840017539,
  },
  {
    name: "Logan",
    state_id: "WV",
    lat: 37.8509,
    lng: -81.9857,
    id: 1840006336,
  },
  {
    name: "Gresham Park",
    state_id: "GA",
    lat: 33.7053,
    lng: -84.3155,
    id: 1840029454,
  },
  {
    name: "Poteau",
    state_id: "OK",
    lat: 35.043,
    lng: -94.6357,
    id: 1840020445,
  },
  {
    name: "Cabo Rojo",
    state_id: "PR",
    lat: 18.0867,
    lng: -67.1482,
    id: 1630023608,
  },
  {
    name: "New Prague",
    state_id: "MN",
    lat: 44.5459,
    lng: -93.5758,
    id: 1840007882,
  },
  {
    name: "Woodbury",
    state_id: "NY",
    lat: 40.8177,
    lng: -73.4703,
    id: 1840150821,
  },
  {
    name: "Kodiak",
    state_id: "AK",
    lat: 57.7934,
    lng: -152.406,
    id: 1840023301,
  },
  {
    name: "Aliquippa",
    state_id: "PA",
    lat: 40.6155,
    lng: -80.2547,
    id: 1840001009,
  },
  {
    name: "Bridgeport",
    state_id: "WV",
    lat: 39.3036,
    lng: -80.2477,
    id: 1840005766,
  },
  {
    name: "Berkeley",
    state_id: "MO",
    lat: 38.7439,
    lng: -90.3361,
    id: 1840007450,
  },
  {
    name: "Riverdale",
    state_id: "UT",
    lat: 41.1732,
    lng: -112.0024,
    id: 1840020131,
  },
  {
    name: "Covington",
    state_id: "TN",
    lat: 35.5661,
    lng: -89.6482,
    id: 1840014547,
  },
  {
    name: "Rhinelander",
    state_id: "WI",
    lat: 45.6361,
    lng: -89.4256,
    id: 1840002046,
  },
  {
    name: "Ville Platte",
    state_id: "LA",
    lat: 30.6901,
    lng: -92.2744,
    id: 1840015896,
  },
  {
    name: "Olmsted Falls",
    state_id: "OH",
    lat: 41.3658,
    lng: -81.9042,
    id: 1840003404,
  },
  {
    name: "Glencoe",
    state_id: "IL",
    lat: 42.1347,
    lng: -87.7641,
    id: 1840011279,
  },
  {
    name: "Orange Park",
    state_id: "FL",
    lat: 30.1706,
    lng: -81.7041,
    id: 1840017206,
  },
  {
    name: "Canal Winchester",
    state_id: "OH",
    lat: 39.8432,
    lng: -82.8121,
    id: 1840010524,
  },
  {
    name: "Absecon",
    state_id: "NJ",
    lat: 39.4228,
    lng: -74.4944,
    id: 1840003797,
  },
  {
    name: "Glens Falls North",
    state_id: "NY",
    lat: 43.335,
    lng: -73.6824,
    id: 1840073682,
  },
  {
    name: "Palm Beach",
    state_id: "FL",
    lat: 26.6932,
    lng: -80.0406,
    id: 1840017267,
  },
  {
    name: "North Sarasota",
    state_id: "FL",
    lat: 27.3711,
    lng: -82.5177,
    id: 1840029061,
  },
  {
    name: "Franklin",
    state_id: "PA",
    lat: 41.3936,
    lng: -79.8425,
    id: 1840003425,
  },
  {
    name: "Richmond Heights",
    state_id: "MO",
    lat: 38.6309,
    lng: -90.3333,
    id: 1840009769,
  },
  {
    name: "Oakville",
    state_id: "CT",
    lat: 41.5923,
    lng: -73.0858,
    id: 1840003260,
  },
  {
    name: "Clanton",
    state_id: "AL",
    lat: 32.844,
    lng: -86.623,
    id: 1840006157,
  },
  {
    name: "Collingdale",
    state_id: "PA",
    lat: 39.9151,
    lng: -75.2776,
    id: 1840000693,
  },
  {
    name: "Airmont",
    state_id: "NY",
    lat: 41.0992,
    lng: -74.099,
    id: 1840004991,
  },
  {
    name: "Quakertown",
    state_id: "PA",
    lat: 40.4398,
    lng: -75.3456,
    id: 1840001310,
  },
  {
    name: "Citrus Springs",
    state_id: "FL",
    lat: 28.9931,
    lng: -82.4595,
    id: 1840014062,
  },
  {
    name: "Villas",
    state_id: "NJ",
    lat: 39.0158,
    lng: -74.9349,
    id: 1840005869,
  },
  {
    name: "Frankfort Square",
    state_id: "IL",
    lat: 41.5221,
    lng: -87.8033,
    id: 1840029897,
  },
  {
    name: "West Miami",
    state_id: "FL",
    lat: 25.7578,
    lng: -80.2969,
    id: 1840016009,
  },
  {
    name: "Wharton",
    state_id: "TX",
    lat: 29.3138,
    lng: -96.1044,
    id: 1840022229,
  },
  {
    name: "Redlands",
    state_id: "CO",
    lat: 39.0886,
    lng: -108.6583,
    id: 1840018812,
  },
  {
    name: "New Square",
    state_id: "NY",
    lat: 41.141,
    lng: -74.0294,
    id: 1840004980,
  },
  {
    name: "South Kensington",
    state_id: "MD",
    lat: 39.0188,
    lng: -77.0785,
    id: 1840031497,
  },
  {
    name: "Canonsburg",
    state_id: "PA",
    lat: 40.2643,
    lng: -80.1868,
    id: 1840003643,
  },
  {
    name: "Edgewood",
    state_id: "KY",
    lat: 39.0091,
    lng: -84.5604,
    id: 1840013166,
  },
  {
    name: "Graham",
    state_id: "TX",
    lat: 33.1017,
    lng: -98.5778,
    id: 1840020668,
  },
  {
    name: "New Albany",
    state_id: "MS",
    lat: 34.4909,
    lng: -89.0201,
    id: 1840014678,
  },
  {
    name: "Clarendon Hills",
    state_id: "IL",
    lat: 41.7981,
    lng: -87.9569,
    id: 1840010170,
  },
  {
    name: "Needles",
    state_id: "CA",
    lat: 34.8164,
    lng: -114.6189,
    id: 1840020409,
  },
  {
    name: "Pitman",
    state_id: "NJ",
    lat: 39.7335,
    lng: -75.1306,
    id: 1840001521,
  },
  {
    name: "Rock Falls",
    state_id: "IL",
    lat: 41.7723,
    lng: -89.6928,
    id: 1840009202,
  },
  {
    name: "Perkasie",
    state_id: "PA",
    lat: 40.3719,
    lng: -75.292,
    id: 1840001309,
  },
  {
    name: "Clayton",
    state_id: "NJ",
    lat: 39.6627,
    lng: -75.0782,
    id: 1840001520,
  },
  {
    name: "Cortez",
    state_id: "CO",
    lat: 37.3505,
    lng: -108.5766,
    id: 1840018928,
  },
  {
    name: "Princeton",
    state_id: "IN",
    lat: 38.3553,
    lng: -87.5784,
    id: 1840009801,
  },
  {
    name: "Olney",
    state_id: "IL",
    lat: 38.7285,
    lng: -88.0838,
    id: 1840009783,
  },
  {
    name: "Sanger",
    state_id: "TX",
    lat: 33.3716,
    lng: -97.1677,
    id: 1840022004,
  },
  {
    name: "Seven Corners",
    state_id: "VA",
    lat: 38.8658,
    lng: -77.1445,
    id: 1840006038,
  },
  {
    name: "Cape St. Claire",
    state_id: "MD",
    lat: 39.0433,
    lng: -76.4471,
    id: 1840005908,
  },
  {
    name: "Kirby",
    state_id: "TX",
    lat: 29.4611,
    lng: -98.3861,
    id: 1840020962,
  },
  {
    name: "Glenwood",
    state_id: "IL",
    lat: 41.5409,
    lng: -87.6116,
    id: 1840011281,
  },
  {
    name: "Dumbarton",
    state_id: "VA",
    lat: 37.613,
    lng: -77.5065,
    id: 1840006387,
  },
  {
    name: "Garden City",
    state_id: "GA",
    lat: 32.0868,
    lng: -81.1773,
    id: 1840013839,
  },
  {
    name: "Crestline",
    state_id: "CA",
    lat: 34.2486,
    lng: -117.289,
    id: 1840017796,
  },
  {
    name: "Jerseyville",
    state_id: "IL",
    lat: 39.1175,
    lng: -90.3271,
    id: 1840008530,
  },
  {
    name: "Thompsonville",
    state_id: "CT",
    lat: 41.9916,
    lng: -72.5965,
    id: 1840003267,
  },
  {
    name: "Nevada",
    state_id: "MO",
    lat: 37.8445,
    lng: -94.3492,
    id: 1840008690,
  },
  {
    name: "Des Peres",
    state_id: "MO",
    lat: 38.5973,
    lng: -90.448,
    id: 1840007456,
  },
  {
    name: "Perryville",
    state_id: "MO",
    lat: 37.7263,
    lng: -89.8759,
    id: 1840009871,
  },
  {
    name: "Corrales",
    state_id: "NM",
    lat: 35.2366,
    lng: -106.6191,
    id: 1840022681,
  },
  {
    name: "Fort Knox",
    state_id: "KY",
    lat: 37.8915,
    lng: -85.9636,
    id: 1840073833,
  },
  {
    name: "Greenville",
    state_id: "RI",
    lat: 41.88,
    lng: -71.5549,
    id: 1840003292,
  },
  {
    name: "Park Ridge",
    state_id: "NJ",
    lat: 41.0353,
    lng: -74.0423,
    id: 1840000917,
  },
  {
    name: "Fern Park",
    state_id: "FL",
    lat: 28.6484,
    lng: -81.3458,
    id: 1840014068,
  },
  {
    name: "Greenbriar",
    state_id: "VA",
    lat: 38.8717,
    lng: -77.397,
    id: 1840024572,
  },
  {
    name: "Coral Hills",
    state_id: "MD",
    lat: 38.8709,
    lng: -76.9234,
    id: 1840005950,
  },
  {
    name: "Montesano",
    state_id: "WA",
    lat: 47.0102,
    lng: -123.5857,
    id: 1840019847,
  },
  {
    name: "Pleasant Hill",
    state_id: "MO",
    lat: 38.8061,
    lng: -94.2655,
    id: 1840009786,
  },
  {
    name: "Andalusia",
    state_id: "AL",
    lat: 31.3101,
    lng: -86.4781,
    id: 1840004591,
  },
  {
    name: "Scott",
    state_id: "LA",
    lat: 30.2398,
    lng: -92.0947,
    id: 1840015932,
  },
  {
    name: "Incline Village",
    state_id: "NV",
    lat: 39.2639,
    lng: -119.9453,
    id: 1840033809,
  },
  {
    name: "Glenshaw",
    state_id: "PA",
    lat: 40.5391,
    lng: -79.9735,
    id: 1840026474,
  },
  {
    name: "Mount Pleasant",
    state_id: "IA",
    lat: 40.9625,
    lng: -91.5452,
    id: 1840000888,
  },
  {
    name: "Cresskill",
    state_id: "NJ",
    lat: 40.9405,
    lng: -73.9596,
    id: 1840003539,
  },
  {
    name: "Earlimart",
    state_id: "CA",
    lat: 35.8824,
    lng: -119.2715,
    id: 1840019032,
  },
  {
    name: "Hawthorn Woods",
    state_id: "IL",
    lat: 42.2313,
    lng: -88.0623,
    id: 1840011154,
  },
  {
    name: "Hoquiam",
    state_id: "WA",
    lat: 46.9863,
    lng: -123.9022,
    id: 1840019845,
  },
  {
    name: "Brigantine",
    state_id: "NJ",
    lat: 39.4138,
    lng: -74.3787,
    id: 1840001533,
  },
  {
    name: "Fishersville",
    state_id: "VA",
    lat: 38.1005,
    lng: -78.9687,
    id: 1840006240,
  },
  {
    name: "Swissvale",
    state_id: "PA",
    lat: 40.4207,
    lng: -79.8858,
    id: 1840001264,
  },
  {
    name: "Kings Grant",
    state_id: "NC",
    lat: 34.2665,
    lng: -77.8659,
    id: 1840032902,
  },
  {
    name: "Kennedale",
    state_id: "TX",
    lat: 32.6434,
    lng: -97.2173,
    id: 1840019429,
  },
  {
    name: "Matawan",
    state_id: "NJ",
    lat: 40.4127,
    lng: -74.2365,
    id: 1840003675,
  },
  {
    name: "San Sebastián",
    state_id: "PR",
    lat: 18.3356,
    lng: -66.9949,
    id: 1630023635,
  },
  {
    name: "Monahans",
    state_id: "TX",
    lat: 31.6289,
    lng: -103.0403,
    id: 1840036514,
  },
  {
    name: "Audubon",
    state_id: "NJ",
    lat: 39.8906,
    lng: -75.0722,
    id: 1840003775,
  },
  {
    name: "North Madison",
    state_id: "OH",
    lat: 41.8298,
    lng: -81.0511,
    id: 1840004801,
  },
  {
    name: "Prairie du Sac",
    state_id: "WI",
    lat: 43.292,
    lng: -89.7353,
    id: 1840002726,
  },
  {
    name: "York",
    state_id: "SC",
    lat: 34.9967,
    lng: -81.234,
    id: 1840015489,
  },
  {
    name: "Salem",
    state_id: "UT",
    lat: 40.054,
    lng: -111.672,
    id: 1840021395,
  },
  {
    name: "Hyrum",
    state_id: "UT",
    lat: 41.6311,
    lng: -111.8418,
    id: 1840018699,
  },
  {
    name: "Richlands",
    state_id: "VA",
    lat: 37.0878,
    lng: -81.808,
    id: 1840006454,
  },
  {
    name: "Ladue",
    state_id: "MO",
    lat: 38.6378,
    lng: -90.3815,
    id: 1840008599,
  },
  {
    name: "Rockland",
    state_id: "ME",
    lat: 44.1274,
    lng: -69.1369,
    id: 1840000322,
  },
  {
    name: "Alamo Heights",
    state_id: "TX",
    lat: 29.4828,
    lng: -98.4682,
    id: 1840019653,
  },
  {
    name: "Reserve",
    state_id: "LA",
    lat: 30.0741,
    lng: -90.5557,
    id: 1840013969,
  },
  {
    name: "Orting",
    state_id: "WA",
    lat: 47.0966,
    lng: -122.2111,
    id: 1840019858,
  },
  {
    name: "Tuba City",
    state_id: "AZ",
    lat: 36.125,
    lng: -111.2468,
    id: 1840018972,
  },
  {
    name: "Signal Mountain",
    state_id: "TN",
    lat: 35.1449,
    lng: -85.3457,
    id: 1840017859,
  },
  {
    name: "Fairmount",
    state_id: "CO",
    lat: 39.7931,
    lng: -105.1712,
    id: 1840038703,
  },
  {
    name: "Riverside",
    state_id: "CT",
    lat: 41.0319,
    lng: -73.5827,
    id: 1840026286,
  },
  {
    name: "Corning",
    state_id: "CA",
    lat: 39.9282,
    lng: -122.182,
    id: 1840018762,
  },
  {
    name: "Orrville",
    state_id: "OH",
    lat: 40.848,
    lng: -81.7743,
    id: 1840009371,
  },
  {
    name: "Elsmere",
    state_id: "KY",
    lat: 38.9949,
    lng: -84.6017,
    id: 1840013167,
  },
  {
    name: "Farmville",
    state_id: "VA",
    lat: 37.2959,
    lng: -78.4002,
    id: 1840006443,
  },
  {
    name: "Sheridan",
    state_id: "OR",
    lat: 45.096,
    lng: -123.3983,
    id: 1840021220,
  },
  {
    name: "Sanatoga",
    state_id: "PA",
    lat: 40.2498,
    lng: -75.5886,
    id: 1840035186,
  },
  {
    name: "Kalifornsky",
    state_id: "AK",
    lat: 60.4417,
    lng: -151.1972,
    id: 1840023406,
  },
  {
    name: "Willowbrook",
    state_id: "IL",
    lat: 41.7635,
    lng: -87.9456,
    id: 1840011409,
  },
  {
    name: "Springfield",
    state_id: "FL",
    lat: 30.1713,
    lng: -85.6089,
    id: 1840015923,
  },
  {
    name: "Green Cove Springs",
    state_id: "FL",
    lat: 29.9904,
    lng: -81.6807,
    id: 1840013999,
  },
  {
    name: "White Horse",
    state_id: "NJ",
    lat: 40.192,
    lng: -74.7022,
    id: 1840005475,
  },
  {
    name: "Perry Heights",
    state_id: "OH",
    lat: 40.7977,
    lng: -81.468,
    id: 1840005201,
  },
  {
    name: "Lake Villa",
    state_id: "IL",
    lat: 42.4184,
    lng: -88.0836,
    id: 1840011159,
  },
  {
    name: "Guntersville",
    state_id: "AL",
    lat: 34.367,
    lng: -86.2638,
    id: 1840006456,
  },
  {
    name: "Montvale",
    state_id: "NJ",
    lat: 41.0529,
    lng: -74.0499,
    id: 1840000906,
  },
  {
    name: "Aspen",
    state_id: "CO",
    lat: 39.195,
    lng: -106.8369,
    id: 1840018813,
  },
  {
    name: "Ione",
    state_id: "CA",
    lat: 38.3613,
    lng: -120.9422,
    id: 1840018851,
  },
  {
    name: "Brooksville",
    state_id: "FL",
    lat: 28.5404,
    lng: -82.3903,
    id: 1840014112,
  },
  {
    name: "Riverside",
    state_id: "IL",
    lat: 41.831,
    lng: -87.8169,
    id: 1840011287,
  },
  {
    name: "Wyoming",
    state_id: "OH",
    lat: 39.2297,
    lng: -84.4816,
    id: 1840001619,
  },
  {
    name: "West Frankfort",
    state_id: "IL",
    lat: 37.8997,
    lng: -88.9301,
    id: 1840010833,
  },
  {
    name: "Indian Harbour Beach",
    state_id: "FL",
    lat: 28.153,
    lng: -80.5976,
    id: 1840015096,
  },
  {
    name: "Lake Park",
    state_id: "FL",
    lat: 26.7998,
    lng: -80.068,
    id: 1840017258,
  },
  {
    name: "Lucas",
    state_id: "TX",
    lat: 33.0942,
    lng: -96.5803,
    id: 1840020656,
  },
  {
    name: "Piedmont",
    state_id: "OK",
    lat: 35.6784,
    lng: -97.7528,
    id: 1840020422,
  },
  {
    name: "Franklin",
    state_id: "VA",
    lat: 36.6831,
    lng: -76.9386,
    id: 1840003876,
  },
  {
    name: "Conover",
    state_id: "NC",
    lat: 35.7155,
    lng: -81.217,
    id: 1840014522,
  },
  {
    name: "Wetumpka",
    state_id: "AL",
    lat: 32.5407,
    lng: -86.2052,
    id: 1840006013,
  },
  {
    name: "East Grand Forks",
    state_id: "MN",
    lat: 47.9284,
    lng: -97.0138,
    id: 1840007700,
  },
  {
    name: "Park Hills",
    state_id: "MO",
    lat: 37.8211,
    lng: -90.505,
    id: 1840009857,
  },
  {
    name: "Laurel",
    state_id: "MT",
    lat: 45.6735,
    lng: -108.7707,
    id: 1840019900,
  },
  {
    name: "Edgemere",
    state_id: "MD",
    lat: 39.2273,
    lng: -76.459,
    id: 1840005674,
  },
  {
    name: "Morrisville",
    state_id: "PA",
    lat: 40.2074,
    lng: -74.78,
    id: 1840001326,
  },
  {
    name: "Rawlins",
    state_id: "WY",
    lat: 41.7849,
    lng: -107.2265,
    id: 1840020094,
  },
  {
    name: "Succasunna",
    state_id: "NJ",
    lat: 40.8507,
    lng: -74.6596,
    id: 1840024235,
  },
  {
    name: "Perryton",
    state_id: "TX",
    lat: 36.393,
    lng: -100.7976,
    id: 1840020379,
  },
  {
    name: "Closter",
    state_id: "NJ",
    lat: 40.9733,
    lng: -73.9604,
    id: 1840003538,
  },
  {
    name: "St. Johns",
    state_id: "MI",
    lat: 43.0005,
    lng: -84.5555,
    id: 1840003076,
  },
  {
    name: "Terrace Heights",
    state_id: "WA",
    lat: 46.6024,
    lng: -120.4482,
    id: 1840018465,
  },
  {
    name: "Frostburg",
    state_id: "MD",
    lat: 39.6506,
    lng: -78.9269,
    id: 1840005622,
  },
  {
    name: "Rolesville",
    state_id: "NC",
    lat: 35.9224,
    lng: -78.4656,
    id: 1840017759,
  },
  {
    name: "Booneville",
    state_id: "MS",
    lat: 34.6643,
    lng: -88.5684,
    id: 1840013591,
  },
  {
    name: "Sallisaw",
    state_id: "OK",
    lat: 35.4605,
    lng: -94.807,
    id: 1840021749,
  },
  {
    name: "East York",
    state_id: "PA",
    lat: 39.9687,
    lng: -76.6759,
    id: 1840034943,
  },
  {
    name: "Sitka",
    state_id: "AK",
    lat: 57.2401,
    lng: -135.3153,
    id: 1840023319,
  },
  {
    name: "Stuttgart",
    state_id: "AR",
    lat: 34.495,
    lng: -91.5485,
    id: 1840015556,
  },
  {
    name: "Malverne",
    state_id: "NY",
    lat: 40.6746,
    lng: -73.6721,
    id: 1840005305,
  },
  {
    name: "Mystic Island",
    state_id: "NJ",
    lat: 39.5659,
    lng: -74.3831,
    id: 1840005530,
  },
  {
    name: "Willow Street",
    state_id: "PA",
    lat: 39.9809,
    lng: -76.2705,
    id: 1840005494,
  },
  {
    name: "Lansdowne",
    state_id: "MD",
    lat: 39.2365,
    lng: -76.6659,
    id: 1840026625,
  },
  {
    name: "Hillsboro",
    state_id: "TX",
    lat: 32.0091,
    lng: -97.1151,
    id: 1840020783,
  },
  {
    name: "Summit View",
    state_id: "WA",
    lat: 47.1343,
    lng: -122.3468,
    id: 1840042106,
  },
  {
    name: "Corona de Tucson",
    state_id: "AZ",
    lat: 31.9495,
    lng: -110.7836,
    id: 1840018106,
  },
  {
    name: "Middleton",
    state_id: "ID",
    lat: 43.7114,
    lng: -116.6155,
    id: 1840020040,
  },
  {
    name: "Tuscumbia",
    state_id: "AL",
    lat: 34.7204,
    lng: -87.7035,
    id: 1840005567,
  },
  {
    name: "Mason",
    state_id: "MI",
    lat: 42.5806,
    lng: -84.4427,
    id: 1840003104,
  },
  {
    name: "Humboldt",
    state_id: "TN",
    lat: 35.8254,
    lng: -88.9043,
    id: 1840014484,
  },
  {
    name: "Helena Valley Southeast",
    state_id: "MT",
    lat: 46.6175,
    lng: -111.9186,
    id: 1840074075,
  },
  {
    name: "Sunset Hills",
    state_id: "MO",
    lat: 38.531,
    lng: -90.4087,
    id: 1840009742,
  },
  {
    name: "University of Virginia",
    state_id: "VA",
    lat: 38.0405,
    lng: -78.5163,
    id: 1840140574,
  },
  {
    name: "Prien",
    state_id: "LA",
    lat: 30.1601,
    lng: -93.2611,
    id: 1840013963,
  },
  {
    name: "Onalaska",
    state_id: "TX",
    lat: 30.8209,
    lng: -95.1111,
    id: 1840020858,
  },
  {
    name: "Pine Lake Park",
    state_id: "NJ",
    lat: 40.0017,
    lng: -74.2595,
    id: 1840024354,
  },
  {
    name: "Los Altos Hills",
    state_id: "CA",
    lat: 37.3671,
    lng: -122.139,
    id: 1840021574,
  },
  {
    name: "Santa Clara",
    state_id: "UT",
    lat: 37.1311,
    lng: -113.6561,
    id: 1840021560,
  },
  {
    name: "Monticello",
    state_id: "IN",
    lat: 40.7455,
    lng: -86.7669,
    id: 1840008296,
  },
  {
    name: "Ogden",
    state_id: "NC",
    lat: 34.2656,
    lng: -77.7965,
    id: 1840013633,
  },
  {
    name: "Lake of the Woods",
    state_id: "VA",
    lat: 38.3342,
    lng: -77.7599,
    id: 1840042948,
  },
  {
    name: "Congers",
    state_id: "NY",
    lat: 41.1484,
    lng: -73.9456,
    id: 1840004960,
  },
  {
    name: "Ashland",
    state_id: "NJ",
    lat: 39.8782,
    lng: -75.0085,
    id: 1840033467,
  },
  {
    name: "August",
    state_id: "CA",
    lat: 37.9797,
    lng: -121.2625,
    id: 1840028357,
  },
  {
    name: "Makaha",
    state_id: "HI",
    lat: 21.4734,
    lng: -158.2103,
    id: 1840029523,
  },
  {
    name: "Spring Valley Lake",
    state_id: "CA",
    lat: 34.4987,
    lng: -117.2683,
    id: 1840024936,
  },
  {
    name: "Bayport",
    state_id: "NY",
    lat: 40.746,
    lng: -73.0546,
    id: 1840005018,
  },
  {
    name: "Savoy",
    state_id: "IL",
    lat: 40.06,
    lng: -88.2552,
    id: 1840012236,
  },
  {
    name: "Fair Lakes",
    state_id: "VA",
    lat: 38.853,
    lng: -77.3885,
    id: 1840041732,
  },
  {
    name: "Tecumseh",
    state_id: "MI",
    lat: 42.0065,
    lng: -83.945,
    id: 1840003228,
  },
  {
    name: "North Haledon",
    state_id: "NJ",
    lat: 40.9628,
    lng: -74.1844,
    id: 1840000836,
  },
  {
    name: "Paris",
    state_id: "IL",
    lat: 39.6149,
    lng: -87.6904,
    id: 1840009542,
  },
  {
    name: "Gaylord",
    state_id: "MI",
    lat: 45.0213,
    lng: -84.6803,
    id: 1840002148,
  },
  {
    name: "Aransas Pass",
    state_id: "TX",
    lat: 27.8877,
    lng: -97.1134,
    id: 1840019708,
  },
  {
    name: "Carbondale",
    state_id: "PA",
    lat: 41.5714,
    lng: -75.5048,
    id: 1840003376,
  },
  {
    name: "Arab",
    state_id: "AL",
    lat: 34.3309,
    lng: -86.4991,
    id: 1840013600,
  },
  {
    name: "Long Branch",
    state_id: "VA",
    lat: 38.8302,
    lng: -77.2713,
    id: 1840041774,
  },
  {
    name: "Fairless Hills",
    state_id: "PA",
    lat: 40.1784,
    lng: -74.8524,
    id: 1840001307,
  },
  {
    name: "Highland Heights",
    state_id: "OH",
    lat: 41.5518,
    lng: -81.4691,
    id: 1840000605,
  },
  {
    name: "Somers",
    state_id: "WI",
    lat: 42.6411,
    lng: -87.8919,
    id: 1840038024,
  },
  {
    name: "Charlestown",
    state_id: "IN",
    lat: 38.427,
    lng: -85.6677,
    id: 1840007489,
  },
  {
    name: "Fletcher",
    state_id: "NC",
    lat: 35.4316,
    lng: -82.5038,
    id: 1840016371,
  },
  {
    name: "Druid Hills",
    state_id: "GA",
    lat: 33.7842,
    lng: -84.3273,
    id: 1840013092,
  },
  {
    name: "Baxter",
    state_id: "MN",
    lat: 46.3426,
    lng: -94.2793,
    id: 1840006643,
  },
  {
    name: "Seffner",
    state_id: "FL",
    lat: 27.9981,
    lng: -82.2735,
    id: 1840014153,
  },
  {
    name: "Millersville",
    state_id: "PA",
    lat: 40.0047,
    lng: -76.3522,
    id: 1840003722,
  },
  {
    name: "Wimauma",
    state_id: "FL",
    lat: 27.6964,
    lng: -82.3034,
    id: 1840014155,
  },
  {
    name: "Miramar Beach",
    state_id: "FL",
    lat: 30.3854,
    lng: -86.3443,
    id: 1840013925,
  },
  {
    name: "Hempstead",
    state_id: "TX",
    lat: 30.1004,
    lng: -96.0779,
    id: 1840020913,
  },
  {
    name: "Gulf Hills",
    state_id: "MS",
    lat: 30.4365,
    lng: -88.8149,
    id: 1840013930,
  },
  {
    name: "Buckhannon",
    state_id: "WV",
    lat: 38.9927,
    lng: -80.2282,
    id: 1840005997,
  },
  {
    name: "Kulpsville",
    state_id: "PA",
    lat: 40.244,
    lng: -75.3407,
    id: 1840005465,
  },
  {
    name: "Socorro",
    state_id: "NM",
    lat: 34.0543,
    lng: -106.9066,
    id: 1840021898,
  },
  {
    name: "West Livingston",
    state_id: "TX",
    lat: 30.6957,
    lng: -95.0097,
    id: 1840019561,
  },
  {
    name: "Tallulah",
    state_id: "LA",
    lat: 32.4067,
    lng: -91.1915,
    id: 1840015808,
  },
  {
    name: "Midway City",
    state_id: "CA",
    lat: 33.7451,
    lng: -117.9849,
    id: 1840025037,
  },
  {
    name: "Orono",
    state_id: "MN",
    lat: 44.9657,
    lng: -93.5908,
    id: 1840008927,
  },
  {
    name: "Bath",
    state_id: "ME",
    lat: 43.9346,
    lng: -69.8346,
    id: 1840000332,
  },
  {
    name: "Clover",
    state_id: "SC",
    lat: 35.1125,
    lng: -81.2203,
    id: 1840016478,
  },
  {
    name: "Bogota",
    state_id: "NJ",
    lat: 40.875,
    lng: -74.0293,
    id: 1840000894,
  },
  {
    name: "Garden City Park",
    state_id: "NY",
    lat: 40.7436,
    lng: -73.6639,
    id: 1840005257,
  },
  {
    name: "Petoskey",
    state_id: "MI",
    lat: 45.365,
    lng: -84.9887,
    id: 1840003941,
  },
  {
    name: "Grandview Heights",
    state_id: "OH",
    lat: 39.9793,
    lng: -83.0408,
    id: 1840003761,
  },
  {
    name: "Harwood Heights",
    state_id: "IL",
    lat: 41.9663,
    lng: -87.8057,
    id: 1840011252,
  },
  {
    name: "Beaver Falls",
    state_id: "PA",
    lat: 40.762,
    lng: -80.3225,
    id: 1840001013,
  },
  {
    name: "Tillamook",
    state_id: "OR",
    lat: 45.4562,
    lng: -123.8331,
    id: 1840021203,
  },
  {
    name: "Oak Hills Place",
    state_id: "LA",
    lat: 30.369,
    lng: -91.0887,
    id: 1840031107,
  },
  {
    name: "Westwego",
    state_id: "LA",
    lat: 29.9058,
    lng: -90.1434,
    id: 1840015937,
  },
  {
    name: "Flowery Branch",
    state_id: "GA",
    lat: 34.1713,
    lng: -83.9142,
    id: 1840013611,
  },
  {
    name: "St. Joseph",
    state_id: "MI",
    lat: 42.0967,
    lng: -86.485,
    id: 1840003213,
  },
  {
    name: "Holly",
    state_id: "MI",
    lat: 42.7987,
    lng: -83.6235,
    id: 1840011056,
  },
  {
    name: "Quincy",
    state_id: "WA",
    lat: 47.2344,
    lng: -119.8531,
    id: 1840019818,
  },
  {
    name: "Larkfield-Wikiup",
    state_id: "CA",
    lat: 38.513,
    lng: -122.7536,
    id: 1840074293,
  },
  {
    name: "Frostproof",
    state_id: "FL",
    lat: 27.7493,
    lng: -81.5252,
    id: 1840014135,
  },
  {
    name: "Dalhart",
    state_id: "TX",
    lat: 36.0579,
    lng: -102.5123,
    id: 1840019052,
  },
  {
    name: "Garrison",
    state_id: "MD",
    lat: 39.4023,
    lng: -76.7514,
    id: 1840005691,
  },
  {
    name: "Gonzales",
    state_id: "CA",
    lat: 36.506,
    lng: -121.4429,
    id: 1840020355,
  },
  {
    name: "Orosi",
    state_id: "CA",
    lat: 36.5434,
    lng: -119.2903,
    id: 1840019035,
  },
  {
    name: "Runnemede",
    state_id: "NJ",
    lat: 39.8521,
    lng: -75.0739,
    id: 1840001511,
  },
  {
    name: "Bel Aire",
    state_id: "KS",
    lat: 37.7749,
    lng: -97.2457,
    id: 1840045965,
  },
  {
    name: "Milton",
    state_id: "WA",
    lat: 47.2522,
    lng: -122.3156,
    id: 1840019857,
  },
  {
    name: "Aurora",
    state_id: "MO",
    lat: 36.9674,
    lng: -93.7183,
    id: 1840007636,
  },
  {
    name: "Firebaugh",
    state_id: "CA",
    lat: 36.8534,
    lng: -120.4536,
    id: 1840020317,
  },
  {
    name: "Whitefish",
    state_id: "MT",
    lat: 48.4329,
    lng: -114.3591,
    id: 1840021047,
  },
  {
    name: "Mahtomedi",
    state_id: "MN",
    lat: 45.0619,
    lng: -92.966,
    id: 1840008913,
  },
  {
    name: "Haledon",
    state_id: "NJ",
    lat: 40.9363,
    lng: -74.1887,
    id: 1840003512,
  },
  {
    name: "West Vero Corridor",
    state_id: "FL",
    lat: 27.6378,
    lng: -80.4855,
    id: 1840073271,
  },
  {
    name: "Floris",
    state_id: "VA",
    lat: 38.9347,
    lng: -77.4083,
    id: 1840024569,
  },
  {
    name: "Savannah",
    state_id: "TN",
    lat: 35.221,
    lng: -88.236,
    id: 1840015453,
  },
  {
    name: "Dormont",
    state_id: "PA",
    lat: 40.3941,
    lng: -80.0377,
    id: 1840001222,
  },
  {
    name: "Mount Joy",
    state_id: "PA",
    lat: 40.1105,
    lng: -76.5065,
    id: 1840003723,
  },
  {
    name: "McRae-Helena",
    state_id: "GA",
    lat: 32.0635,
    lng: -82.8968,
    id: 1840043457,
  },
  {
    name: "Trinidad",
    state_id: "CO",
    lat: 37.175,
    lng: -104.4908,
    id: 1840021545,
  },
  {
    name: "Kenton",
    state_id: "OH",
    lat: 40.6448,
    lng: -83.6095,
    id: 1840008320,
  },
  {
    name: "Carl Junction",
    state_id: "MO",
    lat: 37.1668,
    lng: -94.5468,
    id: 1840007627,
  },
  {
    name: "Alondra Park",
    state_id: "CA",
    lat: 33.8885,
    lng: -118.335,
    id: 1840028356,
  },
  {
    name: "Southeast Arcadia",
    state_id: "FL",
    lat: 27.1862,
    lng: -81.8521,
    id: 1840035778,
  },
  {
    name: "Paw Paw",
    state_id: "MI",
    lat: 42.2147,
    lng: -85.8918,
    id: 1840011208,
  },
  {
    name: "Lake City",
    state_id: "SC",
    lat: 33.8676,
    lng: -79.7533,
    id: 1840014715,
  },
  {
    name: "Colorado City",
    state_id: "AZ",
    lat: 36.9774,
    lng: -112.983,
    id: 1840023170,
  },
  {
    name: "Oak Grove",
    state_id: "MO",
    lat: 39.0077,
    lng: -94.1283,
    id: 1840008533,
  },
  {
    name: "Moundsville",
    state_id: "WV",
    lat: 39.9221,
    lng: -80.7422,
    id: 1840005557,
  },
  {
    name: "Cumberland Hill",
    state_id: "RI",
    lat: 41.9736,
    lng: -71.4605,
    id: 1840003291,
  },
  {
    name: "Four Corners",
    state_id: "MD",
    lat: 39.0235,
    lng: -77.0102,
    id: 1840024489,
  },
  {
    name: "Montecito",
    state_id: "CA",
    lat: 34.4382,
    lng: -119.6286,
    id: 1840019182,
  },
  {
    name: "Denison",
    state_id: "IA",
    lat: 42.016,
    lng: -95.3528,
    id: 1840007008,
  },
  {
    name: "Locust Grove",
    state_id: "GA",
    lat: 33.3442,
    lng: -84.1071,
    id: 1840015705,
  },
  {
    name: "Half Moon",
    state_id: "NC",
    lat: 34.8298,
    lng: -77.4591,
    id: 1840013556,
  },
  {
    name: "Lugoff",
    state_id: "SC",
    lat: 34.2216,
    lng: -80.6849,
    id: 1840013597,
  },
  {
    name: "Fort Mitchell",
    state_id: "KY",
    lat: 39.0459,
    lng: -84.5563,
    id: 1840013170,
  },
  {
    name: "Manhattan",
    state_id: "IL",
    lat: 41.4274,
    lng: -87.9805,
    id: 1840011490,
  },
  {
    name: "North Weeki Wachee",
    state_id: "FL",
    lat: 28.5591,
    lng: -82.5537,
    id: 1840073872,
  },
  {
    name: "Mammoth Lakes",
    state_id: "CA",
    lat: 37.6273,
    lng: -118.99,
    id: 1840022493,
  },
  {
    name: "Johnstown",
    state_id: "NY",
    lat: 43.0073,
    lng: -74.3755,
    id: 1840000376,
  },
  {
    name: "Spotswood",
    state_id: "NJ",
    lat: 40.3949,
    lng: -74.392,
    id: 1840001336,
  },
  {
    name: "Waynesburg",
    state_id: "PA",
    lat: 39.8983,
    lng: -80.1855,
    id: 1840000722,
  },
  {
    name: "Thomson",
    state_id: "GA",
    lat: 33.4666,
    lng: -82.4992,
    id: 1840015703,
  },
  {
    name: "Irondale",
    state_id: "GA",
    lat: 33.4773,
    lng: -84.36,
    id: 1840029456,
  },
  {
    name: "Westlake Village",
    state_id: "CA",
    lat: 34.1369,
    lng: -118.822,
    id: 1840037458,
  },
  {
    name: "Castle Shannon",
    state_id: "PA",
    lat: 40.3664,
    lng: -80.0194,
    id: 1840001215,
  },
  {
    name: "Chester",
    state_id: "IL",
    lat: 37.9199,
    lng: -89.8259,
    id: 1840007529,
  },
  {
    name: "Cheviot",
    state_id: "OH",
    lat: 39.1577,
    lng: -84.6139,
    id: 1840003813,
  },
  {
    name: "Napoleon",
    state_id: "OH",
    lat: 41.3978,
    lng: -84.1244,
    id: 1840008242,
  },
  {
    name: "Upper Saddle River",
    state_id: "NJ",
    lat: 41.0633,
    lng: -74.0986,
    id: 1840000920,
  },
  {
    name: "Mahopac",
    state_id: "NY",
    lat: 41.3684,
    lng: -73.7401,
    id: 1840004891,
  },
  {
    name: "Temperance",
    state_id: "MI",
    lat: 41.7653,
    lng: -83.5755,
    id: 1840006579,
  },
  {
    name: "Oberlin",
    state_id: "OH",
    lat: 41.2857,
    lng: -82.2197,
    id: 1840008209,
  },
  {
    name: "Twin Lakes",
    state_id: "CO",
    lat: 39.8229,
    lng: -105.0036,
    id: 1840136771,
  },
  {
    name: "Lake Norman of Catawba",
    state_id: "NC",
    lat: 35.5995,
    lng: -80.984,
    id: 1840073862,
  },
  {
    name: "Jefferson City",
    state_id: "TN",
    lat: 36.1197,
    lng: -83.4838,
    id: 1840014485,
  },
  {
    name: "Country Club Estates",
    state_id: "GA",
    lat: 31.2113,
    lng: -81.4622,
    id: 1840029452,
  },
  {
    name: "Kenilworth",
    state_id: "NJ",
    lat: 40.6781,
    lng: -74.2889,
    id: 1840003616,
  },
  {
    name: "Ellsworth",
    state_id: "ME",
    lat: 44.5847,
    lng: -68.4875,
    id: 1840000281,
  },
  {
    name: "Wedgefield",
    state_id: "FL",
    lat: 28.4847,
    lng: -81.0808,
    id: 1840014091,
  },
  {
    name: "Monona",
    state_id: "WI",
    lat: 43.054,
    lng: -89.3334,
    id: 1840002920,
  },
  {
    name: "Center Line",
    state_id: "MI",
    lat: 42.4805,
    lng: -83.0274,
    id: 1840002419,
  },
  {
    name: "Venice Gardens",
    state_id: "FL",
    lat: 27.0694,
    lng: -82.4054,
    id: 1840014182,
  },
  {
    name: "Broadview Park",
    state_id: "FL",
    lat: 26.0979,
    lng: -80.2088,
    id: 1840028918,
  },
  {
    name: "Pismo Beach",
    state_id: "CA",
    lat: 35.1484,
    lng: -120.6492,
    id: 1840020416,
  },
  {
    name: "Beebe",
    state_id: "AR",
    lat: 35.0712,
    lng: -91.8996,
    id: 1840013439,
  },
  {
    name: "Milliken",
    state_id: "CO",
    lat: 40.3115,
    lng: -104.8561,
    id: 1840022397,
  },
  {
    name: "Black Mountain",
    state_id: "NC",
    lat: 35.6142,
    lng: -82.3275,
    id: 1840015392,
  },
  {
    name: "Crestwood Village",
    state_id: "NJ",
    lat: 39.9568,
    lng: -74.3524,
    id: 1840005527,
  },
  {
    name: "Meadow Lakes",
    state_id: "AK",
    lat: 61.638,
    lng: -149.608,
    id: 1840023681,
  },
  {
    name: "Center Moriches",
    state_id: "NY",
    lat: 40.8015,
    lng: -72.796,
    id: 1840005025,
  },
  {
    name: "Port Jefferson",
    state_id: "NY",
    lat: 40.9465,
    lng: -73.0579,
    id: 1840005131,
  },
  {
    name: "Middleport",
    state_id: "OH",
    lat: 38.9948,
    lng: -82.0643,
    id: 1840012749,
  },
  {
    name: "Eaton",
    state_id: "OH",
    lat: 39.7506,
    lng: -84.6343,
    id: 1840001519,
  },
  {
    name: "Tuskegee",
    state_id: "AL",
    lat: 32.4395,
    lng: -85.7139,
    id: 1840004337,
  },
  {
    name: "South San Gabriel",
    state_id: "CA",
    lat: 34.0489,
    lng: -118.0961,
    id: 1840019207,
  },
  {
    name: "Ephrata",
    state_id: "WA",
    lat: 47.3122,
    lng: -119.534,
    id: 1840019813,
  },
  {
    name: "Rockwood",
    state_id: "VA",
    lat: 37.463,
    lng: -77.5744,
    id: 1840041858,
  },
  {
    name: "Leesville",
    state_id: "LA",
    lat: 31.1397,
    lng: -93.2741,
    id: 1840014974,
  },
  {
    name: "Oradell",
    state_id: "NJ",
    lat: 40.9562,
    lng: -74.0314,
    id: 1840000914,
  },
  {
    name: "Minnetrista",
    state_id: "MN",
    lat: 44.9356,
    lng: -93.7103,
    id: 1840007833,
  },
  {
    name: "Gering",
    state_id: "NE",
    lat: 41.8275,
    lng: -103.6649,
    id: 1840008139,
  },
  {
    name: "Harrison",
    state_id: "TN",
    lat: 35.1276,
    lng: -85.1464,
    id: 1840013449,
  },
  {
    name: "Hazard",
    state_id: "KY",
    lat: 37.2583,
    lng: -83.1977,
    id: 1840014376,
  },
  {
    name: "Summit Park",
    state_id: "UT",
    lat: 40.7432,
    lng: -111.5814,
    id: 1840018725,
  },
  {
    name: "Cherry Valley",
    state_id: "CA",
    lat: 33.9797,
    lng: -116.9694,
    id: 1840017983,
  },
  {
    name: "Omak",
    state_id: "WA",
    lat: 48.4228,
    lng: -119.5159,
    id: 1840019761,
  },
  {
    name: "Vandenberg Village",
    state_id: "CA",
    lat: 34.7111,
    lng: -120.4623,
    id: 1840019185,
  },
  {
    name: "West Dundee",
    state_id: "IL",
    lat: 42.0985,
    lng: -88.3073,
    id: 1840011357,
  },
  {
    name: "Blandon",
    state_id: "PA",
    lat: 40.4446,
    lng: -75.8799,
    id: 1840026444,
  },
  {
    name: "Duvall",
    state_id: "WA",
    lat: 47.7354,
    lng: -121.9726,
    id: 1840018427,
  },
  {
    name: "Vinton",
    state_id: "VA",
    lat: 37.2746,
    lng: -79.8887,
    id: 1840003856,
  },
  {
    name: "Joshua",
    state_id: "TX",
    lat: 32.458,
    lng: -97.3849,
    id: 1840019468,
  },
  {
    name: "Oak Hill",
    state_id: "WV",
    lat: 37.9844,
    lng: -81.1277,
    id: 1840006297,
  },
  {
    name: "Monument",
    state_id: "CO",
    lat: 39.0735,
    lng: -104.8467,
    id: 1840022474,
  },
  {
    name: "Croton-on-Hudson",
    state_id: "NY",
    lat: 41.2005,
    lng: -73.9002,
    id: 1840004951,
  },
  {
    name: "Bellevue",
    state_id: "OH",
    lat: 41.2743,
    lng: -82.8394,
    id: 1840000654,
  },
  {
    name: "Briarcliff Manor",
    state_id: "NY",
    lat: 41.14,
    lng: -73.844,
    id: 1840004948,
  },
  {
    name: "Mount Rainier",
    state_id: "MD",
    lat: 38.9423,
    lng: -76.9645,
    id: 1840005977,
  },
  {
    name: "Alliance",
    state_id: "NE",
    lat: 42.1025,
    lng: -102.8766,
    id: 1840006975,
  },
  {
    name: "Maplewood",
    state_id: "MO",
    lat: 38.6121,
    lng: -90.324,
    id: 1840009740,
  },
  {
    name: "Wickenburg",
    state_id: "AZ",
    lat: 33.9837,
    lng: -112.7668,
    id: 1840022916,
  },
  {
    name: "Selah",
    state_id: "WA",
    lat: 46.6482,
    lng: -120.539,
    id: 1840021149,
  },
  {
    name: "Shorewood",
    state_id: "MN",
    lat: 44.9033,
    lng: -93.5903,
    id: 1840008925,
  },
  {
    name: "Helena Valley West Central",
    state_id: "MT",
    lat: 46.6634,
    lng: -112.0604,
    id: 1840074081,
  },
  {
    name: "Applewood",
    state_id: "CO",
    lat: 39.7524,
    lng: -105.1605,
    id: 1840028573,
  },
  {
    name: "Kronenwetter",
    state_id: "WI",
    lat: 44.8164,
    lng: -89.5807,
    id: 1840038099,
  },
  {
    name: "Gilberts",
    state_id: "IL",
    lat: 42.1096,
    lng: -88.3716,
    id: 1840011348,
  },
  {
    name: "Bargersville",
    state_id: "IN",
    lat: 39.5412,
    lng: -86.2004,
    id: 1840009569,
  },
  {
    name: "Hampton",
    state_id: "GA",
    lat: 33.3835,
    lng: -84.2855,
    id: 1840013751,
  },
  {
    name: "Sudden Valley",
    state_id: "WA",
    lat: 48.7199,
    lng: -122.3468,
    id: 1840037740,
  },
  {
    name: "Hollymead",
    state_id: "VA",
    lat: 38.1266,
    lng: -78.4386,
    id: 1840024700,
  },
  {
    name: "Rockmart",
    state_id: "GA",
    lat: 34.0103,
    lng: -85.0441,
    id: 1840014753,
  },
  {
    name: "Lake Carmel",
    state_id: "NY",
    lat: 41.4612,
    lng: -73.6681,
    id: 1840004890,
  },
  {
    name: "Lockwood",
    state_id: "MT",
    lat: 45.8199,
    lng: -108.4072,
    id: 1840018492,
  },
  {
    name: "Wyoming",
    state_id: "MN",
    lat: 45.3365,
    lng: -92.9766,
    id: 1840009992,
  },
  {
    name: "Lake Dallas",
    state_id: "TX",
    lat: 33.1277,
    lng: -97.0234,
    id: 1840020646,
  },
  {
    name: "Bloomingdale",
    state_id: "NJ",
    lat: 41.03,
    lng: -74.3319,
    id: 1840000832,
  },
  {
    name: "Rolling Hills Estates",
    state_id: "CA",
    lat: 33.7783,
    lng: -118.3509,
    id: 1840020494,
  },
  {
    name: "North Bay Village",
    state_id: "FL",
    lat: 25.8487,
    lng: -80.1535,
    id: 1840015153,
  },
  {
    name: "South Cleveland",
    state_id: "TN",
    lat: 35.1097,
    lng: -84.9097,
    id: 1840036101,
  },
  {
    name: "Willits",
    state_id: "CA",
    lat: 39.4047,
    lng: -123.3494,
    id: 1840021421,
  },
  {
    name: "Clinton",
    state_id: "IL",
    lat: 40.1468,
    lng: -88.9633,
    id: 1840007255,
  },
  {
    name: "Skiatook",
    state_id: "OK",
    lat: 36.3694,
    lng: -95.9819,
    id: 1840022597,
  },
  {
    name: "Decorah",
    state_id: "IA",
    lat: 43.3015,
    lng: -91.7844,
    id: 1840006864,
  },
  {
    name: "Brookhaven",
    state_id: "PA",
    lat: 39.8715,
    lng: -75.3918,
    id: 1840000689,
  },
  {
    name: "Diamondhead",
    state_id: "MS",
    lat: 30.3791,
    lng: -89.3708,
    id: 1840013106,
  },
  {
    name: "Conshohocken",
    state_id: "PA",
    lat: 40.0772,
    lng: -75.3035,
    id: 1840003697,
  },
  {
    name: "Floresville",
    state_id: "TX",
    lat: 29.14,
    lng: -98.163,
    id: 1840020981,
  },
  {
    name: "Ripley",
    state_id: "TN",
    lat: 35.7449,
    lng: -89.5358,
    id: 1840014509,
  },
  {
    name: "Chardon",
    state_id: "OH",
    lat: 41.5803,
    lng: -81.2082,
    id: 1840010219,
  },
  {
    name: "Morehead",
    state_id: "KY",
    lat: 38.1906,
    lng: -83.4467,
    id: 1840014291,
  },
  {
    name: "Ripon",
    state_id: "WI",
    lat: 43.8436,
    lng: -88.8387,
    id: 1840002609,
  },
  {
    name: "Odessa",
    state_id: "FL",
    lat: 28.182,
    lng: -82.553,
    id: 1840014121,
  },
  {
    name: "Bellevue",
    state_id: "PA",
    lat: 40.4945,
    lng: -80.055,
    id: 1840001203,
  },
  {
    name: "Mayo",
    state_id: "MD",
    lat: 38.9041,
    lng: -76.518,
    id: 1840005923,
  },
  {
    name: "Northfield",
    state_id: "NJ",
    lat: 39.3718,
    lng: -74.5543,
    id: 1840001538,
  },
  {
    name: "Menominee",
    state_id: "MI",
    lat: 45.122,
    lng: -87.6234,
    id: 1840002006,
  },
  {
    name: "Pleasant Hills",
    state_id: "PA",
    lat: 40.3298,
    lng: -79.9596,
    id: 1840001255,
  },
  {
    name: "Pahokee",
    state_id: "FL",
    lat: 26.8202,
    lng: -80.662,
    id: 1840015124,
  },
  {
    name: "Ahuimanu",
    state_id: "HI",
    lat: 21.4379,
    lng: -157.8404,
    id: 1840029468,
  },
  {
    name: "Crete",
    state_id: "IL",
    lat: 41.44,
    lng: -87.6236,
    id: 1840011484,
  },
  {
    name: "Loudoun Valley Estates",
    state_id: "VA",
    lat: 38.9801,
    lng: -77.5082,
    id: 1840041775,
  },
  {
    name: "Chestnut Ridge",
    state_id: "NY",
    lat: 41.0829,
    lng: -74.0551,
    id: 1840004992,
  },
  {
    name: "Laurel Hill",
    state_id: "VA",
    lat: 38.7026,
    lng: -77.2422,
    id: 1840025639,
  },
  {
    name: "Island Lake",
    state_id: "IL",
    lat: 42.2783,
    lng: -88.1999,
    id: 1840011155,
  },
  {
    name: "Oak Brook",
    state_id: "IL",
    lat: 41.8372,
    lng: -87.9513,
    id: 1840011408,
  },
  {
    name: "Seaford",
    state_id: "DE",
    lat: 38.6538,
    lng: -75.611,
    id: 1840006071,
  },
  {
    name: "Evergreen",
    state_id: "MT",
    lat: 48.2308,
    lng: -114.2701,
    id: 1840017297,
  },
  {
    name: "Mascoutah",
    state_id: "IL",
    lat: 38.5192,
    lng: -89.8044,
    id: 1840008631,
  },
  {
    name: "Brazil",
    state_id: "IN",
    lat: 39.5226,
    lng: -87.124,
    id: 1840005744,
  },
  {
    name: "Aberdeen",
    state_id: "NC",
    lat: 35.135,
    lng: -79.4326,
    id: 1840015433,
  },
  {
    name: "Jennings Lodge",
    state_id: "OR",
    lat: 45.3926,
    lng: -122.6153,
    id: 1840017419,
  },
  {
    name: "Jefferson",
    state_id: "WI",
    lat: 43.0044,
    lng: -88.8084,
    id: 1840003001,
  },
  {
    name: "Paloma Creek South",
    state_id: "TX",
    lat: 33.21,
    lng: -96.9327,
    id: 1840041381,
  },
  {
    name: "Manhasset",
    state_id: "NY",
    lat: 40.7884,
    lng: -73.6943,
    id: 1840005273,
  },
  {
    name: "Green",
    state_id: "OR",
    lat: 43.1509,
    lng: -123.3854,
    id: 1840017439,
  },
  {
    name: "Lampasas",
    state_id: "TX",
    lat: 31.064,
    lng: -98.1824,
    id: 1840020846,
  },
  {
    name: "Village Green-Green Ridge",
    state_id: "PA",
    lat: 39.8638,
    lng: -75.4256,
    id: 1840073727,
  },
  {
    name: "Kensington",
    state_id: "CT",
    lat: 41.6298,
    lng: -72.7714,
    id: 1840000505,
  },
  {
    name: "Fort Belvoir",
    state_id: "VA",
    lat: 38.7119,
    lng: -77.1459,
    id: 1840073578,
  },
  {
    name: "Brentwood",
    state_id: "MO",
    lat: 38.6194,
    lng: -90.3476,
    id: 1840006128,
  },
  {
    name: "Laughlin",
    state_id: "NV",
    lat: 35.1316,
    lng: -114.689,
    id: 1840017689,
  },
  {
    name: "Aztec",
    state_id: "NM",
    lat: 36.819,
    lng: -107.9826,
    id: 1840018993,
  },
  {
    name: "St. Martin",
    state_id: "MS",
    lat: 30.4399,
    lng: -88.8651,
    id: 1840032563,
  },
  {
    name: "La Junta",
    state_id: "CO",
    lat: 37.9792,
    lng: -103.5473,
    id: 1840020270,
  },
  {
    name: "Murphy",
    state_id: "MO",
    lat: 38.4922,
    lng: -90.4856,
    id: 1840006236,
  },
  {
    name: "Southwest Ranches",
    state_id: "FL",
    lat: 26.0476,
    lng: -80.375,
    id: 1840018328,
  },
  {
    name: "Quincy",
    state_id: "FL",
    lat: 30.5659,
    lng: -84.5857,
    id: 1840015022,
  },
  {
    name: "Richland Hills",
    state_id: "TX",
    lat: 32.8095,
    lng: -97.2273,
    id: 1840020700,
  },
  {
    name: "Maryville",
    state_id: "IL",
    lat: 38.7261,
    lng: -89.9646,
    id: 1840012801,
  },
  {
    name: "Baltimore Highlands",
    state_id: "MD",
    lat: 39.2355,
    lng: -76.6367,
    id: 1840024447,
  },
  {
    name: "Caldwell",
    state_id: "NJ",
    lat: 40.839,
    lng: -74.2776,
    id: 1840005354,
  },
  {
    name: "Hillside",
    state_id: "IL",
    lat: 41.8674,
    lng: -87.9019,
    id: 1840011261,
  },
  {
    name: "Columbiana",
    state_id: "OH",
    lat: 40.8871,
    lng: -80.675,
    id: 1840007175,
  },
  {
    name: "Logan",
    state_id: "OH",
    lat: 39.5386,
    lng: -82.4063,
    id: 1840009566,
  },
  {
    name: "Batesville",
    state_id: "IN",
    lat: 39.2974,
    lng: -85.2139,
    id: 1840007379,
  },
  {
    name: "Glastonbury Center",
    state_id: "CT",
    lat: 41.7019,
    lng: -72.6001,
    id: 1840000503,
  },
  {
    name: "Sheboygan Falls",
    state_id: "WI",
    lat: 43.729,
    lng: -87.8266,
    id: 1840002629,
  },
  {
    name: "Temple Hills",
    state_id: "MD",
    lat: 38.8106,
    lng: -76.9495,
    id: 1840005967,
  },
  {
    name: "Wytheville",
    state_id: "VA",
    lat: 36.953,
    lng: -81.0881,
    id: 1840006504,
  },
  {
    name: "St. Francis",
    state_id: "MN",
    lat: 45.3991,
    lng: -93.3902,
    id: 1840008905,
  },
  {
    name: "Hitchcock",
    state_id: "TX",
    lat: 29.2945,
    lng: -95.025,
    id: 1840020971,
  },
  {
    name: "Catalina",
    state_id: "AZ",
    lat: 32.4848,
    lng: -110.8998,
    id: 1840018109,
  },
  {
    name: "Bonner Springs",
    state_id: "KS",
    lat: 39.0817,
    lng: -94.8776,
    id: 1840001624,
  },
  {
    name: "Long Grove",
    state_id: "IL",
    lat: 42.1968,
    lng: -88.0057,
    id: 1840011164,
  },
  {
    name: "Camp Hill",
    state_id: "PA",
    lat: 40.2423,
    lng: -76.9274,
    id: 1840001385,
  },
  {
    name: "Mansfield Center",
    state_id: "MA",
    lat: 42.0225,
    lng: -71.218,
    id: 1840073506,
  },
  {
    name: "Wilmington Manor",
    state_id: "DE",
    lat: 39.6858,
    lng: -75.5849,
    id: 1840005575,
  },
  {
    name: "Topanga",
    state_id: "CA",
    lat: 34.0964,
    lng: -118.6053,
    id: 1840025006,
  },
  {
    name: "Downingtown",
    state_id: "PA",
    lat: 40.0076,
    lng: -75.7019,
    id: 1840001425,
  },
  {
    name: "Grand Ledge",
    state_id: "MI",
    lat: 42.7534,
    lng: -84.7448,
    id: 1840003109,
  },
  {
    name: "Old Forge",
    state_id: "PA",
    lat: 41.3705,
    lng: -75.7409,
    id: 1840003387,
  },
  {
    name: "Lincolnshire",
    state_id: "IL",
    lat: 42.1957,
    lng: -87.9182,
    id: 1840011162,
  },
  {
    name: "Blair",
    state_id: "NE",
    lat: 41.5417,
    lng: -96.1361,
    id: 1840007094,
  },
  {
    name: "Jersey Village",
    state_id: "TX",
    lat: 29.8903,
    lng: -95.5721,
    id: 1840019620,
  },
  {
    name: "Lexington",
    state_id: "TN",
    lat: 35.6618,
    lng: -88.3945,
    id: 1840014527,
  },
  {
    name: "Richfield",
    state_id: "UT",
    lat: 38.7628,
    lng: -112.0926,
    id: 1840020239,
  },
  {
    name: "Centerville",
    state_id: "GA",
    lat: 32.6342,
    lng: -83.6853,
    id: 1840014884,
  },
  {
    name: "West Long Branch",
    state_id: "NJ",
    lat: 40.2883,
    lng: -74.0185,
    id: 1840001370,
  },
  {
    name: "Flushing",
    state_id: "MI",
    lat: 43.0637,
    lng: -83.8403,
    id: 1840002950,
  },
  {
    name: "Middletown",
    state_id: "KY",
    lat: 38.241,
    lng: -85.5215,
    id: 1840014295,
  },
  {
    name: "Ashland",
    state_id: "VA",
    lat: 37.7596,
    lng: -77.4715,
    id: 1840006341,
  },
  {
    name: "Fruitvale",
    state_id: "CO",
    lat: 39.0933,
    lng: -108.4789,
    id: 1840017557,
  },
  {
    name: "Seminole",
    state_id: "TX",
    lat: 32.7208,
    lng: -102.6503,
    id: 1840022061,
  },
  {
    name: "Altoona",
    state_id: "WI",
    lat: 44.8029,
    lng: -91.4385,
    id: 1840002295,
  },
  {
    name: "Grand Blanc",
    state_id: "MI",
    lat: 42.9258,
    lng: -83.6181,
    id: 1840002951,
  },
  {
    name: "Abingdon",
    state_id: "VA",
    lat: 36.7089,
    lng: -81.9713,
    id: 1840006529,
  },
  {
    name: "Bridge City",
    state_id: "TX",
    lat: 30.0298,
    lng: -93.8406,
    id: 1840019609,
  },
  {
    name: "Woodlake",
    state_id: "CA",
    lat: 36.4124,
    lng: -119.0999,
    id: 1840021640,
  },
  {
    name: "Hastings-on-Hudson",
    state_id: "NY",
    lat: 40.9902,
    lng: -73.8801,
    id: 1840004932,
  },
  {
    name: "Bryans Road",
    state_id: "MD",
    lat: 38.6144,
    lng: -77.085,
    id: 1840006172,
  },
  {
    name: "York",
    state_id: "NE",
    lat: 40.8698,
    lng: -97.5928,
    id: 1840010328,
  },
  {
    name: "Ashland",
    state_id: "WI",
    lat: 46.5801,
    lng: -90.8715,
    id: 1840003916,
  },
  {
    name: "Princeton",
    state_id: "IL",
    lat: 41.3807,
    lng: -89.464,
    id: 1840009276,
  },
  {
    name: "Sangaree",
    state_id: "SC",
    lat: 33.0328,
    lng: -80.1253,
    id: 1840035792,
  },
  {
    name: "Templeton",
    state_id: "CA",
    lat: 35.556,
    lng: -120.7182,
    id: 1840019127,
  },
  {
    name: "Los Fresnos",
    state_id: "TX",
    lat: 26.075,
    lng: -97.4877,
    id: 1840021037,
  },
  {
    name: "South Weber",
    state_id: "UT",
    lat: 41.1334,
    lng: -111.9392,
    id: 1840021350,
  },
  {
    name: "Watford City",
    state_id: "ND",
    lat: 47.8028,
    lng: -103.2678,
    id: 1840001892,
  },
  {
    name: "Crookston",
    state_id: "MN",
    lat: 47.7747,
    lng: -96.6062,
    id: 1840006604,
  },
  {
    name: "Madison Park",
    state_id: "NJ",
    lat: 40.4461,
    lng: -74.2959,
    id: 1840005421,
  },
  {
    name: "Covington",
    state_id: "VA",
    lat: 37.7785,
    lng: -79.9868,
    id: 1840001688,
  },
  {
    name: "Latrobe",
    state_id: "PA",
    lat: 40.3125,
    lng: -79.3826,
    id: 1840001142,
  },
  {
    name: "DeFuniak Springs",
    state_id: "FL",
    lat: 30.7123,
    lng: -86.1208,
    id: 1840015011,
  },
  {
    name: "Williamsburg",
    state_id: "FL",
    lat: 28.4015,
    lng: -81.4461,
    id: 1840029100,
  },
  {
    name: "Olivette",
    state_id: "MO",
    lat: 38.6724,
    lng: -90.3786,
    id: 1840009743,
  },
  {
    name: "Baldwinsville",
    state_id: "NY",
    lat: 43.157,
    lng: -76.3318,
    id: 1840004330,
  },
  {
    name: "Sound Beach",
    state_id: "NY",
    lat: 40.9578,
    lng: -72.9726,
    id: 1840005099,
  },
  {
    name: "Winters",
    state_id: "CA",
    lat: 38.532,
    lng: -121.9781,
    id: 1840021484,
  },
  {
    name: "Aibonito",
    state_id: "PR",
    lat: 18.1398,
    lng: -66.2659,
    id: 1630035609,
  },
  {
    name: "Glenside",
    state_id: "PA",
    lat: 40.1032,
    lng: -75.1517,
    id: 1840005462,
  },
  {
    name: "Breckenridge",
    state_id: "CO",
    lat: 39.4995,
    lng: -106.0433,
    id: 1840021428,
  },
  {
    name: "Interlaken",
    state_id: "CA",
    lat: 36.9505,
    lng: -121.7363,
    id: 1840017653,
  },
  {
    name: "Southgate",
    state_id: "FL",
    lat: 27.3082,
    lng: -82.5096,
    id: 1840029078,
  },
  {
    name: "Medina",
    state_id: "NY",
    lat: 43.2197,
    lng: -78.3888,
    id: 1840004264,
  },
  {
    name: "Strathmore",
    state_id: "NJ",
    lat: 40.4018,
    lng: -74.2193,
    id: 1840005451,
  },
  {
    name: "Greenville",
    state_id: "NY",
    lat: 40.9981,
    lng: -73.8194,
    id: 1840133415,
  },
  {
    name: "Seville",
    state_id: "OH",
    lat: 41.0208,
    lng: -81.8671,
    id: 1840011714,
  },
  {
    name: "Kenai",
    state_id: "AK",
    lat: 60.5619,
    lng: -151.1985,
    id: 1840023421,
  },
  {
    name: "Wilson",
    state_id: "PA",
    lat: 40.6844,
    lng: -75.2407,
    id: 1840000984,
  },
  {
    name: "Carnegie",
    state_id: "PA",
    lat: 40.408,
    lng: -80.0861,
    id: 1840001214,
  },
  {
    name: "Aubrey",
    state_id: "TX",
    lat: 33.2872,
    lng: -96.9622,
    id: 1840019387,
  },
  {
    name: "Pittston",
    state_id: "PA",
    lat: 41.3274,
    lng: -75.7885,
    id: 1840000766,
  },
  {
    name: "Holly Springs",
    state_id: "MS",
    lat: 34.7768,
    lng: -89.4466,
    id: 1840014644,
  },
  {
    name: "Rio Bravo",
    state_id: "TX",
    lat: 27.3648,
    lng: -99.482,
    id: 1840021001,
  },
  {
    name: "Withamsville",
    state_id: "OH",
    lat: 39.0628,
    lng: -84.2808,
    id: 1840007390,
  },
  {
    name: "Kaufman",
    state_id: "TX",
    lat: 32.5769,
    lng: -96.316,
    id: 1840019450,
  },
  {
    name: "Campbell",
    state_id: "OH",
    lat: 41.0777,
    lng: -80.5904,
    id: 1840000926,
  },
  {
    name: "Guadalupe",
    state_id: "CA",
    lat: 34.9608,
    lng: -120.5735,
    id: 1840020467,
  },
  {
    name: "Providence",
    state_id: "UT",
    lat: 41.7033,
    lng: -111.8121,
    id: 1840020115,
  },
  {
    name: "Milan",
    state_id: "MI",
    lat: 42.0816,
    lng: -83.6853,
    id: 1840003170,
  },
  {
    name: "Oroville East",
    state_id: "CA",
    lat: 39.4947,
    lng: -121.4866,
    id: 1840074539,
  },
  {
    name: "Estes Park",
    state_id: "CO",
    lat: 40.3699,
    lng: -105.5216,
    id: 1840021382,
  },
  {
    name: "Waite Park",
    state_id: "MN",
    lat: 45.5313,
    lng: -94.2528,
    id: 1840009989,
  },
  {
    name: "Antigo",
    state_id: "WI",
    lat: 45.1413,
    lng: -89.1556,
    id: 1840001565,
  },
  {
    name: "Park City",
    state_id: "KS",
    lat: 37.8103,
    lng: -97.3255,
    id: 1840009870,
  },
  {
    name: "Forsyth",
    state_id: "MO",
    lat: 36.686,
    lng: -93.1016,
    id: 1840008806,
  },
  {
    name: "McGregor",
    state_id: "FL",
    lat: 26.5611,
    lng: -81.9134,
    id: 1840029055,
  },
  {
    name: "Cheraw",
    state_id: "SC",
    lat: 34.6955,
    lng: -79.9085,
    id: 1840016582,
  },
  {
    name: "Swainsboro",
    state_id: "GA",
    lat: 32.5866,
    lng: -82.3345,
    id: 1840015787,
  },
  {
    name: "Jasper",
    state_id: "TX",
    lat: 30.9221,
    lng: -93.9947,
    id: 1840019560,
  },
  {
    name: "Wynne",
    state_id: "AR",
    lat: 35.2322,
    lng: -90.7894,
    id: 1840015443,
  },
  {
    name: "Round Lake Park",
    state_id: "IL",
    lat: 42.3309,
    lng: -88.075,
    id: 1840011173,
  },
  {
    name: "Quarryville",
    state_id: "PA",
    lat: 39.8957,
    lng: -76.1617,
    id: 1840003726,
  },
  {
    name: "Salida",
    state_id: "CO",
    lat: 38.53,
    lng: -105.9981,
    id: 1840021473,
  },
  {
    name: "South Boston",
    state_id: "VA",
    lat: 36.7132,
    lng: -78.9135,
    id: 1840037461,
  },
  {
    name: "Citrus Hills",
    state_id: "FL",
    lat: 28.887,
    lng: -82.4312,
    id: 1840028978,
  },
  {
    name: "Albion",
    state_id: "NY",
    lat: 43.246,
    lng: -78.1902,
    id: 1840004265,
  },
  {
    name: "Wading River",
    state_id: "NY",
    lat: 40.9464,
    lng: -72.823,
    id: 1840005107,
  },
  {
    name: "Salem",
    state_id: "IL",
    lat: 38.6279,
    lng: -88.959,
    id: 1840009789,
  },
  {
    name: "Boyes Hot Springs",
    state_id: "CA",
    lat: 38.3126,
    lng: -122.4888,
    id: 1840017572,
  },
  {
    name: "Payette",
    state_id: "ID",
    lat: 44.0788,
    lng: -116.9255,
    id: 1840020015,
  },
  {
    name: "Grove",
    state_id: "OK",
    lat: 36.593,
    lng: -94.7879,
    id: 1840020369,
  },
  {
    name: "Gettysburg",
    state_id: "PA",
    lat: 39.8304,
    lng: -77.2339,
    id: 1840000683,
  },
  {
    name: "Highlands",
    state_id: "TX",
    lat: 29.813,
    lng: -95.0577,
    id: 1840018254,
  },
  {
    name: "Welcome",
    state_id: "SC",
    lat: 34.8204,
    lng: -82.46,
    id: 1840013499,
  },
  {
    name: "Kasson",
    state_id: "MN",
    lat: 44.0333,
    lng: -92.7482,
    id: 1840007913,
  },
  {
    name: "Granite Falls",
    state_id: "WA",
    lat: 48.0874,
    lng: -121.9706,
    id: 1840019787,
  },
  {
    name: "Creston",
    state_id: "IA",
    lat: 41.0597,
    lng: -94.365,
    id: 1840000892,
  },
  {
    name: "Clear Lake",
    state_id: "IA",
    lat: 43.1346,
    lng: -93.374,
    id: 1840000379,
  },
  {
    name: "Steele Creek",
    state_id: "AK",
    lat: 64.9295,
    lng: -147.3955,
    id: 1840075216,
  },
  {
    name: "Fort Scott",
    state_id: "KS",
    lat: 37.8283,
    lng: -94.7038,
    id: 1840001683,
  },
  {
    name: "Fanwood",
    state_id: "NJ",
    lat: 40.6417,
    lng: -74.3857,
    id: 1840003614,
  },
  {
    name: "Pukalani",
    state_id: "HI",
    lat: 20.8329,
    lng: -156.3415,
    id: 1840029545,
  },
  {
    name: "Hornsby Bend",
    state_id: "TX",
    lat: 30.245,
    lng: -97.5833,
    id: 1840027020,
  },
  {
    name: "Carbondale",
    state_id: "CO",
    lat: 39.3949,
    lng: -107.2147,
    id: 1840022431,
  },
  {
    name: "Robinwood",
    state_id: "MD",
    lat: 39.6266,
    lng: -77.663,
    id: 1840031480,
  },
  {
    name: "Perry",
    state_id: "IA",
    lat: 41.8387,
    lng: -94.0937,
    id: 1840009211,
  },
  {
    name: "Sebastopol",
    state_id: "CA",
    lat: 38.4001,
    lng: -122.8276,
    id: 1840021489,
  },
  {
    name: "Webster City",
    state_id: "IA",
    lat: 42.4623,
    lng: -93.8167,
    id: 1840000449,
  },
  {
    name: "Hastings",
    state_id: "MI",
    lat: 42.6498,
    lng: -85.2887,
    id: 1840003110,
  },
  {
    name: "Plain City",
    state_id: "UT",
    lat: 41.307,
    lng: -112.0877,
    id: 1840020136,
  },
  {
    name: "Indiantown",
    state_id: "FL",
    lat: 27.0375,
    lng: -80.4913,
    id: 1840014189,
  },
  {
    name: "Fort Dix",
    state_id: "NJ",
    lat: 40.006,
    lng: -74.6089,
    id: 1840005516,
  },
  {
    name: "Collinsville",
    state_id: "VA",
    lat: 36.7215,
    lng: -79.9121,
    id: 1840006553,
  },
  {
    name: "Holtville",
    state_id: "CA",
    lat: 32.813,
    lng: -115.378,
    id: 1840020631,
  },
  {
    name: "Wellington",
    state_id: "KS",
    lat: 37.2622,
    lng: -97.4282,
    id: 1840001703,
  },
  {
    name: "Piñon Hills",
    state_id: "CA",
    lat: 34.4438,
    lng: -117.6214,
    id: 1840025799,
  },
  {
    name: "Auburn",
    state_id: "GA",
    lat: 34.0157,
    lng: -83.8319,
    id: 1840013680,
  },
  {
    name: "Thurmont",
    state_id: "MD",
    lat: 39.621,
    lng: -77.4076,
    id: 1840005716,
  },
  {
    name: "Old Jefferson",
    state_id: "LA",
    lat: 30.3776,
    lng: -91.006,
    id: 1840031108,
  },
  {
    name: "North Patchogue",
    state_id: "NY",
    lat: 40.7833,
    lng: -73.0234,
    id: 1840005059,
  },
  {
    name: "Brighton",
    state_id: "MI",
    lat: 42.5298,
    lng: -83.7847,
    id: 1840003099,
  },
  {
    name: "Oakdale",
    state_id: "LA",
    lat: 30.8157,
    lng: -92.6542,
    id: 1840015012,
  },
  {
    name: "San Antonio",
    state_id: "PR",
    lat: 18.4468,
    lng: -66.3002,
    id: 1630023733,
  },
  {
    name: "Lamar",
    state_id: "CO",
    lat: 38.0739,
    lng: -102.6153,
    id: 1840020267,
  },
  {
    name: "Butler",
    state_id: "NJ",
    lat: 40.9989,
    lng: -74.3471,
    id: 1840000947,
  },
  {
    name: "Chehalis",
    state_id: "WA",
    lat: 46.6638,
    lng: -122.965,
    id: 1840018472,
  },
  {
    name: "Etowah",
    state_id: "NC",
    lat: 35.3061,
    lng: -82.5902,
    id: 1840013074,
  },
  {
    name: "East Franklin",
    state_id: "NJ",
    lat: 40.4933,
    lng: -74.4711,
    id: 1840039869,
  },
  {
    name: "Waller",
    state_id: "WA",
    lat: 47.2035,
    lng: -122.3699,
    id: 1840037889,
  },
  {
    name: "Windsor",
    state_id: "WI",
    lat: 43.2406,
    lng: -89.2952,
    id: 1840006867,
  },
  {
    name: "Scotia",
    state_id: "NY",
    lat: 42.8321,
    lng: -73.9607,
    id: 1840004448,
  },
  {
    name: "Marshfield",
    state_id: "MO",
    lat: 37.3414,
    lng: -92.9116,
    id: 1840008742,
  },
  {
    name: "Devils Lake",
    state_id: "ND",
    lat: 48.1131,
    lng: -98.875,
    id: 1840091411,
  },
  {
    name: "River Oaks",
    state_id: "TX",
    lat: 32.7767,
    lng: -97.3984,
    id: 1840020701,
  },
  {
    name: "Waupaca",
    state_id: "WI",
    lat: 44.3506,
    lng: -89.0719,
    id: 1840002353,
  },
  {
    name: "Wapato",
    state_id: "WA",
    lat: 46.4435,
    lng: -120.4215,
    id: 1840021153,
  },
  {
    name: "South Beloit",
    state_id: "IL",
    lat: 42.4822,
    lng: -89.0248,
    id: 1840009131,
  },
  {
    name: "Baldwin Harbor",
    state_id: "NY",
    lat: 40.6296,
    lng: -73.6025,
    id: 1840005229,
  },
  {
    name: "Kula",
    state_id: "HI",
    lat: 20.7706,
    lng: -156.3284,
    id: 1840039188,
  },
  {
    name: "Broadview",
    state_id: "IL",
    lat: 41.8584,
    lng: -87.8562,
    id: 1840010141,
  },
  {
    name: "Cushing",
    state_id: "OK",
    lat: 35.9798,
    lng: -96.7602,
    id: 1840019062,
  },
  {
    name: "Pike Creek",
    state_id: "DE",
    lat: 39.7485,
    lng: -75.6953,
    id: 1840028618,
  },
  {
    name: "Milan",
    state_id: "TN",
    lat: 35.9126,
    lng: -88.7554,
    id: 1840014483,
  },
  {
    name: "Senatobia",
    state_id: "MS",
    lat: 34.6081,
    lng: -89.9762,
    id: 1840015545,
  },
  {
    name: "Juncos",
    state_id: "PR",
    lat: 18.2264,
    lng: -65.9163,
    id: 1630023721,
  },
  {
    name: "Sioux Center",
    state_id: "IA",
    lat: 43.0748,
    lng: -96.1707,
    id: 1840009043,
  },
  {
    name: "Calumet Park",
    state_id: "IL",
    lat: 41.665,
    lng: -87.6578,
    id: 1840010145,
  },
  {
    name: "Escalon",
    state_id: "CA",
    lat: 37.7912,
    lng: -120.9982,
    id: 1840020262,
  },
  {
    name: "Luquillo",
    state_id: "PR",
    lat: 18.3735,
    lng: -65.7218,
    id: 1630023692,
  },
  {
    name: "Lake Shore",
    state_id: "WA",
    lat: 45.6911,
    lng: -122.6911,
    id: 1840037873,
  },
  {
    name: "Pulaski",
    state_id: "TN",
    lat: 35.1933,
    lng: -87.035,
    id: 1840014575,
  },
  {
    name: "Emerson",
    state_id: "NJ",
    lat: 40.9748,
    lng: -74.0239,
    id: 1840003545,
  },
  {
    name: "Bernardsville",
    state_id: "NJ",
    lat: 40.7268,
    lng: -74.5918,
    id: 1840001052,
  },
  {
    name: "Caribou",
    state_id: "ME",
    lat: 46.8662,
    lng: -67.9919,
    id: 1840000145,
  },
  {
    name: "Tuttle",
    state_id: "OK",
    lat: 35.3069,
    lng: -97.7562,
    id: 1840021802,
  },
  {
    name: "LaFayette",
    state_id: "GA",
    lat: 34.7088,
    lng: -85.2815,
    id: 1840014656,
  },
  {
    name: "Palmyra",
    state_id: "PA",
    lat: 40.31,
    lng: -76.5945,
    id: 1840001350,
  },
  {
    name: "Joshua Tree",
    state_id: "CA",
    lat: 34.1236,
    lng: -116.3128,
    id: 1840017802,
  },
  {
    name: "Leola",
    state_id: "PA",
    lat: 40.0915,
    lng: -76.1891,
    id: 1840005492,
  },
  {
    name: "Dayton",
    state_id: "NJ",
    lat: 40.3815,
    lng: -74.5137,
    id: 1840005414,
  },
  {
    name: "Inwood",
    state_id: "FL",
    lat: 28.0391,
    lng: -81.7677,
    id: 1840014123,
  },
  {
    name: "Glen Ridge",
    state_id: "NJ",
    lat: 40.8041,
    lng: -74.2043,
    id: 1840005356,
  },
  {
    name: "Plumas Lake",
    state_id: "CA",
    lat: 38.9924,
    lng: -121.558,
    id: 1840024456,
  },
  {
    name: "Hales Corners",
    state_id: "WI",
    lat: 42.941,
    lng: -88.0491,
    id: 1840003040,
  },
  {
    name: "Hughson",
    state_id: "CA",
    lat: 37.602,
    lng: -120.866,
    id: 1840020285,
  },
  {
    name: "Scappoose",
    state_id: "OR",
    lat: 45.7564,
    lng: -122.8771,
    id: 1840021186,
  },
  {
    name: "McCook",
    state_id: "NE",
    lat: 40.2046,
    lng: -100.6214,
    id: 1840008369,
  },
  {
    name: "Lago Vista",
    state_id: "TX",
    lat: 30.4519,
    lng: -97.9908,
    id: 1840020893,
  },
  {
    name: "Columbia Falls",
    state_id: "MT",
    lat: 48.3708,
    lng: -114.1903,
    id: 1840018351,
  },
  {
    name: "Dry Run",
    state_id: "OH",
    lat: 39.1049,
    lng: -84.3312,
    id: 1840034384,
  },
  {
    name: "Jackson",
    state_id: "CA",
    lat: 38.3485,
    lng: -120.7728,
    id: 1840018852,
  },
  {
    name: "Marshall",
    state_id: "MI",
    lat: 42.2617,
    lng: -84.9597,
    id: 1840003177,
  },
  {
    name: "Montrose",
    state_id: "VA",
    lat: 37.52,
    lng: -77.3772,
    id: 1840006389,
  },
  {
    name: "Albertville",
    state_id: "MN",
    lat: 45.2364,
    lng: -93.6618,
    id: 1840006706,
  },
  {
    name: "Porters Neck",
    state_id: "NC",
    lat: 34.2942,
    lng: -77.7695,
    id: 1840040293,
  },
  {
    name: "Gold River",
    state_id: "CA",
    lat: 38.6268,
    lng: -121.2488,
    id: 1840017583,
  },
  {
    name: "Berlin",
    state_id: "NJ",
    lat: 39.7915,
    lng: -74.9375,
    id: 1840001505,
  },
  {
    name: "Delafield",
    state_id: "WI",
    lat: 43.072,
    lng: -88.3913,
    id: 1840003017,
  },
  {
    name: "Mount Horeb",
    state_id: "WI",
    lat: 43.006,
    lng: -89.7317,
    id: 1840002921,
  },
  {
    name: "Big Stone Gap",
    state_id: "VA",
    lat: 36.8626,
    lng: -82.7769,
    id: 1840006481,
  },
  {
    name: "Gonzales",
    state_id: "TX",
    lat: 29.5126,
    lng: -97.4472,
    id: 1840020953,
  },
  {
    name: "Pikeville",
    state_id: "KY",
    lat: 37.4807,
    lng: -82.5262,
    id: 1840014348,
  },
  {
    name: "Haddon Heights",
    state_id: "NJ",
    lat: 39.8791,
    lng: -75.0645,
    id: 1840003779,
  },
  {
    name: "Page",
    state_id: "AZ",
    lat: 36.9426,
    lng: -111.5071,
    id: 1840020336,
  },
  {
    name: "Fairfax",
    state_id: "CA",
    lat: 37.9886,
    lng: -122.5952,
    id: 1840021516,
  },
  {
    name: "Yreka",
    state_id: "CA",
    lat: 41.7292,
    lng: -122.6312,
    id: 1840021320,
  },
  {
    name: "Benton",
    state_id: "IL",
    lat: 38.011,
    lng: -88.9179,
    id: 1840007543,
  },
  {
    name: "Norwich",
    state_id: "NY",
    lat: 42.5333,
    lng: -75.5227,
    id: 1840000424,
  },
  {
    name: "Marengo",
    state_id: "IL",
    lat: 42.2312,
    lng: -88.6153,
    id: 1840008084,
  },
  {
    name: "Fairlawn",
    state_id: "OH",
    lat: 41.127,
    lng: -81.6215,
    id: 1840008253,
  },
  {
    name: "Robinson",
    state_id: "IL",
    lat: 39.0089,
    lng: -87.7333,
    id: 1840009691,
  },
  {
    name: "Gurabo",
    state_id: "PR",
    lat: 18.2529,
    lng: -65.9786,
    id: 1630035678,
  },
  {
    name: "Tyrone",
    state_id: "GA",
    lat: 33.4753,
    lng: -84.5941,
    id: 1840016845,
  },
  {
    name: "North Star",
    state_id: "DE",
    lat: 39.755,
    lng: -75.7331,
    id: 1840028615,
  },
  {
    name: "Purcell",
    state_id: "OK",
    lat: 35.018,
    lng: -97.3747,
    id: 1840020454,
  },
  {
    name: "Lowell",
    state_id: "MI",
    lat: 42.9351,
    lng: -85.3457,
    id: 1840002931,
  },
  {
    name: "Hatboro",
    state_id: "PA",
    lat: 40.1775,
    lng: -75.1054,
    id: 1840003700,
  },
  {
    name: "Landen",
    state_id: "OH",
    lat: 39.3153,
    lng: -84.277,
    id: 1840034146,
  },
  {
    name: "Dos Palos",
    state_id: "CA",
    lat: 36.9854,
    lng: -120.6336,
    id: 1840018935,
  },
  {
    name: "Oceano",
    state_id: "CA",
    lat: 35.1019,
    lng: -120.609,
    id: 1840019123,
  },
  {
    name: "Sappington",
    state_id: "MO",
    lat: 38.526,
    lng: -90.373,
    id: 1840006122,
  },
  {
    name: "McCordsville",
    state_id: "IN",
    lat: 39.8967,
    lng: -85.9209,
    id: 1840009526,
  },
  {
    name: "Silvis",
    state_id: "IL",
    lat: 41.4976,
    lng: -90.4101,
    id: 1840009232,
  },
  {
    name: "Newport",
    state_id: "AR",
    lat: 35.6234,
    lng: -91.2322,
    id: 1840014516,
  },
  {
    name: "Southwood Acres",
    state_id: "CT",
    lat: 41.961,
    lng: -72.5719,
    id: 1840028602,
  },
  {
    name: "Mount Shasta",
    state_id: "CA",
    lat: 41.3206,
    lng: -122.315,
    id: 1840020106,
  },
  {
    name: "Ojai",
    state_id: "CA",
    lat: 34.4487,
    lng: -119.2469,
    id: 1840020473,
  },
  {
    name: "Monticello",
    state_id: "NY",
    lat: 41.6522,
    lng: -74.6876,
    id: 1840004791,
  },
  {
    name: "Villa Hills",
    state_id: "KY",
    lat: 39.0656,
    lng: -84.595,
    id: 1840015176,
  },
  {
    name: "St. Gabriel",
    state_id: "LA",
    lat: 30.2537,
    lng: -91.1013,
    id: 1840015926,
  },
  {
    name: "Lander",
    state_id: "WY",
    lat: 42.8313,
    lng: -108.7599,
    id: 1840020027,
  },
  {
    name: "Palmer",
    state_id: "AK",
    lat: 61.5971,
    lng: -149.1147,
    id: 1840023379,
  },
  {
    name: "Dock Junction",
    state_id: "GA",
    lat: 31.2031,
    lng: -81.5156,
    id: 1840029453,
  },
  {
    name: "Keystone Heights",
    state_id: "FL",
    lat: 29.7812,
    lng: -82.034,
    id: 1840015049,
  },
  {
    name: "Waikele",
    state_id: "HI",
    lat: 21.4025,
    lng: -158.0058,
    id: 1840039259,
  },
  {
    name: "White Oak",
    state_id: "PA",
    lat: 40.3415,
    lng: -79.8007,
    id: 1840001277,
  },
  {
    name: "Hillcrest",
    state_id: "NY",
    lat: 41.1298,
    lng: -74.035,
    id: 1840004967,
  },
  {
    name: "Rockton",
    state_id: "IL",
    lat: 42.45,
    lng: -89.0629,
    id: 1840011121,
  },
  {
    name: "Park City",
    state_id: "IL",
    lat: 42.3522,
    lng: -87.8915,
    id: 1840009133,
  },
  {
    name: "Ashland",
    state_id: "PA",
    lat: 40.7811,
    lng: -76.3451,
    id: 1840002753,
  },
  {
    name: "Florence",
    state_id: "CO",
    lat: 38.3836,
    lng: -105.1114,
    id: 1840020250,
  },
  {
    name: "Old Town",
    state_id: "ME",
    lat: 44.9491,
    lng: -68.7249,
    id: 1840000240,
  },
  {
    name: "Edgerton",
    state_id: "WI",
    lat: 42.8385,
    lng: -89.0699,
    id: 1840002468,
  },
  {
    name: "New London",
    state_id: "WI",
    lat: 44.395,
    lng: -88.7394,
    id: 1840002361,
  },
  {
    name: "North Bend",
    state_id: "WA",
    lat: 47.49,
    lng: -121.7742,
    id: 1840019833,
  },
  {
    name: "Hiawatha",
    state_id: "IA",
    lat: 42.0547,
    lng: -91.6911,
    id: 1840006989,
  },
  {
    name: "Hubbard",
    state_id: "OH",
    lat: 41.1595,
    lng: -80.5672,
    id: 1840007130,
  },
  {
    name: "River Rouge",
    state_id: "MI",
    lat: 42.2731,
    lng: -83.1246,
    id: 1840003960,
  },
  {
    name: "Sandwich",
    state_id: "IL",
    lat: 41.6496,
    lng: -88.6177,
    id: 1840009188,
  },
  {
    name: "Belen",
    state_id: "NM",
    lat: 34.7115,
    lng: -106.7985,
    id: 1840019194,
  },
  {
    name: "Marion",
    state_id: "VA",
    lat: 36.8389,
    lng: -81.5135,
    id: 1840006515,
  },
  {
    name: "Inverness",
    state_id: "FL",
    lat: 28.8397,
    lng: -82.3437,
    id: 1840015085,
  },
  {
    name: "Cotati",
    state_id: "CA",
    lat: 38.3279,
    lng: -122.7092,
    id: 1840018840,
  },
  {
    name: "Wauseon",
    state_id: "OH",
    lat: 41.5532,
    lng: -84.141,
    id: 1840010217,
  },
  {
    name: "Mills River",
    state_id: "NC",
    lat: 35.3853,
    lng: -82.5854,
    id: 1840016370,
  },
  {
    name: "Saranac Lake",
    state_id: "NY",
    lat: 44.3245,
    lng: -74.1314,
    id: 1840004068,
  },
  {
    name: "Sanibel",
    state_id: "FL",
    lat: 26.4534,
    lng: -82.1023,
    id: 1840015996,
  },
  {
    name: "Farmington",
    state_id: "AR",
    lat: 36.037,
    lng: -94.2537,
    id: 1840013367,
  },
  {
    name: "Soldotna",
    state_id: "AK",
    lat: 60.4862,
    lng: -151.0672,
    id: 1840023415,
  },
  {
    name: "Port Jefferson Station",
    state_id: "NY",
    lat: 40.926,
    lng: -73.0651,
    id: 1840005066,
  },
  {
    name: "Greenville",
    state_id: "AL",
    lat: 31.8437,
    lng: -86.6379,
    id: 1840003498,
  },
  {
    name: "West Concord",
    state_id: "MA",
    lat: 42.4518,
    lng: -71.4035,
    id: 1840003133,
  },
  {
    name: "New Bremen",
    state_id: "OH",
    lat: 40.4356,
    lng: -84.3777,
    id: 1840012071,
  },
  {
    name: "Mentor-on-the-Lake",
    state_id: "OH",
    lat: 41.7135,
    lng: -81.365,
    id: 1840034156,
  },
  {
    name: "Ancient Oaks",
    state_id: "PA",
    lat: 40.536,
    lng: -75.5852,
    id: 1840034845,
  },
  {
    name: "Farr West",
    state_id: "UT",
    lat: 41.3015,
    lng: -112.0318,
    id: 1840020128,
  },
  {
    name: "South Apopka",
    state_id: "FL",
    lat: 28.6569,
    lng: -81.5057,
    id: 1840028779,
  },
  {
    name: "Pembroke",
    state_id: "NC",
    lat: 34.6766,
    lng: -79.1934,
    id: 1840016541,
  },
  {
    name: "Canóvanas",
    state_id: "PR",
    lat: 18.3782,
    lng: -65.9056,
    id: 1630023717,
  },
  {
    name: "Providence Village",
    state_id: "TX",
    lat: 33.2363,
    lng: -96.9611,
    id: 1840043421,
  },
  {
    name: "Inverness",
    state_id: "IL",
    lat: 42.1152,
    lng: -88.1019,
    id: 1840011266,
  },
  {
    name: "Gypsum",
    state_id: "CO",
    lat: 39.6285,
    lng: -106.9334,
    id: 1840021426,
  },
  {
    name: "Clarkston",
    state_id: "WA",
    lat: 46.4161,
    lng: -117.0505,
    id: 1840018499,
  },
  {
    name: "Tolleson",
    state_id: "AZ",
    lat: 33.4484,
    lng: -112.2561,
    id: 1840021943,
  },
  {
    name: "Montpelier",
    state_id: "VT",
    lat: 44.2658,
    lng: -72.5717,
    id: 1840002187,
  },
  {
    name: "New Roads",
    state_id: "LA",
    lat: 30.6959,
    lng: -91.4537,
    id: 1840015001,
  },
  {
    name: "Ponchatoula",
    state_id: "LA",
    lat: 30.4402,
    lng: -90.4428,
    id: 1840015004,
  },
  {
    name: "Camp Pendleton North",
    state_id: "CA",
    lat: 33.3148,
    lng: -117.3162,
    id: 1840073901,
  },
  {
    name: "Smithville",
    state_id: "NJ",
    lat: 39.4934,
    lng: -74.4782,
    id: 1840024384,
  },
  {
    name: "Eden Isle",
    state_id: "LA",
    lat: 30.2268,
    lng: -89.8043,
    id: 1840031150,
  },
  {
    name: "Pomona",
    state_id: "NJ",
    lat: 39.4687,
    lng: -74.5501,
    id: 1840005596,
  },
  {
    name: "Charles City",
    state_id: "IA",
    lat: 43.0644,
    lng: -92.6745,
    id: 1840000382,
  },
  {
    name: "Stanwood",
    state_id: "WA",
    lat: 48.2449,
    lng: -122.3425,
    id: 1840021079,
  },
  {
    name: "St. Rose",
    state_id: "LA",
    lat: 29.9649,
    lng: -90.3088,
    id: 1840013973,
  },
  {
    name: "St. Joseph",
    state_id: "MN",
    lat: 45.5611,
    lng: -94.3081,
    id: 1840008882,
  },
  {
    name: "Hudsonville",
    state_id: "MI",
    lat: 42.8631,
    lng: -85.8628,
    id: 1840002997,
  },
  {
    name: "Spring Hill",
    state_id: "KS",
    lat: 38.7565,
    lng: -94.82,
    id: 1840001642,
  },
  {
    name: "Philadelphia",
    state_id: "MS",
    lat: 32.7761,
    lng: -89.1221,
    id: 1840014869,
  },
  {
    name: "Bellbrook",
    state_id: "OH",
    lat: 39.6384,
    lng: -84.0863,
    id: 1840007313,
  },
  {
    name: "Mexia",
    state_id: "TX",
    lat: 31.6809,
    lng: -96.4833,
    id: 1840020822,
  },
  {
    name: "Kenwood",
    state_id: "OH",
    lat: 39.2067,
    lng: -84.3746,
    id: 1840034154,
  },
  {
    name: "Middleborough Center",
    state_id: "MA",
    lat: 41.8945,
    lng: -70.926,
    id: 1840073513,
  },
  {
    name: "Mantua",
    state_id: "VA",
    lat: 38.8526,
    lng: -77.2571,
    id: 1840006036,
  },
  {
    name: "Makawao",
    state_id: "HI",
    lat: 20.848,
    lng: -156.319,
    id: 1840023236,
  },
  {
    name: "Gibsonville",
    state_id: "NC",
    lat: 36.0991,
    lng: -79.5417,
    id: 1840016137,
  },
  {
    name: "Dade City",
    state_id: "FL",
    lat: 28.3568,
    lng: -82.1942,
    id: 1840015101,
  },
  {
    name: "Red Chute",
    state_id: "LA",
    lat: 32.5732,
    lng: -93.606,
    id: 1840013789,
  },
  {
    name: "Village St. George",
    state_id: "LA",
    lat: 30.3598,
    lng: -91.0672,
    id: 1840031109,
  },
  {
    name: "Winooski",
    state_id: "VT",
    lat: 44.4951,
    lng: -73.1843,
    id: 1840002312,
  },
  {
    name: "Sky Lake",
    state_id: "FL",
    lat: 28.4611,
    lng: -81.3912,
    id: 1840014085,
  },
  {
    name: "Valley Center",
    state_id: "KS",
    lat: 37.8333,
    lng: -97.3645,
    id: 1840010848,
  },
  {
    name: "Umatilla",
    state_id: "OR",
    lat: 45.912,
    lng: -119.3145,
    id: 1840021195,
  },
  {
    name: "Oak Grove",
    state_id: "KY",
    lat: 36.6686,
    lng: -87.4216,
    id: 1840014397,
  },
  {
    name: "Strasburg",
    state_id: "VA",
    lat: 38.9961,
    lng: -78.3548,
    id: 1840006004,
  },
  {
    name: "Geneva",
    state_id: "OH",
    lat: 41.8006,
    lng: -80.9461,
    id: 1840008145,
  },
  {
    name: "Whiteville",
    state_id: "NC",
    lat: 34.3306,
    lng: -78.7013,
    id: 1840015570,
  },
  {
    name: "University of California-Davis",
    state_id: "CA",
    lat: 38.5374,
    lng: -121.7576,
    id: 1840042938,
  },
  {
    name: "Sabana Seca",
    state_id: "PR",
    lat: 18.4273,
    lng: -66.1809,
    id: 1630035569,
  },
  {
    name: "Ephraim",
    state_id: "UT",
    lat: 39.3564,
    lng: -111.5847,
    id: 1840020202,
  },
  {
    name: "Lochbuie",
    state_id: "CO",
    lat: 40.0119,
    lng: -104.7271,
    id: 1840021373,
  },
  {
    name: "North Springfield",
    state_id: "VA",
    lat: 38.8024,
    lng: -77.2028,
    id: 1840006022,
  },
  {
    name: "Longboat Key",
    state_id: "FL",
    lat: 27.3926,
    lng: -82.6341,
    id: 1840017250,
  },
  {
    name: "Dahlonega",
    state_id: "GA",
    lat: 34.5309,
    lng: -83.9804,
    id: 1840014672,
  },
  {
    name: "Nebraska City",
    state_id: "NE",
    lat: 40.6762,
    lng: -95.8613,
    id: 1840008324,
  },
  {
    name: "Hardeeville",
    state_id: "SC",
    lat: 32.2951,
    lng: -81.0318,
    id: 1840013808,
  },
  {
    name: "Connellsville",
    state_id: "PA",
    lat: 40.0158,
    lng: -79.5899,
    id: 1840001501,
  },
  {
    name: "Torrington",
    state_id: "WY",
    lat: 42.0658,
    lng: -104.1623,
    id: 1840021302,
  },
  {
    name: "New Cumberland",
    state_id: "PA",
    lat: 40.2298,
    lng: -76.8763,
    id: 1840001391,
  },
  {
    name: "Pinehurst",
    state_id: "MA",
    lat: 42.5334,
    lng: -71.234,
    id: 1840004521,
  },
  {
    name: "Huron",
    state_id: "CA",
    lat: 36.2041,
    lng: -120.0961,
    id: 1840018946,
  },
  {
    name: "Alta Sierra",
    state_id: "CA",
    lat: 39.1237,
    lng: -121.0523,
    id: 1840017553,
  },
  {
    name: "Northridge",
    state_id: "OH",
    lat: 39.9971,
    lng: -83.777,
    id: 1840034124,
  },
  {
    name: "Moab",
    state_id: "UT",
    lat: 38.5701,
    lng: -109.5477,
    id: 1840020227,
  },
  {
    name: "Wildwood",
    state_id: "FL",
    lat: 28.8014,
    lng: -82.0058,
    id: 1840015956,
  },
  {
    name: "Baywood",
    state_id: "NY",
    lat: 40.7533,
    lng: -73.29,
    id: 1840034028,
  },
  {
    name: "Northport",
    state_id: "NY",
    lat: 40.9036,
    lng: -73.3446,
    id: 1840005126,
  },
  {
    name: "Ralston",
    state_id: "NE",
    lat: 41.2005,
    lng: -96.0354,
    id: 1840009316,
  },
  {
    name: "Madison",
    state_id: "SD",
    lat: 44.0062,
    lng: -97.1084,
    id: 1840002540,
  },
  {
    name: "Tanglewilde",
    state_id: "WA",
    lat: 47.0512,
    lng: -122.781,
    id: 1840037899,
  },
  {
    name: "Ormond-by-the-Sea",
    state_id: "FL",
    lat: 29.3436,
    lng: -81.0677,
    id: 1840014042,
  },
  {
    name: "Delphos",
    state_id: "OH",
    lat: 40.8481,
    lng: -84.3368,
    id: 1840007172,
  },
  {
    name: "Stevensville",
    state_id: "MD",
    lat: 38.9745,
    lng: -76.3184,
    id: 1840005897,
  },
  {
    name: "Lihue",
    state_id: "HI",
    lat: 21.9728,
    lng: -159.3541,
    id: 1840029519,
  },
  {
    name: "Murillo",
    state_id: "TX",
    lat: 26.2642,
    lng: -98.1233,
    id: 1840037125,
  },
  {
    name: "St. Louis",
    state_id: "MI",
    lat: 43.4082,
    lng: -84.6118,
    id: 1840002878,
  },
  {
    name: "Hobart",
    state_id: "WA",
    lat: 47.412,
    lng: -121.996,
    id: 1840017344,
  },
  {
    name: "Pleasantville",
    state_id: "NY",
    lat: 41.1378,
    lng: -73.7827,
    id: 1840004940,
  },
  {
    name: "Neptune Beach",
    state_id: "FL",
    lat: 30.3165,
    lng: -81.4118,
    id: 1840015030,
  },
  {
    name: "Crystal City",
    state_id: "TX",
    lat: 28.6909,
    lng: -99.8257,
    id: 1840019688,
  },
  {
    name: "Williston Park",
    state_id: "NY",
    lat: 40.7587,
    lng: -73.6465,
    id: 1840005333,
  },
  {
    name: "Union Grove",
    state_id: "WI",
    lat: 42.6863,
    lng: -88.0495,
    id: 1840002487,
  },
  {
    name: "Richland",
    state_id: "MS",
    lat: 32.2309,
    lng: -90.1592,
    id: 1840014889,
  },
  {
    name: "Bedford",
    state_id: "VA",
    lat: 37.336,
    lng: -79.5179,
    id: 1840001713,
  },
  {
    name: "Belle Isle",
    state_id: "FL",
    lat: 28.4724,
    lng: -81.3491,
    id: 1840014101,
  },
  {
    name: "Woodstock",
    state_id: "VA",
    lat: 38.875,
    lng: -78.516,
    id: 1840006006,
  },
  {
    name: "Franklin",
    state_id: "NC",
    lat: 35.1798,
    lng: -83.3808,
    id: 1840016421,
  },
  {
    name: "Collinsville",
    state_id: "OK",
    lat: 36.3718,
    lng: -95.8611,
    id: 1840019061,
  },
  {
    name: "Willow Oak",
    state_id: "FL",
    lat: 27.9216,
    lng: -82.0244,
    id: 1840028919,
  },
  {
    name: "Emmitsburg",
    state_id: "MD",
    lat: 39.7051,
    lng: -77.3216,
    id: 1840005708,
  },
  {
    name: "Willows",
    state_id: "CA",
    lat: 39.5149,
    lng: -122.1995,
    id: 1840021438,
  },
  {
    name: "Parkville",
    state_id: "PA",
    lat: 39.7859,
    lng: -76.9686,
    id: 1840005507,
  },
  {
    name: "Roosevelt",
    state_id: "UT",
    lat: 40.2924,
    lng: -110.0093,
    id: 1840020162,
  },
  {
    name: "East Hills",
    state_id: "NY",
    lat: 40.7958,
    lng: -73.6292,
    id: 1840005342,
  },
  {
    name: "Acton",
    state_id: "CA",
    lat: 34.4956,
    lng: -118.1857,
    id: 1840017917,
  },
  {
    name: "La Feria",
    state_id: "TX",
    lat: 26.1544,
    lng: -97.8255,
    id: 1840021041,
  },
  {
    name: "Washington",
    state_id: "IA",
    lat: 41.2982,
    lng: -91.6928,
    id: 1840010255,
  },
  {
    name: "Tappan",
    state_id: "NY",
    lat: 41.0269,
    lng: -73.952,
    id: 1840004969,
  },
  {
    name: "Cochituate",
    state_id: "MA",
    lat: 42.329,
    lng: -71.3576,
    id: 1840003129,
  },
  {
    name: "Whitinsville",
    state_id: "MA",
    lat: 42.1146,
    lng: -71.6688,
    id: 1840003153,
  },
  {
    name: "Batesville",
    state_id: "MS",
    lat: 34.3147,
    lng: -89.9249,
    id: 1840013607,
  },
  {
    name: "Lake Lorraine",
    state_id: "FL",
    lat: 30.4407,
    lng: -86.5657,
    id: 1840029048,
  },
  {
    name: "Seward",
    state_id: "NE",
    lat: 40.9099,
    lng: -97.0957,
    id: 1840009356,
  },
  {
    name: "Midland Park",
    state_id: "NJ",
    lat: 40.9952,
    lng: -74.1411,
    id: 1840000905,
  },
  {
    name: "Moapa Valley",
    state_id: "NV",
    lat: 36.6078,
    lng: -114.4566,
    id: 1840033831,
  },
  {
    name: "Middletown",
    state_id: "PA",
    lat: 40.6441,
    lng: -75.3244,
    id: 1840145547,
  },
  {
    name: "Corbin",
    state_id: "KY",
    lat: 36.9321,
    lng: -84.1004,
    id: 1840014405,
  },
  {
    name: "Dunellen",
    state_id: "NJ",
    lat: 40.5903,
    lng: -74.4656,
    id: 1840003634,
  },
  {
    name: "Jackson",
    state_id: "WI",
    lat: 43.3237,
    lng: -88.166,
    id: 1840002832,
  },
  {
    name: "Riverdale Park",
    state_id: "MD",
    lat: 38.9642,
    lng: -76.9266,
    id: 1840005989,
  },
  {
    name: "Knoxville",
    state_id: "IA",
    lat: 41.3188,
    lng: -93.1024,
    id: 1840008229,
  },
  {
    name: "Unionville",
    state_id: "NC",
    lat: 35.0716,
    lng: -80.5173,
    id: 1840016457,
  },
  {
    name: "Gunnison",
    state_id: "CO",
    lat: 38.5455,
    lng: -106.9225,
    id: 1840020235,
  },
  {
    name: "Morrow",
    state_id: "GA",
    lat: 33.5816,
    lng: -84.3392,
    id: 1840014814,
  },
  {
    name: "Sandston",
    state_id: "VA",
    lat: 37.512,
    lng: -77.3149,
    id: 1840026826,
  },
  {
    name: "Belle Plaine",
    state_id: "MN",
    lat: 44.6188,
    lng: -93.7643,
    id: 1840006775,
  },
  {
    name: "Nuevo",
    state_id: "CA",
    lat: 33.8011,
    lng: -117.1415,
    id: 1840019287,
  },
  {
    name: "Enoch",
    state_id: "UT",
    lat: 37.767,
    lng: -113.0449,
    id: 1840020273,
  },
  {
    name: "Pacific",
    state_id: "WA",
    lat: 47.261,
    lng: -122.2507,
    id: 1840019834,
  },
  {
    name: "Utuado",
    state_id: "PR",
    lat: 18.2697,
    lng: -66.7049,
    id: 1630023623,
  },
  {
    name: "Canfield",
    state_id: "OH",
    lat: 41.0315,
    lng: -80.7672,
    id: 1840007163,
  },
  {
    name: "Independence",
    state_id: "OH",
    lat: 41.378,
    lng: -81.6385,
    id: 1840000607,
  },
  {
    name: "Elsa",
    state_id: "TX",
    lat: 26.2978,
    lng: -97.9936,
    id: 1840021021,
  },
  {
    name: "Centerville",
    state_id: "SC",
    lat: 34.5256,
    lng: -82.7161,
    id: 1840134659,
  },
  {
    name: "Commerce",
    state_id: "GA",
    lat: 34.2133,
    lng: -83.473,
    id: 1840014720,
  },
  {
    name: "Austell",
    state_id: "GA",
    lat: 33.82,
    lng: -84.645,
    id: 1840013689,
  },
  {
    name: "Glenolden",
    state_id: "PA",
    lat: 39.8996,
    lng: -75.292,
    id: 1840000699,
  },
  {
    name: "Parkville",
    state_id: "MO",
    lat: 39.2004,
    lng: -94.7222,
    id: 1840009590,
  },
  {
    name: "Hillsborough",
    state_id: "NC",
    lat: 36.0681,
    lng: -79.0994,
    id: 1840016151,
  },
  {
    name: "Crozet",
    state_id: "VA",
    lat: 38.0645,
    lng: -78.6962,
    id: 1840006280,
  },
  {
    name: "Pollock Pines",
    state_id: "CA",
    lat: 38.7564,
    lng: -120.5904,
    id: 1840018831,
  },
  {
    name: "Nyack",
    state_id: "NY",
    lat: 41.0919,
    lng: -73.9143,
    id: 1840004981,
  },
  {
    name: "Tyrone",
    state_id: "PA",
    lat: 40.6764,
    lng: -78.246,
    id: 1840001087,
  },
  {
    name: "Larose",
    state_id: "LA",
    lat: 29.5669,
    lng: -90.3751,
    id: 1840014025,
  },
  {
    name: "Lawrenceville",
    state_id: "IL",
    lat: 38.7264,
    lng: -87.6873,
    id: 1840008606,
  },
  {
    name: "Brook Highland",
    state_id: "AL",
    lat: 33.4359,
    lng: -86.6849,
    id: 1840027551,
  },
  {
    name: "Cottage Grove",
    state_id: "WI",
    lat: 43.091,
    lng: -89.2034,
    id: 1840002910,
  },
  {
    name: "De Soto",
    state_id: "MO",
    lat: 38.141,
    lng: -90.5609,
    id: 1840007509,
  },
  {
    name: "Palmyra",
    state_id: "NJ",
    lat: 40.0025,
    lng: -75.036,
    id: 1840001479,
  },
  {
    name: "Nappanee",
    state_id: "IN",
    lat: 41.4452,
    lng: -85.9941,
    id: 1840008189,
  },
  {
    name: "Atherton",
    state_id: "CA",
    lat: 37.4539,
    lng: -122.2032,
    id: 1840020308,
  },
  {
    name: "Nibley",
    state_id: "UT",
    lat: 41.6725,
    lng: -111.8454,
    id: 1840020113,
  },
  {
    name: "Kingsland",
    state_id: "TX",
    lat: 30.6651,
    lng: -98.4545,
    id: 1840018217,
  },
  {
    name: "Walled Lake",
    state_id: "MI",
    lat: 42.538,
    lng: -83.4786,
    id: 1840002435,
  },
  {
    name: "San Lorenzo",
    state_id: "PR",
    lat: 18.1894,
    lng: -65.9674,
    id: 1630035578,
  },
  {
    name: "Winsted",
    state_id: "CT",
    lat: 41.9268,
    lng: -73.0675,
    id: 1840003262,
  },
  {
    name: "Bonne Terre",
    state_id: "MO",
    lat: 37.9209,
    lng: -90.5426,
    id: 1840007551,
  },
  {
    name: "Live Oak",
    state_id: "FL",
    lat: 30.2956,
    lng: -82.9847,
    id: 1840015929,
  },
  {
    name: "Rockville",
    state_id: "CT",
    lat: 41.8667,
    lng: -72.4528,
    id: 1840003274,
  },
  {
    name: "Calipatria",
    state_id: "CA",
    lat: 33.1493,
    lng: -115.5056,
    id: 1840019380,
  },
  {
    name: "Mead",
    state_id: "WA",
    lat: 47.7795,
    lng: -117.35,
    id: 1840023786,
  },
  {
    name: "Parkwood",
    state_id: "WA",
    lat: 47.5266,
    lng: -122.5986,
    id: 1840037518,
  },
  {
    name: "Russellville",
    state_id: "KY",
    lat: 36.8393,
    lng: -86.895,
    id: 1840014401,
  },
  {
    name: "Pinson",
    state_id: "AL",
    lat: 33.7057,
    lng: -86.6674,
    id: 1840022941,
  },
  {
    name: "Selma",
    state_id: "NC",
    lat: 35.5436,
    lng: -78.2954,
    id: 1840017794,
  },
  {
    name: "Marble Falls",
    state_id: "TX",
    lat: 30.5649,
    lng: -98.2768,
    id: 1840020870,
  },
  {
    name: "Decatur",
    state_id: "TX",
    lat: 33.2262,
    lng: -97.5876,
    id: 1840019385,
  },
  {
    name: "Fort Myers Beach",
    state_id: "FL",
    lat: 26.4324,
    lng: -81.9168,
    id: 1840017269,
  },
  {
    name: "Dunbar",
    state_id: "WV",
    lat: 38.3688,
    lng: -81.7345,
    id: 1840006197,
  },
  {
    name: "Byron Center",
    state_id: "MI",
    lat: 42.8121,
    lng: -85.7279,
    id: 1840004306,
  },
  {
    name: "Homestead Meadows South",
    state_id: "TX",
    lat: 31.811,
    lng: -106.1643,
    id: 1840073263,
  },
  {
    name: "Portage Lakes",
    state_id: "OH",
    lat: 41.0034,
    lng: -81.5347,
    id: 1840004956,
  },
  {
    name: "Meridianville",
    state_id: "AL",
    lat: 34.8729,
    lng: -86.5722,
    id: 1840013546,
  },
  {
    name: "Homeland",
    state_id: "CA",
    lat: 33.7459,
    lng: -117.1132,
    id: 1840017988,
  },
  {
    name: "Osceola",
    state_id: "AR",
    lat: 35.6942,
    lng: -89.9934,
    id: 1840014501,
  },
  {
    name: "Syracuse",
    state_id: "IN",
    lat: 41.4226,
    lng: -85.7492,
    id: 1840010280,
  },
  {
    name: "Souderton",
    state_id: "PA",
    lat: 40.311,
    lng: -75.3224,
    id: 1840001378,
  },
  {
    name: "Flatwoods",
    state_id: "KY",
    lat: 38.521,
    lng: -82.7195,
    id: 1840013182,
  },
  {
    name: "Fair Plain",
    state_id: "MI",
    lat: 42.0823,
    lng: -86.4515,
    id: 1840004697,
  },
  {
    name: "Gateway",
    state_id: "AK",
    lat: 61.5737,
    lng: -149.2389,
    id: 1840023654,
  },
  {
    name: "Mount Ivy",
    state_id: "NY",
    lat: 41.1926,
    lng: -74.0297,
    id: 1840004962,
  },
  {
    name: "Milton-Freewater",
    state_id: "OR",
    lat: 45.9348,
    lng: -118.3913,
    id: 1840019916,
  },
  {
    name: "River Park",
    state_id: "FL",
    lat: 27.3214,
    lng: -80.3307,
    id: 1840028958,
  },
  {
    name: "Yabucoa",
    state_id: "PR",
    lat: 18.0469,
    lng: -65.8792,
    id: 1630023682,
  },
  {
    name: "Sausalito",
    state_id: "CA",
    lat: 37.858,
    lng: -122.4932,
    id: 1840021514,
  },
  {
    name: "South Lockport",
    state_id: "NY",
    lat: 43.1377,
    lng: -78.6864,
    id: 1840004271,
  },
  {
    name: "Ridley Park",
    state_id: "PA",
    lat: 39.8785,
    lng: -75.3251,
    id: 1840000708,
  },
  {
    name: "Highland Heights",
    state_id: "KY",
    lat: 39.0355,
    lng: -84.4567,
    id: 1840014262,
  },
  {
    name: "Woodlake",
    state_id: "VA",
    lat: 37.4216,
    lng: -77.6783,
    id: 1840026845,
  },
  {
    name: "Barnesville",
    state_id: "GA",
    lat: 33.0509,
    lng: -84.1527,
    id: 1840013783,
  },
  {
    name: "Berrien Springs",
    state_id: "MI",
    lat: 41.9474,
    lng: -86.3403,
    id: 1840011224,
  },
  {
    name: "St. Helena",
    state_id: "CA",
    lat: 38.5063,
    lng: -122.4682,
    id: 1840021486,
  },
  {
    name: "Hudson Falls",
    state_id: "NY",
    lat: 43.3042,
    lng: -73.5818,
    id: 1840004178,
  },
  {
    name: "Noble",
    state_id: "OK",
    lat: 35.1385,
    lng: -97.371,
    id: 1840020450,
  },
  {
    name: "Gulf Park Estates",
    state_id: "MS",
    lat: 30.3801,
    lng: -88.7581,
    id: 1840032475,
  },
  {
    name: "Oak Ridge",
    state_id: "NC",
    lat: 36.174,
    lng: -79.9916,
    id: 1840016141,
  },
  {
    name: "Rochester",
    state_id: "IN",
    lat: 41.059,
    lng: -86.196,
    id: 1840009329,
  },
  {
    name: "San Isidro",
    state_id: "PR",
    lat: 18.3919,
    lng: -65.8853,
    id: 1630035575,
  },
  {
    name: "Orange City",
    state_id: "IA",
    lat: 43.0023,
    lng: -96.0566,
    id: 1840009044,
  },
  {
    name: "Seminole",
    state_id: "OK",
    lat: 35.2346,
    lng: -96.65,
    id: 1840021776,
  },
  {
    name: "Groesbeck",
    state_id: "OH",
    lat: 39.2292,
    lng: -84.5964,
    id: 1840005878,
  },
  {
    name: "Springs",
    state_id: "NY",
    lat: 41.0212,
    lng: -72.1584,
    id: 1840005102,
  },
  {
    name: "Marksville",
    state_id: "LA",
    lat: 31.1247,
    lng: -92.0652,
    id: 1840015878,
  },
  {
    name: "Truth or Consequences",
    state_id: "NM",
    lat: 33.1864,
    lng: -107.2589,
    id: 1840021995,
  },
  {
    name: "Laurium",
    state_id: "MI",
    lat: 47.2351,
    lng: -88.4382,
    id: 1840013133,
  },
  {
    name: "Archbald",
    state_id: "PA",
    lat: 41.5077,
    lng: -75.5453,
    id: 1840003374,
  },
  {
    name: "Willis",
    state_id: "TX",
    lat: 30.4314,
    lng: -95.4832,
    id: 1840022175,
  },
  {
    name: "Great Neck Plaza",
    state_id: "NY",
    lat: 40.7869,
    lng: -73.7261,
    id: 1840005292,
  },
  {
    name: "Rancho Calaveras",
    state_id: "CA",
    lat: 38.1248,
    lng: -120.857,
    id: 1840018867,
  },
  {
    name: "Orland Hills",
    state_id: "IL",
    lat: 41.5905,
    lng: -87.8413,
    id: 1840011309,
  },
  {
    name: "Kentfield",
    state_id: "CA",
    lat: 37.9481,
    lng: -122.5496,
    id: 1840017603,
  },
  {
    name: "Kiel",
    state_id: "WI",
    lat: 43.9167,
    lng: -88.0266,
    id: 1840002210,
  },
  {
    name: "Mount Carmel",
    state_id: "IL",
    lat: 38.4187,
    lng: -87.7694,
    id: 1840008643,
  },
  {
    name: "Crete",
    state_id: "NE",
    lat: 40.6254,
    lng: -96.9575,
    id: 1840007215,
  },
  {
    name: "Meraux",
    state_id: "LA",
    lat: 29.9284,
    lng: -89.9179,
    id: 1840014003,
  },
  {
    name: "Garden Home-Whitford",
    state_id: "OR",
    lat: 45.4642,
    lng: -122.7589,
    id: 1840073958,
  },
  {
    name: "St. Augustine Beach",
    state_id: "FL",
    lat: 29.8414,
    lng: -81.2713,
    id: 1840015936,
  },
  {
    name: "Zuni Pueblo",
    state_id: "NM",
    lat: 35.0708,
    lng: -108.8484,
    id: 1840019095,
  },
  {
    name: "Mendota",
    state_id: "IL",
    lat: 41.5553,
    lng: -89.1042,
    id: 1840008199,
  },
  {
    name: "Cos Cob",
    state_id: "CT",
    lat: 41.0513,
    lng: -73.5931,
    id: 1840026281,
  },
  {
    name: "Adel",
    state_id: "GA",
    lat: 31.1272,
    lng: -83.4232,
    id: 1840013879,
  },
  {
    name: "Heathrow",
    state_id: "FL",
    lat: 28.7753,
    lng: -81.3721,
    id: 1840014070,
  },
  {
    name: "Calverton",
    state_id: "NY",
    lat: 40.9163,
    lng: -72.7645,
    id: 1840005024,
  },
  {
    name: "Galliano",
    state_id: "LA",
    lat: 29.447,
    lng: -90.3096,
    id: 1840014023,
  },
  {
    name: "Eagle",
    state_id: "CO",
    lat: 39.6368,
    lng: -106.8123,
    id: 1840021424,
  },
  {
    name: "Hillsboro",
    state_id: "IL",
    lat: 39.1668,
    lng: -89.4735,
    id: 1840007364,
  },
  {
    name: "Oakdale",
    state_id: "NY",
    lat: 40.7373,
    lng: -73.1345,
    id: 1840005063,
  },
  {
    name: "Barberton",
    state_id: "WA",
    lat: 45.7136,
    lng: -122.6115,
    id: 1840017397,
  },
  {
    name: "Keyport",
    state_id: "NJ",
    lat: 40.4327,
    lng: -74.2011,
    id: 1840003691,
  },
  {
    name: "Beacon Square",
    state_id: "FL",
    lat: 28.2118,
    lng: -82.7504,
    id: 1840028798,
  },
  {
    name: "Brier",
    state_id: "WA",
    lat: 47.7924,
    lng: -122.2734,
    id: 1840018379,
  },
  {
    name: "Trumann",
    state_id: "AR",
    lat: 35.6771,
    lng: -90.5261,
    id: 1840015406,
  },
  {
    name: "Milltown",
    state_id: "NJ",
    lat: 40.4504,
    lng: -74.4351,
    id: 1840001338,
  },
  {
    name: "Hamilton",
    state_id: "MT",
    lat: 46.2527,
    lng: -114.1598,
    id: 1840019896,
  },
  {
    name: "Leitchfield",
    state_id: "KY",
    lat: 37.4862,
    lng: -86.2857,
    id: 1840014366,
  },
  {
    name: "Creswell",
    state_id: "OR",
    lat: 43.9212,
    lng: -123.016,
    id: 1840018625,
  },
  {
    name: "Bondurant",
    state_id: "IA",
    lat: 41.6986,
    lng: -93.4551,
    id: 1840007067,
  },
  {
    name: "Stratford",
    state_id: "NJ",
    lat: 39.829,
    lng: -75.0156,
    id: 1840001516,
  },
  {
    name: "Prairie View",
    state_id: "TX",
    lat: 30.085,
    lng: -95.9897,
    id: 1840021698,
  },
  {
    name: "Catoosa",
    state_id: "OK",
    lat: 36.1832,
    lng: -95.7662,
    id: 1840019048,
  },
  {
    name: "Paintsville",
    state_id: "KY",
    lat: 37.8167,
    lng: -82.8088,
    id: 1840014331,
  },
  {
    name: "Plymouth Meeting",
    state_id: "PA",
    lat: 40.11,
    lng: -75.2794,
    id: 1840005459,
  },
  {
    name: "Bremen",
    state_id: "GA",
    lat: 33.7085,
    lng: -85.1495,
    id: 1840013715,
  },
  {
    name: "Ocean City",
    state_id: "MD",
    lat: 38.3998,
    lng: -75.0715,
    id: 1840006259,
  },
  {
    name: "Vandalia",
    state_id: "IL",
    lat: 38.9754,
    lng: -89.1117,
    id: 1840010693,
  },
  {
    name: "Edgemoor",
    state_id: "DE",
    lat: 39.7551,
    lng: -75.507,
    id: 1840028608,
  },
  {
    name: "Westmere",
    state_id: "NY",
    lat: 42.6883,
    lng: -73.8744,
    id: 1840004489,
  },
  {
    name: "Fort Meade",
    state_id: "FL",
    lat: 27.7645,
    lng: -81.8058,
    id: 1840014134,
  },
  {
    name: "Osprey",
    state_id: "FL",
    lat: 27.1914,
    lng: -82.48,
    id: 1840014186,
  },
  {
    name: "Franklin",
    state_id: "CA",
    lat: 37.3277,
    lng: -120.5321,
    id: 1840075835,
  },
  {
    name: "Fort Pierce North",
    state_id: "FL",
    lat: 27.4736,
    lng: -80.3594,
    id: 1840073834,
  },
  {
    name: "Sturgis",
    state_id: "SD",
    lat: 44.411,
    lng: -103.4975,
    id: 1840002174,
  },
  {
    name: "West Samoset",
    state_id: "FL",
    lat: 27.4702,
    lng: -82.5552,
    id: 1840029097,
  },
  {
    name: "Treasure Island",
    state_id: "FL",
    lat: 27.774,
    lng: -82.7663,
    id: 1840015981,
  },
  {
    name: "Spring Lake Park",
    state_id: "MN",
    lat: 45.1161,
    lng: -93.2451,
    id: 1840008903,
  },
  {
    name: "Black Jack",
    state_id: "MO",
    lat: 38.7993,
    lng: -90.264,
    id: 1840007452,
  },
  {
    name: "Lewisburg",
    state_id: "WV",
    lat: 37.8096,
    lng: -80.4327,
    id: 1840006285,
  },
  {
    name: "Heber Springs",
    state_id: "AR",
    lat: 35.5003,
    lng: -92.0332,
    id: 1840014543,
  },
  {
    name: "West Slope",
    state_id: "OR",
    lat: 45.4962,
    lng: -122.7731,
    id: 1840018554,
  },
  {
    name: "Woodland",
    state_id: "WA",
    lat: 45.9145,
    lng: -122.7506,
    id: 1840021181,
  },
  {
    name: "Gulf Breeze",
    state_id: "FL",
    lat: 30.3685,
    lng: -87.1769,
    id: 1840013920,
  },
  {
    name: "Viola",
    state_id: "NY",
    lat: 41.1287,
    lng: -74.0855,
    id: 1840004972,
  },
  {
    name: "Freeland",
    state_id: "MI",
    lat: 43.5198,
    lng: -84.1124,
    id: 1840004224,
  },
  {
    name: "Lake Mills",
    state_id: "WI",
    lat: 43.0776,
    lng: -88.9054,
    id: 1840003003,
  },
  {
    name: "Byron",
    state_id: "IL",
    lat: 42.1224,
    lng: -89.2665,
    id: 1840007021,
  },
  {
    name: "Latimer",
    state_id: "MS",
    lat: 30.4972,
    lng: -88.8607,
    id: 1840013936,
  },
  {
    name: "Thermalito",
    state_id: "CA",
    lat: 39.4909,
    lng: -121.615,
    id: 1840018780,
  },
  {
    name: "Whitemarsh Island",
    state_id: "GA",
    lat: 32.0304,
    lng: -81.0109,
    id: 1840029460,
  },
  {
    name: "Colusa",
    state_id: "CA",
    lat: 39.2059,
    lng: -122.0125,
    id: 1840018810,
  },
  {
    name: "Union",
    state_id: "OH",
    lat: 39.909,
    lng: -84.2896,
    id: 1840010583,
  },
  {
    name: "Junction City",
    state_id: "OR",
    lat: 44.2067,
    lng: -123.2101,
    id: 1840018623,
  },
  {
    name: "Ben Lomond",
    state_id: "CA",
    lat: 37.0782,
    lng: -122.0882,
    id: 1840017649,
  },
  {
    name: "University Park",
    state_id: "IL",
    lat: 41.4461,
    lng: -87.7154,
    id: 1840029849,
  },
  {
    name: "Gleneagle",
    state_id: "CO",
    lat: 39.0453,
    lng: -104.8288,
    id: 1840028578,
  },
  {
    name: "Mountainside",
    state_id: "NJ",
    lat: 40.6811,
    lng: -74.36,
    id: 1840003612,
  },
  {
    name: "Moores Mill",
    state_id: "AL",
    lat: 34.8491,
    lng: -86.5222,
    id: 1840013547,
  },
  {
    name: "Perry",
    state_id: "FL",
    lat: 30.109,
    lng: -83.5821,
    id: 1840015046,
  },
  {
    name: "Chestertown",
    state_id: "MD",
    lat: 39.2182,
    lng: -76.0714,
    id: 1840005798,
  },
  {
    name: "St. Martinville",
    state_id: "LA",
    lat: 30.1263,
    lng: -91.8319,
    id: 1840015924,
  },
  {
    name: "Harrisville",
    state_id: "UT",
    lat: 41.2853,
    lng: -111.9859,
    id: 1840020129,
  },
  {
    name: "Twin Rivers",
    state_id: "NJ",
    lat: 40.263,
    lng: -74.4917,
    id: 1840005474,
  },
  {
    name: "Huron",
    state_id: "OH",
    lat: 41.3913,
    lng: -82.5635,
    id: 1840007095,
  },
  {
    name: "Mechanicstown",
    state_id: "NY",
    lat: 41.4472,
    lng: -74.3914,
    id: 1840004857,
  },
  {
    name: "Loomis",
    state_id: "CA",
    lat: 38.8093,
    lng: -121.1955,
    id: 1840021462,
  },
  {
    name: "King",
    state_id: "NC",
    lat: 36.2766,
    lng: -80.3564,
    id: 1840014431,
  },
  {
    name: "West Glens Falls",
    state_id: "NY",
    lat: 43.3019,
    lng: -73.6874,
    id: 1840004184,
  },
  {
    name: "Youngtown",
    state_id: "AZ",
    lat: 33.5846,
    lng: -112.3047,
    id: 1840022917,
  },
  {
    name: "Lemoore Station",
    state_id: "CA",
    lat: 36.2633,
    lng: -119.9049,
    id: 1840074319,
  },
  {
    name: "Lutherville",
    state_id: "MD",
    lat: 39.4239,
    lng: -76.6176,
    id: 1840026623,
  },
  {
    name: "Milford",
    state_id: "OH",
    lat: 39.1699,
    lng: -84.2811,
    id: 1840003825,
  },
  {
    name: "Greenbrier",
    state_id: "TN",
    lat: 36.4239,
    lng: -86.7976,
    id: 1840016014,
  },
  {
    name: "Lecanto",
    state_id: "FL",
    lat: 28.8359,
    lng: -82.488,
    id: 1840014064,
  },
  {
    name: "Eldridge",
    state_id: "IA",
    lat: 41.639,
    lng: -90.5809,
    id: 1840008182,
  },
  {
    name: "Old Greenwich",
    state_id: "CT",
    lat: 41.0253,
    lng: -73.5691,
    id: 1840026284,
  },
  {
    name: "Idabel",
    state_id: "OK",
    lat: 33.9041,
    lng: -94.8294,
    id: 1840019260,
  },
  {
    name: "South Patrick Shores",
    state_id: "FL",
    lat: 28.202,
    lng: -80.6137,
    id: 1840029080,
  },
  {
    name: "Sunnyvale",
    state_id: "TX",
    lat: 32.7974,
    lng: -96.5577,
    id: 1840023031,
  },
  {
    name: "Bee Cave",
    state_id: "TX",
    lat: 30.3084,
    lng: -97.9629,
    id: 1840023142,
  },
  {
    name: "Lovejoy",
    state_id: "GA",
    lat: 33.4426,
    lng: -84.3176,
    id: 1840015704,
  },
  {
    name: "Fircrest",
    state_id: "WA",
    lat: 47.2307,
    lng: -122.5157,
    id: 1840019854,
  },
  {
    name: "Las Piedras",
    state_id: "PR",
    lat: 18.1784,
    lng: -65.8708,
    id: 1630023710,
  },
  {
    name: "Huntertown",
    state_id: "IN",
    lat: 41.2155,
    lng: -85.1715,
    id: 1840010297,
  },
  {
    name: "Crossett",
    state_id: "AR",
    lat: 33.128,
    lng: -91.9631,
    id: 1840014838,
  },
  {
    name: "Taneytown",
    state_id: "MD",
    lat: 39.657,
    lng: -77.1683,
    id: 1840005700,
  },
  {
    name: "Mount Vernon",
    state_id: "IN",
    lat: 37.9364,
    lng: -87.8959,
    id: 1840013884,
  },
  {
    name: "Fort Myers Shores",
    state_id: "FL",
    lat: 26.7135,
    lng: -81.7383,
    id: 1840014212,
  },
  {
    name: "Kirtland",
    state_id: "OH",
    lat: 41.5969,
    lng: -81.3406,
    id: 1840000535,
  },
  {
    name: "Lakes of the Four Seasons",
    state_id: "IN",
    lat: 41.4074,
    lng: -87.2203,
    id: 1840004833,
  },
  {
    name: "Marion",
    state_id: "SC",
    lat: 34.1787,
    lng: -79.3966,
    id: 1840015595,
  },
  {
    name: "San Martin",
    state_id: "CA",
    lat: 37.0829,
    lng: -121.5963,
    id: 1840018948,
  },
  {
    name: "Kimberly",
    state_id: "WI",
    lat: 44.267,
    lng: -88.3377,
    id: 1840002404,
  },
  {
    name: "Hewlett",
    state_id: "NY",
    lat: 40.6422,
    lng: -73.6942,
    id: 1840005265,
  },
  {
    name: "Taylor Mill",
    state_id: "KY",
    lat: 39.0092,
    lng: -84.4988,
    id: 1840015175,
  },
  {
    name: "Yardville",
    state_id: "NJ",
    lat: 40.1849,
    lng: -74.6603,
    id: 1840026523,
  },
  {
    name: "East Shoreham",
    state_id: "NY",
    lat: 40.946,
    lng: -72.8811,
    id: 1840034048,
  },
  {
    name: "Valley Park",
    state_id: "MO",
    lat: 38.5513,
    lng: -90.4924,
    id: 1840010740,
  },
  {
    name: "Thousand Palms",
    state_id: "CA",
    lat: 33.815,
    lng: -116.3545,
    id: 1840019291,
  },
  {
    name: "Amory",
    state_id: "MS",
    lat: 33.9813,
    lng: -88.4823,
    id: 1840013686,
  },
  {
    name: "Columbus",
    state_id: "WI",
    lat: 43.3354,
    lng: -89.03,
    id: 1840002708,
  },
  {
    name: "Lake Mathews",
    state_id: "CA",
    lat: 33.825,
    lng: -117.3683,
    id: 1840075920,
  },
  {
    name: "Marvin",
    state_id: "NC",
    lat: 34.989,
    lng: -80.8032,
    id: 1840017880,
  },
  {
    name: "Fowler",
    state_id: "CA",
    lat: 36.6243,
    lng: -119.6737,
    id: 1840020318,
  },
  {
    name: "Fishhook",
    state_id: "AK",
    lat: 61.711,
    lng: -149.2657,
    id: 1840037578,
  },
  {
    name: "Breinigsville",
    state_id: "PA",
    lat: 40.5394,
    lng: -75.6344,
    id: 1840026409,
  },
  {
    name: "Lincoln",
    state_id: "AL",
    lat: 33.5935,
    lng: -86.1371,
    id: 1840015698,
  },
  {
    name: "Myers Corner",
    state_id: "NY",
    lat: 41.5947,
    lng: -73.8744,
    id: 1840004742,
  },
  {
    name: "South Valley Stream",
    state_id: "NY",
    lat: 40.6557,
    lng: -73.7186,
    id: 1840034062,
  },
  {
    name: "Millersville",
    state_id: "TN",
    lat: 36.3968,
    lng: -86.7111,
    id: 1840014416,
  },
  {
    name: "Cortland",
    state_id: "OH",
    lat: 41.332,
    lng: -80.7195,
    id: 1840007128,
  },
  {
    name: "Guánica",
    state_id: "PR",
    lat: 17.9698,
    lng: -66.9309,
    id: 1630023537,
  },
  {
    name: "Desert Palms",
    state_id: "CA",
    lat: 33.779,
    lng: -116.293,
    id: 1840043016,
  },
  {
    name: "Indian River Estates",
    state_id: "FL",
    lat: 27.3564,
    lng: -80.2983,
    id: 1840029044,
  },
  {
    name: "Pembroke Park",
    state_id: "FL",
    lat: 25.9852,
    lng: -80.1777,
    id: 1840017273,
  },
  {
    name: "Lake Hallie",
    state_id: "WI",
    lat: 44.8921,
    lng: -91.4199,
    id: 1840000279,
  },
  {
    name: "Ellettsville",
    state_id: "IN",
    lat: 39.2322,
    lng: -86.6232,
    id: 1840010665,
  },
  {
    name: "Prairie Grove",
    state_id: "AR",
    lat: 35.9858,
    lng: -94.3048,
    id: 1840014481,
  },
  {
    name: "Napili-Honokowai",
    state_id: "HI",
    lat: 20.9767,
    lng: -156.6646,
    id: 1840075038,
  },
  {
    name: "Montevallo",
    state_id: "AL",
    lat: 33.1246,
    lng: -86.8476,
    id: 1840014825,
  },
  {
    name: "Mount Healthy",
    state_id: "OH",
    lat: 39.2338,
    lng: -84.5469,
    id: 1840001607,
  },
  {
    name: "Clover Creek",
    state_id: "WA",
    lat: 47.1404,
    lng: -122.3827,
    id: 1840041920,
  },
  {
    name: "Allendale",
    state_id: "NJ",
    lat: 41.0333,
    lng: -74.1333,
    id: 1840003550,
  },
  {
    name: "Rupert",
    state_id: "ID",
    lat: 42.6189,
    lng: -113.674,
    id: 1840020066,
  },
  {
    name: "Snowflake",
    state_id: "AZ",
    lat: 34.5225,
    lng: -110.0913,
    id: 1840022572,
  },
  {
    name: "Bayville",
    state_id: "NY",
    lat: 40.9076,
    lng: -73.5602,
    id: 1840005336,
  },
  {
    name: "Litchfield",
    state_id: "IL",
    lat: 39.1959,
    lng: -89.6295,
    id: 1840009601,
  },
  {
    name: "Maple Glen",
    state_id: "PA",
    lat: 40.1778,
    lng: -75.1793,
    id: 1840035082,
  },
  {
    name: "Eatonton",
    state_id: "GA",
    lat: 33.3258,
    lng: -83.3886,
    id: 1840013764,
  },
  {
    name: "Bellwood",
    state_id: "VA",
    lat: 37.406,
    lng: -77.4363,
    id: 1840006407,
  },
  {
    name: "Lacy-Lakeview",
    state_id: "TX",
    lat: 31.6292,
    lng: -97.1052,
    id: 1840020818,
  },
  {
    name: "Kosciusko",
    state_id: "MS",
    lat: 33.0584,
    lng: -89.5893,
    id: 1840014848,
  },
  {
    name: "Orchard Mesa",
    state_id: "CO",
    lat: 39.0363,
    lng: -108.5169,
    id: 1840018811,
  },
  {
    name: "Roaring Spring",
    state_id: "PA",
    lat: 40.3348,
    lng: -78.3958,
    id: 1840001086,
  },
  {
    name: "Woodfin",
    state_id: "NC",
    lat: 35.6458,
    lng: -82.5914,
    id: 1840016285,
  },
  {
    name: "Rumson",
    state_id: "NJ",
    lat: 40.3626,
    lng: -74.0046,
    id: 1840001360,
  },
  {
    name: "Broadway",
    state_id: "VA",
    lat: 38.6083,
    lng: -78.8016,
    id: 1840006136,
  },
  {
    name: "Medina",
    state_id: "MN",
    lat: 45.0326,
    lng: -93.5834,
    id: 1840007827,
  },
  {
    name: "Harrisburg",
    state_id: "SD",
    lat: 43.4321,
    lng: -96.7037,
    id: 1840002845,
  },
  {
    name: "Bermuda Dunes",
    state_id: "CA",
    lat: 33.7434,
    lng: -116.2874,
    id: 1840017981,
  },
  {
    name: "Clifton Heights",
    state_id: "PA",
    lat: 39.9301,
    lng: -75.2958,
    id: 1840000692,
  },
  {
    name: "Wescosville",
    state_id: "PA",
    lat: 40.5617,
    lng: -75.5489,
    id: 1840035250,
  },
  {
    name: "Roeland Park",
    state_id: "KS",
    lat: 39.0358,
    lng: -94.6374,
    id: 1840001640,
  },
  {
    name: "Dayton",
    state_id: "MN",
    lat: 45.1906,
    lng: -93.4758,
    id: 1840006750,
  },
  {
    name: "Lucas Valley-Marinwood",
    state_id: "CA",
    lat: 38.0405,
    lng: -122.5765,
    id: 1840073922,
  },
  {
    name: "Cherryville",
    state_id: "NC",
    lat: 35.3844,
    lng: -81.3781,
    id: 1840014589,
  },
  {
    name: "Belfast",
    state_id: "ME",
    lat: 44.428,
    lng: -69.0325,
    id: 1840000300,
  },
  {
    name: "Nevada",
    state_id: "IA",
    lat: 42.0186,
    lng: -93.4635,
    id: 1840008125,
  },
  {
    name: "Dunlap",
    state_id: "IN",
    lat: 41.6346,
    lng: -85.9235,
    id: 1840004814,
  },
  {
    name: "Lauderdale-by-the-Sea",
    state_id: "FL",
    lat: 26.199,
    lng: -80.0972,
    id: 1840017271,
  },
  {
    name: "Church Hill",
    state_id: "TN",
    lat: 36.5204,
    lng: -82.715,
    id: 1840014428,
  },
  {
    name: "Potomac Mills",
    state_id: "VA",
    lat: 38.6547,
    lng: -77.3012,
    id: 1840026711,
  },
  {
    name: "Linwood",
    state_id: "NJ",
    lat: 39.3436,
    lng: -74.5708,
    id: 1840016701,
  },
  {
    name: "Ashville",
    state_id: "OH",
    lat: 39.7239,
    lng: -82.9575,
    id: 1840012552,
  },
  {
    name: "Valley Falls",
    state_id: "SC",
    lat: 35.0073,
    lng: -81.9692,
    id: 1840013512,
  },
  {
    name: "Sandersville",
    state_id: "GA",
    lat: 32.9827,
    lng: -82.8089,
    id: 1840015746,
  },
  {
    name: "Blairsville",
    state_id: "PA",
    lat: 40.4325,
    lng: -79.2599,
    id: 1840000990,
  },
  {
    name: "Bridgeport",
    state_id: "TX",
    lat: 33.21,
    lng: -97.7708,
    id: 1840019384,
  },
  {
    name: "Sturtevant",
    state_id: "WI",
    lat: 42.6995,
    lng: -87.9019,
    id: 1840002486,
  },
  {
    name: "Litchfield",
    state_id: "MN",
    lat: 45.1221,
    lng: -94.5255,
    id: 1840008912,
  },
  {
    name: "Cherry Hills Village",
    state_id: "CO",
    lat: 39.6375,
    lng: -104.9481,
    id: 1840018793,
  },
  {
    name: "Notre Dame",
    state_id: "IN",
    lat: 41.7014,
    lng: -86.2378,
    id: 1840039286,
  },
  {
    name: "Pocahontas",
    state_id: "AR",
    lat: 36.2637,
    lng: -90.9706,
    id: 1840014448,
  },
  {
    name: "Morrilton",
    state_id: "AR",
    lat: 35.1558,
    lng: -92.7387,
    id: 1840014564,
  },
  {
    name: "Barrington",
    state_id: "NJ",
    lat: 39.8689,
    lng: -75.0514,
    id: 1840003771,
  },
  {
    name: "Belle Haven",
    state_id: "VA",
    lat: 38.7775,
    lng: -77.0574,
    id: 1840145044,
  },
  {
    name: "Juana Díaz",
    state_id: "PR",
    lat: 18.0532,
    lng: -66.5047,
    id: 1630023583,
  },
  {
    name: "Tecumseh",
    state_id: "OK",
    lat: 35.2639,
    lng: -96.9338,
    id: 1840021786,
  },
  {
    name: "Mecca",
    state_id: "CA",
    lat: 33.5767,
    lng: -116.0645,
    id: 1840019285,
  },
  {
    name: "Mira Monte",
    state_id: "CA",
    lat: 34.4284,
    lng: -119.2853,
    id: 1840019197,
  },
  {
    name: "Cedarhurst",
    state_id: "NY",
    lat: 40.6252,
    lng: -73.7278,
    id: 1840005339,
  },
  {
    name: "Folcroft",
    state_id: "PA",
    lat: 39.8891,
    lng: -75.277,
    id: 1840000698,
  },
  {
    name: "Pea Ridge",
    state_id: "WV",
    lat: 38.4154,
    lng: -82.3188,
    id: 1840038311,
  },
  {
    name: "Belmont",
    state_id: "VA",
    lat: 39.065,
    lng: -77.4965,
    id: 1840024496,
  },
  {
    name: "Green Knoll",
    state_id: "NJ",
    lat: 40.6048,
    lng: -74.615,
    id: 1840033460,
  },
  {
    name: "Guadalupe",
    state_id: "AZ",
    lat: 33.3664,
    lng: -111.9633,
    id: 1840021948,
  },
  {
    name: "Veneta",
    state_id: "OR",
    lat: 44.0471,
    lng: -123.3514,
    id: 1840021264,
  },
  {
    name: "North Syracuse",
    state_id: "NY",
    lat: 43.1339,
    lng: -76.1306,
    id: 1840004326,
  },
  {
    name: "Manorhaven",
    state_id: "NY",
    lat: 40.8399,
    lng: -73.7127,
    id: 1840005306,
  },
  {
    name: "Battlefield",
    state_id: "MO",
    lat: 37.1194,
    lng: -93.3683,
    id: 1840007616,
  },
  {
    name: "Puerto Real",
    state_id: "PR",
    lat: 18.0762,
    lng: -67.1861,
    id: 1630035555,
  },
  {
    name: "North Caldwell",
    state_id: "NJ",
    lat: 40.8629,
    lng: -74.2576,
    id: 1840002793,
  },
  {
    name: "Beaver Dam",
    state_id: "KY",
    lat: 37.4042,
    lng: -86.8742,
    id: 1840013232,
  },
  {
    name: "Ladera Heights",
    state_id: "CA",
    lat: 33.9972,
    lng: -118.374,
    id: 1840028381,
  },
  {
    name: "Winthrop Harbor",
    state_id: "IL",
    lat: 42.4805,
    lng: -87.8294,
    id: 1840011178,
  },
  {
    name: "Whitmore Lake",
    state_id: "MI",
    lat: 42.4235,
    lng: -83.7524,
    id: 1840006923,
  },
  {
    name: "Hillsboro",
    state_id: "OH",
    lat: 39.2123,
    lng: -83.6112,
    id: 1840007375,
  },
  {
    name: "Brewton",
    state_id: "AL",
    lat: 31.1111,
    lng: -87.0737,
    id: 1840006146,
  },
  {
    name: "Kermit",
    state_id: "TX",
    lat: 31.854,
    lng: -103.0924,
    id: 1840020796,
  },
  {
    name: "Demopolis",
    state_id: "AL",
    lat: 32.498,
    lng: -87.8298,
    id: 1840014897,
  },
  {
    name: "Talent",
    state_id: "OR",
    lat: 42.2404,
    lng: -122.7807,
    id: 1840021300,
  },
  {
    name: "Hamilton",
    state_id: "AL",
    lat: 34.1346,
    lng: -87.9755,
    id: 1840003629,
  },
  {
    name: "Trinity",
    state_id: "NC",
    lat: 35.8756,
    lng: -80.0093,
    id: 1840015374,
  },
  {
    name: "Normandy Park",
    state_id: "WA",
    lat: 47.4343,
    lng: -122.3436,
    id: 1840019832,
  },
  {
    name: "Bridge City",
    state_id: "LA",
    lat: 29.9321,
    lng: -90.1594,
    id: 1840013989,
  },
  {
    name: "Ambridge",
    state_id: "PA",
    lat: 40.5922,
    lng: -80.2265,
    id: 1840001010,
  },
  {
    name: "Amite City",
    state_id: "LA",
    lat: 30.7323,
    lng: -90.5133,
    id: 1840036149,
  },
  {
    name: "Catasauqua",
    state_id: "PA",
    lat: 40.6531,
    lng: -75.4643,
    id: 1840001045,
  },
  {
    name: "Cocoa West",
    state_id: "FL",
    lat: 28.3595,
    lng: -80.7712,
    id: 1840073847,
  },
  {
    name: "Forestbrook",
    state_id: "SC",
    lat: 33.7243,
    lng: -78.9678,
    id: 1840035797,
  },
  {
    name: "Bethel",
    state_id: "AK",
    lat: 60.7928,
    lng: -161.7917,
    id: 1840023353,
  },
  {
    name: "Ewa Villages",
    state_id: "HI",
    lat: 21.3418,
    lng: -158.039,
    id: 1840029578,
  },
  {
    name: "Northgate",
    state_id: "OH",
    lat: 39.2531,
    lng: -84.5943,
    id: 1840034390,
  },
  {
    name: "Oneonta",
    state_id: "AL",
    lat: 33.9392,
    lng: -86.4929,
    id: 1840005206,
  },
  {
    name: "Mont Belvieu",
    state_id: "TX",
    lat: 29.8524,
    lng: -94.8784,
    id: 1840020942,
  },
  {
    name: "South Monrovia Island",
    state_id: "CA",
    lat: 34.1234,
    lng: -117.9958,
    id: 1840043043,
  },
  {
    name: "Cold Spring",
    state_id: "KY",
    lat: 39.013,
    lng: -84.4349,
    id: 1840014257,
  },
  {
    name: "Keene",
    state_id: "TX",
    lat: 32.3955,
    lng: -97.3226,
    id: 1840019469,
  },
  {
    name: "Walnut Ridge",
    state_id: "AR",
    lat: 36.0851,
    lng: -90.9463,
    id: 1840015327,
  },
  {
    name: "Thompson's Station",
    state_id: "TN",
    lat: 35.809,
    lng: -86.8994,
    id: 1840016218,
  },
  {
    name: "Stickney",
    state_id: "IL",
    lat: 41.8183,
    lng: -87.773,
    id: 1840011326,
  },
  {
    name: "Green Hill",
    state_id: "TN",
    lat: 36.2349,
    lng: -86.5733,
    id: 1840013350,
  },
  {
    name: "Rossford",
    state_id: "OH",
    lat: 41.5832,
    lng: -83.5692,
    id: 1840000624,
  },
  {
    name: "Blackwell",
    state_id: "OK",
    lat: 36.8011,
    lng: -97.3008,
    id: 1840018980,
  },
  {
    name: "Contra Costa Centre",
    state_id: "CA",
    lat: 37.9261,
    lng: -122.054,
    id: 1840028422,
  },
  {
    name: "Lawrence",
    state_id: "NY",
    lat: 40.6042,
    lng: -73.7149,
    id: 1840005303,
  },
  {
    name: "Roslyn Heights",
    state_id: "NY",
    lat: 40.7787,
    lng: -73.6396,
    id: 1840005254,
  },
  {
    name: "South Hill",
    state_id: "NY",
    lat: 42.4113,
    lng: -76.4883,
    id: 1840004541,
  },
  {
    name: "Tuckahoe",
    state_id: "NY",
    lat: 40.953,
    lng: -73.823,
    id: 1840004946,
  },
  {
    name: "Waikoloa Village",
    state_id: "HI",
    lat: 19.9285,
    lng: -155.8185,
    id: 1840029587,
  },
  {
    name: "Keyes",
    state_id: "CA",
    lat: 37.5618,
    lng: -120.9088,
    id: 1840017618,
  },
  {
    name: "Cumming",
    state_id: "GA",
    lat: 34.2064,
    lng: -84.1337,
    id: 1840014712,
  },
  {
    name: "Portage",
    state_id: "PA",
    lat: 40.3868,
    lng: -78.6746,
    id: 1840001122,
  },
  {
    name: "Fox Point",
    state_id: "WI",
    lat: 43.1581,
    lng: -87.9013,
    id: 1840003042,
  },
  {
    name: "Slippery Rock",
    state_id: "PA",
    lat: 41.0695,
    lng: -80.058,
    id: 1840000874,
  },
  {
    name: "Clairton",
    state_id: "PA",
    lat: 40.2976,
    lng: -79.8853,
    id: 1840001219,
  },
  {
    name: "Oakmont",
    state_id: "PA",
    lat: 40.5201,
    lng: -79.8365,
    id: 1840001250,
  },
  {
    name: "Fairview",
    state_id: "GA",
    lat: 34.9296,
    lng: -85.294,
    id: 1840013084,
  },
  {
    name: "Plaquemine",
    state_id: "LA",
    lat: 30.2834,
    lng: -91.2429,
    id: 1840015037,
  },
  {
    name: "Elkin",
    state_id: "NC",
    lat: 36.2621,
    lng: -80.8441,
    id: 1840016031,
  },
  {
    name: "Plattsmouth",
    state_id: "NE",
    lat: 41.0053,
    lng: -95.894,
    id: 1840009355,
  },
  {
    name: "De Queen",
    state_id: "AR",
    lat: 34.0426,
    lng: -94.342,
    id: 1840014735,
  },
  {
    name: "Hapeville",
    state_id: "GA",
    lat: 33.6609,
    lng: -84.4093,
    id: 1840013663,
  },
  {
    name: "Laurence Harbor",
    state_id: "NJ",
    lat: 40.4489,
    lng: -74.2494,
    id: 1840005420,
  },
  {
    name: "Atlantic",
    state_id: "IA",
    lat: 41.3957,
    lng: -95.0138,
    id: 1840007125,
  },
  {
    name: "Castroville",
    state_id: "CA",
    lat: 36.765,
    lng: -121.7535,
    id: 1840017682,
  },
  {
    name: "Russells Point",
    state_id: "OH",
    lat: 40.468,
    lng: -83.8939,
    id: 1840012155,
  },
  {
    name: "Martins Ferry",
    state_id: "OH",
    lat: 40.1014,
    lng: -80.7253,
    id: 1840008388,
  },
  {
    name: "West View",
    state_id: "PA",
    lat: 40.5182,
    lng: -80.0333,
    id: 1840001274,
  },
  {
    name: "Milford",
    state_id: "MI",
    lat: 42.587,
    lng: -83.6012,
    id: 1840011058,
  },
  {
    name: "Kingston",
    state_id: "RI",
    lat: 41.4738,
    lng: -71.5236,
    id: 1840003365,
  },
  {
    name: "Air Force Academy",
    state_id: "CO",
    lat: 38.9942,
    lng: -104.8639,
    id: 1840036508,
  },
  {
    name: "De Soto",
    state_id: "KS",
    lat: 38.9686,
    lng: -94.9548,
    id: 1840001633,
  },
  {
    name: "Avon",
    state_id: "CO",
    lat: 39.6445,
    lng: -106.5133,
    id: 1840020194,
  },
  {
    name: "Heathcote",
    state_id: "NJ",
    lat: 40.3908,
    lng: -74.5756,
    id: 1840033480,
  },
  {
    name: "Naples Manor",
    state_id: "FL",
    lat: 26.0892,
    lng: -81.7254,
    id: 1840014232,
  },
  {
    name: "Rittman",
    state_id: "OH",
    lat: 40.9736,
    lng: -81.7849,
    id: 1840009372,
  },
  {
    name: "Bangor Base",
    state_id: "WA",
    lat: 47.7227,
    lng: -122.7146,
    id: 1840036750,
  },
  {
    name: "Anadarko",
    state_id: "OK",
    lat: 35.0652,
    lng: -98.2441,
    id: 1840019159,
  },
  {
    name: "Schriever",
    state_id: "LA",
    lat: 29.7334,
    lng: -90.831,
    id: 1840014033,
  },
  {
    name: "Harrah",
    state_id: "OK",
    lat: 35.4779,
    lng: -97.1857,
    id: 1840020424,
  },
  {
    name: "Monticello",
    state_id: "KY",
    lat: 36.8404,
    lng: -84.8506,
    id: 1840014404,
  },
  {
    name: "Williamston",
    state_id: "MI",
    lat: 42.6835,
    lng: -84.2836,
    id: 1840000420,
  },
  {
    name: "Homeacre-Lyndora",
    state_id: "PA",
    lat: 40.8721,
    lng: -79.9211,
    id: 1840073691,
  },
  {
    name: "Pratt",
    state_id: "KS",
    lat: 37.6764,
    lng: -98.7451,
    id: 1840001687,
  },
  {
    name: "Basehor",
    state_id: "KS",
    lat: 39.1332,
    lng: -94.9333,
    id: 1840007373,
  },
  {
    name: "Geneseo",
    state_id: "IL",
    lat: 41.4508,
    lng: -90.154,
    id: 1840008205,
  },
  {
    name: "Severance",
    state_id: "CO",
    lat: 40.527,
    lng: -104.8642,
    id: 1840022400,
  },
  {
    name: "Ocean Shores",
    state_id: "WA",
    lat: 46.9685,
    lng: -124.1521,
    id: 1840019849,
  },
  {
    name: "La Habra Heights",
    state_id: "CA",
    lat: 33.9602,
    lng: -117.951,
    id: 1840020511,
  },
  {
    name: "Prospect Park",
    state_id: "PA",
    lat: 39.8858,
    lng: -75.3074,
    id: 1840000707,
  },
  {
    name: "Ambler",
    state_id: "PA",
    lat: 40.1564,
    lng: -75.2215,
    id: 1840003693,
  },
  {
    name: "East Rochester",
    state_id: "NY",
    lat: 43.112,
    lng: -77.4869,
    id: 1840004289,
  },
  {
    name: "Mulvane",
    state_id: "KS",
    lat: 37.4788,
    lng: -97.2724,
    id: 1840008748,
  },
  {
    name: "Camilla",
    state_id: "GA",
    lat: 31.2337,
    lng: -84.2089,
    id: 1840013877,
  },
  {
    name: "Lynchburg",
    state_id: "TN",
    lat: 35.2846,
    lng: -86.3587,
    id: 1840036144,
  },
  {
    name: "Hillandale",
    state_id: "MD",
    lat: 39.0254,
    lng: -76.9751,
    id: 1840005841,
  },
  {
    name: "Stratmoor",
    state_id: "CO",
    lat: 38.7732,
    lng: -104.7787,
    id: 1840028582,
  },
  {
    name: "Buena Vista",
    state_id: "VA",
    lat: 37.7319,
    lng: -79.3569,
    id: 1840003851,
  },
  {
    name: "Prairie du Chien",
    state_id: "WI",
    lat: 43.0429,
    lng: -91.1373,
    id: 1840002890,
  },
  {
    name: "Silver Lake",
    state_id: "NC",
    lat: 34.1408,
    lng: -77.909,
    id: 1840032901,
  },
  {
    name: "Irvington",
    state_id: "NY",
    lat: 41.0349,
    lng: -73.8661,
    id: 1840004933,
  },
  {
    name: "Prestonsburg",
    state_id: "KY",
    lat: 37.6816,
    lng: -82.7662,
    id: 1840014346,
  },
  {
    name: "Moraine",
    state_id: "OH",
    lat: 39.6983,
    lng: -84.2459,
    id: 1840003784,
  },
  {
    name: "Penn Yan",
    state_id: "NY",
    lat: 42.6608,
    lng: -77.0536,
    id: 1840004503,
  },
  {
    name: "Forest Glen",
    state_id: "MD",
    lat: 39.0191,
    lng: -77.0445,
    id: 1840005824,
  },
  {
    name: "Scenic Oaks",
    state_id: "TX",
    lat: 29.7038,
    lng: -98.6713,
    id: 1840019650,
  },
  {
    name: "Columbia",
    state_id: "MS",
    lat: 31.2564,
    lng: -89.8266,
    id: 1840014970,
  },
  {
    name: "Genoa",
    state_id: "IL",
    lat: 42.0926,
    lng: -88.6964,
    id: 1840008133,
  },
  {
    name: "Otis Orchards-East Farms",
    state_id: "WA",
    lat: 47.703,
    lng: -117.0854,
    id: 1840037126,
  },
  {
    name: "Sidney",
    state_id: "MT",
    lat: 47.7152,
    lng: -104.168,
    id: 1840021085,
  },
  {
    name: "Darnestown",
    state_id: "MD",
    lat: 39.096,
    lng: -77.3033,
    id: 1840005833,
  },
  {
    name: "Hebron",
    state_id: "KY",
    lat: 39.0626,
    lng: -84.709,
    id: 1840026680,
  },
  {
    name: "Glendive",
    state_id: "MT",
    lat: 47.1102,
    lng: -104.7069,
    id: 1840019824,
  },
  {
    name: "Yorketown",
    state_id: "NJ",
    lat: 40.3064,
    lng: -74.3385,
    id: 1840033488,
  },
  {
    name: "Lake Murray of Richland",
    state_id: "SC",
    lat: 34.1209,
    lng: -81.2653,
    id: 1840073861,
  },
  {
    name: "Ironwood",
    state_id: "MI",
    lat: 46.4522,
    lng: -90.1505,
    id: 1840001942,
  },
  {
    name: "Sealy",
    state_id: "TX",
    lat: 29.7676,
    lng: -96.1679,
    id: 1840022209,
  },
  {
    name: "Emporia",
    state_id: "VA",
    lat: 36.6953,
    lng: -77.5356,
    id: 1840003875,
  },
  {
    name: "Carrollton",
    state_id: "VA",
    lat: 36.9394,
    lng: -76.5264,
    id: 1840024808,
  },
  {
    name: "Inverness Highlands South",
    state_id: "FL",
    lat: 28.8007,
    lng: -82.3371,
    id: 1840073844,
  },
  {
    name: "East Falmouth",
    state_id: "MA",
    lat: 41.5707,
    lng: -70.5556,
    id: 1840003237,
  },
  {
    name: "Upper Sandusky",
    state_id: "OH",
    lat: 40.8298,
    lng: -83.2721,
    id: 1840010346,
  },
  {
    name: "Vine Grove",
    state_id: "KY",
    lat: 37.8133,
    lng: -85.9828,
    id: 1840015218,
  },
  {
    name: "Litchfield Park",
    state_id: "AZ",
    lat: 33.5024,
    lng: -112.3586,
    id: 1840020565,
  },
  {
    name: "Devine",
    state_id: "TX",
    lat: 29.1457,
    lng: -98.9049,
    id: 1840019657,
  },
  {
    name: "Orange Lake",
    state_id: "NY",
    lat: 41.5319,
    lng: -74.0936,
    id: 1840004859,
  },
  {
    name: "Oelwein",
    state_id: "IA",
    lat: 42.6715,
    lng: -91.9133,
    id: 1840008007,
  },
  {
    name: "Carthage",
    state_id: "TX",
    lat: 32.1526,
    lng: -94.3368,
    id: 1840019497,
  },
  {
    name: "Crystal Lake",
    state_id: "FL",
    lat: 28.0371,
    lng: -81.9063,
    id: 1840029030,
  },
  {
    name: "Hurricane",
    state_id: "WV",
    lat: 38.4293,
    lng: -82.0168,
    id: 1840006180,
  },
  {
    name: "North Riverside",
    state_id: "IL",
    lat: 41.8461,
    lng: -87.8263,
    id: 1840011303,
  },
  {
    name: "Cheverly",
    state_id: "MD",
    lat: 38.9254,
    lng: -76.914,
    id: 1840005984,
  },
  {
    name: "Savage",
    state_id: "MD",
    lat: 39.1485,
    lng: -76.8228,
    id: 1840026664,
  },
  {
    name: "Bridgeport",
    state_id: "MI",
    lat: 43.3706,
    lng: -83.8828,
    id: 1840004227,
  },
  {
    name: "Valley City",
    state_id: "ND",
    lat: 46.9223,
    lng: -98.0055,
    id: 1840000160,
  },
  {
    name: "Salem",
    state_id: "IN",
    lat: 38.6048,
    lng: -86.0977,
    id: 1840009790,
  },
  {
    name: "Douglas",
    state_id: "WY",
    lat: 42.7541,
    lng: -105.3968,
    id: 1840018655,
  },
  {
    name: "Greenville",
    state_id: "IL",
    lat: 38.8866,
    lng: -89.3893,
    id: 1840008563,
  },
  {
    name: "Orlovista",
    state_id: "FL",
    lat: 28.5441,
    lng: -81.4629,
    id: 1840014096,
  },
  {
    name: "Eudora",
    state_id: "KS",
    lat: 38.9345,
    lng: -95.0957,
    id: 1840008558,
  },
  {
    name: "Walkersville",
    state_id: "MD",
    lat: 39.4832,
    lng: -77.3559,
    id: 1840005717,
  },
  {
    name: "Garrett",
    state_id: "IN",
    lat: 41.3526,
    lng: -85.1238,
    id: 1840008207,
  },
  {
    name: "Bowleys Quarters",
    state_id: "MD",
    lat: 39.3198,
    lng: -76.3858,
    id: 1840005677,
  },
  {
    name: "Littlestown",
    state_id: "PA",
    lat: 39.7452,
    lng: -77.0892,
    id: 1840000684,
  },
  {
    name: "Bronxville",
    state_id: "NY",
    lat: 40.9394,
    lng: -73.8263,
    id: 1840004949,
  },
  {
    name: "Kingston Estates",
    state_id: "NJ",
    lat: 39.9188,
    lng: -74.9898,
    id: 1840033314,
  },
  {
    name: "Burnet",
    state_id: "TX",
    lat: 30.7496,
    lng: -98.2383,
    id: 1840019567,
  },
  {
    name: "Lincroft",
    state_id: "NJ",
    lat: 40.3391,
    lng: -74.1283,
    id: 1840005449,
  },
  {
    name: "Jordan",
    state_id: "MN",
    lat: 44.6652,
    lng: -93.6351,
    id: 1840007864,
  },
  {
    name: "Hampstead",
    state_id: "MD",
    lat: 39.6104,
    lng: -76.855,
    id: 1840005699,
  },
  {
    name: "Carolina Beach",
    state_id: "NC",
    lat: 34.0396,
    lng: -77.8966,
    id: 1840015575,
  },
  {
    name: "Mount Vernon",
    state_id: "IA",
    lat: 41.923,
    lng: -91.4244,
    id: 1840008100,
  },
  {
    name: "Lionville",
    state_id: "PA",
    lat: 40.0524,
    lng: -75.644,
    id: 1840005502,
  },
  {
    name: "Ramtown",
    state_id: "NJ",
    lat: 40.1144,
    lng: -74.1492,
    id: 1840033393,
  },
  {
    name: "Rockford",
    state_id: "MI",
    lat: 43.1266,
    lng: -85.5582,
    id: 1840002924,
  },
  {
    name: "Steilacoom",
    state_id: "WA",
    lat: 47.1703,
    lng: -122.5934,
    id: 1840022309,
  },
  {
    name: "Opp",
    state_id: "AL",
    lat: 31.2848,
    lng: -86.257,
    id: 1840014960,
  },
  {
    name: "Abilene",
    state_id: "KS",
    lat: 38.9229,
    lng: -97.2251,
    id: 1840001629,
  },
  {
    name: "Rhome",
    state_id: "TX",
    lat: 33.0647,
    lng: -97.4779,
    id: 1840020632,
  },
  {
    name: "Aptos",
    state_id: "CA",
    lat: 36.9912,
    lng: -121.8934,
    id: 1840017648,
  },
  {
    name: "Belpre",
    state_id: "OH",
    lat: 39.2815,
    lng: -81.5971,
    id: 1840001544,
  },
  {
    name: "Crockett",
    state_id: "TX",
    lat: 31.3177,
    lng: -95.4564,
    id: 1840019547,
  },
  {
    name: "Ellenville",
    state_id: "NY",
    lat: 41.7009,
    lng: -74.3609,
    id: 1840004733,
  },
  {
    name: "East End",
    state_id: "AR",
    lat: 34.5554,
    lng: -92.3261,
    id: 1840013086,
  },
  {
    name: "Rushville",
    state_id: "IN",
    lat: 39.6172,
    lng: -85.4463,
    id: 1840014235,
  },
  {
    name: "International Falls",
    state_id: "MN",
    lat: 48.5884,
    lng: -93.4083,
    id: 1840007684,
  },
  {
    name: "Rogersville",
    state_id: "TN",
    lat: 36.4081,
    lng: -83.0043,
    id: 1840017699,
  },
  {
    name: "Prosser",
    state_id: "WA",
    lat: 46.2068,
    lng: -119.7662,
    id: 1840019890,
  },
  {
    name: "West Point",
    state_id: "NY",
    lat: 41.3642,
    lng: -74.0118,
    id: 1840004866,
  },
  {
    name: "Nephi",
    state_id: "UT",
    lat: 39.7072,
    lng: -111.8323,
    id: 1840020188,
  },
  {
    name: "Richboro",
    state_id: "PA",
    lat: 40.2262,
    lng: -75.0006,
    id: 1840005407,
  },
  {
    name: "Cullowhee",
    state_id: "NC",
    lat: 35.3107,
    lng: -83.1815,
    id: 1840013072,
  },
  {
    name: "Mims",
    state_id: "FL",
    lat: 28.6928,
    lng: -80.8468,
    id: 1840014078,
  },
  {
    name: "Village of Oak Creek",
    state_id: "AZ",
    lat: 34.7813,
    lng: -111.7606,
    id: 1840036808,
  },
  {
    name: "Jasper",
    state_id: "GA",
    lat: 34.471,
    lng: -84.4496,
    id: 1840014687,
  },
  {
    name: "Nitro",
    state_id: "WV",
    lat: 38.4119,
    lng: -81.8194,
    id: 1840006199,
  },
  {
    name: "Wharton",
    state_id: "NJ",
    lat: 40.8999,
    lng: -74.5808,
    id: 1840000955,
  },
  {
    name: "Johnsburg",
    state_id: "IL",
    lat: 42.383,
    lng: -88.2476,
    id: 1840011128,
  },
  {
    name: "Fort Riley",
    state_id: "KS",
    lat: 39.1111,
    lng: -96.8138,
    id: 1840073757,
  },
  {
    name: "Sunbury",
    state_id: "OH",
    lat: 40.2485,
    lng: -82.8804,
    id: 1840003707,
  },
  {
    name: "Wollochet",
    state_id: "WA",
    lat: 47.2828,
    lng: -122.5769,
    id: 1840023843,
  },
  {
    name: "Keyser",
    state_id: "WV",
    lat: 39.4394,
    lng: -78.9822,
    id: 1840005723,
  },
  {
    name: "Kathleen",
    state_id: "FL",
    lat: 28.1224,
    lng: -82.0387,
    id: 1840014129,
  },
  {
    name: "Combee Settlement",
    state_id: "FL",
    lat: 28.0596,
    lng: -81.9053,
    id: 1840029027,
  },
  {
    name: "Henderson",
    state_id: "TN",
    lat: 35.4446,
    lng: -88.6531,
    id: 1840014550,
  },
  {
    name: "Cynthiana",
    state_id: "KY",
    lat: 38.386,
    lng: -84.2993,
    id: 1840014283,
  },
  {
    name: "Tabor City",
    state_id: "NC",
    lat: 34.1538,
    lng: -78.8737,
    id: 1840016665,
  },
  {
    name: "North Fort Lewis",
    state_id: "WA",
    lat: 47.122,
    lng: -122.5966,
    id: 1840042007,
  },
  {
    name: "West Hattiesburg",
    state_id: "MS",
    lat: 31.3114,
    lng: -89.374,
    id: 1840032564,
  },
  {
    name: "Allegan",
    state_id: "MI",
    lat: 42.5299,
    lng: -85.8462,
    id: 1840003114,
  },
  {
    name: "Dacula",
    state_id: "GA",
    lat: 33.9816,
    lng: -83.8951,
    id: 1840014743,
  },
  {
    name: "Schuyler",
    state_id: "NE",
    lat: 41.4497,
    lng: -97.0619,
    id: 1840009252,
  },
  {
    name: "Tipton",
    state_id: "IN",
    lat: 40.282,
    lng: -86.0422,
    id: 1840010448,
  },
  {
    name: "Clarkston Heights-Vineland",
    state_id: "WA",
    lat: 46.3876,
    lng: -117.0831,
    id: 1840073891,
  },
  {
    name: "Galax",
    state_id: "VA",
    lat: 36.6661,
    lng: -80.9176,
    id: 1840003877,
  },
  {
    name: "Jan Phyl Village",
    state_id: "FL",
    lat: 28.0201,
    lng: -81.7933,
    id: 1840028708,
  },
  {
    name: "Swarthmore",
    state_id: "PA",
    lat: 39.9023,
    lng: -75.3488,
    id: 1840000712,
  },
  {
    name: "Carmel Hamlet",
    state_id: "NY",
    lat: 41.415,
    lng: -73.6855,
    id: 1840073604,
  },
  {
    name: "Gladewater",
    state_id: "TX",
    lat: 32.5426,
    lng: -94.9465,
    id: 1840020745,
  },
  {
    name: "Iowa Park",
    state_id: "TX",
    lat: 33.9622,
    lng: -98.6819,
    id: 1840019279,
  },
  {
    name: "Yaphank",
    state_id: "NY",
    lat: 40.8323,
    lng: -72.9233,
    id: 1840005116,
  },
  {
    name: "St. John",
    state_id: "MO",
    lat: 38.7148,
    lng: -90.3462,
    id: 1840073797,
  },
  {
    name: "Theodore",
    state_id: "AL",
    lat: 30.5408,
    lng: -88.1884,
    id: 1840013895,
  },
  {
    name: "Pepper Pike",
    state_id: "OH",
    lat: 41.4801,
    lng: -81.4619,
    id: 1840003408,
  },
  {
    name: "Red Lion",
    state_id: "PA",
    lat: 39.8986,
    lng: -76.6078,
    id: 1840001461,
  },
  {
    name: "Hamlet",
    state_id: "NC",
    lat: 34.8891,
    lng: -79.7099,
    id: 1840013516,
  },
  {
    name: "White Oak",
    state_id: "TX",
    lat: 32.5313,
    lng: -94.8564,
    id: 1840022080,
  },
  {
    name: "Comstock Northwest",
    state_id: "MI",
    lat: 42.3219,
    lng: -85.518,
    id: 1840073785,
  },
  {
    name: "Islamorada, Village of Islands",
    state_id: "FL",
    lat: 24.9408,
    lng: -80.6097,
    id: 1840073890,
  },
  {
    name: "Mascotte",
    state_id: "FL",
    lat: 28.611,
    lng: -81.9107,
    id: 1840015951,
  },
  {
    name: "Pass Christian",
    state_id: "MS",
    lat: 30.327,
    lng: -89.2436,
    id: 1840015024,
  },
  {
    name: "Jeanerette",
    state_id: "LA",
    lat: 29.9157,
    lng: -91.6758,
    id: 1840015054,
  },
  {
    name: "Delano",
    state_id: "MN",
    lat: 45.0383,
    lng: -93.7922,
    id: 1840006710,
  },
  {
    name: "Waveland",
    state_id: "MS",
    lat: 30.293,
    lng: -89.3904,
    id: 1840015918,
  },
  {
    name: "North Hobbs",
    state_id: "NM",
    lat: 32.7731,
    lng: -103.125,
    id: 1840043200,
  },
  {
    name: "Forest Hills",
    state_id: "PA",
    lat: 40.4251,
    lng: -79.8544,
    id: 1840001232,
  },
  {
    name: "El Rio",
    state_id: "CA",
    lat: 34.2452,
    lng: -119.1568,
    id: 1840017913,
  },
  {
    name: "Lely Resort",
    state_id: "FL",
    lat: 26.0889,
    lng: -81.7031,
    id: 1840028991,
  },
  {
    name: "East Porterville",
    state_id: "CA",
    lat: 36.0573,
    lng: -118.9713,
    id: 1840017691,
  },
  {
    name: "Ranchettes",
    state_id: "WY",
    lat: 41.2186,
    lng: -104.7729,
    id: 1840038441,
  },
  {
    name: "Boiling Spring Lakes",
    state_id: "NC",
    lat: 34.0322,
    lng: -78.068,
    id: 1840013635,
  },
  {
    name: "Stone Mountain",
    state_id: "GA",
    lat: 33.8034,
    lng: -84.1724,
    id: 1840015645,
  },
  {
    name: "Covedale",
    state_id: "OH",
    lat: 39.1267,
    lng: -84.637,
    id: 1840034226,
  },
  {
    name: "California",
    state_id: "PA",
    lat: 40.0692,
    lng: -79.9152,
    id: 1840003642,
  },
  {
    name: "St. Robert",
    state_id: "MO",
    lat: 37.8244,
    lng: -92.1532,
    id: 1840009866,
  },
  {
    name: "New Castle",
    state_id: "CO",
    lat: 39.5776,
    lng: -107.5267,
    id: 1840022429,
  },
  {
    name: "Howland Center",
    state_id: "OH",
    lat: 41.2483,
    lng: -80.7444,
    id: 1840034386,
  },
  {
    name: "Pigeon Forge",
    state_id: "TN",
    lat: 35.7977,
    lng: -83.5623,
    id: 1840014500,
  },
  {
    name: "Huntington Woods",
    state_id: "MI",
    lat: 42.4816,
    lng: -83.1685,
    id: 1840002445,
  },
  {
    name: "Richmond",
    state_id: "MI",
    lat: 42.8094,
    lng: -82.7518,
    id: 1840003091,
  },
  {
    name: "Fairwood",
    state_id: "MD",
    lat: 38.9565,
    lng: -76.778,
    id: 1840039458,
  },
  {
    name: "East Glenville",
    state_id: "NY",
    lat: 42.8614,
    lng: -73.9206,
    id: 1840004444,
  },
  {
    name: "Lake Alfred",
    state_id: "FL",
    lat: 28.1041,
    lng: -81.7264,
    id: 1840015106,
  },
  {
    name: "Snyderville",
    state_id: "UT",
    lat: 40.7042,
    lng: -111.5438,
    id: 1840024187,
  },
  {
    name: "Morris Plains",
    state_id: "NJ",
    lat: 40.8357,
    lng: -74.4786,
    id: 1840003576,
  },
  {
    name: "Powell",
    state_id: "WY",
    lat: 44.7958,
    lng: -108.7681,
    id: 1840019976,
  },
  {
    name: "Hampshire",
    state_id: "IL",
    lat: 42.1124,
    lng: -88.5122,
    id: 1840011343,
  },
  {
    name: "Enola",
    state_id: "PA",
    lat: 40.2908,
    lng: -76.9348,
    id: 1840005483,
  },
  {
    name: "Salton City",
    state_id: "CA",
    lat: 33.2994,
    lng: -115.9609,
    id: 1840019374,
  },
  {
    name: "Blue Bell",
    state_id: "PA",
    lat: 40.1474,
    lng: -75.2687,
    id: 1840034868,
  },
  {
    name: "Corcoran",
    state_id: "MN",
    lat: 45.1089,
    lng: -93.5837,
    id: 1840006744,
  },
  {
    name: "Ocean City",
    state_id: "FL",
    lat: 30.4398,
    lng: -86.6071,
    id: 1840013922,
  },
  {
    name: "Walker",
    state_id: "LA",
    lat: 30.485,
    lng: -90.8656,
    id: 1840017185,
  },
  {
    name: "St. James",
    state_id: "NC",
    lat: 33.9467,
    lng: -78.1132,
    id: 1840017954,
  },
  {
    name: "Camp Swift",
    state_id: "TX",
    lat: 30.1885,
    lng: -97.2933,
    id: 1840018243,
  },
  {
    name: "South Run",
    state_id: "VA",
    lat: 38.7467,
    lng: -77.2754,
    id: 1840041864,
  },
  {
    name: "Pecan Plantation",
    state_id: "TX",
    lat: 32.3628,
    lng: -97.6554,
    id: 1840019460,
  },
  {
    name: "New Whiteland",
    state_id: "IN",
    lat: 39.5617,
    lng: -86.0996,
    id: 1840009570,
  },
  {
    name: "Cameron Park",
    state_id: "TX",
    lat: 25.9705,
    lng: -97.4775,
    id: 1840037105,
  },
  {
    name: "Sylvester",
    state_id: "GA",
    lat: 31.53,
    lng: -83.8338,
    id: 1840015855,
  },
  {
    name: "Boonville",
    state_id: "IN",
    lat: 38.0469,
    lng: -87.2846,
    id: 1840007528,
  },
  {
    name: "Boothwyn",
    state_id: "PA",
    lat: 39.8357,
    lng: -75.4453,
    id: 1840005548,
  },
  {
    name: "Orange Beach",
    state_id: "AL",
    lat: 30.2941,
    lng: -87.5851,
    id: 1840014980,
  },
  {
    name: "Buena Vista",
    state_id: "MI",
    lat: 43.4196,
    lng: -83.8992,
    id: 1840004228,
  },
  {
    name: "Solvay",
    state_id: "NY",
    lat: 43.0573,
    lng: -76.2126,
    id: 1840004328,
  },
  {
    name: "Champion Heights",
    state_id: "OH",
    lat: 41.3031,
    lng: -80.8514,
    id: 1840073783,
  },
  {
    name: "East Foothills",
    state_id: "CA",
    lat: 37.3827,
    lng: -121.8138,
    id: 1840028369,
  },
  {
    name: "South Gate Ridge",
    state_id: "FL",
    lat: 27.2856,
    lng: -82.497,
    id: 1840029079,
  },
  {
    name: "Newberry",
    state_id: "FL",
    lat: 29.6385,
    lng: -82.6057,
    id: 1840015058,
  },
  {
    name: "Saranap",
    state_id: "CA",
    lat: 37.8878,
    lng: -122.076,
    id: 1840024710,
  },
  {
    name: "Glenwood",
    state_id: "IA",
    lat: 41.0446,
    lng: -95.7408,
    id: 1840008273,
  },
  {
    name: "Kenedy",
    state_id: "TX",
    lat: 28.8176,
    lng: -97.8518,
    id: 1840019684,
  },
  {
    name: "Pimmit Hills",
    state_id: "VA",
    lat: 38.9105,
    lng: -77.1991,
    id: 1840006024,
  },
  {
    name: "Edinboro",
    state_id: "PA",
    lat: 41.8762,
    lng: -80.1246,
    id: 1840000476,
  },
  {
    name: "Fruit Heights",
    state_id: "UT",
    lat: 41.0277,
    lng: -111.9081,
    id: 1840020143,
  },
  {
    name: "Addis",
    state_id: "LA",
    lat: 30.3652,
    lng: -91.2659,
    id: 1840015915,
  },
  {
    name: "Wailea",
    state_id: "HI",
    lat: 20.6873,
    lng: -156.4291,
    id: 1840025210,
  },
  {
    name: "Chelan",
    state_id: "WA",
    lat: 47.8414,
    lng: -120.0263,
    id: 1840018369,
  },
  {
    name: "Pea Ridge",
    state_id: "AR",
    lat: 36.449,
    lng: -94.1211,
    id: 1840014443,
  },
  {
    name: "Blakely",
    state_id: "PA",
    lat: 41.4859,
    lng: -75.6012,
    id: 1840003375,
  },
  {
    name: "Grayson Valley",
    state_id: "AL",
    lat: 33.6469,
    lng: -86.6414,
    id: 1840027631,
  },
  {
    name: "Corry",
    state_id: "PA",
    lat: 41.9259,
    lng: -79.6358,
    id: 1840000474,
  },
  {
    name: "Twin Lakes",
    state_id: "WI",
    lat: 42.5192,
    lng: -88.2489,
    id: 1840003159,
  },
  {
    name: "Avra Valley",
    state_id: "AZ",
    lat: 32.4195,
    lng: -111.3393,
    id: 1840027985,
  },
  {
    name: "Kennett Square",
    state_id: "PA",
    lat: 39.8438,
    lng: -75.7113,
    id: 1840001428,
  },
  {
    name: "Everman",
    state_id: "TX",
    lat: 32.6296,
    lng: -97.2827,
    id: 1840020694,
  },
  {
    name: "Union Gap",
    state_id: "WA",
    lat: 46.5566,
    lng: -120.4977,
    id: 1840021152,
  },
  {
    name: "Satsuma",
    state_id: "AL",
    lat: 30.8577,
    lng: -88.0632,
    id: 1840015887,
  },
  {
    name: "Sheridan",
    state_id: "CO",
    lat: 39.6466,
    lng: -105.0181,
    id: 1840021440,
  },
  {
    name: "Leonardtown",
    state_id: "MD",
    lat: 38.3039,
    lng: -76.6396,
    id: 1840006232,
  },
  {
    name: "Eastman",
    state_id: "GA",
    lat: 32.1973,
    lng: -83.1714,
    id: 1840013829,
  },
  {
    name: "High Springs",
    state_id: "FL",
    lat: 29.808,
    lng: -82.5949,
    id: 1840015060,
  },
  {
    name: "University at Buffalo",
    state_id: "NY",
    lat: 43.0025,
    lng: -78.7887,
    id: 1840043236,
  },
  {
    name: "Huntingburg",
    state_id: "IN",
    lat: 38.301,
    lng: -86.9622,
    id: 1840007496,
  },
  {
    name: "Pontotoc",
    state_id: "MS",
    lat: 34.2532,
    lng: -89.0091,
    id: 1840014705,
  },
  {
    name: "East Farmingdale",
    state_id: "NY",
    lat: 40.7336,
    lng: -73.4169,
    id: 1840034046,
  },
  {
    name: "Clyde",
    state_id: "OH",
    lat: 41.3046,
    lng: -82.9782,
    id: 1840007131,
  },
  {
    name: "Eagar",
    state_id: "AZ",
    lat: 34.1058,
    lng: -109.2956,
    id: 1840021623,
  },
  {
    name: "Branford Center",
    state_id: "CT",
    lat: 41.2779,
    lng: -72.8148,
    id: 1840073557,
  },
  {
    name: "Rib Mountain",
    state_id: "WI",
    lat: 44.9196,
    lng: -89.6763,
    id: 1840038097,
  },
  {
    name: "Henryetta",
    state_id: "OK",
    lat: 35.4421,
    lng: -95.9848,
    id: 1840020398,
  },
  {
    name: "Montgomery",
    state_id: "PA",
    lat: 41.1709,
    lng: -76.874,
    id: 1840000631,
  },
  {
    name: "Simsbury Center",
    state_id: "CT",
    lat: 41.8808,
    lng: -72.8111,
    id: 1840034967,
  },
  {
    name: "Clinton",
    state_id: "IN",
    lat: 39.6608,
    lng: -87.4045,
    id: 1840007263,
  },
  {
    name: "Evansville",
    state_id: "WI",
    lat: 42.7781,
    lng: -89.2967,
    id: 1840002469,
  },
  {
    name: "Lanett",
    state_id: "AL",
    lat: 32.8571,
    lng: -85.2081,
    id: 1840014858,
  },
  {
    name: "Raleigh Hills",
    state_id: "OR",
    lat: 45.4852,
    lng: -122.7567,
    id: 1840034810,
  },
  {
    name: "Bridgewater",
    state_id: "VA",
    lat: 38.3862,
    lng: -78.9674,
    id: 1840006135,
  },
  {
    name: "Glenarden",
    state_id: "MD",
    lat: 38.9293,
    lng: -76.8577,
    id: 1840005973,
  },
  {
    name: "East Pasadena",
    state_id: "CA",
    lat: 34.1377,
    lng: -118.0776,
    id: 1840028234,
  },
  {
    name: "Pelican Bay",
    state_id: "FL",
    lat: 26.2326,
    lng: -81.8108,
    id: 1840028996,
  },
  {
    name: "West Loch Estate",
    state_id: "HI",
    lat: 21.3617,
    lng: -158.0246,
    id: 1840039268,
  },
  {
    name: "Tequesta",
    state_id: "FL",
    lat: 26.9618,
    lng: -80.1011,
    id: 1840018310,
  },
  {
    name: "Elm Grove",
    state_id: "WI",
    lat: 43.0479,
    lng: -88.0867,
    id: 1840003011,
  },
  {
    name: "Carlstadt",
    state_id: "NJ",
    lat: 40.8247,
    lng: -74.0613,
    id: 1840000895,
  },
  {
    name: "North Windham",
    state_id: "ME",
    lat: 43.8238,
    lng: -70.4288,
    id: 1840002553,
  },
  {
    name: "Cochran",
    state_id: "GA",
    lat: 32.3875,
    lng: -83.3523,
    id: 1840014890,
  },
  {
    name: "Lakeview",
    state_id: "NY",
    lat: 40.6775,
    lng: -73.6493,
    id: 1840005269,
  },
  {
    name: "Binghamton University",
    state_id: "NY",
    lat: 42.0893,
    lng: -75.9684,
    id: 1840043235,
  },
  {
    name: "Eucalyptus Hills",
    state_id: "CA",
    lat: 32.885,
    lng: -116.9453,
    id: 1840026996,
  },
  {
    name: "Arlington Heights",
    state_id: "PA",
    lat: 41.0039,
    lng: -75.2116,
    id: 1840034849,
  },
  {
    name: "Independence",
    state_id: "IA",
    lat: 42.4622,
    lng: -91.9027,
    id: 1840008058,
  },
  {
    name: "Laurel Bay",
    state_id: "SC",
    lat: 32.4599,
    lng: -80.7869,
    id: 1840013817,
  },
  {
    name: "Garrettsville",
    state_id: "OH",
    lat: 41.2843,
    lng: -81.0933,
    id: 1840000807,
  },
  {
    name: "Gardnerville",
    state_id: "NV",
    lat: 38.939,
    lng: -119.7369,
    id: 1840017563,
  },
  {
    name: "Isanti",
    state_id: "MN",
    lat: 45.4928,
    lng: -93.2407,
    id: 1840007789,
  },
  {
    name: "Punxsutawney",
    state_id: "PA",
    lat: 40.9437,
    lng: -78.9767,
    id: 1840003471,
  },
  {
    name: "Country Homes",
    state_id: "WA",
    lat: 47.7478,
    lng: -117.4196,
    id: 1840017334,
  },
  {
    name: "Wailua Homesteads",
    state_id: "HI",
    lat: 22.065,
    lng: -159.3771,
    id: 1840029591,
  },
  {
    name: "Fruitridge Pocket",
    state_id: "CA",
    lat: 38.5326,
    lng: -121.4558,
    id: 1840075898,
  },
  {
    name: "Sidney",
    state_id: "NE",
    lat: 41.134,
    lng: -102.9681,
    id: 1840009309,
  },
  {
    name: "Princeton",
    state_id: "KY",
    lat: 37.1068,
    lng: -87.8854,
    id: 1840014382,
  },
  {
    name: "Bartonville",
    state_id: "IL",
    lat: 40.6398,
    lng: -89.6608,
    id: 1840011920,
  },
  {
    name: "Chena Ridge",
    state_id: "AK",
    lat: 64.7941,
    lng: -148.0357,
    id: 1840075179,
  },
  {
    name: "Pauls Valley",
    state_id: "OK",
    lat: 34.7236,
    lng: -97.2291,
    id: 1840020479,
  },
  {
    name: "Munford",
    state_id: "TN",
    lat: 35.4433,
    lng: -89.8148,
    id: 1840014548,
  },
  {
    name: "Tarrant",
    state_id: "AL",
    lat: 33.5945,
    lng: -86.7684,
    id: 1840015671,
  },
  {
    name: "Los Chaves",
    state_id: "NM",
    lat: 34.7332,
    lng: -106.7631,
    id: 1840017903,
  },
  {
    name: "Los Ranchos de Albuquerque",
    state_id: "NM",
    lat: 35.1625,
    lng: -106.6481,
    id: 1840022795,
  },
  {
    name: "Edgewood",
    state_id: "NM",
    lat: 35.1318,
    lng: -106.2151,
    id: 1840074899,
  },
  {
    name: "Aquia Harbour",
    state_id: "VA",
    lat: 38.4597,
    lng: -77.3806,
    id: 1840006216,
  },
  {
    name: "Indian Hills",
    state_id: "NV",
    lat: 39.0894,
    lng: -119.7977,
    id: 1840033829,
  },
  {
    name: "El Granada",
    state_id: "CA",
    lat: 37.5134,
    lng: -122.466,
    id: 1840017624,
  },
  {
    name: "Stewartville",
    state_id: "MN",
    lat: 43.8605,
    lng: -92.4896,
    id: 1840008985,
  },
  {
    name: "Bensley",
    state_id: "VA",
    lat: 37.447,
    lng: -77.442,
    id: 1840006408,
  },
  {
    name: "Shrewsbury",
    state_id: "MO",
    lat: 38.5866,
    lng: -90.3282,
    id: 1840009741,
  },
  {
    name: "Alpine",
    state_id: "TX",
    lat: 30.364,
    lng: -103.665,
    id: 1840019580,
  },
  {
    name: "Harlan",
    state_id: "KY",
    lat: 36.8432,
    lng: -83.3184,
    id: 1840013280,
  },
  {
    name: "Larchmont",
    state_id: "NY",
    lat: 40.9258,
    lng: -73.7529,
    id: 1840004934,
  },
  {
    name: "Willard",
    state_id: "OH",
    lat: 41.0518,
    lng: -82.7232,
    id: 1840000818,
  },
  {
    name: "Ontario",
    state_id: "OH",
    lat: 40.771,
    lng: -82.6105,
    id: 1840009370,
  },
  {
    name: "Wheelersburg",
    state_id: "OH",
    lat: 38.7383,
    lng: -82.8421,
    id: 1840007422,
  },
  {
    name: "Cheshire Village",
    state_id: "CT",
    lat: 41.5026,
    lng: -72.8993,
    id: 1840073562,
  },
  {
    name: "Pleasure Point",
    state_id: "CA",
    lat: 36.9618,
    lng: -121.9715,
    id: 1840074532,
  },
  {
    name: "Arroyo",
    state_id: "PR",
    lat: 17.9706,
    lng: -66.0609,
    id: 1630035613,
  },
  {
    name: "Clifton Springs",
    state_id: "NY",
    lat: 42.9608,
    lng: -77.1348,
    id: 1840004416,
  },
  {
    name: "South Williamsport",
    state_id: "PA",
    lat: 41.2294,
    lng: -77.0009,
    id: 1840000636,
  },
  {
    name: "Nelsonville",
    state_id: "OH",
    lat: 39.456,
    lng: -82.2219,
    id: 1840008482,
  },
  {
    name: "Childress",
    state_id: "TX",
    lat: 34.4293,
    lng: -100.2516,
    id: 1840019250,
  },
  {
    name: "Deschutes River Woods",
    state_id: "OR",
    lat: 43.9887,
    lng: -121.3608,
    id: 1840034826,
  },
  {
    name: "Brookhaven",
    state_id: "WV",
    lat: 39.6062,
    lng: -79.8812,
    id: 1840005643,
  },
  {
    name: "Carrizo Springs",
    state_id: "TX",
    lat: 28.5266,
    lng: -99.8589,
    id: 1840019702,
  },
  {
    name: "Riverside",
    state_id: "MD",
    lat: 39.4777,
    lng: -76.2385,
    id: 1840031473,
  },
  {
    name: "Barbourville",
    state_id: "KY",
    lat: 36.8667,
    lng: -83.885,
    id: 1840013277,
  },
  {
    name: "Dacono",
    state_id: "CO",
    lat: 40.062,
    lng: -104.9484,
    id: 1840018740,
  },
  {
    name: "Union",
    state_id: "KY",
    lat: 38.9472,
    lng: -84.6731,
    id: 1840015166,
  },
  {
    name: "Tea",
    state_id: "SD",
    lat: 43.4511,
    lng: -96.834,
    id: 1840002841,
  },
  {
    name: "Rensselaer",
    state_id: "IN",
    lat: 40.9375,
    lng: -87.1684,
    id: 1840009320,
  },
  {
    name: "Pinehurst",
    state_id: "TX",
    lat: 30.1889,
    lng: -95.7017,
    id: 1840141710,
  },
  {
    name: "Finley",
    state_id: "WA",
    lat: 46.1697,
    lng: -119.0446,
    id: 1840017380,
  },
  {
    name: "Cumberland",
    state_id: "IN",
    lat: 39.7844,
    lng: -85.9458,
    id: 1840009532,
  },
  {
    name: "Chillicothe",
    state_id: "IL",
    lat: 40.9157,
    lng: -89.502,
    id: 1840007174,
  },
  {
    name: "Fort Shawnee",
    state_id: "OH",
    lat: 40.6814,
    lng: -84.1487,
    id: 1840011955,
  },
  {
    name: "Metropolis",
    state_id: "IL",
    lat: 37.1565,
    lng: -88.7083,
    id: 1840008773,
  },
  {
    name: "Maquoketa",
    state_id: "IA",
    lat: 42.0598,
    lng: -90.6651,
    id: 1840009137,
  },
  {
    name: "Watchung",
    state_id: "NJ",
    lat: 40.6432,
    lng: -74.4391,
    id: 1840001061,
  },
  {
    name: "Davenport",
    state_id: "FL",
    lat: 28.1588,
    lng: -81.6084,
    id: 1840015104,
  },
  {
    name: "Portland",
    state_id: "IN",
    lat: 40.4375,
    lng: -84.9833,
    id: 1840014175,
  },
  {
    name: "Williamsburg",
    state_id: "KY",
    lat: 36.7392,
    lng: -84.1647,
    id: 1840015267,
  },
  {
    name: "Du Quoin",
    state_id: "IL",
    lat: 38.0019,
    lng: -89.2323,
    id: 1840008673,
  },
  {
    name: "Baldwin",
    state_id: "WI",
    lat: 44.954,
    lng: -92.3709,
    id: 1840002136,
  },
  {
    name: "Elburn",
    state_id: "IL",
    lat: 41.8838,
    lng: -88.4615,
    id: 1840011347,
  },
  {
    name: "Ashton-Sandy Spring",
    state_id: "MD",
    lat: 39.1515,
    lng: -77.0065,
    id: 1840073588,
  },
  {
    name: "Kingston",
    state_id: "TN",
    lat: 35.8713,
    lng: -84.4959,
    id: 1840014499,
  },
  {
    name: "Homeland Park",
    state_id: "SC",
    lat: 34.4644,
    lng: -82.6593,
    id: 1840013578,
  },
  {
    name: "Lewistown",
    state_id: "MT",
    lat: 47.0514,
    lng: -109.4524,
    id: 1840019823,
  },
  {
    name: "Durham",
    state_id: "CA",
    lat: 39.6232,
    lng: -121.7875,
    id: 1840018775,
  },
  {
    name: "Winfield",
    state_id: "IN",
    lat: 41.4098,
    lng: -87.2623,
    id: 1840010228,
  },
  {
    name: "Cut Off",
    state_id: "LA",
    lat: 29.5164,
    lng: -90.3291,
    id: 1840013113,
  },
  {
    name: "District Heights",
    state_id: "MD",
    lat: 38.8588,
    lng: -76.8885,
    id: 1840005972,
  },
  {
    name: "Louisville",
    state_id: "MS",
    lat: 33.1224,
    lng: -89.0553,
    id: 1840015745,
  },
  {
    name: "Northern Cambria",
    state_id: "PA",
    lat: 40.6561,
    lng: -78.7784,
    id: 1840001097,
  },
  {
    name: "Pevely",
    state_id: "MO",
    lat: 38.2863,
    lng: -90.4005,
    id: 1840009810,
  },
  {
    name: "South Monroe",
    state_id: "MI",
    lat: 41.893,
    lng: -83.4179,
    id: 1840006577,
  },
  {
    name: "Naval Academy",
    state_id: "MD",
    lat: 38.9859,
    lng: -76.488,
    id: 1840073633,
  },
  {
    name: "North Hills",
    state_id: "NY",
    lat: 40.7765,
    lng: -73.6778,
    id: 1840005314,
  },
  {
    name: "Lakemoor",
    state_id: "IL",
    lat: 42.3396,
    lng: -88.2038,
    id: 1840011130,
  },
  {
    name: "Trooper",
    state_id: "PA",
    lat: 40.1489,
    lng: -75.3995,
    id: 1840005470,
  },
  {
    name: "Vail",
    state_id: "CO",
    lat: 39.6386,
    lng: -106.3607,
    id: 1840022434,
  },
  {
    name: "Inniswold",
    state_id: "LA",
    lat: 30.3982,
    lng: -91.071,
    id: 1840031059,
  },
  {
    name: "Steelton",
    state_id: "PA",
    lat: 40.2258,
    lng: -76.8254,
    id: 1840001297,
  },
  {
    name: "Northville",
    state_id: "MI",
    lat: 42.4355,
    lng: -83.489,
    id: 1840003958,
  },
  {
    name: "Johnson Lane",
    state_id: "NV",
    lat: 39.0489,
    lng: -119.7245,
    id: 1840033830,
  },
  {
    name: "Lucerne Valley",
    state_id: "CA",
    lat: 34.4427,
    lng: -116.9021,
    id: 1840025800,
  },
  {
    name: "St. Clair",
    state_id: "MO",
    lat: 38.3479,
    lng: -90.9934,
    id: 1840009645,
  },
  {
    name: "East Alton",
    state_id: "IL",
    lat: 38.884,
    lng: -90.1073,
    id: 1840012794,
  },
  {
    name: "Rocky Mount",
    state_id: "VA",
    lat: 37.0045,
    lng: -79.8854,
    id: 1840006479,
  },
  {
    name: "Savannah",
    state_id: "TX",
    lat: 33.2257,
    lng: -96.9082,
    id: 1840041547,
  },
  {
    name: "Barnhart",
    state_id: "MO",
    lat: 38.336,
    lng: -90.4046,
    id: 1840006237,
  },
  {
    name: "Mila Doce",
    state_id: "TX",
    lat: 26.223,
    lng: -97.9601,
    id: 1840037094,
  },
  {
    name: "Gowanda",
    state_id: "NY",
    lat: 42.4612,
    lng: -78.9339,
    id: 1840004590,
  },
  {
    name: "Shields",
    state_id: "MI",
    lat: 43.4174,
    lng: -84.0731,
    id: 1840004226,
  },
  {
    name: "Progreso",
    state_id: "TX",
    lat: 26.0962,
    lng: -97.9566,
    id: 1840021031,
  },
  {
    name: "Gray",
    state_id: "LA",
    lat: 29.6776,
    lng: -90.7833,
    id: 1840014030,
  },
  {
    name: "Westville",
    state_id: "IN",
    lat: 41.5375,
    lng: -86.9049,
    id: 1840010203,
  },
  {
    name: "Yoakum",
    state_id: "TX",
    lat: 29.2933,
    lng: -97.1469,
    id: 1840022242,
  },
  {
    name: "Darlington",
    state_id: "SC",
    lat: 34.3015,
    lng: -79.867,
    id: 1840014693,
  },
  {
    name: "Raton",
    state_id: "NM",
    lat: 36.8849,
    lng: -104.4396,
    id: 1840020353,
  },
  {
    name: "Goodrich",
    state_id: "MI",
    lat: 42.9147,
    lng: -83.5092,
    id: 1840011007,
  },
  {
    name: "Countryside",
    state_id: "IL",
    lat: 41.7741,
    lng: -87.8752,
    id: 1840007026,
  },
  {
    name: "Sullivan City",
    state_id: "TX",
    lat: 26.2752,
    lng: -98.5644,
    id: 1840022257,
  },
  {
    name: "Penn Wynne",
    state_id: "PA",
    lat: 39.9867,
    lng: -75.2715,
    id: 1840035305,
  },
  {
    name: "Paradise",
    state_id: "CA",
    lat: 39.7558,
    lng: -121.6063,
    id: 1840022428,
  },
  {
    name: "Pagosa Springs",
    state_id: "CO",
    lat: 37.2674,
    lng: -107.0307,
    id: 1840022565,
  },
  {
    name: "Woodcreek",
    state_id: "TX",
    lat: 30.0266,
    lng: -98.1115,
    id: 1840022191,
  },
  {
    name: "Dumfries",
    state_id: "VA",
    lat: 38.567,
    lng: -77.3233,
    id: 1840006102,
  },
  {
    name: "Homer",
    state_id: "AK",
    lat: 59.653,
    lng: -151.5255,
    id: 1840023419,
  },
  {
    name: "Wesley Hills",
    state_id: "NY",
    lat: 41.1579,
    lng: -74.0768,
    id: 1840004989,
  },
  {
    name: "Mineola",
    state_id: "TX",
    lat: 32.6461,
    lng: -95.4775,
    id: 1840020685,
  },
  {
    name: "Finderne",
    state_id: "NJ",
    lat: 40.5626,
    lng: -74.5743,
    id: 1840024274,
  },
  {
    name: "Zebulon",
    state_id: "NC",
    lat: 35.8318,
    lng: -78.3162,
    id: 1840016201,
  },
  {
    name: "Cresaptown",
    state_id: "MD",
    lat: 39.5912,
    lng: -78.855,
    id: 1840026620,
  },
  {
    name: "Granville",
    state_id: "OH",
    lat: 40.0648,
    lng: -82.5023,
    id: 1840012324,
  },
  {
    name: "Staunton",
    state_id: "IL",
    lat: 39.0117,
    lng: -89.7906,
    id: 1840009602,
  },
  {
    name: "Taylor",
    state_id: "PA",
    lat: 41.3957,
    lng: -75.7147,
    id: 1840003390,
  },
  {
    name: "Roanoke",
    state_id: "AL",
    lat: 33.1454,
    lng: -85.3694,
    id: 1840014834,
  },
  {
    name: "Greenacres",
    state_id: "CA",
    lat: 35.3831,
    lng: -119.1184,
    id: 1840026940,
  },
  {
    name: "Eagleton Village",
    state_id: "TN",
    lat: 35.7885,
    lng: -83.9363,
    id: 1840013068,
  },
  {
    name: "Selinsgrove",
    state_id: "PA",
    lat: 40.8003,
    lng: -76.8647,
    id: 1840002799,
  },
  {
    name: "Hampstead",
    state_id: "NC",
    lat: 34.3627,
    lng: -77.7318,
    id: 1840025847,
  },
  {
    name: "Norwood",
    state_id: "PA",
    lat: 39.8865,
    lng: -75.2964,
    id: 1840000705,
  },
  {
    name: "Sweetwater",
    state_id: "TN",
    lat: 35.6029,
    lng: -84.4717,
    id: 1840015415,
  },
  {
    name: "Old Tappan",
    state_id: "NJ",
    lat: 41.0163,
    lng: -73.9856,
    id: 1840000913,
  },
  {
    name: "Willard",
    state_id: "MO",
    lat: 37.2929,
    lng: -93.4171,
    id: 1840010870,
  },
  {
    name: "Madisonville",
    state_id: "TN",
    lat: 35.5233,
    lng: -84.363,
    id: 1840015414,
  },
  {
    name: "Loudon",
    state_id: "TN",
    lat: 35.7413,
    lng: -84.3704,
    id: 1840016254,
  },
  {
    name: "Slaton",
    state_id: "TX",
    lat: 33.4421,
    lng: -101.6476,
    id: 1840021982,
  },
  {
    name: "Chamberlayne",
    state_id: "VA",
    lat: 37.628,
    lng: -77.4288,
    id: 1840037466,
  },
  {
    name: "Montezuma",
    state_id: "GA",
    lat: 32.2997,
    lng: -84.0246,
    id: 1840014898,
  },
  {
    name: "Jamesburg",
    state_id: "NJ",
    lat: 40.3494,
    lng: -74.44,
    id: 1840003637,
  },
  {
    name: "Massanetta Springs",
    state_id: "VA",
    lat: 38.3899,
    lng: -78.834,
    id: 1840026720,
  },
  {
    name: "Gustine",
    state_id: "CA",
    lat: 37.2545,
    lng: -120.9949,
    id: 1840020310,
  },
  {
    name: "Nolanville",
    state_id: "TX",
    lat: 31.0754,
    lng: -97.609,
    id: 1840020853,
  },
  {
    name: "Windcrest",
    state_id: "TX",
    lat: 29.5149,
    lng: -98.3818,
    id: 1840022226,
  },
  {
    name: "Spring Ridge",
    state_id: "MD",
    lat: 39.4043,
    lng: -77.3413,
    id: 1840031475,
  },
  {
    name: "Brookville",
    state_id: "OH",
    lat: 39.8393,
    lng: -84.4176,
    id: 1840005562,
  },
  {
    name: "Mayflower Village",
    state_id: "CA",
    lat: 34.116,
    lng: -118.0096,
    id: 1840028387,
  },
  {
    name: "Marlow Heights",
    state_id: "MD",
    lat: 38.8237,
    lng: -76.9485,
    id: 1840005961,
  },
  {
    name: "Elsmere",
    state_id: "DE",
    lat: 39.7385,
    lng: -75.5946,
    id: 1840005574,
  },
  {
    name: "Turpin Hills",
    state_id: "OH",
    lat: 39.1063,
    lng: -84.3703,
    id: 1840034162,
  },
  {
    name: "Luling",
    state_id: "TX",
    lat: 29.6814,
    lng: -97.6468,
    id: 1840020937,
  },
  {
    name: "Glendale",
    state_id: "MO",
    lat: 38.5935,
    lng: -90.3825,
    id: 1840008588,
  },
  {
    name: "Hutchins",
    state_id: "TX",
    lat: 32.6421,
    lng: -96.7093,
    id: 1840019437,
  },
  {
    name: "Chester",
    state_id: "NY",
    lat: 41.357,
    lng: -74.2769,
    id: 1840004884,
  },
  {
    name: "Belton",
    state_id: "SC",
    lat: 34.5237,
    lng: -82.4937,
    id: 1840013582,
  },
  {
    name: "Margate City",
    state_id: "NJ",
    lat: 39.3307,
    lng: -74.5071,
    id: 1840003793,
  },
  {
    name: "Posen",
    state_id: "IL",
    lat: 41.6291,
    lng: -87.6858,
    id: 1840011282,
  },
  {
    name: "Dowagiac",
    state_id: "MI",
    lat: 41.9834,
    lng: -86.1126,
    id: 1840003256,
  },
  {
    name: "Alma",
    state_id: "AR",
    lat: 35.4919,
    lng: -94.2165,
    id: 1840013420,
  },
  {
    name: "Las Flores",
    state_id: "CA",
    lat: 33.5838,
    lng: -117.6235,
    id: 1840136071,
  },
  {
    name: "Franklin Center",
    state_id: "NJ",
    lat: 40.5321,
    lng: -74.5415,
    id: 1840039879,
  },
  {
    name: "McKees Rocks",
    state_id: "PA",
    lat: 40.4688,
    lng: -80.063,
    id: 1840001244,
  },
  {
    name: "Paulsboro",
    state_id: "NJ",
    lat: 39.84,
    lng: -75.2397,
    id: 1840001523,
  },
  {
    name: "New Martinsville",
    state_id: "WV",
    lat: 39.6636,
    lng: -80.8569,
    id: 1840005670,
  },
  {
    name: "Breese",
    state_id: "IL",
    lat: 38.6138,
    lng: -89.523,
    id: 1840006153,
  },
  {
    name: "Mount Arlington",
    state_id: "NJ",
    lat: 40.919,
    lng: -74.639,
    id: 1840003579,
  },
  {
    name: "Old Fig Garden",
    state_id: "CA",
    lat: 36.7989,
    lng: -119.8051,
    id: 1840076004,
  },
  {
    name: "Silver Lakes",
    state_id: "CA",
    lat: 34.7519,
    lng: -117.3431,
    id: 1840024935,
  },
  {
    name: "Harris Hill",
    state_id: "NY",
    lat: 42.973,
    lng: -78.6793,
    id: 1840004378,
  },
  {
    name: "Carterville",
    state_id: "IL",
    lat: 37.763,
    lng: -89.0841,
    id: 1840007575,
  },
  {
    name: "Orchard Homes",
    state_id: "MT",
    lat: 46.8559,
    lng: -114.0778,
    id: 1840032750,
  },
  {
    name: "Littlefield",
    state_id: "TX",
    lat: 33.9191,
    lng: -102.3349,
    id: 1840020539,
  },
  {
    name: "Prospect Park",
    state_id: "NJ",
    lat: 40.938,
    lng: -74.1736,
    id: 1840000834,
  },
  {
    name: "Willow Park",
    state_id: "TX",
    lat: 32.7548,
    lng: -97.6499,
    id: 1840022042,
  },
  {
    name: "Hartwell",
    state_id: "GA",
    lat: 34.3496,
    lng: -82.9289,
    id: 1840013614,
  },
  {
    name: "Woodcliff Lake",
    state_id: "NJ",
    lat: 41.0253,
    lng: -74.0603,
    id: 1840000924,
  },
  {
    name: "Cave Creek",
    state_id: "AZ",
    lat: 33.8513,
    lng: -111.9801,
    id: 1840022915,
  },
  {
    name: "Chelsea",
    state_id: "MI",
    lat: 42.3132,
    lng: -84.0188,
    id: 1840006976,
  },
  {
    name: "Roseland",
    state_id: "NJ",
    lat: 40.8208,
    lng: -74.3085,
    id: 1840000998,
  },
  {
    name: "Cidra",
    state_id: "PR",
    lat: 18.1775,
    lng: -66.1582,
    id: 1630035647,
  },
  {
    name: "Greenwood",
    state_id: "MO",
    lat: 38.8508,
    lng: -94.3378,
    id: 1840007397,
  },
  {
    name: "Moosic",
    state_id: "PA",
    lat: 41.3584,
    lng: -75.7027,
    id: 1840003385,
  },
  {
    name: "Hellertown",
    state_id: "PA",
    lat: 40.5811,
    lng: -75.3378,
    id: 1840000973,
  },
  {
    name: "South Hooksett",
    state_id: "NH",
    lat: 43.0337,
    lng: -71.4256,
    id: 1840002803,
  },
  {
    name: "Berne",
    state_id: "IN",
    lat: 40.6572,
    lng: -84.9555,
    id: 1840007176,
  },
  {
    name: "West Clarkston-Highland",
    state_id: "WA",
    lat: 46.4022,
    lng: -117.0628,
    id: 1840074702,
  },
  {
    name: "Eldorado at Santa Fe",
    state_id: "NM",
    lat: 35.5273,
    lng: -105.934,
    id: 1840033719,
  },
  {
    name: "Mattydale",
    state_id: "NY",
    lat: 43.0992,
    lng: -76.1388,
    id: 1840004317,
  },
  {
    name: "Harvest",
    state_id: "AL",
    lat: 34.8558,
    lng: -86.7521,
    id: 1840013544,
  },
  {
    name: "Lebanon",
    state_id: "KY",
    lat: 37.5689,
    lng: -85.2578,
    id: 1840014353,
  },
  {
    name: "Brookshire",
    state_id: "TX",
    lat: 29.7824,
    lng: -95.9515,
    id: 1840019610,
  },
  {
    name: "Bright",
    state_id: "IN",
    lat: 39.2254,
    lng: -84.8613,
    id: 1840005882,
  },
  {
    name: "Volo",
    state_id: "IL",
    lat: 42.3298,
    lng: -88.1599,
    id: 1840011182,
  },
  {
    name: "Ramblewood",
    state_id: "NJ",
    lat: 39.9322,
    lng: -74.9527,
    id: 1840033392,
  },
  {
    name: "Lake Mohegan",
    state_id: "NY",
    lat: 41.3165,
    lng: -73.8475,
    id: 1840004923,
  },
  {
    name: "Beardstown",
    state_id: "IL",
    lat: 39.9994,
    lng: -90.4178,
    id: 1840007268,
  },
  {
    name: "Manasquan",
    state_id: "NJ",
    lat: 40.1186,
    lng: -74.045,
    id: 1840003674,
  },
  {
    name: "Marianna",
    state_id: "FL",
    lat: 30.7943,
    lng: -85.226,
    id: 1840015897,
  },
  {
    name: "West Bountiful",
    state_id: "UT",
    lat: 40.8982,
    lng: -111.9082,
    id: 1840021353,
  },
  {
    name: "Fellsmere",
    state_id: "FL",
    lat: 27.7241,
    lng: -80.5975,
    id: 1840014165,
  },
  {
    name: "Mount Zion",
    state_id: "IL",
    lat: 39.7794,
    lng: -88.8834,
    id: 1840012429,
  },
  {
    name: "Piedmont",
    state_id: "SC",
    lat: 34.7074,
    lng: -82.4651,
    id: 1840013580,
  },
  {
    name: "Plymouth",
    state_id: "PA",
    lat: 41.2404,
    lng: -75.9505,
    id: 1840000767,
  },
  {
    name: "Bay Harbor Islands",
    state_id: "FL",
    lat: 25.8878,
    lng: -80.1335,
    id: 1840016005,
  },
  {
    name: "Norwood",
    state_id: "NJ",
    lat: 40.9933,
    lng: -73.951,
    id: 1840000911,
  },
  {
    name: "Patterson",
    state_id: "LA",
    lat: 29.6909,
    lng: -91.3096,
    id: 1840015057,
  },
  {
    name: "Villa Park",
    state_id: "CA",
    lat: 33.818,
    lng: -117.8104,
    id: 1840021968,
  },
  {
    name: "Highgrove",
    state_id: "CA",
    lat: 34.0106,
    lng: -117.3098,
    id: 1840017986,
  },
  {
    name: "The Village of Indian Hill",
    state_id: "OH",
    lat: 39.1916,
    lng: -84.3344,
    id: 1840034360,
  },
  {
    name: "Conley",
    state_id: "GA",
    lat: 33.6398,
    lng: -84.3376,
    id: 1840013096,
  },
  {
    name: "Little Silver",
    state_id: "NJ",
    lat: 40.3357,
    lng: -74.0346,
    id: 1840003671,
  },
  {
    name: "Edna",
    state_id: "TX",
    lat: 28.9757,
    lng: -96.6483,
    id: 1840020984,
  },
  {
    name: "Peoria Heights",
    state_id: "IL",
    lat: 40.7466,
    lng: -89.57,
    id: 1840011929,
  },
  {
    name: "Hilton",
    state_id: "NY",
    lat: 43.29,
    lng: -77.7925,
    id: 1840004281,
  },
  {
    name: "Granite Shoals",
    state_id: "TX",
    lat: 30.5897,
    lng: -98.3715,
    id: 1840020867,
  },
  {
    name: "Palmview",
    state_id: "TX",
    lat: 26.2303,
    lng: -98.3791,
    id: 1840021028,
  },
  {
    name: "Fountainhead-Orchard Hills",
    state_id: "MD",
    lat: 39.6878,
    lng: -77.7173,
    id: 1840073674,
  },
  {
    name: "Eaton",
    state_id: "CO",
    lat: 40.5256,
    lng: -104.713,
    id: 1840021359,
  },
  {
    name: "Gas City",
    state_id: "IN",
    lat: 40.4889,
    lng: -85.5946,
    id: 1840008338,
  },
  {
    name: "Brownfields",
    state_id: "LA",
    lat: 30.5467,
    lng: -91.1225,
    id: 1840031149,
  },
  {
    name: "Attalla",
    state_id: "AL",
    lat: 34.0049,
    lng: -86.104,
    id: 1840013655,
  },
  {
    name: "Belding",
    state_id: "MI",
    lat: 43.0968,
    lng: -85.2331,
    id: 1840003074,
  },
  {
    name: "Crafton",
    state_id: "PA",
    lat: 40.4333,
    lng: -80.0712,
    id: 1840001221,
  },
  {
    name: "Washingtonville",
    state_id: "NY",
    lat: 41.4296,
    lng: -74.1578,
    id: 1840004882,
  },
  {
    name: "Brush",
    state_id: "CO",
    lat: 40.2598,
    lng: -103.6323,
    id: 1840018758,
  },
  {
    name: "White City",
    state_id: "UT",
    lat: 40.5666,
    lng: -111.8636,
    id: 1840037177,
  },
  {
    name: "Trenton",
    state_id: "MO",
    lat: 40.0819,
    lng: -93.603,
    id: 1840010490,
  },
  {
    name: "Fort Lee",
    state_id: "VA",
    lat: 37.2357,
    lng: -77.3325,
    id: 1840073580,
  },
  {
    name: "Oak Point",
    state_id: "TX",
    lat: 33.1803,
    lng: -96.9911,
    id: 1840020642,
  },
  {
    name: "West Modesto",
    state_id: "CA",
    lat: 37.618,
    lng: -121.0343,
    id: 1840074716,
  },
  {
    name: "Dickson City",
    state_id: "PA",
    lat: 41.4684,
    lng: -75.6358,
    id: 1840003380,
  },
  {
    name: "Hilmar-Irwin",
    state_id: "CA",
    lat: 37.4045,
    lng: -120.8504,
    id: 1840074136,
  },
  {
    name: "Violet",
    state_id: "LA",
    lat: 29.8962,
    lng: -89.892,
    id: 1840014002,
  },
  {
    name: "White Rock",
    state_id: "NM",
    lat: 35.8075,
    lng: -106.2067,
    id: 1840019105,
  },
  {
    name: "Flanders",
    state_id: "NY",
    lat: 40.8925,
    lng: -72.6049,
    id: 1840005012,
  },
  {
    name: "Sansom Park",
    state_id: "TX",
    lat: 32.8028,
    lng: -97.4021,
    id: 1840022047,
  },
  {
    name: "North Manchester",
    state_id: "IN",
    lat: 41.0044,
    lng: -85.775,
    id: 1840009360,
  },
  {
    name: "Asbury",
    state_id: "IA",
    lat: 42.5119,
    lng: -90.7795,
    id: 1840006928,
  },
  {
    name: "Fort Wright",
    state_id: "KY",
    lat: 39.0462,
    lng: -84.5362,
    id: 1840013171,
  },
  {
    name: "Rancho Murieta",
    state_id: "CA",
    lat: 38.5085,
    lng: -121.0716,
    id: 1840028329,
  },
  {
    name: "West Yarmouth",
    state_id: "MA",
    lat: 41.6496,
    lng: -70.2487,
    id: 1840003246,
  },
  {
    name: "Warrenton",
    state_id: "OR",
    lat: 46.1685,
    lng: -123.9302,
    id: 1840021183,
  },
  {
    name: "Sinton",
    state_id: "TX",
    lat: 28.0392,
    lng: -97.5154,
    id: 1840022251,
  },
  {
    name: "Fair Haven",
    state_id: "NJ",
    lat: 40.3619,
    lng: -74.0392,
    id: 1840003681,
  },
  {
    name: "Fabens",
    state_id: "TX",
    lat: 31.5136,
    lng: -106.1521,
    id: 1840018152,
  },
  {
    name: "Lee Acres",
    state_id: "NM",
    lat: 36.7103,
    lng: -108.0725,
    id: 1840033565,
  },
  {
    name: "Naples Park",
    state_id: "FL",
    lat: 26.2633,
    lng: -81.8094,
    id: 1840014233,
  },
  {
    name: "Benton",
    state_id: "LA",
    lat: 32.6906,
    lng: -93.74,
    id: 1840015769,
  },
  {
    name: "Fort Washington",
    state_id: "PA",
    lat: 40.1407,
    lng: -75.1925,
    id: 1840005460,
  },
  {
    name: "Crosspointe",
    state_id: "VA",
    lat: 38.7253,
    lng: -77.2638,
    id: 1840041713,
  },
  {
    name: "Mount Carmel",
    state_id: "PA",
    lat: 40.7959,
    lng: -76.4121,
    id: 1840000846,
  },
  {
    name: "Oceanport",
    state_id: "NJ",
    lat: 40.316,
    lng: -74.0205,
    id: 1840001365,
  },
  {
    name: "Bellevue",
    state_id: "KY",
    lat: 39.1011,
    lng: -84.4776,
    id: 1840013164,
  },
  {
    name: "South Haven",
    state_id: "MI",
    lat: 42.4011,
    lng: -86.2677,
    id: 1840003178,
  },
  {
    name: "Paola",
    state_id: "KS",
    lat: 38.5784,
    lng: -94.862,
    id: 1840001654,
  },
  {
    name: "Monroeville",
    state_id: "AL",
    lat: 31.5162,
    lng: -87.3279,
    id: 1840005191,
  },
  {
    name: "Zapata",
    state_id: "TX",
    lat: 26.9027,
    lng: -99.2612,
    id: 1840019723,
  },
  {
    name: "South Tucson",
    state_id: "AZ",
    lat: 32.1955,
    lng: -110.9692,
    id: 1840022100,
  },
  {
    name: "Grafton",
    state_id: "WV",
    lat: 39.3409,
    lng: -80.0161,
    id: 1840005779,
  },
  {
    name: "Rio Pinar",
    state_id: "FL",
    lat: 28.5271,
    lng: -81.2628,
    id: 1840039091,
  },
  {
    name: "Ulysses",
    state_id: "KS",
    lat: 37.5774,
    lng: -101.3549,
    id: 1840010856,
  },
  {
    name: "Lewisburg",
    state_id: "PA",
    lat: 40.9642,
    lng: -76.8901,
    id: 1840003531,
  },
  {
    name: "Dripping Springs",
    state_id: "TX",
    lat: 30.1954,
    lng: -98.094,
    id: 1840019606,
  },
  {
    name: "Grafton",
    state_id: "OH",
    lat: 41.2808,
    lng: -82.0367,
    id: 1840011590,
  },
  {
    name: "Highland",
    state_id: "NY",
    lat: 41.7179,
    lng: -73.9646,
    id: 1840004716,
  },
  {
    name: "Douglass Hills",
    state_id: "KY",
    lat: 38.2366,
    lng: -85.5499,
    id: 1840014294,
  },
  {
    name: "Spring Grove",
    state_id: "IL",
    lat: 42.4543,
    lng: -88.2402,
    id: 1840011143,
  },
  {
    name: "Woods Creek",
    state_id: "WA",
    lat: 47.8821,
    lng: -121.8982,
    id: 1840037864,
  },
  {
    name: "Laie",
    state_id: "HI",
    lat: 21.6443,
    lng: -157.928,
    id: 1840029514,
  },
  {
    name: "Belle Fourche",
    state_id: "SD",
    lat: 44.6642,
    lng: -103.8564,
    id: 1840002127,
  },
  {
    name: "Chickasaw",
    state_id: "AL",
    lat: 30.7714,
    lng: -88.0793,
    id: 1840014986,
  },
  {
    name: "Nazareth",
    state_id: "PA",
    lat: 40.74,
    lng: -75.3132,
    id: 1840000974,
  },
  {
    name: "Elmwood",
    state_id: "LA",
    lat: 29.9555,
    lng: -90.188,
    id: 1840031147,
  },
  {
    name: "Sharon Hill",
    state_id: "PA",
    lat: 39.9075,
    lng: -75.2678,
    id: 1840000711,
  },
  {
    name: "Wrightsville",
    state_id: "GA",
    lat: 32.7265,
    lng: -82.7197,
    id: 1840015789,
  },
  {
    name: "Linglestown",
    state_id: "PA",
    lat: 40.3445,
    lng: -76.795,
    id: 1840005401,
  },
  {
    name: "Media",
    state_id: "PA",
    lat: 39.9198,
    lng: -75.3888,
    id: 1840000702,
  },
  {
    name: "New Brighton",
    state_id: "PA",
    lat: 40.7355,
    lng: -80.3091,
    id: 1840001026,
  },
  {
    name: "Princeton",
    state_id: "WV",
    lat: 37.3689,
    lng: -81.0961,
    id: 1840006402,
  },
  {
    name: "Hollidaysburg",
    state_id: "PA",
    lat: 40.4311,
    lng: -78.393,
    id: 1840001083,
  },
  {
    name: "Loughman",
    state_id: "FL",
    lat: 28.2381,
    lng: -81.5685,
    id: 1840014130,
  },
  {
    name: "Manchester",
    state_id: "WA",
    lat: 47.5519,
    lng: -122.5517,
    id: 1840017336,
  },
  {
    name: "Dove Valley",
    state_id: "CO",
    lat: 39.5741,
    lng: -104.8289,
    id: 1840028589,
  },
  {
    name: "Tellico Village",
    state_id: "TN",
    lat: 35.6977,
    lng: -84.2661,
    id: 1840040591,
  },
  {
    name: "Estherville",
    state_id: "IA",
    lat: 43.3998,
    lng: -94.8343,
    id: 1840007962,
  },
  {
    name: "Philomath",
    state_id: "OR",
    lat: 44.5422,
    lng: -123.3599,
    id: 1840019998,
  },
  {
    name: "Greenbrier",
    state_id: "AR",
    lat: 35.2289,
    lng: -92.3836,
    id: 1840013477,
  },
  {
    name: "Wilton",
    state_id: "CA",
    lat: 38.413,
    lng: -121.2127,
    id: 1840018848,
  },
  {
    name: "Mathis",
    state_id: "TX",
    lat: 28.0909,
    lng: -97.817,
    id: 1840021004,
  },
  {
    name: "Hartford City",
    state_id: "IN",
    lat: 40.4537,
    lng: -85.3736,
    id: 1840007231,
  },
  {
    name: "Wayne",
    state_id: "NE",
    lat: 42.2379,
    lng: -97.01,
    id: 1840010121,
  },
  {
    name: "Eastwood",
    state_id: "MI",
    lat: 42.3028,
    lng: -85.5447,
    id: 1840004656,
  },
  {
    name: "Richmond",
    state_id: "MO",
    lat: 39.2755,
    lng: -93.9731,
    id: 1840009600,
  },
  {
    name: "Gardiner",
    state_id: "ME",
    lat: 44.191,
    lng: -69.7921,
    id: 1840000303,
  },
  {
    name: "Wilmington",
    state_id: "IL",
    lat: 41.3204,
    lng: -88.164,
    id: 1840010213,
  },
  {
    name: "Surfside",
    state_id: "FL",
    lat: 25.8787,
    lng: -80.125,
    id: 1840017278,
  },
  {
    name: "Paoli",
    state_id: "PA",
    lat: 40.042,
    lng: -75.4912,
    id: 1840005501,
  },
  {
    name: "Starke",
    state_id: "FL",
    lat: 29.9474,
    lng: -82.1129,
    id: 1840015940,
  },
  {
    name: "East Flat Rock",
    state_id: "NC",
    lat: 35.2802,
    lng: -82.4171,
    id: 1840013073,
  },
  {
    name: "Dandridge",
    state_id: "TN",
    lat: 36.0285,
    lng: -83.4308,
    id: 1840016168,
  },
  {
    name: "Cambria",
    state_id: "CA",
    lat: 35.5523,
    lng: -121.0847,
    id: 1840017806,
  },
  {
    name: "Corydon",
    state_id: "IN",
    lat: 38.213,
    lng: -86.1257,
    id: 1840009820,
  },
  {
    name: "Pontoon Beach",
    state_id: "IL",
    lat: 38.7208,
    lng: -90.0609,
    id: 1840012797,
  },
  {
    name: "Byron",
    state_id: "MN",
    lat: 44.0379,
    lng: -92.6411,
    id: 1840006809,
  },
  {
    name: "Wyndmoor",
    state_id: "PA",
    lat: 40.0856,
    lng: -75.1941,
    id: 1840035271,
  },
  {
    name: "Samsula-Spruce Creek",
    state_id: "FL",
    lat: 29.0484,
    lng: -81.0628,
    id: 1840035478,
  },
  {
    name: "Zephyrhills South",
    state_id: "FL",
    lat: 28.2152,
    lng: -82.1886,
    id: 1840073858,
  },
  {
    name: "Lakehills",
    state_id: "TX",
    lat: 29.6237,
    lng: -98.9448,
    id: 1840018261,
  },
  {
    name: "Alva",
    state_id: "OK",
    lat: 36.7892,
    lng: -98.6648,
    id: 1840018976,
  },
  {
    name: "Milton",
    state_id: "WI",
    lat: 42.7751,
    lng: -88.943,
    id: 1840002473,
  },
  {
    name: "Oreland",
    state_id: "PA",
    lat: 40.1148,
    lng: -75.1801,
    id: 1840005458,
  },
  {
    name: "Groveport",
    state_id: "OH",
    lat: 39.8585,
    lng: -82.8978,
    id: 1840012401,
  },
  {
    name: "Olivarez",
    state_id: "TX",
    lat: 26.2285,
    lng: -97.9931,
    id: 1840037067,
  },
  {
    name: "Willow Springs",
    state_id: "IL",
    lat: 41.7332,
    lng: -87.8859,
    id: 1840011315,
  },
  {
    name: "Jonesborough",
    state_id: "TN",
    lat: 36.2959,
    lng: -82.4766,
    id: 1840016092,
  },
  {
    name: "Kingstree",
    state_id: "SC",
    lat: 33.6665,
    lng: -79.8292,
    id: 1840016790,
  },
  {
    name: "Anna",
    state_id: "IL",
    lat: 37.4612,
    lng: -89.2388,
    id: 1840007602,
  },
  {
    name: "Lake Delton",
    state_id: "WI",
    lat: 43.5932,
    lng: -89.7842,
    id: 1840002718,
  },
  {
    name: "Dayton",
    state_id: "KY",
    lat: 39.1127,
    lng: -84.464,
    id: 1840014259,
  },
  {
    name: "Cottleville",
    state_id: "MO",
    lat: 38.7513,
    lng: -90.6582,
    id: 1840007427,
  },
  {
    name: "Bowling Green",
    state_id: "MO",
    lat: 39.3447,
    lng: -91.2032,
    id: 1840007344,
  },
  {
    name: "Blaine",
    state_id: "WA",
    lat: 48.9839,
    lng: -122.7414,
    id: 1840018347,
  },
  {
    name: "Lackland AFB",
    state_id: "TX",
    lat: 29.3867,
    lng: -98.6179,
    id: 1840074237,
  },
  {
    name: "Elverta",
    state_id: "CA",
    lat: 38.7185,
    lng: -121.4455,
    id: 1840024623,
  },
  {
    name: "Mansfield",
    state_id: "LA",
    lat: 32.0355,
    lng: -93.7004,
    id: 1840015821,
  },
  {
    name: "Rusk",
    state_id: "TX",
    lat: 31.7978,
    lng: -95.1491,
    id: 1840020790,
  },
  {
    name: "Linton",
    state_id: "IN",
    lat: 39.0356,
    lng: -87.1586,
    id: 1840009692,
  },
  {
    name: "Semmes",
    state_id: "AL",
    lat: 30.7941,
    lng: -88.2358,
    id: 1840027491,
  },
  {
    name: "Palmview South",
    state_id: "TX",
    lat: 26.2164,
    lng: -98.378,
    id: 1840073265,
  },
  {
    name: "Honesdale",
    state_id: "PA",
    lat: 41.5774,
    lng: -75.2524,
    id: 1840003314,
  },
  {
    name: "Warren",
    state_id: "AR",
    lat: 33.6113,
    lng: -92.0677,
    id: 1840015696,
  },
  {
    name: "Tonganoxie",
    state_id: "KS",
    lat: 39.1083,
    lng: -95.0829,
    id: 1840010661,
  },
  {
    name: "Marlin",
    state_id: "TX",
    lat: 31.3085,
    lng: -96.8934,
    id: 1840020844,
  },
  {
    name: "Oxford",
    state_id: "PA",
    lat: 39.7858,
    lng: -75.9801,
    id: 1840001431,
  },
  {
    name: "Islip Terrace",
    state_id: "NY",
    lat: 40.7506,
    lng: -73.1872,
    id: 1840005083,
  },
  {
    name: "Bessemer City",
    state_id: "NC",
    lat: 35.2841,
    lng: -81.283,
    id: 1840013470,
  },
  {
    name: "Siesta Key",
    state_id: "FL",
    lat: 27.2779,
    lng: -82.5516,
    id: 1840028968,
  },
  {
    name: "New Carlisle",
    state_id: "OH",
    lat: 39.9446,
    lng: -84.0257,
    id: 1840008401,
  },
  {
    name: "Deer Park",
    state_id: "OH",
    lat: 39.2039,
    lng: -84.3977,
    id: 1840003815,
  },
  {
    name: "Slinger",
    state_id: "WI",
    lat: 43.3318,
    lng: -88.2799,
    id: 1840002828,
  },
  {
    name: "Lake Bluff",
    state_id: "IL",
    lat: 42.2826,
    lng: -87.851,
    id: 1840011158,
  },
  {
    name: "Waterford",
    state_id: "WI",
    lat: 42.7646,
    lng: -88.216,
    id: 1840002488,
  },
  {
    name: "Frankenmuth",
    state_id: "MI",
    lat: 43.3321,
    lng: -83.7395,
    id: 1840002814,
  },
  {
    name: "Eureka",
    state_id: "IL",
    lat: 40.7148,
    lng: -89.2775,
    id: 1840008294,
  },
  {
    name: "Stroudsburg",
    state_id: "PA",
    lat: 40.9838,
    lng: -75.1972,
    id: 1840003509,
  },
  {
    name: "Duquesne",
    state_id: "PA",
    lat: 40.3732,
    lng: -79.8502,
    id: 1840001224,
  },
  {
    name: "Preston",
    state_id: "ID",
    lat: 42.0989,
    lng: -111.8799,
    id: 1840020099,
  },
  {
    name: "Tracyton",
    state_id: "WA",
    lat: 47.6095,
    lng: -122.6533,
    id: 1840018408,
  },
  {
    name: "Nashville",
    state_id: "NC",
    lat: 35.9692,
    lng: -77.9555,
    id: 1840016167,
  },
  {
    name: "Leadville",
    state_id: "CO",
    lat: 39.2473,
    lng: -106.2934,
    id: 1840020228,
  },
  {
    name: "Ceiba",
    state_id: "PR",
    lat: 18.265,
    lng: -65.6488,
    id: 1630035643,
  },
  {
    name: "Berryville",
    state_id: "AR",
    lat: 36.3713,
    lng: -93.5704,
    id: 1840013323,
  },
  {
    name: "Vinita",
    state_id: "OK",
    lat: 36.6364,
    lng: -95.177,
    id: 1840021599,
  },
  {
    name: "Connell",
    state_id: "WA",
    lat: 46.6622,
    lng: -118.8404,
    id: 1840018479,
  },
  {
    name: "Belmar",
    state_id: "NJ",
    lat: 40.1798,
    lng: -74.0255,
    id: 1840001355,
  },
  {
    name: "Buckner",
    state_id: "KY",
    lat: 38.3867,
    lng: -85.4503,
    id: 1840013192,
  },
  {
    name: "Taylorsville",
    state_id: "NC",
    lat: 35.9175,
    lng: -81.1754,
    id: 1840016220,
  },
  {
    name: "Glencoe",
    state_id: "MN",
    lat: 44.7699,
    lng: -94.1512,
    id: 1840007845,
  },
  {
    name: "Wappingers Falls",
    state_id: "NY",
    lat: 41.5984,
    lng: -73.9182,
    id: 1840004755,
  },
  {
    name: "Lake Park",
    state_id: "GA",
    lat: 30.6852,
    lng: -83.1875,
    id: 1840015000,
  },
  {
    name: "Ada",
    state_id: "OH",
    lat: 40.7681,
    lng: -83.8253,
    id: 1840010371,
  },
  {
    name: "Exeter",
    state_id: "PA",
    lat: 41.3338,
    lng: -75.8214,
    id: 1840000749,
  },
  {
    name: "Hidden Valley Lake",
    state_id: "CA",
    lat: 38.8003,
    lng: -122.5505,
    id: 1840017549,
  },
  {
    name: "Waterville",
    state_id: "OH",
    lat: 41.5015,
    lng: -83.7365,
    id: 1840011479,
  },
  {
    name: "Waimanalo",
    state_id: "HI",
    lat: 21.3421,
    lng: -157.7303,
    id: 1840029558,
  },
  {
    name: "Anamosa",
    state_id: "IA",
    lat: 42.1091,
    lng: -91.2758,
    id: 1840006993,
  },
  {
    name: "Zeeland",
    state_id: "MI",
    lat: 42.8139,
    lng: -86.0129,
    id: 1840000383,
  },
  {
    name: "Forest",
    state_id: "MS",
    lat: 32.3595,
    lng: -89.4761,
    id: 1840013822,
  },
  {
    name: "Pelham Manor",
    state_id: "NY",
    lat: 40.893,
    lng: -73.8057,
    id: 1840004939,
  },
  {
    name: "Monticello",
    state_id: "IL",
    lat: 40.0341,
    lng: -88.5729,
    id: 1840008377,
  },
  {
    name: "Colorado City",
    state_id: "TX",
    lat: 32.3994,
    lng: -100.8582,
    id: 1840019472,
  },
  {
    name: "Weldon Spring",
    state_id: "MO",
    lat: 38.7118,
    lng: -90.6513,
    id: 1840010716,
  },
  {
    name: "Hidden Valley",
    state_id: "IN",
    lat: 39.1673,
    lng: -84.8444,
    id: 1840005881,
  },
  {
    name: "Shaw Heights",
    state_id: "CO",
    lat: 39.8566,
    lng: -105.039,
    id: 1840028586,
  },
  {
    name: "Falcon Heights",
    state_id: "MN",
    lat: 44.9899,
    lng: -93.177,
    id: 1840007838,
  },
  {
    name: "Strawberry",
    state_id: "CA",
    lat: 37.8925,
    lng: -122.5078,
    id: 1840136945,
  },
  {
    name: "Atlanta",
    state_id: "TX",
    lat: 33.1136,
    lng: -94.1672,
    id: 1840019407,
  },
  {
    name: "Palm Springs North",
    state_id: "FL",
    lat: 25.9351,
    lng: -80.3339,
    id: 1840047821,
  },
  {
    name: "Weiser",
    state_id: "ID",
    lat: 44.2547,
    lng: -116.9689,
    id: 1840021250,
  },
  {
    name: "Yosemite Lakes",
    state_id: "CA",
    lat: 37.1885,
    lng: -119.7723,
    id: 1840018920,
  },
  {
    name: "Clare",
    state_id: "MI",
    lat: 43.8258,
    lng: -84.763,
    id: 1840002561,
  },
  {
    name: "Highland Lakes",
    state_id: "AL",
    lat: 33.397,
    lng: -86.6497,
    id: 1840075149,
  },
  {
    name: "Germantown",
    state_id: "OH",
    lat: 39.6324,
    lng: -84.3645,
    id: 1840012489,
  },
  {
    name: "Baxley",
    state_id: "GA",
    lat: 31.7643,
    lng: -82.3508,
    id: 1840013850,
  },
  {
    name: "Santa Teresa",
    state_id: "NM",
    lat: 31.8701,
    lng: -106.6714,
    id: 1840019414,
  },
  {
    name: "Carlinville",
    state_id: "IL",
    lat: 39.2774,
    lng: -89.8761,
    id: 1840007366,
  },
  {
    name: "Sherwood Manor",
    state_id: "CT",
    lat: 42.0125,
    lng: -72.566,
    id: 1840028600,
  },
  {
    name: "Elberton",
    state_id: "GA",
    lat: 34.1064,
    lng: -82.8705,
    id: 1840013651,
  },
  {
    name: "Kayenta",
    state_id: "AZ",
    lat: 36.7144,
    lng: -110.2589,
    id: 1840017658,
  },
  {
    name: "Winston",
    state_id: "OR",
    lat: 43.12,
    lng: -123.4242,
    id: 1840021275,
  },
  {
    name: "Wellston",
    state_id: "OH",
    lat: 39.1172,
    lng: -82.5375,
    id: 1840001622,
  },
  {
    name: "Rockdale",
    state_id: "TX",
    lat: 30.6542,
    lng: -97.0089,
    id: 1840020859,
  },
  {
    name: "Holdenville",
    state_id: "OK",
    lat: 35.0836,
    lng: -96.4004,
    id: 1840020460,
  },
  {
    name: "Swartz Creek",
    state_id: "MI",
    lat: 42.9626,
    lng: -83.826,
    id: 1840002944,
  },
  {
    name: "Mena",
    state_id: "AR",
    lat: 34.5812,
    lng: -94.2369,
    id: 1840015549,
  },
  {
    name: "Stokesdale",
    state_id: "NC",
    lat: 36.2318,
    lng: -79.9834,
    id: 1840016138,
  },
  {
    name: "Fort Plain",
    state_id: "NY",
    lat: 42.9316,
    lng: -74.6277,
    id: 1840004402,
  },
  {
    name: "Kalaheo",
    state_id: "HI",
    lat: 21.9159,
    lng: -159.5236,
    id: 1840029492,
  },
  {
    name: "Wolfforth",
    state_id: "TX",
    lat: 33.5148,
    lng: -102.0066,
    id: 1840021983,
  },
  {
    name: "Fairview",
    state_id: "NY",
    lat: 41.732,
    lng: -73.9139,
    id: 1840132690,
  },
  {
    name: "Manteo",
    state_id: "NC",
    lat: 35.9013,
    lng: -75.661,
    id: 1840016156,
  },
  {
    name: "Peculiar",
    state_id: "MO",
    lat: 38.7306,
    lng: -94.4736,
    id: 1840009785,
  },
  {
    name: "Muldrow",
    state_id: "OK",
    lat: 35.4039,
    lng: -94.5969,
    id: 1840022738,
  },
  {
    name: "Breckenridge",
    state_id: "TX",
    lat: 32.7566,
    lng: -98.9125,
    id: 1840019448,
  },
  {
    name: "Somerdale",
    state_id: "NJ",
    lat: 39.8454,
    lng: -75.0218,
    id: 1840001512,
  },
  {
    name: "Sparta",
    state_id: "TN",
    lat: 35.9347,
    lng: -85.4726,
    id: 1840015354,
  },
  {
    name: "Dulles Town Center",
    state_id: "VA",
    lat: 39.0265,
    lng: -77.4196,
    id: 1840024501,
  },
  {
    name: "Indian Wells",
    state_id: "CA",
    lat: 33.7036,
    lng: -116.3396,
    id: 1840019303,
  },
  {
    name: "Williams",
    state_id: "CA",
    lat: 39.1498,
    lng: -122.1389,
    id: 1840021460,
  },
  {
    name: "Charleston",
    state_id: "MO",
    lat: 36.9179,
    lng: -89.3344,
    id: 1840007648,
  },
  {
    name: "Cameron",
    state_id: "TX",
    lat: 30.8608,
    lng: -96.9762,
    id: 1840019562,
  },
  {
    name: "New Holland",
    state_id: "PA",
    lat: 40.1008,
    lng: -76.09,
    id: 1840003725,
  },
  {
    name: "Pana",
    state_id: "IL",
    lat: 39.3828,
    lng: -89.0642,
    id: 1840009548,
  },
  {
    name: "Woodside",
    state_id: "CA",
    lat: 37.4222,
    lng: -122.2591,
    id: 1840022548,
  },
  {
    name: "Adel",
    state_id: "IA",
    lat: 41.6121,
    lng: -94.0129,
    id: 1840007053,
  },
  {
    name: "Waipio Acres",
    state_id: "HI",
    lat: 21.4689,
    lng: -158.0173,
    id: 1840029562,
  },
  {
    name: "Jim Thorpe",
    state_id: "PA",
    lat: 40.8712,
    lng: -75.7433,
    id: 1840000931,
  },
  {
    name: "Terrell Hills",
    state_id: "TX",
    lat: 29.4771,
    lng: -98.4472,
    id: 1840022224,
  },
  {
    name: "Carlisle",
    state_id: "OH",
    lat: 39.5807,
    lng: -84.3201,
    id: 1840007345,
  },
  {
    name: "Colby",
    state_id: "KS",
    lat: 39.3843,
    lng: -101.0459,
    id: 1840001553,
  },
  {
    name: "Centerville",
    state_id: "IA",
    lat: 40.7294,
    lng: -92.8718,
    id: 1840001006,
  },
  {
    name: "Jamul",
    state_id: "CA",
    lat: 32.7184,
    lng: -116.8709,
    id: 1840018021,
  },
  {
    name: "East Moriches",
    state_id: "NY",
    lat: 40.8097,
    lng: -72.7581,
    id: 1840005038,
  },
  {
    name: "Springhill",
    state_id: "LA",
    lat: 33.0019,
    lng: -93.4613,
    id: 1840015771,
  },
  {
    name: "South Pittsburg",
    state_id: "TN",
    lat: 35.0111,
    lng: -85.7183,
    id: 1840015471,
  },
  {
    name: "Eaton Rapids",
    state_id: "MI",
    lat: 42.5092,
    lng: -84.653,
    id: 1840003108,
  },
  {
    name: "Gilbertsville",
    state_id: "PA",
    lat: 40.3219,
    lng: -75.609,
    id: 1840005461,
  },
  {
    name: "Robbins",
    state_id: "IL",
    lat: 41.6431,
    lng: -87.708,
    id: 1840011288,
  },
  {
    name: "South Highpoint",
    state_id: "FL",
    lat: 27.9086,
    lng: -82.7162,
    id: 1840029006,
  },
  {
    name: "Northwood",
    state_id: "OH",
    lat: 41.6089,
    lng: -83.4836,
    id: 1840000622,
  },
  {
    name: "St. Augustine South",
    state_id: "FL",
    lat: 29.8449,
    lng: -81.3156,
    id: 1840029110,
  },
  {
    name: "Black River Falls",
    state_id: "WI",
    lat: 44.2981,
    lng: -90.842,
    id: 1840002397,
  },
  {
    name: "Honeoye Falls",
    state_id: "NY",
    lat: 42.9557,
    lng: -77.5903,
    id: 1840004282,
  },
  {
    name: "Fruitland",
    state_id: "ID",
    lat: 44.0195,
    lng: -116.9221,
    id: 1840020013,
  },
  {
    name: "Rockwood",
    state_id: "TN",
    lat: 35.8693,
    lng: -84.6731,
    id: 1840014498,
  },
  {
    name: "Coraopolis",
    state_id: "PA",
    lat: 40.5148,
    lng: -80.1627,
    id: 1840001220,
  },
  {
    name: "Shady Side",
    state_id: "MD",
    lat: 38.8285,
    lng: -76.5211,
    id: 1840005927,
  },
  {
    name: "Waynesboro",
    state_id: "GA",
    lat: 33.0909,
    lng: -82.0146,
    id: 1840015742,
  },
  {
    name: "Monaca",
    state_id: "PA",
    lat: 40.6833,
    lng: -80.2736,
    id: 1840001025,
  },
  {
    name: "Jackson",
    state_id: "GA",
    lat: 33.292,
    lng: -83.9678,
    id: 1840014836,
  },
  {
    name: "Lafayette",
    state_id: "TN",
    lat: 36.5242,
    lng: -86.0307,
    id: 1840014423,
  },
  {
    name: "Angier",
    state_id: "NC",
    lat: 35.512,
    lng: -78.7405,
    id: 1840015423,
  },
  {
    name: "Batesburg-Leesville",
    state_id: "SC",
    lat: 33.9125,
    lng: -81.5313,
    id: 1840003900,
  },
  {
    name: "Denver City",
    state_id: "TX",
    lat: 32.968,
    lng: -102.8318,
    id: 1840022029,
  },
  {
    name: "Chadron",
    state_id: "NE",
    lat: 42.826,
    lng: -103.0025,
    id: 1840006893,
  },
  {
    name: "Ferriday",
    state_id: "LA",
    lat: 31.6343,
    lng: -91.5562,
    id: 1840017075,
  },
  {
    name: "New Hempstead",
    state_id: "NY",
    lat: 41.1488,
    lng: -74.0485,
    id: 1840033996,
  },
  {
    name: "Coal City",
    state_id: "IL",
    lat: 41.2772,
    lng: -88.2803,
    id: 1840011613,
  },
  {
    name: "Canal Fulton",
    state_id: "OH",
    lat: 40.8895,
    lng: -81.5882,
    id: 1840007173,
  },
  {
    name: "Holdrege",
    state_id: "NE",
    lat: 40.4395,
    lng: -99.3773,
    id: 1840001131,
  },
  {
    name: "East Port Orchard",
    state_id: "WA",
    lat: 47.5193,
    lng: -122.6183,
    id: 1840037838,
  },
  {
    name: "Salamanca",
    state_id: "NY",
    lat: 42.1631,
    lng: -78.7233,
    id: 1840000453,
  },
  {
    name: "Valparaiso",
    state_id: "FL",
    lat: 30.4926,
    lng: -86.5079,
    id: 1840015903,
  },
  {
    name: "Valatie",
    state_id: "NY",
    lat: 42.4134,
    lng: -73.6778,
    id: 1840004632,
  },
  {
    name: "Algona",
    state_id: "IA",
    lat: 43.0743,
    lng: -94.2302,
    id: 1840000352,
  },
  {
    name: "Mocksville",
    state_id: "NC",
    lat: 35.9006,
    lng: -80.5631,
    id: 1840016212,
  },
  {
    name: "Friendship Heights Village",
    state_id: "MD",
    lat: 38.9633,
    lng: -77.09,
    id: 1840073677,
  },
  {
    name: "Palermo",
    state_id: "CA",
    lat: 39.4313,
    lng: -121.5225,
    id: 1840018777,
  },
  {
    name: "Floral City",
    state_id: "FL",
    lat: 28.7065,
    lng: -82.309,
    id: 1840014057,
  },
  {
    name: "Centerport",
    state_id: "NY",
    lat: 40.8943,
    lng: -73.3714,
    id: 1840005027,
  },
  {
    name: "Pinckneyville",
    state_id: "IL",
    lat: 38.0851,
    lng: -89.3718,
    id: 1840009844,
  },
  {
    name: "New Castle",
    state_id: "DE",
    lat: 39.6685,
    lng: -75.5692,
    id: 1840005577,
  },
  {
    name: "Smiths Station",
    state_id: "AL",
    lat: 32.5284,
    lng: -85.096,
    id: 1840015792,
  },
  {
    name: "Warm Mineral Springs",
    state_id: "FL",
    lat: 27.0469,
    lng: -82.2702,
    id: 1840014183,
  },
  {
    name: "Manitou Springs",
    state_id: "CO",
    lat: 38.8576,
    lng: -104.9128,
    id: 1840020237,
  },
  {
    name: "Northfield",
    state_id: "IL",
    lat: 42.1026,
    lng: -87.7791,
    id: 1840011305,
  },
  {
    name: "Berlin",
    state_id: "WI",
    lat: 43.9704,
    lng: -88.9505,
    id: 1840002590,
  },
  {
    name: "Central",
    state_id: "SC",
    lat: 34.7234,
    lng: -82.7787,
    id: 1840016494,
  },
  {
    name: "La Grange",
    state_id: "TX",
    lat: 29.9129,
    lng: -96.8767,
    id: 1840020934,
  },
  {
    name: "Winterset",
    state_id: "IA",
    lat: 41.3457,
    lng: -94.0137,
    id: 1840000649,
  },
  {
    name: "Lemmon Valley",
    state_id: "NV",
    lat: 39.6879,
    lng: -119.8364,
    id: 1840033785,
  },
  {
    name: "Exton",
    state_id: "PA",
    lat: 40.0307,
    lng: -75.6303,
    id: 1840001421,
  },
  {
    name: "Bellows Falls",
    state_id: "VT",
    lat: 43.1344,
    lng: -72.455,
    id: 1840004334,
  },
  {
    name: "Orwigsburg",
    state_id: "PA",
    lat: 40.6541,
    lng: -76.104,
    id: 1840002770,
  },
  {
    name: "Citrus Park",
    state_id: "AZ",
    lat: 33.5304,
    lng: -112.444,
    id: 1840022921,
  },
  {
    name: "Caldwell",
    state_id: "OH",
    lat: 39.7467,
    lng: -81.5127,
    id: 1840010565,
  },
  {
    name: "Cleveland",
    state_id: "GA",
    lat: 34.5971,
    lng: -83.7621,
    id: 1840014665,
  },
  {
    name: "Lisbon",
    state_id: "OH",
    lat: 40.7752,
    lng: -80.7628,
    id: 1840011932,
  },
  {
    name: "Polson",
    state_id: "MT",
    lat: 47.6886,
    lng: -114.1411,
    id: 1840019802,
  },
  {
    name: "Cavalero",
    state_id: "WA",
    lat: 47.9846,
    lng: -122.0743,
    id: 1840041913,
  },
  {
    name: "Kaser",
    state_id: "NY",
    lat: 41.1214,
    lng: -74.0686,
    id: 1840004977,
  },
  {
    name: "Estacada",
    state_id: "OR",
    lat: 45.2987,
    lng: -122.3338,
    id: 1840019948,
  },
  {
    name: "Chackbay",
    state_id: "LA",
    lat: 29.8817,
    lng: -90.7742,
    id: 1840014024,
  },
  {
    name: "Manchester",
    state_id: "MD",
    lat: 39.6584,
    lng: -76.8881,
    id: 1840005702,
  },
  {
    name: "Clarinda",
    state_id: "IA",
    lat: 40.738,
    lng: -95.034,
    id: 1840007180,
  },
  {
    name: "Sunset",
    state_id: "UT",
    lat: 41.1392,
    lng: -112.0285,
    id: 1840021351,
  },
  {
    name: "Wind Lake",
    state_id: "WI",
    lat: 42.823,
    lng: -88.1573,
    id: 1840006921,
  },
  {
    name: "Penn Estates",
    state_id: "PA",
    lat: 41.0346,
    lng: -75.2417,
    id: 1840035454,
  },
  {
    name: "St. Paul Park",
    state_id: "MN",
    lat: 44.836,
    lng: -92.9949,
    id: 1840008917,
  },
  {
    name: "Simonton Lake",
    state_id: "IN",
    lat: 41.7478,
    lng: -85.9657,
    id: 1840004816,
  },
  {
    name: "Ward",
    state_id: "AR",
    lat: 35.0117,
    lng: -91.9577,
    id: 1840015504,
  },
  {
    name: "Hockinson",
    state_id: "WA",
    lat: 45.7302,
    lng: -122.4833,
    id: 1840017399,
  },
  {
    name: "Añasco",
    state_id: "PR",
    lat: 18.286,
    lng: -67.1411,
    id: 1630023590,
  },
  {
    name: "Englewood Cliffs",
    state_id: "NJ",
    lat: 40.8822,
    lng: -73.9466,
    id: 1840003547,
  },
  {
    name: "James City",
    state_id: "NC",
    lat: 35.0592,
    lng: -77.02,
    id: 1840013471,
  },
  {
    name: "Anthony",
    state_id: "TX",
    lat: 31.9876,
    lng: -106.5933,
    id: 1840022138,
  },
  {
    name: "Shepherdstown",
    state_id: "WV",
    lat: 39.4318,
    lng: -77.8048,
    id: 1840005761,
  },
  {
    name: "North Oaks",
    state_id: "MN",
    lat: 45.1002,
    lng: -93.0882,
    id: 1840007842,
  },
  {
    name: "Mattawa",
    state_id: "WA",
    lat: 46.737,
    lng: -119.904,
    id: 1840022292,
  },
  {
    name: "Caruthersville",
    state_id: "MO",
    lat: 36.1814,
    lng: -89.6664,
    id: 1840007669,
  },
  {
    name: "Port LaBelle",
    state_id: "FL",
    lat: 26.7493,
    lng: -81.3876,
    id: 1840014210,
  },
  {
    name: "Williamson",
    state_id: "AZ",
    lat: 34.7082,
    lng: -112.5342,
    id: 1840019166,
  },
  {
    name: "Travelers Rest",
    state_id: "SC",
    lat: 34.9684,
    lng: -82.4417,
    id: 1840015478,
  },
  {
    name: "Rincon Valley",
    state_id: "AZ",
    lat: 32.1101,
    lng: -110.6889,
    id: 1840038089,
  },
  {
    name: "East Quogue",
    state_id: "NY",
    lat: 40.8489,
    lng: -72.5783,
    id: 1840005041,
  },
  {
    name: "Macon",
    state_id: "MO",
    lat: 39.7424,
    lng: -92.4712,
    id: 1840009486,
  },
  {
    name: "Morganfield",
    state_id: "KY",
    lat: 37.6869,
    lng: -87.8876,
    id: 1840014342,
  },
  {
    name: "Apple Valley",
    state_id: "OH",
    lat: 40.4389,
    lng: -82.3481,
    id: 1840026491,
  },
  {
    name: "Palmerton",
    state_id: "PA",
    lat: 40.8023,
    lng: -75.616,
    id: 1840003564,
  },
  {
    name: "San Leon",
    state_id: "TX",
    lat: 29.4901,
    lng: -94.9403,
    id: 1840019662,
  },
  {
    name: "Edgewater",
    state_id: "CO",
    lat: 39.7507,
    lng: -105.0626,
    id: 1840020196,
  },
  {
    name: "Jonesboro",
    state_id: "LA",
    lat: 32.2348,
    lng: -92.7098,
    id: 1840017001,
  },
  {
    name: "Morris",
    state_id: "MN",
    lat: 45.5856,
    lng: -95.9048,
    id: 1840007786,
  },
  {
    name: "McGregor",
    state_id: "TX",
    lat: 31.4187,
    lng: -97.4283,
    id: 1840020816,
  },
  {
    name: "Westernport",
    state_id: "MD",
    lat: 39.488,
    lng: -79.0429,
    id: 1840005627,
  },
  {
    name: "Kensington",
    state_id: "CA",
    lat: 37.9084,
    lng: -122.2805,
    id: 1840017613,
  },
  {
    name: "Wake Village",
    state_id: "TX",
    lat: 33.424,
    lng: -94.1188,
    id: 1840021988,
  },
  {
    name: "Avondale",
    state_id: "LA",
    lat: 29.9072,
    lng: -90.1933,
    id: 1840013987,
  },
  {
    name: "Hawaiian Beaches",
    state_id: "HI",
    lat: 19.5423,
    lng: -154.9223,
    id: 1840029580,
  },
  {
    name: "Shillington",
    state_id: "PA",
    lat: 40.3029,
    lng: -75.967,
    id: 1840001188,
  },
  {
    name: "McKenzie",
    state_id: "TN",
    lat: 36.1371,
    lng: -88.5077,
    id: 1840015346,
  },
  {
    name: "Uhrichsville",
    state_id: "OH",
    lat: 40.4005,
    lng: -81.3515,
    id: 1840010393,
  },
  {
    name: "Lehighton",
    state_id: "PA",
    lat: 40.8306,
    lng: -75.7166,
    id: 1840000933,
  },
  {
    name: "Minot AFB",
    state_id: "ND",
    lat: 48.4209,
    lng: -101.3381,
    id: 1840073766,
  },
  {
    name: "Fort Rucker",
    state_id: "AL",
    lat: 31.3428,
    lng: -85.7154,
    id: 1840073838,
  },
  {
    name: "Fruitland",
    state_id: "MD",
    lat: 38.3214,
    lng: -75.6246,
    id: 1840006218,
  },
  {
    name: "Savannah",
    state_id: "MO",
    lat: 39.9391,
    lng: -94.8279,
    id: 1840009477,
  },
  {
    name: "Fairport",
    state_id: "NY",
    lat: 43.099,
    lng: -77.4427,
    id: 1840004280,
  },
  {
    name: "Union Beach",
    state_id: "NJ",
    lat: 40.4454,
    lng: -74.1699,
    id: 1840001369,
  },
  {
    name: "Pawcatuck",
    state_id: "CT",
    lat: 41.3774,
    lng: -71.8492,
    id: 1840003353,
  },
  {
    name: "Hightstown",
    state_id: "NJ",
    lat: 40.2686,
    lng: -74.5253,
    id: 1840003708,
  },
  {
    name: "Brady",
    state_id: "TX",
    lat: 31.1322,
    lng: -99.3697,
    id: 1840019550,
  },
  {
    name: "Brent",
    state_id: "AL",
    lat: 32.9421,
    lng: -87.1753,
    id: 1840013781,
  },
  {
    name: "Eagleville",
    state_id: "PA",
    lat: 40.1604,
    lng: -75.409,
    id: 1840034937,
  },
  {
    name: "Bulverde",
    state_id: "TX",
    lat: 29.7744,
    lng: -98.4364,
    id: 1840019628,
  },
  {
    name: "Paxtonia",
    state_id: "PA",
    lat: 40.3166,
    lng: -76.7884,
    id: 1840005399,
  },
  {
    name: "Waynesville",
    state_id: "MO",
    lat: 37.8207,
    lng: -92.22,
    id: 1840010846,
  },
  {
    name: "Mount Carmel",
    state_id: "TN",
    lat: 36.562,
    lng: -82.6618,
    id: 1840016025,
  },
  {
    name: "Kings Point",
    state_id: "NY",
    lat: 40.8162,
    lng: -73.7407,
    id: 1840005299,
  },
  {
    name: "Bear Valley Springs",
    state_id: "CA",
    lat: 35.1775,
    lng: -118.646,
    id: 1840017809,
  },
  {
    name: "Orange",
    state_id: "VA",
    lat: 38.2486,
    lng: -78.1127,
    id: 1840006268,
  },
  {
    name: "Kahaluu-Keauhou",
    state_id: "HI",
    lat: 19.5726,
    lng: -155.958,
    id: 1840075021,
  },
  {
    name: "Iola",
    state_id: "KS",
    lat: 37.9274,
    lng: -95.4006,
    id: 1840001681,
  },
  {
    name: "Zephyrhills West",
    state_id: "FL",
    lat: 28.2311,
    lng: -82.2052,
    id: 1840035801,
  },
  {
    name: "Garnet",
    state_id: "CA",
    lat: 33.9179,
    lng: -116.4796,
    id: 1840026985,
  },
  {
    name: "Lake Fenton",
    state_id: "MI",
    lat: 42.8453,
    lng: -83.7086,
    id: 1840004341,
  },
  {
    name: "Old Bethpage",
    state_id: "NY",
    lat: 40.7557,
    lng: -73.4544,
    id: 1840005247,
  },
  {
    name: "South Sarasota",
    state_id: "FL",
    lat: 27.2856,
    lng: -82.5333,
    id: 1840029081,
  },
  {
    name: "Midway",
    state_id: "UT",
    lat: 40.5183,
    lng: -111.4745,
    id: 1840020165,
  },
  {
    name: "Big Bear Lake",
    state_id: "CA",
    lat: 34.2429,
    lng: -116.8951,
    id: 1840019119,
  },
  {
    name: "Titusville",
    state_id: "PA",
    lat: 41.6273,
    lng: -79.6699,
    id: 1840000562,
  },
  {
    name: "St. Clair",
    state_id: "MI",
    lat: 42.8262,
    lng: -82.493,
    id: 1840003954,
  },
  {
    name: "Grayson",
    state_id: "KY",
    lat: 38.3317,
    lng: -82.9371,
    id: 1840013196,
  },
  {
    name: "Red Oak",
    state_id: "IA",
    lat: 41.0141,
    lng: -95.2248,
    id: 1840009346,
  },
  {
    name: "Cave Springs",
    state_id: "AR",
    lat: 36.2701,
    lng: -94.2225,
    id: 1840014440,
  },
  {
    name: "Wadesboro",
    state_id: "NC",
    lat: 34.9645,
    lng: -80.0746,
    id: 1840016450,
  },
  {
    name: "Ripley",
    state_id: "MS",
    lat: 34.7321,
    lng: -88.9444,
    id: 1840014640,
  },
  {
    name: "Hawkinsville",
    state_id: "GA",
    lat: 32.2964,
    lng: -83.4814,
    id: 1840014904,
  },
  {
    name: "Forsyth",
    state_id: "GA",
    lat: 33.0347,
    lng: -83.9381,
    id: 1840013784,
  },
  {
    name: "Rothschild",
    state_id: "WI",
    lat: 44.8761,
    lng: -89.6173,
    id: 1840002172,
  },
  {
    name: "Delhi Hills",
    state_id: "OH",
    lat: 39.0871,
    lng: -84.6178,
    id: 1840034107,
  },
  {
    name: "Belvedere",
    state_id: "SC",
    lat: 33.5369,
    lng: -81.9424,
    id: 1840013720,
  },
  {
    name: "Pecan Acres",
    state_id: "TX",
    lat: 32.9703,
    lng: -97.4727,
    id: 1840019420,
  },
  {
    name: "Giddings",
    state_id: "TX",
    lat: 30.1833,
    lng: -96.9279,
    id: 1840020895,
  },
  {
    name: "Venus",
    state_id: "TX",
    lat: 32.4312,
    lng: -97.102,
    id: 1840023050,
  },
  {
    name: "Perry",
    state_id: "UT",
    lat: 41.4648,
    lng: -112.0401,
    id: 1840020119,
  },
  {
    name: "Carlyss",
    state_id: "LA",
    lat: 30.1761,
    lng: -93.3704,
    id: 1840013961,
  },
  {
    name: "Santa Isabel",
    state_id: "PR",
    lat: 17.9687,
    lng: -66.4049,
    id: 1630023545,
  },
  {
    name: "Calistoga",
    state_id: "CA",
    lat: 38.5818,
    lng: -122.5824,
    id: 1840018835,
  },
  {
    name: "Odessa",
    state_id: "MO",
    lat: 38.9988,
    lng: -93.9666,
    id: 1840008526,
  },
  {
    name: "Poolesville",
    state_id: "MD",
    lat: 39.1423,
    lng: -77.4102,
    id: 1840005858,
  },
  {
    name: "Livingston",
    state_id: "TX",
    lat: 30.71,
    lng: -94.9381,
    id: 1840022159,
  },
  {
    name: "Walkertown",
    state_id: "NC",
    lat: 36.1578,
    lng: -80.1642,
    id: 1840016125,
  },
  {
    name: "Osceola",
    state_id: "IA",
    lat: 41.0302,
    lng: -93.7829,
    id: 1840000889,
  },
  {
    name: "Yarmouth Port",
    state_id: "MA",
    lat: 41.71,
    lng: -70.2257,
    id: 1840003248,
  },
  {
    name: "Briar",
    state_id: "TX",
    lat: 32.9884,
    lng: -97.5528,
    id: 1840018035,
  },
  {
    name: "Ranson",
    state_id: "WV",
    lat: 39.325,
    lng: -77.8666,
    id: 1840038308,
  },
  {
    name: "Salem",
    state_id: "NJ",
    lat: 39.5681,
    lng: -75.4724,
    id: 1840001529,
  },
  {
    name: "Lake Wildwood",
    state_id: "CA",
    lat: 39.235,
    lng: -121.2003,
    id: 1840028313,
  },
  {
    name: "Hendron",
    state_id: "KY",
    lat: 37.0346,
    lng: -88.6437,
    id: 1840013262,
  },
  {
    name: "Bangor",
    state_id: "PA",
    lat: 40.8678,
    lng: -75.2085,
    id: 1840000966,
  },
  {
    name: "Hollywood",
    state_id: "SC",
    lat: 32.7523,
    lng: -80.2106,
    id: 1840017281,
  },
  {
    name: "Byron",
    state_id: "GA",
    lat: 32.6474,
    lng: -83.7541,
    id: 1840013820,
  },
  {
    name: "Ballston Spa",
    state_id: "NY",
    lat: 43.0068,
    lng: -73.8525,
    id: 1840004254,
  },
  {
    name: "Bisbee",
    state_id: "AZ",
    lat: 31.4126,
    lng: -109.9179,
    id: 1840019495,
  },
  {
    name: "Albertson",
    state_id: "NY",
    lat: 40.7715,
    lng: -73.6482,
    id: 1840005227,
  },
  {
    name: "Springfield",
    state_id: "GA",
    lat: 32.3634,
    lng: -81.3029,
    id: 1840015803,
  },
  {
    name: "Highwood",
    state_id: "IL",
    lat: 42.206,
    lng: -87.8128,
    id: 1840006974,
  },
  {
    name: "Chisago City",
    state_id: "MN",
    lat: 45.3474,
    lng: -92.9116,
    id: 1840000269,
  },
  {
    name: "Union City",
    state_id: "IN",
    lat: 40.1995,
    lng: -84.8206,
    id: 1840010480,
  },
  {
    name: "Myrtletown",
    state_id: "CA",
    lat: 40.7888,
    lng: -124.1286,
    id: 1840018716,
  },
  {
    name: "Gilmer",
    state_id: "TX",
    lat: 32.7317,
    lng: -94.946,
    id: 1840020731,
  },
  {
    name: "Williamsville",
    state_id: "NY",
    lat: 42.9623,
    lng: -78.7418,
    id: 1840004392,
  },
  {
    name: "Layhill",
    state_id: "MD",
    lat: 39.087,
    lng: -77.0401,
    id: 1840024491,
  },
  {
    name: "Elmsford",
    state_id: "NY",
    lat: 41.0541,
    lng: -73.8143,
    id: 1840004953,
  },
  {
    name: "Center",
    state_id: "TX",
    lat: 31.793,
    lng: -94.1796,
    id: 1840019528,
  },
  {
    name: "Cutler",
    state_id: "CA",
    lat: 36.5263,
    lng: -119.2884,
    id: 1840019030,
  },
  {
    name: "Farmers Loop",
    state_id: "AK",
    lat: 64.9061,
    lng: -147.6957,
    id: 1840075181,
  },
  {
    name: "Naguabo",
    state_id: "PR",
    lat: 18.2116,
    lng: -65.737,
    id: 1630035528,
  },
  {
    name: "Montrose",
    state_id: "MN",
    lat: 45.0668,
    lng: -93.9206,
    id: 1840007796,
  },
  {
    name: "Pinardville",
    state_id: "NH",
    lat: 43.001,
    lng: -71.5171,
    id: 1840002991,
  },
  {
    name: "Aberdeen",
    state_id: "MS",
    lat: 33.8287,
    lng: -88.5539,
    id: 1840013685,
  },
  {
    name: "Diboll",
    state_id: "TX",
    lat: 31.1881,
    lng: -94.783,
    id: 1840019549,
  },
  {
    name: "Hutchinson Island South",
    state_id: "FL",
    lat: 27.3243,
    lng: -80.2425,
    id: 1840073842,
  },
  {
    name: "Williamston",
    state_id: "NC",
    lat: 35.8468,
    lng: -77.0655,
    id: 1840016206,
  },
  {
    name: "Ravena",
    state_id: "NY",
    lat: 42.4755,
    lng: -73.8113,
    id: 1840004492,
  },
  {
    name: "Ettrick",
    state_id: "VA",
    lat: 37.2435,
    lng: -77.4287,
    id: 1840001699,
  },
  {
    name: "Thatcher",
    state_id: "AZ",
    lat: 32.832,
    lng: -109.7595,
    id: 1840022955,
  },
  {
    name: "South Bay",
    state_id: "FL",
    lat: 26.677,
    lng: -80.7265,
    id: 1840015992,
  },
  {
    name: "Ocean Bluff-Brant Rock",
    state_id: "MA",
    lat: 42.1006,
    lng: -70.6625,
    id: 1840073527,
  },
  {
    name: "Springfield",
    state_id: "MI",
    lat: 42.3246,
    lng: -85.2371,
    id: 1840003174,
  },
  {
    name: "Timber Pines",
    state_id: "FL",
    lat: 28.469,
    lng: -82.5999,
    id: 1840029088,
  },
  {
    name: "West Wendover",
    state_id: "NV",
    lat: 40.7407,
    lng: -114.0783,
    id: 1840021336,
  },
  {
    name: "Obetz",
    state_id: "OH",
    lat: 39.8671,
    lng: -82.9451,
    id: 1840012408,
  },
  {
    name: "Twin Lakes",
    state_id: "CA",
    lat: 36.9646,
    lng: -121.9896,
    id: 1840028200,
  },
  {
    name: "DeWitt",
    state_id: "IA",
    lat: 41.8227,
    lng: -90.5448,
    id: 1840007042,
  },
  {
    name: "Sawmills",
    state_id: "NC",
    lat: 35.8162,
    lng: -81.4779,
    id: 1840017755,
  },
  {
    name: "Paulden",
    state_id: "AZ",
    lat: 34.8899,
    lng: -112.4938,
    id: 1840019162,
  },
  {
    name: "Attica",
    state_id: "IN",
    lat: 40.2874,
    lng: -87.2452,
    id: 1840007244,
  },
  {
    name: "Mount Olive",
    state_id: "NC",
    lat: 35.1997,
    lng: -78.0662,
    id: 1840016321,
  },
  {
    name: "Boulder Creek",
    state_id: "CA",
    lat: 37.1341,
    lng: -122.1271,
    id: 1840017650,
  },
  {
    name: "Tichigan",
    state_id: "WI",
    lat: 42.8087,
    lng: -88.215,
    id: 1840006920,
  },
  {
    name: "Parker",
    state_id: "TX",
    lat: 33.057,
    lng: -96.6248,
    id: 1840020661,
  },
  {
    name: "Dunlap",
    state_id: "TN",
    lat: 35.3675,
    lng: -85.3899,
    id: 1840013437,
  },
  {
    name: "Doffing",
    state_id: "TX",
    lat: 26.2788,
    lng: -98.3856,
    id: 1840037042,
  },
  {
    name: "Collegeville",
    state_id: "PA",
    lat: 40.1873,
    lng: -75.4581,
    id: 1840003696,
  },
  {
    name: "West Pleasant View",
    state_id: "CO",
    lat: 39.732,
    lng: -105.1785,
    id: 1840074740,
  },
  {
    name: "Broadmoor",
    state_id: "CA",
    lat: 37.6914,
    lng: -122.4811,
    id: 1840017623,
  },
  {
    name: "Grandwood Park",
    state_id: "IL",
    lat: 42.3929,
    lng: -87.9871,
    id: 1840004639,
  },
  {
    name: "Creve Coeur",
    state_id: "IL",
    lat: 40.6425,
    lng: -89.5983,
    id: 1840011987,
  },
  {
    name: "Sterling",
    state_id: "AK",
    lat: 60.5405,
    lng: -150.8089,
    id: 1840023417,
  },
  {
    name: "Crawfordville",
    state_id: "FL",
    lat: 30.1995,
    lng: -84.3634,
    id: 1840025125,
  },
  {
    name: "Lake City",
    state_id: "MN",
    lat: 44.4453,
    lng: -92.2796,
    id: 1840000321,
  },
  {
    name: "River Ridge",
    state_id: "FL",
    lat: 28.2669,
    lng: -82.6257,
    id: 1840039098,
  },
  {
    name: "Erwin",
    state_id: "NC",
    lat: 35.3226,
    lng: -78.6734,
    id: 1840016328,
  },
  {
    name: "Grosse Pointe",
    state_id: "MI",
    lat: 42.3915,
    lng: -82.9118,
    id: 1840003976,
  },
  {
    name: "Blauvelt",
    state_id: "NY",
    lat: 41.0689,
    lng: -73.9545,
    id: 1840004959,
  },
  {
    name: "Hanamaulu",
    state_id: "HI",
    lat: 21.9954,
    lng: -159.3493,
    id: 1840029477,
  },
  {
    name: "Bliss Corner",
    state_id: "MA",
    lat: 41.6054,
    lng: -70.9421,
    id: 1840003221,
  },
  {
    name: "Grambling",
    state_id: "LA",
    lat: 32.5276,
    lng: -92.7124,
    id: 1840013807,
  },
  {
    name: "Bret Harte",
    state_id: "CA",
    lat: 37.6021,
    lng: -121.0045,
    id: 1840036997,
  },
  {
    name: "Ottawa",
    state_id: "OH",
    lat: 41.0203,
    lng: -84.0354,
    id: 1840011758,
  },
  {
    name: "Birdsboro",
    state_id: "PA",
    lat: 40.262,
    lng: -75.8099,
    id: 1840001172,
  },
  {
    name: "Bristol",
    state_id: "WI",
    lat: 42.5378,
    lng: -88.0149,
    id: 1840037916,
  },
  {
    name: "Peñuelas",
    state_id: "PR",
    lat: 18.0595,
    lng: -66.7206,
    id: 1630023556,
  },
  {
    name: "St. Clairsville",
    state_id: "OH",
    lat: 40.0792,
    lng: -80.8997,
    id: 1840009468,
  },
  {
    name: "Ayden",
    state_id: "NC",
    lat: 35.4694,
    lng: -77.4167,
    id: 1840015388,
  },
  {
    name: "Glendale",
    state_id: "CO",
    lat: 39.7036,
    lng: -104.9352,
    id: 1840028450,
  },
  {
    name: "Turtle Creek",
    state_id: "PA",
    lat: 40.4085,
    lng: -79.8214,
    id: 1840001267,
  },
  {
    name: "Margaret",
    state_id: "AL",
    lat: 33.6735,
    lng: -86.468,
    id: 1840016772,
  },
  {
    name: "Colville",
    state_id: "WA",
    lat: 48.5454,
    lng: -117.8986,
    id: 1840018361,
  },
  {
    name: "Carle Place",
    state_id: "NY",
    lat: 40.75,
    lng: -73.6122,
    id: 1840005234,
  },
  {
    name: "Primera",
    state_id: "TX",
    lat: 26.2237,
    lng: -97.7528,
    id: 1840022261,
  },
  {
    name: "Humboldt",
    state_id: "IA",
    lat: 42.7232,
    lng: -94.2245,
    id: 1840000400,
  },
  {
    name: "Sergeant Bluff",
    state_id: "IA",
    lat: 42.3976,
    lng: -96.3517,
    id: 1840009111,
  },
  {
    name: "Homestead Meadows North",
    state_id: "TX",
    lat: 31.8483,
    lng: -106.1707,
    id: 1840034867,
  },
  {
    name: "Spring Valley",
    state_id: "IL",
    lat: 41.3357,
    lng: -89.2034,
    id: 1840009275,
  },
  {
    name: "Flagler Beach",
    state_id: "FL",
    lat: 29.4716,
    lng: -81.1303,
    id: 1840014035,
  },
  {
    name: "Canutillo",
    state_id: "TX",
    lat: 31.9185,
    lng: -106.6006,
    id: 1840018151,
  },
  {
    name: "Rainsville",
    state_id: "AL",
    lat: 34.4939,
    lng: -85.8435,
    id: 1840014658,
  },
  {
    name: "Kenneth City",
    state_id: "FL",
    lat: 27.8155,
    lng: -82.7162,
    id: 1840017244,
  },
  {
    name: "Lone Grove",
    state_id: "OK",
    lat: 34.1809,
    lng: -97.2559,
    id: 1840020532,
  },
  {
    name: "Silver Hill",
    state_id: "MD",
    lat: 38.8392,
    lng: -76.9367,
    id: 1840024548,
  },
  {
    name: "Colona",
    state_id: "IL",
    lat: 41.4678,
    lng: -90.3445,
    id: 1840007098,
  },
  {
    name: "Tara Hills",
    state_id: "CA",
    lat: 37.9939,
    lng: -122.3188,
    id: 1840028315,
  },
  {
    name: "Battlement Mesa",
    state_id: "CO",
    lat: 39.4505,
    lng: -108.0066,
    id: 1840017532,
  },
  {
    name: "Post",
    state_id: "TX",
    lat: 33.1911,
    lng: -101.3814,
    id: 1840020667,
  },
  {
    name: "Bowie",
    state_id: "TX",
    lat: 33.5566,
    lng: -97.844,
    id: 1840019314,
  },
  {
    name: "McGuire AFB",
    state_id: "NJ",
    lat: 40.0285,
    lng: -74.5883,
    id: 1840073621,
  },
  {
    name: "Carmi",
    state_id: "IL",
    lat: 38.0863,
    lng: -88.1718,
    id: 1840007525,
  },
  {
    name: "Kings Park",
    state_id: "VA",
    lat: 38.8026,
    lng: -77.2396,
    id: 1840024574,
  },
  {
    name: "Quantico Base",
    state_id: "VA",
    lat: 38.5228,
    lng: -77.3187,
    id: 1840073661,
  },
  {
    name: "Coco",
    state_id: "PR",
    lat: 18.001,
    lng: -66.2603,
    id: 1630023633,
  },
  {
    name: "Utica",
    state_id: "MI",
    lat: 42.629,
    lng: -83.0218,
    id: 1840002417,
  },
  {
    name: "Schuylkill Haven",
    state_id: "PA",
    lat: 40.6284,
    lng: -76.1729,
    id: 1840002778,
  },
  {
    name: "Scott City",
    state_id: "MO",
    lat: 37.2243,
    lng: -89.536,
    id: 1840009923,
  },
  {
    name: "Portland",
    state_id: "MI",
    lat: 42.8696,
    lng: -84.899,
    id: 1840003073,
  },
  {
    name: "Poplar Grove",
    state_id: "IL",
    lat: 42.3516,
    lng: -88.8353,
    id: 1840011146,
  },
  {
    name: "Buellton",
    state_id: "CA",
    lat: 34.6152,
    lng: -120.1943,
    id: 1840019186,
  },
  {
    name: "Daleville",
    state_id: "AL",
    lat: 31.2915,
    lng: -85.7117,
    id: 1840014955,
  },
  {
    name: "Belleview",
    state_id: "FL",
    lat: 29.0609,
    lng: -82.0565,
    id: 1840014040,
  },
  {
    name: "Denair",
    state_id: "CA",
    lat: 37.5262,
    lng: -120.7994,
    id: 1840018906,
  },
  {
    name: "Highland Lakes",
    state_id: "NJ",
    lat: 41.1716,
    lng: -74.4643,
    id: 1840033298,
  },
  {
    name: "Johnstown",
    state_id: "OH",
    lat: 40.15,
    lng: -82.6881,
    id: 1840012328,
  },
  {
    name: "South Pasadena",
    state_id: "FL",
    lat: 27.7526,
    lng: -82.7394,
    id: 1840015979,
  },
  {
    name: "South Haven",
    state_id: "IN",
    lat: 41.5438,
    lng: -87.1367,
    id: 1840004834,
  },
  {
    name: "Hugo",
    state_id: "OK",
    lat: 34.0121,
    lng: -95.5115,
    id: 1840020546,
  },
  {
    name: "Tice",
    state_id: "FL",
    lat: 26.6758,
    lng: -81.8171,
    id: 1840014219,
  },
  {
    name: "Town and Country",
    state_id: "WA",
    lat: 47.7259,
    lng: -117.422,
    id: 1840037888,
  },
  {
    name: "Lakeview",
    state_id: "GA",
    lat: 34.9777,
    lng: -85.2539,
    id: 1840013553,
  },
  {
    name: "Morganville",
    state_id: "NJ",
    lat: 40.3756,
    lng: -74.2444,
    id: 1840005442,
  },
  {
    name: "New Burlington",
    state_id: "OH",
    lat: 39.2624,
    lng: -84.552,
    id: 1840139258,
  },
  {
    name: "Holbrook",
    state_id: "AZ",
    lat: 34.9044,
    lng: -110.1672,
    id: 1840020334,
  },
  {
    name: "Four Corners",
    state_id: "MT",
    lat: 45.6704,
    lng: -111.178,
    id: 1840032809,
  },
  {
    name: "Rio Hondo",
    state_id: "TX",
    lat: 26.2347,
    lng: -97.5817,
    id: 1840021038,
  },
  {
    name: "Sheldon",
    state_id: "IA",
    lat: 43.1796,
    lng: -95.8441,
    id: 1840009046,
  },
  {
    name: "North Fond du Lac",
    state_id: "WI",
    lat: 43.8107,
    lng: -88.4861,
    id: 1840002607,
  },
  {
    name: "Crestwood",
    state_id: "KY",
    lat: 38.3356,
    lng: -85.4839,
    id: 1840014284,
  },
  {
    name: "Fox Chapel",
    state_id: "PA",
    lat: 40.5247,
    lng: -79.8898,
    id: 1840001197,
  },
  {
    name: "Vinton",
    state_id: "IA",
    lat: 42.1631,
    lng: -92.026,
    id: 1840000470,
  },
  {
    name: "De Motte",
    state_id: "IN",
    lat: 41.1988,
    lng: -87.1973,
    id: 1840030064,
  },
  {
    name: "Silver Springs",
    state_id: "NV",
    lat: 39.3826,
    lng: -119.2149,
    id: 1840018797,
  },
  {
    name: "Maplewood",
    state_id: "WA",
    lat: 47.3716,
    lng: -122.5689,
    id: 1840037569,
  },
  {
    name: "Glencoe",
    state_id: "AL",
    lat: 33.9449,
    lng: -85.9319,
    id: 1840003257,
  },
  {
    name: "Shanor-Northvue",
    state_id: "PA",
    lat: 40.9104,
    lng: -79.9157,
    id: 1840035235,
  },
  {
    name: "Massac",
    state_id: "KY",
    lat: 37.0335,
    lng: -88.6859,
    id: 1840013263,
  },
  {
    name: "Munroe Falls",
    state_id: "OH",
    lat: 41.1386,
    lng: -81.4344,
    id: 1840000798,
  },
  {
    name: "Saltillo",
    state_id: "MS",
    lat: 34.3789,
    lng: -88.6939,
    id: 1840015560,
  },
  {
    name: "Monee",
    state_id: "IL",
    lat: 41.4181,
    lng: -87.7499,
    id: 1840011492,
  },
  {
    name: "Iowa Falls",
    state_id: "IA",
    lat: 42.519,
    lng: -93.2662,
    id: 1840008079,
  },
  {
    name: "Roseburg North",
    state_id: "OR",
    lat: 43.2653,
    lng: -123.3025,
    id: 1840074590,
  },
  {
    name: "Buckley",
    state_id: "WA",
    lat: 47.1615,
    lng: -122.02,
    id: 1840018455,
  },
  {
    name: "Gretna",
    state_id: "NE",
    lat: 41.1319,
    lng: -96.2447,
    id: 1840007143,
  },
  {
    name: "Stewartstown",
    state_id: "PA",
    lat: 39.7528,
    lng: -76.5925,
    id: 1840001465,
  },
  {
    name: "West Hills",
    state_id: "NY",
    lat: 40.8198,
    lng: -73.4339,
    id: 1840034031,
  },
  {
    name: "Glen Head",
    state_id: "NY",
    lat: 40.845,
    lng: -73.618,
    id: 1840005259,
  },
  {
    name: "Salinas",
    state_id: "PR",
    lat: 17.9779,
    lng: -66.2961,
    id: 1630035570,
  },
  {
    name: "Clifton",
    state_id: "AZ",
    lat: 33.0249,
    lng: -109.2883,
    id: 1840022949,
  },
  {
    name: "Offutt AFB",
    state_id: "NE",
    lat: 41.1207,
    lng: -95.9209,
    id: 1840073770,
  },
  {
    name: "Berkeley",
    state_id: "IL",
    lat: 41.8891,
    lng: -87.9114,
    id: 1840011249,
  },
  {
    name: "Carver",
    state_id: "MN",
    lat: 44.76,
    lng: -93.6305,
    id: 1840006758,
  },
  {
    name: "Dundee",
    state_id: "FL",
    lat: 28.0115,
    lng: -81.5995,
    id: 1840017241,
  },
  {
    name: "North Scituate",
    state_id: "MA",
    lat: 42.2121,
    lng: -70.7652,
    id: 1840004680,
  },
  {
    name: "Montevideo",
    state_id: "MN",
    lat: 44.9505,
    lng: -95.7153,
    id: 1840007837,
  },
  {
    name: "Sulphur",
    state_id: "OK",
    lat: 34.4977,
    lng: -96.9899,
    id: 1840021891,
  },
  {
    name: "Summit",
    state_id: "WI",
    lat: 43.0504,
    lng: -88.4815,
    id: 1840072793,
  },
  {
    name: "Amelia",
    state_id: "OH",
    lat: 39.0229,
    lng: -84.2184,
    id: 1840010681,
  },
  {
    name: "Mechanicville",
    state_id: "NY",
    lat: 42.9037,
    lng: -73.6895,
    id: 1840000368,
  },
  {
    name: "Barling",
    state_id: "AR",
    lat: 35.3284,
    lng: -94.2792,
    id: 1840013455,
  },
  {
    name: "Krum",
    state_id: "TX",
    lat: 33.2652,
    lng: -97.2256,
    id: 1840020645,
  },
  {
    name: "Terryville",
    state_id: "CT",
    lat: 41.6784,
    lng: -73.0064,
    id: 1840003261,
  },
  {
    name: "La Crescent",
    state_id: "MN",
    lat: 43.8299,
    lng: -91.3043,
    id: 1840007938,
  },
  {
    name: "Worland",
    state_id: "WY",
    lat: 44.0026,
    lng: -107.9543,
    id: 1840021266,
  },
  {
    name: "Olyphant",
    state_id: "PA",
    lat: 41.4507,
    lng: -75.5753,
    id: 1840003388,
  },
  {
    name: "Stafford Springs",
    state_id: "CT",
    lat: 41.9595,
    lng: -72.3105,
    id: 1840003275,
  },
  {
    name: "Whiskey Creek",
    state_id: "FL",
    lat: 26.5733,
    lng: -81.8903,
    id: 1840029099,
  },
  {
    name: "Doney Park",
    state_id: "AZ",
    lat: 35.2687,
    lng: -111.5053,
    id: 1840075310,
  },
  {
    name: "Glennville",
    state_id: "GA",
    lat: 31.9382,
    lng: -81.9305,
    id: 1840013832,
  },
  {
    name: "Sea Cliff",
    state_id: "NY",
    lat: 40.8441,
    lng: -73.6442,
    id: 1840005326,
  },
  {
    name: "Abbeville",
    state_id: "SC",
    lat: 34.1787,
    lng: -82.3774,
    id: 1840013618,
  },
  {
    name: "Muleshoe",
    state_id: "TX",
    lat: 34.2292,
    lng: -102.7284,
    id: 1840020541,
  },
  {
    name: "West Salem",
    state_id: "WI",
    lat: 43.8989,
    lng: -91.0883,
    id: 1840002575,
  },
  {
    name: "Guerneville",
    state_id: "CA",
    lat: 38.5137,
    lng: -122.9894,
    id: 1840017578,
  },
  {
    name: "Hawaiian Ocean View",
    state_id: "HI",
    lat: 19.0959,
    lng: -155.775,
    id: 1840029581,
  },
  {
    name: "West Sayville",
    state_id: "NY",
    lat: 40.7294,
    lng: -73.105,
    id: 1840005112,
  },
  {
    name: "Mullins",
    state_id: "SC",
    lat: 34.2042,
    lng: -79.2535,
    id: 1840014719,
  },
  {
    name: "Swoyersville",
    state_id: "PA",
    lat: 41.2975,
    lng: -75.8799,
    id: 1840000771,
  },
  {
    name: "Hoopeston",
    state_id: "IL",
    lat: 40.4608,
    lng: -87.6635,
    id: 1840007236,
  },
  {
    name: "Greenfield",
    state_id: "OH",
    lat: 39.3535,
    lng: -83.3884,
    id: 1840008513,
  },
  {
    name: "Hudson",
    state_id: "TX",
    lat: 31.3285,
    lng: -94.8014,
    id: 1840020838,
  },
  {
    name: "Ridgeland",
    state_id: "SC",
    lat: 32.468,
    lng: -80.9176,
    id: 1840018086,
  },
  {
    name: "Dwight",
    state_id: "IL",
    lat: 41.0987,
    lng: -88.424,
    id: 1840011783,
  },
  {
    name: "Oak Park Heights",
    state_id: "MN",
    lat: 45.0324,
    lng: -92.8099,
    id: 1840007812,
  },
  {
    name: "Gillespie",
    state_id: "IL",
    lat: 39.1258,
    lng: -89.8173,
    id: 1840008489,
  },
  {
    name: "Midfield",
    state_id: "AL",
    lat: 33.4552,
    lng: -86.9226,
    id: 1840014791,
  },
  {
    name: "Westvale",
    state_id: "NY",
    lat: 43.04,
    lng: -76.2177,
    id: 1840004318,
  },
  {
    name: "Timnath",
    state_id: "CO",
    lat: 40.5343,
    lng: -104.962,
    id: 1840022410,
  },
  {
    name: "Dellwood",
    state_id: "MO",
    lat: 38.7564,
    lng: -90.2767,
    id: 1840007455,
  },
  {
    name: "South Barrington",
    state_id: "IL",
    lat: 42.0881,
    lng: -88.158,
    id: 1840011322,
  },
  {
    name: "Pine Manor",
    state_id: "FL",
    lat: 26.5727,
    lng: -81.8775,
    id: 1840028755,
  },
  {
    name: "Milan",
    state_id: "IL",
    lat: 41.4415,
    lng: -90.5593,
    id: 1840011444,
  },
  {
    name: "Orangetree",
    state_id: "FL",
    lat: 26.2929,
    lng: -81.5786,
    id: 1840014234,
  },
  {
    name: "Pittsburg",
    state_id: "TX",
    lat: 32.9997,
    lng: -94.9668,
    id: 1840020682,
  },
  {
    name: "Concordia",
    state_id: "KS",
    lat: 39.5669,
    lng: -97.6466,
    id: 1840001541,
  },
  {
    name: "Manchester",
    state_id: "IA",
    lat: 42.4854,
    lng: -91.4556,
    id: 1840009099,
  },
  {
    name: "Montrose-Ghent",
    state_id: "OH",
    lat: 41.1538,
    lng: -81.6438,
    id: 1840073767,
  },
  {
    name: "Gibson",
    state_id: "AR",
    lat: 34.8923,
    lng: -92.2341,
    id: 1840013533,
  },
  {
    name: "Huxley",
    state_id: "IA",
    lat: 41.8963,
    lng: -93.5914,
    id: 1840007020,
  },
  {
    name: "Princeton",
    state_id: "MN",
    lat: 45.5689,
    lng: -93.59,
    id: 1840008869,
  },
  {
    name: "Alexandria",
    state_id: "IN",
    lat: 40.2584,
    lng: -85.676,
    id: 1840007241,
  },
  {
    name: "Winnsboro",
    state_id: "LA",
    lat: 32.1651,
    lng: -91.721,
    id: 1840015814,
  },
  {
    name: "Medical Lake",
    state_id: "WA",
    lat: 47.5712,
    lng: -117.6893,
    id: 1840019804,
  },
  {
    name: "Redwood Falls",
    state_id: "MN",
    lat: 44.5471,
    lng: -95.1031,
    id: 1840008961,
  },
  {
    name: "Adairsville",
    state_id: "GA",
    lat: 34.3708,
    lng: -84.9211,
    id: 1840013620,
  },
  {
    name: "White Hall",
    state_id: "AR",
    lat: 34.2737,
    lng: -92.1005,
    id: 1840015566,
  },
  {
    name: "Jonesboro",
    state_id: "GA",
    lat: 33.5212,
    lng: -84.3541,
    id: 1840014816,
  },
  {
    name: "Polk City",
    state_id: "IA",
    lat: 41.7773,
    lng: -93.7132,
    id: 1840009220,
  },
  {
    name: "Parker",
    state_id: "AZ",
    lat: 34.0286,
    lng: -114.2224,
    id: 1840022875,
  },
  {
    name: "Carrollton",
    state_id: "KY",
    lat: 38.68,
    lng: -85.1656,
    id: 1840014272,
  },
  {
    name: "Everson",
    state_id: "WA",
    lat: 48.9123,
    lng: -122.351,
    id: 1840019750,
  },
  {
    name: "Clementon",
    state_id: "NJ",
    lat: 39.8046,
    lng: -74.9851,
    id: 1840003773,
  },
  {
    name: "Raeford",
    state_id: "NC",
    lat: 34.981,
    lng: -79.2291,
    id: 1840014612,
  },
  {
    name: "Wellington",
    state_id: "OH",
    lat: 41.161,
    lng: -82.2268,
    id: 1840011595,
  },
  {
    name: "Becker",
    state_id: "MN",
    lat: 45.3815,
    lng: -93.8737,
    id: 1840006700,
  },
  {
    name: "Cicero",
    state_id: "IN",
    lat: 40.1247,
    lng: -86.0242,
    id: 1840009459,
  },
  {
    name: "Kingsford",
    state_id: "MI",
    lat: 45.8061,
    lng: -88.0994,
    id: 1840001992,
  },
  {
    name: "Richland Center",
    state_id: "WI",
    lat: 43.3403,
    lng: -90.384,
    id: 1840024306,
  },
  {
    name: "Westlake",
    state_id: "LA",
    lat: 30.2606,
    lng: -93.2638,
    id: 1840015928,
  },
  {
    name: "Mayfair",
    state_id: "CA",
    lat: 36.7693,
    lng: -119.7612,
    id: 1840075970,
  },
  {
    name: "Wildwood",
    state_id: "NJ",
    lat: 38.9877,
    lng: -74.8188,
    id: 1840001602,
  },
  {
    name: "Beckett",
    state_id: "NJ",
    lat: 39.7565,
    lng: -75.3564,
    id: 1840033476,
  },
  {
    name: "Alvarado",
    state_id: "TX",
    lat: 32.4068,
    lng: -97.2149,
    id: 1840019465,
  },
  {
    name: "Centreville",
    state_id: "MD",
    lat: 39.042,
    lng: -76.0631,
    id: 1840005899,
  },
  {
    name: "Blue Point",
    state_id: "NY",
    lat: 40.7518,
    lng: -73.0352,
    id: 1840005019,
  },
  {
    name: "Wheatley Heights",
    state_id: "NY",
    lat: 40.7625,
    lng: -73.3704,
    id: 1840005114,
  },
  {
    name: "Jasper",
    state_id: "FL",
    lat: 30.5062,
    lng: -82.954,
    id: 1840015028,
  },
  {
    name: "Vancleave",
    state_id: "MS",
    lat: 30.5483,
    lng: -88.6675,
    id: 1840013934,
  },
  {
    name: "Circle Pines",
    state_id: "MN",
    lat: 45.1409,
    lng: -93.1505,
    id: 1840006711,
  },
  {
    name: "Fairfield",
    state_id: "IL",
    lat: 38.3799,
    lng: -88.3724,
    id: 1840008641,
  },
  {
    name: "Bolivar",
    state_id: "TN",
    lat: 35.2645,
    lng: -89.0118,
    id: 1840013465,
  },
  {
    name: "Maize",
    state_id: "KS",
    lat: 37.7748,
    lng: -97.462,
    id: 1840009869,
  },
  {
    name: "Pleasant Garden",
    state_id: "NC",
    lat: 35.9595,
    lng: -79.7599,
    id: 1840016142,
  },
  {
    name: "Mount Carmel",
    state_id: "OH",
    lat: 39.0978,
    lng: -84.2995,
    id: 1840034326,
  },
  {
    name: "Cold Spring Harbor",
    state_id: "NY",
    lat: 40.8608,
    lng: -73.4488,
    id: 1840005029,
  },
  {
    name: "Kanab",
    state_id: "UT",
    lat: 37.0262,
    lng: -112.5219,
    id: 1840018947,
  },
  {
    name: "Long View",
    state_id: "NC",
    lat: 35.7217,
    lng: -81.3858,
    id: 1840033007,
  },
  {
    name: "Sheridan",
    state_id: "AR",
    lat: 34.2995,
    lng: -92.4226,
    id: 1840015565,
  },
  {
    name: "Blackwood",
    state_id: "NJ",
    lat: 39.7982,
    lng: -75.0629,
    id: 1840005560,
  },
  {
    name: "Farmville",
    state_id: "NC",
    lat: 35.5955,
    lng: -77.5913,
    id: 1840016275,
  },
  {
    name: "Northvale",
    state_id: "NJ",
    lat: 41.0099,
    lng: -73.9505,
    id: 1840000910,
  },
  {
    name: "Crystal Springs",
    state_id: "MS",
    lat: 31.9903,
    lng: -90.354,
    id: 1840014932,
  },
  {
    name: "Raynham Center",
    state_id: "MA",
    lat: 41.9327,
    lng: -71.0431,
    id: 1840004735,
  },
  {
    name: "Toronto",
    state_id: "OH",
    lat: 40.4581,
    lng: -80.6096,
    id: 1840010407,
  },
  {
    name: "Rutherford",
    state_id: "PA",
    lat: 40.2696,
    lng: -76.7678,
    id: 1840035437,
  },
  {
    name: "Salem",
    state_id: "MO",
    lat: 37.6399,
    lng: -91.5346,
    id: 1840009878,
  },
  {
    name: "Glasgow Village",
    state_id: "MO",
    lat: 38.7579,
    lng: -90.1984,
    id: 1840006118,
  },
  {
    name: "Firthcliffe",
    state_id: "NY",
    lat: 41.4409,
    lng: -74.0353,
    id: 1840004854,
  },
  {
    name: "Midway",
    state_id: "NC",
    lat: 35.9751,
    lng: -80.2204,
    id: 1840022708,
  },
  {
    name: "St. Joseph",
    state_id: "IL",
    lat: 40.1141,
    lng: -88.0354,
    id: 1840012230,
  },
  {
    name: "Buena Vista",
    state_id: "CO",
    lat: 38.832,
    lng: -106.1386,
    id: 1840021472,
  },
  {
    name: "Bay Hill",
    state_id: "FL",
    lat: 28.4558,
    lng: -81.5122,
    id: 1840029020,
  },
  {
    name: "Kingfisher",
    state_id: "OK",
    lat: 35.8439,
    lng: -97.9381,
    id: 1840020389,
  },
  {
    name: "New Haven",
    state_id: "MI",
    lat: 42.7306,
    lng: -82.7953,
    id: 1840011051,
  },
  {
    name: "Prospect",
    state_id: "KY",
    lat: 38.3471,
    lng: -85.6103,
    id: 1840014301,
  },
  {
    name: "Sugarcreek",
    state_id: "PA",
    lat: 41.438,
    lng: -79.8183,
    id: 1840003430,
  },
  {
    name: "Bridgeville",
    state_id: "PA",
    lat: 40.3578,
    lng: -80.1061,
    id: 1840001213,
  },
  {
    name: "Seven Lakes",
    state_id: "NC",
    lat: 35.2647,
    lng: -79.5865,
    id: 1840013443,
  },
  {
    name: "Winona Lake",
    state_id: "IN",
    lat: 41.2166,
    lng: -85.8106,
    id: 1840010281,
  },
  {
    name: "King George",
    state_id: "VA",
    lat: 38.2807,
    lng: -77.1896,
    id: 1840026758,
  },
  {
    name: "Mount Pleasant",
    state_id: "TN",
    lat: 35.5484,
    lng: -87.1872,
    id: 1840014519,
  },
  {
    name: "Centreville",
    state_id: "IL",
    lat: 38.5798,
    lng: -90.1039,
    id: 1840007485,
  },
  {
    name: "Big Pine Key",
    state_id: "FL",
    lat: 24.6892,
    lng: -81.3676,
    id: 1840014251,
  },
  {
    name: "Lake Worth",
    state_id: "TX",
    lat: 32.813,
    lng: -97.4306,
    id: 1840020705,
  },
  {
    name: "Telford",
    state_id: "PA",
    lat: 40.3259,
    lng: -75.3274,
    id: 1840035222,
  },
  {
    name: "Demarest",
    state_id: "NJ",
    lat: 40.955,
    lng: -73.9567,
    id: 1840003540,
  },
  {
    name: "Cashmere",
    state_id: "WA",
    lat: 47.5177,
    lng: -120.4673,
    id: 1840018368,
  },
  {
    name: "Mayville",
    state_id: "WI",
    lat: 43.4986,
    lng: -88.5475,
    id: 1840002742,
  },
  {
    name: "Flower Hill",
    state_id: "NY",
    lat: 40.8075,
    lng: -73.6755,
    id: 1840005287,
  },
  {
    name: "Suncook",
    state_id: "NH",
    lat: 43.1382,
    lng: -71.4529,
    id: 1840002804,
  },
  {
    name: "Pleasant Run",
    state_id: "OH",
    lat: 39.2927,
    lng: -84.5757,
    id: 1840034160,
  },
  {
    name: "Island Park",
    state_id: "NY",
    lat: 40.6051,
    lng: -73.6553,
    id: 1840005297,
  },
  {
    name: "Smithville",
    state_id: "TN",
    lat: 35.9584,
    lng: -85.8211,
    id: 1840015350,
  },
  {
    name: "Desloge",
    state_id: "MO",
    lat: 37.8751,
    lng: -90.5192,
    id: 1840007552,
  },
  {
    name: "Davison",
    state_id: "MI",
    lat: 43.0319,
    lng: -83.5186,
    id: 1840002947,
  },
  {
    name: "Benson",
    state_id: "AZ",
    lat: 31.9157,
    lng: -110.3257,
    id: 1840019494,
  },
  {
    name: "West Columbia",
    state_id: "TX",
    lat: 29.1422,
    lng: -95.649,
    id: 1840022237,
  },
  {
    name: "East Freehold",
    state_id: "NJ",
    lat: 40.2718,
    lng: -74.2425,
    id: 1840005441,
  },
  {
    name: "Chinle",
    state_id: "AZ",
    lat: 36.1504,
    lng: -109.5794,
    id: 1840017672,
  },
  {
    name: "New Lexington",
    state_id: "OH",
    lat: 39.716,
    lng: -82.208,
    id: 1840012483,
  },
  {
    name: "Vandergrift",
    state_id: "PA",
    lat: 40.5994,
    lng: -79.5748,
    id: 1840001163,
  },
  {
    name: "Bealeton",
    state_id: "VA",
    lat: 38.5822,
    lng: -77.7781,
    id: 1840024589,
  },
  {
    name: "Denton",
    state_id: "MD",
    lat: 38.8769,
    lng: -75.8265,
    id: 1840005932,
  },
  {
    name: "Thiells",
    state_id: "NY",
    lat: 41.2067,
    lng: -74.0122,
    id: 1840004970,
  },
  {
    name: "Hunters Creek Village",
    state_id: "TX",
    lat: 29.7715,
    lng: -95.4987,
    id: 1840020927,
  },
  {
    name: "Cherokee",
    state_id: "IA",
    lat: 42.7501,
    lng: -95.5528,
    id: 1840006901,
  },
  {
    name: "Shenandoah",
    state_id: "IA",
    lat: 40.7583,
    lng: -95.372,
    id: 1840009387,
  },
  {
    name: "Middlefield",
    state_id: "OH",
    lat: 41.4604,
    lng: -81.0734,
    id: 1840011516,
  },
  {
    name: "Lake Barrington",
    state_id: "IL",
    lat: 42.2121,
    lng: -88.1684,
    id: 1840011157,
  },
  {
    name: "Tucumcari",
    state_id: "NM",
    lat: 35.17,
    lng: -103.7046,
    id: 1840021739,
  },
  {
    name: "Berlin",
    state_id: "MD",
    lat: 38.3309,
    lng: -75.215,
    id: 1840006258,
  },
  {
    name: "Clarks Summit",
    state_id: "PA",
    lat: 41.4902,
    lng: -75.7065,
    id: 1840003378,
  },
  {
    name: "Wahneta",
    state_id: "FL",
    lat: 27.9574,
    lng: -81.7288,
    id: 1840014125,
  },
  {
    name: "Lincoln Village",
    state_id: "CA",
    lat: 38.0054,
    lng: -121.3339,
    id: 1840028163,
  },
  {
    name: "Chittenango",
    state_id: "NY",
    lat: 43.0462,
    lng: -75.8749,
    id: 1840004361,
  },
  {
    name: "Flora",
    state_id: "IL",
    lat: 38.6689,
    lng: -88.4759,
    id: 1840008579,
  },
  {
    name: "Duryea",
    state_id: "PA",
    lat: 41.3537,
    lng: -75.7758,
    id: 1840000747,
  },
  {
    name: "Palmetto",
    state_id: "GA",
    lat: 33.5311,
    lng: -84.6677,
    id: 1840014740,
  },
  {
    name: "Glendora",
    state_id: "NJ",
    lat: 39.8405,
    lng: -75.0678,
    id: 1840033286,
  },
  {
    name: "Nashville",
    state_id: "GA",
    lat: 31.2051,
    lng: -83.2486,
    id: 1840014964,
  },
  {
    name: "Chisholm",
    state_id: "MN",
    lat: 47.4877,
    lng: -92.8788,
    id: 1840006588,
  },
  {
    name: "Manheim",
    state_id: "PA",
    lat: 40.1631,
    lng: -76.3963,
    id: 1840003720,
  },
  {
    name: "Luray",
    state_id: "VA",
    lat: 38.665,
    lng: -78.4546,
    id: 1840006141,
  },
  {
    name: "Whitehouse",
    state_id: "OH",
    lat: 41.5215,
    lng: -83.7957,
    id: 1840011480,
  },
  {
    name: "Sawgrass",
    state_id: "FL",
    lat: 30.19,
    lng: -81.3704,
    id: 1840029073,
  },
  {
    name: "Normandy",
    state_id: "MO",
    lat: 38.7071,
    lng: -90.3009,
    id: 1840008592,
  },
  {
    name: "Bluefield",
    state_id: "VA",
    lat: 37.2351,
    lng: -81.2751,
    id: 1840006451,
  },
  {
    name: "Perry",
    state_id: "OK",
    lat: 36.2844,
    lng: -97.3088,
    id: 1840020371,
  },
  {
    name: "Childersburg",
    state_id: "AL",
    lat: 33.2961,
    lng: -86.3457,
    id: 1840014813,
  },
  {
    name: "Porter",
    state_id: "IN",
    lat: 41.6257,
    lng: -87.0801,
    id: 1840010229,
  },
  {
    name: "Marseilles",
    state_id: "IL",
    lat: 41.3099,
    lng: -88.685,
    id: 1840008198,
  },
  {
    name: "Green Tree",
    state_id: "PA",
    lat: 40.417,
    lng: -80.0544,
    id: 1840001235,
  },
  {
    name: "Arnold",
    state_id: "PA",
    lat: 40.5789,
    lng: -79.7653,
    id: 1840003624,
  },
  {
    name: "Rockford",
    state_id: "MN",
    lat: 45.092,
    lng: -93.7453,
    id: 1840008900,
  },
  {
    name: "West Milton",
    state_id: "OH",
    lat: 39.9585,
    lng: -84.3262,
    id: 1840012379,
  },
  {
    name: "Stone Park",
    state_id: "IL",
    lat: 41.9033,
    lng: -87.8806,
    id: 1840011327,
  },
  {
    name: "Chattahoochee",
    state_id: "FL",
    lat: 30.6977,
    lng: -84.8341,
    id: 1840015020,
  },
  {
    name: "Hummelstown",
    state_id: "PA",
    lat: 40.2657,
    lng: -76.7126,
    id: 1840001290,
  },
  {
    name: "Caro",
    state_id: "MI",
    lat: 43.489,
    lng: -83.4025,
    id: 1840010048,
  },
  {
    name: "Forest Hills",
    state_id: "TN",
    lat: 36.0651,
    lng: -86.8403,
    id: 1840013344,
  },
  {
    name: "Ridge Manor",
    state_id: "FL",
    lat: 28.4988,
    lng: -82.1832,
    id: 1840014111,
  },
  {
    name: "Falfurrias",
    state_id: "TX",
    lat: 27.2243,
    lng: -98.1451,
    id: 1840021014,
  },
  {
    name: "Edgefield",
    state_id: "SC",
    lat: 33.7871,
    lng: -81.9292,
    id: 1840016779,
  },
  {
    name: "Windsor Heights",
    state_id: "IA",
    lat: 41.6043,
    lng: -93.7128,
    id: 1840010186,
  },
  {
    name: "DeWitt",
    state_id: "MI",
    lat: 42.8364,
    lng: -84.575,
    id: 1840003077,
  },
  {
    name: "Waynesboro",
    state_id: "MS",
    lat: 31.6773,
    lng: -88.6353,
    id: 1840015853,
  },
  {
    name: "Three Points",
    state_id: "AZ",
    lat: 32.0596,
    lng: -111.2866,
    id: 1840019486,
  },
  {
    name: "West Bay Shore",
    state_id: "NY",
    lat: 40.708,
    lng: -73.2719,
    id: 1840005110,
  },
  {
    name: "Nassau Village-Ratliff",
    state_id: "FL",
    lat: 30.5109,
    lng: -81.8088,
    id: 1840073870,
  },
  {
    name: "Chesterbrook",
    state_id: "PA",
    lat: 40.0736,
    lng: -75.4582,
    id: 1840035419,
  },
  {
    name: "Magnolia",
    state_id: "TX",
    lat: 30.2117,
    lng: -95.7419,
    id: 1840020884,
  },
  {
    name: "Michigan Center",
    state_id: "MI",
    lat: 42.2267,
    lng: -84.323,
    id: 1840004652,
  },
  {
    name: "West Jefferson",
    state_id: "OH",
    lat: 39.9481,
    lng: -83.2983,
    id: 1840012420,
  },
  {
    name: "Hyde Park",
    state_id: "UT",
    lat: 41.8008,
    lng: -111.8119,
    id: 1840018698,
  },
  {
    name: "Dallas",
    state_id: "NC",
    lat: 35.3167,
    lng: -81.1827,
    id: 1840016396,
  },
  {
    name: "Goddard",
    state_id: "KS",
    lat: 37.6658,
    lng: -97.5621,
    id: 1840008705,
  },
  {
    name: "East Harwich",
    state_id: "MA",
    lat: 41.7081,
    lng: -70.0339,
    id: 1840003238,
  },
  {
    name: "Pleasant View",
    state_id: "TN",
    lat: 36.3893,
    lng: -87.0455,
    id: 1840014461,
  },
  {
    name: "Temple",
    state_id: "GA",
    lat: 33.7342,
    lng: -85.0289,
    id: 1840015678,
  },
  {
    name: "Hickory Creek",
    state_id: "TX",
    lat: 33.1114,
    lng: -97.0313,
    id: 1840022013,
  },
  {
    name: "Schlusser",
    state_id: "PA",
    lat: 40.2421,
    lng: -77.1778,
    id: 1840035438,
  },
  {
    name: "Dillon",
    state_id: "MT",
    lat: 45.2177,
    lng: -112.6348,
    id: 1840018539,
  },
  {
    name: "Midway North",
    state_id: "TX",
    lat: 26.1872,
    lng: -98.0188,
    id: 1840034872,
  },
  {
    name: "Middletown",
    state_id: "MD",
    lat: 39.4415,
    lng: -77.5348,
    id: 1840005713,
  },
  {
    name: "Olympia Fields",
    state_id: "IL",
    lat: 41.517,
    lng: -87.6924,
    id: 1840011308,
  },
  {
    name: "Lindale",
    state_id: "GA",
    lat: 34.1884,
    lng: -85.1808,
    id: 1840013604,
  },
  {
    name: "Manchester",
    state_id: "KY",
    lat: 37.1464,
    lng: -83.7635,
    id: 1840015255,
  },
  {
    name: "DeQuincy",
    state_id: "LA",
    lat: 30.449,
    lng: -93.4456,
    id: 1840015038,
  },
  {
    name: "Wolverine Lake",
    state_id: "MI",
    lat: 42.555,
    lng: -83.4883,
    id: 1840011061,
  },
  {
    name: "Wamego",
    state_id: "KS",
    lat: 39.2054,
    lng: -96.31,
    id: 1840010634,
  },
  {
    name: "Carthage",
    state_id: "MS",
    lat: 32.7431,
    lng: -89.5337,
    id: 1840014870,
  },
  {
    name: "Black Diamond",
    state_id: "WA",
    lat: 47.313,
    lng: -122.0181,
    id: 1840018418,
  },
  {
    name: "Clifton Forge",
    state_id: "VA",
    lat: 37.8232,
    lng: -79.825,
    id: 1840006365,
  },
  {
    name: "Landrum",
    state_id: "SC",
    lat: 35.1747,
    lng: -82.1849,
    id: 1840014616,
  },
  {
    name: "Elko New Market",
    state_id: "MN",
    lat: 44.5667,
    lng: -93.338,
    id: 1840007863,
  },
  {
    name: "Monticello",
    state_id: "LA",
    lat: 30.4881,
    lng: -91.0449,
    id: 1840031106,
  },
  {
    name: "Bells",
    state_id: "TN",
    lat: 35.7203,
    lng: -89.0859,
    id: 1840013394,
  },
  {
    name: "Wilmer",
    state_id: "TX",
    lat: 32.5982,
    lng: -96.6816,
    id: 1840022056,
  },
  {
    name: "New Boston",
    state_id: "TX",
    lat: 33.4612,
    lng: -94.4182,
    id: 1840020613,
  },
  {
    name: "Watseka",
    state_id: "IL",
    lat: 40.7751,
    lng: -87.7304,
    id: 1840010336,
  },
  {
    name: "Ashland City",
    state_id: "TN",
    lat: 36.2607,
    lng: -87.0412,
    id: 1840015304,
  },
  {
    name: "Harlan",
    state_id: "IA",
    lat: 41.6496,
    lng: -95.3268,
    id: 1840000530,
  },
  {
    name: "Potosi",
    state_id: "MO",
    lat: 37.9337,
    lng: -90.775,
    id: 1840009846,
  },
  {
    name: "Whiting",
    state_id: "IN",
    lat: 41.6731,
    lng: -87.4843,
    id: 1840010227,
  },
  {
    name: "Crooksville",
    state_id: "OH",
    lat: 39.769,
    lng: -82.0956,
    id: 1840012480,
  },
  {
    name: "Dansville",
    state_id: "NY",
    lat: 42.5625,
    lng: -77.6968,
    id: 1840004430,
  },
  {
    name: "Weston",
    state_id: "WV",
    lat: 39.0392,
    lng: -80.4618,
    id: 1840005930,
  },
  {
    name: "Ahoskie",
    state_id: "NC",
    lat: 36.2843,
    lng: -76.9898,
    id: 1840015277,
  },
  {
    name: "Benton City",
    state_id: "WA",
    lat: 46.2623,
    lng: -119.4812,
    id: 1840018480,
  },
  {
    name: "Shelley",
    state_id: "ID",
    lat: 43.3795,
    lng: -112.1261,
    id: 1840021282,
  },
  {
    name: "West Pittston",
    state_id: "PA",
    lat: 41.3295,
    lng: -75.7998,
    id: 1840000774,
  },
  {
    name: "Forked River",
    state_id: "NJ",
    lat: 39.8255,
    lng: -74.1822,
    id: 1840005524,
  },
  {
    name: "Ligonier",
    state_id: "IN",
    lat: 41.4625,
    lng: -85.5948,
    id: 1840009278,
  },
  {
    name: "Royal Pines",
    state_id: "NC",
    lat: 35.4783,
    lng: -82.5038,
    id: 1840013412,
  },
  {
    name: "Penns Grove",
    state_id: "NJ",
    lat: 39.7275,
    lng: -75.4691,
    id: 1840001530,
  },
  {
    name: "Mount Plymouth",
    state_id: "FL",
    lat: 28.8024,
    lng: -81.535,
    id: 1840014049,
  },
  {
    name: "Shelbyville",
    state_id: "IL",
    lat: 39.4096,
    lng: -88.8005,
    id: 1840009568,
  },
  {
    name: "Dodgeville",
    state_id: "WI",
    lat: 42.966,
    lng: -90.1297,
    id: 1840002959,
  },
  {
    name: "Royersford",
    state_id: "PA",
    lat: 40.1862,
    lng: -75.5382,
    id: 1840001376,
  },
  {
    name: "Ford City",
    state_id: "CA",
    lat: 35.1647,
    lng: -119.4584,
    id: 1840017817,
  },
  {
    name: "Lake Sarasota",
    state_id: "FL",
    lat: 27.2916,
    lng: -82.437,
    id: 1840029050,
  },
  {
    name: "Cherry Grove",
    state_id: "OH",
    lat: 39.0802,
    lng: -84.322,
    id: 1840005879,
  },
  {
    name: "Madisonville",
    state_id: "TX",
    lat: 30.9538,
    lng: -95.9091,
    id: 1840020861,
  },
  {
    name: "Bloomfield",
    state_id: "WI",
    lat: 42.5488,
    lng: -88.352,
    id: 1840043424,
  },
  {
    name: "Lincoln",
    state_id: "ND",
    lat: 46.7691,
    lng: -100.6987,
    id: 1840000158,
  },
  {
    name: "Rockwell",
    state_id: "AR",
    lat: 34.4641,
    lng: -93.1341,
    id: 1840027857,
  },
  {
    name: "McLoud",
    state_id: "OK",
    lat: 35.407,
    lng: -97.1013,
    id: 1840022764,
  },
  {
    name: "Port Allen",
    state_id: "LA",
    lat: 30.4456,
    lng: -91.2098,
    id: 1840015026,
  },
  {
    name: "Evansdale",
    state_id: "IA",
    lat: 42.4635,
    lng: -92.2764,
    id: 1840000441,
  },
  {
    name: "Nowthen",
    state_id: "MN",
    lat: 45.3421,
    lng: -93.4495,
    id: 1840022338,
  },
  {
    name: "Baxter Springs",
    state_id: "KS",
    lat: 37.0196,
    lng: -94.7351,
    id: 1840001714,
  },
  {
    name: "Seat Pleasant",
    state_id: "MD",
    lat: 38.8952,
    lng: -76.9016,
    id: 1840005979,
  },
  {
    name: "Palos Park",
    state_id: "IL",
    lat: 41.6682,
    lng: -87.8885,
    id: 1840011312,
  },
  {
    name: "Manning",
    state_id: "SC",
    lat: 33.6933,
    lng: -80.2167,
    id: 1840015655,
  },
  {
    name: "Columbia",
    state_id: "KY",
    lat: 37.1036,
    lng: -85.3074,
    id: 1840014384,
  },
  {
    name: "Candlewick Lake",
    state_id: "IL",
    lat: 42.3524,
    lng: -88.8693,
    id: 1840141570,
  },
  {
    name: "Campanilla",
    state_id: "PR",
    lat: 18.4229,
    lng: -66.2382,
    id: 1630035630,
  },
  {
    name: "Winnfield",
    state_id: "LA",
    lat: 31.924,
    lng: -92.6425,
    id: 1840015837,
  },
  {
    name: "Mead",
    state_id: "CO",
    lat: 40.2321,
    lng: -104.9928,
    id: 1840022396,
  },
  {
    name: "Santa Bárbara",
    state_id: "PR",
    lat: 18.3951,
    lng: -65.9176,
    id: 1630023714,
  },
  {
    name: "Harrington Park",
    state_id: "NJ",
    lat: 40.9899,
    lng: -73.9803,
    id: 1840003557,
  },
  {
    name: "Archbold",
    state_id: "OH",
    lat: 41.5165,
    lng: -84.3036,
    id: 1840011510,
  },
  {
    name: "Silverton",
    state_id: "OH",
    lat: 39.1884,
    lng: -84.401,
    id: 1840001614,
  },
  {
    name: "Medina",
    state_id: "TX",
    lat: 26.929,
    lng: -99.2614,
    id: 1840136274,
  },
  {
    name: "Madill",
    state_id: "OK",
    lat: 34.0867,
    lng: -96.7741,
    id: 1840020543,
  },
  {
    name: "El Jebel",
    state_id: "CO",
    lat: 39.4051,
    lng: -107.092,
    id: 1840017536,
  },
  {
    name: "Campbelltown",
    state_id: "PA",
    lat: 40.2761,
    lng: -76.5848,
    id: 1840005427,
  },
  {
    name: "Grandyle Village",
    state_id: "NY",
    lat: 42.9866,
    lng: -78.9524,
    id: 1840024062,
  },
  {
    name: "Edwardsville",
    state_id: "PA",
    lat: 41.2614,
    lng: -75.9071,
    id: 1840000748,
  },
  {
    name: "Chula Vista",
    state_id: "TX",
    lat: 28.6573,
    lng: -100.422,
    id: 1840073267,
  },
  {
    name: "Camino Tassajara",
    state_id: "CA",
    lat: 37.7909,
    lng: -121.885,
    id: 1840028420,
  },
  {
    name: "Emerald Lake Hills",
    state_id: "CA",
    lat: 37.4662,
    lng: -122.2675,
    id: 1840028374,
  },
  {
    name: "Weston Lakes",
    state_id: "TX",
    lat: 29.6627,
    lng: -95.9353,
    id: 1840023155,
  },
  {
    name: "Indian Mountain Lake",
    state_id: "PA",
    lat: 41.0003,
    lng: -75.5058,
    id: 1840035026,
  },
  {
    name: "Butler Beach",
    state_id: "FL",
    lat: 29.798,
    lng: -81.2653,
    id: 1840029025,
  },
  {
    name: "Penitas",
    state_id: "TX",
    lat: 26.2508,
    lng: -98.4426,
    id: 1840021029,
  },
  {
    name: "Dexter",
    state_id: "MI",
    lat: 42.3319,
    lng: -83.8803,
    id: 1840011193,
  },
  {
    name: "Highlands",
    state_id: "NJ",
    lat: 40.4036,
    lng: -73.9899,
    id: 1840003688,
  },
  {
    name: "Lake San Marcos",
    state_id: "CA",
    lat: 33.1196,
    lng: -117.2088,
    id: 1840018024,
  },
  {
    name: "Headland",
    state_id: "AL",
    lat: 31.3501,
    lng: -85.3523,
    id: 1840014949,
  },
  {
    name: "Summit",
    state_id: "AZ",
    lat: 32.0619,
    lng: -110.9483,
    id: 1840019488,
  },
  {
    name: "Westwood",
    state_id: "KY",
    lat: 38.4806,
    lng: -82.6798,
    id: 1840142797,
  },
  {
    name: "Eldon",
    state_id: "MO",
    lat: 38.3512,
    lng: -92.5766,
    id: 1840008659,
  },
  {
    name: "Deale",
    state_id: "MD",
    lat: 38.791,
    lng: -76.5469,
    id: 1840005911,
  },
  {
    name: "Winchester",
    state_id: "IN",
    lat: 40.172,
    lng: -84.9766,
    id: 1840014526,
  },
  {
    name: "Castroville",
    state_id: "TX",
    lat: 29.3513,
    lng: -98.8711,
    id: 1840019656,
  },
  {
    name: "Crystal City",
    state_id: "MO",
    lat: 38.2226,
    lng: -90.3813,
    id: 1840007505,
  },
  {
    name: "Baldwin City",
    state_id: "KS",
    lat: 38.7782,
    lng: -95.1879,
    id: 1840007415,
  },
  {
    name: "Fountain Hill",
    state_id: "PA",
    lat: 40.6029,
    lng: -75.3961,
    id: 1840001049,
  },
  {
    name: "Gladstone",
    state_id: "MI",
    lat: 45.8522,
    lng: -87.0356,
    id: 1840003926,
  },
  {
    name: "Miami Heights",
    state_id: "OH",
    lat: 39.1687,
    lng: -84.7152,
    id: 1840034233,
  },
  {
    name: "Broad Brook",
    state_id: "CT",
    lat: 41.9097,
    lng: -72.5426,
    id: 1840004776,
  },
  {
    name: "Mountain Grove",
    state_id: "MO",
    lat: 37.1341,
    lng: -92.266,
    id: 1840008744,
  },
  {
    name: "Falmouth",
    state_id: "VA",
    lat: 38.3319,
    lng: -77.4655,
    id: 1840006215,
  },
  {
    name: "Cheboygan",
    state_id: "MI",
    lat: 45.6415,
    lng: -84.4685,
    id: 1840002047,
  },
  {
    name: "Bishopville",
    state_id: "SC",
    lat: 34.2202,
    lng: -80.2483,
    id: 1840013634,
  },
  {
    name: "Placitas",
    state_id: "NM",
    lat: 35.3226,
    lng: -106.4436,
    id: 1840019064,
  },
  {
    name: "Westmont",
    state_id: "PA",
    lat: 40.3194,
    lng: -78.9524,
    id: 1840001128,
  },
  {
    name: "Morgan's Point Resort",
    state_id: "TX",
    lat: 31.1518,
    lng: -97.4581,
    id: 1840037019,
  },
  {
    name: "Lexington",
    state_id: "OH",
    lat: 40.68,
    lng: -82.5793,
    id: 1840011882,
  },
  {
    name: "Sierra View",
    state_id: "PA",
    lat: 41.0007,
    lng: -75.4476,
    id: 1840035455,
  },
  {
    name: "Lake View",
    state_id: "AL",
    lat: 33.2802,
    lng: -87.1258,
    id: 1840016839,
  },
  {
    name: "Wayland",
    state_id: "MI",
    lat: 42.6725,
    lng: -85.6408,
    id: 1840000421,
  },
  {
    name: "Sherman",
    state_id: "IL",
    lat: 39.8876,
    lng: -89.6066,
    id: 1840012455,
  },
  {
    name: "Mountain Lake Park",
    state_id: "MD",
    lat: 39.4004,
    lng: -79.3812,
    id: 1840005633,
  },
  {
    name: "Smith Mills",
    state_id: "MA",
    lat: 41.6432,
    lng: -70.992,
    id: 1840004736,
  },
  {
    name: "Mattituck",
    state_id: "NY",
    lat: 41.0012,
    lng: -72.5419,
    id: 1840005091,
  },
  {
    name: "Brookdale",
    state_id: "SC",
    lat: 33.5183,
    lng: -80.8332,
    id: 1840035795,
  },
  {
    name: "Connerton",
    state_id: "FL",
    lat: 28.3022,
    lng: -82.4624,
    id: 1840038857,
  },
  {
    name: "Brisbane",
    state_id: "CA",
    lat: 37.6898,
    lng: -122.402,
    id: 1840028147,
  },
  {
    name: "North Braddock",
    state_id: "PA",
    lat: 40.4023,
    lng: -79.8533,
    id: 1840001248,
  },
  {
    name: "June Park",
    state_id: "FL",
    lat: 28.0718,
    lng: -80.6874,
    id: 1840014074,
  },
  {
    name: "South Lebanon",
    state_id: "OH",
    lat: 39.3672,
    lng: -84.2204,
    id: 1840012616,
  },
  {
    name: "Bigfork",
    state_id: "MT",
    lat: 48.0852,
    lng: -114.0551,
    id: 1840017296,
  },
  {
    name: "Osage Beach",
    state_id: "MO",
    lat: 38.1353,
    lng: -92.6479,
    id: 1840009754,
  },
  {
    name: "Brielle",
    state_id: "NJ",
    lat: 40.1048,
    lng: -74.0636,
    id: 1840001357,
  },
  {
    name: "Summerside",
    state_id: "OH",
    lat: 39.1187,
    lng: -84.2862,
    id: 1840034135,
  },
  {
    name: "Spurgeon",
    state_id: "TN",
    lat: 36.443,
    lng: -82.4621,
    id: 1840013334,
  },
  {
    name: "Tuscola",
    state_id: "IL",
    lat: 39.7967,
    lng: -88.2749,
    id: 1840010587,
  },
  {
    name: "Cherokee Village",
    state_id: "AR",
    lat: 36.2959,
    lng: -91.5696,
    id: 1840014446,
  },
  {
    name: "Sandy Oaks",
    state_id: "TX",
    lat: 29.1821,
    lng: -98.4081,
    id: 1840037146,
  },
  {
    name: "Carolina Shores",
    state_id: "NC",
    lat: 33.9076,
    lng: -78.5738,
    id: 1840015581,
  },
  {
    name: "Granite Falls",
    state_id: "NC",
    lat: 35.7966,
    lng: -81.4233,
    id: 1840016189,
  },
  {
    name: "Hawthorne",
    state_id: "NY",
    lat: 41.1035,
    lng: -73.7969,
    id: 1840004922,
  },
  {
    name: "Chester",
    state_id: "MD",
    lat: 38.9677,
    lng: -76.2823,
    id: 1840005894,
  },
  {
    name: "Millis-Clicquot",
    state_id: "MA",
    lat: 42.1648,
    lng: -71.3544,
    id: 1840073515,
  },
  {
    name: "Wayzata",
    state_id: "MN",
    lat: 44.9671,
    lng: -93.5161,
    id: 1840010000,
  },
  {
    name: "Tama",
    state_id: "IA",
    lat: 41.9648,
    lng: -92.5746,
    id: 1840009150,
  },
  {
    name: "Madison",
    state_id: "GA",
    lat: 33.579,
    lng: -83.4758,
    id: 1840015676,
  },
  {
    name: "Paradise Hills",
    state_id: "NM",
    lat: 35.1981,
    lng: -106.7023,
    id: 1840024971,
  },
  {
    name: "Tulia",
    state_id: "TX",
    lat: 34.5374,
    lng: -101.7742,
    id: 1840021880,
  },
  {
    name: "West Elmira",
    state_id: "NY",
    lat: 42.0879,
    lng: -76.8473,
    id: 1840004687,
  },
  {
    name: "Phoenix",
    state_id: "OR",
    lat: 42.2748,
    lng: -122.8151,
    id: 1840020077,
  },
  {
    name: "Reiffton",
    state_id: "PA",
    lat: 40.3126,
    lng: -75.8662,
    id: 1840005391,
  },
  {
    name: "Danville",
    state_id: "PA",
    lat: 40.9615,
    lng: -76.6121,
    id: 1840000854,
  },
  {
    name: "Little Falls",
    state_id: "NY",
    lat: 43.0452,
    lng: -74.8566,
    id: 1840000333,
  },
  {
    name: "Boalsburg",
    state_id: "PA",
    lat: 40.7783,
    lng: -77.7734,
    id: 1840005154,
  },
  {
    name: "Woodruff",
    state_id: "SC",
    lat: 34.7397,
    lng: -82.0323,
    id: 1840015484,
  },
  {
    name: "Galeville",
    state_id: "NY",
    lat: 43.0889,
    lng: -76.1815,
    id: 1840004315,
  },
  {
    name: "Jackson",
    state_id: "AL",
    lat: 31.5318,
    lng: -87.8915,
    id: 1840014935,
  },
  {
    name: "Pea Ridge",
    state_id: "FL",
    lat: 30.6029,
    lng: -87.1022,
    id: 1840043095,
  },
  {
    name: "Kaplan",
    state_id: "LA",
    lat: 30.006,
    lng: -92.284,
    id: 1840015050,
  },
  {
    name: "Lemoyne",
    state_id: "PA",
    lat: 40.2442,
    lng: -76.8991,
    id: 1840001387,
  },
  {
    name: "Durand",
    state_id: "MI",
    lat: 42.9127,
    lng: -83.989,
    id: 1840003071,
  },
  {
    name: "East Palestine",
    state_id: "OH",
    lat: 40.8392,
    lng: -80.5467,
    id: 1840008292,
  },
  {
    name: "Gardnertown",
    state_id: "NY",
    lat: 41.5328,
    lng: -74.0594,
    id: 1840004861,
  },
  {
    name: "Wanamassa",
    state_id: "NJ",
    lat: 40.2365,
    lng: -74.0294,
    id: 1840005452,
  },
  {
    name: "Spruce Pine",
    state_id: "NC",
    lat: 35.9104,
    lng: -82.0731,
    id: 1840017751,
  },
  {
    name: "Swartz",
    state_id: "LA",
    lat: 32.5809,
    lng: -91.9869,
    id: 1840013812,
  },
  {
    name: "Hebbronville",
    state_id: "TX",
    lat: 27.3239,
    lng: -98.686,
    id: 1840018298,
  },
  {
    name: "Daingerfield",
    state_id: "TX",
    lat: 33.0306,
    lng: -94.725,
    id: 1840019406,
  },
  {
    name: "Hampton",
    state_id: "MD",
    lat: 39.4222,
    lng: -76.5696,
    id: 1840005692,
  },
  {
    name: "Comerío",
    state_id: "PR",
    lat: 18.2203,
    lng: -66.2243,
    id: 1630023541,
  },
  {
    name: "Vilonia",
    state_id: "AR",
    lat: 35.0807,
    lng: -92.2088,
    id: 1840015465,
  },
  {
    name: "Auburn",
    state_id: "IL",
    lat: 39.5762,
    lng: -89.7441,
    id: 1840007301,
  },
  {
    name: "Rock Hill",
    state_id: "MO",
    lat: 38.6091,
    lng: -90.3673,
    id: 1840009770,
  },
  {
    name: "Cape May Court House",
    state_id: "NJ",
    lat: 39.079,
    lng: -74.8207,
    id: 1840005864,
  },
  {
    name: "Blountstown",
    state_id: "FL",
    lat: 30.443,
    lng: -85.0455,
    id: 1840013952,
  },
  {
    name: "Vandercook Lake",
    state_id: "MI",
    lat: 42.1916,
    lng: -84.3854,
    id: 1840006977,
  },
  {
    name: "Hazardville",
    state_id: "CT",
    lat: 41.9899,
    lng: -72.5256,
    id: 1840000504,
  },
  {
    name: "Edenton",
    state_id: "NC",
    lat: 36.058,
    lng: -76.6008,
    id: 1840016103,
  },
  {
    name: "Old Westbury",
    state_id: "NY",
    lat: 40.7866,
    lng: -73.5975,
    id: 1840005316,
  },
  {
    name: "Daytona Beach Shores",
    state_id: "FL",
    lat: 29.1723,
    lng: -80.9808,
    id: 1840015069,
  },
  {
    name: "Creedmoor",
    state_id: "NC",
    lat: 36.1223,
    lng: -78.6758,
    id: 1840014434,
  },
  {
    name: "Higginsville",
    state_id: "MO",
    lat: 39.0653,
    lng: -93.727,
    id: 1840007389,
  },
  {
    name: "Coldwater",
    state_id: "OH",
    lat: 40.4833,
    lng: -84.6317,
    id: 1840012000,
  },
  {
    name: "Woodmore",
    state_id: "MD",
    lat: 38.9222,
    lng: -76.781,
    id: 1840005969,
  },
  {
    name: "Spotsylvania Courthouse",
    state_id: "VA",
    lat: 38.1982,
    lng: -77.5885,
    id: 1840006269,
  },
  {
    name: "Winnsboro",
    state_id: "SC",
    lat: 34.3718,
    lng: -81.0907,
    id: 1840016627,
  },
  {
    name: "Bushnell",
    state_id: "FL",
    lat: 28.6856,
    lng: -82.116,
    id: 1840014067,
  },
  {
    name: "Haskell",
    state_id: "AR",
    lat: 34.5095,
    lng: -92.6406,
    id: 1840013571,
  },
  {
    name: "Village of Four Seasons",
    state_id: "MO",
    lat: 38.1974,
    lng: -92.7179,
    id: 1840032256,
  },
  {
    name: "Altavista",
    state_id: "VA",
    lat: 37.1232,
    lng: -79.2858,
    id: 1840006438,
  },
  {
    name: "Newport",
    state_id: "SC",
    lat: 34.9832,
    lng: -81.0992,
    id: 1840013520,
  },
  {
    name: "Dundee",
    state_id: "MI",
    lat: 41.9643,
    lng: -83.6636,
    id: 1840013156,
  },
  {
    name: "Cold Spring",
    state_id: "MN",
    lat: 45.4572,
    lng: -94.4296,
    id: 1840006682,
  },
  {
    name: "Oak View",
    state_id: "CA",
    lat: 34.399,
    lng: -119.2976,
    id: 1840019199,
  },
  {
    name: "North Sea",
    state_id: "NY",
    lat: 40.933,
    lng: -72.4047,
    id: 1840005060,
  },
  {
    name: "Neptune City",
    state_id: "NJ",
    lat: 40.2005,
    lng: -74.0333,
    id: 1840003677,
  },
  {
    name: "Lajas",
    state_id: "PR",
    lat: 18.0453,
    lng: -67.0583,
    id: 1630023598,
  },
  {
    name: "Pascoag",
    state_id: "RI",
    lat: 41.9518,
    lng: -71.7041,
    id: 1840003294,
  },
  {
    name: "Wingate",
    state_id: "NC",
    lat: 34.9852,
    lng: -80.4497,
    id: 1840016460,
  },
  {
    name: "Williamstown",
    state_id: "PA",
    lat: 40.5809,
    lng: -76.617,
    id: 1840001298,
  },
  {
    name: "Montebello",
    state_id: "NY",
    lat: 41.1317,
    lng: -74.1134,
    id: 1840004978,
  },
  {
    name: "Searingtown",
    state_id: "NY",
    lat: 40.7705,
    lng: -73.6603,
    id: 1840033957,
  },
  {
    name: "Hollister",
    state_id: "MO",
    lat: 36.6058,
    lng: -93.2338,
    id: 1840007660,
  },
  {
    name: "Suncoast Estates",
    state_id: "FL",
    lat: 26.7122,
    lng: -81.8684,
    id: 1840029083,
  },
  {
    name: "Newport",
    state_id: "NC",
    lat: 34.772,
    lng: -76.8771,
    id: 1840016485,
  },
  {
    name: "Meadows Place",
    state_id: "TX",
    lat: 29.6513,
    lng: -95.5873,
    id: 1840020949,
  },
  {
    name: "Edinburgh",
    state_id: "IN",
    lat: 39.3509,
    lng: -85.9625,
    id: 1840013729,
  },
  {
    name: "Verdigris",
    state_id: "OK",
    lat: 36.2533,
    lng: -95.6602,
    id: 1840022656,
  },
  {
    name: "Fort Defiance",
    state_id: "AZ",
    lat: 35.7469,
    lng: -109.0685,
    id: 1840017675,
  },
  {
    name: "Spry",
    state_id: "PA",
    lat: 39.9125,
    lng: -76.6863,
    id: 1840005508,
  },
  {
    name: "Mount Ephraim",
    state_id: "NJ",
    lat: 39.8806,
    lng: -75.0917,
    id: 1840003772,
  },
  {
    name: "Plain City",
    state_id: "OH",
    lat: 40.1067,
    lng: -83.2693,
    id: 1840012418,
  },
  {
    name: "Coopertown",
    state_id: "TN",
    lat: 36.4143,
    lng: -86.9658,
    id: 1840016013,
  },
  {
    name: "Montgomery",
    state_id: "GA",
    lat: 31.9438,
    lng: -81.1081,
    id: 1840013838,
  },
  {
    name: "Lower Grand Lagoon",
    state_id: "FL",
    lat: 30.1444,
    lng: -85.7526,
    id: 1840029054,
  },
  {
    name: "Farrell",
    state_id: "PA",
    lat: 41.2112,
    lng: -80.497,
    id: 1840000657,
  },
  {
    name: "Flemington",
    state_id: "NJ",
    lat: 40.5087,
    lng: -74.8599,
    id: 1840003605,
  },
  {
    name: "Kahaluu",
    state_id: "HI",
    lat: 21.4576,
    lng: -157.8453,
    id: 1840029490,
  },
  {
    name: "Beattystown",
    state_id: "NJ",
    lat: 40.8222,
    lng: -74.8502,
    id: 1840005192,
  },
  {
    name: "Peppermill Village",
    state_id: "MD",
    lat: 38.894,
    lng: -76.8878,
    id: 1840031500,
  },
  {
    name: "Buffalo",
    state_id: "WY",
    lat: 44.3426,
    lng: -106.7139,
    id: 1840018615,
  },
  {
    name: "Fox River Grove",
    state_id: "IL",
    lat: 42.1955,
    lng: -88.2147,
    id: 1840011135,
  },
  {
    name: "North Kansas City",
    state_id: "MO",
    lat: 39.1396,
    lng: -94.5643,
    id: 1840008498,
  },
  {
    name: "West York",
    state_id: "PA",
    lat: 39.9534,
    lng: -76.761,
    id: 1840001467,
  },
  {
    name: "Blakely",
    state_id: "GA",
    lat: 31.3827,
    lng: -84.9228,
    id: 1840013872,
  },
  {
    name: "Bridgeport",
    state_id: "PA",
    lat: 40.1042,
    lng: -75.3437,
    id: 1840003694,
  },
  {
    name: "Portola Valley",
    state_id: "CA",
    lat: 37.3713,
    lng: -122.2203,
    id: 1840021553,
  },
  {
    name: "Scottsville",
    state_id: "KY",
    lat: 36.7521,
    lng: -86.2018,
    id: 1840015271,
  },
  {
    name: "Breckenridge Hills",
    state_id: "MO",
    lat: 38.7158,
    lng: -90.3685,
    id: 1840006127,
  },
  {
    name: "Belfair",
    state_id: "WA",
    lat: 47.4495,
    lng: -122.8487,
    id: 1840025252,
  },
  {
    name: "Vamo",
    state_id: "FL",
    lat: 27.2254,
    lng: -82.4944,
    id: 1840014181,
  },
  {
    name: "Benton",
    state_id: "KY",
    lat: 36.8512,
    lng: -88.3594,
    id: 1840013275,
  },
  {
    name: "Gray",
    state_id: "GA",
    lat: 33.002,
    lng: -83.5373,
    id: 1840013786,
  },
  {
    name: "Louisburg",
    state_id: "KS",
    lat: 38.6203,
    lng: -94.677,
    id: 1840001653,
  },
  {
    name: "Donora",
    state_id: "PA",
    lat: 40.179,
    lng: -79.8625,
    id: 1840003650,
  },
  {
    name: "Whitfield",
    state_id: "PA",
    lat: 40.3358,
    lng: -76.0048,
    id: 1840035443,
  },
  {
    name: "Byram",
    state_id: "CT",
    lat: 41.0011,
    lng: -73.6528,
    id: 1840026280,
  },
  {
    name: "Sullivan",
    state_id: "IL",
    lat: 39.5954,
    lng: -88.6085,
    id: 1840009552,
  },
  {
    name: "Ellisville",
    state_id: "MS",
    lat: 31.5969,
    lng: -89.2091,
    id: 1840013856,
  },
  {
    name: "Perezville",
    state_id: "TX",
    lat: 26.2396,
    lng: -98.4023,
    id: 1840025180,
  },
  {
    name: "Castle Pines Village",
    state_id: "CO",
    lat: 39.4418,
    lng: -104.897,
    id: 1840028593,
  },
  {
    name: "Gang Mills",
    state_id: "NY",
    lat: 42.1534,
    lng: -77.123,
    id: 1840004548,
  },
  {
    name: "Rio Communities",
    state_id: "NM",
    lat: 34.6459,
    lng: -106.7164,
    id: 1840033728,
  },
  {
    name: "Point Pleasant Beach",
    state_id: "NJ",
    lat: 40.0929,
    lng: -74.0459,
    id: 1840001486,
  },
  {
    name: "Okauchee Lake",
    state_id: "WI",
    lat: 43.1248,
    lng: -88.4408,
    id: 1840004353,
  },
  {
    name: "Hartsdale",
    state_id: "NY",
    lat: 41.0153,
    lng: -73.8036,
    id: 1840004921,
  },
  {
    name: "Columbiana",
    state_id: "AL",
    lat: 33.193,
    lng: -86.6115,
    id: 1840006158,
  },
  {
    name: "Fox Farm-College",
    state_id: "WY",
    lat: 41.1103,
    lng: -104.7872,
    id: 1840073950,
  },
  {
    name: "Wahoo",
    state_id: "NE",
    lat: 41.2166,
    lng: -96.6172,
    id: 1840003455,
  },
  {
    name: "Eastwood",
    state_id: "LA",
    lat: 32.5606,
    lng: -93.5625,
    id: 1840013100,
  },
  {
    name: "Aurora",
    state_id: "NE",
    lat: 40.8642,
    lng: -98.007,
    id: 1840007144,
  },
  {
    name: "Union Springs",
    state_id: "AL",
    lat: 32.1395,
    lng: -85.7141,
    id: 1840004415,
  },
  {
    name: "Leisure Village",
    state_id: "NJ",
    lat: 40.0445,
    lng: -74.1852,
    id: 1840005534,
  },
  {
    name: "Bremen",
    state_id: "IN",
    lat: 41.4484,
    lng: -86.1506,
    id: 1840009300,
  },
  {
    name: "Santa Ynez",
    state_id: "CA",
    lat: 34.6151,
    lng: -120.0944,
    id: 1840019184,
  },
  {
    name: "Oconto",
    state_id: "WI",
    lat: 44.8918,
    lng: -87.8692,
    id: 1840001575,
  },
  {
    name: "Social Circle",
    state_id: "GA",
    lat: 33.6505,
    lng: -83.7116,
    id: 1840015658,
  },
  {
    name: "Canastota",
    state_id: "NY",
    lat: 43.0835,
    lng: -75.7559,
    id: 1840004359,
  },
  {
    name: "Harrington",
    state_id: "DE",
    lat: 38.9244,
    lng: -75.5707,
    id: 1840005811,
  },
  {
    name: "Dardanelle",
    state_id: "AR",
    lat: 35.2263,
    lng: -93.1649,
    id: 1840014607,
  },
  {
    name: "Wiggins",
    state_id: "MS",
    lat: 30.8557,
    lng: -89.1385,
    id: 1840015904,
  },
  {
    name: "Dilley",
    state_id: "TX",
    lat: 28.6675,
    lng: -99.1765,
    id: 1840019687,
  },
  {
    name: "Avalon",
    state_id: "PA",
    lat: 40.501,
    lng: -80.068,
    id: 1840001200,
  },
  {
    name: "Gunnison",
    state_id: "UT",
    lat: 39.157,
    lng: -111.8135,
    id: 1840037159,
  },
  {
    name: "Boiling Springs",
    state_id: "NC",
    lat: 35.2521,
    lng: -81.6636,
    id: 1840015425,
  },
  {
    name: "Piedmont",
    state_id: "AL",
    lat: 33.9268,
    lng: -85.6148,
    id: 1840014781,
  },
  {
    name: "Palacios",
    state_id: "TX",
    lat: 28.7198,
    lng: -96.235,
    id: 1840020990,
  },
  {
    name: "East Hampton North",
    state_id: "NY",
    lat: 40.9725,
    lng: -72.1889,
    id: 1840035108,
  },
  {
    name: "Paxton",
    state_id: "IL",
    lat: 40.4563,
    lng: -88.1021,
    id: 1840009368,
  },
  {
    name: "Lexington",
    state_id: "MO",
    lat: 39.1798,
    lng: -93.8694,
    id: 1840008528,
  },
  {
    name: "Whiteland",
    state_id: "IN",
    lat: 39.5507,
    lng: -86.0742,
    id: 1840010618,
  },
  {
    name: "Luverne",
    state_id: "MN",
    lat: 43.6533,
    lng: -96.2146,
    id: 1840008995,
  },
  {
    name: "Watertown",
    state_id: "MN",
    lat: 44.9603,
    lng: -93.8431,
    id: 1840010009,
  },
  {
    name: "Laureles",
    state_id: "TX",
    lat: 26.1168,
    lng: -97.4875,
    id: 1840018327,
  },
  {
    name: "Hazlehurst",
    state_id: "MS",
    lat: 31.8646,
    lng: -90.3929,
    id: 1840014933,
  },
  {
    name: "Moses Lake North",
    state_id: "WA",
    lat: 47.195,
    lng: -119.3185,
    id: 1840074436,
  },
  {
    name: "Negaunee",
    state_id: "MI",
    lat: 46.497,
    lng: -87.5968,
    id: 1840003921,
  },
  {
    name: "Surfside Beach",
    state_id: "SC",
    lat: 33.6093,
    lng: -78.9772,
    id: 1840016712,
  },
  {
    name: "Wrightsboro",
    state_id: "NC",
    lat: 34.2895,
    lng: -77.9217,
    id: 1840013630,
  },
  {
    name: "Pilot Point",
    state_id: "TX",
    lat: 33.3955,
    lng: -96.9501,
    id: 1840020643,
  },
  {
    name: "Buhl",
    state_id: "ID",
    lat: 42.5984,
    lng: -114.7596,
    id: 1840018679,
  },
  {
    name: "Chain Lake",
    state_id: "WA",
    lat: 47.9038,
    lng: -121.9861,
    id: 1840041915,
  },
  {
    name: "Spring Lake Heights",
    state_id: "NJ",
    lat: 40.1522,
    lng: -74.043,
    id: 1840001367,
  },
  {
    name: "Piney",
    state_id: "AR",
    lat: 34.5024,
    lng: -93.142,
    id: 1840013589,
  },
  {
    name: "South Hill",
    state_id: "VA",
    lat: 36.7254,
    lng: -78.1287,
    id: 1840006541,
  },
  {
    name: "Gibraltar",
    state_id: "MI",
    lat: 42.096,
    lng: -83.2029,
    id: 1840003975,
  },
  {
    name: "Winfield",
    state_id: "AL",
    lat: 33.9334,
    lng: -87.7962,
    id: 1840015587,
  },
  {
    name: "Bonneauville",
    state_id: "PA",
    lat: 39.8105,
    lng: -77.1363,
    id: 1840000679,
  },
  {
    name: "South Bound Brook",
    state_id: "NJ",
    lat: 40.5535,
    lng: -74.5277,
    id: 1840001057,
  },
  {
    name: "Reminderville",
    state_id: "OH",
    lat: 41.3338,
    lng: -81.4018,
    id: 1840000801,
  },
  {
    name: "Oak Hill",
    state_id: "TN",
    lat: 36.0735,
    lng: -86.7856,
    id: 1840014464,
  },
  {
    name: "Smithville",
    state_id: "TX",
    lat: 30.008,
    lng: -97.1511,
    id: 1840022186,
  },
  {
    name: "Capitol Heights",
    state_id: "MD",
    lat: 38.8766,
    lng: -76.9074,
    id: 1840005983,
  },
  {
    name: "Lake Holiday",
    state_id: "IL",
    lat: 41.6156,
    lng: -88.6703,
    id: 1840026300,
  },
  {
    name: "Phoenix Lake",
    state_id: "CA",
    lat: 38.0075,
    lng: -120.3089,
    id: 1840026756,
  },
  {
    name: "North Westport",
    state_id: "MA",
    lat: 41.6539,
    lng: -71.0927,
    id: 1840031244,
  },
  {
    name: "Mount Vernon",
    state_id: "MO",
    lat: 37.105,
    lng: -93.8191,
    id: 1840008780,
  },
  {
    name: "Hancock",
    state_id: "MI",
    lat: 47.1355,
    lng: -88.5987,
    id: 1840003920,
  },
  {
    name: "Sauk Centre",
    state_id: "MN",
    lat: 45.736,
    lng: -94.9523,
    id: 1840008887,
  },
  {
    name: "Lake Wisconsin",
    state_id: "WI",
    lat: 43.3782,
    lng: -89.5762,
    id: 1840004203,
  },
  {
    name: "Ludlow",
    state_id: "KY",
    lat: 39.0904,
    lng: -84.5498,
    id: 1840015173,
  },
  {
    name: "Laguna Beach",
    state_id: "FL",
    lat: 30.2549,
    lng: -85.951,
    id: 1840013957,
  },
  {
    name: "East Petersburg",
    state_id: "PA",
    lat: 40.1006,
    lng: -76.3511,
    id: 1840003715,
  },
  {
    name: "Silver Summit",
    state_id: "UT",
    lat: 40.7434,
    lng: -111.4905,
    id: 1840041658,
  },
  {
    name: "Ardsley",
    state_id: "NY",
    lat: 41.0135,
    lng: -73.8395,
    id: 1840004947,
  },
  {
    name: "Tallassee",
    state_id: "AL",
    lat: 32.5314,
    lng: -85.8902,
    id: 1840015790,
  },
  {
    name: "Guayanilla",
    state_id: "PR",
    lat: 18.0222,
    lng: -66.7898,
    id: 1630023620,
  },
  {
    name: "Crystal River",
    state_id: "FL",
    lat: 28.8964,
    lng: -82.5992,
    id: 1840015084,
  },
  {
    name: "Hazlehurst",
    state_id: "GA",
    lat: 31.8651,
    lng: -82.5994,
    id: 1840014937,
  },
  {
    name: "Ogallala",
    state_id: "NE",
    lat: 41.1292,
    lng: -101.721,
    id: 1840008250,
  },
  {
    name: "Bayshore",
    state_id: "NC",
    lat: 34.2893,
    lng: -77.8025,
    id: 1840033015,
  },
  {
    name: "Edwardsville",
    state_id: "KS",
    lat: 39.0765,
    lng: -94.8166,
    id: 1840001625,
  },
  {
    name: "Fremont",
    state_id: "MI",
    lat: 43.463,
    lng: -85.9541,
    id: 1840002680,
  },
  {
    name: "Florence",
    state_id: "MS",
    lat: 32.1557,
    lng: -90.1225,
    id: 1840016982,
  },
  {
    name: "Orangeburg",
    state_id: "NY",
    lat: 41.0488,
    lng: -73.9407,
    id: 1840004965,
  },
  {
    name: "Ottawa Hills",
    state_id: "OH",
    lat: 41.6682,
    lng: -83.6433,
    id: 1840000571,
  },
  {
    name: "Jamestown",
    state_id: "NC",
    lat: 35.9985,
    lng: -79.9347,
    id: 1840016136,
  },
  {
    name: "Sayre",
    state_id: "OK",
    lat: 35.2973,
    lng: -99.6284,
    id: 1840021774,
  },
  {
    name: "Pine Level",
    state_id: "AL",
    lat: 32.5797,
    lng: -86.4529,
    id: 1840023046,
  },
  {
    name: "Ebensburg",
    state_id: "PA",
    lat: 40.4884,
    lng: -78.7264,
    id: 1840001109,
  },
  {
    name: "Fussels Corner",
    state_id: "FL",
    lat: 28.0574,
    lng: -81.861,
    id: 1840029036,
  },
  {
    name: "Medina",
    state_id: "TN",
    lat: 35.8152,
    lng: -88.7901,
    id: 1840015340,
  },
  {
    name: "San Diego",
    state_id: "TX",
    lat: 27.7609,
    lng: -98.2389,
    id: 1840022255,
  },
  {
    name: "Santa Venetia",
    state_id: "CA",
    lat: 38.0055,
    lng: -122.5032,
    id: 1840018887,
  },
  {
    name: "Williamstown",
    state_id: "KY",
    lat: 38.6415,
    lng: -84.5676,
    id: 1840015179,
  },
  {
    name: "Shady Hollow",
    state_id: "TX",
    lat: 30.1646,
    lng: -97.8629,
    id: 1840019587,
  },
  {
    name: "Rosedale",
    state_id: "WA",
    lat: 47.3453,
    lng: -122.6368,
    id: 1840023839,
  },
  {
    name: "Walhalla",
    state_id: "SC",
    lat: 34.7705,
    lng: -83.0615,
    id: 1840015507,
  },
  {
    name: "Newton Falls",
    state_id: "OH",
    lat: 41.1888,
    lng: -80.9712,
    id: 1840008240,
  },
  {
    name: "Castle Hills",
    state_id: "TX",
    lat: 29.5229,
    lng: -98.5197,
    id: 1840019652,
  },
  {
    name: "Pinetop-Lakeside",
    state_id: "AZ",
    lat: 34.1486,
    lng: -109.9658,
    id: 1840022571,
  },
  {
    name: "Goodland",
    state_id: "KS",
    lat: 39.3489,
    lng: -101.7142,
    id: 1840001552,
  },
  {
    name: "Huber Ridge",
    state_id: "OH",
    lat: 40.0909,
    lng: -82.9174,
    id: 1840005544,
  },
  {
    name: "Woodville",
    state_id: "TX",
    lat: 30.774,
    lng: -94.4236,
    id: 1840023135,
  },
  {
    name: "Berryville",
    state_id: "VA",
    lat: 39.1504,
    lng: -77.9824,
    id: 1840005892,
  },
  {
    name: "Millersburg",
    state_id: "PA",
    lat: 40.5422,
    lng: -76.9554,
    id: 1840001292,
  },
  {
    name: "Utqiagvik",
    state_id: "AK",
    lat: 71.2728,
    lng: -156.7575,
    id: 1840023481,
  },
  {
    name: "Helena Valley Northwest",
    state_id: "MT",
    lat: 46.729,
    lng: -112.0627,
    id: 1840074064,
  },
  {
    name: "Holiday City South",
    state_id: "NJ",
    lat: 39.9533,
    lng: -74.2366,
    id: 1840073688,
  },
  {
    name: "White City",
    state_id: "FL",
    lat: 27.3722,
    lng: -80.3403,
    id: 1840134890,
  },
  {
    name: "Sunnyside",
    state_id: "CA",
    lat: 36.7294,
    lng: -119.6945,
    id: 1840024781,
  },
  {
    name: "Algood",
    state_id: "TN",
    lat: 36.1999,
    lng: -85.4467,
    id: 1840015317,
  },
  {
    name: "Mount Holly Springs",
    state_id: "PA",
    lat: 40.1126,
    lng: -77.1854,
    id: 1840001389,
  },
  {
    name: "Horse Cave",
    state_id: "KY",
    lat: 37.1738,
    lng: -85.9146,
    id: 1840014375,
  },
  {
    name: "High Ridge",
    state_id: "MO",
    lat: 38.4608,
    lng: -90.5339,
    id: 1840006233,
  },
  {
    name: "Cramerton",
    state_id: "NC",
    lat: 35.2344,
    lng: -81.0734,
    id: 1840016395,
  },
  {
    name: "Oak Grove",
    state_id: "TN",
    lat: 36.4223,
    lng: -82.4293,
    id: 1840013335,
  },
  {
    name: "Bonsall",
    state_id: "CA",
    lat: 33.2761,
    lng: -117.1942,
    id: 1840018016,
  },
  {
    name: "Minerva",
    state_id: "OH",
    lat: 40.7306,
    lng: -81.1022,
    id: 1840011916,
  },
  {
    name: "Whiteville",
    state_id: "TN",
    lat: 35.318,
    lng: -89.1498,
    id: 1840016388,
  },
  {
    name: "West Peoria",
    state_id: "IL",
    lat: 40.697,
    lng: -89.6398,
    id: 1840010352,
  },
  {
    name: "Beaufort",
    state_id: "NC",
    lat: 34.7308,
    lng: -76.6487,
    id: 1840015496,
  },
  {
    name: "Grayson",
    state_id: "GA",
    lat: 33.89,
    lng: -83.9574,
    id: 1840013676,
  },
  {
    name: "San Carlos",
    state_id: "AZ",
    lat: 33.35,
    lng: -110.4654,
    id: 1840019263,
  },
  {
    name: "East Merrimack",
    state_id: "NH",
    lat: 42.867,
    lng: -71.484,
    id: 1840033211,
  },
  {
    name: "Holtville",
    state_id: "AL",
    lat: 32.6314,
    lng: -86.3268,
    id: 1840023043,
  },
  {
    name: "Mountain View Acres",
    state_id: "CA",
    lat: 34.4976,
    lng: -117.3472,
    id: 1840019111,
  },
  {
    name: "La Verkin",
    state_id: "UT",
    lat: 37.2308,
    lng: -113.2523,
    id: 1840037162,
  },
  {
    name: "Mount Hood Village",
    state_id: "OR",
    lat: 45.3433,
    lng: -121.9832,
    id: 1840034828,
  },
  {
    name: "Pupukea",
    state_id: "HI",
    lat: 21.6511,
    lng: -158.0436,
    id: 1840029586,
  },
  {
    name: "Reedsport",
    state_id: "OR",
    lat: 43.6983,
    lng: -124.1122,
    id: 1840020032,
  },
  {
    name: "Lake Tansi",
    state_id: "TN",
    lat: 35.8706,
    lng: -85.0617,
    id: 1840013375,
  },
  {
    name: "Coquille",
    state_id: "OR",
    lat: 43.1802,
    lng: -124.1841,
    id: 1840018654,
  },
  {
    name: "Armonk",
    state_id: "NY",
    lat: 41.132,
    lng: -73.7137,
    id: 1840004914,
  },
  {
    name: "Mary Esther",
    state_id: "FL",
    lat: 30.4127,
    lng: -86.6588,
    id: 1840015902,
  },
  {
    name: "Saukville",
    state_id: "WI",
    lat: 43.3847,
    lng: -87.9439,
    id: 1840002827,
  },
  {
    name: "Unalaska",
    state_id: "AK",
    lat: 53.8984,
    lng: -166.568,
    id: 1840023260,
  },
  {
    name: "The Meadows",
    state_id: "FL",
    lat: 27.3653,
    lng: -82.4724,
    id: 1840029087,
  },
  {
    name: "Tazewell",
    state_id: "VA",
    lat: 37.1268,
    lng: -81.5134,
    id: 1840006455,
  },
  {
    name: "Beecher",
    state_id: "IL",
    lat: 41.3501,
    lng: -87.6174,
    id: 1840011481,
  },
  {
    name: "Crestline",
    state_id: "OH",
    lat: 40.7836,
    lng: -82.7458,
    id: 1840007171,
  },
  {
    name: "Enon",
    state_id: "VA",
    lat: 37.3273,
    lng: -77.3194,
    id: 1840026844,
  },
  {
    name: "Russell",
    state_id: "KS",
    lat: 38.8878,
    lng: -98.8513,
    id: 1840001631,
  },
  {
    name: "Dilworth",
    state_id: "MN",
    lat: 46.8795,
    lng: -96.6986,
    id: 1840006637,
  },
  {
    name: "Valdese",
    state_id: "NC",
    lat: 35.7566,
    lng: -81.5634,
    id: 1840016234,
  },
  {
    name: "Marlow",
    state_id: "OK",
    lat: 34.6296,
    lng: -97.9579,
    id: 1840020526,
  },
  {
    name: "Jenkintown",
    state_id: "PA",
    lat: 40.0962,
    lng: -75.1296,
    id: 1840003702,
  },
  {
    name: "Perryville",
    state_id: "MD",
    lat: 39.5738,
    lng: -76.0669,
    id: 1840005640,
  },
  {
    name: "Berwick",
    state_id: "LA",
    lat: 29.7013,
    lng: -91.2377,
    id: 1840015942,
  },
  {
    name: "Planada",
    state_id: "CA",
    lat: 37.2892,
    lng: -120.3207,
    id: 1840018932,
  },
  {
    name: "Towanda",
    state_id: "PA",
    lat: 41.7707,
    lng: -76.4472,
    id: 1840000520,
  },
  {
    name: "California",
    state_id: "MO",
    lat: 38.6304,
    lng: -92.5667,
    id: 1840007435,
  },
  {
    name: "Ste. Genevieve",
    state_id: "MO",
    lat: 37.9747,
    lng: -90.0481,
    id: 1840009854,
  },
  {
    name: "Stock Island",
    state_id: "FL",
    lat: 24.5658,
    lng: -81.7351,
    id: 1840029082,
  },
  {
    name: "Brookridge",
    state_id: "FL",
    lat: 28.5483,
    lng: -82.4897,
    id: 1840029022,
  },
  {
    name: "Churchville",
    state_id: "PA",
    lat: 40.1994,
    lng: -74.9986,
    id: 1840035279,
  },
  {
    name: "Senoia",
    state_id: "GA",
    lat: 33.3082,
    lng: -84.5546,
    id: 1840015719,
  },
  {
    name: "Cortland",
    state_id: "IL",
    lat: 41.9255,
    lng: -88.6794,
    id: 1840009189,
  },
  {
    name: "King City",
    state_id: "OR",
    lat: 45.4011,
    lng: -122.8071,
    id: 1840019934,
  },
  {
    name: "Kensington Park",
    state_id: "FL",
    lat: 27.3591,
    lng: -82.4931,
    id: 1840029046,
  },
  {
    name: "Armona",
    state_id: "CA",
    lat: 36.3179,
    lng: -119.7054,
    id: 1840017716,
  },
  {
    name: "Jourdanton",
    state_id: "TX",
    lat: 28.9138,
    lng: -98.5411,
    id: 1840019680,
  },
  {
    name: "Sheffield",
    state_id: "OH",
    lat: 41.4559,
    lng: -82.0912,
    id: 1840000646,
  },
  {
    name: "Viroqua",
    state_id: "WI",
    lat: 43.5582,
    lng: -90.8863,
    id: 1840002688,
  },
  {
    name: "Jessup",
    state_id: "PA",
    lat: 41.4622,
    lng: -75.5478,
    id: 1840003383,
  },
  {
    name: "St. James",
    state_id: "MN",
    lat: 43.9832,
    lng: -94.6249,
    id: 1840008994,
  },
  {
    name: "Ashdown",
    state_id: "AR",
    lat: 33.6746,
    lng: -94.1264,
    id: 1840013711,
  },
  {
    name: "Sparta",
    state_id: "MI",
    lat: 43.1575,
    lng: -85.7091,
    id: 1840011006,
  },
  {
    name: "Montoursville",
    state_id: "PA",
    lat: 41.2472,
    lng: -76.9184,
    id: 1840000632,
  },
  {
    name: "Walworth",
    state_id: "WI",
    lat: 42.5305,
    lng: -88.5941,
    id: 1840002475,
  },
  {
    name: "Samoset",
    state_id: "FL",
    lat: 27.4752,
    lng: -82.543,
    id: 1840014169,
  },
  {
    name: "McSwain",
    state_id: "CA",
    lat: 37.3146,
    lng: -120.5867,
    id: 1840038303,
  },
  {
    name: "Carlisle",
    state_id: "IA",
    lat: 41.5116,
    lng: -93.4949,
    id: 1840007104,
  },
  {
    name: "Suquamish",
    state_id: "WA",
    lat: 47.7237,
    lng: -122.583,
    id: 1840018411,
  },
  {
    name: "Stanford",
    state_id: "KY",
    lat: 37.535,
    lng: -84.6605,
    id: 1840015242,
  },
  {
    name: "Ely",
    state_id: "NV",
    lat: 39.2649,
    lng: -114.8709,
    id: 1840020183,
  },
  {
    name: "Hallsville",
    state_id: "TX",
    lat: 32.5027,
    lng: -94.57,
    id: 1840020738,
  },
  {
    name: "Holts Summit",
    state_id: "MO",
    lat: 38.6474,
    lng: -92.1142,
    id: 1840007417,
  },
  {
    name: "Clintonville",
    state_id: "WI",
    lat: 44.6222,
    lng: -88.7513,
    id: 1840002358,
  },
  {
    name: "Clarkdale",
    state_id: "AZ",
    lat: 34.7503,
    lng: -112.055,
    id: 1840022754,
  },
  {
    name: "Church Point",
    state_id: "LA",
    lat: 30.4037,
    lng: -92.2145,
    id: 1840017200,
  },
  {
    name: "Brookville",
    state_id: "PA",
    lat: 41.1615,
    lng: -79.0827,
    id: 1840003469,
  },
  {
    name: "Redland",
    state_id: "AL",
    lat: 32.4754,
    lng: -86.1375,
    id: 1840037844,
  },
  {
    name: "Hurstbourne",
    state_id: "KY",
    lat: 38.238,
    lng: -85.5891,
    id: 1840014308,
  },
  {
    name: "Mount Olive",
    state_id: "AL",
    lat: 33.6778,
    lng: -86.8726,
    id: 1840013727,
  },
  {
    name: "Coopersville",
    state_id: "MI",
    lat: 43.0658,
    lng: -85.9341,
    id: 1840002993,
  },
  {
    name: "Arabi",
    state_id: "LA",
    lat: 29.9562,
    lng: -89.9986,
    id: 1840014000,
  },
  {
    name: "Windom",
    state_id: "MN",
    lat: 43.8736,
    lng: -95.1201,
    id: 1840010032,
  },
  {
    name: "Nashville",
    state_id: "AR",
    lat: 33.9418,
    lng: -93.8516,
    id: 1840014709,
  },
  {
    name: "Kingsville",
    state_id: "MD",
    lat: 39.4496,
    lng: -76.4204,
    id: 1840005693,
  },
  {
    name: "Larksville",
    state_id: "PA",
    lat: 41.2639,
    lng: -75.9326,
    id: 1840000758,
  },
  {
    name: "Jacksboro",
    state_id: "TX",
    lat: 33.2256,
    lng: -98.1594,
    id: 1840019369,
  },
  {
    name: "West Laurel",
    state_id: "MD",
    lat: 39.1133,
    lng: -76.8924,
    id: 1840005968,
  },
  {
    name: "Argyle",
    state_id: "TX",
    lat: 33.1105,
    lng: -97.1864,
    id: 1840019386,
  },
  {
    name: "Van Alstyne",
    state_id: "TX",
    lat: 33.4203,
    lng: -96.5834,
    id: 1840021956,
  },
  {
    name: "Cortez",
    state_id: "FL",
    lat: 27.4668,
    lng: -82.6687,
    id: 1840013125,
  },
  {
    name: "Cornwall",
    state_id: "PA",
    lat: 40.2659,
    lng: -76.4077,
    id: 1840001345,
  },
  {
    name: "Fetters Hot Springs-Agua Caliente",
    state_id: "CA",
    lat: 38.3274,
    lng: -122.4871,
    id: 1840073930,
  },
  {
    name: "Caldwell",
    state_id: "TX",
    lat: 30.5307,
    lng: -96.7007,
    id: 1840019578,
  },
  {
    name: "Woodbridge",
    state_id: "VA",
    lat: 38.6569,
    lng: -77.2403,
    id: 1840006106,
  },
  {
    name: "Albion",
    state_id: "PA",
    lat: 41.8904,
    lng: -80.3641,
    id: 1840000473,
  },
  {
    name: "Noyack",
    state_id: "NY",
    lat: 40.9827,
    lng: -72.335,
    id: 1840005062,
  },
  {
    name: "Heeia",
    state_id: "HI",
    lat: 21.4211,
    lng: -157.8203,
    id: 1840029481,
  },
  {
    name: "Pendleton",
    state_id: "IN",
    lat: 40.0067,
    lng: -85.7712,
    id: 1840014100,
  },
  {
    name: "Wonder Lake",
    state_id: "IL",
    lat: 42.3792,
    lng: -88.3496,
    id: 1840011142,
  },
  {
    name: "Fort Branch",
    state_id: "IN",
    lat: 38.2461,
    lng: -87.5734,
    id: 1840010796,
  },
  {
    name: "Long Hill",
    state_id: "CT",
    lat: 41.3536,
    lng: -72.0517,
    id: 1840003348,
  },
  {
    name: "Pittsboro",
    state_id: "NC",
    lat: 35.7256,
    lng: -79.1714,
    id: 1840016259,
  },
  {
    name: "Grottoes",
    state_id: "VA",
    lat: 38.2692,
    lng: -78.8252,
    id: 1840006134,
  },
  {
    name: "Euharlee",
    state_id: "GA",
    lat: 34.1441,
    lng: -84.9327,
    id: 1840013622,
  },
  {
    name: "Tarentum",
    state_id: "PA",
    lat: 40.6041,
    lng: -79.7601,
    id: 1840001265,
  },
  {
    name: "Camanche",
    state_id: "IA",
    lat: 41.7928,
    lng: -90.2766,
    id: 1840007039,
  },
  {
    name: "Todd Creek",
    state_id: "CO",
    lat: 39.9795,
    lng: -104.8726,
    id: 1840028571,
  },
  {
    name: "Deer Park",
    state_id: "WA",
    lat: 47.9642,
    lng: -117.4398,
    id: 1840018400,
  },
  {
    name: "Balm",
    state_id: "FL",
    lat: 27.7541,
    lng: -82.2882,
    id: 1840025162,
  },
  {
    name: "Pacheco",
    state_id: "CA",
    lat: 37.9878,
    lng: -122.07,
    id: 1840018898,
  },
  {
    name: "Lake Providence",
    state_id: "LA",
    lat: 32.8133,
    lng: -91.1826,
    id: 1840016945,
  },
  {
    name: "Copperopolis",
    state_id: "CA",
    lat: 37.9365,
    lng: -120.6277,
    id: 1840017589,
  },
  {
    name: "Isle of Palms",
    state_id: "SC",
    lat: 32.8043,
    lng: -79.7552,
    id: 1840015165,
  },
  {
    name: "Tontitown",
    state_id: "AR",
    lat: 36.1642,
    lng: -94.2457,
    id: 1840015337,
  },
  {
    name: "Laurel",
    state_id: "DE",
    lat: 38.5692,
    lng: -75.5689,
    id: 1840006078,
  },
  {
    name: "Hamburg",
    state_id: "PA",
    lat: 40.5561,
    lng: -75.9825,
    id: 1840001176,
  },
  {
    name: "Mauston",
    state_id: "WI",
    lat: 43.7995,
    lng: -90.0775,
    id: 1840002230,
  },
  {
    name: "West Hazleton",
    state_id: "PA",
    lat: 40.9699,
    lng: -76.013,
    id: 1840000773,
  },
  {
    name: "Hearne",
    state_id: "TX",
    lat: 30.877,
    lng: -96.5956,
    id: 1840020850,
  },
  {
    name: "Garwood",
    state_id: "NJ",
    lat: 40.6513,
    lng: -74.3231,
    id: 1840003615,
  },
  {
    name: "Shallotte",
    state_id: "NC",
    lat: 33.9759,
    lng: -78.3807,
    id: 1840017953,
  },
  {
    name: "Atlantic Highlands",
    state_id: "NJ",
    lat: 40.4112,
    lng: -74.0296,
    id: 1840003685,
  },
  {
    name: "Sullivan",
    state_id: "IN",
    lat: 39.0973,
    lng: -87.4074,
    id: 1840009628,
  },
  {
    name: "Harrogate",
    state_id: "TN",
    lat: 36.5752,
    lng: -83.6462,
    id: 1840013303,
  },
  {
    name: "Argo",
    state_id: "AL",
    lat: 33.6961,
    lng: -86.5066,
    id: 1840015668,
  },
  {
    name: "Canton",
    state_id: "NC",
    lat: 35.5515,
    lng: -82.8406,
    id: 1840015395,
  },
  {
    name: "American Falls",
    state_id: "ID",
    lat: 42.7828,
    lng: -112.8541,
    id: 1840018666,
  },
  {
    name: "Cedarville",
    state_id: "OH",
    lat: 39.7471,
    lng: -83.8109,
    id: 1840010597,
  },
  {
    name: "Brices Creek",
    state_id: "NC",
    lat: 35.0505,
    lng: -77.0844,
    id: 1840033011,
  },
  {
    name: "Greendale",
    state_id: "IN",
    lat: 39.1317,
    lng: -84.851,
    id: 1840008522,
  },
  {
    name: "French Island",
    state_id: "WI",
    lat: 43.8593,
    lng: -91.2614,
    id: 1840004157,
  },
  {
    name: "North Terre Haute",
    state_id: "IN",
    lat: 39.537,
    lng: -87.3653,
    id: 1840005743,
  },
  {
    name: "East Troy",
    state_id: "WI",
    lat: 42.7848,
    lng: -88.3973,
    id: 1840002484,
  },
  {
    name: "St. Bernard",
    state_id: "OH",
    lat: 39.1711,
    lng: -84.4956,
    id: 1840001612,
  },
  {
    name: "Eldorado",
    state_id: "IL",
    lat: 37.8113,
    lng: -88.4415,
    id: 1840008708,
  },
  {
    name: "Narberth",
    state_id: "PA",
    lat: 40.0077,
    lng: -75.2635,
    id: 1840003704,
  },
  {
    name: "Elk Ridge",
    state_id: "UT",
    lat: 40.0099,
    lng: -111.6775,
    id: 1840020166,
  },
  {
    name: "Bayside",
    state_id: "WI",
    lat: 43.1827,
    lng: -87.9017,
    id: 1840003044,
  },
  {
    name: "Flourtown",
    state_id: "PA",
    lat: 40.1039,
    lng: -75.2069,
    id: 1840034968,
  },
  {
    name: "Dyersville",
    state_id: "IA",
    lat: 42.4817,
    lng: -91.1176,
    id: 1840008044,
  },
  {
    name: "Lake Montezuma",
    state_id: "AZ",
    lat: 34.6414,
    lng: -111.796,
    id: 1840017849,
  },
  {
    name: "Forks",
    state_id: "WA",
    lat: 47.9528,
    lng: -124.3904,
    id: 1840019781,
  },
  {
    name: "Fulton",
    state_id: "MD",
    lat: 39.1516,
    lng: -76.9163,
    id: 1840024480,
  },
  {
    name: "Wallace",
    state_id: "NC",
    lat: 34.7379,
    lng: -77.9909,
    id: 1840016471,
  },
  {
    name: "Cokato",
    state_id: "MN",
    lat: 45.0771,
    lng: -94.1879,
    id: 1840006705,
  },
  {
    name: "Taylor",
    state_id: "AZ",
    lat: 34.4393,
    lng: -110.1018,
    id: 1840022573,
  },
  {
    name: "Dumas",
    state_id: "AR",
    lat: 33.8849,
    lng: -91.4861,
    id: 1840013683,
  },
  {
    name: "Spring Valley Village",
    state_id: "TX",
    lat: 29.7898,
    lng: -95.5041,
    id: 1840074867,
  },
  {
    name: "Del Mar",
    state_id: "CA",
    lat: 32.9633,
    lng: -117.2627,
    id: 1840019355,
  },
  {
    name: "Montgomery",
    state_id: "WV",
    lat: 38.1741,
    lng: -81.3241,
    id: 1840038276,
  },
  {
    name: "Bayou Vista",
    state_id: "LA",
    lat: 29.6912,
    lng: -91.2672,
    id: 1840014017,
  },
  {
    name: "Nikiski",
    state_id: "AK",
    lat: 60.7152,
    lng: -151.2317,
    id: 1840023615,
  },
  {
    name: "Glassport",
    state_id: "PA",
    lat: 40.3262,
    lng: -79.8861,
    id: 1840001233,
  },
  {
    name: "Humboldt Hill",
    state_id: "CA",
    lat: 40.7219,
    lng: -124.1987,
    id: 1840028380,
  },
  {
    name: "Larned",
    state_id: "KS",
    lat: 38.1834,
    lng: -99.1014,
    id: 1840001667,
  },
  {
    name: "Moon Lake",
    state_id: "FL",
    lat: 28.2993,
    lng: -82.6049,
    id: 1840039008,
  },
  {
    name: "Bethel Manor",
    state_id: "VA",
    lat: 37.0973,
    lng: -76.4244,
    id: 1840043173,
  },
  {
    name: "Indian River Shores",
    state_id: "FL",
    lat: 27.7084,
    lng: -80.3851,
    id: 1840017246,
  },
  {
    name: "West Ocean City",
    state_id: "MD",
    lat: 38.3476,
    lng: -75.1115,
    id: 1840006255,
  },
  {
    name: "Wright City",
    state_id: "MO",
    lat: 38.8341,
    lng: -91.0399,
    id: 1840010715,
  },
  {
    name: "Slatington",
    state_id: "PA",
    lat: 40.7544,
    lng: -75.6114,
    id: 1840001051,
  },
  {
    name: "Aumsville",
    state_id: "OR",
    lat: 44.8462,
    lng: -122.8698,
    id: 1840018577,
  },
  {
    name: "Holmes Beach",
    state_id: "FL",
    lat: 27.5108,
    lng: -82.7153,
    id: 1840015117,
  },
  {
    name: "Springville",
    state_id: "AL",
    lat: 33.7643,
    lng: -86.4749,
    id: 1840015642,
  },
  {
    name: "Pennside",
    state_id: "PA",
    lat: 40.3384,
    lng: -75.8781,
    id: 1840035147,
  },
  {
    name: "Madeira Beach",
    state_id: "FL",
    lat: 27.7985,
    lng: -82.7887,
    id: 1840015972,
  },
  {
    name: "Whitesboro",
    state_id: "TX",
    lat: 33.6612,
    lng: -96.9022,
    id: 1840021957,
  },
  {
    name: "Whiteriver",
    state_id: "AZ",
    lat: 33.8358,
    lng: -109.9616,
    id: 1840018966,
  },
  {
    name: "Slaughterville",
    state_id: "OK",
    lat: 35.091,
    lng: -97.2879,
    id: 1840022780,
  },
  {
    name: "National Harbor",
    state_id: "MD",
    lat: 38.7883,
    lng: -77.0106,
    id: 1840039517,
  },
  {
    name: "West Reading",
    state_id: "PA",
    lat: 40.3337,
    lng: -75.947,
    id: 1840001194,
  },
  {
    name: "Prescott",
    state_id: "WI",
    lat: 44.7523,
    lng: -92.7883,
    id: 1840002288,
  },
  {
    name: "Lamar",
    state_id: "MO",
    lat: 37.4945,
    lng: -94.2791,
    id: 1840008727,
  },
  {
    name: "Wauna",
    state_id: "WA",
    lat: 47.386,
    lng: -122.6694,
    id: 1840023842,
  },
  {
    name: "La Joya",
    state_id: "TX",
    lat: 26.2518,
    lng: -98.4699,
    id: 1840021032,
  },
  {
    name: "Middlebury",
    state_id: "IN",
    lat: 41.6696,
    lng: -85.7075,
    id: 1840009239,
  },
  {
    name: "Barnwell",
    state_id: "SC",
    lat: 33.2419,
    lng: -81.3659,
    id: 1840013763,
  },
  {
    name: "Priceville",
    state_id: "AL",
    lat: 34.5247,
    lng: -86.8855,
    id: 1840017927,
  },
  {
    name: "Selmer",
    state_id: "TN",
    lat: 35.1708,
    lng: -88.5955,
    id: 1840017869,
  },
  {
    name: "Chatsworth",
    state_id: "GA",
    lat: 34.7808,
    lng: -84.7835,
    id: 1840014648,
  },
  {
    name: "Crane",
    state_id: "TX",
    lat: 31.3919,
    lng: -102.3503,
    id: 1840019542,
  },
  {
    name: "Geneva",
    state_id: "AL",
    lat: 31.0437,
    lng: -85.8763,
    id: 1840003161,
  },
  {
    name: "Melwood",
    state_id: "MD",
    lat: 38.8022,
    lng: -76.8419,
    id: 1840039512,
  },
  {
    name: "Crittenden",
    state_id: "KY",
    lat: 38.7754,
    lng: -84.6137,
    id: 1840014271,
  },
  {
    name: "Springville",
    state_id: "NY",
    lat: 42.5084,
    lng: -78.6696,
    id: 1840004391,
  },
  {
    name: "Ridge Wood Heights",
    state_id: "FL",
    lat: 27.2874,
    lng: -82.5137,
    id: 1840029070,
  },
  {
    name: "Nelson",
    state_id: "GA",
    lat: 34.379,
    lng: -84.3707,
    id: 1840014686,
  },
  {
    name: "Cross Plains",
    state_id: "WI",
    lat: 43.1145,
    lng: -89.648,
    id: 1840002911,
  },
  {
    name: "Grant-Valkaria",
    state_id: "FL",
    lat: 27.9322,
    lng: -80.5655,
    id: 1840017235,
  },
  {
    name: "Hayfield",
    state_id: "VA",
    lat: 38.7536,
    lng: -77.1321,
    id: 1840024573,
  },
  {
    name: "Indian Rocks Beach",
    state_id: "FL",
    lat: 27.8961,
    lng: -82.8444,
    id: 1840015113,
  },
  {
    name: "Osawatomie",
    state_id: "KS",
    lat: 38.5064,
    lng: -94.9483,
    id: 1840003841,
  },
  {
    name: "Buena",
    state_id: "NJ",
    lat: 39.5282,
    lng: -74.9448,
    id: 1840001534,
  },
  {
    name: "Sellersville",
    state_id: "PA",
    lat: 40.36,
    lng: -75.3083,
    id: 1840001313,
  },
  {
    name: "Medford",
    state_id: "WI",
    lat: 45.1365,
    lng: -90.3436,
    id: 1840001571,
  },
  {
    name: "Mifflinburg",
    state_id: "PA",
    lat: 40.9202,
    lng: -77.0465,
    id: 1840003532,
  },
  {
    name: "Three Oaks",
    state_id: "FL",
    lat: 26.4738,
    lng: -81.796,
    id: 1840029011,
  },
  {
    name: "Adamsville",
    state_id: "AL",
    lat: 33.6063,
    lng: -86.9745,
    id: 1840013724,
  },
  {
    name: "Justin",
    state_id: "TX",
    lat: 33.0862,
    lng: -97.3009,
    id: 1840019388,
  },
  {
    name: "Fayette",
    state_id: "AL",
    lat: 33.6943,
    lng: -87.8307,
    id: 1840006295,
  },
  {
    name: "Kenmar",
    state_id: "PA",
    lat: 41.2547,
    lng: -76.955,
    id: 1840035287,
  },
  {
    name: "Lytle",
    state_id: "TX",
    lat: 29.2342,
    lng: -98.795,
    id: 1840020987,
  },
  {
    name: "Coleman",
    state_id: "TX",
    lat: 31.8317,
    lng: -99.4223,
    id: 1840019516,
  },
  {
    name: "Sparta",
    state_id: "IL",
    lat: 38.1514,
    lng: -89.7185,
    id: 1840009840,
  },
  {
    name: "Morgan",
    state_id: "UT",
    lat: 41.0414,
    lng: -111.6802,
    id: 1840020138,
  },
  {
    name: "Magnolia",
    state_id: "NJ",
    lat: 39.8562,
    lng: -75.0365,
    id: 1840003769,
  },
  {
    name: "Mather",
    state_id: "CA",
    lat: 38.5489,
    lng: -121.2832,
    id: 1840075962,
  },
  {
    name: "Centralia",
    state_id: "MO",
    lat: 39.2105,
    lng: -92.1341,
    id: 1840007392,
  },
  {
    name: "Chalfont",
    state_id: "PA",
    lat: 40.2894,
    lng: -75.2096,
    id: 1840001319,
  },
  {
    name: "Quebradillas",
    state_id: "PR",
    lat: 18.4732,
    lng: -66.936,
    id: 1630035558,
  },
  {
    name: "Oneida",
    state_id: "TN",
    lat: 36.5172,
    lng: -84.5104,
    id: 1840016019,
  },
  {
    name: "Port Aransas",
    state_id: "TX",
    lat: 27.8102,
    lng: -97.0875,
    id: 1840021012,
  },
  {
    name: "Beaver",
    state_id: "PA",
    lat: 40.6936,
    lng: -80.3074,
    id: 1840001012,
  },
  {
    name: "Travis Ranch",
    state_id: "TX",
    lat: 32.8038,
    lng: -96.4759,
    id: 1840041568,
  },
  {
    name: "Lafayette",
    state_id: "OR",
    lat: 45.2463,
    lng: -123.1116,
    id: 1840019958,
  },
  {
    name: "Reidland",
    state_id: "KY",
    lat: 37.0085,
    lng: -88.5261,
    id: 1840013264,
  },
  {
    name: "Kewaskum",
    state_id: "WI",
    lat: 43.5177,
    lng: -88.2306,
    id: 1840002833,
  },
  {
    name: "Park Layne",
    state_id: "OH",
    lat: 39.8881,
    lng: -84.0394,
    id: 1840034392,
  },
  {
    name: "Clearwater",
    state_id: "SC",
    lat: 33.5038,
    lng: -81.91,
    id: 1840013721,
  },
  {
    name: "Buchanan",
    state_id: "MI",
    lat: 41.8283,
    lng: -86.3657,
    id: 1840003217,
  },
  {
    name: "Blanchester",
    state_id: "OH",
    lat: 39.2934,
    lng: -83.9797,
    id: 1840012618,
  },
  {
    name: "Belleair",
    state_id: "FL",
    lat: 27.9365,
    lng: -82.8114,
    id: 1840015973,
  },
  {
    name: "Williamston",
    state_id: "SC",
    lat: 34.6194,
    lng: -82.4794,
    id: 1840016576,
  },
  {
    name: "Florida",
    state_id: "PR",
    lat: 18.3643,
    lng: -66.5611,
    id: 1630023648,
  },
  {
    name: "Imperial",
    state_id: "MO",
    lat: 38.3672,
    lng: -90.3706,
    id: 1840006235,
  },
  {
    name: "Barboursville",
    state_id: "WV",
    lat: 38.4061,
    lng: -82.297,
    id: 1840006213,
  },
  {
    name: "Adjuntas",
    state_id: "PR",
    lat: 18.1638,
    lng: -66.7235,
    id: 1630035603,
  },
  {
    name: "Mulberry",
    state_id: "FL",
    lat: 27.9058,
    lng: -81.9872,
    id: 1840015105,
  },
  {
    name: "Hokes Bluff",
    state_id: "AL",
    lat: 33.9902,
    lng: -85.8639,
    id: 1840014733,
  },
  {
    name: "Newport",
    state_id: "VT",
    lat: 44.9375,
    lng: -72.2088,
    id: 1840002270,
  },
  {
    name: "Mifflintown",
    state_id: "PA",
    lat: 40.5711,
    lng: -77.3951,
    id: 1840003620,
  },
  {
    name: "Pittsfield",
    state_id: "IL",
    lat: 39.6202,
    lng: -90.7934,
    id: 1840009545,
  },
  {
    name: "Ellisburg",
    state_id: "NJ",
    lat: 39.9199,
    lng: -75.0093,
    id: 1840033268,
  },
  {
    name: "Groesbeck",
    state_id: "TX",
    lat: 31.5259,
    lng: -96.5284,
    id: 1840020821,
  },
  {
    name: "Edgewood",
    state_id: "OH",
    lat: 41.8783,
    lng: -80.7461,
    id: 1840004797,
  },
  {
    name: "Stafford Courthouse",
    state_id: "VA",
    lat: 38.417,
    lng: -77.4207,
    id: 1840041867,
  },
  {
    name: "Garden City South",
    state_id: "NY",
    lat: 40.7121,
    lng: -73.6605,
    id: 1840005258,
  },
  {
    name: "Parker",
    state_id: "FL",
    lat: 30.1289,
    lng: -85.6008,
    id: 1840015036,
  },
  {
    name: "Mitchell",
    state_id: "IN",
    lat: 38.7368,
    lng: -86.4755,
    id: 1840008567,
  },
  {
    name: "Mystic",
    state_id: "CT",
    lat: 41.3573,
    lng: -71.9548,
    id: 1840003349,
  },
  {
    name: "Mount Repose",
    state_id: "OH",
    lat: 39.1908,
    lng: -84.2197,
    id: 1840005891,
  },
  {
    name: "Barclay",
    state_id: "NJ",
    lat: 39.9013,
    lng: -75.0009,
    id: 1840043406,
  },
  {
    name: "Pleasant Run Farm",
    state_id: "OH",
    lat: 39.3017,
    lng: -84.5493,
    id: 1840034394,
  },
  {
    name: "Farley",
    state_id: "KY",
    lat: 37.0405,
    lng: -88.5734,
    id: 1840043099,
  },
  {
    name: "Georgetown",
    state_id: "OH",
    lat: 38.8683,
    lng: -83.8993,
    id: 1840012719,
  },
  {
    name: "Freeburg",
    state_id: "IL",
    lat: 38.4398,
    lng: -89.917,
    id: 1840012871,
  },
  {
    name: "Cotulla",
    state_id: "TX",
    lat: 28.4364,
    lng: -99.2367,
    id: 1840019699,
  },
  {
    name: "Raymond",
    state_id: "WA",
    lat: 46.6839,
    lng: -123.738,
    id: 1840019881,
  },
  {
    name: "Sissonville",
    state_id: "WV",
    lat: 38.5042,
    lng: -81.6398,
    id: 1840006195,
  },
  {
    name: "East Tawas",
    state_id: "MI",
    lat: 44.2884,
    lng: -83.4849,
    id: 1840002517,
  },
  {
    name: "Lebanon",
    state_id: "IL",
    lat: 38.6004,
    lng: -89.8116,
    id: 1840008633,
  },
  {
    name: "Waverly",
    state_id: "OH",
    lat: 39.1256,
    lng: -82.9836,
    id: 1840034369,
  },
  {
    name: "Park Rapids",
    state_id: "MN",
    lat: 46.917,
    lng: -95.0577,
    id: 1840008839,
  },
  {
    name: "Fort Pierce South",
    state_id: "FL",
    lat: 27.4096,
    lng: -80.3539,
    id: 1840073835,
  },
  {
    name: "Lyons",
    state_id: "GA",
    lat: 32.2062,
    lng: -82.3215,
    id: 1840015818,
  },
  {
    name: "Pocomoke City",
    state_id: "MD",
    lat: 38.0635,
    lng: -75.5553,
    id: 1840006257,
  },
  {
    name: "Verona",
    state_id: "VA",
    lat: 38.1939,
    lng: -79.0087,
    id: 1840006248,
  },
  {
    name: "Venersborg",
    state_id: "WA",
    lat: 45.786,
    lng: -122.4721,
    id: 1840018521,
  },
  {
    name: "Bethlehem",
    state_id: "NC",
    lat: 35.8162,
    lng: -81.2962,
    id: 1840013382,
  },
  {
    name: "Deer Park",
    state_id: "IL",
    lat: 42.1656,
    lng: -88.0868,
    id: 1840011166,
  },
  {
    name: "Dorneyville",
    state_id: "PA",
    lat: 40.5755,
    lng: -75.5188,
    id: 1840034931,
  },
  {
    name: "Hiram",
    state_id: "GA",
    lat: 33.8771,
    lng: -84.771,
    id: 1840014758,
  },
  {
    name: "Mountain Lakes",
    state_id: "NJ",
    lat: 40.8907,
    lng: -74.4406,
    id: 1840003578,
  },
  {
    name: "Mount Pleasant",
    state_id: "PA",
    lat: 40.151,
    lng: -79.5435,
    id: 1840001147,
  },
  {
    name: "Lake Darby",
    state_id: "OH",
    lat: 39.9604,
    lng: -83.2243,
    id: 1840034387,
  },
  {
    name: "Masontown",
    state_id: "PA",
    lat: 39.8488,
    lng: -79.9081,
    id: 1840000664,
  },
  {
    name: "China Grove",
    state_id: "NC",
    lat: 35.5718,
    lng: -80.5788,
    id: 1840016264,
  },
  {
    name: "Bellville",
    state_id: "TX",
    lat: 29.9472,
    lng: -96.2598,
    id: 1840019624,
  },
  {
    name: "Broken Bow",
    state_id: "OK",
    lat: 34.0277,
    lng: -94.7419,
    id: 1840019259,
  },
  {
    name: "Singac",
    state_id: "NJ",
    lat: 40.8849,
    lng: -74.243,
    id: 1840033411,
  },
  {
    name: "Fredericktown",
    state_id: "MO",
    lat: 37.5643,
    lng: -90.2987,
    id: 1840008730,
  },
  {
    name: "McMurray",
    state_id: "PA",
    lat: 40.2815,
    lng: -80.0874,
    id: 1840005434,
  },
  {
    name: "Ivanhoe",
    state_id: "CA",
    lat: 36.3886,
    lng: -119.2202,
    id: 1840017694,
  },
  {
    name: "Brookfield",
    state_id: "MO",
    lat: 39.7853,
    lng: -93.0772,
    id: 1840005551,
  },
  {
    name: "Mayodan",
    state_id: "NC",
    lat: 36.421,
    lng: -79.9676,
    id: 1840016054,
  },
  {
    name: "Moyock",
    state_id: "NC",
    lat: 36.5166,
    lng: -76.1728,
    id: 1840025764,
  },
  {
    name: "Lonsdale",
    state_id: "MN",
    lat: 44.4776,
    lng: -93.422,
    id: 1840008969,
  },
  {
    name: "Central Gardens",
    state_id: "TX",
    lat: 29.9893,
    lng: -94.0217,
    id: 1840018247,
  },
  {
    name: "Libby",
    state_id: "MT",
    lat: 48.3872,
    lng: -115.556,
    id: 1840019757,
  },
  {
    name: "Hampton",
    state_id: "IA",
    lat: 42.742,
    lng: -93.205,
    id: 1840000401,
  },
  {
    name: "Greenville",
    state_id: "KY",
    lat: 37.2121,
    lng: -87.1779,
    id: 1840013249,
  },
  {
    name: "Esperance",
    state_id: "WA",
    lat: 47.7937,
    lng: -122.3469,
    id: 1840037867,
  },
  {
    name: "Red Rock",
    state_id: "AZ",
    lat: 32.561,
    lng: -111.3745,
    id: 1840022985,
  },
  {
    name: "Woodstock",
    state_id: "AL",
    lat: 33.2193,
    lng: -87.1489,
    id: 1840016895,
  },
  {
    name: "Spindale",
    state_id: "NC",
    lat: 35.3599,
    lng: -81.9232,
    id: 1840017835,
  },
  {
    name: "Lancaster",
    state_id: "KY",
    lat: 37.6152,
    lng: -84.5817,
    id: 1840014345,
  },
  {
    name: "Bristow",
    state_id: "OK",
    lat: 35.8338,
    lng: -96.3941,
    id: 1840019075,
  },
  {
    name: "South Brooksville",
    state_id: "FL",
    lat: 28.5243,
    lng: -82.4158,
    id: 1840029077,
  },
  {
    name: "Comanche",
    state_id: "TX",
    lat: 31.9005,
    lng: -98.6044,
    id: 1840019510,
  },
  {
    name: "Silver Lake",
    state_id: "NJ",
    lat: 40.7804,
    lng: -74.1829,
    id: 1840042853,
  },
  {
    name: "Ellenton",
    state_id: "FL",
    lat: 27.5266,
    lng: -82.5261,
    id: 1840013124,
  },
  {
    name: "Barrington Hills",
    state_id: "IL",
    lat: 42.1399,
    lng: -88.2035,
    id: 1840011245,
  },
  {
    name: "Prairie Heights",
    state_id: "WA",
    lat: 47.1529,
    lng: -122.1048,
    id: 1840042027,
  },
  {
    name: "Lonoke",
    state_id: "AR",
    lat: 34.7908,
    lng: -91.9069,
    id: 1840015502,
  },
  {
    name: "Sebring",
    state_id: "OH",
    lat: 40.9207,
    lng: -81.0231,
    id: 1840000927,
  },
  {
    name: "Thornwood",
    state_id: "NY",
    lat: 41.1185,
    lng: -73.7796,
    id: 1840004928,
  },
  {
    name: "Malden",
    state_id: "MO",
    lat: 36.585,
    lng: -89.9808,
    id: 1840009948,
  },
  {
    name: "Golden Triangle",
    state_id: "NJ",
    lat: 39.9288,
    lng: -75.0398,
    id: 1840033471,
  },
  {
    name: "University Gardens",
    state_id: "NY",
    lat: 40.7751,
    lng: -73.7279,
    id: 1840005280,
  },
  {
    name: "Trenton",
    state_id: "TN",
    lat: 35.9689,
    lng: -88.9449,
    id: 1840015343,
  },
  {
    name: "Monte Vista",
    state_id: "CO",
    lat: 37.5789,
    lng: -106.1499,
    id: 1840020299,
  },
  {
    name: "Malmstrom AFB",
    state_id: "MT",
    lat: 47.5059,
    lng: -111.1825,
    id: 1840073924,
  },
  {
    name: "Hometown",
    state_id: "IL",
    lat: 41.7312,
    lng: -87.7311,
    id: 1840007031,
  },
  {
    name: "Scandia",
    state_id: "MN",
    lat: 45.254,
    lng: -92.8278,
    id: 1840008918,
  },
  {
    name: "Carlyle",
    state_id: "IL",
    lat: 38.6217,
    lng: -89.3735,
    id: 1840007466,
  },
  {
    name: "Westover",
    state_id: "WV",
    lat: 39.6324,
    lng: -79.9773,
    id: 1840005648,
  },
  {
    name: "Mountain Home",
    state_id: "NC",
    lat: 35.3708,
    lng: -82.5021,
    id: 1840013448,
  },
  {
    name: "Moosup",
    state_id: "CT",
    lat: 41.717,
    lng: -71.875,
    id: 1840003277,
  },
  {
    name: "Captain Cook",
    state_id: "HI",
    lat: 19.4995,
    lng: -155.8937,
    id: 1840023191,
  },
  {
    name: "El Cerro Mission",
    state_id: "NM",
    lat: 34.7687,
    lng: -106.6447,
    id: 1840043409,
  },
  {
    name: "Bluffton",
    state_id: "OH",
    lat: 40.8912,
    lng: -83.8887,
    id: 1840011951,
  },
  {
    name: "Whiteman AFB",
    state_id: "MO",
    lat: 38.7302,
    lng: -93.559,
    id: 1840073788,
  },
  {
    name: "Ovilla",
    state_id: "TX",
    lat: 32.5352,
    lng: -96.8895,
    id: 1840020759,
  },
  {
    name: "Columbus",
    state_id: "TX",
    lat: 29.7055,
    lng: -96.5563,
    id: 1840019629,
  },
  {
    name: "Lochmoor Waterway Estates",
    state_id: "FL",
    lat: 26.6437,
    lng: -81.9098,
    id: 1840029053,
  },
  {
    name: "Fortville",
    state_id: "IN",
    lat: 39.9247,
    lng: -85.8465,
    id: 1840013731,
  },
  {
    name: "Ketchum",
    state_id: "ID",
    lat: 43.6868,
    lng: -114.3731,
    id: 1840020029,
  },
  {
    name: "Aldan",
    state_id: "PA",
    lat: 39.9224,
    lng: -75.2881,
    id: 1840000688,
  },
  {
    name: "West Menlo Park",
    state_id: "CA",
    lat: 37.4338,
    lng: -122.2034,
    id: 1840028407,
  },
  {
    name: "Otisville",
    state_id: "NY",
    lat: 41.4714,
    lng: -74.5397,
    id: 1840004876,
  },
  {
    name: "Tiffin",
    state_id: "IA",
    lat: 41.7067,
    lng: -91.6582,
    id: 1840010189,
  },
  {
    name: "Minersville",
    state_id: "PA",
    lat: 40.6908,
    lng: -76.2594,
    id: 1840002766,
  },
  {
    name: "Grafton",
    state_id: "ND",
    lat: 48.4142,
    lng: -97.4055,
    id: 1840000081,
  },
  {
    name: "Heritage Village",
    state_id: "CT",
    lat: 41.4845,
    lng: -73.2351,
    id: 1840028598,
  },
  {
    name: "Oakwood",
    state_id: "GA",
    lat: 34.2237,
    lng: -83.8849,
    id: 1840014697,
  },
  {
    name: "Fruit Hill",
    state_id: "OH",
    lat: 39.0699,
    lng: -84.367,
    id: 1840034265,
  },
  {
    name: "Harbison Canyon",
    state_id: "CA",
    lat: 32.8273,
    lng: -116.8381,
    id: 1840018020,
  },
  {
    name: "Cockrell Hill",
    state_id: "TX",
    lat: 32.7382,
    lng: -96.8886,
    id: 1840019434,
  },
  {
    name: "Carmel Valley Village",
    state_id: "CA",
    lat: 36.4824,
    lng: -121.7198,
    id: 1840028359,
  },
  {
    name: "Tri-City",
    state_id: "OR",
    lat: 42.9893,
    lng: -123.3,
    id: 1840018637,
  },
  {
    name: "Blythewood",
    state_id: "SC",
    lat: 34.2121,
    lng: -80.9921,
    id: 1840015598,
  },
  {
    name: "Burgaw",
    state_id: "NC",
    lat: 34.5507,
    lng: -77.9224,
    id: 1840015548,
  },
  {
    name: "Virden",
    state_id: "IL",
    lat: 39.5058,
    lng: -89.7711,
    id: 1840010647,
  },
  {
    name: "Bradley Beach",
    state_id: "NJ",
    lat: 40.2018,
    lng: -74.0128,
    id: 1840001356,
  },
  {
    name: "Barnesville",
    state_id: "OH",
    lat: 39.9888,
    lng: -81.1728,
    id: 1840012380,
  },
  {
    name: "Waverly",
    state_id: "TN",
    lat: 36.0939,
    lng: -87.7846,
    id: 1840015331,
  },
  {
    name: "Camdenton",
    state_id: "MO",
    lat: 38.0121,
    lng: -92.75,
    id: 1840007520,
  },
  {
    name: "Westville",
    state_id: "NJ",
    lat: 39.8704,
    lng: -75.1301,
    id: 1840001526,
  },
  {
    name: "Imbéry",
    state_id: "PR",
    lat: 18.4371,
    lng: -66.5566,
    id: 1630035464,
  },
  {
    name: "Constantine",
    state_id: "MI",
    lat: 41.8378,
    lng: -85.665,
    id: 1840011383,
  },
  {
    name: "Mulberry",
    state_id: "OH",
    lat: 39.1975,
    lng: -84.2511,
    id: 1840034291,
  },
  {
    name: "Haleyville",
    state_id: "AL",
    lat: 34.2331,
    lng: -87.6175,
    id: 1840013644,
  },
  {
    name: "Louisville",
    state_id: "TN",
    lat: 35.8246,
    lng: -84.0552,
    id: 1840015379,
  },
  {
    name: "Chariton",
    state_id: "IA",
    lat: 41.0176,
    lng: -93.3095,
    id: 1840000890,
  },
  {
    name: "Taft",
    state_id: "TX",
    lat: 27.98,
    lng: -97.3929,
    id: 1840022252,
  },
  {
    name: "Rice Lake",
    state_id: "MN",
    lat: 46.8977,
    lng: -92.1137,
    id: 1840032021,
  },
  {
    name: "Herculaneum",
    state_id: "MO",
    lat: 38.2579,
    lng: -90.3949,
    id: 1840007507,
  },
  {
    name: "Charlevoix",
    state_id: "MI",
    lat: 45.3138,
    lng: -85.2557,
    id: 1840003944,
  },
  {
    name: "Dewey-Humboldt",
    state_id: "AZ",
    lat: 34.5175,
    lng: -112.2498,
    id: 1840021771,
  },
  {
    name: "Northwest Harwich",
    state_id: "MA",
    lat: 41.6917,
    lng: -70.1027,
    id: 1840073524,
  },
  {
    name: "Peotone",
    state_id: "IL",
    lat: 41.3312,
    lng: -87.7937,
    id: 1840011494,
  },
  {
    name: "Riverdale",
    state_id: "NJ",
    lat: 40.9921,
    lng: -74.3125,
    id: 1840000950,
  },
  {
    name: "South Greeley",
    state_id: "WY",
    lat: 41.0928,
    lng: -104.8066,
    id: 1840038443,
  },
  {
    name: "Etowah",
    state_id: "TN",
    lat: 35.3381,
    lng: -84.5282,
    id: 1840013430,
  },
  {
    name: "Riva",
    state_id: "MD",
    lat: 38.9449,
    lng: -76.5876,
    id: 1840005915,
  },
  {
    name: "Garden Ridge",
    state_id: "TX",
    lat: 29.6379,
    lng: -98.2927,
    id: 1840020940,
  },
  {
    name: "Columbus",
    state_id: "MN",
    lat: 45.2685,
    lng: -93.081,
    id: 1840006713,
  },
  {
    name: "Swannanoa",
    state_id: "NC",
    lat: 35.6008,
    lng: -82.3917,
    id: 1840013410,
  },
  {
    name: "Dawson",
    state_id: "GA",
    lat: 31.7721,
    lng: -84.4437,
    id: 1840014938,
  },
  {
    name: "Rockville",
    state_id: "IN",
    lat: 39.7665,
    lng: -87.2295,
    id: 1840014205,
  },
  {
    name: "Maeser",
    state_id: "UT",
    lat: 40.4719,
    lng: -109.5786,
    id: 1840017501,
  },
  {
    name: "Peaceful Valley",
    state_id: "WA",
    lat: 48.9477,
    lng: -122.1406,
    id: 1840037896,
  },
  {
    name: "Herricks",
    state_id: "NY",
    lat: 40.7567,
    lng: -73.6635,
    id: 1840005264,
  },
  {
    name: "Lenwood",
    state_id: "CA",
    lat: 34.8861,
    lng: -117.1078,
    id: 1840017804,
  },
  {
    name: "New Carlisle",
    state_id: "IN",
    lat: 41.7018,
    lng: -86.4916,
    id: 1840009243,
  },
  {
    name: "Pocola",
    state_id: "OK",
    lat: 35.2456,
    lng: -94.4774,
    id: 1840021795,
  },
  {
    name: "Benton Heights",
    state_id: "MI",
    lat: 42.1211,
    lng: -86.4137,
    id: 1840004700,
  },
  {
    name: "Stanton",
    state_id: "KY",
    lat: 37.8465,
    lng: -83.8563,
    id: 1840015222,
  },
  {
    name: "Christopher",
    state_id: "IL",
    lat: 37.9708,
    lng: -89.0531,
    id: 1840007542,
  },
  {
    name: "Austin",
    state_id: "IN",
    lat: 38.7418,
    lng: -85.8112,
    id: 1840010757,
  },
  {
    name: "Westport",
    state_id: "NC",
    lat: 35.5103,
    lng: -80.9783,
    id: 1840013435,
  },
  {
    name: "Falls City",
    state_id: "NE",
    lat: 40.0623,
    lng: -95.599,
    id: 1840008380,
  },
  {
    name: "New Tazewell",
    state_id: "TN",
    lat: 36.4381,
    lng: -83.6053,
    id: 1840016022,
  },
  {
    name: "Grant",
    state_id: "MN",
    lat: 45.0825,
    lng: -92.909,
    id: 1840007809,
  },
  {
    name: "Walthourville",
    state_id: "GA",
    lat: 31.7716,
    lng: -81.6216,
    id: 1840015840,
  },
  {
    name: "Waverly",
    state_id: "NY",
    lat: 42.0119,
    lng: -76.5406,
    id: 1840004670,
  },
  {
    name: "Randleman",
    state_id: "NC",
    lat: 35.8165,
    lng: -79.8066,
    id: 1840014512,
  },
  {
    name: "Cassville",
    state_id: "MO",
    lat: 36.6786,
    lng: -93.8682,
    id: 1840007654,
  },
  {
    name: "Woodstown",
    state_id: "NJ",
    lat: 39.6503,
    lng: -75.3251,
    id: 1840001531,
  },
  {
    name: "Burns",
    state_id: "OR",
    lat: 43.5883,
    lng: -119.0613,
    id: 1840018632,
  },
  {
    name: "Heritage Hills",
    state_id: "NY",
    lat: 41.3398,
    lng: -73.7016,
    id: 1840034035,
  },
  {
    name: "St. Marys",
    state_id: "WV",
    lat: 39.4023,
    lng: -81.195,
    id: 1840005763,
  },
  {
    name: "Caseyville",
    state_id: "IL",
    lat: 38.6302,
    lng: -90.0339,
    id: 1840010782,
  },
  {
    name: "Goodview",
    state_id: "MN",
    lat: 44.0693,
    lng: -91.7167,
    id: 1840007920,
  },
  {
    name: "Forest Oaks",
    state_id: "NC",
    lat: 35.9889,
    lng: -79.7079,
    id: 1840033016,
  },
  {
    name: "Sinking Spring",
    state_id: "PA",
    lat: 40.3241,
    lng: -76.0236,
    id: 1840001190,
  },
  {
    name: "Wadena",
    state_id: "MN",
    lat: 46.4452,
    lng: -95.1281,
    id: 1840009971,
  },
  {
    name: "Del Monte Forest",
    state_id: "CA",
    lat: 36.5857,
    lng: -121.9439,
    id: 1840019016,
  },
  {
    name: "Fleetwood",
    state_id: "PA",
    lat: 40.4565,
    lng: -75.8209,
    id: 1840001175,
  },
  {
    name: "Sparks",
    state_id: "TX",
    lat: 31.6727,
    lng: -106.2399,
    id: 1840037097,
  },
  {
    name: "Moxee",
    state_id: "WA",
    lat: 46.564,
    lng: -120.3966,
    id: 1840037381,
  },
  {
    name: "Perry",
    state_id: "MI",
    lat: 42.8195,
    lng: -84.232,
    id: 1840003069,
  },
  {
    name: "Jefferson",
    state_id: "IA",
    lat: 42.0173,
    lng: -94.3798,
    id: 1840000492,
  },
  {
    name: "Monongahela",
    state_id: "PA",
    lat: 40.1956,
    lng: -79.9221,
    id: 1840003661,
  },
  {
    name: "Pearl River",
    state_id: "MS",
    lat: 32.7889,
    lng: -89.2384,
    id: 1840013800,
  },
  {
    name: "Dimmitt",
    state_id: "TX",
    lat: 34.5324,
    lng: -102.3111,
    id: 1840019249,
  },
  {
    name: "Magee",
    state_id: "MS",
    lat: 31.8731,
    lng: -89.734,
    id: 1840015845,
  },
  {
    name: "West Milwaukee",
    state_id: "WI",
    lat: 43.0124,
    lng: -87.971,
    id: 1840003035,
  },
  {
    name: "Holloman AFB",
    state_id: "NM",
    lat: 32.8483,
    lng: -106.0998,
    id: 1840037076,
  },
  {
    name: "Presidio",
    state_id: "TX",
    lat: 29.5603,
    lng: -104.3653,
    id: 1840020883,
  },
  {
    name: "Bonifay",
    state_id: "FL",
    lat: 30.7826,
    lng: -85.6845,
    id: 1840013924,
  },
  {
    name: "Scottdale",
    state_id: "PA",
    lat: 40.1034,
    lng: -79.5899,
    id: 1840001157,
  },
  {
    name: "Wood Village",
    state_id: "OR",
    lat: 45.5358,
    lng: -122.4205,
    id: 1840021210,
  },
  {
    name: "Lathrup Village",
    state_id: "MI",
    lat: 42.4921,
    lng: -83.2273,
    id: 1840002448,
  },
  {
    name: "North Plymouth",
    state_id: "MA",
    lat: 41.9728,
    lng: -70.6882,
    id: 1840004679,
  },
  {
    name: "Burnham",
    state_id: "IL",
    lat: 41.6376,
    lng: -87.5447,
    id: 1840010144,
  },
  {
    name: "Rutherfordton",
    state_id: "NC",
    lat: 35.3642,
    lng: -81.9613,
    id: 1840017834,
  },
  {
    name: "Marietta-Alderwood",
    state_id: "WA",
    lat: 48.7932,
    lng: -122.5566,
    id: 1840073928,
  },
  {
    name: "North Richmond",
    state_id: "CA",
    lat: 37.9642,
    lng: -122.3711,
    id: 1840024709,
  },
  {
    name: "Lorane",
    state_id: "PA",
    lat: 40.2921,
    lng: -75.8486,
    id: 1840035070,
  },
  {
    name: "Pemberwick",
    state_id: "CT",
    lat: 41.0224,
    lng: -73.6564,
    id: 1840026285,
  },
  {
    name: "Charlotte Harbor",
    state_id: "FL",
    lat: 26.9629,
    lng: -82.0571,
    id: 1840014198,
  },
  {
    name: "Camuy",
    state_id: "PR",
    lat: 18.4825,
    lng: -66.8501,
    id: 1630035632,
  },
  {
    name: "Austin",
    state_id: "AR",
    lat: 35.0061,
    lng: -91.9895,
    id: 1840013528,
  },
  {
    name: "Whispering Pines",
    state_id: "NC",
    lat: 35.2536,
    lng: -79.3755,
    id: 1840017853,
  },
  {
    name: "Newcomerstown",
    state_id: "OH",
    lat: 40.2762,
    lng: -81.5949,
    id: 1840012093,
  },
  {
    name: "Waimanalo Beach",
    state_id: "HI",
    lat: 21.3288,
    lng: -157.6975,
    id: 1840029559,
  },
  {
    name: "Northwoods",
    state_id: "MO",
    lat: 38.7035,
    lng: -90.2824,
    id: 1840008593,
  },
  {
    name: "Waverly",
    state_id: "NE",
    lat: 40.9111,
    lng: -96.534,
    id: 1840010333,
  },
  {
    name: "Beavercreek",
    state_id: "OR",
    lat: 45.2756,
    lng: -122.5136,
    id: 1840034713,
  },
  {
    name: "Sharpsville",
    state_id: "PA",
    lat: 41.2595,
    lng: -80.4813,
    id: 1840003442,
  },
  {
    name: "Punta Santiago",
    state_id: "PR",
    lat: 18.1619,
    lng: -65.7584,
    id: 1630035556,
  },
  {
    name: "Brewerton",
    state_id: "NY",
    lat: 43.2344,
    lng: -76.1411,
    id: 1840004312,
  },
  {
    name: "Ripley",
    state_id: "WV",
    lat: 38.8201,
    lng: -81.7091,
    id: 1840006008,
  },
  {
    name: "Mosinee",
    state_id: "WI",
    lat: 44.7874,
    lng: -89.6839,
    id: 1840002171,
  },
  {
    name: "Boyertown",
    state_id: "PA",
    lat: 40.3323,
    lng: -75.6375,
    id: 1840001173,
  },
  {
    name: "Running Springs",
    state_id: "CA",
    lat: 34.2104,
    lng: -117.1147,
    id: 1840019112,
  },
  {
    name: "Narrows",
    state_id: "VA",
    lat: 37.3315,
    lng: -80.8083,
    id: 1840006432,
  },
  {
    name: "Aguilita",
    state_id: "PR",
    lat: 18.0284,
    lng: -66.5331,
    id: 1630023581,
  },
  {
    name: "Alexandria",
    state_id: "AL",
    lat: 33.7675,
    lng: -85.8792,
    id: 1840013707,
  },
  {
    name: "New Lebanon",
    state_id: "OH",
    lat: 39.744,
    lng: -84.3943,
    id: 1840012491,
  },
  {
    name: "University",
    state_id: "MS",
    lat: 34.3656,
    lng: -89.5379,
    id: 1840032487,
  },
  {
    name: "Rosemont",
    state_id: "IL",
    lat: 41.989,
    lng: -87.8717,
    id: 1840011289,
  },
  {
    name: "Rising Sun-Lebanon",
    state_id: "DE",
    lat: 39.0999,
    lng: -75.5047,
    id: 1840073664,
  },
  {
    name: "Ho-Ho-Kus",
    state_id: "NJ",
    lat: 41.0001,
    lng: -74.0972,
    id: 1840003561,
  },
  {
    name: "West Bradenton",
    state_id: "FL",
    lat: 27.5016,
    lng: -82.6146,
    id: 1840029094,
  },
  {
    name: "Rosewood Heights",
    state_id: "IL",
    lat: 38.8885,
    lng: -90.0722,
    id: 1840006059,
  },
  {
    name: "La Paloma",
    state_id: "TX",
    lat: 26.0518,
    lng: -97.6552,
    id: 1840018325,
  },
  {
    name: "Desoto Lakes",
    state_id: "FL",
    lat: 27.373,
    lng: -82.4968,
    id: 1840029033,
  },
  {
    name: "Stilwell",
    state_id: "OK",
    lat: 35.8151,
    lng: -94.6312,
    id: 1840021702,
  },
  {
    name: "Marine City",
    state_id: "MI",
    lat: 42.7138,
    lng: -82.5012,
    id: 1840003956,
  },
  {
    name: "Walton",
    state_id: "KY",
    lat: 38.8646,
    lng: -84.6122,
    id: 1840015167,
  },
  {
    name: "Crescent Springs",
    state_id: "KY",
    lat: 39.0553,
    lng: -84.5788,
    id: 1840014264,
  },
  {
    name: "Le Sueur",
    state_id: "MN",
    lat: 44.4704,
    lng: -93.9025,
    id: 1840007887,
  },
  {
    name: "Rio Dell",
    state_id: "CA",
    lat: 40.5022,
    lng: -124.1103,
    id: 1840020127,
  },
  {
    name: "Ridgway",
    state_id: "PA",
    lat: 41.427,
    lng: -78.7297,
    id: 1840000617,
  },
  {
    name: "Kimberly",
    state_id: "ID",
    lat: 42.5345,
    lng: -114.3695,
    id: 1840020084,
  },
  {
    name: "Spackenkill",
    state_id: "NY",
    lat: 41.6549,
    lng: -73.9102,
    id: 1840034063,
  },
  {
    name: "Shrewsbury",
    state_id: "NJ",
    lat: 40.3249,
    lng: -74.06,
    id: 1840001363,
  },
  {
    name: "Egg Harbor City",
    state_id: "NJ",
    lat: 39.564,
    lng: -74.5961,
    id: 1840016638,
  },
  {
    name: "Empire",
    state_id: "CA",
    lat: 37.6432,
    lng: -120.9045,
    id: 1840017615,
  },
  {
    name: "Cannon Falls",
    state_id: "MN",
    lat: 44.5122,
    lng: -92.9034,
    id: 1840006778,
  },
  {
    name: "Livingston",
    state_id: "TN",
    lat: 36.3875,
    lng: -85.3265,
    id: 1840016059,
  },
  {
    name: "Hampton",
    state_id: "SC",
    lat: 32.8676,
    lng: -81.1092,
    id: 1840016927,
  },
  {
    name: "Lake Belvedere Estates",
    state_id: "FL",
    lat: 26.6888,
    lng: -80.1371,
    id: 1840028988,
  },
  {
    name: "Pipestone",
    state_id: "MN",
    lat: 43.9958,
    lng: -96.3117,
    id: 1840008980,
  },
  {
    name: "Marienville",
    state_id: "PA",
    lat: 41.4687,
    lng: -79.1181,
    id: 1840024142,
  },
  {
    name: "County Center",
    state_id: "VA",
    lat: 38.6917,
    lng: -77.3507,
    id: 1840043275,
  },
  {
    name: "Spring House",
    state_id: "PA",
    lat: 40.1847,
    lng: -75.2267,
    id: 1840005467,
  },
  {
    name: "Fayetteville",
    state_id: "NY",
    lat: 43.0308,
    lng: -75.9985,
    id: 1840004320,
  },
  {
    name: "Forty Fort",
    state_id: "PA",
    lat: 41.2843,
    lng: -75.8689,
    id: 1840000750,
  },
  {
    name: "Jamestown",
    state_id: "OH",
    lat: 39.6599,
    lng: -83.7424,
    id: 1840012526,
  },
  {
    name: "Loch Lomond",
    state_id: "VA",
    lat: 38.7812,
    lng: -77.4817,
    id: 1840006101,
  },
  {
    name: "Sunset Beach",
    state_id: "NC",
    lat: 33.892,
    lng: -78.5104,
    id: 1840016685,
  },
  {
    name: "Woodson Terrace",
    state_id: "MO",
    lat: 38.7286,
    lng: -90.36,
    id: 1840010748,
  },
  {
    name: "Algonac",
    state_id: "MI",
    lat: 42.6208,
    lng: -82.534,
    id: 1840003955,
  },
  {
    name: "Goulding",
    state_id: "FL",
    lat: 30.4397,
    lng: -87.2299,
    id: 1840013911,
  },
  {
    name: "St. James",
    state_id: "MO",
    lat: 38.0013,
    lng: -91.6153,
    id: 1840009850,
  },
  {
    name: "Redwood",
    state_id: "TX",
    lat: 29.81,
    lng: -97.9103,
    id: 1840019638,
  },
  {
    name: "Red Springs",
    state_id: "NC",
    lat: 34.8046,
    lng: -79.1822,
    id: 1840017907,
  },
  {
    name: "Vandalia",
    state_id: "MO",
    lat: 39.308,
    lng: -91.4892,
    id: 1840010671,
  },
  {
    name: "Trevose",
    state_id: "PA",
    lat: 40.1509,
    lng: -74.982,
    id: 1840024296,
  },
  {
    name: "Belzoni",
    state_id: "MS",
    lat: 33.1804,
    lng: -90.4863,
    id: 1840013777,
  },
  {
    name: "Hatillo",
    state_id: "PR",
    lat: 18.4811,
    lng: -66.8221,
    id: 1630035679,
  },
  {
    name: "Lake Land'Or",
    state_id: "VA",
    lat: 38.017,
    lng: -77.5593,
    id: 1840043221,
  },
  {
    name: "Weaverville",
    state_id: "NC",
    lat: 35.6963,
    lng: -82.5584,
    id: 1840016284,
  },
  {
    name: "Fenton",
    state_id: "MO",
    lat: 38.5279,
    lng: -90.4489,
    id: 1840008583,
  },
  {
    name: "Crooked River Ranch",
    state_id: "OR",
    lat: 44.4242,
    lng: -121.2757,
    id: 1840141645,
  },
  {
    name: "Forest City",
    state_id: "IA",
    lat: 43.257,
    lng: -93.6365,
    id: 1840007953,
  },
  {
    name: "Horseshoe Bay",
    state_id: "TX",
    lat: 30.5403,
    lng: -98.3804,
    id: 1840020869,
  },
  {
    name: "Gentry",
    state_id: "AR",
    lat: 36.257,
    lng: -94.4907,
    id: 1840013317,
  },
  {
    name: "San Joaquin",
    state_id: "CA",
    lat: 36.6057,
    lng: -120.1897,
    id: 1840021565,
  },
  {
    name: "Trent Woods",
    state_id: "NC",
    lat: 35.081,
    lng: -77.0945,
    id: 1840016401,
  },
  {
    name: "San Carlos",
    state_id: "TX",
    lat: 26.2957,
    lng: -98.063,
    id: 1840019730,
  },
  {
    name: "Lisbon Falls",
    state_id: "ME",
    lat: 44.0086,
    lng: -70.057,
    id: 1840002189,
  },
  {
    name: "Crandall",
    state_id: "TX",
    lat: 32.6279,
    lng: -96.4535,
    id: 1840019453,
  },
  {
    name: "Butte",
    state_id: "AK",
    lat: 61.5539,
    lng: -149.0035,
    id: 1840023368,
  },
  {
    name: "Akron",
    state_id: "PA",
    lat: 40.158,
    lng: -76.2031,
    id: 1840003711,
  },
  {
    name: "Bellaire",
    state_id: "OH",
    lat: 40.0176,
    lng: -80.7469,
    id: 1840012381,
  },
  {
    name: "Southgate",
    state_id: "KY",
    lat: 39.063,
    lng: -84.4715,
    id: 1840015170,
  },
  {
    name: "Danielson",
    state_id: "CT",
    lat: 41.8086,
    lng: -71.8854,
    id: 1840004781,
  },
  {
    name: "Louisa",
    state_id: "KY",
    lat: 38.1079,
    lng: -82.6132,
    id: 1840015209,
  },
  {
    name: "Kildeer",
    state_id: "IL",
    lat: 42.183,
    lng: -88.0488,
    id: 1840011156,
  },
  {
    name: "Rocky Ford",
    state_id: "CO",
    lat: 38.05,
    lng: -103.7226,
    id: 1840020269,
  },
  {
    name: "Rayville",
    state_id: "LA",
    lat: 32.4707,
    lng: -91.7576,
    id: 1840018088,
  },
  {
    name: "Taylor Creek",
    state_id: "FL",
    lat: 27.2172,
    lng: -80.7927,
    id: 1840029086,
  },
  {
    name: "Turners Falls",
    state_id: "MA",
    lat: 42.5976,
    lng: -72.5572,
    id: 1840004519,
  },
  {
    name: "Rossville",
    state_id: "GA",
    lat: 34.9747,
    lng: -85.2898,
    id: 1840014655,
  },
  {
    name: "Saylorville",
    state_id: "IA",
    lat: 41.6809,
    lng: -93.6279,
    id: 1840004800,
  },
  {
    name: "University Center",
    state_id: "VA",
    lat: 39.0601,
    lng: -77.4445,
    id: 1840024511,
  },
  {
    name: "India Hook",
    state_id: "SC",
    lat: 35.0142,
    lng: -81.0377,
    id: 1840013517,
  },
  {
    name: "Ashburn",
    state_id: "GA",
    lat: 31.7095,
    lng: -83.6528,
    id: 1840013854,
  },
  {
    name: "South Chicago Heights",
    state_id: "IL",
    lat: 41.4831,
    lng: -87.637,
    id: 1840011323,
  },
  {
    name: "Centerville",
    state_id: "MN",
    lat: 45.164,
    lng: -93.054,
    id: 1840006715,
  },
  {
    name: "Park Hill",
    state_id: "OK",
    lat: 35.8546,
    lng: -94.9559,
    id: 1840019078,
  },
  {
    name: "Lakewood",
    state_id: "IL",
    lat: 42.2278,
    lng: -88.393,
    id: 1840011131,
  },
  {
    name: "Butler",
    state_id: "MO",
    lat: 38.2598,
    lng: -94.3393,
    id: 1840007510,
  },
  {
    name: "Bloomfield Hills",
    state_id: "MI",
    lat: 42.578,
    lng: -83.2456,
    id: 1840002439,
  },
  {
    name: "Gamewell",
    state_id: "NC",
    lat: 35.8637,
    lng: -81.5979,
    id: 1840016188,
  },
  {
    name: "Mabank",
    state_id: "TX",
    lat: 32.3681,
    lng: -96.114,
    id: 1840023034,
  },
  {
    name: "Parkesburg",
    state_id: "PA",
    lat: 39.9593,
    lng: -75.9177,
    id: 1840001432,
  },
  {
    name: "Lawnton",
    state_id: "PA",
    lat: 40.2638,
    lng: -76.7978,
    id: 1840035053,
  },
  {
    name: "Jena",
    state_id: "LA",
    lat: 31.6914,
    lng: -92.1298,
    id: 1840017057,
  },
  {
    name: "Shannon Hills",
    state_id: "AR",
    lat: 34.6157,
    lng: -92.4217,
    id: 1840015541,
  },
  {
    name: "Southside",
    state_id: "AR",
    lat: 35.7149,
    lng: -91.6329,
    id: 1840027852,
  },
  {
    name: "Richwood",
    state_id: "TX",
    lat: 29.0723,
    lng: -95.4045,
    id: 1840020976,
  },
  {
    name: "Morrison",
    state_id: "IL",
    lat: 41.8076,
    lng: -89.9616,
    id: 1840008149,
  },
  {
    name: "Greencastle",
    state_id: "PA",
    lat: 39.7907,
    lng: -77.7267,
    id: 1840001411,
  },
  {
    name: "Marshall",
    state_id: "WI",
    lat: 43.1726,
    lng: -89.0614,
    id: 1840002917,
  },
  {
    name: "Wrightwood",
    state_id: "CA",
    lat: 34.3495,
    lng: -117.6299,
    id: 1840019116,
  },
  {
    name: "Clay Center",
    state_id: "KS",
    lat: 39.3812,
    lng: -97.1278,
    id: 1840001554,
  },
  {
    name: "Louisburg",
    state_id: "NC",
    lat: 36.0979,
    lng: -78.301,
    id: 1840016116,
  },
  {
    name: "Fulton",
    state_id: "MS",
    lat: 34.2616,
    lng: -88.4021,
    id: 1840013619,
  },
  {
    name: "Mills",
    state_id: "WY",
    lat: 42.8473,
    lng: -106.383,
    id: 1840022359,
  },
  {
    name: "Pocono Woodland Lakes",
    state_id: "PA",
    lat: 41.3225,
    lng: -74.8902,
    id: 1840043344,
  },
  {
    name: "Shavano Park",
    state_id: "TX",
    lat: 29.5861,
    lng: -98.5563,
    id: 1840022222,
  },
  {
    name: "Madison",
    state_id: "WV",
    lat: 38.0631,
    lng: -81.8046,
    id: 1840006305,
  },
  {
    name: "High Point",
    state_id: "FL",
    lat: 28.5474,
    lng: -82.5206,
    id: 1840029105,
  },
  {
    name: "Lyncourt",
    state_id: "NY",
    lat: 43.0821,
    lng: -76.1264,
    id: 1840034054,
  },
  {
    name: "Longbranch",
    state_id: "WA",
    lat: 47.2257,
    lng: -122.7739,
    id: 1840023835,
  },
  {
    name: "Harrisburg",
    state_id: "OR",
    lat: 44.2718,
    lng: -123.1656,
    id: 1840019990,
  },
  {
    name: "Nassau Bay",
    state_id: "TX",
    lat: 29.5448,
    lng: -95.0865,
    id: 1840020929,
  },
  {
    name: "Tiptonville",
    state_id: "TN",
    lat: 36.387,
    lng: -89.4677,
    id: 1840016072,
  },
  {
    name: "West DeLand",
    state_id: "FL",
    lat: 29.0162,
    lng: -81.3343,
    id: 1840029095,
  },
  {
    name: "Meridian",
    state_id: "PA",
    lat: 40.8531,
    lng: -79.9555,
    id: 1840005181,
  },
  {
    name: "Manchester",
    state_id: "GA",
    lat: 32.8566,
    lng: -84.6329,
    id: 1840015749,
  },
  {
    name: "Rose Hill",
    state_id: "KS",
    lat: 37.5702,
    lng: -97.1361,
    id: 1840009856,
  },
  {
    name: "Ashland",
    state_id: "MO",
    lat: 38.7929,
    lng: -92.2477,
    id: 1840007393,
  },
  {
    name: "Spencer",
    state_id: "OK",
    lat: 35.5108,
    lng: -97.3715,
    id: 1840021741,
  },
  {
    name: "Bardonia",
    state_id: "NY",
    lat: 41.1129,
    lng: -73.9823,
    id: 1840004958,
  },
  {
    name: "Southport",
    state_id: "NC",
    lat: 33.9412,
    lng: -78.0205,
    id: 1840015582,
  },
  {
    name: "Blackshear",
    state_id: "GA",
    lat: 31.2954,
    lng: -82.2453,
    id: 1840013870,
  },
  {
    name: "Burney",
    state_id: "CA",
    lat: 40.8846,
    lng: -121.6691,
    id: 1840017491,
  },
  {
    name: "Le Claire",
    state_id: "IA",
    lat: 41.5963,
    lng: -90.3687,
    id: 1840008186,
  },
  {
    name: "Whitmore Village",
    state_id: "HI",
    lat: 21.512,
    lng: -158.0273,
    id: 1840029564,
  },
  {
    name: "Winona",
    state_id: "MS",
    lat: 33.4899,
    lng: -89.728,
    id: 1840015702,
  },
  {
    name: "Washington",
    state_id: "GA",
    lat: 33.7355,
    lng: -82.7427,
    id: 1840015641,
  },
  {
    name: "Lolo",
    state_id: "MT",
    lat: 46.7756,
    lng: -114.1018,
    id: 1840017349,
  },
  {
    name: "Fairway",
    state_id: "KS",
    lat: 39.0245,
    lng: -94.6287,
    id: 1840001635,
  },
  {
    name: "Sykesville",
    state_id: "MD",
    lat: 39.3714,
    lng: -76.9716,
    id: 1840005705,
  },
  {
    name: "Fort Gibson",
    state_id: "OK",
    lat: 35.7766,
    lng: -95.2599,
    id: 1840021721,
  },
  {
    name: "Buckingham",
    state_id: "FL",
    lat: 26.6615,
    lng: -81.7399,
    id: 1840014216,
  },
  {
    name: "Barceloneta",
    state_id: "PR",
    lat: 18.4529,
    lng: -66.5381,
    id: 1630035618,
  },
  {
    name: "Angels",
    state_id: "CA",
    lat: 38.0707,
    lng: -120.5501,
    id: 1840074780,
  },
  {
    name: "Brazoria",
    state_id: "TX",
    lat: 29.0455,
    lng: -95.5673,
    id: 1840019673,
  },
  {
    name: "Crewe",
    state_id: "VA",
    lat: 37.1812,
    lng: -78.1307,
    id: 1840006462,
  },
  {
    name: "Oaklyn",
    state_id: "NJ",
    lat: 39.9023,
    lng: -75.0813,
    id: 1840001515,
  },
  {
    name: "Tyro",
    state_id: "NC",
    lat: 35.7997,
    lng: -80.3765,
    id: 1840024912,
  },
  {
    name: "Roselawn",
    state_id: "IN",
    lat: 41.1535,
    lng: -87.2881,
    id: 1840005175,
  },
  {
    name: "Holt",
    state_id: "AL",
    lat: 33.2302,
    lng: -87.4827,
    id: 1840013753,
  },
  {
    name: "Kalama",
    state_id: "WA",
    lat: 46.0164,
    lng: -122.8402,
    id: 1840018502,
  },
  {
    name: "Mount Hermon",
    state_id: "VA",
    lat: 36.6716,
    lng: -79.4196,
    id: 1840026888,
  },
  {
    name: "Lawrenceville",
    state_id: "NJ",
    lat: 40.3018,
    lng: -74.7378,
    id: 1840005473,
  },
  {
    name: "Elroy",
    state_id: "NC",
    lat: 35.3308,
    lng: -77.9226,
    id: 1840013071,
  },
  {
    name: "Elverson",
    state_id: "PA",
    lat: 40.1559,
    lng: -75.8304,
    id: 1840001426,
  },
  {
    name: "Mahanoy City",
    state_id: "PA",
    lat: 40.8126,
    lng: -76.1382,
    id: 1840002763,
  },
  {
    name: "Hephzibah",
    state_id: "GA",
    lat: 33.2906,
    lng: -82.0994,
    id: 1840014829,
  },
  {
    name: "Metter",
    state_id: "GA",
    lat: 32.3958,
    lng: -82.0621,
    id: 1840014896,
  },
  {
    name: "West Nyack",
    state_id: "NY",
    lat: 41.0907,
    lng: -73.9714,
    id: 1840004973,
  },
  {
    name: "Carroll Valley",
    state_id: "PA",
    lat: 39.7499,
    lng: -77.3805,
    id: 1840000680,
  },
  {
    name: "Wellsville",
    state_id: "UT",
    lat: 41.6223,
    lng: -111.943,
    id: 1840021322,
  },
  {
    name: "Chagrin Falls",
    state_id: "OH",
    lat: 41.4321,
    lng: -81.3887,
    id: 1840000595,
  },
  {
    name: "Linden",
    state_id: "MI",
    lat: 42.8194,
    lng: -83.7811,
    id: 1840002952,
  },
  {
    name: "Bunk Foss",
    state_id: "WA",
    lat: 47.9617,
    lng: -122.0944,
    id: 1840041912,
  },
  {
    name: "Nichols Hills",
    state_id: "OK",
    lat: 35.5468,
    lng: -97.5444,
    id: 1840020426,
  },
  {
    name: "Bunker Hill Village",
    state_id: "TX",
    lat: 29.7647,
    lng: -95.5317,
    id: 1840019618,
  },
  {
    name: "Ball",
    state_id: "LA",
    lat: 31.4188,
    lng: -92.4084,
    id: 1840015868,
  },
  {
    name: "North El Monte",
    state_id: "CA",
    lat: 34.103,
    lng: -118.0238,
    id: 1840028391,
  },
  {
    name: "Village Shires",
    state_id: "PA",
    lat: 40.2016,
    lng: -74.9706,
    id: 1840035442,
  },
  {
    name: "Clearlake Riviera",
    state_id: "CA",
    lat: 38.9512,
    lng: -122.7207,
    id: 1840075664,
  },
  {
    name: "Mount Angel",
    state_id: "OR",
    lat: 45.0694,
    lng: -122.797,
    id: 1840019962,
  },
  {
    name: "Skyline View",
    state_id: "PA",
    lat: 40.3376,
    lng: -76.7256,
    id: 1840035197,
  },
  {
    name: "Bayard",
    state_id: "NM",
    lat: 32.7579,
    lng: -108.1338,
    id: 1840019408,
  },
  {
    name: "Deephaven",
    state_id: "MN",
    lat: 44.9318,
    lng: -93.5293,
    id: 1840006751,
  },
  {
    name: "Jefferson",
    state_id: "NC",
    lat: 36.4209,
    lng: -81.4686,
    id: 1840016027,
  },
  {
    name: "Maytown",
    state_id: "PA",
    lat: 40.0791,
    lng: -76.5791,
    id: 1840005493,
  },
  {
    name: "Carefree",
    state_id: "AZ",
    lat: 33.8234,
    lng: -111.9161,
    id: 1840022914,
  },
  {
    name: "Richwood",
    state_id: "NJ",
    lat: 39.714,
    lng: -75.1734,
    id: 1840033399,
  },
  {
    name: "Lares",
    state_id: "PR",
    lat: 18.2951,
    lng: -66.8821,
    id: 1630035500,
  },
  {
    name: "Montpelier",
    state_id: "OH",
    lat: 41.5817,
    lng: -84.5968,
    id: 1840011522,
  },
  {
    name: "Benson",
    state_id: "NC",
    lat: 35.386,
    lng: -78.5436,
    id: 1840015393,
  },
  {
    name: "Brookmont",
    state_id: "MD",
    lat: 38.9546,
    lng: -77.1292,
    id: 1840031313,
  },
  {
    name: "Dana",
    state_id: "NC",
    lat: 35.3239,
    lng: -82.3722,
    id: 1840024953,
  },
  {
    name: "Fox Island",
    state_id: "WA",
    lat: 47.2475,
    lng: -122.6255,
    id: 1840037870,
  },
  {
    name: "Highland Beach",
    state_id: "FL",
    lat: 26.4088,
    lng: -80.0661,
    id: 1840015995,
  },
  {
    name: "Pageland",
    state_id: "SC",
    lat: 34.772,
    lng: -80.3897,
    id: 1840016584,
  },
  {
    name: "Medford Lakes",
    state_id: "NJ",
    lat: 39.8582,
    lng: -74.8054,
    id: 1840003752,
  },
  {
    name: "Boardman",
    state_id: "OR",
    lat: 45.8372,
    lng: -119.6957,
    id: 1840018540,
  },
  {
    name: "Roland",
    state_id: "OK",
    lat: 35.4147,
    lng: -94.5137,
    id: 1840021750,
  },
  {
    name: "Genesee",
    state_id: "CO",
    lat: 39.6873,
    lng: -105.2719,
    id: 1840028577,
  },
  {
    name: "Roebling",
    state_id: "NJ",
    lat: 40.1167,
    lng: -74.7776,
    id: 1840024353,
  },
  {
    name: "Laureldale",
    state_id: "PA",
    lat: 40.3897,
    lng: -75.9135,
    id: 1840001179,
  },
  {
    name: "Lake Park",
    state_id: "NC",
    lat: 35.0848,
    lng: -80.6348,
    id: 1840017879,
  },
  {
    name: "New Hope",
    state_id: "MS",
    lat: 33.4521,
    lng: -88.3399,
    id: 1840013745,
  },
  {
    name: "Hardin",
    state_id: "MT",
    lat: 45.7422,
    lng: -107.6083,
    id: 1840019912,
  },
  {
    name: "Mount Gilead",
    state_id: "OH",
    lat: 40.5532,
    lng: -82.8279,
    id: 1840012024,
  },
  {
    name: "Hazel Green",
    state_id: "AL",
    lat: 34.9237,
    lng: -86.5671,
    id: 1840013545,
  },
  {
    name: "Charleroi",
    state_id: "PA",
    lat: 40.1384,
    lng: -79.9002,
    id: 1840003645,
  },
  {
    name: "Ravenswood",
    state_id: "WV",
    lat: 38.956,
    lng: -81.7619,
    id: 1840006007,
  },
  {
    name: "Andrews",
    state_id: "SC",
    lat: 33.4494,
    lng: -79.566,
    id: 1840015685,
  },
  {
    name: "Fairview",
    state_id: "NC",
    lat: 35.1545,
    lng: -80.5345,
    id: 1840016456,
  },
  {
    name: "Green Oaks",
    state_id: "IL",
    lat: 42.2954,
    lng: -87.9116,
    id: 1840011151,
  },
  {
    name: "Grand Bay",
    state_id: "AL",
    lat: 30.4717,
    lng: -88.345,
    id: 1840013894,
  },
  {
    name: "Belleville",
    state_id: "MI",
    lat: 42.2021,
    lng: -83.4838,
    id: 1840003968,
  },
  {
    name: "Throop",
    state_id: "PA",
    lat: 41.4382,
    lng: -75.5926,
    id: 1840003391,
  },
  {
    name: "Odenville",
    state_id: "AL",
    lat: 33.6987,
    lng: -86.4225,
    id: 1840016774,
  },
  {
    name: "Apalachicola",
    state_id: "FL",
    lat: 29.7282,
    lng: -84.994,
    id: 1840014015,
  },
  {
    name: "Elkton",
    state_id: "VA",
    lat: 38.4106,
    lng: -78.616,
    id: 1840006133,
  },
  {
    name: "Iroquois Point",
    state_id: "HI",
    lat: 21.3232,
    lng: -157.9775,
    id: 1840029590,
  },
  {
    name: "Vineyards",
    state_id: "FL",
    lat: 26.2279,
    lng: -81.728,
    id: 1840029014,
  },
  {
    name: "Bosque Farms",
    state_id: "NM",
    lat: 34.8538,
    lng: -106.7011,
    id: 1840022813,
  },
  {
    name: "Vine Hill",
    state_id: "CA",
    lat: 38.0071,
    lng: -122.0873,
    id: 1840018902,
  },
  {
    name: "Metzger",
    state_id: "OR",
    lat: 45.4492,
    lng: -122.7623,
    id: 1840018553,
  },
  {
    name: "Wolf Lake",
    state_id: "MI",
    lat: 43.2451,
    lng: -86.1073,
    id: 1840006865,
  },
  {
    name: "North Great River",
    state_id: "NY",
    lat: 40.7599,
    lng: -73.1678,
    id: 1840005057,
  },
  {
    name: "Level Green",
    state_id: "PA",
    lat: 40.3902,
    lng: -79.7215,
    id: 1840026433,
  },
  {
    name: "Monticello",
    state_id: "IA",
    lat: 42.2298,
    lng: -91.1861,
    id: 1840008107,
  },
  {
    name: "Baden",
    state_id: "PA",
    lat: 40.6396,
    lng: -80.2228,
    id: 1840001011,
  },
  {
    name: "Clyde",
    state_id: "TX",
    lat: 32.4056,
    lng: -99.5039,
    id: 1840019479,
  },
  {
    name: "Dupo",
    state_id: "IL",
    lat: 38.5146,
    lng: -90.2168,
    id: 1840012867,
  },
  {
    name: "Waikapu",
    state_id: "HI",
    lat: 20.8391,
    lng: -156.5221,
    id: 1840029556,
  },
  {
    name: "North River Shores",
    state_id: "FL",
    lat: 27.2223,
    lng: -80.2738,
    id: 1840014191,
  },
  {
    name: "Wheatland",
    state_id: "CA",
    lat: 39.0287,
    lng: -121.39,
    id: 1840021446,
  },
  {
    name: "Niwot",
    state_id: "CO",
    lat: 40.098,
    lng: -105.1552,
    id: 1840018773,
  },
  {
    name: "Nome",
    state_id: "AK",
    lat: 64.5213,
    lng: -165.4053,
    id: 1840023510,
  },
  {
    name: "Rochester",
    state_id: "WI",
    lat: 42.7338,
    lng: -88.2489,
    id: 1840002494,
  },
  {
    name: "Washington Park",
    state_id: "IL",
    lat: 38.6285,
    lng: -90.0928,
    id: 1840012881,
  },
  {
    name: "Menands",
    state_id: "NY",
    lat: 42.691,
    lng: -73.7271,
    id: 1840004491,
  },
  {
    name: "Port Reading",
    state_id: "NJ",
    lat: 40.5669,
    lng: -74.2475,
    id: 1840005417,
  },
  {
    name: "Grantley",
    state_id: "PA",
    lat: 39.9406,
    lng: -76.7287,
    id: 1840035424,
  },
  {
    name: "Rogersville",
    state_id: "MO",
    lat: 37.1147,
    lng: -93.0742,
    id: 1840009897,
  },
  {
    name: "Bridgeville",
    state_id: "DE",
    lat: 38.7251,
    lng: -75.6029,
    id: 1840006075,
  },
  {
    name: "Citronelle",
    state_id: "AL",
    lat: 31.0952,
    lng: -88.2468,
    id: 1840014987,
  },
  {
    name: "Umatilla",
    state_id: "FL",
    lat: 28.9273,
    lng: -81.6651,
    id: 1840015954,
  },
  {
    name: "Canton",
    state_id: "TX",
    lat: 32.5543,
    lng: -95.864,
    id: 1840019454,
  },
  {
    name: "Gatlinburg",
    state_id: "TN",
    lat: 35.725,
    lng: -83.4938,
    id: 1840013387,
  },
  {
    name: "St. James",
    state_id: "MD",
    lat: 39.5738,
    lng: -77.7482,
    id: 1840005611,
  },
  {
    name: "Chilton",
    state_id: "WI",
    lat: 44.0293,
    lng: -88.1625,
    id: 1840002521,
  },
  {
    name: "Leo-Cedarville",
    state_id: "IN",
    lat: 41.2199,
    lng: -85.0192,
    id: 1840009322,
  },
  {
    name: "Swanton",
    state_id: "OH",
    lat: 41.584,
    lng: -83.8881,
    id: 1840011515,
  },
  {
    name: "Bunkie",
    state_id: "LA",
    lat: 30.954,
    lng: -92.1883,
    id: 1840013880,
  },
  {
    name: "Millstadt",
    state_id: "IL",
    lat: 38.458,
    lng: -90.0833,
    id: 1840012875,
  },
  {
    name: "Dallastown",
    state_id: "PA",
    lat: 39.8996,
    lng: -76.641,
    id: 1840001439,
  },
  {
    name: "Shrewsbury",
    state_id: "PA",
    lat: 39.7714,
    lng: -76.6799,
    id: 1840001463,
  },
  {
    name: "Valdez",
    state_id: "AK",
    lat: 61.0963,
    lng: -146.2596,
    id: 1840023396,
  },
  {
    name: "Pennsburg",
    state_id: "PA",
    lat: 40.3936,
    lng: -75.4965,
    id: 1840001372,
  },
  {
    name: "Mojave",
    state_id: "CA",
    lat: 35.0139,
    lng: -118.1895,
    id: 1840019134,
  },
  {
    name: "Rock Valley",
    state_id: "IA",
    lat: 43.2028,
    lng: -96.2902,
    id: 1840009045,
  },
  {
    name: "Wurtsboro",
    state_id: "NY",
    lat: 41.5761,
    lng: -74.4856,
    id: 1840004793,
  },
  {
    name: "Mount Sterling",
    state_id: "IL",
    lat: 39.9854,
    lng: -90.7641,
    id: 1840008395,
  },
  {
    name: "Mountain Green",
    state_id: "UT",
    lat: 41.1475,
    lng: -111.7901,
    id: 1840024172,
  },
  {
    name: "Yellow Springs",
    state_id: "OH",
    lat: 39.7986,
    lng: -83.8905,
    id: 1840012527,
  },
  {
    name: "Cle Elum",
    state_id: "WA",
    lat: 47.1943,
    lng: -120.9539,
    id: 1840018440,
  },
  {
    name: "Denver",
    state_id: "PA",
    lat: 40.2325,
    lng: -76.1393,
    id: 1840003714,
  },
  {
    name: "Bethel",
    state_id: "WA",
    lat: 47.4874,
    lng: -122.6175,
    id: 1840023792,
  },
  {
    name: "Larch Way",
    state_id: "WA",
    lat: 47.8429,
    lng: -122.2528,
    id: 1840041976,
  },
  {
    name: "Puhi",
    state_id: "HI",
    lat: 21.9622,
    lng: -159.3918,
    id: 1840029544,
  },
  {
    name: "Eastland",
    state_id: "TX",
    lat: 32.4024,
    lng: -98.8176,
    id: 1840020764,
  },
  {
    name: "Chipley",
    state_id: "FL",
    lat: 30.7752,
    lng: -85.5406,
    id: 1840015015,
  },
  {
    name: "Shingle Springs",
    state_id: "CA",
    lat: 38.6665,
    lng: -120.937,
    id: 1840018830,
  },
  {
    name: "Smithton",
    state_id: "IL",
    lat: 38.4159,
    lng: -89.9903,
    id: 1840012878,
  },
  {
    name: "Glenwood Landing",
    state_id: "NY",
    lat: 40.8295,
    lng: -73.6378,
    id: 1840005260,
  },
  {
    name: "Mission Hills",
    state_id: "CA",
    lat: 34.6861,
    lng: -120.4404,
    id: 1840028388,
  },
  {
    name: "French Camp",
    state_id: "CA",
    lat: 37.8802,
    lng: -121.2803,
    id: 1840017606,
  },
  {
    name: "South Point",
    state_id: "OH",
    lat: 38.4214,
    lng: -82.5784,
    id: 1840012839,
  },
  {
    name: "Palmer Heights",
    state_id: "PA",
    lat: 40.6907,
    lng: -75.2671,
    id: 1840005205,
  },
  {
    name: "Thomasville",
    state_id: "AL",
    lat: 31.9111,
    lng: -87.7419,
    id: 1840015849,
  },
  {
    name: "Huntingdon",
    state_id: "TN",
    lat: 36.0052,
    lng: -88.4181,
    id: 1840016180,
  },
  {
    name: "St. Augusta",
    state_id: "MN",
    lat: 45.4497,
    lng: -94.1996,
    id: 1840008880,
  },
  {
    name: "Abbotsford",
    state_id: "WI",
    lat: 44.9432,
    lng: -90.3169,
    id: 1840002168,
  },
  {
    name: "Level Park-Oak Park",
    state_id: "MI",
    lat: 42.3642,
    lng: -85.2665,
    id: 1840073764,
  },
  {
    name: "Meadow Lake",
    state_id: "NM",
    lat: 34.8024,
    lng: -106.5701,
    id: 1840019192,
  },
  {
    name: "Granger",
    state_id: "WA",
    lat: 46.3455,
    lng: -120.1926,
    id: 1840019872,
  },
  {
    name: "Leesburg",
    state_id: "GA",
    lat: 31.7325,
    lng: -84.1687,
    id: 1840014940,
  },
  {
    name: "Highland Falls",
    state_id: "NY",
    lat: 41.3643,
    lng: -73.9683,
    id: 1840004871,
  },
  {
    name: "Nash",
    state_id: "TX",
    lat: 33.4422,
    lng: -94.1283,
    id: 1840020612,
  },
  {
    name: "Saxon",
    state_id: "SC",
    lat: 34.9616,
    lng: -81.9713,
    id: 1840013510,
  },
  {
    name: "LaGrange",
    state_id: "OH",
    lat: 41.2401,
    lng: -82.1181,
    id: 1840034279,
  },
  {
    name: "River Road",
    state_id: "NC",
    lat: 35.5107,
    lng: -76.9898,
    id: 1840033022,
  },
  {
    name: "Honea Path",
    state_id: "SC",
    lat: 34.4476,
    lng: -82.395,
    id: 1840016572,
  },
  {
    name: "Mogadore",
    state_id: "OH",
    lat: 41.0523,
    lng: -81.3993,
    id: 1840000790,
  },
  {
    name: "Pleasant Hills",
    state_id: "MD",
    lat: 39.4861,
    lng: -76.3908,
    id: 1840031472,
  },
  {
    name: "Houtzdale",
    state_id: "PA",
    lat: 40.8251,
    lng: -78.351,
    id: 1840003496,
  },
  {
    name: "Lincoln",
    state_id: "ID",
    lat: 43.5178,
    lng: -111.9685,
    id: 1840024022,
  },
  {
    name: "Ossian",
    state_id: "IN",
    lat: 40.877,
    lng: -85.1682,
    id: 1840009376,
  },
  {
    name: "Windber",
    state_id: "PA",
    lat: 40.2355,
    lng: -78.8247,
    id: 1840003751,
  },
  {
    name: "Leland",
    state_id: "MS",
    lat: 33.4051,
    lng: -90.891,
    id: 1840014831,
  },
  {
    name: "Greentown",
    state_id: "OH",
    lat: 40.9274,
    lng: -81.402,
    id: 1840005200,
  },
  {
    name: "Vidalia",
    state_id: "LA",
    lat: 31.5668,
    lng: -91.4405,
    id: 1840017076,
  },
  {
    name: "Panther Valley",
    state_id: "NJ",
    lat: 40.9078,
    lng: -74.8412,
    id: 1840039942,
  },
  {
    name: "Hohenwald",
    state_id: "TN",
    lat: 35.5515,
    lng: -87.5539,
    id: 1840014546,
  },
  {
    name: "North Hudson",
    state_id: "WI",
    lat: 44.9976,
    lng: -92.7559,
    id: 1840002139,
  },
  {
    name: "Carmel-by-the-Sea",
    state_id: "CA",
    lat: 36.5528,
    lng: -121.9222,
    id: 1840019023,
  },
  {
    name: "Marshall",
    state_id: "IL",
    lat: 39.3984,
    lng: -87.6899,
    id: 1840008493,
  },
  {
    name: "Elba",
    state_id: "AL",
    lat: 31.4172,
    lng: -86.0759,
    id: 1840001532,
  },
  {
    name: "Arbuckle",
    state_id: "CA",
    lat: 39.0141,
    lng: -122.061,
    id: 1840017555,
  },
  {
    name: "Winneconne",
    state_id: "WI",
    lat: 44.1112,
    lng: -88.7114,
    id: 1840002242,
  },
  {
    name: "Friona",
    state_id: "TX",
    lat: 34.6394,
    lng: -102.7232,
    id: 1840020525,
  },
  {
    name: "Barrett",
    state_id: "TX",
    lat: 29.8671,
    lng: -95.0537,
    id: 1840018252,
  },
  {
    name: "Key Center",
    state_id: "WA",
    lat: 47.3376,
    lng: -122.7505,
    id: 1840023833,
  },
  {
    name: "Midland",
    state_id: "NC",
    lat: 35.245,
    lng: -80.5212,
    id: 1840016362,
  },
  {
    name: "Warminster Heights",
    state_id: "PA",
    lat: 40.1884,
    lng: -75.0841,
    id: 1840035296,
  },
  {
    name: "Elmira Heights",
    state_id: "NY",
    lat: 42.1267,
    lng: -76.8255,
    id: 1840004692,
  },
  {
    name: "Oakhurst",
    state_id: "NJ",
    lat: 40.2607,
    lng: -74.0263,
    id: 1840005445,
  },
  {
    name: "Welcome",
    state_id: "NC",
    lat: 35.9066,
    lng: -80.2548,
    id: 1840013388,
  },
  {
    name: "Independence",
    state_id: "MN",
    lat: 45.0219,
    lng: -93.7077,
    id: 1840007834,
  },
  {
    name: "Longview Heights",
    state_id: "WA",
    lat: 46.1797,
    lng: -122.9578,
    id: 1840037874,
  },
  {
    name: "Lansford",
    state_id: "PA",
    lat: 40.8331,
    lng: -75.8848,
    id: 1840000932,
  },
  {
    name: "Indian Head",
    state_id: "MD",
    lat: 38.5987,
    lng: -77.1556,
    id: 1840006176,
  },
  {
    name: "Fowlerville",
    state_id: "MI",
    lat: 42.6596,
    lng: -84.0743,
    id: 1840011078,
  },
  {
    name: "Lambertville",
    state_id: "NJ",
    lat: 40.3687,
    lng: -74.943,
    id: 1840001039,
  },
  {
    name: "Brooktrails",
    state_id: "CA",
    lat: 39.4429,
    lng: -123.3963,
    id: 1840028317,
  },
  {
    name: "Avenue B and C",
    state_id: "AZ",
    lat: 32.7192,
    lng: -114.6599,
    id: 1840075233,
  },
  {
    name: "Lillington",
    state_id: "NC",
    lat: 35.4001,
    lng: -78.8139,
    id: 1840016326,
  },
  {
    name: "Roosevelt Park",
    state_id: "MI",
    lat: 43.1981,
    lng: -86.2733,
    id: 1840002874,
  },
  {
    name: "North Muskegon",
    state_id: "MI",
    lat: 43.2518,
    lng: -86.2714,
    id: 1840002872,
  },
  {
    name: "Forestdale",
    state_id: "MA",
    lat: 41.6846,
    lng: -70.5082,
    id: 1840003241,
  },
  {
    name: "Grayling",
    state_id: "MI",
    lat: 44.6566,
    lng: -84.7091,
    id: 1840002291,
  },
  {
    name: "Carter Lake",
    state_id: "IA",
    lat: 41.2884,
    lng: -95.9171,
    id: 1840007120,
  },
  {
    name: "Booneville",
    state_id: "AR",
    lat: 35.1393,
    lng: -93.9179,
    id: 1840013466,
  },
  {
    name: "Valley Green",
    state_id: "PA",
    lat: 40.1567,
    lng: -76.7947,
    id: 1840035440,
  },
  {
    name: "Cedar Springs",
    state_id: "MI",
    lat: 43.2202,
    lng: -85.5535,
    id: 1840002926,
  },
  {
    name: "Cisco",
    state_id: "TX",
    lat: 32.3848,
    lng: -98.9805,
    id: 1840019477,
  },
  {
    name: "Mirrormont",
    state_id: "WA",
    lat: 47.4616,
    lng: -121.9936,
    id: 1840037876,
  },
  {
    name: "Grifton",
    state_id: "NC",
    lat: 35.3804,
    lng: -77.4333,
    id: 1840016277,
  },
  {
    name: "Gibbstown",
    state_id: "NJ",
    lat: 39.8233,
    lng: -75.2783,
    id: 1840005564,
  },
  {
    name: "Eagle Lake",
    state_id: "TX",
    lat: 29.5879,
    lng: -96.3282,
    id: 1840019630,
  },
  {
    name: "Plainwell",
    state_id: "MI",
    lat: 42.4457,
    lng: -85.6435,
    id: 1840003112,
  },
  {
    name: "Freedom",
    state_id: "CA",
    lat: 36.9402,
    lng: -121.7953,
    id: 1840017652,
  },
  {
    name: "Hugoton",
    state_id: "KS",
    lat: 37.1745,
    lng: -101.3447,
    id: 1840007618,
  },
  {
    name: "Lake Goodwin",
    state_id: "WA",
    lat: 48.1387,
    lng: -122.2804,
    id: 1840037872,
  },
  {
    name: "Colonial Beach",
    state_id: "VA",
    lat: 38.2565,
    lng: -76.9784,
    id: 1840006282,
  },
  {
    name: "Norwood Young America",
    state_id: "MN",
    lat: 44.7721,
    lng: -93.9192,
    id: 1840007849,
  },
  {
    name: "Waldport",
    state_id: "OR",
    lat: 44.4173,
    lng: -124.0664,
    id: 1840021235,
  },
  {
    name: "Toledo",
    state_id: "OR",
    lat: 44.6198,
    lng: -123.9341,
    id: 1840021234,
  },
  {
    name: "Country Lake Estates",
    state_id: "NJ",
    lat: 39.9488,
    lng: -74.5411,
    id: 1840005518,
  },
  {
    name: "West Liberty",
    state_id: "IA",
    lat: 41.5727,
    lng: -91.2622,
    id: 1840010236,
  },
  {
    name: "Graceville",
    state_id: "FL",
    lat: 30.9609,
    lng: -85.5123,
    id: 1840013906,
  },
  {
    name: "Bayport",
    state_id: "MN",
    lat: 45.0152,
    lng: -92.7789,
    id: 1840006737,
  },
  {
    name: "Quartzsite",
    state_id: "AZ",
    lat: 33.6675,
    lng: -114.217,
    id: 1840021913,
  },
  {
    name: "Port St. Joe",
    state_id: "FL",
    lat: 29.8267,
    lng: -85.3069,
    id: 1840015048,
  },
  {
    name: "Stanley",
    state_id: "NC",
    lat: 35.3552,
    lng: -81.0937,
    id: 1840016398,
  },
  {
    name: "Centre",
    state_id: "AL",
    lat: 34.1551,
    lng: -85.6699,
    id: 1840001099,
  },
  {
    name: "Merton",
    state_id: "WI",
    lat: 43.1423,
    lng: -88.3088,
    id: 1840003021,
  },
  {
    name: "Eastover",
    state_id: "NC",
    lat: 35.0958,
    lng: -78.7862,
    id: 1840022794,
  },
  {
    name: "Fulton",
    state_id: "KY",
    lat: 36.5145,
    lng: -88.8819,
    id: 1840013296,
  },
  {
    name: "Imlay City",
    state_id: "MI",
    lat: 43.017,
    lng: -83.0765,
    id: 1840002895,
  },
  {
    name: "Forestville",
    state_id: "CA",
    lat: 38.4825,
    lng: -122.8899,
    id: 1840017575,
  },
  {
    name: "Melody Hill",
    state_id: "IN",
    lat: 38.024,
    lng: -87.512,
    id: 1840006310,
  },
  {
    name: "North Wildwood",
    state_id: "NJ",
    lat: 39.0047,
    lng: -74.799,
    id: 1840001597,
  },
  {
    name: "Celada",
    state_id: "PR",
    lat: 18.2666,
    lng: -65.9621,
    id: 1630023584,
  },
  {
    name: "Bystrom",
    state_id: "CA",
    lat: 37.6199,
    lng: -120.9827,
    id: 1840028337,
  },
  {
    name: "St. Charles",
    state_id: "MN",
    lat: 43.9688,
    lng: -92.0591,
    id: 1840008991,
  },
  {
    name: "Madison",
    state_id: "IL",
    lat: 38.7001,
    lng: -90.1425,
    id: 1840009716,
  },
  {
    name: "Haleiwa",
    state_id: "HI",
    lat: 21.5871,
    lng: -158.1074,
    id: 1840029474,
  },
  {
    name: "San Castle",
    state_id: "FL",
    lat: 26.5651,
    lng: -80.0611,
    id: 1840042878,
  },
  {
    name: "Brownsville",
    state_id: "LA",
    lat: 32.4883,
    lng: -92.1619,
    id: 1840025900,
  },
  {
    name: "Calcutta",
    state_id: "OH",
    lat: 40.6847,
    lng: -80.5624,
    id: 1840005224,
  },
  {
    name: "Manti",
    state_id: "UT",
    lat: 39.2669,
    lng: -111.6351,
    id: 1840020206,
  },
  {
    name: "Harbour Heights",
    state_id: "FL",
    lat: 26.9928,
    lng: -82.0072,
    id: 1840014197,
  },
  {
    name: "Scott City",
    state_id: "KS",
    lat: 38.479,
    lng: -100.9032,
    id: 1840009647,
  },
  {
    name: "Franklinton",
    state_id: "LA",
    lat: 30.8479,
    lng: -90.1459,
    id: 1840017147,
  },
  {
    name: "Mamou",
    state_id: "LA",
    lat: 30.6349,
    lng: -92.4179,
    id: 1840017148,
  },
  {
    name: "Irwin",
    state_id: "PA",
    lat: 40.3249,
    lng: -79.6997,
    id: 1840001140,
  },
  {
    name: "South Russell",
    state_id: "OH",
    lat: 41.4318,
    lng: -81.3351,
    id: 1840011517,
  },
  {
    name: "Whitfield",
    state_id: "FL",
    lat: 27.4115,
    lng: -82.5659,
    id: 1840134894,
  },
  {
    name: "Agua Dulce",
    state_id: "CA",
    lat: 34.5044,
    lng: -118.316,
    id: 1840022825,
  },
  {
    name: "Johnson",
    state_id: "AR",
    lat: 36.1328,
    lng: -94.1757,
    id: 1840014482,
  },
  {
    name: "Hesston",
    state_id: "KS",
    lat: 38.1403,
    lng: -97.4272,
    id: 1840007534,
  },
  {
    name: "Sumiton",
    state_id: "AL",
    lat: 33.7503,
    lng: -87.0483,
    id: 1840015640,
  },
  {
    name: "White Hall",
    state_id: "IL",
    lat: 39.4388,
    lng: -90.4019,
    id: 1840010651,
  },
  {
    name: "Cresco",
    state_id: "IA",
    lat: 43.3718,
    lng: -92.1164,
    id: 1840006859,
  },
  {
    name: "Cross City",
    state_id: "FL",
    lat: 29.6386,
    lng: -83.1248,
    id: 1840017280,
  },
  {
    name: "Farmerville",
    state_id: "LA",
    lat: 32.7752,
    lng: -92.4013,
    id: 1840016939,
  },
  {
    name: "West Point",
    state_id: "GA",
    lat: 32.8937,
    lng: -85.1453,
    id: 1840015752,
  },
  {
    name: "Cozad",
    state_id: "NE",
    lat: 40.8613,
    lng: -99.9863,
    id: 1840007167,
  },
  {
    name: "Madison",
    state_id: "FL",
    lat: 30.4716,
    lng: -83.413,
    id: 1840015917,
  },
  {
    name: "North Webster",
    state_id: "IN",
    lat: 41.324,
    lng: -85.6979,
    id: 1840009308,
  },
  {
    name: "Ocilla",
    state_id: "GA",
    lat: 31.5987,
    lng: -83.2499,
    id: 1840014950,
  },
  {
    name: "La Cienega",
    state_id: "NM",
    lat: 35.5801,
    lng: -106.1127,
    id: 1840017774,
  },
  {
    name: "Turnersville",
    state_id: "NJ",
    lat: 39.7666,
    lng: -75.0614,
    id: 1840005565,
  },
  {
    name: "Village Green",
    state_id: "NY",
    lat: 43.1324,
    lng: -76.3108,
    id: 1840034064,
  },
  {
    name: "Boyne City",
    state_id: "MI",
    lat: 45.2135,
    lng: -85.0131,
    id: 1840003943,
  },
  {
    name: "Jackson",
    state_id: "LA",
    lat: 30.8346,
    lng: -91.208,
    id: 1840017159,
  },
  {
    name: "Bagdad",
    state_id: "FL",
    lat: 30.5824,
    lng: -87.0447,
    id: 1840013918,
  },
  {
    name: "Bullard",
    state_id: "TX",
    lat: 32.1451,
    lng: -95.3195,
    id: 1840022074,
  },
  {
    name: "Itta Bena",
    state_id: "MS",
    lat: 33.5003,
    lng: -90.3256,
    id: 1840014803,
  },
  {
    name: "North Alamo",
    state_id: "TX",
    lat: 26.216,
    lng: -98.1264,
    id: 1840019728,
  },
  {
    name: "Upland",
    state_id: "IN",
    lat: 40.4639,
    lng: -85.5008,
    id: 1840010398,
  },
  {
    name: "Trappe",
    state_id: "PA",
    lat: 40.1991,
    lng: -75.4753,
    id: 1840001379,
  },
  {
    name: "Lavon",
    state_id: "TX",
    lat: 33.0245,
    lng: -96.4397,
    id: 1840020664,
  },
  {
    name: "Coal Valley",
    state_id: "IL",
    lat: 41.4416,
    lng: -90.4463,
    id: 1840011439,
  },
  {
    name: "Pulaski",
    state_id: "WI",
    lat: 44.6684,
    lng: -88.2351,
    id: 1840002349,
  },
  {
    name: "Indian Head Park",
    state_id: "IL",
    lat: 41.769,
    lng: -87.8977,
    id: 1840011265,
  },
  {
    name: "Lyman",
    state_id: "SC",
    lat: 34.9661,
    lng: -82.1231,
    id: 1840016461,
  },
  {
    name: "Comfort",
    state_id: "TX",
    lat: 29.9714,
    lng: -98.904,
    id: 1840018257,
  },
  {
    name: "Boulevard Park",
    state_id: "WA",
    lat: 47.5136,
    lng: -122.316,
    id: 1840037519,
  },
  {
    name: "Sweeny",
    state_id: "TX",
    lat: 29.0464,
    lng: -95.6986,
    id: 1840022236,
  },
  {
    name: "Lowell",
    state_id: "NC",
    lat: 35.2689,
    lng: -81.1013,
    id: 1840015454,
  },
  {
    name: "Maiden",
    state_id: "NC",
    lat: 35.5853,
    lng: -81.2201,
    id: 1840016281,
  },
  {
    name: "St. Anthony",
    state_id: "ID",
    lat: 43.9649,
    lng: -111.6841,
    id: 1840021255,
  },
  {
    name: "Metamora",
    state_id: "IL",
    lat: 40.7959,
    lng: -89.3689,
    id: 1840011945,
  },
  {
    name: "Stanley",
    state_id: "WI",
    lat: 44.9597,
    lng: -90.9423,
    id: 1840002105,
  },
  {
    name: "St. Pauls",
    state_id: "NC",
    lat: 34.8085,
    lng: -78.9747,
    id: 1840017910,
  },
  {
    name: "Hudson",
    state_id: "NC",
    lat: 35.8474,
    lng: -81.4864,
    id: 1840016187,
  },
  {
    name: "Rochester",
    state_id: "IL",
    lat: 39.7493,
    lng: -89.5455,
    id: 1840012449,
  },
  {
    name: "Bryn Mawr",
    state_id: "PA",
    lat: 40.0227,
    lng: -75.3156,
    id: 1840005456,
  },
  {
    name: "Belvidere",
    state_id: "NJ",
    lat: 40.8294,
    lng: -75.0728,
    id: 1840000943,
  },
  {
    name: "Flagler Estates",
    state_id: "FL",
    lat: 29.6442,
    lng: -81.4571,
    id: 1840038863,
  },
  {
    name: "Fordyce",
    state_id: "AR",
    lat: 33.8181,
    lng: -92.4174,
    id: 1840013679,
  },
  {
    name: "Sunnyslope",
    state_id: "WA",
    lat: 47.4913,
    lng: -120.3492,
    id: 1840136960,
  },
  {
    name: "Livonia",
    state_id: "LA",
    lat: 30.5623,
    lng: -91.5502,
    id: 1840017145,
  },
  {
    name: "Lancaster",
    state_id: "WI",
    lat: 42.8473,
    lng: -90.7065,
    id: 1840002969,
  },
  {
    name: "Mountain View",
    state_id: "NC",
    lat: 35.6828,
    lng: -81.3683,
    id: 1840033019,
  },
  {
    name: "Ellijay",
    state_id: "GA",
    lat: 34.6913,
    lng: -84.484,
    id: 1840013575,
  },
  {
    name: "Merchantville",
    state_id: "NJ",
    lat: 39.9502,
    lng: -75.0504,
    id: 1840003770,
  },
  {
    name: "Battle Mountain",
    state_id: "NV",
    lat: 40.6379,
    lng: -116.9485,
    id: 1840017497,
  },
  {
    name: "Plymouth",
    state_id: "NC",
    lat: 35.8588,
    lng: -76.7487,
    id: 1840016242,
  },
  {
    name: "Emerald Isle",
    state_id: "NC",
    lat: 34.6649,
    lng: -77.0291,
    id: 1840016484,
  },
  {
    name: "SUNY Oswego",
    state_id: "NY",
    lat: 43.4515,
    lng: -76.5439,
    id: 1840043282,
  },
  {
    name: "Candelaria Arenas",
    state_id: "PR",
    lat: 18.4175,
    lng: -66.2203,
    id: 1630035633,
  },
  {
    name: "San Manuel",
    state_id: "AZ",
    lat: 32.6091,
    lng: -110.6415,
    id: 1840019362,
  },
  {
    name: "Clarcona",
    state_id: "FL",
    lat: 28.6201,
    lng: -81.5002,
    id: 1840025145,
  },
  {
    name: "Mount Union",
    state_id: "PA",
    lat: 40.3845,
    lng: -77.8824,
    id: 1840001072,
  },
  {
    name: "Leisure Village East",
    state_id: "NJ",
    lat: 40.0384,
    lng: -74.1678,
    id: 1840033212,
  },
  {
    name: "Wolf Point",
    state_id: "MT",
    lat: 48.0933,
    lng: -105.6413,
    id: 1840021071,
  },
  {
    name: "Montauk",
    state_id: "NY",
    lat: 41.0471,
    lng: -71.9449,
    id: 1840005047,
  },
  {
    name: "South Toms River",
    state_id: "NJ",
    lat: 39.9417,
    lng: -74.2087,
    id: 1840001490,
  },
  {
    name: "Woodbourne",
    state_id: "PA",
    lat: 40.2016,
    lng: -74.8872,
    id: 1840035367,
  },
  {
    name: "Emmetsburg",
    state_id: "IA",
    lat: 43.1146,
    lng: -94.6799,
    id: 1840007990,
  },
  {
    name: "McGehee",
    state_id: "AR",
    lat: 33.628,
    lng: -91.3953,
    id: 1840015630,
  },
  {
    name: "Lake of the Woods",
    state_id: "AZ",
    lat: 34.1547,
    lng: -109.9936,
    id: 1840022579,
  },
  {
    name: "Maybrook",
    state_id: "NY",
    lat: 41.4881,
    lng: -74.2131,
    id: 1840004873,
  },
  {
    name: "Beach Haven West",
    state_id: "NJ",
    lat: 39.6702,
    lng: -74.2338,
    id: 1840005526,
  },
  {
    name: "Pine Ridge",
    state_id: "SD",
    lat: 43.027,
    lng: -102.5525,
    id: 1840004201,
  },
  {
    name: "Albia",
    state_id: "IA",
    lat: 41.0272,
    lng: -92.8039,
    id: 1840007152,
  },
  {
    name: "Avalon",
    state_id: "CA",
    lat: 33.3342,
    lng: -118.3315,
    id: 1840019225,
  },
  {
    name: "Meadow Vista",
    state_id: "CA",
    lat: 39.003,
    lng: -121.0304,
    id: 1840018815,
  },
  {
    name: "Potosi",
    state_id: "TX",
    lat: 32.3404,
    lng: -99.6789,
    id: 1840019475,
  },
  {
    name: "Aurora",
    state_id: "IN",
    lat: 39.0677,
    lng: -84.9046,
    id: 1840013709,
  },
  {
    name: "Lely",
    state_id: "FL",
    lat: 26.1032,
    lng: -81.7297,
    id: 1840029052,
  },
  {
    name: "Sagamore",
    state_id: "MA",
    lat: 41.7842,
    lng: -70.5332,
    id: 1840004763,
  },
  {
    name: "Brookland",
    state_id: "AR",
    lat: 35.8991,
    lng: -90.5767,
    id: 1840015370,
  },
  {
    name: "Arlington",
    state_id: "NY",
    lat: 41.6958,
    lng: -73.8988,
    id: 1840004739,
  },
  {
    name: "Feather Sound",
    state_id: "FL",
    lat: 27.9061,
    lng: -82.6814,
    id: 1840029034,
  },
  {
    name: "Hebron",
    state_id: "IN",
    lat: 41.3246,
    lng: -87.2017,
    id: 1840010230,
  },
  {
    name: "Wintersville",
    state_id: "OH",
    lat: 40.3791,
    lng: -80.7095,
    id: 1840012127,
  },
  {
    name: "Milan",
    state_id: "NM",
    lat: 35.195,
    lng: -107.8947,
    id: 1840022781,
  },
  {
    name: "Ranlo",
    state_id: "NC",
    lat: 35.2889,
    lng: -81.1292,
    id: 1840017864,
  },
  {
    name: "Muttontown",
    state_id: "NY",
    lat: 40.8254,
    lng: -73.5363,
    id: 1840005312,
  },
  {
    name: "Moca",
    state_id: "PR",
    lat: 18.3971,
    lng: -67.1155,
    id: 1630035522,
  },
  {
    name: "Auburn Lake Trails",
    state_id: "CA",
    lat: 38.8917,
    lng: -120.9849,
    id: 1840028321,
  },
  {
    name: "Northfield",
    state_id: "OH",
    lat: 41.3427,
    lng: -81.5286,
    id: 1840000799,
  },
  {
    name: "Royal Palm Estates",
    state_id: "FL",
    lat: 26.6815,
    lng: -80.1265,
    id: 1840029001,
  },
  {
    name: "Millvale",
    state_id: "PA",
    lat: 40.4818,
    lng: -79.9736,
    id: 1840001245,
  },
  {
    name: "Mullica Hill",
    state_id: "NJ",
    lat: 39.7266,
    lng: -75.2191,
    id: 1840016703,
  },
  {
    name: "Lloyd Harbor",
    state_id: "NY",
    lat: 40.9139,
    lng: -73.4618,
    id: 1840005123,
  },
  {
    name: "Fritch",
    state_id: "TX",
    lat: 35.6431,
    lng: -101.5964,
    id: 1840020394,
  },
  {
    name: "Spencerport",
    state_id: "NY",
    lat: 43.1882,
    lng: -77.8069,
    id: 1840004285,
  },
  {
    name: "Melrose",
    state_id: "MN",
    lat: 45.6757,
    lng: -94.8128,
    id: 1840007782,
  },
  {
    name: "Oracle",
    state_id: "AZ",
    lat: 32.6085,
    lng: -110.7826,
    id: 1840019359,
  },
  {
    name: "Troy",
    state_id: "NC",
    lat: 35.3648,
    lng: -79.8919,
    id: 1840016361,
  },
  {
    name: "Thorndale",
    state_id: "PA",
    lat: 39.9991,
    lng: -75.7517,
    id: 1840005504,
  },
  {
    name: "Boonsboro",
    state_id: "MD",
    lat: 39.5079,
    lng: -77.6586,
    id: 1840005614,
  },
  {
    name: "Juno Beach",
    state_id: "FL",
    lat: 26.8755,
    lng: -80.0589,
    id: 1840017254,
  },
  {
    name: "Mora",
    state_id: "MN",
    lat: 45.8768,
    lng: -93.2914,
    id: 1840007765,
  },
  {
    name: "White Bluff",
    state_id: "TN",
    lat: 36.1002,
    lng: -87.2113,
    id: 1840016106,
  },
  {
    name: "Quitman",
    state_id: "GA",
    lat: 30.7849,
    lng: -83.5603,
    id: 1840014995,
  },
  {
    name: "Port Vue",
    state_id: "PA",
    lat: 40.3371,
    lng: -79.872,
    id: 1840001256,
  },
  {
    name: "Wills Point",
    state_id: "TX",
    lat: 32.7093,
    lng: -96.0052,
    id: 1840022069,
  },
  {
    name: "Wadsworth",
    state_id: "IL",
    lat: 42.4441,
    lng: -87.9198,
    id: 1840011183,
  },
  {
    name: "Fairbury",
    state_id: "IL",
    lat: 40.746,
    lng: -88.5161,
    id: 1840008280,
  },
  {
    name: "Squaw Valley",
    state_id: "CA",
    lat: 36.6944,
    lng: -119.2,
    id: 1840018940,
  },
  {
    name: "Perham",
    state_id: "MN",
    lat: 46.6,
    lng: -95.5773,
    id: 1840008852,
  },
  {
    name: "Old River-Winfree",
    state_id: "TX",
    lat: 29.8745,
    lng: -94.8268,
    id: 1840020943,
  },
  {
    name: "Horicon",
    state_id: "WI",
    lat: 43.4453,
    lng: -88.6403,
    id: 1840002731,
  },
  {
    name: "Richfield",
    state_id: "OH",
    lat: 41.2353,
    lng: -81.6421,
    id: 1840011671,
  },
  {
    name: "Northlake",
    state_id: "SC",
    lat: 34.5691,
    lng: -82.6837,
    id: 1840035847,
  },
  {
    name: "North East",
    state_id: "MD",
    lat: 39.6077,
    lng: -75.9414,
    id: 1840005639,
  },
  {
    name: "Shenandoah Farms",
    state_id: "VA",
    lat: 38.9793,
    lng: -78.0469,
    id: 1840026699,
  },
  {
    name: "Ballinger",
    state_id: "TX",
    lat: 31.7395,
    lng: -99.9559,
    id: 1840019517,
  },
  {
    name: "Maple Heights-Lake Desire",
    state_id: "WA",
    lat: 47.4441,
    lng: -122.0973,
    id: 1840073925,
  },
  {
    name: "Shady Cove",
    state_id: "OR",
    lat: 42.6115,
    lng: -122.8178,
    id: 1840021299,
  },
  {
    name: "Meridian",
    state_id: "CO",
    lat: 39.5389,
    lng: -104.8476,
    id: 1840028565,
  },
  {
    name: "Society Hill",
    state_id: "NJ",
    lat: 40.5344,
    lng: -74.4577,
    id: 1840033486,
  },
  {
    name: "Wildwood Lake",
    state_id: "TN",
    lat: 35.0893,
    lng: -84.8513,
    id: 1840036154,
  },
  {
    name: "Heavener",
    state_id: "OK",
    lat: 34.8919,
    lng: -94.6075,
    id: 1840020444,
  },
  {
    name: "Ponderosa Park",
    state_id: "CO",
    lat: 39.3986,
    lng: -104.6355,
    id: 1840028453,
  },
  {
    name: "Westway",
    state_id: "TX",
    lat: 31.9603,
    lng: -106.576,
    id: 1840019525,
  },
  {
    name: "South Glens Falls",
    state_id: "NY",
    lat: 43.2951,
    lng: -73.6351,
    id: 1840004251,
  },
  {
    name: "Felton",
    state_id: "CA",
    lat: 37.0392,
    lng: -122.0804,
    id: 1840017651,
  },
  {
    name: "Blanding",
    state_id: "UT",
    lat: 37.6213,
    lng: -109.5072,
    id: 1840018877,
  },
  {
    name: "Camden",
    state_id: "TN",
    lat: 36.0663,
    lng: -88.1046,
    id: 1840013349,
  },
  {
    name: "Clearview",
    state_id: "WA",
    lat: 47.8292,
    lng: -122.1452,
    id: 1840023773,
  },
  {
    name: "Ringgold",
    state_id: "GA",
    lat: 34.9137,
    lng: -85.1216,
    id: 1840014652,
  },
  {
    name: "Farmersville",
    state_id: "TX",
    lat: 33.1609,
    lng: -96.3608,
    id: 1840020653,
  },
  {
    name: "South Duxbury",
    state_id: "MA",
    lat: 42.0203,
    lng: -70.6913,
    id: 1840004684,
  },
  {
    name: "Dell Rapids",
    state_id: "SD",
    lat: 43.8239,
    lng: -96.7138,
    id: 1840000341,
  },
  {
    name: "Lake Clarke Shores",
    state_id: "FL",
    lat: 26.646,
    lng: -80.0752,
    id: 1840017257,
  },
  {
    name: "Paoli",
    state_id: "IN",
    lat: 38.5572,
    lng: -86.4699,
    id: 1840014683,
  },
  {
    name: "Duncan",
    state_id: "SC",
    lat: 34.9351,
    lng: -82.144,
    id: 1840016464,
  },
  {
    name: "Bolivar",
    state_id: "OH",
    lat: 40.6506,
    lng: -81.4555,
    id: 1840012086,
  },
  {
    name: "Fairbury",
    state_id: "NE",
    lat: 40.1449,
    lng: -97.1764,
    id: 1840008368,
  },
  {
    name: "Beloit",
    state_id: "KS",
    lat: 39.4645,
    lng: -98.1083,
    id: 1840001588,
  },
  {
    name: "Waukon",
    state_id: "IA",
    lat: 43.2684,
    lng: -91.4782,
    id: 1840010062,
  },
  {
    name: "Oakwood",
    state_id: "OH",
    lat: 41.3668,
    lng: -81.5036,
    id: 1840144439,
  },
  {
    name: "Las Animas",
    state_id: "CO",
    lat: 38.0695,
    lng: -103.2237,
    id: 1840020268,
  },
  {
    name: "Pajaro",
    state_id: "CA",
    lat: 36.9016,
    lng: -121.7417,
    id: 1840019019,
  },
  {
    name: "New London",
    state_id: "MN",
    lat: 45.2975,
    lng: -94.9476,
    id: 1840007801,
  },
  {
    name: "Cedaredge",
    state_id: "CO",
    lat: 38.8941,
    lng: -107.9255,
    id: 1840022473,
  },
  {
    name: "Manhasset Hills",
    state_id: "NY",
    lat: 40.7592,
    lng: -73.681,
    id: 1840034055,
  },
  {
    name: "Northchase",
    state_id: "NC",
    lat: 34.3049,
    lng: -77.8754,
    id: 1840040283,
  },
  {
    name: "Pittsboro",
    state_id: "IN",
    lat: 39.8705,
    lng: -86.4654,
    id: 1840010578,
  },
  {
    name: "Dover Base Housing",
    state_id: "DE",
    lat: 39.1179,
    lng: -75.4838,
    id: 1840073616,
  },
  {
    name: "Clayton",
    state_id: "GA",
    lat: 34.8775,
    lng: -83.4027,
    id: 1840014636,
  },
  {
    name: "Dunean",
    state_id: "SC",
    lat: 34.8203,
    lng: -82.4225,
    id: 1840013078,
  },
  {
    name: "El Dorado Springs",
    state_id: "MO",
    lat: 37.8694,
    lng: -94.0196,
    id: 1840008710,
  },
  {
    name: "Whitesboro",
    state_id: "NY",
    lat: 43.124,
    lng: -75.2966,
    id: 1840004216,
  },
  {
    name: "Frackville",
    state_id: "PA",
    lat: 40.7833,
    lng: -76.2329,
    id: 1840002758,
  },
  {
    name: "Century",
    state_id: "FL",
    lat: 30.9766,
    lng: -87.2629,
    id: 1840017155,
  },
  {
    name: "Tarpey Village",
    state_id: "CA",
    lat: 36.7941,
    lng: -119.7012,
    id: 1840024782,
  },
  {
    name: "Mercer",
    state_id: "PA",
    lat: 41.2266,
    lng: -80.2361,
    id: 1840000735,
  },
  {
    name: "Northumberland",
    state_id: "PA",
    lat: 40.896,
    lng: -76.7937,
    id: 1840000847,
  },
  {
    name: "Romeo",
    state_id: "MI",
    lat: 42.8049,
    lng: -83.0041,
    id: 1840011050,
  },
  {
    name: "Brookville",
    state_id: "NY",
    lat: 40.8123,
    lng: -73.5696,
    id: 1840005338,
  },
  {
    name: "Saluda",
    state_id: "SC",
    lat: 34.0009,
    lng: -81.7713,
    id: 1840017971,
  },
  {
    name: "Cayuga Heights",
    state_id: "NY",
    lat: 42.468,
    lng: -76.4876,
    id: 1840004546,
  },
  {
    name: "Kutztown University",
    state_id: "PA",
    lat: 40.5103,
    lng: -75.785,
    id: 1840043333,
  },
  {
    name: "Heber",
    state_id: "CA",
    lat: 32.7318,
    lng: -115.5204,
    id: 1840018034,
  },
  {
    name: "Moss Beach",
    state_id: "CA",
    lat: 37.5184,
    lng: -122.5036,
    id: 1840018923,
  },
  {
    name: "Zelienople",
    state_id: "PA",
    lat: 40.7886,
    lng: -80.1428,
    id: 1840000878,
  },
  {
    name: "Bonanza",
    state_id: "GA",
    lat: 33.4589,
    lng: -84.3379,
    id: 1840029445,
  },
  {
    name: "Delta",
    state_id: "UT",
    lat: 39.3623,
    lng: -112.5467,
    id: 1840018809,
  },
  {
    name: "Ridgecrest",
    state_id: "FL",
    lat: 27.8953,
    lng: -82.8063,
    id: 1840029000,
  },
  {
    name: "Lake Cassidy",
    state_id: "WA",
    lat: 48.0639,
    lng: -122.092,
    id: 1840041959,
  },
  {
    name: "Gooding",
    state_id: "ID",
    lat: 42.9373,
    lng: -114.7134,
    id: 1840020063,
  },
  {
    name: "Palmyra",
    state_id: "MO",
    lat: 39.7985,
    lng: -91.5272,
    id: 1840009525,
  },
  {
    name: "Lake of the Pines",
    state_id: "CA",
    lat: 39.0378,
    lng: -121.0642,
    id: 1840017554,
  },
  {
    name: "McCall",
    state_id: "ID",
    lat: 44.9076,
    lng: -116.1124,
    id: 1840019964,
  },
  {
    name: "Gordon Heights",
    state_id: "NY",
    lat: 40.8645,
    lng: -72.9678,
    id: 1840005072,
  },
  {
    name: "Ozark",
    state_id: "AR",
    lat: 35.4995,
    lng: -93.8431,
    id: 1840014531,
  },
  {
    name: "Scissors",
    state_id: "TX",
    lat: 26.1333,
    lng: -98.0476,
    id: 1840036333,
  },
  {
    name: "Narragansett Pier",
    state_id: "RI",
    lat: 41.428,
    lng: -71.467,
    id: 1840003366,
  },
  {
    name: "Unicoi",
    state_id: "TN",
    lat: 36.2217,
    lng: -82.3293,
    id: 1840016131,
  },
  {
    name: "Combined Locks",
    state_id: "WI",
    lat: 44.2642,
    lng: -88.3064,
    id: 1840002407,
  },
  {
    name: "Kimberly",
    state_id: "AL",
    lat: 33.776,
    lng: -86.7968,
    id: 1840016799,
  },
  {
    name: "Pine Lawn",
    state_id: "MO",
    lat: 38.6953,
    lng: -90.2756,
    id: 1840009768,
  },
  {
    name: "Loxahatchee Groves",
    state_id: "FL",
    lat: 26.7106,
    lng: -80.2763,
    id: 1840017260,
  },
  {
    name: "Goldstream",
    state_id: "AK",
    lat: 64.9339,
    lng: -148.0083,
    id: 1840075196,
  },
  {
    name: "Southern Shops",
    state_id: "SC",
    lat: 34.9846,
    lng: -81.9934,
    id: 1840035851,
  },
  {
    name: "Elm Creek",
    state_id: "TX",
    lat: 28.7782,
    lng: -100.4911,
    id: 1840037046,
  },
  {
    name: "Newport",
    state_id: "MN",
    lat: 44.8744,
    lng: -92.9975,
    id: 1840007811,
  },
  {
    name: "North Baltimore",
    state_id: "OH",
    lat: 41.1798,
    lng: -83.6702,
    id: 1840011560,
  },
  {
    name: "Peralta",
    state_id: "NM",
    lat: 34.8283,
    lng: -106.686,
    id: 1840022815,
  },
  {
    name: "Omro",
    state_id: "WI",
    lat: 44.0391,
    lng: -88.7378,
    id: 1840002245,
  },
  {
    name: "Woodbridge",
    state_id: "CA",
    lat: 38.1692,
    lng: -121.3113,
    id: 1840026773,
  },
  {
    name: "Saw Creek",
    state_id: "PA",
    lat: 41.1197,
    lng: -75.0465,
    id: 1840035379,
  },
  {
    name: "Stallion Springs",
    state_id: "CA",
    lat: 35.0935,
    lng: -118.6477,
    id: 1840019132,
  },
  {
    name: "Christiana",
    state_id: "TN",
    lat: 35.7204,
    lng: -86.4109,
    id: 1840036020,
  },
  {
    name: "Darien",
    state_id: "GA",
    lat: 31.3568,
    lng: -81.4315,
    id: 1840014952,
  },
  {
    name: "O'Neill",
    state_id: "NE",
    lat: 42.461,
    lng: -98.6465,
    id: 1840000406,
  },
  {
    name: "Orofino",
    state_id: "ID",
    lat: 46.4866,
    lng: -116.2568,
    id: 1840019876,
  },
  {
    name: "Osceola",
    state_id: "WI",
    lat: 45.3193,
    lng: -92.6936,
    id: 1840002057,
  },
  {
    name: "Golf Manor",
    state_id: "OH",
    lat: 39.1878,
    lng: -84.4469,
    id: 1840003820,
  },
  {
    name: "Thompsonville",
    state_id: "PA",
    lat: 40.2802,
    lng: -80.1204,
    id: 1840005436,
  },
  {
    name: "Sandia Heights",
    state_id: "NM",
    lat: 35.1746,
    lng: -106.4884,
    id: 1840033723,
  },
  {
    name: "Taylor Lake Village",
    state_id: "TX",
    lat: 29.5764,
    lng: -95.0562,
    id: 1840022202,
  },
  {
    name: "Kitty Hawk",
    state_id: "NC",
    lat: 36.0717,
    lng: -75.7184,
    id: 1840016155,
  },
  {
    name: "Valley Grande",
    state_id: "AL",
    lat: 32.4862,
    lng: -87.0305,
    id: 1840015794,
  },
  {
    name: "Volcano",
    state_id: "HI",
    lat: 19.48,
    lng: -155.2487,
    id: 1840023209,
  },
  {
    name: "Hubbard",
    state_id: "OR",
    lat: 45.1818,
    lng: -122.8073,
    id: 1840019961,
  },
  {
    name: "Meiners Oaks",
    state_id: "CA",
    lat: 34.4505,
    lng: -119.2731,
    id: 1840019196,
  },
  {
    name: "Eveleth",
    state_id: "MN",
    lat: 47.4637,
    lng: -92.5443,
    id: 1840007687,
  },
  {
    name: "Greenhills",
    state_id: "OH",
    lat: 39.267,
    lng: -84.5194,
    id: 1840003821,
  },
  {
    name: "Rineyville",
    state_id: "KY",
    lat: 37.7502,
    lng: -85.9703,
    id: 1840030569,
  },
  {
    name: "Dixmoor",
    state_id: "IL",
    lat: 41.633,
    lng: -87.6672,
    id: 1840011268,
  },
  {
    name: "Lake Erie Beach",
    state_id: "NY",
    lat: 42.6242,
    lng: -79.0786,
    id: 1840004380,
  },
  {
    name: "Riverwoods",
    state_id: "IL",
    lat: 42.1723,
    lng: -87.895,
    id: 1840011169,
  },
  {
    name: "Newtown Grant",
    state_id: "PA",
    lat: 40.2599,
    lng: -74.9561,
    id: 1840035430,
  },
  {
    name: "Kelseyville",
    state_id: "CA",
    lat: 38.9704,
    lng: -122.8327,
    id: 1840017550,
  },
  {
    name: "Cambridge City",
    state_id: "IN",
    lat: 39.8124,
    lng: -85.1703,
    id: 1840009493,
  },
  {
    name: "Canton",
    state_id: "SD",
    lat: 43.3032,
    lng: -96.5848,
    id: 1840002843,
  },
  {
    name: "Oglesby",
    state_id: "IL",
    lat: 41.296,
    lng: -89.0693,
    id: 1840008200,
  },
  {
    name: "Hainesville",
    state_id: "IL",
    lat: 42.3414,
    lng: -88.0686,
    id: 1840011153,
  },
  {
    name: "Brittany Farms-The Highlands",
    state_id: "PA",
    lat: 40.269,
    lng: -75.214,
    id: 1840073600,
  },
  {
    name: "Helena Valley Northeast",
    state_id: "MT",
    lat: 46.6993,
    lng: -111.9516,
    id: 1840074061,
  },
  {
    name: "Long Lake",
    state_id: "IL",
    lat: 42.3765,
    lng: -88.1266,
    id: 1840004640,
  },
  {
    name: "Northwest Harbor",
    state_id: "NY",
    lat: 41.0053,
    lng: -72.222,
    id: 1840034057,
  },
  {
    name: "Oxford",
    state_id: "MI",
    lat: 42.8217,
    lng: -83.2563,
    id: 1840011060,
  },
  {
    name: "Gallipolis",
    state_id: "OH",
    lat: 38.8186,
    lng: -82.1934,
    id: 1840012775,
  },
  {
    name: "Pen Argyl",
    state_id: "PA",
    lat: 40.8675,
    lng: -75.2535,
    id: 1840000977,
  },
  {
    name: "Oregon",
    state_id: "IL",
    lat: 42.0131,
    lng: -89.3354,
    id: 1840009177,
  },
  {
    name: "Osage",
    state_id: "IA",
    lat: 43.2826,
    lng: -92.8114,
    id: 1840009032,
  },
  {
    name: "Carey",
    state_id: "OH",
    lat: 40.9499,
    lng: -83.3826,
    id: 1840010347,
  },
  {
    name: "Annandale",
    state_id: "MN",
    lat: 45.2598,
    lng: -94.1205,
    id: 1840006707,
  },
  {
    name: "Dublin",
    state_id: "TX",
    lat: 32.0875,
    lng: -98.3391,
    id: 1840019478,
  },
  {
    name: "Nowata",
    state_id: "OK",
    lat: 36.699,
    lng: -95.6379,
    id: 1840020337,
  },
  {
    name: "Camden",
    state_id: "DE",
    lat: 39.0991,
    lng: -75.557,
    id: 1840005813,
  },
  {
    name: "Vienna",
    state_id: "GA",
    lat: 32.0925,
    lng: -83.7864,
    id: 1840015829,
  },
  {
    name: "Baltimore",
    state_id: "OH",
    lat: 39.846,
    lng: -82.6047,
    id: 1840012468,
  },
  {
    name: "Shadyside",
    state_id: "OH",
    lat: 39.9716,
    lng: -80.7511,
    id: 1840012389,
  },
  {
    name: "Mission Hills",
    state_id: "KS",
    lat: 39.0141,
    lng: -94.6172,
    id: 1840003832,
  },
  {
    name: "Marlboro",
    state_id: "NY",
    lat: 41.6028,
    lng: -73.9774,
    id: 1840004722,
  },
  {
    name: "Boiling Springs",
    state_id: "PA",
    lat: 40.1592,
    lng: -77.139,
    id: 1840005484,
  },
  {
    name: "Knox",
    state_id: "IN",
    lat: 41.291,
    lng: -86.621,
    id: 1840013836,
  },
  {
    name: "Frederick",
    state_id: "OK",
    lat: 34.3709,
    lng: -99.001,
    id: 1840020527,
  },
  {
    name: "Windermere",
    state_id: "FL",
    lat: 28.5035,
    lng: -81.541,
    id: 1840017238,
  },
  {
    name: "Nanty-Glo",
    state_id: "PA",
    lat: 40.4708,
    lng: -78.8339,
    id: 1840066082,
  },
  {
    name: "Frontenac",
    state_id: "MO",
    lat: 38.6301,
    lng: -90.419,
    id: 1840008587,
  },
  {
    name: "Amberley",
    state_id: "OH",
    lat: 39.2035,
    lng: -84.4283,
    id: 1840003810,
  },
  {
    name: "Mississippi State",
    state_id: "MS",
    lat: 33.4515,
    lng: -88.7913,
    id: 1840042497,
  },
  {
    name: "Sloan",
    state_id: "NY",
    lat: 42.8921,
    lng: -78.7918,
    id: 1840004390,
  },
  {
    name: "Centerville",
    state_id: "TN",
    lat: 35.8042,
    lng: -87.4577,
    id: 1840016240,
  },
  {
    name: "Breñas",
    state_id: "PR",
    lat: 18.4734,
    lng: -66.3346,
    id: 1630035461,
  },
  {
    name: "Robins",
    state_id: "IA",
    lat: 42.0799,
    lng: -91.6762,
    id: 1840009148,
  },
  {
    name: "Valhalla",
    state_id: "NY",
    lat: 41.0775,
    lng: -73.778,
    id: 1840033975,
  },
  {
    name: "Willcox",
    state_id: "AZ",
    lat: 32.2514,
    lng: -109.8359,
    id: 1840022105,
  },
  {
    name: "Lead",
    state_id: "SD",
    lat: 44.3528,
    lng: -103.7671,
    id: 1840002376,
  },
  {
    name: "Berwyn",
    state_id: "PA",
    lat: 40.0396,
    lng: -75.4439,
    id: 1840024351,
  },
  {
    name: "Momence",
    state_id: "IL",
    lat: 41.164,
    lng: -87.663,
    id: 1840008259,
  },
  {
    name: "Mount Pleasant",
    state_id: "UT",
    lat: 39.5408,
    lng: -111.4558,
    id: 1840020208,
  },
  {
    name: "Two Harbors",
    state_id: "MN",
    lat: 47.03,
    lng: -91.675,
    id: 1840009960,
  },
  {
    name: "Piggott",
    state_id: "AR",
    lat: 36.3858,
    lng: -90.2017,
    id: 1840014457,
  },
  {
    name: "Buzzards Bay",
    state_id: "MA",
    lat: 41.7551,
    lng: -70.6129,
    id: 1840003232,
  },
  {
    name: "Morton",
    state_id: "MS",
    lat: 32.3455,
    lng: -89.6572,
    id: 1840014886,
  },
  {
    name: "Evergreen",
    state_id: "AL",
    lat: 31.4342,
    lng: -86.9723,
    id: 1840001842,
  },
  {
    name: "Unadilla",
    state_id: "GA",
    lat: 32.2583,
    lng: -83.7356,
    id: 1840015828,
  },
  {
    name: "Indianola",
    state_id: "WA",
    lat: 47.7566,
    lng: -122.5142,
    id: 1840017337,
  },
  {
    name: "Rockfish",
    state_id: "NC",
    lat: 34.9902,
    lng: -79.0694,
    id: 1840013507,
  },
  {
    name: "Lockeford",
    state_id: "CA",
    lat: 38.1509,
    lng: -121.1554,
    id: 1840018894,
  },
  {
    name: "Holden Heights",
    state_id: "FL",
    lat: 28.5013,
    lng: -81.3878,
    id: 1840029043,
  },
  {
    name: "West Liberty",
    state_id: "KY",
    lat: 37.9161,
    lng: -83.2668,
    id: 1840015215,
  },
  {
    name: "Paw Paw Lake",
    state_id: "MI",
    lat: 42.2113,
    lng: -86.2759,
    id: 1840004699,
  },
  {
    name: "Harrison",
    state_id: "MI",
    lat: 44.0168,
    lng: -84.808,
    id: 1840002562,
  },
  {
    name: "Golden Grove",
    state_id: "SC",
    lat: 34.7324,
    lng: -82.4408,
    id: 1840013493,
  },
  {
    name: "Mariemont",
    state_id: "OH",
    lat: 39.1429,
    lng: -84.3784,
    id: 1840001606,
  },
  {
    name: "Reliez Valley",
    state_id: "CA",
    lat: 37.9399,
    lng: -122.1027,
    id: 1840076147,
  },
  {
    name: "Southern Gateway",
    state_id: "VA",
    lat: 38.3489,
    lng: -77.5036,
    id: 1840041862,
  },
  {
    name: "Gravette",
    state_id: "AR",
    lat: 36.4291,
    lng: -94.3714,
    id: 1840013318,
  },
  {
    name: "Teague",
    state_id: "TX",
    lat: 31.6295,
    lng: -96.2801,
    id: 1840022134,
  },
  {
    name: "Silver Creek",
    state_id: "NY",
    lat: 42.5426,
    lng: -79.1677,
    id: 1840004571,
  },
  {
    name: "Brimfield",
    state_id: "OH",
    lat: 41.0941,
    lng: -81.3483,
    id: 1840004957,
  },
  {
    name: "St. Johns",
    state_id: "AZ",
    lat: 34.5018,
    lng: -109.3784,
    id: 1840021622,
  },
  {
    name: "La Conner",
    state_id: "WA",
    lat: 48.3927,
    lng: -122.4944,
    id: 1840021068,
  },
  {
    name: "Clayton",
    state_id: "DE",
    lat: 39.2846,
    lng: -75.6364,
    id: 1840005815,
  },
  {
    name: "Wrightstown",
    state_id: "WI",
    lat: 44.3262,
    lng: -88.1756,
    id: 1840002343,
  },
  {
    name: "Poteet",
    state_id: "TX",
    lat: 29.0372,
    lng: -98.5728,
    id: 1840020989,
  },
  {
    name: "Hanover",
    state_id: "MN",
    lat: 45.158,
    lng: -93.6631,
    id: 1840006708,
  },
  {
    name: "Riverside",
    state_id: "MO",
    lat: 39.1703,
    lng: -94.6305,
    id: 1840009593,
  },
  {
    name: "Goldendale",
    state_id: "WA",
    lat: 45.8191,
    lng: -120.8233,
    id: 1840019911,
  },
  {
    name: "Bloomer",
    state_id: "WI",
    lat: 45.103,
    lng: -91.4913,
    id: 1840002106,
  },
  {
    name: "Lyons",
    state_id: "KS",
    lat: 38.3462,
    lng: -98.2045,
    id: 1840001662,
  },
  {
    name: "Seymour",
    state_id: "WI",
    lat: 44.5142,
    lng: -88.3271,
    id: 1840002410,
  },
  {
    name: "Amity Gardens",
    state_id: "PA",
    lat: 40.2719,
    lng: -75.7321,
    id: 1840035418,
  },
  {
    name: "Hot Springs",
    state_id: "SD",
    lat: 43.4196,
    lng: -103.4658,
    id: 1840002868,
  },
  {
    name: "Valley Springs",
    state_id: "CA",
    lat: 38.1837,
    lng: -120.815,
    id: 1840018870,
  },
  {
    name: "Hanover",
    state_id: "IN",
    lat: 38.7133,
    lng: -85.4726,
    id: 1840013778,
  },
  {
    name: "Nellis AFB",
    state_id: "NV",
    lat: 36.2473,
    lng: -115.0574,
    id: 1840074502,
  },
  {
    name: "Zumbrota",
    state_id: "MN",
    lat: 44.295,
    lng: -92.6734,
    id: 1840000306,
  },
  {
    name: "Vassar",
    state_id: "MI",
    lat: 43.3719,
    lng: -83.577,
    id: 1840002686,
  },
  {
    name: "Granite Hills",
    state_id: "CA",
    lat: 32.8033,
    lng: -116.9056,
    id: 1840028377,
  },
  {
    name: "Broken Bow",
    state_id: "NE",
    lat: 41.4052,
    lng: -99.6401,
    id: 1840003347,
  },
  {
    name: "Llano",
    state_id: "TX",
    lat: 30.7508,
    lng: -98.6763,
    id: 1840020873,
  },
  {
    name: "Moscow Mills",
    state_id: "MO",
    lat: 38.94,
    lng: -90.9254,
    id: 1840008542,
  },
  {
    name: "Emerald Lakes",
    state_id: "PA",
    lat: 41.0845,
    lng: -75.4154,
    id: 1840035380,
  },
  {
    name: "Lake Isabella",
    state_id: "CA",
    lat: 35.6378,
    lng: -118.4819,
    id: 1840017823,
  },
  {
    name: "Glen Raven",
    state_id: "NC",
    lat: 36.1247,
    lng: -79.4649,
    id: 1840013357,
  },
  {
    name: "Grasonville",
    state_id: "MD",
    lat: 38.9559,
    lng: -76.1948,
    id: 1840005895,
  },
  {
    name: "Le Roy",
    state_id: "IL",
    lat: 40.3395,
    lng: -88.7628,
    id: 1840008325,
  },
  {
    name: "East Prairie",
    state_id: "MO",
    lat: 36.7788,
    lng: -89.3837,
    id: 1840008790,
  },
  {
    name: "Newaygo",
    state_id: "MI",
    lat: 43.4162,
    lng: -85.8031,
    id: 1840002679,
  },
  {
    name: "St. James City",
    state_id: "FL",
    lat: 26.5507,
    lng: -82.098,
    id: 1840014211,
  },
  {
    name: "Waller",
    state_id: "TX",
    lat: 30.0629,
    lng: -95.9221,
    id: 1840022195,
  },
  {
    name: "West Monroe",
    state_id: "MI",
    lat: 41.9143,
    lng: -83.4317,
    id: 1840007675,
  },
  {
    name: "Elkins",
    state_id: "AR",
    lat: 36.0163,
    lng: -94.025,
    id: 1840013365,
  },
  {
    name: "Huntingtown",
    state_id: "MD",
    lat: 38.6117,
    lng: -76.6187,
    id: 1840006147,
  },
  {
    name: "Lake Royale",
    state_id: "NC",
    lat: 35.9666,
    lng: -78.196,
    id: 1840040280,
  },
  {
    name: "Sauk City",
    state_id: "WI",
    lat: 43.2722,
    lng: -89.7312,
    id: 1840002729,
  },
  {
    name: "Myrtle Creek",
    state_id: "OR",
    lat: 43.0226,
    lng: -123.2812,
    id: 1840020035,
  },
  {
    name: "Kane",
    state_id: "PA",
    lat: 41.663,
    lng: -78.8096,
    id: 1840000525,
  },
  {
    name: "Vicksburg",
    state_id: "MI",
    lat: 42.1186,
    lng: -85.5413,
    id: 1840011213,
  },
  {
    name: "Delshire",
    state_id: "OH",
    lat: 39.0884,
    lng: -84.595,
    id: 1840042360,
  },
  {
    name: "Vivian",
    state_id: "LA",
    lat: 32.8714,
    lng: -93.9862,
    id: 1840016932,
  },
  {
    name: "Locust Valley",
    state_id: "NY",
    lat: 40.8782,
    lng: -73.5884,
    id: 1840005272,
  },
  {
    name: "Three Lakes",
    state_id: "WA",
    lat: 47.942,
    lng: -121.9924,
    id: 1840018375,
  },
  {
    name: "Watkins Glen",
    state_id: "NY",
    lat: 42.3801,
    lng: -76.8673,
    id: 1840004585,
  },
  {
    name: "Red Bud",
    state_id: "IL",
    lat: 38.2096,
    lng: -89.9997,
    id: 1840009841,
  },
  {
    name: "Forsyth",
    state_id: "IL",
    lat: 39.9259,
    lng: -88.964,
    id: 1840012428,
  },
  {
    name: "Yuma",
    state_id: "CO",
    lat: 40.124,
    lng: -102.7161,
    id: 1840021413,
  },
  {
    name: "Raymond",
    state_id: "MS",
    lat: 32.2609,
    lng: -90.4083,
    id: 1840014894,
  },
  {
    name: "Balmville",
    state_id: "NY",
    lat: 41.5281,
    lng: -74.0234,
    id: 1840004856,
  },
  {
    name: "Cienegas Terrace",
    state_id: "TX",
    lat: 29.3687,
    lng: -100.9432,
    id: 1840037038,
  },
  {
    name: "Carrollton",
    state_id: "MO",
    lat: 39.3636,
    lng: -93.4955,
    id: 1840007333,
  },
  {
    name: "Alturas",
    state_id: "FL",
    lat: 27.8419,
    lng: -81.6952,
    id: 1840025153,
  },
  {
    name: "Pine Island",
    state_id: "MN",
    lat: 44.197,
    lng: -92.6165,
    id: 1840008958,
  },
  {
    name: "Middleville",
    state_id: "MI",
    lat: 42.7137,
    lng: -85.4692,
    id: 1840011089,
  },
  {
    name: "East Sandwich",
    state_id: "MA",
    lat: 41.7365,
    lng: -70.4341,
    id: 1840003239,
  },
  {
    name: "Emerald Mountain",
    state_id: "AL",
    lat: 32.4396,
    lng: -86.0857,
    id: 1840037820,
  },
  {
    name: "Brentwood",
    state_id: "MD",
    lat: 38.9439,
    lng: -76.9571,
    id: 1840005982,
  },
  {
    name: "Marlboro Meadows",
    state_id: "MD",
    lat: 38.8374,
    lng: -76.7144,
    id: 1840031483,
  },
  {
    name: "Red Oak",
    state_id: "NC",
    lat: 36.0415,
    lng: -77.9065,
    id: 1840017745,
  },
  {
    name: "Pelham",
    state_id: "GA",
    lat: 31.1267,
    lng: -84.1514,
    id: 1840014967,
  },
  {
    name: "Hicksville",
    state_id: "OH",
    lat: 41.2944,
    lng: -84.7648,
    id: 1840011644,
  },
  {
    name: "Johnston City",
    state_id: "IL",
    lat: 37.8214,
    lng: -88.929,
    id: 1840008713,
  },
  {
    name: "Cedar Grove",
    state_id: "FL",
    lat: 30.1812,
    lng: -85.6268,
    id: 1840027022,
  },
  {
    name: "Hidden Meadows",
    state_id: "CA",
    lat: 33.2236,
    lng: -117.1198,
    id: 1840028378,
  },
  {
    name: "Wheatland",
    state_id: "WY",
    lat: 42.0516,
    lng: -104.9595,
    id: 1840022368,
  },
  {
    name: "Hanceville",
    state_id: "AL",
    lat: 34.0634,
    lng: -86.7651,
    id: 1840013642,
  },
  {
    name: "Hodgenville",
    state_id: "KY",
    lat: 37.5668,
    lng: -85.7353,
    id: 1840014351,
  },
  {
    name: "Shannondale",
    state_id: "WV",
    lat: 39.2123,
    lng: -77.8105,
    id: 1840025610,
  },
  {
    name: "North Pembroke",
    state_id: "MA",
    lat: 42.0989,
    lng: -70.7823,
    id: 1840004678,
  },
  {
    name: "Sunset",
    state_id: "LA",
    lat: 30.4083,
    lng: -92.0641,
    id: 1840017174,
  },
  {
    name: "Brodhead",
    state_id: "WI",
    lat: 42.6168,
    lng: -89.3756,
    id: 1840002460,
  },
  {
    name: "Alexander",
    state_id: "AR",
    lat: 34.6185,
    lng: -92.4512,
    id: 1840013537,
  },
  {
    name: "Loyalhanna",
    state_id: "PA",
    lat: 40.3144,
    lng: -79.356,
    id: 1840026436,
  },
  {
    name: "Malvern",
    state_id: "PA",
    lat: 40.0329,
    lng: -75.5146,
    id: 1840001429,
  },
  {
    name: "Putnam Lake",
    state_id: "NY",
    lat: 41.4747,
    lng: -73.5483,
    id: 1840004889,
  },
  {
    name: "McLendon-Chisholm",
    state_id: "TX",
    lat: 32.8512,
    lng: -96.3924,
    id: 1840003905,
  },
  {
    name: "Carlton",
    state_id: "OR",
    lat: 45.2945,
    lng: -123.1753,
    id: 1840018573,
  },
  {
    name: "Elim",
    state_id: "PA",
    lat: 40.2989,
    lng: -78.943,
    id: 1840035422,
  },
  {
    name: "Wilkesboro",
    state_id: "NC",
    lat: 36.142,
    lng: -81.173,
    id: 1840016093,
  },
  {
    name: "Cross Mountain",
    state_id: "TX",
    lat: 29.6535,
    lng: -98.6564,
    id: 1840018268,
  },
  {
    name: "Pawhuska",
    state_id: "OK",
    lat: 36.6687,
    lng: -96.3312,
    id: 1840020339,
  },
  {
    name: "Doolittle",
    state_id: "TX",
    lat: 26.3598,
    lng: -98.1168,
    id: 1840037043,
  },
  {
    name: "Rollingwood",
    state_id: "CA",
    lat: 37.9655,
    lng: -122.3305,
    id: 1840028352,
  },
  {
    name: "Gothenburg",
    state_id: "NE",
    lat: 40.9241,
    lng: -100.154,
    id: 1840008284,
  },
  {
    name: "Hannahs Mill",
    state_id: "GA",
    lat: 32.9369,
    lng: -84.3405,
    id: 1840013795,
  },
  {
    name: "Jasper",
    state_id: "TN",
    lat: 35.0664,
    lng: -85.6228,
    id: 1840016428,
  },
  {
    name: "Mineral Ridge",
    state_id: "OH",
    lat: 41.1379,
    lng: -80.7653,
    id: 1840004899,
  },
  {
    name: "Heyburn",
    state_id: "ID",
    lat: 42.5599,
    lng: -113.7623,
    id: 1840020065,
  },
  {
    name: "Oakhurst",
    state_id: "CA",
    lat: 37.3342,
    lng: -119.6483,
    id: 1840018918,
  },
  {
    name: "Clifton",
    state_id: "TX",
    lat: 31.7822,
    lng: -97.5824,
    id: 1840019512,
  },
  {
    name: "Moore Haven",
    state_id: "FL",
    lat: 26.8338,
    lng: -81.0984,
    id: 1840015121,
  },
  {
    name: "Highland Acres",
    state_id: "DE",
    lat: 39.1162,
    lng: -75.5216,
    id: 1840028616,
  },
  {
    name: "Aguas Buenas",
    state_id: "PR",
    lat: 18.2573,
    lng: -66.1062,
    id: 1630035606,
  },
  {
    name: "Mono Vista",
    state_id: "CA",
    lat: 38.0124,
    lng: -120.2697,
    id: 1840018880,
  },
  {
    name: "Aledo",
    state_id: "IL",
    lat: 41.1984,
    lng: -90.7459,
    id: 1840007139,
  },
  {
    name: "Mount Orab",
    state_id: "OH",
    lat: 39.0293,
    lng: -83.9267,
    id: 1840012724,
  },
  {
    name: "Piney Point Village",
    state_id: "TX",
    lat: 29.7588,
    lng: -95.5156,
    id: 1840020931,
  },
  {
    name: "Mobridge",
    state_id: "SD",
    lat: 45.541,
    lng: -100.4349,
    id: 1840002090,
  },
  {
    name: "Dillonvale",
    state_id: "OH",
    lat: 39.2173,
    lng: -84.403,
    id: 1840146897,
  },
  {
    name: "Kekaha",
    state_id: "HI",
    lat: 21.9684,
    lng: -159.7141,
    id: 1840023218,
  },
  {
    name: "Oaklawn-Sunview",
    state_id: "KS",
    lat: 37.6084,
    lng: -97.2984,
    id: 1840035328,
  },
  {
    name: "Point Baker",
    state_id: "FL",
    lat: 30.6855,
    lng: -87.0521,
    id: 1840025118,
  },
  {
    name: "Lockland",
    state_id: "OH",
    lat: 39.2278,
    lng: -84.4565,
    id: 1840003823,
  },
  {
    name: "Rochester",
    state_id: "PA",
    lat: 40.7025,
    lng: -80.2838,
    id: 1840001008,
  },
  {
    name: "Treasure Lake",
    state_id: "PA",
    lat: 41.1713,
    lng: -78.7173,
    id: 1840005151,
  },
  {
    name: "Cottonport",
    state_id: "LA",
    lat: 30.9892,
    lng: -92.0509,
    id: 1840017120,
  },
  {
    name: "Tierra Verde",
    state_id: "FL",
    lat: 27.6685,
    lng: -82.7304,
    id: 1840014142,
  },
  {
    name: "Landmark",
    state_id: "AR",
    lat: 34.607,
    lng: -92.3206,
    id: 1840027803,
  },
  {
    name: "Iowa",
    state_id: "LA",
    lat: 30.2393,
    lng: -93.0129,
    id: 1840017194,
  },
  {
    name: "Riverton",
    state_id: "IL",
    lat: 39.8456,
    lng: -89.5394,
    id: 1840012448,
  },
  {
    name: "Canyon Creek",
    state_id: "WA",
    lat: 48.1157,
    lng: -121.9831,
    id: 1840074181,
  },
  {
    name: "Riverdale",
    state_id: "CA",
    lat: 36.4304,
    lng: -119.8671,
    id: 1840018942,
  },
  {
    name: "Hoosick Falls",
    state_id: "NY",
    lat: 42.9009,
    lng: -73.35,
    id: 1840004436,
  },
  {
    name: "San Miguel",
    state_id: "CA",
    lat: 37.8867,
    lng: -122.0368,
    id: 1840076163,
  },
  {
    name: "Ross",
    state_id: "OH",
    lat: 39.3146,
    lng: -84.6599,
    id: 1840005748,
  },
  {
    name: "Yadkinville",
    state_id: "NC",
    lat: 36.131,
    lng: -80.6588,
    id: 1840016112,
  },
  {
    name: "La Vale",
    state_id: "MD",
    lat: 39.6518,
    lng: -78.8162,
    id: 1840005620,
  },
  {
    name: "Gibson City",
    state_id: "IL",
    lat: 40.4665,
    lng: -88.3788,
    id: 1840029738,
  },
  {
    name: "South Beach",
    state_id: "FL",
    lat: 27.5883,
    lng: -80.3435,
    id: 1840029075,
  },
  {
    name: "Dennis Port",
    state_id: "MA",
    lat: 41.6677,
    lng: -70.1358,
    id: 1840003235,
  },
  {
    name: "Monte Sereno",
    state_id: "CA",
    lat: 37.2404,
    lng: -121.9882,
    id: 1840020330,
  },
  {
    name: "Houston",
    state_id: "MS",
    lat: 33.8963,
    lng: -89.0031,
    id: 1840014760,
  },
  {
    name: "Hobart",
    state_id: "OK",
    lat: 35.0246,
    lng: -99.0875,
    id: 1840020465,
  },
  {
    name: "Ingenio",
    state_id: "PR",
    lat: 18.4454,
    lng: -66.2259,
    id: 1630035685,
  },
  {
    name: "Montura",
    state_id: "FL",
    lat: 26.6429,
    lng: -81.0938,
    id: 1840039004,
  },
  {
    name: "Freeland",
    state_id: "PA",
    lat: 41.0212,
    lng: -75.8963,
    id: 1840000751,
  },
  {
    name: "Smithville-Sanders",
    state_id: "IN",
    lat: 39.0597,
    lng: -86.5108,
    id: 1840039288,
  },
  {
    name: "Germantown Hills",
    state_id: "IL",
    lat: 40.7712,
    lng: -89.467,
    id: 1840011943,
  },
  {
    name: "Paulding",
    state_id: "OH",
    lat: 41.1424,
    lng: -84.5824,
    id: 1840011727,
  },
  {
    name: "Cape May",
    state_id: "NJ",
    lat: 38.9409,
    lng: -74.9042,
    id: 1840001594,
  },
  {
    name: "Harlem",
    state_id: "GA",
    lat: 33.4262,
    lng: -82.3129,
    id: 1840013749,
  },
  {
    name: "Claxton",
    state_id: "GA",
    lat: 32.1613,
    lng: -81.9094,
    id: 1840014910,
  },
  {
    name: "Cuthbert",
    state_id: "GA",
    lat: 31.7713,
    lng: -84.794,
    id: 1840014939,
  },
  {
    name: "Tutwiler",
    state_id: "MS",
    lat: 34.0172,
    lng: -90.4333,
    id: 1840016741,
  },
  {
    name: "Minoa",
    state_id: "NY",
    lat: 43.0742,
    lng: -76.0085,
    id: 1840004325,
  },
  {
    name: "Oliver Springs",
    state_id: "TN",
    lat: 36.0398,
    lng: -84.3284,
    id: 1840016221,
  },
  {
    name: "Baldwin",
    state_id: "GA",
    lat: 34.4864,
    lng: -83.5494,
    id: 1840013576,
  },
  {
    name: "Meadowbrook",
    state_id: "CA",
    lat: 33.7253,
    lng: -117.2851,
    id: 1840075971,
  },
  {
    name: "Hansville",
    state_id: "WA",
    lat: 47.8978,
    lng: -122.5579,
    id: 1840023796,
  },
  {
    name: "Leisure Village West",
    state_id: "NJ",
    lat: 40.0104,
    lng: -74.2806,
    id: 1840033491,
  },
  {
    name: "High Bridge",
    state_id: "NJ",
    lat: 40.6684,
    lng: -74.894,
    id: 1840003609,
  },
  {
    name: "Heflin",
    state_id: "AL",
    lat: 33.6471,
    lng: -85.5698,
    id: 1840007380,
  },
  {
    name: "Campo",
    state_id: "CA",
    lat: 32.6394,
    lng: -116.4745,
    id: 1840022969,
  },
  {
    name: "Eagle Grove",
    state_id: "IA",
    lat: 42.6664,
    lng: -93.9026,
    id: 1840008032,
  },
  {
    name: "New Hampton",
    state_id: "IA",
    lat: 43.0563,
    lng: -92.3153,
    id: 1840007998,
  },
  {
    name: "Calcium",
    state_id: "NY",
    lat: 44.0401,
    lng: -75.8468,
    id: 1840004105,
  },
  {
    name: "Lake Lakengren",
    state_id: "OH",
    lat: 39.6894,
    lng: -84.69,
    id: 1840034397,
  },
  {
    name: "La Center",
    state_id: "WA",
    lat: 45.8595,
    lng: -122.6757,
    id: 1840019910,
  },
  {
    name: "Woodlawn",
    state_id: "OH",
    lat: 39.2556,
    lng: -84.471,
    id: 1840001618,
  },
  {
    name: "Dunkirk",
    state_id: "IN",
    lat: 40.3741,
    lng: -85.2076,
    id: 1840008351,
  },
  {
    name: "Marianna",
    state_id: "AR",
    lat: 34.7736,
    lng: -90.7674,
    id: 1840015534,
  },
  {
    name: "Concordia",
    state_id: "NJ",
    lat: 40.3117,
    lng: -74.4477,
    id: 1840005412,
  },
  {
    name: "Richwood",
    state_id: "LA",
    lat: 32.4486,
    lng: -92.0774,
    id: 1840018087,
  },
  {
    name: "Newcastle",
    state_id: "WY",
    lat: 43.851,
    lng: -104.2123,
    id: 1840020012,
  },
  {
    name: "Toa Alta",
    state_id: "PR",
    lat: 18.3883,
    lng: -66.2503,
    id: 1630023642,
  },
  {
    name: "Haworth",
    state_id: "NJ",
    lat: 40.9622,
    lng: -73.9976,
    id: 1840003559,
  },
  {
    name: "Trinity",
    state_id: "TX",
    lat: 30.9445,
    lng: -95.3736,
    id: 1840022153,
  },
  {
    name: "South Dennis",
    state_id: "MA",
    lat: 41.7051,
    lng: -70.1537,
    id: 1840004765,
  },
  {
    name: "Tolono",
    state_id: "IL",
    lat: 39.9912,
    lng: -88.2625,
    id: 1840012239,
  },
  {
    name: "South Vacherie",
    state_id: "LA",
    lat: 29.9406,
    lng: -90.6891,
    id: 1840031153,
  },
  {
    name: "Ladonia",
    state_id: "AL",
    lat: 32.4609,
    lng: -85.0877,
    id: 1840013828,
  },
  {
    name: "Tipton",
    state_id: "MO",
    lat: 38.6549,
    lng: -92.7803,
    id: 1840010724,
  },
  {
    name: "Leavenworth",
    state_id: "WA",
    lat: 47.5948,
    lng: -120.6628,
    id: 1840019778,
  },
  {
    name: "Hominy",
    state_id: "OK",
    lat: 36.422,
    lng: -96.3929,
    id: 1840020338,
  },
  {
    name: "Tuckerton",
    state_id: "NJ",
    lat: 39.5974,
    lng: -74.3306,
    id: 1840001495,
  },
  {
    name: "Frontenac",
    state_id: "KS",
    lat: 37.4583,
    lng: -94.7018,
    id: 1840001695,
  },
  {
    name: "Pantops",
    state_id: "VA",
    lat: 38.0308,
    lng: -78.4444,
    id: 1840037472,
  },
  {
    name: "Nyssa",
    state_id: "OR",
    lat: 43.8776,
    lng: -116.9951,
    id: 1840020001,
  },
  {
    name: "Vandenberg AFB",
    state_id: "CA",
    lat: 34.7483,
    lng: -120.5182,
    id: 1840074669,
  },
  {
    name: "Clyde Hill",
    state_id: "WA",
    lat: 47.6303,
    lng: -122.218,
    id: 1840018413,
  },
  {
    name: "Crosby",
    state_id: "MN",
    lat: 46.4892,
    lng: -93.9573,
    id: 1840006640,
  },
  {
    name: "Crisfield",
    state_id: "MD",
    lat: 37.9827,
    lng: -75.8516,
    id: 1840006278,
  },
  {
    name: "Dewey",
    state_id: "OK",
    lat: 36.7915,
    lng: -95.9328,
    id: 1840018978,
  },
  {
    name: "Flat Rock",
    state_id: "NC",
    lat: 35.2677,
    lng: -82.4526,
    id: 1840017857,
  },
  {
    name: "Old Mystic",
    state_id: "CT",
    lat: 41.3854,
    lng: -71.985,
    id: 1840003352,
  },
  {
    name: "Karnes City",
    state_id: "TX",
    lat: 28.8857,
    lng: -97.9001,
    id: 1840019683,
  },
  {
    name: "Coronita",
    state_id: "CA",
    lat: 33.876,
    lng: -117.6109,
    id: 1840075705,
  },
  {
    name: "Sylvan Beach",
    state_id: "NY",
    lat: 43.2061,
    lng: -75.7234,
    id: 1840004213,
  },
  {
    name: "Mountain View",
    state_id: "HI",
    lat: 19.5358,
    lng: -155.1596,
    id: 1840029529,
  },
  {
    name: "Keego Harbor",
    state_id: "MI",
    lat: 42.6088,
    lng: -83.3443,
    id: 1840002446,
  },
  {
    name: "McChord AFB",
    state_id: "WA",
    lat: 47.1328,
    lng: -122.4924,
    id: 1840073929,
  },
  {
    name: "Alma",
    state_id: "GA",
    lat: 31.5432,
    lng: -82.4745,
    id: 1840013863,
  },
  {
    name: "Oakridge",
    state_id: "OR",
    lat: 43.745,
    lng: -122.4637,
    id: 1840020010,
  },
  {
    name: "Elizabethtown",
    state_id: "NC",
    lat: 34.6238,
    lng: -78.6097,
    id: 1840016564,
  },
  {
    name: "Cleves",
    state_id: "OH",
    lat: 39.1635,
    lng: -84.7469,
    id: 1840010672,
  },
  {
    name: "Pine Hills",
    state_id: "CA",
    lat: 40.7313,
    lng: -124.1551,
    id: 1840028396,
  },
  {
    name: "Glasgow",
    state_id: "MT",
    lat: 48.199,
    lng: -106.6321,
    id: 1840019754,
  },
  {
    name: "Waldron",
    state_id: "AR",
    lat: 34.902,
    lng: -94.0953,
    id: 1840015500,
  },
  {
    name: "Windsor",
    state_id: "NC",
    lat: 35.9927,
    lng: -76.9399,
    id: 1840016150,
  },
  {
    name: "Grand Mound",
    state_id: "WA",
    lat: 46.8053,
    lng: -123.0119,
    id: 1840017366,
  },
  {
    name: "Churchville",
    state_id: "NY",
    lat: 43.1036,
    lng: -77.8824,
    id: 1840004288,
  },
  {
    name: "Casa Conejo",
    state_id: "CA",
    lat: 34.1847,
    lng: -118.9445,
    id: 1840017912,
  },
  {
    name: "Georgetown",
    state_id: "IN",
    lat: 38.2958,
    lng: -85.9628,
    id: 1840010813,
  },
  {
    name: "Arcadia",
    state_id: "SC",
    lat: 34.9609,
    lng: -81.9929,
    id: 1840026955,
  },
  {
    name: "Skidway Lake",
    state_id: "MI",
    lat: 44.1919,
    lng: -84.0467,
    id: 1840004096,
  },
  {
    name: "Mar-Mac",
    state_id: "NC",
    lat: 35.3345,
    lng: -78.0545,
    id: 1840033018,
  },
  {
    name: "Loyola",
    state_id: "CA",
    lat: 37.3502,
    lng: -122.098,
    id: 1840028385,
  },
  {
    name: "Big Lake",
    state_id: "TX",
    lat: 31.1941,
    lng: -101.4534,
    id: 1840019543,
  },
  {
    name: "Shady Spring",
    state_id: "WV",
    lat: 37.7032,
    lng: -81.0912,
    id: 1840006348,
  },
  {
    name: "Paris",
    state_id: "AR",
    lat: 35.2894,
    lng: -93.7253,
    id: 1840014586,
  },
  {
    name: "North Yelm",
    state_id: "WA",
    lat: 46.9648,
    lng: -122.603,
    id: 1840037880,
  },
  {
    name: "Jefferson",
    state_id: "OR",
    lat: 44.7171,
    lng: -123.0069,
    id: 1840018580,
  },
  {
    name: "Culloden",
    state_id: "WV",
    lat: 38.4159,
    lng: -82.0722,
    id: 1840006210,
  },
  {
    name: "Tiger Point",
    state_id: "FL",
    lat: 30.3775,
    lng: -87.0578,
    id: 1840029101,
  },
  {
    name: "Port Monmouth",
    state_id: "NJ",
    lat: 40.4337,
    lng: -74.101,
    id: 1840005447,
  },
  {
    name: "Eastlawn Gardens",
    state_id: "PA",
    lat: 40.748,
    lng: -75.2913,
    id: 1840035420,
  },
  {
    name: "Cactus",
    state_id: "TX",
    lat: 36.0444,
    lng: -102.008,
    id: 1840019085,
  },
  {
    name: "Bethel",
    state_id: "OH",
    lat: 38.9627,
    lng: -84.0846,
    id: 1840012705,
  },
  {
    name: "Burbank",
    state_id: "WA",
    lat: 46.1991,
    lng: -118.9728,
    id: 1840017383,
  },
  {
    name: "Manila",
    state_id: "AR",
    lat: 35.8848,
    lng: -90.1653,
    id: 1840015363,
  },
  {
    name: "Kennedy",
    state_id: "CA",
    lat: 37.929,
    lng: -121.2457,
    id: 1840028346,
  },
  {
    name: "Ahtanum",
    state_id: "WA",
    lat: 46.5583,
    lng: -120.611,
    id: 1840017368,
  },
  {
    name: "Sleepy Eye",
    state_id: "MN",
    lat: 44.2988,
    lng: -94.7235,
    id: 1840008970,
  },
  {
    name: "Ely",
    state_id: "MN",
    lat: 47.9054,
    lng: -91.8517,
    id: 1840007686,
  },
  {
    name: "Nokomis",
    state_id: "FL",
    lat: 27.1227,
    lng: -82.437,
    id: 1840014185,
  },
  {
    name: "Delmar",
    state_id: "MD",
    lat: 38.4451,
    lng: -75.5662,
    id: 1840006220,
  },
  {
    name: "Mattapoisett Center",
    state_id: "MA",
    lat: 41.6667,
    lng: -70.8054,
    id: 1840073510,
  },
  {
    name: "Hartford",
    state_id: "SD",
    lat: 43.621,
    lng: -96.9424,
    id: 1840002653,
  },
  {
    name: "Experiment",
    state_id: "GA",
    lat: 33.2789,
    lng: -84.276,
    id: 1840013099,
  },
  {
    name: "Cornville",
    state_id: "AZ",
    lat: 34.7389,
    lng: -111.9086,
    id: 1840017842,
  },
  {
    name: "Elma",
    state_id: "WA",
    lat: 47.0055,
    lng: -123.4112,
    id: 1840019844,
  },
  {
    name: "North Lakeport",
    state_id: "CA",
    lat: 39.0883,
    lng: -122.9054,
    id: 1840037120,
  },
  {
    name: "Houghton Lake",
    state_id: "MI",
    lat: 44.3124,
    lng: -84.7625,
    id: 1840004091,
  },
  {
    name: "Loíza",
    state_id: "PR",
    lat: 18.431,
    lng: -65.88,
    id: 1630035508,
  },
  {
    name: "Lincoln Heights",
    state_id: "OH",
    lat: 39.2434,
    lng: -84.457,
    id: 1840003822,
  },
  {
    name: "Northlake",
    state_id: "TX",
    lat: 33.0802,
    lng: -97.2545,
    id: 1840022989,
  },
  {
    name: "Pixley",
    state_id: "CA",
    lat: 35.9769,
    lng: -119.2889,
    id: 1840019036,
  },
  {
    name: "Lake Placid",
    state_id: "NY",
    lat: 44.2838,
    lng: -73.9855,
    id: 1840004089,
  },
  {
    name: "Howe",
    state_id: "TX",
    lat: 33.5066,
    lng: -96.6155,
    id: 1840021960,
  },
  {
    name: "Reno",
    state_id: "TX",
    lat: 33.6659,
    lng: -95.4768,
    id: 1840020592,
  },
  {
    name: "Shallowater",
    state_id: "TX",
    lat: 33.6901,
    lng: -101.9893,
    id: 1840021981,
  },
  {
    name: "Peshtigo",
    state_id: "WI",
    lat: 45.0556,
    lng: -87.7476,
    id: 1840003932,
  },
  {
    name: "Oak Valley",
    state_id: "NJ",
    lat: 39.8055,
    lng: -75.1589,
    id: 1840033371,
  },
  {
    name: "Manor",
    state_id: "PA",
    lat: 40.3459,
    lng: -79.6693,
    id: 1840001145,
  },
  {
    name: "Corunna",
    state_id: "MI",
    lat: 42.9838,
    lng: -84.1163,
    id: 1840003070,
  },
  {
    name: "Crestview Hills",
    state_id: "KY",
    lat: 39.0249,
    lng: -84.5695,
    id: 1840014265,
  },
  {
    name: "Hollywood Park",
    state_id: "TX",
    lat: 29.5994,
    lng: -98.4841,
    id: 1840022228,
  },
  {
    name: "Mansfield",
    state_id: "PA",
    lat: 41.8062,
    lng: -77.0787,
    id: 1840003301,
  },
  {
    name: "Kinsey",
    state_id: "AL",
    lat: 31.2926,
    lng: -85.3353,
    id: 1840017127,
  },
  {
    name: "Auburn",
    state_id: "NE",
    lat: 40.3894,
    lng: -95.8436,
    id: 1840007232,
  },
  {
    name: "Mayfield",
    state_id: "OH",
    lat: 41.5501,
    lng: -81.4369,
    id: 1840000612,
  },
  {
    name: "Schnecksville",
    state_id: "PA",
    lat: 40.6693,
    lng: -75.6116,
    id: 1840005374,
  },
  {
    name: "Fairchild AFB",
    state_id: "WA",
    lat: 47.6186,
    lng: -117.6484,
    id: 1840073916,
  },
  {
    name: "Mackinaw",
    state_id: "IL",
    lat: 40.5342,
    lng: -89.3551,
    id: 1840011989,
  },
  {
    name: "Dade City North",
    state_id: "FL",
    lat: 28.3838,
    lng: -82.1943,
    id: 1840073823,
  },
  {
    name: "Nicholls",
    state_id: "GA",
    lat: 31.5191,
    lng: -82.6386,
    id: 1840014947,
  },
  {
    name: "Waterloo",
    state_id: "WI",
    lat: 43.1835,
    lng: -88.9898,
    id: 1840002999,
  },
  {
    name: "Lake Holm",
    state_id: "WA",
    lat: 47.2986,
    lng: -122.1335,
    id: 1840041966,
  },
  {
    name: "Blennerhassett",
    state_id: "WV",
    lat: 39.2562,
    lng: -81.6316,
    id: 1840005781,
  },
  {
    name: "Kingwood",
    state_id: "WV",
    lat: 39.4719,
    lng: -79.6822,
    id: 1840005651,
  },
  {
    name: "Inwood",
    state_id: "WV",
    lat: 39.3538,
    lng: -78.0552,
    id: 1840005740,
  },
  {
    name: "Blackstone",
    state_id: "VA",
    lat: 37.0821,
    lng: -78.0029,
    id: 1840006460,
  },
  {
    name: "Hardwick",
    state_id: "GA",
    lat: 33.0523,
    lng: -83.244,
    id: 1840013785,
  },
  {
    name: "Lake California",
    state_id: "CA",
    lat: 40.3608,
    lng: -122.2102,
    id: 1840042932,
  },
  {
    name: "Pottsgrove",
    state_id: "PA",
    lat: 40.2644,
    lng: -75.6093,
    id: 1840035435,
  },
  {
    name: "Sherwood",
    state_id: "OH",
    lat: 39.0853,
    lng: -84.3608,
    id: 1840149141,
  },
  {
    name: "Collinsville",
    state_id: "CT",
    lat: 41.819,
    lng: -72.922,
    id: 1840004779,
  },
  {
    name: "Pottsville",
    state_id: "AR",
    lat: 35.2314,
    lng: -93.0652,
    id: 1840014540,
  },
  {
    name: "Carthage",
    state_id: "TN",
    lat: 36.2566,
    lng: -85.943,
    id: 1840016096,
  },
  {
    name: "Hemlock Farms",
    state_id: "PA",
    lat: 41.3157,
    lng: -75.0515,
    id: 1840035012,
  },
  {
    name: "Upland",
    state_id: "PA",
    lat: 39.8562,
    lng: -75.3795,
    id: 1840000714,
  },
  {
    name: "Bajadero",
    state_id: "PR",
    lat: 18.4235,
    lng: -66.677,
    id: 1630035615,
  },
  {
    name: "Bainbridge",
    state_id: "OH",
    lat: 41.3944,
    lng: -81.3366,
    id: 1840152715,
  },
  {
    name: "Laurel Lake",
    state_id: "NJ",
    lat: 39.3265,
    lng: -75.0306,
    id: 1840005752,
  },
  {
    name: "Kamas",
    state_id: "UT",
    lat: 40.6499,
    lng: -111.2723,
    id: 1840018726,
  },
  {
    name: "Winchester",
    state_id: "CA",
    lat: 33.7146,
    lng: -117.0775,
    id: 1840019295,
  },
  {
    name: "Barron",
    state_id: "WI",
    lat: 45.4015,
    lng: -91.8476,
    id: 1840002069,
  },
  {
    name: "Story City",
    state_id: "IA",
    lat: 42.1866,
    lng: -93.5864,
    id: 1840009175,
  },
  {
    name: "Graniteville",
    state_id: "SC",
    lat: 33.5646,
    lng: -81.8079,
    id: 1840026989,
  },
  {
    name: "Desert Edge",
    state_id: "CA",
    lat: 33.9223,
    lng: -116.4401,
    id: 1840022907,
  },
  {
    name: "Sharpsburg",
    state_id: "PA",
    lat: 40.494,
    lng: -79.9251,
    id: 1840001262,
  },
  {
    name: "Cherryvale",
    state_id: "SC",
    lat: 33.9543,
    lng: -80.4609,
    id: 1840013667,
  },
  {
    name: "Chattahoochee Hills",
    state_id: "GA",
    lat: 33.5695,
    lng: -84.7441,
    id: 1840022892,
  },
  {
    name: "Chandler",
    state_id: "IN",
    lat: 38.0368,
    lng: -87.3747,
    id: 1840009835,
  },
  {
    name: "Ellsworth",
    state_id: "WI",
    lat: 44.7364,
    lng: -92.4806,
    id: 1840002283,
  },
  {
    name: "Alamo",
    state_id: "GA",
    lat: 32.1469,
    lng: -82.7798,
    id: 1840015824,
  },
  {
    name: "South Nyack",
    state_id: "NY",
    lat: 41.0798,
    lng: -73.9127,
    id: 1840004985,
  },
  {
    name: "Fulton",
    state_id: "IL",
    lat: 41.865,
    lng: -90.1593,
    id: 1840008148,
  },
  {
    name: "Port Ewen",
    state_id: "NY",
    lat: 41.9048,
    lng: -73.9776,
    id: 1840004711,
  },
  {
    name: "Salem Heights",
    state_id: "OH",
    lat: 39.0658,
    lng: -84.384,
    id: 1840034161,
  },
  {
    name: "New Baden",
    state_id: "IL",
    lat: 38.5368,
    lng: -89.7071,
    id: 1840012858,
  },
  {
    name: "Edcouch",
    state_id: "TX",
    lat: 26.2937,
    lng: -97.9631,
    id: 1840021019,
  },
  {
    name: "Patillas",
    state_id: "PR",
    lat: 18.0069,
    lng: -66.0134,
    id: 1630023610,
  },
  {
    name: "Chesnee",
    state_id: "SC",
    lat: 35.1459,
    lng: -81.8627,
    id: 1840014614,
  },
  {
    name: "Pleasant Gap",
    state_id: "PA",
    lat: 40.8673,
    lng: -77.7439,
    id: 1840005161,
  },
  {
    name: "Islandia",
    state_id: "NY",
    lat: 40.8068,
    lng: -73.1711,
    id: 1840005120,
  },
  {
    name: "Etna",
    state_id: "PA",
    lat: 40.4975,
    lng: -79.9476,
    id: 1840001231,
  },
  {
    name: "Waialua",
    state_id: "HI",
    lat: 21.566,
    lng: -158.1198,
    id: 1840023255,
  },
  {
    name: "Ponce Inlet",
    state_id: "FL",
    lat: 29.0927,
    lng: -80.9473,
    id: 1840017227,
  },
  {
    name: "Lake Junaluska",
    state_id: "NC",
    lat: 35.5305,
    lng: -82.9749,
    id: 1840013416,
  },
  {
    name: "Taylor Creek",
    state_id: "OH",
    lat: 39.2364,
    lng: -84.6796,
    id: 1840042385,
  },
  {
    name: "Wellsville",
    state_id: "OH",
    lat: 40.6042,
    lng: -80.655,
    id: 1840002784,
  },
  {
    name: "Stanhope",
    state_id: "NJ",
    lat: 40.9141,
    lng: -74.7027,
    id: 1840000788,
  },
  {
    name: "Greensboro",
    state_id: "GA",
    lat: 33.5678,
    lng: -83.1878,
    id: 1840013744,
  },
  {
    name: "Goshen",
    state_id: "CA",
    lat: 36.3518,
    lng: -119.421,
    id: 1840017693,
  },
  {
    name: "Moreland Hills",
    state_id: "OH",
    lat: 41.4422,
    lng: -81.4294,
    id: 1840000615,
  },
  {
    name: "Manistique",
    state_id: "MI",
    lat: 45.9591,
    lng: -86.2507,
    id: 1840001966,
  },
  {
    name: "Spring City",
    state_id: "PA",
    lat: 40.1768,
    lng: -75.5466,
    id: 1840001435,
  },
  {
    name: "Santo Domingo",
    state_id: "PR",
    lat: 18.0722,
    lng: -66.7458,
    id: 1630035582,
  },
  {
    name: "Elma Center",
    state_id: "NY",
    lat: 42.8282,
    lng: -78.6342,
    id: 1840004372,
  },
  {
    name: "Long Prairie",
    state_id: "MN",
    lat: 45.9775,
    lng: -94.8629,
    id: 1840008859,
  },
  {
    name: "Haviland",
    state_id: "NY",
    lat: 41.7682,
    lng: -73.9007,
    id: 1840034051,
  },
  {
    name: "Winnsboro",
    state_id: "TX",
    lat: 32.9557,
    lng: -95.2909,
    id: 1840022037,
  },
  {
    name: "Shark River Hills",
    state_id: "NJ",
    lat: 40.1923,
    lng: -74.0463,
    id: 1840005450,
  },
  {
    name: "Plainview",
    state_id: "MN",
    lat: 44.1648,
    lng: -92.1697,
    id: 1840008973,
  },
  {
    name: "Paloma Creek",
    state_id: "TX",
    lat: 33.2253,
    lng: -96.9371,
    id: 1840041379,
  },
  {
    name: "Melbourne Beach",
    state_id: "FL",
    lat: 28.0668,
    lng: -80.561,
    id: 1840017233,
  },
  {
    name: "Balcones Heights",
    state_id: "TX",
    lat: 29.49,
    lng: -98.5503,
    id: 1840019654,
  },
  {
    name: "Haughton",
    state_id: "LA",
    lat: 32.5271,
    lng: -93.5042,
    id: 1840015770,
  },
  {
    name: "Grantville",
    state_id: "GA",
    lat: 33.2371,
    lng: -84.8293,
    id: 1840013760,
  },
  {
    name: "El Verano",
    state_id: "CA",
    lat: 38.2975,
    lng: -122.4915,
    id: 1840017573,
  },
  {
    name: "Cloverdale",
    state_id: "VA",
    lat: 37.3566,
    lng: -79.9055,
    id: 1840006375,
  },
  {
    name: "Pretty Bayou",
    state_id: "FL",
    lat: 30.1976,
    lng: -85.6975,
    id: 1840029069,
  },
  {
    name: "Littleton Common",
    state_id: "MA",
    lat: 42.5352,
    lng: -71.4727,
    id: 1840003139,
  },
  {
    name: "Hillsboro",
    state_id: "MO",
    lat: 38.233,
    lng: -90.5671,
    id: 1840007508,
  },
  {
    name: "Newbern",
    state_id: "TN",
    lat: 36.1169,
    lng: -89.2713,
    id: 1840016161,
  },
  {
    name: "Cuba",
    state_id: "MO",
    lat: 38.0671,
    lng: -91.4057,
    id: 1840007531,
  },
  {
    name: "Pagedale",
    state_id: "MO",
    lat: 38.6802,
    lng: -90.308,
    id: 1840009745,
  },
  {
    name: "Lindsborg",
    state_id: "KS",
    lat: 38.5773,
    lng: -97.6738,
    id: 1840001658,
  },
  {
    name: "Orange",
    state_id: "OH",
    lat: 41.4415,
    lng: -81.4743,
    id: 1840003405,
  },
  {
    name: "Carthage",
    state_id: "NY",
    lat: 43.9837,
    lng: -75.6021,
    id: 1840004125,
  },
  {
    name: "Citrus City",
    state_id: "TX",
    lat: 26.3281,
    lng: -98.3923,
    id: 1840023200,
  },
  {
    name: "Springdale",
    state_id: "PA",
    lat: 40.5409,
    lng: -79.782,
    id: 1840001263,
  },
  {
    name: "Medina",
    state_id: "WA",
    lat: 47.6273,
    lng: -122.2427,
    id: 1840019829,
  },
  {
    name: "Blue Ridge",
    state_id: "VA",
    lat: 37.3783,
    lng: -79.8206,
    id: 1840006374,
  },
  {
    name: "West Lake Hills",
    state_id: "TX",
    lat: 30.292,
    lng: -97.8083,
    id: 1840022180,
  },
  {
    name: "Seacliff",
    state_id: "CA",
    lat: 36.977,
    lng: -121.9176,
    id: 1840028410,
  },
  {
    name: "Pendleton",
    state_id: "SC",
    lat: 34.6454,
    lng: -82.7821,
    id: 1840016578,
  },
  {
    name: "Ohioville",
    state_id: "PA",
    lat: 40.6872,
    lng: -80.4783,
    id: 1840001028,
  },
  {
    name: "West Point",
    state_id: "NE",
    lat: 41.8378,
    lng: -96.7062,
    id: 1840003222,
  },
  {
    name: "Lincoln Park",
    state_id: "CO",
    lat: 38.4255,
    lng: -105.2132,
    id: 1840018853,
  },
  {
    name: "Lakeland",
    state_id: "GA",
    lat: 31.041,
    lng: -83.0748,
    id: 1840012921,
  },
  {
    name: "Mount Oliver",
    state_id: "PA",
    lat: 40.4113,
    lng: -79.9856,
    id: 1840001246,
  },
  {
    name: "Ainaloa",
    state_id: "HI",
    lat: 19.5214,
    lng: -154.9944,
    id: 1840029568,
  },
  {
    name: "Tybee Island",
    state_id: "GA",
    lat: 32.01,
    lng: -80.8527,
    id: 1840015831,
  },
  {
    name: "Essexville",
    state_id: "MI",
    lat: 43.6123,
    lng: -83.8423,
    id: 1840002584,
  },
  {
    name: "Pine Grove",
    state_id: "PA",
    lat: 40.5534,
    lng: -76.386,
    id: 1840002772,
  },
  {
    name: "Lagrange",
    state_id: "IN",
    lat: 41.6481,
    lng: -85.4181,
    id: 1840013851,
  },
  {
    name: "Sonterra",
    state_id: "TX",
    lat: 30.8081,
    lng: -97.5953,
    id: 1840033584,
  },
  {
    name: "Grand Point",
    state_id: "LA",
    lat: 30.0451,
    lng: -90.7488,
    id: 1840025127,
  },
  {
    name: "Summersville",
    state_id: "WV",
    lat: 38.2865,
    lng: -80.841,
    id: 1840006227,
  },
  {
    name: "West End-Cobb Town",
    state_id: "AL",
    lat: 33.6526,
    lng: -85.8742,
    id: 1840073855,
  },
  {
    name: "Dundee",
    state_id: "OR",
    lat: 45.2759,
    lng: -123.0072,
    id: 1840018575,
  },
  {
    name: "Grand Saline",
    state_id: "TX",
    lat: 32.6776,
    lng: -95.7114,
    id: 1840020915,
  },
  {
    name: "Howards Grove",
    state_id: "WI",
    lat: 43.8261,
    lng: -87.8232,
    id: 1840002619,
  },
  {
    name: "Esparto",
    state_id: "CA",
    lat: 38.6934,
    lng: -122.0241,
    id: 1840017569,
  },
  {
    name: "Jones Creek",
    state_id: "TX",
    lat: 28.9752,
    lng: -95.4714,
    id: 1840023163,
  },
  {
    name: "Montrose",
    state_id: "NY",
    lat: 41.2455,
    lng: -73.9376,
    id: 1840024180,
  },
  {
    name: "White Pine",
    state_id: "TN",
    lat: 36.1087,
    lng: -83.297,
    id: 1840016169,
  },
  {
    name: "Marysville",
    state_id: "KS",
    lat: 39.8431,
    lng: -96.6386,
    id: 1840003768,
  },
  {
    name: "Hahnville",
    state_id: "LA",
    lat: 29.9641,
    lng: -90.4141,
    id: 1840013974,
  },
  {
    name: "Agua Dulce",
    state_id: "TX",
    lat: 31.6549,
    lng: -106.1369,
    id: 1840142999,
  },
  {
    name: "Baldwyn",
    state_id: "MS",
    lat: 34.5071,
    lng: -88.6416,
    id: 1840013590,
  },
  {
    name: "Mauriceville",
    state_id: "TX",
    lat: 30.2187,
    lng: -93.8758,
    id: 1840018246,
  },
  {
    name: "Blissfield",
    state_id: "MI",
    lat: 41.8311,
    lng: -83.8635,
    id: 1840011371,
  },
  {
    name: "Crockett",
    state_id: "CA",
    lat: 38.0519,
    lng: -122.2201,
    id: 1840017608,
  },
  {
    name: "Carrollton",
    state_id: "OH",
    lat: 40.5788,
    lng: -81.0907,
    id: 1840010383,
  },
  {
    name: "North Wales",
    state_id: "PA",
    lat: 40.2111,
    lng: -75.2744,
    id: 1840001371,
  },
  {
    name: "Somerville",
    state_id: "TN",
    lat: 35.2352,
    lng: -89.3869,
    id: 1840017868,
  },
  {
    name: "Rural Hall",
    state_id: "NC",
    lat: 36.2251,
    lng: -80.2973,
    id: 1840017727,
  },
  {
    name: "Hilliard",
    state_id: "FL",
    lat: 30.6865,
    lng: -81.9212,
    id: 1840017180,
  },
  {
    name: "Leisuretowne",
    state_id: "NJ",
    lat: 39.8987,
    lng: -74.7052,
    id: 1840005521,
  },
  {
    name: "Pomona",
    state_id: "NY",
    lat: 41.1892,
    lng: -74.0543,
    id: 1840004983,
  },
  {
    name: "Denver",
    state_id: "NC",
    lat: 35.5344,
    lng: -81.034,
    id: 1840024951,
  },
  {
    name: "Livingston",
    state_id: "AL",
    lat: 32.5982,
    lng: -88.1891,
    id: 1840005153,
  },
  {
    name: "Sugar Creek",
    state_id: "MO",
    lat: 39.1421,
    lng: -94.4018,
    id: 1840009680,
  },
  {
    name: "Lordstown",
    state_id: "OH",
    lat: 41.1684,
    lng: -80.859,
    id: 1840000651,
  },
  {
    name: "Cambrian Park",
    state_id: "CA",
    lat: 37.2563,
    lng: -121.9287,
    id: 1840017639,
  },
  {
    name: "Philippi",
    state_id: "WV",
    lat: 39.1487,
    lng: -80.0418,
    id: 1840005883,
  },
  {
    name: "Spencer",
    state_id: "NC",
    lat: 35.6989,
    lng: -80.4248,
    id: 1840017787,
  },
  {
    name: "West Point",
    state_id: "VA",
    lat: 37.5519,
    lng: -76.8017,
    id: 1840006367,
  },
  {
    name: "Chickamauga",
    state_id: "GA",
    lat: 34.8753,
    lng: -85.2886,
    id: 1840014654,
  },
  {
    name: "Spearman",
    state_id: "TX",
    lat: 36.1983,
    lng: -101.1944,
    id: 1840021668,
  },
  {
    name: "West Wyomissing",
    state_id: "PA",
    lat: 40.3221,
    lng: -75.9953,
    id: 1840035256,
  },
  {
    name: "Berwyn Heights",
    state_id: "MD",
    lat: 38.9929,
    lng: -76.9131,
    id: 1840005980,
  },
  {
    name: "Water Valley",
    state_id: "MS",
    lat: 34.1622,
    lng: -89.6301,
    id: 1840015606,
  },
  {
    name: "Newburgh",
    state_id: "IN",
    lat: 37.9494,
    lng: -87.4053,
    id: 1840014684,
  },
  {
    name: "Myerstown",
    state_id: "PA",
    lat: 40.3722,
    lng: -76.3055,
    id: 1840001349,
  },
  {
    name: "Nesquehoning",
    state_id: "PA",
    lat: 40.8659,
    lng: -75.8322,
    id: 1840000934,
  },
  {
    name: "North Shore",
    state_id: "VA",
    lat: 37.0778,
    lng: -79.6518,
    id: 1840006474,
  },
  {
    name: "Bad Axe",
    state_id: "MI",
    lat: 43.8036,
    lng: -82.9981,
    id: 1840003951,
  },
  {
    name: "Williams",
    state_id: "AZ",
    lat: 35.2476,
    lng: -112.1833,
    id: 1840021585,
  },
  {
    name: "Annetta",
    state_id: "TX",
    lat: 32.6938,
    lng: -97.6581,
    id: 1840022043,
  },
  {
    name: "Cabán",
    state_id: "PR",
    lat: 18.4448,
    lng: -67.1357,
    id: 1630035478,
  },
  {
    name: "Franklin",
    state_id: "MI",
    lat: 42.5187,
    lng: -83.303,
    id: 1840011057,
  },
  {
    name: "Dawsonville",
    state_id: "GA",
    lat: 34.4362,
    lng: -84.125,
    id: 1840014677,
  },
  {
    name: "Four Bridges",
    state_id: "OH",
    lat: 39.3828,
    lng: -84.3542,
    id: 1840040315,
  },
  {
    name: "Kotzebue",
    state_id: "AK",
    lat: 66.8766,
    lng: -162.5231,
    id: 1840023494,
  },
  {
    name: "Grangeville",
    state_id: "ID",
    lat: 45.926,
    lng: -116.1225,
    id: 1840019893,
  },
  {
    name: "Sleepy Hollow",
    state_id: "IL",
    lat: 42.0913,
    lng: -88.3137,
    id: 1840011353,
  },
  {
    name: "Cottonwood",
    state_id: "CA",
    lat: 40.3904,
    lng: -122.2861,
    id: 1840017489,
  },
  {
    name: "Yerington",
    state_id: "NV",
    lat: 38.9554,
    lng: -119.1101,
    id: 1840021443,
  },
  {
    name: "Uniontown",
    state_id: "OH",
    lat: 40.9736,
    lng: -81.4045,
    id: 1840005202,
  },
  {
    name: "Moulton",
    state_id: "AL",
    lat: 34.4843,
    lng: -87.2831,
    id: 1840008190,
  },
  {
    name: "Monmouth Beach",
    state_id: "NJ",
    lat: 40.3364,
    lng: -73.9863,
    id: 1840001364,
  },
  {
    name: "Locust",
    state_id: "NC",
    lat: 35.2687,
    lng: -80.4368,
    id: 1840015437,
  },
  {
    name: "Tipton",
    state_id: "CA",
    lat: 36.0585,
    lng: -119.313,
    id: 1840019039,
  },
  {
    name: "Bay Pines",
    state_id: "FL",
    lat: 27.8139,
    lng: -82.7747,
    id: 1840014141,
  },
  {
    name: "Garnett",
    state_id: "KS",
    lat: 38.2845,
    lng: -95.2408,
    id: 1840001666,
  },
  {
    name: "Kemmerer",
    state_id: "WY",
    lat: 41.7761,
    lng: -110.5555,
    id: 1840018662,
  },
  {
    name: "Vinton",
    state_id: "LA",
    lat: 30.1957,
    lng: -93.5818,
    id: 1840017195,
  },
  {
    name: "Greenbriar",
    state_id: "FL",
    lat: 28.0112,
    lng: -82.7523,
    id: 1840038903,
  },
  {
    name: "Iowa Colony",
    state_id: "TX",
    lat: 29.4405,
    lng: -95.4168,
    id: 1840023162,
  },
  {
    name: "Hauula",
    state_id: "HI",
    lat: 21.6111,
    lng: -157.9118,
    id: 1840029479,
  },
  {
    name: "Verona",
    state_id: "MS",
    lat: 34.1907,
    lng: -88.7203,
    id: 1840015562,
  },
  {
    name: "Wimberley",
    state_id: "TX",
    lat: 29.9849,
    lng: -98.0906,
    id: 1840022190,
  },
  {
    name: "Enochville",
    state_id: "NC",
    lat: 35.5205,
    lng: -80.6646,
    id: 1840013069,
  },
  {
    name: "Port Angeles East",
    state_id: "WA",
    lat: 48.1041,
    lng: -123.3695,
    id: 1840074570,
  },
  {
    name: "Russell",
    state_id: "KY",
    lat: 38.5116,
    lng: -82.6996,
    id: 1840014275,
  },
  {
    name: "Norris",
    state_id: "TN",
    lat: 36.2136,
    lng: -84.0629,
    id: 1840014472,
  },
  {
    name: "Eielson AFB",
    state_id: "AK",
    lat: 64.6741,
    lng: -147.0605,
    id: 1840037568,
  },
  {
    name: "Silt",
    state_id: "CO",
    lat: 39.5483,
    lng: -107.6532,
    id: 1840022432,
  },
  {
    name: "Wellsboro",
    state_id: "PA",
    lat: 41.7459,
    lng: -77.3031,
    id: 1840003304,
  },
  {
    name: "Welsh",
    state_id: "LA",
    lat: 30.2355,
    lng: -92.8135,
    id: 1840017198,
  },
  {
    name: "Bedford Hills",
    state_id: "NY",
    lat: 41.2362,
    lng: -73.6959,
    id: 1840024178,
  },
  {
    name: "Linwood",
    state_id: "PA",
    lat: 39.8244,
    lng: -75.4244,
    id: 1840035067,
  },
  {
    name: "Wewoka",
    state_id: "OK",
    lat: 35.1441,
    lng: -96.4967,
    id: 1840021777,
  },
  {
    name: "West Orange",
    state_id: "TX",
    lat: 30.0791,
    lng: -93.7598,
    id: 1840022194,
  },
  {
    name: "Tipton",
    state_id: "IA",
    lat: 41.7701,
    lng: -91.1283,
    id: 1840010173,
  },
  {
    name: "Mount Morris",
    state_id: "IL",
    lat: 42.0484,
    lng: -89.4293,
    id: 1840011238,
  },
  {
    name: "Sharpes",
    state_id: "FL",
    lat: 28.4368,
    lng: -80.7555,
    id: 1840014075,
  },
  {
    name: "Hinton",
    state_id: "OK",
    lat: 35.4793,
    lng: -98.359,
    id: 1840021768,
  },
  {
    name: "Port Hadlock-Irondale",
    state_id: "WA",
    lat: 48.0316,
    lng: -122.789,
    id: 1840074571,
  },
  {
    name: "Rush City",
    state_id: "MN",
    lat: 45.6875,
    lng: -92.9654,
    id: 1840000270,
  },
  {
    name: "Canterwood",
    state_id: "WA",
    lat: 47.3766,
    lng: -122.6022,
    id: 1840037842,
  },
  {
    name: "Coquí",
    state_id: "PR",
    lat: 17.9793,
    lng: -66.2255,
    id: 1630035652,
  },
  {
    name: "Pistakee Highlands",
    state_id: "IL",
    lat: 42.4023,
    lng: -88.2115,
    id: 1840004635,
  },
  {
    name: "Watergate",
    state_id: "FL",
    lat: 26.3359,
    lng: -80.2126,
    id: 1840042907,
  },
  {
    name: "East Dundee",
    state_id: "IL",
    lat: 42.0969,
    lng: -88.2545,
    id: 1840011346,
  },
  {
    name: "Fairfield",
    state_id: "TX",
    lat: 31.7184,
    lng: -96.1697,
    id: 1840020803,
  },
  {
    name: "Coplay",
    state_id: "PA",
    lat: 40.671,
    lng: -75.4961,
    id: 1840001047,
  },
  {
    name: "Warm Springs",
    state_id: "OR",
    lat: 44.7669,
    lng: -121.2899,
    id: 1840018602,
  },
  {
    name: "West Portsmouth",
    state_id: "OH",
    lat: 38.7622,
    lng: -83.0416,
    id: 1840007421,
  },
  {
    name: "Three Rivers",
    state_id: "OR",
    lat: 43.8366,
    lng: -121.4646,
    id: 1840034834,
  },
  {
    name: "Orchard City",
    state_id: "CO",
    lat: 38.8144,
    lng: -107.9705,
    id: 1840022471,
  },
  {
    name: "New York Mills",
    state_id: "NY",
    lat: 43.1007,
    lng: -75.2932,
    id: 1840004207,
  },
  {
    name: "Warrior",
    state_id: "AL",
    lat: 33.8153,
    lng: -86.8153,
    id: 1840015674,
  },
  {
    name: "Wanakah",
    state_id: "NY",
    lat: 42.7434,
    lng: -78.9028,
    id: 1840024063,
  },
  {
    name: "Holton",
    state_id: "KS",
    lat: 39.4699,
    lng: -95.7319,
    id: 1840001542,
  },
  {
    name: "Lowesville",
    state_id: "NC",
    lat: 35.4193,
    lng: -80.9999,
    id: 1840013436,
  },
  {
    name: "Algona",
    state_id: "WA",
    lat: 47.2819,
    lng: -122.2504,
    id: 1840018415,
  },
  {
    name: "Louisiana",
    state_id: "MO",
    lat: 39.4413,
    lng: -91.0626,
    id: 1840009676,
  },
  {
    name: "Steeleville",
    state_id: "IL",
    lat: 38.0084,
    lng: -89.6624,
    id: 1840012940,
  },
  {
    name: "Wareham Center",
    state_id: "MA",
    lat: 41.751,
    lng: -70.7198,
    id: 1840031229,
  },
  {
    name: "Mingo Junction",
    state_id: "OH",
    lat: 40.3244,
    lng: -80.6174,
    id: 1840012124,
  },
  {
    name: "Colfax",
    state_id: "CA",
    lat: 39.0938,
    lng: -120.9533,
    id: 1840018818,
  },
  {
    name: "Williston",
    state_id: "FL",
    lat: 29.3734,
    lng: -82.4578,
    id: 1840015949,
  },
  {
    name: "Central Park",
    state_id: "WA",
    lat: 46.9705,
    lng: -123.7018,
    id: 1840017358,
  },
  {
    name: "Cumberland",
    state_id: "KY",
    lat: 36.9824,
    lng: -82.9919,
    id: 1840014403,
  },
  {
    name: "Bandon",
    state_id: "OR",
    lat: 43.1141,
    lng: -124.4157,
    id: 1840018652,
  },
  {
    name: "El Cerro",
    state_id: "NM",
    lat: 34.7807,
    lng: -106.6956,
    id: 1840024994,
  },
  {
    name: "Hoopa",
    state_id: "CA",
    lat: 41.0802,
    lng: -123.6972,
    id: 1840010406,
  },
  {
    name: "Millersburg",
    state_id: "OH",
    lat: 40.5534,
    lng: -81.9171,
    id: 1840012082,
  },
  {
    name: "Cutchogue",
    state_id: "NY",
    lat: 41.0167,
    lng: -72.4872,
    id: 1840005033,
  },
  {
    name: "Springdale",
    state_id: "MD",
    lat: 38.9383,
    lng: -76.8423,
    id: 1840031487,
  },
  {
    name: "Port Washington North",
    state_id: "NY",
    lat: 40.8434,
    lng: -73.7014,
    id: 1840005319,
  },
  {
    name: "Ingram",
    state_id: "PA",
    lat: 40.4449,
    lng: -80.0684,
    id: 1840001239,
  },
  {
    name: "Lake Panasoffkee",
    state_id: "FL",
    lat: 28.7878,
    lng: -82.1318,
    id: 1840014066,
  },
  {
    name: "Mannford",
    state_id: "OK",
    lat: 36.1298,
    lng: -96.3348,
    id: 1840022699,
  },
  {
    name: "Bethel Acres",
    state_id: "OK",
    lat: 35.3048,
    lng: -97.037,
    id: 1840020443,
  },
  {
    name: "Walkerton",
    state_id: "IN",
    lat: 41.4656,
    lng: -86.4825,
    id: 1840010197,
  },
  {
    name: "San Antonio Heights",
    state_id: "CA",
    lat: 34.1571,
    lng: -117.6592,
    id: 1840019113,
  },
  {
    name: "Springtown",
    state_id: "TX",
    lat: 32.9693,
    lng: -97.6803,
    id: 1840022040,
  },
  {
    name: "Bamberg",
    state_id: "SC",
    lat: 33.2995,
    lng: -81.0323,
    id: 1840015730,
  },
  {
    name: "Athens",
    state_id: "PA",
    lat: 41.9515,
    lng: -76.5186,
    id: 1840000510,
  },
  {
    name: "Lake Orion",
    state_id: "MI",
    lat: 42.7827,
    lng: -83.2455,
    id: 1840011054,
  },
  {
    name: "Burgettstown",
    state_id: "PA",
    lat: 40.3813,
    lng: -80.3926,
    id: 1840003641,
  },
  {
    name: "Chattanooga Valley",
    state_id: "GA",
    lat: 34.9241,
    lng: -85.3431,
    id: 1840029373,
  },
  {
    name: "Glenville",
    state_id: "WV",
    lat: 38.9379,
    lng: -80.8339,
    id: 1840005998,
  },
  {
    name: "Capitanejo",
    state_id: "PR",
    lat: 18.0132,
    lng: -66.5359,
    id: 1630035462,
  },
  {
    name: "Tallapoosa",
    state_id: "GA",
    lat: 33.7335,
    lng: -85.2859,
    id: 1840015662,
  },
  {
    name: "Beaver",
    state_id: "UT",
    lat: 38.2759,
    lng: -112.6383,
    id: 1840018857,
  },
  {
    name: "Superior",
    state_id: "AZ",
    lat: 33.2851,
    lng: -111.1109,
    id: 1840022981,
  },
  {
    name: "Malabar",
    state_id: "FL",
    lat: 27.9885,
    lng: -80.5781,
    id: 1840017232,
  },
  {
    name: "Georgetown",
    state_id: "IL",
    lat: 39.9774,
    lng: -87.6354,
    id: 1840008356,
  },
  {
    name: "South Hempstead",
    state_id: "NY",
    lat: 40.6814,
    lng: -73.6233,
    id: 1840005277,
  },
  {
    name: "Lutcher",
    state_id: "LA",
    lat: 30.0646,
    lng: -90.7124,
    id: 1840017208,
  },
  {
    name: "Elgin",
    state_id: "OK",
    lat: 34.7848,
    lng: -98.301,
    id: 1840020476,
  },
  {
    name: "Terra Bella",
    state_id: "CA",
    lat: 35.9567,
    lng: -119.0328,
    id: 1840019043,
  },
  {
    name: "Waynesville",
    state_id: "OH",
    lat: 39.5329,
    lng: -84.0903,
    id: 1840012617,
  },
  {
    name: "Sparta",
    state_id: "GA",
    lat: 33.2767,
    lng: -82.9704,
    id: 1840015725,
  },
  {
    name: "Chandler",
    state_id: "TX",
    lat: 32.3065,
    lng: -95.4781,
    id: 1840019499,
  },
  {
    name: "Laguna Vista",
    state_id: "TX",
    lat: 26.1075,
    lng: -97.2967,
    id: 1840022264,
  },
  {
    name: "North Middletown",
    state_id: "NJ",
    lat: 40.439,
    lng: -74.1187,
    id: 1840005444,
  },
  {
    name: "Jackson",
    state_id: "MN",
    lat: 43.6288,
    lng: -94.9886,
    id: 1840007942,
  },
  {
    name: "Angwin",
    state_id: "CA",
    lat: 38.5776,
    lng: -122.4513,
    id: 1840017570,
  },
  {
    name: "Macungie",
    state_id: "PA",
    lat: 40.5165,
    lng: -75.5545,
    id: 1840001050,
  },
  {
    name: "Loma Rica",
    state_id: "CA",
    lat: 39.3204,
    lng: -121.4038,
    id: 1840017544,
  },
  {
    name: "Colonial Heights",
    state_id: "TN",
    lat: 36.4852,
    lng: -82.507,
    id: 1840013063,
  },
  {
    name: "Acushnet Center",
    state_id: "MA",
    lat: 41.685,
    lng: -70.9065,
    id: 1840073388,
  },
  {
    name: "Frisco",
    state_id: "CO",
    lat: 39.5792,
    lng: -106.0916,
    id: 1840021430,
  },
  {
    name: "Hideaway",
    state_id: "TX",
    lat: 32.489,
    lng: -95.4578,
    id: 1840020741,
  },
  {
    name: "Red Oaks Mill",
    state_id: "NY",
    lat: 41.6522,
    lng: -73.8746,
    id: 1840004745,
  },
  {
    name: "Union City",
    state_id: "PA",
    lat: 41.897,
    lng: -79.8437,
    id: 1840000485,
  },
  {
    name: "Saddle River",
    state_id: "NJ",
    lat: 41.0273,
    lng: -74.0956,
    id: 1840000903,
  },
  {
    name: "San Saba",
    state_id: "TX",
    lat: 31.1965,
    lng: -98.7244,
    id: 1840023129,
  },
  {
    name: "Breckenridge",
    state_id: "MN",
    lat: 46.2662,
    lng: -96.585,
    id: 1840004003,
  },
  {
    name: "Ingalls Park",
    state_id: "IL",
    lat: 41.5203,
    lng: -88.0346,
    id: 1840004818,
  },
  {
    name: "Pickens",
    state_id: "SC",
    lat: 34.8857,
    lng: -82.7103,
    id: 1840014627,
  },
  {
    name: "Underwood-Petersville",
    state_id: "AL",
    lat: 34.877,
    lng: -87.6975,
    id: 1840073851,
  },
  {
    name: "Salmon",
    state_id: "ID",
    lat: 45.1743,
    lng: -113.8948,
    id: 1840021213,
  },
  {
    name: "Jones",
    state_id: "OK",
    lat: 35.5662,
    lng: -97.2901,
    id: 1840021746,
  },
  {
    name: "Cold Spring",
    state_id: "NY",
    lat: 41.4191,
    lng: -73.9545,
    id: 1840004894,
  },
  {
    name: "Tunkhannock",
    state_id: "PA",
    lat: 41.5413,
    lng: -75.9483,
    id: 1840000580,
  },
  {
    name: "Morada",
    state_id: "CA",
    lat: 38.0386,
    lng: -121.2456,
    id: 1840028389,
  },
  {
    name: "Madison",
    state_id: "OH",
    lat: 41.7722,
    lng: -81.053,
    id: 1840000538,
  },
  {
    name: "Zellwood",
    state_id: "FL",
    lat: 28.7301,
    lng: -81.5915,
    id: 1840014092,
  },
  {
    name: "Parowan",
    state_id: "UT",
    lat: 37.8326,
    lng: -112.8296,
    id: 1840020274,
  },
  {
    name: "Williamsburg",
    state_id: "IA",
    lat: 41.6683,
    lng: -92.0103,
    id: 1840010179,
  },
  {
    name: "Greentown",
    state_id: "IN",
    lat: 40.4766,
    lng: -85.9623,
    id: 1840010429,
  },
  {
    name: "Hidden Springs",
    state_id: "ID",
    lat: 43.7198,
    lng: -116.2512,
    id: 1840042421,
  },
  {
    name: "Amesti",
    state_id: "CA",
    lat: 36.9583,
    lng: -121.7805,
    id: 1840028334,
  },
  {
    name: "East Richmond Heights",
    state_id: "CA",
    lat: 37.945,
    lng: -122.314,
    id: 1840028372,
  },
  {
    name: "Oak Ridge North",
    state_id: "TX",
    lat: 30.157,
    lng: -95.4421,
    id: 1840020886,
  },
  {
    name: "Cadiz",
    state_id: "OH",
    lat: 40.2644,
    lng: -80.9981,
    id: 1840010445,
  },
  {
    name: "West Union",
    state_id: "OH",
    lat: 38.7917,
    lng: -83.5441,
    id: 1840012773,
  },
  {
    name: "North Brooksville",
    state_id: "FL",
    lat: 28.5731,
    lng: -82.3745,
    id: 1840029058,
  },
  {
    name: "El Cenizo",
    state_id: "TX",
    lat: 27.3318,
    lng: -99.5028,
    id: 1840021000,
  },
  {
    name: "South Eliot",
    state_id: "ME",
    lat: 43.1287,
    lng: -70.7952,
    id: 1840002673,
  },
  {
    name: "Early",
    state_id: "TX",
    lat: 31.745,
    lng: -98.9375,
    id: 1840019520,
  },
  {
    name: "Monte Grande",
    state_id: "PR",
    lat: 18.0895,
    lng: -67.1201,
    id: 1630023603,
  },
  {
    name: "Weaverville",
    state_id: "CA",
    lat: 40.7488,
    lng: -122.9274,
    id: 1840018721,
  },
  {
    name: "Pitcairn",
    state_id: "PA",
    lat: 40.4079,
    lng: -79.7765,
    id: 1840001253,
  },
  {
    name: "Kings Bay Base",
    state_id: "GA",
    lat: 30.7985,
    lng: -81.5628,
    id: 1840035817,
  },
  {
    name: "Galena",
    state_id: "IL",
    lat: 42.4218,
    lng: -90.4293,
    id: 1840008082,
  },
  {
    name: "Rockwood",
    state_id: "MI",
    lat: 42.0705,
    lng: -83.243,
    id: 1840003962,
  },
  {
    name: "Ladysmith",
    state_id: "WI",
    lat: 45.4606,
    lng: -91.0972,
    id: 1840002082,
  },
  {
    name: "Boscobel",
    state_id: "WI",
    lat: 43.1435,
    lng: -90.6951,
    id: 1840002972,
  },
  {
    name: "Dakota Dunes",
    state_id: "SD",
    lat: 42.4919,
    lng: -96.4867,
    id: 1840026185,
  },
  {
    name: "South Blooming Grove",
    state_id: "NY",
    lat: 41.3735,
    lng: -74.179,
    id: 1840004877,
  },
  {
    name: "Newton",
    state_id: "MS",
    lat: 32.3296,
    lng: -89.1534,
    id: 1840014892,
  },
  {
    name: "Delta",
    state_id: "OH",
    lat: 41.5753,
    lng: -84.0084,
    id: 1840011511,
  },
  {
    name: "Liberty",
    state_id: "SC",
    lat: 34.7904,
    lng: -82.6962,
    id: 1840015501,
  },
  {
    name: "Wynantskill",
    state_id: "NY",
    lat: 42.6881,
    lng: -73.6467,
    id: 1840004435,
  },
  {
    name: "Lucedale",
    state_id: "MS",
    lat: 30.9312,
    lng: -88.5959,
    id: 1840015901,
  },
  {
    name: "Walbridge",
    state_id: "OH",
    lat: 41.5864,
    lng: -83.4929,
    id: 1840011563,
  },
  {
    name: "Haskell",
    state_id: "TX",
    lat: 33.1597,
    lng: -99.732,
    id: 1840020671,
  },
  {
    name: "Moose Lake",
    state_id: "MN",
    lat: 46.4445,
    lng: -92.7634,
    id: 1840007743,
  },
  {
    name: "Minden",
    state_id: "NV",
    lat: 38.9609,
    lng: -119.7688,
    id: 1840018826,
  },
  {
    name: "Meadowdale",
    state_id: "WA",
    lat: 47.8583,
    lng: -122.3159,
    id: 1840023774,
  },
  {
    name: "Tierras Nuevas Poniente",
    state_id: "PR",
    lat: 18.4614,
    lng: -66.4891,
    id: 1630035588,
  },
  {
    name: "Homestead",
    state_id: "PA",
    lat: 40.4074,
    lng: -79.9097,
    id: 1840001238,
  },
  {
    name: "Soperton",
    state_id: "GA",
    lat: 32.3782,
    lng: -82.5944,
    id: 1840015811,
  },
  {
    name: "High Bridge",
    state_id: "WA",
    lat: 47.7968,
    lng: -122.0281,
    id: 1840041937,
  },
  {
    name: "Nevada City",
    state_id: "CA",
    lat: 39.2607,
    lng: -121.0231,
    id: 1840020226,
  },
  {
    name: "Lebanon",
    state_id: "VA",
    lat: 36.8993,
    lng: -82.0777,
    id: 1840006492,
  },
  {
    name: "Pine City",
    state_id: "MN",
    lat: 45.8367,
    lng: -92.9683,
    id: 1840000237,
  },
  {
    name: "Eleele",
    state_id: "HI",
    lat: 21.9088,
    lng: -159.5801,
    id: 1840029471,
  },
  {
    name: "Lake Success",
    state_id: "NY",
    lat: 40.768,
    lng: -73.7089,
    id: 1840005300,
  },
  {
    name: "Reamstown",
    state_id: "PA",
    lat: 40.2113,
    lng: -76.118,
    id: 1840005488,
  },
  {
    name: "Pleasant Valley",
    state_id: "WV",
    lat: 39.4505,
    lng: -80.1552,
    id: 1840005734,
  },
  {
    name: "Zillah",
    state_id: "WA",
    lat: 46.4084,
    lng: -120.2725,
    id: 1840021155,
  },
  {
    name: "Blanchard",
    state_id: "LA",
    lat: 32.609,
    lng: -93.8842,
    id: 1840015767,
  },
  {
    name: "Granville",
    state_id: "WV",
    lat: 39.6471,
    lng: -79.9974,
    id: 1840005646,
  },
  {
    name: "Tomahawk",
    state_id: "WI",
    lat: 45.4746,
    lng: -89.7228,
    id: 1840001561,
  },
  {
    name: "Burlington",
    state_id: "CO",
    lat: 39.3041,
    lng: -102.2714,
    id: 1840018806,
  },
  {
    name: "Salunga",
    state_id: "PA",
    lat: 40.0929,
    lng: -76.4279,
    id: 1840035185,
  },
  {
    name: "Beulah",
    state_id: "ND",
    lat: 47.2665,
    lng: -101.7733,
    id: 1840001907,
  },
  {
    name: "Nokomis",
    state_id: "IL",
    lat: 39.3001,
    lng: -89.2854,
    id: 1840008488,
  },
  {
    name: "Orchidlands Estates",
    state_id: "HI",
    lat: 19.557,
    lng: -155.0142,
    id: 1840029575,
  },
  {
    name: "Versailles",
    state_id: "OH",
    lat: 40.2225,
    lng: -84.4832,
    id: 1840012259,
  },
  {
    name: "Centerville",
    state_id: "PA",
    lat: 40.03,
    lng: -79.9632,
    id: 1840003644,
  },
  {
    name: "Sheridan",
    state_id: "IN",
    lat: 40.132,
    lng: -86.2197,
    id: 1840010499,
  },
  {
    name: "Landis",
    state_id: "NC",
    lat: 35.5484,
    lng: -80.6116,
    id: 1840016263,
  },
  {
    name: "Cottondale",
    state_id: "AL",
    lat: 33.1922,
    lng: -87.4544,
    id: 1840023723,
  },
  {
    name: "Petersburg",
    state_id: "AK",
    lat: 56.7566,
    lng: -132.8727,
    id: 1840023308,
  },
  {
    name: "Brackenridge",
    state_id: "PA",
    lat: 40.6079,
    lng: -79.7411,
    id: 1840001208,
  },
  {
    name: "Denmark",
    state_id: "SC",
    lat: 33.3152,
    lng: -81.1366,
    id: 1840014837,
  },
  {
    name: "Bicknell",
    state_id: "IN",
    lat: 38.7743,
    lng: -87.3078,
    id: 1840007437,
  },
  {
    name: "Okemah",
    state_id: "OK",
    lat: 35.4297,
    lng: -96.3005,
    id: 1840020429,
  },
  {
    name: "Hamburg",
    state_id: "NJ",
    lat: 41.1485,
    lng: -74.5736,
    id: 1840003481,
  },
  {
    name: "Netcong",
    state_id: "NJ",
    lat: 40.8985,
    lng: -74.7019,
    id: 1840003580,
  },
  {
    name: "Yorkville",
    state_id: "WI",
    lat: 42.7155,
    lng: -88.0097,
    id: 1840033795,
  },
  {
    name: "Heron Bay",
    state_id: "GA",
    lat: 33.3393,
    lng: -84.1922,
    id: 1840039167,
  },
  {
    name: "Avondale Estates",
    state_id: "GA",
    lat: 33.7699,
    lng: -84.2648,
    id: 1840013702,
  },
  {
    name: "Hogansville",
    state_id: "GA",
    lat: 33.1674,
    lng: -84.9027,
    id: 1840014852,
  },
  {
    name: "Greenwood",
    state_id: "LA",
    lat: 32.436,
    lng: -93.9635,
    id: 1840016931,
  },
  {
    name: "Marin City",
    state_id: "CA",
    lat: 37.8711,
    lng: -122.5137,
    id: 1840026767,
  },
  {
    name: "Rochester",
    state_id: "WA",
    lat: 46.8288,
    lng: -123.0722,
    id: 1840018459,
  },
  {
    name: "Cleveland",
    state_id: "OK",
    lat: 36.3002,
    lng: -96.4631,
    id: 1840019050,
  },
  {
    name: "Thiensville",
    state_id: "WI",
    lat: 43.236,
    lng: -87.9785,
    id: 1840002821,
  },
  {
    name: "Needville",
    state_id: "TX",
    lat: 29.3957,
    lng: -95.8387,
    id: 1840020951,
  },
  {
    name: "Oakland",
    state_id: "FL",
    lat: 28.5535,
    lng: -81.6357,
    id: 1840015097,
  },
  {
    name: "Marion",
    state_id: "AL",
    lat: 32.6318,
    lng: -87.3172,
    id: 1840008188,
  },
  {
    name: "Mount Penn",
    state_id: "PA",
    lat: 40.3287,
    lng: -75.8897,
    id: 1840001184,
  },
  {
    name: "San Andreas",
    state_id: "CA",
    lat: 38.1911,
    lng: -120.6763,
    id: 1840018868,
  },
  {
    name: "Henrietta",
    state_id: "TX",
    lat: 33.8147,
    lng: -98.1925,
    id: 1840020547,
  },
  {
    name: "Hiawatha",
    state_id: "KS",
    lat: 39.8517,
    lng: -95.5381,
    id: 1840000726,
  },
  {
    name: "Lake Pocotopaug",
    state_id: "CT",
    lat: 41.5961,
    lng: -72.5124,
    id: 1840028596,
  },
  {
    name: "Wesleyville",
    state_id: "PA",
    lat: 42.1369,
    lng: -80.0123,
    id: 1840000488,
  },
  {
    name: "Paddock Lake",
    state_id: "WI",
    lat: 42.5707,
    lng: -88.1044,
    id: 1840003162,
  },
  {
    name: "Gosnell",
    state_id: "AR",
    lat: 35.9645,
    lng: -89.9721,
    id: 1840013389,
  },
  {
    name: "Abingdon",
    state_id: "IL",
    lat: 40.8039,
    lng: -90.401,
    id: 1840007161,
  },
  {
    name: "North Lakeville",
    state_id: "MA",
    lat: 41.8619,
    lng: -70.9411,
    id: 1840031242,
  },
  {
    name: "Kellogg",
    state_id: "ID",
    lat: 47.5371,
    lng: -116.142,
    id: 1840018392,
  },
  {
    name: "Bean Station",
    state_id: "TN",
    lat: 36.3322,
    lng: -83.2852,
    id: 1840013342,
  },
  {
    name: "Shackle Island",
    state_id: "TN",
    lat: 36.3775,
    lng: -86.6168,
    id: 1840024896,
  },
  {
    name: "Island Walk",
    state_id: "FL",
    lat: 26.2511,
    lng: -81.711,
    id: 1840038939,
  },
  {
    name: "Mineral Springs",
    state_id: "NC",
    lat: 34.9411,
    lng: -80.6846,
    id: 1840016455,
  },
  {
    name: "Arcadia",
    state_id: "WI",
    lat: 44.2486,
    lng: -91.4915,
    id: 1840002383,
  },
  {
    name: "Beechwood",
    state_id: "MI",
    lat: 42.7982,
    lng: -86.123,
    id: 1840004346,
  },
  {
    name: "Blue Earth",
    state_id: "MN",
    lat: 43.6404,
    lng: -94.1004,
    id: 1840006841,
  },
  {
    name: "Wallburg",
    state_id: "NC",
    lat: 36.0088,
    lng: -80.1453,
    id: 1840016224,
  },
  {
    name: "Jamestown",
    state_id: "CA",
    lat: 37.9574,
    lng: -120.411,
    id: 1840017600,
  },
  {
    name: "North Cape May",
    state_id: "NJ",
    lat: 38.9766,
    lng: -74.9516,
    id: 1840005866,
  },
  {
    name: "Beechwood Trails",
    state_id: "OH",
    lat: 40.0252,
    lng: -82.6463,
    id: 1840034378,
  },
  {
    name: "Milbank",
    state_id: "SD",
    lat: 45.2194,
    lng: -96.634,
    id: 1840002099,
  },
  {
    name: "South Jacksonville",
    state_id: "IL",
    lat: 39.7024,
    lng: -90.2292,
    id: 1840012516,
  },
  {
    name: "New Holstein",
    state_id: "WI",
    lat: 43.9491,
    lng: -88.0942,
    id: 1840002522,
  },
  {
    name: "Blountville",
    state_id: "TN",
    lat: 36.533,
    lng: -82.329,
    id: 1840013298,
  },
  {
    name: "Oak Trail Shores",
    state_id: "TX",
    lat: 32.4885,
    lng: -97.8357,
    id: 1840019459,
  },
  {
    name: "Acres Green",
    state_id: "CO",
    lat: 39.5558,
    lng: -104.8958,
    id: 1840028559,
  },
  {
    name: "Vado",
    state_id: "NM",
    lat: 32.1279,
    lng: -106.6571,
    id: 1840019416,
  },
  {
    name: "Olney",
    state_id: "TX",
    lat: 33.3643,
    lng: -98.7584,
    id: 1840020670,
  },
  {
    name: "Federalsburg",
    state_id: "MD",
    lat: 38.6928,
    lng: -75.7727,
    id: 1840005933,
  },
  {
    name: "Bonny Doon",
    state_id: "CA",
    lat: 37.0435,
    lng: -122.1369,
    id: 1840022568,
  },
  {
    name: "Checotah",
    state_id: "OK",
    lat: 35.4821,
    lng: -95.5224,
    id: 1840019157,
  },
  {
    name: "Sloatsburg",
    state_id: "NY",
    lat: 41.162,
    lng: -74.1902,
    id: 1840004984,
  },
  {
    name: "Dahlgren",
    state_id: "VA",
    lat: 38.3444,
    lng: -77.0624,
    id: 1840006266,
  },
  {
    name: "Lodi",
    state_id: "WI",
    lat: 43.3154,
    lng: -89.5366,
    id: 1840002710,
  },
  {
    name: "Ocean Grove",
    state_id: "MA",
    lat: 41.7281,
    lng: -71.2098,
    id: 1840004734,
  },
  {
    name: "Buffalo",
    state_id: "MO",
    lat: 37.6444,
    lng: -93.0969,
    id: 1840007571,
  },
  {
    name: "Cadiz",
    state_id: "KY",
    lat: 36.8695,
    lng: -87.8048,
    id: 1840013282,
  },
  {
    name: "McSherrystown",
    state_id: "PA",
    lat: 39.8035,
    lng: -77.0199,
    id: 1840000685,
  },
  {
    name: "Morongo Valley",
    state_id: "CA",
    lat: 34.0724,
    lng: -116.5627,
    id: 1840019110,
  },
  {
    name: "Warsaw",
    state_id: "NC",
    lat: 34.9987,
    lng: -78.0926,
    id: 1840016472,
  },
  {
    name: "Brownstown",
    state_id: "IN",
    lat: 38.8789,
    lng: -86.0479,
    id: 1840009709,
  },
  {
    name: "Greenwood Lake",
    state_id: "NY",
    lat: 41.2215,
    lng: -74.2891,
    id: 1840004869,
  },
  {
    name: "Eagle Lake",
    state_id: "MN",
    lat: 44.1616,
    lng: -93.8846,
    id: 1840007903,
  },
  {
    name: "Fiskdale",
    state_id: "MA",
    lat: 42.1227,
    lng: -72.1087,
    id: 1840003148,
  },
  {
    name: "East Rockingham",
    state_id: "NC",
    lat: 34.9116,
    lng: -79.766,
    id: 1840013081,
  },
  {
    name: "Chandler",
    state_id: "OK",
    lat: 35.7237,
    lng: -96.8951,
    id: 1840019106,
  },
  {
    name: "Red Bay",
    state_id: "AL",
    lat: 34.4358,
    lng: -88.1362,
    id: 1840014682,
  },
  {
    name: "Sonora",
    state_id: "TX",
    lat: 30.5706,
    lng: -100.642,
    id: 1840022172,
  },
  {
    name: "Filer",
    state_id: "ID",
    lat: 42.5679,
    lng: -114.6115,
    id: 1840020080,
  },
  {
    name: "Watertown",
    state_id: "FL",
    lat: 30.1855,
    lng: -82.6026,
    id: 1840013955,
  },
  {
    name: "Tishomingo",
    state_id: "OK",
    lat: 34.2387,
    lng: -96.6805,
    id: 1840021904,
  },
  {
    name: "Hartville",
    state_id: "OH",
    lat: 40.9618,
    lng: -81.3347,
    id: 1840011910,
  },
  {
    name: "Andrews AFB",
    state_id: "MD",
    lat: 38.8053,
    lng: -76.8744,
    id: 1840073587,
  },
  {
    name: "Cleveland",
    state_id: "FL",
    lat: 26.9529,
    lng: -81.9925,
    id: 1840013128,
  },
  {
    name: "Brillion",
    state_id: "WI",
    lat: 44.1757,
    lng: -88.0711,
    id: 1840002520,
  },
  {
    name: "Anza",
    state_id: "CA",
    lat: 33.5679,
    lng: -116.6967,
    id: 1840022905,
  },
  {
    name: "Licking",
    state_id: "MO",
    lat: 37.4993,
    lng: -91.8618,
    id: 1840009892,
  },
  {
    name: "Moriches",
    state_id: "NY",
    lat: 40.807,
    lng: -72.8235,
    id: 1840005048,
  },
  {
    name: "Eatonville",
    state_id: "WA",
    lat: 46.8681,
    lng: -122.2697,
    id: 1840021131,
  },
  {
    name: "Clarksville",
    state_id: "TX",
    lat: 33.611,
    lng: -95.0561,
    id: 1840019315,
  },
  {
    name: "Lakemore",
    state_id: "OH",
    lat: 41.0215,
    lng: -81.4264,
    id: 1840000796,
  },
  {
    name: "Jefferson",
    state_id: "OH",
    lat: 41.7384,
    lng: -80.7689,
    id: 1840011415,
  },
  {
    name: "Cut Bank",
    state_id: "MT",
    lat: 48.6345,
    lng: -112.3304,
    id: 1840018354,
  },
  {
    name: "Savanna",
    state_id: "IL",
    lat: 42.0904,
    lng: -90.1396,
    id: 1840009180,
  },
  {
    name: "Double Oak",
    state_id: "TX",
    lat: 33.0633,
    lng: -97.1117,
    id: 1840022008,
  },
  {
    name: "Fairport Harbor",
    state_id: "OH",
    lat: 41.7477,
    lng: -81.2731,
    id: 1840011436,
  },
  {
    name: "Doylestown",
    state_id: "OH",
    lat: 40.97,
    lng: -81.6954,
    id: 1840011894,
  },
  {
    name: "Lake Arrowhead",
    state_id: "ME",
    lat: 43.6634,
    lng: -70.7382,
    id: 1840002670,
  },
  {
    name: "Biscayne Park",
    state_id: "FL",
    lat: 25.8817,
    lng: -80.1813,
    id: 1840018331,
  },
  {
    name: "Navy Yard City",
    state_id: "WA",
    lat: 47.5508,
    lng: -122.6655,
    id: 1840018407,
  },
  {
    name: "Bethany",
    state_id: "MO",
    lat: 40.2684,
    lng: -94.0281,
    id: 1840007230,
  },
  {
    name: "Paramount-Long Meadow",
    state_id: "MD",
    lat: 39.6798,
    lng: -77.6921,
    id: 1840073650,
  },
  {
    name: "Dyer",
    state_id: "TN",
    lat: 36.071,
    lng: -88.9921,
    id: 1840013371,
  },
  {
    name: "Monterey",
    state_id: "TN",
    lat: 36.1446,
    lng: -85.2643,
    id: 1840016108,
  },
  {
    name: "Weaver",
    state_id: "AL",
    lat: 33.7602,
    lng: -85.8117,
    id: 1840015646,
  },
  {
    name: "Golden Beach",
    state_id: "MD",
    lat: 38.49,
    lng: -76.7019,
    id: 1840006230,
  },
  {
    name: "Brightwaters",
    state_id: "NY",
    lat: 40.7193,
    lng: -73.2642,
    id: 1840005145,
  },
  {
    name: "Big Sky",
    state_id: "MT",
    lat: 45.2592,
    lng: -111.3426,
    id: 1840017393,
  },
  {
    name: "Blackhawk",
    state_id: "SD",
    lat: 44.1514,
    lng: -103.334,
    id: 1840004031,
  },
  {
    name: "Dalzell",
    state_id: "SC",
    lat: 34.0189,
    lng: -80.4299,
    id: 1840013090,
  },
  {
    name: "Bishop",
    state_id: "TX",
    lat: 27.5851,
    lng: -97.7977,
    id: 1840019717,
  },
  {
    name: "Wilder",
    state_id: "KY",
    lat: 39.04,
    lng: -84.4816,
    id: 1840015171,
  },
  {
    name: "Corning",
    state_id: "AR",
    lat: 36.4111,
    lng: -90.5861,
    id: 1840014455,
  },
  {
    name: "Wormleysburg",
    state_id: "PA",
    lat: 40.2604,
    lng: -76.9094,
    id: 1840001394,
  },
  {
    name: "Richville",
    state_id: "OH",
    lat: 40.7529,
    lng: -81.4684,
    id: 1840026392,
  },
  {
    name: "Dadeville",
    state_id: "AL",
    lat: 32.8326,
    lng: -85.7675,
    id: 1840001397,
  },
  {
    name: "Columbus",
    state_id: "KS",
    lat: 37.1714,
    lng: -94.8442,
    id: 1840001715,
  },
  {
    name: "Montgomery",
    state_id: "MN",
    lat: 44.4452,
    lng: -93.5797,
    id: 1840007883,
  },
  {
    name: "Loxley",
    state_id: "AL",
    lat: 30.6813,
    lng: -87.7418,
    id: 1840017123,
  },
  {
    name: "Wildwood Crest",
    state_id: "NJ",
    lat: 38.9718,
    lng: -74.8376,
    id: 1840001603,
  },
  {
    name: "Algoma",
    state_id: "WI",
    lat: 44.6057,
    lng: -87.4465,
    id: 1840001567,
  },
  {
    name: "Beechwood",
    state_id: "MS",
    lat: 32.3303,
    lng: -90.819,
    id: 1840025904,
  },
  {
    name: "Ocean Grove",
    state_id: "NJ",
    lat: 40.2119,
    lng: -74.0083,
    id: 1840005446,
  },
  {
    name: "Fayetteville",
    state_id: "PA",
    lat: 39.9114,
    lng: -77.5651,
    id: 1840005495,
  },
  {
    name: "Sherwood",
    state_id: "WI",
    lat: 44.1764,
    lng: -88.2756,
    id: 1840002247,
  },
  {
    name: "Johnson Creek",
    state_id: "WI",
    lat: 43.0812,
    lng: -88.7702,
    id: 1840003002,
  },
  {
    name: "Hill 'n Dale",
    state_id: "FL",
    lat: 28.519,
    lng: -82.2918,
    id: 1840028916,
  },
  {
    name: "Wellton",
    state_id: "AZ",
    lat: 32.64,
    lng: -114.214,
    id: 1840022976,
  },
  {
    name: "Kings Beach",
    state_id: "CA",
    lat: 39.249,
    lng: -120.0201,
    id: 1840017560,
  },
  {
    name: "Benson",
    state_id: "MN",
    lat: 45.3152,
    lng: -95.6058,
    id: 1840006727,
  },
  {
    name: "Heyworth",
    state_id: "IL",
    lat: 40.3138,
    lng: -88.9928,
    id: 1840011973,
  },
  {
    name: "DeCordova",
    state_id: "TX",
    lat: 32.428,
    lng: -97.6911,
    id: 1840019463,
  },
  {
    name: "McDonald",
    state_id: "OH",
    lat: 41.1634,
    lng: -80.723,
    id: 1840011596,
  },
  {
    name: "Kimberling City",
    state_id: "MO",
    lat: 36.6441,
    lng: -93.4239,
    id: 1840008795,
  },
  {
    name: "Cordaville",
    state_id: "MA",
    lat: 42.2727,
    lng: -71.5221,
    id: 1840003145,
  },
  {
    name: "Trafford",
    state_id: "PA",
    lat: 40.3847,
    lng: -79.7575,
    id: 1840001168,
  },
  {
    name: "Genoa",
    state_id: "OH",
    lat: 41.5204,
    lng: -83.3619,
    id: 1840011500,
  },
  {
    name: "Eunice",
    state_id: "NM",
    lat: 32.4465,
    lng: -103.2011,
    id: 1840020615,
  },
  {
    name: "Greenfield",
    state_id: "MN",
    lat: 45.0997,
    lng: -93.6884,
    id: 1840007825,
  },
  {
    name: "Kaunakakai",
    state_id: "HI",
    lat: 21.0905,
    lng: -156.9981,
    id: 1840023226,
  },
  {
    name: "Atkins",
    state_id: "AR",
    lat: 35.2414,
    lng: -92.9465,
    id: 1840013422,
  },
  {
    name: "Mazomanie",
    state_id: "WI",
    lat: 43.1731,
    lng: -89.7955,
    id: 1840002918,
  },
  {
    name: "Roosevelt Gardens",
    state_id: "FL",
    lat: 26.1406,
    lng: -80.181,
    id: 1840029108,
  },
  {
    name: "Penrose",
    state_id: "CO",
    lat: 38.4213,
    lng: -105.0004,
    id: 1840018854,
  },
  {
    name: "Garner",
    state_id: "IA",
    lat: 43.1,
    lng: -93.6026,
    id: 1840007986,
  },
  {
    name: "Day Valley",
    state_id: "CA",
    lat: 37.0255,
    lng: -121.8559,
    id: 1840028365,
  },
  {
    name: "Strathmore",
    state_id: "CA",
    lat: 36.1438,
    lng: -119.0611,
    id: 1840019042,
  },
  {
    name: "Carrizales",
    state_id: "PR",
    lat: 18.4811,
    lng: -66.7878,
    id: 1630023532,
  },
  {
    name: "Emigsville",
    state_id: "PA",
    lat: 40.0085,
    lng: -76.731,
    id: 1840005506,
  },
  {
    name: "Vails Gate",
    state_id: "NY",
    lat: 41.4587,
    lng: -74.0533,
    id: 1840004863,
  },
  {
    name: "Boutte",
    state_id: "LA",
    lat: 29.8825,
    lng: -90.3936,
    id: 1840013977,
  },
  {
    name: "Hahira",
    state_id: "GA",
    lat: 30.9941,
    lng: -83.3787,
    id: 1840013904,
  },
  {
    name: "Thermopolis",
    state_id: "WY",
    lat: 43.6479,
    lng: -108.2139,
    id: 1840022353,
  },
  {
    name: "Walsenburg",
    state_id: "CO",
    lat: 37.6307,
    lng: -104.7818,
    id: 1840021535,
  },
  {
    name: "Proctor",
    state_id: "MN",
    lat: 46.7416,
    lng: -92.2276,
    id: 1840008818,
  },
  {
    name: "Ferrysburg",
    state_id: "MI",
    lat: 43.085,
    lng: -86.2221,
    id: 1840002994,
  },
  {
    name: "Luyando",
    state_id: "PR",
    lat: 18.3575,
    lng: -67.1553,
    id: 1630023527,
  },
  {
    name: "Afton",
    state_id: "MN",
    lat: 44.9042,
    lng: -92.8174,
    id: 1840006736,
  },
  {
    name: "Oostburg",
    state_id: "WI",
    lat: 43.6237,
    lng: -87.7889,
    id: 1840002625,
  },
  {
    name: "River Bend",
    state_id: "NC",
    lat: 35.0717,
    lng: -77.1504,
    id: 1840017866,
  },
  {
    name: "Edgecliff Village",
    state_id: "TX",
    lat: 32.6561,
    lng: -97.3406,
    id: 1840022052,
  },
  {
    name: "Pleasant Valley",
    state_id: "MO",
    lat: 39.2172,
    lng: -94.481,
    id: 1840009606,
  },
  {
    name: "Lido Beach",
    state_id: "NY",
    lat: 40.5904,
    lng: -73.6121,
    id: 1840005271,
  },
  {
    name: "Petersburg",
    state_id: "IL",
    lat: 40.0143,
    lng: -89.8455,
    id: 1840009469,
  },
  {
    name: "Delphi",
    state_id: "IN",
    lat: 40.5834,
    lng: -86.6668,
    id: 1840013728,
  },
  {
    name: "Shelby",
    state_id: "MT",
    lat: 48.5031,
    lng: -111.8615,
    id: 1840021062,
  },
  {
    name: "Soda Springs",
    state_id: "ID",
    lat: 42.659,
    lng: -111.5878,
    id: 1840021297,
  },
  {
    name: "Jarrettsville",
    state_id: "MD",
    lat: 39.6031,
    lng: -76.4752,
    id: 1840005664,
  },
  {
    name: "Buckner",
    state_id: "MO",
    lat: 39.1336,
    lng: -94.1929,
    id: 1840007395,
  },
  {
    name: "Dermott",
    state_id: "AR",
    lat: 33.5271,
    lng: -91.4324,
    id: 1840014820,
  },
  {
    name: "Everett",
    state_id: "PA",
    lat: 40.0133,
    lng: -78.3667,
    id: 1840001398,
  },
  {
    name: "Mohnton",
    state_id: "PA",
    lat: 40.2872,
    lng: -75.9871,
    id: 1840001183,
  },
  {
    name: "Santa Rosa Valley",
    state_id: "CA",
    lat: 34.2453,
    lng: -118.9023,
    id: 1840042299,
  },
  {
    name: "Atoka",
    state_id: "OK",
    lat: 34.3857,
    lng: -96.1313,
    id: 1840019254,
  },
  {
    name: "Lesslie",
    state_id: "SC",
    lat: 34.8856,
    lng: -80.9541,
    id: 1840013519,
  },
  {
    name: "Kenvil",
    state_id: "NJ",
    lat: 40.8747,
    lng: -74.6274,
    id: 1840024234,
  },
  {
    name: "Strasburg",
    state_id: "CO",
    lat: 39.7181,
    lng: -104.3195,
    id: 1840018795,
  },
  {
    name: "Granite Quarry",
    state_id: "NC",
    lat: 35.6126,
    lng: -80.4484,
    id: 1840016268,
  },
  {
    name: "Hamilton",
    state_id: "TX",
    lat: 31.6997,
    lng: -98.1208,
    id: 1840020801,
  },
  {
    name: "Milton",
    state_id: "DE",
    lat: 38.7751,
    lng: -75.3104,
    id: 1840006081,
  },
  {
    name: "Nashville",
    state_id: "IL",
    lat: 38.3541,
    lng: -89.377,
    id: 1840008651,
  },
  {
    name: "Wyoming",
    state_id: "PA",
    lat: 41.3058,
    lng: -75.8416,
    id: 1840000778,
  },
  {
    name: "Vieques",
    state_id: "PR",
    lat: 18.1463,
    lng: -65.4429,
    id: 1630035597,
  },
  {
    name: "Northwest Harwinton",
    state_id: "CT",
    lat: 41.7769,
    lng: -73.0794,
    id: 1840073302,
  },
  {
    name: "Choccolocco",
    state_id: "AL",
    lat: 33.6683,
    lng: -85.7129,
    id: 1840022930,
  },
  {
    name: "Mountain Home AFB",
    state_id: "ID",
    lat: 43.0489,
    lng: -115.866,
    id: 1840074437,
  },
  {
    name: "Tularosa",
    state_id: "NM",
    lat: 33.0752,
    lng: -106.0175,
    id: 1840023002,
  },
  {
    name: "Sutter",
    state_id: "CA",
    lat: 39.1556,
    lng: -121.7492,
    id: 1840018819,
  },
  {
    name: "Calais",
    state_id: "ME",
    lat: 45.134,
    lng: -67.224,
    id: 1840000271,
  },
  {
    name: "DeWitt",
    state_id: "AR",
    lat: 34.2873,
    lng: -91.3383,
    id: 1840027649,
  },
  {
    name: "K. I. Sawyer",
    state_id: "MI",
    lat: 46.3298,
    lng: -87.3673,
    id: 1840031758,
  },
  {
    name: "Edgewood",
    state_id: "PA",
    lat: 40.4313,
    lng: -79.8839,
    id: 1840001227,
  },
  {
    name: "Edgewood",
    state_id: "FL",
    lat: 28.4878,
    lng: -81.3772,
    id: 1840014099,
  },
  {
    name: "Byrnes Mill",
    state_id: "MO",
    lat: 38.4395,
    lng: -90.5741,
    id: 1840007504,
  },
  {
    name: "Nocona",
    state_id: "TX",
    lat: 33.7835,
    lng: -97.7302,
    id: 1840020569,
  },
  {
    name: "Indian Hills",
    state_id: "TX",
    lat: 26.2124,
    lng: -97.9165,
    id: 1840037050,
  },
  {
    name: "Staples",
    state_id: "MN",
    lat: 46.369,
    lng: -94.8018,
    id: 1840008860,
  },
  {
    name: "Midway",
    state_id: "FL",
    lat: 30.4993,
    lng: -84.4583,
    id: 1840015021,
  },
  {
    name: "Stanton",
    state_id: "TX",
    lat: 32.1316,
    lng: -101.7925,
    id: 1840022090,
  },
  {
    name: "Providence",
    state_id: "KY",
    lat: 37.3993,
    lng: -87.7509,
    id: 1840014361,
  },
  {
    name: "Milford",
    state_id: "IA",
    lat: 43.3335,
    lng: -95.1474,
    id: 1840007960,
  },
  {
    name: "Iron River",
    state_id: "MI",
    lat: 46.0966,
    lng: -88.6396,
    id: 1840001972,
  },
  {
    name: "Palmer Lake",
    state_id: "CO",
    lat: 39.1152,
    lng: -104.9057,
    id: 1840022475,
  },
  {
    name: "Wisconsin Dells",
    state_id: "WI",
    lat: 43.6337,
    lng: -89.7752,
    id: 1840002703,
  },
  {
    name: "Dry Ridge",
    state_id: "OH",
    lat: 39.2587,
    lng: -84.6339,
    id: 1840034254,
  },
  {
    name: "Winnebago",
    state_id: "IL",
    lat: 42.2671,
    lng: -89.234,
    id: 1840011126,
  },
  {
    name: "Prescott",
    state_id: "AR",
    lat: 33.8056,
    lng: -93.3909,
    id: 1840014784,
  },
  {
    name: "Royston",
    state_id: "GA",
    lat: 34.2859,
    lng: -83.1097,
    id: 1840014691,
  },
  {
    name: "Villalba",
    state_id: "PR",
    lat: 18.1279,
    lng: -66.4814,
    id: 1630023667,
  },
  {
    name: "Havana",
    state_id: "IL",
    lat: 40.295,
    lng: -90.0567,
    id: 1840007238,
  },
  {
    name: "Shenandoah",
    state_id: "TX",
    lat: 30.184,
    lng: -95.4555,
    id: 1840022173,
  },
  {
    name: "West",
    state_id: "TX",
    lat: 31.8032,
    lng: -97.0942,
    id: 1840022141,
  },
  {
    name: "Penbrook",
    state_id: "PA",
    lat: 40.278,
    lng: -76.8484,
    id: 1840001294,
  },
  {
    name: "Genoa City",
    state_id: "WI",
    lat: 42.5065,
    lng: -88.3192,
    id: 1840002478,
  },
  {
    name: "Fobes Hill",
    state_id: "WA",
    lat: 47.9392,
    lng: -122.1341,
    id: 1840037736,
  },
  {
    name: "Park Hills",
    state_id: "KY",
    lat: 39.0691,
    lng: -84.5312,
    id: 1840014266,
  },
  {
    name: "Luis Lloréns Torres",
    state_id: "PR",
    lat: 18.0566,
    lng: -66.5267,
    id: 1630023578,
  },
  {
    name: "Combes",
    state_id: "TX",
    lat: 26.2444,
    lng: -97.7254,
    id: 1840023166,
  },
  {
    name: "Lake Nacimiento",
    state_id: "CA",
    lat: 35.7309,
    lng: -120.8702,
    id: 1840017808,
  },
  {
    name: "Tonkawa",
    state_id: "OK",
    lat: 36.6787,
    lng: -97.3281,
    id: 1840021614,
  },
  {
    name: "Sumner",
    state_id: "IL",
    lat: 38.7195,
    lng: -87.8722,
    id: 1840009781,
  },
  {
    name: "Day Heights",
    state_id: "OH",
    lat: 39.1755,
    lng: -84.2275,
    id: 1840034382,
  },
  {
    name: "Woodlawn Beach",
    state_id: "FL",
    lat: 30.3861,
    lng: -86.9931,
    id: 1840027011,
  },
  {
    name: "Hawaiian Acres",
    state_id: "HI",
    lat: 19.5325,
    lng: -155.0497,
    id: 1840029572,
  },
  {
    name: "Tangelo Park",
    state_id: "FL",
    lat: 28.456,
    lng: -81.4465,
    id: 1840014087,
  },
  {
    name: "Nags Head",
    state_id: "NC",
    lat: 35.9474,
    lng: -75.6274,
    id: 1840016492,
  },
  {
    name: "Sherrill",
    state_id: "NY",
    lat: 43.0704,
    lng: -75.5992,
    id: 1840000350,
  },
  {
    name: "Ferris",
    state_id: "TX",
    lat: 32.5369,
    lng: -96.6738,
    id: 1840020755,
  },
  {
    name: "Smithsburg",
    state_id: "MD",
    lat: 39.6564,
    lng: -77.5762,
    id: 1840005618,
  },
  {
    name: "Lochsloy",
    state_id: "WA",
    lat: 48.0597,
    lng: -122.0411,
    id: 1840037500,
  },
  {
    name: "Fort Hall",
    state_id: "ID",
    lat: 43.0145,
    lng: -112.4573,
    id: 1840029704,
  },
  {
    name: "Bayfield",
    state_id: "CO",
    lat: 37.234,
    lng: -107.5952,
    id: 1840020309,
  },
  {
    name: "Redwood",
    state_id: "OR",
    lat: 42.4219,
    lng: -123.3915,
    id: 1840034831,
  },
  {
    name: "Clarence Center",
    state_id: "NY",
    lat: 43.0085,
    lng: -78.6308,
    id: 1840004376,
  },
  {
    name: "Akron",
    state_id: "NY",
    lat: 43.0179,
    lng: -78.4978,
    id: 1840004393,
  },
  {
    name: "Hayti",
    state_id: "MO",
    lat: 36.2323,
    lng: -89.7474,
    id: 1840007670,
  },
  {
    name: "Indian Hills",
    state_id: "KY",
    lat: 38.2808,
    lng: -85.6616,
    id: 1840014310,
  },
  {
    name: "Yountville",
    state_id: "CA",
    lat: 38.3963,
    lng: -122.3671,
    id: 1840021487,
  },
  {
    name: "Gloverville",
    state_id: "SC",
    lat: 33.5274,
    lng: -81.8145,
    id: 1840013719,
  },
  {
    name: "New Richmond",
    state_id: "OH",
    lat: 38.962,
    lng: -84.2779,
    id: 1840012709,
  },
  {
    name: "Newberry",
    state_id: "MI",
    lat: 46.3538,
    lng: -85.5098,
    id: 1840010916,
  },
  {
    name: "Irvine",
    state_id: "KY",
    lat: 37.6969,
    lng: -83.9685,
    id: 1840014344,
  },
  {
    name: "Woodbury Heights",
    state_id: "NJ",
    lat: 39.816,
    lng: -75.1512,
    id: 1840001528,
  },
  {
    name: "Evansville",
    state_id: "WY",
    lat: 42.868,
    lng: -106.2525,
    id: 1840021290,
  },
  {
    name: "Ellsworth",
    state_id: "KS",
    lat: 38.7326,
    lng: -98.2286,
    id: 1840001649,
  },
  {
    name: "Kenova",
    state_id: "WV",
    lat: 38.4026,
    lng: -82.5822,
    id: 1840006263,
  },
  {
    name: "Murfreesboro",
    state_id: "NC",
    lat: 36.4421,
    lng: -77.0964,
    id: 1840016045,
  },
  {
    name: "Springfield",
    state_id: "KY",
    lat: 37.6921,
    lng: -85.2193,
    id: 1840015225,
  },
  {
    name: "Lula",
    state_id: "GA",
    lat: 34.3956,
    lng: -83.6655,
    id: 1840015559,
  },
  {
    name: "Bal Harbour",
    state_id: "FL",
    lat: 25.8935,
    lng: -80.1258,
    id: 1840018330,
  },
  {
    name: "Hartley",
    state_id: "CA",
    lat: 38.4203,
    lng: -121.9521,
    id: 1840024675,
  },
  {
    name: "East Norwich",
    state_id: "NY",
    lat: 40.8496,
    lng: -73.5288,
    id: 1840005237,
  },
  {
    name: "Southern Shores",
    state_id: "NC",
    lat: 36.1205,
    lng: -75.7327,
    id: 1840017739,
  },
  {
    name: "Lopezville",
    state_id: "TX",
    lat: 26.2453,
    lng: -98.1537,
    id: 1840036275,
  },
  {
    name: "Lykens",
    state_id: "PA",
    lat: 40.5641,
    lng: -76.6983,
    id: 1840001282,
  },
  {
    name: "South Alamo",
    state_id: "TX",
    lat: 26.1518,
    lng: -98.1079,
    id: 1840037078,
  },
  {
    name: "Summit Hill",
    state_id: "PA",
    lat: 40.8249,
    lng: -75.8464,
    id: 1840003566,
  },
  {
    name: "Alva",
    state_id: "FL",
    lat: 26.7188,
    lng: -81.6269,
    id: 1840014214,
  },
  {
    name: "North Barrington",
    state_id: "IL",
    lat: 42.2065,
    lng: -88.1316,
    id: 1840011176,
  },
  {
    name: "Minden",
    state_id: "NE",
    lat: 40.4982,
    lng: -98.967,
    id: 1840008337,
  },
  {
    name: "Deer Lodge",
    state_id: "MT",
    lat: 46.3974,
    lng: -112.7337,
    id: 1840018437,
  },
  {
    name: "Flemingsburg",
    state_id: "KY",
    lat: 38.4236,
    lng: -83.7372,
    id: 1840013191,
  },
  {
    name: "Ramseur",
    state_id: "NC",
    lat: 35.7372,
    lng: -79.6543,
    id: 1840017780,
  },
  {
    name: "Graymoor-Devondale",
    state_id: "KY",
    lat: 38.2733,
    lng: -85.6177,
    id: 1840013205,
  },
  {
    name: "The Plains",
    state_id: "OH",
    lat: 39.3656,
    lng: -82.1343,
    id: 1840005755,
  },
  {
    name: "Westville",
    state_id: "IL",
    lat: 40.0439,
    lng: -87.6389,
    id: 1840012189,
  },
  {
    name: "University Park",
    state_id: "NM",
    lat: 32.2767,
    lng: -106.7462,
    id: 1840019415,
  },
  {
    name: "Horace",
    state_id: "ND",
    lat: 46.7559,
    lng: -96.9078,
    id: 1840000182,
  },
  {
    name: "Portageville",
    state_id: "MO",
    lat: 36.4295,
    lng: -89.6994,
    id: 1840009942,
  },
  {
    name: "National Park",
    state_id: "NJ",
    lat: 39.8676,
    lng: -75.1853,
    id: 1840003788,
  },
  {
    name: "Bunnell",
    state_id: "FL",
    lat: 29.4193,
    lng: -81.3232,
    id: 1840014036,
  },
  {
    name: "Lake Cherokee",
    state_id: "TX",
    lat: 32.3569,
    lng: -94.6513,
    id: 1840042592,
  },
  {
    name: "Trenton",
    state_id: "FL",
    lat: 29.6092,
    lng: -82.8147,
    id: 1840015945,
  },
  {
    name: "Stamford",
    state_id: "TX",
    lat: 33.0156,
    lng: -99.6716,
    id: 1840022062,
  },
  {
    name: "Pioneer Village",
    state_id: "KY",
    lat: 38.0599,
    lng: -85.68,
    id: 1840014323,
  },
  {
    name: "East Greenville",
    state_id: "PA",
    lat: 40.4057,
    lng: -75.5059,
    id: 1840003698,
  },
  {
    name: "Galateo",
    state_id: "PR",
    lat: 18.3639,
    lng: -66.259,
    id: 1630035671,
  },
  {
    name: "Malakoff",
    state_id: "TX",
    lat: 32.1727,
    lng: -96.0162,
    id: 1840020774,
  },
  {
    name: "Dover",
    state_id: "FL",
    lat: 27.9927,
    lng: -82.2198,
    id: 1840013123,
  },
  {
    name: "Munsons Corners",
    state_id: "NY",
    lat: 42.5761,
    lng: -76.2053,
    id: 1840004496,
  },
  {
    name: "Dresden",
    state_id: "TN",
    lat: 36.279,
    lng: -88.6941,
    id: 1840016070,
  },
  {
    name: "Iuka",
    state_id: "MS",
    lat: 34.8077,
    lng: -88.1978,
    id: 1840014639,
  },
  {
    name: "Newton",
    state_id: "IL",
    lat: 38.9872,
    lng: -88.1644,
    id: 1840008550,
  },
  {
    name: "Watkinsville",
    state_id: "GA",
    lat: 33.861,
    lng: -83.4072,
    id: 1840012722,
  },
  {
    name: "Seagraves",
    state_id: "TX",
    lat: 32.9419,
    lng: -102.5657,
    id: 1840022060,
  },
  {
    name: "Niantic",
    state_id: "CT",
    lat: 41.3265,
    lng: -72.1949,
    id: 1840003350,
  },
  {
    name: "Venetian Village",
    state_id: "IL",
    lat: 42.4012,
    lng: -88.0465,
    id: 1840006972,
  },
  {
    name: "Port Richey",
    state_id: "FL",
    lat: 28.2758,
    lng: -82.725,
    id: 1840015103,
  },
  {
    name: "Keeseville",
    state_id: "NY",
    lat: 44.5035,
    lng: -73.4812,
    id: 1840033988,
  },
  {
    name: "Kachina Village",
    state_id: "AZ",
    lat: 35.0949,
    lng: -111.6927,
    id: 1840017663,
  },
  {
    name: "Elgin",
    state_id: "SC",
    lat: 34.6724,
    lng: -80.7215,
    id: 1840152916,
  },
  {
    name: "Liberty Hill",
    state_id: "TX",
    lat: 30.6634,
    lng: -97.9073,
    id: 1840022612,
  },
  {
    name: "Sterlington",
    state_id: "LA",
    lat: 32.6888,
    lng: -92.0602,
    id: 1840016971,
  },
  {
    name: "Ozona",
    state_id: "TX",
    lat: 30.7074,
    lng: -101.2059,
    id: 1840019563,
  },
  {
    name: "Milaca",
    state_id: "MN",
    lat: 45.7573,
    lng: -93.6522,
    id: 1840007761,
  },
  {
    name: "Holiday Shores",
    state_id: "IL",
    lat: 38.9227,
    lng: -89.936,
    id: 1840025642,
  },
  {
    name: "Barton Creek",
    state_id: "TX",
    lat: 30.2818,
    lng: -97.8681,
    id: 1840023318,
  },
  {
    name: "Prince Frederick",
    state_id: "MD",
    lat: 38.544,
    lng: -76.5879,
    id: 1840014982,
  },
  {
    name: "North Sioux City",
    state_id: "SD",
    lat: 42.535,
    lng: -96.4998,
    id: 1840000388,
  },
  {
    name: "Cumberland Center",
    state_id: "ME",
    lat: 43.7982,
    lng: -70.2541,
    id: 1840002550,
  },
  {
    name: "Williston",
    state_id: "SC",
    lat: 33.4018,
    lng: -81.422,
    id: 1840016870,
  },
  {
    name: "Weedsport",
    state_id: "NY",
    lat: 43.0482,
    lng: -76.5637,
    id: 1840004243,
  },
  {
    name: "Allendale",
    state_id: "SC",
    lat: 33.008,
    lng: -81.3092,
    id: 1840015758,
  },
  {
    name: "Las Lomas",
    state_id: "CA",
    lat: 36.8689,
    lng: -121.7317,
    id: 1840028099,
  },
  {
    name: "Simpsonville",
    state_id: "KY",
    lat: 38.2167,
    lng: -85.3514,
    id: 1840015206,
  },
  {
    name: "Churchill",
    state_id: "PA",
    lat: 40.4389,
    lng: -79.8417,
    id: 1840001218,
  },
  {
    name: "Somerset",
    state_id: "WI",
    lat: 45.1269,
    lng: -92.6756,
    id: 1840002129,
  },
  {
    name: "East Syracuse",
    state_id: "NY",
    lat: 43.0637,
    lng: -76.0697,
    id: 1840004332,
  },
  {
    name: "Cutten",
    state_id: "CA",
    lat: 40.7657,
    lng: -124.1446,
    id: 1840018715,
  },
  {
    name: "Florida",
    state_id: "NY",
    lat: 41.3312,
    lng: -74.3533,
    id: 1840004867,
  },
  {
    name: "Cornwall-on-Hudson",
    state_id: "NY",
    lat: 41.4369,
    lng: -74.0145,
    id: 1840004885,
  },
  {
    name: "Loogootee",
    state_id: "IN",
    lat: 38.6756,
    lng: -86.9141,
    id: 1840009734,
  },
  {
    name: "Chaffee",
    state_id: "MO",
    lat: 37.1812,
    lng: -89.6615,
    id: 1840007637,
  },
  {
    name: "Hooks",
    state_id: "TX",
    lat: 33.4707,
    lng: -94.2841,
    id: 1840020608,
  },
  {
    name: "New Hope",
    state_id: "AL",
    lat: 34.537,
    lng: -86.4128,
    id: 1840014646,
  },
  {
    name: "Woodlynne",
    state_id: "NJ",
    lat: 39.9167,
    lng: -75.0957,
    id: 1840033448,
  },
  {
    name: "Wailua",
    state_id: "HI",
    lat: 22.055,
    lng: -159.3406,
    id: 1840137116,
  },
  {
    name: "Lakeview",
    state_id: "OR",
    lat: 42.1918,
    lng: -120.3528,
    id: 1840021284,
  },
  {
    name: "Swift Trail Junction",
    state_id: "AZ",
    lat: 32.7305,
    lng: -109.7147,
    id: 1840019341,
  },
  {
    name: "Cabana Colony",
    state_id: "FL",
    lat: 26.8555,
    lng: -80.0865,
    id: 1840042855,
  },
  {
    name: "Castle Rock",
    state_id: "WA",
    lat: 46.2709,
    lng: -122.9051,
    id: 1840018501,
  },
  {
    name: "Blue Hills",
    state_id: "CT",
    lat: 41.8134,
    lng: -72.6954,
    id: 1840004775,
  },
  {
    name: "Schulenburg",
    state_id: "TX",
    lat: 29.682,
    lng: -96.9075,
    id: 1840022206,
  },
  {
    name: "Girard",
    state_id: "PA",
    lat: 42.0042,
    lng: -80.3192,
    id: 1840000479,
  },
  {
    name: "Hortonville",
    state_id: "WI",
    lat: 44.3368,
    lng: -88.6361,
    id: 1840002402,
  },
  {
    name: "Seaside Heights",
    state_id: "NJ",
    lat: 39.9452,
    lng: -74.079,
    id: 1840001487,
  },
  {
    name: "La Fayette",
    state_id: "AL",
    lat: 32.8997,
    lng: -85.4007,
    id: 1840008339,
  },
  {
    name: "Braddock Heights",
    state_id: "MD",
    lat: 39.4114,
    lng: -77.5009,
    id: 1840005707,
  },
  {
    name: "Brighton",
    state_id: "TN",
    lat: 35.4812,
    lng: -89.7354,
    id: 1840015417,
  },
  {
    name: "Indialantic",
    state_id: "FL",
    lat: 28.087,
    lng: -80.57,
    id: 1840017231,
  },
  {
    name: "Natalbany",
    state_id: "LA",
    lat: 30.5486,
    lng: -90.4848,
    id: 1840013907,
  },
  {
    name: "Louisville",
    state_id: "GA",
    lat: 32.9954,
    lng: -82.4,
    id: 1840015735,
  },
  {
    name: "Womelsdorf",
    state_id: "PA",
    lat: 40.3655,
    lng: -76.1864,
    id: 1840001195,
  },
  {
    name: "Monmouth Junction",
    state_id: "NJ",
    lat: 40.3811,
    lng: -74.5435,
    id: 1840005415,
  },
  {
    name: "South Oroville",
    state_id: "CA",
    lat: 39.4767,
    lng: -121.5439,
    id: 1840018776,
  },
  {
    name: "New Madrid",
    state_id: "MO",
    lat: 36.5879,
    lng: -89.5505,
    id: 1840008803,
  },
  {
    name: "Wise",
    state_id: "VA",
    lat: 36.9767,
    lng: -82.5809,
    id: 1840006485,
  },
  {
    name: "Sands Point",
    state_id: "NY",
    lat: 40.8531,
    lng: -73.7039,
    id: 1840005325,
  },
  {
    name: "Spring Lake",
    state_id: "NJ",
    lat: 40.1539,
    lng: -74.0273,
    id: 1840001366,
  },
  {
    name: "Eagle Lake",
    state_id: "FL",
    lat: 27.976,
    lng: -81.7573,
    id: 1840014133,
  },
  {
    name: "Channel Islands Beach",
    state_id: "CA",
    lat: 34.1586,
    lng: -119.2231,
    id: 1840028360,
  },
  {
    name: "Roslyn",
    state_id: "NY",
    lat: 40.799,
    lng: -73.6491,
    id: 1840005321,
  },
  {
    name: "Wyncote",
    state_id: "PA",
    lat: 40.0915,
    lng: -75.1462,
    id: 1840035270,
  },
  {
    name: "Jacksonwald",
    state_id: "PA",
    lat: 40.3279,
    lng: -75.8413,
    id: 1840035031,
  },
  {
    name: "Lodi",
    state_id: "OH",
    lat: 41.0348,
    lng: -82.0122,
    id: 1840011712,
  },
  {
    name: "Jacksonville",
    state_id: "OR",
    lat: 42.3127,
    lng: -122.9692,
    id: 1840018675,
  },
  {
    name: "Poplarville",
    state_id: "MS",
    lat: 30.8391,
    lng: -89.5306,
    id: 1840015003,
  },
  {
    name: "Six Mile Run",
    state_id: "NJ",
    lat: 40.473,
    lng: -74.5334,
    id: 1840039968,
  },
  {
    name: "Grape Creek",
    state_id: "TX",
    lat: 31.5817,
    lng: -100.5474,
    id: 1840037048,
  },
  {
    name: "Davis",
    state_id: "OK",
    lat: 34.4577,
    lng: -97.1655,
    id: 1840019256,
  },
  {
    name: "Lucerne",
    state_id: "CA",
    lat: 39.0697,
    lng: -122.776,
    id: 1840017548,
  },
  {
    name: "Hildale",
    state_id: "UT",
    lat: 37.0171,
    lng: -112.9786,
    id: 1840020315,
  },
  {
    name: "Gladwin",
    state_id: "MI",
    lat: 43.9837,
    lng: -84.4876,
    id: 1840002564,
  },
  {
    name: "Scott AFB",
    state_id: "IL",
    lat: 38.5412,
    lng: -89.8528,
    id: 1840073774,
  },
  {
    name: "Pocasset",
    state_id: "MA",
    lat: 41.6877,
    lng: -70.6202,
    id: 1840004761,
  },
  {
    name: "Marks",
    state_id: "MS",
    lat: 34.2535,
    lng: -90.2723,
    id: 1840015558,
  },
  {
    name: "Cliffwood Beach",
    state_id: "NJ",
    lat: 40.4426,
    lng: -74.2178,
    id: 1840005440,
  },
  {
    name: "Valley",
    state_id: "NE",
    lat: 41.3147,
    lng: -96.3565,
    id: 1840010287,
  },
  {
    name: "West Burlington",
    state_id: "IA",
    lat: 40.8215,
    lng: -91.1755,
    id: 1840010323,
  },
  {
    name: "Alderton",
    state_id: "WA",
    lat: 47.1718,
    lng: -122.2192,
    id: 1840037523,
  },
  {
    name: "Woodbury",
    state_id: "TN",
    lat: 35.8247,
    lng: -86.0725,
    id: 1840016243,
  },
  {
    name: "Bowling Green",
    state_id: "FL",
    lat: 27.638,
    lng: -81.8244,
    id: 1840014167,
  },
  {
    name: "Lawnside",
    state_id: "NJ",
    lat: 39.8673,
    lng: -75.0289,
    id: 1840001514,
  },
  {
    name: "Halfway House",
    state_id: "PA",
    lat: 40.2795,
    lng: -75.6402,
    id: 1840035425,
  },
  {
    name: "Great Neck Estates",
    state_id: "NY",
    lat: 40.7857,
    lng: -73.7396,
    id: 1840005291,
  },
  {
    name: "Pemberton Heights",
    state_id: "NJ",
    lat: 39.9565,
    lng: -74.6766,
    id: 1840005519,
  },
  {
    name: "Cherry Valley",
    state_id: "IL",
    lat: 42.2222,
    lng: -88.9716,
    id: 1840010106,
  },
  {
    name: "Brandenburg",
    state_id: "KY",
    lat: 37.9956,
    lng: -86.1773,
    id: 1840013213,
  },
  {
    name: "Chincoteague",
    state_id: "VA",
    lat: 37.9591,
    lng: -75.3496,
    id: 1840006323,
  },
  {
    name: "Central City",
    state_id: "NE",
    lat: 41.1133,
    lng: -98.0003,
    id: 1840003466,
  },
  {
    name: "Bartlett",
    state_id: "TX",
    lat: 30.7951,
    lng: -97.4325,
    id: 1840019555,
  },
  {
    name: "Colfax",
    state_id: "WA",
    lat: 46.903,
    lng: -117.3399,
    id: 1840018458,
  },
  {
    name: "Williamson",
    state_id: "WV",
    lat: 37.6741,
    lng: -82.271,
    id: 1840006359,
  },
  {
    name: "Ridgemark",
    state_id: "CA",
    lat: 36.8082,
    lng: -121.3623,
    id: 1840019015,
  },
  {
    name: "Aberdeen Proving Ground",
    state_id: "MD",
    lat: 39.4673,
    lng: -76.1308,
    id: 1840073583,
  },
  {
    name: "Pearl Beach",
    state_id: "MI",
    lat: 42.626,
    lng: -82.5942,
    id: 1840006575,
  },
  {
    name: "Montana City",
    state_id: "MT",
    lat: 46.5257,
    lng: -111.94,
    id: 1840018491,
  },
  {
    name: "Buckeye Lake",
    state_id: "OH",
    lat: 39.9355,
    lng: -82.4826,
    id: 1840010486,
  },
  {
    name: "Lake Wilderness",
    state_id: "VA",
    lat: 38.3036,
    lng: -77.7276,
    id: 1840043215,
  },
  {
    name: "La Villa",
    state_id: "TX",
    lat: 26.2964,
    lng: -97.927,
    id: 1840021033,
  },
  {
    name: "Williamstown",
    state_id: "WV",
    lat: 39.3987,
    lng: -81.4549,
    id: 1840005788,
  },
  {
    name: "Bald Knob",
    state_id: "AR",
    lat: 35.3123,
    lng: -91.5709,
    id: 1840013438,
  },
  {
    name: "Fearrington Village",
    state_id: "NC",
    lat: 35.7996,
    lng: -79.0818,
    id: 1840013406,
  },
  {
    name: "Bushnell",
    state_id: "IL",
    lat: 40.5518,
    lng: -90.5045,
    id: 1840007221,
  },
  {
    name: "Harbor Bluffs",
    state_id: "FL",
    lat: 27.908,
    lng: -82.827,
    id: 1840014140,
  },
  {
    name: "Shady Shores",
    state_id: "TX",
    lat: 33.1627,
    lng: -97.0394,
    id: 1840022992,
  },
  {
    name: "Homestead Valley",
    state_id: "CA",
    lat: 34.273,
    lng: -116.4145,
    id: 1840043055,
  },
  {
    name: "Ava",
    state_id: "MO",
    lat: 36.9546,
    lng: -92.6661,
    id: 1840007647,
  },
  {
    name: "Mountain View",
    state_id: "AR",
    lat: 35.8635,
    lng: -92.1041,
    id: 1840014491,
  },
  {
    name: "Knightstown",
    state_id: "IN",
    lat: 39.796,
    lng: -85.5312,
    id: 1840009480,
  },
  {
    name: "North Browning",
    state_id: "MT",
    lat: 48.5684,
    lng: -113.0171,
    id: 1840032847,
  },
  {
    name: "Shrub Oak",
    state_id: "NY",
    lat: 41.3256,
    lng: -73.8295,
    id: 1840004927,
  },
  {
    name: "Brackettville",
    state_id: "TX",
    lat: 29.3181,
    lng: -100.4109,
    id: 1840019661,
  },
  {
    name: "Lawrenceville",
    state_id: "VA",
    lat: 36.7564,
    lng: -77.8539,
    id: 1840006510,
  },
  {
    name: "Meadow Glade",
    state_id: "WA",
    lat: 45.7523,
    lng: -122.5616,
    id: 1840018517,
  },
  {
    name: "Zephyrhills North",
    state_id: "FL",
    lat: 28.252,
    lng: -82.1654,
    id: 1840073857,
  },
  {
    name: "Galena",
    state_id: "KS",
    lat: 37.0751,
    lng: -94.6353,
    id: 1840001716,
  },
  {
    name: "Belle Meade",
    state_id: "TN",
    lat: 36.0994,
    lng: -86.8562,
    id: 1840013346,
  },
  {
    name: "Oasis",
    state_id: "CA",
    lat: 33.5275,
    lng: -116.1261,
    id: 1840026987,
  },
  {
    name: "Covington",
    state_id: "OH",
    lat: 40.1174,
    lng: -84.3508,
    id: 1840012374,
  },
  {
    name: "Youngwood",
    state_id: "PA",
    lat: 40.2443,
    lng: -79.5812,
    id: 1840001167,
  },
  {
    name: "Fairfax",
    state_id: "IA",
    lat: 41.9281,
    lng: -91.7791,
    id: 1840008098,
  },
  {
    name: "Chenango Bridge",
    state_id: "NY",
    lat: 42.1716,
    lng: -75.8586,
    id: 1840025505,
  },
  {
    name: "Lake City",
    state_id: "PA",
    lat: 42.018,
    lng: -80.3466,
    id: 1840000480,
  },
  {
    name: "Montalvin Manor",
    state_id: "CA",
    lat: 37.9977,
    lng: -122.33,
    id: 1840028314,
  },
  {
    name: "David City",
    state_id: "NE",
    lat: 41.2549,
    lng: -97.1269,
    id: 1840003454,
  },
  {
    name: "Rushville",
    state_id: "IL",
    lat: 40.1199,
    lng: -90.5666,
    id: 1840009452,
  },
  {
    name: "Roebuck",
    state_id: "SC",
    lat: 34.8788,
    lng: -81.9646,
    id: 1840013514,
  },
  {
    name: "Gold Beach",
    state_id: "OR",
    lat: 42.3974,
    lng: -124.4171,
    id: 1840020078,
  },
  {
    name: "Kelly Ridge",
    state_id: "CA",
    lat: 39.5289,
    lng: -121.4662,
    id: 1840042820,
  },
  {
    name: "Swartzville",
    state_id: "PA",
    lat: 40.2287,
    lng: -76.0809,
    id: 1840035364,
  },
  {
    name: "Norco",
    state_id: "LA",
    lat: 29.9986,
    lng: -90.4036,
    id: 1840013983,
  },
  {
    name: "Crown Heights",
    state_id: "NY",
    lat: 41.6416,
    lng: -73.9287,
    id: 1840034044,
  },
  {
    name: "Biscoe",
    state_id: "NC",
    lat: 35.3585,
    lng: -79.7816,
    id: 1840015435,
  },
  {
    name: "Kewaunee",
    state_id: "WI",
    lat: 44.4607,
    lng: -87.5133,
    id: 1840002364,
  },
  {
    name: "Clayton",
    state_id: "AL",
    lat: 31.8822,
    lng: -85.4604,
    id: 1840001251,
  },
  {
    name: "Mountville",
    state_id: "PA",
    lat: 40.0402,
    lng: -76.4341,
    id: 1840003724,
  },
  {
    name: "Meadowood",
    state_id: "PA",
    lat: 40.8443,
    lng: -79.8939,
    id: 1840035429,
  },
  {
    name: "Eufaula",
    state_id: "OK",
    lat: 35.2922,
    lng: -95.5862,
    id: 1840020434,
  },
  {
    name: "Chico",
    state_id: "WA",
    lat: 47.6234,
    lng: -122.7198,
    id: 1840037630,
  },
  {
    name: "Poplar",
    state_id: "MT",
    lat: 48.1105,
    lng: -105.1969,
    id: 1840019775,
  },
  {
    name: "Lake Wissota",
    state_id: "WI",
    lat: 44.922,
    lng: -91.3015,
    id: 1840004021,
  },
  {
    name: "Almont",
    state_id: "MI",
    lat: 42.9208,
    lng: -83.0435,
    id: 1840010065,
  },
  {
    name: "Kingman",
    state_id: "KS",
    lat: 37.6476,
    lng: -98.1161,
    id: 1840001692,
  },
  {
    name: "Mount Morris",
    state_id: "MI",
    lat: 43.1176,
    lng: -83.6987,
    id: 1840002943,
  },
  {
    name: "Shell Point",
    state_id: "SC",
    lat: 32.3744,
    lng: -80.7523,
    id: 1840035794,
  },
  {
    name: "Ajo",
    state_id: "AZ",
    lat: 32.3923,
    lng: -112.8839,
    id: 1840018107,
  },
  {
    name: "Watonga",
    state_id: "OK",
    lat: 35.8661,
    lng: -98.4166,
    id: 1840021684,
  },
  {
    name: "Marion",
    state_id: "KY",
    lat: 37.3321,
    lng: -88.0795,
    id: 1840015248,
  },
  {
    name: "De Leon Springs",
    state_id: "FL",
    lat: 29.1172,
    lng: -81.3517,
    id: 1840013118,
  },
  {
    name: "Hypoluxo",
    state_id: "FL",
    lat: 26.5634,
    lng: -80.0531,
    id: 1840017253,
  },
  {
    name: "Statham",
    state_id: "GA",
    lat: 33.9646,
    lng: -83.6024,
    id: 1840015622,
  },
  {
    name: "East Oakdale",
    state_id: "CA",
    lat: 37.7854,
    lng: -120.8007,
    id: 1840018907,
  },
  {
    name: "West Grove",
    state_id: "PA",
    lat: 39.8206,
    lng: -75.8284,
    id: 1840001437,
  },
  {
    name: "Eagle Crest",
    state_id: "OR",
    lat: 44.2614,
    lng: -121.2995,
    id: 1840040419,
  },
  {
    name: "Jean Lafitte",
    state_id: "LA",
    lat: 29.7496,
    lng: -90.1036,
    id: 1840017204,
  },
  {
    name: "Gambrills",
    state_id: "MD",
    lat: 39.0929,
    lng: -76.6512,
    id: 1840024529,
  },
  {
    name: "Whitesboro",
    state_id: "NJ",
    lat: 39.0417,
    lng: -74.8674,
    id: 1840033443,
  },
  {
    name: "Thayer",
    state_id: "MO",
    lat: 36.5223,
    lng: -91.5408,
    id: 1840009937,
  },
  {
    name: "Ashland",
    state_id: "NE",
    lat: 41.0404,
    lng: -96.3707,
    id: 1840007132,
  },
  {
    name: "Stigler",
    state_id: "OK",
    lat: 35.2566,
    lng: -95.1185,
    id: 1840021790,
  },
  {
    name: "Holly Hills",
    state_id: "CO",
    lat: 39.6678,
    lng: -104.9218,
    id: 1840028590,
  },
  {
    name: "Drumright",
    state_id: "OK",
    lat: 35.9893,
    lng: -96.599,
    id: 1840019076,
  },
  {
    name: "Mountain Iron",
    state_id: "MN",
    lat: 47.5473,
    lng: -92.6252,
    id: 1840007692,
  },
  {
    name: "Rio Grande",
    state_id: "NJ",
    lat: 39.0196,
    lng: -74.8762,
    id: 1840005867,
  },
  {
    name: "Hanapepe",
    state_id: "HI",
    lat: 21.914,
    lng: -159.5874,
    id: 1840029478,
  },
  {
    name: "Homer",
    state_id: "LA",
    lat: 32.793,
    lng: -93.0582,
    id: 1840016937,
  },
  {
    name: "Hacienda San José",
    state_id: "PR",
    lat: 18.2409,
    lng: -66.0721,
    id: 1630025216,
  },
  {
    name: "Winner",
    state_id: "SD",
    lat: 43.3777,
    lng: -99.855,
    id: 1840002682,
  },
  {
    name: "Canadian",
    state_id: "TX",
    lat: 35.9096,
    lng: -100.3838,
    id: 1840019082,
  },
  {
    name: "Riverview",
    state_id: "MO",
    lat: 38.7441,
    lng: -90.211,
    id: 1840012821,
  },
  {
    name: "Arcola",
    state_id: "IL",
    lat: 39.6833,
    lng: -88.3013,
    id: 1840007310,
  },
  {
    name: "North Catasauqua",
    state_id: "PA",
    lat: 40.664,
    lng: -75.4741,
    id: 1840000976,
  },
  {
    name: "St. Clair",
    state_id: "PA",
    lat: 40.7211,
    lng: -76.1903,
    id: 1840002777,
  },
  {
    name: "Carson",
    state_id: "WA",
    lat: 45.7353,
    lng: -121.8219,
    id: 1840017391,
  },
  {
    name: "Lake City",
    state_id: "GA",
    lat: 33.6065,
    lng: -84.3411,
    id: 1840014817,
  },
  {
    name: "Chatfield",
    state_id: "MN",
    lat: 43.8445,
    lng: -92.1829,
    id: 1840006837,
  },
  {
    name: "Port Ludlow",
    state_id: "WA",
    lat: 47.9101,
    lng: -122.7006,
    id: 1840018388,
  },
  {
    name: "Holly Ridge",
    state_id: "NC",
    lat: 34.4953,
    lng: -77.527,
    id: 1840016531,
  },
  {
    name: "Oxoboxo River",
    state_id: "CT",
    lat: 41.4441,
    lng: -72.125,
    id: 1840073305,
  },
  {
    name: "Big Coppitt Key",
    state_id: "FL",
    lat: 24.5915,
    lng: -81.6574,
    id: 1840029021,
  },
  {
    name: "Black Canyon City",
    state_id: "AZ",
    lat: 34.0709,
    lng: -112.122,
    id: 1840017847,
  },
  {
    name: "Shorewood Forest",
    state_id: "IN",
    lat: 41.4598,
    lng: -87.1497,
    id: 1840026274,
  },
  {
    name: "Wilton",
    state_id: "IA",
    lat: 41.5898,
    lng: -91.0271,
    id: 1840010237,
  },
  {
    name: "Moscow",
    state_id: "PA",
    lat: 41.3417,
    lng: -75.5316,
    id: 1840003386,
  },
  {
    name: "Lyndonville",
    state_id: "VT",
    lat: 44.5352,
    lng: -72.0016,
    id: 1840004081,
  },
  {
    name: "Homedale",
    state_id: "ID",
    lat: 43.6154,
    lng: -116.9379,
    id: 1840020049,
  },
  {
    name: "Castle Point",
    state_id: "MO",
    lat: 38.7568,
    lng: -90.2485,
    id: 1840006125,
  },
  {
    name: "Carrabelle",
    state_id: "FL",
    lat: 29.8555,
    lng: -84.6685,
    id: 1840015055,
  },
  {
    name: "Potala Pastillo",
    state_id: "PR",
    lat: 17.9921,
    lng: -66.4966,
    id: 1630023579,
  },
  {
    name: "Park View",
    state_id: "IA",
    lat: 41.6922,
    lng: -90.5393,
    id: 1840004813,
  },
  {
    name: "Perkins",
    state_id: "OK",
    lat: 35.978,
    lng: -97.0299,
    id: 1840020386,
  },
  {
    name: "Minster",
    state_id: "OH",
    lat: 40.3947,
    lng: -84.3789,
    id: 1840012070,
  },
  {
    name: "Owensville",
    state_id: "MO",
    lat: 38.349,
    lng: -91.4974,
    id: 1840009641,
  },
  {
    name: "Hillsboro",
    state_id: "KS",
    lat: 38.3527,
    lng: -97.1992,
    id: 1840001661,
  },
  {
    name: "Country Squire Lakes",
    state_id: "IN",
    lat: 39.038,
    lng: -85.6849,
    id: 1840030082,
  },
  {
    name: "Clearbrook Park",
    state_id: "NJ",
    lat: 40.3096,
    lng: -74.4644,
    id: 1840033478,
  },
  {
    name: "Amery",
    state_id: "WI",
    lat: 45.3045,
    lng: -92.3635,
    id: 1840002049,
  },
  {
    name: "Tilton Northfield",
    state_id: "NH",
    lat: 43.443,
    lng: -71.5937,
    id: 1840073462,
  },
  {
    name: "Warden",
    state_id: "WA",
    lat: 46.9672,
    lng: -119.0523,
    id: 1840021103,
  },
  {
    name: "Bar Nunn",
    state_id: "WY",
    lat: 42.922,
    lng: -106.3475,
    id: 1840020060,
  },
  {
    name: "Aguada",
    state_id: "PR",
    lat: 18.3804,
    lng: -67.1884,
    id: 1630023528,
  },
  {
    name: "Cache",
    state_id: "OK",
    lat: 34.6297,
    lng: -98.6181,
    id: 1840019203,
  },
  {
    name: "Bayview",
    state_id: "CA",
    lat: 40.7654,
    lng: -124.1787,
    id: 1840017484,
  },
  {
    name: "Northeast Ithaca",
    state_id: "NY",
    lat: 42.4703,
    lng: -76.4623,
    id: 1840073642,
  },
  {
    name: "Osage City",
    state_id: "KS",
    lat: 38.6337,
    lng: -95.8218,
    id: 1840003836,
  },
  {
    name: "Pleasant Hill",
    state_id: "PA",
    lat: 40.3369,
    lng: -76.4481,
    id: 1840035153,
  },
  {
    name: "Gleed",
    state_id: "WA",
    lat: 46.6594,
    lng: -120.6025,
    id: 1840017370,
  },
  {
    name: "Penngrove",
    state_id: "CA",
    lat: 38.3005,
    lng: -122.6707,
    id: 1840024619,
  },
  {
    name: "Monument Beach",
    state_id: "MA",
    lat: 41.7192,
    lng: -70.6061,
    id: 1840004756,
  },
  {
    name: "Douglas",
    state_id: "MI",
    lat: 42.641,
    lng: -86.2082,
    id: 1840006925,
  },
  {
    name: "Daleville",
    state_id: "VA",
    lat: 37.415,
    lng: -79.9132,
    id: 1840006376,
  },
  {
    name: "Kenhorst",
    state_id: "PA",
    lat: 40.3069,
    lng: -75.9438,
    id: 1840001177,
  },
  {
    name: "Roseau",
    state_id: "MN",
    lat: 48.8446,
    lng: -95.7623,
    id: 1840008814,
  },
  {
    name: "Richmond",
    state_id: "UT",
    lat: 41.923,
    lng: -111.8075,
    id: 1840020109,
  },
  {
    name: "North Crossett",
    state_id: "AR",
    lat: 33.171,
    lng: -91.9367,
    id: 1840013771,
  },
  {
    name: "Lake Helen",
    state_id: "FL",
    lat: 28.9828,
    lng: -81.2306,
    id: 1840015079,
  },
  {
    name: "Brockway",
    state_id: "PA",
    lat: 41.2469,
    lng: -78.7929,
    id: 1840003468,
  },
  {
    name: "Oak Grove",
    state_id: "VA",
    lat: 38.9845,
    lng: -77.4174,
    id: 1840024506,
  },
  {
    name: "Wernersville",
    state_id: "PA",
    lat: 40.3302,
    lng: -76.082,
    id: 1840001193,
  },
  {
    name: "Fraser",
    state_id: "CO",
    lat: 39.9303,
    lng: -105.803,
    id: 1840021406,
  },
  {
    name: "Bennett",
    state_id: "CO",
    lat: 39.7367,
    lng: -104.4287,
    id: 1840020193,
  },
  {
    name: "Linden",
    state_id: "AZ",
    lat: 34.2625,
    lng: -110.1424,
    id: 1840022580,
  },
  {
    name: "Lakewood",
    state_id: "NY",
    lat: 42.0991,
    lng: -79.32,
    id: 1840004567,
  },
  {
    name: "Seward",
    state_id: "AK",
    lat: 60.1124,
    lng: -149.3925,
    id: 1840023423,
  },
  {
    name: "Oconto Falls",
    state_id: "WI",
    lat: 44.8738,
    lng: -88.1444,
    id: 1840001576,
  },
  {
    name: "Lake Arthur",
    state_id: "LA",
    lat: 30.0795,
    lng: -92.6773,
    id: 1840017196,
  },
  {
    name: "Delhi",
    state_id: "LA",
    lat: 32.4529,
    lng: -91.49,
    id: 1840016977,
  },
  {
    name: "Village of Grosse Pointe Shores",
    state_id: "MI",
    lat: 42.437,
    lng: -82.8787,
    id: 1840073805,
  },
  {
    name: "Rosita",
    state_id: "TX",
    lat: 28.6244,
    lng: -100.4287,
    id: 1840073268,
  },
  {
    name: "North Kingsville",
    state_id: "OH",
    lat: 41.9189,
    lng: -80.6724,
    id: 1840003338,
  },
  {
    name: "Guntown",
    state_id: "MS",
    lat: 34.4447,
    lng: -88.6641,
    id: 1840016649,
  },
  {
    name: "Beach City",
    state_id: "TX",
    lat: 29.7269,
    lng: -94.8549,
    id: 1840019634,
  },
  {
    name: "Circle D-KC Estates",
    state_id: "TX",
    lat: 30.1611,
    lng: -97.2349,
    id: 1840073284,
  },
  {
    name: "Park City",
    state_id: "TN",
    lat: 35.0782,
    lng: -86.5834,
    id: 1840024961,
  },
  {
    name: "McGovern",
    state_id: "PA",
    lat: 40.2385,
    lng: -80.2257,
    id: 1840005433,
  },
  {
    name: "Poydras",
    state_id: "LA",
    lat: 29.8625,
    lng: -89.8877,
    id: 1840014004,
  },
  {
    name: "Winnie",
    state_id: "TX",
    lat: 29.8167,
    lng: -94.3807,
    id: 1840019632,
  },
  {
    name: "Marshville",
    state_id: "NC",
    lat: 34.9867,
    lng: -80.3685,
    id: 1840016454,
  },
  {
    name: "Little Flock",
    state_id: "AR",
    lat: 36.3854,
    lng: -94.1364,
    id: 1840015284,
  },
  {
    name: "Sneads Ferry",
    state_id: "NC",
    lat: 34.5564,
    lng: -77.3824,
    id: 1840013557,
  },
  {
    name: "Knob Noster",
    state_id: "MO",
    lat: 38.7674,
    lng: -93.5616,
    id: 1840008577,
  },
  {
    name: "Windsor",
    state_id: "MO",
    lat: 38.5319,
    lng: -93.5227,
    id: 1840010791,
  },
  {
    name: "Heritage Lake",
    state_id: "IN",
    lat: 39.7288,
    lng: -86.7123,
    id: 1840026610,
  },
  {
    name: "Rising Sun",
    state_id: "MD",
    lat: 39.7003,
    lng: -76.0587,
    id: 1840005642,
  },
  {
    name: "Sale Creek",
    state_id: "TN",
    lat: 35.3895,
    lng: -85.0875,
    id: 1840024960,
  },
  {
    name: "Sisters",
    state_id: "OR",
    lat: 44.292,
    lng: -121.5534,
    id: 1840021262,
  },
  {
    name: "Munising",
    state_id: "MI",
    lat: 46.4172,
    lng: -86.6439,
    id: 1840003924,
  },
  {
    name: "Greenwood",
    state_id: "PA",
    lat: 40.5325,
    lng: -78.3589,
    id: 1840026416,
  },
  {
    name: "Tyndall AFB",
    state_id: "FL",
    lat: 30.0856,
    lng: -85.6075,
    id: 1840035791,
  },
  {
    name: "Garden View",
    state_id: "PA",
    lat: 41.2569,
    lng: -77.049,
    id: 1840034979,
  },
  {
    name: "South Padre Island",
    state_id: "TX",
    lat: 26.1218,
    lng: -97.1703,
    id: 1840023168,
  },
  {
    name: "West Hammond",
    state_id: "NM",
    lat: 36.6812,
    lng: -108.0492,
    id: 1840040220,
  },
  {
    name: "Lindsay",
    state_id: "OK",
    lat: 34.8392,
    lng: -97.6105,
    id: 1840020478,
  },
  {
    name: "Brighton",
    state_id: "AL",
    lat: 33.4393,
    lng: -86.9454,
    id: 1840013734,
  },
  {
    name: "Norton Center",
    state_id: "MA",
    lat: 41.9726,
    lng: -71.1854,
    id: 1840073525,
  },
  {
    name: "Harveys Lake",
    state_id: "PA",
    lat: 41.3626,
    lng: -76.0381,
    id: 1840000752,
  },
  {
    name: "Central Heights-Midland City",
    state_id: "AZ",
    lat: 33.4,
    lng: -110.8153,
    id: 1840073906,
  },
  {
    name: "Pine Mountain Lake",
    state_id: "CA",
    lat: 37.8599,
    lng: -120.1843,
    id: 1840076099,
  },
  {
    name: "Sublimity",
    state_id: "OR",
    lat: 44.8293,
    lng: -122.7925,
    id: 1840021228,
  },
  {
    name: "Bethel Heights",
    state_id: "AR",
    lat: 36.2262,
    lng: -94.1282,
    id: 1840013320,
  },
  {
    name: "Voorheesville",
    state_id: "NY",
    lat: 42.6518,
    lng: -73.9349,
    id: 1840004493,
  },
  {
    name: "Rose Hills",
    state_id: "CA",
    lat: 34.009,
    lng: -118.0419,
    id: 1840075983,
  },
  {
    name: "Plainsboro Center",
    state_id: "NJ",
    lat: 40.3311,
    lng: -74.5937,
    id: 1840033473,
  },
  {
    name: "Clever",
    state_id: "MO",
    lat: 37.0326,
    lng: -93.474,
    id: 1840007644,
  },
  {
    name: "Fairfield Harbour",
    state_id: "NC",
    lat: 35.0706,
    lng: -76.9571,
    id: 1840013076,
  },
  {
    name: "Albany",
    state_id: "MN",
    lat: 45.6284,
    lng: -94.5677,
    id: 1840006683,
  },
  {
    name: "Troutman",
    state_id: "NC",
    lat: 35.6875,
    lng: -80.883,
    id: 1840016217,
  },
  {
    name: "Dodge Center",
    state_id: "MN",
    lat: 44.0289,
    lng: -92.8507,
    id: 1840006808,
  },
  {
    name: "Fairmount",
    state_id: "IN",
    lat: 40.4173,
    lng: -85.6477,
    id: 1840010394,
  },
  {
    name: "Marietta",
    state_id: "OK",
    lat: 33.9355,
    lng: -97.1242,
    id: 1840020561,
  },
  {
    name: "Hudson",
    state_id: "CO",
    lat: 40.0898,
    lng: -104.6216,
    id: 1840021368,
  },
  {
    name: "Ithaca",
    state_id: "MI",
    lat: 43.2912,
    lng: -84.5956,
    id: 1840002880,
  },
  {
    name: "Millen",
    state_id: "GA",
    lat: 32.8071,
    lng: -81.9423,
    id: 1840014867,
  },
  {
    name: "Kalkaska",
    state_id: "MI",
    lat: 44.7322,
    lng: -85.1781,
    id: 1840010929,
  },
  {
    name: "Crest",
    state_id: "CA",
    lat: 32.8,
    lng: -116.8671,
    id: 1840028340,
  },
  {
    name: "Ford City",
    state_id: "PA",
    lat: 40.7693,
    lng: -79.5327,
    id: 1840003516,
  },
  {
    name: "Onawa",
    state_id: "IA",
    lat: 42.0268,
    lng: -96.0905,
    id: 1840000489,
  },
  {
    name: "West Simsbury",
    state_id: "CT",
    lat: 41.8747,
    lng: -72.8448,
    id: 1840003270,
  },
  {
    name: "Pawnee",
    state_id: "IL",
    lat: 39.5937,
    lng: -89.5824,
    id: 1840012452,
  },
  {
    name: "Lakeside Park",
    state_id: "KY",
    lat: 39.0338,
    lng: -84.5674,
    id: 1840014269,
  },
  {
    name: "Gervais",
    state_id: "OR",
    lat: 45.1078,
    lng: -122.8962,
    id: 1840019960,
  },
  {
    name: "Loris",
    state_id: "SC",
    lat: 34.0575,
    lng: -78.8876,
    id: 1840015591,
  },
  {
    name: "Westminster",
    state_id: "LA",
    lat: 30.408,
    lng: -91.0906,
    id: 1840031110,
  },
  {
    name: "Talty",
    state_id: "TX",
    lat: 32.6945,
    lng: -96.4007,
    id: 1840023219,
  },
  {
    name: "Platteville",
    state_id: "CO",
    lat: 40.2221,
    lng: -104.834,
    id: 1840021360,
  },
  {
    name: "Windsor",
    state_id: "VA",
    lat: 36.8095,
    lng: -76.7406,
    id: 1840006488,
  },
  {
    name: "Westworth Village",
    state_id: "TX",
    lat: 32.7599,
    lng: -97.4239,
    id: 1840074884,
  },
  {
    name: "Wilson's Mills",
    state_id: "NC",
    lat: 35.5863,
    lng: -78.3644,
    id: 1840032896,
  },
  {
    name: "North Shore",
    state_id: "CA",
    lat: 33.5157,
    lng: -115.9092,
    id: 1840038388,
  },
  {
    name: "East Gaffney",
    state_id: "SC",
    lat: 35.0846,
    lng: -81.6219,
    id: 1840013080,
  },
  {
    name: "Kulpmont",
    state_id: "PA",
    lat: 40.7951,
    lng: -76.4718,
    id: 1840000842,
  },
  {
    name: "Sisco Heights",
    state_id: "WA",
    lat: 48.1187,
    lng: -122.1076,
    id: 1840037510,
  },
  {
    name: "Woodville",
    state_id: "FL",
    lat: 30.3114,
    lng: -84.2595,
    id: 1840013944,
  },
  {
    name: "Caledonia",
    state_id: "MN",
    lat: 43.6344,
    lng: -91.5009,
    id: 1840006833,
  },
  {
    name: "Potterville",
    state_id: "MI",
    lat: 42.6299,
    lng: -84.7433,
    id: 1840003106,
  },
  {
    name: "Dulce",
    state_id: "NM",
    lat: 36.9399,
    lng: -107.0049,
    id: 1840018985,
  },
  {
    name: "Green Forest",
    state_id: "AR",
    lat: 36.3344,
    lng: -93.4286,
    id: 1840013322,
  },
  {
    name: "Lakewood",
    state_id: "SC",
    lat: 33.8429,
    lng: -80.3492,
    id: 1840035840,
  },
  {
    name: "Norway",
    state_id: "MI",
    lat: 45.8018,
    lng: -87.9132,
    id: 1840001990,
  },
  {
    name: "Herald Harbor",
    state_id: "MD",
    lat: 39.0516,
    lng: -76.5746,
    id: 1840005918,
  },
  {
    name: "Burnettown",
    state_id: "SC",
    lat: 33.5186,
    lng: -81.8634,
    id: 1840015666,
  },
  {
    name: "South Rosemary",
    state_id: "NC",
    lat: 36.4463,
    lng: -77.7065,
    id: 1840033024,
  },
  {
    name: "Sixteen Mile Stand",
    state_id: "OH",
    lat: 39.2749,
    lng: -84.3265,
    id: 1840034315,
  },
  {
    name: "McLeansboro",
    state_id: "IL",
    lat: 38.0902,
    lng: -88.5386,
    id: 1840008671,
  },
  {
    name: "Pinch",
    state_id: "WV",
    lat: 38.4062,
    lng: -81.481,
    id: 1840006191,
  },
  {
    name: "Longtown",
    state_id: "OK",
    lat: 35.2405,
    lng: -95.5183,
    id: 1840034462,
  },
  {
    name: "Refugio",
    state_id: "TX",
    lat: 28.3073,
    lng: -97.275,
    id: 1840022248,
  },
  {
    name: "Ballville",
    state_id: "OH",
    lat: 41.3269,
    lng: -83.1367,
    id: 1840004905,
  },
  {
    name: "Luverne",
    state_id: "AL",
    lat: 31.7186,
    lng: -86.2687,
    id: 1840004042,
  },
  {
    name: "Dayton",
    state_id: "OR",
    lat: 45.2198,
    lng: -123.0781,
    id: 1840018574,
  },
  {
    name: "Chester Heights",
    state_id: "PA",
    lat: 39.8926,
    lng: -75.4698,
    id: 1840000691,
  },
  {
    name: "Van",
    state_id: "TX",
    lat: 32.5242,
    lng: -95.6373,
    id: 1840022068,
  },
  {
    name: "Odem",
    state_id: "TX",
    lat: 27.9463,
    lng: -97.5865,
    id: 1840021005,
  },
  {
    name: "Coeburn",
    state_id: "VA",
    lat: 36.9444,
    lng: -82.4698,
    id: 1840006482,
  },
  {
    name: "Manchester",
    state_id: "PA",
    lat: 40.0614,
    lng: -76.7194,
    id: 1840001455,
  },
  {
    name: "Gilman",
    state_id: "IL",
    lat: 40.7627,
    lng: -87.9973,
    id: 1840008288,
  },
  {
    name: "Oak Harbor",
    state_id: "OH",
    lat: 41.5122,
    lng: -83.1425,
    id: 1840011503,
  },
  {
    name: "Vista Santa Rosa",
    state_id: "CA",
    lat: 33.6227,
    lng: -116.2126,
    id: 1840028414,
  },
  {
    name: "Koloa",
    state_id: "HI",
    lat: 21.903,
    lng: -159.461,
    id: 1840029510,
  },
  {
    name: "Petersburg",
    state_id: "WV",
    lat: 38.9957,
    lng: -79.1276,
    id: 1840005861,
  },
  {
    name: "West Ishpeming",
    state_id: "MI",
    lat: 46.4884,
    lng: -87.717,
    id: 1840007674,
  },
  {
    name: "Mount Vernon",
    state_id: "TX",
    lat: 33.176,
    lng: -95.2253,
    id: 1840023005,
  },
  {
    name: "Osseo",
    state_id: "MN",
    lat: 45.1179,
    lng: -93.3992,
    id: 1840008928,
  },
  {
    name: "Wind Gap",
    state_id: "PA",
    lat: 40.8484,
    lng: -75.2917,
    id: 1840000985,
  },
  {
    name: "Sylva",
    state_id: "NC",
    lat: 35.3754,
    lng: -83.2185,
    id: 1840016347,
  },
  {
    name: "Weatogue",
    state_id: "CT",
    lat: 41.8459,
    lng: -72.8294,
    id: 1840003268,
  },
  {
    name: "Crescent City",
    state_id: "FL",
    lat: 29.4375,
    lng: -81.5173,
    id: 1840015061,
  },
  {
    name: "Cajah's Mountain",
    state_id: "NC",
    lat: 35.8507,
    lng: -81.5361,
    id: 1840033010,
  },
  {
    name: "Palisade",
    state_id: "CO",
    lat: 39.1083,
    lng: -108.3573,
    id: 1840022465,
  },
  {
    name: "Seven Fields",
    state_id: "PA",
    lat: 40.6867,
    lng: -80.0639,
    id: 1840003514,
  },
  {
    name: "Agua Fria",
    state_id: "NM",
    lat: 35.6615,
    lng: -106.0149,
    id: 1840017766,
  },
  {
    name: "Val Verde",
    state_id: "CA",
    lat: 34.4504,
    lng: -118.6717,
    id: 1840019212,
  },
  {
    name: "New Cordell",
    state_id: "OK",
    lat: 35.2973,
    lng: -98.9815,
    id: 1840037403,
  },
  {
    name: "West Bishop",
    state_id: "CA",
    lat: 37.3571,
    lng: -118.4542,
    id: 1840028404,
  },
  {
    name: "Springdale",
    state_id: "SC",
    lat: 33.9616,
    lng: -81.1119,
    id: 1840017969,
  },
  {
    name: "North Hartsville",
    state_id: "SC",
    lat: 34.401,
    lng: -80.071,
    id: 1840013609,
  },
  {
    name: "Palmhurst",
    state_id: "TX",
    lat: 26.2583,
    lng: -98.2953,
    id: 1840021027,
  },
  {
    name: "Snowmass Village",
    state_id: "CO",
    lat: 39.2207,
    lng: -106.9386,
    id: 1840022467,
  },
  {
    name: "Val Verde Park",
    state_id: "TX",
    lat: 29.3746,
    lng: -100.8306,
    id: 1840037083,
  },
  {
    name: "El Paso",
    state_id: "IL",
    lat: 40.7405,
    lng: -89.0182,
    id: 1840008293,
  },
  {
    name: "Electra",
    state_id: "TX",
    lat: 34.0325,
    lng: -98.92,
    id: 1840020542,
  },
  {
    name: "Pearisburg",
    state_id: "VA",
    lat: 37.3289,
    lng: -80.7275,
    id: 1840006433,
  },
  {
    name: "Kingston Springs",
    state_id: "TN",
    lat: 36.0841,
    lng: -87.1039,
    id: 1840016090,
  },
  {
    name: "Evendale",
    state_id: "OH",
    lat: 39.2509,
    lng: -84.4268,
    id: 1840003817,
  },
  {
    name: "Knoxville",
    state_id: "IL",
    lat: 40.907,
    lng: -90.2857,
    id: 1840008278,
  },
  {
    name: "Hartford",
    state_id: "KY",
    lat: 37.4506,
    lng: -86.893,
    id: 1840013231,
  },
  {
    name: "Weed",
    state_id: "CA",
    lat: 41.4128,
    lng: -122.3816,
    id: 1840021319,
  },
  {
    name: "Cohasset",
    state_id: "MN",
    lat: 47.2389,
    lng: -93.6396,
    id: 1840006610,
  },
  {
    name: "Reynoldsville",
    state_id: "PA",
    lat: 41.0946,
    lng: -78.888,
    id: 1840003472,
  },
  {
    name: "Mount Healthy Heights",
    state_id: "OH",
    lat: 39.2702,
    lng: -84.5693,
    id: 1840034389,
  },
  {
    name: "Polk City",
    state_id: "FL",
    lat: 28.1805,
    lng: -81.8295,
    id: 1840017242,
  },
  {
    name: "Apison",
    state_id: "TN",
    lat: 35.0105,
    lng: -85.0089,
    id: 1840024958,
  },
  {
    name: "Ashley",
    state_id: "PA",
    lat: 41.2128,
    lng: -75.8994,
    id: 1840000741,
  },
  {
    name: "Santa Rosa",
    state_id: "TX",
    lat: 26.2562,
    lng: -97.8253,
    id: 1840023167,
  },
  {
    name: "Tangerine",
    state_id: "FL",
    lat: 28.7589,
    lng: -81.6341,
    id: 1840014088,
  },
  {
    name: "Norton",
    state_id: "KS",
    lat: 39.8362,
    lng: -99.8916,
    id: 1840003767,
  },
  {
    name: "Youngsville",
    state_id: "PA",
    lat: 41.8524,
    lng: -79.3167,
    id: 1840003335,
  },
  {
    name: "Ravenel",
    state_id: "SC",
    lat: 32.7768,
    lng: -80.2272,
    id: 1840018341,
  },
  {
    name: "Wentworth",
    state_id: "NC",
    lat: 36.3914,
    lng: -79.751,
    id: 1840016056,
  },
  {
    name: "Fayetteville",
    state_id: "WV",
    lat: 38.0622,
    lng: -81.1084,
    id: 1840006293,
  },
  {
    name: "Hopkins",
    state_id: "SC",
    lat: 33.8983,
    lng: -80.86,
    id: 1840026977,
  },
  {
    name: "Lena",
    state_id: "IL",
    lat: 42.3786,
    lng: -89.8217,
    id: 1840011111,
  },
  {
    name: "Monticello",
    state_id: "GA",
    lat: 33.2955,
    lng: -83.686,
    id: 1840014832,
  },
  {
    name: "Wellford",
    state_id: "SC",
    lat: 34.9615,
    lng: -82.0922,
    id: 1840015483,
  },
  {
    name: "Piedra Aguza",
    state_id: "PR",
    lat: 18.0339,
    lng: -66.4949,
    id: 1630027034,
  },
  {
    name: "Butler",
    state_id: "IN",
    lat: 41.4274,
    lng: -84.8721,
    id: 1840007099,
  },
  {
    name: "Tobaccoville",
    state_id: "NC",
    lat: 36.2256,
    lng: -80.3591,
    id: 1840017729,
  },
  {
    name: "Skippers Corner",
    state_id: "NC",
    lat: 34.3292,
    lng: -77.9141,
    id: 1840032999,
  },
  {
    name: "Cambridge",
    state_id: "WI",
    lat: 43.0071,
    lng: -89.0214,
    id: 1840002909,
  },
  {
    name: "Hamilton",
    state_id: "IL",
    lat: 40.3899,
    lng: -91.3623,
    id: 1840007220,
  },
  {
    name: "Wendell",
    state_id: "ID",
    lat: 42.7745,
    lng: -114.7025,
    id: 1840021296,
  },
  {
    name: "Desert Aire",
    state_id: "WA",
    lat: 46.6873,
    lng: -119.9311,
    id: 1840037611,
  },
  {
    name: "Hayfork",
    state_id: "CA",
    lat: 40.561,
    lng: -123.1299,
    id: 1840017487,
  },
  {
    name: "Munsey Park",
    state_id: "NY",
    lat: 40.799,
    lng: -73.6799,
    id: 1840005311,
  },
  {
    name: "Halesite",
    state_id: "NY",
    lat: 40.8864,
    lng: -73.4139,
    id: 1840005075,
  },
  {
    name: "Bonners Ferry",
    state_id: "ID",
    lat: 48.6928,
    lng: -116.3183,
    id: 1840018352,
  },
  {
    name: "Follansbee",
    state_id: "WV",
    lat: 40.3385,
    lng: -80.5976,
    id: 1840005477,
  },
  {
    name: "Clarion",
    state_id: "IA",
    lat: 42.7319,
    lng: -93.7297,
    id: 1840006904,
  },
  {
    name: "Bermuda Run",
    state_id: "NC",
    lat: 36.0033,
    lng: -80.4311,
    id: 1840015357,
  },
  {
    name: "Newport",
    state_id: "WA",
    lat: 48.1788,
    lng: -117.0543,
    id: 1840019765,
  },
  {
    name: "Lakehurst",
    state_id: "NJ",
    lat: 40.0132,
    lng: -74.3201,
    id: 1840001491,
  },
  {
    name: "Valentine",
    state_id: "NE",
    lat: 42.8739,
    lng: -100.5498,
    id: 1840010081,
  },
  {
    name: "Abernathy",
    state_id: "TX",
    lat: 33.8389,
    lng: -101.823,
    id: 1840019272,
  },
  {
    name: "Lanai City",
    state_id: "HI",
    lat: 20.8279,
    lng: -156.9147,
    id: 1840029515,
  },
  {
    name: "Pine Ridge",
    state_id: "PA",
    lat: 41.1355,
    lng: -74.9923,
    id: 1840035456,
  },
  {
    name: "Brooklyn",
    state_id: "MI",
    lat: 42.1058,
    lng: -84.2491,
    id: 1840010118,
  },
  {
    name: "Jesup",
    state_id: "IA",
    lat: 42.4746,
    lng: -92.0657,
    id: 1840008059,
  },
  {
    name: "Moonachie",
    state_id: "NJ",
    lat: 40.8409,
    lng: -74.059,
    id: 1840000907,
  },
  {
    name: "Devola",
    state_id: "OH",
    lat: 39.4739,
    lng: -81.468,
    id: 1840005728,
  },
  {
    name: "Inez",
    state_id: "TX",
    lat: 28.8718,
    lng: -96.7959,
    id: 1840018279,
  },
  {
    name: "Ridgeway",
    state_id: "AK",
    lat: 60.5305,
    lng: -151.0301,
    id: 1840023687,
  },
  {
    name: "Arcadia",
    state_id: "LA",
    lat: 32.5506,
    lng: -92.9218,
    id: 1840015805,
  },
  {
    name: "Stroud",
    state_id: "OK",
    lat: 35.768,
    lng: -96.6474,
    id: 1840021709,
  },
  {
    name: "White Mountain Lake",
    state_id: "AZ",
    lat: 34.3441,
    lng: -109.9879,
    id: 1840022587,
  },
  {
    name: "Glen Rose",
    state_id: "TX",
    lat: 32.2462,
    lng: -97.7441,
    id: 1840020782,
  },
  {
    name: "St. Marys",
    state_id: "KS",
    lat: 39.1942,
    lng: -96.0643,
    id: 1840009589,
  },
  {
    name: "Fairmont",
    state_id: "IL",
    lat: 41.5614,
    lng: -88.0587,
    id: 1840004817,
  },
  {
    name: "Gray Summit",
    state_id: "MO",
    lat: 38.4951,
    lng: -90.8173,
    id: 1840006160,
  },
  {
    name: "Mangum",
    state_id: "OK",
    lat: 34.8783,
    lng: -99.504,
    id: 1840020466,
  },
  {
    name: "Granite Falls",
    state_id: "MN",
    lat: 44.8107,
    lng: -95.5375,
    id: 1840007851,
  },
  {
    name: "Havana",
    state_id: "FL",
    lat: 30.6298,
    lng: -84.4133,
    id: 1840015912,
  },
  {
    name: "Collierville",
    state_id: "CA",
    lat: 38.2141,
    lng: -121.2675,
    id: 1840022522,
  },
  {
    name: "West Sand Lake",
    state_id: "NY",
    lat: 42.6403,
    lng: -73.6032,
    id: 1840004434,
  },
  {
    name: "Bawcomville",
    state_id: "LA",
    lat: 32.4703,
    lng: -92.1736,
    id: 1840025899,
  },
  {
    name: "Many",
    state_id: "LA",
    lat: 31.5663,
    lng: -93.4779,
    id: 1840017063,
  },
  {
    name: "Heber-Overgaard",
    state_id: "AZ",
    lat: 34.4141,
    lng: -110.5696,
    id: 1840074050,
  },
  {
    name: "North Boston",
    state_id: "NY",
    lat: 42.6773,
    lng: -78.7797,
    id: 1840004377,
  },
  {
    name: "Aspinwall",
    state_id: "PA",
    lat: 40.493,
    lng: -79.9038,
    id: 1840001199,
  },
  {
    name: "La Luisa",
    state_id: "PR",
    lat: 18.4488,
    lng: -66.5079,
    id: 1630023561,
  },
  {
    name: "Hyde Park",
    state_id: "PA",
    lat: 40.3755,
    lng: -75.924,
    id: 1840149782,
  },
  {
    name: "White Salmon",
    state_id: "WA",
    lat: 45.7283,
    lng: -121.4856,
    id: 1840021191,
  },
  {
    name: "Timberville",
    state_id: "VA",
    lat: 38.634,
    lng: -78.773,
    id: 1840006139,
  },
  {
    name: "Bohners Lake",
    state_id: "WI",
    lat: 42.6241,
    lng: -88.2879,
    id: 1840004481,
  },
  {
    name: "Solon",
    state_id: "IA",
    lat: 41.8055,
    lng: -91.4961,
    id: 1840009229,
  },
  {
    name: "Guilford Center",
    state_id: "CT",
    lat: 41.2818,
    lng: -72.6763,
    id: 1840073296,
  },
  {
    name: "Fayette",
    state_id: "MO",
    lat: 39.147,
    lng: -92.6857,
    id: 1840008517,
  },
  {
    name: "England",
    state_id: "AR",
    lat: 34.546,
    lng: -91.969,
    id: 1840013529,
  },
  {
    name: "Tusculum",
    state_id: "TN",
    lat: 36.175,
    lng: -82.7454,
    id: 1840015307,
  },
  {
    name: "Strasburg",
    state_id: "OH",
    lat: 40.6007,
    lng: -81.5295,
    id: 1840012097,
  },
  {
    name: "Seminole Manor",
    state_id: "FL",
    lat: 26.584,
    lng: -80.1001,
    id: 1840029003,
  },
  {
    name: "Hawthorne",
    state_id: "NV",
    lat: 38.5249,
    lng: -118.627,
    id: 1840017564,
  },
  {
    name: "Bloomingdale",
    state_id: "GA",
    lat: 32.125,
    lng: -81.3077,
    id: 1840013840,
  },
  {
    name: "Tilton",
    state_id: "IL",
    lat: 40.0941,
    lng: -87.6398,
    id: 1840012188,
  },
  {
    name: "Kilauea",
    state_id: "HI",
    lat: 22.2108,
    lng: -159.3964,
    id: 1840029508,
  },
  {
    name: "Lexington Hills",
    state_id: "CA",
    lat: 37.1616,
    lng: -121.9879,
    id: 1840028383,
  },
  {
    name: "Riverton",
    state_id: "NJ",
    lat: 40.0115,
    lng: -75.0148,
    id: 1840001478,
  },
  {
    name: "Hampton Beach",
    state_id: "NH",
    lat: 42.9142,
    lng: -70.8128,
    id: 1840026174,
  },
  {
    name: "Abbeville",
    state_id: "GA",
    lat: 31.9925,
    lng: -83.3068,
    id: 1840013845,
  },
  {
    name: "Healdton",
    state_id: "OK",
    lat: 34.2335,
    lng: -97.4868,
    id: 1840020531,
  },
  {
    name: "Dupont",
    state_id: "PA",
    lat: 41.3237,
    lng: -75.7421,
    id: 1840000746,
  },
  {
    name: "Grand Forks AFB",
    state_id: "ND",
    lat: 47.9557,
    lng: -97.3913,
    id: 1840073759,
  },
  {
    name: "Ford Heights",
    state_id: "IL",
    lat: 41.5109,
    lng: -87.5814,
    id: 1840011275,
  },
  {
    name: "Clayton",
    state_id: "NM",
    lat: 36.4425,
    lng: -103.1573,
    id: 1840022631,
  },
  {
    name: "Casey",
    state_id: "IL",
    lat: 39.3027,
    lng: -87.9895,
    id: 1840007370,
  },
  {
    name: "Como",
    state_id: "WI",
    lat: 42.6114,
    lng: -88.4914,
    id: 1840004478,
  },
  {
    name: "La Selva Beach",
    state_id: "CA",
    lat: 36.9276,
    lng: -121.8445,
    id: 1840024794,
  },
  {
    name: "Bloomfield",
    state_id: "IA",
    lat: 40.7487,
    lng: -92.4173,
    id: 1840001002,
  },
  {
    name: "Montgomery City",
    state_id: "MO",
    lat: 38.9737,
    lng: -91.5024,
    id: 1840008553,
  },
  {
    name: "Big Lake",
    state_id: "AK",
    lat: 61.526,
    lng: -149.972,
    id: 1840023666,
  },
  {
    name: "Muse",
    state_id: "PA",
    lat: 40.2924,
    lng: -80.2055,
    id: 1840026507,
  },
  {
    name: "West Brattleboro",
    state_id: "VT",
    lat: 42.8558,
    lng: -72.6017,
    id: 1840002941,
  },
  {
    name: "Stanley",
    state_id: "ND",
    lat: 48.3164,
    lng: -102.3873,
    id: 1840001879,
  },
  {
    name: "Dalton",
    state_id: "OH",
    lat: 40.7986,
    lng: -81.7033,
    id: 1840011893,
  },
  {
    name: "Floydada",
    state_id: "TX",
    lat: 33.9835,
    lng: -101.3367,
    id: 1840020535,
  },
  {
    name: "West Wyoming",
    state_id: "PA",
    lat: 41.3217,
    lng: -75.8578,
    id: 1840000775,
  },
  {
    name: "Chewelah",
    state_id: "WA",
    lat: 48.293,
    lng: -117.7336,
    id: 1840018360,
  },
  {
    name: "Hernando Beach",
    state_id: "FL",
    lat: 28.4753,
    lng: -82.6612,
    id: 1840014102,
  },
  {
    name: "Bel-Ridge",
    state_id: "MO",
    lat: 38.7129,
    lng: -90.3285,
    id: 1840012816,
  },
  {
    name: "Avery Creek",
    state_id: "NC",
    lat: 35.4651,
    lng: -82.5719,
    id: 1840013408,
  },
  {
    name: "Mohave Valley",
    state_id: "AZ",
    lat: 34.9008,
    lng: -114.5732,
    id: 1840019745,
  },
  {
    name: "Cambridge Springs",
    state_id: "PA",
    lat: 41.8018,
    lng: -80.0597,
    id: 1840000551,
  },
  {
    name: "Foley",
    state_id: "MN",
    lat: 45.6636,
    lng: -93.9095,
    id: 1840007776,
  },
  {
    name: "Jemison",
    state_id: "AL",
    lat: 32.9696,
    lng: -86.7339,
    id: 1840014862,
  },
  {
    name: "East Lansdowne",
    state_id: "PA",
    lat: 39.944,
    lng: -75.2608,
    id: 1840000696,
  },
  {
    name: "Girard",
    state_id: "KS",
    lat: 37.5094,
    lng: -94.8456,
    id: 1840001696,
  },
  {
    name: "St. Helen",
    state_id: "MI",
    lat: 44.3579,
    lng: -84.4134,
    id: 1840004093,
  },
  {
    name: "Washoe Valley",
    state_id: "NV",
    lat: 39.2923,
    lng: -119.7767,
    id: 1840043162,
  },
  {
    name: "Manatee Road",
    state_id: "FL",
    lat: 29.5145,
    lng: -82.9192,
    id: 1840028993,
  },
  {
    name: "Morton",
    state_id: "PA",
    lat: 39.9116,
    lng: -75.3267,
    id: 1840000704,
  },
  {
    name: "Grundy Center",
    state_id: "IA",
    lat: 42.3637,
    lng: -92.774,
    id: 1840006965,
  },
  {
    name: "Newtown",
    state_id: "OH",
    lat: 39.1243,
    lng: -84.3516,
    id: 1840001608,
  },
  {
    name: "Chetek",
    state_id: "WI",
    lat: 45.3159,
    lng: -91.6532,
    id: 1840002071,
  },
  {
    name: "Bath",
    state_id: "PA",
    lat: 40.7279,
    lng: -75.3919,
    id: 1840000967,
  },
  {
    name: "Walton Park",
    state_id: "NY",
    lat: 41.3118,
    lng: -74.2249,
    id: 1840004864,
  },
  {
    name: "Desert View Highlands",
    state_id: "CA",
    lat: 34.5901,
    lng: -118.1535,
    id: 1840028320,
  },
  {
    name: "Juneau",
    state_id: "WI",
    lat: 43.4052,
    lng: -88.7041,
    id: 1840002734,
  },
  {
    name: "Hokendauqua",
    state_id: "PA",
    lat: 40.6584,
    lng: -75.4952,
    id: 1840035017,
  },
  {
    name: "Oronogo",
    state_id: "MO",
    lat: 37.1917,
    lng: -94.4639,
    id: 1840009910,
  },
  {
    name: "Las Lomas",
    state_id: "TX",
    lat: 26.3638,
    lng: -98.7747,
    id: 1840018318,
  },
  {
    name: "Freer",
    state_id: "TX",
    lat: 27.8823,
    lng: -98.6181,
    id: 1840021007,
  },
  {
    name: "Trenton",
    state_id: "GA",
    lat: 34.8741,
    lng: -85.5096,
    id: 1840015529,
  },
  {
    name: "Dixon Lane-Meadow Creek",
    state_id: "CA",
    lat: 37.3864,
    lng: -118.4152,
    id: 1840074358,
  },
  {
    name: "Wilburton",
    state_id: "OK",
    lat: 34.9183,
    lng: -95.3046,
    id: 1840021832,
  },
  {
    name: "Arcola",
    state_id: "TX",
    lat: 29.5032,
    lng: -95.4693,
    id: 1840019646,
  },
  {
    name: "El Lago",
    state_id: "TX",
    lat: 29.5733,
    lng: -95.044,
    id: 1840020921,
  },
  {
    name: "Blasdell",
    state_id: "NY",
    lat: 42.7966,
    lng: -78.8324,
    id: 1840004396,
  },
  {
    name: "Terre du Lac",
    state_id: "MO",
    lat: 37.9031,
    lng: -90.6169,
    id: 1840025697,
  },
  {
    name: "Fords Prairie",
    state_id: "WA",
    lat: 46.7461,
    lng: -123.0029,
    id: 1840017377,
  },
  {
    name: "Malvern",
    state_id: "OH",
    lat: 40.6894,
    lng: -81.1804,
    id: 1840011998,
  },
  {
    name: "Hillsville",
    state_id: "VA",
    lat: 36.7613,
    lng: -80.7372,
    id: 1840006526,
  },
  {
    name: "Summerset",
    state_id: "SD",
    lat: 44.1912,
    lng: -103.3393,
    id: 1840008941,
  },
  {
    name: "Folly Beach",
    state_id: "SC",
    lat: 32.6693,
    lng: -79.95,
    id: 1840014255,
  },
  {
    name: "Milford",
    state_id: "NJ",
    lat: 40.5694,
    lng: -75.0916,
    id: 1840001041,
  },
  {
    name: "Townsend",
    state_id: "DE",
    lat: 39.3968,
    lng: -75.6945,
    id: 1840005584,
  },
  {
    name: "Liberty",
    state_id: "NC",
    lat: 35.8555,
    lng: -79.5682,
    id: 1840016252,
  },
  {
    name: "St. Maries",
    state_id: "ID",
    lat: 47.3146,
    lng: -116.5722,
    id: 1840021126,
  },
  {
    name: "Warsaw",
    state_id: "MO",
    lat: 38.2476,
    lng: -93.3711,
    id: 1840010795,
  },
  {
    name: "West Fork",
    state_id: "AR",
    lat: 35.9357,
    lng: -94.1799,
    id: 1840015338,
  },
  {
    name: "Clifton",
    state_id: "TN",
    lat: 35.3792,
    lng: -87.9927,
    id: 1840014562,
  },
  {
    name: "Rogers City",
    state_id: "MI",
    lat: 45.415,
    lng: -83.8181,
    id: 1840002065,
  },
  {
    name: "Point Clear",
    state_id: "AL",
    lat: 30.4902,
    lng: -87.9105,
    id: 1840013883,
  },
  {
    name: "Lexington",
    state_id: "MN",
    lat: 45.1381,
    lng: -93.1714,
    id: 1840007800,
  },
  {
    name: "Merrimac",
    state_id: "VA",
    lat: 37.1904,
    lng: -80.4244,
    id: 1840037349,
  },
  {
    name: "Preston Heights",
    state_id: "IL",
    lat: 41.4944,
    lng: -88.0757,
    id: 1840004820,
  },
  {
    name: "Fillmore",
    state_id: "UT",
    lat: 38.964,
    lng: -112.3387,
    id: 1840020224,
  },
  {
    name: "Lake City",
    state_id: "AR",
    lat: 35.8202,
    lng: -90.4546,
    id: 1840014508,
  },
  {
    name: "Tahoka",
    state_id: "TX",
    lat: 33.164,
    lng: -101.7951,
    id: 1840022027,
  },
  {
    name: "Mountain View",
    state_id: "MO",
    lat: 36.9936,
    lng: -91.7019,
    id: 1840008791,
  },
  {
    name: "Cetronia",
    state_id: "PA",
    lat: 40.5851,
    lng: -75.5435,
    id: 1840034896,
  },
  {
    name: "Middlebush",
    state_id: "NJ",
    lat: 40.5015,
    lng: -74.5349,
    id: 1840024280,
  },
  {
    name: "Castle Dale",
    state_id: "UT",
    lat: 39.2218,
    lng: -111.0229,
    id: 1840018799,
  },
  {
    name: "Crosby",
    state_id: "TX",
    lat: 29.9146,
    lng: -95.0591,
    id: 1840018249,
  },
  {
    name: "Hallam",
    state_id: "PA",
    lat: 40.0024,
    lng: -76.6042,
    id: 1840001449,
  },
  {
    name: "Round Lake Heights",
    state_id: "IL",
    lat: 42.3855,
    lng: -88.1038,
    id: 1840011172,
  },
  {
    name: "Marquette Heights",
    state_id: "IL",
    lat: 40.6184,
    lng: -89.6047,
    id: 1840008328,
  },
  {
    name: "Reidsville",
    state_id: "GA",
    lat: 32.0871,
    lng: -82.1244,
    id: 1840014908,
  },
  {
    name: "Rossmoor",
    state_id: "NJ",
    lat: 40.3361,
    lng: -74.4726,
    id: 1840033485,
  },
  {
    name: "Dogtown",
    state_id: "CA",
    lat: 38.2096,
    lng: -121.1551,
    id: 1840024697,
  },
  {
    name: "Chimayo",
    state_id: "NM",
    lat: 35.9976,
    lng: -105.9363,
    id: 1840017667,
  },
  {
    name: "Port Barre",
    state_id: "LA",
    lat: 30.5565,
    lng: -91.9565,
    id: 1840017176,
  },
  {
    name: "Williams Bay",
    state_id: "WI",
    lat: 42.5788,
    lng: -88.5457,
    id: 1840002477,
  },
  {
    name: "Sandstone",
    state_id: "MN",
    lat: 46.1292,
    lng: -92.8646,
    id: 1840008858,
  },
  {
    name: "Lakeview",
    state_id: "CA",
    lat: 33.8285,
    lng: -117.1233,
    id: 1840017990,
  },
  {
    name: "Russell Springs",
    state_id: "KY",
    lat: 37.0509,
    lng: -85.0774,
    id: 1840014391,
  },
  {
    name: "Alturas",
    state_id: "CA",
    lat: 41.4898,
    lng: -120.5516,
    id: 1840018708,
  },
  {
    name: "Dunkirk",
    state_id: "MD",
    lat: 38.7156,
    lng: -76.673,
    id: 1840006144,
  },
  {
    name: "Hallettsville",
    state_id: "TX",
    lat: 29.4429,
    lng: -96.9436,
    id: 1840020967,
  },
  {
    name: "Brush Prairie",
    state_id: "WA",
    lat: 45.7251,
    lng: -122.5483,
    id: 1840017398,
  },
  {
    name: "South Creek",
    state_id: "WA",
    lat: 46.9994,
    lng: -122.3921,
    id: 1840042075,
  },
  {
    name: "Santa Rosa",
    state_id: "NM",
    lat: 34.936,
    lng: -104.6769,
    id: 1840021821,
  },
  {
    name: "Alburtis",
    state_id: "PA",
    lat: 40.509,
    lng: -75.6001,
    id: 1840001043,
  },
  {
    name: "Erda",
    state_id: "UT",
    lat: 40.6028,
    lng: -112.3214,
    id: 1840017495,
  },
  {
    name: "Hedwig Village",
    state_id: "TX",
    lat: 29.7799,
    lng: -95.5193,
    id: 1840020923,
  },
  {
    name: "La Dolores",
    state_id: "PR",
    lat: 18.3725,
    lng: -65.8546,
    id: 1630035695,
  },
  {
    name: "Stansberry Lake",
    state_id: "WA",
    lat: 47.3814,
    lng: -122.7154,
    id: 1840042090,
  },
  {
    name: "White Island Shores",
    state_id: "MA",
    lat: 41.7933,
    lng: -70.639,
    id: 1840003203,
  },
  {
    name: "Whittingham",
    state_id: "NJ",
    lat: 40.3297,
    lng: -74.4451,
    id: 1840073737,
  },
  {
    name: "Dawson Springs",
    state_id: "KY",
    lat: 37.174,
    lng: -87.688,
    id: 1840014367,
  },
  {
    name: "University Park",
    state_id: "MD",
    lat: 38.9719,
    lng: -76.9445,
    id: 1840003827,
  },
  {
    name: "Cayucos",
    state_id: "CA",
    lat: 35.4375,
    lng: -120.8847,
    id: 1840017807,
  },
  {
    name: "Avoca",
    state_id: "PA",
    lat: 41.3381,
    lng: -75.7423,
    id: 1840000742,
  },
  {
    name: "Freemansburg",
    state_id: "PA",
    lat: 40.628,
    lng: -75.3401,
    id: 1840000971,
  },
  {
    name: "Chuluota",
    state_id: "FL",
    lat: 28.638,
    lng: -81.1159,
    id: 1840014071,
  },
  {
    name: "Sewaren",
    state_id: "NJ",
    lat: 40.5508,
    lng: -74.2602,
    id: 1840005422,
  },
  {
    name: "Sand Hill",
    state_id: "PA",
    lat: 40.3614,
    lng: -76.4217,
    id: 1840035187,
  },
  {
    name: "Glouster",
    state_id: "OH",
    lat: 39.5023,
    lng: -82.0842,
    id: 1840012626,
  },
  {
    name: "Mariano Colón",
    state_id: "PR",
    lat: 18.0287,
    lng: -66.3356,
    id: 1630023664,
  },
  {
    name: "New Concord",
    state_id: "OH",
    lat: 39.9937,
    lng: -81.7381,
    id: 1840012394,
  },
  {
    name: "Pembroke",
    state_id: "GA",
    lat: 32.1455,
    lng: -81.6185,
    id: 1840014912,
  },
  {
    name: "Wolfdale",
    state_id: "PA",
    lat: 40.1983,
    lng: -80.3028,
    id: 1840005437,
  },
  {
    name: "Westhampton",
    state_id: "NY",
    lat: 40.8325,
    lng: -72.6617,
    id: 1840005113,
  },
  {
    name: "Thunderbolt",
    state_id: "GA",
    lat: 32.0339,
    lng: -81.048,
    id: 1840017030,
  },
  {
    name: "Macon",
    state_id: "MS",
    lat: 33.1217,
    lng: -88.5564,
    id: 1840015743,
  },
  {
    name: "Sutter Creek",
    state_id: "CA",
    lat: 38.3827,
    lng: -120.8107,
    id: 1840021492,
  },
  {
    name: "Green Island",
    state_id: "NY",
    lat: 42.7474,
    lng: -73.6925,
    id: 1840004490,
  },
  {
    name: "Spencer",
    state_id: "WV",
    lat: 38.8025,
    lng: -81.3534,
    id: 1840006110,
  },
  {
    name: "Riverview Park",
    state_id: "PA",
    lat: 40.3912,
    lng: -75.9509,
    id: 1840035173,
  },
  {
    name: "La Grange",
    state_id: "NC",
    lat: 35.3064,
    lng: -77.789,
    id: 1840016389,
  },
  {
    name: "Green Park",
    state_id: "MO",
    lat: 38.5243,
    lng: -90.3366,
    id: 1840008589,
  },
  {
    name: "Loudonville",
    state_id: "OH",
    lat: 40.6342,
    lng: -82.2328,
    id: 1840011805,
  },
  {
    name: "Merkel",
    state_id: "TX",
    lat: 32.4697,
    lng: -100.0111,
    id: 1840023054,
  },
  {
    name: "Brusly",
    state_id: "LA",
    lat: 30.3943,
    lng: -91.2517,
    id: 1840015916,
  },
  {
    name: "Wayne Heights",
    state_id: "PA",
    lat: 39.7455,
    lng: -77.5436,
    id: 1840035247,
  },
  {
    name: "Desert Hills",
    state_id: "AZ",
    lat: 34.5506,
    lng: -114.3754,
    id: 1840027987,
  },
  {
    name: "Missouri Valley",
    state_id: "IA",
    lat: 41.5574,
    lng: -95.9028,
    id: 1840008151,
  },
  {
    name: "Abita Springs",
    state_id: "LA",
    lat: 30.4761,
    lng: -90.0292,
    id: 1840015910,
  },
  {
    name: "Bridgeport",
    state_id: "WA",
    lat: 48.0066,
    lng: -119.6722,
    id: 1840018387,
  },
  {
    name: "Thomaston",
    state_id: "NY",
    lat: 40.7875,
    lng: -73.7152,
    id: 1840005329,
  },
  {
    name: "Horseheads North",
    state_id: "NY",
    lat: 42.1928,
    lng: -76.8078,
    id: 1840073692,
  },
  {
    name: "Keaau",
    state_id: "HI",
    lat: 19.6156,
    lng: -155.0372,
    id: 1840029503,
  },
  {
    name: "Verona Walk",
    state_id: "FL",
    lat: 26.0839,
    lng: -81.6794,
    id: 1840039117,
  },
  {
    name: "Eldora",
    state_id: "IA",
    lat: 42.3605,
    lng: -93.1013,
    id: 1840000450,
  },
  {
    name: "Salina",
    state_id: "UT",
    lat: 38.9366,
    lng: -111.8665,
    id: 1840021475,
  },
  {
    name: "Moundville",
    state_id: "AL",
    lat: 32.9968,
    lng: -87.6272,
    id: 1840016942,
  },
  {
    name: "Glenwood",
    state_id: "MN",
    lat: 45.6517,
    lng: -95.3643,
    id: 1840007788,
  },
  {
    name: "Pearl River",
    state_id: "LA",
    lat: 30.3687,
    lng: -89.7495,
    id: 1840017182,
  },
  {
    name: "Centreville",
    state_id: "AL",
    lat: 32.9597,
    lng: -87.1334,
    id: 1840007089,
  },
  {
    name: "Sells",
    state_id: "AZ",
    lat: 31.9202,
    lng: -111.8775,
    id: 1840019485,
  },
  {
    name: "Sandusky",
    state_id: "MI",
    lat: 43.4212,
    lng: -82.8333,
    id: 1840002698,
  },
  {
    name: "Trenton",
    state_id: "IL",
    lat: 38.607,
    lng: -89.6845,
    id: 1840010764,
  },
  {
    name: "Irrigon",
    state_id: "OR",
    lat: 45.8959,
    lng: -119.4883,
    id: 1840018542,
  },
  {
    name: "Hayward",
    state_id: "WI",
    lat: 46.0094,
    lng: -91.4826,
    id: 1840001997,
  },
  {
    name: "Clarkson Valley",
    state_id: "MO",
    lat: 38.6254,
    lng: -90.5945,
    id: 1840007438,
  },
  {
    name: "Marietta",
    state_id: "PA",
    lat: 40.0574,
    lng: -76.5515,
    id: 1840003721,
  },
  {
    name: "Meadow Oaks",
    state_id: "FL",
    lat: 28.3465,
    lng: -82.6025,
    id: 1840038992,
  },
  {
    name: "Warm Beach",
    state_id: "WA",
    lat: 48.1649,
    lng: -122.3442,
    id: 1840018377,
  },
  {
    name: "Eutaw",
    state_id: "AL",
    lat: 32.8436,
    lng: -87.8987,
    id: 1840001838,
  },
  {
    name: "Villa Ridge",
    state_id: "MO",
    lat: 38.468,
    lng: -90.885,
    id: 1840007474,
  },
  {
    name: "Hamburg",
    state_id: "AR",
    lat: 33.2248,
    lng: -91.7972,
    id: 1840013772,
  },
  {
    name: "Massanutten",
    state_id: "VA",
    lat: 38.4115,
    lng: -78.7269,
    id: 1840006132,
  },
  {
    name: "Valley View",
    state_id: "PA",
    lat: 39.9498,
    lng: -76.701,
    id: 1840133810,
  },
  {
    name: "Barnesville",
    state_id: "MN",
    lat: 46.6501,
    lng: -96.4164,
    id: 1840006634,
  },
  {
    name: "Whitesboro",
    state_id: "AL",
    lat: 34.1682,
    lng: -86.0572,
    id: 1840022891,
  },
  {
    name: "Hoxie",
    state_id: "AR",
    lat: 36.036,
    lng: -90.9736,
    id: 1840014478,
  },
  {
    name: "East Newark",
    state_id: "NJ",
    lat: 40.751,
    lng: -74.1623,
    id: 1840003596,
  },
  {
    name: "McConnelsville",
    state_id: "OH",
    lat: 39.6568,
    lng: -81.8467,
    id: 1840012569,
  },
  {
    name: "Davis Junction",
    state_id: "IL",
    lat: 42.1146,
    lng: -89.0903,
    id: 1840011235,
  },
  {
    name: "Peapack and Gladstone",
    state_id: "NJ",
    lat: 40.7168,
    lng: -74.6561,
    id: 1840056420,
  },
  {
    name: "Fairmont",
    state_id: "NC",
    lat: 34.4947,
    lng: -79.1136,
    id: 1840016538,
  },
  {
    name: "Mountain City",
    state_id: "TN",
    lat: 36.4695,
    lng: -81.8049,
    id: 1840016016,
  },
  {
    name: "Moorefield",
    state_id: "WV",
    lat: 39.0674,
    lng: -78.9624,
    id: 1840005904,
  },
  {
    name: "Edisto",
    state_id: "SC",
    lat: 33.4776,
    lng: -80.8998,
    id: 1840035843,
  },
  {
    name: "Dennison",
    state_id: "OH",
    lat: 40.3972,
    lng: -81.3275,
    id: 1840012087,
  },
  {
    name: "Fairview",
    state_id: "OK",
    lat: 36.2705,
    lng: -98.4771,
    id: 1840020377,
  },
  {
    name: "Bluewell",
    state_id: "WV",
    lat: 37.3144,
    lng: -81.2581,
    id: 1840038220,
  },
  {
    name: "New Town",
    state_id: "ND",
    lat: 47.9848,
    lng: -102.4813,
    id: 1840000067,
  },
  {
    name: "Hartford",
    state_id: "MI",
    lat: 42.2047,
    lng: -86.1661,
    id: 1840003181,
  },
  {
    name: "Dayton",
    state_id: "WA",
    lat: 46.3169,
    lng: -117.9768,
    id: 1840018486,
  },
  {
    name: "East Douglas",
    state_id: "MA",
    lat: 42.0779,
    lng: -71.7121,
    id: 1840003147,
  },
  {
    name: "Westminster",
    state_id: "SC",
    lat: 34.6657,
    lng: -83.0911,
    id: 1840015508,
  },
  {
    name: "Okolona",
    state_id: "MS",
    lat: 34.0053,
    lng: -88.7479,
    id: 1840014759,
  },
  {
    name: "Brinkley",
    state_id: "AR",
    lat: 34.89,
    lng: -91.1902,
    id: 1840013541,
  },
  {
    name: "Rugby",
    state_id: "ND",
    lat: 48.3656,
    lng: -99.9906,
    id: 1840001882,
  },
  {
    name: "Garland",
    state_id: "UT",
    lat: 41.7361,
    lng: -112.1627,
    id: 1840020117,
  },
  {
    name: "East Prospect",
    state_id: "PA",
    lat: 39.971,
    lng: -76.5215,
    id: 1840001443,
  },
  {
    name: "Okanogan",
    state_id: "WA",
    lat: 48.3673,
    lng: -119.5781,
    id: 1840019760,
  },
  {
    name: "Cabazon",
    state_id: "CA",
    lat: 33.9127,
    lng: -116.7828,
    id: 1840017982,
  },
  {
    name: "Inverness Highlands North",
    state_id: "FL",
    lat: 28.8642,
    lng: -82.3768,
    id: 1840073843,
  },
  {
    name: "Frazier Park",
    state_id: "CA",
    lat: 34.8123,
    lng: -118.9542,
    id: 1840017818,
  },
  {
    name: "Vergennes",
    state_id: "VT",
    lat: 44.1664,
    lng: -73.2555,
    id: 1840002218,
  },
  {
    name: "Rothsville",
    state_id: "PA",
    lat: 40.1532,
    lng: -76.2473,
    id: 1840005490,
  },
  {
    name: "Cordes Lakes",
    state_id: "AZ",
    lat: 34.3103,
    lng: -112.1073,
    id: 1840017841,
  },
  {
    name: "Lake Kiowa",
    state_id: "TX",
    lat: 33.5703,
    lng: -97.0129,
    id: 1840017998,
  },
  {
    name: "Rockville",
    state_id: "MN",
    lat: 45.465,
    lng: -94.3222,
    id: 1840008877,
  },
  {
    name: "Wales",
    state_id: "WI",
    lat: 43.0042,
    lng: -88.3739,
    id: 1840003007,
  },
  {
    name: "Buies Creek",
    state_id: "NC",
    lat: 35.4127,
    lng: -78.7363,
    id: 1840013433,
  },
  {
    name: "Dublin",
    state_id: "VA",
    lat: 37.0987,
    lng: -80.6831,
    id: 1840006468,
  },
  {
    name: "Sabetha",
    state_id: "KS",
    lat: 39.9136,
    lng: -95.7916,
    id: 1840000728,
  },
  {
    name: "Huguley",
    state_id: "AL",
    lat: 32.8431,
    lng: -85.2397,
    id: 1840013787,
  },
  {
    name: "Dillsburg",
    state_id: "PA",
    lat: 40.1103,
    lng: -77.0335,
    id: 1840001441,
  },
  {
    name: "Belmont",
    state_id: "PA",
    lat: 40.283,
    lng: -78.8907,
    id: 1840005378,
  },
  {
    name: "Eminence",
    state_id: "KY",
    lat: 38.3637,
    lng: -85.1778,
    id: 1840013187,
  },
  {
    name: "Hooverson Heights",
    state_id: "WV",
    lat: 40.319,
    lng: -80.5825,
    id: 1840005476,
  },
  {
    name: "Curwensville",
    state_id: "PA",
    lat: 40.9735,
    lng: -78.5189,
    id: 1840003492,
  },
  {
    name: "Gap",
    state_id: "PA",
    lat: 39.9891,
    lng: -76.0215,
    id: 1840005491,
  },
  {
    name: "Plumsteadville",
    state_id: "PA",
    lat: 40.3863,
    lng: -75.1429,
    id: 1840026488,
  },
  {
    name: "Coalfield",
    state_id: "TN",
    lat: 36.0219,
    lng: -84.4414,
    id: 1840025774,
  },
  {
    name: "Deenwood",
    state_id: "GA",
    lat: 31.2476,
    lng: -82.3677,
    id: 1840029444,
  },
  {
    name: "Sabina",
    state_id: "OH",
    lat: 39.4902,
    lng: -83.6336,
    id: 1840012620,
  },
  {
    name: "Centerville",
    state_id: "IN",
    lat: 39.8321,
    lng: -84.9922,
    id: 1840009494,
  },
  {
    name: "Crab Orchard",
    state_id: "WV",
    lat: 37.741,
    lng: -81.23,
    id: 1840006346,
  },
  {
    name: "Hartford",
    state_id: "AL",
    lat: 31.1055,
    lng: -85.6916,
    id: 1840013892,
  },
  {
    name: "Carlisle-Rockledge",
    state_id: "AL",
    lat: 34.114,
    lng: -86.1191,
    id: 1840037805,
  },
  {
    name: "Georgetown",
    state_id: "CA",
    lat: 38.9112,
    lng: -120.8356,
    id: 1840017566,
  },
  {
    name: "Pennington",
    state_id: "NJ",
    lat: 40.3262,
    lng: -74.7908,
    id: 1840001382,
  },
  {
    name: "Brownstown",
    state_id: "PA",
    lat: 40.1268,
    lng: -76.2184,
    id: 1840152867,
  },
  {
    name: "Glenrock",
    state_id: "WY",
    lat: 42.8565,
    lng: -105.8624,
    id: 1840021287,
  },
  {
    name: "Escobares",
    state_id: "TX",
    lat: 26.412,
    lng: -98.9599,
    id: 1840021015,
  },
  {
    name: "East Dennis",
    state_id: "MA",
    lat: 41.7392,
    lng: -70.1577,
    id: 1840003236,
  },
  {
    name: "Effort",
    state_id: "PA",
    lat: 40.9436,
    lng: -75.439,
    id: 1840026362,
  },
  {
    name: "Ruidoso Downs",
    state_id: "NM",
    lat: 33.3312,
    lng: -105.5968,
    id: 1840020534,
  },
  {
    name: "St. Paul",
    state_id: "MO",
    lat: 38.8485,
    lng: -90.7406,
    id: 1840009720,
  },
  {
    name: "Maine",
    state_id: "WI",
    lat: 45.0497,
    lng: -89.6816,
    id: 1840038104,
  },
  {
    name: "Sibley",
    state_id: "IA",
    lat: 43.4013,
    lng: -95.7438,
    id: 1840009029,
  },
  {
    name: "Canadian Lakes",
    state_id: "MI",
    lat: 43.5789,
    lng: -85.2973,
    id: 1840004173,
  },
  {
    name: "Homerville",
    state_id: "GA",
    lat: 31.0385,
    lng: -82.742,
    id: 1840014984,
  },
  {
    name: "White Sulphur Springs",
    state_id: "WV",
    lat: 37.7981,
    lng: -80.3004,
    id: 1840006287,
  },
  {
    name: "Lake Mary Jane",
    state_id: "FL",
    lat: 28.3853,
    lng: -81.1709,
    id: 1840038991,
  },
  {
    name: "Feasterville",
    state_id: "PA",
    lat: 40.154,
    lng: -74.9915,
    id: 1840035284,
  },
  {
    name: "Lakeland",
    state_id: "NY",
    lat: 43.0908,
    lng: -76.2426,
    id: 1840004316,
  },
  {
    name: "Spooner",
    state_id: "WI",
    lat: 45.8271,
    lng: -91.886,
    id: 1840002002,
  },
  {
    name: "Luxemburg",
    state_id: "WI",
    lat: 44.5449,
    lng: -87.7065,
    id: 1840002366,
  },
  {
    name: "Winter Beach",
    state_id: "FL",
    lat: 27.712,
    lng: -80.4254,
    id: 1840014163,
  },
  {
    name: "Lake Meade",
    state_id: "PA",
    lat: 39.9835,
    lng: -77.0421,
    id: 1840035413,
  },
  {
    name: "Williamsburg",
    state_id: "OH",
    lat: 39.0556,
    lng: -84.0478,
    id: 1840012712,
  },
  {
    name: "Huntsville",
    state_id: "AR",
    lat: 36.0985,
    lng: -93.7363,
    id: 1840014469,
  },
  {
    name: "Yorkville",
    state_id: "NY",
    lat: 43.1124,
    lng: -75.2739,
    id: 1840004217,
  },
  {
    name: "Swedesboro",
    state_id: "NJ",
    lat: 39.7459,
    lng: -75.3117,
    id: 1840001524,
  },
  {
    name: "Leonardo",
    state_id: "NJ",
    lat: 40.4191,
    lng: -74.0599,
    id: 1840005448,
  },
  {
    name: "North Rock Springs",
    state_id: "WY",
    lat: 41.6698,
    lng: -109.2714,
    id: 1840018691,
  },
  {
    name: "Coopersburg",
    state_id: "PA",
    lat: 40.5102,
    lng: -75.3915,
    id: 1840001046,
  },
  {
    name: "Freeport",
    state_id: "FL",
    lat: 30.5039,
    lng: -86.139,
    id: 1840013926,
  },
  {
    name: "Forest Heights",
    state_id: "MD",
    lat: 38.8105,
    lng: -76.9995,
    id: 1840005966,
  },
  {
    name: "Mondovi",
    state_id: "WI",
    lat: 44.5714,
    lng: -91.6668,
    id: 1840002381,
  },
  {
    name: "Bellevue",
    state_id: "IA",
    lat: 42.2625,
    lng: -90.4319,
    id: 1840006981,
  },
  {
    name: "Villano Beach",
    state_id: "FL",
    lat: 29.936,
    lng: -81.3021,
    id: 1840029091,
  },
  {
    name: "Lawson Heights",
    state_id: "PA",
    lat: 40.2938,
    lng: -79.3873,
    id: 1840005389,
  },
  {
    name: "Martinsburg",
    state_id: "PA",
    lat: 40.3109,
    lng: -78.3242,
    id: 1840001084,
  },
  {
    name: "George West",
    state_id: "TX",
    lat: 28.33,
    lng: -98.1183,
    id: 1840020994,
  },
  {
    name: "Riverbend",
    state_id: "WA",
    lat: 47.4652,
    lng: -121.7492,
    id: 1840037860,
  },
  {
    name: "Friday Harbor",
    state_id: "WA",
    lat: 48.5324,
    lng: -123.0655,
    id: 1840021065,
  },
  {
    name: "Tappahannock",
    state_id: "VA",
    lat: 37.9187,
    lng: -76.8667,
    id: 1840006311,
  },
  {
    name: "Sylvania",
    state_id: "GA",
    lat: 32.7465,
    lng: -81.641,
    id: 1840015763,
  },
  {
    name: "Polkton",
    state_id: "NC",
    lat: 35.0031,
    lng: -80.1963,
    id: 1840016452,
  },
  {
    name: "Mount Vernon",
    state_id: "KY",
    lat: 37.3578,
    lng: -84.3431,
    id: 1840014371,
  },
  {
    name: "Abbeville",
    state_id: "AL",
    lat: 31.5664,
    lng: -85.2528,
    id: 1840000552,
  },
  {
    name: "Quitman",
    state_id: "TX",
    lat: 32.7951,
    lng: -95.4442,
    id: 1840020686,
  },
  {
    name: "Marysville",
    state_id: "PA",
    lat: 40.3377,
    lng: -76.9337,
    id: 1840001303,
  },
  {
    name: "New Palestine",
    state_id: "IN",
    lat: 39.7255,
    lng: -85.8961,
    id: 1840009527,
  },
  {
    name: "Myrtle Point",
    state_id: "OR",
    lat: 43.0617,
    lng: -124.1326,
    id: 1840020056,
  },
  {
    name: "Indian Springs Village",
    state_id: "AL",
    lat: 33.3591,
    lng: -86.7493,
    id: 1840014828,
  },
  {
    name: "Turley",
    state_id: "OK",
    lat: 36.2473,
    lng: -95.9654,
    id: 1840019057,
  },
  {
    name: "Caruthers",
    state_id: "CA",
    lat: 36.5399,
    lng: -119.845,
    id: 1840017632,
  },
  {
    name: "Pima",
    state_id: "AZ",
    lat: 32.8886,
    lng: -109.8437,
    id: 1840022954,
  },
  {
    name: "Argentine",
    state_id: "MI",
    lat: 42.7905,
    lng: -83.8356,
    id: 1840004342,
  },
  {
    name: "Delavan Lake",
    state_id: "WI",
    lat: 42.5987,
    lng: -88.6177,
    id: 1840004479,
  },
  {
    name: "Millersburg",
    state_id: "OR",
    lat: 44.6779,
    lng: -123.0691,
    id: 1840019994,
  },
  {
    name: "Ruleville",
    state_id: "MS",
    lat: 33.7245,
    lng: -90.55,
    id: 1840014773,
  },
  {
    name: "Wrightsville Beach",
    state_id: "NC",
    lat: 34.213,
    lng: -77.798,
    id: 1840016677,
  },
  {
    name: "Center Point",
    state_id: "IA",
    lat: 42.1844,
    lng: -91.7795,
    id: 1840006985,
  },
  {
    name: "St. Henry",
    state_id: "OH",
    lat: 40.4208,
    lng: -84.6326,
    id: 1840012003,
  },
  {
    name: "Brownville",
    state_id: "NJ",
    lat: 40.4009,
    lng: -74.2959,
    id: 1840033468,
  },
  {
    name: "Donalsonville",
    state_id: "GA",
    lat: 31.0404,
    lng: -84.8792,
    id: 1840014997,
  },
  {
    name: "Carthage",
    state_id: "NC",
    lat: 35.3225,
    lng: -79.4115,
    id: 1840016355,
  },
  {
    name: "Clearwater",
    state_id: "KS",
    lat: 37.5096,
    lng: -97.4975,
    id: 1840007563,
  },
  {
    name: "Pelican Rapids",
    state_id: "MN",
    lat: 46.5701,
    lng: -96.086,
    id: 1840008851,
  },
  {
    name: "Colwyn",
    state_id: "PA",
    lat: 39.9119,
    lng: -75.253,
    id: 1840000694,
  },
  {
    name: "Mathews",
    state_id: "LA",
    lat: 29.6822,
    lng: -90.5559,
    id: 1840014026,
  },
  {
    name: "Pacolet",
    state_id: "SC",
    lat: 34.91,
    lng: -81.7666,
    id: 1840016465,
  },
  {
    name: "East Williston",
    state_id: "NY",
    lat: 40.7608,
    lng: -73.6336,
    id: 1840005344,
  },
  {
    name: "Seymour",
    state_id: "TX",
    lat: 33.5955,
    lng: -99.258,
    id: 1840021980,
  },
  {
    name: "Elmwood",
    state_id: "IL",
    lat: 40.7804,
    lng: -89.9656,
    id: 1840008291,
  },
  {
    name: "Madrid",
    state_id: "IA",
    lat: 41.8757,
    lng: -93.8205,
    id: 1840009168,
  },
  {
    name: "Bolivar Peninsula",
    state_id: "TX",
    lat: 29.486,
    lng: -94.5666,
    id: 1840018274,
  },
  {
    name: "Bakerstown",
    state_id: "PA",
    lat: 40.6528,
    lng: -79.9407,
    id: 1840026470,
  },
  {
    name: "Putney",
    state_id: "GA",
    lat: 31.4756,
    lng: -84.101,
    id: 1840013865,
  },
  {
    name: "Edneyville",
    state_id: "NC",
    lat: 35.4025,
    lng: -82.3371,
    id: 1840024954,
  },
  {
    name: "Broad Creek",
    state_id: "NC",
    lat: 34.7304,
    lng: -76.9241,
    id: 1840025835,
  },
  {
    name: "New Lisbon",
    state_id: "WI",
    lat: 43.878,
    lng: -90.1627,
    id: 1840002232,
  },
  {
    name: "Edwards AFB",
    state_id: "CA",
    lat: 34.9045,
    lng: -117.9295,
    id: 1840073911,
  },
  {
    name: "Eddyville",
    state_id: "KY",
    lat: 37.0759,
    lng: -88.0769,
    id: 1840013267,
  },
  {
    name: "Burlington",
    state_id: "KS",
    lat: 38.1932,
    lng: -95.7452,
    id: 1840001664,
  },
  {
    name: "Premont",
    state_id: "TX",
    lat: 27.3586,
    lng: -98.1244,
    id: 1840021009,
  },
  {
    name: "Maugansville",
    state_id: "MD",
    lat: 39.6936,
    lng: -77.7472,
    id: 1840005608,
  },
  {
    name: "Jay",
    state_id: "OK",
    lat: 36.4273,
    lng: -94.7954,
    id: 1840019046,
  },
  {
    name: "Ironton",
    state_id: "MO",
    lat: 37.5984,
    lng: -90.6379,
    id: 1840008716,
  },
  {
    name: "Jamestown West",
    state_id: "NY",
    lat: 42.0886,
    lng: -79.2811,
    id: 1840073698,
  },
  {
    name: "Nixon",
    state_id: "TX",
    lat: 29.2698,
    lng: -97.7658,
    id: 1840020954,
  },
  {
    name: "Pine Crest",
    state_id: "TN",
    lat: 36.2966,
    lng: -82.3107,
    id: 1840036143,
  },
  {
    name: "Lyford",
    state_id: "TX",
    lat: 26.4145,
    lng: -97.7894,
    id: 1840021034,
  },
  {
    name: "Selbyville",
    state_id: "DE",
    lat: 38.4631,
    lng: -75.2115,
    id: 1840006083,
  },
  {
    name: "Glenville",
    state_id: "CT",
    lat: 41.0349,
    lng: -73.6656,
    id: 1840026282,
  },
  {
    name: "Montpelier",
    state_id: "ID",
    lat: 42.326,
    lng: -111.2988,
    id: 1840020091,
  },
  {
    name: "Kalona",
    state_id: "IA",
    lat: 41.4874,
    lng: -91.7018,
    id: 1840008210,
  },
  {
    name: "Leisure Knoll",
    state_id: "NJ",
    lat: 40.0186,
    lng: -74.2908,
    id: 1840033482,
  },
  {
    name: "Konterra",
    state_id: "MD",
    lat: 39.0774,
    lng: -76.9022,
    id: 1840039491,
  },
  {
    name: "Haw River",
    state_id: "NC",
    lat: 36.0925,
    lng: -79.3616,
    id: 1840015329,
  },
  {
    name: "Nicholson",
    state_id: "MS",
    lat: 30.4866,
    lng: -89.6997,
    id: 1840025991,
  },
  {
    name: "Spirit Lake",
    state_id: "ID",
    lat: 47.966,
    lng: -116.8701,
    id: 1840021098,
  },
  {
    name: "Milton",
    state_id: "WV",
    lat: 38.4353,
    lng: -82.1376,
    id: 1840006212,
  },
  {
    name: "Lynchburg",
    state_id: "MS",
    lat: 34.9623,
    lng: -90.1052,
    id: 1840013543,
  },
  {
    name: "Imperial",
    state_id: "PA",
    lat: 40.4537,
    lng: -80.2499,
    id: 1840026477,
  },
  {
    name: "Deerfield",
    state_id: "WI",
    lat: 43.0492,
    lng: -89.0762,
    id: 1840002913,
  },
  {
    name: "Piermont",
    state_id: "NY",
    lat: 41.0423,
    lng: -73.915,
    id: 1840004982,
  },
  {
    name: "Wellsburg",
    state_id: "WV",
    lat: 40.2811,
    lng: -80.6102,
    id: 1840005478,
  },
  {
    name: "Coventry Lake",
    state_id: "CT",
    lat: 41.7736,
    lng: -72.3287,
    id: 1840028597,
  },
  {
    name: "New Hope",
    state_id: "PA",
    lat: 40.3616,
    lng: -74.9574,
    id: 1840001328,
  },
  {
    name: "Rockledge",
    state_id: "PA",
    lat: 40.0822,
    lng: -75.0898,
    id: 1840001375,
  },
  {
    name: "Lincolnville",
    state_id: "SC",
    lat: 33.0079,
    lng: -80.156,
    id: 1840017283,
  },
  {
    name: "Holly Lake Ranch",
    state_id: "TX",
    lat: 32.7125,
    lng: -95.2003,
    id: 1840042590,
  },
  {
    name: "Brookville",
    state_id: "IN",
    lat: 39.423,
    lng: -85.0105,
    id: 1840009594,
  },
  {
    name: "Owings",
    state_id: "MD",
    lat: 38.7117,
    lng: -76.6055,
    id: 1840006145,
  },
  {
    name: "Nice",
    state_id: "CA",
    lat: 39.1266,
    lng: -122.8526,
    id: 1840018803,
  },
  {
    name: "Delmont",
    state_id: "PA",
    lat: 40.4145,
    lng: -79.573,
    id: 1840003628,
  },
  {
    name: "Fredericktown",
    state_id: "OH",
    lat: 40.4784,
    lng: -82.5485,
    id: 1840001342,
  },
  {
    name: "Mays Landing",
    state_id: "NJ",
    lat: 39.4524,
    lng: -74.7241,
    id: 1840005597,
  },
  {
    name: "Osburn",
    state_id: "ID",
    lat: 47.5055,
    lng: -116.0008,
    id: 1840019799,
  },
  {
    name: "Downs",
    state_id: "IL",
    lat: 40.3988,
    lng: -88.8894,
    id: 1840011978,
  },
  {
    name: "Rock Rapids",
    state_id: "IA",
    lat: 43.4273,
    lng: -96.1662,
    id: 1840009035,
  },
  {
    name: "York Harbor",
    state_id: "ME",
    lat: 43.1435,
    lng: -70.649,
    id: 1840002677,
  },
  {
    name: "Burlington",
    state_id: "OH",
    lat: 38.4099,
    lng: -82.5283,
    id: 1840006140,
  },
  {
    name: "Lauderdale",
    state_id: "MN",
    lat: 44.9942,
    lng: -93.2026,
    id: 1840007844,
  },
  {
    name: "Clinton",
    state_id: "AR",
    lat: 35.5796,
    lng: -92.4543,
    id: 1840014529,
  },
  {
    name: "Sheridan",
    state_id: "IL",
    lat: 41.5285,
    lng: -88.679,
    id: 1840011540,
  },
  {
    name: "Pantego",
    state_id: "TX",
    lat: 32.7147,
    lng: -97.154,
    id: 1840023029,
  },
  {
    name: "Kent Acres",
    state_id: "DE",
    lat: 39.1325,
    lng: -75.5161,
    id: 1840028609,
  },
  {
    name: "West Lafayette",
    state_id: "OH",
    lat: 40.2761,
    lng: -81.7518,
    id: 1840012201,
  },
  {
    name: "Paynesville",
    state_id: "MN",
    lat: 45.3786,
    lng: -94.7216,
    id: 1840008875,
  },
  {
    name: "Jayuya",
    state_id: "PR",
    lat: 18.2188,
    lng: -66.5967,
    id: 1630023586,
  },
  {
    name: "Le Center",
    state_id: "MN",
    lat: 44.3866,
    lng: -93.7311,
    id: 1840007886,
  },
  {
    name: "North Falmouth",
    state_id: "MA",
    lat: 41.6395,
    lng: -70.626,
    id: 1840004759,
  },
  {
    name: "Mabton",
    state_id: "WA",
    lat: 46.2114,
    lng: -119.9938,
    id: 1840019873,
  },
  {
    name: "Yardley",
    state_id: "PA",
    lat: 40.2408,
    lng: -74.8385,
    id: 1840001317,
  },
  {
    name: "Ahwahnee",
    state_id: "CA",
    lat: 37.3652,
    lng: -119.7194,
    id: 1840025722,
  },
  {
    name: "Derry",
    state_id: "PA",
    lat: 40.3335,
    lng: -79.3011,
    id: 1840001133,
  },
  {
    name: "Adamstown",
    state_id: "MD",
    lat: 39.3065,
    lng: -77.4668,
    id: 1840024448,
  },
  {
    name: "Chase City",
    state_id: "VA",
    lat: 36.7998,
    lng: -78.461,
    id: 1840006538,
  },
  {
    name: "Poynette",
    state_id: "WI",
    lat: 43.3922,
    lng: -89.4058,
    id: 1840002713,
  },
  {
    name: "Idyllwild-Pine Cove",
    state_id: "CA",
    lat: 33.7443,
    lng: -116.7257,
    id: 1840074173,
  },
  {
    name: "Commerce",
    state_id: "OK",
    lat: 36.9352,
    lng: -94.8611,
    id: 1840018984,
  },
  {
    name: "Rancho Santa Fe",
    state_id: "CA",
    lat: 33.024,
    lng: -117.1992,
    id: 1840019348,
  },
  {
    name: "Indian Springs",
    state_id: "GA",
    lat: 34.9606,
    lng: -85.1592,
    id: 1840013552,
  },
  {
    name: "Mission Canyon",
    state_id: "CA",
    lat: 34.4534,
    lng: -119.7125,
    id: 1840028348,
  },
  {
    name: "White House Station",
    state_id: "NJ",
    lat: 40.6159,
    lng: -74.772,
    id: 1840033442,
  },
  {
    name: "Diamond",
    state_id: "IL",
    lat: 41.2852,
    lng: -88.2506,
    id: 1840011614,
  },
  {
    name: "Halls",
    state_id: "TN",
    lat: 35.8795,
    lng: -89.405,
    id: 1840016246,
  },
  {
    name: "Calvert City",
    state_id: "KY",
    lat: 37.0295,
    lng: -88.3603,
    id: 1840013276,
  },
  {
    name: "Sewanee",
    state_id: "TN",
    lat: 35.1975,
    lng: -85.9213,
    id: 1840013478,
  },
  {
    name: "Montara",
    state_id: "CA",
    lat: 37.5482,
    lng: -122.4924,
    id: 1840018922,
  },
  {
    name: "Overton",
    state_id: "TX",
    lat: 32.276,
    lng: -94.9726,
    id: 1840020770,
  },
  {
    name: "Victor",
    state_id: "ID",
    lat: 43.6015,
    lng: -111.1107,
    id: 1840021273,
  },
  {
    name: "Wrangell",
    state_id: "AK",
    lat: 56.3317,
    lng: -132.0223,
    id: 1840000416,
  },
  {
    name: "Bunker Hill",
    state_id: "OR",
    lat: 43.3502,
    lng: -124.2082,
    id: 1840017442,
  },
  {
    name: "Lake Wynonah",
    state_id: "PA",
    lat: 40.5878,
    lng: -76.1806,
    id: 1840035383,
  },
  {
    name: "Coats",
    state_id: "NC",
    lat: 35.407,
    lng: -78.6685,
    id: 1840016327,
  },
  {
    name: "Alderson",
    state_id: "WV",
    lat: 37.7277,
    lng: -80.6436,
    id: 1840006288,
  },
  {
    name: "Covington",
    state_id: "IN",
    lat: 40.1406,
    lng: -87.3913,
    id: 1840007243,
  },
  {
    name: "Toftrees",
    state_id: "PA",
    lat: 40.8249,
    lng: -77.885,
    id: 1840035453,
  },
  {
    name: "Country Club",
    state_id: "MO",
    lat: 39.8388,
    lng: -94.8205,
    id: 1840032036,
  },
  {
    name: "Clio",
    state_id: "MI",
    lat: 43.1773,
    lng: -83.7355,
    id: 1840002946,
  },
  {
    name: "Gibsonburg",
    state_id: "OH",
    lat: 41.3875,
    lng: -83.3216,
    id: 1840011601,
  },
  {
    name: "Geneva",
    state_id: "FL",
    lat: 28.7377,
    lng: -81.1143,
    id: 1840014069,
  },
  {
    name: "Lake Crystal",
    state_id: "MN",
    lat: 44.1054,
    lng: -94.2188,
    id: 1840007905,
  },
  {
    name: "Charleston",
    state_id: "MS",
    lat: 34.0075,
    lng: -90.0552,
    id: 1840014746,
  },
  {
    name: "Tainter Lake",
    state_id: "WI",
    lat: 44.9882,
    lng: -91.8437,
    id: 1840004024,
  },
  {
    name: "Silver Lake",
    state_id: "OH",
    lat: 41.1593,
    lng: -81.4605,
    id: 1840000802,
  },
  {
    name: "Antón Ruíz",
    state_id: "PR",
    lat: 18.1883,
    lng: -65.8079,
    id: 1630035459,
  },
  {
    name: "West Branch",
    state_id: "IA",
    lat: 41.6662,
    lng: -91.3442,
    id: 1840010174,
  },
  {
    name: "Raoul",
    state_id: "GA",
    lat: 34.4548,
    lng: -83.5995,
    id: 1840013577,
  },
  {
    name: "State Line",
    state_id: "PA",
    lat: 39.7329,
    lng: -77.7247,
    id: 1840026534,
  },
  {
    name: "Matoaca",
    state_id: "VA",
    lat: 37.2313,
    lng: -77.4679,
    id: 1840006411,
  },
  {
    name: "Oakland City",
    state_id: "IN",
    lat: 38.3378,
    lng: -87.3494,
    id: 1840008645,
  },
  {
    name: "Pottsboro",
    state_id: "TX",
    lat: 33.7708,
    lng: -96.6714,
    id: 1840021959,
  },
  {
    name: "St. Hedwig",
    state_id: "TX",
    lat: 29.4197,
    lng: -98.2046,
    id: 1840022050,
  },
  {
    name: "South Hutchinson",
    state_id: "KS",
    lat: 38.0278,
    lng: -97.9428,
    id: 1840009762,
  },
  {
    name: "Quanah",
    state_id: "TX",
    lat: 34.2954,
    lng: -99.7429,
    id: 1840020530,
  },
  {
    name: "Long Neck",
    state_id: "DE",
    lat: 38.62,
    lng: -75.1511,
    id: 1840028617,
  },
  {
    name: "The Hills",
    state_id: "TX",
    lat: 30.3465,
    lng: -97.9864,
    id: 1840023146,
  },
  {
    name: "Isle of Hope",
    state_id: "GA",
    lat: 31.9848,
    lng: -81.0532,
    id: 1840013837,
  },
  {
    name: "Gambier",
    state_id: "OH",
    lat: 40.3764,
    lng: -82.3949,
    id: 1840012140,
  },
  {
    name: "Río Lajas",
    state_id: "PR",
    lat: 18.397,
    lng: -66.2643,
    id: 1630035564,
  },
  {
    name: "Hart",
    state_id: "MI",
    lat: 43.6972,
    lng: -86.3639,
    id: 1840002662,
  },
  {
    name: "Spring Lake",
    state_id: "MI",
    lat: 43.0733,
    lng: -86.1944,
    id: 1840011010,
  },
  {
    name: "Wautoma",
    state_id: "WI",
    lat: 44.0669,
    lng: -89.2915,
    id: 1840002235,
  },
  {
    name: "Bono",
    state_id: "AR",
    lat: 35.9112,
    lng: -90.8009,
    id: 1840013396,
  },
  {
    name: "South Congaree",
    state_id: "SC",
    lat: 33.9094,
    lng: -81.1375,
    id: 1840017968,
  },
  {
    name: "Osceola",
    state_id: "IN",
    lat: 41.6647,
    lng: -86.0787,
    id: 1840009245,
  },
  {
    name: "San José",
    state_id: "PR",
    lat: 18.4029,
    lng: -66.2492,
    id: 1630023734,
  },
  {
    name: "South Sumter",
    state_id: "SC",
    lat: 33.8855,
    lng: -80.3378,
    id: 1840035799,
  },
  {
    name: "Lincoln",
    state_id: "AR",
    lat: 35.949,
    lng: -94.4174,
    id: 1840015335,
  },
  {
    name: "Walnut Hill",
    state_id: "TN",
    lat: 36.5679,
    lng: -82.2655,
    id: 1840013299,
  },
  {
    name: "Cumings",
    state_id: "TX",
    lat: 29.5836,
    lng: -95.7966,
    id: 1840019641,
  },
  {
    name: "Brooks",
    state_id: "KY",
    lat: 38.0656,
    lng: -85.7178,
    id: 1840013215,
  },
  {
    name: "Essex Village",
    state_id: "CT",
    lat: 41.3552,
    lng: -72.391,
    id: 1840073295,
  },
  {
    name: "Richwood",
    state_id: "OH",
    lat: 40.4277,
    lng: -83.2955,
    id: 1840012171,
  },
  {
    name: "West Newton",
    state_id: "PA",
    lat: 40.2094,
    lng: -79.77,
    id: 1840001165,
  },
  {
    name: "Bentleyville",
    state_id: "PA",
    lat: 40.1178,
    lng: -80.0044,
    id: 1840003640,
  },
  {
    name: "Bourg",
    state_id: "LA",
    lat: 29.557,
    lng: -90.6064,
    id: 1840025140,
  },
  {
    name: "Hudson Oaks",
    state_id: "TX",
    lat: 32.7508,
    lng: -97.6999,
    id: 1840020691,
  },
  {
    name: "Bayou La Batre",
    state_id: "AL",
    lat: 30.4126,
    lng: -88.261,
    id: 1840013897,
  },
  {
    name: "Lennox",
    state_id: "SD",
    lat: 43.3479,
    lng: -96.8939,
    id: 1840002847,
  },
  {
    name: "Beverly",
    state_id: "NJ",
    lat: 40.0652,
    lng: -74.9221,
    id: 1840001475,
  },
  {
    name: "Telluride",
    state_id: "CO",
    lat: 37.9364,
    lng: -107.8269,
    id: 1840022536,
  },
  {
    name: "Aromas",
    state_id: "CA",
    lat: 36.8771,
    lng: -121.6404,
    id: 1840017680,
  },
  {
    name: "Galva",
    state_id: "IL",
    lat: 41.1689,
    lng: -90.0375,
    id: 1840008204,
  },
  {
    name: "Conrad",
    state_id: "MT",
    lat: 48.1741,
    lng: -111.9466,
    id: 1840018371,
  },
  {
    name: "Versailles",
    state_id: "MO",
    lat: 38.4332,
    lng: -92.8453,
    id: 1840010776,
  },
  {
    name: "Chesterfield",
    state_id: "IN",
    lat: 40.113,
    lng: -85.5943,
    id: 1840009437,
  },
  {
    name: "Trinity",
    state_id: "AL",
    lat: 34.6012,
    lng: -87.0818,
    id: 1840016612,
  },
  {
    name: "Hebron",
    state_id: "OH",
    lat: 39.9644,
    lng: -82.4898,
    id: 1840012330,
  },
  {
    name: "Kenilworth",
    state_id: "IL",
    lat: 42.0888,
    lng: -87.7144,
    id: 1840011254,
  },
  {
    name: "Casselton",
    state_id: "ND",
    lat: 46.8972,
    lng: -97.2129,
    id: 1840000175,
  },
  {
    name: "Cannon AFB",
    state_id: "NM",
    lat: 34.3885,
    lng: -103.3185,
    id: 1840073903,
  },
  {
    name: "Orchard Lake Village",
    state_id: "MI",
    lat: 42.5859,
    lng: -83.3756,
    id: 1840072998,
  },
  {
    name: "Wabasha",
    state_id: "MN",
    lat: 44.3707,
    lng: -92.042,
    id: 1840010023,
  },
  {
    name: "Elm Springs",
    state_id: "AR",
    lat: 36.2069,
    lng: -94.2366,
    id: 1840013366,
  },
  {
    name: "Lake Katrine",
    state_id: "NY",
    lat: 41.9863,
    lng: -73.9895,
    id: 1840004719,
  },
  {
    name: "Hoisington",
    state_id: "KS",
    lat: 38.518,
    lng: -98.7773,
    id: 1840001657,
  },
  {
    name: "Phillipsburg",
    state_id: "KS",
    lat: 39.7512,
    lng: -99.321,
    id: 1840000725,
  },
  {
    name: "Ranger",
    state_id: "TX",
    lat: 32.4693,
    lng: -98.6751,
    id: 1840020766,
  },
  {
    name: "Midland",
    state_id: "PA",
    lat: 40.6373,
    lng: -80.4565,
    id: 1840003588,
  },
  {
    name: "Surf City",
    state_id: "NC",
    lat: 34.4346,
    lng: -77.5644,
    id: 1840016603,
  },
  {
    name: "Hudson",
    state_id: "IA",
    lat: 42.432,
    lng: -92.4522,
    id: 1840006947,
  },
  {
    name: "Charleston",
    state_id: "AR",
    lat: 35.2954,
    lng: -94.0461,
    id: 1840014530,
  },
  {
    name: "Greenville",
    state_id: "DE",
    lat: 39.7783,
    lng: -75.6049,
    id: 1840005572,
  },
  {
    name: "Wallace",
    state_id: "FL",
    lat: 30.6746,
    lng: -87.1996,
    id: 1840027010,
  },
  {
    name: "Nicoma Park",
    state_id: "OK",
    lat: 35.4912,
    lng: -97.3254,
    id: 1840020427,
  },
  {
    name: "Marengo",
    state_id: "IA",
    lat: 41.7961,
    lng: -92.0675,
    id: 1840008154,
  },
  {
    name: "Gloucester Courthouse",
    state_id: "VA",
    lat: 37.4053,
    lng: -76.526,
    id: 1840037468,
  },
  {
    name: "Olmos Park",
    state_id: "TX",
    lat: 29.4749,
    lng: -98.4867,
    id: 1840020961,
  },
  {
    name: "Stonewall",
    state_id: "LA",
    lat: 32.2715,
    lng: -93.81,
    id: 1840017014,
  },
  {
    name: "Mineral Point",
    state_id: "WI",
    lat: 42.8632,
    lng: -90.1814,
    id: 1840002961,
  },
  {
    name: "Belleville",
    state_id: "WI",
    lat: 42.8643,
    lng: -89.5402,
    id: 1840002906,
  },
  {
    name: "Strafford",
    state_id: "MO",
    lat: 37.2691,
    lng: -93.1177,
    id: 1840009905,
  },
  {
    name: "Rancho Viejo",
    state_id: "TX",
    lat: 26.0361,
    lng: -97.5568,
    id: 1840022262,
  },
  {
    name: "New Egypt",
    state_id: "NJ",
    lat: 40.0644,
    lng: -74.5284,
    id: 1840005531,
  },
  {
    name: "Geneva",
    state_id: "WA",
    lat: 48.7461,
    lng: -122.4064,
    id: 1840017291,
  },
  {
    name: "Windy Hills",
    state_id: "KY",
    lat: 38.2702,
    lng: -85.6378,
    id: 1840015202,
  },
  {
    name: "Gastonville",
    state_id: "PA",
    lat: 40.2669,
    lng: -80.0095,
    id: 1840005435,
  },
  {
    name: "Weatherly",
    state_id: "PA",
    lat: 40.942,
    lng: -75.821,
    id: 1840035248,
  },
  {
    name: "Carthage",
    state_id: "IL",
    lat: 40.4143,
    lng: -91.1276,
    id: 1840007219,
  },
  {
    name: "Warren AFB",
    state_id: "WY",
    lat: 41.1488,
    lng: -104.8618,
    id: 1840074698,
  },
  {
    name: "Oliver",
    state_id: "PA",
    lat: 39.9153,
    lng: -79.7213,
    id: 1840005540,
  },
  {
    name: "Fennimore",
    state_id: "WI",
    lat: 42.9793,
    lng: -90.6492,
    id: 1840002968,
  },
  {
    name: "Bellevue",
    state_id: "ID",
    lat: 43.4685,
    lng: -114.2551,
    id: 1840018633,
  },
  {
    name: "Goldsby",
    state_id: "OK",
    lat: 35.1304,
    lng: -97.4779,
    id: 1840021810,
  },
  {
    name: "Liberty",
    state_id: "PA",
    lat: 40.3244,
    lng: -79.8589,
    id: 1840001241,
  },
  {
    name: "Window Rock",
    state_id: "AZ",
    lat: 35.6705,
    lng: -109.0639,
    id: 1840019005,
  },
  {
    name: "Avilla",
    state_id: "IN",
    lat: 41.3637,
    lng: -85.2317,
    id: 1840010253,
  },
  {
    name: "Fairmont City",
    state_id: "IL",
    lat: 38.6504,
    lng: -90.1025,
    id: 1840012869,
  },
  {
    name: "Grissom AFB",
    state_id: "IN",
    lat: 40.6585,
    lng: -86.148,
    id: 1840073761,
  },
  {
    name: "Alianza",
    state_id: "PR",
    lat: 18.4533,
    lng: -66.8556,
    id: 1630027033,
  },
  {
    name: "H. Rivera Colón",
    state_id: "PR",
    lat: 18.3482,
    lng: -66.2733,
    id: 1630035482,
  },
  {
    name: "Harriman",
    state_id: "NY",
    lat: 41.3088,
    lng: -74.1444,
    id: 1840004870,
  },
  {
    name: "Ross",
    state_id: "CA",
    lat: 37.9638,
    lng: -122.5615,
    id: 1840021515,
  },
  {
    name: "Middletown",
    state_id: "IN",
    lat: 40.0596,
    lng: -85.5422,
    id: 1840009482,
  },
  {
    name: "Parksdale",
    state_id: "CA",
    lat: 36.9465,
    lng: -120.0212,
    id: 1840028393,
  },
  {
    name: "Lomira",
    state_id: "WI",
    lat: 43.5944,
    lng: -88.442,
    id: 1840002740,
  },
  {
    name: "Norwood",
    state_id: "NC",
    lat: 35.2336,
    lng: -80.1146,
    id: 1840016367,
  },
  {
    name: "Newmanstown",
    state_id: "PA",
    lat: 40.3514,
    lng: -76.2117,
    id: 1840005428,
  },
  {
    name: "Winters",
    state_id: "TX",
    lat: 31.9564,
    lng: -99.9293,
    id: 1840022132,
  },
  {
    name: "Lakeview Estates",
    state_id: "GA",
    lat: 33.7046,
    lng: -84.0367,
    id: 1840029280,
  },
  {
    name: "Blue Mound",
    state_id: "TX",
    lat: 32.8543,
    lng: -97.3383,
    id: 1840019426,
  },
  {
    name: "Hawarden",
    state_id: "IA",
    lat: 43.0015,
    lng: -96.4829,
    id: 1840006871,
  },
  {
    name: "Woodside",
    state_id: "PA",
    lat: 40.2305,
    lng: -74.8607,
    id: 1840133907,
  },
  {
    name: "Combine",
    state_id: "TX",
    lat: 32.5912,
    lng: -96.5183,
    id: 1840019451,
  },
  {
    name: "Rural Hill",
    state_id: "TN",
    lat: 36.1164,
    lng: -86.5152,
    id: 1840013351,
  },
  {
    name: "Belle Plaine",
    state_id: "IA",
    lat: 41.8954,
    lng: -92.2751,
    id: 1840000468,
  },
  {
    name: "Monroe City",
    state_id: "MO",
    lat: 39.6542,
    lng: -91.7328,
    id: 1840008451,
  },
  {
    name: "Kentwood",
    state_id: "LA",
    lat: 30.9339,
    lng: -90.5153,
    id: 1840017154,
  },
  {
    name: "Trucksville",
    state_id: "PA",
    lat: 41.3101,
    lng: -75.9281,
    id: 1840035227,
  },
  {
    name: "Shadow Lake",
    state_id: "WA",
    lat: 47.4017,
    lng: -122.0764,
    id: 1840042051,
  },
  {
    name: "Claryville",
    state_id: "KY",
    lat: 38.9141,
    lng: -84.4103,
    id: 1840030406,
  },
  {
    name: "Morgantown",
    state_id: "KY",
    lat: 37.2173,
    lng: -86.7001,
    id: 1840014380,
  },
  {
    name: "Deltana",
    state_id: "AK",
    lat: 63.8724,
    lng: -145.2158,
    id: 1840075067,
  },
  {
    name: "Inman",
    state_id: "SC",
    lat: 35.0465,
    lng: -82.09,
    id: 1840014615,
  },
  {
    name: "Wyldwood",
    state_id: "TX",
    lat: 30.1299,
    lng: -97.4801,
    id: 1840019602,
  },
  {
    name: "Stormstown",
    state_id: "PA",
    lat: 40.789,
    lng: -78.0191,
    id: 1840005168,
  },
  {
    name: "Wayne",
    state_id: "IL",
    lat: 41.9494,
    lng: -88.2584,
    id: 1840011413,
  },
  {
    name: "Maynardville",
    state_id: "TN",
    lat: 36.247,
    lng: -83.8057,
    id: 1840015305,
  },
  {
    name: "Taylor",
    state_id: "AL",
    lat: 31.1658,
    lng: -85.4697,
    id: 1840017133,
  },
  {
    name: "Bella Vista",
    state_id: "CA",
    lat: 40.6502,
    lng: -122.245,
    id: 1840022392,
  },
  {
    name: "Lavaca",
    state_id: "AR",
    lat: 35.3341,
    lng: -94.1799,
    id: 1840014577,
  },
  {
    name: "Woodside East",
    state_id: "DE",
    lat: 39.0676,
    lng: -75.5376,
    id: 1840073744,
  },
  {
    name: "Cathcart",
    state_id: "WA",
    lat: 47.8525,
    lng: -122.1058,
    id: 1840017324,
  },
  {
    name: "Cave City",
    state_id: "KY",
    lat: 37.14,
    lng: -85.9646,
    id: 1840014394,
  },
  {
    name: "Fall City",
    state_id: "WA",
    lat: 47.5717,
    lng: -121.9134,
    id: 1840017342,
  },
  {
    name: "Collins",
    state_id: "MS",
    lat: 31.6469,
    lng: -89.5669,
    id: 1840014948,
  },
  {
    name: "Pleasant Ridge",
    state_id: "MI",
    lat: 42.4715,
    lng: -83.1443,
    id: 1840002426,
  },
  {
    name: "Melrose Park",
    state_id: "NY",
    lat: 42.9085,
    lng: -76.5263,
    id: 1840004237,
  },
  {
    name: "Panorama Village",
    state_id: "TX",
    lat: 30.3806,
    lng: -95.4944,
    id: 1840020887,
  },
  {
    name: "Ingalls",
    state_id: "IN",
    lat: 39.9746,
    lng: -85.8148,
    id: 1840010457,
  },
  {
    name: "Three Rivers",
    state_id: "CA",
    lat: 36.4319,
    lng: -118.8806,
    id: 1840019038,
  },
  {
    name: "Anchorage",
    state_id: "KY",
    lat: 38.2689,
    lng: -85.5363,
    id: 1840013198,
  },
  {
    name: "North Zanesville",
    state_id: "OH",
    lat: 39.9905,
    lng: -81.9982,
    id: 1840005538,
  },
  {
    name: "Nekoosa",
    state_id: "WI",
    lat: 44.3135,
    lng: -89.9073,
    id: 1840002338,
  },
  {
    name: "Prairie Creek",
    state_id: "AR",
    lat: 36.34,
    lng: -94.0613,
    id: 1840027774,
  },
  {
    name: "Midway South",
    state_id: "TX",
    lat: 26.1567,
    lng: -98.0209,
    id: 1840073264,
  },
  {
    name: "Marked Tree",
    state_id: "AR",
    lat: 35.5251,
    lng: -90.4251,
    id: 1840015405,
  },
  {
    name: "Pinckney",
    state_id: "MI",
    lat: 42.4543,
    lng: -83.9457,
    id: 1840011079,
  },
  {
    name: "Crownpoint",
    state_id: "NM",
    lat: 35.688,
    lng: -108.1494,
    id: 1840017763,
  },
  {
    name: "Waynesboro",
    state_id: "TN",
    lat: 35.3236,
    lng: -87.7593,
    id: 1840015439,
  },
  {
    name: "Cowpens",
    state_id: "SC",
    lat: 35.0188,
    lng: -81.8043,
    id: 1840016463,
  },
  {
    name: "Villa Grove",
    state_id: "IL",
    lat: 39.8644,
    lng: -88.16,
    id: 1840010588,
  },
  {
    name: "Tiburones",
    state_id: "PR",
    lat: 18.4382,
    lng: -66.5809,
    id: 1630023650,
  },
  {
    name: "Barnum Island",
    state_id: "NY",
    lat: 40.605,
    lng: -73.6437,
    id: 1840034042,
  },
  {
    name: "Woodbine",
    state_id: "NJ",
    lat: 39.2283,
    lng: -74.8096,
    id: 1840001604,
  },
  {
    name: "Lawson",
    state_id: "MO",
    lat: 39.436,
    lng: -94.2151,
    id: 1840008487,
  },
  {
    name: "Allyn",
    state_id: "WA",
    lat: 47.3863,
    lng: -122.8386,
    id: 1840025251,
  },
  {
    name: "Westmoreland",
    state_id: "TN",
    lat: 36.562,
    lng: -86.246,
    id: 1840016015,
  },
  {
    name: "Laporte",
    state_id: "CO",
    lat: 40.6385,
    lng: -105.1444,
    id: 1840017498,
  },
  {
    name: "Shepherd",
    state_id: "TX",
    lat: 30.491,
    lng: -95.0021,
    id: 1840022168,
  },
  {
    name: "Breezy Point",
    state_id: "MN",
    lat: 46.6081,
    lng: -94.218,
    id: 1840004002,
  },
  {
    name: "Eddystone",
    state_id: "PA",
    lat: 39.8566,
    lng: -75.3342,
    id: 1840000697,
  },
  {
    name: "Houston",
    state_id: "AK",
    lat: 61.6159,
    lng: -149.8003,
    id: 1840023378,
  },
  {
    name: "Perryman",
    state_id: "MD",
    lat: 39.4633,
    lng: -76.2115,
    id: 1840005663,
  },
  {
    name: "Santa Claus",
    state_id: "IN",
    lat: 38.1168,
    lng: -86.9292,
    id: 1840010826,
  },
  {
    name: "Verona",
    state_id: "PA",
    lat: 40.505,
    lng: -79.8419,
    id: 1840001268,
  },
  {
    name: "Dalton Gardens",
    state_id: "ID",
    lat: 47.7328,
    lng: -116.7696,
    id: 1840018403,
  },
  {
    name: "Bloomfield",
    state_id: "IN",
    lat: 39.026,
    lng: -86.9375,
    id: 1840009693,
  },
  {
    name: "Spring Valley",
    state_id: "MN",
    lat: 43.6888,
    lng: -92.3897,
    id: 1840009010,
  },
  {
    name: "Arlington Heights",
    state_id: "WA",
    lat: 48.2119,
    lng: -122.063,
    id: 1840037743,
  },
  {
    name: "Neillsville",
    state_id: "WI",
    lat: 44.5605,
    lng: -90.5905,
    id: 1840002255,
  },
  {
    name: "Monticello",
    state_id: "FL",
    lat: 30.5423,
    lng: -83.8721,
    id: 1840015025,
  },
  {
    name: "Chauvin",
    state_id: "LA",
    lat: 29.4455,
    lng: -90.594,
    id: 1840014032,
  },
  {
    name: "Coudersport",
    state_id: "PA",
    lat: 41.7758,
    lng: -78.016,
    id: 1840003307,
  },
  {
    name: "Lake of the Woods",
    state_id: "IL",
    lat: 40.2048,
    lng: -88.3732,
    id: 1840029898,
  },
  {
    name: "Lockport",
    state_id: "LA",
    lat: 29.6418,
    lng: -90.5376,
    id: 1840017216,
  },
  {
    name: "Junction",
    state_id: "TX",
    lat: 30.4906,
    lng: -99.7725,
    id: 1840019579,
  },
  {
    name: "Spring Arbor",
    state_id: "MI",
    lat: 42.2055,
    lng: -84.5551,
    id: 1840004654,
  },
  {
    name: "Marcus Hook",
    state_id: "PA",
    lat: 39.8131,
    lng: -75.4165,
    id: 1840000701,
  },
  {
    name: "Charlotte Park",
    state_id: "FL",
    lat: 26.9031,
    lng: -82.0486,
    id: 1840028650,
  },
  {
    name: "Thornton",
    state_id: "IL",
    lat: 41.5711,
    lng: -87.6187,
    id: 1840011330,
  },
  {
    name: "Enfield",
    state_id: "NC",
    lat: 36.1802,
    lng: -77.6678,
    id: 1840016063,
  },
  {
    name: "Meyersdale",
    state_id: "PA",
    lat: 39.8133,
    lng: -79.0277,
    id: 1840003740,
  },
  {
    name: "North Seekonk",
    state_id: "MA",
    lat: 41.8882,
    lng: -71.3297,
    id: 1840031243,
  },
  {
    name: "Lordsburg",
    state_id: "NM",
    lat: 32.3438,
    lng: -108.7021,
    id: 1840020740,
  },
  {
    name: "El Portal",
    state_id: "FL",
    lat: 25.8556,
    lng: -80.1954,
    id: 1840018332,
  },
  {
    name: "East Dublin",
    state_id: "GA",
    lat: 32.5468,
    lng: -82.8675,
    id: 1840013815,
  },
  {
    name: "Sisseton",
    state_id: "SD",
    lat: 45.6625,
    lng: -97.0453,
    id: 1840002037,
  },
  {
    name: "Lapel",
    state_id: "IN",
    lat: 40.0334,
    lng: -85.8407,
    id: 1840009439,
  },
  {
    name: "Campo Rico",
    state_id: "PR",
    lat: 18.3371,
    lng: -65.8974,
    id: 1630035631,
  },
  {
    name: "Carrollton",
    state_id: "IL",
    lat: 39.2948,
    lng: -90.4062,
    id: 1840007368,
  },
  {
    name: "Bolindale",
    state_id: "OH",
    lat: 41.2089,
    lng: -80.7784,
    id: 1840034380,
  },
  {
    name: "St. Ignace",
    state_id: "MI",
    lat: 45.8695,
    lng: -84.7255,
    id: 1840003940,
  },
  {
    name: "Colonial Pine Hills",
    state_id: "SD",
    lat: 44.0141,
    lng: -103.3146,
    id: 1840004100,
  },
  {
    name: "Danville",
    state_id: "AR",
    lat: 35.053,
    lng: -93.3902,
    id: 1840014606,
  },
  {
    name: "Hide-A-Way Lake",
    state_id: "MS",
    lat: 30.574,
    lng: -89.6419,
    id: 1840032566,
  },
  {
    name: "Purvis",
    state_id: "MS",
    lat: 31.145,
    lng: -89.4054,
    id: 1840014969,
  },
  {
    name: "Madison",
    state_id: "NE",
    lat: 41.8283,
    lng: -97.4568,
    id: 1840009191,
  },
  {
    name: "Glasco",
    state_id: "NY",
    lat: 42.0461,
    lng: -73.9487,
    id: 1840004714,
  },
  {
    name: "Reed City",
    state_id: "MI",
    lat: 43.8725,
    lng: -85.5069,
    id: 1840002559,
  },
  {
    name: "Mayflower",
    state_id: "AR",
    lat: 34.9686,
    lng: -92.4225,
    id: 1840015464,
  },
  {
    name: "Hunter",
    state_id: "TN",
    lat: 36.3978,
    lng: -82.1511,
    id: 1840013311,
  },
  {
    name: "Mendenhall",
    state_id: "MS",
    lat: 31.9609,
    lng: -89.8692,
    id: 1840015846,
  },
  {
    name: "Buckhorn",
    state_id: "CA",
    lat: 38.4532,
    lng: -120.5354,
    id: 1840025656,
  },
  {
    name: "Seneca",
    state_id: "MO",
    lat: 36.8447,
    lng: -94.6093,
    id: 1840009927,
  },
  {
    name: "Wright-Patterson AFB",
    state_id: "OH",
    lat: 39.8112,
    lng: -84.0573,
    id: 1840073790,
  },
  {
    name: "Enon",
    state_id: "OH",
    lat: 39.865,
    lng: -83.9331,
    id: 1840012435,
  },
  {
    name: "Conneaut Lakeshore",
    state_id: "PA",
    lat: 41.6272,
    lng: -80.3102,
    id: 1840073612,
  },
  {
    name: "Hallowell",
    state_id: "ME",
    lat: 44.2904,
    lng: -69.8141,
    id: 1840000304,
  },
  {
    name: "Johnston",
    state_id: "SC",
    lat: 33.8327,
    lng: -81.8051,
    id: 1840016778,
  },
  {
    name: "New Miami",
    state_id: "OH",
    lat: 39.4319,
    lng: -84.5399,
    id: 1840012608,
  },
  {
    name: "Barranquitas",
    state_id: "PR",
    lat: 18.1847,
    lng: -66.3102,
    id: 1630035619,
  },
  {
    name: "Virginia Gardens",
    state_id: "FL",
    lat: 25.8095,
    lng: -80.2974,
    id: 1840018337,
  },
  {
    name: "Susquehanna Depot",
    state_id: "PA",
    lat: 41.9438,
    lng: -75.6039,
    id: 1840066084,
  },
  {
    name: "Odell",
    state_id: "OR",
    lat: 45.6354,
    lng: -121.5522,
    id: 1840018559,
  },
  {
    name: "Icard",
    state_id: "NC",
    lat: 35.7253,
    lng: -81.4582,
    id: 1840013391,
  },
  {
    name: "Paden City",
    state_id: "WV",
    lat: 39.6035,
    lng: -80.9351,
    id: 1840005671,
  },
  {
    name: "Poland",
    state_id: "OH",
    lat: 41.022,
    lng: -80.6156,
    id: 1840011777,
  },
  {
    name: "Riverside",
    state_id: "AL",
    lat: 33.6171,
    lng: -86.2042,
    id: 1840017995,
  },
  {
    name: "Harlem",
    state_id: "FL",
    lat: 26.7325,
    lng: -80.9518,
    id: 1840014209,
  },
  {
    name: "Craigsville",
    state_id: "WV",
    lat: 38.3247,
    lng: -80.645,
    id: 1840006225,
  },
  {
    name: "St. Bonifacius",
    state_id: "MN",
    lat: 44.9054,
    lng: -93.7476,
    id: 1840008934,
  },
  {
    name: "Kimball",
    state_id: "NE",
    lat: 41.2335,
    lng: -103.6512,
    id: 1840008252,
  },
  {
    name: "Highspire",
    state_id: "PA",
    lat: 40.2085,
    lng: -76.7851,
    id: 1840001289,
  },
  {
    name: "Fruitland",
    state_id: "NC",
    lat: 35.3928,
    lng: -82.412,
    id: 1840024955,
  },
  {
    name: "Albion",
    state_id: "IN",
    lat: 41.3966,
    lng: -85.4187,
    id: 1840010252,
  },
  {
    name: "Mount Vernon",
    state_id: "GA",
    lat: 32.1836,
    lng: -82.5953,
    id: 1840014905,
  },
  {
    name: "Hickman",
    state_id: "NE",
    lat: 40.6248,
    lng: -96.6306,
    id: 1840007168,
  },
  {
    name: "Decherd",
    state_id: "TN",
    lat: 35.2156,
    lng: -86.0756,
    id: 1840014601,
  },
  {
    name: "Whidbey Island Station",
    state_id: "WA",
    lat: 48.3387,
    lng: -122.6616,
    id: 1840073897,
  },
  {
    name: "Calera",
    state_id: "OK",
    lat: 33.9312,
    lng: -96.4299,
    id: 1840022897,
  },
  {
    name: "Dalworthington Gardens",
    state_id: "TX",
    lat: 32.6936,
    lng: -97.1565,
    id: 1840019432,
  },
  {
    name: "Salado",
    state_id: "TX",
    lat: 30.9462,
    lng: -97.5281,
    id: 1840023130,
  },
  {
    name: "Newton",
    state_id: "TX",
    lat: 30.8502,
    lng: -93.7537,
    id: 1840020855,
  },
  {
    name: "Pine Canyon",
    state_id: "CA",
    lat: 36.1757,
    lng: -121.1498,
    id: 1840076064,
  },
  {
    name: "Ponder",
    state_id: "TX",
    lat: 33.1775,
    lng: -97.291,
    id: 1840022009,
  },
  {
    name: "Chester",
    state_id: "WV",
    lat: 40.6129,
    lng: -80.5627,
    id: 1840005403,
  },
  {
    name: "Kinder",
    state_id: "LA",
    lat: 30.4835,
    lng: -92.8508,
    id: 1840017166,
  },
  {
    name: "Horse Shoe",
    state_id: "NC",
    lat: 35.3423,
    lng: -82.5568,
    id: 1840024957,
  },
  {
    name: "Fife Heights",
    state_id: "WA",
    lat: 47.2593,
    lng: -122.3459,
    id: 1840037653,
  },
  {
    name: "Prague",
    state_id: "OK",
    lat: 35.4997,
    lng: -96.6999,
    id: 1840020396,
  },
  {
    name: "Lake Heritage",
    state_id: "PA",
    lat: 39.8098,
    lng: -77.1854,
    id: 1840035412,
  },
  {
    name: "Lovell",
    state_id: "WY",
    lat: 44.8357,
    lng: -108.3919,
    id: 1840021242,
  },
  {
    name: "Chamberlain",
    state_id: "SD",
    lat: 43.7861,
    lng: -99.3269,
    id: 1840000336,
  },
  {
    name: "Timmonsville",
    state_id: "SC",
    lat: 34.1334,
    lng: -79.9408,
    id: 1840016706,
  },
  {
    name: "Concordia",
    state_id: "MO",
    lat: 38.9877,
    lng: -93.5683,
    id: 1840007385,
  },
  {
    name: "Pine Ridge",
    state_id: "SC",
    lat: 33.9084,
    lng: -81.0975,
    id: 1840035828,
  },
  {
    name: "Ashville",
    state_id: "AL",
    lat: 33.8331,
    lng: -86.2698,
    id: 1840013697,
  },
  {
    name: "Henagar",
    state_id: "AL",
    lat: 34.631,
    lng: -85.7405,
    id: 1840014659,
  },
  {
    name: "Riverside",
    state_id: "NY",
    lat: 40.9093,
    lng: -72.6699,
    id: 1840142078,
  },
  {
    name: "Good Hope",
    state_id: "AL",
    lat: 34.1098,
    lng: -86.8651,
    id: 1840003328,
  },
  {
    name: "Matamoras",
    state_id: "PA",
    lat: 41.3666,
    lng: -74.6997,
    id: 1840000627,
  },
  {
    name: "Montague",
    state_id: "MI",
    lat: 43.4128,
    lng: -86.3636,
    id: 1840002869,
  },
  {
    name: "Red Hill",
    state_id: "PA",
    lat: 40.3771,
    lng: -75.4838,
    id: 1840001374,
  },
  {
    name: "Dillingham",
    state_id: "AK",
    lat: 59.055,
    lng: -158.5379,
    id: 1840023279,
  },
  {
    name: "Rainbow",
    state_id: "CA",
    lat: 33.4098,
    lng: -117.1395,
    id: 1840019345,
  },
  {
    name: "Monroe",
    state_id: "UT",
    lat: 38.6229,
    lng: -112.1198,
    id: 1840020240,
  },
  {
    name: "New London",
    state_id: "OH",
    lat: 41.0796,
    lng: -82.4066,
    id: 1840011709,
  },
  {
    name: "Midland City",
    state_id: "AL",
    lat: 31.3157,
    lng: -85.4937,
    id: 1840017084,
  },
  {
    name: "Walters",
    state_id: "OK",
    lat: 34.3612,
    lng: -98.3563,
    id: 1840021906,
  },
  {
    name: "Brewster",
    state_id: "WA",
    lat: 48.1015,
    lng: -119.776,
    id: 1840018359,
  },
  {
    name: "Hinton",
    state_id: "WV",
    lat: 37.6651,
    lng: -80.8828,
    id: 1840006368,
  },
  {
    name: "East Brewton",
    state_id: "AL",
    lat: 31.0906,
    lng: -87.0556,
    id: 1840013889,
  },
  {
    name: "Raceland",
    state_id: "KY",
    lat: 38.5376,
    lng: -82.7344,
    id: 1840014274,
  },
  {
    name: "Deep River Center",
    state_id: "CT",
    lat: 41.3823,
    lng: -72.4386,
    id: 1840073290,
  },
  {
    name: "Carleton",
    state_id: "MI",
    lat: 42.0572,
    lng: -83.3898,
    id: 1840010912,
  },
  {
    name: "Iona",
    state_id: "ID",
    lat: 43.527,
    lng: -111.9309,
    id: 1840018645,
  },
  {
    name: "Crosslake",
    state_id: "MN",
    lat: 46.6755,
    lng: -94.0955,
    id: 1840031784,
  },
  {
    name: "Llano Grande",
    state_id: "TX",
    lat: 26.1309,
    lng: -97.9687,
    id: 1840037059,
  },
  {
    name: "Holiday Island",
    state_id: "AR",
    lat: 36.4762,
    lng: -93.7342,
    id: 1840027804,
  },
  {
    name: "Gold Bar",
    state_id: "WA",
    lat: 47.8563,
    lng: -121.692,
    id: 1840019786,
  },
  {
    name: "McCormick",
    state_id: "SC",
    lat: 33.9129,
    lng: -82.2876,
    id: 1840016751,
  },
  {
    name: "Winfield",
    state_id: "WV",
    lat: 38.5285,
    lng: -81.8861,
    id: 1840006184,
  },
  {
    name: "Byesville",
    state_id: "OH",
    lat: 39.9735,
    lng: -81.5459,
    id: 1840010495,
  },
  {
    name: "Winamac",
    state_id: "IN",
    lat: 41.0535,
    lng: -86.6037,
    id: 1840014463,
  },
  {
    name: "Emsworth",
    state_id: "PA",
    lat: 40.5112,
    lng: -80.0965,
    id: 1840001230,
  },
  {
    name: "Crompond",
    state_id: "NY",
    lat: 41.2908,
    lng: -73.8357,
    id: 1840004917,
  },
  {
    name: "New Wilmington",
    state_id: "PA",
    lat: 41.1174,
    lng: -80.3323,
    id: 1840000939,
  },
  {
    name: "Wray",
    state_id: "CO",
    lat: 40.0802,
    lng: -102.2284,
    id: 1840021412,
  },
  {
    name: "East Jordan",
    state_id: "MI",
    lat: 45.1547,
    lng: -85.13,
    id: 1840003945,
  },
  {
    name: "Lyman",
    state_id: "MS",
    lat: 30.5044,
    lng: -89.1348,
    id: 1840013946,
  },
  {
    name: "Colorado City",
    state_id: "CO",
    lat: 37.9365,
    lng: -104.8459,
    id: 1840017587,
  },
  {
    name: "Oxford",
    state_id: "GA",
    lat: 33.6277,
    lng: -83.8721,
    id: 1840014809,
  },
  {
    name: "Moline Acres",
    state_id: "MO",
    lat: 38.7456,
    lng: -90.2429,
    id: 1840008595,
  },
  {
    name: "Maxton",
    state_id: "NC",
    lat: 34.7364,
    lng: -79.3527,
    id: 1840016536,
  },
  {
    name: "Eureka",
    state_id: "KS",
    lat: 37.8261,
    lng: -96.2889,
    id: 1840001672,
  },
  {
    name: "Hartshorne",
    state_id: "OK",
    lat: 34.8389,
    lng: -95.5585,
    id: 1840020456,
  },
  {
    name: "Belgium",
    state_id: "WI",
    lat: 43.5019,
    lng: -87.8476,
    id: 1840002824,
  },
  {
    name: "Emporium",
    state_id: "PA",
    lat: 41.5102,
    lng: -78.2363,
    id: 1840000626,
  },
  {
    name: "Leith-Hatfield",
    state_id: "PA",
    lat: 39.8772,
    lng: -79.7314,
    id: 1840073703,
  },
  {
    name: "Ely",
    state_id: "IA",
    lat: 41.8753,
    lng: -91.585,
    id: 1840008097,
  },
  {
    name: "Hermann",
    state_id: "MO",
    lat: 38.6983,
    lng: -91.4342,
    id: 1840007473,
  },
  {
    name: "La Salle",
    state_id: "CO",
    lat: 40.3484,
    lng: -104.7063,
    id: 1840021372,
  },
  {
    name: "East Bernard",
    state_id: "TX",
    lat: 29.5241,
    lng: -96.0622,
    id: 1840019658,
  },
  {
    name: "Excelsior",
    state_id: "MN",
    lat: 44.9025,
    lng: -93.5663,
    id: 1840007823,
  },
  {
    name: "Altamont",
    state_id: "IL",
    lat: 39.057,
    lng: -88.7474,
    id: 1840007402,
  },
  {
    name: "Anahuac",
    state_id: "TX",
    lat: 29.7649,
    lng: -94.6787,
    id: 1840019633,
  },
  {
    name: "West Sharyland",
    state_id: "TX",
    lat: 26.2718,
    lng: -98.3404,
    id: 1840037087,
  },
  {
    name: "Neodesha",
    state_id: "KS",
    lat: 37.4242,
    lng: -95.6849,
    id: 1840003853,
  },
  {
    name: "Hardinsburg",
    state_id: "KY",
    lat: 37.7755,
    lng: -86.4537,
    id: 1840013217,
  },
  {
    name: "Belle Rose",
    state_id: "LA",
    lat: 30.0422,
    lng: -91.0498,
    id: 1840014008,
  },
  {
    name: "Darlington",
    state_id: "WI",
    lat: 42.6765,
    lng: -90.1204,
    id: 1840003098,
  },
  {
    name: "Brewster",
    state_id: "NY",
    lat: 41.3969,
    lng: -73.6151,
    id: 1840004893,
  },
  {
    name: "Mosheim",
    state_id: "TN",
    lat: 36.1955,
    lng: -82.9655,
    id: 1840016098,
  },
  {
    name: "Eastpoint",
    state_id: "FL",
    lat: 29.7547,
    lng: -84.8694,
    id: 1840013112,
  },
  {
    name: "Princeton Junction",
    state_id: "NJ",
    lat: 40.3207,
    lng: -74.6236,
    id: 1840005472,
  },
  {
    name: "Wofford Heights",
    state_id: "CA",
    lat: 35.7127,
    lng: -118.4732,
    id: 1840019146,
  },
  {
    name: "Olivia",
    state_id: "MN",
    lat: 44.777,
    lng: -94.9971,
    id: 1840008952,
  },
  {
    name: "Mulino",
    state_id: "OR",
    lat: 45.2235,
    lng: -122.5616,
    id: 1840023909,
  },
  {
    name: "Olga",
    state_id: "FL",
    lat: 26.7113,
    lng: -81.695,
    id: 1840028841,
  },
  {
    name: "St. Paul",
    state_id: "NE",
    lat: 41.214,
    lng: -98.46,
    id: 1840009313,
  },
  {
    name: "Shenandoah",
    state_id: "VA",
    lat: 38.4875,
    lng: -78.6171,
    id: 1840006142,
  },
  {
    name: "Rogue River",
    state_id: "OR",
    lat: 42.4354,
    lng: -123.1683,
    id: 1840020075,
  },
  {
    name: "Wheeler AFB",
    state_id: "HI",
    lat: 21.4735,
    lng: -158.0346,
    id: 1840075054,
  },
  {
    name: "Lithonia",
    state_id: "GA",
    lat: 33.7128,
    lng: -84.106,
    id: 1840015644,
  },
  {
    name: "Sun Valley",
    state_id: "PA",
    lat: 40.98,
    lng: -75.4688,
    id: 1840026364,
  },
  {
    name: "Laurel Park",
    state_id: "NC",
    lat: 35.3118,
    lng: -82.5039,
    id: 1840016369,
  },
  {
    name: "Parkside",
    state_id: "PA",
    lat: 39.8674,
    lng: -75.378,
    id: 1840000706,
  },
  {
    name: "Cascade",
    state_id: "IA",
    lat: 42.3018,
    lng: -91.0047,
    id: 1840006926,
  },
  {
    name: "Edgemont Park",
    state_id: "MI",
    lat: 42.7468,
    lng: -84.5926,
    id: 1840031756,
  },
  {
    name: "Kensington",
    state_id: "MD",
    lat: 39.0265,
    lng: -77.0738,
    id: 1840005856,
  },
  {
    name: "McQueeney",
    state_id: "TX",
    lat: 29.6,
    lng: -98.0439,
    id: 1840019637,
  },
  {
    name: "Dousman",
    state_id: "WI",
    lat: 43.0088,
    lng: -88.476,
    id: 1840003018,
  },
  {
    name: "Abram",
    state_id: "TX",
    lat: 26.2182,
    lng: -98.4181,
    id: 1840025179,
  },
  {
    name: "Green Level",
    state_id: "NC",
    lat: 36.1233,
    lng: -79.3461,
    id: 1840016144,
  },
  {
    name: "Shenorock",
    state_id: "NY",
    lat: 41.3307,
    lng: -73.7409,
    id: 1840004926,
  },
  {
    name: "Bridgetown",
    state_id: "MS",
    lat: 34.8907,
    lng: -89.9069,
    id: 1840042496,
  },
  {
    name: "West Belmar",
    state_id: "NJ",
    lat: 40.1707,
    lng: -74.0376,
    id: 1840005453,
  },
  {
    name: "Winnsboro Mills",
    state_id: "SC",
    lat: 34.3561,
    lng: -81.0709,
    id: 1840013605,
  },
  {
    name: "Campbell",
    state_id: "FL",
    lat: 28.2603,
    lng: -81.4525,
    id: 1840014137,
  },
  {
    name: "Fairlawn",
    state_id: "VA",
    lat: 37.1476,
    lng: -80.5567,
    id: 1840001721,
  },
  {
    name: "Amboy",
    state_id: "IL",
    lat: 41.7288,
    lng: -89.3686,
    id: 1840007050,
  },
  {
    name: "Antlers",
    state_id: "OK",
    lat: 34.2325,
    lng: -95.6214,
    id: 1840019255,
  },
  {
    name: "Bethlehem",
    state_id: "WV",
    lat: 40.0452,
    lng: -80.6898,
    id: 1840005514,
  },
  {
    name: "Kershaw",
    state_id: "SC",
    lat: 34.5462,
    lng: -80.5872,
    id: 1840016498,
  },
  {
    name: "Weldon",
    state_id: "CA",
    lat: 35.6431,
    lng: -118.3103,
    id: 1840019145,
  },
  {
    name: "Lebanon South",
    state_id: "PA",
    lat: 40.328,
    lng: -76.4066,
    id: 1840073702,
  },
  {
    name: "South Paris",
    state_id: "ME",
    lat: 44.219,
    lng: -70.5113,
    id: 1840001583,
  },
  {
    name: "Kiln",
    state_id: "MS",
    lat: 30.4171,
    lng: -89.4322,
    id: 1840013949,
  },
  {
    name: "Bath",
    state_id: "MI",
    lat: 42.8202,
    lng: -84.4546,
    id: 1840024058,
  },
  {
    name: "Petersburg",
    state_id: "IN",
    lat: 38.4919,
    lng: -87.281,
    id: 1840009799,
  },
  {
    name: "Pine Air",
    state_id: "FL",
    lat: 26.6588,
    lng: -80.1073,
    id: 1840042877,
  },
  {
    name: "Shawnee Hills",
    state_id: "OH",
    lat: 39.6515,
    lng: -83.7822,
    id: 1840145757,
  },
  {
    name: "Richgrove",
    state_id: "CA",
    lat: 35.7966,
    lng: -119.1069,
    id: 1840019037,
  },
  {
    name: "Flandreau",
    state_id: "SD",
    lat: 44.0465,
    lng: -96.5984,
    id: 1840002534,
  },
  {
    name: "La Blanca",
    state_id: "TX",
    lat: 26.3048,
    lng: -98.0286,
    id: 1840036257,
  },
  {
    name: "Liberty City",
    state_id: "TX",
    lat: 32.4502,
    lng: -94.9438,
    id: 1840019456,
  },
  {
    name: "Marshfield Hills",
    state_id: "MA",
    lat: 42.1487,
    lng: -70.7315,
    id: 1840003208,
  },
  {
    name: "La Mesilla",
    state_id: "NM",
    lat: 35.9461,
    lng: -106.0719,
    id: 1840024835,
  },
  {
    name: "Riverview",
    state_id: "DE",
    lat: 39.0293,
    lng: -75.5202,
    id: 1840028613,
  },
  {
    name: "Portola",
    state_id: "CA",
    lat: 39.8241,
    lng: -120.4733,
    id: 1840020177,
  },
  {
    name: "Coal Creek",
    state_id: "CO",
    lat: 39.9071,
    lng: -105.3799,
    id: 1840152096,
  },
  {
    name: "Panhandle",
    state_id: "TX",
    lat: 35.346,
    lng: -101.3804,
    id: 1840022745,
  },
  {
    name: "Hazen",
    state_id: "ND",
    lat: 47.299,
    lng: -101.626,
    id: 1840000143,
  },
  {
    name: "Anahola",
    state_id: "HI",
    lat: 22.1455,
    lng: -159.3151,
    id: 1840023211,
  },
  {
    name: "Twain Harte",
    state_id: "CA",
    lat: 38.0384,
    lng: -120.234,
    id: 1840018882,
  },
  {
    name: "Wilberforce",
    state_id: "OH",
    lat: 39.7151,
    lng: -83.8848,
    id: 1840007311,
  },
  {
    name: "Ferrum",
    state_id: "VA",
    lat: 36.9313,
    lng: -80.0149,
    id: 1840006473,
  },
  {
    name: "Nedrow",
    state_id: "NY",
    lat: 42.9779,
    lng: -76.1417,
    id: 1840004314,
  },
  {
    name: "Mascot",
    state_id: "TN",
    lat: 36.0673,
    lng: -83.765,
    id: 1840013374,
  },
  {
    name: "Harrisburg",
    state_id: "AR",
    lat: 35.5637,
    lng: -90.7216,
    id: 1840013426,
  },
  {
    name: "Churchill",
    state_id: "OH",
    lat: 41.1692,
    lng: -80.666,
    id: 1840004904,
  },
  {
    name: "Star Valley",
    state_id: "AZ",
    lat: 34.2611,
    lng: -111.2197,
    id: 1840022858,
  },
  {
    name: "Idalou",
    state_id: "TX",
    lat: 33.6624,
    lng: -101.6838,
    id: 1840019337,
  },
  {
    name: "Navassa",
    state_id: "NC",
    lat: 34.2831,
    lng: -78.029,
    id: 1840016687,
  },
  {
    name: "Junction City",
    state_id: "KY",
    lat: 37.5854,
    lng: -84.7903,
    id: 1840014356,
  },
  {
    name: "West Union",
    state_id: "IA",
    lat: 42.9588,
    lng: -91.8132,
    id: 1840000390,
  },
  {
    name: "Mason",
    state_id: "TX",
    lat: 30.748,
    lng: -99.2288,
    id: 1840020872,
  },
  {
    name: "Hollandale",
    state_id: "MS",
    lat: 33.1761,
    lng: -90.8529,
    id: 1840014830,
  },
  {
    name: "Whetstone",
    state_id: "AZ",
    lat: 31.7011,
    lng: -110.3398,
    id: 1840027991,
  },
  {
    name: "Burley",
    state_id: "WA",
    lat: 47.4166,
    lng: -122.6377,
    id: 1840023793,
  },
  {
    name: "Red Lodge",
    state_id: "MT",
    lat: 45.1933,
    lng: -109.2501,
    id: 1840019946,
  },
  {
    name: "Bronson",
    state_id: "MI",
    lat: 41.8727,
    lng: -85.1914,
    id: 1840003249,
  },
  {
    name: "Fowler",
    state_id: "IN",
    lat: 40.617,
    lng: -87.3184,
    id: 1840010379,
  },
  {
    name: "Tool",
    state_id: "TX",
    lat: 32.2803,
    lng: -96.1725,
    id: 1840022111,
  },
  {
    name: "Canton",
    state_id: "MO",
    lat: 40.1295,
    lng: -91.5266,
    id: 1840007258,
  },
  {
    name: "Honaunau-Napoopoo",
    state_id: "HI",
    lat: 19.4538,
    lng: -155.8588,
    id: 1840075010,
  },
  {
    name: "Milton",
    state_id: "LA",
    lat: 30.1122,
    lng: -92.071,
    id: 1840025123,
  },
  {
    name: "Pequot Lakes",
    state_id: "MN",
    lat: 46.5875,
    lng: -94.2983,
    id: 1840008845,
  },
  {
    name: "Hull",
    state_id: "IA",
    lat: 43.1894,
    lng: -96.1342,
    id: 1840006873,
  },
  {
    name: "Anson",
    state_id: "TX",
    lat: 32.755,
    lng: -99.8962,
    id: 1840019446,
  },
  {
    name: "Bridgeport",
    state_id: "AL",
    lat: 34.9495,
    lng: -85.7243,
    id: 1840001007,
  },
  {
    name: "Wrightsville",
    state_id: "PA",
    lat: 40.0239,
    lng: -76.5311,
    id: 1840001470,
  },
  {
    name: "Old Saybrook Center",
    state_id: "CT",
    lat: 41.2918,
    lng: -72.3682,
    id: 1840073303,
  },
  {
    name: "Gibsonia",
    state_id: "PA",
    lat: 40.6322,
    lng: -79.9684,
    id: 1840026467,
  },
  {
    name: "Romoland",
    state_id: "CA",
    lat: 33.7648,
    lng: -117.1572,
    id: 1840019289,
  },
  {
    name: "Terrace Park",
    state_id: "OH",
    lat: 39.1585,
    lng: -84.3112,
    id: 1840001616,
  },
  {
    name: "Salmon Brook",
    state_id: "CT",
    lat: 41.9567,
    lng: -72.7931,
    id: 1840028599,
  },
  {
    name: "Orchard Hills",
    state_id: "PA",
    lat: 40.5849,
    lng: -79.5416,
    id: 1840005183,
  },
  {
    name: "Woodland Beach",
    state_id: "MI",
    lat: 41.9418,
    lng: -83.3141,
    id: 1840007676,
  },
  {
    name: "Redington Shores",
    state_id: "FL",
    lat: 27.8293,
    lng: -82.8278,
    id: 1840018295,
  },
  {
    name: "Pine Ridge at Crestwood",
    state_id: "NJ",
    lat: 39.9585,
    lng: -74.3169,
    id: 1840033484,
  },
  {
    name: "Santo Domingo Pueblo",
    state_id: "NM",
    lat: 35.5174,
    lng: -106.367,
    id: 1840019069,
  },
  {
    name: "Lincoln Park",
    state_id: "NY",
    lat: 41.9559,
    lng: -74.0016,
    id: 1840004720,
  },
  {
    name: "Arnold",
    state_id: "CA",
    lat: 38.2423,
    lng: -120.3547,
    id: 1840017590,
  },
  {
    name: "Johnsonburg",
    state_id: "PA",
    lat: 41.4913,
    lng: -78.6791,
    id: 1840000616,
  },
  {
    name: "Weedpatch",
    state_id: "CA",
    lat: 35.2369,
    lng: -118.9123,
    id: 1840019144,
  },
  {
    name: "Clinton",
    state_id: "MI",
    lat: 42.0699,
    lng: -83.9687,
    id: 1840011372,
  },
  {
    name: "Parsons",
    state_id: "TN",
    lat: 35.6512,
    lng: -88.1231,
    id: 1840014520,
  },
  {
    name: "Gumlog",
    state_id: "GA",
    lat: 34.4952,
    lng: -83.0975,
    id: 1840029455,
  },
  {
    name: "Rainier",
    state_id: "WA",
    lat: 46.8917,
    lng: -122.6867,
    id: 1840021147,
  },
  {
    name: "Texanna",
    state_id: "OK",
    lat: 35.3572,
    lng: -95.5047,
    id: 1840019158,
  },
  {
    name: "Croswell",
    state_id: "MI",
    lat: 43.2736,
    lng: -82.6177,
    id: 1840002700,
  },
  {
    name: "Wellston",
    state_id: "MO",
    lat: 38.675,
    lng: -90.2941,
    id: 1840010745,
  },
  {
    name: "Poth",
    state_id: "TX",
    lat: 29.0726,
    lng: -98.0809,
    id: 1840022241,
  },
  {
    name: "Oceana",
    state_id: "WV",
    lat: 37.6927,
    lng: -81.6324,
    id: 1840006382,
  },
  {
    name: "Slippery Rock University",
    state_id: "PA",
    lat: 41.0647,
    lng: -80.0419,
    id: 1840043348,
  },
  {
    name: "Greenport West",
    state_id: "NY",
    lat: 41.1022,
    lng: -72.3759,
    id: 1840073684,
  },
  {
    name: "Sag Harbor",
    state_id: "NY",
    lat: 40.997,
    lng: -72.2892,
    id: 1840005133,
  },
  {
    name: "Ludowici",
    state_id: "GA",
    lat: 31.7096,
    lng: -81.7457,
    id: 1840015848,
  },
  {
    name: "Carnation",
    state_id: "WA",
    lat: 47.6442,
    lng: -121.9041,
    id: 1840018421,
  },
  {
    name: "Vernonia",
    state_id: "OR",
    lat: 45.8611,
    lng: -123.1834,
    id: 1840021187,
  },
  {
    name: "Boquerón",
    state_id: "PR",
    lat: 18.034,
    lng: -67.1715,
    id: 1630035624,
  },
  {
    name: "Brice Prairie",
    state_id: "WI",
    lat: 43.9375,
    lng: -91.3074,
    id: 1840004158,
  },
  {
    name: "Ina",
    state_id: "IL",
    lat: 38.1499,
    lng: -88.904,
    id: 1840012912,
  },
  {
    name: "Hughesville",
    state_id: "MD",
    lat: 38.5376,
    lng: -76.7748,
    id: 1840006174,
  },
  {
    name: "Brogden",
    state_id: "NC",
    lat: 35.2961,
    lng: -78.025,
    id: 1840013431,
  },
  {
    name: "Trowbridge Park",
    state_id: "MI",
    lat: 46.5565,
    lng: -87.4417,
    id: 1840006571,
  },
  {
    name: "East Sonora",
    state_id: "CA",
    lat: 37.9777,
    lng: -120.341,
    id: 1840017599,
  },
  {
    name: "North Spearfish",
    state_id: "SD",
    lat: 44.5123,
    lng: -103.8955,
    id: 1840073768,
  },
  {
    name: "Denmark",
    state_id: "WI",
    lat: 44.3493,
    lng: -87.8313,
    id: 1840002347,
  },
  {
    name: "Carlin",
    state_id: "NV",
    lat: 40.719,
    lng: -116.108,
    id: 1840018706,
  },
  {
    name: "Alamo",
    state_id: "TN",
    lat: 35.783,
    lng: -89.1168,
    id: 1840015368,
  },
  {
    name: "Cass City",
    state_id: "MI",
    lat: 43.6007,
    lng: -83.1767,
    id: 1840010049,
  },
  {
    name: "Tazewell",
    state_id: "TN",
    lat: 36.4625,
    lng: -83.567,
    id: 1840016021,
  },
  {
    name: "Guin",
    state_id: "AL",
    lat: 33.9832,
    lng: -87.9023,
    id: 1840013640,
  },
  {
    name: "Pablo",
    state_id: "MT",
    lat: 47.6035,
    lng: -114.1059,
    id: 1840018394,
  },
  {
    name: "Egypt",
    state_id: "PA",
    lat: 40.6858,
    lng: -75.5334,
    id: 1840026406,
  },
  {
    name: "Redfield",
    state_id: "SD",
    lat: 44.8725,
    lng: -98.5186,
    id: 1840002120,
  },
  {
    name: "Sleepy Hollow",
    state_id: "CA",
    lat: 38.012,
    lng: -122.5877,
    id: 1840140381,
  },
  {
    name: "Oakhurst",
    state_id: "OK",
    lat: 36.0828,
    lng: -96.0641,
    id: 1840019074,
  },
  {
    name: "Geistown",
    state_id: "PA",
    lat: 40.2935,
    lng: -78.8727,
    id: 1840001114,
  },
  {
    name: "Belcourt",
    state_id: "ND",
    lat: 48.8417,
    lng: -99.7447,
    id: 1840003990,
  },
  {
    name: "Calabash",
    state_id: "NC",
    lat: 33.8945,
    lng: -78.5571,
    id: 1840015580,
  },
  {
    name: "Walton Hills",
    state_id: "OH",
    lat: 41.3662,
    lng: -81.5554,
    id: 1840003418,
  },
  {
    name: "Herington",
    state_id: "KS",
    lat: 38.6863,
    lng: -96.8667,
    id: 1840001630,
  },
  {
    name: "Greensboro",
    state_id: "AL",
    lat: 32.7014,
    lng: -87.595,
    id: 1840006442,
  },
  {
    name: "Brinckerhoff",
    state_id: "NY",
    lat: 41.5511,
    lng: -73.8696,
    id: 1840004740,
  },
  {
    name: "Quail Ridge",
    state_id: "FL",
    lat: 28.3451,
    lng: -82.5535,
    id: 1840039089,
  },
  {
    name: "Skyline Acres",
    state_id: "OH",
    lat: 39.2276,
    lng: -84.5665,
    id: 1840034134,
  },
  {
    name: "Mayer",
    state_id: "MN",
    lat: 44.8868,
    lng: -93.8904,
    id: 1840007847,
  },
  {
    name: "Aguas Claras",
    state_id: "PR",
    lat: 18.2447,
    lng: -65.6662,
    id: 1630023722,
  },
  {
    name: "Clearlake Oaks",
    state_id: "CA",
    lat: 39.0218,
    lng: -122.6593,
    id: 1840017545,
  },
  {
    name: "Center",
    state_id: "CO",
    lat: 37.7512,
    lng: -106.1105,
    id: 1840022517,
  },
  {
    name: "Fairdale",
    state_id: "PA",
    lat: 39.8901,
    lng: -79.968,
    id: 1840000716,
  },
  {
    name: "Rangely",
    state_id: "CO",
    lat: 40.0861,
    lng: -108.7794,
    id: 1840021417,
  },
  {
    name: "Fort Polk North",
    state_id: "LA",
    lat: 31.1031,
    lng: -93.1793,
    id: 1840073836,
  },
  {
    name: "Belmond",
    state_id: "IA",
    lat: 42.8466,
    lng: -93.6084,
    id: 1840006905,
  },
  {
    name: "Wilson-Conococheague",
    state_id: "MD",
    lat: 39.653,
    lng: -77.8306,
    id: 1840073739,
  },
  {
    name: "Durant",
    state_id: "MS",
    lat: 33.0799,
    lng: -89.8566,
    id: 1840013775,
  },
  {
    name: "Utica",
    state_id: "OH",
    lat: 40.2335,
    lng: -82.441,
    id: 1840012332,
  },
  {
    name: "Tunica Resorts",
    state_id: "MS",
    lat: 34.8298,
    lng: -90.3218,
    id: 1840032567,
  },
  {
    name: "Poplar-Cotton Center",
    state_id: "CA",
    lat: 36.0564,
    lng: -119.1493,
    id: 1840074566,
  },
  {
    name: "Waterloo",
    state_id: "IN",
    lat: 41.4328,
    lng: -85.0347,
    id: 1840010250,
  },
  {
    name: "West Wareham",
    state_id: "MA",
    lat: 41.79,
    lng: -70.755,
    id: 1840003202,
  },
  {
    name: "Mitchellville",
    state_id: "IA",
    lat: 41.668,
    lng: -93.3629,
    id: 1840008169,
  },
  {
    name: "Zion",
    state_id: "PA",
    lat: 40.9167,
    lng: -77.6908,
    id: 1840005170,
  },
  {
    name: "Escatawpa",
    state_id: "MS",
    lat: 30.4912,
    lng: -88.5495,
    id: 1840013105,
  },
  {
    name: "Spencer",
    state_id: "IN",
    lat: 39.2862,
    lng: -86.7726,
    id: 1840010655,
  },
  {
    name: "Splendora",
    state_id: "TX",
    lat: 30.2324,
    lng: -95.1616,
    id: 1840022174,
  },
  {
    name: "Ogdensburg",
    state_id: "NJ",
    lat: 41.0769,
    lng: -74.5973,
    id: 1840000787,
  },
  {
    name: "Oak Hills",
    state_id: "PA",
    lat: 40.8263,
    lng: -79.9152,
    id: 1840035432,
  },
  {
    name: "Westby",
    state_id: "WI",
    lat: 43.653,
    lng: -90.8584,
    id: 1840002689,
  },
  {
    name: "Alpha",
    state_id: "NJ",
    lat: 40.6598,
    lng: -75.1571,
    id: 1840003572,
  },
  {
    name: "Four Oaks",
    state_id: "NC",
    lat: 35.4454,
    lng: -78.4164,
    id: 1840016291,
  },
  {
    name: "Westmorland",
    state_id: "CA",
    lat: 33.0389,
    lng: -115.6223,
    id: 1840022001,
  },
  {
    name: "Oyster Bay Cove",
    state_id: "NY",
    lat: 40.857,
    lng: -73.5037,
    id: 1840005317,
  },
  {
    name: "Madelia",
    state_id: "MN",
    lat: 44.0482,
    lng: -94.4198,
    id: 1840008992,
  },
  {
    name: "Aliceville",
    state_id: "AL",
    lat: 33.1237,
    lng: -88.1594,
    id: 1840013758,
  },
  {
    name: "Honalo",
    state_id: "HI",
    lat: 19.5787,
    lng: -155.902,
    id: 1840023195,
  },
  {
    name: "New Market",
    state_id: "VA",
    lat: 38.6459,
    lng: -78.6709,
    id: 1840006003,
  },
  {
    name: "Fredonia",
    state_id: "WI",
    lat: 43.4715,
    lng: -87.9489,
    id: 1840002822,
  },
  {
    name: "Grove City",
    state_id: "FL",
    lat: 26.9071,
    lng: -82.3258,
    id: 1840014196,
  },
  {
    name: "Watsontown",
    state_id: "PA",
    lat: 41.085,
    lng: -76.8646,
    id: 1840000853,
  },
  {
    name: "Janesville",
    state_id: "MN",
    lat: 44.1214,
    lng: -93.7107,
    id: 1840007915,
  },
  {
    name: "Tompkinsville",
    state_id: "KY",
    lat: 36.7012,
    lng: -85.6927,
    id: 1840015272,
  },
  {
    name: "Meeker",
    state_id: "CO",
    lat: 40.05,
    lng: -107.8949,
    id: 1840022427,
  },
  {
    name: "Seneca",
    state_id: "IL",
    lat: 41.3207,
    lng: -88.5889,
    id: 1840011539,
  },
  {
    name: "Aptos Hills-Larkin Valley",
    state_id: "CA",
    lat: 36.9606,
    lng: -121.8341,
    id: 1840036659,
  },
  {
    name: "Hunter",
    state_id: "OH",
    lat: 39.4947,
    lng: -84.2903,
    id: 1840034373,
  },
  {
    name: "Willamina",
    state_id: "OR",
    lat: 45.0786,
    lng: -123.4847,
    id: 1840021221,
  },
  {
    name: "Lamoni",
    state_id: "IA",
    lat: 40.6202,
    lng: -93.9307,
    id: 1840001004,
  },
  {
    name: "Lakeside",
    state_id: "MT",
    lat: 48.0182,
    lng: -114.2274,
    id: 1840017300,
  },
  {
    name: "Los Prados",
    state_id: "PR",
    lat: 18.233,
    lng: -66.0619,
    id: 1630025219,
  },
  {
    name: "Lineville",
    state_id: "AL",
    lat: 33.313,
    lng: -85.7508,
    id: 1840008076,
  },
  {
    name: "Paia",
    state_id: "HI",
    lat: 20.9062,
    lng: -156.3678,
    id: 1840029539,
  },
  {
    name: "Keokea",
    state_id: "HI",
    lat: 20.7211,
    lng: -156.3624,
    id: 1840029506,
  },
  {
    name: "Falconer",
    state_id: "NY",
    lat: 42.119,
    lng: -79.1973,
    id: 1840004564,
  },
  {
    name: "Ferdinand",
    state_id: "IN",
    lat: 38.2269,
    lng: -86.8631,
    id: 1840010801,
  },
  {
    name: "Lisbon",
    state_id: "IA",
    lat: 41.9205,
    lng: -91.3917,
    id: 1840009144,
  },
  {
    name: "Spring Mount",
    state_id: "PA",
    lat: 40.2755,
    lng: -75.4651,
    id: 1840005468,
  },
  {
    name: "Colstrip",
    state_id: "MT",
    lat: 45.8943,
    lng: -106.628,
    id: 1840018466,
  },
  {
    name: "Shingletown",
    state_id: "CA",
    lat: 40.5035,
    lng: -121.86,
    id: 1840018728,
  },
  {
    name: "Fairmount",
    state_id: "TN",
    lat: 35.1844,
    lng: -85.3302,
    id: 1840013075,
  },
  {
    name: "Los Llanos",
    state_id: "PR",
    lat: 18.0579,
    lng: -66.4092,
    id: 1630023663,
  },
  {
    name: "Laughlin AFB",
    state_id: "TX",
    lat: 29.3568,
    lng: -100.7829,
    id: 1840037090,
  },
  {
    name: "Leadville North",
    state_id: "CO",
    lat: 39.26,
    lng: -106.3112,
    id: 1840074311,
  },
  {
    name: "Franklinton",
    state_id: "NC",
    lat: 36.1011,
    lng: -78.4523,
    id: 1840016118,
  },
  {
    name: "Vista Center",
    state_id: "NJ",
    lat: 40.1584,
    lng: -74.3227,
    id: 1840033475,
  },
  {
    name: "Phoenix",
    state_id: "NY",
    lat: 43.2316,
    lng: -76.2961,
    id: 1840004193,
  },
  {
    name: "Lake Hamilton",
    state_id: "AR",
    lat: 34.4269,
    lng: -93.0887,
    id: 1840013588,
  },
  {
    name: "La Porte City",
    state_id: "IA",
    lat: 42.3138,
    lng: -92.1914,
    id: 1840008064,
  },
  {
    name: "Newtown",
    state_id: "PA",
    lat: 40.229,
    lng: -74.9324,
    id: 1840001329,
  },
  {
    name: "Winsted",
    state_id: "MN",
    lat: 44.9574,
    lng: -94.0498,
    id: 1840010006,
  },
  {
    name: "Grand Canyon Village",
    state_id: "AZ",
    lat: 36.0492,
    lng: -112.1566,
    id: 1840074025,
  },
  {
    name: "York",
    state_id: "AL",
    lat: 32.4998,
    lng: -88.2938,
    id: 1840015778,
  },
  {
    name: "Mulberry",
    state_id: "NC",
    lat: 36.2257,
    lng: -81.1653,
    id: 1840013340,
  },
  {
    name: "East Berwick",
    state_id: "PA",
    lat: 41.0657,
    lng: -76.2208,
    id: 1840034938,
  },
  {
    name: "Garfield",
    state_id: "TX",
    lat: 30.1961,
    lng: -97.5514,
    id: 1840018233,
  },
  {
    name: "Plattsburg",
    state_id: "MO",
    lat: 39.5644,
    lng: -94.4615,
    id: 1840009557,
  },
  {
    name: "Chesaning",
    state_id: "MI",
    lat: 43.1851,
    lng: -84.12,
    id: 1840010053,
  },
  {
    name: "Royal City",
    state_id: "WA",
    lat: 46.9019,
    lng: -119.6208,
    id: 1840019816,
  },
  {
    name: "Westbrook Center",
    state_id: "CT",
    lat: 41.2811,
    lng: -72.4424,
    id: 1840073313,
  },
  {
    name: "La Fermina",
    state_id: "PR",
    lat: 18.1744,
    lng: -65.8527,
    id: 1630023729,
  },
  {
    name: "Holden",
    state_id: "MO",
    lat: 38.7136,
    lng: -93.9895,
    id: 1840007434,
  },
  {
    name: "West Dennis",
    state_id: "MA",
    lat: 41.6666,
    lng: -70.1656,
    id: 1840003244,
  },
  {
    name: "Jefferson",
    state_id: "MD",
    lat: 39.3661,
    lng: -77.5409,
    id: 1840024451,
  },
  {
    name: "Sugarcreek",
    state_id: "OH",
    lat: 40.5076,
    lng: -81.6409,
    id: 1840012098,
  },
  {
    name: "Oakwood",
    state_id: "PA",
    lat: 41.0112,
    lng: -80.3793,
    id: 1840005190,
  },
  {
    name: "Buchanan",
    state_id: "NY",
    lat: 41.2643,
    lng: -73.9465,
    id: 1840004950,
  },
  {
    name: "Jackson",
    state_id: "KY",
    lat: 37.5589,
    lng: -83.3782,
    id: 1840014357,
  },
  {
    name: "Park Falls",
    state_id: "WI",
    lat: 45.9348,
    lng: -90.4463,
    id: 1840002010,
  },
  {
    name: "North Prairie",
    state_id: "WI",
    lat: 42.9357,
    lng: -88.4038,
    id: 1840003026,
  },
  {
    name: "Hope",
    state_id: "IN",
    lat: 39.2993,
    lng: -85.766,
    id: 1840010669,
  },
  {
    name: "Drum Point",
    state_id: "MD",
    lat: 38.3325,
    lng: -76.4295,
    id: 1840026724,
  },
  {
    name: "Sky Valley",
    state_id: "CA",
    lat: 33.8912,
    lng: -116.3551,
    id: 1840025034,
  },
  {
    name: "Uniontown",
    state_id: "AL",
    lat: 32.449,
    lng: -87.4958,
    id: 1840016959,
  },
  {
    name: "Weimar",
    state_id: "TX",
    lat: 29.7,
    lng: -96.7773,
    id: 1840022211,
  },
  {
    name: "Guyton",
    state_id: "GA",
    lat: 32.3408,
    lng: -81.4226,
    id: 1840013823,
  },
  {
    name: "Sewall's Point",
    state_id: "FL",
    lat: 27.1971,
    lng: -80.1982,
    id: 1840018304,
  },
  {
    name: "Centerburg",
    state_id: "OH",
    lat: 40.3039,
    lng: -82.6979,
    id: 1840010420,
  },
  {
    name: "Columbia",
    state_id: "CA",
    lat: 38.0333,
    lng: -120.4097,
    id: 1840017597,
  },
  {
    name: "Elwood",
    state_id: "IL",
    lat: 41.4118,
    lng: -88.1347,
    id: 1840011485,
  },
  {
    name: "Fredonia",
    state_id: "KS",
    lat: 37.5328,
    lng: -95.8223,
    id: 1840001693,
  },
  {
    name: "Pulaski",
    state_id: "NY",
    lat: 43.5653,
    lng: -76.1267,
    id: 1840004194,
  },
  {
    name: "Southworth",
    state_id: "WA",
    lat: 47.5126,
    lng: -122.5298,
    id: 1840023800,
  },
  {
    name: "Brownsville",
    state_id: "PA",
    lat: 40.0188,
    lng: -79.891,
    id: 1840001500,
  },
  {
    name: "Farmington",
    state_id: "IL",
    lat: 40.697,
    lng: -90.0024,
    id: 1840008330,
  },
  {
    name: "Hennessey",
    state_id: "OK",
    lat: 36.1056,
    lng: -97.8989,
    id: 1840021680,
  },
  {
    name: "Mount Pleasant",
    state_id: "NC",
    lat: 35.3771,
    lng: -80.4497,
    id: 1840016363,
  },
  {
    name: "Dolan Springs",
    state_id: "AZ",
    lat: 35.5905,
    lng: -114.2852,
    id: 1840019744,
  },
  {
    name: "Boron",
    state_id: "CA",
    lat: 35.0164,
    lng: -117.6659,
    id: 1840017811,
  },
  {
    name: "Union",
    state_id: "OR",
    lat: 45.2089,
    lng: -117.8679,
    id: 1840021200,
  },
  {
    name: "North Eagle Butte",
    state_id: "SD",
    lat: 45.0169,
    lng: -101.2365,
    id: 1840004020,
  },
  {
    name: "Grenelefe",
    state_id: "FL",
    lat: 28.0579,
    lng: -81.5538,
    id: 1840038904,
  },
  {
    name: "Baldwin",
    state_id: "LA",
    lat: 29.8388,
    lng: -91.5522,
    id: 1840015941,
  },
  {
    name: "Cleona",
    state_id: "PA",
    lat: 40.3385,
    lng: -76.477,
    id: 1840001344,
  },
  {
    name: "Henderson",
    state_id: "GA",
    lat: 32.0103,
    lng: -81.2656,
    id: 1840137955,
  },
  {
    name: "Lumberton",
    state_id: "MS",
    lat: 31.0064,
    lng: -89.4592,
    id: 1840015873,
  },
  {
    name: "Gibbsboro",
    state_id: "NJ",
    lat: 39.8332,
    lng: -74.9657,
    id: 1840003776,
  },
  {
    name: "Woodsfield",
    state_id: "OH",
    lat: 39.7631,
    lng: -81.1169,
    id: 1840012523,
  },
  {
    name: "Central",
    state_id: "TN",
    lat: 36.3289,
    lng: -82.294,
    id: 1840013312,
  },
  {
    name: "Gates Mills",
    state_id: "OH",
    lat: 41.5316,
    lng: -81.41,
    id: 1840000603,
  },
  {
    name: "Cape Neddick",
    state_id: "ME",
    lat: 43.1706,
    lng: -70.622,
    id: 1840002665,
  },
  {
    name: "Fort Pierre",
    state_id: "SD",
    lat: 44.368,
    lng: -100.3831,
    id: 1840002303,
  },
  {
    name: "San Felipe Pueblo",
    state_id: "NM",
    lat: 35.4338,
    lng: -106.425,
    id: 1840033699,
  },
  {
    name: "Soulsbyville",
    state_id: "CA",
    lat: 37.9917,
    lng: -120.2617,
    id: 1840018878,
  },
  {
    name: "Jonesville",
    state_id: "NC",
    lat: 36.2336,
    lng: -80.8351,
    id: 1840016110,
  },
  {
    name: "Jonesville",
    state_id: "MI",
    lat: 41.9785,
    lng: -84.6656,
    id: 1840011378,
  },
  {
    name: "Amelia",
    state_id: "LA",
    lat: 29.6645,
    lng: -91.1072,
    id: 1840014016,
  },
  {
    name: "Chesterland",
    state_id: "OH",
    lat: 41.5213,
    lng: -81.3363,
    id: 1840004823,
  },
  {
    name: "Oak Hill",
    state_id: "FL",
    lat: 28.8784,
    lng: -80.8351,
    id: 1840015074,
  },
  {
    name: "South Fulton",
    state_id: "TN",
    lat: 36.4931,
    lng: -88.8835,
    id: 1840015279,
  },
  {
    name: "Eatonville",
    state_id: "FL",
    lat: 28.6166,
    lng: -81.3907,
    id: 1840017237,
  },
  {
    name: "Green Meadows",
    state_id: "OH",
    lat: 39.8672,
    lng: -83.9454,
    id: 1840034385,
  },
  {
    name: "Vincent",
    state_id: "AL",
    lat: 33.3848,
    lng: -86.4086,
    id: 1840016848,
  },
  {
    name: "Magnolia",
    state_id: "MS",
    lat: 31.1619,
    lng: -90.467,
    id: 1840015876,
  },
  {
    name: "Bridgman",
    state_id: "MI",
    lat: 41.9396,
    lng: -86.5652,
    id: 1840003216,
  },
  {
    name: "New Llano",
    state_id: "LA",
    lat: 31.097,
    lng: -93.2767,
    id: 1840017111,
  },
  {
    name: "Wenonah",
    state_id: "NJ",
    lat: 39.7913,
    lng: -75.1486,
    id: 1840001525,
  },
  {
    name: "Hudson",
    state_id: "MI",
    lat: 41.8569,
    lng: -84.3451,
    id: 1840003229,
  },
  {
    name: "East Quincy",
    state_id: "CA",
    lat: 39.9165,
    lng: -120.9189,
    id: 1840017517,
  },
  {
    name: "Henry",
    state_id: "IL",
    lat: 41.1172,
    lng: -89.3569,
    id: 1840007162,
  },
  {
    name: "Evarts",
    state_id: "KY",
    lat: 36.8644,
    lng: -83.1926,
    id: 1840013279,
  },
  {
    name: "Sterling",
    state_id: "KS",
    lat: 38.2094,
    lng: -98.2063,
    id: 1840001663,
  },
  {
    name: "White River Junction",
    state_id: "VT",
    lat: 43.6496,
    lng: -72.3239,
    id: 1840004162,
  },
  {
    name: "Holyoke",
    state_id: "CO",
    lat: 40.582,
    lng: -102.2975,
    id: 1840020164,
  },
  {
    name: "West Terre Haute",
    state_id: "IN",
    lat: 39.4638,
    lng: -87.4506,
    id: 1840010622,
  },
  {
    name: "Hudson Bend",
    state_id: "TX",
    lat: 30.4139,
    lng: -97.928,
    id: 1840018234,
  },
  {
    name: "Gaylord",
    state_id: "MN",
    lat: 44.5555,
    lng: -94.2123,
    id: 1840007865,
  },
  {
    name: "South Pottstown",
    state_id: "PA",
    lat: 40.2367,
    lng: -75.6599,
    id: 1840005503,
  },
  {
    name: "Elmwood Place",
    state_id: "OH",
    lat: 39.1855,
    lng: -84.489,
    id: 1840003816,
  },
  {
    name: "Crimora",
    state_id: "VA",
    lat: 38.1618,
    lng: -78.8413,
    id: 1840006241,
  },
  {
    name: "Olimpo",
    state_id: "PR",
    lat: 18.0026,
    lng: -66.1094,
    id: 1630023657,
  },
  {
    name: "Dry Ridge",
    state_id: "KY",
    lat: 38.6837,
    lng: -84.6006,
    id: 1840013179,
  },
  {
    name: "Liverpool",
    state_id: "NY",
    lat: 43.1061,
    lng: -76.2093,
    id: 1840004322,
  },
  {
    name: "Fox Lake Hills",
    state_id: "IL",
    lat: 42.4128,
    lng: -88.1271,
    id: 1840004637,
  },
  {
    name: "Lake Village",
    state_id: "AR",
    lat: 33.3299,
    lng: -91.2847,
    id: 1840014821,
  },
  {
    name: "North Plains",
    state_id: "OR",
    lat: 45.5974,
    lng: -122.9946,
    id: 1840019933,
  },
  {
    name: "Wynnewood",
    state_id: "OK",
    lat: 34.6442,
    lng: -97.1627,
    id: 1840021855,
  },
  {
    name: "St. George",
    state_id: "SC",
    lat: 33.186,
    lng: -80.5794,
    id: 1840018042,
  },
  {
    name: "Shinnecock Hills",
    state_id: "NY",
    lat: 40.8878,
    lng: -72.4554,
    id: 1840005096,
  },
  {
    name: "Windham",
    state_id: "OH",
    lat: 41.2375,
    lng: -81.0373,
    id: 1840011676,
  },
  {
    name: "Cimarron",
    state_id: "KS",
    lat: 37.8097,
    lng: -100.3454,
    id: 1840007558,
  },
  {
    name: "Long Valley",
    state_id: "NJ",
    lat: 40.7826,
    lng: -74.7777,
    id: 1840005197,
  },
  {
    name: "Cecil-Bishop",
    state_id: "PA",
    lat: 40.3184,
    lng: -80.1933,
    id: 1840035078,
  },
  {
    name: "Moorhead",
    state_id: "MS",
    lat: 33.4494,
    lng: -90.5063,
    id: 1840014772,
  },
  {
    name: "Arthur",
    state_id: "IL",
    lat: 39.7143,
    lng: -88.4695,
    id: 1840012506,
  },
  {
    name: "Lavonia",
    state_id: "GA",
    lat: 34.434,
    lng: -83.1081,
    id: 1840014692,
  },
  {
    name: "Lovettsville",
    state_id: "VA",
    lat: 39.2736,
    lng: -77.6399,
    id: 1840005873,
  },
  {
    name: "De Leon",
    state_id: "TX",
    lat: 32.1114,
    lng: -98.5351,
    id: 1840019511,
  },
  {
    name: "Hawley",
    state_id: "MN",
    lat: 46.877,
    lng: -96.318,
    id: 1840006635,
  },
  {
    name: "Madison Center",
    state_id: "CT",
    lat: 41.2794,
    lng: -72.6003,
    id: 1840073297,
  },
  {
    name: "Brewster Hill",
    state_id: "NY",
    lat: 41.4228,
    lng: -73.6069,
    id: 1840004888,
  },
  {
    name: "Valle Vista",
    state_id: "AZ",
    lat: 35.4109,
    lng: -113.8627,
    id: 1840075499,
  },
  {
    name: "Ball Ground",
    state_id: "GA",
    lat: 34.3426,
    lng: -84.3634,
    id: 1840013623,
  },
  {
    name: "Monserrate",
    state_id: "PR",
    lat: 18.4367,
    lng: -66.3579,
    id: 1630035523,
  },
  {
    name: "Kingston",
    state_id: "WA",
    lat: 47.8014,
    lng: -122.4989,
    id: 1840017338,
  },
  {
    name: "Palmas del Mar",
    state_id: "PR",
    lat: 18.0924,
    lng: -65.8005,
    id: 1630027040,
  },
  {
    name: "Corazón",
    state_id: "PR",
    lat: 17.994,
    lng: -66.0832,
    id: 1630035653,
  },
  {
    name: "Fremont",
    state_id: "IN",
    lat: 41.728,
    lng: -84.9396,
    id: 1840010204,
  },
  {
    name: "Waskom",
    state_id: "TX",
    lat: 32.4766,
    lng: -94.0646,
    id: 1840022073,
  },
  {
    name: "Blackville",
    state_id: "SC",
    lat: 33.3552,
    lng: -81.2835,
    id: 1840015723,
  },
  {
    name: "Fairfield Bay",
    state_id: "AR",
    lat: 35.605,
    lng: -92.2658,
    id: 1840013414,
  },
  {
    name: "Lyons",
    state_id: "CO",
    lat: 40.2231,
    lng: -105.2692,
    id: 1840022423,
  },
  {
    name: "Crystal Lake",
    state_id: "CT",
    lat: 41.9343,
    lng: -72.3756,
    id: 1840000506,
  },
  {
    name: "Ravensworth",
    state_id: "VA",
    lat: 38.8032,
    lng: -77.2223,
    id: 1840024581,
  },
  {
    name: "Murphys",
    state_id: "CA",
    lat: 38.1447,
    lng: -120.4348,
    id: 1840018865,
  },
  {
    name: "Lemont",
    state_id: "PA",
    lat: 40.812,
    lng: -77.8161,
    id: 1840005164,
  },
  {
    name: "Belleair Bluffs",
    state_id: "FL",
    lat: 27.9198,
    lng: -82.8192,
    id: 1840014147,
  },
  {
    name: "Old Brookville",
    state_id: "NY",
    lat: 40.8332,
    lng: -73.604,
    id: 1840005315,
  },
  {
    name: "Chiefland",
    state_id: "FL",
    lat: 29.4923,
    lng: -82.8682,
    id: 1840015066,
  },
  {
    name: "Old Orchard",
    state_id: "PA",
    lat: 40.6575,
    lng: -75.2609,
    id: 1840035433,
  },
  {
    name: "Arial",
    state_id: "SC",
    lat: 34.8462,
    lng: -82.6404,
    id: 1840013525,
  },
  {
    name: "Lexington",
    state_id: "OK",
    lat: 35.0179,
    lng: -97.3345,
    id: 1840020448,
  },
  {
    name: "Pine Beach",
    state_id: "NJ",
    lat: 39.9359,
    lng: -74.17,
    id: 1840001484,
  },
  {
    name: "Parkwood",
    state_id: "CA",
    lat: 36.9293,
    lng: -120.0482,
    id: 1840028394,
  },
  {
    name: "Pink",
    state_id: "OK",
    lat: 35.2327,
    lng: -97.1002,
    id: 1840022765,
  },
  {
    name: "Marionville",
    state_id: "MO",
    lat: 37.0021,
    lng: -93.6362,
    id: 1840008779,
  },
  {
    name: "Ashford",
    state_id: "AL",
    lat: 31.1858,
    lng: -85.2359,
    id: 1840000737,
  },
  {
    name: "Glendale",
    state_id: "OH",
    lat: 39.2707,
    lng: -84.4586,
    id: 1840003819,
  },
  {
    name: "Tullytown",
    state_id: "PA",
    lat: 40.1401,
    lng: -74.8107,
    id: 1840001316,
  },
  {
    name: "Rio Verde",
    state_id: "AZ",
    lat: 33.7278,
    lng: -111.674,
    id: 1840019308,
  },
  {
    name: "Erwinville",
    state_id: "LA",
    lat: 30.5427,
    lng: -91.3913,
    id: 1840026009,
  },
  {
    name: "Hoschton",
    state_id: "GA",
    lat: 34.0853,
    lng: -83.7593,
    id: 1840014722,
  },
  {
    name: "Amherst",
    state_id: "VA",
    lat: 37.5816,
    lng: -79.0509,
    id: 1840006373,
  },
  {
    name: "North Conway",
    state_id: "NH",
    lat: 44.0524,
    lng: -71.1247,
    id: 1840002222,
  },
  {
    name: "Pierre Part",
    state_id: "LA",
    lat: 29.9555,
    lng: -91.2074,
    id: 1840014012,
  },
  {
    name: "Earle",
    state_id: "AR",
    lat: 35.2734,
    lng: -90.4642,
    id: 1840013462,
  },
  {
    name: "Spring Grove",
    state_id: "PA",
    lat: 39.8809,
    lng: -76.8641,
    id: 1840001464,
  },
  {
    name: "Ranchos de Taos",
    state_id: "NM",
    lat: 36.3619,
    lng: -105.6016,
    id: 1840019011,
  },
  {
    name: "East Uniontown",
    state_id: "PA",
    lat: 39.896,
    lng: -79.6976,
    id: 1840035421,
  },
  {
    name: "Pine Island Center",
    state_id: "FL",
    lat: 26.6351,
    lng: -82.1253,
    id: 1840014223,
  },
  {
    name: "Upper Nyack",
    state_id: "NY",
    lat: 41.1143,
    lng: -73.9057,
    id: 1840004988,
  },
  {
    name: "Croom",
    state_id: "MD",
    lat: 38.7458,
    lng: -76.7555,
    id: 1840024543,
  },
  {
    name: "Cibecue",
    state_id: "AZ",
    lat: 34.0333,
    lng: -110.4854,
    id: 1840017654,
  },
  {
    name: "Boles Acres",
    state_id: "NM",
    lat: 32.8196,
    lng: -105.9782,
    id: 1840018038,
  },
  {
    name: "Polo",
    state_id: "IL",
    lat: 41.9847,
    lng: -89.579,
    id: 1840009178,
  },
  {
    name: "Newkirk",
    state_id: "OK",
    lat: 36.8816,
    lng: -97.0554,
    id: 1840020344,
  },
  {
    name: "Lakeshore",
    state_id: "LA",
    lat: 32.535,
    lng: -92.0328,
    id: 1840031019,
  },
  {
    name: "Creston",
    state_id: "OH",
    lat: 40.9769,
    lng: -81.8998,
    id: 1840011892,
  },
  {
    name: "Cordova",
    state_id: "AK",
    lat: 60.5348,
    lng: -145.6092,
    id: 1840023402,
  },
  {
    name: "Wells",
    state_id: "MN",
    lat: 43.7432,
    lng: -93.7278,
    id: 1840010043,
  },
  {
    name: "South Fallsburg",
    state_id: "NY",
    lat: 41.7218,
    lng: -74.635,
    id: 1840004788,
  },
  {
    name: "Kearny",
    state_id: "AZ",
    lat: 33.0564,
    lng: -110.9074,
    id: 1840022000,
  },
  {
    name: "Cheney",
    state_id: "KS",
    lat: 37.6353,
    lng: -97.7797,
    id: 1840007565,
  },
  {
    name: "Schofield",
    state_id: "WI",
    lat: 44.9149,
    lng: -89.6148,
    id: 1840002173,
  },
  {
    name: "Jellico",
    state_id: "TN",
    lat: 36.5651,
    lng: -84.1355,
    id: 1840036119,
  },
  {
    name: "Adamsville",
    state_id: "TN",
    lat: 35.2563,
    lng: -88.3862,
    id: 1840015462,
  },
  {
    name: "Pymatuning Central",
    state_id: "PA",
    lat: 41.5855,
    lng: -80.4795,
    id: 1840035155,
  },
  {
    name: "Shiner",
    state_id: "TX",
    lat: 29.4332,
    lng: -97.1734,
    id: 1840022230,
  },
  {
    name: "Whitney",
    state_id: "TX",
    lat: 31.952,
    lng: -97.3192,
    id: 1840023105,
  },
  {
    name: "Bethel Island",
    state_id: "CA",
    lat: 38.0288,
    lng: -121.6404,
    id: 1840017610,
  },
  {
    name: "Chinchilla",
    state_id: "PA",
    lat: 41.4846,
    lng: -75.666,
    id: 1840026296,
  },
  {
    name: "Spencerville",
    state_id: "OH",
    lat: 40.7079,
    lng: -84.3526,
    id: 1840011956,
  },
  {
    name: "Corralitos",
    state_id: "CA",
    lat: 36.9941,
    lng: -121.7892,
    id: 1840017647,
  },
  {
    name: "Rockwell",
    state_id: "NC",
    lat: 35.5538,
    lng: -80.4072,
    id: 1840017786,
  },
  {
    name: "Eagle",
    state_id: "WI",
    lat: 42.879,
    lng: -88.4697,
    id: 1840003019,
  },
  {
    name: "Spiro",
    state_id: "OK",
    lat: 35.2412,
    lng: -94.623,
    id: 1840022774,
  },
  {
    name: "Messiah College",
    state_id: "PA",
    lat: 40.1576,
    lng: -76.984,
    id: 1840043363,
  },
  {
    name: "Gassville",
    state_id: "AR",
    lat: 36.285,
    lng: -92.4868,
    id: 1840013330,
  },
  {
    name: "Patton Village",
    state_id: "TX",
    lat: 30.1954,
    lng: -95.1643,
    id: 1840020888,
  },
  {
    name: "Penndel",
    state_id: "PA",
    lat: 40.1552,
    lng: -74.9147,
    id: 1840001308,
  },
  {
    name: "Union City",
    state_id: "OK",
    lat: 35.4015,
    lng: -97.9047,
    id: 1840022732,
  },
  {
    name: "Carlisle",
    state_id: "AR",
    lat: 34.7947,
    lng: -91.7361,
    id: 1840014628,
  },
  {
    name: "McAdoo",
    state_id: "PA",
    lat: 40.9009,
    lng: -75.9924,
    id: 1840003567,
  },
  {
    name: "Plainview",
    state_id: "TN",
    lat: 36.1808,
    lng: -83.7931,
    id: 1840073879,
  },
  {
    name: "Walden",
    state_id: "TN",
    lat: 35.1635,
    lng: -85.3098,
    id: 1840016375,
  },
  {
    name: "Helmetta",
    state_id: "NJ",
    lat: 40.3777,
    lng: -74.4239,
    id: 1840003635,
  },
  {
    name: "Farmington",
    state_id: "MS",
    lat: 34.9224,
    lng: -88.4432,
    id: 1840016512,
  },
  {
    name: "Glendale",
    state_id: "MS",
    lat: 31.368,
    lng: -89.3086,
    id: 1840025969,
  },
  {
    name: "Garyville",
    state_id: "LA",
    lat: 30.0688,
    lng: -90.6285,
    id: 1840013966,
  },
  {
    name: "Reed Creek",
    state_id: "GA",
    lat: 34.4395,
    lng: -82.9103,
    id: 1840013613,
  },
  {
    name: "Bottineau",
    state_id: "ND",
    lat: 48.8248,
    lng: -100.4425,
    id: 1840000003,
  },
  {
    name: "Ware Shoals",
    state_id: "SC",
    lat: 34.3922,
    lng: -82.2421,
    id: 1840016674,
  },
  {
    name: "Manchester",
    state_id: "MI",
    lat: 42.1484,
    lng: -84.0346,
    id: 1840011194,
  },
  {
    name: "Freeland",
    state_id: "WA",
    lat: 48.0185,
    lng: -122.5358,
    id: 1840017317,
  },
  {
    name: "Brewster",
    state_id: "OH",
    lat: 40.7135,
    lng: -81.5992,
    id: 1840010351,
  },
  {
    name: "Townsend",
    state_id: "MT",
    lat: 46.3192,
    lng: -111.5197,
    id: 1840021165,
  },
  {
    name: "Eglin AFB",
    state_id: "FL",
    lat: 30.4592,
    lng: -86.5499,
    id: 1840073828,
  },
  {
    name: "New Ellenton",
    state_id: "SC",
    lat: 33.4198,
    lng: -81.6825,
    id: 1840016797,
  },
  {
    name: "New Glarus",
    state_id: "WI",
    lat: 42.813,
    lng: -89.6337,
    id: 1840002466,
  },
  {
    name: "Stonybrook",
    state_id: "PA",
    lat: 39.9798,
    lng: -76.6318,
    id: 1840024352,
  },
  {
    name: "Evansburg",
    state_id: "PA",
    lat: 40.1899,
    lng: -75.4348,
    id: 1840034955,
  },
  {
    name: "Millville",
    state_id: "UT",
    lat: 41.685,
    lng: -111.8212,
    id: 1840020112,
  },
  {
    name: "Lake Wazeecha",
    state_id: "WI",
    lat: 44.3709,
    lng: -89.7551,
    id: 1840004085,
  },
  {
    name: "Elmendorf",
    state_id: "TX",
    lat: 29.258,
    lng: -98.3219,
    id: 1840020955,
  },
  {
    name: "Morris",
    state_id: "AL",
    lat: 33.7467,
    lng: -86.8037,
    id: 1840016801,
  },
  {
    name: "Maple Lake",
    state_id: "MN",
    lat: 45.2334,
    lng: -94.0059,
    id: 1840008897,
  },
  {
    name: "Caryville",
    state_id: "TN",
    lat: 36.3252,
    lng: -84.2198,
    id: 1840016024,
  },
  {
    name: "Laymantown",
    state_id: "VA",
    lat: 37.3628,
    lng: -79.8525,
    id: 1840006377,
  },
  {
    name: "Albany",
    state_id: "IN",
    lat: 40.3056,
    lng: -85.2331,
    id: 1840010461,
  },
  {
    name: "Parma",
    state_id: "ID",
    lat: 43.7863,
    lng: -116.9428,
    id: 1840020043,
  },
  {
    name: "Marlborough",
    state_id: "MO",
    lat: 38.5683,
    lng: -90.3393,
    id: 1840012823,
  },
  {
    name: "Berkeley Lake",
    state_id: "GA",
    lat: 33.9805,
    lng: -84.1839,
    id: 1840013677,
  },
  {
    name: "Barahona",
    state_id: "PR",
    lat: 18.3523,
    lng: -66.4442,
    id: 1630023612,
  },
  {
    name: "Morenci",
    state_id: "MI",
    lat: 41.7226,
    lng: -84.2169,
    id: 1840003227,
  },
  {
    name: "Lewistown",
    state_id: "IL",
    lat: 40.3968,
    lng: -90.1555,
    id: 1840008331,
  },
  {
    name: "Arlington",
    state_id: "MN",
    lat: 44.6083,
    lng: -94.0766,
    id: 1840006776,
  },
  {
    name: "Chester",
    state_id: "CA",
    lat: 40.3017,
    lng: -121.2339,
    id: 1840017516,
  },
  {
    name: "Cloverdale",
    state_id: "IN",
    lat: 39.5211,
    lng: -86.7995,
    id: 1840009544,
  },
  {
    name: "Borrego Springs",
    state_id: "CA",
    lat: 33.241,
    lng: -116.3571,
    id: 1840018017,
  },
  {
    name: "Rockport",
    state_id: "IN",
    lat: 37.8896,
    lng: -87.0542,
    id: 1840009848,
  },
  {
    name: "Kingsbury",
    state_id: "NV",
    lat: 38.9909,
    lng: -119.8837,
    id: 1840033778,
  },
  {
    name: "Long Beach",
    state_id: "MD",
    lat: 38.4568,
    lng: -76.4737,
    id: 1840026725,
  },
  {
    name: "Toledo",
    state_id: "IA",
    lat: 41.9902,
    lng: -92.5803,
    id: 1840010127,
  },
  {
    name: "Haverhill",
    state_id: "FL",
    lat: 26.6909,
    lng: -80.1217,
    id: 1840015994,
  },
  {
    name: "Lafourche Crossing",
    state_id: "LA",
    lat: 29.7675,
    lng: -90.7697,
    id: 1840039417,
  },
  {
    name: "Fannett",
    state_id: "TX",
    lat: 29.925,
    lng: -94.2389,
    id: 1840026033,
  },
  {
    name: "Lakin",
    state_id: "KS",
    lat: 37.94,
    lng: -101.2585,
    id: 1840008668,
  },
  {
    name: "Piketon",
    state_id: "OH",
    lat: 39.0649,
    lng: -82.9985,
    id: 1840012752,
  },
  {
    name: "Coaldale",
    state_id: "PA",
    lat: 40.8197,
    lng: -75.9161,
    id: 1840002755,
  },
  {
    name: "Lorenz Park",
    state_id: "NY",
    lat: 42.2669,
    lng: -73.77,
    id: 1840034053,
  },
  {
    name: "Clinton",
    state_id: "WI",
    lat: 42.5574,
    lng: -88.8679,
    id: 1840002472,
  },
  {
    name: "Varnell",
    state_id: "GA",
    lat: 34.9015,
    lng: -84.9645,
    id: 1840015528,
  },
  {
    name: "Lipscomb",
    state_id: "AL",
    lat: 33.4261,
    lng: -86.926,
    id: 1840015667,
  },
  {
    name: "Rising Sun",
    state_id: "IN",
    lat: 38.9531,
    lng: -84.8545,
    id: 1840014664,
  },
  {
    name: "Granby",
    state_id: "CO",
    lat: 40.0648,
    lng: -105.9194,
    id: 1840021407,
  },
  {
    name: "Cherryvale",
    state_id: "KS",
    lat: 37.2694,
    lng: -95.554,
    id: 1840001706,
  },
  {
    name: "Malad City",
    state_id: "ID",
    lat: 42.1903,
    lng: -112.25,
    id: 1840020093,
  },
  {
    name: "Ridgetop",
    state_id: "TN",
    lat: 36.4045,
    lng: -86.7681,
    id: 1840014415,
  },
  {
    name: "Howard Lake",
    state_id: "MN",
    lat: 45.0604,
    lng: -94.067,
    id: 1840006709,
  },
  {
    name: "China Lake Acres",
    state_id: "CA",
    lat: 35.6387,
    lng: -117.7667,
    id: 1840028339,
  },
  {
    name: "Aviston",
    state_id: "IL",
    lat: 38.6162,
    lng: -89.6087,
    id: 1840012851,
  },
  {
    name: "Santa Nella",
    state_id: "CA",
    lat: 37.1014,
    lng: -121.0153,
    id: 1840076205,
  },
  {
    name: "Emajagua",
    state_id: "PR",
    lat: 18.0015,
    lng: -65.8829,
    id: 1630023699,
  },
  {
    name: "Dublin",
    state_id: "PA",
    lat: 40.373,
    lng: -75.204,
    id: 1840001321,
  },
  {
    name: "Wewahitchka",
    state_id: "FL",
    lat: 30.1148,
    lng: -85.1935,
    id: 1840015938,
  },
  {
    name: "Montegut",
    state_id: "LA",
    lat: 29.4656,
    lng: -90.5617,
    id: 1840014034,
  },
  {
    name: "Tavernier",
    state_id: "FL",
    lat: 25.0188,
    lng: -80.5132,
    id: 1840014252,
  },
  {
    name: "Solomons",
    state_id: "MD",
    lat: 38.3373,
    lng: -76.4611,
    id: 1840006149,
  },
  {
    name: "Wrightsville",
    state_id: "AR",
    lat: 34.6109,
    lng: -92.2136,
    id: 1840015512,
  },
  {
    name: "Brighton",
    state_id: "IL",
    lat: 39.0405,
    lng: -90.1408,
    id: 1840010648,
  },
  {
    name: "Johnson City",
    state_id: "TX",
    lat: 30.2742,
    lng: -98.4063,
    id: 1840019595,
  },
  {
    name: "Liberty",
    state_id: "KY",
    lat: 37.3206,
    lng: -84.9285,
    id: 1840015247,
  },
  {
    name: "Reinholds",
    state_id: "PA",
    lat: 40.2697,
    lng: -76.1218,
    id: 1840024344,
  },
  {
    name: "Owens Cross Roads",
    state_id: "AL",
    lat: 34.5845,
    lng: -86.4574,
    id: 1840016521,
  },
  {
    name: "Atlantis",
    state_id: "FL",
    lat: 26.5962,
    lng: -80.1031,
    id: 1840014203,
  },
  {
    name: "Elkton",
    state_id: "KY",
    lat: 36.8135,
    lng: -87.161,
    id: 1840013272,
  },
  {
    name: "Anchor Point",
    state_id: "AK",
    lat: 59.7703,
    lng: -151.7052,
    id: 1840023410,
  },
  {
    name: "Belford",
    state_id: "NJ",
    lat: 40.4262,
    lng: -74.0803,
    id: 1840005439,
  },
  {
    name: "Glenwood",
    state_id: "AR",
    lat: 34.3279,
    lng: -93.5309,
    id: 1840013636,
  },
  {
    name: "Redgranite",
    state_id: "WI",
    lat: 44.0513,
    lng: -89.1058,
    id: 1840002241,
  },
  {
    name: "North Beach Haven",
    state_id: "NJ",
    lat: 39.6008,
    lng: -74.2123,
    id: 1840005532,
  },
  {
    name: "Rouses Point",
    state_id: "NY",
    lat: 44.9933,
    lng: -73.3633,
    id: 1840004043,
  },
  {
    name: "Salt Creek Commons",
    state_id: "IN",
    lat: 41.5112,
    lng: -87.1412,
    id: 1840043144,
  },
  {
    name: "Demorest",
    state_id: "GA",
    lat: 34.5648,
    lng: -83.5424,
    id: 1840014662,
  },
  {
    name: "Orleans",
    state_id: "IN",
    lat: 38.6615,
    lng: -86.4517,
    id: 1840009657,
  },
  {
    name: "Palmer",
    state_id: "TX",
    lat: 32.4259,
    lng: -96.6724,
    id: 1840023052,
  },
  {
    name: "Vineyard Haven",
    state_id: "MA",
    lat: 41.4565,
    lng: -70.6069,
    id: 1840004895,
  },
  {
    name: "Tennille",
    state_id: "GA",
    lat: 32.936,
    lng: -82.8134,
    id: 1840015747,
  },
  {
    name: "Jasonville",
    state_id: "IN",
    lat: 39.162,
    lng: -87.1998,
    id: 1840008551,
  },
  {
    name: "Council Grove",
    state_id: "KS",
    lat: 38.6623,
    lng: -96.4917,
    id: 1840001648,
  },
  {
    name: "Cabin John",
    state_id: "MD",
    lat: 38.9743,
    lng: -77.1635,
    id: 1840005828,
  },
  {
    name: "Turner",
    state_id: "OR",
    lat: 44.8489,
    lng: -122.9517,
    id: 1840021229,
  },
  {
    name: "Tremont",
    state_id: "IL",
    lat: 40.5249,
    lng: -89.4902,
    id: 1840011994,
  },
  {
    name: "Mason City",
    state_id: "IL",
    lat: 40.2022,
    lng: -89.6974,
    id: 1840008357,
  },
  {
    name: "Country Knolls",
    state_id: "NY",
    lat: 42.9141,
    lng: -73.8062,
    id: 1840034043,
  },
  {
    name: "Kahuku",
    state_id: "HI",
    lat: 21.6793,
    lng: -157.9473,
    id: 1840029491,
  },
  {
    name: "Mount Jackson",
    state_id: "VA",
    lat: 38.7394,
    lng: -78.6513,
    id: 1840006002,
  },
  {
    name: "Welch",
    state_id: "WV",
    lat: 37.4455,
    lng: -81.5722,
    id: 1840006422,
  },
  {
    name: "Jal",
    state_id: "NM",
    lat: 32.1148,
    lng: -103.19,
    id: 1840019342,
  },
  {
    name: "Cedar Grove",
    state_id: "WI",
    lat: 43.5682,
    lng: -87.8243,
    id: 1840002624,
  },
  {
    name: "Landisville",
    state_id: "PA",
    lat: 40.0902,
    lng: -76.4063,
    id: 1840024340,
  },
  {
    name: "Watchtower",
    state_id: "NY",
    lat: 41.6371,
    lng: -74.2633,
    id: 1840026219,
  },
  {
    name: "Madison",
    state_id: "NC",
    lat: 36.3868,
    lng: -79.975,
    id: 1840016053,
  },
  {
    name: "Ciales",
    state_id: "PR",
    lat: 18.3343,
    lng: -66.4704,
    id: 1630023668,
  },
  {
    name: "Vinita Park",
    state_id: "MO",
    lat: 38.6888,
    lng: -90.3393,
    id: 1840010742,
  },
  {
    name: "Ronan",
    state_id: "MT",
    lat: 47.5287,
    lng: -114.1005,
    id: 1840019801,
  },
  {
    name: "Maple Plain",
    state_id: "MN",
    lat: 45.0088,
    lng: -93.6618,
    id: 1840008924,
  },
  {
    name: "Hamilton City",
    state_id: "CA",
    lat: 39.7422,
    lng: -122.0125,
    id: 1840017542,
  },
  {
    name: "Arnold Line",
    state_id: "MS",
    lat: 31.3372,
    lng: -89.3772,
    id: 1840025971,
  },
  {
    name: "Hertford",
    state_id: "NC",
    lat: 36.1813,
    lng: -76.4673,
    id: 1840015311,
  },
  {
    name: "Stanfield",
    state_id: "OR",
    lat: 45.7849,
    lng: -119.219,
    id: 1840021193,
  },
  {
    name: "Huntington",
    state_id: "TX",
    lat: 31.2803,
    lng: -94.5772,
    id: 1840020839,
  },
  {
    name: "East Missoula",
    state_id: "MT",
    lat: 46.8801,
    lng: -113.9401,
    id: 1840018434,
  },
  {
    name: "Wilmerding",
    state_id: "PA",
    lat: 40.3942,
    lng: -79.8101,
    id: 1840001279,
  },
  {
    name: "Quitman",
    state_id: "MS",
    lat: 32.0417,
    lng: -88.7202,
    id: 1840014920,
  },
  {
    name: "Montz",
    state_id: "LA",
    lat: 30.0168,
    lng: -90.4634,
    id: 1840013981,
  },
  {
    name: "Unionville",
    state_id: "TN",
    lat: 35.6118,
    lng: -86.5739,
    id: 1840024945,
  },
  {
    name: "Horseshoe Bend",
    state_id: "AR",
    lat: 36.2217,
    lng: -91.7396,
    id: 1840014476,
  },
  {
    name: "Breckinridge Center",
    state_id: "KY",
    lat: 37.682,
    lng: -87.8656,
    id: 1840030812,
  },
  {
    name: "Lake Lotawana",
    state_id: "MO",
    lat: 38.902,
    lng: -94.2626,
    id: 1840008536,
  },
  {
    name: "Bell Canyon",
    state_id: "CA",
    lat: 34.2081,
    lng: -118.6876,
    id: 1840042214,
  },
  {
    name: "Hickman",
    state_id: "KY",
    lat: 36.565,
    lng: -89.1837,
    id: 1840014420,
  },
  {
    name: "Kountze",
    state_id: "TX",
    lat: 30.3724,
    lng: -94.3161,
    id: 1840020897,
  },
  {
    name: "West Hamburg",
    state_id: "PA",
    lat: 40.5524,
    lng: -76.002,
    id: 1840035303,
  },
  {
    name: "Pawnee",
    state_id: "OK",
    lat: 36.3363,
    lng: -96.8019,
    id: 1840020376,
  },
  {
    name: "Jamestown",
    state_id: "TN",
    lat: 36.4319,
    lng: -84.9341,
    id: 1840014429,
  },
  {
    name: "Williston Highlands",
    state_id: "FL",
    lat: 29.3336,
    lng: -82.5357,
    id: 1840014037,
  },
  {
    name: "Eureka Springs",
    state_id: "AR",
    lat: 36.4105,
    lng: -93.745,
    id: 1840013321,
  },
  {
    name: "Helper",
    state_id: "UT",
    lat: 39.6898,
    lng: -110.8597,
    id: 1840020200,
  },
  {
    name: "Cudjoe Key",
    state_id: "FL",
    lat: 24.6746,
    lng: -81.4986,
    id: 1840029031,
  },
  {
    name: "Conesus Lake",
    state_id: "NY",
    lat: 42.7756,
    lng: -77.7135,
    id: 1840040222,
  },
  {
    name: "Braddock",
    state_id: "PA",
    lat: 40.4018,
    lng: -79.8688,
    id: 1840001209,
  },
  {
    name: "Jonestown",
    state_id: "TX",
    lat: 30.4754,
    lng: -97.9297,
    id: 1840019591,
  },
  {
    name: "Belvedere",
    state_id: "CA",
    lat: 37.8735,
    lng: -122.4662,
    id: 1840018890,
  },
  {
    name: "Shinnston",
    state_id: "WV",
    lat: 39.3923,
    lng: -80.2989,
    id: 1840005769,
  },
  {
    name: "San Juan Bautista",
    state_id: "CA",
    lat: 36.8455,
    lng: -121.538,
    id: 1840021631,
  },
  {
    name: "Belville",
    state_id: "NC",
    lat: 34.2194,
    lng: -77.9984,
    id: 1840015578,
  },
  {
    name: "East Helena",
    state_id: "MT",
    lat: 46.585,
    lng: -111.9158,
    id: 1840021111,
  },
  {
    name: "Maysville",
    state_id: "GA",
    lat: 34.2557,
    lng: -83.5521,
    id: 1840016660,
  },
  {
    name: "Cumberland",
    state_id: "WI",
    lat: 45.5361,
    lng: -92.0289,
    id: 1840002072,
  },
  {
    name: "Falmouth",
    state_id: "KY",
    lat: 38.6708,
    lng: -84.331,
    id: 1840013173,
  },
  {
    name: "Angola",
    state_id: "NY",
    lat: 42.6379,
    lng: -79.0295,
    id: 1840004395,
  },
  {
    name: "Smoke Rise",
    state_id: "AL",
    lat: 33.8789,
    lng: -86.824,
    id: 1840013654,
  },
  {
    name: "Hanley Hills",
    state_id: "MO",
    lat: 38.6856,
    lng: -90.3249,
    id: 1840012817,
  },
  {
    name: "Gila Bend",
    state_id: "AZ",
    lat: 32.9662,
    lng: -112.7155,
    id: 1840021946,
  },
  {
    name: "Westport",
    state_id: "WA",
    lat: 46.8917,
    lng: -124.1118,
    id: 1840021124,
  },
  {
    name: "Bowdon",
    state_id: "GA",
    lat: 33.5378,
    lng: -85.2539,
    id: 1840013738,
  },
  {
    name: "Willow Springs",
    state_id: "MO",
    lat: 36.9878,
    lng: -91.9621,
    id: 1840010888,
  },
  {
    name: "New Boston",
    state_id: "OH",
    lat: 38.7516,
    lng: -82.9351,
    id: 1840001645,
  },
  {
    name: "Poquonock Bridge",
    state_id: "CT",
    lat: 41.3379,
    lng: -72.0175,
    id: 1840003354,
  },
  {
    name: "Oakbrook Terrace",
    state_id: "IL",
    lat: 41.8539,
    lng: -87.9685,
    id: 1840008144,
  },
  {
    name: "Kure Beach",
    state_id: "NC",
    lat: 34.0003,
    lng: -77.9077,
    id: 1840016676,
  },
  {
    name: "Nisswa",
    state_id: "MN",
    lat: 46.5003,
    lng: -94.2977,
    id: 1840007740,
  },
  {
    name: "South Zanesville",
    state_id: "OH",
    lat: 39.9036,
    lng: -82.0178,
    id: 1840012397,
  },
  {
    name: "North Pole",
    state_id: "AK",
    lat: 64.753,
    lng: -147.36,
    id: 1840023464,
  },
  {
    name: "Josephine",
    state_id: "TX",
    lat: 33.0618,
    lng: -96.3186,
    id: 1840019399,
  },
  {
    name: "Manahawkin",
    state_id: "NJ",
    lat: 39.6931,
    lng: -74.2493,
    id: 1840005535,
  },
  {
    name: "Chouteau",
    state_id: "OK",
    lat: 36.1884,
    lng: -95.3362,
    id: 1840022667,
  },
  {
    name: "Graysville",
    state_id: "AL",
    lat: 33.6583,
    lng: -86.9674,
    id: 1840003493,
  },
  {
    name: "Malone",
    state_id: "FL",
    lat: 30.9589,
    lng: -85.1622,
    id: 1840017149,
  },
  {
    name: "Milford",
    state_id: "NE",
    lat: 40.7722,
    lng: -97.0535,
    id: 1840008287,
  },
  {
    name: "Laguna Heights",
    state_id: "TX",
    lat: 26.081,
    lng: -97.2592,
    id: 1840018326,
  },
  {
    name: "Williamsport",
    state_id: "MD",
    lat: 39.5974,
    lng: -77.818,
    id: 1840005619,
  },
  {
    name: "Calwa",
    state_id: "CA",
    lat: 36.7136,
    lng: -119.7607,
    id: 1840022562,
  },
  {
    name: "Essex Fells",
    state_id: "NJ",
    lat: 40.8271,
    lng: -74.2798,
    id: 1840005355,
  },
  {
    name: "Walnutport",
    state_id: "PA",
    lat: 40.7514,
    lng: -75.5955,
    id: 1840000982,
  },
  {
    name: "Greenock",
    state_id: "PA",
    lat: 40.3125,
    lng: -79.8036,
    id: 1840026475,
  },
  {
    name: "Lame Deer",
    state_id: "MT",
    lat: 45.6176,
    lng: -106.6134,
    id: 1840017373,
  },
  {
    name: "Greenfield",
    state_id: "TN",
    lat: 36.16,
    lng: -88.8036,
    id: 1840013316,
  },
  {
    name: "Geneva",
    state_id: "NE",
    lat: 40.5308,
    lng: -97.5974,
    id: 1840008333,
  },
  {
    name: "Gurdon",
    state_id: "AR",
    lat: 33.9155,
    lng: -93.1544,
    id: 1840013639,
  },
  {
    name: "Marceline",
    state_id: "MO",
    lat: 39.7167,
    lng: -92.9473,
    id: 1840008402,
  },
  {
    name: "Granby",
    state_id: "MO",
    lat: 36.9181,
    lng: -94.2607,
    id: 1840008792,
  },
  {
    name: "Patterson Tract",
    state_id: "CA",
    lat: 36.3795,
    lng: -119.2956,
    id: 1840042286,
  },
  {
    name: "Astatula",
    state_id: "FL",
    lat: 28.7065,
    lng: -81.7346,
    id: 1840015952,
  },
  {
    name: "New Eagle",
    state_id: "PA",
    lat: 40.2064,
    lng: -79.9532,
    id: 1840003662,
  },
  {
    name: "Fairbanks Ranch",
    state_id: "CA",
    lat: 32.9919,
    lng: -117.1859,
    id: 1840135662,
  },
  {
    name: "Lake Holiday",
    state_id: "VA",
    lat: 39.3092,
    lng: -78.3245,
    id: 1840043223,
  },
  {
    name: "Versailles",
    state_id: "IN",
    lat: 39.0636,
    lng: -85.2564,
    id: 1840014406,
  },
  {
    name: "Cairo",
    state_id: "IL",
    lat: 37.0062,
    lng: -89.1819,
    id: 1840007633,
  },
  {
    name: "Auburn",
    state_id: "MI",
    lat: 43.6021,
    lng: -84.0757,
    id: 1840002582,
  },
  {
    name: "Houston",
    state_id: "MO",
    lat: 37.3212,
    lng: -91.9611,
    id: 1840007601,
  },
  {
    name: "Rockwell City",
    state_id: "IA",
    lat: 42.3981,
    lng: -94.6289,
    id: 1840009120,
  },
  {
    name: "Naples",
    state_id: "UT",
    lat: 40.4318,
    lng: -109.4913,
    id: 1840020160,
  },
  {
    name: "Mineralwells",
    state_id: "WV",
    lat: 39.1807,
    lng: -81.513,
    id: 1840005783,
  },
  {
    name: "Galesburg",
    state_id: "MI",
    lat: 42.2911,
    lng: -85.4182,
    id: 1840003184,
  },
  {
    name: "Cabool",
    state_id: "MO",
    lat: 37.1262,
    lng: -92.1032,
    id: 1840007600,
  },
  {
    name: "Horse Pasture",
    state_id: "VA",
    lat: 36.6313,
    lng: -79.9514,
    id: 1840006555,
  },
  {
    name: "Cowarts",
    state_id: "AL",
    lat: 31.2041,
    lng: -85.3062,
    id: 1840017131,
  },
  {
    name: "Spencerville",
    state_id: "MD",
    lat: 39.119,
    lng: -76.9828,
    id: 1840024492,
  },
  {
    name: "Columbus Grove",
    state_id: "OH",
    lat: 40.9205,
    lng: -84.0598,
    id: 1840011751,
  },
  {
    name: "Fifth Street",
    state_id: "TX",
    lat: 29.5982,
    lng: -95.5519,
    id: 1840018264,
  },
  {
    name: "Columbus",
    state_id: "MT",
    lat: 45.636,
    lng: -109.2488,
    id: 1840022332,
  },
  {
    name: "Honokaa",
    state_id: "HI",
    lat: 20.0746,
    lng: -155.4656,
    id: 1840029483,
  },
  {
    name: "Cambridge",
    state_id: "IL",
    lat: 41.2984,
    lng: -90.1907,
    id: 1840010243,
  },
  {
    name: "Pecatonica",
    state_id: "IL",
    lat: 42.3087,
    lng: -89.3577,
    id: 1840011125,
  },
  {
    name: "Tracy",
    state_id: "MN",
    lat: 44.239,
    lng: -95.6153,
    id: 1840010020,
  },
  {
    name: "Elsberry",
    state_id: "MO",
    lat: 39.1683,
    lng: -90.7879,
    id: 1840008540,
  },
  {
    name: "Southmont",
    state_id: "PA",
    lat: 40.3108,
    lng: -78.9327,
    id: 1840001126,
  },
  {
    name: "Ord",
    state_id: "NE",
    lat: 41.5995,
    lng: -98.9169,
    id: 1840009254,
  },
  {
    name: "River Heights",
    state_id: "UT",
    lat: 41.7219,
    lng: -111.8195,
    id: 1840020110,
  },
  {
    name: "Seneca",
    state_id: "KS",
    lat: 39.8376,
    lng: -96.0689,
    id: 1840000729,
  },
  {
    name: "Oakley",
    state_id: "KS",
    lat: 39.1234,
    lng: -100.8451,
    id: 1840008471,
  },
  {
    name: "Fritz Creek",
    state_id: "AK",
    lat: 59.747,
    lng: -151.2884,
    id: 1840023672,
  },
  {
    name: "Coal Grove",
    state_id: "OH",
    lat: 38.4976,
    lng: -82.6423,
    id: 1840012837,
  },
  {
    name: "Morrisville",
    state_id: "VT",
    lat: 44.5583,
    lng: -72.5933,
    id: 1840004080,
  },
  {
    name: "Holcomb",
    state_id: "KS",
    lat: 37.9864,
    lng: -100.9937,
    id: 1840007523,
  },
  {
    name: "Weatherby Lake",
    state_id: "MO",
    lat: 39.2365,
    lng: -94.696,
    id: 1840010642,
  },
  {
    name: "Stevensville",
    state_id: "MT",
    lat: 46.511,
    lng: -114.0788,
    id: 1840022324,
  },
  {
    name: "Lincoln Beach",
    state_id: "OR",
    lat: 44.8746,
    lng: -124.031,
    id: 1840018591,
  },
  {
    name: "New Haven",
    state_id: "MO",
    lat: 38.6048,
    lng: -91.2179,
    id: 1840008622,
  },
  {
    name: "Cardington",
    state_id: "OH",
    lat: 40.498,
    lng: -82.8937,
    id: 1840010387,
  },
  {
    name: "Blanco",
    state_id: "TX",
    lat: 30.0987,
    lng: -98.4169,
    id: 1840019594,
  },
  {
    name: "Lyman",
    state_id: "WY",
    lat: 41.3275,
    lng: -110.2975,
    id: 1840021340,
  },
  {
    name: "Anthony",
    state_id: "KS",
    lat: 37.1573,
    lng: -98.0408,
    id: 1840001709,
  },
  {
    name: "Topton",
    state_id: "PA",
    lat: 40.5032,
    lng: -75.7024,
    id: 1840001192,
  },
  {
    name: "Kirbyville",
    state_id: "TX",
    lat: 30.6578,
    lng: -93.9001,
    id: 1840020856,
  },
  {
    name: "Dolgeville",
    state_id: "NY",
    lat: 43.1031,
    lng: -74.7749,
    id: 1840004156,
  },
  {
    name: "Wellington",
    state_id: "TX",
    lat: 34.8538,
    lng: -100.2141,
    id: 1840021822,
  },
  {
    name: "Seneca Knolls",
    state_id: "NY",
    lat: 43.12,
    lng: -76.2874,
    id: 1840034041,
  },
  {
    name: "Sac City",
    state_id: "IA",
    lat: 42.4217,
    lng: -94.9979,
    id: 1840009113,
  },
  {
    name: "Pegram",
    state_id: "TN",
    lat: 36.1037,
    lng: -87.0565,
    id: 1840016091,
  },
  {
    name: "Robesonia",
    state_id: "PA",
    lat: 40.3495,
    lng: -76.1391,
    id: 1840001186,
  },
  {
    name: "Conway",
    state_id: "PA",
    lat: 40.6674,
    lng: -80.2411,
    id: 1840001015,
  },
  {
    name: "Greensburg",
    state_id: "KY",
    lat: 37.2599,
    lng: -85.4966,
    id: 1840013243,
  },
  {
    name: "Porter Heights",
    state_id: "TX",
    lat: 30.1501,
    lng: -95.3217,
    id: 1840019584,
  },
  {
    name: "Glen Rock",
    state_id: "PA",
    lat: 39.7935,
    lng: -76.7307,
    id: 1840001447,
  },
  {
    name: "Fairplains",
    state_id: "NC",
    lat: 36.1954,
    lng: -81.1559,
    id: 1840013065,
  },
  {
    name: "Shadybrook",
    state_id: "TX",
    lat: 32.1108,
    lng: -95.4212,
    id: 1840042635,
  },
  {
    name: "Drexel",
    state_id: "OH",
    lat: 39.7382,
    lng: -84.2931,
    id: 1840034253,
  },
  {
    name: "Colfax",
    state_id: "IA",
    lat: 41.68,
    lng: -93.2386,
    id: 1840007070,
  },
  {
    name: "Stephens City",
    state_id: "VA",
    lat: 39.0902,
    lng: -78.223,
    id: 1840005777,
  },
  {
    name: "Pardeeville",
    state_id: "WI",
    lat: 43.5359,
    lng: -89.301,
    id: 1840002711,
  },
  {
    name: "Marshall",
    state_id: "VA",
    lat: 38.8661,
    lng: -77.8453,
    id: 1840024592,
  },
  {
    name: "Las Marías",
    state_id: "PR",
    lat: 18.2928,
    lng: -67.145,
    id: 1630035501,
  },
  {
    name: "Midway",
    state_id: "PA",
    lat: 39.8039,
    lng: -77.0052,
    id: 1840145546,
  },
  {
    name: "Midway",
    state_id: "GA",
    lat: 31.8003,
    lng: -81.419,
    id: 1840014929,
  },
  {
    name: "Cape Carteret",
    state_id: "NC",
    lat: 34.6973,
    lng: -77.0572,
    id: 1840015498,
  },
  {
    name: "McCamey",
    state_id: "TX",
    lat: 31.1325,
    lng: -102.22,
    id: 1840020829,
  },
  {
    name: "Olton",
    state_id: "TX",
    lat: 34.1802,
    lng: -102.137,
    id: 1840020540,
  },
  {
    name: "New Stanton",
    state_id: "PA",
    lat: 40.2237,
    lng: -79.6086,
    id: 1840001152,
  },
  {
    name: "San Ysidro",
    state_id: "NM",
    lat: 32.3568,
    lng: -106.8129,
    id: 1840148458,
  },
  {
    name: "Simmesport",
    state_id: "LA",
    lat: 30.9805,
    lng: -91.8143,
    id: 1840018188,
  },
  {
    name: "Kohler",
    state_id: "WI",
    lat: 43.7364,
    lng: -87.7795,
    id: 1840002620,
  },
  {
    name: "St. George",
    state_id: "MO",
    lat: 38.5376,
    lng: -90.312,
    id: 1840141651,
  },
  {
    name: "Jacksboro",
    state_id: "TN",
    lat: 36.3352,
    lng: -84.1928,
    id: 1840016023,
  },
  {
    name: "Stratford",
    state_id: "TX",
    lat: 36.3376,
    lng: -102.076,
    id: 1840021666,
  },
  {
    name: "Lake Summerset",
    state_id: "IL",
    lat: 42.4521,
    lng: -89.3984,
    id: 1840004634,
  },
  {
    name: "Kerhonkson",
    state_id: "NY",
    lat: 41.7797,
    lng: -74.2956,
    id: 1840004718,
  },
  {
    name: "Burnt Store Marina",
    state_id: "FL",
    lat: 26.7645,
    lng: -82.0507,
    id: 1840028975,
  },
  {
    name: "Thorsby",
    state_id: "AL",
    lat: 32.917,
    lng: -86.7203,
    id: 1840016920,
  },
  {
    name: "Wilsonville",
    state_id: "AL",
    lat: 33.2351,
    lng: -86.4882,
    id: 1840016850,
  },
  {
    name: "Chevy Chase Village",
    state_id: "MD",
    lat: 38.9698,
    lng: -77.0793,
    id: 1840005854,
  },
  {
    name: "Bellerose Terrace",
    state_id: "NY",
    lat: 40.7221,
    lng: -73.7252,
    id: 1840005231,
  },
  {
    name: "Ganado",
    state_id: "TX",
    lat: 29.0413,
    lng: -96.511,
    id: 1840020985,
  },
  {
    name: "Imperial",
    state_id: "NE",
    lat: 40.5145,
    lng: -101.6374,
    id: 1840007214,
  },
  {
    name: "Three Forks",
    state_id: "MT",
    lat: 45.89,
    lng: -111.5522,
    id: 1840021184,
  },
  {
    name: "Leonard",
    state_id: "TX",
    lat: 33.383,
    lng: -96.2467,
    id: 1840020597,
  },
  {
    name: "Hale Center",
    state_id: "TX",
    lat: 34.0662,
    lng: -101.8462,
    id: 1840020536,
  },
  {
    name: "Buena Vista",
    state_id: "GA",
    lat: 32.3188,
    lng: -84.5177,
    id: 1840013827,
  },
  {
    name: "Jermyn",
    state_id: "PA",
    lat: 41.5274,
    lng: -75.5455,
    id: 1840003382,
  },
  {
    name: "Rosemount",
    state_id: "OH",
    lat: 38.7812,
    lng: -82.9664,
    id: 1840006054,
  },
  {
    name: "Postville",
    state_id: "IA",
    lat: 43.084,
    lng: -91.5683,
    id: 1840009039,
  },
  {
    name: "Tigerville",
    state_id: "SC",
    lat: 35.0669,
    lng: -82.3695,
    id: 1840026953,
  },
  {
    name: "Clancy",
    state_id: "MT",
    lat: 46.4493,
    lng: -112.0029,
    id: 1840017385,
  },
  {
    name: "Ida Grove",
    state_id: "IA",
    lat: 42.3439,
    lng: -95.4732,
    id: 1840006957,
  },
  {
    name: "Lisbon",
    state_id: "ND",
    lat: 46.4385,
    lng: -97.6841,
    id: 1840000227,
  },
  {
    name: "Yorktown",
    state_id: "TX",
    lat: 28.9829,
    lng: -97.505,
    id: 1840022243,
  },
  {
    name: "West Branch",
    state_id: "MI",
    lat: 44.2736,
    lng: -84.2362,
    id: 1840000317,
  },
  {
    name: "Hinckley",
    state_id: "IL",
    lat: 41.7714,
    lng: -88.6397,
    id: 1840011337,
  },
  {
    name: "Snow Hill",
    state_id: "MD",
    lat: 38.1654,
    lng: -75.3968,
    id: 1840006260,
  },
  {
    name: "McDonald",
    state_id: "PA",
    lat: 40.3701,
    lng: -80.2323,
    id: 1840001353,
  },
  {
    name: "Bellevue",
    state_id: "IL",
    lat: 40.6877,
    lng: -89.6732,
    id: 1840011921,
  },
  {
    name: "Newburgh Heights",
    state_id: "OH",
    lat: 41.4509,
    lng: -81.6627,
    id: 1840003399,
  },
  {
    name: "Bellport",
    state_id: "NY",
    lat: 40.7547,
    lng: -72.9423,
    id: 1840005144,
  },
  {
    name: "Veedersburg",
    state_id: "IN",
    lat: 40.114,
    lng: -87.2568,
    id: 1840010466,
  },
  {
    name: "Lawai",
    state_id: "HI",
    lat: 21.9203,
    lng: -159.5006,
    id: 1840029518,
  },
  {
    name: "Raven",
    state_id: "VA",
    lat: 37.0937,
    lng: -81.8601,
    id: 1840006450,
  },
  {
    name: "St. Croix Falls",
    state_id: "WI",
    lat: 45.41,
    lng: -92.6268,
    id: 1840002058,
  },
  {
    name: "West End",
    state_id: "NY",
    lat: 42.4667,
    lng: -75.0969,
    id: 1840034066,
  },
  {
    name: "Cherokee",
    state_id: "NC",
    lat: 35.486,
    lng: -83.3011,
    id: 1840026949,
  },
  {
    name: "Hanscom AFB",
    state_id: "MA",
    lat: 42.4582,
    lng: -71.2793,
    id: 1840043425,
  },
  {
    name: "Troup",
    state_id: "TX",
    lat: 32.145,
    lng: -95.1229,
    id: 1840022075,
  },
  {
    name: "Hopwood",
    state_id: "PA",
    lat: 39.8773,
    lng: -79.7017,
    id: 1840005543,
  },
  {
    name: "Esko",
    state_id: "MN",
    lat: 46.71,
    lng: -92.3721,
    id: 1840025266,
  },
  {
    name: "Pearson",
    state_id: "GA",
    lat: 31.297,
    lng: -82.8544,
    id: 1840014971,
  },
  {
    name: "Ninety Six",
    state_id: "SC",
    lat: 34.1696,
    lng: -82.024,
    id: 1840016675,
  },
  {
    name: "Aquebogue",
    state_id: "NY",
    lat: 40.9425,
    lng: -72.6149,
    id: 1840005015,
  },
  {
    name: "East McKeesport",
    state_id: "PA",
    lat: 40.3849,
    lng: -79.8073,
    id: 1840001225,
  },
  {
    name: "Afton",
    state_id: "WY",
    lat: 42.7271,
    lng: -110.9315,
    id: 1840021291,
  },
  {
    name: "South Deerfield",
    state_id: "MA",
    lat: 42.4795,
    lng: -72.5947,
    id: 1840004518,
  },
  {
    name: "Gotha",
    state_id: "FL",
    lat: 28.5307,
    lng: -81.5191,
    id: 1840014081,
  },
  {
    name: "Halstead",
    state_id: "KS",
    lat: 37.9999,
    lng: -97.5102,
    id: 1840001671,
  },
  {
    name: "Lake Odessa",
    state_id: "MI",
    lat: 42.7824,
    lng: -85.1374,
    id: 1840011016,
  },
  {
    name: "North Beach",
    state_id: "MD",
    lat: 38.708,
    lng: -76.5347,
    id: 1840006152,
  },
  {
    name: "Erath",
    state_id: "LA",
    lat: 29.9587,
    lng: -92.0371,
    id: 1840017211,
  },
  {
    name: "Little River-Academy",
    state_id: "TX",
    lat: 30.9876,
    lng: -97.3531,
    id: 1840003907,
  },
  {
    name: "Mountain Lake",
    state_id: "MN",
    lat: 43.9414,
    lng: -94.9273,
    id: 1840007917,
  },
  {
    name: "Homosassa",
    state_id: "FL",
    lat: 28.7849,
    lng: -82.6079,
    id: 1840014059,
  },
  {
    name: "Rafael González",
    state_id: "PR",
    lat: 18.4264,
    lng: -66.7873,
    id: 1630035489,
  },
  {
    name: "Creola",
    state_id: "AL",
    lat: 30.8903,
    lng: -88.01,
    id: 1840014988,
  },
  {
    name: "Fairview-Ferndale",
    state_id: "PA",
    lat: 40.7803,
    lng: -76.5754,
    id: 1840073577,
  },
  {
    name: "Washburn",
    state_id: "WI",
    lat: 46.6769,
    lng: -90.9017,
    id: 1840003913,
  },
  {
    name: "Banks",
    state_id: "OR",
    lat: 45.6148,
    lng: -123.1064,
    id: 1840018555,
  },
  {
    name: "Ocean Gate",
    state_id: "NJ",
    lat: 39.9268,
    lng: -74.135,
    id: 1840001493,
  },
  {
    name: "North Corbin",
    state_id: "KY",
    lat: 36.9649,
    lng: -84.0973,
    id: 1840013256,
  },
  {
    name: "Estill Springs",
    state_id: "TN",
    lat: 35.2753,
    lng: -86.1395,
    id: 1840016420,
  },
  {
    name: "Gwinn",
    state_id: "MI",
    lat: 46.2913,
    lng: -87.4372,
    id: 1840006567,
  },
  {
    name: "Seabrook Farms",
    state_id: "NJ",
    lat: 39.5015,
    lng: -75.219,
    id: 1840005753,
  },
  {
    name: "West Hill",
    state_id: "OH",
    lat: 41.23,
    lng: -80.5269,
    id: 1840034224,
  },
  {
    name: "Eddington",
    state_id: "PA",
    lat: 40.0865,
    lng: -74.9445,
    id: 1840034945,
  },
  {
    name: "Laurel Hollow",
    state_id: "NY",
    lat: 40.8541,
    lng: -73.4755,
    id: 1840005302,
  },
  {
    name: "Rouse",
    state_id: "CA",
    lat: 37.6196,
    lng: -121.0084,
    id: 1840042936,
  },
  {
    name: "Flying Hills",
    state_id: "PA",
    lat: 40.2787,
    lng: -75.9164,
    id: 1840035423,
  },
  {
    name: "Star City",
    state_id: "AR",
    lat: 33.9408,
    lng: -91.84,
    id: 1840015611,
  },
  {
    name: "Bodfish",
    state_id: "CA",
    lat: 35.5765,
    lng: -118.4764,
    id: 1840017810,
  },
  {
    name: "Sea Isle City",
    state_id: "NJ",
    lat: 39.1523,
    lng: -74.6976,
    id: 1840001596,
  },
  {
    name: "Cuba City",
    state_id: "WI",
    lat: 42.6036,
    lng: -90.4316,
    id: 1840002974,
  },
  {
    name: "Hughesville",
    state_id: "PA",
    lat: 41.239,
    lng: -76.7254,
    id: 1840000629,
  },
  {
    name: "Kahoka",
    state_id: "MO",
    lat: 40.4236,
    lng: -91.7187,
    id: 1840008343,
  },
  {
    name: "Oakwood Hills",
    state_id: "IL",
    lat: 42.2478,
    lng: -88.2401,
    id: 1840011141,
  },
  {
    name: "Jonestown",
    state_id: "PA",
    lat: 40.4129,
    lng: -76.4807,
    id: 1840001346,
  },
  {
    name: "Volga",
    state_id: "SD",
    lat: 44.3232,
    lng: -96.9223,
    id: 1840002504,
  },
  {
    name: "Pennville",
    state_id: "PA",
    lat: 39.788,
    lng: -76.9906,
    id: 1840035148,
  },
  {
    name: "Woodlands",
    state_id: "CA",
    lat: 35.0289,
    lng: -120.5524,
    id: 1840038491,
  },
  {
    name: "Rankin",
    state_id: "PA",
    lat: 40.411,
    lng: -79.8811,
    id: 1840001257,
  },
  {
    name: "Pojoaque",
    state_id: "NM",
    lat: 35.8962,
    lng: -106.0105,
    id: 1840019101,
  },
  {
    name: "North York",
    state_id: "PA",
    lat: 39.978,
    lng: -76.7309,
    id: 1840001459,
  },
  {
    name: "Beresford",
    state_id: "SD",
    lat: 43.0797,
    lng: -96.7802,
    id: 1840003079,
  },
  {
    name: "Lexington",
    state_id: "IL",
    lat: 40.647,
    lng: -88.7841,
    id: 1840008326,
  },
  {
    name: "Jarales",
    state_id: "NM",
    lat: 34.6147,
    lng: -106.7631,
    id: 1840017904,
  },
  {
    name: "Hurlock",
    state_id: "MD",
    lat: 38.6268,
    lng: -75.864,
    id: 1840006164,
  },
  {
    name: "Mannington",
    state_id: "WV",
    lat: 39.5274,
    lng: -80.3409,
    id: 1840005733,
  },
  {
    name: "Columbia City",
    state_id: "OR",
    lat: 45.895,
    lng: -122.8115,
    id: 1840018513,
  },
  {
    name: "Vinton",
    state_id: "TX",
    lat: 31.9597,
    lng: -106.5935,
    id: 1840023112,
  },
  {
    name: "Leipsic",
    state_id: "OH",
    lat: 41.1096,
    lng: -83.9674,
    id: 1840011749,
  },
  {
    name: "Hamlin",
    state_id: "TX",
    lat: 32.8898,
    lng: -100.1329,
    id: 1840020726,
  },
  {
    name: "Tyler Run",
    state_id: "PA",
    lat: 39.9304,
    lng: -76.7011,
    id: 1840040532,
  },
  {
    name: "Kiefer",
    state_id: "OK",
    lat: 35.9439,
    lng: -96.0527,
    id: 1840021694,
  },
  {
    name: "Pine Level",
    state_id: "NC",
    lat: 35.503,
    lng: -78.2494,
    id: 1840016293,
  },
  {
    name: "Kemah",
    state_id: "TX",
    lat: 29.5307,
    lng: -95.0194,
    id: 1840019666,
  },
  {
    name: "Haynesville",
    state_id: "LA",
    lat: 32.9667,
    lng: -93.1376,
    id: 1840015772,
  },
  {
    name: "East Cleveland",
    state_id: "TN",
    lat: 35.1525,
    lng: -84.8541,
    id: 1840036031,
  },
  {
    name: "Mangonia Park",
    state_id: "FL",
    lat: 26.7586,
    lng: -80.0761,
    id: 1840017262,
  },
  {
    name: "South Temple",
    state_id: "PA",
    lat: 40.399,
    lng: -75.9224,
    id: 1840035203,
  },
  {
    name: "Valencia",
    state_id: "NM",
    lat: 34.8053,
    lng: -106.6869,
    id: 1840019193,
  },
  {
    name: "Easton",
    state_id: "CA",
    lat: 36.6524,
    lng: -119.7908,
    id: 1840017633,
  },
  {
    name: "Southwest Greensburg",
    state_id: "PA",
    lat: 40.292,
    lng: -79.5477,
    id: 1840001161,
  },
  {
    name: "Jemez Pueblo",
    state_id: "NM",
    lat: 35.6068,
    lng: -106.7332,
    id: 1840033696,
  },
  {
    name: "Sigourney",
    state_id: "IA",
    lat: 41.3341,
    lng: -92.2045,
    id: 1840009284,
  },
  {
    name: "Woodville",
    state_id: "OH",
    lat: 41.451,
    lng: -83.364,
    id: 1840011602,
  },
  {
    name: "Troy",
    state_id: "TX",
    lat: 31.2,
    lng: -97.3019,
    id: 1840022155,
  },
  {
    name: "Walla Walla East",
    state_id: "WA",
    lat: 46.0517,
    lng: -118.3024,
    id: 1840074693,
  },
  {
    name: "Sparks",
    state_id: "GA",
    lat: 31.1693,
    lng: -83.4405,
    id: 1840018187,
  },
  {
    name: "Sharpsburg",
    state_id: "NC",
    lat: 35.8663,
    lng: -77.8306,
    id: 1840017746,
  },
  {
    name: "Shelby",
    state_id: "MI",
    lat: 43.6114,
    lng: -86.3648,
    id: 1840010959,
  },
  {
    name: "Memphis",
    state_id: "TX",
    lat: 34.7268,
    lng: -100.5417,
    id: 1840020523,
  },
  {
    name: "Hillsboro Beach",
    state_id: "FL",
    lat: 26.2837,
    lng: -80.0796,
    id: 1840017270,
  },
  {
    name: "El Mangó",
    state_id: "PR",
    lat: 18.2345,
    lng: -65.8751,
    id: 1630035662,
  },
  {
    name: "Ellis",
    state_id: "KS",
    lat: 38.9346,
    lng: -99.5568,
    id: 1840001627,
  },
  {
    name: "Rainier",
    state_id: "OR",
    lat: 46.0768,
    lng: -122.9404,
    id: 1840019908,
  },
  {
    name: "Seeley",
    state_id: "CA",
    lat: 32.7899,
    lng: -115.6842,
    id: 1840019376,
  },
  {
    name: "St. Paris",
    state_id: "OH",
    lat: 40.127,
    lng: -83.9644,
    id: 1840012333,
  },
  {
    name: "Crow Agency",
    state_id: "MT",
    lat: 45.6026,
    lng: -107.4591,
    id: 1840017405,
  },
  {
    name: "Seymour",
    state_id: "MO",
    lat: 37.1485,
    lng: -92.7694,
    id: 1840009896,
  },
  {
    name: "Hildebran",
    state_id: "NC",
    lat: 35.7181,
    lng: -81.4211,
    id: 1840016230,
  },
  {
    name: "Lathrop",
    state_id: "MO",
    lat: 39.5512,
    lng: -94.3286,
    id: 1840008445,
  },
  {
    name: "Collinsville",
    state_id: "AL",
    lat: 34.267,
    lng: -85.8652,
    id: 1840016553,
  },
  {
    name: "Auberry",
    state_id: "CA",
    lat: 37.0755,
    lng: -119.4901,
    id: 1840017628,
  },
  {
    name: "Tonopah",
    state_id: "NV",
    lat: 38.0996,
    lng: -117.2482,
    id: 1840018822,
  },
  {
    name: "El Valle de Arroyo Seco",
    state_id: "NM",
    lat: 35.9526,
    lng: -106.0262,
    id: 1840033711,
  },
  {
    name: "Arcanum",
    state_id: "OH",
    lat: 39.9923,
    lng: -84.5543,
    id: 1840012245,
  },
  {
    name: "Roman Forest",
    state_id: "TX",
    lat: 30.1813,
    lng: -95.1548,
    id: 1840022178,
  },
  {
    name: "Kechi",
    state_id: "KS",
    lat: 37.8234,
    lng: -97.289,
    id: 1840008707,
  },
  {
    name: "Pelican Bay",
    state_id: "TX",
    lat: 32.9227,
    lng: -97.5189,
    id: 1840020704,
  },
  {
    name: "Eupora",
    state_id: "MS",
    lat: 33.5436,
    lng: -89.2766,
    id: 1840013746,
  },
  {
    name: "Springfield",
    state_id: "MN",
    lat: 44.2372,
    lng: -94.9819,
    id: 1840008971,
  },
  {
    name: "Goliad",
    state_id: "TX",
    lat: 28.6708,
    lng: -97.3916,
    id: 1840020993,
  },
  {
    name: "Copeland",
    state_id: "OK",
    lat: 36.6571,
    lng: -94.8204,
    id: 1840034532,
  },
  {
    name: "Level Plains",
    state_id: "AL",
    lat: 31.3071,
    lng: -85.7687,
    id: 1840017083,
  },
  {
    name: "Albany",
    state_id: "KY",
    lat: 36.6906,
    lng: -85.1353,
    id: 1840013289,
  },
  {
    name: "Flora",
    state_id: "IN",
    lat: 40.5455,
    lng: -86.5226,
    id: 1840010381,
  },
  {
    name: "Eyota",
    state_id: "MN",
    lat: 43.9883,
    lng: -92.2321,
    id: 1840007916,
  },
  {
    name: "Napavine",
    state_id: "WA",
    lat: 46.5842,
    lng: -122.9006,
    id: 1840019884,
  },
  {
    name: "Shorewood Hills",
    state_id: "WI",
    lat: 43.0788,
    lng: -89.4467,
    id: 1840002900,
  },
  {
    name: "Laton",
    state_id: "CA",
    lat: 36.4338,
    lng: -119.6916,
    id: 1840017637,
  },
  {
    name: "Girard",
    state_id: "IL",
    lat: 39.4466,
    lng: -89.7821,
    id: 1840008490,
  },
  {
    name: "Jonesville",
    state_id: "LA",
    lat: 31.6238,
    lng: -91.83,
    id: 1840017052,
  },
  {
    name: "Wapello",
    state_id: "IA",
    lat: 41.1771,
    lng: -91.1881,
    id: 1840010282,
  },
  {
    name: "Choctaw Lake",
    state_id: "OH",
    lat: 39.9609,
    lng: -83.4874,
    id: 1840034381,
  },
  {
    name: "Edgerton",
    state_id: "OH",
    lat: 41.4494,
    lng: -84.75,
    id: 1840011520,
  },
  {
    name: "Port Allegany",
    state_id: "PA",
    lat: 41.8149,
    lng: -78.2768,
    id: 1840000528,
  },
  {
    name: "Taft Heights",
    state_id: "CA",
    lat: 35.1337,
    lng: -119.4711,
    id: 1840019150,
  },
  {
    name: "Valley View",
    state_id: "OH",
    lat: 41.3832,
    lng: -81.6067,
    id: 1840003417,
  },
  {
    name: "Fern Acres",
    state_id: "HI",
    lat: 19.5082,
    lng: -155.0785,
    id: 1840029570,
  },
  {
    name: "Fránquez",
    state_id: "PR",
    lat: 18.3401,
    lng: -66.4276,
    id: 1630035480,
  },
  {
    name: "Enterprise",
    state_id: "OR",
    lat: 45.4258,
    lng: -117.2789,
    id: 1840019919,
  },
  {
    name: "South Greensburg",
    state_id: "PA",
    lat: 40.2783,
    lng: -79.5473,
    id: 1840001160,
  },
  {
    name: "Wickerham Manor-Fisher",
    state_id: "PA",
    lat: 40.1771,
    lng: -79.9079,
    id: 1840073738,
  },
  {
    name: "Bethlehem Village",
    state_id: "CT",
    lat: 41.6401,
    lng: -73.2031,
    id: 1840073556,
  },
  {
    name: "Judsonia",
    state_id: "AR",
    lat: 35.277,
    lng: -91.6411,
    id: 1840014554,
  },
  {
    name: "Wedgefield",
    state_id: "SC",
    lat: 33.8829,
    lng: -80.5157,
    id: 1840013670,
  },
  {
    name: "Ossun",
    state_id: "LA",
    lat: 30.2834,
    lng: -92.107,
    id: 1840025124,
  },
  {
    name: "Cooper",
    state_id: "TX",
    lat: 33.3716,
    lng: -95.6912,
    id: 1840019356,
  },
  {
    name: "Burnham",
    state_id: "PA",
    lat: 40.6353,
    lng: -77.5632,
    id: 1840003589,
  },
  {
    name: "Salem",
    state_id: "AR",
    lat: 34.631,
    lng: -92.5608,
    id: 1840150803,
  },
  {
    name: "Trail Creek",
    state_id: "IN",
    lat: 41.6963,
    lng: -86.8555,
    id: 1840010201,
  },
  {
    name: "South Roxana",
    state_id: "IL",
    lat: 38.812,
    lng: -90.0611,
    id: 1840012805,
  },
  {
    name: "Martorell",
    state_id: "PR",
    lat: 18.0729,
    lng: -65.8976,
    id: 1630023679,
  },
  {
    name: "Preston",
    state_id: "TX",
    lat: 33.8671,
    lng: -96.6596,
    id: 1840025870,
  },
  {
    name: "Roanoke",
    state_id: "IL",
    lat: 40.7969,
    lng: -89.2023,
    id: 1840011944,
  },
  {
    name: "Leechburg",
    state_id: "PA",
    lat: 40.6303,
    lng: -79.6022,
    id: 1840003520,
  },
  {
    name: "Saugerties South",
    state_id: "NY",
    lat: 42.0614,
    lng: -73.9506,
    id: 1840035216,
  },
  {
    name: "Bertsch-Oceanview",
    state_id: "CA",
    lat: 41.7524,
    lng: -124.1594,
    id: 1840036786,
  },
  {
    name: "Belmont",
    state_id: "MS",
    lat: 34.5046,
    lng: -88.208,
    id: 1840015516,
  },
  {
    name: "Tome",
    state_id: "NM",
    lat: 34.7397,
    lng: -106.723,
    id: 1840026966,
  },
  {
    name: "Beverly Hills",
    state_id: "TX",
    lat: 31.5224,
    lng: -97.1563,
    id: 1840019532,
  },
  {
    name: "Kenilworth",
    state_id: "PA",
    lat: 40.2242,
    lng: -75.6418,
    id: 1840035042,
  },
  {
    name: "Willow",
    state_id: "AK",
    lat: 61.8157,
    lng: -149.753,
    id: 1840023377,
  },
  {
    name: "Carlisle",
    state_id: "KY",
    lat: 38.3162,
    lng: -84.0333,
    id: 1840014290,
  },
  {
    name: "Mason Neck",
    state_id: "VA",
    lat: 38.6556,
    lng: -77.1819,
    id: 1840041817,
  },
  {
    name: "Piperton",
    state_id: "TN",
    lat: 35.0512,
    lng: -89.6091,
    id: 1840014596,
  },
  {
    name: "Liberty",
    state_id: "IN",
    lat: 39.6349,
    lng: -84.926,
    id: 1840013860,
  },
  {
    name: "Aitkin",
    state_id: "MN",
    lat: 46.5289,
    lng: -93.7067,
    id: 1840006638,
  },
  {
    name: "Sussex",
    state_id: "NJ",
    lat: 41.2095,
    lng: -74.6079,
    id: 1840000789,
  },
  {
    name: "Lake Mills",
    state_id: "IA",
    lat: 43.4169,
    lng: -93.5321,
    id: 1840007954,
  },
  {
    name: "Batavia",
    state_id: "OH",
    lat: 39.0815,
    lng: -84.1718,
    id: 1840012704,
  },
  {
    name: "Mattawan",
    state_id: "MI",
    lat: 42.2165,
    lng: -85.7858,
    id: 1840011207,
  },
  {
    name: "Shavertown",
    state_id: "PA",
    lat: 41.3188,
    lng: -75.9405,
    id: 1840026334,
  },
  {
    name: "Carrington",
    state_id: "ND",
    lat: 47.4521,
    lng: -99.1304,
    id: 1840000138,
  },
  {
    name: "Ogden",
    state_id: "IA",
    lat: 42.0395,
    lng: -94.0283,
    id: 1840008120,
  },
  {
    name: "South Bloomfield",
    state_id: "OH",
    lat: 39.717,
    lng: -82.9931,
    id: 1840012558,
  },
  {
    name: "Churubusco",
    state_id: "IN",
    lat: 41.231,
    lng: -85.3197,
    id: 1840009318,
  },
  {
    name: "Hayden",
    state_id: "CO",
    lat: 40.4851,
    lng: -107.2391,
    id: 1840021375,
  },
  {
    name: "Springerville",
    state_id: "AZ",
    lat: 34.156,
    lng: -109.2989,
    id: 1840022616,
  },
  {
    name: "Epworth",
    state_id: "IA",
    lat: 42.4465,
    lng: -90.9313,
    id: 1840008045,
  },
  {
    name: "Grill",
    state_id: "PA",
    lat: 40.3,
    lng: -75.9344,
    id: 1840034998,
  },
  {
    name: "Yorklyn",
    state_id: "PA",
    lat: 39.9929,
    lng: -76.6428,
    id: 1840035301,
  },
  {
    name: "Aberdeen",
    state_id: "ID",
    lat: 42.9441,
    lng: -112.8384,
    id: 1840018647,
  },
  {
    name: "Cave Junction",
    state_id: "OR",
    lat: 42.1672,
    lng: -123.6474,
    id: 1840018681,
  },
  {
    name: "Maunawili",
    state_id: "HI",
    lat: 21.3667,
    lng: -157.7713,
    id: 1840029526,
  },
  {
    name: "Varnville",
    state_id: "SC",
    lat: 32.8521,
    lng: -81.0802,
    id: 1840016928,
  },
  {
    name: "Moorefield Station",
    state_id: "VA",
    lat: 38.9981,
    lng: -77.4971,
    id: 1840041846,
  },
  {
    name: "Battle Ground",
    state_id: "IN",
    lat: 40.5076,
    lng: -86.8527,
    id: 1840009424,
  },
  {
    name: "Minonk",
    state_id: "IL",
    lat: 40.9104,
    lng: -89.0381,
    id: 1840008295,
  },
  {
    name: "Slayton",
    state_id: "MN",
    lat: 43.9902,
    lng: -95.7578,
    id: 1840008982,
  },
  {
    name: "Loveland Park",
    state_id: "OH",
    lat: 39.2943,
    lng: -84.2636,
    id: 1840034078,
  },
  {
    name: "Livingston",
    state_id: "LA",
    lat: 30.4953,
    lng: -90.7467,
    id: 1840017184,
  },
  {
    name: "Camden",
    state_id: "OH",
    lat: 39.6374,
    lng: -84.6445,
    id: 1840010585,
  },
  {
    name: "Badin",
    state_id: "NC",
    lat: 35.4072,
    lng: -80.1184,
    id: 1840015438,
  },
  {
    name: "Lofall",
    state_id: "WA",
    lat: 47.8113,
    lng: -122.6564,
    id: 1840023798,
  },
  {
    name: "Coupeville",
    state_id: "WA",
    lat: 48.2172,
    lng: -122.6775,
    id: 1840021075,
  },
  {
    name: "Houserville",
    state_id: "PA",
    lat: 40.8265,
    lng: -77.8226,
    id: 1840035023,
  },
  {
    name: "Arcade",
    state_id: "GA",
    lat: 34.0694,
    lng: -83.5399,
    id: 1840013650,
  },
  {
    name: "Coleraine",
    state_id: "MN",
    lat: 47.2638,
    lng: -93.4524,
    id: 1840006611,
  },
  {
    name: "Perry Park",
    state_id: "CO",
    lat: 39.2597,
    lng: -104.9825,
    id: 1840028567,
  },
  {
    name: "Hodgkins",
    state_id: "IL",
    lat: 41.7659,
    lng: -87.8613,
    id: 1840011262,
  },
  {
    name: "West Falmouth",
    state_id: "MA",
    lat: 41.6011,
    lng: -70.6364,
    id: 1840003245,
  },
  {
    name: "Atkins",
    state_id: "IA",
    lat: 41.9943,
    lng: -91.8585,
    id: 1840006982,
  },
  {
    name: "McColl",
    state_id: "SC",
    lat: 34.6665,
    lng: -79.5447,
    id: 1840016586,
  },
  {
    name: "San Pasqual",
    state_id: "CA",
    lat: 34.1393,
    lng: -118.1027,
    id: 1840028271,
  },
  {
    name: "Devens",
    state_id: "MA",
    lat: 42.5438,
    lng: -71.6147,
    id: 1840026192,
  },
  {
    name: "Custer",
    state_id: "SD",
    lat: 43.7668,
    lng: -103.6005,
    id: 1840000337,
  },
  {
    name: "Monticello",
    state_id: "UT",
    lat: 37.8684,
    lng: -109.3384,
    id: 1840020257,
  },
  {
    name: "Mill City",
    state_id: "OR",
    lat: 44.7519,
    lng: -122.4779,
    id: 1840019993,
  },
  {
    name: "Carterville",
    state_id: "MO",
    lat: 37.1466,
    lng: -94.4388,
    id: 1840007628,
  },
  {
    name: "Caney",
    state_id: "KS",
    lat: 37.014,
    lng: -95.9316,
    id: 1840001705,
  },
  {
    name: "Monte Alto",
    state_id: "TX",
    lat: 26.3744,
    lng: -97.9727,
    id: 1840019727,
  },
  {
    name: "Coto Laurel",
    state_id: "PR",
    lat: 18.0491,
    lng: -66.5517,
    id: 1630035656,
  },
  {
    name: "Canyonville",
    state_id: "OR",
    lat: 42.9274,
    lng: -123.2781,
    id: 1840018639,
  },
  {
    name: "Somerset",
    state_id: "TX",
    lat: 29.2285,
    lng: -98.6567,
    id: 1840022223,
  },
  {
    name: "Trexlertown",
    state_id: "PA",
    lat: 40.5523,
    lng: -75.6039,
    id: 1840026410,
  },
  {
    name: "Waretown",
    state_id: "NJ",
    lat: 39.7898,
    lng: -74.1925,
    id: 1840005537,
  },
  {
    name: "Yanceyville",
    state_id: "NC",
    lat: 36.4111,
    lng: -79.3401,
    id: 1840016058,
  },
  {
    name: "Mart",
    state_id: "TX",
    lat: 31.5421,
    lng: -96.8299,
    id: 1840020815,
  },
  {
    name: "Sumner",
    state_id: "IA",
    lat: 42.8498,
    lng: -92.0971,
    id: 1840000403,
  },
  {
    name: "Lebanon Junction",
    state_id: "KY",
    lat: 37.8358,
    lng: -85.7243,
    id: 1840014326,
  },
  {
    name: "Groveville",
    state_id: "NJ",
    lat: 40.1678,
    lng: -74.6515,
    id: 1840024323,
  },
  {
    name: "Clyde",
    state_id: "NY",
    lat: 43.0839,
    lng: -76.8707,
    id: 1840004298,
  },
  {
    name: "Mowbray Mountain",
    state_id: "TN",
    lat: 35.2751,
    lng: -85.2225,
    id: 1840040578,
  },
  {
    name: "Quail Creek",
    state_id: "TX",
    lat: 28.7773,
    lng: -97.0848,
    id: 1840026048,
  },
  {
    name: "Syracuse",
    state_id: "NE",
    lat: 40.6635,
    lng: -96.1827,
    id: 1840009400,
  },
  {
    name: "Golden Meadow",
    state_id: "LA",
    lat: 29.3876,
    lng: -90.2739,
    id: 1840017217,
  },
  {
    name: "Mokuleia",
    state_id: "HI",
    lat: 21.5775,
    lng: -158.1487,
    id: 1840029528,
  },
  {
    name: "Collinsville",
    state_id: "TX",
    lat: 33.5593,
    lng: -96.9072,
    id: 1840022934,
  },
  {
    name: "Davisboro",
    state_id: "GA",
    lat: 32.9826,
    lng: -82.6032,
    id: 1840014850,
  },
  {
    name: "Kouts",
    state_id: "IN",
    lat: 41.3172,
    lng: -87.0269,
    id: 1840009269,
  },
  {
    name: "Fern Prairie",
    state_id: "WA",
    lat: 45.637,
    lng: -122.3963,
    id: 1840023890,
  },
  {
    name: "Willard",
    state_id: "UT",
    lat: 41.4146,
    lng: -112.0446,
    id: 1840021327,
  },
  {
    name: "Harbor",
    state_id: "OR",
    lat: 42.0386,
    lng: -124.2516,
    id: 1840017458,
  },
  {
    name: "Bowmansville",
    state_id: "PA",
    lat: 40.203,
    lng: -76.0199,
    id: 1840024330,
  },
  {
    name: "Ogden",
    state_id: "KS",
    lat: 39.1127,
    lng: -96.7028,
    id: 1840008479,
  },
  {
    name: "Carbon Cliff",
    state_id: "IL",
    lat: 41.4981,
    lng: -90.3917,
    id: 1840010191,
  },
  {
    name: "Granite",
    state_id: "OK",
    lat: 34.9561,
    lng: -99.3703,
    id: 1840021828,
  },
  {
    name: "Harlem Heights",
    state_id: "FL",
    lat: 26.516,
    lng: -81.9293,
    id: 1840028985,
  },
  {
    name: "Stewart Manor",
    state_id: "NY",
    lat: 40.7203,
    lng: -73.6853,
    id: 1840005328,
  },
  {
    name: "Chenoweth",
    state_id: "OR",
    lat: 45.6206,
    lng: -121.2363,
    id: 1840017417,
  },
  {
    name: "Ocean Ridge",
    state_id: "FL",
    lat: 26.5287,
    lng: -80.0499,
    id: 1840017266,
  },
  {
    name: "Northwood",
    state_id: "IA",
    lat: 43.4441,
    lng: -93.2166,
    id: 1840007965,
  },
  {
    name: "Britt",
    state_id: "IA",
    lat: 43.0975,
    lng: -93.8032,
    id: 1840004340,
  },
  {
    name: "Manchester",
    state_id: "OH",
    lat: 38.6902,
    lng: -83.6053,
    id: 1840012769,
  },
  {
    name: "Silver Lake",
    state_id: "FL",
    lat: 28.8436,
    lng: -81.8008,
    id: 1840029074,
  },
  {
    name: "Waverly",
    state_id: "VA",
    lat: 37.034,
    lng: -77.0956,
    id: 1840006500,
  },
  {
    name: "Quincy",
    state_id: "CA",
    lat: 39.931,
    lng: -120.9548,
    id: 1840018768,
  },
  {
    name: "Limon",
    state_id: "CO",
    lat: 39.2653,
    lng: -103.6855,
    id: 1840021453,
  },
  {
    name: "Stevenson",
    state_id: "AL",
    lat: 34.8487,
    lng: -85.8437,
    id: 1840016526,
  },
  {
    name: "Weyers Cave",
    state_id: "VA",
    lat: 38.2844,
    lng: -78.9125,
    id: 1840006249,
  },
  {
    name: "Colby",
    state_id: "WI",
    lat: 44.9107,
    lng: -90.3159,
    id: 1840002251,
  },
  {
    name: "Three Rivers",
    state_id: "TX",
    lat: 28.4668,
    lng: -98.1784,
    id: 1840022246,
  },
  {
    name: "Shelby",
    state_id: "MS",
    lat: 33.9508,
    lng: -90.7653,
    id: 1840015629,
  },
  {
    name: "Cricket",
    state_id: "NC",
    lat: 36.1688,
    lng: -81.1933,
    id: 1840013336,
  },
  {
    name: "Frenchtown",
    state_id: "MT",
    lat: 47.0287,
    lng: -114.2461,
    id: 1840017350,
  },
  {
    name: "Star City",
    state_id: "WV",
    lat: 39.6587,
    lng: -79.9868,
    id: 1840005650,
  },
  {
    name: "Augusta",
    state_id: "AR",
    lat: 35.2866,
    lng: -91.3614,
    id: 1840013461,
  },
  {
    name: "Hoyt Lakes",
    state_id: "MN",
    lat: 47.5598,
    lng: -92.1157,
    id: 1840006595,
  },
  {
    name: "Sparta",
    state_id: "MO",
    lat: 37.002,
    lng: -93.0846,
    id: 1840009925,
  },
  {
    name: "Baraga",
    state_id: "MI",
    lat: 46.7766,
    lng: -88.4965,
    id: 1840010914,
  },
  {
    name: "Bull Shoals",
    state_id: "AR",
    lat: 36.3747,
    lng: -92.5903,
    id: 1840013329,
  },
  {
    name: "Inkerman",
    state_id: "PA",
    lat: 41.2968,
    lng: -75.8167,
    id: 1840035029,
  },
  {
    name: "Krebs",
    state_id: "OK",
    lat: 34.93,
    lng: -95.72,
    id: 1840020458,
  },
  {
    name: "Heritage Pines",
    state_id: "FL",
    lat: 28.4267,
    lng: -82.6254,
    id: 1840038917,
  },
  {
    name: "Ellinwood",
    state_id: "KS",
    lat: 38.3574,
    lng: -98.5837,
    id: 1840001655,
  },
  {
    name: "Warren",
    state_id: "OR",
    lat: 45.8133,
    lng: -122.8797,
    id: 1840025280,
  },
  {
    name: "Prudenville",
    state_id: "MI",
    lat: 44.3015,
    lng: -84.6652,
    id: 1840004092,
  },
  {
    name: "Goshen",
    state_id: "AR",
    lat: 36.1043,
    lng: -94.0035,
    id: 1840016153,
  },
  {
    name: "New Chicago",
    state_id: "IN",
    lat: 41.5587,
    lng: -87.2718,
    id: 1840009263,
  },
  {
    name: "Wrens",
    state_id: "GA",
    lat: 33.2069,
    lng: -82.3878,
    id: 1840015739,
  },
  {
    name: "Prophetstown",
    state_id: "IL",
    lat: 41.6702,
    lng: -89.9349,
    id: 1840009201,
  },
  {
    name: "Mars Hill",
    state_id: "NC",
    lat: 35.8281,
    lng: -82.5484,
    id: 1840016210,
  },
  {
    name: "Mount Olive",
    state_id: "IL",
    lat: 39.0726,
    lng: -89.7277,
    id: 1840008491,
  },
  {
    name: "Brookdale",
    state_id: "CA",
    lat: 37.1058,
    lng: -122.1106,
    id: 1840022569,
  },
  {
    name: "Lima",
    state_id: "PA",
    lat: 39.9194,
    lng: -75.4417,
    id: 1840035060,
  },
  {
    name: "Fisher",
    state_id: "IL",
    lat: 40.3157,
    lng: -88.3503,
    id: 1840012224,
  },
  {
    name: "Zwolle",
    state_id: "LA",
    lat: 31.6398,
    lng: -93.6412,
    id: 1840017064,
  },
  {
    name: "Shippensburg University",
    state_id: "PA",
    lat: 40.0618,
    lng: -77.5228,
    id: 1840043364,
  },
  {
    name: "First Mesa",
    state_id: "AZ",
    lat: 35.8389,
    lng: -110.3665,
    id: 1840027978,
  },
  {
    name: "Farmer City",
    state_id: "IL",
    lat: 40.248,
    lng: -88.6424,
    id: 1840008376,
  },
  {
    name: "Blooming Prairie",
    state_id: "MN",
    lat: 43.8683,
    lng: -93.0552,
    id: 1840006805,
  },
  {
    name: "West Carthage",
    state_id: "NY",
    lat: 43.973,
    lng: -75.6219,
    id: 1840004118,
  },
  {
    name: "Huntington",
    state_id: "UT",
    lat: 39.3301,
    lng: -110.9628,
    id: 1840020217,
  },
  {
    name: "Albany",
    state_id: "TX",
    lat: 32.7272,
    lng: -99.2956,
    id: 1840019447,
  },
  {
    name: "Stockton",
    state_id: "MO",
    lat: 37.697,
    lng: -93.796,
    id: 1840009873,
  },
  {
    name: "Patrick Springs",
    state_id: "VA",
    lat: 36.6344,
    lng: -80.1985,
    id: 1840006548,
  },
  {
    name: "Bellville",
    state_id: "OH",
    lat: 40.6292,
    lng: -82.5238,
    id: 1840034242,
  },
  {
    name: "Prairie Grove",
    state_id: "IL",
    lat: 42.2772,
    lng: -88.2696,
    id: 1840011137,
  },
  {
    name: "North Puyallup",
    state_id: "WA",
    lat: 47.2015,
    lng: -122.2743,
    id: 1840037690,
  },
  {
    name: "Mayer",
    state_id: "AZ",
    lat: 34.4233,
    lng: -112.2408,
    id: 1840017848,
  },
  {
    name: "Haskell",
    state_id: "OK",
    lat: 35.8187,
    lng: -95.6805,
    id: 1840021722,
  },
  {
    name: "Rolling Fork",
    state_id: "MS",
    lat: 32.9073,
    lng: -90.8769,
    id: 1840014860,
  },
  {
    name: "La Pine",
    state_id: "OR",
    lat: 43.6866,
    lng: -121.4856,
    id: 1840020004,
  },
  {
    name: "Jenkins",
    state_id: "KY",
    lat: 37.1826,
    lng: -82.6311,
    id: 1840014385,
  },
  {
    name: "Tchula",
    state_id: "MS",
    lat: 33.1831,
    lng: -90.2232,
    id: 1840016881,
  },
  {
    name: "Parkersburg",
    state_id: "IA",
    lat: 42.5739,
    lng: -92.7785,
    id: 1840009088,
  },
  {
    name: "Hinckley",
    state_id: "MN",
    lat: 46.0122,
    lng: -92.9256,
    id: 1840006662,
  },
  {
    name: "Durham",
    state_id: "OR",
    lat: 45.3941,
    lng: -122.758,
    id: 1840018558,
  },
  {
    name: "Cave City",
    state_id: "AR",
    lat: 35.9481,
    lng: -91.5468,
    id: 1840014445,
  },
  {
    name: "Valley Hill",
    state_id: "NC",
    lat: 35.2958,
    lng: -82.4913,
    id: 1840013447,
  },
  {
    name: "Catlin",
    state_id: "IL",
    lat: 40.0675,
    lng: -87.7079,
    id: 1840010437,
  },
  {
    name: "Princeville",
    state_id: "NC",
    lat: 35.8861,
    lng: -77.5194,
    id: 1840017752,
  },
  {
    name: "Sour Lake",
    state_id: "TX",
    lat: 30.1378,
    lng: -94.4046,
    id: 1840022183,
  },
  {
    name: "Sullivan's Island",
    state_id: "SC",
    lat: 32.7684,
    lng: -79.8354,
    id: 1840035787,
  },
  {
    name: "Painted Post",
    state_id: "NY",
    lat: 42.1633,
    lng: -77.0925,
    id: 1840004551,
  },
  {
    name: "Italy",
    state_id: "TX",
    lat: 32.1858,
    lng: -96.8866,
    id: 1840022086,
  },
  {
    name: "Haslet",
    state_id: "TX",
    lat: 32.9609,
    lng: -97.3382,
    id: 1840020699,
  },
  {
    name: "Manchester Center",
    state_id: "VT",
    lat: 43.1822,
    lng: -73.0362,
    id: 1840002899,
  },
  {
    name: "Kealakekua",
    state_id: "HI",
    lat: 19.5286,
    lng: -155.9033,
    id: 1840029504,
  },
  {
    name: "Springfield",
    state_id: "SD",
    lat: 42.8625,
    lng: -97.8963,
    id: 1840003064,
  },
  {
    name: "Merlin",
    state_id: "OR",
    lat: 42.5204,
    lng: -123.4253,
    id: 1840025484,
  },
  {
    name: "Enetai",
    state_id: "WA",
    lat: 47.5885,
    lng: -122.6058,
    id: 1840023794,
  },
  {
    name: "Sheldon",
    state_id: "TX",
    lat: 29.8596,
    lng: -95.134,
    id: 1840019614,
  },
  {
    name: "Masury",
    state_id: "OH",
    lat: 41.2083,
    lng: -80.5378,
    id: 1840004901,
  },
  {
    name: "West Hurley",
    state_id: "NY",
    lat: 42.0083,
    lng: -74.1121,
    id: 1840004728,
  },
  {
    name: "Plainfield Village",
    state_id: "CT",
    lat: 41.6766,
    lng: -71.925,
    id: 1840073306,
  },
  {
    name: "Stagecoach",
    state_id: "NV",
    lat: 39.3656,
    lng: -119.3832,
    id: 1840026616,
  },
  {
    name: "Anderson",
    state_id: "MO",
    lat: 36.6533,
    lng: -94.4442,
    id: 1840007661,
  },
  {
    name: "Nettleton",
    state_id: "MS",
    lat: 34.0853,
    lng: -88.6248,
    id: 1840014754,
  },
  {
    name: "Queensland",
    state_id: "MD",
    lat: 38.8021,
    lng: -76.7839,
    id: 1840138164,
  },
  {
    name: "Kingstown",
    state_id: "MD",
    lat: 39.2036,
    lng: -76.0465,
    id: 1840005896,
  },
  {
    name: "Cecilia",
    state_id: "LA",
    lat: 30.3368,
    lng: -91.8476,
    id: 1840013959,
  },
  {
    name: "Eastport",
    state_id: "NY",
    lat: 40.8407,
    lng: -72.7251,
    id: 1840005042,
  },
  {
    name: "Doniphan",
    state_id: "MO",
    lat: 36.6234,
    lng: -90.8219,
    id: 1840007658,
  },
  {
    name: "Shadeland",
    state_id: "IN",
    lat: 40.3509,
    lng: -86.9622,
    id: 1840010431,
  },
  {
    name: "Privateer",
    state_id: "SC",
    lat: 33.8169,
    lng: -80.3947,
    id: 1840013673,
  },
  {
    name: "Scottsville",
    state_id: "NY",
    lat: 43.0221,
    lng: -77.7555,
    id: 1840004284,
  },
  {
    name: "Linden",
    state_id: "TX",
    lat: 33.011,
    lng: -94.3626,
    id: 1840020679,
  },
  {
    name: "Jefferson",
    state_id: "TX",
    lat: 32.7634,
    lng: -94.3512,
    id: 1840019449,
  },
  {
    name: "North Liberty",
    state_id: "IN",
    lat: 41.5324,
    lng: -86.428,
    id: 1840009244,
  },
  {
    name: "Englishtown",
    state_id: "NJ",
    lat: 40.2971,
    lng: -74.3607,
    id: 1840003680,
  },
  {
    name: "St. Matthews",
    state_id: "SC",
    lat: 33.664,
    lng: -80.778,
    id: 1840017999,
  },
  {
    name: "Oakboro",
    state_id: "NC",
    lat: 35.2283,
    lng: -80.3339,
    id: 1840016368,
  },
  {
    name: "Glen Gardner",
    state_id: "NJ",
    lat: 40.7004,
    lng: -74.9395,
    id: 1840003607,
  },
  {
    name: "Hampton Manor",
    state_id: "NY",
    lat: 42.6214,
    lng: -73.7279,
    id: 1840034050,
  },
  {
    name: "Ixonia",
    state_id: "WI",
    lat: 43.1396,
    lng: -88.5963,
    id: 1840004349,
  },
  {
    name: "Rockdale",
    state_id: "IL",
    lat: 41.5058,
    lng: -88.1183,
    id: 1840011488,
  },
  {
    name: "Georgetown",
    state_id: "PA",
    lat: 41.2267,
    lng: -75.8719,
    id: 1840144823,
  },
  {
    name: "St. Bonaventure",
    state_id: "NY",
    lat: 42.0806,
    lng: -78.475,
    id: 1840034060,
  },
  {
    name: "Phoenix",
    state_id: "IL",
    lat: 41.6118,
    lng: -87.6308,
    id: 1840011314,
  },
  {
    name: "Athens",
    state_id: "IL",
    lat: 39.9618,
    lng: -89.7217,
    id: 1840007262,
  },
  {
    name: "Chelsea",
    state_id: "OK",
    lat: 36.5323,
    lng: -95.4355,
    id: 1840022654,
  },
  {
    name: "Hopewell",
    state_id: "NJ",
    lat: 40.3893,
    lng: -74.7638,
    id: 1840003709,
  },
  {
    name: "Florala",
    state_id: "AL",
    lat: 31.0176,
    lng: -86.3138,
    id: 1840007209,
  },
  {
    name: "Manhattan",
    state_id: "MT",
    lat: 45.8622,
    lng: -111.3343,
    id: 1840022328,
  },
  {
    name: "Dora",
    state_id: "AL",
    lat: 33.7292,
    lng: -87.085,
    id: 1840005829,
  },
  {
    name: "Wild Peach Village",
    state_id: "TX",
    lat: 29.0811,
    lng: -95.6373,
    id: 1840019670,
  },
  {
    name: "Richmond",
    state_id: "IL",
    lat: 42.4639,
    lng: -88.3093,
    id: 1840011138,
  },
  {
    name: "Unionville",
    state_id: "GA",
    lat: 31.4363,
    lng: -83.5084,
    id: 1840029357,
  },
  {
    name: "Alta",
    state_id: "IA",
    lat: 42.6717,
    lng: -95.3045,
    id: 1840006899,
  },
  {
    name: "Camino",
    state_id: "CA",
    lat: 38.7412,
    lng: -120.6815,
    id: 1840022477,
  },
  {
    name: "Holiday Heights",
    state_id: "NJ",
    lat: 39.9396,
    lng: -74.2572,
    id: 1840033481,
  },
  {
    name: "Eudora",
    state_id: "AR",
    lat: 33.119,
    lng: -91.264,
    id: 1840013754,
  },
  {
    name: "Belington",
    state_id: "WV",
    lat: 39.022,
    lng: -79.9383,
    id: 1840005884,
  },
  {
    name: "Atlantic Beach",
    state_id: "NY",
    lat: 40.5894,
    lng: -73.7296,
    id: 1840005335,
  },
  {
    name: "Brundidge",
    state_id: "AL",
    lat: 31.7182,
    lng: -85.8177,
    id: 1840013848,
  },
  {
    name: "Calhoun Falls",
    state_id: "SC",
    lat: 34.0933,
    lng: -82.5963,
    id: 1840015571,
  },
  {
    name: "Buffalo",
    state_id: "TX",
    lat: 31.46,
    lng: -96.066,
    id: 1840019540,
  },
  {
    name: "Alorton",
    state_id: "IL",
    lat: 38.585,
    lng: -90.1139,
    id: 1840010783,
  },
  {
    name: "Saltville",
    state_id: "VA",
    lat: 36.8751,
    lng: -81.7642,
    id: 1840006516,
  },
  {
    name: "Burns Flat",
    state_id: "OK",
    lat: 35.3548,
    lng: -99.1751,
    id: 1840021780,
  },
  {
    name: "Minneapolis",
    state_id: "KS",
    lat: 39.1243,
    lng: -97.6997,
    id: 1840003824,
  },
  {
    name: "Hopewell",
    state_id: "TN",
    lat: 35.2387,
    lng: -84.9139,
    id: 1840013479,
  },
  {
    name: "Victory Lakes",
    state_id: "NJ",
    lat: 39.6305,
    lng: -74.9661,
    id: 1840005566,
  },
  {
    name: "Albion",
    state_id: "IL",
    lat: 38.3767,
    lng: -88.0579,
    id: 1840007490,
  },
  {
    name: "Union Point",
    state_id: "GA",
    lat: 33.6177,
    lng: -83.0756,
    id: 1840015686,
  },
  {
    name: "Arkoma",
    state_id: "OK",
    lat: 35.3374,
    lng: -94.4438,
    id: 1840020446,
  },
  {
    name: "Lake Dunlap",
    state_id: "TX",
    lat: 29.6683,
    lng: -98.0773,
    id: 1840026038,
  },
  {
    name: "Brooklawn",
    state_id: "NJ",
    lat: 39.8788,
    lng: -75.1207,
    id: 1840001506,
  },
  {
    name: "Charlotte",
    state_id: "TX",
    lat: 28.8593,
    lng: -98.7006,
    id: 1840019679,
  },
  {
    name: "Leslie",
    state_id: "MI",
    lat: 42.4508,
    lng: -84.4335,
    id: 1840003103,
  },
  {
    name: "Audubon",
    state_id: "IA",
    lat: 41.7181,
    lng: -94.9286,
    id: 1840007059,
  },
  {
    name: "Slocomb",
    state_id: "AL",
    lat: 31.1103,
    lng: -85.5954,
    id: 1840015884,
  },
  {
    name: "Marriott-Slaterville",
    state_id: "UT",
    lat: 41.2624,
    lng: -112.0366,
    id: 1840020133,
  },
  {
    name: "Las Carolinas",
    state_id: "PR",
    lat: 18.2539,
    lng: -66.0658,
    id: 1630035614,
  },
  {
    name: "Warson Woods",
    state_id: "MO",
    lat: 38.6069,
    lng: -90.3912,
    id: 1840010743,
  },
  {
    name: "Jobos",
    state_id: "PR",
    lat: 17.9608,
    lng: -66.1655,
    id: 1630035690,
  },
  {
    name: "Union",
    state_id: "MS",
    lat: 32.5711,
    lng: -89.1152,
    id: 1840016989,
  },
  {
    name: "St. Charles",
    state_id: "MI",
    lat: 43.2988,
    lng: -84.1476,
    id: 1840010984,
  },
  {
    name: "Ashland",
    state_id: "AL",
    lat: 33.2704,
    lng: -85.8337,
    id: 1840006738,
  },
  {
    name: "Sylvania",
    state_id: "AL",
    lat: 34.5619,
    lng: -85.8046,
    id: 1840016558,
  },
  {
    name: "Monroe",
    state_id: "IA",
    lat: 41.5189,
    lng: -93.1038,
    id: 1840008174,
  },
  {
    name: "McAlmont",
    state_id: "AR",
    lat: 34.7926,
    lng: -92.1959,
    id: 1840013538,
  },
  {
    name: "Baker",
    state_id: "MT",
    lat: 46.364,
    lng: -104.2741,
    id: 1840018482,
  },
  {
    name: "Adams",
    state_id: "WI",
    lat: 43.9554,
    lng: -89.8169,
    id: 1840002234,
  },
  {
    name: "Pierson",
    state_id: "FL",
    lat: 29.2401,
    lng: -81.4559,
    id: 1840017226,
  },
  {
    name: "Trion",
    state_id: "GA",
    lat: 34.548,
    lng: -85.3108,
    id: 1840016623,
  },
  {
    name: "Midway",
    state_id: "KY",
    lat: 38.1543,
    lng: -84.678,
    id: 1840014318,
  },
  {
    name: "Priest River",
    state_id: "ID",
    lat: 48.1782,
    lng: -116.8841,
    id: 1840019772,
  },
  {
    name: "Independence",
    state_id: "LA",
    lat: 30.6361,
    lng: -90.5056,
    id: 1840017153,
  },
  {
    name: "Waterville",
    state_id: "MN",
    lat: 44.2236,
    lng: -93.5747,
    id: 1840010021,
  },
  {
    name: "Carbon Hill",
    state_id: "AL",
    lat: 33.8944,
    lng: -87.5224,
    id: 1840014766,
  },
  {
    name: "Bayou L'Ourse",
    state_id: "LA",
    lat: 29.7174,
    lng: -91.0607,
    id: 1840039400,
  },
  {
    name: "Leesport",
    state_id: "PA",
    lat: 40.4451,
    lng: -75.9684,
    id: 1840001180,
  },
  {
    name: "Enterprise",
    state_id: "UT",
    lat: 37.5717,
    lng: -113.743,
    id: 1840020314,
  },
  {
    name: "Hurstbourne Acres",
    state_id: "KY",
    lat: 38.2201,
    lng: -85.5907,
    id: 1840014309,
  },
  {
    name: "Derwood",
    state_id: "MD",
    lat: 39.1136,
    lng: -77.1509,
    id: 1840024488,
  },
  {
    name: "Hidden Hills",
    state_id: "CA",
    lat: 34.1637,
    lng: -118.6612,
    id: 1840020488,
  },
  {
    name: "Aceitunas",
    state_id: "PR",
    lat: 18.4455,
    lng: -67.0669,
    id: 1630023602,
  },
  {
    name: "Piedmont",
    state_id: "MO",
    lat: 37.1492,
    lng: -90.6969,
    id: 1840009916,
  },
  {
    name: "Lookout Mountain",
    state_id: "TN",
    lat: 34.9944,
    lng: -85.3516,
    id: 1840016374,
  },
  {
    name: "Steele",
    state_id: "MO",
    lat: 36.0904,
    lng: -89.846,
    id: 1840009950,
  },
  {
    name: "Lynnwood-Pricedale",
    state_id: "PA",
    lat: 40.1303,
    lng: -79.8515,
    id: 1840073707,
  },
  {
    name: "Roberts",
    state_id: "WI",
    lat: 44.9732,
    lng: -92.5505,
    id: 1840002140,
  },
  {
    name: "Graton",
    state_id: "CA",
    lat: 38.4376,
    lng: -122.866,
    id: 1840017577,
  },
  {
    name: "East Atlantic Beach",
    state_id: "NY",
    lat: 40.5876,
    lng: -73.7092,
    id: 1840034033,
  },
  {
    name: "San Augustine",
    state_id: "TX",
    lat: 31.5297,
    lng: -94.1108,
    id: 1840022148,
  },
  {
    name: "Fairchance",
    state_id: "PA",
    lat: 39.8251,
    lng: -79.754,
    id: 1840000661,
  },
  {
    name: "Gregory",
    state_id: "TX",
    lat: 27.9221,
    lng: -97.2918,
    id: 1840021003,
  },
  {
    name: "Hammond",
    state_id: "WI",
    lat: 44.969,
    lng: -92.438,
    id: 1840002134,
  },
  {
    name: "East Ithaca",
    state_id: "NY",
    lat: 42.4263,
    lng: -76.4627,
    id: 1840033874,
  },
  {
    name: "Waurika",
    state_id: "OK",
    lat: 34.1846,
    lng: -98.0245,
    id: 1840021918,
  },
  {
    name: "Lookout Mountain",
    state_id: "AL",
    lat: 34.1091,
    lng: -85.9585,
    id: 1840075152,
  },
  {
    name: "New Athens",
    state_id: "IL",
    lat: 38.3188,
    lng: -89.8743,
    id: 1840012876,
  },
  {
    name: "Southside Place",
    state_id: "TX",
    lat: 29.7089,
    lng: -95.4367,
    id: 1840022200,
  },
  {
    name: "Indian River",
    state_id: "MI",
    lat: 45.4236,
    lng: -84.6236,
    id: 1840004016,
  },
  {
    name: "Evart",
    state_id: "MI",
    lat: 43.9002,
    lng: -85.2748,
    id: 1840002560,
  },
  {
    name: "Lake Ripley",
    state_id: "WI",
    lat: 43.0045,
    lng: -88.9829,
    id: 1840004351,
  },
  {
    name: "Remsenburg-Speonk",
    state_id: "NY",
    lat: 40.8169,
    lng: -72.7046,
    id: 1840073662,
  },
  {
    name: "Monson Center",
    state_id: "MA",
    lat: 42.099,
    lng: -72.3048,
    id: 1840073517,
  },
  {
    name: "Blacksburg",
    state_id: "SC",
    lat: 35.1221,
    lng: -81.5182,
    id: 1840015487,
  },
  {
    name: "Adamstown",
    state_id: "PA",
    lat: 40.2417,
    lng: -76.0608,
    id: 1840001409,
  },
  {
    name: "Bernie",
    state_id: "MO",
    lat: 36.6717,
    lng: -89.9707,
    id: 1840007641,
  },
  {
    name: "Youngstown",
    state_id: "NY",
    lat: 43.2486,
    lng: -79.0444,
    id: 1840004275,
  },
  {
    name: "Linden",
    state_id: "AL",
    lat: 32.3001,
    lng: -87.7926,
    id: 1840005138,
  },
  {
    name: "Arden on the Severn",
    state_id: "MD",
    lat: 39.0677,
    lng: -76.5963,
    id: 1840031474,
  },
  {
    name: "Biggs",
    state_id: "CA",
    lat: 39.4102,
    lng: -121.7133,
    id: 1840018779,
  },
  {
    name: "Belleville",
    state_id: "KS",
    lat: 39.8245,
    lng: -97.6337,
    id: 1840000723,
  },
  {
    name: "Barview",
    state_id: "OR",
    lat: 43.347,
    lng: -124.3073,
    id: 1840017441,
  },
  {
    name: "Henryville",
    state_id: "IN",
    lat: 38.5395,
    lng: -85.7655,
    id: 1840006207,
  },
  {
    name: "Spencer",
    state_id: "WI",
    lat: 44.7544,
    lng: -90.2983,
    id: 1840002161,
  },
  {
    name: "Prince George",
    state_id: "VA",
    lat: 37.2209,
    lng: -77.2683,
    id: 1840026869,
  },
  {
    name: "Berino",
    state_id: "NM",
    lat: 32.0689,
    lng: -106.6219,
    id: 1840025080,
  },
  {
    name: "Greensboro",
    state_id: "MD",
    lat: 38.9764,
    lng: -75.808,
    id: 1840005935,
  },
  {
    name: "Great Falls",
    state_id: "SC",
    lat: 34.5753,
    lng: -80.9048,
    id: 1840016571,
  },
  {
    name: "Erma",
    state_id: "NJ",
    lat: 38.9971,
    lng: -74.8932,
    id: 1840005863,
  },
  {
    name: "Mount Cobb",
    state_id: "PA",
    lat: 41.4215,
    lng: -75.5005,
    id: 1840035315,
  },
  {
    name: "Amboy",
    state_id: "WA",
    lat: 45.9122,
    lng: -122.4796,
    id: 1840017396,
  },
  {
    name: "Warm Springs",
    state_id: "CA",
    lat: 33.7067,
    lng: -117.3344,
    id: 1840076411,
  },
  {
    name: "New Johnsonville",
    state_id: "TN",
    lat: 36.0163,
    lng: -87.9696,
    id: 1840014480,
  },
  {
    name: "Slater-Marietta",
    state_id: "SC",
    lat: 35.0348,
    lng: -82.4927,
    id: 1840035725,
  },
  {
    name: "Hollis",
    state_id: "OK",
    lat: 34.6895,
    lng: -99.9153,
    id: 1840020470,
  },
  {
    name: "Lake Mohawk",
    state_id: "OH",
    lat: 40.6608,
    lng: -81.1928,
    id: 1840026419,
  },
  {
    name: "Pinedale",
    state_id: "WY",
    lat: 42.8676,
    lng: -109.8706,
    id: 1840022361,
  },
  {
    name: "Elk Point",
    state_id: "SD",
    lat: 42.6816,
    lng: -96.68,
    id: 1840003080,
  },
  {
    name: "Ault",
    state_id: "CO",
    lat: 40.5893,
    lng: -104.7392,
    id: 1840020150,
  },
  {
    name: "Somonauk",
    state_id: "IL",
    lat: 41.642,
    lng: -88.6732,
    id: 1840011340,
  },
  {
    name: "Suárez",
    state_id: "PR",
    lat: 18.4311,
    lng: -65.8514,
    id: 1630023696,
  },
  {
    name: "Yale",
    state_id: "MI",
    lat: 43.1277,
    lng: -82.7973,
    id: 1840001845,
  },
  {
    name: "Van Horn",
    state_id: "TX",
    lat: 31.0407,
    lng: -104.834,
    id: 1840023110,
  },
  {
    name: "Shannon",
    state_id: "GA",
    lat: 34.3406,
    lng: -85.0854,
    id: 1840013603,
  },
  {
    name: "Moundridge",
    state_id: "KS",
    lat: 38.2028,
    lng: -97.515,
    id: 1840008636,
  },
  {
    name: "Nicholson",
    state_id: "GA",
    lat: 34.1172,
    lng: -83.4289,
    id: 1840014721,
  },
  {
    name: "Devon",
    state_id: "PA",
    lat: 40.0496,
    lng: -75.4271,
    id: 1840034928,
  },
  {
    name: "Gate City",
    state_id: "VA",
    lat: 36.6401,
    lng: -82.5782,
    id: 1840006544,
  },
  {
    name: "Gibbon",
    state_id: "NE",
    lat: 40.7461,
    lng: -98.8458,
    id: 1840008286,
  },
  {
    name: "Daniels",
    state_id: "WV",
    lat: 37.724,
    lng: -81.1267,
    id: 1840038126,
  },
  {
    name: "Melbourne",
    state_id: "AR",
    lat: 36.0638,
    lng: -91.8819,
    id: 1840015325,
  },
  {
    name: "New Buffalo",
    state_id: "MI",
    lat: 41.7922,
    lng: -86.7423,
    id: 1840003211,
  },
  {
    name: "Ellaville",
    state_id: "GA",
    lat: 32.238,
    lng: -84.3089,
    id: 1840013830,
  },
  {
    name: "Sumrall",
    state_id: "MS",
    lat: 31.4172,
    lng: -89.5424,
    id: 1840017106,
  },
  {
    name: "Conyngham",
    state_id: "PA",
    lat: 40.9911,
    lng: -76.0595,
    id: 1840000743,
  },
  {
    name: "Lavallette",
    state_id: "NJ",
    lat: 39.9699,
    lng: -74.0722,
    id: 1840001492,
  },
  {
    name: "Laurel Springs",
    state_id: "NJ",
    lat: 39.8213,
    lng: -75.0053,
    id: 1840001513,
  },
  {
    name: "Bradford",
    state_id: "OH",
    lat: 40.1319,
    lng: -84.4293,
    id: 1840012372,
  },
  {
    name: "Seabrook Island",
    state_id: "SC",
    lat: 32.5825,
    lng: -80.1736,
    id: 1840018343,
  },
  {
    name: "Tenino",
    state_id: "WA",
    lat: 46.8537,
    lng: -122.8607,
    id: 1840021144,
  },
  {
    name: "Charlotte Hall",
    state_id: "MD",
    lat: 38.4667,
    lng: -76.7847,
    id: 1840006229,
  },
  {
    name: "West Homestead",
    state_id: "PA",
    lat: 40.3977,
    lng: -79.9185,
    id: 1840001272,
  },
  {
    name: "Lakesite",
    state_id: "TN",
    lat: 35.2027,
    lng: -85.1413,
    id: 1840014573,
  },
  {
    name: "South Connellsville",
    state_id: "PA",
    lat: 39.9946,
    lng: -79.5807,
    id: 1840000670,
  },
  {
    name: "Leachville",
    state_id: "AR",
    lat: 35.9042,
    lng: -90.2622,
    id: 1840014503,
  },
  {
    name: "Durant",
    state_id: "IA",
    lat: 41.6011,
    lng: -90.9137,
    id: 1840008146,
  },
  {
    name: "Haines",
    state_id: "AK",
    lat: 59.2417,
    lng: -135.4549,
    id: 1840023317,
  },
  {
    name: "Elysburg",
    state_id: "PA",
    lat: 40.868,
    lng: -76.5492,
    id: 1840005177,
  },
  {
    name: "Flora",
    state_id: "MS",
    lat: 32.5433,
    lng: -90.3141,
    id: 1840016958,
  },
  {
    name: "Malta",
    state_id: "MT",
    lat: 48.3555,
    lng: -107.8709,
    id: 1840019756,
  },
  {
    name: "Canton",
    state_id: "PA",
    lat: 41.6566,
    lng: -76.8529,
    id: 1840000512,
  },
  {
    name: "Langston",
    state_id: "OK",
    lat: 35.9324,
    lng: -97.263,
    id: 1840021683,
  },
  {
    name: "Ledbetter",
    state_id: "KY",
    lat: 37.0521,
    lng: -88.4966,
    id: 1840013246,
  },
  {
    name: "Lakemont",
    state_id: "PA",
    lat: 40.466,
    lng: -78.3916,
    id: 1840026413,
  },
  {
    name: "Sesser",
    state_id: "IL",
    lat: 38.0905,
    lng: -89.0509,
    id: 1840009852,
  },
  {
    name: "Moriarty",
    state_id: "NM",
    lat: 35.0034,
    lng: -106.0445,
    id: 1840020469,
  },
  {
    name: "Blaine",
    state_id: "TN",
    lat: 36.1478,
    lng: -83.694,
    id: 1840013343,
  },
  {
    name: "Dakota City",
    state_id: "NE",
    lat: 42.4184,
    lng: -96.4202,
    id: 1840006968,
  },
  {
    name: "Wadley",
    state_id: "GA",
    lat: 32.8663,
    lng: -82.4014,
    id: 1840015738,
  },
  {
    name: "Venice",
    state_id: "IL",
    lat: 38.6719,
    lng: -90.169,
    id: 1840010710,
  },
  {
    name: "Sylvan Lake",
    state_id: "MI",
    lat: 42.6172,
    lng: -83.3331,
    id: 1840002433,
  },
  {
    name: "Edgewood",
    state_id: "IN",
    lat: 40.1032,
    lng: -85.7375,
    id: 1840010455,
  },
  {
    name: "Merrionette Park",
    state_id: "IL",
    lat: 41.6812,
    lng: -87.7012,
    id: 1840011297,
  },
  {
    name: "North Belle Vernon",
    state_id: "PA",
    lat: 40.132,
    lng: -79.8641,
    id: 1840001153,
  },
  {
    name: "Gordon",
    state_id: "GA",
    lat: 32.8866,
    lng: -83.3349,
    id: 1840013792,
  },
  {
    name: "Crossville",
    state_id: "AL",
    lat: 34.2853,
    lng: -85.9997,
    id: 1840016554,
  },
  {
    name: "Estill",
    state_id: "SC",
    lat: 32.7538,
    lng: -81.2412,
    id: 1840016924,
  },
  {
    name: "Peosta",
    state_id: "IA",
    lat: 42.4477,
    lng: -90.8446,
    id: 1840009096,
  },
  {
    name: "Point of Rocks",
    state_id: "MD",
    lat: 39.2781,
    lng: -77.5292,
    id: 1840024454,
  },
  {
    name: "Big Beaver",
    state_id: "PA",
    lat: 40.8233,
    lng: -80.3633,
    id: 1840035408,
  },
  {
    name: "North Vacherie",
    state_id: "LA",
    lat: 30.0047,
    lng: -90.711,
    id: 1840031151,
  },
  {
    name: "Denver",
    state_id: "IA",
    lat: 42.669,
    lng: -92.3342,
    id: 1840006916,
  },
  {
    name: "Foresthill",
    state_id: "CA",
    lat: 39.0053,
    lng: -120.8314,
    id: 1840017558,
  },
  {
    name: "Richwood",
    state_id: "WV",
    lat: 38.2221,
    lng: -80.5363,
    id: 1840006226,
  },
  {
    name: "Drexel",
    state_id: "NC",
    lat: 35.7569,
    lng: -81.6089,
    id: 1840016232,
  },
  {
    name: "Samson",
    state_id: "AL",
    lat: 31.1124,
    lng: -86.0462,
    id: 1840015883,
  },
  {
    name: "Riverside",
    state_id: "PA",
    lat: 40.9461,
    lng: -76.6461,
    id: 1840000848,
  },
  {
    name: "Plain View",
    state_id: "NC",
    lat: 35.2457,
    lng: -78.5634,
    id: 1840013486,
  },
  {
    name: "Glenmoor",
    state_id: "OH",
    lat: 40.6639,
    lng: -80.6133,
    id: 1840005222,
  },
  {
    name: "Williamsport",
    state_id: "IN",
    lat: 40.2884,
    lng: -87.2922,
    id: 1840014427,
  },
  {
    name: "Rivanna",
    state_id: "VA",
    lat: 37.9945,
    lng: -78.3788,
    id: 1840041853,
  },
  {
    name: "Spring City",
    state_id: "TN",
    lat: 35.6884,
    lng: -84.8626,
    id: 1840017789,
  },
  {
    name: "Wilber",
    state_id: "NE",
    lat: 40.481,
    lng: -96.9645,
    id: 1840010390,
  },
  {
    name: "Vernon",
    state_id: "AL",
    lat: 33.7588,
    lng: -88.1143,
    id: 1840005593,
  },
  {
    name: "Ingram",
    state_id: "TX",
    lat: 30.0773,
    lng: -99.2381,
    id: 1840019608,
  },
  {
    name: "Roundup",
    state_id: "MT",
    lat: 46.4485,
    lng: -108.5402,
    id: 1840019885,
  },
  {
    name: "Wakarusa",
    state_id: "IN",
    lat: 41.5336,
    lng: -86.0139,
    id: 1840010194,
  },
  {
    name: "Cordova",
    state_id: "AL",
    lat: 33.7613,
    lng: -87.1925,
    id: 1840014767,
  },
  {
    name: "Delcambre",
    state_id: "LA",
    lat: 29.9515,
    lng: -91.9917,
    id: 1840017210,
  },
  {
    name: "Five Points",
    state_id: "OH",
    lat: 39.5595,
    lng: -84.1867,
    id: 1840034075,
  },
  {
    name: "Morrisville",
    state_id: "NY",
    lat: 42.8987,
    lng: -75.6448,
    id: 1840004356,
  },
  {
    name: "Milan",
    state_id: "IN",
    lat: 39.1255,
    lng: -85.127,
    id: 1840009620,
  },
  {
    name: "Brookwood",
    state_id: "AL",
    lat: 33.2397,
    lng: -87.3277,
    id: 1840015711,
  },
  {
    name: "Merriam Woods",
    state_id: "MO",
    lat: 36.7179,
    lng: -93.1715,
    id: 1840013058,
  },
  {
    name: "Doe Valley",
    state_id: "KY",
    lat: 37.9745,
    lng: -86.1107,
    id: 1840030816,
  },
  {
    name: "Andrews",
    state_id: "NC",
    lat: 35.1994,
    lng: -83.8258,
    id: 1840015474,
  },
  {
    name: "Rolling Hills",
    state_id: "CA",
    lat: 33.76,
    lng: -118.3471,
    id: 1840020493,
  },
  {
    name: "Hooker",
    state_id: "OK",
    lat: 36.8612,
    lng: -101.216,
    id: 1840020351,
  },
  {
    name: "Alpine",
    state_id: "NJ",
    lat: 40.9615,
    lng: -73.9199,
    id: 1840003551,
  },
  {
    name: "Whitesburg",
    state_id: "KY",
    lat: 37.1183,
    lng: -82.823,
    id: 1840015259,
  },
  {
    name: "Eagleview",
    state_id: "PA",
    lat: 40.0598,
    lng: -75.6789,
    id: 1840040475,
  },
  {
    name: "L'Anse",
    state_id: "MI",
    lat: 46.7525,
    lng: -88.4477,
    id: 1840010915,
  },
  {
    name: "Langley",
    state_id: "SC",
    lat: 33.512,
    lng: -81.8343,
    id: 1840025039,
  },
  {
    name: "G. L. García",
    state_id: "PR",
    lat: 18.1258,
    lng: -66.1027,
    id: 1630035481,
  },
  {
    name: "Milam",
    state_id: "TX",
    lat: 31.4541,
    lng: -93.7979,
    id: 1840019545,
  },
  {
    name: "Scotland Neck",
    state_id: "NC",
    lat: 36.1309,
    lng: -77.4214,
    id: 1840017705,
  },
  {
    name: "Dunnellon",
    state_id: "FL",
    lat: 29.0529,
    lng: -82.441,
    id: 1840014039,
  },
  {
    name: "St. Lawrence",
    state_id: "PA",
    lat: 40.3262,
    lng: -75.8661,
    id: 1840001187,
  },
  {
    name: "Roseville",
    state_id: "OH",
    lat: 39.8064,
    lng: -82.0758,
    id: 1840012393,
  },
  {
    name: "New London",
    state_id: "IA",
    lat: 40.923,
    lng: -91.4012,
    id: 1840008266,
  },
  {
    name: "Myersville",
    state_id: "MD",
    lat: 39.5062,
    lng: -77.5689,
    id: 1840005714,
  },
  {
    name: "Greybull",
    state_id: "WY",
    lat: 44.4887,
    lng: -108.0592,
    id: 1840021241,
  },
  {
    name: "Greenfield",
    state_id: "IA",
    lat: 41.3056,
    lng: -94.4592,
    id: 1840008238,
  },
  {
    name: "Capac",
    state_id: "MI",
    lat: 43.0074,
    lng: -82.9271,
    id: 1840010911,
  },
  {
    name: "Corcovado",
    state_id: "PR",
    lat: 18.461,
    lng: -66.7778,
    id: 1630023533,
  },
  {
    name: "Delaware City",
    state_id: "DE",
    lat: 39.5743,
    lng: -75.5939,
    id: 1840005576,
  },
  {
    name: "Olathe",
    state_id: "CO",
    lat: 38.6083,
    lng: -107.9833,
    id: 1840022506,
  },
  {
    name: "Columbus Junction",
    state_id: "IA",
    lat: 41.2787,
    lng: -91.3651,
    id: 1840007135,
  },
  {
    name: "Frankton",
    state_id: "IN",
    lat: 40.2211,
    lng: -85.7719,
    id: 1840010456,
  },
  {
    name: "Trainer",
    state_id: "PA",
    lat: 39.8244,
    lng: -75.4032,
    id: 1840000713,
  },
  {
    name: "Krugerville",
    state_id: "TX",
    lat: 33.279,
    lng: -96.9881,
    id: 1840020644,
  },
  {
    name: "Morgan",
    state_id: "GA",
    lat: 31.5381,
    lng: -84.6033,
    id: 1840014953,
  },
  {
    name: "Medicine Lodge",
    state_id: "KS",
    lat: 37.2851,
    lng: -98.5812,
    id: 1840008754,
  },
  {
    name: "Sulligent",
    state_id: "AL",
    lat: 33.8912,
    lng: -88.1266,
    id: 1840015636,
  },
  {
    name: "Colquitt",
    state_id: "GA",
    lat: 31.1741,
    lng: -84.7319,
    id: 1840014983,
  },
  {
    name: "Lincoln University",
    state_id: "PA",
    lat: 39.8065,
    lng: -75.9279,
    id: 1840043361,
  },
  {
    name: "Brass Castle",
    state_id: "NJ",
    lat: 40.7621,
    lng: -75.0141,
    id: 1840005193,
  },
  {
    name: "Cedar Hill",
    state_id: "MO",
    lat: 38.3575,
    lng: -90.6409,
    id: 1840006238,
  },
  {
    name: "Memphis",
    state_id: "MO",
    lat: 40.4614,
    lng: -92.1704,
    id: 1840008344,
  },
  {
    name: "Jacobus",
    state_id: "PA",
    lat: 39.8825,
    lng: -76.7121,
    id: 1840001451,
  },
  {
    name: "Bangor",
    state_id: "MI",
    lat: 42.312,
    lng: -86.1135,
    id: 1840003179,
  },
  {
    name: "Bloomfield",
    state_id: "MO",
    lat: 36.8877,
    lng: -89.9309,
    id: 1840007642,
  },
  {
    name: "Goldthwaite",
    state_id: "TX",
    lat: 31.4513,
    lng: -98.5736,
    id: 1840020824,
  },
  {
    name: "Christmas",
    state_id: "FL",
    lat: 28.5606,
    lng: -81.0201,
    id: 1840014084,
  },
  {
    name: "Jarrell",
    state_id: "TX",
    lat: 30.8127,
    lng: -97.6132,
    id: 1840019574,
  },
  {
    name: "Leetonia",
    state_id: "OH",
    lat: 40.8785,
    lng: -80.7623,
    id: 1840011931,
  },
  {
    name: "Upper Pohatcong",
    state_id: "NJ",
    lat: 40.6774,
    lng: -75.1558,
    id: 1840039970,
  },
  {
    name: "Clatskanie",
    state_id: "OR",
    lat: 46.1047,
    lng: -123.2045,
    id: 1840018512,
  },
  {
    name: "Presidential Lakes Estates",
    state_id: "NJ",
    lat: 39.915,
    lng: -74.5649,
    id: 1840005520,
  },
  {
    name: "Howard City",
    state_id: "MI",
    lat: 43.3947,
    lng: -85.4683,
    id: 1840010991,
  },
  {
    name: "Cienega Springs",
    state_id: "AZ",
    lat: 34.2008,
    lng: -114.2089,
    id: 1840022877,
  },
  {
    name: "Washington Heights",
    state_id: "NY",
    lat: 41.4693,
    lng: -74.4179,
    id: 1840004865,
  },
  {
    name: "Arapahoe",
    state_id: "WY",
    lat: 42.9912,
    lng: -108.4562,
    id: 1840017433,
  },
  {
    name: "Mesilla",
    state_id: "NM",
    lat: 32.2691,
    lng: -106.8086,
    id: 1840023024,
  },
  {
    name: "Lovelock",
    state_id: "NV",
    lat: 40.1789,
    lng: -118.4773,
    id: 1840020154,
  },
  {
    name: "Parchment",
    state_id: "MI",
    lat: 42.3277,
    lng: -85.5667,
    id: 1840003182,
  },
  {
    name: "Burns Harbor",
    state_id: "IN",
    lat: 41.6189,
    lng: -87.1258,
    id: 1840009266,
  },
  {
    name: "Lake Ozark",
    state_id: "MO",
    lat: 38.2035,
    lng: -92.6257,
    id: 1840008660,
  },
  {
    name: "Campbellsport",
    state_id: "WI",
    lat: 43.5975,
    lng: -88.2814,
    id: 1840002604,
  },
  {
    name: "Fort Hancock",
    state_id: "TX",
    lat: 31.2837,
    lng: -105.8446,
    id: 1840018150,
  },
  {
    name: "Pahala",
    state_id: "HI",
    lat: 19.1995,
    lng: -155.4794,
    id: 1840029537,
  },
  {
    name: "Cross Plains",
    state_id: "TN",
    lat: 36.5412,
    lng: -86.6766,
    id: 1840014413,
  },
  {
    name: "Beaver Dam Lake",
    state_id: "NY",
    lat: 41.4441,
    lng: -74.1178,
    id: 1840034067,
  },
  {
    name: "Seth Ward",
    state_id: "TX",
    lat: 34.2163,
    lng: -101.6945,
    id: 1840019271,
  },
  {
    name: "East Bronson",
    state_id: "FL",
    lat: 29.4593,
    lng: -82.5905,
    id: 1840073825,
  },
  {
    name: "Sacaton",
    state_id: "AZ",
    lat: 33.0793,
    lng: -111.7683,
    id: 1840019361,
  },
  {
    name: "Lusby",
    state_id: "MD",
    lat: 38.362,
    lng: -76.4372,
    id: 1840006148,
  },
  {
    name: "Hope Valley",
    state_id: "RI",
    lat: 41.5151,
    lng: -71.72,
    id: 1840003364,
  },
  {
    name: "Second Mesa",
    state_id: "AZ",
    lat: 35.8222,
    lng: -110.4884,
    id: 1840018965,
  },
  {
    name: "Wilder",
    state_id: "ID",
    lat: 43.6782,
    lng: -116.907,
    id: 1840021278,
  },
  {
    name: "Leon",
    state_id: "IA",
    lat: 40.7407,
    lng: -93.7546,
    id: 1840001005,
  },
  {
    name: "Brownsville",
    state_id: "OR",
    lat: 44.3923,
    lng: -122.9833,
    id: 1840018606,
  },
  {
    name: "Cedar Bluff",
    state_id: "AL",
    lat: 34.2217,
    lng: -85.5877,
    id: 1840005812,
  },
  {
    name: "Delmar",
    state_id: "DE",
    lat: 38.465,
    lng: -75.5552,
    id: 1840006077,
  },
  {
    name: "Clarkesville",
    state_id: "GA",
    lat: 34.6103,
    lng: -83.5284,
    id: 1840014660,
  },
  {
    name: "South Wenatchee",
    state_id: "WA",
    lat: 47.3885,
    lng: -120.2828,
    id: 1840037106,
  },
  {
    name: "Bay",
    state_id: "AR",
    lat: 35.7456,
    lng: -90.5547,
    id: 1840013395,
  },
  {
    name: "Susquehanna Trails",
    state_id: "PA",
    lat: 39.7583,
    lng: -76.3684,
    id: 1840035439,
  },
  {
    name: "Cana",
    state_id: "VA",
    lat: 36.5816,
    lng: -80.6688,
    id: 1840006525,
  },
  {
    name: "Lyndhurst",
    state_id: "VA",
    lat: 38.0221,
    lng: -78.9516,
    id: 1840006245,
  },
  {
    name: "Nambe",
    state_id: "NM",
    lat: 35.8984,
    lng: -105.9667,
    id: 1840024915,
  },
  {
    name: "Lakeland",
    state_id: "MN",
    lat: 44.9503,
    lng: -92.77,
    id: 1840007816,
  },
  {
    name: "Briarcliff",
    state_id: "TX",
    lat: 30.4088,
    lng: -98.0451,
    id: 1840023143,
  },
  {
    name: "Bayou Gauche",
    state_id: "LA",
    lat: 29.8081,
    lng: -90.421,
    id: 1840013976,
  },
  {
    name: "Gardendale",
    state_id: "TX",
    lat: 32.0105,
    lng: -102.3587,
    id: 1840018145,
  },
  {
    name: "Vale",
    state_id: "OR",
    lat: 43.984,
    lng: -117.2418,
    id: 1840021261,
  },
  {
    name: "Clearwater",
    state_id: "MN",
    lat: 45.4102,
    lng: -94.0446,
    id: 1840006704,
  },
  {
    name: "Bruce",
    state_id: "MS",
    lat: 33.9909,
    lng: -89.3454,
    id: 1840015617,
  },
  {
    name: "Driggs",
    state_id: "ID",
    lat: 43.7297,
    lng: -111.1033,
    id: 1840018635,
  },
  {
    name: "Pine",
    state_id: "AZ",
    lat: 34.3788,
    lng: -111.4574,
    id: 1840019262,
  },
  {
    name: "Bryant",
    state_id: "WA",
    lat: 48.24,
    lng: -122.1638,
    id: 1840023772,
  },
  {
    name: "Long Lake",
    state_id: "MN",
    lat: 44.9841,
    lng: -93.5689,
    id: 1840008921,
  },
  {
    name: "Braham",
    state_id: "MN",
    lat: 45.7223,
    lng: -93.1717,
    id: 1840006693,
  },
  {
    name: "Oriole Beach",
    state_id: "FL",
    lat: 30.3687,
    lng: -87.0962,
    id: 1840027018,
  },
  {
    name: "Oakland",
    state_id: "MD",
    lat: 39.4165,
    lng: -79.4022,
    id: 1840005634,
  },
  {
    name: "Olivet",
    state_id: "TN",
    lat: 35.2048,
    lng: -88.1939,
    id: 1840025828,
  },
  {
    name: "Rossmoyne",
    state_id: "OH",
    lat: 39.2145,
    lng: -84.3886,
    id: 1840034306,
  },
  {
    name: "Prosperity",
    state_id: "WV",
    lat: 37.8385,
    lng: -81.1986,
    id: 1840006347,
  },
  {
    name: "Crystal Lawns",
    state_id: "IL",
    lat: 41.5685,
    lng: -88.1638,
    id: 1840029896,
  },
  {
    name: "Weston",
    state_id: "MO",
    lat: 39.403,
    lng: -94.886,
    id: 1840010643,
  },
  {
    name: "Brooklet",
    state_id: "GA",
    lat: 32.3898,
    lng: -81.6668,
    id: 1840013821,
  },
  {
    name: "St. Stephen",
    state_id: "SC",
    lat: 33.4046,
    lng: -79.9264,
    id: 1840018013,
  },
  {
    name: "Mount Zion",
    state_id: "GA",
    lat: 33.6409,
    lng: -85.1804,
    id: 1840014802,
  },
  {
    name: "Hudson",
    state_id: "IL",
    lat: 40.6054,
    lng: -88.9893,
    id: 1840011974,
  },
  {
    name: "Stateburg",
    state_id: "SC",
    lat: 33.9753,
    lng: -80.5264,
    id: 1840013669,
  },
  {
    name: "Arenas Valley",
    state_id: "NM",
    lat: 32.7765,
    lng: -108.203,
    id: 1840025065,
  },
  {
    name: "New Oxford",
    state_id: "PA",
    lat: 39.863,
    lng: -77.0555,
    id: 1840000686,
  },
  {
    name: "Morton",
    state_id: "TX",
    lat: 33.7248,
    lng: -102.7593,
    id: 1840020605,
  },
  {
    name: "Sunray",
    state_id: "TX",
    lat: 36.0182,
    lng: -101.8245,
    id: 1840021704,
  },
  {
    name: "Navarre",
    state_id: "OH",
    lat: 40.7262,
    lng: -81.5146,
    id: 1840011917,
  },
  {
    name: "South Park",
    state_id: "WY",
    lat: 43.4235,
    lng: -110.7993,
    id: 1840038436,
  },
  {
    name: "Selmont-West Selmont",
    state_id: "AL",
    lat: 32.3784,
    lng: -87.0074,
    id: 1840035626,
  },
  {
    name: "Lake Dalecarlia",
    state_id: "IN",
    lat: 41.337,
    lng: -87.4036,
    id: 1840004832,
  },
  {
    name: "East Pepperell",
    state_id: "MA",
    lat: 42.6654,
    lng: -71.5635,
    id: 1840003130,
  },
  {
    name: "East Washington",
    state_id: "PA",
    lat: 40.1745,
    lng: -80.2325,
    id: 1840003652,
  },
  {
    name: "Maunabo",
    state_id: "PR",
    lat: 18.0056,
    lng: -65.9009,
    id: 1630023697,
  },
  {
    name: "Hemby Bridge",
    state_id: "NC",
    lat: 35.1055,
    lng: -80.6258,
    id: 1840015480,
  },
  {
    name: "Harwich Port",
    state_id: "MA",
    lat: 41.6723,
    lng: -70.0641,
    id: 1840003242,
  },
  {
    name: "Campbell",
    state_id: "MO",
    lat: 36.493,
    lng: -90.0762,
    id: 1840007663,
  },
  {
    name: "Noel",
    state_id: "MO",
    lat: 36.5433,
    lng: -94.4888,
    id: 1840008808,
  },
  {
    name: "Mona",
    state_id: "UT",
    lat: 39.8122,
    lng: -111.8502,
    id: 1840020187,
  },
  {
    name: "Lone Pine",
    state_id: "CA",
    lat: 36.5774,
    lng: -118.0777,
    id: 1840017642,
  },
  {
    name: "North Warren",
    state_id: "PA",
    lat: 41.8833,
    lng: -79.1672,
    id: 1840024120,
  },
  {
    name: "Superior",
    state_id: "NE",
    lat: 40.023,
    lng: -98.0663,
    id: 1840009446,
  },
  {
    name: "Lepanto",
    state_id: "AR",
    lat: 35.6085,
    lng: -90.333,
    id: 1840014545,
  },
  {
    name: "Ralls",
    state_id: "TX",
    lat: 33.6788,
    lng: -101.3845,
    id: 1840020603,
  },
  {
    name: "Shelburne Falls",
    state_id: "MA",
    lat: 42.6063,
    lng: -72.7434,
    id: 1840004517,
  },
  {
    name: "Marissa",
    state_id: "IL",
    lat: 38.2501,
    lng: -89.7746,
    id: 1840012874,
  },
  {
    name: "Piru",
    state_id: "CA",
    lat: 34.4075,
    lng: -118.7999,
    id: 1840019200,
  },
  {
    name: "Lawton",
    state_id: "MI",
    lat: 42.1678,
    lng: -85.846,
    id: 1840011205,
  },
  {
    name: "Alum Creek",
    state_id: "WV",
    lat: 38.2876,
    lng: -81.833,
    id: 1840006188,
  },
  {
    name: "Plainville",
    state_id: "KS",
    lat: 39.2341,
    lng: -99.3009,
    id: 1840009576,
  },
  {
    name: "Guttenberg",
    state_id: "IA",
    lat: 42.7886,
    lng: -91.1059,
    id: 1840006891,
  },
  {
    name: "Inola",
    state_id: "OK",
    lat: 36.1315,
    lng: -95.5373,
    id: 1840021644,
  },
  {
    name: "Jackson",
    state_id: "SC",
    lat: 33.3284,
    lng: -81.7925,
    id: 1840016794,
  },
  {
    name: "Elida",
    state_id: "OH",
    lat: 40.7868,
    lng: -84.1995,
    id: 1840011954,
  },
  {
    name: "Mayville",
    state_id: "ND",
    lat: 47.4982,
    lng: -97.3264,
    id: 1840000136,
  },
  {
    name: "Moravian Falls",
    state_id: "NC",
    lat: 36.1068,
    lng: -81.1808,
    id: 1840013339,
  },
  {
    name: "Kenyon",
    state_id: "MN",
    lat: 44.2726,
    lng: -92.9858,
    id: 1840007870,
  },
  {
    name: "Plymouth",
    state_id: "OH",
    lat: 40.9966,
    lng: -82.6672,
    id: 1840011707,
  },
  {
    name: "Kapaau",
    state_id: "HI",
    lat: 20.2267,
    lng: -155.805,
    id: 1840029500,
  },
  {
    name: "Millwood",
    state_id: "WA",
    lat: 47.6856,
    lng: -117.2806,
    id: 1840022289,
  },
  {
    name: "Durand",
    state_id: "WI",
    lat: 44.6271,
    lng: -91.9607,
    id: 1840002351,
  },
  {
    name: "Crandon",
    state_id: "WI",
    lat: 45.5686,
    lng: -88.8973,
    id: 1840002005,
  },
  {
    name: "West Liberty",
    state_id: "OH",
    lat: 40.2569,
    lng: -83.7584,
    id: 1840012158,
  },
  {
    name: "Browns Lake",
    state_id: "WI",
    lat: 42.6908,
    lng: -88.2308,
    id: 1840004482,
  },
  {
    name: "Lewiston",
    state_id: "UT",
    lat: 41.9615,
    lng: -111.8797,
    id: 1840020107,
  },
  {
    name: "Orion",
    state_id: "IL",
    lat: 41.3515,
    lng: -90.3767,
    id: 1840011587,
  },
  {
    name: "Grandview",
    state_id: "TX",
    lat: 32.2685,
    lng: -97.1775,
    id: 1840020752,
  },
  {
    name: "Butler",
    state_id: "WI",
    lat: 43.1085,
    lng: -88.0713,
    id: 1840003013,
  },
  {
    name: "Lakeside",
    state_id: "OR",
    lat: 43.5795,
    lng: -124.1732,
    id: 1840020059,
  },
  {
    name: "Westhampton Beach",
    state_id: "NY",
    lat: 40.808,
    lng: -72.6457,
    id: 1840005139,
  },
  {
    name: "Elgin",
    state_id: "OR",
    lat: 45.5643,
    lng: -117.9212,
    id: 1840019922,
  },
  {
    name: "Cologne",
    state_id: "MN",
    lat: 44.7696,
    lng: -93.7931,
    id: 1840006757,
  },
  {
    name: "Weweantic",
    state_id: "MA",
    lat: 41.7398,
    lng: -70.7351,
    id: 1840031245,
  },
  {
    name: "Spinnerstown",
    state_id: "PA",
    lat: 40.4412,
    lng: -75.4414,
    id: 1840026489,
  },
  {
    name: "Lithopolis",
    state_id: "OH",
    lat: 39.8113,
    lng: -82.8155,
    id: 1840012469,
  },
  {
    name: "Yacolt",
    state_id: "WA",
    lat: 45.8653,
    lng: -122.4062,
    id: 1840022333,
  },
  {
    name: "Richland",
    state_id: "MO",
    lat: 37.861,
    lng: -92.3993,
    id: 1840009865,
  },
  {
    name: "Henderson",
    state_id: "LA",
    lat: 30.3186,
    lng: -91.8039,
    id: 1840015925,
  },
  {
    name: "Warroad",
    state_id: "MN",
    lat: 48.9169,
    lng: -95.3272,
    id: 1840009952,
  },
  {
    name: "Arroyo Seco",
    state_id: "NM",
    lat: 36.5215,
    lng: -105.586,
    id: 1840026909,
  },
  {
    name: "Appomattox",
    state_id: "VA",
    lat: 37.3591,
    lng: -78.8269,
    id: 1840006412,
  },
  {
    name: "Mount Sterling",
    state_id: "OH",
    lat: 39.7134,
    lng: -83.2734,
    id: 1840012417,
  },
  {
    name: "Village of the Branch",
    state_id: "NY",
    lat: 40.8524,
    lng: -73.1844,
    id: 1840034011,
  },
  {
    name: "Coalgate",
    state_id: "OK",
    lat: 34.5323,
    lng: -96.2208,
    id: 1840019248,
  },
  {
    name: "Golden's Bridge",
    state_id: "NY",
    lat: 41.2877,
    lng: -73.6681,
    id: 1840033892,
  },
  {
    name: "Cridersville",
    state_id: "OH",
    lat: 40.6509,
    lng: -84.1427,
    id: 1840012069,
  },
  {
    name: "Jamestown",
    state_id: "KY",
    lat: 36.9896,
    lng: -85.0662,
    id: 1840014392,
  },
  {
    name: "Buena Vista",
    state_id: "PR",
    lat: 17.9962,
    lng: -66.052,
    id: 1630035626,
  },
  {
    name: "Decatur",
    state_id: "AR",
    lat: 36.3402,
    lng: -94.4578,
    id: 1840014442,
  },
  {
    name: "South Floral Park",
    state_id: "NY",
    lat: 40.7136,
    lng: -73.7005,
    id: 1840005327,
  },
  {
    name: "King",
    state_id: "WI",
    lat: 44.34,
    lng: -89.1231,
    id: 1840023955,
  },
  {
    name: "Port Sulphur",
    state_id: "LA",
    lat: 29.5043,
    lng: -89.7203,
    id: 1840014029,
  },
  {
    name: "Beech Mountain Lakes",
    state_id: "PA",
    lat: 41.0414,
    lng: -75.9324,
    id: 1840035447,
  },
  {
    name: "Cameron",
    state_id: "WI",
    lat: 45.4057,
    lng: -91.7423,
    id: 1840002070,
  },
  {
    name: "Red Corral",
    state_id: "CA",
    lat: 38.4116,
    lng: -120.6056,
    id: 1840076141,
  },
  {
    name: "Coushatta",
    state_id: "LA",
    lat: 32.0256,
    lng: -93.3406,
    id: 1840017029,
  },
  {
    name: "Red Lake",
    state_id: "MN",
    lat: 47.8707,
    lng: -95.0052,
    id: 1840003994,
  },
  {
    name: "Bonneau Beach",
    state_id: "SC",
    lat: 33.3229,
    lng: -79.9899,
    id: 1840025042,
  },
  {
    name: "Idaho Springs",
    state_id: "CO",
    lat: 39.7445,
    lng: -105.5003,
    id: 1840018790,
  },
  {
    name: "Bartonville",
    state_id: "TX",
    lat: 33.0795,
    lng: -97.1506,
    id: 1840020648,
  },
  {
    name: "Sea Breeze",
    state_id: "NC",
    lat: 34.0698,
    lng: -77.8965,
    id: 1840013629,
  },
  {
    name: "Castlewood",
    state_id: "VA",
    lat: 36.8782,
    lng: -82.2879,
    id: 1840006489,
  },
  {
    name: "Edgard",
    state_id: "LA",
    lat: 30.0362,
    lng: -90.546,
    id: 1840013107,
  },
  {
    name: "Spring Park",
    state_id: "MN",
    lat: 44.9364,
    lng: -93.6319,
    id: 1840008926,
  },
  {
    name: "Edgerton",
    state_id: "KS",
    lat: 38.7717,
    lng: -94.9705,
    id: 1840001634,
  },
  {
    name: "Basile",
    state_id: "LA",
    lat: 30.4855,
    lng: -92.601,
    id: 1840015895,
  },
  {
    name: "Weston",
    state_id: "NJ",
    lat: 40.5224,
    lng: -74.5765,
    id: 1840033440,
  },
  {
    name: "Las Palmas II",
    state_id: "TX",
    lat: 26.2032,
    lng: -97.7395,
    id: 1840040969,
  },
  {
    name: "Avon-by-the-Sea",
    state_id: "NJ",
    lat: 40.1913,
    lng: -74.0166,
    id: 1840003676,
  },
  {
    name: "Evadale",
    state_id: "TX",
    lat: 30.3369,
    lng: -94.06,
    id: 1840018198,
  },
  {
    name: "Braddock Hills",
    state_id: "PA",
    lat: 40.418,
    lng: -79.8629,
    id: 1840001210,
  },
  {
    name: "Bloomington",
    state_id: "TX",
    lat: 28.6504,
    lng: -96.9022,
    id: 1840018278,
  },
  {
    name: "Tawas City",
    state_id: "MI",
    lat: 44.2679,
    lng: -83.5244,
    id: 1840002516,
  },
  {
    name: "Ortonville",
    state_id: "MN",
    lat: 45.3017,
    lng: -96.4415,
    id: 1840008896,
  },
  {
    name: "Sturgis",
    state_id: "KY",
    lat: 37.5465,
    lng: -87.9878,
    id: 1840015227,
  },
  {
    name: "Clarendon",
    state_id: "TX",
    lat: 34.9366,
    lng: -100.8918,
    id: 1840019177,
  },
  {
    name: "Wellsville",
    state_id: "KS",
    lat: 38.7166,
    lng: -95.0806,
    id: 1840010767,
  },
  {
    name: "Miami",
    state_id: "AZ",
    lat: 33.3951,
    lng: -110.8721,
    id: 1840022856,
  },
  {
    name: "Gilbert",
    state_id: "MN",
    lat: 47.4913,
    lng: -92.4612,
    id: 1840007689,
  },
  {
    name: "Galena",
    state_id: "IN",
    lat: 38.3513,
    lng: -85.9372,
    id: 1840006265,
  },
  {
    name: "Sneads",
    state_id: "FL",
    lat: 30.7085,
    lng: -84.9249,
    id: 1840018207,
  },
  {
    name: "French Lick",
    state_id: "IN",
    lat: 38.5462,
    lng: -86.6202,
    id: 1840010778,
  },
  {
    name: "Bovina",
    state_id: "TX",
    lat: 34.5157,
    lng: -102.8846,
    id: 1840019251,
  },
  {
    name: "Kearney Park",
    state_id: "MS",
    lat: 32.5897,
    lng: -90.3158,
    id: 1840025892,
  },
  {
    name: "Dallas Center",
    state_id: "IA",
    lat: 41.6854,
    lng: -93.9816,
    id: 1840007052,
  },
  {
    name: "Orchard Grass Hills",
    state_id: "KY",
    lat: 38.3234,
    lng: -85.5236,
    id: 1840030806,
  },
  {
    name: "Folsom",
    state_id: "NJ",
    lat: 39.5928,
    lng: -74.8424,
    id: 1840003799,
  },
  {
    name: "Port Carbon",
    state_id: "PA",
    lat: 40.6969,
    lng: -76.1666,
    id: 1840002773,
  },
  {
    name: "Milan",
    state_id: "MO",
    lat: 40.2031,
    lng: -93.124,
    id: 1840008361,
  },
  {
    name: "Marion",
    state_id: "KS",
    lat: 38.355,
    lng: -97.0087,
    id: 1840003843,
  },
  {
    name: "Allentown",
    state_id: "NJ",
    lat: 40.1777,
    lng: -74.5873,
    id: 1840003683,
  },
  {
    name: "Wabasso Beach",
    state_id: "FL",
    lat: 27.7569,
    lng: -80.3991,
    id: 1840014161,
  },
  {
    name: "Sardis City",
    state_id: "AL",
    lat: 34.1749,
    lng: -86.1125,
    id: 1840017967,
  },
  {
    name: "Morgantown",
    state_id: "MS",
    lat: 31.5751,
    lng: -91.3521,
    id: 1840139473,
  },
  {
    name: "Lake City",
    state_id: "TN",
    lat: 36.2242,
    lng: -84.1547,
    id: 1840145968,
  },
  {
    name: "Big Lake",
    state_id: "WA",
    lat: 48.3933,
    lng: -122.2422,
    id: 1840017313,
  },
  {
    name: "Bagdad",
    state_id: "AZ",
    lat: 34.5771,
    lng: -113.1771,
    id: 1840017845,
  },
  {
    name: "Zolfo Springs",
    state_id: "FL",
    lat: 27.4926,
    lng: -81.7869,
    id: 1840017249,
  },
  {
    name: "Glencoe",
    state_id: "FL",
    lat: 29.0005,
    lng: -80.9604,
    id: 1840014041,
  },
  {
    name: "Ravensdale",
    state_id: "WA",
    lat: 47.3563,
    lng: -121.9692,
    id: 1840018412,
  },
  {
    name: "Sabana Hoyos",
    state_id: "PR",
    lat: 18.4281,
    lng: -66.6117,
    id: 1630035568,
  },
  {
    name: "Loretto",
    state_id: "TN",
    lat: 35.0794,
    lng: -87.4402,
    id: 1840015440,
  },
  {
    name: "Lagunitas-Forest Knolls",
    state_id: "CA",
    lat: 38.018,
    lng: -122.6914,
    id: 1840074238,
  },
  {
    name: "Stinnett",
    state_id: "TX",
    lat: 35.8231,
    lng: -101.4436,
    id: 1840021703,
  },
  {
    name: "Forsyth",
    state_id: "MT",
    lat: 46.2669,
    lng: -106.6748,
    id: 1840019879,
  },
  {
    name: "Southport",
    state_id: "IN",
    lat: 39.66,
    lng: -86.1171,
    id: 1840009529,
  },
  {
    name: "Humboldt",
    state_id: "KS",
    lat: 37.8119,
    lng: -95.437,
    id: 1840001680,
  },
  {
    name: "Kiawah Island",
    state_id: "SC",
    lat: 32.6159,
    lng: -80.0607,
    id: 1840017282,
  },
  {
    name: "Fort Denaud",
    state_id: "FL",
    lat: 26.7404,
    lng: -81.5241,
    id: 1840038865,
  },
  {
    name: "Duson",
    state_id: "LA",
    lat: 30.2364,
    lng: -92.193,
    id: 1840017201,
  },
  {
    name: "South Taft",
    state_id: "CA",
    lat: 35.1293,
    lng: -119.4575,
    id: 1840019130,
  },
  {
    name: "Cannon Beach",
    state_id: "OR",
    lat: 45.8884,
    lng: -123.9602,
    id: 1840018505,
  },
  {
    name: "Marlette",
    state_id: "MI",
    lat: 43.3268,
    lng: -83.0807,
    id: 1840002701,
  },
  {
    name: "Mountain Lodge Park",
    state_id: "NY",
    lat: 41.3862,
    lng: -74.1389,
    id: 1840026299,
  },
  {
    name: "Sea Girt",
    state_id: "NJ",
    lat: 40.1308,
    lng: -74.0358,
    id: 1840001362,
  },
  {
    name: "Camden",
    state_id: "AL",
    lat: 32.0007,
    lng: -87.2967,
    id: 1840001060,
  },
  {
    name: "Mechanicsville",
    state_id: "MD",
    lat: 38.4355,
    lng: -76.7424,
    id: 1840026743,
  },
  {
    name: "WaKeeney",
    state_id: "KS",
    lat: 39.0234,
    lng: -99.8814,
    id: 1840010705,
  },
  {
    name: "Lattingtown",
    state_id: "NY",
    lat: 40.8922,
    lng: -73.5966,
    id: 1840005301,
  },
  {
    name: "McCleary",
    state_id: "WA",
    lat: 47.0584,
    lng: -123.2722,
    id: 1840019846,
  },
  {
    name: "Shamrock",
    state_id: "TX",
    lat: 35.2153,
    lng: -100.2461,
    id: 1840021758,
  },
  {
    name: "Shannon",
    state_id: "MS",
    lat: 34.1144,
    lng: -88.6931,
    id: 1840017943,
  },
  {
    name: "Port Edwards",
    state_id: "WI",
    lat: 44.3484,
    lng: -89.854,
    id: 1840002340,
  },
  {
    name: "Woodville",
    state_id: "CA",
    lat: 36.0898,
    lng: -119.205,
    id: 1840019041,
  },
  {
    name: "Boronda",
    state_id: "CA",
    lat: 36.6947,
    lng: -121.6745,
    id: 1840028335,
  },
  {
    name: "Stony Point",
    state_id: "MI",
    lat: 41.9448,
    lng: -83.273,
    id: 1840006578,
  },
  {
    name: "Olivet",
    state_id: "MI",
    lat: 42.444,
    lng: -84.9246,
    id: 1840003105,
  },
  {
    name: "Silver Bay",
    state_id: "MN",
    lat: 47.294,
    lng: -91.278,
    id: 1840008824,
  },
  {
    name: "Slater",
    state_id: "MO",
    lat: 39.2227,
    lng: -93.065,
    id: 1840009613,
  },
  {
    name: "Anna Maria",
    state_id: "FL",
    lat: 27.5297,
    lng: -82.7339,
    id: 1840014171,
  },
  {
    name: "Pine Grove",
    state_id: "CA",
    lat: 38.4074,
    lng: -120.6606,
    id: 1840024637,
  },
  {
    name: "North Newton",
    state_id: "KS",
    lat: 38.0767,
    lng: -97.3475,
    id: 1840008675,
  },
  {
    name: "Santa Clara",
    state_id: "NM",
    lat: 32.7773,
    lng: -108.154,
    id: 1840023010,
  },
  {
    name: "Weyauwega",
    state_id: "WI",
    lat: 44.324,
    lng: -88.9332,
    id: 1840002354,
  },
  {
    name: "Katonah",
    state_id: "NY",
    lat: 41.2559,
    lng: -73.6856,
    id: 1840024179,
  },
  {
    name: "Surgoinsville",
    state_id: "TN",
    lat: 36.4745,
    lng: -82.8594,
    id: 1840016026,
  },
  {
    name: "Danbury",
    state_id: "TX",
    lat: 29.2274,
    lng: -95.3461,
    id: 1840019675,
  },
  {
    name: "Belleville",
    state_id: "PA",
    lat: 40.606,
    lng: -77.721,
    id: 1840005368,
  },
  {
    name: "Lincoln Park",
    state_id: "PA",
    lat: 40.3147,
    lng: -75.9886,
    id: 1840035063,
  },
  {
    name: "Butler",
    state_id: "GA",
    lat: 32.557,
    lng: -84.2377,
    id: 1840013809,
  },
  {
    name: "Luther",
    state_id: "OK",
    lat: 35.668,
    lng: -97.1905,
    id: 1840021748,
  },
  {
    name: "Elkhart",
    state_id: "KS",
    lat: 37.0038,
    lng: -101.8944,
    id: 1840008759,
  },
  {
    name: "Pomeroy",
    state_id: "OH",
    lat: 39.0292,
    lng: -82.034,
    id: 1840012746,
  },
  {
    name: "East Pittsburgh",
    state_id: "PA",
    lat: 40.3969,
    lng: -79.8382,
    id: 1840001226,
  },
  {
    name: "Palmyra",
    state_id: "WI",
    lat: 42.8811,
    lng: -88.6,
    id: 1840003005,
  },
  {
    name: "Salem",
    state_id: "NC",
    lat: 35.6997,
    lng: -81.7004,
    id: 1840013392,
  },
  {
    name: "South Dos Palos",
    state_id: "CA",
    lat: 36.9707,
    lng: -120.6467,
    id: 1840018931,
  },
  {
    name: "Dover Beaches North",
    state_id: "NJ",
    lat: 39.9921,
    lng: -74.0715,
    id: 1840005528,
  },
  {
    name: "Morovis",
    state_id: "PR",
    lat: 18.3269,
    lng: -66.4058,
    id: 1630035526,
  },
  {
    name: "Lorena",
    state_id: "TX",
    lat: 31.3818,
    lng: -97.2127,
    id: 1840020811,
  },
  {
    name: "Lake Camelot",
    state_id: "IL",
    lat: 40.6341,
    lng: -89.7509,
    id: 1840029900,
  },
  {
    name: "Río Blanco",
    state_id: "PR",
    lat: 18.2123,
    lng: -65.793,
    id: 1630023707,
  },
  {
    name: "Questa",
    state_id: "NM",
    lat: 36.7114,
    lng: -105.5935,
    id: 1840022639,
  },
  {
    name: "Bourbon",
    state_id: "IN",
    lat: 41.2993,
    lng: -86.1167,
    id: 1840009299,
  },
  {
    name: "Cooperstown",
    state_id: "NY",
    lat: 42.6996,
    lng: -74.93,
    id: 1840004460,
  },
  {
    name: "Jeffersonville",
    state_id: "KY",
    lat: 37.9659,
    lng: -83.832,
    id: 1840014320,
  },
  {
    name: "Wright",
    state_id: "WY",
    lat: 43.7484,
    lng: -105.4963,
    id: 1840022344,
  },
  {
    name: "Langdon",
    state_id: "ND",
    lat: 48.7624,
    lng: -98.3747,
    id: 1840000027,
  },
  {
    name: "Bellwood",
    state_id: "PA",
    lat: 40.6009,
    lng: -78.3343,
    id: 1840001081,
  },
  {
    name: "Pine Mountain Club",
    state_id: "CA",
    lat: 34.8446,
    lng: -119.1669,
    id: 1840028071,
  },
  {
    name: "Tri-Lakes",
    state_id: "IN",
    lat: 41.2507,
    lng: -85.4433,
    id: 1840005007,
  },
  {
    name: "Central Square",
    state_id: "NY",
    lat: 43.286,
    lng: -76.1419,
    id: 1840004197,
  },
  {
    name: "Farley",
    state_id: "IA",
    lat: 42.4437,
    lng: -91.0092,
    id: 1840008046,
  },
  {
    name: "Rafter J Ranch",
    state_id: "WY",
    lat: 43.4317,
    lng: -110.7922,
    id: 1840038440,
  },
  {
    name: "Gallatin",
    state_id: "MO",
    lat: 39.9106,
    lng: -93.9642,
    id: 1840008391,
  },
  {
    name: "Randolph",
    state_id: "WI",
    lat: 43.5396,
    lng: -89.0029,
    id: 1840002744,
  },
  {
    name: "Catlettsburg",
    state_id: "KY",
    lat: 38.4189,
    lng: -82.6024,
    id: 1840014288,
  },
  {
    name: "Lewisburg",
    state_id: "OH",
    lat: 39.8508,
    lng: -84.5434,
    id: 1840012494,
  },
  {
    name: "Calico Rock",
    state_id: "AR",
    lat: 36.1399,
    lng: -92.1337,
    id: 1840013352,
  },
  {
    name: "Elberta",
    state_id: "AL",
    lat: 30.3855,
    lng: -87.5888,
    id: 1840017125,
  },
  {
    name: "Los Molinos",
    state_id: "CA",
    lat: 40.027,
    lng: -122.0981,
    id: 1840017505,
  },
  {
    name: "Monon",
    state_id: "IN",
    lat: 40.8642,
    lng: -86.8786,
    id: 1840009380,
  },
  {
    name: "Ocean Park",
    state_id: "WA",
    lat: 46.4961,
    lng: -124.0443,
    id: 1840018468,
  },
  {
    name: "Davenport",
    state_id: "WA",
    lat: 47.655,
    lng: -118.1519,
    id: 1840018404,
  },
  {
    name: "Upper Brookville",
    state_id: "NY",
    lat: 40.8475,
    lng: -73.5628,
    id: 1840005330,
  },
  {
    name: "Elizabeth Lake",
    state_id: "CA",
    lat: 34.659,
    lng: -118.3783,
    id: 1840075741,
  },
  {
    name: "Osakis",
    state_id: "MN",
    lat: 45.8649,
    lng: -95.1524,
    id: 1840008871,
  },
  {
    name: "Mio",
    state_id: "MI",
    lat: 44.6605,
    lng: -84.1418,
    id: 1840004075,
  },
  {
    name: "Baden",
    state_id: "MD",
    lat: 38.6719,
    lng: -76.7428,
    id: 1840024540,
  },
  {
    name: "Middleport",
    state_id: "NY",
    lat: 43.2117,
    lng: -78.4757,
    id: 1840004273,
  },
  {
    name: "Unionville",
    state_id: "MO",
    lat: 40.4757,
    lng: -93.0044,
    id: 1840010410,
  },
  {
    name: "Detroit Beach",
    state_id: "MI",
    lat: 41.9313,
    lng: -83.3292,
    id: 1840006580,
  },
  {
    name: "Hampton",
    state_id: "IL",
    lat: 41.5555,
    lng: -90.4047,
    id: 1840011437,
  },
  {
    name: "Ko Olina",
    state_id: "HI",
    lat: 21.3379,
    lng: -158.1188,
    id: 1840039182,
  },
  {
    name: "Fort Washakie",
    state_id: "WY",
    lat: 43.0069,
    lng: -108.9205,
    id: 1840017436,
  },
  {
    name: "City View",
    state_id: "SC",
    lat: 34.862,
    lng: -82.4249,
    id: 1840013496,
  },
  {
    name: "Fontana-on-Geneva Lake",
    state_id: "WI",
    lat: 42.5453,
    lng: -88.5702,
    id: 1840033457,
  },
  {
    name: "Pikeville",
    state_id: "TN",
    lat: 35.6027,
    lng: -85.1984,
    id: 1840014532,
  },
  {
    name: "Forest City",
    state_id: "PA",
    lat: 41.6521,
    lng: -75.4691,
    id: 1840003318,
  },
  {
    name: "Bradley",
    state_id: "WV",
    lat: 37.8675,
    lng: -81.2046,
    id: 1840006345,
  },
  {
    name: "South Whitley",
    state_id: "IN",
    lat: 41.0838,
    lng: -85.6278,
    id: 1840010291,
  },
  {
    name: "Fall River",
    state_id: "WI",
    lat: 43.3856,
    lng: -89.0456,
    id: 1840002706,
  },
  {
    name: "Oakley",
    state_id: "UT",
    lat: 40.7257,
    lng: -111.2788,
    id: 1840020139,
  },
  {
    name: "Lacon",
    state_id: "IL",
    lat: 41.0228,
    lng: -89.4062,
    id: 1840008279,
  },
  {
    name: "Elkland",
    state_id: "PA",
    lat: 41.989,
    lng: -77.314,
    id: 1840003297,
  },
  {
    name: "Le Grand",
    state_id: "CA",
    lat: 37.2288,
    lng: -120.254,
    id: 1840017627,
  },
  {
    name: "Grove Hill",
    state_id: "AL",
    lat: 31.7006,
    lng: -87.7748,
    id: 1840005924,
  },
  {
    name: "Monroe North",
    state_id: "WA",
    lat: 47.8828,
    lng: -121.9885,
    id: 1840042006,
  },
  {
    name: "Gruetli-Laager",
    state_id: "TN",
    lat: 35.3725,
    lng: -85.6375,
    id: 1840013441,
  },
  {
    name: "Honey Grove",
    state_id: "TX",
    lat: 33.5866,
    lng: -95.909,
    id: 1840020596,
  },
  {
    name: "Buna",
    state_id: "TX",
    lat: 30.4458,
    lng: -93.9625,
    id: 1840018197,
  },
  {
    name: "Huachuca City",
    state_id: "AZ",
    lat: 31.6308,
    lng: -110.3422,
    id: 1840022106,
  },
  {
    name: "Vázquez",
    state_id: "PR",
    lat: 18.0688,
    lng: -66.2339,
    id: 1630023631,
  },
  {
    name: "Baldwinville",
    state_id: "MA",
    lat: 42.6049,
    lng: -72.0771,
    id: 1840003142,
  },
  {
    name: "Toquerville",
    state_id: "UT",
    lat: 37.2666,
    lng: -113.2951,
    id: 1840022560,
  },
  {
    name: "Itasca",
    state_id: "TX",
    lat: 32.1586,
    lng: -97.1478,
    id: 1840019508,
  },
  {
    name: "Eaton",
    state_id: "IN",
    lat: 40.3218,
    lng: -85.3591,
    id: 1840010459,
  },
  {
    name: "Louisa",
    state_id: "VA",
    lat: 38.0212,
    lng: -77.9985,
    id: 1840006312,
  },
  {
    name: "Stafford",
    state_id: "OR",
    lat: 45.3786,
    lng: -122.6823,
    id: 1840023910,
  },
  {
    name: "Nissequogue",
    state_id: "NY",
    lat: 40.9023,
    lng: -73.1923,
    id: 1840005124,
  },
  {
    name: "Plentywood",
    state_id: "MT",
    lat: 48.7761,
    lng: -104.5574,
    id: 1840019767,
  },
  {
    name: "Wanchese",
    state_id: "NC",
    lat: 35.8396,
    lng: -75.6379,
    id: 1840013370,
  },
  {
    name: "Rector",
    state_id: "AR",
    lat: 36.2642,
    lng: -90.2935,
    id: 1840014459,
  },
  {
    name: "Decatur",
    state_id: "MI",
    lat: 42.1075,
    lng: -85.9746,
    id: 1840011206,
  },
  {
    name: "Pine Bush",
    state_id: "NY",
    lat: 41.6094,
    lng: -74.2966,
    id: 1840004860,
  },
  {
    name: "Baiting Hollow",
    state_id: "NY",
    lat: 40.9645,
    lng: -72.7402,
    id: 1840005016,
  },
  {
    name: "McEwen",
    state_id: "TN",
    lat: 36.1099,
    lng: -87.6355,
    id: 1840015330,
  },
  {
    name: "Ben Avon",
    state_id: "PA",
    lat: 40.5058,
    lng: -80.0814,
    id: 1840001204,
  },
  {
    name: "West Pasco",
    state_id: "WA",
    lat: 46.2497,
    lng: -119.1683,
    id: 1840037891,
  },
  {
    name: "Makaha Valley",
    state_id: "HI",
    lat: 21.4842,
    lng: -158.1854,
    id: 1840029585,
  },
  {
    name: "Whitwell",
    state_id: "TN",
    lat: 35.1918,
    lng: -85.5212,
    id: 1840015472,
  },
  {
    name: "El Tumbao",
    state_id: "PR",
    lat: 18.0015,
    lng: -66.8996,
    id: 1630027032,
  },
  {
    name: "Pineville",
    state_id: "KY",
    lat: 36.7537,
    lng: -83.7087,
    id: 1840014407,
  },
  {
    name: "Oberlin",
    state_id: "LA",
    lat: 30.607,
    lng: -92.7724,
    id: 1840017168,
  },
  {
    name: "Hampden-Sydney",
    state_id: "VA",
    lat: 37.2416,
    lng: -78.4693,
    id: 1840037380,
  },
  {
    name: "Estell Manor",
    state_id: "NJ",
    lat: 39.3587,
    lng: -74.7752,
    id: 1840003796,
  },
  {
    name: "Kirkland",
    state_id: "IL",
    lat: 42.0905,
    lng: -88.8491,
    id: 1840011336,
  },
  {
    name: "Mont Alto",
    state_id: "PA",
    lat: 39.8404,
    lng: -77.5555,
    id: 1840001413,
  },
  {
    name: "Cherryville",
    state_id: "PA",
    lat: 40.7549,
    lng: -75.5309,
    id: 1840034901,
  },
  {
    name: "Pierce",
    state_id: "NE",
    lat: 42.199,
    lng: -97.5284,
    id: 1840009135,
  },
  {
    name: "Bridgeport",
    state_id: "OH",
    lat: 40.0673,
    lng: -80.7473,
    id: 1840010516,
  },
  {
    name: "Stella",
    state_id: "PR",
    lat: 18.3235,
    lng: -67.2462,
    id: 1630023615,
  },
  {
    name: "Stuart",
    state_id: "IA",
    lat: 41.5003,
    lng: -94.3187,
    id: 1840009296,
  },
  {
    name: "Toro Canyon",
    state_id: "CA",
    lat: 34.4235,
    lng: -119.5568,
    id: 1840028353,
  },
  {
    name: "Lillian",
    state_id: "AL",
    lat: 30.4122,
    lng: -87.432,
    id: 1840025228,
  },
  {
    name: "Lester Prairie",
    state_id: "MN",
    lat: 44.8829,
    lng: -94.0374,
    id: 1840007846,
  },
  {
    name: "Lowry Crossing",
    state_id: "TX",
    lat: 33.171,
    lng: -96.5444,
    id: 1840020655,
  },
  {
    name: "Merritt Park",
    state_id: "NY",
    lat: 41.5385,
    lng: -73.8724,
    id: 1840040260,
  },
  {
    name: "Harpersville",
    state_id: "AL",
    lat: 33.3184,
    lng: -86.4287,
    id: 1840016847,
  },
  {
    name: "Sandy Valley",
    state_id: "NV",
    lat: 35.8423,
    lng: -115.6304,
    id: 1840019026,
  },
  {
    name: "Westover",
    state_id: "AL",
    lat: 33.3685,
    lng: -86.5346,
    id: 1840016849,
  },
  {
    name: "Webster",
    state_id: "SD",
    lat: 45.3366,
    lng: -97.5218,
    id: 1840001555,
  },
  {
    name: "South Willard",
    state_id: "UT",
    lat: 41.3583,
    lng: -112.0408,
    id: 1840037248,
  },
  {
    name: "Lost Hills",
    state_id: "CA",
    lat: 35.6254,
    lng: -119.6789,
    id: 1840017814,
  },
  {
    name: "Fredericksburg",
    state_id: "PA",
    lat: 40.4441,
    lng: -76.4377,
    id: 1840005430,
  },
  {
    name: "Town Line",
    state_id: "NY",
    lat: 42.8858,
    lng: -78.561,
    id: 1840004382,
  },
  {
    name: "Coal City",
    state_id: "WV",
    lat: 37.6776,
    lng: -81.218,
    id: 1840038232,
  },
  {
    name: "Manitou Beach-Devils Lake",
    state_id: "MI",
    lat: 41.9756,
    lng: -84.2862,
    id: 1840073765,
  },
  {
    name: "Amity",
    state_id: "OR",
    lat: 45.1155,
    lng: -123.2034,
    id: 1840018572,
  },
  {
    name: "Crested Butte",
    state_id: "CO",
    lat: 38.8675,
    lng: -106.9772,
    id: 1840021464,
  },
  {
    name: "Dravosburg",
    state_id: "PA",
    lat: 40.3509,
    lng: -79.8906,
    id: 1840001223,
  },
  {
    name: "Chenoa",
    state_id: "IL",
    lat: 40.7389,
    lng: -88.7259,
    id: 1840007206,
  },
  {
    name: "Akron",
    state_id: "CO",
    lat: 40.1635,
    lng: -103.2202,
    id: 1840021415,
  },
  {
    name: "Sparta",
    state_id: "NC",
    lat: 36.5034,
    lng: -81.1217,
    id: 1840017700,
  },
  {
    name: "Las Maravillas",
    state_id: "NM",
    lat: 34.734,
    lng: -106.669,
    id: 1840033726,
  },
  {
    name: "Lamar",
    state_id: "AR",
    lat: 35.4436,
    lng: -93.3951,
    id: 1840014535,
  },
  {
    name: "Ragland",
    state_id: "AL",
    lat: 33.7469,
    lng: -86.1384,
    id: 1840017994,
  },
  {
    name: "Wesson",
    state_id: "MS",
    lat: 31.7,
    lng: -90.396,
    id: 1840017047,
  },
  {
    name: "Deshler",
    state_id: "OH",
    lat: 41.2075,
    lng: -83.9056,
    id: 1840011606,
  },
  {
    name: "Pennington Gap",
    state_id: "VA",
    lat: 36.7594,
    lng: -83.03,
    id: 1840006535,
  },
  {
    name: "Butler",
    state_id: "AL",
    lat: 32.0965,
    lng: -88.2108,
    id: 1840015823,
  },
  {
    name: "Whitewright",
    state_id: "TX",
    lat: 33.5108,
    lng: -96.3955,
    id: 1840022936,
  },
  {
    name: "Lewisville",
    state_id: "WA",
    lat: 45.8128,
    lng: -122.5143,
    id: 1840037855,
  },
  {
    name: "Warrenton",
    state_id: "GA",
    lat: 33.4067,
    lng: -82.6653,
    id: 1840015710,
  },
  {
    name: "Reynolds Heights",
    state_id: "PA",
    lat: 41.3432,
    lng: -80.4033,
    id: 1840026316,
  },
  {
    name: "Holley",
    state_id: "FL",
    lat: 30.454,
    lng: -86.9036,
    id: 1840027015,
  },
  {
    name: "Greenvale",
    state_id: "NY",
    lat: 40.8118,
    lng: -73.6262,
    id: 1840005261,
  },
  {
    name: "Prairie City",
    state_id: "IA",
    lat: 41.5954,
    lng: -93.2393,
    id: 1840009224,
  },
  {
    name: "Prudhoe Bay",
    state_id: "AK",
    lat: 70.3128,
    lng: -148.6955,
    id: 1840023478,
  },
  {
    name: "Trumansburg",
    state_id: "NY",
    lat: 42.541,
    lng: -76.6618,
    id: 1840004545,
  },
  {
    name: "Peebles",
    state_id: "OH",
    lat: 38.9463,
    lng: -83.4094,
    id: 1840012770,
  },
  {
    name: "Sun Prairie",
    state_id: "MT",
    lat: 47.5386,
    lng: -111.4919,
    id: 1840136955,
  },
  {
    name: "Meridian Hills",
    state_id: "IN",
    lat: 39.8874,
    lng: -86.1567,
    id: 1840009533,
  },
  {
    name: "Armada",
    state_id: "MI",
    lat: 42.8424,
    lng: -82.8832,
    id: 1840011049,
  },
  {
    name: "Moweaqua",
    state_id: "IL",
    lat: 39.6265,
    lng: -89.0187,
    id: 1840012589,
  },
  {
    name: "Stonewood",
    state_id: "WV",
    lat: 39.2501,
    lng: -80.3055,
    id: 1840005770,
  },
  {
    name: "East Palatka",
    state_id: "FL",
    lat: 29.6502,
    lng: -81.5998,
    id: 1840013115,
  },
  {
    name: "Redwood Valley",
    state_id: "CA",
    lat: 39.269,
    lng: -123.2023,
    id: 1840026602,
  },
  {
    name: "Frewsburg",
    state_id: "NY",
    lat: 42.0576,
    lng: -79.1482,
    id: 1840004563,
  },
  {
    name: "Stockton",
    state_id: "IL",
    lat: 42.3526,
    lng: -90.0019,
    id: 1840011109,
  },
  {
    name: "Zeigler",
    state_id: "IL",
    lat: 37.9067,
    lng: -89.0521,
    id: 1840010834,
  },
  {
    name: "North Judson",
    state_id: "IN",
    lat: 41.2161,
    lng: -86.7769,
    id: 1840009310,
  },
  {
    name: "Chadbourn",
    state_id: "NC",
    lat: 34.3251,
    lng: -78.825,
    id: 1840016663,
  },
  {
    name: "Maroa",
    state_id: "IL",
    lat: 40.0366,
    lng: -88.9561,
    id: 1840008397,
  },
  {
    name: "Industry",
    state_id: "PA",
    lat: 40.6595,
    lng: -80.4115,
    id: 1840003586,
  },
  {
    name: "Roanoke",
    state_id: "IN",
    lat: 40.9639,
    lng: -85.3761,
    id: 1840010340,
  },
  {
    name: "Choteau",
    state_id: "MT",
    lat: 47.8137,
    lng: -112.1794,
    id: 1840018391,
  },
  {
    name: "Duchesne",
    state_id: "UT",
    lat: 40.1753,
    lng: -110.394,
    id: 1840018751,
  },
  {
    name: "Evans City",
    state_id: "PA",
    lat: 40.7685,
    lng: -80.0584,
    id: 1840000864,
  },
  {
    name: "Westlake",
    state_id: "TX",
    lat: 32.981,
    lng: -97.2038,
    id: 1840022994,
  },
  {
    name: "Hughes Springs",
    state_id: "TX",
    lat: 32.9987,
    lng: -94.631,
    id: 1840020678,
  },
  {
    name: "Bear Creek",
    state_id: "FL",
    lat: 27.7542,
    lng: -82.7285,
    id: 1840038846,
  },
  {
    name: "Tekamah",
    state_id: "NE",
    lat: 41.778,
    lng: -96.2247,
    id: 1840003263,
  },
  {
    name: "Nanawale Estates",
    state_id: "HI",
    lat: 19.5042,
    lng: -154.9117,
    id: 1840029574,
  },
  {
    name: "Bessemer",
    state_id: "MI",
    lat: 46.4775,
    lng: -90.0498,
    id: 1840001941,
  },
  {
    name: "Clinton",
    state_id: "NY",
    lat: 43.0488,
    lng: -75.3785,
    id: 1840004223,
  },
  {
    name: "Bartonsville",
    state_id: "MD",
    lat: 39.3866,
    lng: -77.3434,
    id: 1840024449,
  },
  {
    name: "Syracuse",
    state_id: "KS",
    lat: 37.9619,
    lng: -101.7782,
    id: 1840009756,
  },
  {
    name: "Keeler Farm",
    state_id: "NM",
    lat: 32.3161,
    lng: -107.7602,
    id: 1840040084,
  },
  {
    name: "Shamokin Dam",
    state_id: "PA",
    lat: 40.8547,
    lng: -76.8231,
    id: 1840002800,
  },
  {
    name: "Biola",
    state_id: "CA",
    lat: 36.8006,
    lng: -120.0199,
    id: 1840017629,
  },
  {
    name: "Whiting",
    state_id: "WI",
    lat: 44.4907,
    lng: -89.56,
    id: 1840002321,
  },
  {
    name: "Bridgeport",
    state_id: "IL",
    lat: 38.7095,
    lng: -87.759,
    id: 1840006131,
  },
  {
    name: "Montverde",
    state_id: "FL",
    lat: 28.5971,
    lng: -81.6787,
    id: 1840017230,
  },
  {
    name: "Decatur",
    state_id: "MS",
    lat: 32.4339,
    lng: -89.1092,
    id: 1840016988,
  },
  {
    name: "Ridgeville",
    state_id: "SC",
    lat: 33.09,
    lng: -80.3071,
    id: 1840018041,
  },
  {
    name: "Sorrento",
    state_id: "LA",
    lat: 30.1828,
    lng: -90.8674,
    id: 1840018244,
  },
  {
    name: "Kayak Point",
    state_id: "WA",
    lat: 48.1387,
    lng: -122.3342,
    id: 1840041938,
  },
  {
    name: "Meadowlakes",
    state_id: "TX",
    lat: 30.5639,
    lng: -98.2954,
    id: 1840020871,
  },
  {
    name: "Warsaw",
    state_id: "KY",
    lat: 38.7773,
    lng: -84.9042,
    id: 1840015178,
  },
  {
    name: "Rosebud",
    state_id: "SD",
    lat: 43.2399,
    lng: -100.823,
    id: 1840004258,
  },
  {
    name: "Richlands",
    state_id: "NC",
    lat: 34.9003,
    lng: -77.5427,
    id: 1840017901,
  },
  {
    name: "Chilhowie",
    state_id: "VA",
    lat: 36.8009,
    lng: -81.6832,
    id: 1840006514,
  },
  {
    name: "Archer City",
    state_id: "TX",
    lat: 33.5937,
    lng: -98.6257,
    id: 1840019334,
  },
  {
    name: "Twin City",
    state_id: "GA",
    lat: 32.5829,
    lng: -82.1575,
    id: 1840015788,
  },
  {
    name: "Scenic",
    state_id: "AZ",
    lat: 36.7931,
    lng: -114.0151,
    id: 1840075488,
  },
  {
    name: "Fairfax",
    state_id: "OH",
    lat: 39.1432,
    lng: -84.3972,
    id: 1840012701,
  },
  {
    name: "Big Timber",
    state_id: "MT",
    lat: 45.8347,
    lng: -109.9487,
    id: 1840018506,
  },
  {
    name: "La Grulla",
    state_id: "TX",
    lat: 26.272,
    lng: -98.6483,
    id: 1840021018,
  },
  {
    name: "Oakes",
    state_id: "ND",
    lat: 46.1398,
    lng: -98.0871,
    id: 1840000256,
  },
  {
    name: "Oberlin",
    state_id: "KS",
    lat: 39.823,
    lng: -100.5308,
    id: 1840003766,
  },
  {
    name: "Jonesboro",
    state_id: "IL",
    lat: 37.451,
    lng: -89.2666,
    id: 1840008737,
  },
  {
    name: "Cassopolis",
    state_id: "MI",
    lat: 41.9116,
    lng: -86.0085,
    id: 1840010159,
  },
  {
    name: "Gaston",
    state_id: "SC",
    lat: 33.818,
    lng: -81.0997,
    id: 1840016726,
  },
  {
    name: "Caddo Mills",
    state_id: "TX",
    lat: 33.0504,
    lng: -96.2279,
    id: 1840019392,
  },
  {
    name: "Labadieville",
    state_id: "LA",
    lat: 29.8247,
    lng: -90.9525,
    id: 1840014010,
  },
  {
    name: "Glen Lyon",
    state_id: "PA",
    lat: 41.1795,
    lng: -76.0783,
    id: 1840004912,
  },
  {
    name: "South Bend",
    state_id: "WA",
    lat: 46.6678,
    lng: -123.8006,
    id: 1840021161,
  },
  {
    name: "McMechen",
    state_id: "WV",
    lat: 39.9854,
    lng: -80.7335,
    id: 1840005556,
  },
  {
    name: "Teaticket",
    state_id: "MA",
    lat: 41.5624,
    lng: -70.5858,
    id: 1840004767,
  },
  {
    name: "North Bennington",
    state_id: "VT",
    lat: 42.924,
    lng: -73.2411,
    id: 1840004301,
  },
  {
    name: "Ripley",
    state_id: "OH",
    lat: 38.7325,
    lng: -83.8346,
    id: 1840012720,
  },
  {
    name: "Bruceville-Eddy",
    state_id: "TX",
    lat: 31.3103,
    lng: -97.2478,
    id: 1840003906,
  },
  {
    name: "Haubstadt",
    state_id: "IN",
    lat: 38.2039,
    lng: -87.5752,
    id: 1840010798,
  },
  {
    name: "Punta Rassa",
    state_id: "FL",
    lat: 26.5036,
    lng: -81.9998,
    id: 1840014225,
  },
  {
    name: "Pleasantville",
    state_id: "IA",
    lat: 41.3864,
    lng: -93.2727,
    id: 1840009294,
  },
  {
    name: "Cheswold",
    state_id: "DE",
    lat: 39.2189,
    lng: -75.5883,
    id: 1840005814,
  },
  {
    name: "El Paraiso",
    state_id: "PR",
    lat: 18.0648,
    lng: -66.6021,
    id: 1630035699,
  },
  {
    name: "Wind Point",
    state_id: "WI",
    lat: 42.7814,
    lng: -87.7721,
    id: 1840002489,
  },
  {
    name: "Mayfield",
    state_id: "PA",
    lat: 41.5394,
    lng: -75.5315,
    id: 1840003384,
  },
  {
    name: "Wilson",
    state_id: "OK",
    lat: 34.1685,
    lng: -97.426,
    id: 1840021899,
  },
  {
    name: "Romney",
    state_id: "WV",
    lat: 39.3454,
    lng: -78.7564,
    id: 1840005756,
  },
  {
    name: "Rushford",
    state_id: "MN",
    lat: 43.8131,
    lng: -91.7535,
    id: 1840009014,
  },
  {
    name: "Jauca",
    state_id: "PR",
    lat: 17.9627,
    lng: -66.3682,
    id: 1630023547,
  },
  {
    name: "Kualapuu",
    state_id: "HI",
    lat: 21.1563,
    lng: -157.0591,
    id: 1840029511,
  },
  {
    name: "Pumpkin Center",
    state_id: "NC",
    lat: 34.786,
    lng: -77.3642,
    id: 1840013559,
  },
  {
    name: "Bristol",
    state_id: "IN",
    lat: 41.7183,
    lng: -85.8227,
    id: 1840009238,
  },
  {
    name: "Mapleton",
    state_id: "MN",
    lat: 43.9267,
    lng: -93.9544,
    id: 1840008976,
  },
  {
    name: "Mulberry",
    state_id: "AR",
    lat: 35.5102,
    lng: -94.0734,
    id: 1840014538,
  },
  {
    name: "Plantsville",
    state_id: "CT",
    lat: 41.5832,
    lng: -72.8915,
    id: 1840028595,
  },
  {
    name: "Angola on the Lake",
    state_id: "NY",
    lat: 42.655,
    lng: -79.0523,
    id: 1840004373,
  },
  {
    name: "Wilder",
    state_id: "VT",
    lat: 43.673,
    lng: -72.3109,
    id: 1840004163,
  },
  {
    name: "White Castle",
    state_id: "LA",
    lat: 30.161,
    lng: -91.1496,
    id: 1840017193,
  },
  {
    name: "Spencer",
    state_id: "TN",
    lat: 35.7393,
    lng: -85.456,
    id: 1840017790,
  },
  {
    name: "Minorca",
    state_id: "LA",
    lat: 31.5725,
    lng: -91.4884,
    id: 1840025947,
  },
  {
    name: "Dresden",
    state_id: "OH",
    lat: 40.122,
    lng: -82.013,
    id: 1840012390,
  },
  {
    name: "Fort Montgomery",
    state_id: "NY",
    lat: 41.3401,
    lng: -73.9853,
    id: 1840004855,
  },
  {
    name: "Heritage Lake",
    state_id: "IL",
    lat: 40.5454,
    lng: -89.3309,
    id: 1840029901,
  },
  {
    name: "London",
    state_id: "CA",
    lat: 36.481,
    lng: -119.444,
    id: 1840017692,
  },
  {
    name: "Des Allemands",
    state_id: "LA",
    lat: 29.8162,
    lng: -90.4746,
    id: 1840013109,
  },
  {
    name: "Lewisport",
    state_id: "KY",
    lat: 37.931,
    lng: -86.9029,
    id: 1840014333,
  },
  {
    name: "Searles Valley",
    state_id: "CA",
    lat: 35.7701,
    lng: -117.3967,
    id: 1840028399,
  },
  {
    name: "Temple",
    state_id: "PA",
    lat: 40.4085,
    lng: -75.9225,
    id: 1840005392,
  },
  {
    name: "Smackover",
    state_id: "AR",
    lat: 33.3636,
    lng: -92.7314,
    id: 1840015732,
  },
  {
    name: "Flora Vista",
    state_id: "NM",
    lat: 36.8056,
    lng: -108.0856,
    id: 1840017670,
  },
  {
    name: "Three Way",
    state_id: "TN",
    lat: 35.772,
    lng: -88.8572,
    id: 1840036153,
  },
  {
    name: "Bunker Hill",
    state_id: "IL",
    lat: 39.0416,
    lng: -89.9512,
    id: 1840007365,
  },
  {
    name: "Mammoth",
    state_id: "AZ",
    lat: 32.6853,
    lng: -110.7292,
    id: 1840022980,
  },
  {
    name: "Bylas",
    state_id: "AZ",
    lat: 33.1292,
    lng: -110.1163,
    id: 1840022957,
  },
  {
    name: "Rocky Point",
    state_id: "NC",
    lat: 34.4441,
    lng: -77.8909,
    id: 1840025848,
  },
  {
    name: "King Arthur Park",
    state_id: "MT",
    lat: 45.6664,
    lng: -111.1268,
    id: 1840032824,
  },
  {
    name: "Upper Fruitland",
    state_id: "NM",
    lat: 36.7205,
    lng: -108.3234,
    id: 1840033717,
  },
  {
    name: "Brownlee Park",
    state_id: "MI",
    lat: 42.3257,
    lng: -85.1356,
    id: 1840004655,
  },
  {
    name: "Tonto Basin",
    state_id: "AZ",
    lat: 33.8361,
    lng: -111.3003,
    id: 1840019264,
  },
  {
    name: "Shaw",
    state_id: "MS",
    lat: 33.6015,
    lng: -90.772,
    id: 1840015628,
  },
  {
    name: "Washington Park",
    state_id: "FL",
    lat: 26.1304,
    lng: -80.1801,
    id: 1840029015,
  },
  {
    name: "Horton",
    state_id: "KS",
    lat: 39.6627,
    lng: -95.5327,
    id: 1840000727,
  },
  {
    name: "Nashville",
    state_id: "MI",
    lat: 42.6032,
    lng: -85.0941,
    id: 1840011090,
  },
  {
    name: "Hamilton",
    state_id: "MO",
    lat: 39.7426,
    lng: -94.002,
    id: 1840007320,
  },
  {
    name: "Antwerp",
    state_id: "OH",
    lat: 41.1798,
    lng: -84.7377,
    id: 1840010302,
  },
  {
    name: "Holley",
    state_id: "NY",
    lat: 43.2247,
    lng: -78.0292,
    id: 1840004262,
  },
  {
    name: "Shawneeland",
    state_id: "VA",
    lat: 39.1976,
    lng: -78.3464,
    id: 1840037404,
  },
  {
    name: "De Pue",
    state_id: "IL",
    lat: 41.328,
    lng: -89.2959,
    id: 1840073809,
  },
  {
    name: "Steep Falls",
    state_id: "ME",
    lat: 43.7905,
    lng: -70.6275,
    id: 1840024008,
  },
  {
    name: "East Lexington",
    state_id: "VA",
    lat: 37.8009,
    lng: -79.416,
    id: 1840026792,
  },
  {
    name: "Hato Candal",
    state_id: "PR",
    lat: 18.3733,
    lng: -65.7906,
    id: 1630035681,
  },
  {
    name: "Mundys Corner",
    state_id: "PA",
    lat: 40.4432,
    lng: -78.8325,
    id: 1840026420,
  },
  {
    name: "Tuckerman",
    state_id: "AR",
    lat: 35.724,
    lng: -91.2025,
    id: 1840015378,
  },
  {
    name: "Lake Como",
    state_id: "NJ",
    lat: 40.1706,
    lng: -74.0262,
    id: 1840033317,
  },
  {
    name: "Westwood Shores",
    state_id: "TX",
    lat: 30.9376,
    lng: -95.3254,
    id: 1840042658,
  },
  {
    name: "Panguitch",
    state_id: "UT",
    lat: 37.8232,
    lng: -112.4348,
    id: 1840020272,
  },
  {
    name: "Rancho Tehama Reserve",
    state_id: "CA",
    lat: 40.001,
    lng: -122.4418,
    id: 1840018761,
  },
  {
    name: "Summerdale",
    state_id: "AL",
    lat: 30.4783,
    lng: -87.6904,
    id: 1840017126,
  },
  {
    name: "Kersey",
    state_id: "CO",
    lat: 40.3895,
    lng: -104.5771,
    id: 1840021371,
  },
  {
    name: "Culver",
    state_id: "OR",
    lat: 44.5234,
    lng: -121.211,
    id: 1840018603,
  },
  {
    name: "MacArthur",
    state_id: "WV",
    lat: 37.7553,
    lng: -81.2078,
    id: 1840038266,
  },
  {
    name: "South Point",
    state_id: "TX",
    lat: 25.873,
    lng: -97.383,
    id: 1840037080,
  },
  {
    name: "Oswego",
    state_id: "KS",
    lat: 37.1673,
    lng: -95.1122,
    id: 1840003857,
  },
  {
    name: "Denton",
    state_id: "NC",
    lat: 35.6375,
    lng: -80.1106,
    id: 1840016223,
  },
  {
    name: "Island Heights",
    state_id: "NJ",
    lat: 39.9423,
    lng: -74.1454,
    id: 1840003758,
  },
  {
    name: "Commercial Point",
    state_id: "OH",
    lat: 39.7813,
    lng: -83.0383,
    id: 1840012553,
  },
  {
    name: "Roodhouse",
    state_id: "IL",
    lat: 39.484,
    lng: -90.3741,
    id: 1840009604,
  },
  {
    name: "Freeport",
    state_id: "PA",
    lat: 40.6829,
    lng: -79.6837,
    id: 1840003518,
  },
  {
    name: "Lake Sherwood",
    state_id: "CA",
    lat: 34.1339,
    lng: -118.8804,
    id: 1840042247,
  },
  {
    name: "Osseo",
    state_id: "WI",
    lat: 44.5795,
    lng: -91.212,
    id: 1840002392,
  },
  {
    name: "Haymarket",
    state_id: "VA",
    lat: 38.8122,
    lng: -77.6363,
    id: 1840006103,
  },
  {
    name: "Peridot",
    state_id: "AZ",
    lat: 33.3015,
    lng: -110.4554,
    id: 1840019261,
  },
  {
    name: "Lake Isabella",
    state_id: "MI",
    lat: 43.6417,
    lng: -85.003,
    id: 1840010961,
  },
  {
    name: "North Fork",
    state_id: "AZ",
    lat: 33.9965,
    lng: -109.9593,
    id: 1840075412,
  },
  {
    name: "Fairfax",
    state_id: "SC",
    lat: 32.9593,
    lng: -81.2363,
    id: 1840016904,
  },
  {
    name: "Cheswick",
    state_id: "PA",
    lat: 40.5428,
    lng: -79.8013,
    id: 1840001217,
  },
  {
    name: "Gunter",
    state_id: "TX",
    lat: 33.4646,
    lng: -96.7646,
    id: 1840020571,
  },
  {
    name: "South Amherst",
    state_id: "OH",
    lat: 41.3515,
    lng: -82.2394,
    id: 1840011594,
  },
  {
    name: "Oroville",
    state_id: "WA",
    lat: 48.9419,
    lng: -119.4305,
    id: 1840019762,
  },
  {
    name: "Albany",
    state_id: "MO",
    lat: 40.2479,
    lng: -94.3335,
    id: 1840007240,
  },
  {
    name: "Kingston",
    state_id: "OK",
    lat: 33.9979,
    lng: -96.6952,
    id: 1840021925,
  },
  {
    name: "Pleak",
    state_id: "TX",
    lat: 29.4852,
    lng: -95.8098,
    id: 1840023154,
  },
  {
    name: "Harrisville",
    state_id: "WV",
    lat: 39.2104,
    lng: -81.0485,
    id: 1840005791,
  },
  {
    name: "Elkview",
    state_id: "WV",
    lat: 38.4327,
    lng: -81.4771,
    id: 1840006187,
  },
  {
    name: "Sabinal",
    state_id: "TX",
    lat: 29.3214,
    lng: -99.4696,
    id: 1840020968,
  },
  {
    name: "Hagerstown",
    state_id: "IN",
    lat: 39.9115,
    lng: -85.1554,
    id: 1840010546,
  },
  {
    name: "Walnut Grove",
    state_id: "MS",
    lat: 32.5932,
    lng: -89.4578,
    id: 1840016953,
  },
  {
    name: "Cascade Valley",
    state_id: "WA",
    lat: 47.1412,
    lng: -119.3291,
    id: 1840037865,
  },
  {
    name: "Stanley",
    state_id: "VA",
    lat: 38.5769,
    lng: -78.5027,
    id: 1840006143,
  },
  {
    name: "John Day",
    state_id: "OR",
    lat: 44.4181,
    lng: -118.9535,
    id: 1840018598,
  },
  {
    name: "Princeville",
    state_id: "IL",
    lat: 40.9348,
    lng: -89.7547,
    id: 1840011926,
  },
  {
    name: "West Unity",
    state_id: "OH",
    lat: 41.5889,
    lng: -84.4305,
    id: 1840011525,
  },
  {
    name: "Daguao",
    state_id: "PR",
    lat: 18.2217,
    lng: -65.6798,
    id: 1630035659,
  },
  {
    name: "Joseph City",
    state_id: "AZ",
    lat: 34.9614,
    lng: -110.3274,
    id: 1840022578,
  },
  {
    name: "Canby",
    state_id: "MN",
    lat: 44.7157,
    lng: -96.269,
    id: 1840006763,
  },
  {
    name: "Blountsville",
    state_id: "AL",
    lat: 34.0814,
    lng: -86.5867,
    id: 1840015600,
  },
  {
    name: "Ronceverte",
    state_id: "WV",
    lat: 37.7503,
    lng: -80.4714,
    id: 1840006286,
  },
  {
    name: "Holliday",
    state_id: "TX",
    lat: 33.8142,
    lng: -98.6899,
    id: 1840020601,
  },
  {
    name: "Bluff City",
    state_id: "TN",
    lat: 36.4589,
    lng: -82.2769,
    id: 1840013300,
  },
  {
    name: "Altamont",
    state_id: "NY",
    lat: 42.7055,
    lng: -74.034,
    id: 1840004494,
  },
  {
    name: "Princeville",
    state_id: "HI",
    lat: 22.2177,
    lng: -159.4809,
    id: 1840023223,
  },
  {
    name: "Calhoun City",
    state_id: "MS",
    lat: 33.8588,
    lng: -89.3145,
    id: 1840015618,
  },
  {
    name: "Wakefield",
    state_id: "MI",
    lat: 46.4767,
    lng: -89.9336,
    id: 1840001940,
  },
  {
    name: "Gadsden",
    state_id: "SC",
    lat: 33.848,
    lng: -80.7663,
    id: 1840026976,
  },
  {
    name: "Kentland",
    state_id: "IN",
    lat: 40.774,
    lng: -87.4465,
    id: 1840010306,
  },
  {
    name: "Dayton",
    state_id: "IN",
    lat: 40.3752,
    lng: -86.7739,
    id: 1840009426,
  },
  {
    name: "Columbus AFB",
    state_id: "MS",
    lat: 33.6276,
    lng: -88.4454,
    id: 1840073848,
  },
  {
    name: "Bement",
    state_id: "IL",
    lat: 39.9228,
    lng: -88.5724,
    id: 1840012320,
  },
  {
    name: "Decatur",
    state_id: "TN",
    lat: 35.5291,
    lng: -84.7933,
    id: 1840016301,
  },
  {
    name: "Bent Creek",
    state_id: "NC",
    lat: 35.5081,
    lng: -82.6176,
    id: 1840013409,
  },
  {
    name: "Perryopolis",
    state_id: "PA",
    lat: 40.0868,
    lng: -79.7528,
    id: 1840000667,
  },
  {
    name: "Tremont",
    state_id: "PA",
    lat: 40.6302,
    lng: -76.3912,
    id: 1840002782,
  },
  {
    name: "Parcelas de Navarro",
    state_id: "PR",
    lat: 18.2301,
    lng: -66.0042,
    id: 1630025221,
  },
  {
    name: "Knollwood",
    state_id: "IL",
    lat: 42.2846,
    lng: -87.8793,
    id: 1840026206,
  },
  {
    name: "Uvalde Estates",
    state_id: "TX",
    lat: 29.1695,
    lng: -99.8329,
    id: 1840019660,
  },
  {
    name: "Trempealeau",
    state_id: "WI",
    lat: 44.003,
    lng: -91.4285,
    id: 1840002385,
  },
  {
    name: "Coaling",
    state_id: "AL",
    lat: 33.1641,
    lng: -87.3534,
    id: 1840016840,
  },
  {
    name: "Cementon",
    state_id: "PA",
    lat: 40.689,
    lng: -75.5161,
    id: 1840034894,
  },
  {
    name: "Playita",
    state_id: "PR",
    lat: 18.0405,
    lng: -65.9071,
    id: 1630023680,
  },
  {
    name: "Cosmopolis",
    state_id: "WA",
    lat: 46.954,
    lng: -123.7726,
    id: 1840018448,
  },
  {
    name: "Arcadia",
    state_id: "IN",
    lat: 40.1742,
    lng: -86.021,
    id: 1840010497,
  },
  {
    name: "Blue Grass",
    state_id: "IA",
    lat: 41.5096,
    lng: -90.7649,
    id: 1840007080,
  },
  {
    name: "Young Harris",
    state_id: "GA",
    lat: 34.9349,
    lng: -83.8472,
    id: 1840015523,
  },
  {
    name: "Southport",
    state_id: "CT",
    lat: 41.135,
    lng: -73.2874,
    id: 1840026287,
  },
  {
    name: "Channel Lake",
    state_id: "IL",
    lat: 42.483,
    lng: -88.1491,
    id: 1840029894,
  },
  {
    name: "Blackwater",
    state_id: "AZ",
    lat: 33.0396,
    lng: -111.5798,
    id: 1840018031,
  },
  {
    name: "Star Valley Ranch",
    state_id: "WY",
    lat: 42.9798,
    lng: -110.9617,
    id: 1840022364,
  },
  {
    name: "Munfordville",
    state_id: "KY",
    lat: 37.2795,
    lng: -85.8982,
    id: 1840014374,
  },
  {
    name: "Cowan",
    state_id: "TN",
    lat: 35.1629,
    lng: -86.0142,
    id: 1840014600,
  },
  {
    name: "Terrebonne",
    state_id: "OR",
    lat: 44.3533,
    lng: -121.1807,
    id: 1840018619,
  },
  {
    name: "Bay Springs",
    state_id: "MS",
    lat: 31.9772,
    lng: -89.2792,
    id: 1840013843,
  },
  {
    name: "Sedgwick",
    state_id: "KS",
    lat: 37.9157,
    lng: -97.4215,
    id: 1840009759,
  },
  {
    name: "Douglass",
    state_id: "KS",
    lat: 37.5169,
    lng: -97.0105,
    id: 1840007548,
  },
  {
    name: "Salyersville",
    state_id: "KY",
    lat: 37.7459,
    lng: -83.0646,
    id: 1840015230,
  },
  {
    name: "Murphy",
    state_id: "NC",
    lat: 35.0932,
    lng: -84.0279,
    id: 1840016437,
  },
  {
    name: "South Rockwood",
    state_id: "MI",
    lat: 42.0597,
    lng: -83.2654,
    id: 1840013159,
  },
  {
    name: "Rehobeth",
    state_id: "AL",
    lat: 31.1242,
    lng: -85.4382,
    id: 1840018195,
  },
  {
    name: "Ridgely",
    state_id: "MD",
    lat: 38.9529,
    lng: -75.8828,
    id: 1840005940,
  },
  {
    name: "Kaibito",
    state_id: "AZ",
    lat: 36.592,
    lng: -111.1071,
    id: 1840017664,
  },
  {
    name: "Tillson",
    state_id: "NY",
    lat: 41.8316,
    lng: -74.0696,
    id: 1840004725,
  },
  {
    name: "Muhlenberg Park",
    state_id: "PA",
    lat: 40.3867,
    lng: -75.9398,
    id: 1840035444,
  },
  {
    name: "Black Rock",
    state_id: "NM",
    lat: 35.0833,
    lng: -108.7979,
    id: 1840017764,
  },
  {
    name: "Plains",
    state_id: "TX",
    lat: 33.1896,
    lng: -102.8272,
    id: 1840023003,
  },
  {
    name: "Cade",
    state_id: "LA",
    lat: 30.0926,
    lng: -91.8996,
    id: 1840025122,
  },
  {
    name: "Holland",
    state_id: "OH",
    lat: 41.6192,
    lng: -83.7083,
    id: 1840011478,
  },
  {
    name: "Vance",
    state_id: "AL",
    lat: 33.1745,
    lng: -87.2313,
    id: 1840016842,
  },
  {
    name: "Metlakatla",
    state_id: "AK",
    lat: 55.1182,
    lng: -131.5689,
    id: 1840023513,
  },
  {
    name: "Ritzville",
    state_id: "WA",
    lat: 47.1253,
    lng: -118.3809,
    id: 1840019861,
  },
  {
    name: "Del Rey Oaks",
    state_id: "CA",
    lat: 36.5929,
    lng: -121.8381,
    id: 1840019024,
  },
  {
    name: "Elk Creek",
    state_id: "KY",
    lat: 38.117,
    lng: -85.3722,
    id: 1840025690,
  },
  {
    name: "Montpelier",
    state_id: "IN",
    lat: 40.5494,
    lng: -85.2874,
    id: 1840008352,
  },
  {
    name: "Juarez",
    state_id: "TX",
    lat: 26.2009,
    lng: -97.7296,
    id: 1840040893,
  },
  {
    name: "Rock Creek",
    state_id: "MN",
    lat: 45.7604,
    lng: -92.9088,
    id: 1840008856,
  },
  {
    name: "La Playa",
    state_id: "PR",
    lat: 18.2864,
    lng: -67.1866,
    id: 1630035496,
  },
  {
    name: "Canton Valley",
    state_id: "CT",
    lat: 41.8316,
    lng: -72.8978,
    id: 1840004777,
  },
  {
    name: "Hatch",
    state_id: "NM",
    lat: 32.6682,
    lng: -107.1642,
    id: 1840023025,
  },
  {
    name: "Steelville",
    state_id: "MO",
    lat: 37.9696,
    lng: -91.3545,
    id: 1840009845,
  },
  {
    name: "Lazy Mountain",
    state_id: "AK",
    lat: 61.6516,
    lng: -148.902,
    id: 1840023678,
  },
  {
    name: "Church Hill",
    state_id: "PA",
    lat: 40.6926,
    lng: -77.5949,
    id: 1840034904,
  },
  {
    name: "Stockdale",
    state_id: "TX",
    lat: 29.2361,
    lng: -97.9636,
    id: 1840022240,
  },
  {
    name: "Daleville",
    state_id: "IN",
    lat: 40.1189,
    lng: -85.5573,
    id: 1840009442,
  },
  {
    name: "Vevay",
    state_id: "IN",
    lat: 38.7427,
    lng: -85.0783,
    id: 1840010722,
  },
  {
    name: "Minco",
    state_id: "OK",
    lat: 35.3174,
    lng: -97.9518,
    id: 1840020447,
  },
  {
    name: "Burnsville",
    state_id: "NC",
    lat: 35.9164,
    lng: -82.2974,
    id: 1840015353,
  },
  {
    name: "Weston",
    state_id: "OH",
    lat: 41.3464,
    lng: -83.7948,
    id: 1840011566,
  },
  {
    name: "Edgeworth",
    state_id: "PA",
    lat: 40.5546,
    lng: -80.1922,
    id: 1840001228,
  },
  {
    name: "Greilickville",
    state_id: "MI",
    lat: 44.8049,
    lng: -85.663,
    id: 1840006573,
  },
  {
    name: "Duck Hill",
    state_id: "MS",
    lat: 33.6316,
    lng: -89.7153,
    id: 1840016833,
  },
  {
    name: "Harvey",
    state_id: "ND",
    lat: 47.7766,
    lng: -99.9301,
    id: 1840000119,
  },
  {
    name: "Watervliet",
    state_id: "MI",
    lat: 42.1883,
    lng: -86.2576,
    id: 1840003214,
  },
  {
    name: "Dundas",
    state_id: "MN",
    lat: 44.4276,
    lng: -93.2039,
    id: 1840007888,
  },
  {
    name: "Pole Ojea",
    state_id: "PR",
    lat: 17.9743,
    lng: -67.1832,
    id: 1630023604,
  },
  {
    name: "Annandale",
    state_id: "NJ",
    lat: 40.6468,
    lng: -74.8881,
    id: 1840033215,
  },
  {
    name: "Homer",
    state_id: "MI",
    lat: 42.1461,
    lng: -84.8103,
    id: 1840011201,
  },
  {
    name: "Carnegie",
    state_id: "OK",
    lat: 35.1024,
    lng: -98.5995,
    id: 1840022750,
  },
  {
    name: "Muenster",
    state_id: "TX",
    lat: 33.6586,
    lng: -97.3874,
    id: 1840020574,
  },
  {
    name: "Franklin",
    state_id: "TX",
    lat: 31.0251,
    lng: -96.4874,
    id: 1840020849,
  },
  {
    name: "Westwood",
    state_id: "CA",
    lat: 40.3049,
    lng: -121.0045,
    id: 1840018727,
  },
  {
    name: "Honeyville",
    state_id: "UT",
    lat: 41.6361,
    lng: -112.0857,
    id: 1840020118,
  },
  {
    name: "Connelly Springs",
    state_id: "NC",
    lat: 35.755,
    lng: -81.4971,
    id: 1840016231,
  },
  {
    name: "Richland",
    state_id: "GA",
    lat: 32.0885,
    lng: -84.6625,
    id: 1840014916,
  },
  {
    name: "Jonesboro",
    state_id: "IN",
    lat: 40.4796,
    lng: -85.6302,
    id: 1840008340,
  },
  {
    name: "McArthur",
    state_id: "OH",
    lat: 39.2466,
    lng: -82.4787,
    id: 1840012687,
  },
  {
    name: "Lake City",
    state_id: "IA",
    lat: 42.2676,
    lng: -94.7311,
    id: 1840000448,
  },
  {
    name: "Teutopolis",
    state_id: "IL",
    lat: 39.1319,
    lng: -88.4793,
    id: 1840012742,
  },
  {
    name: "Westwood",
    state_id: "KS",
    lat: 39.0394,
    lng: -94.6156,
    id: 1840001643,
  },
  {
    name: "Spring Green",
    state_id: "WI",
    lat: 43.1776,
    lng: -90.0688,
    id: 1840002715,
  },
  {
    name: "Thoreau",
    state_id: "NM",
    lat: 35.4209,
    lng: -108.2235,
    id: 1840019096,
  },
  {
    name: "Intercourse",
    state_id: "PA",
    lat: 40.0378,
    lng: -76.1082,
    id: 1840024337,
  },
  {
    name: "Woodburn",
    state_id: "IN",
    lat: 41.1287,
    lng: -84.8501,
    id: 1840010298,
  },
  {
    name: "Houghton",
    state_id: "NY",
    lat: 42.4277,
    lng: -78.1615,
    id: 1840004604,
  },
  {
    name: "Duquesne",
    state_id: "MO",
    lat: 37.0711,
    lng: -94.4573,
    id: 1840013016,
  },
  {
    name: "Los Ranchos",
    state_id: "CA",
    lat: 35.2102,
    lng: -120.6291,
    id: 1840075936,
  },
  {
    name: "Garden City",
    state_id: "MO",
    lat: 38.5629,
    lng: -94.1958,
    id: 1840008611,
  },
  {
    name: "McComb",
    state_id: "OH",
    lat: 41.1068,
    lng: -83.7898,
    id: 1840011742,
  },
  {
    name: "Boaz",
    state_id: "WV",
    lat: 39.3671,
    lng: -81.4854,
    id: 1840005782,
  },
  {
    name: "Hudson Lake",
    state_id: "IN",
    lat: 41.7173,
    lng: -86.5476,
    id: 1840026259,
  },
  {
    name: "Dayton",
    state_id: "VA",
    lat: 38.4173,
    lng: -78.9412,
    id: 1840006137,
  },
  {
    name: "Caledonia",
    state_id: "MI",
    lat: 42.7945,
    lng: -85.5153,
    id: 1840010066,
  },
  {
    name: "Landover Hills",
    state_id: "MD",
    lat: 38.9424,
    lng: -76.8946,
    id: 1840005987,
  },
  {
    name: "Kettle Falls",
    state_id: "WA",
    lat: 48.6055,
    lng: -118.062,
    id: 1840019764,
  },
  {
    name: "Moville",
    state_id: "IA",
    lat: 42.49,
    lng: -96.0679,
    id: 1840008065,
  },
  {
    name: "Charlotte",
    state_id: "TN",
    lat: 36.1861,
    lng: -87.3389,
    id: 1840016104,
  },
  {
    name: "New Berlinville",
    state_id: "PA",
    lat: 40.3451,
    lng: -75.6313,
    id: 1840035121,
  },
  {
    name: "Walcott",
    state_id: "IA",
    lat: 41.5989,
    lng: -90.7745,
    id: 1840010193,
  },
  {
    name: "Pocahontas",
    state_id: "IA",
    lat: 42.7371,
    lng: -94.6661,
    id: 1840009079,
  },
  {
    name: "Stewartville",
    state_id: "AL",
    lat: 33.0725,
    lng: -86.2658,
    id: 1840023022,
  },
  {
    name: "Gearhart",
    state_id: "OR",
    lat: 46.0289,
    lng: -123.9174,
    id: 1840019905,
  },
  {
    name: "Buckhead Ridge",
    state_id: "FL",
    lat: 27.133,
    lng: -80.8868,
    id: 1840014195,
  },
  {
    name: "Los Alamos",
    state_id: "CA",
    lat: 34.7375,
    lng: -120.2783,
    id: 1840017886,
  },
  {
    name: "Bayou Vista",
    state_id: "TX",
    lat: 29.3258,
    lng: -94.939,
    id: 1840019664,
  },
  {
    name: "Chapin",
    state_id: "SC",
    lat: 34.1645,
    lng: -81.3449,
    id: 1840016725,
  },
  {
    name: "Marbury",
    state_id: "AL",
    lat: 32.6793,
    lng: -86.4607,
    id: 1840023045,
  },
  {
    name: "Wilderness Rim",
    state_id: "WA",
    lat: 47.4463,
    lng: -121.7691,
    id: 1840042145,
  },
  {
    name: "Congress",
    state_id: "AZ",
    lat: 34.1526,
    lng: -112.8642,
    id: 1840017840,
  },
  {
    name: "Boulevard Gardens",
    state_id: "FL",
    lat: 26.1252,
    lng: -80.1822,
    id: 1840029103,
  },
  {
    name: "Sebewaing",
    state_id: "MI",
    lat: 43.7321,
    lng: -83.4511,
    id: 1840013153,
  },
  {
    name: "Water Mill",
    state_id: "NY",
    lat: 40.9222,
    lng: -72.3532,
    id: 1840033982,
  },
  {
    name: "Nooksack",
    state_id: "WA",
    lat: 48.9276,
    lng: -122.3211,
    id: 1840019753,
  },
  {
    name: "Lockney",
    state_id: "TX",
    lat: 34.1231,
    lng: -101.4424,
    id: 1840021915,
  },
  {
    name: "Dundee",
    state_id: "NY",
    lat: 42.5236,
    lng: -76.9775,
    id: 1840004506,
  },
  {
    name: "South Lancaster",
    state_id: "MA",
    lat: 42.4373,
    lng: -71.692,
    id: 1840004532,
  },
  {
    name: "Georgetown",
    state_id: "CT",
    lat: 41.2494,
    lng: -73.4343,
    id: 1840000575,
  },
  {
    name: "Topaz Ranch Estates",
    state_id: "NV",
    lat: 38.7357,
    lng: -119.5008,
    id: 1840039850,
  },
  {
    name: "Canyon Day",
    state_id: "AZ",
    lat: 33.7826,
    lng: -110.0328,
    id: 1840017945,
  },
  {
    name: "Pinewood Estates",
    state_id: "TX",
    lat: 30.1692,
    lng: -94.3207,
    id: 1840037096,
  },
  {
    name: "Shiremanstown",
    state_id: "PA",
    lat: 40.2223,
    lng: -76.9555,
    id: 1840001393,
  },
  {
    name: "East Bangor",
    state_id: "PA",
    lat: 40.8812,
    lng: -75.186,
    id: 1840000969,
  },
  {
    name: "Smith Valley",
    state_id: "NV",
    lat: 38.7845,
    lng: -119.3444,
    id: 1840037104,
  },
  {
    name: "Victoria",
    state_id: "VA",
    lat: 36.9947,
    lng: -78.2242,
    id: 1840003865,
  },
  {
    name: "Pinehurst",
    state_id: "ID",
    lat: 47.5364,
    lng: -116.2327,
    id: 1840019800,
  },
  {
    name: "Aurora",
    state_id: "MN",
    lat: 47.5312,
    lng: -92.2399,
    id: 1840006590,
  },
  {
    name: "Reinbeck",
    state_id: "IA",
    lat: 42.3225,
    lng: -92.5943,
    id: 1840009128,
  },
  {
    name: "Perry",
    state_id: "OH",
    lat: 41.7637,
    lng: -81.143,
    id: 1840000543,
  },
  {
    name: "Penn State Erie",
    state_id: "PA",
    lat: 42.1185,
    lng: -79.9818,
    id: 1840043382,
  },
  {
    name: "Argos",
    state_id: "IN",
    lat: 41.2379,
    lng: -86.2523,
    id: 1840010268,
  },
  {
    name: "St. Francisville",
    state_id: "LA",
    lat: 30.7852,
    lng: -91.3787,
    id: 1840018200,
  },
  {
    name: "Kingsley",
    state_id: "MI",
    lat: 44.5845,
    lng: -85.5346,
    id: 1840013142,
  },
  {
    name: "San Antonio",
    state_id: "PR",
    lat: 18.4931,
    lng: -67.0998,
    id: 1630149533,
  },
  {
    name: "Marfa",
    state_id: "TX",
    lat: 30.3107,
    lng: -104.0255,
    id: 1840020882,
  },
  {
    name: "Springfield",
    state_id: "NE",
    lat: 41.0854,
    lng: -96.1332,
    id: 1840009327,
  },
  {
    name: "Hillsboro",
    state_id: "ND",
    lat: 47.403,
    lng: -97.0635,
    id: 1840000135,
  },
  {
    name: "Gordonsville",
    state_id: "VA",
    lat: 38.136,
    lng: -78.1879,
    id: 1840006267,
  },
  {
    name: "Waterflow",
    state_id: "NM",
    lat: 36.7575,
    lng: -108.4711,
    id: 1840024853,
  },
  {
    name: "Ridgely",
    state_id: "TN",
    lat: 36.2607,
    lng: -89.4808,
    id: 1840017710,
  },
  {
    name: "Mitchell",
    state_id: "NE",
    lat: 41.9427,
    lng: -103.8097,
    id: 1840008141,
  },
  {
    name: "Harlan",
    state_id: "IN",
    lat: 41.1966,
    lng: -84.9245,
    id: 1840026346,
  },
  {
    name: "Jagual",
    state_id: "PR",
    lat: 18.1611,
    lng: -65.9997,
    id: 1630023577,
  },
  {
    name: "Black Point-Green Point",
    state_id: "CA",
    lat: 38.112,
    lng: -122.5191,
    id: 1840036851,
  },
  {
    name: "Ester",
    state_id: "AK",
    lat: 64.8646,
    lng: -148.0916,
    id: 1840023460,
  },
  {
    name: "Luis M. Cintrón",
    state_id: "PR",
    lat: 18.3014,
    lng: -65.6379,
    id: 1630023718,
  },
  {
    name: "Temelec",
    state_id: "CA",
    lat: 38.2577,
    lng: -122.4982,
    id: 1840018839,
  },
  {
    name: "Wellington",
    state_id: "UT",
    lat: 39.5358,
    lng: -110.7344,
    id: 1840021435,
  },
  {
    name: "West Glendive",
    state_id: "MT",
    lat: 47.1056,
    lng: -104.754,
    id: 1840032841,
  },
  {
    name: "Ainsworth",
    state_id: "NE",
    lat: 42.5485,
    lng: -99.8574,
    id: 1840006919,
  },
  {
    name: "Siesta Acres",
    state_id: "TX",
    lat: 28.7564,
    lng: -100.491,
    id: 1840037144,
  },
  {
    name: "Wilkinson Heights",
    state_id: "SC",
    lat: 33.4919,
    lng: -80.8285,
    id: 1840035796,
  },
  {
    name: "Mount Ayr",
    state_id: "IA",
    lat: 40.7141,
    lng: -94.2376,
    id: 1840001003,
  },
  {
    name: "Pinebluff",
    state_id: "NC",
    lat: 35.1088,
    lng: -79.4713,
    id: 1840016358,
  },
  {
    name: "South Charleston",
    state_id: "OH",
    lat: 39.8243,
    lng: -83.6416,
    id: 1840012437,
  },
  {
    name: "Rose Hill",
    state_id: "NC",
    lat: 34.8263,
    lng: -78.0259,
    id: 1840017883,
  },
  {
    name: "Chesilhurst",
    state_id: "NJ",
    lat: 39.7307,
    lng: -74.8786,
    id: 1840001508,
  },
  {
    name: "Remsen",
    state_id: "IA",
    lat: 42.8148,
    lng: -95.9725,
    id: 1840009072,
  },
  {
    name: "Quincy",
    state_id: "MI",
    lat: 41.9432,
    lng: -84.8844,
    id: 1840011375,
  },
  {
    name: "Kensett",
    state_id: "AR",
    lat: 35.2354,
    lng: -91.6706,
    id: 1840014555,
  },
  {
    name: "Columbus",
    state_id: "NM",
    lat: 31.8273,
    lng: -107.6423,
    id: 1840023048,
  },
  {
    name: "Concord",
    state_id: "AL",
    lat: 33.4674,
    lng: -87.0392,
    id: 1840013094,
  },
  {
    name: "Delavan",
    state_id: "IL",
    lat: 40.371,
    lng: -89.5461,
    id: 1840007208,
  },
  {
    name: "Key Vista",
    state_id: "FL",
    lat: 28.1946,
    lng: -82.7703,
    id: 1840038986,
  },
  {
    name: "Lakefield",
    state_id: "MN",
    lat: 43.678,
    lng: -95.1695,
    id: 1840007943,
  },
  {
    name: "Tryon",
    state_id: "NC",
    lat: 35.2086,
    lng: -82.2379,
    id: 1840016405,
  },
  {
    name: "Elk Rapids",
    state_id: "MI",
    lat: 44.895,
    lng: -85.4055,
    id: 1840010923,
  },
  {
    name: "Saratoga",
    state_id: "WY",
    lat: 41.4517,
    lng: -106.8107,
    id: 1840022371,
  },
  {
    name: "Gordo",
    state_id: "AL",
    lat: 33.322,
    lng: -87.904,
    id: 1840003367,
  },
  {
    name: "Ovid",
    state_id: "MI",
    lat: 43.0031,
    lng: -84.3742,
    id: 1840011026,
  },
  {
    name: "Bladenboro",
    state_id: "NC",
    lat: 34.5406,
    lng: -78.7949,
    id: 1840015539,
  },
  {
    name: "El Negro",
    state_id: "PR",
    lat: 18.037,
    lng: -65.8494,
    id: 1630023684,
  },
  {
    name: "Sawyerwood",
    state_id: "OH",
    lat: 41.0344,
    lng: -81.4413,
    id: 1840034132,
  },
  {
    name: "Bokeelia",
    state_id: "FL",
    lat: 26.68,
    lng: -82.1409,
    id: 1840014215,
  },
  {
    name: "Oglala",
    state_id: "SD",
    lat: 43.1849,
    lng: -102.7267,
    id: 1840004200,
  },
  {
    name: "Crosbyton",
    state_id: "TX",
    lat: 33.6413,
    lng: -101.2377,
    id: 1840019336,
  },
  {
    name: "North Eastham",
    state_id: "MA",
    lat: 41.8539,
    lng: -69.9968,
    id: 1840004758,
  },
  {
    name: "Richland",
    state_id: "PA",
    lat: 40.3574,
    lng: -76.2568,
    id: 1840001351,
  },
  {
    name: "Mars",
    state_id: "PA",
    lat: 40.6969,
    lng: -80.0141,
    id: 1840000869,
  },
  {
    name: "Fox Chase",
    state_id: "PA",
    lat: 40.3952,
    lng: -75.9634,
    id: 1840035445,
  },
  {
    name: "Corrigan",
    state_id: "TX",
    lat: 30.9988,
    lng: -94.8275,
    id: 1840023132,
  },
  {
    name: "Thorp",
    state_id: "WI",
    lat: 44.958,
    lng: -90.8021,
    id: 1840002176,
  },
  {
    name: "Senath",
    state_id: "MO",
    lat: 36.134,
    lng: -90.1614,
    id: 1840009949,
  },
  {
    name: "Lake Bryan",
    state_id: "TX",
    lat: 30.7188,
    lng: -96.4631,
    id: 1840139791,
  },
  {
    name: "Bethel",
    state_id: "NC",
    lat: 35.8072,
    lng: -77.3762,
    id: 1840015389,
  },
  {
    name: "Falmouth Foreside",
    state_id: "ME",
    lat: 43.7348,
    lng: -70.2152,
    id: 1840002551,
  },
  {
    name: "Maurice",
    state_id: "LA",
    lat: 30.1041,
    lng: -92.1211,
    id: 1840018255,
  },
  {
    name: "Spencerville",
    state_id: "NM",
    lat: 36.8192,
    lng: -108.056,
    id: 1840024852,
  },
  {
    name: "Drew",
    state_id: "MS",
    lat: 33.8101,
    lng: -90.5304,
    id: 1840013698,
  },
  {
    name: "Ashley",
    state_id: "OH",
    lat: 40.4098,
    lng: -82.9516,
    id: 1840003706,
  },
  {
    name: "Mill Hall",
    state_id: "PA",
    lat: 41.1045,
    lng: -77.4888,
    id: 1840003452,
  },
  {
    name: "Georgiana",
    state_id: "AL",
    lat: 31.6391,
    lng: -86.7468,
    id: 1840003191,
  },
  {
    name: "La Huerta",
    state_id: "NM",
    lat: 32.4488,
    lng: -104.2224,
    id: 1840018082,
  },
  {
    name: "Port Byron",
    state_id: "IL",
    lat: 41.616,
    lng: -90.328,
    id: 1840011441,
  },
  {
    name: "Ladera",
    state_id: "CA",
    lat: 37.3996,
    lng: -122.199,
    id: 1840028324,
  },
  {
    name: "Fish Lake",
    state_id: "IN",
    lat: 41.5616,
    lng: -86.5513,
    id: 1840026261,
  },
  {
    name: "Ballplay",
    state_id: "AL",
    lat: 34.0179,
    lng: -85.7853,
    id: 1840022887,
  },
  {
    name: "Aberdeen",
    state_id: "OH",
    lat: 38.6714,
    lng: -83.7705,
    id: 1840010685,
  },
  {
    name: "Montour Falls",
    state_id: "NY",
    lat: 42.3499,
    lng: -76.8483,
    id: 1840004583,
  },
  {
    name: "Peach Lake",
    state_id: "NY",
    lat: 41.3682,
    lng: -73.5778,
    id: 1840034058,
  },
  {
    name: "Grand Ronde",
    state_id: "OR",
    lat: 45.0739,
    lng: -123.621,
    id: 1840017424,
  },
  {
    name: "Belleair Beach",
    state_id: "FL",
    lat: 27.9242,
    lng: -82.8358,
    id: 1840014146,
  },
  {
    name: "Soap Lake",
    state_id: "WA",
    lat: 47.3914,
    lng: -119.4892,
    id: 1840021102,
  },
  {
    name: "Waverly",
    state_id: "MN",
    lat: 45.065,
    lng: -93.9663,
    id: 1840009994,
  },
  {
    name: "Neoga",
    state_id: "IL",
    lat: 39.3215,
    lng: -88.4504,
    id: 1840008512,
  },
  {
    name: "Leavittsburg",
    state_id: "OH",
    lat: 41.2452,
    lng: -80.8789,
    id: 1840004900,
  },
  {
    name: "Towamensing Trails",
    state_id: "PA",
    lat: 40.995,
    lng: -75.5826,
    id: 1840035384,
  },
  {
    name: "Espy",
    state_id: "PA",
    lat: 41.0055,
    lng: -76.4164,
    id: 1840034953,
  },
  {
    name: "Stevenson",
    state_id: "WA",
    lat: 45.6944,
    lng: -121.8931,
    id: 1840021180,
  },
  {
    name: "Trevorton",
    state_id: "PA",
    lat: 40.7841,
    lng: -76.6713,
    id: 1840005178,
  },
  {
    name: "Corydon",
    state_id: "IA",
    lat: 40.7574,
    lng: -93.3174,
    id: 1840007200,
  },
  {
    name: "Monterey Park",
    state_id: "NM",
    lat: 34.7511,
    lng: -106.653,
    id: 1840033727,
  },
  {
    name: "Atlanta",
    state_id: "IL",
    lat: 40.2637,
    lng: -89.231,
    id: 1840007252,
  },
  {
    name: "South Browning",
    state_id: "MT",
    lat: 48.5459,
    lng: -113.0128,
    id: 1840032848,
  },
  {
    name: "Naranjito",
    state_id: "PR",
    lat: 18.302,
    lng: -66.246,
    id: 1630023649,
  },
  {
    name: "Bowman",
    state_id: "ND",
    lat: 46.184,
    lng: -103.3991,
    id: 1840000257,
  },
  {
    name: "Saxapahaw",
    state_id: "NC",
    lat: 35.9489,
    lng: -79.321,
    id: 1840013359,
  },
  {
    name: "Eureka Mill",
    state_id: "SC",
    lat: 34.718,
    lng: -81.193,
    id: 1840035844,
  },
  {
    name: "Adrian",
    state_id: "MO",
    lat: 38.396,
    lng: -94.3498,
    id: 1840007511,
  },
  {
    name: "Brooklyn",
    state_id: "IN",
    lat: 39.5436,
    lng: -86.3674,
    id: 1840009666,
  },
  {
    name: "Emerson",
    state_id: "GA",
    lat: 34.1303,
    lng: -84.7484,
    id: 1840013621,
  },
  {
    name: "Galesville",
    state_id: "WI",
    lat: 44.0839,
    lng: -91.358,
    id: 1840002387,
  },
  {
    name: "Coalville",
    state_id: "UT",
    lat: 40.912,
    lng: -111.413,
    id: 1840018723,
  },
  {
    name: "Spring Valley",
    state_id: "AZ",
    lat: 34.3531,
    lng: -112.1513,
    id: 1840019161,
  },
  {
    name: "Rocky Point",
    state_id: "WA",
    lat: 47.5881,
    lng: -122.6668,
    id: 1840037507,
  },
  {
    name: "Traer",
    state_id: "IA",
    lat: 42.1895,
    lng: -92.4639,
    id: 1840010128,
  },
  {
    name: "Monette",
    state_id: "AR",
    lat: 35.8931,
    lng: -90.3442,
    id: 1840014506,
  },
  {
    name: "Edmonton",
    state_id: "KY",
    lat: 36.9854,
    lng: -85.6193,
    id: 1840013266,
  },
  {
    name: "Astor",
    state_id: "FL",
    lat: 29.1635,
    lng: -81.5349,
    id: 1840014046,
  },
  {
    name: "Bernice",
    state_id: "LA",
    lat: 32.8276,
    lng: -92.6584,
    id: 1840015773,
  },
  {
    name: "Orwell",
    state_id: "OH",
    lat: 41.5364,
    lng: -80.8596,
    id: 1840011418,
  },
  {
    name: "Lakeside",
    state_id: "TX",
    lat: 32.8219,
    lng: -97.4869,
    id: 1840022053,
  },
  {
    name: "Shoreacres",
    state_id: "TX",
    lat: 29.6204,
    lng: -95.0188,
    id: 1840022198,
  },
  {
    name: "De Kalb",
    state_id: "TX",
    lat: 33.5076,
    lng: -94.6167,
    id: 1840019340,
  },
  {
    name: "Calumet",
    state_id: "PA",
    lat: 40.2189,
    lng: -79.4892,
    id: 1840026435,
  },
  {
    name: "Linn",
    state_id: "MO",
    lat: 38.4788,
    lng: -91.845,
    id: 1840009646,
  },
  {
    name: "New Hope",
    state_id: "OR",
    lat: 42.3693,
    lng: -123.359,
    id: 1840025485,
  },
  {
    name: "Emigration Canyon",
    state_id: "UT",
    lat: 40.7886,
    lng: -111.7414,
    id: 1840041631,
  },
  {
    name: "Eagle River",
    state_id: "WI",
    lat: 45.9249,
    lng: -89.2561,
    id: 1840001982,
  },
  {
    name: "Mayville",
    state_id: "NY",
    lat: 42.2532,
    lng: -79.5031,
    id: 1840004568,
  },
  {
    name: "Midway",
    state_id: "FL",
    lat: 28.7924,
    lng: -81.2314,
    id: 1840148976,
  },
  {
    name: "Del Rio",
    state_id: "CA",
    lat: 37.7432,
    lng: -121.0091,
    id: 1840028341,
  },
  {
    name: "Crooked Lake Park",
    state_id: "FL",
    lat: 27.8295,
    lng: -81.5897,
    id: 1840028657,
  },
  {
    name: "Aberdeen",
    state_id: "IN",
    lat: 41.4418,
    lng: -87.1166,
    id: 1840030083,
  },
  {
    name: "Cressona",
    state_id: "PA",
    lat: 40.6304,
    lng: -76.1938,
    id: 1840002756,
  },
  {
    name: "Mechanicsburg",
    state_id: "OH",
    lat: 40.0735,
    lng: -83.5564,
    id: 1840012334,
  },
  {
    name: "Woodland Hills",
    state_id: "UT",
    lat: 40.0132,
    lng: -111.6558,
    id: 1840021400,
  },
  {
    name: "Darien",
    state_id: "WI",
    lat: 42.6008,
    lng: -88.7127,
    id: 1840002482,
  },
  {
    name: "Warner",
    state_id: "OK",
    lat: 35.4902,
    lng: -95.3085,
    id: 1840022724,
  },
  {
    name: "Avon",
    state_id: "MN",
    lat: 45.6101,
    lng: -94.4619,
    id: 1840006684,
  },
  {
    name: "Natalia",
    state_id: "TX",
    lat: 29.1877,
    lng: -98.8524,
    id: 1840020964,
  },
  {
    name: "Nashua",
    state_id: "IA",
    lat: 42.9502,
    lng: -92.5417,
    id: 1840007997,
  },
  {
    name: "Shelbina",
    state_id: "MO",
    lat: 39.693,
    lng: -92.0396,
    id: 1840009519,
  },
  {
    name: "Palomas",
    state_id: "PR",
    lat: 18.0135,
    lng: -66.8688,
    id: 1630137130,
  },
  {
    name: "Woodland Park",
    state_id: "NE",
    lat: 42.0542,
    lng: -97.3452,
    id: 1840042699,
  },
  {
    name: "Jerome",
    state_id: "IL",
    lat: 39.7677,
    lng: -89.6785,
    id: 1840012442,
  },
  {
    name: "Union City",
    state_id: "OH",
    lat: 40.1996,
    lng: -84.7943,
    id: 1840012258,
  },
  {
    name: "Lockport Heights",
    state_id: "LA",
    lat: 29.6564,
    lng: -90.5486,
    id: 1840025137,
  },
  {
    name: "Homer City",
    state_id: "PA",
    lat: 40.5403,
    lng: -79.1597,
    id: 1840000996,
  },
  {
    name: "Rosedale",
    state_id: "MS",
    lat: 33.8525,
    lng: -91.0339,
    id: 1840014750,
  },
  {
    name: "McCord",
    state_id: "OK",
    lat: 36.6798,
    lng: -97.0346,
    id: 1840034657,
  },
  {
    name: "West Milton",
    state_id: "PA",
    lat: 41.0183,
    lng: -76.8767,
    id: 1840024213,
  },
  {
    name: "Eleanor",
    state_id: "WV",
    lat: 38.5374,
    lng: -81.9256,
    id: 1840006179,
  },
  {
    name: "Earlville",
    state_id: "IL",
    lat: 41.588,
    lng: -88.9229,
    id: 1840008197,
  },
  {
    name: "Kalida",
    state_id: "OH",
    lat: 40.9854,
    lng: -84.1945,
    id: 1840011748,
  },
  {
    name: "Harbor Beach",
    state_id: "MI",
    lat: 43.8456,
    lng: -82.6554,
    id: 1840003952,
  },
  {
    name: "Tecumseh",
    state_id: "NE",
    lat: 40.3718,
    lng: -96.1888,
    id: 1840009428,
  },
  {
    name: "Murfreesboro",
    state_id: "AR",
    lat: 34.0546,
    lng: -93.6834,
    id: 1840014711,
  },
  {
    name: "Sparkill",
    state_id: "NY",
    lat: 41.0289,
    lng: -73.9333,
    id: 1840026341,
  },
  {
    name: "Paradis",
    state_id: "LA",
    lat: 29.868,
    lng: -90.4398,
    id: 1840013984,
  },
  {
    name: "Albion",
    state_id: "NE",
    lat: 41.6872,
    lng: -97.9987,
    id: 1840007049,
  },
  {
    name: "Osgood",
    state_id: "IN",
    lat: 39.1291,
    lng: -85.292,
    id: 1840009622,
  },
  {
    name: "Coarsegold",
    state_id: "CA",
    lat: 37.2526,
    lng: -119.7,
    id: 1840025724,
  },
  {
    name: "Clark Mills",
    state_id: "NY",
    lat: 43.0895,
    lng: -75.3757,
    id: 1840004204,
  },
  {
    name: "Rollingwood",
    state_id: "TX",
    lat: 30.2736,
    lng: -97.7867,
    id: 1840020889,
  },
  {
    name: "Kenly",
    state_id: "NC",
    lat: 35.5887,
    lng: -78.1368,
    id: 1840016288,
  },
  {
    name: "Prestbury",
    state_id: "IL",
    lat: 41.7847,
    lng: -88.4225,
    id: 1840026220,
  },
  {
    name: "Tuolumne City",
    state_id: "CA",
    lat: 37.9615,
    lng: -120.2428,
    id: 1840018881,
  },
  {
    name: "Bourbon",
    state_id: "MO",
    lat: 38.1505,
    lng: -91.2493,
    id: 1840007532,
  },
  {
    name: "Des Arc",
    state_id: "AR",
    lat: 34.9777,
    lng: -91.5068,
    id: 1840014624,
  },
  {
    name: "Sardis",
    state_id: "MS",
    lat: 34.4355,
    lng: -89.9114,
    id: 1840017936,
  },
  {
    name: "Hamilton",
    state_id: "IN",
    lat: 41.541,
    lng: -84.9204,
    id: 1840010205,
  },
  {
    name: "Langhorne",
    state_id: "PA",
    lat: 40.1777,
    lng: -74.9212,
    id: 1840001324,
  },
  {
    name: "Sturgeon",
    state_id: "PA",
    lat: 40.3835,
    lng: -80.2153,
    id: 1840026480,
  },
  {
    name: "Graysville",
    state_id: "TN",
    lat: 35.4493,
    lng: -85.0763,
    id: 1840016286,
  },
  {
    name: "Belhaven",
    state_id: "NC",
    lat: 35.5428,
    lng: -76.6232,
    id: 1840015400,
  },
  {
    name: "Eldorado",
    state_id: "TX",
    lat: 30.8617,
    lng: -100.5979,
    id: 1840020864,
  },
  {
    name: "Oakland",
    state_id: "PA",
    lat: 40.9915,
    lng: -80.3671,
    id: 1840149256,
  },
  {
    name: "Foscoe",
    state_id: "NC",
    lat: 36.1535,
    lng: -81.7736,
    id: 1840024901,
  },
  {
    name: "Brookside Village",
    state_id: "TX",
    lat: 29.5907,
    lng: -95.318,
    id: 1840019674,
  },
  {
    name: "Stratford",
    state_id: "WI",
    lat: 44.8009,
    lng: -90.0722,
    id: 1840002162,
  },
  {
    name: "Hallsville",
    state_id: "MO",
    lat: 39.1196,
    lng: -92.2268,
    id: 1840007394,
  },
  {
    name: "Morenci",
    state_id: "AZ",
    lat: 33.0531,
    lng: -109.3307,
    id: 1840019339,
  },
  {
    name: "East Canton",
    state_id: "OH",
    lat: 40.7889,
    lng: -81.285,
    id: 1840011913,
  },
  {
    name: "Runaway Bay",
    state_id: "TX",
    lat: 33.1773,
    lng: -97.871,
    id: 1840020633,
  },
  {
    name: "Banner Hill",
    state_id: "TN",
    lat: 36.1222,
    lng: -82.4203,
    id: 1840013353,
  },
  {
    name: "Leilani Estates",
    state_id: "HI",
    lat: 19.4659,
    lng: -154.9157,
    id: 1840029573,
  },
  {
    name: "Wayne",
    state_id: "WV",
    lat: 38.2296,
    lng: -82.4417,
    id: 1840006264,
  },
  {
    name: "River Hills",
    state_id: "WI",
    lat: 43.1717,
    lng: -87.9353,
    id: 1840003048,
  },
  {
    name: "Lookout Mountain",
    state_id: "GA",
    lat: 34.9664,
    lng: -85.3622,
    id: 1840015530,
  },
  {
    name: "Francis",
    state_id: "UT",
    lat: 40.6105,
    lng: -111.2744,
    id: 1840021346,
  },
  {
    name: "Hartley",
    state_id: "IA",
    lat: 43.179,
    lng: -95.4768,
    id: 1840006876,
  },
  {
    name: "Central Garage",
    state_id: "VA",
    lat: 37.7458,
    lng: -77.1315,
    id: 1840024751,
  },
  {
    name: "Alvord",
    state_id: "TX",
    lat: 33.3569,
    lng: -97.696,
    id: 1840022003,
  },
  {
    name: "Owingsville",
    state_id: "KY",
    lat: 38.1364,
    lng: -83.7603,
    id: 1840014316,
  },
  {
    name: "Thorntown",
    state_id: "IN",
    lat: 40.1292,
    lng: -86.6099,
    id: 1840010512,
  },
  {
    name: "Adwolf",
    state_id: "VA",
    lat: 36.793,
    lng: -81.5927,
    id: 1840006511,
  },
  {
    name: "Shasta",
    state_id: "CA",
    lat: 40.591,
    lng: -122.4797,
    id: 1840024204,
  },
  {
    name: "Pirtleville",
    state_id: "AZ",
    lat: 31.3613,
    lng: -109.5661,
    id: 1840019492,
  },
  {
    name: "Whitehall",
    state_id: "WI",
    lat: 44.3705,
    lng: -91.3476,
    id: 1840002386,
  },
  {
    name: "Warren Park",
    state_id: "IN",
    lat: 39.7833,
    lng: -86.0516,
    id: 1840010575,
  },
  {
    name: "New Paris",
    state_id: "OH",
    lat: 39.8565,
    lng: -84.7922,
    id: 1840012496,
  },
  {
    name: "Mount Carroll",
    state_id: "IL",
    lat: 42.0947,
    lng: -89.9769,
    id: 1840008127,
  },
  {
    name: "Leona Valley",
    state_id: "CA",
    lat: 34.6147,
    lng: -118.299,
    id: 1840025004,
  },
  {
    name: "Wacousta",
    state_id: "MI",
    lat: 42.8202,
    lng: -84.6907,
    id: 1840024060,
  },
  {
    name: "Estancia",
    state_id: "NM",
    lat: 34.7667,
    lng: -106.0301,
    id: 1840021834,
  },
  {
    name: "Southern View",
    state_id: "IL",
    lat: 39.7559,
    lng: -89.6511,
    id: 1840012456,
  },
  {
    name: "Smith Center",
    state_id: "KS",
    lat: 39.7748,
    lng: -98.783,
    id: 1840009501,
  },
  {
    name: "Summit",
    state_id: "MS",
    lat: 31.2808,
    lng: -90.467,
    id: 1840017116,
  },
  {
    name: "Pewee Valley",
    state_id: "KY",
    lat: 38.3105,
    lng: -85.4895,
    id: 1840014285,
  },
  {
    name: "Blossom",
    state_id: "TX",
    lat: 33.6633,
    lng: -95.3837,
    id: 1840019329,
  },
  {
    name: "East Dubuque",
    state_id: "IL",
    lat: 42.4889,
    lng: -90.6278,
    id: 1840008081,
  },
  {
    name: "Tonka Bay",
    state_id: "MN",
    lat: 44.9167,
    lng: -93.5886,
    id: 1840009999,
  },
  {
    name: "Del Norte",
    state_id: "CO",
    lat: 37.6785,
    lng: -106.354,
    id: 1840021542,
  },
  {
    name: "Descanso",
    state_id: "CA",
    lat: 32.8696,
    lng: -116.6279,
    id: 1840022970,
  },
  {
    name: "Genola",
    state_id: "UT",
    lat: 40.0117,
    lng: -111.8464,
    id: 1840021402,
  },
  {
    name: "West Lawn",
    state_id: "PA",
    lat: 40.3289,
    lng: -75.9939,
    id: 1840035253,
  },
  {
    name: "Inverness",
    state_id: "CO",
    lat: 39.5787,
    lng: -104.8624,
    id: 1840028591,
  },
  {
    name: "Fairlea",
    state_id: "WV",
    lat: 37.7756,
    lng: -80.4582,
    id: 1840038243,
  },
  {
    name: "Sausal",
    state_id: "NM",
    lat: 34.6803,
    lng: -106.7589,
    id: 1840040215,
  },
  {
    name: "Manson",
    state_id: "IA",
    lat: 42.5284,
    lng: -94.5401,
    id: 1840009116,
  },
  {
    name: "Elizabeth",
    state_id: "CO",
    lat: 39.36,
    lng: -104.6071,
    id: 1840021449,
  },
  {
    name: "Chester Center",
    state_id: "CT",
    lat: 41.4014,
    lng: -72.4523,
    id: 1840073563,
  },
  {
    name: "Milford",
    state_id: "IN",
    lat: 41.4142,
    lng: -85.8446,
    id: 1840009307,
  },
  {
    name: "Random Lake",
    state_id: "WI",
    lat: 43.5541,
    lng: -87.9548,
    id: 1840002627,
  },
  {
    name: "Grayville",
    state_id: "IL",
    lat: 38.2554,
    lng: -87.997,
    id: 1840008669,
  },
  {
    name: "Chester",
    state_id: "GA",
    lat: 32.3945,
    lng: -83.1551,
    id: 1840017004,
  },
  {
    name: "Toast",
    state_id: "NC",
    lat: 36.4966,
    lng: -80.6346,
    id: 1840013306,
  },
  {
    name: "Union City",
    state_id: "MI",
    lat: 42.0662,
    lng: -85.1432,
    id: 1840011377,
  },
  {
    name: "Dunsmuir",
    state_id: "CA",
    lat: 41.2318,
    lng: -122.2714,
    id: 1840018697,
  },
  {
    name: "Ada",
    state_id: "MN",
    lat: 47.2992,
    lng: -96.5165,
    id: 1840006617,
  },
  {
    name: "Crescent",
    state_id: "OK",
    lat: 35.9516,
    lng: -97.5949,
    id: 1840019073,
  },
  {
    name: "LeChee",
    state_id: "AZ",
    lat: 36.871,
    lng: -111.4328,
    id: 1840027980,
  },
  {
    name: "Baidland",
    state_id: "PA",
    lat: 40.1888,
    lng: -79.9533,
    id: 1840005432,
  },
  {
    name: "Norwood",
    state_id: "NY",
    lat: 44.7481,
    lng: -74.997,
    id: 1840004060,
  },
  {
    name: "Wardsville",
    state_id: "MO",
    lat: 38.4901,
    lng: -92.1791,
    id: 1840012859,
  },
  {
    name: "Comanche",
    state_id: "OK",
    lat: 34.3597,
    lng: -97.9478,
    id: 1840019252,
  },
  {
    name: "Franklin Furnace",
    state_id: "OH",
    lat: 38.6115,
    lng: -82.8439,
    id: 1840006052,
  },
  {
    name: "Sharon",
    state_id: "WI",
    lat: 42.5047,
    lng: -88.7394,
    id: 1840002485,
  },
  {
    name: "Olive Hill",
    state_id: "KY",
    lat: 38.3033,
    lng: -83.1695,
    id: 1840014289,
  },
  {
    name: "Belle Plaine",
    state_id: "KS",
    lat: 37.3934,
    lng: -97.2796,
    id: 1840007610,
  },
  {
    name: "Ames Lake",
    state_id: "WA",
    lat: 47.6346,
    lng: -121.9664,
    id: 1840037846,
  },
  {
    name: "Manton",
    state_id: "MI",
    lat: 44.4114,
    lng: -85.401,
    id: 1840002513,
  },
  {
    name: "New Plymouth",
    state_id: "ID",
    lat: 43.9705,
    lng: -116.8187,
    id: 1840020014,
  },
  {
    name: "Ransomville",
    state_id: "NY",
    lat: 43.2389,
    lng: -78.9114,
    id: 1840004268,
  },
  {
    name: "Oak Grove",
    state_id: "LA",
    lat: 32.8619,
    lng: -91.3911,
    id: 1840016944,
  },
  {
    name: "Vonore",
    state_id: "TN",
    lat: 35.5977,
    lng: -84.2365,
    id: 1840016312,
  },
  {
    name: "Woodmont",
    state_id: "CT",
    lat: 41.2282,
    lng: -72.993,
    id: 1840004853,
  },
  {
    name: "Carrier Mills",
    state_id: "IL",
    lat: 37.6886,
    lng: -88.6294,
    id: 1840010850,
  },
  {
    name: "Reform",
    state_id: "AL",
    lat: 33.3788,
    lng: -88.0173,
    id: 1840018010,
  },
  {
    name: "Moroni",
    state_id: "UT",
    lat: 39.5271,
    lng: -111.5832,
    id: 1840020207,
  },
  {
    name: "Warren",
    state_id: "MN",
    lat: 48.194,
    lng: -96.7688,
    id: 1840009957,
  },
  {
    name: "McMillin",
    state_id: "WA",
    lat: 47.1286,
    lng: -122.2374,
    id: 1840023837,
  },
  {
    name: "Westville",
    state_id: "OK",
    lat: 35.9903,
    lng: -94.5743,
    id: 1840022706,
  },
  {
    name: "East Spencer",
    state_id: "NC",
    lat: 35.6821,
    lng: -80.4274,
    id: 1840016266,
  },
  {
    name: "Schoolcraft",
    state_id: "MI",
    lat: 42.117,
    lng: -85.6337,
    id: 1840011212,
  },
  {
    name: "Sarcoxie",
    state_id: "MO",
    lat: 37.0683,
    lng: -94.1259,
    id: 1840009912,
  },
  {
    name: "Bay Park",
    state_id: "NY",
    lat: 40.6302,
    lng: -73.667,
    id: 1840005230,
  },
  {
    name: "New Middletown",
    state_id: "OH",
    lat: 40.9646,
    lng: -80.5597,
    id: 1840011779,
  },
  {
    name: "Duque",
    state_id: "PR",
    lat: 18.2381,
    lng: -65.7432,
    id: 1630023709,
  },
  {
    name: "Worthington Hills",
    state_id: "KY",
    lat: 38.3093,
    lng: -85.527,
    id: 1840015204,
  },
  {
    name: "Wyoming",
    state_id: "DE",
    lat: 39.1147,
    lng: -75.5631,
    id: 1840005822,
  },
  {
    name: "Montrose",
    state_id: "MI",
    lat: 43.1765,
    lng: -83.8932,
    id: 1840002942,
  },
  {
    name: "Cedar Glen Lakes",
    state_id: "NJ",
    lat: 39.9527,
    lng: -74.3995,
    id: 1840033477,
  },
  {
    name: "Stanfield",
    state_id: "NC",
    lat: 35.2336,
    lng: -80.4303,
    id: 1840016365,
  },
  {
    name: "Bressler",
    state_id: "PA",
    lat: 40.2319,
    lng: -76.819,
    id: 1840034875,
  },
  {
    name: "Shawsville",
    state_id: "VA",
    lat: 37.1707,
    lng: -80.2527,
    id: 1840006445,
  },
  {
    name: "Wagner",
    state_id: "SD",
    lat: 43.0768,
    lng: -98.2934,
    id: 1840002850,
  },
  {
    name: "Linden",
    state_id: "CA",
    lat: 38.0186,
    lng: -121.0994,
    id: 1840018893,
  },
  {
    name: "Lewiston",
    state_id: "MN",
    lat: 43.9823,
    lng: -91.8695,
    id: 1840007921,
  },
  {
    name: "Judson",
    state_id: "SC",
    lat: 34.8335,
    lng: -82.4272,
    id: 1840013494,
  },
  {
    name: "Dover",
    state_id: "TN",
    lat: 36.4818,
    lng: -87.8438,
    id: 1840013293,
  },
  {
    name: "Rehoboth Beach",
    state_id: "DE",
    lat: 38.7171,
    lng: -75.0845,
    id: 1840006070,
  },
  {
    name: "Paxtang",
    state_id: "PA",
    lat: 40.2623,
    lng: -76.8342,
    id: 1840001293,
  },
  {
    name: "Crothersville",
    state_id: "IN",
    lat: 38.7944,
    lng: -85.84,
    id: 1840009710,
  },
  {
    name: "Miranda",
    state_id: "PR",
    lat: 18.3869,
    lng: -66.3789,
    id: 1630035521,
  },
  {
    name: "East Berlin",
    state_id: "PA",
    lat: 39.9368,
    lng: -76.9794,
    id: 1840000681,
  },
  {
    name: "Hines",
    state_id: "OR",
    lat: 43.557,
    lng: -119.0803,
    id: 1840020025,
  },
  {
    name: "Three Oaks",
    state_id: "MI",
    lat: 41.7987,
    lng: -86.613,
    id: 1840011231,
  },
  {
    name: "Rossville",
    state_id: "IN",
    lat: 40.4196,
    lng: -86.5958,
    id: 1840010446,
  },
  {
    name: "North Pekin",
    state_id: "IL",
    lat: 40.6114,
    lng: -89.6225,
    id: 1840011992,
  },
  {
    name: "Nescopeck",
    state_id: "PA",
    lat: 41.0528,
    lng: -76.2121,
    id: 1840000762,
  },
  {
    name: "New Fairview",
    state_id: "TX",
    lat: 33.1122,
    lng: -97.4489,
    id: 1840020634,
  },
  {
    name: "Niagara",
    state_id: "WI",
    lat: 45.7801,
    lng: -87.9991,
    id: 1840003931,
  },
  {
    name: "Chatmoss",
    state_id: "VA",
    lat: 36.6758,
    lng: -79.7981,
    id: 1840006552,
  },
  {
    name: "Tipton",
    state_id: "PA",
    lat: 40.6359,
    lng: -78.3001,
    id: 1840005377,
  },
  {
    name: "Seaside Park",
    state_id: "NJ",
    lat: 39.9264,
    lng: -74.0785,
    id: 1840001488,
  },
  {
    name: "East Valley",
    state_id: "NV",
    lat: 38.9462,
    lng: -119.7003,
    id: 1840039807,
  },
  {
    name: "Newfield",
    state_id: "NJ",
    lat: 39.5484,
    lng: -75.0167,
    id: 1840001522,
  },
  {
    name: "Hillsdale",
    state_id: "MO",
    lat: 38.6858,
    lng: -90.2869,
    id: 1840012818,
  },
  {
    name: "Fort Belknap Agency",
    state_id: "MT",
    lat: 48.4278,
    lng: -108.6829,
    id: 1840017305,
  },
  {
    name: "Roseto",
    state_id: "PA",
    lat: 40.8778,
    lng: -75.2204,
    id: 1840000979,
  },
  {
    name: "Rosepine",
    state_id: "LA",
    lat: 30.9208,
    lng: -93.2857,
    id: 1840018184,
  },
  {
    name: "Dobson",
    state_id: "NC",
    lat: 36.3927,
    lng: -80.7239,
    id: 1840016030,
  },
  {
    name: "Fairforest",
    state_id: "SC",
    lat: 34.9532,
    lng: -82.0157,
    id: 1840026960,
  },
  {
    name: "Collings Lakes",
    state_id: "NJ",
    lat: 39.5939,
    lng: -74.8821,
    id: 1840005595,
  },
  {
    name: "Chapel Hill",
    state_id: "TN",
    lat: 35.6294,
    lng: -86.6955,
    id: 1840016306,
  },
  {
    name: "Logansport",
    state_id: "LA",
    lat: 31.9762,
    lng: -93.9933,
    id: 1840017013,
  },
  {
    name: "Pleasant Grove",
    state_id: "OH",
    lat: 39.9513,
    lng: -81.9593,
    id: 1840005539,
  },
  {
    name: "Rheems",
    state_id: "PA",
    lat: 40.1256,
    lng: -76.5734,
    id: 1840005489,
  },
  {
    name: "Neuse Forest",
    state_id: "NC",
    lat: 34.966,
    lng: -76.94,
    id: 1840033020,
  },
  {
    name: "Kodiak Station",
    state_id: "AK",
    lat: 57.7451,
    lng: -152.5443,
    id: 1840075081,
  },
  {
    name: "Ramos",
    state_id: "PR",
    lat: 18.3389,
    lng: -65.7111,
    id: 1630023694,
  },
  {
    name: "Fort Covington Hamlet",
    state_id: "NY",
    lat: 44.9761,
    lng: -74.5097,
    id: 1840040223,
  },
  {
    name: "Harvey",
    state_id: "MI",
    lat: 46.4882,
    lng: -87.3511,
    id: 1840006568,
  },
  {
    name: "Sumas",
    state_id: "WA",
    lat: 48.9956,
    lng: -122.2695,
    id: 1840021045,
  },
  {
    name: "Gallitzin",
    state_id: "PA",
    lat: 40.4809,
    lng: -78.5547,
    id: 1840001113,
  },
  {
    name: "Englewood",
    state_id: "TN",
    lat: 35.4228,
    lng: -84.4883,
    id: 1840016317,
  },
  {
    name: "Hasson Heights",
    state_id: "PA",
    lat: 41.4487,
    lng: -79.6765,
    id: 1840035426,
  },
  {
    name: "Owenton",
    state_id: "KY",
    lat: 38.5386,
    lng: -84.8409,
    id: 1840014278,
  },
  {
    name: "Patrick AFB",
    state_id: "FL",
    lat: 28.2346,
    lng: -80.6079,
    id: 1840042859,
  },
  {
    name: "Appalachia",
    state_id: "VA",
    lat: 36.9098,
    lng: -82.7882,
    id: 1840006480,
  },
  {
    name: "Walnut Cove",
    state_id: "NC",
    lat: 36.2941,
    lng: -80.1403,
    id: 1840016035,
  },
  {
    name: "Coldwater",
    state_id: "MS",
    lat: 34.6902,
    lng: -89.9757,
    id: 1840016596,
  },
  {
    name: "Nederland",
    state_id: "CO",
    lat: 39.9633,
    lng: -105.5071,
    id: 1840022424,
  },
  {
    name: "Brookston",
    state_id: "IN",
    lat: 40.6008,
    lng: -86.8665,
    id: 1840009377,
  },
  {
    name: "Mercersburg",
    state_id: "PA",
    lat: 39.832,
    lng: -77.903,
    id: 1840001412,
  },
  {
    name: "Passapatanzy",
    state_id: "VA",
    lat: 38.2942,
    lng: -77.3266,
    id: 1840026759,
  },
  {
    name: "McConnell AFB",
    state_id: "KS",
    lat: 37.6249,
    lng: -97.26,
    id: 1840039354,
  },
  {
    name: "Mason",
    state_id: "TN",
    lat: 35.4128,
    lng: -89.5412,
    id: 1840016315,
  },
  {
    name: "Baxter",
    state_id: "TN",
    lat: 36.1537,
    lng: -85.6349,
    id: 1840015318,
  },
  {
    name: "Sebree",
    state_id: "KY",
    lat: 37.604,
    lng: -87.5187,
    id: 1840015239,
  },
  {
    name: "Smethport",
    state_id: "PA",
    lat: 41.8071,
    lng: -78.4435,
    id: 1840000529,
  },
  {
    name: "Chepachet",
    state_id: "RI",
    lat: 41.9105,
    lng: -71.6653,
    id: 1840026229,
  },
  {
    name: "Crownsville",
    state_id: "MD",
    lat: 39.0225,
    lng: -76.5903,
    id: 1840005910,
  },
  {
    name: "Fair Grove",
    state_id: "MO",
    lat: 37.3833,
    lng: -93.1522,
    id: 1840008757,
  },
  {
    name: "Zilwaukee",
    state_id: "MI",
    lat: 43.4809,
    lng: -83.9223,
    id: 1840000351,
  },
  {
    name: "Tariffville",
    state_id: "CT",
    lat: 41.9076,
    lng: -72.7682,
    id: 1840003266,
  },
  {
    name: "Hancock",
    state_id: "MD",
    lat: 39.7054,
    lng: -78.1749,
    id: 1840005610,
  },
  {
    name: "Contoocook",
    state_id: "NH",
    lat: 43.2232,
    lng: -71.7129,
    id: 1840002749,
  },
  {
    name: "Grandview Plaza",
    state_id: "KS",
    lat: 39.0327,
    lng: -96.793,
    id: 1840008543,
  },
  {
    name: "White Pigeon",
    state_id: "MI",
    lat: 41.7977,
    lng: -85.6486,
    id: 1840011385,
  },
  {
    name: "Stony Point",
    state_id: "NC",
    lat: 35.8669,
    lng: -81.047,
    id: 1840013383,
  },
  {
    name: "Mescal",
    state_id: "AZ",
    lat: 31.9675,
    lng: -110.4368,
    id: 1840023088,
  },
  {
    name: "Bangs",
    state_id: "TX",
    lat: 31.7161,
    lng: -99.1303,
    id: 1840019518,
  },
  {
    name: "Crugers",
    state_id: "NY",
    lat: 41.2279,
    lng: -73.926,
    id: 1840004918,
  },
  {
    name: "Lusk",
    state_id: "WY",
    lat: 42.7612,
    lng: -104.4583,
    id: 1840021285,
  },
  {
    name: "Edgewood",
    state_id: "TX",
    lat: 32.6947,
    lng: -95.8833,
    id: 1840022070,
  },
  {
    name: "Augusta",
    state_id: "WI",
    lat: 44.6784,
    lng: -91.1203,
    id: 1840002292,
  },
  {
    name: "Port Dickinson",
    state_id: "NY",
    lat: 42.1361,
    lng: -75.8939,
    id: 1840004660,
  },
  {
    name: "Rome",
    state_id: "IL",
    lat: 40.8754,
    lng: -89.5129,
    id: 1840005203,
  },
  {
    name: "Stratford",
    state_id: "OK",
    lat: 34.7952,
    lng: -96.9603,
    id: 1840022824,
  },
  {
    name: "Oronoco",
    state_id: "MN",
    lat: 44.1598,
    lng: -92.5401,
    id: 1840008986,
  },
  {
    name: "Big Pine",
    state_id: "CA",
    lat: 37.1655,
    lng: -118.2952,
    id: 1840017640,
  },
  {
    name: "Kremmling",
    state_id: "CO",
    lat: 40.0566,
    lng: -106.3782,
    id: 1840021410,
  },
  {
    name: "Quinlan",
    state_id: "TX",
    lat: 32.909,
    lng: -96.1312,
    id: 1840020652,
  },
  {
    name: "McConnellstown",
    state_id: "PA",
    lat: 40.464,
    lng: -78.0672,
    id: 1840026412,
  },
  {
    name: "Lake Caroline",
    state_id: "VA",
    lat: 37.9856,
    lng: -77.5244,
    id: 1840043222,
  },
  {
    name: "Watertown",
    state_id: "TN",
    lat: 36.101,
    lng: -86.1403,
    id: 1840015315,
  },
  {
    name: "Kerens",
    state_id: "TX",
    lat: 32.1308,
    lng: -96.2251,
    id: 1840019504,
  },
  {
    name: "Salisbury",
    state_id: "MO",
    lat: 39.4233,
    lng: -92.8025,
    id: 1840009559,
  },
  {
    name: "Apollo",
    state_id: "PA",
    lat: 40.5849,
    lng: -79.5648,
    id: 1840000882,
  },
  {
    name: "Meade",
    state_id: "KS",
    lat: 37.2836,
    lng: -100.343,
    id: 1840008751,
  },
  {
    name: "Fort Loramie",
    state_id: "OH",
    lat: 40.3442,
    lng: -84.3696,
    id: 1840012194,
  },
  {
    name: "Fairmount Heights",
    state_id: "MD",
    lat: 38.9016,
    lng: -76.9153,
    id: 1840005965,
  },
  {
    name: "Lac du Flambeau",
    state_id: "WI",
    lat: 45.9696,
    lng: -89.8995,
    id: 1840004007,
  },
  {
    name: "Salem",
    state_id: "WV",
    lat: 39.2849,
    lng: -80.5645,
    id: 1840005768,
  },
  {
    name: "Limestone",
    state_id: "IL",
    lat: 41.1337,
    lng: -87.9599,
    id: 1840011696,
  },
  {
    name: "Hawthorne",
    state_id: "FL",
    lat: 29.5937,
    lng: -82.0945,
    id: 1840015059,
  },
  {
    name: "Oak Hill",
    state_id: "OH",
    lat: 38.8962,
    lng: -82.5691,
    id: 1840012745,
  },
  {
    name: "Gordon",
    state_id: "NE",
    lat: 42.8063,
    lng: -102.2039,
    id: 1840008019,
  },
  {
    name: "East Brooklyn",
    state_id: "CT",
    lat: 41.7948,
    lng: -71.8992,
    id: 1840000508,
  },
  {
    name: "Avoca",
    state_id: "IA",
    lat: 41.4829,
    lng: -95.3372,
    id: 1840007121,
  },
  {
    name: "Seven Points",
    state_id: "TX",
    lat: 32.3291,
    lng: -96.2142,
    id: 1840022109,
  },
  {
    name: "Bennington",
    state_id: "NE",
    lat: 41.3681,
    lng: -96.1616,
    id: 1840007137,
  },
  {
    name: "Home Garden",
    state_id: "CA",
    lat: 36.3025,
    lng: -119.6362,
    id: 1840017717,
  },
  {
    name: "Plum Creek",
    state_id: "VA",
    lat: 37.1268,
    lng: -80.5047,
    id: 1840037471,
  },
  {
    name: "Powhatan Point",
    state_id: "OH",
    lat: 39.8625,
    lng: -80.8098,
    id: 1840012386,
  },
  {
    name: "Shreve",
    state_id: "OH",
    lat: 40.6813,
    lng: -82.0217,
    id: 1840011898,
  },
  {
    name: "Coal Run Village",
    state_id: "KY",
    lat: 37.5372,
    lng: -82.5578,
    id: 1840014347,
  },
  {
    name: "Anna",
    state_id: "OH",
    lat: 40.3958,
    lng: -84.176,
    id: 1840010440,
  },
  {
    name: "Rainelle",
    state_id: "WV",
    lat: 37.9678,
    lng: -80.7717,
    id: 1840006290,
  },
  {
    name: "Bainbridge",
    state_id: "PA",
    lat: 40.0915,
    lng: -76.6581,
    id: 1840024328,
  },
  {
    name: "Dewart",
    state_id: "PA",
    lat: 41.1099,
    lng: -76.8707,
    id: 1840026370,
  },
  {
    name: "Nellieburg",
    state_id: "MS",
    lat: 32.3973,
    lng: -88.783,
    id: 1840013826,
  },
  {
    name: "Boyd",
    state_id: "TX",
    lat: 33.0843,
    lng: -97.5632,
    id: 1840022002,
  },
  {
    name: "Stacy",
    state_id: "MN",
    lat: 45.3841,
    lng: -92.9938,
    id: 1840008894,
  },
  {
    name: "Pepeekeo",
    state_id: "HI",
    lat: 19.8316,
    lng: -155.1061,
    id: 1840023207,
  },
  {
    name: "Laguna Park",
    state_id: "TX",
    lat: 31.8643,
    lng: -97.3862,
    id: 1840025924,
  },
  {
    name: "Waterville",
    state_id: "NY",
    lat: 42.9306,
    lng: -75.3802,
    id: 1840004215,
  },
  {
    name: "Mediapolis",
    state_id: "IA",
    lat: 41.0078,
    lng: -91.1636,
    id: 1840008281,
  },
  {
    name: "Cross Roads",
    state_id: "TX",
    lat: 33.229,
    lng: -96.9985,
    id: 1840022006,
  },
  {
    name: "Rock Hill",
    state_id: "NY",
    lat: 41.6153,
    lng: -74.5821,
    id: 1840004784,
  },
  {
    name: "Meridian",
    state_id: "TX",
    lat: 31.9262,
    lng: -97.6501,
    id: 1840020786,
  },
  {
    name: "Dallesport",
    state_id: "WA",
    lat: 45.6316,
    lng: -121.1749,
    id: 1840018525,
  },
  {
    name: "Bells",
    state_id: "TX",
    lat: 33.6166,
    lng: -96.4126,
    id: 1840020572,
  },
  {
    name: "Sebastian",
    state_id: "TX",
    lat: 26.3454,
    lng: -97.7964,
    id: 1840019737,
  },
  {
    name: "Claycomo",
    state_id: "MO",
    lat: 39.1986,
    lng: -94.4788,
    id: 1840010656,
  },
  {
    name: "Redfield",
    state_id: "AR",
    lat: 34.4429,
    lng: -92.1843,
    id: 1840014700,
  },
  {
    name: "Roseland",
    state_id: "FL",
    lat: 27.8361,
    lng: -80.4884,
    id: 1840014164,
  },
  {
    name: "Miamitown",
    state_id: "OH",
    lat: 39.2171,
    lng: -84.7095,
    id: 1840024517,
  },
  {
    name: "Twin Lake",
    state_id: "MI",
    lat: 43.3695,
    lng: -86.1805,
    id: 1840004236,
  },
  {
    name: "Chisholm",
    state_id: "ME",
    lat: 44.4916,
    lng: -70.1923,
    id: 1840002061,
  },
  {
    name: "Winchester",
    state_id: "MO",
    lat: 38.5897,
    lng: -90.526,
    id: 1840010747,
  },
  {
    name: "Willow Creek",
    state_id: "CA",
    lat: 40.9385,
    lng: -123.6412,
    id: 1840018718,
  },
  {
    name: "Nutter Fort",
    state_id: "WV",
    lat: 39.2604,
    lng: -80.3265,
    id: 1840005774,
  },
  {
    name: "Atlantic Beach",
    state_id: "NC",
    lat: 34.7011,
    lng: -76.7403,
    id: 1840015495,
  },
  {
    name: "Hilldale",
    state_id: "PA",
    lat: 41.2869,
    lng: -75.8361,
    id: 1840035016,
  },
  {
    name: "Aurora",
    state_id: "TX",
    lat: 33.056,
    lng: -97.5096,
    id: 1840019383,
  },
  {
    name: "Chualar",
    state_id: "CA",
    lat: 36.5711,
    lng: -121.5103,
    id: 1840017679,
  },
  {
    name: "Snow Hill",
    state_id: "NC",
    lat: 35.4506,
    lng: -77.6769,
    id: 1840017829,
  },
  {
    name: "West Salem",
    state_id: "OH",
    lat: 40.9696,
    lng: -82.108,
    id: 1840011900,
  },
  {
    name: "Kings Mills",
    state_id: "OH",
    lat: 39.3586,
    lng: -84.2505,
    id: 1840025603,
  },
  {
    name: "Bass Lake",
    state_id: "IN",
    lat: 41.2304,
    lng: -86.5845,
    id: 1840004910,
  },
  {
    name: "Harbor Hills",
    state_id: "OH",
    lat: 39.9366,
    lng: -82.4343,
    id: 1840005500,
  },
  {
    name: "Russell",
    state_id: "PA",
    lat: 41.9394,
    lng: -79.1393,
    id: 1840026242,
  },
  {
    name: "Pilot Rock",
    state_id: "OR",
    lat: 45.4961,
    lng: -118.8332,
    id: 1840019918,
  },
  {
    name: "Northlakes",
    state_id: "NC",
    lat: 35.7755,
    lng: -81.3669,
    id: 1840033021,
  },
  {
    name: "University of Pittsburgh Johnstown",
    state_id: "PA",
    lat: 40.263,
    lng: -78.8296,
    id: 1840043349,
  },
  {
    name: "Granger",
    state_id: "IA",
    lat: 41.7616,
    lng: -93.8234,
    id: 1840008158,
  },
  {
    name: "Rock Creek",
    state_id: "AL",
    lat: 33.4772,
    lng: -87.0812,
    id: 1840027632,
  },
  {
    name: "Marathon City",
    state_id: "WI",
    lat: 44.9379,
    lng: -89.8436,
    id: 1840037983,
  },
  {
    name: "Huntsville",
    state_id: "MO",
    lat: 39.4364,
    lng: -92.544,
    id: 1840007341,
  },
  {
    name: "Collinsville",
    state_id: "MS",
    lat: 32.4912,
    lng: -88.8452,
    id: 1840013101,
  },
  {
    name: "Granville South",
    state_id: "OH",
    lat: 40.0521,
    lng: -82.5415,
    id: 1840073760,
  },
  {
    name: "Bannockburn",
    state_id: "IL",
    lat: 42.1932,
    lng: -87.8694,
    id: 1840011148,
  },
  {
    name: "Granger",
    state_id: "TX",
    lat: 30.718,
    lng: -97.4411,
    id: 1840020876,
  },
  {
    name: "Worthington",
    state_id: "KY",
    lat: 38.5509,
    lng: -82.7349,
    id: 1840015185,
  },
  {
    name: "Highland Park",
    state_id: "PA",
    lat: 40.6216,
    lng: -77.5707,
    id: 1840005371,
  },
  {
    name: "Mora",
    state_id: "PR",
    lat: 18.4626,
    lng: -67.0322,
    id: 1630035525,
  },
  {
    name: "Herscher",
    state_id: "IL",
    lat: 41.0493,
    lng: -88.1006,
    id: 1840011697,
  },
  {
    name: "Monrovia",
    state_id: "IN",
    lat: 39.5841,
    lng: -86.4793,
    id: 1840009667,
  },
  {
    name: "Lincolnton",
    state_id: "GA",
    lat: 33.7931,
    lng: -82.4776,
    id: 1840015643,
  },
  {
    name: "Lucasville",
    state_id: "OH",
    lat: 38.8783,
    lng: -82.9934,
    id: 1840006053,
  },
  {
    name: "Buxton",
    state_id: "NC",
    lat: 35.2608,
    lng: -75.5392,
    id: 1840025781,
  },
  {
    name: "New Paris",
    state_id: "IN",
    lat: 41.5005,
    lng: -85.8248,
    id: 1840004815,
  },
  {
    name: "Clinton",
    state_id: "LA",
    lat: 30.8614,
    lng: -91.015,
    id: 1840017160,
  },
  {
    name: "Stanton",
    state_id: "NE",
    lat: 41.9472,
    lng: -97.2172,
    id: 1840009192,
  },
  {
    name: "De Soto",
    state_id: "IL",
    lat: 37.8163,
    lng: -89.2269,
    id: 1840012963,
  },
  {
    name: "Shoshone",
    state_id: "ID",
    lat: 42.9369,
    lng: -114.4055,
    id: 1840021295,
  },
  {
    name: "Neligh",
    state_id: "NE",
    lat: 42.1291,
    lng: -98.03,
    id: 1840000458,
  },
  {
    name: "Oakland",
    state_id: "IA",
    lat: 41.3114,
    lng: -95.3951,
    id: 1840008232,
  },
  {
    name: "Charenton",
    state_id: "LA",
    lat: 29.8685,
    lng: -91.5388,
    id: 1840014018,
  },
  {
    name: "Terra Alta",
    state_id: "WV",
    lat: 39.4442,
    lng: -79.5435,
    id: 1840005659,
  },
  {
    name: "Waterman",
    state_id: "IL",
    lat: 41.7683,
    lng: -88.7659,
    id: 1840011341,
  },
  {
    name: "Depoe Bay",
    state_id: "OR",
    lat: 44.8091,
    lng: -124.0599,
    id: 1840018593,
  },
  {
    name: "LaBarque Creek",
    state_id: "MO",
    lat: 38.4163,
    lng: -90.6777,
    id: 1840039631,
  },
  {
    name: "Erie",
    state_id: "IL",
    lat: 41.6588,
    lng: -90.0813,
    id: 1840011421,
  },
  {
    name: "Winfield",
    state_id: "MO",
    lat: 38.9944,
    lng: -90.7437,
    id: 1840010690,
  },
  {
    name: "Columbine Valley",
    state_id: "CO",
    lat: 39.5994,
    lng: -105.0351,
    id: 1840028444,
  },
  {
    name: "Gold Key Lake",
    state_id: "PA",
    lat: 41.3145,
    lng: -74.9434,
    id: 1840035389,
  },
  {
    name: "Orfordville",
    state_id: "WI",
    lat: 42.6294,
    lng: -89.2572,
    id: 1840002474,
  },
  {
    name: "Ackley",
    state_id: "IA",
    lat: 42.5519,
    lng: -93.0526,
    id: 1840006960,
  },
  {
    name: "Russell",
    state_id: "GA",
    lat: 33.9797,
    lng: -83.6922,
    id: 1840013681,
  },
  {
    name: "East Hazel Crest",
    state_id: "IL",
    lat: 41.5758,
    lng: -87.6503,
    id: 1840011270,
  },
  {
    name: "Lukachukai",
    state_id: "AZ",
    lat: 36.4112,
    lng: -109.2267,
    id: 1840017673,
  },
  {
    name: "St. Regis Park",
    state_id: "KY",
    lat: 38.2289,
    lng: -85.6159,
    id: 1840015195,
  },
  {
    name: "Toa Baja",
    state_id: "PR",
    lat: 18.4439,
    lng: -66.2547,
    id: 1630023735,
  },
  {
    name: "Rand",
    state_id: "WV",
    lat: 38.2817,
    lng: -81.5654,
    id: 1840025660,
  },
  {
    name: "Kittitas",
    state_id: "WA",
    lat: 46.9837,
    lng: -120.419,
    id: 1840019843,
  },
  {
    name: "Brooklyn Heights",
    state_id: "OH",
    lat: 41.4178,
    lng: -81.668,
    id: 1840000593,
  },
  {
    name: "Lake Hamilton",
    state_id: "FL",
    lat: 28.0478,
    lng: -81.627,
    id: 1840017240,
  },
  {
    name: "Parks",
    state_id: "AZ",
    lat: 35.2939,
    lng: -111.9771,
    id: 1840018970,
  },
  {
    name: "Long Beach",
    state_id: "WA",
    lat: 46.3559,
    lng: -124.0557,
    id: 1840019880,
  },
  {
    name: "Rafael Capó",
    state_id: "PR",
    lat: 18.3998,
    lng: -66.7908,
    id: 1630035469,
  },
  {
    name: "Mount Vernon",
    state_id: "AL",
    lat: 31.0889,
    lng: -88.0137,
    id: 1840017140,
  },
  {
    name: "Marion",
    state_id: "MS",
    lat: 32.4272,
    lng: -88.6502,
    id: 1840016986,
  },
  {
    name: "Ferndale",
    state_id: "PA",
    lat: 40.2884,
    lng: -78.918,
    id: 1840001111,
  },
  {
    name: "Ferron",
    state_id: "UT",
    lat: 39.0913,
    lng: -111.1337,
    id: 1840020215,
  },
  {
    name: "Centerfield",
    state_id: "UT",
    lat: 39.1275,
    lng: -111.8189,
    id: 1840022442,
  },
  {
    name: "Booker",
    state_id: "TX",
    lat: 36.4561,
    lng: -100.5402,
    id: 1840021669,
  },
  {
    name: "Elizabethville",
    state_id: "PA",
    lat: 40.5472,
    lng: -76.816,
    id: 1840001285,
  },
  {
    name: "Urbana",
    state_id: "IA",
    lat: 42.2279,
    lng: -91.8875,
    id: 1840010123,
  },
  {
    name: "Guthrie Center",
    state_id: "IA",
    lat: 41.6781,
    lng: -94.499,
    id: 1840007075,
  },
  {
    name: "Rapid City",
    state_id: "MI",
    lat: 44.8366,
    lng: -85.2871,
    id: 1840023934,
  },
  {
    name: "Copper Canyon",
    state_id: "TX",
    lat: 33.0961,
    lng: -97.0975,
    id: 1840022990,
  },
  {
    name: "Seadrift",
    state_id: "TX",
    lat: 28.4143,
    lng: -96.7158,
    id: 1840022247,
  },
  {
    name: "Sylvan Springs",
    state_id: "AL",
    lat: 33.5287,
    lng: -87.0312,
    id: 1840016803,
  },
  {
    name: "Cacao",
    state_id: "PR",
    lat: 18.4524,
    lng: -66.9559,
    id: 1630023646,
  },
  {
    name: "Dale",
    state_id: "IN",
    lat: 38.1769,
    lng: -86.9802,
    id: 1840009758,
  },
  {
    name: "Edmonston",
    state_id: "MD",
    lat: 38.9502,
    lng: -76.9332,
    id: 1840005964,
  },
  {
    name: "Greenup",
    state_id: "IL",
    lat: 39.2484,
    lng: -88.1597,
    id: 1840012690,
  },
  {
    name: "Avis",
    state_id: "PA",
    lat: 41.1857,
    lng: -77.3167,
    id: 1840003447,
  },
  {
    name: "Blue Ridge",
    state_id: "AL",
    lat: 32.501,
    lng: -86.1839,
    id: 1840013806,
  },
  {
    name: "Murphys Estates",
    state_id: "SC",
    lat: 33.6003,
    lng: -81.9439,
    id: 1840035841,
  },
  {
    name: "Audubon Park",
    state_id: "KY",
    lat: 38.205,
    lng: -85.727,
    id: 1840013199,
  },
  {
    name: "Baldwin",
    state_id: "FL",
    lat: 30.3051,
    lng: -81.9744,
    id: 1840015920,
  },
  {
    name: "Duncannon",
    state_id: "PA",
    lat: 40.3952,
    lng: -77.0277,
    id: 1840001300,
  },
  {
    name: "Wendover",
    state_id: "UT",
    lat: 40.7241,
    lng: -114.025,
    id: 1840021357,
  },
  {
    name: "Cherokee",
    state_id: "OK",
    lat: 36.7547,
    lng: -98.3551,
    id: 1840018979,
  },
  {
    name: "Colma",
    state_id: "CA",
    lat: 37.6767,
    lng: -122.4526,
    id: 1840022547,
  },
  {
    name: "McCrory",
    state_id: "AR",
    lat: 35.2572,
    lng: -91.1974,
    id: 1840015444,
  },
  {
    name: "Port Barrington",
    state_id: "IL",
    lat: 42.244,
    lng: -88.1943,
    id: 1840011136,
  },
  {
    name: "Au Sable",
    state_id: "MI",
    lat: 44.4105,
    lng: -83.3423,
    id: 1840004095,
  },
  {
    name: "Fulton",
    state_id: "TX",
    lat: 28.0706,
    lng: -97.0428,
    id: 1840022249,
  },
  {
    name: "Sun Valley",
    state_id: "ID",
    lat: 43.6837,
    lng: -114.3337,
    id: 1840021271,
  },
  {
    name: "Shepherd",
    state_id: "MI",
    lat: 43.5235,
    lng: -84.6927,
    id: 1840010963,
  },
  {
    name: "Baird",
    state_id: "TX",
    lat: 32.396,
    lng: -99.3962,
    id: 1840019480,
  },
  {
    name: "Legend Lake",
    state_id: "WI",
    lat: 44.8909,
    lng: -88.544,
    id: 1840004029,
  },
  {
    name: "Pine Knot",
    state_id: "KY",
    lat: 36.6648,
    lng: -84.4395,
    id: 1840013285,
  },
  {
    name: "Bonner-West Riverside",
    state_id: "MT",
    lat: 46.8767,
    lng: -113.8868,
    id: 1840036941,
  },
  {
    name: "Manito",
    state_id: "IL",
    lat: 40.4201,
    lng: -89.7806,
    id: 1840012210,
  },
  {
    name: "Warsaw",
    state_id: "VA",
    lat: 37.9603,
    lng: -76.761,
    id: 1840006314,
  },
  {
    name: "Ethete",
    state_id: "WY",
    lat: 43.0008,
    lng: -108.723,
    id: 1840017435,
  },
  {
    name: "Lacoochee",
    state_id: "FL",
    lat: 28.4655,
    lng: -82.1698,
    id: 1840014119,
  },
  {
    name: "Oakwood",
    state_id: "IL",
    lat: 40.1101,
    lng: -87.7769,
    id: 1840012186,
  },
  {
    name: "Windsor",
    state_id: "PA",
    lat: 39.9163,
    lng: -76.5841,
    id: 1840001468,
  },
  {
    name: "Williamsville",
    state_id: "IL",
    lat: 39.9527,
    lng: -89.5489,
    id: 1840012454,
  },
  {
    name: "Johnsonville",
    state_id: "SC",
    lat: 33.8149,
    lng: -79.4444,
    id: 1840014714,
  },
  {
    name: "North Granby",
    state_id: "CT",
    lat: 42.0152,
    lng: -72.844,
    id: 1840003265,
  },
  {
    name: "Oak Leaf",
    state_id: "TX",
    lat: 32.5138,
    lng: -96.8563,
    id: 1840020758,
  },
  {
    name: "La Alianza",
    state_id: "PR",
    lat: 18.3975,
    lng: -66.5995,
    id: 1630035694,
  },
  {
    name: "Quinebaug",
    state_id: "CT",
    lat: 42.0107,
    lng: -71.937,
    id: 1840003280,
  },
  {
    name: "Babbitt",
    state_id: "MN",
    lat: 47.6445,
    lng: -91.9458,
    id: 1840006591,
  },
  {
    name: "Lynchburg",
    state_id: "OH",
    lat: 39.2401,
    lng: -83.7883,
    id: 1840012695,
  },
  {
    name: "Glen Alpine",
    state_id: "NC",
    lat: 35.7307,
    lng: -81.7826,
    id: 1840016233,
  },
  {
    name: "Blossburg",
    state_id: "PA",
    lat: 41.6795,
    lng: -77.069,
    id: 1840003296,
  },
  {
    name: "Lone Star",
    state_id: "TX",
    lat: 32.9392,
    lng: -94.7091,
    id: 1840020675,
  },
  {
    name: "Justice",
    state_id: "OK",
    lat: 36.2909,
    lng: -95.5635,
    id: 1840034642,
  },
  {
    name: "Cannelton",
    state_id: "IN",
    lat: 37.9106,
    lng: -86.7377,
    id: 1840007521,
  },
  {
    name: "Warsaw",
    state_id: "IL",
    lat: 40.3509,
    lng: -91.4277,
    id: 1840010400,
  },
  {
    name: "Wolfe City",
    state_id: "TX",
    lat: 33.3687,
    lng: -96.072,
    id: 1840022016,
  },
  {
    name: "Paisley",
    state_id: "FL",
    lat: 28.985,
    lng: -81.5404,
    id: 1840014051,
  },
  {
    name: "Bunkerville",
    state_id: "NV",
    lat: 36.7198,
    lng: -114.1221,
    id: 1840017685,
  },
  {
    name: "Airport",
    state_id: "CA",
    lat: 37.6324,
    lng: -120.9774,
    id: 1840075978,
  },
  {
    name: "Belle",
    state_id: "MO",
    lat: 38.2848,
    lng: -91.7217,
    id: 1840007519,
  },
  {
    name: "Brilliant",
    state_id: "OH",
    lat: 40.2701,
    lng: -80.6318,
    id: 1840005424,
  },
  {
    name: "Porterdale",
    state_id: "GA",
    lat: 33.5739,
    lng: -83.894,
    id: 1840014810,
  },
  {
    name: "Bismarck",
    state_id: "MO",
    lat: 37.7673,
    lng: -90.6225,
    id: 1840007550,
  },
  {
    name: "Pine Valley",
    state_id: "CA",
    lat: 32.8415,
    lng: -116.5107,
    id: 1840019344,
  },
  {
    name: "Parkston",
    state_id: "SD",
    lat: 43.3929,
    lng: -97.9864,
    id: 1840000361,
  },
  {
    name: "Montrose",
    state_id: "PA",
    lat: 41.8335,
    lng: -75.8761,
    id: 1840003325,
  },
  {
    name: "Wheeler",
    state_id: "TX",
    lat: 35.4411,
    lng: -100.2752,
    id: 1840021759,
  },
  {
    name: "Home",
    state_id: "WA",
    lat: 47.279,
    lng: -122.7744,
    id: 1840023831,
  },
  {
    name: "Webb",
    state_id: "AL",
    lat: 31.2572,
    lng: -85.2896,
    id: 1840017134,
  },
  {
    name: "Elizabeth",
    state_id: "PA",
    lat: 40.2716,
    lng: -79.8872,
    id: 1840001229,
  },
  {
    name: "Bertram",
    state_id: "TX",
    lat: 30.7427,
    lng: -98.064,
    id: 1840019566,
  },
  {
    name: "North Redington Beach",
    state_id: "FL",
    lat: 27.8213,
    lng: -82.819,
    id: 1840017245,
  },
  {
    name: "Birch Run",
    state_id: "MI",
    lat: 43.2503,
    lng: -83.7911,
    id: 1840010983,
  },
  {
    name: "Sandia Knolls",
    state_id: "NM",
    lat: 35.1604,
    lng: -106.2987,
    id: 1840033703,
  },
  {
    name: "San Antonio",
    state_id: "FL",
    lat: 28.3396,
    lng: -82.2787,
    id: 1840015969,
  },
  {
    name: "Groton",
    state_id: "SD",
    lat: 45.4517,
    lng: -98.1,
    id: 1840002034,
  },
  {
    name: "Bartolo",
    state_id: "PR",
    lat: 18.362,
    lng: -65.8414,
    id: 1630035620,
  },
  {
    name: "Pittsville",
    state_id: "MD",
    lat: 38.3939,
    lng: -75.4074,
    id: 1840006222,
  },
  {
    name: "Cordova",
    state_id: "NC",
    lat: 34.9103,
    lng: -79.8119,
    id: 1840024981,
  },
  {
    name: "Green Valley",
    state_id: "CA",
    lat: 38.2607,
    lng: -122.1622,
    id: 1840135862,
  },
  {
    name: "Hebron",
    state_id: "NE",
    lat: 40.1681,
    lng: -97.5872,
    id: 1840007250,
  },
  {
    name: "Talahi Island",
    state_id: "GA",
    lat: 32.0331,
    lng: -80.976,
    id: 1840039168,
  },
  {
    name: "Freedom",
    state_id: "PA",
    lat: 40.6849,
    lng: -80.2533,
    id: 1840001022,
  },
  {
    name: "Monticello",
    state_id: "MS",
    lat: 31.5525,
    lng: -90.1145,
    id: 1840017077,
  },
  {
    name: "Bridgeport",
    state_id: "NE",
    lat: 41.6639,
    lng: -103.0962,
    id: 1840004795,
  },
  {
    name: "Lebec",
    state_id: "CA",
    lat: 34.845,
    lng: -118.8992,
    id: 1840017826,
  },
  {
    name: "Monona",
    state_id: "IA",
    lat: 43.0513,
    lng: -91.3912,
    id: 1840008018,
  },
  {
    name: "Interlachen",
    state_id: "FL",
    lat: 29.6203,
    lng: -81.8965,
    id: 1840017218,
  },
  {
    name: "Redington Beach",
    state_id: "FL",
    lat: 27.8128,
    lng: -82.8096,
    id: 1840018294,
  },
  {
    name: "Bon Aqua Junction",
    state_id: "TN",
    lat: 35.9281,
    lng: -87.3124,
    id: 1840024918,
  },
  {
    name: "Richmond",
    state_id: "MN",
    lat: 45.4547,
    lng: -94.5135,
    id: 1840008876,
  },
  {
    name: "Victory Gardens",
    state_id: "NJ",
    lat: 40.8762,
    lng: -74.5435,
    id: 1840000954,
  },
  {
    name: "Navesink",
    state_id: "NJ",
    lat: 40.4021,
    lng: -74.0387,
    id: 1840005443,
  },
  {
    name: "Weldon",
    state_id: "NC",
    lat: 36.421,
    lng: -77.6135,
    id: 1840016065,
  },
  {
    name: "South Palm Beach",
    state_id: "FL",
    lat: 26.5899,
    lng: -80.039,
    id: 1840018305,
  },
  {
    name: "Porcupine",
    state_id: "SD",
    lat: 43.2687,
    lng: -102.3353,
    id: 1840004202,
  },
  {
    name: "Paonia",
    state_id: "CO",
    lat: 38.8697,
    lng: -107.5919,
    id: 1840022472,
  },
  {
    name: "Mayo",
    state_id: "SC",
    lat: 35.0857,
    lng: -81.8535,
    id: 1840013513,
  },
  {
    name: "Indian Shores",
    state_id: "FL",
    lat: 27.8536,
    lng: -82.8438,
    id: 1840017243,
  },
  {
    name: "Benson",
    state_id: "UT",
    lat: 41.7489,
    lng: -111.9169,
    id: 1840017476,
  },
  {
    name: "Colwich",
    state_id: "KS",
    lat: 37.7818,
    lng: -97.5362,
    id: 1840007564,
  },
  {
    name: "Winchester",
    state_id: "IL",
    lat: 39.6298,
    lng: -90.456,
    id: 1840010604,
  },
  {
    name: "Rockvale",
    state_id: "TN",
    lat: 35.7668,
    lng: -86.521,
    id: 1840036091,
  },
  {
    name: "Omao",
    state_id: "HI",
    lat: 21.9236,
    lng: -159.4814,
    id: 1840029533,
  },
  {
    name: "Radium Springs",
    state_id: "NM",
    lat: 32.4783,
    lng: -106.9031,
    id: 1840019411,
  },
  {
    name: "Plandome",
    state_id: "NY",
    lat: 40.8069,
    lng: -73.6996,
    id: 1840005318,
  },
  {
    name: "Monument Hills",
    state_id: "CA",
    lat: 38.6641,
    lng: -121.8756,
    id: 1840038332,
  },
  {
    name: "Catawissa",
    state_id: "PA",
    lat: 40.9531,
    lng: -76.4604,
    id: 1840000812,
  },
  {
    name: "La Pryor",
    state_id: "TX",
    lat: 28.9487,
    lng: -99.8483,
    id: 1840018283,
  },
  {
    name: "Montello",
    state_id: "WI",
    lat: 43.7939,
    lng: -89.3332,
    id: 1840002595,
  },
  {
    name: "Stamps",
    state_id: "AR",
    lat: 33.3567,
    lng: -93.4956,
    id: 1840015724,
  },
  {
    name: "Brooklyn",
    state_id: "WI",
    lat: 42.8524,
    lng: -89.3723,
    id: 1840002461,
  },
  {
    name: "Central Aguirre",
    state_id: "PR",
    lat: 17.9559,
    lng: -66.2264,
    id: 1630035645,
  },
  {
    name: "Somerville",
    state_id: "TX",
    lat: 30.346,
    lng: -96.5311,
    id: 1840022171,
  },
  {
    name: "North Lewisburg",
    state_id: "OH",
    lat: 40.2205,
    lng: -83.5581,
    id: 1840012336,
  },
  {
    name: "Big Bend",
    state_id: "WI",
    lat: 42.9023,
    lng: -88.2122,
    id: 1840003015,
  },
  {
    name: "Jackson Center",
    state_id: "OH",
    lat: 40.4385,
    lng: -84.0413,
    id: 1840012191,
  },
  {
    name: "Marble Hill",
    state_id: "MO",
    lat: 37.3032,
    lng: -89.9808,
    id: 1840008738,
  },
  {
    name: "Burkesville",
    state_id: "KY",
    lat: 36.7906,
    lng: -85.3674,
    id: 1840013288,
  },
  {
    name: "Mount Pulaski",
    state_id: "IL",
    lat: 40.0102,
    lng: -89.2839,
    id: 1840008373,
  },
  {
    name: "Fosston",
    state_id: "MN",
    lat: 47.5855,
    lng: -95.7579,
    id: 1840007704,
  },
  {
    name: "Leland Grove",
    state_id: "IL",
    lat: 39.7787,
    lng: -89.6839,
    id: 1840008432,
  },
  {
    name: "Hastings",
    state_id: "FL",
    lat: 29.7144,
    lng: -81.499,
    id: 1840017203,
  },
  {
    name: "Hartington",
    state_id: "NE",
    lat: 42.6205,
    lng: -97.2672,
    id: 1840006918,
  },
  {
    name: "New Haven",
    state_id: "WV",
    lat: 38.9876,
    lng: -81.9655,
    id: 1840006051,
  },
  {
    name: "Colfax",
    state_id: "LA",
    lat: 31.5208,
    lng: -92.7001,
    id: 1840017069,
  },
  {
    name: "Ardmore",
    state_id: "AL",
    lat: 34.9847,
    lng: -86.8373,
    id: 1840015515,
  },
  {
    name: "Cumberland Head",
    state_id: "NY",
    lat: 44.7165,
    lng: -73.3966,
    id: 1840034045,
  },
  {
    name: "Akron",
    state_id: "IA",
    lat: 42.8268,
    lng: -96.5571,
    id: 1840006896,
  },
  {
    name: "Cambria",
    state_id: "IL",
    lat: 37.7799,
    lng: -89.119,
    id: 1840010853,
  },
  {
    name: "Malvern",
    state_id: "AL",
    lat: 31.1428,
    lng: -85.521,
    id: 1840017137,
  },
  {
    name: "Clermont",
    state_id: "IN",
    lat: 39.8167,
    lng: -86.3207,
    id: 1840009530,
  },
  {
    name: "Yaurel",
    state_id: "PR",
    lat: 18.0301,
    lng: -66.056,
    id: 1630023594,
  },
  {
    name: "Pine Grove Mills",
    state_id: "PA",
    lat: 40.7315,
    lng: -77.8884,
    id: 1840005160,
  },
  {
    name: "Wilson",
    state_id: "WY",
    lat: 43.486,
    lng: -110.8937,
    id: 1840018612,
  },
  {
    name: "Garrison",
    state_id: "ND",
    lat: 47.6533,
    lng: -101.4222,
    id: 1840000111,
  },
  {
    name: "Castleton-on-Hudson",
    state_id: "NY",
    lat: 42.5332,
    lng: -73.7493,
    id: 1840004440,
  },
  {
    name: "Versailles",
    state_id: "PA",
    lat: 40.3177,
    lng: -79.8309,
    id: 1840001269,
  },
  {
    name: "Wagon Wheel",
    state_id: "AZ",
    lat: 34.1976,
    lng: -110.0273,
    id: 1840075510,
  },
  {
    name: "Culver",
    state_id: "IN",
    lat: 41.2159,
    lng: -86.4236,
    id: 1840009301,
  },
  {
    name: "Blue Ridge",
    state_id: "GA",
    lat: 34.8666,
    lng: -84.3218,
    id: 1840013551,
  },
  {
    name: "Harrisville",
    state_id: "RI",
    lat: 41.9687,
    lng: -71.6767,
    id: 1840003293,
  },
  {
    name: "Brodheadsville",
    state_id: "PA",
    lat: 40.9267,
    lng: -75.4017,
    id: 1840005171,
  },
  {
    name: "Center Hill",
    state_id: "FL",
    lat: 28.6335,
    lng: -81.9986,
    id: 1840015086,
  },
  {
    name: "Lakewood Shores",
    state_id: "IL",
    lat: 41.2712,
    lng: -88.1361,
    id: 1840004819,
  },
  {
    name: "South Windham",
    state_id: "CT",
    lat: 41.685,
    lng: -72.1845,
    id: 1840003281,
  },
  {
    name: "Dunnigan",
    state_id: "CA",
    lat: 38.8926,
    lng: -121.9741,
    id: 1840024600,
  },
  {
    name: "Slater",
    state_id: "IA",
    lat: 41.8807,
    lng: -93.6912,
    id: 1840009174,
  },
  {
    name: "Perryville",
    state_id: "AR",
    lat: 35.012,
    lng: -92.803,
    id: 1840014622,
  },
  {
    name: "Fort Recovery",
    state_id: "OH",
    lat: 40.4112,
    lng: -84.7761,
    id: 1840012001,
  },
  {
    name: "Wauregan",
    state_id: "CT",
    lat: 41.75,
    lng: -71.9091,
    id: 1840003283,
  },
  {
    name: "Sutton-Alpine",
    state_id: "AK",
    lat: 61.7429,
    lng: -148.8542,
    id: 1840075108,
  },
  {
    name: "Head of the Harbor",
    state_id: "NY",
    lat: 40.8981,
    lng: -73.1624,
    id: 1840005118,
  },
  {
    name: "Burns",
    state_id: "TN",
    lat: 36.0507,
    lng: -87.2983,
    id: 1840015316,
  },
  {
    name: "South Coatesville",
    state_id: "PA",
    lat: 39.969,
    lng: -75.8135,
    id: 1840001434,
  },
  {
    name: "Newton",
    state_id: "AL",
    lat: 31.3423,
    lng: -85.5893,
    id: 1840017088,
  },
  {
    name: "Mineville",
    state_id: "NY",
    lat: 44.0933,
    lng: -73.5246,
    id: 1840023959,
  },
  {
    name: "Somerset",
    state_id: "OH",
    lat: 39.8064,
    lng: -82.2995,
    id: 1840012486,
  },
  {
    name: "Eatons Neck",
    state_id: "NY",
    lat: 40.9328,
    lng: -73.3951,
    id: 1840034049,
  },
  {
    name: "Shorewood-Tower Hills-Harbert",
    state_id: "MI",
    lat: 41.8817,
    lng: -86.6142,
    id: 1840073780,
  },
  {
    name: "China Spring",
    state_id: "TX",
    lat: 31.6492,
    lng: -97.3047,
    id: 1840042564,
  },
  {
    name: "Whitmire",
    state_id: "SC",
    lat: 34.504,
    lng: -81.6144,
    id: 1840016633,
  },
  {
    name: "Towanda",
    state_id: "KS",
    lat: 37.7954,
    lng: -96.9963,
    id: 1840010836,
  },
  {
    name: "Lake Catherine",
    state_id: "IL",
    lat: 42.487,
    lng: -88.1272,
    id: 1840029895,
  },
  {
    name: "Blades",
    state_id: "DE",
    lat: 38.6353,
    lng: -75.6022,
    id: 1840006074,
  },
  {
    name: "Gower",
    state_id: "MO",
    lat: 39.6129,
    lng: -94.5947,
    id: 1840008444,
  },
  {
    name: "Crump",
    state_id: "TN",
    lat: 35.2315,
    lng: -88.3381,
    id: 1840014588,
  },
  {
    name: "Cleveland",
    state_id: "WI",
    lat: 43.9177,
    lng: -87.7469,
    id: 1840002212,
  },
  {
    name: "Colmar Manor",
    state_id: "MD",
    lat: 38.9302,
    lng: -76.9437,
    id: 1840005985,
  },
  {
    name: "Pine Prairie",
    state_id: "LA",
    lat: 30.7818,
    lng: -92.4223,
    id: 1840018205,
  },
  {
    name: "Benítez",
    state_id: "PR",
    lat: 18.2725,
    lng: -65.8788,
    id: 1630035475,
  },
  {
    name: "Lexington",
    state_id: "MS",
    lat: 33.1163,
    lng: -90.0498,
    id: 1840014845,
  },
  {
    name: "Fivepointville",
    state_id: "PA",
    lat: 40.1828,
    lng: -76.0553,
    id: 1840024334,
  },
  {
    name: "Roaming Shores",
    state_id: "OH",
    lat: 41.6366,
    lng: -80.8249,
    id: 1840003339,
  },
  {
    name: "Billington Heights",
    state_id: "NY",
    lat: 42.7862,
    lng: -78.6237,
    id: 1840004374,
  },
  {
    name: "Sugar City",
    state_id: "ID",
    lat: 43.8762,
    lng: -111.751,
    id: 1840021277,
  },
  {
    name: "Bryson City",
    state_id: "NC",
    lat: 35.4262,
    lng: -83.4474,
    id: 1840015413,
  },
  {
    name: "Bangor",
    state_id: "WI",
    lat: 43.8938,
    lng: -90.9935,
    id: 1840002578,
  },
  {
    name: "Bremen",
    state_id: "OH",
    lat: 39.7059,
    lng: -82.4296,
    id: 1840010570,
  },
  {
    name: "Monarch Mill",
    state_id: "SC",
    lat: 34.7161,
    lng: -81.5848,
    id: 1840073867,
  },
  {
    name: "Burton",
    state_id: "OH",
    lat: 41.4708,
    lng: -81.1456,
    id: 1840010218,
  },
  {
    name: "Navajo",
    state_id: "NM",
    lat: 35.9024,
    lng: -109.0321,
    id: 1840019088,
  },
  {
    name: "Guayabal",
    state_id: "PR",
    lat: 18.0761,
    lng: -66.5017,
    id: 1630035674,
  },
  {
    name: "Dixon",
    state_id: "MO",
    lat: 37.9953,
    lng: -92.0956,
    id: 1840007557,
  },
  {
    name: "Paul",
    state_id: "ID",
    lat: 42.6052,
    lng: -113.7846,
    id: 1840020068,
  },
  {
    name: "Stony Prairie",
    state_id: "OH",
    lat: 41.3521,
    lng: -83.1519,
    id: 1840034396,
  },
  {
    name: "Kimball",
    state_id: "TN",
    lat: 35.0469,
    lng: -85.674,
    id: 1840016429,
  },
  {
    name: "Dassel",
    state_id: "MN",
    lat: 45.083,
    lng: -94.315,
    id: 1840006733,
  },
  {
    name: "Fox Lake",
    state_id: "WI",
    lat: 43.5618,
    lng: -88.913,
    id: 1840002737,
  },
  {
    name: "Woodward",
    state_id: "IA",
    lat: 41.8527,
    lng: -93.9208,
    id: 1840010182,
  },
  {
    name: "Vernon Valley",
    state_id: "NJ",
    lat: 41.2396,
    lng: -74.4833,
    id: 1840033487,
  },
  {
    name: "Leoti",
    state_id: "KS",
    lat: 38.4834,
    lng: -101.3579,
    id: 1840008624,
  },
  {
    name: "Batesville",
    state_id: "TX",
    lat: 28.9553,
    lng: -99.6237,
    id: 1840018282,
  },
  {
    name: "El Ojo",
    state_id: "PR",
    lat: 18.0038,
    lng: -66.3919,
    id: 1630035664,
  },
  {
    name: "Ackerman",
    state_id: "MS",
    lat: 33.311,
    lng: -89.1711,
    id: 1840015717,
  },
  {
    name: "Dunlap",
    state_id: "IL",
    lat: 40.8482,
    lng: -89.6716,
    id: 1840011924,
  },
  {
    name: "Rural Retreat",
    state_id: "VA",
    lat: 36.901,
    lng: -81.2765,
    id: 1840006503,
  },
  {
    name: "Westlake",
    state_id: "FL",
    lat: 26.7549,
    lng: -80.2984,
    id: 1840029114,
  },
  {
    name: "West Liberty",
    state_id: "WV",
    lat: 40.1647,
    lng: -80.5972,
    id: 1840005513,
  },
  {
    name: "Irwindale",
    state_id: "CA",
    lat: 34.1121,
    lng: -117.9636,
    id: 1840019238,
  },
  {
    name: "Hudson",
    state_id: "PA",
    lat: 41.2773,
    lng: -75.8312,
    id: 1840035024,
  },
  {
    name: "Walford",
    state_id: "IA",
    lat: 41.8796,
    lng: -91.8308,
    id: 1840010125,
  },
  {
    name: "Koontz Lake",
    state_id: "IN",
    lat: 41.4169,
    lng: -86.4827,
    id: 1840004908,
  },
  {
    name: "Friedens",
    state_id: "PA",
    lat: 40.0449,
    lng: -79.0027,
    id: 1840005498,
  },
  {
    name: "Ash Grove",
    state_id: "MO",
    lat: 37.3177,
    lng: -93.581,
    id: 1840007615,
  },
  {
    name: "Harleigh",
    state_id: "PA",
    lat: 40.9862,
    lng: -75.9702,
    id: 1840035004,
  },
  {
    name: "Grapeland",
    state_id: "TX",
    lat: 31.4948,
    lng: -95.4765,
    id: 1840020833,
  },
  {
    name: "Buttonwillow",
    state_id: "CA",
    lat: 35.4092,
    lng: -119.4406,
    id: 1840017812,
  },
  {
    name: "Ortonville",
    state_id: "MI",
    lat: 42.8511,
    lng: -83.4427,
    id: 1840011059,
  },
  {
    name: "Awendaw",
    state_id: "SC",
    lat: 32.9823,
    lng: -79.6461,
    id: 1840016011,
  },
  {
    name: "Woxall",
    state_id: "PA",
    lat: 40.311,
    lng: -75.4512,
    id: 1840035269,
  },
  {
    name: "Raubsville",
    state_id: "PA",
    lat: 40.6295,
    lng: -75.2024,
    id: 1840035169,
  },
  {
    name: "Edgar",
    state_id: "WI",
    lat: 44.9229,
    lng: -89.9626,
    id: 1840002169,
  },
  {
    name: "Arlington",
    state_id: "OH",
    lat: 40.8939,
    lng: -83.6529,
    id: 1840011738,
  },
  {
    name: "Woodbranch",
    state_id: "TX",
    lat: 30.1813,
    lng: -95.1836,
    id: 1840022176,
  },
  {
    name: "Fayette",
    state_id: "IA",
    lat: 42.8415,
    lng: -91.8036,
    id: 1840000389,
  },
  {
    name: "Dover",
    state_id: "AR",
    lat: 35.391,
    lng: -93.1143,
    id: 1840013423,
  },
  {
    name: "Vienna",
    state_id: "IL",
    lat: 37.4143,
    lng: -88.8871,
    id: 1840010864,
  },
  {
    name: "Girardville",
    state_id: "PA",
    lat: 40.7922,
    lng: -76.283,
    id: 1840002760,
  },
  {
    name: "Northwest Ithaca",
    state_id: "NY",
    lat: 42.4706,
    lng: -76.5414,
    id: 1840073644,
  },
  {
    name: "Hoback",
    state_id: "WY",
    lat: 43.3082,
    lng: -110.7443,
    id: 1840017430,
  },
  {
    name: "Purdy",
    state_id: "WA",
    lat: 47.3938,
    lng: -122.6114,
    id: 1840023838,
  },
  {
    name: "Saxonburg",
    state_id: "PA",
    lat: 40.751,
    lng: -79.815,
    id: 1840000873,
  },
  {
    name: "Keshena",
    state_id: "WI",
    lat: 44.8697,
    lng: -88.6002,
    id: 1840004028,
  },
  {
    name: "Cut and Shoot",
    state_id: "TX",
    lat: 30.3404,
    lng: -95.354,
    id: 1840022177,
  },
  {
    name: "Grand Isle",
    state_id: "LA",
    lat: 29.2134,
    lng: -90.0306,
    id: 1840017205,
  },
  {
    name: "Rotan",
    state_id: "TX",
    lat: 32.854,
    lng: -100.4655,
    id: 1840020724,
  },
  {
    name: "Laflin",
    state_id: "PA",
    lat: 41.2896,
    lng: -75.7942,
    id: 1840000757,
  },
  {
    name: "Dunnstown",
    state_id: "PA",
    lat: 41.1484,
    lng: -77.4202,
    id: 1840004907,
  },
  {
    name: "Huntington Bay",
    state_id: "NY",
    lat: 40.9014,
    lng: -73.4163,
    id: 1840005119,
  },
  {
    name: "Pinetop Country Club",
    state_id: "AZ",
    lat: 34.1163,
    lng: -109.8909,
    id: 1840075441,
  },
  {
    name: "Queens Gate",
    state_id: "PA",
    lat: 39.9406,
    lng: -76.6874,
    id: 1840040526,
  },
  {
    name: "State Center",
    state_id: "IA",
    lat: 42.0149,
    lng: -93.1652,
    id: 1840009158,
  },
  {
    name: "Fayette",
    state_id: "MS",
    lat: 31.7123,
    lng: -91.062,
    id: 1840013853,
  },
  {
    name: "Ivanhoe",
    state_id: "TX",
    lat: 30.6799,
    lng: -94.4154,
    id: 1840037787,
  },
  {
    name: "Gretna",
    state_id: "FL",
    lat: 30.5901,
    lng: -84.6861,
    id: 1840013943,
  },
  {
    name: "Queen City",
    state_id: "TX",
    lat: 33.1507,
    lng: -94.1524,
    id: 1840020680,
  },
  {
    name: "Hurley",
    state_id: "WI",
    lat: 46.4462,
    lng: -90.1982,
    id: 1840001952,
  },
  {
    name: "Virginia",
    state_id: "IL",
    lat: 39.9525,
    lng: -90.2107,
    id: 1840010528,
  },
  {
    name: "Terre Hill",
    state_id: "PA",
    lat: 40.1592,
    lng: -76.0507,
    id: 1840003728,
  },
  {
    name: "St. Peter",
    state_id: "WI",
    lat: 43.8377,
    lng: -88.3465,
    id: 1840024009,
  },
  {
    name: "Roxana",
    state_id: "IL",
    lat: 38.8318,
    lng: -90.0466,
    id: 1840012798,
  },
  {
    name: "Sutton",
    state_id: "NE",
    lat: 40.6072,
    lng: -97.8589,
    id: 1840003618,
  },
  {
    name: "Chappaqua",
    state_id: "NY",
    lat: 41.1601,
    lng: -73.7672,
    id: 1840004916,
  },
  {
    name: "Corning",
    state_id: "IA",
    lat: 40.9929,
    lng: -94.7395,
    id: 1840007156,
  },
  {
    name: "Iota",
    state_id: "LA",
    lat: 30.326,
    lng: -92.4956,
    id: 1840017199,
  },
  {
    name: "Darmstadt",
    state_id: "IN",
    lat: 38.0938,
    lng: -87.5793,
    id: 1840009767,
  },
  {
    name: "Noank",
    state_id: "CT",
    lat: 41.334,
    lng: -71.9978,
    id: 1840003351,
  },
  {
    name: "Oakdale",
    state_id: "PA",
    lat: 40.4001,
    lng: -80.1872,
    id: 1840001249,
  },
  {
    name: "Tarkio",
    state_id: "MO",
    lat: 40.443,
    lng: -95.3835,
    id: 1840009413,
  },
  {
    name: "West Conshohocken",
    state_id: "PA",
    lat: 40.0702,
    lng: -75.3189,
    id: 1840001380,
  },
  {
    name: "Fort Benton",
    state_id: "MT",
    lat: 47.8291,
    lng: -110.6557,
    id: 1840019783,
  },
  {
    name: "Flatonia",
    state_id: "TX",
    lat: 29.6886,
    lng: -97.106,
    id: 1840022208,
  },
  {
    name: "Capitan",
    state_id: "NM",
    lat: 33.5392,
    lng: -105.5983,
    id: 1840022871,
  },
  {
    name: "Forest",
    state_id: "OH",
    lat: 40.8051,
    lng: -83.5116,
    id: 1840011958,
  },
  {
    name: "Las Ollas",
    state_id: "PR",
    lat: 18.0142,
    lng: -66.4207,
    id: 1630035504,
  },
  {
    name: "Mancos",
    state_id: "CO",
    lat: 37.3466,
    lng: -108.2939,
    id: 1840022555,
  },
  {
    name: "Cinnamon Lake",
    state_id: "OH",
    lat: 40.9835,
    lng: -82.1914,
    id: 1840042970,
  },
  {
    name: "Cassville",
    state_id: "WV",
    lat: 39.675,
    lng: -80.0678,
    id: 1840005644,
  },
  {
    name: "Fennville",
    state_id: "MI",
    lat: 42.5947,
    lng: -86.1051,
    id: 1840003115,
  },
  {
    name: "Tylertown",
    state_id: "MS",
    lat: 31.1173,
    lng: -90.1444,
    id: 1840017118,
  },
  {
    name: "Winlock",
    state_id: "WA",
    lat: 46.4907,
    lng: -122.9342,
    id: 1840021164,
  },
  {
    name: "Piedra Gorda",
    state_id: "PR",
    lat: 18.4342,
    lng: -66.8861,
    id: 1630035547,
  },
  {
    name: "La Vernia",
    state_id: "TX",
    lat: 29.3542,
    lng: -98.122,
    id: 1840020982,
  },
  {
    name: "Washington Mills",
    state_id: "NY",
    lat: 43.0479,
    lng: -75.2811,
    id: 1840024028,
  },
  {
    name: "Thompson Falls",
    state_id: "MT",
    lat: 47.5993,
    lng: -115.3425,
    id: 1840021083,
  },
  {
    name: "Chatham",
    state_id: "VA",
    lat: 36.8185,
    lng: -79.3967,
    id: 1840006494,
  },
  {
    name: "Midpines",
    state_id: "CA",
    lat: 37.5531,
    lng: -119.9485,
    id: 1840024755,
  },
  {
    name: "Castle Hill",
    state_id: "CA",
    lat: 37.8729,
    lng: -122.0573,
    id: 1840075652,
  },
  {
    name: "Mountain Village",
    state_id: "CO",
    lat: 37.9324,
    lng: -107.8577,
    id: 1840022532,
  },
  {
    name: "Taos Pueblo",
    state_id: "NM",
    lat: 36.4657,
    lng: -105.5634,
    id: 1840019014,
  },
  {
    name: "Sundown",
    state_id: "TX",
    lat: 33.4575,
    lng: -102.4908,
    id: 1840021985,
  },
  {
    name: "Chevy Chase Heights",
    state_id: "PA",
    lat: 40.6403,
    lng: -79.1456,
    id: 1840035304,
  },
  {
    name: "Cadott",
    state_id: "WI",
    lat: 44.9502,
    lng: -91.1532,
    id: 1840002107,
  },
  {
    name: "Langhorne Manor",
    state_id: "PA",
    lat: 40.1658,
    lng: -74.9174,
    id: 1840001325,
  },
  {
    name: "West Baraboo",
    state_id: "WI",
    lat: 43.4815,
    lng: -89.7718,
    id: 1840002716,
  },
  {
    name: "Centreville",
    state_id: "MS",
    lat: 31.087,
    lng: -91.0654,
    id: 1840017108,
  },
  {
    name: "Westport",
    state_id: "IN",
    lat: 39.1767,
    lng: -85.5747,
    id: 1840010658,
  },
  {
    name: "Presquille",
    state_id: "LA",
    lat: 29.5586,
    lng: -90.6385,
    id: 1840025141,
  },
  {
    name: "Ten Mile Run",
    state_id: "NJ",
    lat: 40.4219,
    lng: -74.589,
    id: 1840033462,
  },
  {
    name: "Greenville",
    state_id: "VA",
    lat: 38.0036,
    lng: -79.1522,
    id: 1840006243,
  },
  {
    name: "Greenwich",
    state_id: "OH",
    lat: 41.0318,
    lng: -82.5198,
    id: 1840011706,
  },
  {
    name: "Stanton",
    state_id: "MI",
    lat: 43.2928,
    lng: -85.0788,
    id: 1840002875,
  },
  {
    name: "Benld",
    state_id: "IL",
    lat: 39.093,
    lng: -89.8023,
    id: 1840007367,
  },
  {
    name: "Oakland",
    state_id: "PA",
    lat: 40.3055,
    lng: -78.8819,
    id: 1840144363,
  },
  {
    name: "Eldridge",
    state_id: "CA",
    lat: 38.3339,
    lng: -122.5065,
    id: 1840017574,
  },
  {
    name: "Manning",
    state_id: "IA",
    lat: 41.9092,
    lng: -95.0642,
    id: 1840000491,
  },
  {
    name: "New Beaver",
    state_id: "PA",
    lat: 40.8773,
    lng: -80.3702,
    id: 1840003571,
  },
  {
    name: "Darrington",
    state_id: "WA",
    lat: 48.2546,
    lng: -121.6031,
    id: 1840021081,
  },
  {
    name: "Stapleton",
    state_id: "AL",
    lat: 30.7394,
    lng: -87.7943,
    id: 1840027497,
  },
  {
    name: "Linntown",
    state_id: "PA",
    lat: 40.9566,
    lng: -76.9002,
    id: 1840035066,
  },
  {
    name: "Celina",
    state_id: "TN",
    lat: 36.5469,
    lng: -85.5043,
    id: 1840014424,
  },
  {
    name: "Walls",
    state_id: "MS",
    lat: 34.9257,
    lng: -90.1604,
    id: 1840001788,
  },
  {
    name: "Pemberville",
    state_id: "OH",
    lat: 41.4096,
    lng: -83.4588,
    id: 1840011561,
  },
  {
    name: "Arma",
    state_id: "KS",
    lat: 37.543,
    lng: -94.7024,
    id: 1840007592,
  },
  {
    name: "Morris",
    state_id: "OK",
    lat: 35.6152,
    lng: -95.8624,
    id: 1840020399,
  },
  {
    name: "Moody",
    state_id: "TX",
    lat: 31.3086,
    lng: -97.3596,
    id: 1840020817,
  },
  {
    name: "Rustburg",
    state_id: "VA",
    lat: 37.2712,
    lng: -79.0962,
    id: 1840006436,
  },
  {
    name: "Black Earth",
    state_id: "WI",
    lat: 43.1341,
    lng: -89.747,
    id: 1840002907,
  },
  {
    name: "San Antonio",
    state_id: "PR",
    lat: 18.4382,
    lng: -66.9362,
    id: 1630146624,
  },
  {
    name: "The Pinehills",
    state_id: "MA",
    lat: 41.8882,
    lng: -70.6,
    id: 1840039566,
  },
  {
    name: "Logan",
    state_id: "IA",
    lat: 41.6453,
    lng: -95.7917,
    id: 1840009205,
  },
  {
    name: "Red Lake Falls",
    state_id: "MN",
    lat: 47.8837,
    lng: -96.2727,
    id: 1840008829,
  },
  {
    name: "Bonduel",
    state_id: "WI",
    lat: 44.7383,
    lng: -88.4475,
    id: 1840002263,
  },
  {
    name: "Pine Mountain",
    state_id: "GA",
    lat: 32.8522,
    lng: -84.8522,
    id: 1840016961,
  },
  {
    name: "Biltmore Forest",
    state_id: "NC",
    lat: 35.5348,
    lng: -82.5403,
    id: 1840015391,
  },
  {
    name: "Rockaway Beach",
    state_id: "OR",
    lat: 45.6186,
    lng: -123.9398,
    id: 1840019927,
  },
  {
    name: "Aristocrat Ranchettes",
    state_id: "CO",
    lat: 40.1096,
    lng: -104.7549,
    id: 1840028560,
  },
  {
    name: "Olmito",
    state_id: "TX",
    lat: 26.0232,
    lng: -97.5379,
    id: 1840019738,
  },
  {
    name: "Chesterfield",
    state_id: "SC",
    lat: 34.733,
    lng: -80.078,
    id: 1840016583,
  },
  {
    name: "Helena",
    state_id: "OK",
    lat: 36.5466,
    lng: -98.2714,
    id: 1840021610,
  },
  {
    name: "Chesapeake",
    state_id: "WV",
    lat: 38.2233,
    lng: -81.5363,
    id: 1840006204,
  },
  {
    name: "Clintondale",
    state_id: "NY",
    lat: 41.6927,
    lng: -74.0458,
    id: 1840004703,
  },
  {
    name: "Felton",
    state_id: "DE",
    lat: 39.0127,
    lng: -75.5764,
    id: 1840005806,
  },
  {
    name: "Verona",
    state_id: "KY",
    lat: 38.8122,
    lng: -84.6649,
    id: 1840026683,
  },
  {
    name: "Hico",
    state_id: "TX",
    lat: 31.9859,
    lng: -98.0291,
    id: 1840020802,
  },
  {
    name: "San Rafael",
    state_id: "NM",
    lat: 35.0878,
    lng: -107.8895,
    id: 1840024968,
  },
  {
    name: "Pilot Mountain",
    state_id: "NC",
    lat: 36.3854,
    lng: -80.4735,
    id: 1840016032,
  },
  {
    name: "Mount Gay-Shamrock",
    state_id: "WV",
    lat: 37.8512,
    lng: -82.0229,
    id: 1840073632,
  },
  {
    name: "Montezuma",
    state_id: "IA",
    lat: 41.5833,
    lng: -92.5274,
    id: 1840008167,
  },
  {
    name: "Sweet Springs",
    state_id: "MO",
    lat: 38.9649,
    lng: -93.4152,
    id: 1840009614,
  },
  {
    name: "Big Sandy",
    state_id: "TX",
    lat: 32.586,
    lng: -95.1127,
    id: 1840020733,
  },
  {
    name: "Raleigh",
    state_id: "MS",
    lat: 32.0322,
    lng: -89.5247,
    id: 1840018131,
  },
  {
    name: "Coloma",
    state_id: "MI",
    lat: 42.1864,
    lng: -86.3083,
    id: 1840003218,
  },
  {
    name: "Woodsboro",
    state_id: "TX",
    lat: 28.2379,
    lng: -97.3255,
    id: 1840023165,
  },
  {
    name: "Earlham",
    state_id: "IA",
    lat: 41.4945,
    lng: -94.1222,
    id: 1840008221,
  },
  {
    name: "Dillsboro",
    state_id: "IN",
    lat: 39.0185,
    lng: -85.0531,
    id: 1840009623,
  },
  {
    name: "Hill City",
    state_id: "KS",
    lat: 39.3671,
    lng: -99.8461,
    id: 1840007352,
  },
  {
    name: "Lake Brownwood",
    state_id: "TX",
    lat: 31.8177,
    lng: -99.1045,
    id: 1840018146,
  },
  {
    name: "Cedarville",
    state_id: "AR",
    lat: 35.583,
    lng: -94.3615,
    id: 1840014536,
  },
  {
    name: "Guthrie",
    state_id: "KY",
    lat: 36.6502,
    lng: -87.1741,
    id: 1840013273,
  },
  {
    name: "Janesville",
    state_id: "CA",
    lat: 40.2809,
    lng: -120.5335,
    id: 1840025533,
  },
  {
    name: "Atglen",
    state_id: "PA",
    lat: 39.9473,
    lng: -75.9754,
    id: 1840001422,
  },
  {
    name: "Millers Creek",
    state_id: "NC",
    lat: 36.1909,
    lng: -81.2356,
    id: 1840013338,
  },
  {
    name: "Claremont",
    state_id: "NC",
    lat: 35.7101,
    lng: -81.1533,
    id: 1840014521,
  },
  {
    name: "South Woodstock",
    state_id: "CT",
    lat: 41.9275,
    lng: -71.9627,
    id: 1840003282,
  },
  {
    name: "Sand Lake",
    state_id: "MI",
    lat: 44.3276,
    lng: -83.6711,
    id: 1840143090,
  },
  {
    name: "Lomas",
    state_id: "PR",
    lat: 18.2687,
    lng: -65.9091,
    id: 1630035509,
  },
  {
    name: "Worthington",
    state_id: "IN",
    lat: 39.1184,
    lng: -86.9798,
    id: 1840010697,
  },
  {
    name: "Cornell",
    state_id: "WI",
    lat: 45.1632,
    lng: -91.1497,
    id: 1840002109,
  },
  {
    name: "Glade Spring",
    state_id: "VA",
    lat: 36.7899,
    lng: -81.7726,
    id: 1840006528,
  },
  {
    name: "Bagley",
    state_id: "MN",
    lat: 47.5239,
    lng: -95.4041,
    id: 1840006608,
  },
  {
    name: "Benavides",
    state_id: "TX",
    lat: 27.5969,
    lng: -98.4137,
    id: 1840019712,
  },
  {
    name: "Millbrook",
    state_id: "NY",
    lat: 41.7842,
    lng: -73.6937,
    id: 1840004749,
  },
  {
    name: "Centreville",
    state_id: "MI",
    lat: 41.9213,
    lng: -85.5259,
    id: 1840010158,
  },
  {
    name: "Silver Lake",
    state_id: "KS",
    lat: 39.0993,
    lng: -95.8564,
    id: 1840009685,
  },
  {
    name: "Eureka",
    state_id: "MT",
    lat: 48.879,
    lng: -115.0492,
    id: 1840021052,
  },
  {
    name: "Dunes City",
    state_id: "OR",
    lat: 43.9079,
    lng: -124.0985,
    id: 1840018626,
  },
  {
    name: "Hubbard",
    state_id: "TX",
    lat: 31.847,
    lng: -96.8003,
    id: 1840020784,
  },
  {
    name: "Harwich Center",
    state_id: "MA",
    lat: 41.6924,
    lng: -70.0694,
    id: 1840073418,
  },
  {
    name: "Great River",
    state_id: "NY",
    lat: 40.7164,
    lng: -73.1603,
    id: 1840005073,
  },
  {
    name: "Shortsville",
    state_id: "NY",
    lat: 42.9555,
    lng: -77.2228,
    id: 1840004413,
  },
  {
    name: "Bay City",
    state_id: "OR",
    lat: 45.5216,
    lng: -123.8858,
    id: 1840018552,
  },
  {
    name: "Bryn Athyn",
    state_id: "PA",
    lat: 40.1411,
    lng: -75.0674,
    id: 1840003695,
  },
  {
    name: "Serenada",
    state_id: "TX",
    lat: 30.6962,
    lng: -97.6969,
    id: 1840019571,
  },
  {
    name: "Menard",
    state_id: "TX",
    lat: 30.9185,
    lng: -99.7837,
    id: 1840020863,
  },
  {
    name: "St. Elmo",
    state_id: "IL",
    lat: 39.0228,
    lng: -88.8513,
    id: 1840009687,
  },
  {
    name: "Greenland",
    state_id: "AR",
    lat: 35.9965,
    lng: -94.1903,
    id: 1840013369,
  },
  {
    name: "Fall Branch",
    state_id: "TN",
    lat: 36.4161,
    lng: -82.6242,
    id: 1840013064,
  },
  {
    name: "Brookfield Center",
    state_id: "OH",
    lat: 41.2383,
    lng: -80.5548,
    id: 1840004903,
  },
  {
    name: "Kingsley",
    state_id: "IA",
    lat: 42.5865,
    lng: -95.9678,
    id: 1840008021,
  },
  {
    name: "Crystal Lakes",
    state_id: "OH",
    lat: 39.8861,
    lng: -84.0242,
    id: 1840034148,
  },
  {
    name: "Shenandoah Heights",
    state_id: "PA",
    lat: 40.8322,
    lng: -76.2061,
    id: 1840035194,
  },
  {
    name: "Markesan",
    state_id: "WI",
    lat: 43.7099,
    lng: -88.9907,
    id: 1840002591,
  },
  {
    name: "Pelzer",
    state_id: "SC",
    lat: 34.6434,
    lng: -82.4588,
    id: 1840016577,
  },
  {
    name: "Twin Grove",
    state_id: "IL",
    lat: 40.4857,
    lng: -89.0993,
    id: 1840025538,
  },
  {
    name: "Dawson",
    state_id: "MN",
    lat: 44.9289,
    lng: -96.0504,
    id: 1840006743,
  },
  {
    name: "Philo",
    state_id: "IL",
    lat: 40.0033,
    lng: -88.1563,
    id: 1840012235,
  },
  {
    name: "New California",
    state_id: "OH",
    lat: 40.1502,
    lng: -83.2372,
    id: 1840034082,
  },
  {
    name: "Lake Waccamaw",
    state_id: "NC",
    lat: 34.3141,
    lng: -78.5117,
    id: 1840016661,
  },
  {
    name: "Crainville",
    state_id: "IL",
    lat: 37.7511,
    lng: -89.06,
    id: 1840012985,
  },
  {
    name: "Avondale",
    state_id: "PA",
    lat: 39.8248,
    lng: -75.7819,
    id: 1840001423,
  },
  {
    name: "North DeLand",
    state_id: "FL",
    lat: 29.0484,
    lng: -81.2966,
    id: 1840029059,
  },
  {
    name: "Vanceburg",
    state_id: "KY",
    lat: 38.5937,
    lng: -83.3212,
    id: 1840015187,
  },
  {
    name: "Madison",
    state_id: "MN",
    lat: 45.0127,
    lng: -96.1889,
    id: 1840008920,
  },
  {
    name: "Brooklyn",
    state_id: "IA",
    lat: 41.7314,
    lng: -92.4453,
    id: 1840004799,
  },
  {
    name: "Whitley City",
    state_id: "KY",
    lat: 36.7238,
    lng: -84.4731,
    id: 1840013284,
  },
  {
    name: "Falling Waters",
    state_id: "WV",
    lat: 39.5637,
    lng: -77.8874,
    id: 1840025601,
  },
  {
    name: "Pioneer",
    state_id: "OH",
    lat: 41.6773,
    lng: -84.5524,
    id: 1840011523,
  },
  {
    name: "Beaver",
    state_id: "OK",
    lat: 36.8151,
    lng: -100.5235,
    id: 1840020352,
  },
  {
    name: "Wetherington",
    state_id: "OH",
    lat: 39.3633,
    lng: -84.3767,
    id: 1840034376,
  },
  {
    name: "Avon",
    state_id: "PA",
    lat: 40.3429,
    lng: -76.3794,
    id: 1840034853,
  },
  {
    name: "Moose Wilson Road",
    state_id: "WY",
    lat: 43.5315,
    lng: -110.834,
    id: 1840038434,
  },
  {
    name: "New Windsor",
    state_id: "MD",
    lat: 39.5442,
    lng: -77.1016,
    id: 1840005704,
  },
  {
    name: "Aetna Estates",
    state_id: "CO",
    lat: 39.7382,
    lng: -104.6734,
    id: 1840038588,
  },
  {
    name: "Gridley",
    state_id: "IL",
    lat: 40.7437,
    lng: -88.8809,
    id: 1840011972,
  },
  {
    name: "Princeton",
    state_id: "NC",
    lat: 35.4671,
    lng: -78.1613,
    id: 1840017793,
  },
  {
    name: "Montague",
    state_id: "CA",
    lat: 41.7276,
    lng: -122.5304,
    id: 1840020105,
  },
  {
    name: "Indian Hills",
    state_id: "CO",
    lat: 39.6294,
    lng: -105.2509,
    id: 1840017540,
  },
  {
    name: "South Carthage",
    state_id: "TN",
    lat: 36.2414,
    lng: -85.9575,
    id: 1840017721,
  },
  {
    name: "Bel-Nor",
    state_id: "MO",
    lat: 38.7017,
    lng: -90.318,
    id: 1840012815,
  },
  {
    name: "Tracy City",
    state_id: "TN",
    lat: 35.262,
    lng: -85.7515,
    id: 1840016344,
  },
  {
    name: "Rome City",
    state_id: "IN",
    lat: 41.4892,
    lng: -85.361,
    id: 1840010251,
  },
  {
    name: "Sackets Harbor",
    state_id: "NY",
    lat: 43.9418,
    lng: -76.1182,
    id: 1840004116,
  },
  {
    name: "Buffalo",
    state_id: "SC",
    lat: 34.7245,
    lng: -81.6843,
    id: 1840013563,
  },
  {
    name: "Mendon",
    state_id: "UT",
    lat: 41.7102,
    lng: -111.9776,
    id: 1840020111,
  },
  {
    name: "Luna Pier",
    state_id: "MI",
    lat: 41.8049,
    lng: -83.4426,
    id: 1840003989,
  },
  {
    name: "Lake Lorelei",
    state_id: "OH",
    lat: 39.1875,
    lng: -83.9716,
    id: 1840042995,
  },
  {
    name: "Elmore",
    state_id: "OH",
    lat: 41.4707,
    lng: -83.2912,
    id: 1840011499,
  },
  {
    name: "Cleora",
    state_id: "OK",
    lat: 36.5738,
    lng: -94.948,
    id: 1840017696,
  },
  {
    name: "Edison",
    state_id: "GA",
    lat: 31.5608,
    lng: -84.7374,
    id: 1840013867,
  },
  {
    name: "Mountain Mesa",
    state_id: "CA",
    lat: 35.6411,
    lng: -118.4047,
    id: 1840019135,
  },
  {
    name: "Middletown",
    state_id: "VA",
    lat: 39.0288,
    lng: -78.278,
    id: 1840005776,
  },
  {
    name: "Locust Grove",
    state_id: "OK",
    lat: 36.1978,
    lng: -95.1685,
    id: 1840021663,
  },
  {
    name: "Pomeroy",
    state_id: "WA",
    lat: 46.4737,
    lng: -117.5965,
    id: 1840019891,
  },
  {
    name: "Brocton",
    state_id: "NY",
    lat: 42.39,
    lng: -79.4429,
    id: 1840004575,
  },
  {
    name: "Bruceton",
    state_id: "TN",
    lat: 36.0349,
    lng: -88.2466,
    id: 1840015348,
  },
  {
    name: "Wellman",
    state_id: "IA",
    lat: 41.4693,
    lng: -91.8354,
    id: 1840010256,
  },
  {
    name: "Irwin",
    state_id: "SC",
    lat: 34.6942,
    lng: -80.8198,
    id: 1840013531,
  },
  {
    name: "Clarks Green",
    state_id: "PA",
    lat: 41.5009,
    lng: -75.6952,
    id: 1840003377,
  },
  {
    name: "Olivet",
    state_id: "NJ",
    lat: 39.5402,
    lng: -75.1727,
    id: 1840005592,
  },
  {
    name: "Milford",
    state_id: "UT",
    lat: 38.3945,
    lng: -113.0123,
    id: 1840020252,
  },
  {
    name: "Donovan Estates",
    state_id: "AZ",
    lat: 32.7094,
    lng: -114.6782,
    id: 1840075311,
  },
  {
    name: "Salina",
    state_id: "OK",
    lat: 36.2907,
    lng: -95.1519,
    id: 1840022668,
  },
  {
    name: "Durand",
    state_id: "IL",
    lat: 42.4341,
    lng: -89.327,
    id: 1840011120,
  },
  {
    name: "Kilmarnock",
    state_id: "VA",
    lat: 37.7118,
    lng: -76.3834,
    id: 1840006370,
  },
  {
    name: "Palmarejo",
    state_id: "PR",
    lat: 18.0412,
    lng: -67.0764,
    id: 1630035467,
  },
  {
    name: "Hillsboro",
    state_id: "WI",
    lat: 43.6559,
    lng: -90.3365,
    id: 1840002691,
  },
  {
    name: "Five Points",
    state_id: "FL",
    lat: 30.2225,
    lng: -82.6465,
    id: 1840013954,
  },
  {
    name: "Loving",
    state_id: "NM",
    lat: 32.2866,
    lng: -104.097,
    id: 1840023033,
  },
  {
    name: "Surrey",
    state_id: "ND",
    lat: 48.2435,
    lng: -101.1339,
    id: 1840001869,
  },
  {
    name: "New Castle Northwest",
    state_id: "PA",
    lat: 41.0221,
    lng: -80.3566,
    id: 1840035123,
  },
  {
    name: "Apache",
    state_id: "OK",
    lat: 34.8938,
    lng: -98.3581,
    id: 1840021763,
  },
  {
    name: "Flomaton",
    state_id: "AL",
    lat: 31.0127,
    lng: -87.2541,
    id: 1840006309,
  },
  {
    name: "Yorktown Heights",
    state_id: "NY",
    lat: 41.2696,
    lng: -73.7755,
    id: 1840004930,
  },
  {
    name: "Gasport",
    state_id: "NY",
    lat: 43.195,
    lng: -78.5764,
    id: 1840004270,
  },
  {
    name: "West Marion",
    state_id: "NC",
    lat: 35.6525,
    lng: -82.0191,
    id: 1840013397,
  },
  {
    name: "Frazee",
    state_id: "MN",
    lat: 46.7282,
    lng: -95.7026,
    id: 1840007725,
  },
  {
    name: "Russellton",
    state_id: "PA",
    lat: 40.6082,
    lng: -79.8401,
    id: 1840005395,
  },
  {
    name: "Woodway",
    state_id: "WA",
    lat: 47.7904,
    lng: -122.3851,
    id: 1840037730,
  },
  {
    name: "Moodus",
    state_id: "CT",
    lat: 41.5043,
    lng: -72.449,
    id: 1840003369,
  },
  {
    name: "Rice",
    state_id: "MN",
    lat: 45.7525,
    lng: -94.2317,
    id: 1840008872,
  },
  {
    name: "Parsons",
    state_id: "WV",
    lat: 39.0942,
    lng: -79.6786,
    id: 1840005888,
  },
  {
    name: "Elmo",
    state_id: "TX",
    lat: 32.7315,
    lng: -96.159,
    id: 1840025893,
  },
  {
    name: "Wolf Creek",
    state_id: "UT",
    lat: 41.3253,
    lng: -111.8288,
    id: 1840037239,
  },
  {
    name: "New Pekin",
    state_id: "IN",
    lat: 38.5029,
    lng: -86.0152,
    id: 1840009794,
  },
  {
    name: "Williams",
    state_id: "OR",
    lat: 42.2194,
    lng: -123.2922,
    id: 1840025489,
  },
  {
    name: "Lakeshire",
    state_id: "MO",
    lat: 38.54,
    lng: -90.3384,
    id: 1840008600,
  },
  {
    name: "Lenox",
    state_id: "IA",
    lat: 40.883,
    lng: -94.5583,
    id: 1840008302,
  },
  {
    name: "New Sarpy",
    state_id: "LA",
    lat: 29.9809,
    lng: -90.3833,
    id: 1840013982,
  },
  {
    name: "Shoemakersville",
    state_id: "PA",
    lat: 40.5004,
    lng: -75.97,
    id: 1840001189,
  },
  {
    name: "Mishicot",
    state_id: "WI",
    lat: 44.2303,
    lng: -87.6422,
    id: 1840002215,
  },
  {
    name: "Allendale",
    state_id: "CA",
    lat: 38.4426,
    lng: -121.9814,
    id: 1840022509,
  },
  {
    name: "Blawnox",
    state_id: "PA",
    lat: 40.4923,
    lng: -79.86,
    id: 1840001207,
  },
  {
    name: "White Cloud",
    state_id: "MI",
    lat: 43.5541,
    lng: -85.772,
    id: 1840000344,
  },
  {
    name: "Kingsford Heights",
    state_id: "IN",
    lat: 41.4784,
    lng: -86.693,
    id: 1840009246,
  },
  {
    name: "Pioneer",
    state_id: "CA",
    lat: 38.4354,
    lng: -120.5848,
    id: 1840024638,
  },
  {
    name: "East Griffin",
    state_id: "GA",
    lat: 33.2439,
    lng: -84.2318,
    id: 1840013098,
  },
  {
    name: "Mashpee Neck",
    state_id: "MA",
    lat: 41.6118,
    lng: -70.4667,
    id: 1840031246,
  },
  {
    name: "Woodbine",
    state_id: "IA",
    lat: 41.7346,
    lng: -95.7067,
    id: 1840010177,
  },
  {
    name: "The Hideout",
    state_id: "PA",
    lat: 41.4398,
    lng: -75.3478,
    id: 1840035450,
  },
  {
    name: "Odon",
    state_id: "IN",
    lat: 38.8423,
    lng: -86.9894,
    id: 1840009738,
  },
  {
    name: "Mount Wolf",
    state_id: "PA",
    lat: 40.0616,
    lng: -76.7053,
    id: 1840001456,
  },
  {
    name: "Adams Center",
    state_id: "NY",
    lat: 43.8616,
    lng: -76.0001,
    id: 1840004104,
  },
  {
    name: "Swepsonville",
    state_id: "NC",
    lat: 36.0285,
    lng: -79.355,
    id: 1840016145,
  },
  {
    name: "Hackberry",
    state_id: "LA",
    lat: 29.9717,
    lng: -93.3947,
    id: 1840014013,
  },
  {
    name: "Tatum",
    state_id: "TX",
    lat: 32.3158,
    lng: -94.5189,
    id: 1840022107,
  },
  {
    name: "Bedford",
    state_id: "IA",
    lat: 40.6711,
    lng: -94.724,
    id: 1840007188,
  },
  {
    name: "Lewiston",
    state_id: "CA",
    lat: 40.6969,
    lng: -122.8225,
    id: 1840017488,
  },
  {
    name: "Grandview",
    state_id: "IL",
    lat: 39.8175,
    lng: -89.6181,
    id: 1840012441,
  },
  {
    name: "Standish",
    state_id: "MI",
    lat: 43.9794,
    lng: -83.9637,
    id: 1840003949,
  },
  {
    name: "Tallaboa Alta",
    state_id: "PR",
    lat: 18.0504,
    lng: -66.7018,
    id: 1630023558,
  },
  {
    name: "Pelahatchie",
    state_id: "MS",
    lat: 32.3148,
    lng: -89.8082,
    id: 1840016983,
  },
  {
    name: "Sanborn",
    state_id: "IA",
    lat: 43.1814,
    lng: -95.6553,
    id: 1840009050,
  },
  {
    name: "Gardner",
    state_id: "IL",
    lat: 41.1931,
    lng: -88.3146,
    id: 1840011616,
  },
  {
    name: "Lone Tree",
    state_id: "IA",
    lat: 41.4859,
    lng: -91.4267,
    id: 1840009227,
  },
  {
    name: "Masonville",
    state_id: "KY",
    lat: 37.6718,
    lng: -87.0511,
    id: 1840013224,
  },
  {
    name: "Auburn",
    state_id: "KY",
    lat: 36.8654,
    lng: -86.7107,
    id: 1840013270,
  },
  {
    name: "Schwenksville",
    state_id: "PA",
    lat: 40.2574,
    lng: -75.4662,
    id: 1840001377,
  },
  {
    name: "Duenweg",
    state_id: "MO",
    lat: 37.0842,
    lng: -94.4119,
    id: 1840008768,
  },
  {
    name: "Wildwood",
    state_id: "TX",
    lat: 30.5256,
    lng: -94.4457,
    id: 1840026014,
  },
  {
    name: "Stanleytown",
    state_id: "VA",
    lat: 36.7512,
    lng: -79.9521,
    id: 1840006558,
  },
  {
    name: "Salome",
    state_id: "AZ",
    lat: 33.7778,
    lng: -113.6061,
    id: 1840019269,
  },
  {
    name: "Fayetteville",
    state_id: "AL",
    lat: 33.1643,
    lng: -86.4411,
    id: 1840022953,
  },
  {
    name: "Watts Mills",
    state_id: "SC",
    lat: 34.5164,
    lng: -81.9857,
    id: 1840073854,
  },
  {
    name: "West Yellowstone",
    state_id: "MT",
    lat: 44.6627,
    lng: -111.1057,
    id: 1840022329,
  },
  {
    name: "Shoal Creek",
    state_id: "AL",
    lat: 33.4328,
    lng: -86.6071,
    id: 1840075164,
  },
  {
    name: "Gleason",
    state_id: "TN",
    lat: 36.2174,
    lng: -88.6107,
    id: 1840016071,
  },
  {
    name: "New Market",
    state_id: "TN",
    lat: 36.1096,
    lng: -83.5493,
    id: 1840016170,
  },
  {
    name: "Springfield",
    state_id: "CO",
    lat: 37.4049,
    lng: -102.6189,
    id: 1840022551,
  },
  {
    name: "Olla",
    state_id: "LA",
    lat: 31.8981,
    lng: -92.2404,
    id: 1840017060,
  },
  {
    name: "Maury",
    state_id: "NC",
    lat: 35.4792,
    lng: -77.5902,
    id: 1840025813,
  },
  {
    name: "Youngsville",
    state_id: "NC",
    lat: 36.0245,
    lng: -78.481,
    id: 1840016119,
  },
  {
    name: "Tubac",
    state_id: "AZ",
    lat: 31.6111,
    lng: -111.0585,
    id: 1840019537,
  },
  {
    name: "Buchanan Dam",
    state_id: "TX",
    lat: 30.7807,
    lng: -98.4393,
    id: 1840018216,
  },
  {
    name: "Godley",
    state_id: "TX",
    lat: 32.4513,
    lng: -97.5322,
    id: 1840020751,
  },
  {
    name: "Cypress Quarters",
    state_id: "FL",
    lat: 27.2479,
    lng: -80.8115,
    id: 1840013126,
  },
  {
    name: "Glyndon",
    state_id: "MN",
    lat: 46.8709,
    lng: -96.5784,
    id: 1840007730,
  },
  {
    name: "Cactus Flats",
    state_id: "AZ",
    lat: 32.7642,
    lng: -109.7205,
    id: 1840001823,
  },
  {
    name: "Kincaid",
    state_id: "IL",
    lat: 39.5868,
    lng: -89.4167,
    id: 1840012544,
  },
  {
    name: "Exmore",
    state_id: "VA",
    lat: 37.5295,
    lng: -75.8283,
    id: 1840006415,
  },
  {
    name: "Vernon Center",
    state_id: "NJ",
    lat: 41.1874,
    lng: -74.5015,
    id: 1840039977,
  },
  {
    name: "Oblong",
    state_id: "IL",
    lat: 39.0022,
    lng: -87.9107,
    id: 1840012755,
  },
  {
    name: "Glen Dale",
    state_id: "WV",
    lat: 39.9466,
    lng: -80.7564,
    id: 1840038307,
  },
  {
    name: "St. Leo",
    state_id: "FL",
    lat: 28.3363,
    lng: -82.2589,
    id: 1840018288,
  },
  {
    name: "Northville",
    state_id: "NY",
    lat: 40.9723,
    lng: -72.618,
    id: 1840147481,
  },
  {
    name: "Ringgold",
    state_id: "LA",
    lat: 32.3261,
    lng: -93.2837,
    id: 1840018094,
  },
  {
    name: "Holstein",
    state_id: "IA",
    lat: 42.4869,
    lng: -95.5429,
    id: 1840006956,
  },
  {
    name: "Mound Bayou",
    state_id: "MS",
    lat: 33.8808,
    lng: -90.728,
    id: 1840014749,
  },
  {
    name: "Fort Ashby",
    state_id: "WV",
    lat: 39.4973,
    lng: -78.7646,
    id: 1840005720,
  },
  {
    name: "Apple Mountain Lake",
    state_id: "VA",
    lat: 38.9231,
    lng: -78.1033,
    id: 1840026696,
  },
  {
    name: "Carbondale",
    state_id: "KS",
    lat: 38.8192,
    lng: -95.6932,
    id: 1840007459,
  },
  {
    name: "Heidelberg",
    state_id: "TX",
    lat: 26.1831,
    lng: -97.885,
    id: 1840036243,
  },
  {
    name: "Sleepy Hollow",
    state_id: "WY",
    lat: 44.2327,
    lng: -105.4309,
    id: 1840038442,
  },
  {
    name: "Oakland",
    state_id: "MO",
    lat: 38.5768,
    lng: -90.3849,
    id: 1840008594,
  },
  {
    name: "Bell Acres",
    state_id: "PA",
    lat: 40.5898,
    lng: -80.1738,
    id: 1840001202,
  },
  {
    name: "Vian",
    state_id: "OK",
    lat: 35.5018,
    lng: -94.9698,
    id: 1840022740,
  },
  {
    name: "Eaton Estates",
    state_id: "OH",
    lat: 41.3061,
    lng: -82.0114,
    id: 1840004896,
  },
  {
    name: "Marmet",
    state_id: "WV",
    lat: 38.2458,
    lng: -81.5711,
    id: 1840006198,
  },
  {
    name: "Mancelona",
    state_id: "MI",
    lat: 44.9024,
    lng: -85.0613,
    id: 1840010925,
  },
  {
    name: "Centennial Park",
    state_id: "AZ",
    lat: 36.9538,
    lng: -112.9813,
    id: 1840075272,
  },
  {
    name: "Parcelas La Milagrosa",
    state_id: "PR",
    lat: 18.1716,
    lng: -66.1868,
    id: 1630035487,
  },
  {
    name: "Glide",
    state_id: "OR",
    lat: 43.3029,
    lng: -123.0666,
    id: 1840017438,
  },
  {
    name: "Pierz",
    state_id: "MN",
    lat: 45.9772,
    lng: -94.1008,
    id: 1840008864,
  },
  {
    name: "Maple Park",
    state_id: "IL",
    lat: 41.9086,
    lng: -88.6063,
    id: 1840011349,
  },
  {
    name: "Reese",
    state_id: "MI",
    lat: 43.4528,
    lng: -83.6893,
    id: 1840010972,
  },
  {
    name: "Lluveras",
    state_id: "PR",
    lat: 18.0381,
    lng: -66.9037,
    id: 1630023549,
  },
  {
    name: "Holiday Valley",
    state_id: "OH",
    lat: 39.8536,
    lng: -83.962,
    id: 1840034145,
  },
  {
    name: "Tsaile",
    state_id: "AZ",
    lat: 36.3033,
    lng: -109.2157,
    id: 1840136753,
  },
  {
    name: "Crystal Falls",
    state_id: "MI",
    lat: 46.0967,
    lng: -88.3264,
    id: 1840001970,
  },
  {
    name: "Walnut Grove",
    state_id: "CA",
    lat: 38.2478,
    lng: -121.528,
    id: 1840018847,
  },
  {
    name: "Grayson",
    state_id: "CA",
    lat: 37.565,
    lng: -121.1794,
    id: 1840017616,
  },
  {
    name: "Wakefield",
    state_id: "NE",
    lat: 42.2665,
    lng: -96.8632,
    id: 1840010092,
  },
  {
    name: "Valencia",
    state_id: "PA",
    lat: 40.6767,
    lng: -79.9881,
    id: 1840000875,
  },
  {
    name: "Panama",
    state_id: "OK",
    lat: 35.1716,
    lng: -94.6707,
    id: 1840022771,
  },
  {
    name: "Capron",
    state_id: "IL",
    lat: 42.3986,
    lng: -88.7393,
    id: 1840010112,
  },
  {
    name: "Leupp",
    state_id: "AZ",
    lat: 35.2966,
    lng: -111.0027,
    id: 1840017665,
  },
  {
    name: "Clarendon",
    state_id: "AR",
    lat: 34.6935,
    lng: -91.3062,
    id: 1840014634,
  },
  {
    name: "Callahan",
    state_id: "FL",
    lat: 30.5619,
    lng: -81.832,
    id: 1840015907,
  },
  {
    name: "Velda City",
    state_id: "MO",
    lat: 38.6941,
    lng: -90.2934,
    id: 1840032251,
  },
  {
    name: "La Croft",
    state_id: "OH",
    lat: 40.6469,
    lng: -80.5998,
    id: 1840005223,
  },
  {
    name: "Okawville",
    state_id: "IL",
    lat: 38.4344,
    lng: -89.548,
    id: 1840012906,
  },
  {
    name: "Skidmore",
    state_id: "TX",
    lat: 28.2626,
    lng: -97.6855,
    id: 1840019691,
  },
  {
    name: "Lake Marcel-Stillwater",
    state_id: "WA",
    lat: 47.6926,
    lng: -121.9152,
    id: 1840074241,
  },
  {
    name: "Tripoli",
    state_id: "IA",
    lat: 42.8083,
    lng: -92.2578,
    id: 1840010088,
  },
  {
    name: "Montgomery",
    state_id: "TX",
    lat: 30.3921,
    lng: -95.6958,
    id: 1840020885,
  },
  {
    name: "Powells Crossroads",
    state_id: "TN",
    lat: 35.1848,
    lng: -85.4848,
    id: 1840017872,
  },
  {
    name: "Geneva",
    state_id: "IN",
    lat: 40.5975,
    lng: -84.9568,
    id: 1840010354,
  },
  {
    name: "Fairview",
    state_id: "UT",
    lat: 39.6299,
    lng: -111.4368,
    id: 1840020203,
  },
  {
    name: "Ravenna",
    state_id: "NE",
    lat: 41.0276,
    lng: -98.8947,
    id: 1840000958,
  },
  {
    name: "Indios",
    state_id: "PR",
    lat: 17.9942,
    lng: -66.821,
    id: 1630023621,
  },
  {
    name: "Redway",
    state_id: "CA",
    lat: 40.1209,
    lng: -123.822,
    id: 1840018717,
  },
  {
    name: "Lampeter",
    state_id: "PA",
    lat: 39.9909,
    lng: -76.242,
    id: 1840024339,
  },
  {
    name: "Coats Bend",
    state_id: "AL",
    lat: 34.0774,
    lng: -85.8742,
    id: 1840075147,
  },
  {
    name: "Nashotah",
    state_id: "WI",
    lat: 43.0938,
    lng: -88.4037,
    id: 1840003024,
  },
  {
    name: "Crane",
    state_id: "MO",
    lat: 36.9027,
    lng: -93.5716,
    id: 1840007651,
  },
  {
    name: "Grand Marais",
    state_id: "MN",
    lat: 47.7586,
    lng: -90.3443,
    id: 1840008810,
  },
  {
    name: "Walnut Grove",
    state_id: "GA",
    lat: 33.7452,
    lng: -83.8512,
    id: 1840015659,
  },
  {
    name: "Minneota",
    state_id: "MN",
    lat: 44.5626,
    lng: -95.9827,
    id: 1840007880,
  },
  {
    name: "Cottage City",
    state_id: "MD",
    lat: 38.9385,
    lng: -76.9492,
    id: 1840005986,
  },
  {
    name: "Piney Mountain",
    state_id: "VA",
    lat: 38.1591,
    lng: -78.4156,
    id: 1840041851,
  },
  {
    name: "Lavalette",
    state_id: "WV",
    lat: 38.3224,
    lng: -82.4479,
    id: 1840025674,
  },
  {
    name: "Rutledge",
    state_id: "TN",
    lat: 36.2757,
    lng: -83.5311,
    id: 1840017722,
  },
  {
    name: "Dunlap",
    state_id: "OH",
    lat: 39.2908,
    lng: -84.6303,
    id: 1840024515,
  },
  {
    name: "Sheridan",
    state_id: "CA",
    lat: 38.969,
    lng: -121.3559,
    id: 1840024513,
  },
  {
    name: "Lower Lake",
    state_id: "CA",
    lat: 38.9119,
    lng: -122.6085,
    id: 1840017547,
  },
  {
    name: "Farmingdale",
    state_id: "NJ",
    lat: 40.1983,
    lng: -74.1701,
    id: 1840003686,
  },
  {
    name: "Lake Riverside",
    state_id: "CA",
    lat: 33.5173,
    lng: -116.8107,
    id: 1840075921,
  },
  {
    name: "Charlack",
    state_id: "MO",
    lat: 38.7029,
    lng: -90.3427,
    id: 1840007445,
  },
  {
    name: "Barker Heights",
    state_id: "NC",
    lat: 35.3104,
    lng: -82.4412,
    id: 1840013446,
  },
  {
    name: "Pioche",
    state_id: "NV",
    lat: 37.952,
    lng: -114.4435,
    id: 1840024652,
  },
  {
    name: "Nora Springs",
    state_id: "IA",
    lat: 43.1445,
    lng: -93.0094,
    id: 1840008004,
  },
  {
    name: "Willacoochee",
    state_id: "GA",
    lat: 31.3358,
    lng: -83.0446,
    id: 1840015875,
  },
  {
    name: "Uintah",
    state_id: "UT",
    lat: 41.1427,
    lng: -111.9334,
    id: 1840022389,
  },
  {
    name: "Woodville",
    state_id: "WI",
    lat: 44.9483,
    lng: -92.2851,
    id: 1840002132,
  },
  {
    name: "Emory",
    state_id: "TX",
    lat: 32.8765,
    lng: -95.7677,
    id: 1840020721,
  },
  {
    name: "Kinsley",
    state_id: "KS",
    lat: 37.9224,
    lng: -99.4114,
    id: 1840001677,
  },
  {
    name: "Mansura",
    state_id: "LA",
    lat: 31.0673,
    lng: -92.0528,
    id: 1840017119,
  },
  {
    name: "Ferndale",
    state_id: "CA",
    lat: 40.5799,
    lng: -124.2619,
    id: 1840020125,
  },
  {
    name: "Woodbine",
    state_id: "GA",
    lat: 30.9595,
    lng: -81.7175,
    id: 1840015889,
  },
  {
    name: "Frenchtown",
    state_id: "NJ",
    lat: 40.5273,
    lng: -75.0571,
    id: 1840003606,
  },
  {
    name: "Rosenhayn",
    state_id: "NJ",
    lat: 39.4787,
    lng: -75.138,
    id: 1840005751,
  },
  {
    name: "Monroeville",
    state_id: "OH",
    lat: 41.244,
    lng: -82.7018,
    id: 1840011708,
  },
  {
    name: "Enigma",
    state_id: "GA",
    lat: 31.4076,
    lng: -83.3291,
    id: 1840017103,
  },
  {
    name: "Beechwood Village",
    state_id: "KY",
    lat: 38.2555,
    lng: -85.6295,
    id: 1840013207,
  },
  {
    name: "Sneedville",
    state_id: "TN",
    lat: 36.5366,
    lng: -83.2099,
    id: 1840017698,
  },
  {
    name: "Beaver Dam",
    state_id: "AZ",
    lat: 36.9071,
    lng: -113.9363,
    id: 1840023172,
  },
  {
    name: "Hughestown",
    state_id: "PA",
    lat: 41.3291,
    lng: -75.7698,
    id: 1840000754,
  },
  {
    name: "Rosebud",
    state_id: "TX",
    lat: 31.0756,
    lng: -96.9749,
    id: 1840020843,
  },
  {
    name: "Gueydan",
    state_id: "LA",
    lat: 30.0291,
    lng: -92.5071,
    id: 1840017212,
  },
  {
    name: "Spring Valley",
    state_id: "WI",
    lat: 44.85,
    lng: -92.2443,
    id: 1840002282,
  },
  {
    name: "Elmore",
    state_id: "AL",
    lat: 32.5552,
    lng: -86.3425,
    id: 1840016966,
  },
  {
    name: "Munford",
    state_id: "AL",
    lat: 33.5259,
    lng: -85.9554,
    id: 1840022952,
  },
  {
    name: "Plano",
    state_id: "KY",
    lat: 36.8772,
    lng: -86.4165,
    id: 1840026880,
  },
  {
    name: "Tangent",
    state_id: "OR",
    lat: 44.5477,
    lng: -123.1109,
    id: 1840021254,
  },
  {
    name: "Owensville",
    state_id: "IN",
    lat: 38.2719,
    lng: -87.6918,
    id: 1840009803,
  },
  {
    name: "Newville",
    state_id: "PA",
    lat: 40.1702,
    lng: -77.4015,
    id: 1840001392,
  },
  {
    name: "Cerro Gordo",
    state_id: "IL",
    lat: 39.8894,
    lng: -88.7347,
    id: 1840010484,
  },
  {
    name: "Winthrop",
    state_id: "MN",
    lat: 44.5427,
    lng: -94.361,
    id: 1840010013,
  },
  {
    name: "Inglis",
    state_id: "FL",
    lat: 29.0323,
    lng: -82.6603,
    id: 1840017222,
  },
  {
    name: "Eagle Butte",
    state_id: "SD",
    lat: 44.9907,
    lng: -101.2275,
    id: 1840000275,
  },
  {
    name: "Clarksville",
    state_id: "IA",
    lat: 42.7799,
    lng: -92.6679,
    id: 1840006912,
  },
  {
    name: "Blair",
    state_id: "WI",
    lat: 44.3082,
    lng: -91.2424,
    id: 1840002391,
  },
  {
    name: "Carbonville",
    state_id: "UT",
    lat: 39.6272,
    lng: -110.8337,
    id: 1840024369,
  },
  {
    name: "Prince's Lakes",
    state_id: "IN",
    lat: 39.3514,
    lng: -86.1094,
    id: 1840010615,
  },
  {
    name: "Trafalgar",
    state_id: "IN",
    lat: 39.4124,
    lng: -86.1499,
    id: 1840010617,
  },
  {
    name: "Hazen",
    state_id: "AR",
    lat: 34.7966,
    lng: -91.5725,
    id: 1840014625,
  },
  {
    name: "Parker City",
    state_id: "IN",
    lat: 40.1898,
    lng: -85.2037,
    id: 1840009451,
  },
  {
    name: "Byers",
    state_id: "CO",
    lat: 39.7101,
    lng: -104.219,
    id: 1840017543,
  },
  {
    name: "Robersonville",
    state_id: "NC",
    lat: 35.8248,
    lng: -77.2529,
    id: 1840017760,
  },
  {
    name: "Hayden",
    state_id: "AL",
    lat: 33.8822,
    lng: -86.7839,
    id: 1840015601,
  },
  {
    name: "Lambert",
    state_id: "MS",
    lat: 34.2006,
    lng: -90.2845,
    id: 1840016644,
  },
  {
    name: "Johnson City",
    state_id: "KS",
    lat: 37.5703,
    lng: -101.7447,
    id: 1840073794,
  },
  {
    name: "Shell Ridge",
    state_id: "CA",
    lat: 37.906,
    lng: -122.0345,
    id: 1840076228,
  },
  {
    name: "Forreston",
    state_id: "IL",
    lat: 42.1273,
    lng: -89.5784,
    id: 1840011236,
  },
  {
    name: "Monroeville",
    state_id: "IN",
    lat: 40.9732,
    lng: -84.8673,
    id: 1840009323,
  },
  {
    name: "Hartford",
    state_id: "IL",
    lat: 38.8182,
    lng: -90.0915,
    id: 1840012792,
  },
  {
    name: "Claypool Hill",
    state_id: "VA",
    lat: 37.0633,
    lng: -81.7506,
    id: 1840006449,
  },
  {
    name: "Penn Valley",
    state_id: "CA",
    lat: 39.1954,
    lng: -121.1942,
    id: 1840028395,
  },
  {
    name: "Chapman",
    state_id: "KS",
    lat: 38.9746,
    lng: -97.0236,
    id: 1840007410,
  },
  {
    name: "Morristown",
    state_id: "IN",
    lat: 39.6738,
    lng: -85.7051,
    id: 1840009562,
  },
  {
    name: "Wood River",
    state_id: "NE",
    lat: 40.8208,
    lng: -98.6003,
    id: 1840010329,
  },
  {
    name: "Winnebago",
    state_id: "MN",
    lat: 43.7644,
    lng: -94.1703,
    id: 1840010044,
  },
  {
    name: "Sublette",
    state_id: "KS",
    lat: 37.4822,
    lng: -100.8465,
    id: 1840009881,
  },
  {
    name: "Tioga",
    state_id: "ND",
    lat: 48.3947,
    lng: -102.9431,
    id: 1840001872,
  },
  {
    name: "Robins AFB",
    state_id: "GA",
    lat: 32.6093,
    lng: -83.5846,
    id: 1840036023,
  },
  {
    name: "New Rockford",
    state_id: "ND",
    lat: 47.6798,
    lng: -99.1377,
    id: 1840000115,
  },
  {
    name: "Sea Bright",
    state_id: "NJ",
    lat: 40.3653,
    lng: -73.9769,
    id: 1840001361,
  },
  {
    name: "Sperry",
    state_id: "OK",
    lat: 36.2978,
    lng: -95.9917,
    id: 1840022678,
  },
  {
    name: "Cresson",
    state_id: "TX",
    lat: 32.5299,
    lng: -97.6155,
    id: 1840019462,
  },
  {
    name: "Park River",
    state_id: "ND",
    lat: 48.3911,
    lng: -97.7428,
    id: 1840000085,
  },
  {
    name: "Stottville",
    state_id: "NY",
    lat: 42.2913,
    lng: -73.7532,
    id: 1840004629,
  },
  {
    name: "Claysburg",
    state_id: "PA",
    lat: 40.2896,
    lng: -78.4482,
    id: 1840005376,
  },
  {
    name: "Weissport East",
    state_id: "PA",
    lat: 40.8369,
    lng: -75.6863,
    id: 1840073731,
  },
  {
    name: "Hayfield",
    state_id: "MN",
    lat: 43.8903,
    lng: -92.8471,
    id: 1840006807,
  },
  {
    name: "Marshall",
    state_id: "AR",
    lat: 35.9083,
    lng: -92.6459,
    id: 1840015352,
  },
  {
    name: "Phillips",
    state_id: "WI",
    lat: 45.6958,
    lng: -90.4018,
    id: 1840002011,
  },
  {
    name: "Manson",
    state_id: "WA",
    lat: 47.8858,
    lng: -120.1563,
    id: 1840025246,
  },
  {
    name: "Aragon",
    state_id: "GA",
    lat: 34.0459,
    lng: -85.0571,
    id: 1840013684,
  },
  {
    name: "Advance",
    state_id: "MO",
    lat: 37.1038,
    lng: -89.9119,
    id: 1840007639,
  },
  {
    name: "Wymore",
    state_id: "NE",
    lat: 40.1221,
    lng: -96.6642,
    id: 1840010434,
  },
  {
    name: "Wade",
    state_id: "MS",
    lat: 30.6401,
    lng: -88.5579,
    id: 1840013935,
  },
  {
    name: "North Utica",
    state_id: "IL",
    lat: 41.3536,
    lng: -89.0109,
    id: 1840073818,
  },
  {
    name: "Shokan",
    state_id: "NY",
    lat: 41.9809,
    lng: -74.213,
    id: 1840004723,
  },
  {
    name: "Lincolndale",
    state_id: "NY",
    lat: 41.336,
    lng: -73.7254,
    id: 1840004924,
  },
  {
    name: "Dauphin Island",
    state_id: "AL",
    lat: 30.2525,
    lng: -88.1438,
    id: 1840017141,
  },
  {
    name: "Ruckersville",
    state_id: "VA",
    lat: 38.2314,
    lng: -78.3756,
    id: 1840026750,
  },
  {
    name: "Milan",
    state_id: "OH",
    lat: 41.2883,
    lng: -82.5995,
    id: 1840011545,
  },
  {
    name: "Inman",
    state_id: "KS",
    lat: 38.2323,
    lng: -97.7714,
    id: 1840008637,
  },
  {
    name: "The Galena Territory",
    state_id: "IL",
    lat: 42.3956,
    lng: -90.3159,
    id: 1840039281,
  },
  {
    name: "Miller",
    state_id: "SD",
    lat: 44.5205,
    lng: -98.9869,
    id: 1840002281,
  },
  {
    name: "Mogul",
    state_id: "NV",
    lat: 39.5164,
    lng: -119.9232,
    id: 1840024130,
  },
  {
    name: "New Berlin",
    state_id: "IL",
    lat: 39.726,
    lng: -89.9144,
    id: 1840012451,
  },
  {
    name: "Fincastle",
    state_id: "TN",
    lat: 36.4038,
    lng: -84.048,
    id: 1840025757,
  },
  {
    name: "Tye",
    state_id: "TX",
    lat: 32.4522,
    lng: -99.8665,
    id: 1840022093,
  },
  {
    name: "Thermal",
    state_id: "CA",
    lat: 33.6262,
    lng: -116.1308,
    id: 1840025035,
  },
  {
    name: "Lanark",
    state_id: "IL",
    lat: 42.1019,
    lng: -89.8328,
    id: 1840008128,
  },
  {
    name: "Robinhood",
    state_id: "MS",
    lat: 32.2045,
    lng: -89.9671,
    id: 1840042511,
  },
  {
    name: "Beverly",
    state_id: "OH",
    lat: 39.55,
    lng: -81.6362,
    id: 1840012594,
  },
  {
    name: "Tennessee Ridge",
    state_id: "TN",
    lat: 36.3194,
    lng: -87.7632,
    id: 1840016102,
  },
  {
    name: "Earlington",
    state_id: "KY",
    lat: 37.2754,
    lng: -87.5066,
    id: 1840013237,
  },
  {
    name: "Litchfield",
    state_id: "MI",
    lat: 42.041,
    lng: -84.7567,
    id: 1840003253,
  },
  {
    name: "Okarche",
    state_id: "OK",
    lat: 35.7189,
    lng: -97.9757,
    id: 1840022687,
  },
  {
    name: "Funny River",
    state_id: "AK",
    lat: 60.4873,
    lng: -150.7808,
    id: 1840023653,
  },
  {
    name: "Bridgeport",
    state_id: "NY",
    lat: 43.1545,
    lng: -75.9734,
    id: 1840033857,
  },
  {
    name: "Nokesville",
    state_id: "VA",
    lat: 38.694,
    lng: -77.5757,
    id: 1840006096,
  },
  {
    name: "Oriskany",
    state_id: "NY",
    lat: 43.1569,
    lng: -75.3336,
    id: 1840004209,
  },
  {
    name: "Redstone Arsenal",
    state_id: "AL",
    lat: 34.6843,
    lng: -86.647,
    id: 1840035873,
  },
  {
    name: "Brookside",
    state_id: "AL",
    lat: 33.638,
    lng: -86.9014,
    id: 1840015669,
  },
  {
    name: "Laverne",
    state_id: "OK",
    lat: 36.7054,
    lng: -99.8971,
    id: 1840021621,
  },
  {
    name: "Stone Ridge",
    state_id: "NY",
    lat: 41.8414,
    lng: -74.1539,
    id: 1840004724,
  },
  {
    name: "Saranac",
    state_id: "MI",
    lat: 42.9294,
    lng: -85.2105,
    id: 1840011018,
  },
  {
    name: "Crooks",
    state_id: "SD",
    lat: 43.6596,
    lng: -96.8092,
    id: 1840000340,
  },
  {
    name: "Forest Meadows",
    state_id: "CA",
    lat: 38.1664,
    lng: -120.4052,
    id: 1840017592,
  },
  {
    name: "Forest Lake",
    state_id: "IL",
    lat: 42.2103,
    lng: -88.0532,
    id: 1840004636,
  },
  {
    name: "Pine Knoll Shores",
    state_id: "NC",
    lat: 34.6974,
    lng: -76.8205,
    id: 1840016487,
  },
  {
    name: "Arlington",
    state_id: "GA",
    lat: 31.4383,
    lng: -84.7253,
    id: 1840013866,
  },
  {
    name: "Flemington",
    state_id: "PA",
    lat: 41.1273,
    lng: -77.4703,
    id: 1840003449,
  },
  {
    name: "Orangeville",
    state_id: "UT",
    lat: 39.2308,
    lng: -111.0591,
    id: 1840020218,
  },
  {
    name: "South Uniontown",
    state_id: "PA",
    lat: 39.8939,
    lng: -79.7465,
    id: 1840026566,
  },
  {
    name: "Morrow",
    state_id: "OH",
    lat: 39.35,
    lng: -84.1231,
    id: 1840012614,
  },
  {
    name: "Iva",
    state_id: "SC",
    lat: 34.307,
    lng: -82.6636,
    id: 1840016573,
  },
  {
    name: "West Alexandria",
    state_id: "OH",
    lat: 39.7451,
    lng: -84.5362,
    id: 1840012498,
  },
  {
    name: "Mullens",
    state_id: "WV",
    lat: 37.5812,
    lng: -81.3855,
    id: 1840006381,
  },
  {
    name: "Appleton",
    state_id: "MN",
    lat: 45.1997,
    lng: -96.0225,
    id: 1840006726,
  },
  {
    name: "Pocono Springs",
    state_id: "PA",
    lat: 41.2797,
    lng: -75.4016,
    id: 1840035392,
  },
  {
    name: "Blowing Rock",
    state_id: "NC",
    lat: 36.1291,
    lng: -81.6713,
    id: 1840015309,
  },
  {
    name: "Bridgehampton",
    state_id: "NY",
    lat: 40.9427,
    lng: -72.3101,
    id: 1840005022,
  },
  {
    name: "Lone Jack",
    state_id: "MO",
    lat: 38.8725,
    lng: -94.174,
    id: 1840009679,
  },
  {
    name: "Lake Holiday",
    state_id: "IN",
    lat: 39.9642,
    lng: -86.9607,
    id: 1840026545,
  },
  {
    name: "Leesburg",
    state_id: "OH",
    lat: 39.3418,
    lng: -83.552,
    id: 1840012693,
  },
  {
    name: "Flippin",
    state_id: "AR",
    lat: 36.2774,
    lng: -92.5935,
    id: 1840013328,
  },
  {
    name: "Killian",
    state_id: "LA",
    lat: 30.353,
    lng: -90.5795,
    id: 1840018229,
  },
  {
    name: "Minerva Park",
    state_id: "OH",
    lat: 40.077,
    lng: -82.9407,
    id: 1840012406,
  },
  {
    name: "Hawkins",
    state_id: "TX",
    lat: 32.5917,
    lng: -95.2027,
    id: 1840020684,
  },
  {
    name: "China Grove",
    state_id: "TX",
    lat: 29.393,
    lng: -98.3443,
    id: 1840023156,
  },
  {
    name: "Yates Center",
    state_id: "KS",
    lat: 37.868,
    lng: -95.7535,
    id: 1840001679,
  },
  {
    name: "Clifton",
    state_id: "IL",
    lat: 40.9348,
    lng: -87.9343,
    id: 1840010339,
  },
  {
    name: "Troy",
    state_id: "TN",
    lat: 36.3419,
    lng: -89.1583,
    id: 1840016068,
  },
  {
    name: "Sutherland",
    state_id: "NE",
    lat: 41.1602,
    lng: -101.1228,
    id: 1840011651,
  },
  {
    name: "Pecos",
    state_id: "NM",
    lat: 35.5754,
    lng: -105.6787,
    id: 1840022717,
  },
  {
    name: "St. Jacob",
    state_id: "IL",
    lat: 38.7197,
    lng: -89.768,
    id: 1840012799,
  },
  {
    name: "Marsing",
    state_id: "ID",
    lat: 43.5466,
    lng: -116.8094,
    id: 1840020050,
  },
  {
    name: "Honaker",
    state_id: "VA",
    lat: 37.0156,
    lng: -81.9685,
    id: 1840006490,
  },
  {
    name: "Ruhenstroth",
    state_id: "NV",
    lat: 38.8913,
    lng: -119.6833,
    id: 1840039849,
  },
  {
    name: "Rich Hill",
    state_id: "MO",
    lat: 38.0958,
    lng: -94.3633,
    id: 1840009811,
  },
  {
    name: "Boston",
    state_id: "GA",
    lat: 30.792,
    lng: -83.7899,
    id: 1840013902,
  },
  {
    name: "Harper",
    state_id: "KS",
    lat: 37.285,
    lng: -98.0277,
    id: 1840001710,
  },
  {
    name: "Schuylerville",
    state_id: "NY",
    lat: 43.1015,
    lng: -73.5808,
    id: 1840004250,
  },
  {
    name: "Frazeysburg",
    state_id: "OH",
    lat: 40.1191,
    lng: -82.1177,
    id: 1840012391,
  },
  {
    name: "Arlington",
    state_id: "NE",
    lat: 41.4548,
    lng: -96.3562,
    id: 1840011526,
  },
  {
    name: "Goodwater",
    state_id: "AL",
    lat: 33.0608,
    lng: -86.0516,
    id: 1840007377,
  },
  {
    name: "West Jefferson",
    state_id: "NC",
    lat: 36.3943,
    lng: -81.4896,
    id: 1840016029,
  },
  {
    name: "Conashaugh Lakes",
    state_id: "PA",
    lat: 41.304,
    lng: -74.9936,
    id: 1840035391,
  },
  {
    name: "Beaver",
    state_id: "WV",
    lat: 37.7356,
    lng: -81.15,
    id: 1840006344,
  },
  {
    name: "Boston Heights",
    state_id: "OH",
    lat: 41.2539,
    lng: -81.509,
    id: 1840000793,
  },
  {
    name: "Deville",
    state_id: "LA",
    lat: 31.3398,
    lng: -92.1389,
    id: 1840013103,
  },
  {
    name: "Duluth",
    state_id: "WA",
    lat: 45.7838,
    lng: -122.647,
    id: 1840140854,
  },
  {
    name: "Bull Run Mountain Estates",
    state_id: "VA",
    lat: 38.9107,
    lng: -77.6638,
    id: 1840026706,
  },
  {
    name: "Dysart",
    state_id: "IA",
    lat: 42.1721,
    lng: -92.3091,
    id: 1840008101,
  },
  {
    name: "Gerald",
    state_id: "MO",
    lat: 38.3992,
    lng: -91.3305,
    id: 1840008621,
  },
  {
    name: "Babson Park",
    state_id: "FL",
    lat: 27.8342,
    lng: -81.5281,
    id: 1840014124,
  },
  {
    name: "Naples",
    state_id: "TX",
    lat: 33.203,
    lng: -94.6776,
    id: 1840020676,
  },
  {
    name: "Sharon",
    state_id: "MS",
    lat: 31.7922,
    lng: -89.0983,
    id: 1840025945,
  },
  {
    name: "Greenfield",
    state_id: "MO",
    lat: 37.4162,
    lng: -93.8432,
    id: 1840008740,
  },
  {
    name: "Arnolds Park",
    state_id: "IA",
    lat: 43.3601,
    lng: -95.1265,
    id: 1840006849,
  },
  {
    name: "Loganville",
    state_id: "PA",
    lat: 39.8558,
    lng: -76.7078,
    id: 1840001454,
  },
  {
    name: "Berry Creek",
    state_id: "CA",
    lat: 39.6314,
    lng: -121.4052,
    id: 1840025563,
  },
  {
    name: "Port Gibson",
    state_id: "MS",
    lat: 31.9558,
    lng: -90.9834,
    id: 1840014921,
  },
  {
    name: "Glenmora",
    state_id: "LA",
    lat: 30.9733,
    lng: -92.5824,
    id: 1840017101,
  },
  {
    name: "Lakeview",
    state_id: "LA",
    lat: 32.5238,
    lng: -93.8299,
    id: 1840025883,
  },
  {
    name: "Fairview Park",
    state_id: "IN",
    lat: 39.6821,
    lng: -87.4143,
    id: 1840010520,
  },
  {
    name: "Robie Creek",
    state_id: "ID",
    lat: 43.668,
    lng: -116.0209,
    id: 1840042429,
  },
  {
    name: "Avila Beach",
    state_id: "CA",
    lat: 35.1969,
    lng: -120.7191,
    id: 1840028073,
  },
  {
    name: "Maple Bluff",
    state_id: "WI",
    lat: 43.1132,
    lng: -89.3719,
    id: 1840002916,
  },
  {
    name: "Yutan",
    state_id: "NE",
    lat: 41.2431,
    lng: -96.3959,
    id: 1840010272,
  },
  {
    name: "Spring Hope",
    state_id: "NC",
    lat: 35.9441,
    lng: -78.109,
    id: 1840017747,
  },
  {
    name: "Elephant Butte",
    state_id: "NM",
    lat: 33.1806,
    lng: -107.2269,
    id: 1840020627,
  },
  {
    name: "Webberville",
    state_id: "MI",
    lat: 42.6642,
    lng: -84.1797,
    id: 1840011082,
  },
  {
    name: "Brickerville",
    state_id: "PA",
    lat: 40.2226,
    lng: -76.2863,
    id: 1840034876,
  },
  {
    name: "Shell Lake",
    state_id: "WI",
    lat: 45.7322,
    lng: -91.9033,
    id: 1840002001,
  },
  {
    name: "Pierce City",
    state_id: "MO",
    lat: 36.9465,
    lng: -94.0032,
    id: 1840009920,
  },
  {
    name: "La Plata",
    state_id: "MO",
    lat: 40.0241,
    lng: -92.4914,
    id: 1840008400,
  },
  {
    name: "Granville",
    state_id: "IL",
    lat: 41.2637,
    lng: -89.23,
    id: 1840011684,
  },
  {
    name: "Elmer",
    state_id: "NJ",
    lat: 39.5919,
    lng: -75.174,
    id: 1840003791,
  },
  {
    name: "Taylorsville",
    state_id: "KY",
    lat: 38.0368,
    lng: -85.3336,
    id: 1840015213,
  },
  {
    name: "Tieton",
    state_id: "WA",
    lat: 46.7026,
    lng: -120.756,
    id: 1840022319,
  },
  {
    name: "Harold",
    state_id: "FL",
    lat: 30.6771,
    lng: -86.831,
    id: 1840025113,
  },
  {
    name: "Black Creek",
    state_id: "WI",
    lat: 44.4741,
    lng: -88.4507,
    id: 1840002406,
  },
  {
    name: "Bloomfield",
    state_id: "NY",
    lat: 42.8991,
    lng: -77.4241,
    id: 1840034027,
  },
  {
    name: "Ranchitos del Norte",
    state_id: "TX",
    lat: 26.4003,
    lng: -98.8715,
    id: 1840041467,
  },
  {
    name: "Cleveland",
    state_id: "AL",
    lat: 33.9965,
    lng: -86.5671,
    id: 1840016718,
  },
  {
    name: "Louisville",
    state_id: "NE",
    lat: 40.9965,
    lng: -96.1614,
    id: 1840009354,
  },
  {
    name: "Green Springs",
    state_id: "OH",
    lat: 41.257,
    lng: -83.0529,
    id: 1840000819,
  },
  {
    name: "Playita Cortada",
    state_id: "PR",
    lat: 17.982,
    lng: -66.4402,
    id: 1630023544,
  },
  {
    name: "Walnut",
    state_id: "IL",
    lat: 41.557,
    lng: -89.5911,
    id: 1840011584,
  },
  {
    name: "Siletz",
    state_id: "OR",
    lat: 44.7219,
    lng: -123.9187,
    id: 1840021233,
  },
  {
    name: "St. Vincent College",
    state_id: "PA",
    lat: 40.2914,
    lng: -79.4054,
    id: 1840043397,
  },
  {
    name: "Ansted",
    state_id: "WV",
    lat: 38.1357,
    lng: -81.1037,
    id: 1840006299,
  },
  {
    name: "Nellysford",
    state_id: "VA",
    lat: 37.913,
    lng: -78.8828,
    id: 1840024714,
  },
  {
    name: "Carnuel",
    state_id: "NM",
    lat: 35.0609,
    lng: -106.4587,
    id: 1840033707,
  },
  {
    name: "Clyde",
    state_id: "NC",
    lat: 35.533,
    lng: -82.9107,
    id: 1840016296,
  },
  {
    name: "Discovery Harbour",
    state_id: "HI",
    lat: 19.0415,
    lng: -155.6254,
    id: 1840029592,
  },
  {
    name: "Rutherford College",
    state_id: "NC",
    lat: 35.7515,
    lng: -81.527,
    id: 1840017777,
  },
  {
    name: "Warren",
    state_id: "IL",
    lat: 42.4948,
    lng: -89.9913,
    id: 1840011110,
  },
  {
    name: "Cedar Point",
    state_id: "NC",
    lat: 34.6862,
    lng: -77.0833,
    id: 1840016483,
  },
  {
    name: "Rodney Village",
    state_id: "DE",
    lat: 39.1284,
    lng: -75.5396,
    id: 1840005804,
  },
  {
    name: "Byron",
    state_id: "CA",
    lat: 37.8756,
    lng: -121.642,
    id: 1840017611,
  },
  {
    name: "Tombstone",
    state_id: "AZ",
    lat: 31.7234,
    lng: -110.0798,
    id: 1840022104,
  },
  {
    name: "Bradford",
    state_id: "RI",
    lat: 41.3944,
    lng: -71.7542,
    id: 1840003363,
  },
  {
    name: "Independence",
    state_id: "WI",
    lat: 44.3328,
    lng: -91.4172,
    id: 1840002388,
  },
  {
    name: "Ness City",
    state_id: "KS",
    lat: 38.454,
    lng: -99.9047,
    id: 1840008625,
  },
  {
    name: "Wallis",
    state_id: "TX",
    lat: 29.6317,
    lng: -96.0638,
    id: 1840022210,
  },
  {
    name: "Glenns Ferry",
    state_id: "ID",
    lat: 42.9503,
    lng: -115.3068,
    id: 1840020016,
  },
  {
    name: "Elroy",
    state_id: "WI",
    lat: 43.7419,
    lng: -90.2704,
    id: 1840002227,
  },
  {
    name: "Bellevue",
    state_id: "MI",
    lat: 42.4443,
    lng: -85.0187,
    id: 1840011083,
  },
  {
    name: "Menahga",
    state_id: "MN",
    lat: 46.7471,
    lng: -95.1012,
    id: 1840007734,
  },
  {
    name: "Orange Grove",
    state_id: "TX",
    lat: 27.9562,
    lng: -97.9386,
    id: 1840021008,
  },
  {
    name: "Jordan",
    state_id: "NY",
    lat: 43.0671,
    lng: -76.4728,
    id: 1840004321,
  },
  {
    name: "Thorndale",
    state_id: "TX",
    lat: 30.6141,
    lng: -97.2068,
    id: 1840022161,
  },
  {
    name: "Urania",
    state_id: "LA",
    lat: 31.8639,
    lng: -92.2913,
    id: 1840017059,
  },
  {
    name: "Springville",
    state_id: "VA",
    lat: 37.194,
    lng: -81.3999,
    id: 1840026867,
  },
  {
    name: "Wainaku",
    state_id: "HI",
    lat: 19.7449,
    lng: -155.0985,
    id: 1840029560,
  },
  {
    name: "Roan Mountain",
    state_id: "TN",
    lat: 36.1866,
    lng: -82.0705,
    id: 1840013313,
  },
  {
    name: "Peach Springs",
    state_id: "AZ",
    lat: 35.5315,
    lng: -113.4319,
    id: 1840019746,
  },
  {
    name: "Cedar Glen West",
    state_id: "NJ",
    lat: 40.0418,
    lng: -74.2852,
    id: 1840033465,
  },
  {
    name: "Stryker",
    state_id: "OH",
    lat: 41.5026,
    lng: -84.4183,
    id: 1840011524,
  },
  {
    name: "Elkhart",
    state_id: "TX",
    lat: 31.6276,
    lng: -95.5789,
    id: 1840022130,
  },
  {
    name: "Coosada",
    state_id: "AL",
    lat: 32.4983,
    lng: -86.3279,
    id: 1840016963,
  },
  {
    name: "Fairchilds",
    state_id: "TX",
    lat: 29.4398,
    lng: -95.7777,
    id: 1840023153,
  },
  {
    name: "Richlandtown",
    state_id: "PA",
    lat: 40.4726,
    lng: -75.3212,
    id: 1840001311,
  },
  {
    name: "Swede Heaven",
    state_id: "WA",
    lat: 48.2829,
    lng: -121.7136,
    id: 1840037821,
  },
  {
    name: "Meggett",
    state_id: "SC",
    lat: 32.6971,
    lng: -80.2731,
    id: 1840017285,
  },
  {
    name: "Waukomis",
    state_id: "OK",
    lat: 36.2833,
    lng: -97.9038,
    id: 1840022659,
  },
  {
    name: "Central City",
    state_id: "IA",
    lat: 42.2024,
    lng: -91.5248,
    id: 1840006986,
  },
  {
    name: "Gold Hill",
    state_id: "OR",
    lat: 42.4358,
    lng: -123.0526,
    id: 1840020074,
  },
  {
    name: "Plattsburgh West",
    state_id: "NY",
    lat: 44.6837,
    lng: -73.5037,
    id: 1840073655,
  },
  {
    name: "Gillett",
    state_id: "WI",
    lat: 44.8897,
    lng: -88.3063,
    id: 1840001574,
  },
  {
    name: "Alamosa East",
    state_id: "CO",
    lat: 37.4767,
    lng: -105.8397,
    id: 1840036558,
  },
  {
    name: "Maharishi Vedic City",
    state_id: "IA",
    lat: 41.0542,
    lng: -92.0142,
    id: 1840009333,
  },
  {
    name: "Golden Valley",
    state_id: "NV",
    lat: 39.6177,
    lng: -119.8231,
    id: 1840033784,
  },
  {
    name: "Herlong",
    state_id: "CA",
    lat: 40.1419,
    lng: -120.1399,
    id: 1840024201,
  },
  {
    name: "Wathena",
    state_id: "KS",
    lat: 39.7602,
    lng: -94.9396,
    id: 1840010556,
  },
  {
    name: "Eden",
    state_id: "TX",
    lat: 31.2162,
    lng: -99.844,
    id: 1840020836,
  },
  {
    name: "Milton",
    state_id: "NY",
    lat: 41.6587,
    lng: -73.9664,
    id: 1840145543,
  },
  {
    name: "Uhland",
    state_id: "TX",
    lat: 29.9624,
    lng: -97.7953,
    id: 1840022189,
  },
  {
    name: "Fairacres",
    state_id: "NM",
    lat: 32.3047,
    lng: -106.8366,
    id: 1840025082,
  },
  {
    name: "Sunriver",
    state_id: "OR",
    lat: 43.8816,
    lng: -121.4368,
    id: 1840034819,
  },
  {
    name: "Encantada-Ranchito-El Calaboz",
    state_id: "TX",
    lat: 26.0333,
    lng: -97.6331,
    id: 1840073286,
  },
  {
    name: "Henry Fork",
    state_id: "VA",
    lat: 36.9679,
    lng: -79.871,
    id: 1840026877,
  },
  {
    name: "New Sharon",
    state_id: "IA",
    lat: 41.4701,
    lng: -92.6509,
    id: 1840008224,
  },
  {
    name: "Deadwood",
    state_id: "SD",
    lat: 44.3871,
    lng: -103.7207,
    id: 1840000312,
  },
  {
    name: "Beulaville",
    state_id: "NC",
    lat: 34.9228,
    lng: -77.7725,
    id: 1840015485,
  },
  {
    name: "Winona",
    state_id: "MO",
    lat: 37.0042,
    lng: -91.327,
    id: 1840010871,
  },
  {
    name: "St. Francis",
    state_id: "KS",
    lat: 39.7714,
    lng: -101.8015,
    id: 1840009498,
  },
  {
    name: "Prosperity",
    state_id: "SC",
    lat: 34.2121,
    lng: -81.5327,
    id: 1840017939,
  },
  {
    name: "Lakewood Club",
    state_id: "MI",
    lat: 43.3761,
    lng: -86.2563,
    id: 1840010987,
  },
  {
    name: "Harristown",
    state_id: "IL",
    lat: 39.8428,
    lng: -89.0607,
    id: 1840012426,
  },
  {
    name: "Elm City",
    state_id: "NC",
    lat: 35.8087,
    lng: -77.8622,
    id: 1840016261,
  },
  {
    name: "Hurley",
    state_id: "MS",
    lat: 30.6638,
    lng: -88.4994,
    id: 1840013932,
  },
  {
    name: "Van Meter",
    state_id: "IA",
    lat: 41.5207,
    lng: -93.9454,
    id: 1840010180,
  },
  {
    name: "Sundance",
    state_id: "WY",
    lat: 44.4048,
    lng: -104.3623,
    id: 1840022348,
  },
  {
    name: "Farwell",
    state_id: "TX",
    lat: 34.3855,
    lng: -103.0373,
    id: 1840020524,
  },
  {
    name: "Cornersville",
    state_id: "TN",
    lat: 35.3628,
    lng: -86.8583,
    id: 1840016307,
  },
  {
    name: "Frankfort",
    state_id: "MI",
    lat: 44.6361,
    lng: -86.2332,
    id: 1840002304,
  },
  {
    name: "Lyons",
    state_id: "OR",
    lat: 44.7776,
    lng: -122.6098,
    id: 1840019992,
  },
  {
    name: "Livermore",
    state_id: "KY",
    lat: 37.4919,
    lng: -87.1342,
    id: 1840015237,
  },
  {
    name: "Tok",
    state_id: "AK",
    lat: 63.329,
    lng: -143.0379,
    id: 1840023473,
  },
  {
    name: "Preston",
    state_id: "MN",
    lat: 43.6726,
    lng: -92.0828,
    id: 1840009013,
  },
  {
    name: "Potomac Heights",
    state_id: "MD",
    lat: 38.5987,
    lng: -77.1372,
    id: 1840006173,
  },
  {
    name: "Lewiston",
    state_id: "MI",
    lat: 44.873,
    lng: -84.3281,
    id: 1840004025,
  },
  {
    name: "Asotin",
    state_id: "WA",
    lat: 46.3366,
    lng: -117.0414,
    id: 1840018500,
  },
  {
    name: "Fall Creek",
    state_id: "WI",
    lat: 44.7639,
    lng: -91.2783,
    id: 1840002294,
  },
  {
    name: "Colony Park",
    state_id: "PA",
    lat: 40.3465,
    lng: -75.9827,
    id: 1840035457,
  },
  {
    name: "Larimore",
    state_id: "ND",
    lat: 47.9091,
    lng: -97.6269,
    id: 1840000103,
  },
  {
    name: "Browntown",
    state_id: "PA",
    lat: 41.3138,
    lng: -75.7826,
    id: 1840034880,
  },
  {
    name: "Parshall",
    state_id: "ND",
    lat: 47.9557,
    lng: -102.1342,
    id: 1840000069,
  },
  {
    name: "Wheaton",
    state_id: "MN",
    lat: 45.8059,
    lng: -96.498,
    id: 1840009987,
  },
  {
    name: "Colchester",
    state_id: "IL",
    lat: 40.4273,
    lng: -90.7923,
    id: 1840007222,
  },
  {
    name: "Verdi",
    state_id: "NV",
    lat: 39.5143,
    lng: -119.9769,
    id: 1840024131,
  },
  {
    name: "Buffalo",
    state_id: "OK",
    lat: 36.8373,
    lng: -99.6464,
    id: 1840021619,
  },
  {
    name: "Von Ormy",
    state_id: "TX",
    lat: 29.2802,
    lng: -98.6556,
    id: 1840023093,
  },
  {
    name: "Broadway",
    state_id: "NC",
    lat: 35.4579,
    lng: -79.0547,
    id: 1840015420,
  },
  {
    name: "Seeley Lake",
    state_id: "MT",
    lat: 47.1614,
    lng: -113.4525,
    id: 1840018435,
  },
  {
    name: "Buckeystown",
    state_id: "MD",
    lat: 39.3301,
    lng: -77.4316,
    id: 1840024450,
  },
  {
    name: "Higganum",
    state_id: "CT",
    lat: 41.49,
    lng: -72.5562,
    id: 1840000583,
  },
  {
    name: "Limestone Creek",
    state_id: "FL",
    lat: 26.9433,
    lng: -80.1408,
    id: 1840028992,
  },
  {
    name: "Latta",
    state_id: "SC",
    lat: 34.339,
    lng: -79.4337,
    id: 1840016616,
  },
  {
    name: "Salem",
    state_id: "SD",
    lat: 43.7228,
    lng: -97.3894,
    id: 1840002655,
  },
  {
    name: "Crosby",
    state_id: "ND",
    lat: 48.9161,
    lng: -103.2966,
    id: 1840000034,
  },
  {
    name: "Laingsburg",
    state_id: "MI",
    lat: 42.8906,
    lng: -84.35,
    id: 1840003072,
  },
  {
    name: "Roscoe",
    state_id: "TX",
    lat: 32.4419,
    lng: -100.5322,
    id: 1840020763,
  },
  {
    name: "Hansen",
    state_id: "ID",
    lat: 42.5313,
    lng: -114.3012,
    id: 1840020081,
  },
  {
    name: "Clintwood",
    state_id: "VA",
    lat: 37.1507,
    lng: -82.4573,
    id: 1840006459,
  },
  {
    name: "Schall Circle",
    state_id: "FL",
    lat: 26.7154,
    lng: -80.1143,
    id: 1840029002,
  },
  {
    name: "Newell",
    state_id: "WV",
    lat: 40.6179,
    lng: -80.6,
    id: 1840005402,
  },
  {
    name: "Millers Falls",
    state_id: "MA",
    lat: 42.5792,
    lng: -72.4925,
    id: 1840003122,
  },
  {
    name: "New Union",
    state_id: "TN",
    lat: 35.534,
    lng: -86.0832,
    id: 1840025812,
  },
  {
    name: "Enosburg Falls",
    state_id: "VT",
    lat: 44.9083,
    lng: -72.8029,
    id: 1840004032,
  },
  {
    name: "Kenwood Estates",
    state_id: "FL",
    lat: 26.6276,
    lng: -80.1151,
    id: 1840042856,
  },
  {
    name: "Redkey",
    state_id: "IN",
    lat: 40.3483,
    lng: -85.1539,
    id: 1840010422,
  },
  {
    name: "Olcott",
    state_id: "NY",
    lat: 43.3302,
    lng: -78.7104,
    id: 1840004267,
  },
  {
    name: "Basin",
    state_id: "WY",
    lat: 44.3804,
    lng: -108.0466,
    id: 1840019977,
  },
  {
    name: "Sistersville",
    state_id: "WV",
    lat: 39.5599,
    lng: -80.9987,
    id: 1840005746,
  },
  {
    name: "Caraway",
    state_id: "AR",
    lat: 35.7598,
    lng: -90.3224,
    id: 1840014505,
  },
  {
    name: "Manasota Key",
    state_id: "FL",
    lat: 26.9226,
    lng: -82.3537,
    id: 1840029056,
  },
  {
    name: "Toughkenamon",
    state_id: "PA",
    lat: 39.8313,
    lng: -75.7565,
    id: 1840005505,
  },
  {
    name: "Grantsburg",
    state_id: "WI",
    lat: 45.7813,
    lng: -92.6836,
    id: 1840001995,
  },
  {
    name: "Fredonia",
    state_id: "AZ",
    lat: 36.9617,
    lng: -112.5195,
    id: 1840021586,
  },
  {
    name: "Erin",
    state_id: "TN",
    lat: 36.3159,
    lng: -87.7026,
    id: 1840013348,
  },
  {
    name: "Bushyhead",
    state_id: "OK",
    lat: 36.4596,
    lng: -95.5164,
    id: 1840034521,
  },
  {
    name: "Entiat",
    state_id: "WA",
    lat: 47.678,
    lng: -120.2176,
    id: 1840019777,
  },
  {
    name: "Falling Water",
    state_id: "TN",
    lat: 35.1962,
    lng: -85.2604,
    id: 1840024959,
  },
  {
    name: "Paulina",
    state_id: "LA",
    lat: 30.0351,
    lng: -90.7226,
    id: 1840025128,
  },
  {
    name: "Garrett",
    state_id: "WA",
    lat: 46.063,
    lng: -118.3885,
    id: 1840017384,
  },
  {
    name: "Bradenton Beach",
    state_id: "FL",
    lat: 27.4649,
    lng: -82.6957,
    id: 1840014174,
  },
  {
    name: "Brownsboro",
    state_id: "TX",
    lat: 32.2985,
    lng: -95.613,
    id: 1840019501,
  },
  {
    name: "Vardaman",
    state_id: "MS",
    lat: 33.8822,
    lng: -89.1778,
    id: 1840016744,
  },
  {
    name: "Morningside",
    state_id: "MD",
    lat: 38.8266,
    lng: -76.8895,
    id: 1840005988,
  },
  {
    name: "Barrackville",
    state_id: "WV",
    lat: 39.5013,
    lng: -80.1696,
    id: 1840005735,
  },
  {
    name: "Buhler",
    state_id: "KS",
    lat: 38.1383,
    lng: -97.7704,
    id: 1840007537,
  },
  {
    name: "Benton",
    state_id: "TN",
    lat: 35.1749,
    lng: -84.6516,
    id: 1840015475,
  },
  {
    name: "Benwood",
    state_id: "WV",
    lat: 40.0139,
    lng: -80.7328,
    id: 1840005553,
  },
  {
    name: "Manly",
    state_id: "IA",
    lat: 43.2881,
    lng: -93.201,
    id: 1840009028,
  },
  {
    name: "Hays",
    state_id: "NC",
    lat: 36.2469,
    lng: -81.1142,
    id: 1840013337,
  },
  {
    name: "Tower City",
    state_id: "PA",
    lat: 40.5884,
    lng: -76.553,
    id: 1840002781,
  },
  {
    name: "Snyder",
    state_id: "OK",
    lat: 34.6552,
    lng: -98.9533,
    id: 1840021824,
  },
  {
    name: "Melville",
    state_id: "RI",
    lat: 41.5587,
    lng: -71.2953,
    id: 1840003356,
  },
  {
    name: "Boulder",
    state_id: "MT",
    lat: 46.2358,
    lng: -112.1198,
    id: 1840021176,
  },
  {
    name: "De Graff",
    state_id: "OH",
    lat: 40.3127,
    lng: -83.9167,
    id: 1840012152,
  },
  {
    name: "Clara City",
    state_id: "MN",
    lat: 44.9583,
    lng: -95.3672,
    id: 1840000294,
  },
  {
    name: "Freeman",
    state_id: "SD",
    lat: 43.35,
    lng: -97.4288,
    id: 1840002859,
  },
  {
    name: "Walker Valley",
    state_id: "NY",
    lat: 41.6386,
    lng: -74.3775,
    id: 1840004726,
  },
  {
    name: "Shell Rock",
    state_id: "IA",
    lat: 42.7126,
    lng: -92.5816,
    id: 1840009087,
  },
  {
    name: "Munday",
    state_id: "TX",
    lat: 33.4471,
    lng: -99.624,
    id: 1840020600,
  },
  {
    name: "Florence",
    state_id: "TX",
    lat: 30.8406,
    lng: -97.7927,
    id: 1840020874,
  },
  {
    name: "Rock Hall",
    state_id: "MD",
    lat: 39.1392,
    lng: -76.2428,
    id: 1840005800,
  },
  {
    name: "Clio",
    state_id: "AL",
    lat: 31.7085,
    lng: -85.6109,
    id: 1840014926,
  },
  {
    name: "Ravenna",
    state_id: "MI",
    lat: 43.1889,
    lng: -85.9416,
    id: 1840010989,
  },
  {
    name: "Germantown",
    state_id: "IL",
    lat: 38.5552,
    lng: -89.5412,
    id: 1840012857,
  },
  {
    name: "Mount Hope",
    state_id: "WV",
    lat: 37.8976,
    lng: -81.1738,
    id: 1840006296,
  },
  {
    name: "Tiltonsville",
    state_id: "OH",
    lat: 40.1721,
    lng: -80.6973,
    id: 1840012130,
  },
  {
    name: "Britton",
    state_id: "SD",
    lat: 45.7924,
    lng: -97.7529,
    id: 1840002042,
  },
  {
    name: "Palestine",
    state_id: "IL",
    lat: 39.0016,
    lng: -87.6126,
    id: 1840012756,
  },
  {
    name: "Ceredo",
    state_id: "WV",
    lat: 38.3961,
    lng: -82.5536,
    id: 1840006262,
  },
  {
    name: "Long Creek",
    state_id: "IL",
    lat: 39.8052,
    lng: -88.8474,
    id: 1840012427,
  },
  {
    name: "Somerset",
    state_id: "MD",
    lat: 38.9666,
    lng: -77.0963,
    id: 1840005859,
  },
  {
    name: "Wyoming",
    state_id: "IL",
    lat: 41.0638,
    lng: -89.7729,
    id: 1840010304,
  },
  {
    name: "Breckenridge",
    state_id: "MI",
    lat: 43.4074,
    lng: -84.4784,
    id: 1840010997,
  },
  {
    name: "Loch Sheldrake",
    state_id: "NY",
    lat: 41.7733,
    lng: -74.6558,
    id: 1840033908,
  },
  {
    name: "Stowell",
    state_id: "TX",
    lat: 29.7814,
    lng: -94.3794,
    id: 1840019635,
  },
  {
    name: "Osborne",
    state_id: "KS",
    lat: 39.4405,
    lng: -98.6994,
    id: 1840003806,
  },
  {
    name: "Hewlett Harbor",
    state_id: "NY",
    lat: 40.6327,
    lng: -73.6842,
    id: 1840005295,
  },
  {
    name: "Salix",
    state_id: "PA",
    lat: 40.296,
    lng: -78.7599,
    id: 1840005380,
  },
  {
    name: "Manawa",
    state_id: "WI",
    lat: 44.4613,
    lng: -88.9204,
    id: 1840002359,
  },
  {
    name: "Hagaman",
    state_id: "NY",
    lat: 42.9728,
    lng: -74.1545,
    id: 1840004404,
  },
  {
    name: "Buffalo",
    state_id: "IA",
    lat: 41.4666,
    lng: -90.717,
    id: 1840007078,
  },
  {
    name: "West Buechel",
    state_id: "KY",
    lat: 38.195,
    lng: -85.6678,
    id: 1840015200,
  },
  {
    name: "Dike",
    state_id: "IA",
    lat: 42.4641,
    lng: -92.6255,
    id: 1840006967,
  },
  {
    name: "Twin Lakes",
    state_id: "VA",
    lat: 38.2505,
    lng: -78.4417,
    id: 1840026751,
  },
  {
    name: "Cleary",
    state_id: "MS",
    lat: 32.1635,
    lng: -90.182,
    id: 1840025905,
  },
  {
    name: "Stuart",
    state_id: "VA",
    lat: 36.6404,
    lng: -80.2687,
    id: 1840006549,
  },
  {
    name: "Markham",
    state_id: "TX",
    lat: 28.9627,
    lng: -96.0645,
    id: 1840018277,
  },
  {
    name: "Starbuck",
    state_id: "MN",
    lat: 45.6121,
    lng: -95.5333,
    id: 1840008890,
  },
  {
    name: "Middleburg",
    state_id: "PA",
    lat: 40.7892,
    lng: -77.0445,
    id: 1840002798,
  },
  {
    name: "Enhaut",
    state_id: "PA",
    lat: 40.2324,
    lng: -76.8253,
    id: 1840034952,
  },
  {
    name: "Goodwell",
    state_id: "OK",
    lat: 36.5943,
    lng: -101.6326,
    id: 1840021625,
  },
  {
    name: "Río Cañas Abajo",
    state_id: "PR",
    lat: 18.0391,
    lng: -66.4679,
    id: 1630035562,
  },
  {
    name: "Woodsboro",
    state_id: "MD",
    lat: 39.5328,
    lng: -77.3096,
    id: 1840005718,
  },
  {
    name: "Huntsville",
    state_id: "TN",
    lat: 36.4112,
    lng: -84.5053,
    id: 1840016017,
  },
  {
    name: "Lake Winnebago",
    state_id: "MO",
    lat: 38.8234,
    lng: -94.3612,
    id: 1840008613,
  },
  {
    name: "Mayo",
    state_id: "FL",
    lat: 30.0512,
    lng: -83.1768,
    id: 1840017202,
  },
  {
    name: "Waymart",
    state_id: "PA",
    lat: 41.5866,
    lng: -75.4047,
    id: 1840003317,
  },
  {
    name: "Toluca",
    state_id: "IL",
    lat: 41.0044,
    lng: -89.1339,
    id: 1840010320,
  },
  {
    name: "North Bend",
    state_id: "NE",
    lat: 41.462,
    lng: -96.7858,
    id: 1840000566,
  },
  {
    name: "Lake Medina Shores",
    state_id: "TX",
    lat: 29.6383,
    lng: -98.9869,
    id: 1840042595,
  },
  {
    name: "Calverton Park",
    state_id: "MO",
    lat: 38.7656,
    lng: -90.3103,
    id: 1840010749,
  },
  {
    name: "Joanna",
    state_id: "SC",
    lat: 34.4149,
    lng: -81.8086,
    id: 1840013584,
  },
  {
    name: "Clymer",
    state_id: "PA",
    lat: 40.6686,
    lng: -79.0132,
    id: 1840000992,
  },
  {
    name: "Lumpkin",
    state_id: "GA",
    lat: 32.0487,
    lng: -84.7981,
    id: 1840015832,
  },
  {
    name: "Hummels Wharf",
    state_id: "PA",
    lat: 40.8308,
    lng: -76.841,
    id: 1840005364,
  },
  {
    name: "Spring Grove",
    state_id: "MN",
    lat: 43.5607,
    lng: -91.637,
    id: 1840009008,
  },
  {
    name: "Westfield",
    state_id: "WI",
    lat: 43.8851,
    lng: -89.4926,
    id: 1840002594,
  },
  {
    name: "Heppner",
    state_id: "OR",
    lat: 45.3547,
    lng: -119.5559,
    id: 1840019921,
  },
  {
    name: "Geary",
    state_id: "OK",
    lat: 35.5927,
    lng: -98.2914,
    id: 1840020391,
  },
  {
    name: "Eastport",
    state_id: "ME",
    lat: 44.9137,
    lng: -67.0183,
    id: 1840000272,
  },
  {
    name: "Otterbein",
    state_id: "IN",
    lat: 40.4882,
    lng: -87.0854,
    id: 1840009404,
  },
  {
    name: "Waldo",
    state_id: "AR",
    lat: 33.3528,
    lng: -93.2949,
    id: 1840015729,
  },
  {
    name: "Fairfax",
    state_id: "OK",
    lat: 36.5698,
    lng: -96.708,
    id: 1840021592,
  },
  {
    name: "Mount Airy",
    state_id: "GA",
    lat: 34.5221,
    lng: -83.4977,
    id: 1840016567,
  },
  {
    name: "Chrisman",
    state_id: "IL",
    lat: 39.8044,
    lng: -87.6749,
    id: 1840007309,
  },
  {
    name: "Nixon",
    state_id: "PA",
    lat: 40.7844,
    lng: -79.934,
    id: 1840035431,
  },
  {
    name: "Washburn",
    state_id: "ND",
    lat: 47.292,
    lng: -101.0278,
    id: 1840001899,
  },
  {
    name: "Fuig",
    state_id: "PR",
    lat: 17.9857,
    lng: -66.917,
    id: 1630035670,
  },
  {
    name: "Onarga",
    state_id: "IL",
    lat: 40.7153,
    lng: -88.0108,
    id: 1840011863,
  },
  {
    name: "Point MacKenzie",
    state_id: "AK",
    lat: 61.3463,
    lng: -150.0787,
    id: 1840023659,
  },
  {
    name: "Taft",
    state_id: "FL",
    lat: 28.4284,
    lng: -81.3677,
    id: 1840014086,
  },
  {
    name: "Wallace",
    state_id: "LA",
    lat: 30.0303,
    lng: -90.6569,
    id: 1840013967,
  },
  {
    name: "Mabscott",
    state_id: "WV",
    lat: 37.7694,
    lng: -81.2133,
    id: 1840006352,
  },
  {
    name: "Palm Beach Shores",
    state_id: "FL",
    lat: 26.7775,
    lng: -80.0367,
    id: 1840017268,
  },
  {
    name: "Roseland",
    state_id: "LA",
    lat: 30.7646,
    lng: -90.5114,
    id: 1840018208,
  },
  {
    name: "Clear Lake",
    state_id: "SD",
    lat: 44.7646,
    lng: -96.6767,
    id: 1840000298,
  },
  {
    name: "Craig",
    state_id: "AK",
    lat: 55.4913,
    lng: -133.1165,
    id: 1840023520,
  },
  {
    name: "Fremont",
    state_id: "NC",
    lat: 35.5436,
    lng: -77.9751,
    id: 1840016323,
  },
  {
    name: "Mounds",
    state_id: "OK",
    lat: 35.8789,
    lng: -96.0682,
    id: 1840022700,
  },
  {
    name: "Midtown",
    state_id: "TN",
    lat: 35.8813,
    lng: -84.5716,
    id: 1840013384,
  },
  {
    name: "Hemlock",
    state_id: "MI",
    lat: 43.418,
    lng: -84.2326,
    id: 1840004225,
  },
  {
    name: "New Brockton",
    state_id: "AL",
    lat: 31.3784,
    lng: -85.9228,
    id: 1840017091,
  },
  {
    name: "North Charleroi",
    state_id: "PA",
    lat: 40.15,
    lng: -79.9083,
    id: 1840003663,
  },
  {
    name: "Del Rey",
    state_id: "CA",
    lat: 36.6562,
    lng: -119.5993,
    id: 1840018938,
  },
  {
    name: "Pinopolis",
    state_id: "SC",
    lat: 33.2287,
    lng: -80.0386,
    id: 1840025043,
  },
  {
    name: "Harkers Island",
    state_id: "NC",
    lat: 34.7002,
    lng: -76.5612,
    id: 1840013523,
  },
  {
    name: "Buffalo",
    state_id: "WV",
    lat: 38.6116,
    lng: -81.982,
    id: 1840006182,
  },
  {
    name: "Galveston",
    state_id: "IN",
    lat: 40.5767,
    lng: -86.1924,
    id: 1840010362,
  },
  {
    name: "Smithville",
    state_id: "OH",
    lat: 40.8625,
    lng: -81.8604,
    id: 1840011899,
  },
  {
    name: "Lauderdale Lakes",
    state_id: "WI",
    lat: 42.7709,
    lng: -88.5778,
    id: 1840042476,
  },
  {
    name: "Mount Hermon",
    state_id: "CA",
    lat: 37.0511,
    lng: -122.0533,
    id: 1840024796,
  },
  {
    name: "Gnadenhutten",
    state_id: "OH",
    lat: 40.3594,
    lng: -81.4293,
    id: 1840012088,
  },
  {
    name: "Platte",
    state_id: "SD",
    lat: 43.3868,
    lng: -98.8437,
    id: 1840002848,
  },
  {
    name: "Morrisonville",
    state_id: "NY",
    lat: 44.6922,
    lng: -73.5505,
    id: 1840004038,
  },
  {
    name: "Hampton",
    state_id: "AR",
    lat: 33.5363,
    lng: -92.4661,
    id: 1840013741,
  },
  {
    name: "Cripple Creek",
    state_id: "CO",
    lat: 38.7466,
    lng: -105.185,
    id: 1840018823,
  },
  {
    name: "Hackleburg",
    state_id: "AL",
    lat: 34.2693,
    lng: -87.8303,
    id: 1840016697,
  },
  {
    name: "Pastos",
    state_id: "PR",
    lat: 18.1193,
    lng: -66.2601,
    id: 1630023599,
  },
  {
    name: "Philmont",
    state_id: "NY",
    lat: 42.2477,
    lng: -73.6463,
    id: 1840004631,
  },
  {
    name: "Falkville",
    state_id: "AL",
    lat: 34.3793,
    lng: -86.9086,
    id: 1840002079,
  },
  {
    name: "Nevada",
    state_id: "TX",
    lat: 33.0463,
    lng: -96.3811,
    id: 1840020660,
  },
  {
    name: "Lumber City",
    state_id: "GA",
    lat: 31.933,
    lng: -82.6828,
    id: 1840015834,
  },
  {
    name: "Utica",
    state_id: "SC",
    lat: 34.6778,
    lng: -82.925,
    id: 1840013532,
  },
  {
    name: "Dimondale",
    state_id: "MI",
    lat: 42.6485,
    lng: -84.6475,
    id: 1840011084,
  },
  {
    name: "Fort Valley",
    state_id: "AZ",
    lat: 35.2554,
    lng: -111.7253,
    id: 1840075372,
  },
  {
    name: "Shattuck",
    state_id: "OK",
    lat: 36.2651,
    lng: -99.8774,
    id: 1840022660,
  },
  {
    name: "Stoneville",
    state_id: "NC",
    lat: 36.4653,
    lng: -79.9064,
    id: 1840016055,
  },
  {
    name: "Stockton",
    state_id: "KS",
    lat: 39.4251,
    lng: -99.2764,
    id: 1840009574,
  },
  {
    name: "Mission",
    state_id: "SD",
    lat: 43.3063,
    lng: -100.661,
    id: 1840002894,
  },
  {
    name: "Bloomfield",
    state_id: "PA",
    lat: 40.4189,
    lng: -77.189,
    id: 1840066083,
  },
  {
    name: "Rush Springs",
    state_id: "OK",
    lat: 34.7792,
    lng: -97.9572,
    id: 1840021806,
  },
  {
    name: "Fort Clark Springs",
    state_id: "TX",
    lat: 29.2937,
    lng: -100.4245,
    id: 1840026041,
  },
  {
    name: "Ohkay Owingeh",
    state_id: "NM",
    lat: 36.0439,
    lng: -106.0565,
    id: 1840136422,
  },
  {
    name: "Medford",
    state_id: "MN",
    lat: 44.1685,
    lng: -93.2472,
    id: 1840007912,
  },
  {
    name: "West Canton",
    state_id: "NC",
    lat: 35.5384,
    lng: -82.8671,
    id: 1840013415,
  },
  {
    name: "Cottonwood",
    state_id: "AL",
    lat: 31.0552,
    lng: -85.3007,
    id: 1840017130,
  },
  {
    name: "Hillsboro",
    state_id: "MS",
    lat: 32.4491,
    lng: -89.4889,
    id: 1840025903,
  },
  {
    name: "Coldstream",
    state_id: "OH",
    lat: 39.0479,
    lng: -84.3474,
    id: 1840042321,
  },
  {
    name: "Fellsburg",
    state_id: "PA",
    lat: 40.1842,
    lng: -79.8269,
    id: 1840026432,
  },
  {
    name: "Faxon",
    state_id: "PA",
    lat: 41.2556,
    lng: -76.9771,
    id: 1840035283,
  },
  {
    name: "Emerald Bay",
    state_id: "TX",
    lat: 32.1602,
    lng: -95.438,
    id: 1840042575,
  },
  {
    name: "Manchaca",
    state_id: "TX",
    lat: 30.1353,
    lng: -97.8362,
    id: 1840027021,
  },
  {
    name: "Roland",
    state_id: "IA",
    lat: 42.1661,
    lng: -93.5029,
    id: 1840009176,
  },
  {
    name: "Pleasant Hill",
    state_id: "OH",
    lat: 40.0506,
    lng: -84.3462,
    id: 1840012378,
  },
  {
    name: "Chinook",
    state_id: "MT",
    lat: 48.5901,
    lng: -109.2318,
    id: 1840018358,
  },
  {
    name: "Colbert",
    state_id: "OK",
    lat: 33.8578,
    lng: -96.5043,
    id: 1840022898,
  },
  {
    name: "Watson",
    state_id: "LA",
    lat: 30.575,
    lng: -90.9508,
    id: 1840026010,
  },
  {
    name: "Wells",
    state_id: "NV",
    lat: 41.1132,
    lng: -114.9535,
    id: 1840021335,
  },
  {
    name: "Oquawka",
    state_id: "IL",
    lat: 40.9379,
    lng: -90.9499,
    id: 1840011796,
  },
  {
    name: "Rolla",
    state_id: "ND",
    lat: 48.8527,
    lng: -99.6142,
    id: 1840001851,
  },
  {
    name: "Lawtell",
    state_id: "LA",
    lat: 30.5126,
    lng: -92.1832,
    id: 1840026001,
  },
  {
    name: "Rogers",
    state_id: "TX",
    lat: 30.93,
    lng: -97.2291,
    id: 1840022156,
  },
  {
    name: "Palmdale",
    state_id: "PA",
    lat: 40.2963,
    lng: -76.6233,
    id: 1840035291,
  },
  {
    name: "West Blocton",
    state_id: "AL",
    lat: 33.1186,
    lng: -87.1253,
    id: 1840016894,
  },
  {
    name: "Moenkopi",
    state_id: "AZ",
    lat: 36.1124,
    lng: -111.2212,
    id: 1840018967,
  },
  {
    name: "Crandon Lakes",
    state_id: "NJ",
    lat: 41.1234,
    lng: -74.8403,
    id: 1840004954,
  },
  {
    name: "Rogersville",
    state_id: "AL",
    lat: 34.8251,
    lng: -87.2821,
    id: 1840017891,
  },
  {
    name: "Barneveld",
    state_id: "WI",
    lat: 43.0117,
    lng: -89.8968,
    id: 1840002957,
  },
  {
    name: "Farmland",
    state_id: "IN",
    lat: 40.1895,
    lng: -85.1273,
    id: 1840010479,
  },
  {
    name: "Marmaduke",
    state_id: "AR",
    lat: 36.1901,
    lng: -90.3851,
    id: 1840015322,
  },
  {
    name: "Clinton",
    state_id: "KY",
    lat: 36.6662,
    lng: -88.9939,
    id: 1840014408,
  },
  {
    name: "Stockbridge",
    state_id: "MI",
    lat: 42.4483,
    lng: -84.1757,
    id: 1840011081,
  },
  {
    name: "Barbourmeade",
    state_id: "KY",
    lat: 38.2986,
    lng: -85.6009,
    id: 1840013200,
  },
  {
    name: "West Easton",
    state_id: "PA",
    lat: 40.6781,
    lng: -75.2357,
    id: 1840000983,
  },
  {
    name: "Holiday Lakes",
    state_id: "TX",
    lat: 29.2083,
    lng: -95.5146,
    id: 1840022239,
  },
  {
    name: "Brown City",
    state_id: "MI",
    lat: 43.2115,
    lng: -82.9879,
    id: 1840002699,
  },
  {
    name: "Ontonagon",
    state_id: "MI",
    lat: 46.8665,
    lng: -89.3122,
    id: 1840010913,
  },
  {
    name: "Grant Park",
    state_id: "IL",
    lat: 41.2429,
    lng: -87.6353,
    id: 1840011694,
  },
  {
    name: "Goodman",
    state_id: "MO",
    lat: 36.7388,
    lng: -94.4091,
    id: 1840010901,
  },
  {
    name: "Meadowlands",
    state_id: "PA",
    lat: 40.2173,
    lng: -80.23,
    id: 1840035091,
  },
  {
    name: "Arivaca Junction",
    state_id: "AZ",
    lat: 31.739,
    lng: -111.0738,
    id: 1840023065,
  },
  {
    name: "Dickson",
    state_id: "OK",
    lat: 34.1877,
    lng: -96.997,
    id: 1840021900,
  },
  {
    name: "Valmeyer",
    state_id: "IL",
    lat: 38.3064,
    lng: -90.2979,
    id: 1840012898,
  },
  {
    name: "Alton",
    state_id: "IA",
    lat: 42.9876,
    lng: -96.0095,
    id: 1840006869,
  },
  {
    name: "Cortland West",
    state_id: "NY",
    lat: 42.5942,
    lng: -76.2258,
    id: 1840035087,
  },
  {
    name: "Millbury",
    state_id: "OH",
    lat: 41.5648,
    lng: -83.4255,
    id: 1840011558,
  },
  {
    name: "Mitchell",
    state_id: "IL",
    lat: 38.7631,
    lng: -90.0816,
    id: 1840026704,
  },
  {
    name: "Fort Totten",
    state_id: "ND",
    lat: 47.9716,
    lng: -98.9971,
    id: 1840003996,
  },
  {
    name: "LaCoste",
    state_id: "TX",
    lat: 29.31,
    lng: -98.8116,
    id: 1840036481,
  },
  {
    name: "Barry",
    state_id: "IL",
    lat: 39.6983,
    lng: -91.0399,
    id: 1840007314,
  },
  {
    name: "Ellendale",
    state_id: "ND",
    lat: 46.0043,
    lng: -98.5254,
    id: 1840000251,
  },
  {
    name: "White Sands",
    state_id: "NM",
    lat: 32.3808,
    lng: -106.4836,
    id: 1840019417,
  },
  {
    name: "Country Club Hills",
    state_id: "MO",
    lat: 38.7208,
    lng: -90.2751,
    id: 1840007441,
  },
  {
    name: "Milroy",
    state_id: "PA",
    lat: 40.7164,
    lng: -77.5871,
    id: 1840005369,
  },
  {
    name: "Bethany Beach",
    state_id: "DE",
    lat: 38.5391,
    lng: -75.066,
    id: 1840006072,
  },
  {
    name: "Bally",
    state_id: "PA",
    lat: 40.4004,
    lng: -75.5876,
    id: 1840001169,
  },
  {
    name: "Taylorsville",
    state_id: "MS",
    lat: 31.8322,
    lng: -89.434,
    id: 1840017038,
  },
  {
    name: "Mansfield",
    state_id: "MO",
    lat: 37.1102,
    lng: -92.5804,
    id: 1840009898,
  },
  {
    name: "Blue Lake",
    state_id: "CA",
    lat: 40.8813,
    lng: -123.9931,
    id: 1840010232,
  },
  {
    name: "Gordonsville",
    state_id: "TN",
    lat: 36.1817,
    lng: -85.9324,
    id: 1840016097,
  },
  {
    name: "Diamond Ridge",
    state_id: "AK",
    lat: 59.7024,
    lng: -151.5545,
    id: 1840023651,
  },
  {
    name: "Hemphill",
    state_id: "TX",
    lat: 31.3431,
    lng: -93.8512,
    id: 1840020831,
  },
  {
    name: "Mescalero",
    state_id: "NM",
    lat: 33.1448,
    lng: -105.7897,
    id: 1840019401,
  },
  {
    name: "Jackpot",
    state_id: "NV",
    lat: 41.9797,
    lng: -114.6645,
    id: 1840024115,
  },
  {
    name: "Kamiah",
    state_id: "ID",
    lat: 46.2269,
    lng: -116.0283,
    id: 1840018497,
  },
  {
    name: "Melcher-Dallas",
    state_id: "IA",
    lat: 41.2275,
    lng: -93.2411,
    id: 1840008228,
  },
  {
    name: "Dexter",
    state_id: "NM",
    lat: 33.1954,
    lng: -104.3689,
    id: 1840021933,
  },
  {
    name: "Muscoda",
    state_id: "WI",
    lat: 43.1873,
    lng: -90.4334,
    id: 1840002979,
  },
  {
    name: "Dover Beaches South",
    state_id: "NJ",
    lat: 39.9549,
    lng: -74.0756,
    id: 1840005529,
  },
  {
    name: "Verplanck",
    state_id: "NY",
    lat: 41.2558,
    lng: -73.9585,
    id: 1840004929,
  },
  {
    name: "Saticoy",
    state_id: "CA",
    lat: 34.2819,
    lng: -119.1452,
    id: 1840025001,
  },
  {
    name: "Gregory",
    state_id: "SD",
    lat: 43.2299,
    lng: -99.4208,
    id: 1840002865,
  },
  {
    name: "Six Shooter Canyon",
    state_id: "AZ",
    lat: 33.3568,
    lng: -110.772,
    id: 1840075490,
  },
  {
    name: "Black River",
    state_id: "NY",
    lat: 44.0089,
    lng: -75.7966,
    id: 1840004122,
  },
  {
    name: "Earlston",
    state_id: "PA",
    lat: 40.0038,
    lng: -78.3736,
    id: 1840026526,
  },
  {
    name: "Fayette",
    state_id: "OH",
    lat: 41.6727,
    lng: -84.3284,
    id: 1840011512,
  },
  {
    name: "Marion",
    state_id: "TX",
    lat: 29.5726,
    lng: -98.143,
    id: 1840020944,
  },
  {
    name: "Bayboro",
    state_id: "NC",
    lat: 35.1474,
    lng: -76.7692,
    id: 1840015470,
  },
  {
    name: "Alto",
    state_id: "TX",
    lat: 31.6509,
    lng: -95.0708,
    id: 1840022125,
  },
  {
    name: "Curtice",
    state_id: "OH",
    lat: 41.616,
    lng: -83.3744,
    id: 1840026265,
  },
  {
    name: "Palm Valley",
    state_id: "TX",
    lat: 26.2015,
    lng: -97.7546,
    id: 1840021039,
  },
  {
    name: "Haverford College",
    state_id: "PA",
    lat: 40.0082,
    lng: -75.3064,
    id: 1840043365,
  },
  {
    name: "Bethany",
    state_id: "IL",
    lat: 39.6441,
    lng: -88.7413,
    id: 1840012560,
  },
  {
    name: "Mapleton",
    state_id: "ND",
    lat: 46.8915,
    lng: -97.0533,
    id: 1840000186,
  },
  {
    name: "Houston",
    state_id: "PA",
    lat: 40.2494,
    lng: -80.211,
    id: 1840003657,
  },
  {
    name: "Cavalier",
    state_id: "ND",
    lat: 48.7939,
    lng: -97.6226,
    id: 1840001857,
  },
  {
    name: "Edwardsburg",
    state_id: "MI",
    lat: 41.7965,
    lng: -86.0835,
    id: 1840011386,
  },
  {
    name: "Haskins",
    state_id: "OH",
    lat: 41.4648,
    lng: -83.7043,
    id: 1840011550,
  },
  {
    name: "Maggie Valley",
    state_id: "NC",
    lat: 35.5239,
    lng: -83.0643,
    id: 1840016295,
  },
  {
    name: "Baltic",
    state_id: "CT",
    lat: 41.614,
    lng: -72.0866,
    id: 1840026270,
  },
  {
    name: "Keenesburg",
    state_id: "CO",
    lat: 40.1112,
    lng: -104.504,
    id: 1840021370,
  },
  {
    name: "Winterville",
    state_id: "GA",
    lat: 33.9666,
    lng: -83.2815,
    id: 1840015638,
  },
  {
    name: "Deatsville",
    state_id: "AL",
    lat: 32.5863,
    lng: -86.3918,
    id: 1840016964,
  },
  {
    name: "Algonquin",
    state_id: "MD",
    lat: 38.5839,
    lng: -76.0978,
    id: 1840031443,
  },
  {
    name: "Beggs",
    state_id: "OK",
    lat: 35.7772,
    lng: -96.0278,
    id: 1840019107,
  },
  {
    name: "Talkeetna",
    state_id: "AK",
    lat: 62.2753,
    lng: -150.0814,
    id: 1840023375,
  },
  {
    name: "South Gull Lake",
    state_id: "MI",
    lat: 42.3901,
    lng: -85.4008,
    id: 1840031757,
  },
  {
    name: "Cottonwood",
    state_id: "MN",
    lat: 44.6106,
    lng: -95.672,
    id: 1840006786,
  },
  {
    name: "Avalon",
    state_id: "NJ",
    lat: 39.0906,
    lng: -74.7357,
    id: 1840003808,
  },
  {
    name: "Kemp",
    state_id: "TX",
    lat: 32.4349,
    lng: -96.2234,
    id: 1840022067,
  },
  {
    name: "Whitaker",
    state_id: "PA",
    lat: 40.4007,
    lng: -79.8864,
    id: 1840001275,
  },
  {
    name: "Pinetops",
    state_id: "NC",
    lat: 35.7912,
    lng: -77.6378,
    id: 1840016178,
  },
  {
    name: "Mississippi Valley State University",
    state_id: "MS",
    lat: 33.5172,
    lng: -90.3444,
    id: 1840042510,
  },
  {
    name: "Fort Thompson",
    state_id: "SD",
    lat: 44.0527,
    lng: -99.4079,
    id: 1840004143,
  },
  {
    name: "Bon Secour",
    state_id: "AL",
    lat: 30.3222,
    lng: -87.7271,
    id: 1840027557,
  },
  {
    name: "Monteagle",
    state_id: "TN",
    lat: 35.2367,
    lng: -85.8513,
    id: 1840016430,
  },
  {
    name: "St. David",
    state_id: "AZ",
    lat: 31.8992,
    lng: -110.2251,
    id: 1840019493,
  },
  {
    name: "Loyal",
    state_id: "WI",
    lat: 44.7366,
    lng: -90.4959,
    id: 1840002254,
  },
  {
    name: "Roslyn Estates",
    state_id: "NY",
    lat: 40.7937,
    lng: -73.6611,
    id: 1840005322,
  },
  {
    name: "Troy",
    state_id: "PA",
    lat: 41.784,
    lng: -76.7897,
    id: 1840000521,
  },
  {
    name: "Cascade-Chipita Park",
    state_id: "CO",
    lat: 38.9433,
    lng: -105.0024,
    id: 1840073905,
  },
  {
    name: "Royalton",
    state_id: "MN",
    lat: 45.8302,
    lng: -94.2931,
    id: 1840008866,
  },
  {
    name: "West Mountain",
    state_id: "UT",
    lat: 40.0654,
    lng: -111.7868,
    id: 1840037253,
  },
  {
    name: "La Crosse",
    state_id: "KS",
    lat: 38.5318,
    lng: -99.3095,
    id: 1840008628,
  },
  {
    name: "Newark",
    state_id: "TX",
    lat: 33.0049,
    lng: -97.4856,
    id: 1840020635,
  },
  {
    name: "Lannon",
    state_id: "WI",
    lat: 43.1522,
    lng: -88.1604,
    id: 1840003014,
  },
  {
    name: "Coon Rapids",
    state_id: "IA",
    lat: 41.8748,
    lng: -94.6785,
    id: 1840006999,
  },
  {
    name: "Moody AFB",
    state_id: "GA",
    lat: 30.9715,
    lng: -83.1954,
    id: 1840073868,
  },
  {
    name: "Moreland",
    state_id: "ID",
    lat: 43.2195,
    lng: -112.4378,
    id: 1840024024,
  },
  {
    name: "Canyon Creek",
    state_id: "TX",
    lat: 32.3927,
    lng: -97.7406,
    id: 1840042522,
  },
  {
    name: "Waitsburg",
    state_id: "WA",
    lat: 46.2696,
    lng: -118.151,
    id: 1840021173,
  },
  {
    name: "Sophia",
    state_id: "WV",
    lat: 37.7117,
    lng: -81.2524,
    id: 1840006354,
  },
  {
    name: "Mahnomen",
    state_id: "MN",
    lat: 47.3146,
    lng: -95.9673,
    id: 1840008832,
  },
  {
    name: "Martindale",
    state_id: "TX",
    lat: 29.8448,
    lng: -97.8396,
    id: 1840020938,
  },
  {
    name: "Vienna Bend",
    state_id: "LA",
    lat: 31.7372,
    lng: -93.0343,
    id: 1840031021,
  },
  {
    name: "Pinconning",
    state_id: "MI",
    lat: 43.8573,
    lng: -83.9638,
    id: 1840002581,
  },
  {
    name: "Omega",
    state_id: "GA",
    lat: 31.3389,
    lng: -83.5949,
    id: 1840014957,
  },
  {
    name: "Atkinson",
    state_id: "NE",
    lat: 42.5309,
    lng: -98.9747,
    id: 1840006917,
  },
  {
    name: "Centre Hall",
    state_id: "PA",
    lat: 40.8448,
    lng: -77.685,
    id: 1840000823,
  },
  {
    name: "Averill Park",
    state_id: "NY",
    lat: 42.6361,
    lng: -73.5529,
    id: 1840004431,
  },
  {
    name: "Ak-Chin Village",
    state_id: "AZ",
    lat: 33.0197,
    lng: -112.0808,
    id: 1840018029,
  },
  {
    name: "Zebulon",
    state_id: "GA",
    lat: 33.0926,
    lng: -84.3423,
    id: 1840015754,
  },
  {
    name: "Ore City",
    state_id: "TX",
    lat: 32.8008,
    lng: -94.7182,
    id: 1840020732,
  },
  {
    name: "New Vienna",
    state_id: "OH",
    lat: 39.3256,
    lng: -83.6937,
    id: 1840012623,
  },
  {
    name: "Niverville",
    state_id: "NY",
    lat: 42.4484,
    lng: -73.6497,
    id: 1840004626,
  },
  {
    name: "Hooper Bay",
    state_id: "AK",
    lat: 61.5262,
    lng: -166.1146,
    id: 1840023324,
  },
  {
    name: "Marshallville",
    state_id: "GA",
    lat: 32.4564,
    lng: -83.9407,
    id: 1840015810,
  },
  {
    name: "Cottonwood Shores",
    state_id: "TX",
    lat: 30.5521,
    lng: -98.325,
    id: 1840019568,
  },
  {
    name: "Terry",
    state_id: "MS",
    lat: 32.1047,
    lng: -90.298,
    id: 1840016992,
  },
  {
    name: "Green Valley",
    state_id: "SD",
    lat: 44.039,
    lng: -103.1116,
    id: 1840004097,
  },
  {
    name: "Suffield Depot",
    state_id: "CT",
    lat: 41.983,
    lng: -72.6463,
    id: 1840028603,
  },
  {
    name: "Telford",
    state_id: "TN",
    lat: 36.2534,
    lng: -82.5478,
    id: 1840025772,
  },
  {
    name: "Mountain View",
    state_id: "WY",
    lat: 41.2723,
    lng: -110.3344,
    id: 1840022388,
  },
  {
    name: "Belmont Estates",
    state_id: "VA",
    lat: 38.4465,
    lng: -78.9228,
    id: 1840026719,
  },
  {
    name: "Tower Lakes",
    state_id: "IL",
    lat: 42.2315,
    lng: -88.1538,
    id: 1840029830,
  },
  {
    name: "Hillcrest",
    state_id: "IL",
    lat: 41.9684,
    lng: -89.0654,
    id: 1840011233,
  },
  {
    name: "Bevil Oaks",
    state_id: "TX",
    lat: 30.1517,
    lng: -94.2711,
    id: 1840019613,
  },
  {
    name: "Topeka",
    state_id: "IN",
    lat: 41.5394,
    lng: -85.5475,
    id: 1840010208,
  },
  {
    name: "Yale",
    state_id: "OK",
    lat: 36.115,
    lng: -96.7012,
    id: 1840021676,
  },
  {
    name: "South Coventry",
    state_id: "CT",
    lat: 41.7695,
    lng: -72.3064,
    id: 1840028601,
  },
  {
    name: "Hurt",
    state_id: "VA",
    lat: 37.0954,
    lng: -79.3029,
    id: 1840006495,
  },
  {
    name: "New York Mills",
    state_id: "MN",
    lat: 46.5198,
    lng: -95.3733,
    id: 1840007748,
  },
  {
    name: "Van Vleck",
    state_id: "TX",
    lat: 29.0278,
    lng: -95.88,
    id: 1840019681,
  },
  {
    name: "Cohoe",
    state_id: "AK",
    lat: 60.2681,
    lng: -151.2843,
    id: 1840023413,
  },
  {
    name: "Rancho Alegre",
    state_id: "TX",
    lat: 27.7392,
    lng: -98.1027,
    id: 1840037071,
  },
  {
    name: "Redland",
    state_id: "TX",
    lat: 31.4033,
    lng: -94.7182,
    id: 1840139817,
  },
  {
    name: "Fullerton",
    state_id: "NE",
    lat: 41.3648,
    lng: -97.9725,
    id: 1840000638,
  },
  {
    name: "Iraan",
    state_id: "TX",
    lat: 30.9129,
    lng: -101.9001,
    id: 1840019552,
  },
  {
    name: "Converse",
    state_id: "IN",
    lat: 40.5773,
    lng: -85.878,
    id: 1840009365,
  },
  {
    name: "Lexington",
    state_id: "TX",
    lat: 30.4147,
    lng: -97.0093,
    id: 1840022181,
  },
  {
    name: "La Luz",
    state_id: "NM",
    lat: 32.9698,
    lng: -105.9388,
    id: 1840018039,
  },
  {
    name: "Fowler",
    state_id: "MI",
    lat: 43.0041,
    lng: -84.7411,
    id: 1840011024,
  },
  {
    name: "Spicer",
    state_id: "MN",
    lat: 45.2329,
    lng: -94.9401,
    id: 1840008906,
  },
  {
    name: "Empire",
    state_id: "LA",
    lat: 29.4133,
    lng: -89.6052,
    id: 1840013114,
  },
  {
    name: "Keedysville",
    state_id: "MD",
    lat: 39.4867,
    lng: -77.6979,
    id: 1840005616,
  },
  {
    name: "Robbins",
    state_id: "NC",
    lat: 35.4279,
    lng: -79.5829,
    id: 1840014558,
  },
  {
    name: "Tidmore Bend",
    state_id: "AL",
    lat: 34.0324,
    lng: -85.9254,
    id: 1840075168,
  },
  {
    name: "Ardmore",
    state_id: "TN",
    lat: 35.0031,
    lng: -86.8547,
    id: 1840013453,
  },
  {
    name: "Beattyville",
    state_id: "KY",
    lat: 37.5862,
    lng: -83.7065,
    id: 1840013234,
  },
  {
    name: "Groveton",
    state_id: "NH",
    lat: 44.6048,
    lng: -71.5201,
    id: 1840002102,
  },
  {
    name: "Riddle",
    state_id: "OR",
    lat: 42.9542,
    lng: -123.3646,
    id: 1840020033,
  },
  {
    name: "West Leechburg",
    state_id: "PA",
    lat: 40.6338,
    lng: -79.617,
    id: 1840001164,
  },
  {
    name: "Homer",
    state_id: "GA",
    lat: 34.335,
    lng: -83.4987,
    id: 1840016659,
  },
  {
    name: "Hagerman",
    state_id: "NM",
    lat: 33.1147,
    lng: -104.3283,
    id: 1840021934,
  },
  {
    name: "Pamplico",
    state_id: "SC",
    lat: 33.9957,
    lng: -79.5698,
    id: 1840016708,
  },
  {
    name: "Latta",
    state_id: "OK",
    lat: 34.7531,
    lng: -96.7088,
    id: 1840034458,
  },
  {
    name: "Jewett",
    state_id: "TX",
    lat: 31.3627,
    lng: -96.1455,
    id: 1840019541,
  },
  {
    name: "Hazel Green",
    state_id: "WI",
    lat: 42.5341,
    lng: -90.4361,
    id: 1840002967,
  },
  {
    name: "Mulberry",
    state_id: "IN",
    lat: 40.3458,
    lng: -86.6672,
    id: 1840009434,
  },
  {
    name: "Greenback",
    state_id: "TN",
    lat: 35.6487,
    lng: -84.1724,
    id: 1840013401,
  },
  {
    name: "Biglerville",
    state_id: "PA",
    lat: 39.9303,
    lng: -77.2462,
    id: 1840000678,
  },
  {
    name: "Strawberry Point",
    state_id: "IA",
    lat: 42.6804,
    lng: -91.5349,
    id: 1840009066,
  },
  {
    name: "Adrian",
    state_id: "MN",
    lat: 43.6332,
    lng: -95.933,
    id: 1840006820,
  },
  {
    name: "Salineville",
    state_id: "OH",
    lat: 40.6207,
    lng: -80.8345,
    id: 1840011934,
  },
  {
    name: "Conway Springs",
    state_id: "KS",
    lat: 37.3899,
    lng: -97.643,
    id: 1840007608,
  },
  {
    name: "Atwood",
    state_id: "KS",
    lat: 39.8103,
    lng: -101.0418,
    id: 1840007276,
  },
  {
    name: "Bowling Green",
    state_id: "MD",
    lat: 39.6272,
    lng: -78.805,
    id: 1840024416,
  },
  {
    name: "Harper",
    state_id: "TX",
    lat: 30.2903,
    lng: -99.2459,
    id: 1840018235,
  },
  {
    name: "Rossville",
    state_id: "IL",
    lat: 40.3815,
    lng: -87.67,
    id: 1840012184,
  },
  {
    name: "Elkader",
    state_id: "IA",
    lat: 42.8572,
    lng: -91.4024,
    id: 1840008009,
  },
  {
    name: "Shelburn",
    state_id: "IN",
    lat: 39.1791,
    lng: -87.3971,
    id: 1840010684,
  },
  {
    name: "Brushy",
    state_id: "OK",
    lat: 35.5578,
    lng: -94.7434,
    id: 1840017831,
  },
  {
    name: "Balfour",
    state_id: "NC",
    lat: 35.3494,
    lng: -82.4824,
    id: 1840013445,
  },
  {
    name: "Hughes",
    state_id: "AR",
    lat: 34.9483,
    lng: -90.4707,
    id: 1840014621,
  },
  {
    name: "Arcola",
    state_id: "VA",
    lat: 38.9447,
    lng: -77.5306,
    id: 1840024494,
  },
  {
    name: "Callender",
    state_id: "CA",
    lat: 35.048,
    lng: -120.5759,
    id: 1840028058,
  },
  {
    name: "Clear Lake Shores",
    state_id: "TX",
    lat: 29.5456,
    lng: -95.0329,
    id: 1840019663,
  },
  {
    name: "Geronimo",
    state_id: "OK",
    lat: 34.4852,
    lng: -98.3893,
    id: 1840021853,
  },
  {
    name: "Oran",
    state_id: "MO",
    lat: 37.0855,
    lng: -89.6534,
    id: 1840009922,
  },
  {
    name: "Courtland",
    state_id: "VA",
    lat: 36.7123,
    lng: -77.062,
    id: 1840006520,
  },
  {
    name: "Harbor Isle",
    state_id: "NY",
    lat: 40.6019,
    lng: -73.6647,
    id: 1840005263,
  },
  {
    name: "Brush Fork",
    state_id: "WV",
    lat: 37.2805,
    lng: -81.245,
    id: 1840038224,
  },
  {
    name: "Sandoval",
    state_id: "IL",
    lat: 38.6121,
    lng: -89.1193,
    id: 1840012846,
  },
  {
    name: "Waterville",
    state_id: "WA",
    lat: 47.648,
    lng: -120.0707,
    id: 1840022287,
  },
  {
    name: "Victoria",
    state_id: "KS",
    lat: 38.8538,
    lng: -99.1473,
    id: 1840010702,
  },
  {
    name: "Bethesda",
    state_id: "OH",
    lat: 40.0164,
    lng: -81.0727,
    id: 1840012383,
  },
  {
    name: "Rison",
    state_id: "AR",
    lat: 33.96,
    lng: -92.1937,
    id: 1840014761,
  },
  {
    name: "Las Quintas Fronterizas",
    state_id: "TX",
    lat: 28.6898,
    lng: -100.468,
    id: 1840037135,
  },
  {
    name: "Scottville",
    state_id: "MI",
    lat: 43.9511,
    lng: -86.2798,
    id: 1840002546,
  },
  {
    name: "Onancock",
    state_id: "VA",
    lat: 37.7102,
    lng: -75.7433,
    id: 1840006326,
  },
  {
    name: "Weber City",
    state_id: "VA",
    lat: 36.6236,
    lng: -82.5631,
    id: 1840006547,
  },
  {
    name: "Warren",
    state_id: "IN",
    lat: 40.6878,
    lng: -85.425,
    id: 1840010342,
  },
  {
    name: "Meadview",
    state_id: "AZ",
    lat: 35.9531,
    lng: -114.0789,
    id: 1840023182,
  },
  {
    name: "Auburn",
    state_id: "KS",
    lat: 38.9077,
    lng: -95.8158,
    id: 1840007401,
  },
  {
    name: "Mantorville",
    state_id: "MN",
    lat: 44.0656,
    lng: -92.7537,
    id: 1840008984,
  },
  {
    name: "Claude",
    state_id: "TX",
    lat: 35.1075,
    lng: -101.3628,
    id: 1840019181,
  },
  {
    name: "Byhalia",
    state_id: "MS",
    lat: 34.869,
    lng: -89.689,
    id: 1840015522,
  },
  {
    name: "Halifax",
    state_id: "VA",
    lat: 36.7612,
    lng: -78.9279,
    id: 1840006505,
  },
  {
    name: "Basin City",
    state_id: "WA",
    lat: 46.5891,
    lng: -119.1567,
    id: 1840017379,
  },
  {
    name: "Newburg",
    state_id: "WI",
    lat: 43.432,
    lng: -88.0477,
    id: 1840002834,
  },
  {
    name: "Ola",
    state_id: "AR",
    lat: 35.031,
    lng: -93.2244,
    id: 1840014608,
  },
  {
    name: "Clinton",
    state_id: "OH",
    lat: 40.9284,
    lng: -81.6304,
    id: 1840000794,
  },
  {
    name: "Palmona Park",
    state_id: "FL",
    lat: 26.6892,
    lng: -81.8946,
    id: 1840028922,
  },
  {
    name: "Fulda",
    state_id: "MN",
    lat: 43.8691,
    lng: -95.6023,
    id: 1840007908,
  },
  {
    name: "Diaz",
    state_id: "AR",
    lat: 35.6478,
    lng: -91.262,
    id: 1840014515,
  },
  {
    name: "DeLisle",
    state_id: "MS",
    lat: 30.3848,
    lng: -89.2746,
    id: 1840032300,
  },
  {
    name: "Clearview Acres",
    state_id: "WY",
    lat: 41.5842,
    lng: -109.2801,
    id: 1840038428,
  },
  {
    name: "Newcastle",
    state_id: "CA",
    lat: 38.8677,
    lng: -121.1318,
    id: 1840024512,
  },
  {
    name: "Heidelberg",
    state_id: "PA",
    lat: 40.3914,
    lng: -80.0923,
    id: 1840001237,
  },
  {
    name: "Harmony",
    state_id: "RI",
    lat: 41.8964,
    lng: -71.6051,
    id: 1840026228,
  },
  {
    name: "Highland",
    state_id: "MD",
    lat: 39.1814,
    lng: -76.957,
    id: 1840024481,
  },
  {
    name: "China",
    state_id: "TX",
    lat: 30.0522,
    lng: -94.3332,
    id: 1840019611,
  },
  {
    name: "Iola",
    state_id: "WI",
    lat: 44.5091,
    lng: -89.1249,
    id: 1840002356,
  },
  {
    name: "Bogart",
    state_id: "GA",
    lat: 33.9479,
    lng: -83.5341,
    id: 1840015648,
  },
  {
    name: "Pasatiempo",
    state_id: "CA",
    lat: 37.0035,
    lng: -122.0265,
    id: 1840028113,
  },
  {
    name: "Paincourtville",
    state_id: "LA",
    lat: 29.9887,
    lng: -91.0554,
    id: 1840014011,
  },
  {
    name: "Theresa",
    state_id: "WI",
    lat: 43.5164,
    lng: -88.454,
    id: 1840002730,
  },
  {
    name: "Beach Haven",
    state_id: "NJ",
    lat: 39.566,
    lng: -74.2492,
    id: 1840001482,
  },
  {
    name: "Pocono Ranch Lands",
    state_id: "PA",
    lat: 41.1779,
    lng: -74.9584,
    id: 1840035395,
  },
  {
    name: "Renovo",
    state_id: "PA",
    lat: 41.3313,
    lng: -77.7478,
    id: 1840003453,
  },
  {
    name: "Harbor Springs",
    state_id: "MI",
    lat: 45.4324,
    lng: -84.989,
    id: 1840003942,
  },
  {
    name: "Highpoint",
    state_id: "OH",
    lat: 39.2892,
    lng: -84.3472,
    id: 1840034153,
  },
  {
    name: "McCaysville",
    state_id: "GA",
    lat: 34.9811,
    lng: -84.3706,
    id: 1840015526,
  },
  {
    name: "Cayuco",
    state_id: "PR",
    lat: 18.2922,
    lng: -66.7348,
    id: 1630035641,
  },
  {
    name: "Hanover",
    state_id: "OH",
    lat: 40.0819,
    lng: -82.2761,
    id: 1840012326,
  },
  {
    name: "Due West",
    state_id: "SC",
    lat: 34.3335,
    lng: -82.3886,
    id: 1840016668,
  },
  {
    name: "Maysville",
    state_id: "OK",
    lat: 34.8544,
    lng: -97.3878,
    id: 1840034465,
  },
  {
    name: "Coleman",
    state_id: "MI",
    lat: 43.7588,
    lng: -84.587,
    id: 1840002661,
  },
  {
    name: "Hallstead",
    state_id: "PA",
    lat: 41.9617,
    lng: -75.7471,
    id: 1840003321,
  },
  {
    name: "Monticello",
    state_id: "WI",
    lat: 42.7456,
    lng: -89.5899,
    id: 1840002465,
  },
  {
    name: "Archie",
    state_id: "MO",
    lat: 38.4826,
    lng: -94.3502,
    id: 1840007462,
  },
  {
    name: "Molino",
    state_id: "FL",
    lat: 30.7123,
    lng: -87.324,
    id: 1840013916,
  },
  {
    name: "Wetumka",
    state_id: "OK",
    lat: 35.2409,
    lng: -96.239,
    id: 1840021816,
  },
  {
    name: "Ames",
    state_id: "TX",
    lat: 30.0451,
    lng: -94.7373,
    id: 1840019597,
  },
  {
    name: "Glenwood City",
    state_id: "WI",
    lat: 45.057,
    lng: -92.1714,
    id: 1840002133,
  },
  {
    name: "Marthasville",
    state_id: "MO",
    lat: 38.631,
    lng: -91.0573,
    id: 1840008566,
  },
  {
    name: "Burlington",
    state_id: "ND",
    lat: 48.2573,
    lng: -101.4146,
    id: 1840000046,
  },
  {
    name: "North Apollo",
    state_id: "PA",
    lat: 40.5938,
    lng: -79.5564,
    id: 1840003522,
  },
  {
    name: "New Madison",
    state_id: "OH",
    lat: 39.9676,
    lng: -84.708,
    id: 1840012251,
  },
  {
    name: "Hillside Lake",
    state_id: "NY",
    lat: 41.6191,
    lng: -73.7944,
    id: 1840034052,
  },
  {
    name: "César Chávez",
    state_id: "TX",
    lat: 26.3118,
    lng: -98.1117,
    id: 1840037037,
  },
  {
    name: "Barataria",
    state_id: "LA",
    lat: 29.7092,
    lng: -90.1217,
    id: 1840013988,
  },
  {
    name: "Avilla",
    state_id: "AR",
    lat: 34.6988,
    lng: -92.5813,
    id: 1840025843,
  },
  {
    name: "Spur",
    state_id: "TX",
    lat: 33.4701,
    lng: -100.8556,
    id: 1840021979,
  },
  {
    name: "Geneva-on-the-Lake",
    state_id: "OH",
    lat: 41.8562,
    lng: -80.9493,
    id: 1840011416,
  },
  {
    name: "Newark",
    state_id: "AR",
    lat: 35.7083,
    lng: -91.4432,
    id: 1840014511,
  },
  {
    name: "Morton",
    state_id: "WA",
    lat: 46.5575,
    lng: -122.2815,
    id: 1840019882,
  },
  {
    name: "Surf City",
    state_id: "NJ",
    lat: 39.6646,
    lng: -74.1711,
    id: 1840001494,
  },
  {
    name: "Kurtistown",
    state_id: "HI",
    lat: 19.5833,
    lng: -155.0721,
    id: 1840029513,
  },
  {
    name: "Laytonville",
    state_id: "CA",
    lat: 39.6715,
    lng: -123.4945,
    id: 1840017534,
  },
  {
    name: "Archer",
    state_id: "FL",
    lat: 29.54,
    lng: -82.5186,
    id: 1840014021,
  },
  {
    name: "Byng",
    state_id: "OK",
    lat: 34.8659,
    lng: -96.6678,
    id: 1840022811,
  },
  {
    name: "Alto",
    state_id: "GA",
    lat: 34.4649,
    lng: -83.5727,
    id: 1840015543,
  },
  {
    name: "Thomas",
    state_id: "OK",
    lat: 35.7476,
    lng: -98.7494,
    id: 1840021724,
  },
  {
    name: "Konawa",
    state_id: "OK",
    lat: 34.9582,
    lng: -96.7535,
    id: 1840020439,
  },
  {
    name: "Locust Fork",
    state_id: "AL",
    lat: 33.8957,
    lng: -86.6301,
    id: 1840016717,
  },
  {
    name: "Lake Telemark",
    state_id: "NJ",
    lat: 40.9605,
    lng: -74.4968,
    id: 1840005196,
  },
  {
    name: "Kenbridge",
    state_id: "VA",
    lat: 36.9608,
    lng: -78.1283,
    id: 1840006497,
  },
  {
    name: "Charlestown",
    state_id: "MD",
    lat: 39.579,
    lng: -75.9812,
    id: 1840005637,
  },
  {
    name: "Broxton",
    state_id: "GA",
    lat: 31.6248,
    lng: -82.8877,
    id: 1840013858,
  },
  {
    name: "Crocker",
    state_id: "WA",
    lat: 47.0843,
    lng: -122.1225,
    id: 1840041924,
  },
  {
    name: "Haliimaile",
    state_id: "HI",
    lat: 20.8671,
    lng: -156.3381,
    id: 1840029475,
  },
  {
    name: "Ashaway",
    state_id: "RI",
    lat: 41.4259,
    lng: -71.7852,
    id: 1840003362,
  },
  {
    name: "Kenton",
    state_id: "TN",
    lat: 36.2021,
    lng: -89.0155,
    id: 1840016067,
  },
  {
    name: "Ladd",
    state_id: "IL",
    lat: 41.3814,
    lng: -89.2154,
    id: 1840011569,
  },
  {
    name: "Brightwood",
    state_id: "VA",
    lat: 38.4132,
    lng: -78.188,
    id: 1840026738,
  },
  {
    name: "St. Martins",
    state_id: "MO",
    lat: 38.5943,
    lng: -92.3312,
    id: 1840009633,
  },
  {
    name: "Grizzly Flats",
    state_id: "CA",
    lat: 38.6357,
    lng: -120.5355,
    id: 1840042931,
  },
  {
    name: "Laurel Hill",
    state_id: "NC",
    lat: 34.8102,
    lng: -79.546,
    id: 1840024990,
  },
  {
    name: "Lincoln",
    state_id: "MO",
    lat: 38.394,
    lng: -93.3313,
    id: 1840009800,
  },
  {
    name: "Waverly",
    state_id: "IL",
    lat: 39.5925,
    lng: -89.9528,
    id: 1840010590,
  },
  {
    name: "Woodbury Center",
    state_id: "CT",
    lat: 41.5445,
    lng: -73.2047,
    id: 1840034972,
  },
  {
    name: "Drain",
    state_id: "OR",
    lat: 43.662,
    lng: -123.3142,
    id: 1840018640,
  },
  {
    name: "Hoxie",
    state_id: "KS",
    lat: 39.3558,
    lng: -100.4397,
    id: 1840007348,
  },
  {
    name: "Harriston",
    state_id: "VA",
    lat: 38.212,
    lng: -78.8283,
    id: 1840026752,
  },
  {
    name: "St. Anne",
    state_id: "IL",
    lat: 41.023,
    lng: -87.7179,
    id: 1840011701,
  },
  {
    name: "Gilbert Creek",
    state_id: "WV",
    lat: 37.5697,
    lng: -81.8906,
    id: 1840006357,
  },
  {
    name: "Atoka",
    state_id: "NM",
    lat: 32.7783,
    lng: -104.3931,
    id: 1840025093,
  },
  {
    name: "Shullsburg",
    state_id: "WI",
    lat: 42.5733,
    lng: -90.2348,
    id: 1840002497,
  },
  {
    name: "Oyster Creek",
    state_id: "TX",
    lat: 28.9978,
    lng: -95.3282,
    id: 1840020978,
  },
  {
    name: "Plainview",
    state_id: "NE",
    lat: 42.3534,
    lng: -97.7871,
    id: 1840009136,
  },
  {
    name: "Elwood",
    state_id: "KS",
    lat: 39.7518,
    lng: -94.8889,
    id: 1840008430,
  },
  {
    name: "Maricopa",
    state_id: "CA",
    lat: 35.0516,
    lng: -119.4054,
    id: 1840020418,
  },
  {
    name: "Tyhee",
    state_id: "ID",
    lat: 42.9552,
    lng: -112.4563,
    id: 1840139285,
  },
  {
    name: "Plymptonville",
    state_id: "PA",
    lat: 41.0426,
    lng: -78.4466,
    id: 1840035434,
  },
  {
    name: "Hanna City",
    state_id: "IL",
    lat: 40.6935,
    lng: -89.8058,
    id: 1840011922,
  },
  {
    name: "Franklin Springs",
    state_id: "GA",
    lat: 34.284,
    lng: -83.1459,
    id: 1840013608,
  },
  {
    name: "Edmore",
    state_id: "MI",
    lat: 43.4073,
    lng: -85.0367,
    id: 1840010992,
  },
  {
    name: "French Settlement",
    state_id: "LA",
    lat: 30.3159,
    lng: -90.8033,
    id: 1840018228,
  },
  {
    name: "Rincón",
    state_id: "PR",
    lat: 18.3389,
    lng: -67.2515,
    id: 1630035560,
  },
  {
    name: "Grand Meadow",
    state_id: "MN",
    lat: 43.7062,
    lng: -92.5702,
    id: 1840007935,
  },
  {
    name: "Rock Port",
    state_id: "MO",
    lat: 40.4109,
    lng: -95.5332,
    id: 1840009414,
  },
  {
    name: "Sidney",
    state_id: "IL",
    lat: 40.025,
    lng: -88.0721,
    id: 1840012237,
  },
  {
    name: "Ashland",
    state_id: "IL",
    lat: 39.8885,
    lng: -90.0079,
    id: 1840012415,
  },
  {
    name: "Loretto",
    state_id: "PA",
    lat: 40.5086,
    lng: -78.635,
    id: 1840001119,
  },
  {
    name: "Helena",
    state_id: "MS",
    lat: 30.4881,
    lng: -88.5056,
    id: 1840013931,
  },
  {
    name: "Minier",
    state_id: "IL",
    lat: 40.4337,
    lng: -89.314,
    id: 1840011990,
  },
  {
    name: "Stearns",
    state_id: "KY",
    lat: 36.6957,
    lng: -84.4769,
    id: 1840013283,
  },
  {
    name: "Blue Jay",
    state_id: "OH",
    lat: 39.2308,
    lng: -84.7443,
    id: 1840034246,
  },
  {
    name: "Lake Michigan Beach",
    state_id: "MI",
    lat: 42.2127,
    lng: -86.376,
    id: 1840004698,
  },
  {
    name: "Gretna",
    state_id: "VA",
    lat: 36.9492,
    lng: -79.3646,
    id: 1840006493,
  },
  {
    name: "Toledo",
    state_id: "IL",
    lat: 39.2729,
    lng: -88.2419,
    id: 1840012692,
  },
  {
    name: "Velva",
    state_id: "ND",
    lat: 48.0582,
    lng: -100.9329,
    id: 1840000060,
  },
  {
    name: "Weston Mills",
    state_id: "NY",
    lat: 42.0752,
    lng: -78.3727,
    id: 1840004587,
  },
  {
    name: "Kensington",
    state_id: "NY",
    lat: 40.7931,
    lng: -73.7221,
    id: 1840005298,
  },
  {
    name: "Sulphur Springs",
    state_id: "AR",
    lat: 34.1708,
    lng: -92.1299,
    id: 1840145348,
  },
  {
    name: "Port Byron",
    state_id: "NY",
    lat: 43.0366,
    lng: -76.6263,
    id: 1840004241,
  },
  {
    name: "Renville",
    state_id: "MN",
    lat: 44.7908,
    lng: -95.2048,
    id: 1840008953,
  },
  {
    name: "Irvington",
    state_id: "KY",
    lat: 37.8779,
    lng: -86.2846,
    id: 1840014328,
  },
  {
    name: "Dalton",
    state_id: "PA",
    lat: 41.5382,
    lng: -75.739,
    id: 1840003379,
  },
  {
    name: "Como",
    state_id: "MS",
    lat: 34.5134,
    lng: -89.9391,
    id: 1840016629,
  },
  {
    name: "Muniz",
    state_id: "TX",
    lat: 26.2578,
    lng: -98.0897,
    id: 1840037065,
  },
  {
    name: "Logan Elm Village",
    state_id: "OH",
    lat: 39.5705,
    lng: -82.9471,
    id: 1840034362,
  },
  {
    name: "Haven",
    state_id: "KS",
    lat: 37.9016,
    lng: -97.7831,
    id: 1840007540,
  },
  {
    name: "Many Farms",
    state_id: "AZ",
    lat: 36.3532,
    lng: -109.6271,
    id: 1840017674,
  },
  {
    name: "Dilkon",
    state_id: "AZ",
    lat: 35.3606,
    lng: -110.3155,
    id: 1840018960,
  },
  {
    name: "Franklinville",
    state_id: "NC",
    lat: 35.7421,
    lng: -79.6893,
    id: 1840016253,
  },
  {
    name: "Kent City",
    state_id: "MI",
    lat: 43.2203,
    lng: -85.7539,
    id: 1840011004,
  },
  {
    name: "Clay",
    state_id: "CA",
    lat: 38.314,
    lng: -121.1596,
    id: 1840022491,
  },
  {
    name: "East Sumter",
    state_id: "SC",
    lat: 33.9261,
    lng: -80.295,
    id: 1840035842,
  },
  {
    name: "Battle Creek",
    state_id: "NE",
    lat: 41.9981,
    lng: -97.5994,
    id: 1840007036,
  },
  {
    name: "Richfield Springs",
    state_id: "NY",
    lat: 42.8537,
    lng: -74.9862,
    id: 1840004457,
  },
  {
    name: "Tornado",
    state_id: "WV",
    lat: 38.3369,
    lng: -81.8551,
    id: 1840133782,
  },
  {
    name: "Boswell",
    state_id: "PA",
    lat: 40.1615,
    lng: -79.0275,
    id: 1840003732,
  },
  {
    name: "Silver Grove",
    state_id: "KY",
    lat: 39.0404,
    lng: -84.3996,
    id: 1840015169,
  },
  {
    name: "Harris",
    state_id: "MN",
    lat: 45.5976,
    lng: -92.9869,
    id: 1840006694,
  },
  {
    name: "Malaga",
    state_id: "CA",
    lat: 36.6816,
    lng: -119.7318,
    id: 1840017634,
  },
  {
    name: "Mariposa",
    state_id: "CA",
    lat: 37.4873,
    lng: -119.9682,
    id: 1840017622,
  },
  {
    name: "Saucier",
    state_id: "MS",
    lat: 30.6239,
    lng: -89.1432,
    id: 1840013945,
  },
  {
    name: "Kingston",
    state_id: "NJ",
    lat: 40.3816,
    lng: -74.6114,
    id: 1840033313,
  },
  {
    name: "El Rancho",
    state_id: "NM",
    lat: 35.8928,
    lng: -106.0856,
    id: 1840017769,
  },
  {
    name: "Hebron",
    state_id: "IL",
    lat: 42.4649,
    lng: -88.4343,
    id: 1840011132,
  },
  {
    name: "Curtisville",
    state_id: "PA",
    lat: 40.6497,
    lng: -79.8492,
    id: 1840005394,
  },
  {
    name: "Chatom",
    state_id: "AL",
    lat: 31.4691,
    lng: -88.246,
    id: 1840001112,
  },
  {
    name: "Cornwells Heights",
    state_id: "PA",
    lat: 40.0771,
    lng: -74.9516,
    id: 1840035324,
  },
  {
    name: "Hayes",
    state_id: "LA",
    lat: 30.1112,
    lng: -92.9236,
    id: 1840026021,
  },
  {
    name: "Georgetown",
    state_id: "PA",
    lat: 39.9426,
    lng: -76.0789,
    id: 1840146109,
  },
  {
    name: "New Richland",
    state_id: "MN",
    lat: 43.8938,
    lng: -93.4939,
    id: 1840007914,
  },
  {
    name: "Howey-in-the-Hills",
    state_id: "FL",
    lat: 28.7098,
    lng: -81.7798,
    id: 1840028859,
  },
  {
    name: "West Fairview",
    state_id: "PA",
    lat: 40.2777,
    lng: -76.9195,
    id: 1840035252,
  },
  {
    name: "Anderson Island",
    state_id: "WA",
    lat: 47.1579,
    lng: -122.7041,
    id: 1840041888,
  },
  {
    name: "Lincoln Center",
    state_id: "KS",
    lat: 39.0429,
    lng: -98.1468,
    id: 1840073795,
  },
  {
    name: "Valley Mills",
    state_id: "TX",
    lat: 31.6582,
    lng: -97.4714,
    id: 1840022123,
  },
  {
    name: "New Waterford",
    state_id: "OH",
    lat: 40.8478,
    lng: -80.6188,
    id: 1840011935,
  },
  {
    name: "Apple Creek",
    state_id: "OH",
    lat: 40.7486,
    lng: -81.8333,
    id: 1840010349,
  },
  {
    name: "Brodhead",
    state_id: "KY",
    lat: 37.4027,
    lng: -84.4144,
    id: 1840013241,
  },
  {
    name: "Axis",
    state_id: "AL",
    lat: 30.9296,
    lng: -88.0179,
    id: 1840025979,
  },
  {
    name: "Westfield Center",
    state_id: "OH",
    lat: 41.0282,
    lng: -81.9314,
    id: 1840011716,
  },
  {
    name: "Oakland",
    state_id: "NE",
    lat: 41.8356,
    lng: -96.4668,
    id: 1840000501,
  },
  {
    name: "Beaverton",
    state_id: "MI",
    lat: 43.8811,
    lng: -84.4887,
    id: 1840002563,
  },
  {
    name: "Baltic",
    state_id: "SD",
    lat: 43.7603,
    lng: -96.7371,
    id: 1840002651,
  },
  {
    name: "Comer",
    state_id: "GA",
    lat: 34.0625,
    lng: -83.1263,
    id: 1840014726,
  },
  {
    name: "Holly Hill",
    state_id: "SC",
    lat: 33.3245,
    lng: -80.413,
    id: 1840016818,
  },
  {
    name: "Bear Creek",
    state_id: "AK",
    lat: 60.2071,
    lng: -149.3524,
    id: 1840023650,
  },
  {
    name: "Marion",
    state_id: "WI",
    lat: 44.6744,
    lng: -88.8902,
    id: 1840002360,
  },
  {
    name: "Chico",
    state_id: "TX",
    lat: 33.2961,
    lng: -97.7986,
    id: 1840019382,
  },
  {
    name: "Quinnesec",
    state_id: "MI",
    lat: 45.803,
    lng: -87.9958,
    id: 1840004008,
  },
  {
    name: "Coto Norte",
    state_id: "PR",
    lat: 18.4328,
    lng: -66.4409,
    id: 1630035657,
  },
  {
    name: "Hebron Estates",
    state_id: "KY",
    lat: 38.0501,
    lng: -85.6682,
    id: 1840014324,
  },
  {
    name: "Williamsburg",
    state_id: "PA",
    lat: 40.4627,
    lng: -78.2045,
    id: 1840001088,
  },
  {
    name: "Goodhue",
    state_id: "MN",
    lat: 44.4007,
    lng: -92.6255,
    id: 1840007869,
  },
  {
    name: "Memphis",
    state_id: "MI",
    lat: 42.8956,
    lng: -82.7689,
    id: 1840002422,
  },
  {
    name: "Bray",
    state_id: "OK",
    lat: 34.616,
    lng: -97.8207,
    id: 1840021886,
  },
  {
    name: "Krotz Springs",
    state_id: "LA",
    lat: 30.5323,
    lng: -91.7518,
    id: 1840017170,
  },
  {
    name: "Knightsen",
    state_id: "CA",
    lat: 37.9633,
    lng: -121.6511,
    id: 1840017614,
  },
  {
    name: "Claypool",
    state_id: "AZ",
    lat: 33.406,
    lng: -110.8421,
    id: 1840017944,
  },
  {
    name: "Buchanan",
    state_id: "GA",
    lat: 33.803,
    lng: -85.1803,
    id: 1840013716,
  },
  {
    name: "Hurley",
    state_id: "NM",
    lat: 32.6982,
    lng: -108.1321,
    id: 1840022035,
  },
  {
    name: "St. John",
    state_id: "KS",
    lat: 38,
    lng: -98.7611,
    id: 1840009832,
  },
  {
    name: "Reno",
    state_id: "OH",
    lat: 39.3769,
    lng: -81.3932,
    id: 1840026639,
  },
  {
    name: "Stanberry",
    state_id: "MO",
    lat: 40.2166,
    lng: -94.5381,
    id: 1840009436,
  },
  {
    name: "Oologah",
    state_id: "OK",
    lat: 36.4436,
    lng: -95.7085,
    id: 1840022653,
  },
  {
    name: "Palenville",
    state_id: "NY",
    lat: 42.1773,
    lng: -74.0229,
    id: 1840004642,
  },
  {
    name: "Bowling Green",
    state_id: "VA",
    lat: 38.0535,
    lng: -77.3473,
    id: 1840006303,
  },
  {
    name: "Tatamy",
    state_id: "PA",
    lat: 40.7409,
    lng: -75.2561,
    id: 1840000981,
  },
  {
    name: "Sabana Eneas",
    state_id: "PR",
    lat: 18.0809,
    lng: -67.0852,
    id: 1630035566,
  },
  {
    name: "Vienna",
    state_id: "NJ",
    lat: 40.8698,
    lng: -74.881,
    id: 1840024233,
  },
  {
    name: "Boley",
    state_id: "OK",
    lat: 35.4903,
    lng: -96.4793,
    id: 1840021753,
  },
  {
    name: "Buchanan",
    state_id: "VA",
    lat: 37.5211,
    lng: -79.6894,
    id: 1840006379,
  },
  {
    name: "Madison Lake",
    state_id: "MN",
    lat: 44.2044,
    lng: -93.8162,
    id: 1840008974,
  },
  {
    name: "Shell Valley",
    state_id: "ND",
    lat: 48.8108,
    lng: -99.8273,
    id: 1840033127,
  },
  {
    name: "Lemmon",
    state_id: "SD",
    lat: 45.9385,
    lng: -102.1589,
    id: 1840002014,
  },
  {
    name: "Dryden",
    state_id: "VA",
    lat: 36.7764,
    lng: -82.9433,
    id: 1840006531,
  },
  {
    name: "Lowell",
    state_id: "OR",
    lat: 43.9209,
    lng: -122.7819,
    id: 1840020009,
  },
  {
    name: "Jeffersonville",
    state_id: "OH",
    lat: 39.6522,
    lng: -83.555,
    id: 1840012573,
  },
  {
    name: "Ama",
    state_id: "LA",
    lat: 29.944,
    lng: -90.2967,
    id: 1840013975,
  },
  {
    name: "Springmont",
    state_id: "PA",
    lat: 40.3274,
    lng: -76.0043,
    id: 1840035206,
  },
  {
    name: "Milford",
    state_id: "IL",
    lat: 40.6276,
    lng: -87.6963,
    id: 1840011862,
  },
  {
    name: "Christiana",
    state_id: "PA",
    lat: 39.9545,
    lng: -75.997,
    id: 1840003712,
  },
  {
    name: "Woodacre",
    state_id: "CA",
    lat: 38.0052,
    lng: -122.6384,
    id: 1840018889,
  },
  {
    name: "Holland",
    state_id: "TX",
    lat: 30.8834,
    lng: -97.4026,
    id: 1840022157,
  },
  {
    name: "Duboistown",
    state_id: "PA",
    lat: 41.2228,
    lng: -77.038,
    id: 1840000628,
  },
  {
    name: "Animas",
    state_id: "PR",
    lat: 18.4457,
    lng: -66.6365,
    id: 1630035611,
  },
  {
    name: "Coburg",
    state_id: "OR",
    lat: 44.1393,
    lng: -123.0588,
    id: 1840018622,
  },
  {
    name: "Wisner",
    state_id: "NE",
    lat: 41.9878,
    lng: -96.9133,
    id: 1840003223,
  },
  {
    name: "Frankston",
    state_id: "TX",
    lat: 32.0561,
    lng: -95.5043,
    id: 1840022131,
  },
  {
    name: "Cuba",
    state_id: "IL",
    lat: 40.4936,
    lng: -90.1933,
    id: 1840007211,
  },
  {
    name: "New Summerfield",
    state_id: "TX",
    lat: 31.9807,
    lng: -95.115,
    id: 1840020791,
  },
  {
    name: "Farm Loop",
    state_id: "AK",
    lat: 61.6394,
    lng: -149.1471,
    id: 1840075075,
  },
  {
    name: "Little Rock",
    state_id: "MN",
    lat: 47.8667,
    lng: -95.106,
    id: 1840032019,
  },
  {
    name: "Marion",
    state_id: "PA",
    lat: 39.8607,
    lng: -77.7016,
    id: 1840026530,
  },
  {
    name: "Silverhill",
    state_id: "AL",
    lat: 30.5455,
    lng: -87.7505,
    id: 1840018194,
  },
  {
    name: "Rose Lodge",
    state_id: "OR",
    lat: 45.0187,
    lng: -123.8881,
    id: 1840018592,
  },
  {
    name: "Duncan Falls",
    state_id: "OH",
    lat: 39.8777,
    lng: -81.9112,
    id: 1840026552,
  },
  {
    name: "Nicollet",
    state_id: "MN",
    lat: 44.2747,
    lng: -94.1885,
    id: 1840007895,
  },
  {
    name: "Yosemite Valley",
    state_id: "CA",
    lat: 37.7407,
    lng: -119.5779,
    id: 1840074769,
  },
  {
    name: "Kingston",
    state_id: "IL",
    lat: 42.1008,
    lng: -88.7619,
    id: 1840011335,
  },
  {
    name: "Terrytown",
    state_id: "NE",
    lat: 41.8465,
    lng: -103.6705,
    id: 1840011397,
  },
  {
    name: "Mooreland",
    state_id: "OK",
    lat: 36.4379,
    lng: -99.2045,
    id: 1840022645,
  },
  {
    name: "Yamhill",
    state_id: "OR",
    lat: 45.3412,
    lng: -123.1851,
    id: 1840021222,
  },
  {
    name: "Princeton",
    state_id: "WI",
    lat: 43.8526,
    lng: -89.1305,
    id: 1840002593,
  },
  {
    name: "Hometown",
    state_id: "PA",
    lat: 40.8216,
    lng: -75.9865,
    id: 1840005216,
  },
  {
    name: "Paris",
    state_id: "MO",
    lat: 39.4773,
    lng: -92.0039,
    id: 1840009565,
  },
  {
    name: "Roseboro",
    state_id: "NC",
    lat: 34.9536,
    lng: -78.5121,
    id: 1840017873,
  },
  {
    name: "Factoryville",
    state_id: "PA",
    lat: 41.5602,
    lng: -75.7822,
    id: 1840000576,
  },
  {
    name: "Hawi",
    state_id: "HI",
    lat: 20.2392,
    lng: -155.8305,
    id: 1840029480,
  },
  {
    name: "Fountain Green",
    state_id: "UT",
    lat: 39.6281,
    lng: -111.6413,
    id: 1840020204,
  },
  {
    name: "Clarksville",
    state_id: "VA",
    lat: 36.6172,
    lng: -78.5639,
    id: 1840006539,
  },
  {
    name: "Stiles",
    state_id: "PA",
    lat: 40.6666,
    lng: -75.5072,
    id: 1840035209,
  },
  {
    name: "Leadwood",
    state_id: "MO",
    lat: 37.8618,
    lng: -90.5892,
    id: 1840008689,
  },
  {
    name: "North Ballston Spa",
    state_id: "NY",
    lat: 43.0197,
    lng: -73.851,
    id: 1840073640,
  },
  {
    name: "Bellefonte",
    state_id: "DE",
    lat: 39.7669,
    lng: -75.4982,
    id: 1840005580,
  },
  {
    name: "West Mayfield",
    state_id: "PA",
    lat: 40.7802,
    lng: -80.3387,
    id: 1840001032,
  },
  {
    name: "Central High",
    state_id: "OK",
    lat: 34.617,
    lng: -98.0892,
    id: 1840022829,
  },
  {
    name: "Goodman",
    state_id: "MS",
    lat: 32.9677,
    lng: -89.9128,
    id: 1840016880,
  },
  {
    name: "Sunnyside-Tahoe City",
    state_id: "CA",
    lat: 39.1504,
    lng: -120.1613,
    id: 1840037346,
  },
  {
    name: "Wiggins",
    state_id: "CO",
    lat: 40.2286,
    lng: -104.0724,
    id: 1840022420,
  },
  {
    name: "Duncansville",
    state_id: "PA",
    lat: 40.4263,
    lng: -78.4303,
    id: 1840001082,
  },
  {
    name: "Bellerose",
    state_id: "NY",
    lat: 40.7242,
    lng: -73.7167,
    id: 1840005337,
  },
  {
    name: "Pleasanton",
    state_id: "KS",
    lat: 38.179,
    lng: -94.7053,
    id: 1840009748,
  },
  {
    name: "Hyde",
    state_id: "PA",
    lat: 41.0075,
    lng: -78.4688,
    id: 1840005150,
  },
  {
    name: "Boling",
    state_id: "TX",
    lat: 29.2584,
    lng: -95.9436,
    id: 1840025142,
  },
  {
    name: "Dutch Island",
    state_id: "GA",
    lat: 32.0047,
    lng: -81.0317,
    id: 1840039166,
  },
  {
    name: "Burwell",
    state_id: "NE",
    lat: 41.78,
    lng: -99.1341,
    id: 1840007037,
  },
  {
    name: "Coldstream",
    state_id: "KY",
    lat: 38.3144,
    lng: -85.5264,
    id: 1840030795,
  },
  {
    name: "Cascade Locks",
    state_id: "OR",
    lat: 45.6763,
    lng: -121.8719,
    id: 1840018561,
  },
  {
    name: "Ucon",
    state_id: "ID",
    lat: 43.5935,
    lng: -111.9595,
    id: 1840021281,
  },
  {
    name: "Toulon",
    state_id: "IL",
    lat: 41.0935,
    lng: -89.8633,
    id: 1840010303,
  },
  {
    name: "West Crossett",
    state_id: "AR",
    lat: 33.1488,
    lng: -92.0221,
    id: 1840013770,
  },
  {
    name: "Marshallton",
    state_id: "PA",
    lat: 40.7853,
    lng: -76.5366,
    id: 1840133638,
  },
  {
    name: "Lecompte",
    state_id: "LA",
    lat: 31.0881,
    lng: -92.3999,
    id: 1840017099,
  },
  {
    name: "Sibley",
    state_id: "LA",
    lat: 32.5423,
    lng: -93.2947,
    id: 1840018061,
  },
  {
    name: "Millbourne",
    state_id: "PA",
    lat: 39.9638,
    lng: -75.2527,
    id: 1840000703,
  },
  {
    name: "Ehrenberg",
    state_id: "AZ",
    lat: 33.6174,
    lng: -114.4883,
    id: 1840017958,
  },
  {
    name: "Homer",
    state_id: "IL",
    lat: 40.0335,
    lng: -87.9578,
    id: 1840012223,
  },
  {
    name: "Pine Bluffs",
    state_id: "WY",
    lat: 41.1803,
    lng: -104.0692,
    id: 1840022387,
  },
  {
    name: "Pine Island",
    state_id: "TX",
    lat: 30.0554,
    lng: -96.0253,
    id: 1840023150,
  },
  {
    name: "Albany",
    state_id: "LA",
    lat: 30.4996,
    lng: -90.584,
    id: 1840018227,
  },
  {
    name: "Hiram",
    state_id: "OH",
    lat: 41.3113,
    lng: -81.1429,
    id: 1840011674,
  },
  {
    name: "Ponderosa Pine",
    state_id: "NM",
    lat: 34.972,
    lng: -106.3369,
    id: 1840024972,
  },
  {
    name: "Wellsville",
    state_id: "MO",
    lat: 39.0734,
    lng: -91.5692,
    id: 1840010699,
  },
  {
    name: "Bratenahl",
    state_id: "OH",
    lat: 41.5546,
    lng: -81.6046,
    id: 1840000589,
  },
  {
    name: "Doña Ana",
    state_id: "NM",
    lat: 32.394,
    lng: -106.8177,
    id: 1840019409,
  },
  {
    name: "Atwood",
    state_id: "IL",
    lat: 39.7992,
    lng: -88.4626,
    id: 1840012507,
  },
  {
    name: "Tahoe Vista",
    state_id: "CA",
    lat: 39.2483,
    lng: -120.0535,
    id: 1840018817,
  },
  {
    name: "Malta",
    state_id: "IL",
    lat: 41.9291,
    lng: -88.867,
    id: 1840011338,
  },
  {
    name: "Long Beach",
    state_id: "IN",
    lat: 41.7435,
    lng: -86.8521,
    id: 1840029976,
  },
  {
    name: "Conehatta",
    state_id: "MS",
    lat: 32.4601,
    lng: -89.2785,
    id: 1840013102,
  },
  {
    name: "Botkins",
    state_id: "OH",
    lat: 40.4587,
    lng: -84.1782,
    id: 1840012190,
  },
  {
    name: "Colfax",
    state_id: "WI",
    lat: 44.997,
    lng: -91.7264,
    id: 1840002144,
  },
  {
    name: "Lake Secession",
    state_id: "SC",
    lat: 34.2821,
    lng: -82.5908,
    id: 1840035839,
  },
  {
    name: "Colon",
    state_id: "MI",
    lat: 41.9592,
    lng: -85.3229,
    id: 1840011382,
  },
  {
    name: "Advance",
    state_id: "NC",
    lat: 35.9468,
    lng: -80.4045,
    id: 1840024909,
  },
  {
    name: "Palm Shores",
    state_id: "FL",
    lat: 28.1918,
    lng: -80.6593,
    id: 1840017236,
  },
  {
    name: "Mapleton",
    state_id: "IA",
    lat: 42.1671,
    lng: -95.7904,
    id: 1840009154,
  },
  {
    name: "Leetsdale",
    state_id: "PA",
    lat: 40.5656,
    lng: -80.2142,
    id: 1840001240,
  },
  {
    name: "Ponderay",
    state_id: "ID",
    lat: 48.3091,
    lng: -116.539,
    id: 1840019771,
  },
  {
    name: "Coal Fork",
    state_id: "WV",
    lat: 38.3185,
    lng: -81.5229,
    id: 1840006189,
  },
  {
    name: "Panaca",
    state_id: "NV",
    lat: 37.7889,
    lng: -114.3997,
    id: 1840024651,
  },
  {
    name: "Yellville",
    state_id: "AR",
    lat: 36.2289,
    lng: -92.686,
    id: 1840015300,
  },
  {
    name: "Oglethorpe",
    state_id: "GA",
    lat: 32.2935,
    lng: -84.0623,
    id: 1840014899,
  },
  {
    name: "Remington",
    state_id: "IN",
    lat: 40.765,
    lng: -87.1556,
    id: 1840010292,
  },
  {
    name: "Hettinger",
    state_id: "ND",
    lat: 46.0034,
    lng: -102.635,
    id: 1840000243,
  },
  {
    name: "Emory",
    state_id: "VA",
    lat: 36.78,
    lng: -81.828,
    id: 1840024856,
  },
  {
    name: "Midway",
    state_id: "LA",
    lat: 31.6788,
    lng: -92.1493,
    id: 1840013852,
  },
  {
    name: "Fort Deposit",
    state_id: "AL",
    lat: 31.9863,
    lng: -86.5664,
    id: 1840002146,
  },
  {
    name: "Tresckow",
    state_id: "PA",
    lat: 40.916,
    lng: -75.9657,
    id: 1840005187,
  },
  {
    name: "Dulac",
    state_id: "LA",
    lat: 29.3864,
    lng: -90.7081,
    id: 1840013116,
  },
  {
    name: "Hastings",
    state_id: "PA",
    lat: 40.6647,
    lng: -78.7094,
    id: 1840001115,
  },
  {
    name: "Lake Lure",
    state_id: "NC",
    lat: 35.4404,
    lng: -82.2064,
    id: 1840016318,
  },
  {
    name: "Lamar",
    state_id: "TX",
    lat: 28.1439,
    lng: -96.9888,
    id: 1840026054,
  },
  {
    name: "West Chatham",
    state_id: "MA",
    lat: 41.6804,
    lng: -69.9918,
    id: 1840003243,
  },
  {
    name: "Frisco City",
    state_id: "AL",
    lat: 31.4353,
    lng: -87.4037,
    id: 1840005003,
  },
  {
    name: "Port Orford",
    state_id: "OR",
    lat: 42.7497,
    lng: -124.4973,
    id: 1840020079,
  },
  {
    name: "Valley Falls",
    state_id: "KS",
    lat: 39.3408,
    lng: -95.4612,
    id: 1840010659,
  },
  {
    name: "Hawley",
    state_id: "PA",
    lat: 41.4765,
    lng: -75.1779,
    id: 1840003313,
  },
  {
    name: "Ship Bottom",
    state_id: "NJ",
    lat: 39.6459,
    lng: -74.1835,
    id: 1840001489,
  },
  {
    name: "Elbow Lake",
    state_id: "MN",
    lat: 45.9905,
    lng: -95.9776,
    id: 1840007766,
  },
  {
    name: "Taos",
    state_id: "MO",
    lat: 38.5157,
    lng: -92.0647,
    id: 1840009796,
  },
  {
    name: "Lyles",
    state_id: "TN",
    lat: 35.9195,
    lng: -87.345,
    id: 1840024919,
  },
  {
    name: "Ohatchee",
    state_id: "AL",
    lat: 33.7877,
    lng: -86.028,
    id: 1840016782,
  },
  {
    name: "Towaoc",
    state_id: "CO",
    lat: 37.2126,
    lng: -108.7265,
    id: 1840018927,
  },
  {
    name: "Elkhorn",
    state_id: "CA",
    lat: 36.8103,
    lng: -121.7212,
    id: 1840017683,
  },
  {
    name: "Cloverport",
    state_id: "KY",
    lat: 37.8312,
    lng: -86.6304,
    id: 1840014327,
  },
  {
    name: "Ranchos Penitas West",
    state_id: "TX",
    lat: 27.6745,
    lng: -99.6035,
    id: 1840037074,
  },
  {
    name: "Grabill",
    state_id: "IN",
    lat: 41.2098,
    lng: -84.9681,
    id: 1840010296,
  },
  {
    name: "Clarkton",
    state_id: "MO",
    lat: 36.4521,
    lng: -89.9681,
    id: 1840007662,
  },
  {
    name: "Lucama",
    state_id: "NC",
    lat: 35.6447,
    lng: -78.0072,
    id: 1840016260,
  },
  {
    name: "Julesburg",
    state_id: "CO",
    lat: 40.9849,
    lng: -102.2627,
    id: 1840021381,
  },
  {
    name: "Republic",
    state_id: "PA",
    lat: 39.9664,
    lng: -79.8767,
    id: 1840005541,
  },
  {
    name: "Webster",
    state_id: "FL",
    lat: 28.6115,
    lng: -82.0509,
    id: 1840015955,
  },
  {
    name: "Windsor",
    state_id: "IL",
    lat: 39.4389,
    lng: -88.5953,
    id: 1840010613,
  },
  {
    name: "Norris City",
    state_id: "IL",
    lat: 37.979,
    lng: -88.3279,
    id: 1840012926,
  },
  {
    name: "San Antonito",
    state_id: "NM",
    lat: 35.1575,
    lng: -106.3476,
    id: 1840138585,
  },
  {
    name: "Griggsville",
    state_id: "IL",
    lat: 39.7078,
    lng: -90.7275,
    id: 1840007315,
  },
  {
    name: "Great Neck Gardens",
    state_id: "NY",
    lat: 40.7969,
    lng: -73.7229,
    id: 1840034034,
  },
  {
    name: "Whitehall",
    state_id: "MT",
    lat: 45.8709,
    lng: -112.0975,
    id: 1840022326,
  },
  {
    name: "Navarre Beach",
    state_id: "FL",
    lat: 30.383,
    lng: -86.8879,
    id: 1840039030,
  },
  {
    name: "Barnsdall",
    state_id: "OK",
    lat: 36.5609,
    lng: -96.1626,
    id: 1840018975,
  },
  {
    name: "Southmont",
    state_id: "NC",
    lat: 35.6536,
    lng: -80.2702,
    id: 1840025789,
  },
  {
    name: "Nortonville",
    state_id: "KY",
    lat: 37.186,
    lng: -87.4556,
    id: 1840014370,
  },
  {
    name: "Lindsay",
    state_id: "TX",
    lat: 33.6415,
    lng: -97.2186,
    id: 1840021961,
  },
  {
    name: "Oxford",
    state_id: "IN",
    lat: 40.5214,
    lng: -87.2482,
    id: 1840014073,
  },
  {
    name: "Monongah",
    state_id: "WV",
    lat: 39.4598,
    lng: -80.2185,
    id: 1840005736,
  },
  {
    name: "Yarrow Point",
    state_id: "WA",
    lat: 47.6488,
    lng: -122.2188,
    id: 1840022300,
  },
  {
    name: "Big River",
    state_id: "CA",
    lat: 34.1393,
    lng: -114.3604,
    id: 1840017798,
  },
  {
    name: "Fruitport",
    state_id: "MI",
    lat: 43.1248,
    lng: -86.1561,
    id: 1840010988,
  },
  {
    name: "Banner Elk",
    state_id: "NC",
    lat: 36.1588,
    lng: -81.8677,
    id: 1840015320,
  },
  {
    name: "Riverside",
    state_id: "ID",
    lat: 43.1965,
    lng: -112.4356,
    id: 1840024025,
  },
  {
    name: "Helena Flats",
    state_id: "MT",
    lat: 48.2864,
    lng: -114.2369,
    id: 1840039682,
  },
  {
    name: "Augusta",
    state_id: "KY",
    lat: 38.7723,
    lng: -84.0003,
    id: 1840013176,
  },
  {
    name: "Mineral Springs",
    state_id: "AR",
    lat: 33.8767,
    lng: -93.9218,
    id: 1840014708,
  },
  {
    name: "Bingham Farms",
    state_id: "MI",
    lat: 42.5176,
    lng: -83.278,
    id: 1840011053,
  },
  {
    name: "Andalusia",
    state_id: "IL",
    lat: 41.4374,
    lng: -90.7165,
    id: 1840010192,
  },
  {
    name: "Bronson",
    state_id: "FL",
    lat: 29.4501,
    lng: -82.6391,
    id: 1840015948,
  },
  {
    name: "Reedsville",
    state_id: "WI",
    lat: 44.1525,
    lng: -87.9517,
    id: 1840002216,
  },
  {
    name: "Garretson",
    state_id: "SD",
    lat: 43.7149,
    lng: -96.5026,
    id: 1840002652,
  },
  {
    name: "Gilbert",
    state_id: "IA",
    lat: 42.1062,
    lng: -93.6461,
    id: 1840008122,
  },
  {
    name: "Union Springs",
    state_id: "NY",
    lat: 42.8458,
    lng: -76.691,
    id: 1840004242,
  },
  {
    name: "Third Lake",
    state_id: "IL",
    lat: 42.3708,
    lng: -88.0122,
    id: 1840011179,
  },
  {
    name: "Kellyville",
    state_id: "OK",
    lat: 35.9482,
    lng: -96.2143,
    id: 1840021693,
  },
  {
    name: "Bayou Country Club",
    state_id: "LA",
    lat: 29.78,
    lng: -90.7894,
    id: 1840039396,
  },
  {
    name: "Tenaha",
    state_id: "TX",
    lat: 31.944,
    lng: -94.2457,
    id: 1840023114,
  },
  {
    name: "Chester Gap",
    state_id: "VA",
    lat: 38.8534,
    lng: -78.1325,
    id: 1840026718,
  },
  {
    name: "Minturn",
    state_id: "CO",
    lat: 39.5461,
    lng: -106.3873,
    id: 1840022433,
  },
  {
    name: "Alpaugh",
    state_id: "CA",
    lat: 35.8876,
    lng: -119.4873,
    id: 1840017690,
  },
  {
    name: "Grass Lake",
    state_id: "MI",
    lat: 42.2511,
    lng: -84.2075,
    id: 1840011195,
  },
  {
    name: "Hasley Canyon",
    state_id: "CA",
    lat: 34.4817,
    lng: -118.6665,
    id: 1840075914,
  },
  {
    name: "Gorman",
    state_id: "NC",
    lat: 36.0418,
    lng: -78.8091,
    id: 1840013363,
  },
  {
    name: "Athena",
    state_id: "OR",
    lat: 45.8132,
    lng: -118.4924,
    id: 1840018536,
  },
  {
    name: "Elk Run Heights",
    state_id: "IA",
    lat: 42.4662,
    lng: -92.2501,
    id: 1840008062,
  },
  {
    name: "Killdeer",
    state_id: "ND",
    lat: 47.3673,
    lng: -102.7535,
    id: 1840000127,
  },
  {
    name: "Camargo",
    state_id: "KY",
    lat: 37.997,
    lng: -83.8926,
    id: 1840013214,
  },
  {
    name: "Fletcher",
    state_id: "OK",
    lat: 34.8224,
    lng: -98.2391,
    id: 1840021852,
  },
  {
    name: "Boonville",
    state_id: "NC",
    lat: 36.234,
    lng: -80.7093,
    id: 1840015319,
  },
  {
    name: "La Harpe",
    state_id: "IL",
    lat: 40.5848,
    lng: -90.9698,
    id: 1840008342,
  },
  {
    name: "Palo Cedro",
    state_id: "CA",
    lat: 40.5511,
    lng: -122.2363,
    id: 1840018731,
  },
  {
    name: "Redby",
    state_id: "MN",
    lat: 47.8717,
    lng: -94.9184,
    id: 1840003995,
  },
  {
    name: "McKinney Acres",
    state_id: "TX",
    lat: 32.2963,
    lng: -102.5297,
    id: 1840037139,
  },
  {
    name: "Banks Springs",
    state_id: "LA",
    lat: 32.0764,
    lng: -92.085,
    id: 1840025921,
  },
  {
    name: "Mount Gilead",
    state_id: "NC",
    lat: 35.2163,
    lng: -80.0047,
    id: 1840016359,
  },
  {
    name: "Woodworth",
    state_id: "LA",
    lat: 31.1655,
    lng: -92.5096,
    id: 1840017102,
  },
  {
    name: "Villisca",
    state_id: "IA",
    lat: 40.9298,
    lng: -94.9819,
    id: 1840010318,
  },
  {
    name: "Skyland Estates",
    state_id: "VA",
    lat: 38.9329,
    lng: -78.085,
    id: 1840026697,
  },
  {
    name: "Alma",
    state_id: "NE",
    lat: 40.1036,
    lng: -99.3656,
    id: 1840001384,
  },
  {
    name: "Fowler",
    state_id: "CO",
    lat: 38.1304,
    lng: -104.0264,
    id: 1840021522,
  },
  {
    name: "Meeker",
    state_id: "OK",
    lat: 35.4852,
    lng: -96.8951,
    id: 1840022711,
  },
  {
    name: "Elton",
    state_id: "LA",
    lat: 30.4812,
    lng: -92.6976,
    id: 1840017197,
  },
  {
    name: "Bradford Woods",
    state_id: "PA",
    lat: 40.6369,
    lng: -80.0809,
    id: 1840001211,
  },
  {
    name: "Mountain Ranch",
    state_id: "CA",
    lat: 38.2509,
    lng: -120.5119,
    id: 1840018864,
  },
  {
    name: "Nahunta",
    state_id: "GA",
    lat: 31.2065,
    lng: -81.9814,
    id: 1840014972,
  },
  {
    name: "Langley",
    state_id: "WA",
    lat: 48.0337,
    lng: -122.4084,
    id: 1840019780,
  },
  {
    name: "Gerber",
    state_id: "CA",
    lat: 40.0614,
    lng: -122.1482,
    id: 1840026519,
  },
  {
    name: "Light Oak",
    state_id: "NC",
    lat: 35.2867,
    lng: -81.4775,
    id: 1840033017,
  },
  {
    name: "Covelo",
    state_id: "CA",
    lat: 39.8025,
    lng: -123.2499,
    id: 1840017533,
  },
  {
    name: "Kempner",
    state_id: "TX",
    lat: 31.0755,
    lng: -97.9727,
    id: 1840019551,
  },
  {
    name: "Oakland",
    state_id: "OK",
    lat: 34.0975,
    lng: -96.7978,
    id: 1840022894,
  },
  {
    name: "Camanche North Shore",
    state_id: "CA",
    lat: 38.2442,
    lng: -120.9538,
    id: 1840075644,
  },
  {
    name: "Stromsburg",
    state_id: "NE",
    lat: 41.116,
    lng: -97.591,
    id: 1840009311,
  },
  {
    name: "Greenwood",
    state_id: "DE",
    lat: 38.8066,
    lng: -75.5897,
    id: 1840006066,
  },
  {
    name: "Great Bend",
    state_id: "NY",
    lat: 44.0197,
    lng: -75.7075,
    id: 1840004109,
  },
  {
    name: "Washington",
    state_id: "WV",
    lat: 39.2422,
    lng: -81.6679,
    id: 1840005785,
  },
  {
    name: "Marcellus",
    state_id: "MI",
    lat: 42.0261,
    lng: -85.8131,
    id: 1840011387,
  },
  {
    name: "Plains",
    state_id: "MT",
    lat: 47.4602,
    lng: -114.8842,
    id: 1840022285,
  },
  {
    name: "Payne",
    state_id: "OH",
    lat: 41.0797,
    lng: -84.7272,
    id: 1840011728,
  },
  {
    name: "Birchwood Lakes",
    state_id: "PA",
    lat: 41.2513,
    lng: -74.9103,
    id: 1840034863,
  },
  {
    name: "Okeene",
    state_id: "OK",
    lat: 36.1171,
    lng: -98.3165,
    id: 1840022693,
  },
  {
    name: "Runge",
    state_id: "TX",
    lat: 28.8859,
    lng: -97.7134,
    id: 1840022244,
  },
  {
    name: "Lorenzo",
    state_id: "TX",
    lat: 33.6703,
    lng: -101.5361,
    id: 1840020602,
  },
  {
    name: "Weeki Wachee Gardens",
    state_id: "FL",
    lat: 28.5375,
    lng: -82.6208,
    id: 1840029093,
  },
  {
    name: "Southmayd",
    state_id: "TX",
    lat: 33.6233,
    lng: -96.7286,
    id: 1840021954,
  },
  {
    name: "Lapwai",
    state_id: "ID",
    lat: 46.4034,
    lng: -116.8041,
    id: 1840019899,
  },
  {
    name: "Plantersville",
    state_id: "MS",
    lat: 34.2103,
    lng: -88.6655,
    id: 1840016650,
  },
  {
    name: "Springville",
    state_id: "IA",
    lat: 42.0529,
    lng: -91.4458,
    id: 1840009145,
  },
  {
    name: "Andrews",
    state_id: "IN",
    lat: 40.8604,
    lng: -85.6023,
    id: 1840010341,
  },
  {
    name: "Matheny",
    state_id: "CA",
    lat: 36.1707,
    lng: -119.3516,
    id: 1840042276,
  },
  {
    name: "Sardinia",
    state_id: "OH",
    lat: 39.0072,
    lng: -83.8022,
    id: 1840012723,
  },
  {
    name: "Kettleman City",
    state_id: "CA",
    lat: 36.009,
    lng: -119.9627,
    id: 1840017718,
  },
  {
    name: "Yemassee",
    state_id: "SC",
    lat: 32.6722,
    lng: -80.8508,
    id: 1840016929,
  },
  {
    name: "South Henderson",
    state_id: "NC",
    lat: 36.3035,
    lng: -78.4054,
    id: 1840013308,
  },
  {
    name: "Owyhee",
    state_id: "NV",
    lat: 41.9166,
    lng: -116.1822,
    id: 1840018705,
  },
  {
    name: "Rocksprings",
    state_id: "TX",
    lat: 30.0172,
    lng: -100.2128,
    id: 1840022192,
  },
  {
    name: "La Monte",
    state_id: "MO",
    lat: 38.772,
    lng: -93.4236,
    id: 1840008575,
  },
  {
    name: "Gruver",
    state_id: "TX",
    lat: 36.2562,
    lng: -101.4122,
    id: 1840020378,
  },
  {
    name: "Pheasant Run",
    state_id: "OH",
    lat: 41.2072,
    lng: -82.1481,
    id: 1840043288,
  },
  {
    name: "Albers",
    state_id: "IL",
    lat: 38.5452,
    lng: -89.6142,
    id: 1840010765,
  },
  {
    name: "Dane",
    state_id: "WI",
    lat: 43.2499,
    lng: -89.4996,
    id: 1840002912,
  },
  {
    name: "Lake Koshkonong",
    state_id: "WI",
    lat: 42.8838,
    lng: -88.9424,
    id: 1840004350,
  },
  {
    name: "Forrest",
    state_id: "IL",
    lat: 40.7511,
    lng: -88.4097,
    id: 1840011786,
  },
  {
    name: "Cole Camp",
    state_id: "MO",
    lat: 38.4595,
    lng: -93.2025,
    id: 1840007495,
  },
  {
    name: "Oolitic",
    state_id: "IN",
    lat: 38.8935,
    lng: -86.5254,
    id: 1840009717,
  },
  {
    name: "Sea Ranch",
    state_id: "CA",
    lat: 38.7165,
    lng: -123.4527,
    id: 1840024621,
  },
  {
    name: "Timpson",
    state_id: "TX",
    lat: 31.9065,
    lng: -94.3971,
    id: 1840022139,
  },
  {
    name: "Acalanes Ridge",
    state_id: "CA",
    lat: 37.9046,
    lng: -122.0786,
    id: 1840075577,
  },
  {
    name: "Horine",
    state_id: "MO",
    lat: 38.2669,
    lng: -90.4324,
    id: 1840006234,
  },
  {
    name: "Culebra",
    state_id: "PR",
    lat: 18.3105,
    lng: -65.3029,
    id: 1630023691,
  },
  {
    name: "Fairbank",
    state_id: "IA",
    lat: 42.64,
    lng: -92.0477,
    id: 1840008057,
  },
  {
    name: "Heritage Creek",
    state_id: "KY",
    lat: 38.0952,
    lng: -85.6125,
    id: 1840014305,
  },
  {
    name: "Waelder",
    state_id: "TX",
    lat: 29.6943,
    lng: -97.2972,
    id: 1840022219,
  },
  {
    name: "Pierce",
    state_id: "CO",
    lat: 40.6335,
    lng: -104.7551,
    id: 1840022399,
  },
  {
    name: "Johnstonville",
    state_id: "CA",
    lat: 40.3789,
    lng: -120.5846,
    id: 1840024202,
  },
  {
    name: "Petersburg",
    state_id: "TX",
    lat: 33.871,
    lng: -101.5974,
    id: 1840020537,
  },
  {
    name: "Red Boiling Springs",
    state_id: "TN",
    lat: 36.5301,
    lng: -85.8481,
    id: 1840014422,
  },
  {
    name: "Baxter",
    state_id: "IA",
    lat: 41.8252,
    lng: -93.1525,
    id: 1840007071,
  },
  {
    name: "Kaaawa",
    state_id: "HI",
    lat: 21.5485,
    lng: -157.8534,
    id: 1840029487,
  },
  {
    name: "Fairfax",
    state_id: "MN",
    lat: 44.5283,
    lng: -94.7231,
    id: 1840007860,
  },
  {
    name: "Cordry Sweetwater Lakes",
    state_id: "IN",
    lat: 39.3085,
    lng: -86.1227,
    id: 1840030086,
  },
  {
    name: "Satanta",
    state_id: "KS",
    lat: 37.4378,
    lng: -100.988,
    id: 1840009882,
  },
  {
    name: "North Grosvenor Dale",
    state_id: "CT",
    lat: 41.9862,
    lng: -71.9034,
    id: 1840003278,
  },
  {
    name: "Cool Valley",
    state_id: "MO",
    lat: 38.725,
    lng: -90.3058,
    id: 1840007440,
  },
  {
    name: "Stevensville",
    state_id: "MI",
    lat: 42.0139,
    lng: -86.5243,
    id: 1840011230,
  },
  {
    name: "Big Rock",
    state_id: "IL",
    lat: 41.7668,
    lng: -88.5265,
    id: 1840011342,
  },
  {
    name: "Mifflinville",
    state_id: "PA",
    lat: 41.0307,
    lng: -76.2995,
    id: 1840004996,
  },
  {
    name: "Yalaha",
    state_id: "FL",
    lat: 28.7509,
    lng: -81.8194,
    id: 1840014048,
  },
  {
    name: "Cavetown",
    state_id: "MD",
    lat: 39.6426,
    lng: -77.5932,
    id: 1840005598,
  },
  {
    name: "Mechanicsville",
    state_id: "IA",
    lat: 41.9056,
    lng: -91.2536,
    id: 1840008147,
  },
  {
    name: "Midway",
    state_id: "AR",
    lat: 36.3916,
    lng: -92.4724,
    id: 1840150886,
  },
  {
    name: "Woodland Heights",
    state_id: "PA",
    lat: 41.4109,
    lng: -79.7047,
    id: 1840004887,
  },
  {
    name: "Alamo",
    state_id: "NV",
    lat: 37.3648,
    lng: -115.163,
    id: 1840024649,
  },
  {
    name: "Purdy",
    state_id: "MO",
    lat: 36.8187,
    lng: -93.9206,
    id: 1840009933,
  },
  {
    name: "Petersburg",
    state_id: "MI",
    lat: 41.8993,
    lng: -83.7136,
    id: 1840003988,
  },
  {
    name: "Liborio Negrón Torres",
    state_id: "PR",
    lat: 18.0431,
    lng: -66.9426,
    id: 1630023548,
  },
  {
    name: "Boyceville",
    state_id: "WI",
    lat: 45.0427,
    lng: -92.0384,
    id: 1840000291,
  },
  {
    name: "Calvert",
    state_id: "TX",
    lat: 30.9791,
    lng: -96.6716,
    id: 1840019554,
  },
  {
    name: "Dellwood",
    state_id: "MN",
    lat: 45.0986,
    lng: -92.9672,
    id: 1840006740,
  },
  {
    name: "Lone Oak",
    state_id: "TN",
    lat: 35.2007,
    lng: -85.3685,
    id: 1840025820,
  },
  {
    name: "Dale",
    state_id: "PA",
    lat: 40.312,
    lng: -78.9049,
    id: 1840001107,
  },
  {
    name: "Belle",
    state_id: "WV",
    lat: 38.2336,
    lng: -81.5385,
    id: 1840006202,
  },
  {
    name: "Warrensburg",
    state_id: "IL",
    lat: 39.932,
    lng: -89.0629,
    id: 1840012432,
  },
  {
    name: "Lubeck",
    state_id: "WV",
    lat: 39.2276,
    lng: -81.6265,
    id: 1840005784,
  },
  {
    name: "Clear Lake",
    state_id: "WA",
    lat: 46.9312,
    lng: -122.2843,
    id: 1840149164,
  },
  {
    name: "Edgerton",
    state_id: "MN",
    lat: 43.8753,
    lng: -96.1304,
    id: 1840007906,
  },
  {
    name: "St. Michaels",
    state_id: "AZ",
    lat: 35.6606,
    lng: -109.0962,
    id: 1840019002,
  },
  {
    name: "Rock Island",
    state_id: "WA",
    lat: 47.3739,
    lng: -120.1382,
    id: 1840019796,
  },
  {
    name: "Sabana",
    state_id: "PR",
    lat: 18.4642,
    lng: -66.3517,
    id: 1630035565,
  },
  {
    name: "Ransom Canyon",
    state_id: "TX",
    lat: 33.5303,
    lng: -101.6838,
    id: 1840021984,
  },
  {
    name: "Rossville",
    state_id: "KS",
    lat: 39.1358,
    lng: -95.9496,
    id: 1840009686,
  },
  {
    name: "Daisetta",
    state_id: "TX",
    lat: 30.1135,
    lng: -94.6426,
    id: 1840019598,
  },
  {
    name: "Edina",
    state_id: "MO",
    lat: 40.168,
    lng: -92.1732,
    id: 1840008374,
  },
  {
    name: "Guernsey",
    state_id: "WY",
    lat: 42.2657,
    lng: -104.743,
    id: 1840021307,
  },
  {
    name: "Joseph",
    state_id: "OR",
    lat: 45.3526,
    lng: -117.2303,
    id: 1840018537,
  },
  {
    name: "Arbon Valley",
    state_id: "ID",
    lat: 42.8847,
    lng: -112.5942,
    id: 1840036681,
  },
  {
    name: "Wamac",
    state_id: "IL",
    lat: 38.4973,
    lng: -89.1457,
    id: 1840010758,
  },
  {
    name: "Randolph AFB",
    state_id: "TX",
    lat: 29.5301,
    lng: -98.2787,
    id: 1840043403,
  },
  {
    name: "Lake Park",
    state_id: "IA",
    lat: 43.4472,
    lng: -95.3247,
    id: 1840007961,
  },
  {
    name: "Wallenpaupack Lake Estates",
    state_id: "PA",
    lat: 41.3957,
    lng: -75.2731,
    id: 1840035451,
  },
  {
    name: "New Market",
    state_id: "AL",
    lat: 34.8892,
    lng: -86.4226,
    id: 1840013548,
  },
  {
    name: "Santa Margarita",
    state_id: "CA",
    lat: 35.3893,
    lng: -120.6084,
    id: 1840024942,
  },
  {
    name: "Tonasket",
    state_id: "WA",
    lat: 48.7077,
    lng: -119.4413,
    id: 1840021057,
  },
  {
    name: "Liberty Center",
    state_id: "OH",
    lat: 41.4436,
    lng: -84.0078,
    id: 1840011604,
  },
  {
    name: "Ansonia",
    state_id: "OH",
    lat: 40.2141,
    lng: -84.6344,
    id: 1840010469,
  },
  {
    name: "Pigeon",
    state_id: "MI",
    lat: 43.8295,
    lng: -83.2702,
    id: 1840013152,
  },
  {
    name: "Laurens",
    state_id: "IA",
    lat: 42.8477,
    lng: -94.8482,
    id: 1840008030,
  },
  {
    name: "St. Ansgar",
    state_id: "IA",
    lat: 43.3797,
    lng: -92.9178,
    id: 1840009033,
  },
  {
    name: "Knox City",
    state_id: "TX",
    lat: 33.4176,
    lng: -99.8157,
    id: 1840021976,
  },
  {
    name: "South Windham",
    state_id: "ME",
    lat: 43.738,
    lng: -70.4236,
    id: 1840026140,
  },
  {
    name: "La Cygne",
    state_id: "KS",
    lat: 38.3479,
    lng: -94.7613,
    id: 1840008666,
  },
  {
    name: "Russiaville",
    state_id: "IN",
    lat: 40.4185,
    lng: -86.2731,
    id: 1840010428,
  },
  {
    name: "Atwater",
    state_id: "MN",
    lat: 45.1356,
    lng: -94.7769,
    id: 1840006722,
  },
  {
    name: "Puget Island",
    state_id: "WA",
    lat: 46.1742,
    lng: -123.383,
    id: 1840042033,
  },
  {
    name: "Gholson",
    state_id: "TX",
    lat: 31.7123,
    lng: -97.2359,
    id: 1840020807,
  },
  {
    name: "Lemannville",
    state_id: "LA",
    lat: 30.1028,
    lng: -90.9239,
    id: 1840026028,
  },
  {
    name: "Schaefferstown",
    state_id: "PA",
    lat: 40.2969,
    lng: -76.2938,
    id: 1840005431,
  },
  {
    name: "Lexington",
    state_id: "MI",
    lat: 43.2671,
    lng: -82.537,
    id: 1840010976,
  },
  {
    name: "Crescent Beach",
    state_id: "FL",
    lat: 29.7357,
    lng: -81.2414,
    id: 1840013108,
  },
  {
    name: "Royalton",
    state_id: "IL",
    lat: 37.8773,
    lng: -89.1142,
    id: 1840012954,
  },
  {
    name: "Hamilton",
    state_id: "GA",
    lat: 32.7648,
    lng: -84.8752,
    id: 1840013803,
  },
  {
    name: "Shafer",
    state_id: "MN",
    lat: 45.3901,
    lng: -92.7531,
    id: 1840008893,
  },
  {
    name: "Garrochales",
    state_id: "PR",
    lat: 18.4572,
    lng: -66.5675,
    id: 1630035672,
  },
  {
    name: "Arizona Village",
    state_id: "AZ",
    lat: 34.8548,
    lng: -114.5893,
    id: 1840018338,
  },
  {
    name: "Twin Lakes",
    state_id: "NM",
    lat: 35.6834,
    lng: -108.7709,
    id: 1840019093,
  },
  {
    name: "Point Roberts",
    state_id: "WA",
    lat: 48.9883,
    lng: -123.0576,
    id: 1840025234,
  },
  {
    name: "Parachute",
    state_id: "CO",
    lat: 39.4501,
    lng: -108.0549,
    id: 1840022430,
  },
  {
    name: "Chatsworth",
    state_id: "IL",
    lat: 40.7294,
    lng: -88.2975,
    id: 1840009353,
  },
  {
    name: "Sykesville",
    state_id: "PA",
    lat: 41.0475,
    lng: -78.8185,
    id: 1840003474,
  },
  {
    name: "Rosharon",
    state_id: "TX",
    lat: 29.35,
    lng: -95.453,
    id: 1840022986,
  },
  {
    name: "Phillipsburg",
    state_id: "GA",
    lat: 31.4389,
    lng: -83.5203,
    id: 1840029310,
  },
  {
    name: "Central City",
    state_id: "IL",
    lat: 38.5485,
    lng: -89.1284,
    id: 1840010759,
  },
  {
    name: "Grant",
    state_id: "NE",
    lat: 40.8445,
    lng: -101.7256,
    id: 1840008289,
  },
  {
    name: "Malvern",
    state_id: "IA",
    lat: 41.004,
    lng: -95.5842,
    id: 1840000891,
  },
  {
    name: "Siesta Shores",
    state_id: "TX",
    lat: 26.8548,
    lng: -99.2605,
    id: 1840019720,
  },
  {
    name: "Mexico Beach",
    state_id: "FL",
    lat: 29.948,
    lng: -85.4157,
    id: 1840015033,
  },
  {
    name: "Lewisville",
    state_id: "AR",
    lat: 33.3643,
    lng: -93.5751,
    id: 1840014835,
  },
  {
    name: "Simpson",
    state_id: "PA",
    lat: 41.5945,
    lng: -75.4813,
    id: 1840026297,
  },
  {
    name: "Hill Country Village",
    state_id: "TX",
    lat: 29.5831,
    lng: -98.4893,
    id: 1840020958,
  },
  {
    name: "Kenwood",
    state_id: "OK",
    lat: 36.3013,
    lng: -94.9979,
    id: 1840024887,
  },
  {
    name: "Greenup",
    state_id: "KY",
    lat: 38.5728,
    lng: -82.8303,
    id: 1840013183,
  },
  {
    name: "Wooster",
    state_id: "AR",
    lat: 35.1998,
    lng: -92.4503,
    id: 1840016418,
  },
  {
    name: "Georgetown",
    state_id: "CO",
    lat: 39.7181,
    lng: -105.694,
    id: 1840021433,
  },
  {
    name: "Macon",
    state_id: "IL",
    lat: 39.7117,
    lng: -88.9965,
    id: 1840009485,
  },
  {
    name: "Cashton",
    state_id: "WI",
    lat: 43.7413,
    lng: -90.7866,
    id: 1840002571,
  },
  {
    name: "Riverview",
    state_id: "SC",
    lat: 35.0108,
    lng: -80.9845,
    id: 1840013521,
  },
  {
    name: "Clint",
    state_id: "TX",
    lat: 31.5901,
    lng: -106.2289,
    id: 1840023111,
  },
  {
    name: "Billings",
    state_id: "MO",
    lat: 37.0626,
    lng: -93.5539,
    id: 1840007645,
  },
  {
    name: "Mantachie",
    state_id: "MS",
    lat: 34.325,
    lng: -88.4961,
    id: 1840016669,
  },
  {
    name: "Carson City",
    state_id: "MI",
    lat: 43.1816,
    lng: -84.8463,
    id: 1840002876,
  },
  {
    name: "Marbleton",
    state_id: "WY",
    lat: 42.5574,
    lng: -110.1004,
    id: 1840022360,
  },
  {
    name: "Quebrada del Agua",
    state_id: "PR",
    lat: 18.0379,
    lng: -66.6846,
    id: 1630025230,
  },
  {
    name: "Wildwood",
    state_id: "TN",
    lat: 35.7999,
    lng: -83.8686,
    id: 1840025791,
  },
  {
    name: "Craig Beach",
    state_id: "OH",
    lat: 41.116,
    lng: -80.9828,
    id: 1840011776,
  },
  {
    name: "Westhaven-Moonstone",
    state_id: "CA",
    lat: 41.0447,
    lng: -124.1023,
    id: 1840074748,
  },
  {
    name: "Prospect",
    state_id: "PA",
    lat: 40.8992,
    lng: -80.0487,
    id: 1840000872,
  },
  {
    name: "Energy",
    state_id: "IL",
    lat: 37.7739,
    lng: -89.0244,
    id: 1840012986,
  },
  {
    name: "Catawba",
    state_id: "SC",
    lat: 34.8464,
    lng: -80.9061,
    id: 1840026964,
  },
  {
    name: "Roslyn Harbor",
    state_id: "NY",
    lat: 40.8157,
    lng: -73.6378,
    id: 1840005323,
  },
  {
    name: "Mountain City",
    state_id: "GA",
    lat: 34.9196,
    lng: -83.3854,
    id: 1840016507,
  },
  {
    name: "Mucarabones",
    state_id: "PR",
    lat: 18.3906,
    lng: -66.2178,
    id: 1630023640,
  },
  {
    name: "Bouse",
    state_id: "AZ",
    lat: 33.8984,
    lng: -113.9962,
    id: 1840017957,
  },
  {
    name: "Pender",
    state_id: "NE",
    lat: 42.1108,
    lng: -96.7113,
    id: 1840011219,
  },
  {
    name: "Merryville",
    state_id: "LA",
    lat: 30.7571,
    lng: -93.5245,
    id: 1840017169,
  },
  {
    name: "Maineville",
    state_id: "OH",
    lat: 39.3126,
    lng: -84.1985,
    id: 1840012613,
  },
  {
    name: "Walnut Creek",
    state_id: "OH",
    lat: 40.5427,
    lng: -81.7213,
    id: 1840026483,
  },
  {
    name: "Riceville",
    state_id: "TN",
    lat: 35.3895,
    lng: -84.699,
    id: 1840025814,
  },
  {
    name: "Highland",
    state_id: "AR",
    lat: 36.2657,
    lng: -91.5186,
    id: 1840014447,
  },
  {
    name: "Lenape Heights",
    state_id: "PA",
    lat: 40.7633,
    lng: -79.5217,
    id: 1840035427,
  },
  {
    name: "Pandora",
    state_id: "OH",
    lat: 40.9474,
    lng: -83.9567,
    id: 1840011760,
  },
  {
    name: "Lonaconing",
    state_id: "MD",
    lat: 39.5658,
    lng: -78.9789,
    id: 1840005624,
  },
  {
    name: "Olinda",
    state_id: "HI",
    lat: 20.8261,
    lng: -156.2917,
    id: 1840039244,
  },
  {
    name: "Lakeview",
    state_id: "OH",
    lat: 40.4875,
    lng: -83.9266,
    id: 1840012150,
  },
  {
    name: "Gilcrest",
    state_id: "CO",
    lat: 40.2842,
    lng: -104.782,
    id: 1840021366,
  },
  {
    name: "Glidden",
    state_id: "IA",
    lat: 42.0576,
    lng: -94.7267,
    id: 1840008116,
  },
  {
    name: "Sheffield",
    state_id: "IA",
    lat: 42.8928,
    lng: -93.2086,
    id: 1840000402,
  },
  {
    name: "Burt",
    state_id: "MI",
    lat: 43.2348,
    lng: -83.9101,
    id: 1840004229,
  },
  {
    name: "Divernon",
    state_id: "IL",
    lat: 39.5707,
    lng: -89.6546,
    id: 1840012447,
  },
  {
    name: "Tornillo",
    state_id: "TX",
    lat: 31.4366,
    lng: -106.1033,
    id: 1840019524,
  },
  {
    name: "South Barre",
    state_id: "VT",
    lat: 44.1646,
    lng: -72.5038,
    id: 1840002188,
  },
  {
    name: "Fairmead",
    state_id: "CA",
    lat: 37.0775,
    lng: -120.1937,
    id: 1840024767,
  },
  {
    name: "Akron",
    state_id: "IN",
    lat: 41.0388,
    lng: -86.0249,
    id: 1840010309,
  },
  {
    name: "Dash Point",
    state_id: "WA",
    lat: 47.313,
    lng: -122.4154,
    id: 1840037637,
  },
  {
    name: "Union Hall",
    state_id: "VA",
    lat: 37.0205,
    lng: -79.6833,
    id: 1840006476,
  },
  {
    name: "Dooms",
    state_id: "VA",
    lat: 38.1027,
    lng: -78.8495,
    id: 1840006242,
  },
  {
    name: "Taft Mosswood",
    state_id: "CA",
    lat: 37.9139,
    lng: -121.2831,
    id: 1840074823,
  },
  {
    name: "Caddo",
    state_id: "OK",
    lat: 34.1288,
    lng: -96.2678,
    id: 1840022896,
  },
  {
    name: "Cayuga",
    state_id: "IN",
    lat: 39.9473,
    lng: -87.4647,
    id: 1840009470,
  },
  {
    name: "Rockford",
    state_id: "OH",
    lat: 40.6915,
    lng: -84.6502,
    id: 1840012002,
  },
  {
    name: "East Conemaugh",
    state_id: "PA",
    lat: 40.3476,
    lng: -78.886,
    id: 1840001108,
  },
  {
    name: "Sweetser",
    state_id: "IN",
    lat: 40.5687,
    lng: -85.7669,
    id: 1840010397,
  },
  {
    name: "Browns Point",
    state_id: "WA",
    lat: 47.3035,
    lng: -122.437,
    id: 1840037624,
  },
  {
    name: "Glasgow",
    state_id: "VA",
    lat: 37.6348,
    lng: -79.4514,
    id: 1840006315,
  },
  {
    name: "Ellport",
    state_id: "PA",
    lat: 40.8618,
    lng: -80.2625,
    id: 1840003569,
  },
  {
    name: "Clear Lake",
    state_id: "WI",
    lat: 45.2491,
    lng: -92.2675,
    id: 1840002053,
  },
  {
    name: "Elwood",
    state_id: "UT",
    lat: 41.6758,
    lng: -112.1377,
    id: 1840021329,
  },
  {
    name: "Rochelle",
    state_id: "GA",
    lat: 31.9488,
    lng: -83.4568,
    id: 1840014928,
  },
  {
    name: "Rosendale Hamlet",
    state_id: "NY",
    lat: 41.8503,
    lng: -74.0738,
    id: 1840073670,
  },
  {
    name: "Leonville",
    state_id: "LA",
    lat: 30.4661,
    lng: -91.9811,
    id: 1840017171,
  },
  {
    name: "Floyd Hill",
    state_id: "CO",
    lat: 39.7241,
    lng: -105.4306,
    id: 1840038713,
  },
  {
    name: "Redwater",
    state_id: "TX",
    lat: 33.3586,
    lng: -94.2551,
    id: 1840023192,
  },
  {
    name: "Gratton",
    state_id: "VA",
    lat: 37.1351,
    lng: -81.4212,
    id: 1840026868,
  },
  {
    name: "Peabody",
    state_id: "KS",
    lat: 38.1683,
    lng: -97.1043,
    id: 1840009662,
  },
  {
    name: "Continental",
    state_id: "OH",
    lat: 41.1001,
    lng: -84.2727,
    id: 1840011752,
  },
  {
    name: "Cedarville",
    state_id: "NJ",
    lat: 39.3382,
    lng: -75.2069,
    id: 1840005749,
  },
  {
    name: "Jonestown",
    state_id: "MS",
    lat: 34.3222,
    lng: -90.4542,
    id: 1840016639,
  },
  {
    name: "White Haven",
    state_id: "PA",
    lat: 41.0566,
    lng: -75.78,
    id: 1840000776,
  },
  {
    name: "Clendenin",
    state_id: "WV",
    lat: 38.4859,
    lng: -81.3503,
    id: 1840006205,
  },
  {
    name: "Danvers",
    state_id: "IL",
    lat: 40.5299,
    lng: -89.1751,
    id: 1840011977,
  },
  {
    name: "Lost Creek",
    state_id: "TX",
    lat: 30.3024,
    lng: -97.8498,
    id: 1840018232,
  },
  {
    name: "Fort Davis",
    state_id: "TX",
    lat: 30.5784,
    lng: -103.8922,
    id: 1840018199,
  },
  {
    name: "Princeton",
    state_id: "MO",
    lat: 40.3966,
    lng: -93.5884,
    id: 1840009415,
  },
  {
    name: "Big Bass Lake",
    state_id: "PA",
    lat: 41.2525,
    lng: -75.4795,
    id: 1840035449,
  },
  {
    name: "Ash Flat",
    state_id: "AR",
    lat: 36.2365,
    lng: -91.6067,
    id: 1840013324,
  },
  {
    name: "Vinco",
    state_id: "PA",
    lat: 40.4111,
    lng: -78.8446,
    id: 1840005382,
  },
  {
    name: "Despard",
    state_id: "WV",
    lat: 39.2874,
    lng: -80.3158,
    id: 1840005765,
  },
  {
    name: "Point Marion",
    state_id: "PA",
    lat: 39.7355,
    lng: -79.9008,
    id: 1840000668,
  },
  {
    name: "Nashville",
    state_id: "IN",
    lat: 39.2079,
    lng: -86.2383,
    id: 1840013887,
  },
  {
    name: "Brookneal",
    state_id: "VA",
    lat: 37.0532,
    lng: -78.9485,
    id: 1840006439,
  },
  {
    name: "Fort Dick",
    state_id: "CA",
    lat: 41.8663,
    lng: -124.1526,
    id: 1840027997,
  },
  {
    name: "Hotevilla-Bacavi",
    state_id: "AZ",
    lat: 35.9225,
    lng: -110.6569,
    id: 1840027979,
  },
  {
    name: "Inverness",
    state_id: "CA",
    lat: 38.082,
    lng: -122.8471,
    id: 1840017602,
  },
  {
    name: "Eagle",
    state_id: "NE",
    lat: 40.8161,
    lng: -96.4327,
    id: 1840011811,
  },
  {
    name: "Hebron",
    state_id: "MD",
    lat: 38.4243,
    lng: -75.6871,
    id: 1840006217,
  },
  {
    name: "Mansfield",
    state_id: "AR",
    lat: 35.0634,
    lng: -94.2375,
    id: 1840015442,
  },
  {
    name: "Yoncalla",
    state_id: "OR",
    lat: 43.6004,
    lng: -123.2876,
    id: 1840021276,
  },
  {
    name: "Clay",
    state_id: "KY",
    lat: 37.4763,
    lng: -87.8203,
    id: 1840014359,
  },
  {
    name: "Dover Plains",
    state_id: "NY",
    lat: 41.7395,
    lng: -73.5734,
    id: 1840004741,
  },
  {
    name: "Louisville",
    state_id: "IL",
    lat: 38.7696,
    lng: -88.5068,
    id: 1840012812,
  },
  {
    name: "Anton",
    state_id: "TX",
    lat: 33.8113,
    lng: -102.1622,
    id: 1840019338,
  },
  {
    name: "Berry",
    state_id: "AL",
    lat: 33.6623,
    lng: -87.6079,
    id: 1840015661,
  },
  {
    name: "Somers",
    state_id: "MT",
    lat: 48.0824,
    lng: -114.2343,
    id: 1840018349,
  },
  {
    name: "Weeping Water",
    state_id: "NE",
    lat: 40.8691,
    lng: -96.14,
    id: 1840010325,
  },
  {
    name: "Andover",
    state_id: "OH",
    lat: 41.6066,
    lng: -80.5687,
    id: 1840010172,
  },
  {
    name: "Powers Lake",
    state_id: "WI",
    lat: 42.5395,
    lng: -88.2935,
    id: 1840004537,
  },
  {
    name: "Stover",
    state_id: "MO",
    lat: 38.4418,
    lng: -92.9901,
    id: 1840009649,
  },
  {
    name: "Paducah",
    state_id: "TX",
    lat: 34.0144,
    lng: -100.3038,
    id: 1840022882,
  },
  {
    name: "Clay City",
    state_id: "KY",
    lat: 37.8622,
    lng: -83.9277,
    id: 1840014339,
  },
  {
    name: "Morocco",
    state_id: "IN",
    lat: 40.9445,
    lng: -87.4503,
    id: 1840009325,
  },
  {
    name: "Chapmanville",
    state_id: "WV",
    lat: 37.9727,
    lng: -82.0216,
    id: 1840006337,
  },
  {
    name: "Ray City",
    state_id: "GA",
    lat: 31.0729,
    lng: -83.196,
    id: 1840014965,
  },
  {
    name: "White Plains",
    state_id: "NC",
    lat: 36.4451,
    lng: -80.6351,
    id: 1840013307,
  },
  {
    name: "Avonia",
    state_id: "PA",
    lat: 42.047,
    lng: -80.2765,
    id: 1840004695,
  },
  {
    name: "Kyle",
    state_id: "SD",
    lat: 43.4245,
    lng: -102.1802,
    id: 1840004199,
  },
  {
    name: "South Pekin",
    state_id: "IL",
    lat: 40.4954,
    lng: -89.6524,
    id: 1840011993,
  },
  {
    name: "Camp Point",
    state_id: "IL",
    lat: 40.0384,
    lng: -91.0663,
    id: 1840010505,
  },
  {
    name: "Atwater",
    state_id: "OH",
    lat: 41.0266,
    lng: -81.1622,
    id: 1840026340,
  },
  {
    name: "Markle",
    state_id: "IN",
    lat: 40.8274,
    lng: -85.3393,
    id: 1840009361,
  },
  {
    name: "Ballard",
    state_id: "UT",
    lat: 40.2949,
    lng: -109.9493,
    id: 1840020161,
  },
  {
    name: "Lowellville",
    state_id: "OH",
    lat: 41.039,
    lng: -80.5463,
    id: 1840011778,
  },
  {
    name: "Challis",
    state_id: "ID",
    lat: 44.5052,
    lng: -114.2283,
    id: 1840018599,
  },
  {
    name: "Livingston Manor",
    state_id: "NY",
    lat: 41.8935,
    lng: -74.8265,
    id: 1840004786,
  },
  {
    name: "Shell Knob",
    state_id: "MO",
    lat: 36.6105,
    lng: -93.6042,
    id: 1840006523,
  },
  {
    name: "Strum",
    state_id: "WI",
    lat: 44.5532,
    lng: -91.3876,
    id: 1840002384,
  },
  {
    name: "West Kittanning",
    state_id: "PA",
    lat: 40.8122,
    lng: -79.5312,
    id: 1840000880,
  },
  {
    name: "Winter Park",
    state_id: "CO",
    lat: 39.8785,
    lng: -105.7828,
    id: 1840022421,
  },
  {
    name: "Coyote Acres",
    state_id: "TX",
    lat: 27.7132,
    lng: -98.1346,
    id: 1840037040,
  },
  {
    name: "Frederic",
    state_id: "WI",
    lat: 45.6548,
    lng: -92.4647,
    id: 1840002048,
  },
  {
    name: "Steele",
    state_id: "AL",
    lat: 33.937,
    lng: -86.2004,
    id: 1840016773,
  },
  {
    name: "Winfield",
    state_id: "IA",
    lat: 41.1259,
    lng: -91.4382,
    id: 1840010315,
  },
  {
    name: "Ogden Dunes",
    state_id: "IN",
    lat: 41.6229,
    lng: -87.1932,
    id: 1840009270,
  },
  {
    name: "Glenvar",
    state_id: "VA",
    lat: 37.276,
    lng: -80.1256,
    id: 1840026859,
  },
  {
    name: "Oakfield",
    state_id: "WI",
    lat: 43.6843,
    lng: -88.5476,
    id: 1840002608,
  },
  {
    name: "Oakland",
    state_id: "SC",
    lat: 33.9868,
    lng: -80.4972,
    id: 1840013671,
  },
  {
    name: "Martinsville",
    state_id: "IL",
    lat: 39.3381,
    lng: -87.8814,
    id: 1840008494,
  },
  {
    name: "Hoopers Creek",
    state_id: "NC",
    lat: 35.4481,
    lng: -82.4294,
    id: 1840024956,
  },
  {
    name: "Erie",
    state_id: "KS",
    lat: 37.5717,
    lng: -95.2418,
    id: 1840008722,
  },
  {
    name: "Sherburn",
    state_id: "MN",
    lat: 43.6547,
    lng: -94.7273,
    id: 1840009016,
  },
  {
    name: "Creighton",
    state_id: "NE",
    lat: 42.4649,
    lng: -97.9072,
    id: 1840002458,
  },
  {
    name: "Occoquan",
    state_id: "VA",
    lat: 38.6825,
    lng: -77.2606,
    id: 1840006108,
  },
  {
    name: "Foster Brook",
    state_id: "PA",
    lat: 41.9793,
    lng: -78.6063,
    id: 1840026236,
  },
  {
    name: "West Hills",
    state_id: "PA",
    lat: 40.8289,
    lng: -79.5452,
    id: 1840035386,
  },
  {
    name: "Hebron",
    state_id: "PA",
    lat: 40.339,
    lng: -76.3989,
    id: 1840035011,
  },
  {
    name: "Boise City",
    state_id: "OK",
    lat: 36.7314,
    lng: -102.5098,
    id: 1840019008,
  },
  {
    name: "Shandon",
    state_id: "CA",
    lat: 35.6536,
    lng: -120.3831,
    id: 1840019121,
  },
  {
    name: "Pueblito del Río",
    state_id: "PR",
    lat: 18.228,
    lng: -65.8615,
    id: 1630035488,
  },
  {
    name: "Lake View",
    state_id: "IA",
    lat: 42.3046,
    lng: -95.0406,
    id: 1840008070,
  },
  {
    name: "Talihina",
    state_id: "OK",
    lat: 34.7522,
    lng: -95.0409,
    id: 1840022775,
  },
  {
    name: "Jefferson Heights",
    state_id: "NY",
    lat: 42.2361,
    lng: -73.8822,
    id: 1840004644,
  },
  {
    name: "Glasgow",
    state_id: "MO",
    lat: 39.2279,
    lng: -92.8394,
    id: 1840008519,
  },
  {
    name: "Moorcroft",
    state_id: "WY",
    lat: 44.2643,
    lng: -104.9473,
    id: 1840022346,
  },
  {
    name: "Bayard",
    state_id: "NE",
    lat: 41.7572,
    lng: -103.323,
    id: 1840007044,
  },
  {
    name: "South Corning",
    state_id: "NY",
    lat: 42.1262,
    lng: -77.0356,
    id: 1840004554,
  },
  {
    name: "Bull Valley",
    state_id: "IL",
    lat: 42.3226,
    lng: -88.3634,
    id: 1840010108,
  },
  {
    name: "Thomasboro",
    state_id: "IL",
    lat: 40.2439,
    lng: -88.1877,
    id: 1840012238,
  },
  {
    name: "Buena",
    state_id: "WA",
    lat: 46.4299,
    lng: -120.3176,
    id: 1840023850,
  },
  {
    name: "Pylesville",
    state_id: "MD",
    lat: 39.688,
    lng: -76.3876,
    id: 1840026622,
  },
  {
    name: "Brooksville",
    state_id: "MS",
    lat: 33.2367,
    lng: -88.5781,
    id: 1840015744,
  },
  {
    name: "Dierks",
    state_id: "AR",
    lat: 34.12,
    lng: -94.0173,
    id: 1840014707,
  },
  {
    name: "Washburn",
    state_id: "IL",
    lat: 40.9203,
    lng: -89.2916,
    id: 1840011949,
  },
  {
    name: "Marianne",
    state_id: "PA",
    lat: 41.2441,
    lng: -79.431,
    id: 1840035378,
  },
  {
    name: "Hilbert",
    state_id: "WI",
    lat: 44.1415,
    lng: -88.1611,
    id: 1840002249,
  },
  {
    name: "Pembroke",
    state_id: "VA",
    lat: 37.3219,
    lng: -80.6365,
    id: 1840006434,
  },
  {
    name: "Gettysburg",
    state_id: "SD",
    lat: 45.006,
    lng: -99.9539,
    id: 1840002114,
  },
  {
    name: "Maysville",
    state_id: "MO",
    lat: 39.8859,
    lng: -94.3633,
    id: 1840008405,
  },
  {
    name: "Tivoli",
    state_id: "NY",
    lat: 42.0587,
    lng: -73.9119,
    id: 1840004754,
  },
  {
    name: "Tom Bean",
    state_id: "TX",
    lat: 33.5201,
    lng: -96.4843,
    id: 1840021955,
  },
  {
    name: "Spring City",
    state_id: "UT",
    lat: 39.4799,
    lng: -111.4918,
    id: 1840021436,
  },
  {
    name: "Van Wyck",
    state_id: "SC",
    lat: 34.8531,
    lng: -80.8333,
    id: 1840030329,
  },
  {
    name: "Appleton City",
    state_id: "MO",
    lat: 38.1906,
    lng: -94.031,
    id: 1840007530,
  },
  {
    name: "Coulee Dam",
    state_id: "WA",
    lat: 47.9689,
    lng: -118.976,
    id: 1840022270,
  },
  {
    name: "Luttrell",
    state_id: "TN",
    lat: 36.2097,
    lng: -83.7478,
    id: 1840016095,
  },
  {
    name: "Little Round Lake",
    state_id: "WI",
    lat: 45.9697,
    lng: -91.367,
    id: 1840004009,
  },
  {
    name: "Baldwin",
    state_id: "MI",
    lat: 43.8971,
    lng: -85.8531,
    id: 1840010948,
  },
  {
    name: "Elgin",
    state_id: "MN",
    lat: 44.1257,
    lng: -92.247,
    id: 1840007898,
  },
  {
    name: "Remerton",
    state_id: "GA",
    lat: 30.8441,
    lng: -83.3086,
    id: 1840014999,
  },
  {
    name: "Jamaica Beach",
    state_id: "TX",
    lat: 29.1905,
    lng: -94.9801,
    id: 1840019665,
  },
  {
    name: "Wanamingo",
    state_id: "MN",
    lat: 44.3026,
    lng: -92.7872,
    id: 1840010014,
  },
  {
    name: "Ryland Heights",
    state_id: "KY",
    lat: 38.9701,
    lng: -84.4607,
    id: 1840015174,
  },
  {
    name: "Crowley Lake",
    state_id: "CA",
    lat: 37.5692,
    lng: -118.7465,
    id: 1840075708,
  },
  {
    name: "Conrad",
    state_id: "IA",
    lat: 42.2234,
    lng: -92.8741,
    id: 1840006963,
  },
  {
    name: "Athens",
    state_id: "WI",
    lat: 45.0349,
    lng: -90.0792,
    id: 1840002160,
  },
  {
    name: "New Columbia",
    state_id: "PA",
    lat: 41.0423,
    lng: -76.8763,
    id: 1840024211,
  },
  {
    name: "Chevak",
    state_id: "AK",
    lat: 61.5295,
    lng: -165.5943,
    id: 1840023322,
  },
  {
    name: "Daniel",
    state_id: "UT",
    lat: 40.4668,
    lng: -111.4097,
    id: 1840021394,
  },
  {
    name: "Shawneetown",
    state_id: "IL",
    lat: 37.7157,
    lng: -88.1865,
    id: 1840009867,
  },
  {
    name: "Hackberry",
    state_id: "TX",
    lat: 33.1501,
    lng: -96.9184,
    id: 1840022011,
  },
  {
    name: "Captains Cove",
    state_id: "VA",
    lat: 38.0003,
    lng: -75.4173,
    id: 1840024722,
  },
  {
    name: "Galeton",
    state_id: "PA",
    lat: 41.7338,
    lng: -77.6454,
    id: 1840003308,
  },
  {
    name: "Piney View",
    state_id: "WV",
    lat: 37.835,
    lng: -81.1275,
    id: 1840038161,
  },
  {
    name: "Rennerdale",
    state_id: "PA",
    lat: 40.4002,
    lng: -80.1397,
    id: 1840026479,
  },
  {
    name: "Milesburg",
    state_id: "PA",
    lat: 40.9426,
    lng: -77.792,
    id: 1840000825,
  },
  {
    name: "Caliente",
    state_id: "NV",
    lat: 37.6436,
    lng: -114.4964,
    id: 1840018856,
  },
  {
    name: "Plymouth",
    state_id: "CA",
    lat: 38.4776,
    lng: -120.8584,
    id: 1840020249,
  },
  {
    name: "Stedman",
    state_id: "NC",
    lat: 35.0129,
    lng: -78.7027,
    id: 1840016442,
  },
  {
    name: "Pacific City",
    state_id: "OR",
    lat: 45.2054,
    lng: -123.9536,
    id: 1840018551,
  },
  {
    name: "Cochranton",
    state_id: "PA",
    lat: 41.5192,
    lng: -80.047,
    id: 1840000553,
  },
  {
    name: "Maxwell",
    state_id: "CA",
    lat: 39.2771,
    lng: -122.1946,
    id: 1840024470,
  },
  {
    name: "Mooreville",
    state_id: "MS",
    lat: 34.2648,
    lng: -88.5769,
    id: 1840025853,
  },
  {
    name: "Marion Center",
    state_id: "MA",
    lat: 41.7042,
    lng: -70.7629,
    id: 1840073508,
  },
  {
    name: "Lake St. Croix Beach",
    state_id: "MN",
    lat: 44.9245,
    lng: -92.7657,
    id: 1840007815,
  },
  {
    name: "Rio Vista",
    state_id: "TX",
    lat: 32.2351,
    lng: -97.3744,
    id: 1840020753,
  },
  {
    name: "Laguna",
    state_id: "NM",
    lat: 35.0504,
    lng: -107.4071,
    id: 1840017871,
  },
  {
    name: "Palouse",
    state_id: "WA",
    lat: 46.9103,
    lng: -117.0751,
    id: 1840019863,
  },
  {
    name: "Hustisford",
    state_id: "WI",
    lat: 43.3442,
    lng: -88.6033,
    id: 1840002732,
  },
  {
    name: "Indian Hills",
    state_id: "NM",
    lat: 34.9832,
    lng: -106.1398,
    id: 1840040012,
  },
  {
    name: "Millersport",
    state_id: "OH",
    lat: 39.8982,
    lng: -82.5388,
    id: 1840012472,
  },
  {
    name: "Estherwood",
    state_id: "LA",
    lat: 30.1816,
    lng: -92.4634,
    id: 1840018240,
  },
  {
    name: "Oak Grove Heights",
    state_id: "AR",
    lat: 36.1271,
    lng: -90.5085,
    id: 1840016122,
  },
  {
    name: "Williamsport",
    state_id: "OH",
    lat: 39.5824,
    lng: -83.1206,
    id: 1840012557,
  },
  {
    name: "Bolinas",
    state_id: "CA",
    lat: 37.9177,
    lng: -122.7095,
    id: 1840017601,
  },
  {
    name: "Belview",
    state_id: "VA",
    lat: 37.1687,
    lng: -80.511,
    id: 1840041677,
  },
  {
    name: "Blue Mound",
    state_id: "IL",
    lat: 39.7004,
    lng: -89.1185,
    id: 1840012425,
  },
  {
    name: "Flat Lick",
    state_id: "KY",
    lat: 36.8353,
    lng: -83.7598,
    id: 1840026894,
  },
  {
    name: "New Waverly",
    state_id: "TX",
    lat: 30.5374,
    lng: -95.4822,
    id: 1840020866,
  },
  {
    name: "Susitna North",
    state_id: "AK",
    lat: 62.1281,
    lng: -149.8593,
    id: 1840037713,
  },
  {
    name: "Punaluu",
    state_id: "HI",
    lat: 21.5811,
    lng: -157.8901,
    id: 1840137106,
  },
  {
    name: "Phil Campbell",
    state_id: "AL",
    lat: 34.3519,
    lng: -87.7062,
    id: 1840016626,
  },
  {
    name: "West Park",
    state_id: "CA",
    lat: 36.7055,
    lng: -119.8516,
    id: 1840026842,
  },
  {
    name: "Farmersburg",
    state_id: "IN",
    lat: 39.2526,
    lng: -87.3807,
    id: 1840010682,
  },
  {
    name: "Cave Spring",
    state_id: "GA",
    lat: 34.1081,
    lng: -85.3388,
    id: 1840014680,
  },
  {
    name: "Edinburg",
    state_id: "VA",
    lat: 38.8235,
    lng: -78.5634,
    id: 1840006001,
  },
  {
    name: "Angel Fire",
    state_id: "NM",
    lat: 36.3821,
    lng: -105.2718,
    id: 1840022641,
  },
  {
    name: "Bradbury",
    state_id: "CA",
    lat: 34.1536,
    lng: -117.9684,
    id: 1840019232,
  },
  {
    name: "Ashippun",
    state_id: "WI",
    lat: 43.2182,
    lng: -88.5159,
    id: 1840025434,
  },
  {
    name: "Forest Park",
    state_id: "OK",
    lat: 35.5095,
    lng: -97.4469,
    id: 1840021745,
  },
  {
    name: "Tyler",
    state_id: "MN",
    lat: 44.2785,
    lng: -96.1355,
    id: 1840010019,
  },
  {
    name: "Osceola Mills",
    state_id: "PA",
    lat: 40.8526,
    lng: -78.2699,
    id: 1840003502,
  },
  {
    name: "Knox",
    state_id: "PA",
    lat: 41.2346,
    lng: -79.536,
    id: 1840003458,
  },
  {
    name: "Bear Creek",
    state_id: "AL",
    lat: 34.2651,
    lng: -87.7164,
    id: 1840015585,
  },
  {
    name: "Dudleyville",
    state_id: "AZ",
    lat: 32.9128,
    lng: -110.7345,
    id: 1840019358,
  },
  {
    name: "Panora",
    state_id: "IA",
    lat: 41.691,
    lng: -94.3636,
    id: 1840009226,
  },
  {
    name: "London",
    state_id: "AR",
    lat: 35.328,
    lng: -93.2417,
    id: 1840015402,
  },
  {
    name: "Rutherford",
    state_id: "TN",
    lat: 36.1249,
    lng: -88.992,
    id: 1840017740,
  },
  {
    name: "Start",
    state_id: "LA",
    lat: 32.4902,
    lng: -91.865,
    id: 1840025902,
  },
  {
    name: "Altamont",
    state_id: "TN",
    lat: 35.4325,
    lng: -85.7433,
    id: 1840015431,
  },
  {
    name: "Texico",
    state_id: "NM",
    lat: 34.3897,
    lng: -103.0513,
    id: 1840021840,
  },
  {
    name: "Kersey",
    state_id: "PA",
    lat: 41.357,
    lng: -78.6101,
    id: 1840026304,
  },
  {
    name: "Palo",
    state_id: "IA",
    lat: 42.0646,
    lng: -91.8002,
    id: 1840009146,
  },
  {
    name: "Republic",
    state_id: "WA",
    lat: 48.6483,
    lng: -118.7334,
    id: 1840019766,
  },
  {
    name: "Bellaire",
    state_id: "MI",
    lat: 44.9738,
    lng: -85.2057,
    id: 1840010922,
  },
  {
    name: "Assumption",
    state_id: "IL",
    lat: 39.518,
    lng: -89.0478,
    id: 1840007316,
  },
  {
    name: "Bloomfield",
    state_id: "KY",
    lat: 37.9122,
    lng: -85.314,
    id: 1840013221,
  },
  {
    name: "Cullen",
    state_id: "LA",
    lat: 32.9699,
    lng: -93.4464,
    id: 1840016936,
  },
  {
    name: "Speers",
    state_id: "PA",
    lat: 40.1222,
    lng: -79.8799,
    id: 1840003665,
  },
  {
    name: "Bracey",
    state_id: "VA",
    lat: 36.5762,
    lng: -78.1107,
    id: 1840024859,
  },
  {
    name: "Bassett",
    state_id: "VA",
    lat: 36.7615,
    lng: -79.9866,
    id: 1840006551,
  },
  {
    name: "Tiki Island",
    state_id: "TX",
    lat: 29.2985,
    lng: -94.9175,
    id: 1840003908,
  },
  {
    name: "Sand Point",
    state_id: "AK",
    lat: 55.3227,
    lng: -160.4834,
    id: 1840023269,
  },
  {
    name: "St. Paul",
    state_id: "IN",
    lat: 39.4282,
    lng: -85.6287,
    id: 1840010657,
  },
  {
    name: "Flanagan",
    state_id: "IL",
    lat: 40.8771,
    lng: -88.8597,
    id: 1840011785,
  },
  {
    name: "Cobden",
    state_id: "IL",
    lat: 37.5335,
    lng: -89.2545,
    id: 1840013007,
  },
  {
    name: "Berryville",
    state_id: "TX",
    lat: 32.0876,
    lng: -95.4667,
    id: 1840020777,
  },
  {
    name: "Amelia Court House",
    state_id: "VA",
    lat: 37.3366,
    lng: -77.9882,
    id: 1840026850,
  },
  {
    name: "Powderly",
    state_id: "TX",
    lat: 33.8091,
    lng: -95.5049,
    id: 1840025872,
  },
  {
    name: "Greene",
    state_id: "IA",
    lat: 42.8967,
    lng: -92.8041,
    id: 1840008038,
  },
  {
    name: "Nauvoo",
    state_id: "IL",
    lat: 40.5457,
    lng: -91.3829,
    id: 1840008341,
  },
  {
    name: "Hydesville",
    state_id: "CA",
    lat: 40.5579,
    lng: -124.0822,
    id: 1840017486,
  },
  {
    name: "Bogata",
    state_id: "TX",
    lat: 33.4698,
    lng: -95.2135,
    id: 1840019316,
  },
  {
    name: "Double Springs",
    state_id: "AL",
    lat: 34.1502,
    lng: -87.3912,
    id: 1840007127,
  },
  {
    name: "Wister",
    state_id: "OK",
    lat: 34.9699,
    lng: -94.7206,
    id: 1840022776,
  },
  {
    name: "Absarokee",
    state_id: "MT",
    lat: 45.5242,
    lng: -109.4474,
    id: 1840017395,
  },
  {
    name: "Maud",
    state_id: "OK",
    lat: 35.1336,
    lng: -96.7783,
    id: 1840020441,
  },
  {
    name: "Clark",
    state_id: "SD",
    lat: 44.8813,
    lng: -97.7346,
    id: 1840000292,
  },
  {
    name: "Lydia",
    state_id: "LA",
    lat: 29.9263,
    lng: -91.7813,
    id: 1840014007,
  },
  {
    name: "Donald",
    state_id: "OR",
    lat: 45.2227,
    lng: -122.8388,
    id: 1840018583,
  },
  {
    name: "Hamburg",
    state_id: "IA",
    lat: 40.6061,
    lng: -95.6545,
    id: 1840001000,
  },
  {
    name: "Elliston",
    state_id: "VA",
    lat: 37.2163,
    lng: -80.2245,
    id: 1840024789,
  },
  {
    name: "Choctaw",
    state_id: "LA",
    lat: 29.8457,
    lng: -90.7238,
    id: 1840031100,
  },
  {
    name: "Washington",
    state_id: "KS",
    lat: 39.816,
    lng: -97.0486,
    id: 1840000724,
  },
  {
    name: "Los Indios",
    state_id: "TX",
    lat: 26.0493,
    lng: -97.7351,
    id: 1840022265,
  },
  {
    name: "Warren",
    state_id: "TX",
    lat: 30.6127,
    lng: -94.4104,
    id: 1840025989,
  },
  {
    name: "Goreville",
    state_id: "IL",
    lat: 37.5611,
    lng: -88.9557,
    id: 1840013003,
  },
  {
    name: "Skagway",
    state_id: "AK",
    lat: 59.4566,
    lng: -135.2927,
    id: 1840023311,
  },
  {
    name: "Holgate",
    state_id: "OH",
    lat: 41.2493,
    lng: -84.1292,
    id: 1840011605,
  },
  {
    name: "Convoy",
    state_id: "OH",
    lat: 40.9177,
    lng: -84.7053,
    id: 1840011901,
  },
  {
    name: "Chadwicks",
    state_id: "NY",
    lat: 43.0291,
    lng: -75.271,
    id: 1840024027,
  },
  {
    name: "Humansville",
    state_id: "MO",
    lat: 37.7952,
    lng: -93.5766,
    id: 1840007579,
  },
  {
    name: "Lake Shore",
    state_id: "MN",
    lat: 46.504,
    lng: -94.3635,
    id: 1840007722,
  },
  {
    name: "Meadow View",
    state_id: "VA",
    lat: 36.7638,
    lng: -81.8728,
    id: 1840041837,
  },
  {
    name: "Caledonia",
    state_id: "MS",
    lat: 33.6836,
    lng: -88.326,
    id: 1840015690,
  },
  {
    name: "Prospect",
    state_id: "OH",
    lat: 40.454,
    lng: -83.1848,
    id: 1840012028,
  },
  {
    name: "Cherry Tree",
    state_id: "OK",
    lat: 35.7454,
    lng: -94.6428,
    id: 1840034527,
  },
  {
    name: "Quinter",
    state_id: "KS",
    lat: 39.0666,
    lng: -100.2347,
    id: 1840009702,
  },
  {
    name: "Oskaloosa",
    state_id: "KS",
    lat: 39.2158,
    lng: -95.3145,
    id: 1840009609,
  },
  {
    name: "Cando",
    state_id: "ND",
    lat: 48.4872,
    lng: -99.2026,
    id: 1840000018,
  },
  {
    name: "South Shore",
    state_id: "KY",
    lat: 38.7224,
    lng: -82.9647,
    id: 1840015184,
  },
  {
    name: "Lilbourn",
    state_id: "MO",
    lat: 36.5908,
    lng: -89.613,
    id: 1840009939,
  },
  {
    name: "New Franklin",
    state_id: "MO",
    lat: 39.0175,
    lng: -92.7406,
    id: 1840008520,
  },
  {
    name: "Grandview",
    state_id: "OH",
    lat: 39.1922,
    lng: -84.7245,
    id: 1840005877,
  },
  {
    name: "Upton",
    state_id: "WY",
    lat: 44.1025,
    lng: -104.6374,
    id: 1840022351,
  },
  {
    name: "Riner",
    state_id: "VA",
    lat: 37.0675,
    lng: -80.4405,
    id: 1840024792,
  },
  {
    name: "Franklin Park",
    state_id: "FL",
    lat: 26.134,
    lng: -80.1762,
    id: 1840029104,
  },
  {
    name: "Greenfields",
    state_id: "PA",
    lat: 40.3627,
    lng: -75.9565,
    id: 1840035458,
  },
  {
    name: "Falls City",
    state_id: "OR",
    lat: 44.8648,
    lng: -123.4372,
    id: 1840019970,
  },
  {
    name: "Shelton",
    state_id: "NE",
    lat: 40.7789,
    lng: -98.7306,
    id: 1840011835,
  },
  {
    name: "Skyline-Ganipa",
    state_id: "NM",
    lat: 35.0328,
    lng: -107.614,
    id: 1840074409,
  },
  {
    name: "Clayton",
    state_id: "IN",
    lat: 39.6892,
    lng: -86.5232,
    id: 1840009537,
  },
  {
    name: "Marcus",
    state_id: "IA",
    lat: 42.8224,
    lng: -95.8068,
    id: 1840008025,
  },
  {
    name: "Ordway",
    state_id: "CO",
    lat: 38.2209,
    lng: -103.7566,
    id: 1840022511,
  },
  {
    name: "Catahoula",
    state_id: "LA",
    lat: 30.2125,
    lng: -91.7127,
    id: 1840026015,
  },
  {
    name: "Shinglehouse",
    state_id: "PA",
    lat: 41.9662,
    lng: -78.1908,
    id: 1840003310,
  },
  {
    name: "Homestead Base",
    state_id: "FL",
    lat: 25.4936,
    lng: -80.3911,
    id: 1840073841,
  },
  {
    name: "Stillman Valley",
    state_id: "IL",
    lat: 42.1048,
    lng: -89.1803,
    id: 1840011239,
  },
  {
    name: "Tuscarawas",
    state_id: "OH",
    lat: 40.3934,
    lng: -81.4006,
    id: 1840012099,
  },
  {
    name: "Penhook",
    state_id: "VA",
    lat: 36.9905,
    lng: -79.6318,
    id: 1840006475,
  },
  {
    name: "New Hope",
    state_id: "TN",
    lat: 35.0044,
    lng: -85.6598,
    id: 1840014604,
  },
  {
    name: "De Smet",
    state_id: "SD",
    lat: 44.3862,
    lng: -97.5497,
    id: 1840000314,
  },
  {
    name: "Aurora",
    state_id: "UT",
    lat: 38.9201,
    lng: -111.934,
    id: 1840018832,
  },
  {
    name: "Port Republic",
    state_id: "NJ",
    lat: 39.5328,
    lng: -74.4852,
    id: 1840001536,
  },
  {
    name: "St. Paul",
    state_id: "TX",
    lat: 33.0437,
    lng: -96.5504,
    id: 1840022020,
  },
  {
    name: "Greenville",
    state_id: "IN",
    lat: 38.3716,
    lng: -85.9827,
    id: 1840010814,
  },
  {
    name: "Northfield",
    state_id: "KY",
    lat: 38.2857,
    lng: -85.6362,
    id: 1840014297,
  },
  {
    name: "Highlandville",
    state_id: "MO",
    lat: 36.9409,
    lng: -93.283,
    id: 1840007646,
  },
  {
    name: "Tioga",
    state_id: "TX",
    lat: 33.4718,
    lng: -96.9187,
    id: 1840022935,
  },
  {
    name: "Bessemer",
    state_id: "PA",
    lat: 40.9756,
    lng: -80.4878,
    id: 1840003568,
  },
  {
    name: "Luck",
    state_id: "WI",
    lat: 45.5718,
    lng: -92.4666,
    id: 1840002055,
  },
  {
    name: "Hector",
    state_id: "MN",
    lat: 44.7412,
    lng: -94.712,
    id: 1840006774,
  },
  {
    name: "Deltaville",
    state_id: "VA",
    lat: 37.5525,
    lng: -76.3331,
    id: 1840026817,
  },
  {
    name: "Ashton",
    state_id: "ID",
    lat: 44.0734,
    lng: -111.4482,
    id: 1840018607,
  },
  {
    name: "Roscommon",
    state_id: "MI",
    lat: 44.4915,
    lng: -84.5914,
    id: 1840010943,
  },
  {
    name: "Aplington",
    state_id: "IA",
    lat: 42.5819,
    lng: -92.8835,
    id: 1840006914,
  },
  {
    name: "Canaan",
    state_id: "CT",
    lat: 42.0347,
    lng: -73.3323,
    id: 1840034218,
  },
  {
    name: "Lake Nebagamon",
    state_id: "WI",
    lat: 46.512,
    lng: -91.6995,
    id: 1840003910,
  },
  {
    name: "Neah Bay",
    state_id: "WA",
    lat: 48.3612,
    lng: -124.6115,
    id: 1840023937,
  },
  {
    name: "Garrett Park",
    state_id: "MD",
    lat: 39.036,
    lng: -77.0934,
    id: 1840005843,
  },
  {
    name: "Baxter Estates",
    state_id: "NY",
    lat: 40.8338,
    lng: -73.6949,
    id: 1840033846,
  },
  {
    name: "Keuka Park",
    state_id: "NY",
    lat: 42.6123,
    lng: -77.0916,
    id: 1840026189,
  },
  {
    name: "Queen Anne",
    state_id: "MD",
    lat: 38.9021,
    lng: -76.6973,
    id: 1840149380,
  },
  {
    name: "Frankfort",
    state_id: "OH",
    lat: 39.4069,
    lng: -83.1842,
    id: 1840012671,
  },
  {
    name: "Gifford",
    state_id: "IL",
    lat: 40.3079,
    lng: -88.0214,
    id: 1840012226,
  },
  {
    name: "Robert Lee",
    state_id: "TX",
    lat: 31.8954,
    lng: -100.4856,
    id: 1840020797,
  },
  {
    name: "Grand Coulee",
    state_id: "WA",
    lat: 47.9392,
    lng: -119.0028,
    id: 1840019815,
  },
  {
    name: "Hamlin",
    state_id: "WV",
    lat: 38.2805,
    lng: -82.1087,
    id: 1840006271,
  },
  {
    name: "Norlina",
    state_id: "NC",
    lat: 36.4451,
    lng: -78.1952,
    id: 1840016038,
  },
  {
    name: "Sidney",
    state_id: "IA",
    lat: 40.7459,
    lng: -95.6443,
    id: 1840009383,
  },
  {
    name: "New Hartford Center",
    state_id: "CT",
    lat: 41.8802,
    lng: -72.9753,
    id: 1840073298,
  },
  {
    name: "Ninnekah",
    state_id: "OK",
    lat: 34.9641,
    lng: -97.9447,
    id: 1840022777,
  },
  {
    name: "Mountainhome",
    state_id: "PA",
    lat: 41.1779,
    lng: -75.2632,
    id: 1840005172,
  },
  {
    name: "Strodes Mills",
    state_id: "PA",
    lat: 40.5487,
    lng: -77.6751,
    id: 1840035213,
  },
  {
    name: "Rawls Springs",
    state_id: "MS",
    lat: 31.3797,
    lng: -89.3878,
    id: 1840025970,
  },
  {
    name: "Campti",
    state_id: "LA",
    lat: 31.898,
    lng: -93.1158,
    id: 1840015838,
  },
  {
    name: "Arnaudville",
    state_id: "LA",
    lat: 30.4021,
    lng: -91.932,
    id: 1840015905,
  },
  {
    name: "Califon",
    state_id: "NJ",
    lat: 40.7192,
    lng: -74.8371,
    id: 1840001038,
  },
  {
    name: "McClellan Park",
    state_id: "CA",
    lat: 38.6631,
    lng: -121.4015,
    id: 1840038292,
  },
  {
    name: "Amherst",
    state_id: "WI",
    lat: 44.4475,
    lng: -89.2832,
    id: 1840002324,
  },
  {
    name: "Martin",
    state_id: "SD",
    lat: 43.1749,
    lng: -101.7324,
    id: 1840002892,
  },
  {
    name: "Fyffe",
    state_id: "AL",
    lat: 34.4496,
    lng: -85.9049,
    id: 1840004830,
  },
  {
    name: "Auxier",
    state_id: "KY",
    lat: 37.7356,
    lng: -82.7661,
    id: 1840026818,
  },
  {
    name: "Lakeview",
    state_id: "WA",
    lat: 47.3755,
    lng: -119.5044,
    id: 1840136062,
  },
  {
    name: "Wolcottville",
    state_id: "IN",
    lat: 41.5255,
    lng: -85.3665,
    id: 1840010254,
  },
  {
    name: "Dodson Branch",
    state_id: "TN",
    lat: 36.3148,
    lng: -85.5322,
    id: 1840025768,
  },
  {
    name: "Reading",
    state_id: "MI",
    lat: 41.8396,
    lng: -84.7478,
    id: 1840003251,
  },
  {
    name: "Luckey",
    state_id: "OH",
    lat: 41.4525,
    lng: -83.4839,
    id: 1840011557,
  },
  {
    name: "McLeansville",
    state_id: "NC",
    lat: 36.1045,
    lng: -79.6554,
    id: 1840013355,
  },
  {
    name: "Beach",
    state_id: "ND",
    lat: 46.9192,
    lng: -104.008,
    id: 1840001916,
  },
  {
    name: "Saylorsburg",
    state_id: "PA",
    lat: 40.8997,
    lng: -75.3179,
    id: 1840026363,
  },
  {
    name: "Maud",
    state_id: "TX",
    lat: 33.3299,
    lng: -94.3461,
    id: 1840020611,
  },
  {
    name: "Alamance",
    state_id: "NC",
    lat: 36.0285,
    lng: -79.4891,
    id: 1840017736,
  },
  {
    name: "Truman",
    state_id: "MN",
    lat: 43.8279,
    lng: -94.4366,
    id: 1840010046,
  },
  {
    name: "Foxfire",
    state_id: "NC",
    lat: 35.179,
    lng: -79.564,
    id: 1840017851,
  },
  {
    name: "George",
    state_id: "IA",
    lat: 43.342,
    lng: -96.0027,
    id: 1840007972,
  },
  {
    name: "Rainbow City",
    state_id: "AZ",
    lat: 33.8731,
    lng: -109.9763,
    id: 1840038035,
  },
  {
    name: "Ranchester",
    state_id: "WY",
    lat: 44.9075,
    lng: -107.1674,
    id: 1840021245,
  },
  {
    name: "Fairfield Beach",
    state_id: "OH",
    lat: 39.9183,
    lng: -82.4791,
    id: 1840005561,
  },
  {
    name: "Worden",
    state_id: "IL",
    lat: 38.9316,
    lng: -89.8402,
    id: 1840012804,
  },
  {
    name: "Akutan",
    state_id: "AK",
    lat: 54.1594,
    lng: -165.7328,
    id: 1840023265,
  },
  {
    name: "Obion",
    state_id: "TN",
    lat: 36.2643,
    lng: -89.1947,
    id: 1840016069,
  },
  {
    name: "Elwood",
    state_id: "NJ",
    lat: 39.5731,
    lng: -74.7148,
    id: 1840005594,
  },
  {
    name: "St. Michaels",
    state_id: "MD",
    lat: 38.7882,
    lng: -76.2243,
    id: 1840006091,
  },
  {
    name: "Concord",
    state_id: "MI",
    lat: 42.1762,
    lng: -84.6456,
    id: 1840011197,
  },
  {
    name: "Lakeville",
    state_id: "CT",
    lat: 41.9594,
    lng: -73.4467,
    id: 1840026222,
  },
  {
    name: "Parklawn",
    state_id: "CA",
    lat: 37.6069,
    lng: -120.9793,
    id: 1840042934,
  },
  {
    name: "Aurora",
    state_id: "OR",
    lat: 45.2285,
    lng: -122.7572,
    id: 1840018578,
  },
  {
    name: "Mound City",
    state_id: "MO",
    lat: 40.1365,
    lng: -95.2337,
    id: 1840008382,
  },
  {
    name: "Crocker",
    state_id: "MO",
    lat: 37.948,
    lng: -92.2676,
    id: 1840007556,
  },
  {
    name: "Clermont",
    state_id: "GA",
    lat: 34.4784,
    lng: -83.7721,
    id: 1840016648,
  },
  {
    name: "Town Creek",
    state_id: "AL",
    lat: 34.6718,
    lng: -87.4063,
    id: 1840016591,
  },
  {
    name: "Newport",
    state_id: "DE",
    lat: 39.7136,
    lng: -75.6052,
    id: 1840005582,
  },
  {
    name: "Ridgway",
    state_id: "CO",
    lat: 38.1573,
    lng: -107.7545,
    id: 1840021512,
  },
  {
    name: "Rio",
    state_id: "WI",
    lat: 43.4497,
    lng: -89.2388,
    id: 1840002714,
  },
  {
    name: "Helena West Side",
    state_id: "MT",
    lat: 46.5966,
    lng: -112.1138,
    id: 1840074087,
  },
  {
    name: "Eden Valley",
    state_id: "MN",
    lat: 45.3263,
    lng: -94.5453,
    id: 1840007806,
  },
  {
    name: "East Gull Lake",
    state_id: "MN",
    lat: 46.3948,
    lng: -94.3548,
    id: 1840007720,
  },
  {
    name: "Asherton",
    state_id: "TX",
    lat: 28.446,
    lng: -99.7604,
    id: 1840019700,
  },
  {
    name: "Eagle Lake",
    state_id: "WI",
    lat: 42.7014,
    lng: -88.1291,
    id: 1840004480,
  },
  {
    name: "Port Norris",
    state_id: "NJ",
    lat: 39.2521,
    lng: -75.0412,
    id: 1840005750,
  },
  {
    name: "Ecru",
    state_id: "MS",
    lat: 34.355,
    lng: -89.0222,
    id: 1840016678,
  },
  {
    name: "Riesel",
    state_id: "TX",
    lat: 31.4763,
    lng: -96.9304,
    id: 1840020812,
  },
  {
    name: "Apalachin",
    state_id: "NY",
    lat: 42.0705,
    lng: -76.1626,
    id: 1840004665,
  },
  {
    name: "Sweet Home",
    state_id: "AR",
    lat: 34.6707,
    lng: -92.2465,
    id: 1840013535,
  },
  {
    name: "Poseyville",
    state_id: "IN",
    lat: 38.1698,
    lng: -87.7832,
    id: 1840010822,
  },
  {
    name: "Candelero Arriba",
    state_id: "PR",
    lat: 18.1017,
    lng: -65.8342,
    id: 1630035635,
  },
  {
    name: "Wolfhurst",
    state_id: "OH",
    lat: 40.069,
    lng: -80.781,
    id: 1840026551,
  },
  {
    name: "Astoria",
    state_id: "IL",
    lat: 40.2279,
    lng: -90.3566,
    id: 1840012006,
  },
  {
    name: "Westfield",
    state_id: "PA",
    lat: 41.9177,
    lng: -77.5403,
    id: 1840003305,
  },
  {
    name: "Knights Landing",
    state_id: "CA",
    lat: 38.7979,
    lng: -121.7175,
    id: 1840024601,
  },
  {
    name: "Kissee Mills",
    state_id: "MO",
    lat: 36.6694,
    lng: -93.0473,
    id: 1840025750,
  },
  {
    name: "Churchill",
    state_id: "MT",
    lat: 45.75,
    lng: -111.3103,
    id: 1840026102,
  },
  {
    name: "Lompico",
    state_id: "CA",
    lat: 37.1101,
    lng: -122.0523,
    id: 1840024795,
  },
  {
    name: "Gorman",
    state_id: "TX",
    lat: 32.2136,
    lng: -98.6721,
    id: 1840020765,
  },
  {
    name: "Chauncey",
    state_id: "OH",
    lat: 39.4005,
    lng: -82.1261,
    id: 1840010638,
  },
  {
    name: "Egypt",
    state_id: "AL",
    lat: 34.0759,
    lng: -86.1651,
    id: 1840022888,
  },
  {
    name: "Ouray",
    state_id: "CO",
    lat: 38.0276,
    lng: -107.6734,
    id: 1840020258,
  },
  {
    name: "Taylors Falls",
    state_id: "MN",
    lat: 45.4121,
    lng: -92.6644,
    id: 1840008895,
  },
  {
    name: "Milford Square",
    state_id: "PA",
    lat: 40.4325,
    lng: -75.4056,
    id: 1840035099,
  },
  {
    name: "Island City",
    state_id: "OR",
    lat: 45.3359,
    lng: -118.0495,
    id: 1840018544,
  },
  {
    name: "Point Venture",
    state_id: "TX",
    lat: 30.3817,
    lng: -98.001,
    id: 1840023144,
  },
  {
    name: "Riverdale Park",
    state_id: "CA",
    lat: 37.6028,
    lng: -121.0425,
    id: 1840028351,
  },
  {
    name: "Carlton",
    state_id: "MN",
    lat: 46.6635,
    lng: -92.407,
    id: 1840006648,
  },
  {
    name: "Thornton",
    state_id: "CA",
    lat: 38.231,
    lng: -121.425,
    id: 1840026770,
  },
  {
    name: "Sunman",
    state_id: "IN",
    lat: 39.2373,
    lng: -85.0931,
    id: 1840010675,
  },
  {
    name: "Fairhope",
    state_id: "PA",
    lat: 40.1151,
    lng: -79.8389,
    id: 1840026562,
  },
  {
    name: "Maypearl",
    state_id: "TX",
    lat: 32.314,
    lng: -97.0052,
    id: 1840020756,
  },
  {
    name: "Wakeman",
    state_id: "OH",
    lat: 41.253,
    lng: -82.4028,
    id: 1840011711,
  },
  {
    name: "Hill City",
    state_id: "SD",
    lat: 43.9338,
    lng: -103.5693,
    id: 1840002184,
  },
  {
    name: "Potter Lake",
    state_id: "WI",
    lat: 42.8196,
    lng: -88.3487,
    id: 1840004477,
  },
  {
    name: "New Bedford",
    state_id: "PA",
    lat: 41.0955,
    lng: -80.4953,
    id: 1840026379,
  },
  {
    name: "Rosendale",
    state_id: "WI",
    lat: 43.808,
    lng: -88.6731,
    id: 1840002610,
  },
  {
    name: "Meigs",
    state_id: "GA",
    lat: 31.0713,
    lng: -84.0922,
    id: 1840015892,
  },
  {
    name: "New Philadelphia",
    state_id: "PA",
    lat: 40.7178,
    lng: -76.1167,
    id: 1840002768,
  },
  {
    name: "Fairland",
    state_id: "OK",
    lat: 36.7506,
    lng: -94.8483,
    id: 1840021618,
  },
  {
    name: "Belle Vernon",
    state_id: "PA",
    lat: 40.1256,
    lng: -79.867,
    id: 1840001499,
  },
  {
    name: "Horatio",
    state_id: "AR",
    lat: 33.941,
    lng: -94.3554,
    id: 1840014736,
  },
  {
    name: "Fanning Springs",
    state_id: "FL",
    lat: 29.5873,
    lng: -82.9245,
    id: 1840014038,
  },
  {
    name: "Arp",
    state_id: "TX",
    lat: 32.2279,
    lng: -95.0536,
    id: 1840019455,
  },
  {
    name: "St. Jo",
    state_id: "TX",
    lat: 33.695,
    lng: -97.5231,
    id: 1840021949,
  },
  {
    name: "Velda Village Hills",
    state_id: "MO",
    lat: 38.6922,
    lng: -90.2877,
    id: 1840012828,
  },
  {
    name: "Central City",
    state_id: "PA",
    lat: 40.1096,
    lng: -78.8043,
    id: 1840003734,
  },
  {
    name: "Melville",
    state_id: "LA",
    lat: 30.6917,
    lng: -91.7485,
    id: 1840017172,
  },
  {
    name: "Plummer",
    state_id: "ID",
    lat: 47.3301,
    lng: -116.8849,
    id: 1840019851,
  },
  {
    name: "Kibler",
    state_id: "AR",
    lat: 35.4265,
    lng: -94.2368,
    id: 1840014539,
  },
  {
    name: "Alexandria Bay",
    state_id: "NY",
    lat: 44.34,
    lng: -75.9161,
    id: 1840004120,
  },
  {
    name: "Wink",
    state_id: "TX",
    lat: 31.7545,
    lng: -103.1541,
    id: 1840022128,
  },
  {
    name: "Newark",
    state_id: "IL",
    lat: 41.5368,
    lng: -88.5804,
    id: 1840011507,
  },
  {
    name: "Castle Hayne",
    state_id: "NC",
    lat: 34.3583,
    lng: -77.9114,
    id: 1840013628,
  },
  {
    name: "Celoron",
    state_id: "NY",
    lat: 42.1053,
    lng: -79.2777,
    id: 1840004577,
  },
  {
    name: "Lynn",
    state_id: "IN",
    lat: 40.0488,
    lng: -84.9424,
    id: 1840009449,
  },
  {
    name: "Luxora",
    state_id: "AR",
    lat: 35.7576,
    lng: -89.9298,
    id: 1840015362,
  },
  {
    name: "University Heights",
    state_id: "IA",
    lat: 41.6551,
    lng: -91.5589,
    id: 1840010190,
  },
  {
    name: "Lovington",
    state_id: "IL",
    lat: 39.7143,
    lng: -88.6315,
    id: 1840012563,
  },
  {
    name: "Abbottstown",
    state_id: "PA",
    lat: 39.8843,
    lng: -76.9888,
    id: 1840000675,
  },
  {
    name: "Point Lookout",
    state_id: "NY",
    lat: 40.5903,
    lng: -73.5795,
    id: 1840005251,
  },
  {
    name: "Wurtland",
    state_id: "KY",
    lat: 38.5495,
    lng: -82.7788,
    id: 1840015186,
  },
  {
    name: "Schurz",
    state_id: "NV",
    lat: 38.9366,
    lng: -118.7925,
    id: 1840018828,
  },
  {
    name: "Glandorf",
    state_id: "OH",
    lat: 41.0279,
    lng: -84.0791,
    id: 1840011756,
  },
  {
    name: "Cascade",
    state_id: "ID",
    lat: 44.5087,
    lng: -116.0426,
    id: 1840018585,
  },
  {
    name: "De Soto",
    state_id: "IA",
    lat: 41.5364,
    lng: -94.0098,
    id: 1840007056,
  },
  {
    name: "Pen Mar",
    state_id: "PA",
    lat: 39.7243,
    lng: -77.515,
    id: 1840026533,
  },
  {
    name: "Rhodhiss",
    state_id: "NC",
    lat: 35.7684,
    lng: -81.4312,
    id: 1840017754,
  },
  {
    name: "Dickeyville",
    state_id: "WI",
    lat: 42.6248,
    lng: -90.5914,
    id: 1840002975,
  },
  {
    name: "Lily Lake",
    state_id: "IL",
    lat: 41.9518,
    lng: -88.4742,
    id: 1840011345,
  },
  {
    name: "Callender Lake",
    state_id: "TX",
    lat: 32.3675,
    lng: -95.6983,
    id: 1840042520,
  },
  {
    name: "Groveton",
    state_id: "TX",
    lat: 31.0572,
    lng: -95.1269,
    id: 1840020847,
  },
  {
    name: "Pocono Pines",
    state_id: "PA",
    lat: 41.1149,
    lng: -75.4589,
    id: 1840005173,
  },
  {
    name: "Parker",
    state_id: "SD",
    lat: 43.3978,
    lng: -97.1334,
    id: 1840000358,
  },
  {
    name: "Overbrook",
    state_id: "KS",
    lat: 38.7794,
    lng: -95.557,
    id: 1840009778,
  },
  {
    name: "Riverside",
    state_id: "IA",
    lat: 41.4894,
    lng: -91.5558,
    id: 1840009280,
  },
  {
    name: "Lake Waynoka",
    state_id: "OH",
    lat: 38.9401,
    lng: -83.7786,
    id: 1840043000,
  },
  {
    name: "Royalton",
    state_id: "PA",
    lat: 40.1873,
    lng: -76.727,
    id: 1840001296,
  },
  {
    name: "Bolivar",
    state_id: "WV",
    lat: 39.3242,
    lng: -77.7517,
    id: 1840005760,
  },
  {
    name: "Paradise",
    state_id: "UT",
    lat: 41.5682,
    lng: -111.8334,
    id: 1840022376,
  },
  {
    name: "Delevan",
    state_id: "NY",
    lat: 42.4913,
    lng: -78.4796,
    id: 1840004599,
  },
  {
    name: "Mallory",
    state_id: "WV",
    lat: 37.7274,
    lng: -81.8206,
    id: 1840006334,
  },
  {
    name: "Tyndall",
    state_id: "SD",
    lat: 42.9899,
    lng: -97.8642,
    id: 1840003066,
  },
  {
    name: "Lincoln",
    state_id: "PA",
    lat: 40.2994,
    lng: -79.8554,
    id: 1840001242,
  },
  {
    name: "Lyndon",
    state_id: "KS",
    lat: 38.6118,
    lng: -95.6844,
    id: 1840009776,
  },
  {
    name: "Thompson",
    state_id: "ND",
    lat: 47.7753,
    lng: -97.1058,
    id: 1840001891,
  },
  {
    name: "Pierceton",
    state_id: "IN",
    lat: 41.1992,
    lng: -85.7032,
    id: 1840010275,
  },
  {
    name: "Plains",
    state_id: "KS",
    lat: 37.2642,
    lng: -100.5898,
    id: 1840009901,
  },
  {
    name: "Kenmare",
    state_id: "ND",
    lat: 48.6728,
    lng: -102.0721,
    id: 1840000051,
  },
  {
    name: "Hart",
    state_id: "TX",
    lat: 34.3857,
    lng: -102.115,
    id: 1840020521,
  },
  {
    name: "Afton",
    state_id: "OK",
    lat: 36.6965,
    lng: -94.9579,
    id: 1840021616,
  },
  {
    name: "Mantua",
    state_id: "OH",
    lat: 41.2819,
    lng: -81.2221,
    id: 1840011675,
  },
  {
    name: "Gaston",
    state_id: "NC",
    lat: 36.4965,
    lng: -77.6449,
    id: 1840016043,
  },
  {
    name: "Beale AFB",
    state_id: "CA",
    lat: 39.1095,
    lng: -121.3543,
    id: 1840073898,
  },
  {
    name: "Watterson Park",
    state_id: "KY",
    lat: 38.189,
    lng: -85.6865,
    id: 1840015198,
  },
  {
    name: "McCloud",
    state_id: "CA",
    lat: 41.254,
    lng: -122.136,
    id: 1840017469,
  },
  {
    name: "Baudette",
    state_id: "MN",
    lat: 48.7119,
    lng: -94.5925,
    id: 1840007673,
  },
  {
    name: "Altamont",
    state_id: "KS",
    lat: 37.1876,
    lng: -95.2897,
    id: 1840007625,
  },
  {
    name: "Cape Charles",
    state_id: "VA",
    lat: 37.257,
    lng: -76.0093,
    id: 1840006416,
  },
  {
    name: "Heath Springs",
    state_id: "SC",
    lat: 34.5933,
    lng: -80.675,
    id: 1840015505,
  },
  {
    name: "San Pablo",
    state_id: "NM",
    lat: 32.2506,
    lng: -106.7626,
    id: 1840025090,
  },
  {
    name: "Julian",
    state_id: "CA",
    lat: 33.0735,
    lng: -116.5889,
    id: 1840018022,
  },
  {
    name: "Plandome Heights",
    state_id: "NY",
    lat: 40.8021,
    lng: -73.7049,
    id: 1840033934,
  },
  {
    name: "Chetopa",
    state_id: "KS",
    lat: 37.0376,
    lng: -95.0926,
    id: 1840001711,
  },
  {
    name: "Silverado Resort",
    state_id: "CA",
    lat: 38.3536,
    lng: -122.2556,
    id: 1840076237,
  },
  {
    name: "Bradner",
    state_id: "OH",
    lat: 41.3237,
    lng: -83.4363,
    id: 1840011548,
  },
  {
    name: "Kingston",
    state_id: "OH",
    lat: 39.4723,
    lng: -82.912,
    id: 1840012670,
  },
  {
    name: "Shongopovi",
    state_id: "AZ",
    lat: 35.8195,
    lng: -110.5331,
    id: 1840136204,
  },
  {
    name: "Elkhart Lake",
    state_id: "WI",
    lat: 43.8321,
    lng: -88.0147,
    id: 1840002621,
  },
  {
    name: "María Antonia",
    state_id: "PR",
    lat: 17.9789,
    lng: -66.8889,
    id: 1630023535,
  },
  {
    name: "Gray",
    state_id: "TN",
    lat: 36.4219,
    lng: -82.4767,
    id: 1840013333,
  },
  {
    name: "Friars Point",
    state_id: "MS",
    lat: 34.368,
    lng: -90.6379,
    id: 1840016643,
  },
  {
    name: "Walton",
    state_id: "IN",
    lat: 40.6622,
    lng: -86.2441,
    id: 1840010363,
  },
  {
    name: "Cedar Ridge",
    state_id: "CA",
    lat: 38.0661,
    lng: -120.2737,
    id: 1840022518,
  },
  {
    name: "Dasher",
    state_id: "GA",
    lat: 30.7462,
    lng: -83.2253,
    id: 1840017144,
  },
  {
    name: "Danville",
    state_id: "OH",
    lat: 40.447,
    lng: -82.2608,
    id: 1840012139,
  },
  {
    name: "Seabrook Beach",
    state_id: "NH",
    lat: 42.8852,
    lng: -70.8235,
    id: 1840026176,
  },
  {
    name: "Browning",
    state_id: "MT",
    lat: 48.5564,
    lng: -113.0151,
    id: 1840021053,
  },
  {
    name: "Rosiclare",
    state_id: "IL",
    lat: 37.4239,
    lng: -88.3501,
    id: 1840009894,
  },
  {
    name: "New London",
    state_id: "TX",
    lat: 32.2687,
    lng: -94.9296,
    id: 1840020769,
  },
  {
    name: "Rapids",
    state_id: "NY",
    lat: 43.0976,
    lng: -78.644,
    id: 1840004269,
  },
  {
    name: "Yoe",
    state_id: "PA",
    lat: 39.9094,
    lng: -76.6366,
    id: 1840001471,
  },
  {
    name: "Peggs",
    state_id: "OK",
    lat: 36.0673,
    lng: -95.0718,
    id: 1840024906,
  },
  {
    name: "Oilton",
    state_id: "OK",
    lat: 36.0821,
    lng: -96.5819,
    id: 1840020392,
  },
  {
    name: "Quogue",
    state_id: "NY",
    lat: 40.8215,
    lng: -72.5987,
    id: 1840005132,
  },
  {
    name: "Colfax",
    state_id: "IL",
    lat: 40.5666,
    lng: -88.616,
    id: 1840011975,
  },
  {
    name: "Keewatin",
    state_id: "MN",
    lat: 47.4006,
    lng: -93.0828,
    id: 1840007717,
  },
  {
    name: "Leesburg",
    state_id: "AL",
    lat: 34.1839,
    lng: -85.775,
    id: 1840016636,
  },
  {
    name: "Grimsley",
    state_id: "TN",
    lat: 36.2761,
    lng: -84.997,
    id: 1840025760,
  },
  {
    name: "Eclectic",
    state_id: "AL",
    lat: 32.6387,
    lng: -86.0386,
    id: 1840016965,
  },
  {
    name: "Alleghenyville",
    state_id: "PA",
    lat: 40.2327,
    lng: -75.9762,
    id: 1840034841,
  },
  {
    name: "Alexandria",
    state_id: "TN",
    lat: 36.0791,
    lng: -86.0381,
    id: 1840015349,
  },
  {
    name: "Edinburg",
    state_id: "IL",
    lat: 39.658,
    lng: -89.3903,
    id: 1840012545,
  },
  {
    name: "Belfield",
    state_id: "ND",
    lat: 46.8859,
    lng: -103.199,
    id: 1840001937,
  },
  {
    name: "Pinesdale",
    state_id: "MT",
    lat: 46.3338,
    lng: -114.2231,
    id: 1840022323,
  },
  {
    name: "Palmer",
    state_id: "PR",
    lat: 18.3699,
    lng: -65.774,
    id: 1630035538,
  },
  {
    name: "Ramsey",
    state_id: "IL",
    lat: 39.1457,
    lng: -89.1103,
    id: 1840012735,
  },
  {
    name: "Coal Hill",
    state_id: "AR",
    lat: 35.4375,
    lng: -93.6647,
    id: 1840014534,
  },
  {
    name: "Lemont Furnace",
    state_id: "PA",
    lat: 39.9103,
    lng: -79.6629,
    id: 1840026570,
  },
  {
    name: "Cyril",
    state_id: "OK",
    lat: 34.8986,
    lng: -98.2031,
    id: 1840021764,
  },
  {
    name: "Cobb",
    state_id: "CA",
    lat: 38.8356,
    lng: -122.723,
    id: 1840017546,
  },
  {
    name: "Red Lick",
    state_id: "TX",
    lat: 33.4767,
    lng: -94.1688,
    id: 1840020614,
  },
  {
    name: "Seelyville",
    state_id: "IN",
    lat: 39.4936,
    lng: -87.2667,
    id: 1840010621,
  },
  {
    name: "Goshen",
    state_id: "KY",
    lat: 38.4027,
    lng: -85.5825,
    id: 1840013193,
  },
  {
    name: "Enterprise",
    state_id: "WV",
    lat: 39.4197,
    lng: -80.2834,
    id: 1840005764,
  },
  {
    name: "Odin",
    state_id: "IL",
    lat: 38.6159,
    lng: -89.0541,
    id: 1840012847,
  },
  {
    name: "Willards",
    state_id: "MD",
    lat: 38.3922,
    lng: -75.3494,
    id: 1840006224,
  },
  {
    name: "Wilhoit",
    state_id: "AZ",
    lat: 34.4083,
    lng: -112.6156,
    id: 1840019165,
  },
  {
    name: "Cherokee",
    state_id: "AL",
    lat: 34.7577,
    lng: -87.967,
    id: 1840016548,
  },
  {
    name: "Richton",
    state_id: "MS",
    lat: 31.3495,
    lng: -88.9407,
    id: 1840018181,
  },
  {
    name: "Beckemeyer",
    state_id: "IL",
    lat: 38.6059,
    lng: -89.4322,
    id: 1840012853,
  },
  {
    name: "Tesuque",
    state_id: "NM",
    lat: 35.7486,
    lng: -105.921,
    id: 1840019104,
  },
  {
    name: "Thornville",
    state_id: "OH",
    lat: 39.8929,
    lng: -82.4142,
    id: 1840012487,
  },
  {
    name: "Waldo",
    state_id: "FL",
    lat: 29.7926,
    lng: -82.1663,
    id: 1840015943,
  },
  {
    name: "Eddyville",
    state_id: "IA",
    lat: 41.1591,
    lng: -92.6297,
    id: 1840008267,
  },
  {
    name: "McCullom Lake",
    state_id: "IL",
    lat: 42.3682,
    lng: -88.2966,
    id: 1840011140,
  },
  {
    name: "North Bonneville",
    state_id: "WA",
    lat: 45.6425,
    lng: -121.9692,
    id: 1840019903,
  },
  {
    name: "Santa Anna",
    state_id: "TX",
    lat: 31.7369,
    lng: -99.3254,
    id: 1840023107,
  },
  {
    name: "North English",
    state_id: "IA",
    lat: 41.5168,
    lng: -92.0777,
    id: 1840008155,
  },
  {
    name: "De Kalb",
    state_id: "MS",
    lat: 32.7707,
    lng: -88.656,
    id: 1840016954,
  },
  {
    name: "Worthing",
    state_id: "SD",
    lat: 43.33,
    lng: -96.7683,
    id: 1840002842,
  },
  {
    name: "Eustace",
    state_id: "TX",
    lat: 32.3075,
    lng: -96.0139,
    id: 1840020771,
  },
  {
    name: "North Branch",
    state_id: "MI",
    lat: 43.2291,
    lng: -83.1903,
    id: 1840011002,
  },
  {
    name: "Winfield",
    state_id: "TN",
    lat: 36.5637,
    lng: -84.4459,
    id: 1840016018,
  },
  {
    name: "New Cumberland",
    state_id: "WV",
    lat: 40.5072,
    lng: -80.6115,
    id: 1840005404,
  },
  {
    name: "Masaryktown",
    state_id: "FL",
    lat: 28.4417,
    lng: -82.461,
    id: 1840014108,
  },
  {
    name: "Winchester",
    state_id: "OH",
    lat: 38.9436,
    lng: -83.654,
    id: 1840012771,
  },
  {
    name: "Vander",
    state_id: "NC",
    lat: 35.0347,
    lng: -78.7888,
    id: 1840013489,
  },
  {
    name: "Salcha",
    state_id: "AK",
    lat: 64.5272,
    lng: -146.904,
    id: 1840037687,
  },
  {
    name: "Pala",
    state_id: "CA",
    lat: 33.3617,
    lng: -117.0696,
    id: 1840028262,
  },
  {
    name: "Rushmere",
    state_id: "VA",
    lat: 37.0836,
    lng: -76.6747,
    id: 1840006486,
  },
  {
    name: "Mount Ida",
    state_id: "AR",
    lat: 34.5509,
    lng: -93.6309,
    id: 1840014671,
  },
  {
    name: "Chalfant",
    state_id: "CA",
    lat: 37.5105,
    lng: -118.375,
    id: 1840075653,
  },
  {
    name: "Tilghman Island",
    state_id: "MD",
    lat: 38.7026,
    lng: -76.3354,
    id: 1840031488,
  },
  {
    name: "Edwards",
    state_id: "MS",
    lat: 32.3326,
    lng: -90.6055,
    id: 1840016991,
  },
  {
    name: "Yorkville",
    state_id: "OH",
    lat: 40.1528,
    lng: -80.7079,
    id: 1840012388,
  },
  {
    name: "Wanatah",
    state_id: "IN",
    lat: 41.4304,
    lng: -86.8926,
    id: 1840010202,
  },
  {
    name: "South Waverly",
    state_id: "PA",
    lat: 41.9955,
    lng: -76.5441,
    id: 1840000518,
  },
  {
    name: "Grand Rapids",
    state_id: "OH",
    lat: 41.4078,
    lng: -83.868,
    id: 1840011549,
  },
  {
    name: "Tolar",
    state_id: "TX",
    lat: 32.3894,
    lng: -97.9191,
    id: 1840022081,
  },
  {
    name: "Casa Blanca",
    state_id: "AZ",
    lat: 33.1154,
    lng: -111.9081,
    id: 1840025877,
  },
  {
    name: "Talmage",
    state_id: "CA",
    lat: 39.1314,
    lng: -123.1648,
    id: 1840018783,
  },
  {
    name: "Tuttletown",
    state_id: "CA",
    lat: 38.0003,
    lng: -120.4503,
    id: 1840024694,
  },
  {
    name: "Pennsboro",
    state_id: "WV",
    lat: 39.2874,
    lng: -80.9754,
    id: 1840005792,
  },
  {
    name: "Farmersville",
    state_id: "OH",
    lat: 39.6787,
    lng: -84.4275,
    id: 1840012488,
  },
  {
    name: "Beaverdale",
    state_id: "IA",
    lat: 40.8537,
    lng: -91.2077,
    id: 1840026382,
  },
  {
    name: "Lakeview",
    state_id: "MI",
    lat: 43.4434,
    lng: -85.2738,
    id: 1840010990,
  },
  {
    name: "Cambridge",
    state_id: "NE",
    lat: 40.2843,
    lng: -100.1653,
    id: 1840007247,
  },
  {
    name: "McConnellsburg",
    state_id: "PA",
    lat: 39.932,
    lng: -77.9958,
    id: 1840001498,
  },
  {
    name: "Sultana",
    state_id: "CA",
    lat: 36.5464,
    lng: -119.3359,
    id: 1840024881,
  },
  {
    name: "Audubon Park",
    state_id: "NJ",
    lat: 39.8969,
    lng: -75.089,
    id: 1840033220,
  },
  {
    name: "Scio",
    state_id: "OR",
    lat: 44.7046,
    lng: -122.8508,
    id: 1840021251,
  },
  {
    name: "Electric City",
    state_id: "WA",
    lat: 47.9218,
    lng: -119.0475,
    id: 1840021104,
  },
  {
    name: "Cedar Bluff",
    state_id: "VA",
    lat: 37.0876,
    lng: -81.7632,
    id: 1840006452,
  },
  {
    name: "Manassa",
    state_id: "CO",
    lat: 37.1738,
    lng: -105.9373,
    id: 1840022566,
  },
  {
    name: "West Cape May",
    state_id: "NJ",
    lat: 38.9423,
    lng: -74.9377,
    id: 1840001600,
  },
  {
    name: "Halsey",
    state_id: "OR",
    lat: 44.3829,
    lng: -123.1095,
    id: 1840019989,
  },
  {
    name: "Trappe",
    state_id: "MD",
    lat: 38.6635,
    lng: -76.0518,
    id: 1840006092,
  },
  {
    name: "Bude",
    state_id: "MS",
    lat: 31.463,
    lng: -90.8509,
    id: 1840015862,
  },
  {
    name: "Clarks",
    state_id: "LA",
    lat: 32.0288,
    lng: -92.1408,
    id: 1840018124,
  },
  {
    name: "King Cove",
    state_id: "AK",
    lat: 55.0858,
    lng: -162.2978,
    id: 1840023268,
  },
  {
    name: "Solomon",
    state_id: "KS",
    lat: 38.9215,
    lng: -97.3692,
    id: 1840009704,
  },
  {
    name: "McAlisterville",
    state_id: "PA",
    lat: 40.6381,
    lng: -77.273,
    id: 1840005385,
  },
];
