import { useContext, useEffect, useMemo, useRef, useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import { usePostHog } from "posthog-js/react";
import cn from "classnames";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import { SnackBarContext } from "components/snack-bar";
import { debounce } from "lodash";
import { Box, makeStyles } from "@material-ui/core";
import { EmitAnalyticsEvent } from "module/analytics/application/EmitAnalyticsEvent";
import { useJsApiLoader } from "@react-google-maps/api";
import { PlaceAutocomplete } from "components/place-autocomplete";
import { CustomTooltipContent } from "components/tooltip-content";
import { LightTooltip } from "components/light-tooltip";
import Header from "components/header";
import { EDDMHeader } from "components/header/EDDMHeader";
import BounceLoader from "components/loaders/bounce-loader";
import { IfElse } from "components/logic";
import { ProgressBarContext } from "components/progress-bar";
import { EDDMSegmentsOriginator, EDDMSegmentsCaretaker } from "module/eddm";
import { checkIsNeedUpdateLastActiveStepState } from "utils";
import { Saving } from "components/Saving";
import { AudienceMapEDDMV2 } from "components/audience-map/components/AudienceMapEDDM/AudienceMapEDDMV2";
import CONFIG from "config/config";
import { DM_ROUTING } from "config/routing";
import {
  getSelectedRoutesFromDistributionLocations,
  radiusToZoom,
} from "components/audience-map/components/AudienceMapEDDM/utils";
import CustomSelect from "components/custom-select";
import { EDDMEmptyState, EDDMToolbar } from "components/audience-map/components";
import { calculateCircleRadius } from "components/audience-map/utils";
import useExitPrompt from "Hooks/useExitPrompt";
import useOverlaysFetch from "Hooks/useOverlaysFetch.js";
import { apiClient } from "../../../module/api";
import { NextButton } from "../../../components/next-button";
import { CAMPAIGN_INTERNAL_STATUSES, EDDM_NAVIGATION, COUPON_CODE_ERROR_MESSAGE, MAP_TYPES } from "../../constants";
import {
  createQuote,
  updateCampaignExtraData,
  updateSelfServeCampaignData,
  updateCampaign as updateCampaignDB,
} from "../../../graphQL";
import BlockingOverlay from "../../../components/BlockingOverlay";
import { styles } from "./styles.js";
import { useStore } from "../../../store";
import { CAMPAIGN_ENUM_TYPES } from "../../../shared/constants";
import "./styles.css";

const libraries = ["geometry", "places", "drawing"];
const useStyles = makeStyles(() => styles);
const radiusOptions = [1, 2, 5, 10].map((v) => ({
  value: v,
  label: `${v} miles`,
}));

export const SegmentsV2 = () => {
  const classes = useStyles();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: CONFIG.GOOGLE_API_KEY,
    libraries: libraries,
    language: "en",
  });

  const posthog = usePostHog();
  const postHogTracker = new EmitAnalyticsEvent(posthog);

  const history = useHistory();
  const [, setShowExitPrompt] = useExitPrompt(false);
  const [radius, setRadius] = useState(2);
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  const [drawingMode, setDrawingMode] = useState(MAP_TYPES.CIRCLE);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [circleSelectorRadius, setCircleSelectorRadius] = useState(calculateCircleRadius(radius)); // radius * 10% * metersPerMiles
  const [isCalculatingMultipleSelection, setIsCalculatingMultipleSelection] = useState(false);
  const [googleMap, setGoogleMap] = useState(null);
  const [shouldHideStatistics, setShouldHideStatistics] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    costsCalculationData,
    country,
    user,
    campaign: { channel, campaignName, internalStatus, isSubmitted, id: campaignId, lastActiveStep, stripeCouponCode },
    errors: { campaignNameDuplicates },
    user: { accessDenied = true, id: userId, isVerified },
    client: { id: clientId, name: clientName },
    map: { center, loading, loadingPercentage, visiblePostCodes = [], shouldShowDefaultPin },
    distributionLocations,
    setDistributionLocations,
    setFocusedLocation,
    updateCampaign,
    updateMap,
    updateErrors,
  } = useStore();
  const fromHome = useMemo(() => lastActiveStep === "home", [lastActiveStep]);
  const { loadData } = useOverlaysFetch({ countryCode: country.code, disabled: false });

  const posthogBasePayload =
    user?.id && clientName && navigator.userAgent
      ? {
          userId: user?.id,
          companyName: clientName,
          device: navigator.userAgent,
        }
      : null;

  const { totalCost, tax } = costsCalculationData?.detailedCost || {};

  // useContext

  const runSnackBar = useContext(SnackBarContext);
  const runProgressBar = useContext(ProgressBarContext);

  // const runDialogModal = useContext(DialogModalContext);

  // useState
  const [selectedRoutes, setSelectedRoutes] = useState(
    getSelectedRoutesFromDistributionLocations(distributionLocations)
  );
  const [shouldAutoSave, setShouldAutoSave] = useState(false);

  const [error, setError] = useState(true);
  // const [gTagEvents, setgTagEvents] = useState({ ss_campaign_aud_area: false, ss_campaign_aud_city: false });
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [validatingCampaignName, setValidatingCampaignName] = useState(false);
  const [isUndoOrRedo, setIsUndoOrRedo] = useState(false);
  const [isSingleRouteSelection, setIsSingleRouteSelection] = useState(false);
  const [routes, setRoutes] = useState({});

  // useRef
  const clickSaveButtonRef = useRef(false);
  const originator = useRef(new EDDMSegmentsOriginator(distributionLocations || [])).current;
  const caretaker = useRef(new EDDMSegmentsCaretaker(originator)).current;

  // useMemo
  const isNextActive = useMemo(() => {
    return costsCalculationData.flyersAmount && costsCalculationData.flyersAmount > 1000;
  }, [costsCalculationData.flyersAmount, isSubmitted]);

  const TooltipContent = useMemo(() => {
    if (!isNextActive) {
      return (
        <CustomTooltipContent
          title="Not enough addresses!"
          content="To proceed, please select at least 1000 addresses."
        />
      );
    }

    return "";
  }, [isNextActive]);

  // useEffect

  useEffect(() => {
    if (isLoaded && internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT && stripeCouponCode) {
      getStripeCouponData({ stripeCouponCode });
    }
  }, [isLoaded, stripeCouponCode, internalStatus]);

  useEffect(() => {
    if (campaignNameDuplicates) {
      setError("Duplicate campaign name. Please choose another.");
      return;
    }
  }, [campaignNameDuplicates]);

  useEffect(() => {
    caretaker.clearHistory(campaignId);
    originator.setState(distributionLocations || []);
    caretaker.save(campaignId);
    if (distributionLocations && distributionLocations.length) {
      const focusedLocation = distributionLocations.find((location) => location.isFocused);
      if (focusedLocation) {
        setRadius(focusedLocation.radius);
        updateMap({
          center: { lat: focusedLocation.lat, lng: focusedLocation.lng, zoom: radiusToZoom[focusedLocation.radius] },
        });
      }
      return () => {
        caretaker.clearHistory(campaignId);
      };
    }
  }, []);

  const saveDataToDB = debounce(() => {
    handleAutoSave();
    setShouldAutoSave(false);
  }, 1000);

  useEffect(() => {
    if (distributionLocations && shouldAutoSave) {
      if (isSingleRouteSelection) {
        updateEDDMSegmentsCaretaker();
        setIsSingleRouteSelection(false);
      }
      saveDataToDB();
    }
    return () => {
      saveDataToDB.cancel();
    };
  }, [distributionLocations]);

  const updateEDDMSegmentsCaretaker = () => {
    if (!isUndoOrRedo && distributionLocations && distributionLocations?.length) {
      originator.setState(distributionLocations);
      caretaker.save(campaignId);
    }

    if (!distributionLocations || !distributionLocations.length) {
      caretaker.clearHistory(campaignId);
      originator.setState([]);
    }
  };

  // useCallback
  const saveCampaign = async (isAutoSave) => {
    setIsSaving(true);
    if (!isSubmitted && distributionLocations) {
      const payloadCampaignExtraData = {
        campaignId,
        totalCosts: totalCost,
        flyersCount: costsCalculationData?.flyersAmount || 0,
        taxes: tax,
      };

      const isNeedUpdateLastActiveStep = checkIsNeedUpdateLastActiveStepState({
        stateLastActiveStep: lastActiveStep,
        newLastActiveStep: "segments",
        isDM: true,
      });

      if (isNeedUpdateLastActiveStep && !isAutoSave) {
        payloadCampaignExtraData.lastActiveStep = "segments";
      }

      await updateCampaignExtraData(payloadCampaignExtraData);

      updateEDDMSegmentsCaretaker();

      await updateSelfServeCampaignData({
        campaignId: campaignId,
        distributionLocations: distributionLocations,
      });
      if (isNeedUpdateLastActiveStep && !isAutoSave) {
        updateCampaign({ lastActiveStep: "segments" });
      }
      await setQuoteNumber(campaignId);
      if (!isAutoSave) {
        nextBtnClicked(campaignId);
      }
    }
    setIsSaving(false);
  };

  const handleAutoSave = async () => {
    setIsSaving(true);
    if (!isSingleRouteSelection) {
      updateEDDMSegmentsCaretaker();
    }
    if (!distributionLocations?.length && lastActiveStep !== "home") {
      await updateCampaignExtraData({
        campaignId,
        lastActiveStep: "home",
      });
      updateCampaign({ lastActiveStep: "home" });
    }
    await updateSelfServeCampaignData({
      campaignId: campaignId,
      distributionLocations: distributionLocations,
    });
    setIsSaving(false);
    setIsCalculatingMultipleSelection(false);
  };

  const fetchRoutes = async ({ layer = "USCarrierRoutesUnflatten", after, previousRoutes = {}, center, radius }) => {
    const includeAddressCount = false;
    const routesResponse = await loadData({
      layer,
      includeAddressCount,
      after,
      centerSphere: center,
      radius,
    });

    if (!routesResponse) {
      setRoutes({});
      return {};
    }

    updateMap({
      loadingPercentage: ((Object.keys(previousRoutes).length * 100) / routesResponse.totalCount).toFixed(),
    });
    if (routesResponse?.pageInfo?.hasNextPage) {
      return await fetchRoutes({
        center,
        radius,
        layer,
        after: routesResponse.pageInfo.endCursor,
        previousRoutes: { ...previousRoutes, ...routesResponse.data },
      });
    } else {
      updateMap({
        loadingPercentage: null,
        loading: false,
      });
      setRoutes({ ...previousRoutes, ...routesResponse.data });
      return { ...previousRoutes, ...routesResponse.data };
    }
  };

  const setQuoteNumber = async (campaignId) => {
    const quote = await createQuote(campaignId);
    updateCampaign({ quote });
  };

  const nextBtnClicked = (id) => {
    return !internalStatus || internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT
      ? history.push(generatePath(DM_ROUTING.DM_UPLOAD_FLYER, { campaignId: id, clientId }))
      : history.push(generatePath(DM_ROUTING.DM_CAMPAIGN_DETAILS, { campaignId: id, clientId }));
  };

  const headerActions = {
    HOME: {
      action: () => {
        console.log("redirect home");
      },
    },
    NEXT: {
      action: async () => {
        if (isSubmitted) {
          runProgressBar(60);
          nextBtnClicked(campaignId);
          runProgressBar(80);
          runProgressBar(-1);
        } else {
          clickSaveButtonRef.current = false;
          await saveCampaign();
        }
      },
    },
  };

  const getStripeCouponData = async ({ stripeCouponCode }) => {
    if (stripeCouponCode) {
      const code = stripeCouponCode.trim();
      try {
        const res = await apiClient.getPromotionCode({
          promotionCode: code,
          countryCode: country?.code,
        });
        if (res.status !== 200) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }
        const { promotionCodes: responseData } = await res.json();
        if (!responseData) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const { data } = responseData;
        if (!data || !data[0] || code !== data[0].code) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const {
          id: promoId,
          coupon: couponObject,
          max_redemptions: max_redemptions_promotion_code,
          times_redeemed: times_redeemed_promotion_code,
          restrictions,
        } = data[0];

        if (!couponObject || !couponObject.id || !couponObject.percent_off) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["NO_PROMO_EXIST"]);
        }
        if (
          max_redemptions_promotion_code &&
          times_redeemed_promotion_code &&
          max_redemptions_promotion_code <= times_redeemed_promotion_code
        ) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["EXCEED_USE_PROMO"]);
        }
        if (!max_redemptions_promotion_code && !times_redeemed_promotion_code && !restrictions) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        const { max_redemptions: max_redemptions_coupon, times_redeemed: times_redeemed_coupon } = couponObject;
        if (max_redemptions_coupon && times_redeemed_coupon && max_redemptions_coupon <= times_redeemed_coupon) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["EXCEED_USE_COUPON"]);
        }
        if (!max_redemptions_coupon && !times_redeemed_coupon && !restrictions) {
          throw new Error(COUPON_CODE_ERROR_MESSAGE["DEFAULT"]);
        }

        if (restrictions) {
          const { first_time_transaction } = restrictions;
          if (first_time_transaction) {
            // TO DO: SAVE THIS ON STRIPE CUSTOMER METADATA OR ON THE OPPIZI USER
            const didReedemed = !!localStorage.getItem(`first_time_transaction_${promoId}`);
            if (didReedemed) {
              throw new Error(COUPON_CODE_ERROR_MESSAGE["ONLY_FIRST"]);
            }
          }
        }

        const percent_off = couponObject.percent_off;

        runSnackBar({
          type: "success",
          vertical: "top",
          msg: `Your ${percent_off}% discount will be applied at checkout.`,
        });
      } catch (err) {
        setCouponErrorMessage(err.message);
      }
    }
    return false;
  };

  const handleAutocompleteChanged = (newMarker) => {
    if (newMarker) {
      const lat = newMarker?.geometry?.location?.lat();
      const lng = newMarker?.geometry?.location?.lng();
      const locationExists = !!distributionLocations.find(
        (location) =>
          location.lat === newMarker?.geometry?.location?.lat() && location.lng === newMarker?.geometry?.location?.lng()
      );

      postHogTracker.run({
        eventName: "Searched initial address to EDDM",
        userId: user?.id,
        clientName,
        device: navigator.userAgent,
      });

      setShouldAutoSave(true);
      setIsUndoOrRedo(false);
      if (!locationExists) {
        setDistributionLocations([
          {
            name: newMarker.formattedAddress,
            lat,
            lng,
            radius: 2,
            isFocused: true,
            selectedRoutes: [],
          },
          ...distributionLocations.map((l) => ({ ...l, isFocused: false })),
        ]);
        setRadius(2);
      } else {
        setDistributionLocations([
          ...distributionLocations.map((l) => ({
            ...l,
            isFocused: l.lat === newMarker?.geometry?.location?.lat() && l.lng === newMarker?.geometry?.location?.lng(),
          })),
        ]);
      }
      updateMap({ center: { lat, lng } });
    }
  };

  const fetchRoutesAndUpdateMap = (focusedLocation, radius) => {
    updateMap({ loading: true, center: { lat: focusedLocation.lat, lng: focusedLocation.lng } });
    fetchRoutes({ center: [focusedLocation.lng, focusedLocation.lat], radius: radius * 1609.34 });
  };

  useEffect(() => {
    if (distributionLocations && distributionLocations.length) {
      setDistributionLocations(
        distributionLocations.map((location) => {
          if (location.isFocused) {
            return { ...location, radius: radius };
          } else {
            return location;
          }
        })
      );
    }
    setCircleSelectorRadius(calculateCircleRadius(radius));
    // setDrawingMode(null);
  }, [radius]);

  const isVerifiedIsLoaded = typeof isVerified === "boolean";
  const getFilteredPostCodes = (key) => [...visiblePostCodes.filter((postCode) => postCode?.name?.includes(key))];

  return (
    <Fragment>
      {isBlocked ? <BlockingOverlay /> : null}
      <Box>
        <Helmet>
          <title>Segments</title>
        </Helmet>
        <EDDMHeader
          tabs={EDDM_NAVIGATION}
          activeTabIndex={1}
          headerActions={headerActions}
          hideBackButton={isSubmitted || !!campaignId}
          hasError={!isNextActive || isEdited}
          isNextActive={isNextActive}
          isDM={channel === CAMPAIGN_ENUM_TYPES.DIRECTMAIL}
          selectedRoutes={selectedRoutes}
          setShouldHideStatistics={setShouldHideStatistics}
        />
        <Box
          className={cn(classes.container, {
            [classes.notVerifiedContainer]: isVerifiedIsLoaded && !isVerified,
          })}
        >
          {isLoaded && !distributionLocations?.length && (
            <EDDMEmptyState
              countryCode={country?.code}
              disablePlaceAutocomplete={isSubmitted || loading}
              handleAutocompleteChanged={handleAutocompleteChanged}
              getFilteredPostCodes={getFilteredPostCodes}
            />
          )}

          <Box className={cn(classes.mapContainerV2, "mob-map-container")}>
            <IfElse condition={isLoaded}>
              <Box className={classes.mapWrapper}>
                <AudienceMapEDDMV2
                  isSubmitted={isSubmitted}
                  radius={radius}
                  setRadius={setRadius}
                  selectedRoutes={selectedRoutes}
                  setSelectedRoutes={setSelectedRoutes}
                  shouldAutoSave={shouldAutoSave}
                  setShouldAutoSave={setShouldAutoSave}
                  drawingMode={drawingMode}
                  setDrawingMode={setDrawingMode}
                  circleSelectorRadius={circleSelectorRadius}
                  isUndoOrRedo={isUndoOrRedo}
                  setIsUndoOrRedo={setIsUndoOrRedo}
                  routes={routes}
                  setRoutes={setRoutes}
                  fetchRoutes={fetchRoutes}
                  fetchRoutesAndUpdateMap={fetchRoutesAndUpdateMap}
                  setCircleSelectorRadius={setCircleSelectorRadius}
                  isCalculatingMultipleSelection={isCalculatingMultipleSelection}
                  setIsCalculatingMultipleSelection={setIsCalculatingMultipleSelection}
                  setIsSingleRouteSelection={setIsSingleRouteSelection}
                  googleMap={googleMap}
                  setGoogleMap={setGoogleMap}
                  handleAutocompleteChanged={handleAutocompleteChanged}
                  getFilteredPostCodes={getFilteredPostCodes}
                  shouldHideStatistics={shouldHideStatistics}
                  setShouldHideStatistics={setShouldHideStatistics}
                />
                {!!distributionLocations?.length && !isMobile && !isSubmitted && (
                  <EDDMToolbar
                    radius={radius}
                    setRadius={setRadius}
                    isSaving={isSaving}
                    radiusOptions={radiusOptions}
                    drawingMode={drawingMode}
                    setDrawingMode={setDrawingMode}
                    setShouldAutoSave={setShouldAutoSave}
                    circleSelectorRadius={circleSelectorRadius}
                    setCircleSelectorRadius={setCircleSelectorRadius}
                    setIsUndoOrRedo={setIsUndoOrRedo}
                    setSelectedRoutes={setSelectedRoutes}
                    fetchRoutes={fetchRoutes}
                    originator={originator}
                    caretaker={caretaker}
                    fetchRoutesAndUpdateMap={fetchRoutesAndUpdateMap}
                    googleMap={googleMap}
                    setShouldHideStatistics={setShouldHideStatistics}
                  />
                )}
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <BounceLoader />
              </Box>
            </IfElse>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
